import { React , useState , useEffect } from 'react'
import { useParams , useNavigate } from 'react-router'
import style from '../../asset/style'
import Basic from '../../common/frame/Basic'
import RegiForm from '../before/register/RegiForm'
import aquery from '../../asset/Img/aquery.png'
import answer from '../../asset/Img/answer.png'
import { UiInput_title } from '../../uiComponents/UiInput/UiInput'
import { Register_One } from '../page_index'
import Register_One_style from '../before/register/Register_One_style'
import UiBtn from '../../uiComponents/UiBtn/UiBtn'
import DaumPostcode from 'react-daum-postcode'
import { UiSearchList } from '../../uiComponents/UiSearchList/UiSearchList'
import util from '../../asset/util'
import apiFn from '../../asset/apiClass'

const Academy_regi_two_add = ({
    c_regiData,
    setC_regiData
}) => {
    let params = useParams();
    let navigate = useNavigate();

    const [isTeacher ,setIsTeacher] = useState(false);
    const [isChk , setIsChk] = useState(false);

    const [mapData,setMapData] = useState({})

    const [academyData,setAcademyData] = useState([])

    const apiPro_chk = async () => {
        try {
            let data = await apiFn.comApi({
                state : {
                    table : "class",
                    page : 1,
                    amount : 9999,
                    sort : "reg_date desc",
                    where: {
                        'replace(name," ","")'
                        // `replace(${content}, ' ', '')` 
                        : 
                        `like '%${util.spaceTrim(c_regiData["name"])}%'`
                    },
                    
                }
            })

            // console.log(data["list"])
            // return;
            setAcademyData([
                ...data["list"]
            ])
        }
        catch(e){
        }
    }

    useEffect(()=>{
        apiPro_chk();
    },[])

    useEffect(()=>{
        if(params["who"] == "teacher"){
            setIsTeacher(true)
        }
        else {
            setIsTeacher(false)
        }
    },[params])

    return (
        <Basic title={"새 클래스룸 생성"}>
            <RegiForm 
                num={2}
                isTeacher={isTeacher}
                title="중복 클래스룸 확인"
                isTopTitle={false}
                dotCnt = {3}
            />
        <div style={{
            fontSize : "12px",
            margin : "20px 0px"
        }}>
            <div>
                {`입력한 정보와 동일한 곳으로 보이는 클래스룸이 ${academyData.length}개 있어요.`}
            </div>
            <div>
                클래스룸 생성을 계속 진행하시겠어요?
            </div>
        </div>
        <UiSearchList
            isAcademy={false}
            data={academyData}
            isTitle={false}
        />
        <div style={Register_One_style.b_c}>
            <UiBtn
                btnStyle={{
                    ...Register_One_style.b,
                    color : style.common.fontColor_5,
                    border : "1px solid",
                    borderColor : style.common.borderColor

                }}
                title="아니요"
                fnClick={()=>{
                    navigate(`/academy/${params["who"]}/academy`)
                }}
            />
            <UiBtn
                btnStyle={{
                    ...Register_One_style.b,
                    borderColor : 
                    ( isTeacher ? style.common.t_color : style.common.s_color )
                    ,
                    backgroundColor : 
                    ( isTeacher ? style.common.t_color : style.common.s_color )
                }}
                title="계속 진행"
                fnClick={()=>{
                    navigate("/academyregithree/"+params["who"])
                    return;
                    if(isChk){
                        navigate("/academyregithree/"+params["who"])
                    }
                }}
            />
        </div>
        </Basic>
    )
}

export default Academy_regi_two_add
