import type { IcDefatultProps } from "../types";
import React from "react";

export function IcEdit({ width = 24, height = 24, color = "white" }: IcDefatultProps): JSX.Element {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.20001 13.0346H13.2M2.80005 13.0346L5.71071 12.4481C5.86522 12.417 6.0071 12.3409 6.11853 12.2294L12.6343 5.71007C12.9467 5.3975 12.9465 4.89084 12.6338 4.57853L11.2536 3.19981C10.941 2.88764 10.4346 2.88785 10.1224 3.20029L3.60589 9.72032C3.49468 9.83159 3.41875 9.97318 3.38758 10.1274L2.80005 13.0346Z"
        stroke={color}
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
