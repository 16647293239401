import UiSearchSection_Style from "../../../uiComponents/UiSearchList/UiSearchSection_Style";
import { UiCheckBox } from "../../../uiComponents/UiCheckBox/UiCheckBox";
import { React, useEffect, useRef, useState } from "react";
import util from "../../../asset/util";
import { useNavigate, useParams } from "react-router";
import { KeyboardArrowDown, KeyboardArrowUp } from "@material-ui/icons";
import style from "../../../asset/style";
import constants from "../../../asset/constants";
import noLogo from "../../../asset/Img/class1.png";

const ClassroomListSection = ({
  isTeacher,
  fnClick,
  data = [],
  isClassStat = true,
  isSearch = false,
  scrollKey = "",
  setScrolllKey,
}) => {
  let params = useParams();

  const [isSimpleView, setIsSimpleView] = useState(
    window.localStorage.getItem("classroom_omit") === "true" ? true : false
  );

  return (
    <div style={UiSearchSection_Style.f}>
      <div style={UiSearchSection_Style.t_w}>소속 클래스룸 총 {data?.length ?? 0}개</div>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
        }}
      >
        <UiCheckBox
          value={"isImportant"}
          name="iI"
          className={params["who"] == "teacher" ? "t" : "s"}
          checked={isSimpleView}
          fnCheck={(e) => {
            setIsSimpleView(e.target.checked);
            window.localStorage.setItem("classroom_omit", e.target.checked);
          }}
        />
        <div
          style={{
            fontSize: "12px",
            color: style.common.fontColor_1,
            marginLeft: "5px",
          }}
        >
          간략히 보기
        </div>
      </div>
      {data?.map((v, i) => (
        <ClassroomCard
          key={"classroomCard" + i}
          isSimpleView={isSimpleView}
          scrollKey={scrollKey}
          setScrolllKey={setScrolllKey}
          isTeacher={isTeacher}
          fnClick={fnClick}
          data={v}
          isClassStat={isClassStat}
          isSearch={isSearch}
        />
      ))}
    </div>
  );
};
const ClassroomCard = ({ isSimpleView, isBorder = true, data = {}, scrollKey, setScrolllKey }) => {
  const navigate = useNavigate();
  const params = useParams();
  const [isShowContent, setIsShowContent] = useState(true);
  let imgRef = useRef(null);
  let scrollRef = useRef(null);

  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollIntoView({
        block: "center",
      });
      if (setScrolllKey) {
        setScrolllKey("");
      }
    }
  }, [scrollRef]);

  const ClassroomRoleText = () => {
    let innerText = "";
    if (data.status === 0) innerText = "승인 대기중";
    else if (data.status === 1 && data.type === 5) innerText = "관리자";
    else if (data.status === 1 && data.type === 6) innerText = "일반 선생님";
    return innerText;
  };

  return (
    <div
      style={{
        ...UiSearchSection_Style.section_c,
        backgroundColor: "white",
        border: isBorder ? "1px solid" : "none",
      }}
      ref={scrollKey == data["id"] ? scrollRef : null}
      onClick={() => {
        navigate("/profile/" + params["who"] + "/" + data["cid"] + "/de");
      }}
    >
      <div style={UiSearchSection_Style.section_w}>
        <div
          style={{
            ...UiSearchSection_Style.section_t_w,
            borderBottom: !isSimpleView && isShowContent ? "1px solid" : "none",
            borderColor: !isSimpleView && isShowContent ? "rgb(200,200,200)" : "none",
            minHeight: !isSimpleView ? "85px" : "40px",
          }}
        >
          <div style={UiSearchSection_Style.section_t_t_c}>
            {!isSimpleView && (
              <div style={UiSearchSection_Style.section_t_t_i_w}>
                <img
                  ref={imgRef}
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                    objectPosition: "center",
                  }}
                  loading={"lazy"}
                  onError={(e) => {
                    e.currentTarget.src = noLogo;
                  }}
                  src={data.img ? `${constants.s3_url}/class/${data.img}.jpg?` : noLogo}
                />
              </div>
            )}
            <div
              style={{
                ...UiSearchSection_Style.section_t_t_r_w,
                marginLeft: !isSimpleView ? "17px" : "0px",
              }}
            >
              <div style={UiSearchSection_Style.section_t_t_r_w_text_1}>
                {data?.class_name ?? "이름 없는 학원"}
              </div>
              {!isSimpleView && (
                <div
                  style={{
                    ...UiSearchSection_Style.section_t_t_r_w_text_2,
                    display: "flex",
                  }}
                >
                  {data?.reg_name ?? ""}
                </div>
              )}
            </div>
          </div>
        </div>
        {!isSimpleView && isShowContent && (
          <div style={UiSearchSection_Style.section_b_w}>
            <div style={UiSearchSection_Style.section_b_w_text}>
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "flex-end",
                }}
              >
                <KeyboardArrowUp
                  style={{
                    color: style.common.t_color,
                    fontSize: "22px",
                    padding: 0,
                    margin: 0,
                  }}
                  onClick={(e) => {
                    setIsShowContent(false);
                    e.preventDefault();
                    e.stopPropagation();
                  }}
                />
              </div>
              {util.line(data?.intro) ?? ""}
            </div>
          </div>
        )}
        {!isSimpleView && (
          <div
            style={{
              ...UiSearchSection_Style.academyAdd_w,
              color: style.common.t_color,
            }}
          >
            <ClassroomRoleText />
          </div>
        )}
        {!isSimpleView && !isShowContent && (
          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "flex-end",
            }}
          >
            <KeyboardArrowDown
              style={{
                color: style.common.t_color,
                fontSize: "22px",
                padding: 0,
                margin: 0,
              }}
              onClick={(e) => {
                setIsShowContent(true);
                e.preventDefault();
                e.stopPropagation();
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default ClassroomListSection;
