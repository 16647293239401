import React, { ReactNode } from "react";
import styled from "styled-components";

interface IHeaderProps {
  title: ReactNode;
  leftIcon?: ReactNode;
  rightIcon?: ReactNode;
  onLeftClick?: () => void;
  onRightClick?: () => void;
}
const Header = ({
  title,
  leftIcon,
  rightIcon,
  onLeftClick,
  onRightClick,
}: IHeaderProps) => {
  return (
    <Container>
      <LeftConatiner onClick={onLeftClick}>
        {leftIcon}
        {title}
      </LeftConatiner>
      <RightContainer onClick={onRightClick}>{rightIcon}</RightContainer>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 48px;
  color: #21272a;
  padding: 10px 20px;
  font-size: 24px;
  font-weight: 700;
  line-height: 29px;
  letter-spacing: 0em;
  position: sticky;
  top: 0;
  z-index: 3;
  background-color: #fbfcfe;
  /* background-color: #fbfcfe88;
  backdrop-filter: blur(5px); */
`;

const LeftConatiner = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const RightContainer = styled(LeftConatiner)`
  gap: 16px;
`;
export default Header;
