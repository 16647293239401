import { React, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";

import apiFn from "../../asset/apiClass";
import style from "../../asset/style";
import Message_Style from "../Message/Message_Style";
import { Frame } from "../page_index";
import Profile_class from "./Profile_class";
import Profile_column from "./Profile_column";
import Profile_lecture from "./Profile_lecture";
import Profile_teacher from "./Profile_teacher";

const Profile = ({
  scrollKey,
  setScrolllKey,
  userInfo,
  setFileObj,
  fileObj,
  setHiddenNav,
  hiddenNav,
  setHiddenUrl,
  isPremium,
}) => {
  let params = useParams();
  let navigate = useNavigate();

  const [isText, setIsText] = useState(false);

  var [academyData, setAcademyData] = useState([]);

  var [academyData_ori, setAcademyData_ori] = useState({});

  const [isTeacher, setIsTeacher] = useState(true);
  const [tap, setTap] = useState("academy");

  var [isLoad, setIsLoad] = useState(false);

  // 클래스룸 정보
  const apiPro_read_2 = async () => {
    try {
      let data = await apiFn.comApi({
        state: {
          table: "class",
          page: 1,
          amount: 9999,
          sort: "reg_date desc",
          where: {
            "HIQDB_class.id": `='${params["id"]}'`, // 클래스룸 ID
            // 'HIQDB_member_class.mid':`='${userInfo["id"]}'`,
          },
        },
      });

      setAcademyData_ori({
        ...data["list"][0],
      });
      setIsLoad(true);
    } catch (e) {}
  };

  // 클래스룸 정보 + 내 정보
  const apiPro_read = async () => {
    try {
      let data = await apiFn.comApi({
        state: {
          table: "member_class",
          page: 1,
          amount: 9999,
          sort: "reg_date desc",
          ref: [
            "HIQDB_class.name|HIQDB_class.id=HIQDB_member_class.cid",
            "HIQDB_class.intro|HIQDB_class.id=HIQDB_member_class.cid",
            "HIQDB_class.address|HIQDB_class.id=HIQDB_member_class.cid",
            "HIQDB_class.tel|HIQDB_class.id=HIQDB_member_class.cid",
            "HIQDB_class.email|HIQDB_class.id=HIQDB_member_class.cid",
            "HIQDB_class.owner|HIQDB_class.id=HIQDB_member_class.cid",
          ],
          where: {
            "HIQDB_class.id": `='${params["id"]}'`, // 클래스룸 ID
            "HIQDB_member_class.mid": `='${userInfo["id"]}'`,
          },
        },
      });

      setAcademyData({
        ...data["list"][0],
      });
    } catch (e) {}
  };

  const apiPro_read_student = async () => {
    try {
      console.log("ASDF?ASD?FAS?DFAS?DFA?SDF?");
      let data = await apiFn.comApi({
        state: {
          table: "class",
          page: 1,
          amount: 9999,
          sort: "reg_date",
          where: {
            "HIQDB_class.pid": `='${params["id"]}'`,
          },
        },
      });

      console.log("TEST API : ", data["list"]);

      let in_id = data["list"].reduce((a, v, i) => {
        // return v["mid"].toString()
        if (i == 0) {
          return (a = `'${v["id"]}'`);
        } else {
          return (a = a + `,'${v["id"]}'`);
        }
      }, "");

      console.log("in_id : ", in_id);

      let data2 = await apiFn.comApi({
        state: {
          table: "member_class",
          page: 1,
          amount: 9999,
          sort: "reg_date desc",
          // sort : sort ? `${sort} desc` : "reg_date desc",
          // ref : [
          //     'HIQDB_member_class.cid|HIQDB_class.id=HIQDB_member_class.cid',
          //     'HIQDB_member_class.mid|HIQDB_class.id=HIQDB_member_class.cid',
          // ],
          where: {
            // 'HIQDB_class.id':`='${params["id"]}'`,
            "HIQDB_member_class.cid": `in (${in_id})`,
            "HIQDB_member_class.type": `='0'`,
            // 'HIQDB_member_class.mid':`!='${userInfo["id"]}'`,
          },
        },
      });

      let in_id2 = data2["list"].reduce((a, v, i) => {
        // return v["mid"].toString()
        if (i == 0) {
          return (a = `'${v["mid"]}'`);
        } else {
          return (a = a + `,'${v["mid"]}'`);
        }
      }, "");

      let data3 = await apiFn.comApi({
        state: {
          table: "user_credential",
          page: 1,
          amount: 9999,
          sort: "name desc",
          ref: ["A.* |HIQDB_user A on A.index=HIQDB_user_credential.user_id and A.user_type = 1"],
          // sort : `${sort || sorting ? (sort || sorting) : "name"} desc`,
          where: {
            "HIQDB_user_credential.name": `in (${in_id2})`,
            // ...where_obj
          },
          // ref : [
          //     `HIQDB_member_class.type ,HIQDB_member_class.reg_date as member_date ,HIQDB_member_class.cid, HIQDB_member_class.status as s_status |HIQDB_member_class.mid=HIQDB_student.id and HIQDB_member_class.cid = '${params["id"]}' `,
          // ]
        },
      });

      console.log("data3 : ", data3);
    } catch (e) {
      console.log("ERROR : ", e);
    }
  };

  useEffect(() => {
    // console.log("parmas => ",params)
    if (params["who"] == "teacher") {
      setIsTeacher(true);
    } else {
      setIsTeacher(false);
    }
  }, [params]);

  useEffect(() => {
    apiPro_read_student();

    if (hiddenNav) {
      if (hiddenNav == "lecture&") {
        setTap("lecture");
      } else if (hiddenNav == "profile_teacher") {
        setTap("teacher");
      } else {
        setTap(hiddenNav);
      }
      setHiddenNav("");
    }
  }, []);

  useEffect(() => {
    if (tap == "academy") {
      apiPro_read();
      apiPro_read_2();
    }
  }, [tap]);

  return (
    <Frame
      navi={`/academy/${params["who"]}/academy`}
      title={academyData_ori["name"]}
      // isPadding={false}
      isSearch={false}
      userInfo={userInfo}
      // isTap={true}
      backEvent={() => {
        setHiddenNav("");
      }}
      isPremium={isPremium}
    >
      <div
        style={{
          width: "100vw",
          height: "100%",
          // backgroundColor:"red"
        }}
      >
        <div
          style={{
            display: "flex",
            // paddingTop : "10px",
            // padding : "10px 10px 0px 10px"
            padding: style.common.sub_padding,
          }}
        >
          <div
            style={{
              ...Message_Style.tap_text,
              marginRight: "25px",
              borderBottom: tap == "academy" ? "2px solid" : "none",
              borderBottomColor:
                tap == "academy"
                  ? isTeacher
                    ? style.common.t_color
                    : style.common.s_color
                  : "none",
              color:
                tap == "academy"
                  ? isTeacher
                    ? style.common.t_color
                    : style.common.s_color
                  : style.common.fontColor_2,
            }}
            onClick={() => {
              // navigate("/profile"+"/"+params["who"]+"/academy")
              setTap("academy");
            }}
          >
            내 클래스룸
          </div>
          <div
            style={{
              ...Message_Style.tap_text,
              marginRight: "25px",
              // borderBottom : tap == "column" ? "2px solid" : "none",
              // borderBottomColor : tap == "column" ?
              // (isTeacher ? style.common.t_color : style.common.s_color)
              // :
              // "none"
              // ,
              color:
                tap == "column"
                  ? isTeacher
                    ? style.common.t_color
                    : style.common.s_color
                  : style.common.fontColor_2,
            }}
            onClick={() => {
              // navigate("/profile"+"/"+params["who"]+"/column")
              setTap("column");
            }}
          >
            컬럼
          </div>
          <div
            style={{
              ...Message_Style.tap_text,
              marginRight: "25px",
              borderBottom: tap == "teacher" ? "2px solid" : "none",
              borderBottomColor:
                tap == "teacher"
                  ? isTeacher
                    ? style.common.t_color
                    : style.common.s_color
                  : "none",
              color:
                tap == "teacher"
                  ? isTeacher
                    ? style.common.t_color
                    : style.common.s_color
                  : style.common.fontColor_2,
            }}
            onClick={() => {
              // navigate("/profile"+"/"+params["who"]+"/teacher")
              setTap("teacher");
            }}
          >
            강사진
          </div>
          <div
            style={{
              ...Message_Style.tap_text,
              marginRight: "25px",
              borderBottom: tap == "lecture" ? "2px solid" : "none",
              borderBottomColor:
                tap == "lecture"
                  ? isTeacher
                    ? style.common.t_color
                    : style.common.s_color
                  : "none",
              color:
                tap == "lecture"
                  ? isTeacher
                    ? style.common.t_color
                    : style.common.s_color
                  : style.common.fontColor_2,
            }}
            onClick={() => {
              // navigate("/profile"+"/"+params["who"]+"/lecture")
              setTap("lecture");
            }}
          >
            강의
          </div>
          {/* <div style={{
                        ...Message_Style.tap_text,
                        marginRight : '25px',
                        borderBottom : tap == "student" ? "2px solid" : "none",
                        borderBottomColor : tap == "student" ?
                        (isTeacher ? style.common.t_color : style.common.s_color)
                        :
                        "none"
                        ,
                        color : 
                        tap == "student" ?
                        (isTeacher ? style.common.t_color : style.common.s_color)
                        :
                        style.common.fontColor_2

                    }}
                        onClick={()=>{
                            // navigate("/profile"+"/"+params["who"]+"/lecture")
                            setTap("student")
                        }}
                    >
                        학생
                    </div> */}
        </div>
        <div
          style={{
            marginTop: "10px",
            width: "100%",
            // height: "100%",
          }}
        >
          {isLoad &&
            (tap == "academy" ? (
              <Profile_class
                scrollKey={scrollKey}
                setScrolllKey={setScrolllKey}
                academyData={academyData}
                academyData_ori={academyData_ori}
                isTeacher={isTeacher}
                userInfo={userInfo}
                apiPro={apiPro_read}
                setTap={setTap}
                setFileObj={setFileObj}
                fileObj={fileObj}
              />
            ) : tap == "column" ? (
              <Profile_column
                scrollKey={scrollKey}
                setScrolllKey={setScrolllKey}
                academyData={academyData}
                isTeacher={isTeacher}
                setHiddenNav={setHiddenNav}
                setFileObj={setFileObj}
              />
            ) : tap == "teacher" ? (
              <Profile_teacher
                scrollKey={scrollKey}
                setScrolllKey={setScrolllKey}
                userInfo={userInfo}
                academyData={academyData}
                isTeacher={isTeacher}
                setHiddenNav={setHiddenNav}
                hiddenNav={hiddenNav}
              />
            ) : (
              tap == "lecture" && (
                <Profile_lecture
                  scrollKey={scrollKey}
                  setHiddenUrl={setHiddenUrl}
                  setHiddenNav={setHiddenNav}
                  setScrolllKey={setScrolllKey}
                  isTeacher={isTeacher}
                  userInfo={userInfo}
                  academyData={academyData}
                  hiddenNav={hiddenNav}
                />
              )
            ))}
        </div>
      </div>
    </Frame>
  );
};

export default Profile;
