import HighlightOffTwoToneIcon from "@mui/icons-material/HighlightOffTwoTone";
import { useClassDetailInfo } from "api/classDetail";
import { ClassRegistRequset } from "api/classRegist";
import constants from "asset/constants";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useParams } from "react-router";
import styled from "styled-components";
import ThumbnailSelect from "./ThumbnailSelect";
import ThumbnailSelectTemplates from "./ThumbnailSelectTemplates";
import VideoTimeArea from "./VideoTimeArea";
interface IClassVideoAreaProps {
  onCancel: () => void;
}
const ClassVideoArea = ({ onCancel }: IClassVideoAreaProps) => {
  const {
    watch,
    setValue,
    control,
    unregister,
    trigger,
    formState: { errors },
  } = useFormContext<ClassRegistRequset>();
  const videoFile = watch("videoFile");
  const videoFiles = watch("videoFiles");
  const params = useParams();
  const { boardId } = params;
  const [videoUrl, setVideoUrl] = React.useState<string | null>(null);
  const { data } = useClassDetailInfo({ boardId: params.boardId });

  useEffect(() => {
    if (videoFiles && typeof videoFiles === "object" && videoFiles?.[0]) {
      setValue("videoFile", videoFiles[0]);
    } else {
      setValue("videoFile", null);
    }
  }, [videoFiles]);

  useEffect(() => {
    if (videoFile && typeof videoFile === "object") {
      setVideoUrl(URL.createObjectURL(videoFile));
    } else if (boardId) {
      setVideoUrl(`${constants.video_bucket}/board/${boardId}.mp4`);
    } else {
      setVideoUrl(null);
    }
  }, [videoFile, params.boardId]);

  const handleCancel = useCallback(() => {
    setValue("videoFiles", null);
    setValue("loadVideoUrl", null);
    onCancel();
  }, [setValue, unregister]);

  const videoRef = useRef<HTMLVideoElement>(null);
  const [durationTime, setDurationTime] = useState<number>(0);

  const start = watch("video_start");
  const end = watch("video_end");

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.currentTime = start || 0;
    }
  }, [start, videoRef.current]);

  useEffect(
    () => () => {
      unregister(["videoFile", "loadVideoUrl"]);
    },
    []
  );

  const thumbnailId = watch("thumbnail_id");
  const thumbnailList = useMemo(
    () => data?.getBoard?.boards[0].thumbnails?.filter((item) => !!item.timestamp) ?? [],
    [data]
  );
  const thumbnailUrl = useMemo(() => {
    const allThumbnailList = data?.getBoard?.boards[0].thumbnails ?? [];
    const findItem =
      allThumbnailList?.find((item) => item.id === thumbnailId) ?? allThumbnailList?.[0];

    return findItem?.path;
  }, [data, thumbnailList, thumbnailId]);

  useEffect(() => {
    if (videoFile) {
      setValue("thumbnail_id", null);
    }
  }, [thumbnailUrl]);
  return (
    <>
      <div>
        <CancelButton onClick={handleCancel} type="button">
          취소 <HighlightOffTwoToneIcon />
        </CancelButton>
        <StyledVideo
          controls
          ref={videoRef}
          playsInline
          poster={thumbnailUrl && `${constants.apiUrl}/${thumbnailUrl}`}
          onLoadedMetadata={(e) => {
            setValue("video_end", e.currentTarget.duration);
            setDurationTime(e.currentTarget.duration);
            setValue("loadVideoUrl", videoUrl);
            trigger("loadVideoUrl");
          }}
          preload="metadata"
          onError={() => setVideoUrl(`${constants.apiUrl}/board/${boardId}.mp4`)}
        >
          <source src={videoUrl} type="video/mp4" />
        </StyledVideo>
        <VideoTimeArea durationTime={durationTime} />
        <ThumbnailSelectTemplates videoUrl={videoUrl} />
      </div>
      {boardId && !videoFile && thumbnailList.length ? <ThumbnailSelect /> : null}
    </>
  );
};

export const StyledVideo = styled.video`
  width: 100%;
  border-radius: 10px;
`;

const CancelButton = styled.button`
  outline: none;
  background-color: transparent;
  border: 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  margin: 16px 0 8px;
  color: #242424;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  gap: 8px;
  svg {
    color: #807e8a;
    font-size: 20px;
  }
`;
export default ClassVideoArea;
