import React, { CSSProperties } from "react";
import style from "../../asset/style";

interface UiTextBtnProps {
  isTeacher?: boolean;
  text: string;
  isUnderline?: boolean;
  fnClick: (e: any) => void;
  btnStyle?: CSSProperties;
  isFile?: boolean;
  isMulti?: boolean;
  accept?: string;
}
const UiTextBtn = ({
  isTeacher,
  text,
  isUnderline = true,
  fnClick,
  btnStyle,
  isFile,
  isMulti = false,
  accept = "*",
}: UiTextBtnProps) => {
  return (
    <>
      {isFile ? (
        <>
          <input
            // isMulti={isMulti}
            multiple={isMulti}
            id="textbtn_file"
            type={"file"}
            onChange={fnClick}
            style={{
              display: "none",
            }}
            accept={accept}
          />
          {/* <input
                        id='local_file_'
                        type='file'
                        style={{display:"none"}}
                        accept={whitelist}
                        // accept=
                        onChange={(e)=>fileOnChange(e)}
                    />
                    <label
                        htmlFor="local_file_"
                        style={UiFileBoxStyle.input_wrapper}
                    >
                        로컬 파일
                    </label> */}
          <label
            htmlFor="textbtn_file"
            style={{
              color: isTeacher ? style.common.t_color : style.common.s_color,
              textDecoration: isUnderline && "underline",
              fontWeight: "normal",
              ...btnStyle,
            }}
          >
            {text}
          </label>
        </>
      ) : (
        <div
          style={{
            color: isTeacher ? style.common.t_color : style.common.s_color,
            textDecoration: isUnderline && "underline",
            fontWeight: "normal",
            ...btnStyle,
          }}
          onClick={fnClick}
        >
          {text}
        </div>
      )}
    </>
  );
};

export default UiTextBtn;
