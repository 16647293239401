import { React, useEffect, useState } from 'react'
import { useNavigate } from 'react-router'
import style from '../../asset/style'
import UiBtn from '../UiBtn/UiBtn'
import UiHiqDescBox from '../UiHiqDescBox/UiHiqDescBox'
import { UiInput_title } from '../UiInput/UiInput'
import UiConfrim_Style from './UiConfrim_Style'

const UiConfirm = ({
    isTeacher,
    navi = -1,
    alert = "삭제되었습니다.",
    fnClick,
    isNavi = true,
}) => {
    let navigate = useNavigate();

    var [confirm_st,setConfirm_st] = useState('');
    var [confirm,setConfirm] = useState('');


    const randomString = () => {
        const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXTZabcdefghiklmnopqrstuvwxyz'
        const stringLength = 4
        let randomstring = ''
        for (let i = 0; i < stringLength; i++) {
          const rnum = Math.floor(Math.random() * chars.length)
          randomstring += chars.substring(rnum, rnum + 1)
        }
        return randomstring
    }


    useEffect(()=>{
        setConfirm_st(randomString());
    },[])

    return (
        <div style={UiConfrim_Style.confrim_c}>
            <UiHiqDescBox 
                isTeacher={isTeacher}
                desc1 = "최종 확인을 위해"
                desc2 = "생성된 문자를 입력해주세요."
                // desc3 = "생성된 문자를 입력 후 확인을 누르면"
                desc3 = {`${confirm_st} 를 입력 후 확인을 누르면`}
                desc4 = "진행이 완료됩니다."
                desc5="(대소문자를 정확히 입력해주세요.)"
            
            />
            <UiInput_title
                inputClass={isTeacher ? "input_t" : "input_c"}
                title="입력문자 확인"
                type="basic"
                fnChange={(e)=>{
                    setConfirm(e.target.value)
                }}
            />
            <div style={UiConfrim_Style.confrim_btn_w}>
                <UiBtn
                    btnStyle={{
                        color : "white",
                        borderColor : ( isTeacher ? style.common.t_color : style.common.s_color )
                        ,
                        backgroundColor : ( isTeacher ? style.common.t_color : style.common.s_color )

                    }}
                    title="확인"
                    fnClick={()=>{
                        if(confirm_st == confirm){
                            window.alert(alert)
                            fnClick();
                            if(isNavi){
                                navigate(navi)
                            }
                        }
                        else {
                            window.alert("입력 문자를 다시 확인해주세요.")
                        }
                    }}
                />
            </div>
        </div>
    )
}

export default UiConfirm