import {
  useApolloClient,
  useMutation as useGqlMutation,
  useQuery as useGqlQuery,
} from "@apollo/client";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import API from "api";
import apiFn from "asset/apiClass";
import { getUser } from "asset/storage";
import { useMemo } from "react";
import { useDispatch } from "react-redux";
import { WhoType } from "types/enum";
import { GetBookQuery } from "types/graphql/graphql";
import { axiosInstance } from "./common";
import { uploadToStorage } from "./fileUpload";
import { BOOK_INFO, BOOK_UPDATE, UPDATE_BOOK_FOLDER } from "./graphql/studyBook";

export const useStudyBookInfo = (bookId: string | number) => {
  return useGqlQuery<GetBookQuery>(BOOK_INFO, {
    variables: {
      bookId,
    },
    skip: !bookId,
  });
};

export const useChangeStudyBookType = () => {
  const userInfo = getUser();
  const apolloClient = useApolloClient();

  return useMutation({
    mutationFn: async ({ bookType, bookId }: { bookType: number; bookId: string | number }) => {
      await axiosInstance.put(`/book/${bookId}/type`, {
        userId: userInfo.id,
        bookType,
      });
    },
    async onSuccess() {
      apolloClient.refetchQueries({
        include: [BOOK_INFO],
      });
    },
  });
};

export type StudyBookFolderListItem = {
  id: number;
  ftype: number;
  open: number;
  usee: number;
  pid: number;
  depth: number;
  mid_index: number;
  tcnt: number;
  tseq: number;
  name: string;
  mid: string;
  content: string;
};

export const useStudyBookFolderList = () => {
  const userInfo = getUser();
  return useQuery<{ list: StudyBookFolderListItem[] }>({
    queryKey: ["studyBookFolderList"],
    queryFn: async () => {
      return await apiFn.comApi({
        state: {
          table: "folder",
          page: 1,
          amount: 9999,
          sort: "name desc",
          where: {
            "HIQDB_folder.mid": `='${userInfo["id"]}'`,
            "HIQDB_folder.ftype": `='0'`,
          },
        },
      });
    },
  });
};

export const useStudyBookFolderOne = (folderId: string) => {
  return useQuery<{ list: StudyBookFolderListItem[] }>({
    queryKey: ["studyBookFolderOne"],
    queryFn: async () => {
      return await apiFn.comApi({
        state: {
          table: "folder",
          page: 1,
          amount: 9999,
          sort: "name desc",
          where: {
            "HIQDB_folder.id": `= '${folderId}'`,
          },
        },
      });
    },
    enabled: !!folderId,
  });
};

export const useStudyBookFolderRegist = () => {
  const userInfo = getUser();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (folderName: string) => {
      await apiFn.comApi({
        state: {
          table: "folder",
          ins: [
            {
              name: folderName,
              ftype: 0,
              mid: userInfo["id"],
              open: 1,
              usee: 1,
            },
          ],
        },
      });
    },
    async onSuccess() {
      queryClient.invalidateQueries(["studyBookFolderList"]);
    },
  });
};

type StudyBookListInFolderResponse = {
  folderData: {
    name: string;
    id: number;
    HIQDB_book_links: StudyBookListInFolderItem[];
  };
  success: boolean;
};

export type StudyBookListInFolderItem = {
  id: number;
  book_type: number;
  status: number;
  type: number;
  img: string;
  reg_id: string;
  mid: string;
  name: string;
};

export const useStudyBookListInFolder = (folderId: string) => {
  const userInfo = getUser();
  return useQuery<StudyBookListInFolderResponse>({
    queryKey: ["useStudyBookListInFolder", folderId],
    queryFn: async () => {
      return await API.getFolderBooks(folderId, userInfo.id);
    },
    enabled: !!folderId && folderId !== "0",
  });
};

type StudyBookListInCurrentClassResponse = {
  success: boolean;
  bookList: StudyBookListInCurrentClassItem[];
};

type StudyBookListInCurrentClassItem = {
  id: number;
  book_type: number;
  status: number;
  type: number;
  name: string;
};

export const useStudyBookListInCurrentClass = (folderId: string) => {
  const userInfo = getUser();
  return useQuery<StudyBookListInCurrentClassResponse>({
    queryKey: ["useStudyBookListInCurrentClass"],
    queryFn: async () => {
      return await API.getCurrentClassBooks(userInfo.id, "participation=true");
    },
    enabled: !!folderId && folderId === "0",
  });
};

export const useStudyBookFolderDelete = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (folderIdList: (string | number)[]) => {
      await apiFn.comApi({
        state: {
          delFid: folderIdList,
          table: "folder",
          del: folderIdList.map((item) => ({ id: item })),
        },
      });
    },
    async onSuccess() {
      queryClient.invalidateQueries(["studyBookFolderList"]);
    },
  });
};

export const useStudyBookUpdate = () => {
  return useGqlMutation(BOOK_UPDATE);
};

export const useStudyBookFolderNameUpdate = () => {
  const queryClient = useQueryClient();
  return useGqlMutation(UPDATE_BOOK_FOLDER, {
    onCompleted(data, clientOptions) {
      queryClient.invalidateQueries(["studyBookFolderList"]);
    },
  });
};

type StudyBookListResponse = {
  success: boolean;
  bookList: StudyBookListItem[];
};

export type StudyBookListItem = {
  id: number;
  book_type: number;
  status: number;
  type: number;
  img: null;
  mid: string;
  reg_id: string;
  name: string;
};

export const useStudyBookList = (params = { isInFolder: false }) => {
  const userInfo = getUser();
  return useQuery<StudyBookListResponse>({
    queryKey: [`/user/${userInfo.id}/books`, params],
  });
};

export const useStudyBookDelete = () => {
  const userInfo = getUser();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (bookId: string | number) => {
      return await apiFn.deleteBook({
        state: {
          boid: bookId,
          orderMid: userInfo["id"],
        },
      });
    },
    onSuccess() {
      queryClient.invalidateQueries([`/user/${userInfo.id}/books`, { isInFolder: false }]);
      queryClient.invalidateQueries([`useStudyBookListInFolder`]);
      queryClient.invalidateQueries([`useStudyBookListInCurrentClass`]);
    },
  });
};

type StudyBookMoveRequest = {
  bidList: (string | number)[];
  fid: string | number;
};
export const useStudyBookMove = () => {
  const userInfo = getUser();
  const isTeacher = useMemo(() => userInfo?.type === WhoType.teacher, [userInfo]);
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (params: StudyBookMoveRequest) => {
      if (isTeacher) {
        const set = params.bidList.map((bid) => ({
          value: { fid: params.fid },
          key: `bid:${bid}' and mid='${userInfo["id"]}`,
        }));
        return await apiFn.comApi({
          state: {
            table: "book_link",
            set,
          },
        });
      } else {
        const set = params.bidList.map((bid) => ({
          bid,
          mid: userInfo["id"],
          fid: params.fid,
        }));
        return await apiFn.studentBookFolderSet({
          state: {
            set,
          },
        });
      }
    },
    onSuccess() {
      queryClient.invalidateQueries([`/user/${userInfo.id}/books`, { isInFolder: false }]);
      queryClient.invalidateQueries([`useStudyBookListInFolder`]);
      queryClient.invalidateQueries([`useStudyBookListInCurrentClass`]);
    },
  });
};

export const useStudyBookRegist = () => {
  const userInfo = getUser();
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  return useMutation({
    mutationFn: async ({ bookName, file }: { bookName: string; file: File }) => {
      const response = await apiFn.comApi({
        state: {
          table: "book",
          ins: [
            {
              name: bookName,
              reg_date: 0,
              reg_id: userInfo["id"],
            },
          ],
        },
      });
      if (file) {
        uploadToStorage({
          fileLocation: `bookCover/${response.id}.jpg`,
          file,
        });
      }
    },
    onSuccess: async () => {
      queryClient.invalidateQueries([`/user/${userInfo.id}/books`, { isInFolder: false }]);
    },
  });
};
