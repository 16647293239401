import { React , useState , useEffect } from 'react'
import { useNavigate, useParams } from 'react-router'
import style from '../../asset/style'
import Basic from '../../common/frame/Basic'
import Message_Style from '../Message/Message_Style'
import { Frame } from '../page_index'
import face_student from '../../asset/Img/face_student.png'
import face_teacher from '../../asset/Img/defaultpic_teacher.png'
import { ArrowForwardIos, Star } from '@material-ui/icons'
import MyInfo_Style from './MyInfo_Style'
import Academy_book_Style from '../Academy/Academy_book_Style'
import UiTextBtn from '../../uiComponents/UiTextBtn/UiTextBtn'
import UiSorting from '../../uiComponents/UiSorting/UiSorting'
import UiReview from '../../uiComponents/UiReview/UiReview'
import apiFn from '../../asset/apiClass'

const MyInfo_review = ({
    userInfo
}) => {    
    let params = useParams()
    let navigate = useNavigate();

    const [isTeacher ,setIsTeacher] = useState(true);
    const [tap,setTap] = useState("home")

    var [data,setData] = useState({
        list : [],
    })

    var [sorting,setSorting] = useState("reg_date desc")

    const apiPro_read = async () => {
        try {
            let state = {}
            if(params["who"].includes("myInfo")){
                state["tid"] = userInfo["id"]
            }
            else{
                if(params["who"] == "teacher"){
                    state["tid"] = userInfo["id"]
                }
                else if(params["who"] == "student"){
                    state["sid"] = userInfo["id"]
                }
                else {
                    state["qid"] = params["who"]
                }
            }
            // if((!params["who"] == "teacher" || params["who"] == "student")){
            //     state["qid"] = params["who"]
            // }
            // else {

            // }
            let data = await apiFn.fnStarList({
                state : {
                    cat : "hiq",
                    cmd : "starList",
                    page : 1,
                    amount : 9999,
                    sort : sorting,
                    ...state
                    // tid : userInfo["id"]
                }
            })

            console.log("data => ",data)
            console.log("data => ",data)
            console.log("data => ",data)
            console.log("data => ",data)

            if(params["who"].includes("myInfo")){
                let list = []
                data["list"].map((v, i)=>{
                    if(v["sid"] == params["who"].split("&")[1]){
                        list.push(v)
                    }
                })
                setData({
                    ...data,
                    list : list
                })
            }
            else {
                setData({
                    ...data
                })
            }
        }
        catch(e){
        }
    }

    useEffect(()=>{
        // console.log("parmas => ",params)
        if(params["who"] == "teacher"){
            setIsTeacher(true)
        }
        else {
            setIsTeacher(false)
        }

    },[params])

    useEffect(()=>{
        apiPro_read();
    },[sorting])



    return (
        <Frame
            title={params["who"].includes("myInfo") ? "나외의 리뷰 목록"  : "리뷰 목록"}
            userInfo={userInfo}
            // isPadding={false}
            isSearch={false}
            // isTap={true}
        >
            <div style={{
                width:"100%",
            }}>
                <div style={{
                    padding : "5px 12px"
                }}>
                    {
                    !params["who"].includes("myInfo")  &&
                    <div style={{
                        fontSize : "20px",
                        fontWeight : 600,
                        display : "flex",
                        alignItems:"center",
                        padding:"10px 0px",
                    }}>
                        <Star style={{
                            fontSize : "26px",
                            marginRight : "5px",
                            color: "#f5b21a",
                        }}/>
                            {/* 4.0 */}
                            {data["avg"]}
                    </div>
                    }
                    <UiSorting 
                        title={`리뷰 ${data["list"].length}개`}
                        data={[
                            { name : "날짜순" , value : "reg_date desc"},
                            { name : "평점순" , value : "rating desc"},
                            { name : "출처순" , value : "nick asc"},
                        ]}
                        fnClick={(v)=>{
                            setSorting(v)
                        }}
                        isTeacher={isTeacher}
                    />
                </div>
                <div style={{
                    padding : "10px 12px"
                }}>
                    {
                    data["list"].map((v,i)=>
                        <UiReview 
                            data={v} 
                            fnClick={(d)=>{
                                // console.log(d);
                                if(d["tid"] == userInfo["id"]){
                                    navigate("/talk/"+params["who"]+"/"+"0"+"/"+d["qid"]+"/"+userInfo["id"]+"/"+d["tid"])
                                }
                                // navigate("/talk/"+params["who"]+"/"+d["id"]+"/"+d["qid"]+"/"+d["sid"]+"/"+d["tid"])
                            }}
                        />
                    )
                    }
                    {/* <UiReview />
                    <UiReview />
                    <UiReview />
                    <UiReview />
                    <UiReview /> */}
                    
                </div>
           </div>
        </Frame>
    )
}

export default MyInfo_review