import { React , useState , useEffect } from 'react'
import { useNavigate, useParams } from 'react-router'
import style from '../../asset/style'
import Basic from '../../common/frame/Basic'
import Message_Style from '../Message/Message_Style'
import { Frame } from '../page_index'
import face_student from '../../asset/Img/face_student.png'
import face_teacher from '../../asset/Img/defaultpic_teacher.png'
import { ArrowForwardIos, Star } from '@material-ui/icons'
import Academy_book_Style from '../Academy/Academy_book_Style'
import UiTextBtn from '../../uiComponents/UiTextBtn/UiTextBtn'
import UiSorting from '../../uiComponents/UiSorting/UiSorting'
import UiReview from '../../uiComponents/UiReview/UiReview'
import apiFn from '../../asset/apiClass'
import UiPeople from '../../uiComponents/UiPeople/UiPeople'
import UiPeople_Style from '../../uiComponents/UiPeople/UiPeople_Style'
import constants from '../../asset/constants'
import util from '../../asset/util'

const Message_teacher = ({
    userInfo
}) => {
    let params = useParams()
    let navigate = useNavigate();

    var [friendsList,setFriendsList] = useState([])

    const apiPro_read = async (d) => {
        try {
            let data = await apiFn.friendsList({
                state : {
                    page : 1,
                    amount : 9999,
                    sort : "reg_date desc",
                    sid : userInfo["id"],
                    qid : params["qid"],
                    bid : params["bid"]
                }
            })
            console.log("friendsList => ",data);

            setFriendsList([
                ...data["list"]
            ])
        }
        catch(e){
            // console.log("@@ ",e)
        }
    }


    const apiPro_chk_subs = async (id) => {
        try {
            let isChk = await apiFn.comApi({
                state : {
                    table : "book_link",
                    page : 1,
                    amount : 9999,
                    sort : "bid desc",
                    where : {
                        'HIQDB_book_link.bid' : `= '${params["bid"]}'`,
                        'HIQDB_book_link.mid' : `= '${id}'`,
                    }

                }
            })

            if(isChk["list"].length > 0){
                if(isChk["list"][0]["type"] == 8 || isChk["list"][0]["type"] == 4){
                    return true
                }
                else {
                    return false
                }
            }
            else {
                return false
            }
        }
        catch(e){
        }
    }



    const apiPro_addQna = async (id) => {
        try {
            let isChk = await apiPro_chk_subs(id);


            // req {
            //     original : (원 질문의 qid)
            //     sid : 질문 학생 ID
            //     (tid : 질문 받는 선생님 ID, 필수 아님. 원 질문 선생님과 다른 선생님이 답변 요청받는 경우)
            //     }
            let isOk = await apiFn.onlineQnaSet({
                state : {
                    sid : userInfo["id"],
                    original : params["qid"],
                    isSubs : isChk ? "Y" : null,
                    // tid : params["tid"],
                    tid : id,
                    bid : params["bid"],
                }
            })

            navigate("/message/"+params["who"]+"/home")
            navigate("/talk/"+params["who"]+"/"+"0"+"/"+isOk["qid"]+"/"+userInfo["id"]+"/"+params["tid"])


        }
        catch(e){
            // console.log("### ",e);
        }
    }


    useEffect(()=>{
        apiPro_read();
    },[])

    return (
        <Frame
            title="추가 질문 대상 선생님"
            userInfo={userInfo}
            // isPadding={false}
            isSearch={false}
            // isTap={true}
        >
            <div style={{
                width:"100%",
            }}>
                {
                friendsList.map((v,i)=>
                <div style={{
                    padding : "10px 12px"
                }}
                    onClick={()=>{
                        apiPro_addQna(v["id"]);
                    }}
                >
                    <div style={{
                        display:"flex",
                        flexDirection : "column",
                        alignItems : "center",
                        flex:1,
                    }}>
                        <div style={{
                            ...UiPeople_Style.section_c,
                            width:"100%"
                        }}>
                            <div style={{
                                // width : '20%',
                                marginRight : "10px",
                            }}
                                onClick={()=>{
                                    // navigate(`/myinfo/${params["who"]}/${v["id"]}`)
                                }}
                            >
                                <div style={UiPeople_Style.section_i_w}>
                                    <img
                                        style={UiPeople_Style.section_i}
                                        onError={(e)=>{
                                            // setIsLoad(false)
                                            e.currentTarget.src = (!v["stype"] && v["stype"] != 0 ) ? face_teacher : face_student
                                        }}
                                        src={
                                            v ?
                                            v["img"]  ?
                                            (
                                            constants.s3_url
                                            +
                                            // '/public'
                                            // +
                                            `${(!v["stype"] && v["stype"] != 0 ) ? "/teacher/" : "/student/"}${v["img"]}`
                                            +
                                            '.jpg?'
                                            )
                                            :
                                            (
                                            constants.apiUrl
                                            +
                                            // '/public'
                                            // +
                                            `${(!v["stype"] && v["stype"] != 0 ) ? "/teacher/" : "/student/"}${v["id"].normalize('NFD')}`
                                            +
                                            '.jpg?'
                                            +
                                            util.getRandomInt(1000,9999)
                                            )
                                            :
                                            ((!v["stype"] && v["stype"] != 0 ) ? face_teacher : face_student )
                                        }
                                    />
                                </div>
                            </div>
                            <div style={UiPeople_Style.section_r_c}
                                onClick={()=>{
                                    // fnClick(v);
                                }}
                            >
                                <div style={UiPeople_Style.section_r_t_w}>
                                    <div style={UiPeople_Style.section_r_t_t_w}>
                                        <div>
                                            {`${v["name"]} 선생님`}
                                        </div>
                                    </div>
                                </div>
                                <div style={UiPeople_Style.section_r_m_w}>
                                    <div style={UiPeople_Style.section_r_m_l_w}>
                                        <div>
                                            {
                                            (v["classList"] && v["classList"].length > 0) &&
                                                (
                                                    v["classList"].reduce((a,v,i)=>{
                                                        if(i == 0){
                                                            a = v["name"]
                                                        }
                                                        else if(i < 2){
                                                            a = a + " , " + v["name"]
                                                        }
                                                        // // console.log("###" ,a);
                                                        return a
                                                    },"")
                                                    +
                                                ( v["classList"].length > 2 ? "외 " +(v["classList"].length - 2) +"개 강의"  : "")
                                            )
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div style={UiPeople_Style.section_r_b_w}>
                                    {`총 질문수 ${v["qtotal"]}개`}
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                )}
           </div>
        </Frame>
    )
}

export default Message_teacher
