import styled from "styled-components";

export const Container = styled.div`
  background: rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(20px);
  position: absolute;
  top: 50%;
  left: 50%;
  padding: 16px;
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.3);
  box-shadow: 0 20px 40px 0 rgba(0, 0, 0, 15%);
  gap: 4px;
  width: 90%;
  display: flex;
  flex-direction: column;
  max-height: 60vh;

  @media (min-width: 720px) {
    max-width: 600px !important;
  }
`;
export const ModalHeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
  color: #fff;
`;
export const HeaderTextSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  flex: 1;
`;
export const CloseButton = styled.button`
  background: #434343;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 99%;
  padding: 4px;
`;
export const ImageWrapper = styled.div`
  flex: 1;
  background: #fff;
  border-radius: 10px;
  overflow: auto;
`;
export const ImageEl = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;
export const QuestionEditButton = styled.button`
  background: rgba(43, 240, 150, 0.1);
  width: 100%;
  color: #2bf096;
  font-size: 16px;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding: 12px 16px;
`;
export const AnswerBox = styled.div`
  height: 100%;
`;
