import { useMessageMarkFolderDelete } from "api/messageMark";
import React, { useCallback, useContext } from "react";
import styled, { css } from "styled-components";
import { MessageMarkContext } from "../context/MessageMarkContextProvider";

const DeleteButton = () => {
  const { checkedFolderList, isDeleteMode } = useContext(MessageMarkContext);
  const { mutateAsync } = useMessageMarkFolderDelete();
  const handleDelete = useCallback(() => {
    if (!isDeleteMode) {
      return;
    }
    if (
      confirm(
        "선택하신 노트를 삭제하시겠어요? \n(노트가 삭제되면 내부에 있는 오답노트 자료도 같이 삭제됩니다.)"
      )
    ) {
      mutateAsync(checkedFolderList.map((item) => item.id));
    }
  }, [checkedFolderList]);
  return (
    <BottomButton isDeleteMode={isDeleteMode} onClick={handleDelete}>
      삭제하기
    </BottomButton>
  );
};

const BottomButton = styled.button<{ isDeleteMode: boolean }>`
  outline: none;
  overflow: hidden;
  border: 0;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  background-color: #252525;
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: center;
  align-items: center;
  justify-content: center;
  position: fixed;
  bottom: 0;
  width: 100vw;
  display: block;
  transition: all 0.3s;
  /* opacity: 0; */
  height: 0;
  ${({ isDeleteMode }) =>
    isDeleteMode &&
    css`
      height: 55px;
      /* opacity: 1; */
    `}
`;

export default DeleteButton;
