import { gql } from "@apollo/client";

export const USER_BY_LECTURE = gql`
  query GetUserByLecture($userId: ID!, $lectureId: [String], $academyId: ID) {
    getUserByLecture(userId: $userId, lectureId: $lectureId, academyId: $academyId) {
      user {
        HIQDB_user_credentials {
          HIQDB_member_classes {
            cid_HIQDB_class {
              HIQDB_member_classes {
                mid_HIQDB_user_credential {
                  user {
                    name
                    id
                    user_type
                    nick
                    intro
                    tel
                  }
                }
                type
                status
              }
              name
            }
          }
        }
      }
      success
      error {
        code
        message
      }
    }
  }
`;

export const TEACHER_FILTERED_BY_TICKET = gql`
  query GetTeacherFilteredByTicket(
    $studentId: ID!
    $ticketType: String
    $academyId: ID
    $lectureId: ID
  ) {
    getTeacherFilteredByTicket(
      studentId: $studentId
      ticketType: $ticketType
      academyId: $academyId
      lectureId: $lectureId
    ) {
      success
      teachers {
        id
        name
        nick
        isExhausted
      }
      error {
        code
        message
      }
    }
  }
`;
