import { fontWeight } from '@mui/system'
import { React , useState , useEffect, useRef } from 'react'
import { useNavigate, useParams } from 'react-router'
import { UiInput } from '../../uiComponents/UiInput/UiInput'
import aquery from '../../asset/Img/aquery.png'
import photopic_book from '../../asset/Img/photopic_book.png'
import { Switch } from '@mui/material'
import util from '../../asset/util'
import constants from '../../asset/constants'
import apiFn from '../../asset/apiClass'
import store from '../../asset/store'

var fontStyle = {
    fontFamily: 'Noto Sans',
    fontStyle: "normal",
}

const Modal_b = ({
    fileObj,
    setFileObj,
    setIsModal,
    isModal,
    userInfo,
    title,
    placeholder
}) => {
    let params = useParams();
    let navigate = useNavigate();

    var [img,setImg] = useState("")
    var [bookName, setBookName] = useState("");
    var [bookPw, setBookPw] = useState();

    var [isApprove, setIsApprove] = useState(true);

    const apiPro_regi = async () => {
        try {
            let obj = {}
            if(isApprove)obj["book_pw"]=bookPw
            let isOk = await apiFn.comApi({
                state : {
                    table : "book",
                    // id : userInfo["id"],
                    ins: [{
                        name : bookName,
                        reg_date : 0,
                        reg_id : userInfo["id"],
                        ...obj

                    }],
                }
            })

            if(fileObj["base64"]){
                // await store.putBucket(fileObj["base64"],`/bookCover/${isOk["id"]}.jpg`, false, false)
                await apiFn.uploadFile(`bookCover/${isOk["id"]}.jpg`, util.dataURLtoFile(fileObj["base64"], `${isOk["id"]}.jpg`))
            }

            window.alert(constants.confirm.cf_23)

            setFileObj(fileObj = {})
            setIsModal(false)
        }
        catch(e){
            console.log("@@@? " ,e)
        }
    }


    const apiPro_img = async (e) => {
        try {
            // setImg(img)
            setFileObj({
                img : img,
                bookName : bookName,
                file : e
            })
            navigate("/crop/teacher/set")
        }
        catch(e){
            console.log("apiPro_img Error= >",e)
        }
    }

    useEffect(()=>{
        // setFileObj({})
        // // console.log("@@ ",fileObj);
        if(fileObj["base64"]){
            setImg(fileObj["base64"])
            setBookName(fileObj["bookName"])
        }
    },[])



    return (
        <div style={{
            position:"fixed",
            top:0,
            zIndex:130002,
            backgroundColor:"rgba(150, 150, 150,0.5)",
            // backgroundColor:"red",
            width:"100%",
            height:"100vh",
            display:"flex",
            justifyContent:"center",
            alignItems:"center"
        }}
            onClick={()=>{
                // setIsModal(false)
            }}
        >
            <div style={{
                // width : "75%",
                width:"300px",
                // minHeight:"55%",
                // maxHeight:"60%",
                backgroundColor:"white",
                padding : "35px 20px 40px 20px",
                borderRadius : 10,
                border : "1px solid rgb(200, 200, 200)",
                display:"flex",
                flexDirection:"column",
                justifyContent:"space-between",
                filter: "drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.37))",
            }}
                onClick={(e)=>{
                    // e.stopPropagation();
                    // e.preventDefault();
                }}
            >
                <div style={{
                    fontWeight:500,
                    display:"flex",
                    justifyContent:"center",
                    fontSize:"18px",
                    fontWeight:700
                }}>
                    {title}
                </div>
                <div style={{
                    display:"flex",
                    flexDirection:"column",
                    alignItems:"center",
                    padding:"15px 0px",
                    fontSize:"14px",
                    fontWeight:400,
                    color : "#3D3A39",
                }}>
                    <img
                        style={{
                            width:"65px",
                            height:"65px",
                        }}
                        src={aquery}
                    />
                    <div style={{
                        marginTop:"15px",
                    }}>
                        교재를 생성하여 선생님의 수업에 공유해보세요!
                    </div>
                    <div>
                        교재별 학생들의 질문을 받고
                    </div>
                    <div>
                        선생님의 모범 답안을 공유할 수 있습니다.
                    </div>
                </div>
                <div style={{
                    display:"flex",
                    fontSize:"14px",
                    fontWeight:700,
                    justifyContent:"center",
                    padding:"10px 0px 25px 0px"
                }}>
                    <div style={{
                        display:"flex",
                        flexDirection:"column",
                        alignItems:"center",
                        width : "78px",
                        height:"96px",
                        marginRight:"10px",
                        borderRadius:"10px"
                    }}>
                        <input
                            id="lfb"
                            accept='image/*'
                            type="file"
                            style={{ display : "none" }}
                            onChange={(e)=>{

                                util.fileOnchange(e,(event,r)=>{
                                    // setImg(r)
                                    // // console.log("ASDFASDF ? ? ? ? ? ",img)
                                    img = r
                                    apiPro_img(e);
                                })
                                // e.stopPropagation();
                                // e.preventDefault();
                            }}
                        />
                        <label
                            htmlFor='lfb'
                            style={{
                                width : "78px",
                                height:"96px",
                            }}

                        >
                            <img
                                src={
                                    img ?
                                    img
                                    :
                                    photopic_book
                                }
                                style={{
                                    width:"100%",
                                    height:"100%",
                                    borderRadius:"10px",
                                }}
                            />

                        </label>
                        <div style={{
                            marginTop:"5px",
                        }}>
                            이미지 등록
                        </div>

                    </div>
                    <div style={{
                        display:"flex",
                        flexDirection:"column",
                    }}>
                        <div style={{
                            marginBottom:"3px",
                        }}>
                            교재이름
                        </div>
                        <div style={{
                            display:"flex",
                            width:"180px",
                            // height:"32px",
                            flex:1,
                        }}>
                            <UiInput
                                inputClass={"input_t"}
                                basic_container_style={{
                                    height:"28px",
                                }}
                                inputValue={bookName}
                                placeholder={placeholder}
                                fnChange={(e)=>{
                                    setBookName(e.target.value)
                                }}
                            />
                        </div>
                        {/* 구독 관련 임시 주석 0220 */}
                        {/* <div style={{
                            display:"flex",
                            marginTop:"7px",
                            alignItems:"center",
                            justifyContent:"space-between",
                            marginBottom:"3px",
                        }}>
                            <div style={{
                            }}>
                                교재 구독 공개
                            </div>
                            <Switch
                                size='small'
                                // checked={isApprove}
                                // onClick={(e)=>{
                                //     if(isPublic){
                                //         if(!isApprove){
                                //             setSubsData({
                                //                 ...subsData,
                                //                 approve : "Y"
                                //             })
                                //         }
                                //         else {
                                //             setSubsData({
                                //                 ...subsData,
                                //                 approve : "N"
                                //             })
                                //         }
                                //     }
                                //     else {

                                //     }
                                //     setIsApprove(!isApprove)
                                // }}
                            />

                        </div> */}
                        {/* 0221 임시 주석 */}
                        {/* <div style={{
                            display:"flex",
                            marginTop:"5px",
                            alignItems:"center",
                            justifyContent:"space-between",
                            marginBottom:"3px",
                        }}>
                            <div style={{
                            }}>
                                교재 자동 승인
                            </div>
                            <Switch
                                size='small'
                                checked={isApprove}
                                onClick={(e)=>{
                                    // if(isPublic){
                                    //     if(!isApprove){
                                    //         setSubsData({
                                    //             ...subsData,
                                    //             approve : "Y"
                                    //         })
                                    //     }
                                    //     else {
                                    //         setSubsData({
                                    //             ...subsData,
                                    //             approve : "N"
                                    //         })
                                    //     }
                                    // }
                                    // else {

                                    // }
                                    setIsApprove(!isApprove)
                                }}
                            />

                        </div>
                        {isApprove ? <></>
                        :
                        <div style={{
                            display:"flex",
                            width:"180px",
                            // height:"32px",
                            flex:1,
                        }}>
                            <UiInput
                                isPw={true}
                                basic_container_style={{
                                    height:"28px",
                                }}
                                // placeholder={placeholder}
                                inputValue={bookPw}
                                placeholder="비밀번호를 입력해주세요."
                                fnChange={(e)=>{
                                    setBookPw(e.target.value)
                                }}
                                inputClass={"input_t"}
                            />

                        </div>
                        } */}

                    </div>

                </div>
                {/* <div style={{
                    marginTop:"20px",
                    // paddingTop:"5px",
                    overflowY:"scroll",
                    display:"flex",
                    height:"24px",
                    flex:1,
                    // flexDirection:"column",
                }}>
                    <UiInput
                        basic_container_style={{
                            height:"32px",
                        }}
                        placeholder={placeholder}
                    />
                </div> */}
                <div style={{
                    display:"flex",
                    width:"100%",
                    justifyContent:"center",
                    marginTop:"10px",
                }}>
                    <div style={{
                        display:"flex",
                        width:"180px",
                        justifyContent:"space-between",
                    }}>
                    <div style={{
                        display:"flex",
                        justifyContent:"center",
                        alignItems:"center",
                        width:"87px",
                        height:"40px",
                        borderRadius:"15px",
                        backgroundColor:"#D8D8D8",
                        fontSize:"14px",
                        color : "#FFFFFF",
                        fontWeight:700
                    }}
                        onClick={()=>{
                            setIsModal(false)
                            setFileObj(fileObj = {})

                        }}
                    >
                        취소
                    </div>
                    <div style={{
                        display:"flex",
                        justifyContent:"center",
                        alignItems:"center",
                        width:"87px",
                        height:"40px",
                        borderRadius:"15px",
                        backgroundColor:"#5EA3C6",
                        fontSize:"14px",
                        color : "#FFFFFF",
                        fontWeight:700
                    }}
                        onClick={()=>{
                            apiPro_regi();
                        }}
                    >
                        등록

                    </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Modal_b
