import { fontWeight } from '@mui/system'
import { React , useState , useEffect, useRef } from 'react'
import { useNavigate, useParams } from 'react-router'
import apiFn from '../../asset/apiClass'
import store from '../../asset/store'
import util from '../../asset/util'
import { UiInput } from '../../uiComponents/UiInput/UiInput'
import UiSearchBox from '../../uiComponents/UiSearch/UiSearchBox'

const Modal_auto_set = ({
    setIsModal,
    setFileObj,
    setAddSearch,
    isTeacher,
    setIsText,
    userInfo,
    msgData
}) => {
    let params = useParams();
    let navigate = useNavigate();

    var [selectDay, setSelectDay] = useState("30")

    useEffect(()=>{
        try {
            if(window.localStorage.getItem("msg_auto_set")){
                setSelectDay(window.localStorage.getItem("msg_auto_set"))
            }
        }
        catch(e){
            console.log('************')
            console.log(e)
            console.log('************')
        }
    },[])
    
    const autoSet_style = {
        container : {

        },
        wrapper : {
            display:"flex",
            flexGrow:1,
            justifyContent:"center",
            alignItems:"center",
            width:"55px",
            height:"100%",
            borderRight : "0.5px solid #BABABA",
            height:"32px",
            padding : 5,
            fontSize:"14px",
            fontWeight:400,
            color : "#B0B0B0"
        } ,
    }

    return (
        <div style={{
            position:"fixed",
            top:0,
            zIndex:130002,
            backgroundColor:"rgba(150, 150, 150,0.5)",
            // backgroundColor:"red",
            width:"100%",
            height:"100vh",
            display:"flex",
            justifyContent:"center",
            alignItems:"center"
        }}
            onClick={()=>{
                setIsModal(false)
            }}
        >
            <div style={{
                // width : "75%",
                width:"300px",
                // minHeight:"55%",
                // maxHeight:"60%",
                backgroundColor:"white",
                padding : "40px 20px 40px 20px",
                borderRadius : 10,
                border : "1px solid rgb(200, 200, 200)",
                display:"flex",
                flexDirection:"column",
                justifyContent:"space-between",
                filter: "drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.37))",
            }}
                onClick={(e)=>{
                    e.stopPropagation();
                    // e.preventDefault();
                }}
            >
                <div style={{
                    fontWeight:500,
                    display:"flex",
                    justifyContent:"center",
                    fontSize:"18px",
                    fontWeight:700,
                    marginBottom:"20px",
                }}>
                    {"보관함 자동 삭제 기간 설정"}
                </div>
                <div style={{
                    display:"flex",
                    flex:1,
                    height:"32px",
                    border : "0.5px solid #BABABA",
                    borderRadius:5,
                    // backgroundColor:"green"
                }}>
                    <div style={{
                        ...autoSet_style.wrapper,
                        backgroundColor : selectDay == "3" ? "#6B6B6B" : "",
                        color : selectDay == "3" ? "white" : "#B0B0B0",
                    }}
                        onClick={()=>{
                            setSelectDay("3")
                            window.localStorage.setItem("msg_auto_set", "3")
                        }}
                    >
                        3일
                    </div>
                    <div style={{
                        ...autoSet_style.wrapper,
                        backgroundColor : selectDay == "7" ? "#6B6B6B" : "",
                        color : selectDay == "7" ? "white" : "#B0B0B0",
                    }}
                        onClick={()=>{
                            setSelectDay("7")
                            window.localStorage.setItem("msg_auto_set", "7")

                        }}
                    >
                        7일
                    </div>
                    <div style={{
                        ...autoSet_style.wrapper,
                        backgroundColor : selectDay == "15" ? "#6B6B6B" : "",
                        color : selectDay == "15" ? "white" : "#B0B0B0",
                    }}
                        onClick={()=>{
                            setSelectDay("15")
                            window.localStorage.setItem("msg_auto_set", "15")
                        }}
                    >
                        15일
                    </div>
                    <div style={{
                        ...autoSet_style.wrapper,
                        backgroundColor : selectDay == "30" ? "#6B6B6B" : "",
                        color : selectDay == "30" ? "white" : "#B0B0B0",
                    }}
                        onClick={()=>{
                            setSelectDay("30")
                            window.localStorage.setItem("msg_auto_set", "30")
                        }}
                    >
                        30일
                    </div>
                    <div style={{
                        ...autoSet_style.wrapper,
                        backgroundColor : selectDay == "60" ? "#6B6B6B" : "",
                        color : selectDay == "60" ? "white" : "#B0B0B0",
                        border : "none"
                        // borderRight : "0.5px solid #BABABA"
                    }}
                        onClick={()=>{
                            setSelectDay("60")
                            window.localStorage.setItem("msg_auto_set", "60")
                        }}
                    >
                        60일
                    </div>
                </div>
                <div style={{
                    display:"flex",
                    width:"100%",
                    justifyContent:"center",
                    marginTop:"20px",
                }}>
                    <div style={{
                        display:"flex",
                        width:"180px",
                        justifyContent:"space-between",
                    }}>
                        <div style={{
                            display:"flex",
                            justifyContent:"center",
                            alignItems:"center",
                            width:"87px",
                            height:"40px",
                            borderRadius:"15px",
                            backgroundColor:"#D8D8D8",
                            fontSize:"14px",
                            color : "#FFFFFF",
                            fontWeight:700
                        }}
                            onClick={()=>{
                                setIsModal(false)
                            }}
                        >
                            취소
                        </div>
                        <div style={{
                            display:"flex",
                            justifyContent:"center",
                            alignItems:"center",
                            width:"87px",
                            height:"40px",
                            borderRadius:"15px",
                            backgroundColor:"#5EA3C6",
                            fontSize:"14px",
                            color : "#FFFFFF",
                            fontWeight:700
                        }}
                            onClick={()=>{
                                console.log(window.localStorage.getItem("msg_auto_set"))
                            }}
                        >
                            적용

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Modal_auto_set