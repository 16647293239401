import { VideoCameraFront } from "@mui/icons-material";
import { useLectureMainVideoList } from "api/classList";
import React from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import Item from "./Item";
import styles from "./style.module.scss";

const LectureVideo = ({ userInfo }) => {
  const { data } = useLectureMainVideoList();
  const lectureVideo = data?.articleList;

  return (
    <div className={styles.LectureVideoContainer}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <h1>새로 올라온 수업 영상</h1>
        {lectureVideo?.length > 0 && (
          <Link to={"/class/video/list"} className={styles.MoreLectures}>
            더 보기+
          </Link>
        )}
      </div>
      <div className={styles.LectureVideoSection}>
        <Slider
          infinite={false}
          slidesToShow={1}
          adaptiveHeight={true}
          slidesToScroll={1}
          dots={false}
          arrows={false}
        >
          {lectureVideo?.map((video) => (
            <div className={styles.ItemSection}>
              <Item boardInfo={video} userInfo={userInfo} />
            </div>
          ))}
        </Slider>
      </div>

      {(!lectureVideo || lectureVideo.length <= 0) && (
        <div className={styles.NoContent}>
          <div>
            <VideoCameraFront fontSize={"large"} />
          </div>
          수업 영상이 없습니다.
        </div>
      )}
      {userInfo.type === 2 && (
        <div className={styles.UploadVideoButton}>
          <Link to={"/class/null/video/regist"}>
            + 내 수업 영상 업로드 하기
          </Link>
        </div>
      )}
    </div>
  );
};

export default LectureVideo;
