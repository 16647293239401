import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import { useClassDetailInfo } from "api/classDetail";
import constants from "asset/constants";
import store from "asset/store";
import { Accordion, AccordionDetails, AccordionSummary } from "components/atoms/Accordion";
import React, { useMemo } from "react";
import { useParams } from "react-router";
import styled from "styled-components";

const AdditionalFile = ({ expanded }: { expanded: boolean }) => {
  const params = useParams();
  const parameters = {
    boardId: params["id"],
  };
  const { data } = useClassDetailInfo(parameters);

  const videoData = useMemo(() => data?.getBoard?.boards[0], [data]);
  const files = useMemo(() => videoData?.files, [videoData]);
  return (
    <>
      <Accordion expanded={expanded}>
        <AccordionSummary></AccordionSummary>
        <AccordionDetails>
          <FileNameContainer>
            {files.map((item) => {
              const filePath = `${constants.apiUrl}/resource/${item?.name}`;
              const href = store.isMobile()
                ? `http://native_download?filename=${item.origin}?filepath=${filePath}`
                : filePath;

              return (
                <FileNameFont href={href} download key={item.id}>
                  <span>{item.origin}</span>
                  <DownloadIconContainer>
                    <FileDownloadOutlinedIcon />
                  </DownloadIconContainer>
                </FileNameFont>
              );
            })}
          </FileNameContainer>
        </AccordionDetails>
      </Accordion>
    </>
  );
};

const FileNameContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 15px;
`;

const FileNameFont = styled.a`
  color: #292929;
  font-feature-settings:
    "clig" off,
    "liga" off;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  & > span {
    flex: 1;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
`;
const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  gap: 2px;
  & svg {
    color: #8b8b8b;
    font-size: 16px;
  }
`;

const DownloadIconContainer = styled(IconContainer)`
  & svg {
    color: #777777;
    font-size: 24px;
  }
`;

export default AdditionalFile;
