import { VideoPlayerState } from "@videojs-player/react";
import { useClassDetailInfo, useVideoViewLog } from "api/classDetail";
import constants from "asset/constants";
import { CommonContext } from "asset/context";
import { getUser } from "asset/storage";
import VideoPlayer from "components/molecules/VideoPlayer";
import React, {
  MutableRefObject,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import ReactPlayer from "react-player";
import { OnProgressProps } from "react-player/base";
import { useParams } from "react-router";
import styled, { css } from "styled-components";
import { VideoJsPlayer } from "video.js";
import { ClassContext } from "..";

const ClassVideo = () => {
  const { playerRef, handleQuestionClick, isShowQuestionButton } = useContext(ClassContext);
  const { qnaData } = useContext(CommonContext);
  const params = useParams();
  const [currentPlayerTime, setCurrentPlayerTime] = useState<number>(0);
  const [isPlayed, setIsPlayed] = useState<boolean>(false);
  const [isPlaying, setIsPlaying] = useState<boolean>(false);
  const [playSpeed, setPlaySpeed] = useState<number>(1);
  const [totalPlayTime, setTotalPlayTime] = useState<number>(0);

  const parameters = {
    boardId: params["id"],
  };
  const { data, refetch } = useClassDetailInfo(parameters);
  const videoData = useMemo(() => data?.getBoard?.boards[0], [data]);
  const userInfo = getUser();
  const viewerLog = useMemo(() => {
    const findedLog = videoData.logs.find((log) => log.wid === userInfo?.id && log.btype === 1);
    return findedLog;
  }, [videoData]);

  const { mutate: upsertVideoViewLog } = useVideoViewLog();

  const isLink = useMemo(() => !!videoData.video_link, [videoData.video_link]);
  const startTime = useMemo(() => videoData.video_start, [videoData.video_start]);
  const endTime = useMemo(() => videoData.video_end, [videoData.video_end]);
  const isTeacher = useMemo(() => params["who"] === "teacher", [params["who"]]);

  useEffect(() => {
    const existVideoStart = qnaData?.video_start;
    if (existVideoStart) {
      if (isLink) {
        const player = playerRef.current as ReactPlayer;
        player?.seekTo(existVideoStart);
      } else {
        const player = playerRef.current as VideoJsPlayer;
        player?.currentTime(existVideoStart);
      }
    } else if (startTime) {
      if (isLink) {
        const player = playerRef.current as ReactPlayer;
        player?.seekTo(startTime);
      } else {
        const player = playerRef.current as VideoJsPlayer;
        player?.currentTime(startTime);
      }
    }
  }, [startTime, qnaData]);

  const handleVideoProgress = useCallback(
    (progress: OnProgressProps) => {
      const { playedSeconds, played } = progress;
      const player = playerRef.current as ReactPlayer;

      if (playedSeconds > endTime || playedSeconds < startTime) {
        player.seekTo(startTime);
      }
      console.log("player", player);

      handleSetVideoState({
        playerTime: playedSeconds,
        playSpeed: player?.getInternalPlayer()?.playbackRate ?? 1,
        isPlaying: !player.getInternalPlayer()?.paused ?? false,
      });
    },
    [startTime, endTime, playerRef.current]
  );

  const interval = useRef<NodeJS.Timeout>();
  const handleChangeVideoState = useCallback(
    (state: VideoPlayerState) => {
      handleSetVideoState({
        playerTime: state?.currentTime ?? 0,
        playSpeed: state?.playbackRate ?? 1,
        isPlaying: state?.playing,
      });
    },

    [interval]
  );

  const handleSetVideoState = useCallback(
    ({ playerTime, playSpeed, isPlaying }) => {
      setCurrentPlayerTime(parseInt(`${playerTime ?? 0}`));
      setPlaySpeed(playSpeed);
      setIsPlaying(isPlaying);
      setIsPlayed((prev) => prev || isPlaying);
      if (isPlaying) {
        interval.current =
          interval.current ||
          setInterval(() => {
            setTotalPlayTime((prev) => prev + 1);
          }, 1000);
      } else {
        clearInterval(interval.current);
        interval.current = null;
      }
    },
    [playerRef.current]
  );

  const videoViewRecord = () => {
    if (!userInfo) {
      return;
    }
    const existPlaySpeed = viewerLog?.class_video_max_play_speed ?? 1;
    upsertVideoViewLog({
      id: viewerLog?.id,
      wid: userInfo?.id,
      bid: Number(videoData.id),
      btype: 1,
      class_video_max_play_speed: Math.max(existPlaySpeed, playSpeed),
      class_video_play_time: totalPlayTime,
      class_video_time_in_play: currentPlayerTime,
    });
    setTotalPlayTime(0);
  };

  useEffect(() => {
    totalPlayTime && totalPlayTime % 20 === 0 && videoViewRecord();
  }, [totalPlayTime]);

  useEffect(() => {
    if (isPlayed) {
      videoViewRecord();
    }
    if (isPlayed && !isPlaying) {
      videoViewRecord();
    }
  }, [isPlayed, isPlaying]);

  const isPlayTimeApply = useRef(false);
  useEffect(() => {
    if (isPlayTimeApply.current) {
      return;
    }
    const playedTime = viewerLog?.class_video_time_in_play;
    if (isLink) {
      const player = playerRef.current as ReactPlayer;
      player?.seekTo(playedTime);
    } else {
      const player = playerRef.current as VideoJsPlayer;
      player?.currentTime(playedTime);
    }
    isPlayTimeApply.current = true;
    return () => {
      clearInterval(interval.current);
    };
  }, []);
  const isIpad = useMemo(() => {
    return /iPad/i.test(navigator.userAgent);
  }, [navigator.userAgent]);

  const [isFullScreen, setIsFullScreen] = useState(false);
  const handleFullScreen = useCallback(() => {
    setIsFullScreen((prev) => !prev);
  }, [isFullScreen]);

  return (
    <Container>
      <PlayerContainer>
        {isLink ? (
          <ReactPlayerContainer isFullScreen={isFullScreen}>
            <ReactPlayer
              url={videoData?.video_link}
              onProgress={handleVideoProgress}
              controls
              width={"100%"}
              height={"100%"}
              pip={false}
              ref={playerRef as MutableRefObject<ReactPlayer>}
            />
            {isIpad && (
              <FullscreenButton onClick={handleFullScreen}>
                {isFullScreen ? "전체화면 해제" : "전체화면"}
              </FullscreenButton>
            )}
          </ReactPlayerContainer>
        ) : (
          <VideoPlayer
            startTime={startTime}
            endTime={endTime}
            onChangeVideoState={handleChangeVideoState}
            setPlayerRef={(ref) => (playerRef.current = ref)}
            poster={`${constants.s3_url}/board/${videoData?.id}.jpg?t=${new Date().getTime()}`}
            src={`${constants.video_bucket}/board/${videoData?.id}.mp4`}
            onErrorSrc={`${constants.s3_url}/board/${videoData?.id}.mp4`}
            addon={{
              button: isShowQuestionButton ? (
                <QuestionRegistButton onClick={handleQuestionClick}>
                  {isTeacher ? "질문 등록" : "질문 하기"}
                </QuestionRegistButton>
              ) : null,
            }}
          />
        )}
      </PlayerContainer>
    </Container>
  );
};

const Container = styled.div`
  position: relative;
`;

const PlayerContainer = styled.div`
  display: flex;
  height: auto;
  width: 100%;
  overflow: hidden;
  border-radius: 10px;
  align-items: center;
  justify-content: center;
  position: relative;
  & video {
    width: 100% !important;
    height: auto !important;
    object-fit: cover;
    position: relative !important;
  }
`;

const QuestionRegistButton = styled.button`
  color: #292929 !important;
  font-size: 14px;
  background: #ffa723 !important;
  border-radius: 6px;
  padding: 6px 12px;
`;

const ReactPlayerContainer = styled.div<{ isFullScreen: boolean }>`
  display: flex;
  position: relative;
  width: 100%;
  min-height: 200px;
  height: 40vh;
  background-color: black;
  flex-direction: column;
  ${({ isFullScreen }) =>
    isFullScreen &&
    css`
      width: 100vw;
      height: 100vh;
      min-height: 100vh;
      position: fixed;
      top: 0;
      left: 0;
      z-index: 4;
    `}
`;

const FullscreenButton = styled.button`
  display: flex;
  outline: none;
  border: none;
  background-color: #ffa723;
  padding: 6px 8px;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  margin-right: 8px;
  margin-bottom: 8px;
  border-radius: 8px;
  color: white;
`;
export default ClassVideo;
