import { ArrowForwardIos } from '@material-ui/icons'
import { React , useState , useEffect } from 'react'
import { useNavigate, useParams } from 'react-router'
import apiFn from '../../asset/apiClass'
import constants from '../../asset/constants'
import style from '../../asset/style'
import util from '../../asset/util'
import Basic from '../../common/frame/Basic'
import UiBtn from '../../uiComponents/UiBtn/UiBtn'
import { UiInput_title } from '../../uiComponents/UiInput/UiInput'
import Lecture_modi_Style from '../Profile/Lecture_modi_Style'
import store from "../../asset/store";

const Message_modi = ({
    userInfo,
    modiValue
}) => {

    let navigate = useNavigate();
    let params = useParams();

    var [t,setT] = useState('');

    const apiPro_set = async () => {
        try {
            let isOk = await apiFn.comApi({
                state : {
                    table : "online_qna",
                    set : [{
                        key : `id:${params["qid"]}`,
                        value : {
                           title : t
                        }
                    }]
                    // set : [{
                    //     // key : `bid:${data["id"]} and qid='${params["qid"]}`,
                    //     key : `qid:${params["qid"]}`,
                    //     value : { bid : data["id"] }
                    // }]
                }
            })

            const stack = store.get('stack')
            if(stack.length > 0)
                navigate(-1);
            else
                navigate('/');
        }
        catch(e){
        }
    }

    useEffect(()=>{
        console.log("modiValue : ",modiValue)
        if(modiValue){
            setT(modiValue)
        }
    },[])

    return (
        <Basic title={"출처 변경"}>
                <UiInput_title
                    title="이름"
                    type="basic"
                    inputClass={"input_t" }
                    placholder="새 출처를 입력해주세요."
                    // maxLength="30"
                    inputValue={t}
                    fnChange={(e)=>{
                        setT(e.target.value)
                    }}
                />
                <div style={{
                    display:"flex",
                    flexDirection : "column",
                    justifyContent:"flex-end",
                    paddingBottom : "10px",
                    width : "100%",
                    flex:1,
                }}>
                    <UiBtn
                        btnStyle={{
                            color : "white",
                            backgroundColor : style.common.t_color ,
                        }}
                        title="확인"
                        fnClick={()=>{
                            apiPro_set();
                            // window.alert("삭제되었습니다.")
                            // navigate(navi)
                        }}
                    />

                </div>
        </Basic>
    )
}


export default Message_modi
