import { React, useEffect, useState } from "react";
import style from "../../asset/style";
import UiTextBtn from "../UiTextBtn/UiTextBtn";
import face_teacher from "../../asset/Img/defaultpic_teacher.png";
import UiTeacherList_Style from "./UiTeacherList_Style";
import constants from "../../asset/constants";
import util from "../../asset/util";
import { useNavigate, useParams } from "react-router";

const UiTeacherList = ({ isTeacher, fnClick, data = [] }) => {
  let navigate = useNavigate();
  let params = useParams();

  const [t_data, setT_data] = useState([]);

  useEffect(() => {
    // console.log("UiTeacherList Data => ",data);
    let array = data.slice(0, 3);
    setT_data([...array]);
  }, [data]);

  return (
    <div style={UiTeacherList_Style.c}>
      <div style={UiTeacherList_Style.title_w}>강사진</div>
      <div style={UiTeacherList_Style.section_c}>
        {t_data.map((v, i) => (
          <div style={UiTeacherList_Style.section_w} key={"teacherList_" + i}>
            <div
              style={{
                width: "50px",
                height: "50px",
                marginBottom: "4px",
              }}
              onClick={() => {
                navigate(`/myinfo/${params["who"]}/${v["id"]}`);
              }}
            >
              <img
                // src={face_teacher}

                onError={(e) => {
                  // setIsLoad(false)
                  e.currentTarget.src = face_teacher;
                }}
                src={
                  v["img"]
                    ? constants.s3_url + `/teacher/${v["img"]}` + ".jpg?"
                    : v["id"]
                      ? constants.s3_url +
                        // ( constants.apiUrl.includes('dev.com') ? '/test' : '/public' )
                        // '/public'
                        // +
                        `/teacher/${v["id"].normalize("NFD")}` +
                        ".jpg?" +
                        util.getRandomInt(1000, 9999)
                      : face_teacher
                }
                style={UiTeacherList_Style.section_i}
              />
            </div>
            {`${v["name"]} 선생님`}
          </div>
        ))}
      </div>
      {data.length > 3 && (
        <div style={UiTeacherList_Style.btn_w}>
          <UiTextBtn
            fnClick={fnClick}
            text={Number(data.length) - 3 + "명 선생님 더 보기"}
            // text={data.length > 3 && ((data.length - 3) + "명 선생님 더 보기")}
            btnStyle={{
              color: isTeacher ? style.common.t_color : style.common.s_color,
              ...UiTeacherList_Style.btn,
            }}
            isUnderline={false}
          />
        </div>
      )}
    </div>
  );
};

export default UiTeacherList;
