import React from "react";
import type { IcDefatultProps } from "../types";

export default function IShare({
  width = 24,
  height = 24,
  color = "#363636",
}: IcDefatultProps): JSX.Element {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.5001 7.63935L8.10006 3.4L8.10006 5.8C2.49994 7 2.49994 12.6 2.49994 12.6C2.49994 12.6 4.89994 9.4 8.10006 9.8L8.10006 12.28L14.5001 7.63935Z"
        stroke={color}
        strokeWidth="1.2"
        strokeLinejoin="round"
      />
    </svg>
  );
}
