import { LectureListItem, useLectureMainVideoList } from "api/classList";
import { ReactComponent as EmptyCharacter } from "asset/images/emptyCharacter.svg";
import { ReactComponent as EyeBallOutline } from "asset/images/icon/eye_ball_outline.svg";
import Font, { FontStyleMap } from "components/atoms/Font";
import Section from "components/atoms/Section";
import dayjs from "dayjs";
import React, { useMemo } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import styled from "styled-components";
import LectureVideoThumbnail from "./LectureVideoThumbnail";

const LectureVideoSlider = () => {
  const { data } = useLectureMainVideoList();
  const lectureVideo = useMemo(() => data?.articleList, [data]);
  const isEmpty = useMemo(() => {
    return !lectureVideo || !lectureVideo.length;
  }, [lectureVideo]);
  return isEmpty ? (
    <EmptyItem />
  ) : (
    <XScrollContainer>
      {lectureVideo.map((item) => (
        <div key={item.id}>
          <LectureVideoPreview {...item} />
        </div>
      ))}
    </XScrollContainer>
  );
};

const LectureVideoPreview = (selectedLectureVideo: LectureListItem) => {
  const { teacherName, viewCount, title, regDate, className } = useMemo(() => {
    const regDate = dayjs((selectedLectureVideo?.reg_date ?? 0) * 1000).format("YYYY.MM.DD");
    return {
      teacherName: selectedLectureVideo?.mid_HIQDB_user_credential.user.name,
      viewCount: selectedLectureVideo?.view_member_count.toLocaleString(),
      title: selectedLectureVideo?.title,
      regDate,
      className: selectedLectureVideo?.cid_HIQDB_class?.name,
    };
  }, [selectedLectureVideo]);
  return (
    <Container>
      <ThumbnailWrapper>
        <LectureVideoThumbnail {...selectedLectureVideo} />
      </ThumbnailWrapper>
      <VideoInfoContainer>
        <Section justify="space-between">
          <Font
            styleName="KR/medium/$KR.CAP.MD.12"
            color="var(--neutral-color-natural-60, #434343);"
          >
            {teacherName} 선생님
          </Font>
          <ViewCountFont>
            <EyeBallOutline />
            {viewCount}
          </ViewCountFont>
        </Section>
        <IntroFont>{title}</IntroFont>
        <BottomFontContainer>
          <span>{className}</span>
          <span>{regDate}</span>
        </BottomFontContainer>
      </VideoInfoContainer>
    </Container>
  );
};

const EmptyItem = () => {
  return (
    <EmptyContainer>
      <EmptyCharacter />
      <div>앗, 새로운 수업영상이 없어요.</div>
      <span>다른 수업의 영상을 둘러보세요.</span>
    </EmptyContainer>
  );
};
const Container = styled.div`
  border-radius: 10px;
  overflow: hidden;
  scroll-snap-align: start;
  @media screen and (min-width: 1180px) {
    flex-direction: column;
    scroll-snap-type: y mandatory;
    height: fit-content;
    display: flex;
  }
`;

export const XScrollContainer = styled.div`
  display: flex;
  overflow: auto hidden;
  width: auto;
  scroll-snap-type: x mandatory;
  -ms-overflow-style: none;
  scrollbar-width: none;
  gap: 16px;
  ::-webkit-scrollbar {
    display: none;
  }
  @media screen and (min-width: 820px) {
    gap: 24px;
  }
  @media screen and (min-width: 1180px) {
    flex-direction: column;
    scroll-snap-type: y mandatory;
    overflow: hidden auto;
  }
  > div {
    min-width: 90%;
  }
`;

const VideoInfoContainer = styled.div`
  display: flex;
  padding: 16px;
  flex-direction: column;
  background-color: #fff;
  @media screen and (min-width: 1180px) {
    padding: 32px 16px;
  }
`;

const ThumbnailWrapper = styled.div`
  overflow: hidden;
  background:
    linear-gradient(0deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0.2) 100%),
    url(<path-to-image>),
    lightgray 50% / cover no-repeat;
`;

const ViewCountFont = styled.div`
  color: var(--neutral-color-natural-50, #8c8c8c);
  ${FontStyleMap["KR/regular/$KR.CAP.REG.12"]}
  display: flex;
  align-items: center;
  gap: 2px;
  > svg {
    width: 16px;
    height: 16px;
  }
`;

const IntroFont = styled.div`
  color: var(--neutral-color-natural-70, var(--Default-Gray-90, #262626));
  ${FontStyleMap["KR/medium/$KR.BL.MD.16"]}
`;

const BottomFontContainer = styled.div`
  margin-top: 10px;
  color: var(--neutral-color-natural-50);
  ${FontStyleMap["KR/regular/$KR.CAP.REG.12"]}
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const EmptyContainer = styled.div`
  margin: 16px auto 0;
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: var(--neutral-color-natural-70);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%; /* 16px */
  > svg {
    margin-bottom: 16px;
  }
  > span {
    margin-top: 5px;
    color: var(--neutral-color-natural-50);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%; /* 18.2px */
  }
`;
export default LectureVideoSlider;
