import { Circle } from "@mui/icons-material";
import dayjs from "dayjs";
import { React, useEffect } from "react";
import { useNavigate, useParams } from "react-router";
import style from "../../asset/style";
import UiTextBtn from "../UiTextBtn/UiTextBtn";
import UiNotice_Style from "./UiNotice_Style";

const UiNotice = ({ title = "공지사항", isTeacher, data = [], where }) => {
  let navigate = useNavigate();
  let params = useParams();

  useEffect(() => {
    console.log("Notice data => ", data);
  }, [data]);

  return (
    <div style={UiNotice_Style.c}>
      <div style={UiNotice_Style.title_w}>
        {title}
        <div style={UiNotice_Style.btn_w}>
          {data.length >= 0 && (
            <UiTextBtn
              text={`+ ${data.length > 3 ? data.length - 3 + "개" : ""} 더보기`}
              btnStyle={{
                color: style.common.fontcolor_8,
                ...UiNotice_Style.btn,
              }}
              isUnderline={false}
              fnClick={() => {
                navigate(
                  "/notice/" + params["who"] + "/" + params["id"] + "/" + where
                );
              }}
            />
          )}
        </div>
      </div>
      {data.map((v, i) => {
        if (i > 2) {
        } else {
          return (
            <div
              style={{
                ...UiNotice_Style.section_c,
                justifyContent: "space-between",
              }}
              onClick={() => {
                if (where) {
                  navigate(
                    "/notice/" +
                      params["who"] +
                      "/" +
                      params["id"] +
                      "/" +
                      where
                  );
                } else {
                  navigate(
                    "/notice/" + params["who"] + "/" + v["id"] + "/" + "de"
                  );
                }
              }}
            >
              <div
                style={{
                  ...style.common.line_text,
                  fontSize: "12px",
                  fontWeight: 400,
                }}
              >
                <Circle
                  style={{
                    fontSize: "5px",
                    marginRight: "3px",
                  }}
                />
                <div
                  style={{
                    color: isTeacher
                      ? style.common.t_color
                      : style.common.s_color,
                    ...UiNotice_Style.section_w,
                    display: "inline-flex",
                  }}
                >
                  {/* [중요] */}
                  {v["important"] == "y" && "[중요]"}
                </div>
                {/* 클래스룸 운영을 잘해야 합니다.클래스룸 운영을 잘해야 합니다.클래스룸 운영을 잘해야 합니다. */}
                {v["title"]}
              </div>
              <div
                style={{
                  fontSize: "12px",
                  fontWeight: 400,
                  color: style.common.fontcolor_8,
                }}
              >
                {v["reg_date"] &&
                  dayjs.unix(v["reg_date"]).format("YY. MM. DD.")}
              </div>
            </div>
          );
        }
      })}
    </div>
  );
};

export default UiNotice;
