import { React, useEffect } from 'react'
import { useParams } from 'react-router'
import util from '../../../asset/util';
import style from '../../../asset/style'
import { UiInput, UiInput_title } from '../../../uiComponents/UiInput/UiInput';
import UiBtn from '../../../uiComponents/UiBtn/UiBtn';
import { useNavigate } from "react-router-dom"
import Frame_Style from '../../../common/frame/Frame_Style';
import { ArrowBackIos } from '@material-ui/icons';
import aquery from '../../../asset/Img/aquery.png'
import answer from '../../../asset/Img/answer.png'
import Register_Intro_Style from './Register_Intro_Style';
import store from '../../../asset/store';

const Register_Intro = () => {
    let navigate = useNavigate();
    let params = useParams();

    useEffect(()=>{
        window.localStorage.removeItem('com.naver.nid.access_token');
        window.localStorage.removeItem('com.naver.nid.oauth.state_token');
    },[])
    return (
        <div style={Frame_Style.c}>
            <div style={Register_Intro_Style.w_1}>
                <div style={Frame_Style.t_c}>
                    <div style={Frame_Style.t_l_w}>
                        <ArrowBackIos
                            onClick={()=>{

            const stack = store.get('stack')
            if(stack.length > 0)
                navigate(-1);
            else
                navigate('/')
                            }}
                        />
                    </div>
                </div>
            </div>
            <div style={Register_Intro_Style.w_2}>
                <div style={{
                    padding : style.common.padding,
                }}>
                    <div style={Register_Intro_Style.s_c}
                        onClick={()=>{
                            if(params["type"] == "apple"){
                                navigate("/regiOne/teacher/apple")
                            }
                            else {
                                navigate("/regiOne/teacher/de")
                            }
                        }}
                    >
                        <div style={Register_Intro_Style.s_w}>
                            <div style={{
                                marginBottom : "4px"
                            }}>
                                학생들의 어려운 문제를 해결해주는
                            </div>
                            <div style={{

                                color : style.common.t_color,
                                fontWeight:"bold",
                            }}>
                                선생님 회원 가입 >
                            </div>
                            <img
                                src={aquery}
                                style={Register_Intro_Style.i}
                            />
                        </div>
                    </div>
                    <div style={Register_Intro_Style.s_c}
                        onClick={()=>{
                            if(params["type"] == "apple"){
                                navigate("/regiOne/student/apple")
                            }
                            else {
                                navigate("/regiOne/student/de")
                            }
                        }}
                    >
                        <div style={Register_Intro_Style.s_w}>
                            <div style={{
                                marginBottom : "4px",
                                fontWeight:''
                            }}>
                                모르는 문제의 해답을 찾아가는
                            </div>
                            <div style={{
                                marginTop : "4px",
                                color : style.common.s_color,
                                fontWeight:"bold",
                            }}>
                                학생 회원 가입 >
                            </div>
                            <img
                                src={answer}
                                style={{
                                    ...Register_Intro_Style.i,
                                    // bottom : -8
                                }}
                            />
                        </div>

                    </div>
                </div>
                {/* <div
                    style={Register_Intro_Style.f}
                    className='small_text'
                >
                    이미 하이큐 회원이신가요?
                </div> */}
            </div>
        </div>
    )
}

export default Register_Intro
