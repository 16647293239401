import {
  KeyboardDoubleArrowLeft,
  KeyboardDoubleArrowRight,
} from "@mui/icons-material";
import React, {useContext, useEffect} from "react";
import styled from "styled-components";
import {PdfViewActionsContextState, PdfViewContext} from "../PdfViewContext";
import {useLocation, useNavigate, useParams} from "react-router";
import {getUser} from "asset/storage";
import {ArrowBackIos} from "@material-ui/icons";
import apiFn from "../../../../asset/apiClass";

const Header = () => {
  const userInfo = getUser()
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search)
  const prevStep = searchParams.get('prevStep')
  const type = searchParams.get('type')
  const {currentSlideInfo, currentSlide, changeModalState} = useContext<PdfViewActionsContextState>(PdfViewContext);

  return (
    <div>
      <Button className={'back'} onClick={() => navigate(-1)}>
        <ArrowBackIos/>
      </Button>
      <ButtonGroup>
        <Button onClick={() => {
          searchParams.set('page', currentSlide.toString())
          navigate({pathname: location.pathname, search: searchParams.toString()}, {replace: true});
          navigate(`/talk/${userInfo?.type === 2 ? 'teacher' : 'student'}/null/${currentSlideInfo.qid}/${currentSlideInfo.qid_HIQDB_online_qna.sid}/${currentSlideInfo.qid_HIQDB_online_qna.tid}`)
        }}>
          <KeyboardDoubleArrowLeft/>
          질문방 보기
        </Button>
        <Button onClick={() => {
          changeModalState(true)
        }}>
          전체 보기
          <KeyboardDoubleArrowRight/>
        </Button>
      </ButtonGroup>
      <ButtonGroup className={prevStep !== 'answer' ? 'answer' : ''}>
        {prevStep === 'answer' && <Button onClick={async ()=> {
          let state = {}
          state["table"] = "online_qna_msg"
          state["read"] = {
            wid: userInfo["id"],
            qid:searchParams.get('prevQuestion'),
            first: "y",
            new:'y'
          }
          state["where"] = {
            "HIQDB_online_qna_msg.qid": `= '${searchParams.get('prevQuestion')}'`,
            "HIQDB_online_qna_msg.dell": `= '0'`,
          }
          state["left"] = [
            'A.name|HIQDB_user_credential X1 ON HIQDB_online_qna_msg.wid = X1.name LEFT OUTER JOIN HIQDB_user A on A.index=X1.user_id and A.user_type = 1',
            'B.nick|HIQDB_user_credential X2 ON HIQDB_online_qna_msg.wid = X2.name LEFT OUTER JOIN HIQDB_user B on B.index=X2.user_id and B.user_type = 2',
          ]
          await Promise.all([
            apiFn.comApi({
              state: {
                table: "online_qna_log",
                set: [{
                  key: `wid:${userInfo["id"]}' and qid='${searchParams.get('prevQuestion')}`,
                  value: {
                    reg_date: 0,
                  }
                }]
              }
            }),
            apiFn.comApi({
              state: {
                page: 1,
                amount: 9999,
                sort: "reg_date desc",
                ...state
              }
            })
          ])
          await apiFn.onlineQnaMsgSet({
            state: {
              wtype: 2,
              wid: userInfo["id"],
              qid: searchParams.get('prevQuestion'),
              ctype: 8, // 1 | 10 not source, 11 source
              content: currentSlideInfo?.qid?.toString(),
              play_time: null,
              file_size: null
            }
          })
          navigate(-1)
        }}>
            <KeyboardDoubleArrowLeft/>
            답변 달기
        </Button>}
        {type === 'question' &&
            <Button onClick={()=> {
              searchParams.set('page', currentSlide.toString())
              navigate({pathname: location.pathname, search: searchParams.toString()}, {replace: true});
              window.localStorage.setItem('talk_bookId',currentSlideInfo.bid.toString());
              window.localStorage.setItem('isAnswer','true');
              window.localStorage.setItem('postImg',currentSlideInfo?.qid_HIQDB_online_qna?.img);
              navigate(`/postsearchresult/teacher/${currentSlideInfo.qid}`)
            }}>
                해설 보기
                <KeyboardDoubleArrowRight/>
            </Button>
        }
      </ButtonGroup>
    </div>
  );
};

const ButtonGroup = styled.div`
  display: flex;
  justify-content: space-between;

  &.answer {
    justify-content: end;
  }
`

const Button = styled.button`
  flex: 1;
  outline: 0;
  background: #ffa723;
  color: #fff;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  border: 1px solid;
  max-width: 180px;
  padding: 8px;

  &.back {
    background: transparent;
    color: #1a1a1a;
    border: none;
  }

  & svg {
    font-size: 20px;
  }
`;

export default Header;
