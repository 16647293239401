import { ArrowBackIos } from "@material-ui/icons";
import { useMessageMarkFolderList } from "api/messageMark";
import Header from "components/molecules/Header";
import React, { useMemo } from "react";
import { useNavigate, useParams } from "react-router";

const MessageMarkListHeader = () => {
  const navigate = useNavigate();
  const params = useParams();
  const isRecent = useMemo(() => {
    return params.id === "recent";
  }, [params.id]);

  const isLazy = useMemo(() => {
    return params.id === "1";
  }, [params.id]);

  const { data: folder } = useMessageMarkFolderList(params.id);
  const folderName = useMemo(() => {
    if (isRecent) {
      return "최근 본 질문";
    } else if (isLazy) {
      return "나중에 볼 질문";
    }
    return folder?.pages[0]?.list[0]?.name;
  }, [folder]);

  return <Header title={folderName} leftIcon={<ArrowBackIos />} onLeftClick={() => navigate(-1)} />;
};

export default MessageMarkListHeader;
