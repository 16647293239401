import axios from 'axios'
import { React, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router'
import apiFn from '../../asset/apiClass'
import constants from '../../asset/constants'
import store from '../../asset/store'
import style from '../../asset/style'
import util from '../../asset/util'
import UiBtn from '../../uiComponents/UiBtn/UiBtn'
import UiConfirm from '../../uiComponents/UiConfirm/UiConfirm'
import { UiInput_title } from '../../uiComponents/UiInput/UiInput'
import UiTopPopTextBtn from '../../uiComponents/UiTopPopTextBtn/UiTopPopTextBtn'
import Register_One_style from '../before/register/Register_One_style'
import { Frame } from '../page_index'
import MyInfo_PW_Style from './MyInfo_PW_Style'

const MyInfo_infoModi = ({
    userInfo
}) => {
    let params = useParams()
    let navigate = useNavigate();

    const [isTeacher ,setIsTeacher] = useState(true);
    const [tap,setTap] = useState("home")

    const [regiData,setRegiData] = useState({

    })

    const [modiData,setModiData] = useState({

    })

    // @TODO : user info modify API
    const apiPro_modi = async () =>{
        try {
            const {list: [userCredentialInfo]} = await apiFn.comApi({
                state: {
                    table: "user_credential",
                    where: {
                        "HIQDB_user_credential.name": `= '${userInfo.id}'`
                    },
                    sort: 'createdAt desc',
                    page: 1,
                    amount: 9999
                }
            })

            if(!userCredentialInfo) {
                alert ('해당 하는 사용자 정보를 찾을 수 없습니다')
                throw new Error('user id = name not found')
            }

            let data = await apiFn.comApi({
                state : {
                    table : "user",
                    set: [
                        {
                            key: `index:${userCredentialInfo.user_id}`,
                            value: { ...modiData }
                        }
                    ]

                }
            })
            // console.log("apiPro Modi => ",data);

            const stack = store.get('stack')
            if(stack.length > 0)
                navigate(-1);
            else
                navigate('/')
        }
        catch(e){
            util.c_err_log(`apiPro_modi Error => ${e}`)
        }
    }

    const apiPro_read = async () =>{
        try {
            let data = await apiFn.comApi({
                state: {
                    table: "user_credential",
                    where: {
                        "HIQDB_user_credential.name": `= '${userInfo.id}'`
                    },
                    sort: 'A.\`index\` desc',
                    page: 1,
                    amount: 9999,
                    ref: [
                        'A.* |HIQDB_user A on A.index=HIQDB_user_credential.user_id'
                    ],
                }
            })
            // return data
            setRegiData({
                ...data
            })
        }
        catch(e){
            util.c_err_log(`apiPro_read Error => ${e}`)
        }
    }

    const apiPro_out = async (type) => {
        try {
            let isOk;
            let kako_logout = async () => {
                Kakao.API.request({
                  url: '/v1/user/unlink',
                  success: function(response) {
                    store.logout();
                },
                fail: function(error) {
                    store.logout();
                  },
                })
            }

            let naver_logout = async () => {
                let naverClientId=constants.naverClientId
                let naverClientSecret=constants.naverClientSecret

                let getUrl = `https://nid.naver.com/oauth2.0/token?client_id=${naverClientId}&client_secret=${naverClientSecret}&access_token=${window.sessionStorage.getItem("n_token")}&grant_type=delete&service_provider=NAVER`
                // let res = await axios.get(getUrl)
                axios.get(getUrl,{
                    'Content-type': 'application/x-www-form-urlencoded;charset=utf-8',
                })
                .then((r)=>{
                    // // console.log(r)

                    window.sessionStorage.removeItem("n_token")
                    store.logout();
                })
                .catch(e=>{
                    window.sessionStorage.removeItem("n_token")
                    store.logout();
                    // // console.log("FFFFF ",e)
                })

              }

            if(type == "st"){
                isOk = await apiFn.deleteStudent({
                    state : {
                        sid : userInfo["id"],
                        orderMid : userInfo["id"]
                    }
                })
                if(isOk){
                    if(userInfo["provider"] == "kakao"){
                        await kako_logout();
                    }
                    else {
                        await naver_logout();
                    }

                }
            }
            else {
                isOk = await apiFn.deleteTeacher({
                    state : {
                        tid : userInfo["id"],
                        orderMid : userInfo["id"]
                    }
                })
                if(isOk){
                    if(userInfo["provider"] == "kakao"){
                        await kako_logout();
                    }
                    else {
                        // store.logout();
                        await naver_logout();
                    }

                }
            }
        }
        catch(e){
            // console.log("apiPro_out Error => ",e)
        }
    }


    useEffect(()=>{
        // console.log("parmas => ",params)
        if(params["who"] == "teacher"){
            setIsTeacher(true)
        }
        else {
            setIsTeacher(false)
        }

    },[params])

    useEffect(()=>{
        // console.log("## ",userInfo);
        apiPro_read();
    },[userInfo])

    useEffect(()=>{
        // console.log("modiData => ",modiData);
    },[modiData])


    return (
        <Frame
            userInfo={userInfo}
            title={params["form"] == "de" ? "정보 변경" : "확인"}
            isSearch={false}
        >
            {
            params["form"] == "de" ?
            <>
            <UiTopPopTextBtn
                title='회원탈퇴'
                c_style={{
                    color : style.common.fontColor_3,
                    top : 3,
                }}
                fnClick={()=>{
                    // // console.log(userInfo)
                    let isOk = window.confirm(constants.confirm.cf_1)
                    if(isOk){
                        navigate("/myinfom/"+params["who"]+"/confirm")
                    }
                }}
            />
            <div style={MyInfo_PW_Style.c}>
                <div style={MyInfo_PW_Style.w_1}>
                    <UiInput_title
                        title="이름"
                        type="basic"
                        inputClass={params["who"] == "teacher" ? "input_t" : "input_s"}
                        placeholder={regiData["name"]}
                        fnChange={(e)=>{
                            setModiData({
                                ...modiData,
                                name : e.target.value
                            })
                        }}
                    />
                    <UiInput_title
                        title="닉네임"
                        type="basic"
                        inputClass={params["who"] == "teacher" ? "input_t" : "input_s"}
                        placeholder={regiData["nick"]}
                        fnChange={(e)=>{
                            const replaceId = constants.regex_imo
                            if(e.target.value.match(replaceId)){
                                alert("글자 혹은 숫자만 입력해주세요.")
                                setModiData({
                                    ...modiData,
                                    nick : ""
                                })
                            }
                            else {
                                setModiData({
                                    ...modiData,
                                    nick : e.target.value
                                })
                            }
                            // setModiData({
                            //     ...modiData,
                            //     nick : e.target.value
                            // })
                        }}
                    />
                    <UiInput_title
                        title="소개글"
                        placeholder={modiData["intro"]}
                        inputValue={modiData["intro"] ? modiData["intro"] : regiData["intro"]}
                        inputClass={params["who"] == "teacher" ? "input_t" : "input_s"}
                        dsecStyle = {{
                            height : "80px",
                        }}
                        type="desc"
                        maxLength={80}
                        fnChange={(e)=>{
                            setModiData({
                                ...modiData,
                                intro : e.target.value
                            })
                        }}
                    />
                    <UiInput_title
                        title="전화번호"
                        type="basic"
                        inputClass={params["who"] == "teacher" ? "input_t" : "input_s"}
                        placeholder={regiData["tel"]}
                        fnChange={(e)=>{
                            setModiData({
                                ...modiData,
                                tel : e.target.value
                            })
                        }}
                    />
                    <UiInput_title
                        title="이메일"
                        inputClass={params["who"] == "teacher" ? "input_t" : "input_s"}
                        type="basic"
                        placeholder={regiData["email"]}
                        fnChange={(e)=>{
                            setModiData({
                                ...modiData,
                                email : e.target.value
                            })
                        }}
                    />
                    {
                    !isTeacher &&
                    <>
                    <UiInput_title
                        title="학교"
                        // title="학교 / 학년"
                        type="basic"
                        placeholder={regiData["school"]}
                        fnChange={(e)=>{
                            setModiData({
                                ...modiData,
                                school : e.target.value
                            })
                        }}
                    />
                    <UiInput_title
                        title="목표대학 / 학과"
                        type="basic"
                        placeholder={regiData["collage"]}
                        fnChange={(e)=>{
                            setModiData({
                                ...modiData,
                                collage : e.target.value
                            })
                        }}
                    />
                    </>
                    }
                </div>
                <div style={MyInfo_PW_Style.w_2}>
                    <UiBtn
                        btnStyle={{
                            ...Register_One_style.b,
                            borderColor :
                            ( isTeacher ? style.common.t_color : style.common.s_color )
                            ,
                            backgroundColor :
                            ( isTeacher ? style.common.t_color : style.common.s_color )

                        }}
                        title={"확인"}
                        fnClick={()=>{
                            let isOk = window.confirm(constants.confirm.cf_11)
                            if(isOk){
                                apiPro_modi();
                            }
                        }}
                    />
                </div>
        </div>
            </>
            :
            <UiConfirm
                isTeacher={isTeacher}
                alert={"탈퇴되었습니다."}
                isNavi={false}
                fnClick={()=>{
                    if(params["who"] == "teacher"){
                        apiPro_out("t")
                    }
                    else {
                        apiPro_out("st")
                    }
                }}
            />
        }
        </Frame>
    )
}

export default MyInfo_infoModi
