import { useStudyBookInfo } from "api/studyBook";
import { PaddingContainer } from "components/atoms/Container";
import React, { Suspense, useMemo } from "react";
import { useParams } from "react-router";
import UiLoading from "uiComponents/UiLoading/UiLoading";
import PdfStudyBookDetail from "./components/BookDetail/components/PdfStudyBookDetail";
import StudyBookHeader from "./components/BookDetail/components/StudyBookHeader";
import StudyBookContextProvider from "./context/StudyBookContextProvider";

const BookDetail = () => {
  const { bookId } = useParams();
  const { data } = useStudyBookInfo(bookId);
  const bookType = useMemo(() => {
    return data?.getBook?.book?.book_type;
  }, [data]);
  return (
    <StudyBookContextProvider>
      <Suspense
        fallback={<UiLoading isTransparent={true} isText={false} isCircle={false} text="로딩중" />}
      >
        <PaddingContainer paddingBottom={"24px"}>
          <Suspense
            fallback={
              <UiLoading isTransparent={true} isText={false} isCircle={false} text="로딩중" />
            }
          >
            <StudyBookHeader />
          </Suspense>
          {bookType === 1 || bookType === 2 ? <PdfStudyBookDetail /> : <></>}
          {/* <Footer /> */}
        </PaddingContainer>
      </Suspense>
    </StudyBookContextProvider>
  );
};

export default BookDetail;
