import {KeyboardArrowDown, KeyboardArrowUp, Search} from '@material-ui/icons'
import {React, useState, useEffect} from 'react'
import style from '../../asset/style';
import UiSorting_Style from './UiSorting_Style';

const UiSorting = ({
                       isTeacher,
                       title = "",
                       cnt = null,
                       data = [],
                       fnClick,
                       defaultValue,
                   }) => {
    const [select, setSelect] = useState("");

    // useEffect(()=>{
    //     if(defaultValue){
    //         setSelect(defaultValue)
    //     }
    // },[])

    return (
        <div style={UiSorting_Style.c}>
            <div style={UiSorting_Style.title_w}>
                {title}{cnt && cnt}
            </div>
            <div style={{
                display: "flex",
            }}>
                {
                    data.map((v, i) =>
                        <div
                            key={'sorting'+i}
                            style={{
                                padding: "0px 5px",
                                fontSize: "14px",
                                color: select == "" ?
                                    (
                                        i == 0 &&
                                        (
                                            isTeacher ?
                                                style.common.t_color
                                                :
                                                style.common.s_color
                                        )
                                    )
                                    :
                                    select == v["name"] &&
                                    (
                                        isTeacher ?
                                            style.common.t_color
                                            :
                                            style.common.s_color
                                    ),
                                textDecoration:
                                    select == "" ?
                                        (
                                            i == 0 &&
                                            ("underline")
                                        )
                                        :
                                        select == v["name"] &&
                                        ("underline"),
                                fontWeight:
                                    select == "" ?
                                        (
                                            i == 0 &&
                                            ("bold")
                                        )
                                        :
                                        select == v["name"] &&
                                        ("bold"),
                            }}
                            onClick={() => {
                                setSelect(v["name"])
                                // console.log(v["value"])
                                fnClick(v["value"])
                            }}
                        >
                            {v["name"]}
                        </div>
                    )}
            </div>

        </div>
    )
}

export default UiSorting