import { ArrowForwardIos } from '@material-ui/icons'
import { React , useState , useEffect } from 'react'
import { useNavigate, useParams } from 'react-router'
import apiFn from '../../asset/apiClass'
import constants from '../../asset/constants'
import style from '../../asset/style'
import util from '../../asset/util'
import Basic from '../../common/frame/Basic'
import UiBtn from '../../uiComponents/UiBtn/UiBtn'
import { UiInput_title } from '../../uiComponents/UiInput/UiInput'
import Lecture_modi_Style from '../Profile/Lecture_modi_Style'
import store from "../../asset/store";

const Book_modi = ({
    userInfo
}) => {

    let navigate = useNavigate();
    let params = useParams();

    const [isTeacher ,setIsTeacher] = useState(true);

    const [name,setName] = useState('')

    var [bookData,setBookData] = useState({})
    var [qnaCount,setQnaCount] = useState({})

    const apiPro_read = async () => {
        try {
            let state = {}
            let data = []
            if(params["who"] == "student"){
                state = {
                    id : params["id"],
                    page : 1,
                    amount : 9999,
                    sort : "reg_date desc",
                    cid : params["cid"],
                    sid : userInfo["id"]
                }
                data = await apiFn.studentBooklist({
                    state : {

                        ...state
                    }
                })
            }
            else {
                data = await apiFn.comApi({
                    state : {
                        table : "book",
                        page : 1,
                        amount : 9999,
                        sort : "reg_date desc",
                        // ref : [
                        //     "HIQDB_book_link.cid|HIQDB_book_link.bid=HIQDB_book.id",
                        // ],
                        equal:{
                            id : params["id"]
                        },
                    }
                })
            }

            util.objLog(data["list"][0])
            setBookData({
                ...data["list"][0]
            })
        }
        catch(e){
            // console.log("@#@# ",e)
        }
    }

    const apiPro_set = async () => {
        try {
            let isOk = await apiFn.comApi({
                state : {
                    table : "book",
                    set : [{
                        key : `id:${params["id"]}`,
                        value : { name : name}
                    }]
                    // set: [ { key: 'prKey:15', value: {a:1,b:2}  } ]
                }
            })
            alert("변경 되었습니다.")

            const stack = store.get('stack')
            if(stack.length > 0)
                navigate(-1);
            else
                navigate('/')
        }
        catch(e){
        }
    }

    const apiPro_del = async () => {
        try {
            let isOk = await apiFn.deleteBook({
                state : {
                    boid : params["id"],
                    orderMid : userInfo["id"]
                }
            })
            // console.log("isOk => ",isOk);
            // navigate("/lecture/detail/"+params["who"]+"/"+params["cid"])
            navigate(`/academy/${params["who"]}/book`)
        }
        catch(e){
            cosnole.log("@@@ ",e);
        }
    }

    useEffect(()=>{
        // console.log("parmas => ",params)
        if(params["who"] == "teacher"){
            setIsTeacher(true)
        }
        else {
            setIsTeacher(false)
        }
    },[params])

    useEffect(()=>{
        apiPro_read()
    },[])

    return (
        <Basic title={"정보 수정"}>
             <div style={{
                 ...Lecture_modi_Style.top_textBtn_w,
                    right : 10,
                    top : 12,
                }}
                    onClick={()=>{
                        let isOk = window.confirm(`[${bookData["name"]}] 교재를 삭제하시겠습니까?`)
                        if(isOk){
                            // navigate("/profile/"+params["who"])
                            // navigate("/lecture/detail/"+params["who"]+"/"+params["id"])
                            apiPro_del();
                        }

                    }}
                >
                    교재 삭제
                </div>
                <UiInput_title
                    title="이름"
                    type="basic"
                    inputClass={isTeacher ? "input_t" : "input_s"}
                    placholder="이름을 입력해주세요."
                    // maxLength="30"
                    fnChange={(e)=>{
                        setName(e.target.value)
                    }}
                />
                {/* <div style={{
                    fontSize : "14px",
                    marginTop : "30px",
                }}>
                    폴더
                    <div style={{
                        display:"flex",
                        justifyContent : "space-between",
                        fontSize : "12px",
                        padding : "10px 0px",
                    }}>
                        /시대인재 고1 수업 모음
                        <div style={{
                            // fontWeight:'lighter',
                            display:"flex",
                            alignItems:"center",
                            color : style.common.t_color
                        }}>
                            변경
                            <ArrowForwardIos
                                style={{
                                    fontSize : "14px",
                                }}
                            />
                        </div>
                    </div>
                </div> */}
                <div style={{
                    display:"flex",
                    flexDirection : "column",
                    justifyContent:"flex-end",
                    paddingBottom : "10px",
                    width : "100%",
                    flex:1,
                }}>
                    <UiBtn
                        btnStyle={{
                            color : "white",
                            backgroundColor : ( isTeacher ? style.common.t_color : style.common.s_color )
                        }}
                        title="확인"
                        fnClick={()=>{
                            apiPro_set();
                            // window.alert("삭제되었습니다.")
                            // navigate(navi)
                        }}
                    />

                </div>
        </Basic>
    )
}


export default Book_modi
