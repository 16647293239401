import {React, useState, useEffect} from 'react'
import UiMessage from '../../uiComponents/UiMessage/UiMessage'
import search_pic from '../../asset/Img/search_pic.png'
import search_text from '../../asset/Img/search_text.png'
import interact from '../../asset/Img/interact.png'
import style from '../../asset/style'
import store from '../../asset/store'
import apiFn from '../../asset/apiClass'
import Message_Home_Style from './Message_Home_Style'
import UiTextBtn from '../../uiComponents/UiTextBtn/UiTextBtn'
import UiMessage_Style from '../../uiComponents/UiMessage/UiMessage_Style'
import {ArrowBackIos, ArrowForward, ArrowForwardIos, Star, StarBorder} from '@material-ui/icons'
import bookmark_off from '../../asset/Img/bookmark_off.png'
import bookmark_on from '../../asset/Img/bookmark_on.png'
import metoo_off from '../../asset/Img/metoo_off.png'
import metoo_on from '../../asset/Img/metoo_on.png'
import review from '../../asset/Img/review.png'
import constants from '../../asset/constants'
import UiBtn from '../../uiComponents/UiBtn/UiBtn'
import {UiInput} from '../../uiComponents/UiInput/UiInput'
import {TextareaAutosize} from '@mui/base'
import Switch from '@mui/material/Switch'
import {useNavigate, useParams} from 'react-router'
import Message_talk_style from './Message_talk_style'
import face_student from '../../asset/Img/face_student.png'
import util from '../../asset/util'
import {padding} from '@mui/system'
import './Message_talk.css'
import Message_talk_header_type_a from './Message_talk_header_type_a'
import Message_talk_header_type_b from './Message_talk_header_type_b'
import Talk from './Talk'
import UiExplain_type_b from '../../uiComponents/UiExplain/UiExplain_type_b'
import Basic from '../../common/frame/Basic'
import UiBook from '../../uiComponents/UiBook/UiBook'


const Message_book_list = ({}) => {

    let params = useParams()
    let navigate = useNavigate();

    const [bookList, setBookList] = useState([]);

    // // 교재 리스트
    // const apiPro_read_book = async () => {
    //     try {
    //         let isOk = await apiFn.bookList({
    //             state : {
    //                 tid : params["tid"],
    //                 sid : params["sid"]
    //             }
    //         })


    //         // console.log("################ ",isOk["list"])

    //         setBookList([
    //             ...isOk["list"]
    //         ])
    //     }
    //     catch(e){
    //     }
    // }

    // 교재 리스트
    const apiPro_read_book = async () => {
        try {
            if (params["who"] == "teacher") {
                let isOk = await apiFn.comApi({
                    state: {
                        table: "book",
                        page: 1,
                        amount: 999,
                        u_id: params["tid"],
                        sort: "reg_date desc",
                        ref: [
                            'HIQDB_book_link.cid|HIQDB_book_link.bid=HIQDB_book.id',
                        ],
                        where: {
                            "HIQDB_book_link.mid": `= '${params["tid"]}'`,
                            "HIQDB_book.status": `!= '9'`,
                            // "HIQDB_book_link.fid" : `= 0`,
                            // "HIQDB_book_link.status" : `= '1'`,
                            "HIQDB_book_link.status": "!= 0",
                            "HIQDB_book_link.type": "!= 9",
                            // "HIQDB_book_link.cid" : `= 0`,
                        }

                    }
                })
                // console.log("teacher Book Data ",isOk["list"])
                setBookList(isOk.list.reduce((acc, cur) => {
                    if (!acc.some(({id}) => id === cur.id)) {
                        acc.push(cur)
                    }
                    return acc
                }, []))
            }
        } catch (e) {
        }
    }

    const apiPro_set = async (data) => {
        try {
            let isOk = await apiFn.comApi({
                state: {
                    table: "book_link",
                    set: [{
                        // key : `bid:${data["id"]} and qid='${params["qid"]}`,
                        key: `qid:${params["qid"]}`,
                        value: {bid: data["id"]}
                    }]
                }
                // key : `bid:${v}' and mid='${userInfo["id"]}`,
                // set: [ { key: 'prKey:15', value: {a:1,b:2}  } ]
            })


            const stack = store.get('stack')
            if (stack.length > 0)
                navigate(-1);
            else
                navigate('/')
        } catch (e) {
        }
    }

    useEffect(() => {
        apiPro_read_book();
    }, [])

    useEffect(() => {
        // console.log("params => ",params);
    }, [params])

    return (
        <Basic
            title="다른 교재로 이동"
        >
            <div style={{
                display: "flex",
                flexWrap: "wrap",
                // padding : "0px 10px",
                // maxWidth:"400px",
                // justifyContent:"space-between",
            }}>
                {
                    bookList.map((v, i) =>
                        <UiBook
                            isTeacher={true}
                            data={v}
                            isShare={false}
                            navigate={() => {
                            }}
                            fnClick={(data) => {
                                // console.log("data => ",data)
                                apiPro_set(data)
                            }}
                        />
                    )
                }
            </div>
        </Basic>
    )
}

export default Message_book_list
