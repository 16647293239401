const UiCheckBoxStyle = {
    container : {
            // flexDirection:'row',
        // display:'flex',
        justifyContent:'flex-start',
        // alignItems:"center",
        // minWidth:'300px',
        // minHeight:'3rem'
        // // width:'50px'
        display:'flex',
        flexDirection:'row',
        alignItems:"center",
        // minWidth:'50px',
        // minHeight:'3rem',
        paddingLeft:3,
        // width:'50px'
    },
    list_container : {
        display:'flex',
        // flex:1,
        // height:"100%",
    },
    input_wrapper : {
    },
    label_style : {
        marginRight:5,
    }
}
export default UiCheckBoxStyle