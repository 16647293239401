import { useEffect, useState } from "react";
import constants from "../asset/constants";

const useClassLectureVideo = (
  userId,
  classId,
  page = 1,
  amount = 5,
  order = "recent",
  keyword = ""
) => {
  const [articleList, setArticleList] = useState(null);
  const [tcnt, setTcnt] = useState(0);

  const getclassVideo = async (additional) => {
    if (!userId) return;
    const res = await fetch(
      `${constants.apiUrl}/user/${userId}/class/board?type=7&page=${page}&amount=${amount}&keyword=${keyword}&order=${order}&cid=${classId}`
    );
    if (res.status !== 200) throw new Error(res.statusText);
    const { articleList, tcnt } = await res.json();
    setArticleList((prev) => {
      if (additional && prev) return [...prev, ...articleList];
      else return articleList;
    });
    setTcnt(tcnt);
  };
  useEffect(() => {
    getclassVideo(true);
  }, [userId, page]);

  useEffect(() => {
    getclassVideo(false);
  }, [order, classId, keyword, amount]);

  return { articleList, tcnt, refresh: getclassVideo };
};

const useDeviceWidth = () => {
  const [deviceWidth, setDeviceWidth] = useState(window.innerWidth);

  const handleResize = () => {
    setDeviceWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return deviceWidth;
};

export { useClassLectureVideo, useDeviceWidth };
