const UiSorting_Style = {
    c : {
        display:"flex",
        justifyContent:"space-between",
        fontSize : "12px",
        padding : "10px 0px 10px 0px",
        backgroundColor:"white",
    },
    title_w : {
        fontSize : "14px",
        fontWeight : "bold"
    }
}

export default UiSorting_Style