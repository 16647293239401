import type { IcDefatultProps } from "../types";
import React from "react";

export function IcArrowRotateLeft({
  width = 16,
  height = 16,
  color = "#434343",
}: IcDefatultProps): JSX.Element {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.2474 9.6665C3.92605 11.7958 5.86972 13.3332 8.16157 13.3332C11.0178 13.3332 13.3333 10.9454 13.3333 7.99984C13.3333 5.05432 11.0178 2.6665 8.16157 2.6665C6.24731 2.6665 4.57596 3.73903 3.68174 5.33317M5.25248 5.99984H2.66663V3.33317"
        stroke={color}
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
