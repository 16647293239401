import React, { useRef } from "react";
import QRCode from "qrcode";
import { LinkOutlined } from "@material-ui/icons";
import { Download } from "@mui/icons-material";
import UiBtn from "../../../uiComponents/UiBtn/UiBtn";
import Register_One_style from "../../before/register/Register_One_style";
import styles from "./styles.module.scss";
import style from "../../../asset/style";
import util from "../../../asset/util";
import constants from "../../../asset/constants";
import apiFn from "../../../asset/apiClass";

const InviteModal = ({ cid, name, onClose }) => {
  const qrRef = useRef(null);

  return (
    <button className={styles.InviteModalContainer} onClick={() => {}}>
      <div className={styles.InviteModalContent}>
        <h1>{name} 수업 초대</h1>

        <div>
          {
            <img
              ref={qrRef}
              src={`${constants.s3_url}/qr/${cid}.png`}
              onError={async () => {
                console.log("image not found");

                try {
                  const qrcode = await QRCode.toDataURL(
                    `${constants.redirect_url}lecture/detail/student/${cid}/de`
                  );
                  await apiFn.uploadFile(`qr/${cid}.png`, util.dataURLtoFile(qrcode));
                } catch (e) {
                  alert("QR 불러오기에 실패했습니다");
                }
                setTimeout(() => {
                  qrRef.current.src = `${constants.s3_url}/qr/${cid}.png`;
                }, 2000);
              }}
            />
          }
        </div>

        <div className={styles.ButtonSection}>
          <button
            onClick={async () => {
              await util.copyClip(`${constants.redirect_url}lecture/detail/student/${cid}/de`);
              alert("수업 주소가 복사됐습니다");
            }}
          >
            <LinkOutlined />
            링크 복사
          </button>
          <a
            href={
              "http://native_download?filename=" +
              `${cid}-${name}-QR.png` +
              "?filepath=" +
              `qr/${cid}.png`
            }
          >
            <Download />
            다운로드
          </a>
        </div>

        <UiBtn
          btnStyle={{
            ...Register_One_style.b,
            borderColor: style.common.s_color,
            backgroundColor: style.common.s_color,
          }}
          title="닫기"
          fnClick={onClose}
        />
      </div>
    </button>
  );
};

export default InviteModal;
