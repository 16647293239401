import { CircularProgress } from "@material-ui/core";
import { React } from "react";
import { useNavigate } from "react-router";
import styled from "styled-components";
import store from "../../asset/store";
import style from "../../asset/style";

const UiLoading = ({
  isFull = true,
  isCancle = false,
  text = "파일을 업로드 중입니다.",
  isText = true,
  isTransparent = false,
  isCircle = true,
  container_style = {},
}) => {
  let navigate = useNavigate();
  return (
    <div
      style={
        isFull
          ? {
              position: "fixed",
              top: 0,
              left: 0,
              display: "flex",
              // flex:1,
              width: "100vw",
              height: "100vh",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              zIndex: 999999999999,
              backgroundColor: isTransparent ? "transparent" : "rgba(200,200,200,0.5)",

              ...container_style,
            }
          : {
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: isTransparent ? "transparent" : "rgba(200,200,200,0.5)",
              ...container_style,
            }
      }
    >
      {isCircle && (
        <CircularProgress
          style={{
            color: style.common.t_color,
          }}
        />
      )}
      <div
        style={{
          marginTop: "15px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          fontWeight: 600,
          color: style.common.t_color,
        }}
      >
        <TextContainer>{text}</TextContainer>
        <div>{isText && "잠시만 기다려주세요"}</div>
        {isCancle && (
          <div
            style={{
              marginTop: "10px",
              color: style.common.fontColor_3,
            }}
            onClick={() => {
              const stack = store.get("stack");
              if (stack.length > 0) navigate(-1);
              else navigate("/");
            }}
          >
            취소하기
          </div>
        )}
      </div>
    </div>
  );
};

const TextContainer = styled.div`
  white-space: pre;
`;

export default UiLoading;
