import { React , useState , useEffect } from 'react'
import style from '../../asset/style'
import aquery from '../../asset/Img/aquery.png'
import answer from '../../asset/Img/answer.png'
import UiHiqDescBox_Style from './ UiHiqDescBox_Style'

const UiHiqDescBox = ({
    isTeacher,
    desc1,
    desc2,
    desc3,
    desc4,
    desc5
}) => {
    return (
        <div style={UiHiqDescBox_Style.c}>
            <div style={UiHiqDescBox_Style.text_w}>
                <div>
                    {desc1}
                </div>
                <div>
                    {desc2}
                </div>
                <div style={{
                    paddingTop : "20px",
                }}>
                    <div>
                        {desc3}
                    </div>
                    <div>
                        {desc4}
                    </div>
                    {
                    desc5 &&
                    desc5
                    }
                </div>
            </div>
            <div style={UiHiqDescBox_Style.img_w}>
                <img 
                    src={isTeacher ? aquery : answer}  
                    style={UiHiqDescBox_Style.img}
                    />
            </div>
        </div>
    )
}

export default UiHiqDescBox