import { CircularProgress } from "@mui/material";
import { useSearchClassList } from "api/classMain";
import React, { useMemo } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import styled from "styled-components";
import LectureSearchItem from "./LectureSearchItem";
interface LectureSearchResultProps {
  searchValue: string;
}

const LectureSearchResult = ({ searchValue }: LectureSearchResultProps) => {
  const { data, fetchNextPage } = useSearchClassList(searchValue);
  const { totalPage, currentPage } = useMemo(() => {
    const lastPage = data?.pages && [...data?.pages].pop();
    return {
      totalPage: lastPage?.totalPage ?? 0,
      currentPage: lastPage?.currentPage ?? 0,
    };
  }, [data]);
  const list = useMemo(() => {
    const newList = data?.pages?.flatMap((page) => page?.signupClassList ?? []);
    return newList;
  }, [data]);

  return (
    <InfiniteScroll
      dataLength={list?.length ?? 0}
      next={fetchNextPage}
      loader={
        currentPage > 0 &&
        currentPage < totalPage && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              width: "100%",
              paddingBottom: 40,
              height: 24,
            }}
          >
            <CircularProgress
              style={{
                fontSize: "12px",
              }}
            />
          </div>
        )
      }
      scrollableTarget="scrollableDiv"
      hasMore={currentPage < totalPage}
    >
      <Container>
        <TitleFont>참여 가능한 수업</TitleFont>
        {list?.map((item) => (
          <LectureSearchItem
            key={item.id}
            id={item.id}
            intro={item.intro}
            classType="search"
            address={item.address}
            class_name={item.name}
            classroom_name={item.pid_HIQDB_class.name}
          />
        ))}
      </Container>
    </InfiniteScroll>
  );
};

const Container = styled.ul`
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0 20px;
  gap: 9px;
  margin-top: 40px;
`;

const TitleFont = styled.div`
  color: #373737;
  font-family: Pretendard;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px; /* 111.111% */
`;

export default LectureSearchResult;
