import { MoreVert, TextsmsOutlined } from "@mui/icons-material";
import DefaultStudentImage from "asset/Img/defaultpic_student.png";
import DefaultTeacherImage from "asset/Img/defaultpic_teacher.png";
import constants from "asset/constants";
import { getUser } from "asset/storage";
import Section from "components/atoms/Section";
import React, { useContext, useMemo, useState } from "react";
import styled, { css } from "styled-components";
import { WhoIs } from "types/enum";
import { BoardReply } from "types/graphql/graphql";
import { ReplyContext } from "./ReplyArea";
import ReplyMoreMenu from "./ReplyMoreMenu";

interface IReplyItem extends BoardReply {
  isRereply?: boolean;
}
const ReplyItem = ({ isRereply, ...replyData }: IReplyItem) => {
  const { wid, replyUser, content, wtype, id: replyId } = replyData;

  const isEllipsis = useMemo(() => content.length > 45, [content]);
  const [isOpenEllipsis, setIsOpenEllipsis] = useState(false);
  const { replyStatus, setReplyId, replyId: selectedReplyId } = useContext(ReplyContext);

  const [open, setOpen] = useState(false);
  const isSimple = useMemo(() => replyStatus === "simple", [replyStatus]);
  const who = useMemo(() => (wtype === "2" ? WhoIs.teacher : WhoIs.student), [wtype]);
  const profileImg = useMemo(() => {
    return `${constants.s3_url}/${who}/${wid}.jpg`;
  }, [wtype, wid, constants]);

  const userInfo = getUser();

  return (
    <>
      <ReplyItemContainer
        onClick={() => {
          isRereply || setReplyId(replyId);
        }}
      >
        <Section direction="column" width={"100%"}>
          <Section width={"100%"} gap="20px">
            <UserPicture
              src={profileImg}
              onError={(event) => {
                event.currentTarget.src =
                  who === WhoIs.teacher ? DefaultTeacherImage : DefaultStudentImage;
              }}
            />
            <ReplyTextContainer>
              <TitleFont>{replyUser?.nick}</TitleFont>
              <ReplyFont isEllipsis={isEllipsis && !isOpenEllipsis}>{content}</ReplyFont>
              {isEllipsis && !isSimple && (
                <MoreButton onClick={() => setIsOpenEllipsis((prev) => !prev)}>
                  {isOpenEllipsis ? "접기" : "더보기"}
                </MoreButton>
              )}
            </ReplyTextContainer>
            {userInfo && (
              <MenuIconContainer>
                <MoreVert
                  onClick={(event) => {
                    event.stopPropagation();
                    setOpen(true);
                  }}
                />
              </MenuIconContainer>
            )}
          </Section>
          {isRereply || (
            <ReplyIconContainer>
              <TextsmsOutlined />
              {replyData?.replies?.length ?? 0}
            </ReplyIconContainer>
          )}
        </Section>
      </ReplyItemContainer>
      {userInfo && <ReplyMoreMenu open={open} onClose={() => setOpen(false)} {...replyData} />}
    </>
  );
};
const TitleFont = styled.div`
  color: #292929;
  font-feature-settings:
    "clig" off,
    "liga" off;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
`;

const ReplyItemContainer = styled.div`
  display: flex;
  width: 100%;
  height: auto;
  gap: 20px;
  display: flex;
  box-sizing: border-box;
`;

const ReplyFont = styled.div<{ isEllipsis: boolean }>`
  color: #67727e;
  font-feature-settings:
    "clig" off,
    "liga" off;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  ${({ isEllipsis }) =>
    isEllipsis &&
    css`
      text-overflow: ellipsis;
      overflow: hidden;
      word-break: break-word;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    `}
`;

const ReplyTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
`;

const UserPicture = styled.img`
  border-radius: 40px;
  width: 40px;
  height: 40px;
`;

const MenuIconContainer = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0;
  outline: 0;
  background: unset;
  width: 16px;
  height: 16px;
  margin-left: auto;
  color: #67727e;
  & svg {
    font-size: 16px;
  }
`;

const ReplyIconContainer = styled(MenuIconContainer)`
  width: auto;
  height: 17.999px;
  font-feature-settings:
    "clig" off,
    "liga" off;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  gap: 3px;
`;

const MoreButton = styled.div`
  cursor: pointer;
  color: #8b8b8b;
  font-family: Pretendard;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
  align-self: flex-end;
`;

export default ReplyItem;
