import { useEffect } from "react";

export function useDebounceEffect(
  fn: () => void,
  waitTime: number,
  // deps?: DependencyList,
  deps: any[]
) {
  useEffect(() => {
    var t = setTimeout(() => {
      fn.apply(undefined, deps);
    }, waitTime);

    return () => {
      clearTimeout(t);
    };
  }, deps);
}
