const UiSlideModal_Style = {
    container: {
        position: "absolute", 
        bottom: 0, 
        margin: 0,
        borderTopLeftRadius: 8,
        borderTopRightRadius: 8,
        width: "800px" 
    },

    mobileContainer: {
        position: "absolute", 
        bottom: 0, 
        margin: 0,
        borderTopLeftRadius: 8,
        borderTopRightRadius: 8,
        width: "100vw" 
    }
}

export default UiSlideModal_Style