import { PaddingContainer } from "components/atoms/Container";
import Footer from "components/molecules/Footer";
import StudyBookContextProvider from "pages/StudyBook/context/StudyBookContext";
import React from "react";
import styled from "styled-components";
import StudyBookFolderHeader from "./components/StudyBookFolderHeader";
import StudyBookList from "./components/StudyBookList";

const StudyBookFolder = () => {
  return (
    <StudyBookContextProvider>
      <Container>
        <StudyBookFolderHeader />
        <StudyBookList />
        <Footer />
      </Container>
    </StudyBookContextProvider>
  );
};

const Container = styled(PaddingContainer)`
  max-height: 100vh;
  overflow: hidden;
  padding-bottom: 72px;
`;

export default StudyBookFolder;
