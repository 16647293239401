import {React, useState} from 'react'
import {useParams, useNavigate} from 'react-router'
import UiBtn from '../../../uiComponents/UiBtn/UiBtn'
import store from '../../../asset/store'

const TestButtonList = ({setUserInfo}) => {
    let navigate = useNavigate();

    const apiPro_test_signIn = async (testId, testPW) => {
        try {
            store.axiosPost({
                cat: "hiq",
                cmd: "newSignIn",
                id: testId,
                pw: testPW
            })
                .then((res) => {
                    if (res) {
                        store.set("user", {
                            ...res
                        })
                        if (res["provider"] == "kakao") {
                            window.localStorage.setItem('provider', "kakao");
                        } else {
                            window.localStorage.setItem('provider', "naver");
                        }
                        window.localStorage.setItem('user', JSON.stringify(res));
                        window.localStorage.setItem('pw', testPW);
                        if (res) {
                            setUserInfo({
                                ...res
                            })
                            if (res["type"] == 1) {
                                navigate("/message/" + "student" + "/" + "home")
                            } else {
                                navigate("/message/" + "teacher" + "/" + "home")
                            }
                        }
                    }
                })
                .catch((e) => {
                    console.log('loginError:', e)
                })
        } catch (e) {
            console.log("new Sign In Error => ", e);
        }
    }

    const TestButton = ({title, testId, testPW}) => {
        return (
            <UiBtn
                title={title}
                btnStyle={{
                    height: "54px",
                    backgroundColor: "#f5b21a",
                    color: "white",
                    border: "none"
                }}
                fnClick={() => {
                    apiPro_test_signIn(testId, testPW);
                }}
            />
        )
    }
    const testUserList = [
        {title: "선생님A", testId: "ZZTestTeacherA1", testPW: "ZZTestTeacherA1"},
        {title: "선생님B", testId: "ZZTestTeacherB1", testPW: "ZZTestTeacherB1"},
        {title: "조교A", testId: "ZZTestAssistantA1", testPW: "ZZTestAssistantA1"},
        {title: "조교B", testId: "ZZTestAssistantB1", testPW: "ZZTestAssistantB1"},
        {title: "학생A", testId: "ZZTestStudentA1", testPW: "ZZTestStudentA1"},
        {title: "학생B", testId: "ZZTestStudentB1", testPW: "ZZTestStudentB1"}
    ]
    return (
        <div>
            {testUserList.map((user)=> <TestButton title={user.title} testId={user.testId} testPW={user.testPW}/>)}
        </div>
    )
}

export default TestButtonList
