import { Modal } from "@mui/material";
import React from "react";
import { useRecoilState } from "recoil";
import { captureStateModalAtom } from "../../../../application/recoils/modal/atoms";
import styled from "styled-components";
import fileImgUrl from "asset/Img/file.png";

const Container = styled.div`
  background: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 16px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export default function CaptureStateModal() {
  const [showStateModal, setShowStateModal] = useRecoilState(captureStateModalAtom);

  const handleClose = () => {
    setShowStateModal(false);
  };

  return (
    <Modal
      open={showStateModal}
      onClose={handleClose}
      sx={{
        zIndex: 1300001,
      }}
    >
      <Container>
        <img
          src={fileImgUrl}
          alt={"이미지 캡쳐 진행중"}
          style={{
            width: "250px",
            height: "250px",
            objectFit: "cover",
          }}
          loading="lazy"
        />
        <p>캡쳐가 진행중이에요.</p>
        <p>잠시만 기다려주세요.</p>
      </Container>
    </Modal>
  );
}
