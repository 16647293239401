import { React, useEffect, useState } from "react";
import style from "../../asset/style";
import { useNavigate, useParams } from "react-router";
import UiTextBtn from "../../uiComponents/UiTextBtn/UiTextBtn";
import Message_Mark_List_Style from "./Message_Mark_List_Style";
import Message_Mark_Style from "./Message_Mark_Style";
import UiFolder from "../../uiComponents/UiFolder/UiFolder";
import apiFn from "../../asset/apiClass";
import UiModal from "../../uiComponents/UiModal/UiModal";

const Message_Mark = ({ userInfo, isTeacher, setIsSearch, createObj, setCreateObj }) => {
  let params = useParams();
  let navigate = useNavigate();

  const [isEdit, setIsEdit] = useState(false);

  var [selectData, setSelectData] = useState({});
  var [selectData_l, setSelectData_l] = useState(0);

  var [folderList, setFolderList] = useState([]);

  var [isModal, setIsModal] = useState(false);

  const apiPro_regi = async (name) => {
    try {
      let isOk = await apiFn.comApi({
        state: {
          table: "folder",
          ins: [
            {
              name: name,
              ftype: 1,
              mid: userInfo["id"],
              open: 1,
              usee: 1,
            },
          ],
        },
      });

      // await apiPro_read();
    } catch (e) {}
  };

  const apiPro_del = async () => {
    try {
      // console.log("del => ", selectData )
      let delArray = Object.keys(selectData).reduce((a, v, i) => {
        return [
          ...a,
          {
            // key : `bid : ${v} and mid : ${userInfo["id"]}`
            id: v,
            mid: userInfo["id"],
          },
        ];
      }, []);

      let isOk = await apiFn.comApi({
        state: {
          table: "folder",
          del: delArray,
        },
      });
      setIsEdit(false);
      setIsSearch(true);
      setSelectData({});

      await apiPro_read();
    } catch (e) {
      // console.log("@@@ ",e);
    }
  };

  const apiPro_read = async () => {
    try {
      let data = await apiFn.comApi({
        state: {
          table: "folder",
          page: 1,
          amount: 9999,
          sort: "name desc",
          where: {
            "HIQDB_folder.mid": `='${userInfo["id"]}'`,
            "HIQDB_folder.ftype": `= '1'`,
          },
        },
      });

      setFolderList([...data["list"]]);
    } catch (e) {
      console.log("message_mark apiPro_read error => ", e);
    }
  };

  const apiPro_modi = async (name) => {
    try {
      let isOk = await apiFn.comApi({
        state: {
          table: "folder",
          set: [
            {
              key: `id:${Object.keys(selectData)[0]}`,
              value: { name: name },
            },
          ],
        },
      });

      setIsEdit(false);
      setIsSearch(true);
      setSelectData({});

      await apiPro_read();
    } catch (e) {}
  };

  useEffect(() => {
    selectData_l = 0;
    Object.keys(selectData).map((v, i) => {
      if (selectData[v] == "Y") {
        selectData_l = selectData_l + 1;
      } else if (selectData[v] == "N") {
        if (selectData_l == 0) return;
        selectData_l = selectData_l - 1;
      }
    });
    setSelectData_l(selectData_l);
  }, [selectData]);

  useEffect(() => {
    apiPro_read();
  }, []);

  return (
    <>
      {isEdit ? (
        <div
          style={{
            ...Message_Mark_List_Style.t_c,
            ...Message_Mark_List_Style.t_c_add,
            borderTop: isEdit ? "none" : "1px solid",
            borderColor: style.common.borderColor,
            fontWeight: "bold",
            // paddingLeft : "5px",
          }}
        >
          선택된 폴더를
          <div style={Message_Mark_List_Style.btn_w}>
            <UiTextBtn
              text={"전체 선택"}
              isUnderline={false}
              btnStyle={{
                ...Message_Mark_List_Style.btn,
                color: style.common.fontColor_1,
                marginLeft: "7.5px",
                fontWeight: 400,
              }}
              fnClick={() => {
                let obj = {};
                folderList.map((v) => {
                  obj[v["id"]] = "Y";
                });
                setSelectData({
                  ...obj,
                });
              }}
            />
            <UiTextBtn
              text={"선택 해제"}
              isUnderline={false}
              btnStyle={{
                ...Message_Mark_List_Style.btn,
                color: style.common.fontColor_1,
                marginLeft: "7.5px",
                fontWeight: 400,
              }}
              fnClick={() => {
                setSelectData({});
                setSelectData_l(0);
              }}
            />
            <UiTextBtn
              text={"취소"}
              isUnderline={false}
              btnStyle={{
                ...Message_Mark_List_Style.btn,
                color: style.common.fontColor_1,
                marginLeft: "7.5px",
                fontWeight: 400,
              }}
              fnClick={() => {
                setIsEdit(false);
              }}
            />
            {selectData_l > 0 && (
              <UiTextBtn
                text={"삭제"}
                isUnderline={false}
                btnStyle={{
                  ...Message_Mark_List_Style.btn,
                  marginRight: "7.5px",
                  marginLeft: "7.5px",
                  fontWeight: 400,
                }}
                fnClick={() => {
                  let isOk = window.confirm(
                    "선택하신 폴더를 삭제하시겠어요? \n(폴더가 삭제되면 내부에 있는 즐겨찾기 자료도 같이 삭제됩니다.)"
                  );
                  if (isOk) {
                    apiPro_del();
                  }
                }}
              />
            )}
            {selectData_l == 1 && (
              <UiTextBtn
                text={"이름 변경"}
                isUnderline={false}
                btnStyle={{
                  ...Message_Mark_List_Style.btn,
                  color: style.common.fontColor_1,
                  marginLeft: "7.5px",
                  fontWeight: 400,
                }}
                fnClick={() => {
                  setIsModal(true);
                  // let name = window.prompt("변경할 폴더 이름을 입력해주세요.")
                  // if(name == "")return;
                  // apiPro_modi(name)
                }}
              />
            )}
          </div>
        </div>
      ) : (
        <div
          style={{
            ...Message_Mark_Style.textBtn_add_c,
            justifyContent: "flex-end",
          }}
        >
          <div
            style={{
              color: "#F7B865",
              fontSize: "16px",
              fontWeight: 400,
            }}
            onClick={() => {
              navigate("/messageMark/" + params["who"] + "/0" + "/recent");
            }}
          >
            최근 본 질문
          </div>
          <div
            style={{
              color: "#49A5B1",
              fontSize: "16px",
              fontWeight: 400,
              marginLeft: "10px",
            }}
            onClick={() => {
              navigate("/messageMark/" + params["who"] + "/1" + "/default");
            }}
          >
            나중에 볼 질문
          </div>
          {/* <div style={Message_Mark_Style.textBtn_add_w}>
                <UiBtn 
                    btnStyle={{
                        ...Message_Mark_Style.textBtn_add,
                        borderColor :  isTeacher ? style.common.t_color : style.common.s_color,
                        backgroundColor : isTeacher ? style.common.t_color : style.common.s_color,
                    }}
                    title="최근 본 질문"
                    fnClick={()=>{
                        navigate("/messageMark/"+params["who"]+"/0"+"/recent")
                    }}
                />
            </div>
            <div style={Message_Mark_Style.textBtn_add_w}>
                <UiBtn 
                    btnStyle={{
                        ...Message_Mark_Style.textBtn_add,
                        borderColor :  isTeacher ? style.common.t_color : style.common.s_color,
                        backgroundColor : isTeacher ? style.common.t_color : style.common.s_color,
                    }}
                    title="나중에 볼 질문"
                    fnClick={()=>{
                        navigate("/messageMark/"+params["who"]+"/1"+"/default")
                    }}
                />
            </div> */}
        </div>
      )}
      <div
        style={{
          ...Message_Mark_Style.textBtn_add_c,
          justifyContent: "flex-end",
          fontSize: "14px",
          fontWeight: 400,
        }}
      >
        {isEdit ? (
          <></>
        ) : (
          <>
            <div
              style={{}}
              onClick={() => {
                // navigate("/messageMark/"+params["who"]+"/0"+"/recent")
                setCreateObj({
                  api: apiPro_regi,
                  title: "폴더 추가",
                  input_title: "폴더 이름",
                });
                navigate(`/create/${params["who"]}`);
              }}
            >
              + 추가
            </div>
            <div
              style={{
                marginLeft: "10px",
              }}
              onClick={() => {
                setIsEdit(true);
              }}
            >
              편집
            </div>
          </>
        )}
      </div>
      <div style={Message_Mark_Style.list_w}>
        {folderList.map((v, i) => (
          <div
            style={{
              display: "flex",
              flexGrow: 1,
              justifyContent: "center",
              maxWidth: "120px",
              // width : "calc((100vw - 40px)/3)"
            }}
          >
            <UiFolder
              data={v}
              setSelectData={setSelectData}
              selectData={selectData}
              isEdit={isEdit}
              isTeacher={isTeacher}
              setnsSearch={setIsSearch}
              fnCheck={(e) => {
                if (selectData[e.target.id] == "Y") {
                  delete selectData[e.target.id];
                  setSelectData({
                    ...selectData,
                  });
                } else {
                  setSelectData({
                    ...selectData,
                    [e.target.id]: "Y",
                  });
                }
              }}
              key={"mark_folder_" + i}
              id={i}
              navigate={navigate}
              fnClick={(d) => {
                navigate("/messageMark/" + params["who"] + "/" + d["id"] + "/default");
              }}
              navi={"/messageMark/" + params["who"] + "/" + v["id"] + "/default"}
              params={params}
            />
          </div>
        ))}
      </div>
      {/* <div style={Message_Mark_Style.b_c}>
            <div style={Message_Mark_Style.b_w}>
                <UiBtn 
                    btnStyle={Message_Mark_Style.b_btn}
                    title={
                        <div style={Academy_book_Style.btn_title}>
                            <Add style={Academy_book_Style.btn_Add} />
                            <div style={{
                                paddingLeft : "12px"
                            }}>
                                폴더 추가
                            </div>
                        </div>
                        // <div style={Message_Mark_Style.b_btn_title}>
                        //     <Add
                        //         style={{
                        //             marginRight : "5px"
                        //         }}
                        //     />
                        //     폴더 추가
                        // </div>
                    }
                    fnClick={()=>{
                        setCreateObj({
                            api : apiPro_regi,
                            title : "폴더 추가",
                            input_title : '폴더 이름',
                        })
                        navigate(`/create/${params["who"]}`)
                        // let name = window.prompt("폴더 이름을 입력해주세요.")
                        // apiPro_regi(name)
                    }}
                />
            </div>
        </div> */}
      {isModal && (
        <UiModal
          title={"변경할 폴더 이름을 입력해주세요."}
          setIsModal={setIsModal}
          apiPro={apiPro_modi}
        />
      )}
    </>
  );
};

export default Message_Mark;
