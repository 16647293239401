import React, { useContext, useEffect, useMemo, useState } from "react";
import Basic from "../../common/frame/Basic";
import style from "../../asset/style";
import noBook from "../../asset/Img/book1.png";
import Search_result_style from "./Search_result_style";
import { ArrowDropDown, ArrowDropUp } from "@material-ui/icons";
import listview from "../../asset/Img/listview.png";
import gridview from "../../asset/Img/gridview.png";
import imageview from "../../asset/Img/imageview.png";
import { UiCheckBox } from "../../uiComponents/UiCheckBox/UiCheckBox";
import { Image, Link, Videocam } from "@mui/icons-material";
import QuestionGridItem from "./components/QuestionGridItem";
import QuestionListItem from "./components/QuestionListItem";
import QuestionImageItem from "./components/QuestionImageItem";
import { useNavigate, useParams } from "react-router";
import { getUser } from "../../asset/storage";
import { CommonContext } from "../../asset/context";
import {
  questionSearchOption,
  questionSearchResponseData,
  useQuestionSearch,
} from "../../api/questionSearch";

type QuestionImageSearch = {
  ori_data: any;
  setIsSR_detail: any;
  setHiddenUrl: any;
};
export const iconTypeMapping = {
  1: <Image style={{ color: "#487fba", fontSize: "18px", marginRight: "2px" }} />,
  2: <Videocam style={{ color: "#487fba", fontSize: "18px", marginRight: "3px" }} />,
  3: <Videocam style={{ color: "#487fba", fontSize: "18px", marginRight: "3px" }} />,
  4: <Link style={{ color: "#487fba", fontSize: "18px", marginRight: "3px" }} />,
  8: <Link style={{ color: "#487fba", fontSize: "18px", marginRight: "3px" }} />,
};
const QuestionBookSearch = ({ ori_data, setIsSR_detail, setHiddenUrl }: QuestionImageSearch) => {
  let params = useParams();
  let navigate = useNavigate();
  const userInfo = getUser();

  let sort_count = [
    { name: "5", value: 5 },
    { name: "10", value: 10 },
    { name: "20", value: 20 },
    { name: "50", value: 50 },
  ];
  let sort_qna = [
    { name: "모범 문항", value: "mobum" },
    { name: "완료 문항", value: "qna" },
    { name: "질문 문항", value: "live" },
    { name: "전체 문항", value: "all" },
  ];

  const [sortingCount, setSortingCount] = useState(5);
  const [sortingQna, setSortingQna] = useState("모범 문항");

  const [isSort, setIsSort] = useState(false);
  const [isSort_qna, setIsSort_qna] = useState(false);

  const [isMy, setIsMy] = useState(
    window.localStorage.getItem("search_result_my")
      ? JSON.parse(window.localStorage.getItem("search_result_my"))
      : false
  );

  const [listStat, setListStat] = useState("mobum");
  const [listForm, setListForm] = useState("listview");
  const { fileObj } = useContext(CommonContext);

  const searchObjectData: { searchType: string; searchData: string } = useMemo(() => {
    const fileObjKeys = Object.keys(fileObj);
    let searchType = "text";
    let searchData = fileObj.text;
    if (fileObjKeys.includes("base64")) {
      searchType = "image";
      searchData = fileObj["base64"]
        ?.replace(/^data:image\/png;base64,/, "")
        ?.replace(/^data:image\/jpeg;base64,/, "");
    }
    return {
      searchType,
      searchData,
    };
  }, [fileObj]);

  const { data: postQuestionResponse } = useQuestionSearch({
    userId: userInfo.id,
    userType: userInfo.type,
    option: questionSearchOption[listStat],
    bookId: Number(params.bookId),
    limit: Number(sortingCount) ?? 5,
    searchData: searchObjectData.searchData,
    searchType: searchObjectData.searchType,
    myQuestion: isMy,
  });

  const postQuestionList = useMemo(() => postQuestionResponse, [postQuestionResponse]);
  const getAnswerIcons = (questionData: questionSearchResponseData) => {
    if ([1, 2].includes(questionData?.HIQDB_book_links?.[0]?.bid_HIQDB_book?.book_type))
      return [<></>];
    if (questionData?.HIQDB_online_qna_msgs?.length === 0) return [<div>답변 없음</div>];

    const icons = new Set(
      questionData?.HIQDB_online_qna_msgs?.map((msg) => iconTypeMapping[msg.ctype]).filter(Boolean)
    );

    return [...icons];
  };
  const getNavigateUrl = (question: questionSearchResponseData) => {
    const bookType = question.HIQDB_book_links[0].bid_HIQDB_book.book_type;
    if ([0, 3].includes(bookType))
      return `/talk/${userInfo.type === 2 ? "teacher" : "student"}/searchresult/${question["id"]}/${question["sid"]}/${question["tid"]}`;
    else if ([1, 2].includes(bookType))
      return `/pdfView/${question?.HIQDB_book_links?.[0]?.bid}?questionId=${question?.id}&prevStep=answer&type=question&prevQuestion=${params.qid}`;
  };

  useEffect(() => {
    if (window.localStorage.getItem("search_result_my")) {
      setIsMy(JSON.parse(window.localStorage.getItem("search_result_my")));
    }
    if (window.localStorage.getItem("s_result_amount")) {
      setSortingCount(Number(JSON.parse(window.localStorage.getItem("s_result_amount"))));
    }
    if (window.localStorage.getItem("s_result_sort")) {
      setListStat(JSON.parse(window.localStorage.getItem("s_result_sort"))["value"]);
      setSortingQna(JSON.parse(window.localStorage.getItem("s_result_sort"))["name"]);
      console.log(JSON.parse(window.localStorage.getItem("s_result_sort")));
    }
    if (window.localStorage.getItem("explain_section")) {
      setListForm(window.localStorage.getItem("explain_section"));
    }
  }, [params]);

  useEffect(
    () => () => {
      window.localStorage.removeItem("isAnswer");
      window.localStorage.removeItem("talk_bookId");
      window.localStorage.removeItem("postImg");
    },
    []
  );

  return (
    <>
      <Basic
        isPadding={false}
        title={"문제검색결과"}
        containerStyle={{ overflow: "hidden", maxHeight: "100vh" }}
        pastSearchQna={true}
      >
        <div
          style={{
            display: "flex",
            flex: 1,
            paddingBottom: "10px",
            flexDirection: "column",
            marginTop: "15px",
            height: "calc(100% - 50px)",
          }}
        >
          <div
            style={{
              display: "flex",
              width: "100%",
              maxHeight: "30%",
              justifyContent: "center",
              overflowY: "auto",
            }}
          >
            <div
              className="solCrop__drag-handle"
              style={{
                display: "flex",
                justifyContent: "center",
                width: "85vw",
                height: "fit-content",
                position: "relative",
              }}
            >
              {searchObjectData.searchType === "image" && (
                <img
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "contain",
                    objectPosition: "center",
                    border: "1px solid",
                    borderColor: style.common.borderColor4,
                    borderRadius: "5px",
                  }}
                  onError={(e) => {
                    if (ori_data) {
                      e.currentTarget.src = noBook;
                    }
                  }}
                  src={fileObj.base64}
                />
              )}
              {searchObjectData.searchType === "text" && (
                <div
                  style={{
                    minHeight: "100px",
                    display: "flex",
                    alignItems: "center",
                    padding: "10px",
                  }}
                >
                  {searchObjectData.searchData}
                </div>
              )}

              <div
                style={{
                  width: "60px",
                  height: "60px",
                  position: "absolute",
                  top: "0",
                  left: 0,
                  borderLeft: Search_result_style.border_,
                  borderTop: Search_result_style.border_,
                }}
              ></div>
              <div
                style={{
                  width: "60px",
                  height: "60px",
                  position: "absolute",
                  top: "0",
                  right: 0,
                  borderRight: Search_result_style.border_,
                  borderTop: Search_result_style.border_,
                }}
              ></div>
              <div
                style={{
                  width: "60px",
                  height: "60px",
                  position: "absolute",
                  bottom: "0",
                  left: 0,
                  borderLeft: Search_result_style.border_,
                  borderBottom: Search_result_style.border_,
                }}
              ></div>
              <div
                style={{
                  width: "60px",
                  height: "60px",
                  position: "absolute",
                  bottom: "0",
                  right: 0,
                  borderRight: Search_result_style.border_,
                  borderBottom: Search_result_style.border_,
                }}
              ></div>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              flex: 1,
              marginTop: "10px",
              height: "60%",
            }}
          >
            <div
              style={{
                backgroundColor: "white",
                padding: "10px 10px 0px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                    }}
                  >
                    {
                      <div
                        style={{
                          position: "relative",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            fontSize: "14px",
                            fontWeight: 400,
                            alignItems: "center",
                          }}
                        >
                          {`${sortingCount ? sortingCount : "5"}개씩`}
                          {isSort ? (
                            <ArrowDropUp
                              style={{
                                fontSize: "40px",
                                marginBottom: "3px",
                              }}
                              onClick={() => {
                                setIsSort(false);
                              }}
                            />
                          ) : (
                            <ArrowDropDown
                              style={{
                                fontSize: "40px",
                                marginBottom: "3px",
                              }}
                              onClick={() => {
                                setIsSort(true);
                              }}
                            />
                          )}
                        </div>
                        {isSort ? (
                          <div
                            style={{
                              position: "absolute",
                              left: 0,
                              // top:10,
                              borderRadius: "20px",
                              border: "1px solid #F6F6F6",
                              boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                              padding: "3px 0px",
                              backgroundColor: "white",
                              zIndex: 10,
                            }}
                          >
                            {sort_count.map((v, i) => (
                              <div
                                style={{
                                  padding: "15px 0px",
                                  width: "120px",
                                  backgroundColor: "white",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  fontWeight: 400,
                                  fontSize: "14px",
                                  color: "#3D3A39",
                                  borderRadius: "20px",
                                }}
                                onClick={(e) => {
                                  setSortingCount(v["value"]);
                                  setIsSort(false);
                                  window.localStorage.setItem(
                                    "s_result_amount",
                                    v["value"].toString()
                                  );
                                }}
                              >
                                {v["name"]}
                              </div>
                            ))}
                          </div>
                        ) : (
                          <></>
                        )}
                      </div>
                    }

                    <div
                      style={{
                        position: "relative",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          fontSize: "14px",
                          fontWeight: 400,
                          alignItems: "center",
                        }}
                      >
                        {`${sortingQna ? sortingQna : "모범문항"}`}
                        {isSort_qna ? (
                          <ArrowDropUp
                            style={{
                              fontSize: "40px",
                              marginBottom: "3px",
                            }}
                            onClick={() => {
                              setIsSort_qna(false);
                            }}
                          />
                        ) : (
                          <ArrowDropDown
                            style={{
                              fontSize: "40px",
                              marginBottom: "3px",
                            }}
                            onClick={() => {
                              setIsSort_qna(true);
                            }}
                          />
                        )}
                      </div>
                      {isSort_qna ? (
                        <div
                          style={{
                            position: "absolute",
                            left: 0,
                            borderRadius: "20px",
                            border: "1px solid #F6F6F6",
                            boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                            padding: "3px 0px",
                            backgroundColor: "white",
                            zIndex: 10,
                          }}
                        >
                          {sort_qna.map((v, i) => (
                            <div
                              style={{
                                padding: "15px 0px",
                                width: "120px",
                                backgroundColor: "white",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                fontWeight: 400,
                                fontSize: "14px",
                                color: "#3D3A39",
                                borderRadius: "20px",
                              }}
                              onClick={(e) => {
                                window.localStorage.setItem("s_result_sort", JSON.stringify(v));
                                setSortingQna(v["name"]);
                                setIsSort_qna(false);
                                setListStat(v.value);
                              }}
                            >
                              {v["name"]}
                            </div>
                          ))}
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                </div>

                <div
                  style={{
                    fontSize: "12px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginRight: "15px",
                    }}
                  >
                    {listForm === "listview" ? (
                      <img
                        src={listview}
                        style={{
                          width: "22px",
                          height: "22px",
                        }}
                        onClick={() => {
                          setListForm("gridview");
                          window.localStorage.setItem("explain_section", "gridview");
                        }}
                      />
                    ) : listForm === "gridview" ? (
                      <img
                        src={gridview}
                        style={{
                          width: "22px",
                          height: "22px",
                        }}
                        onClick={() => {
                          setListForm("imageview");
                          window.localStorage.setItem("explain_section", "imageview");
                        }}
                      />
                    ) : (
                      <img
                        src={imageview}
                        style={{
                          width: "22px",
                          height: "22px",
                        }}
                        onClick={() => {
                          setListForm("listview");
                          window.localStorage.setItem("explain_section", "listview");
                        }}
                      />
                    )}
                  </div>
                  {!window.localStorage.getItem("isAnswer") && (
                    <>
                      <UiCheckBox
                        value={"isImportant"}
                        className={params["who"] == "teacher" ? "t" : "s"}
                        checked={isMy}
                        fnCheck={(e) => {
                          window.localStorage.setItem("search_result_my", JSON.stringify(!isMy));
                          setIsMy((prev) => !prev);
                        }}
                      />
                      <div
                        style={{
                          marginLeft: 3,
                        }}
                      >
                        내 문항
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                padding: "0px 5px",
                overflowY: "auto",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div>
                  <span
                    style={{
                      fontSize: "16px",
                      fontWeight: 400,
                    }}
                  >
                    검색결과
                  </span>
                  <span
                    style={{
                      fontSize: "16px",
                      fontWeight: 700,
                      marginLeft: "6px",
                    }}
                  >
                    {`총 ${postQuestionList?.length ?? 0} 문항`}
                  </span>
                </div>
              </div>
              <>
                {postQuestionList?.length === 0 && (
                  <div
                    style={{
                      display: "flex",
                      flex: 1,
                      width: "100%",
                      height: "100%",
                      justifyContent: "center",
                      alignItems: "center",
                      fontSize: "14px",
                    }}
                  >
                    검색 결과가 없습니다.
                  </div>
                )}
                {listForm === "gridview" ? (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      flexWrap: "wrap",
                    }}
                  >
                    {postQuestionList?.map((question, i) => (
                      <div
                        key={question.id + i}
                        style={{
                          display: "flex",
                          flexGrow: 1,
                          maxWidth: "200px",
                          justifyContent: "center",
                        }}
                      >
                        <QuestionGridItem
                          questionData={question}
                          answerIcon={getAnswerIcons(question)}
                          clickCard={() => {
                            setIsSR_detail(true);
                            window.localStorage.setItem("isImgSearch", "true");
                            navigate(getNavigateUrl(question));
                          }}
                        />
                      </div>
                    ))}
                  </div>
                ) : (
                  postQuestionList?.map((question, i) =>
                    listForm === "listview" ? (
                      <QuestionListItem
                        key={`${question.id}+${i}`}
                        questionData={question}
                        answerIcon={getAnswerIcons(question)}
                        clickCard={() => {
                          setIsSR_detail(true);
                          setHiddenUrl(-1);
                          window.localStorage.setItem("isImgSearch", "true");
                          navigate(getNavigateUrl(question));
                        }}
                      />
                    ) : (
                      <QuestionImageItem
                        key={`questionImageItem${question.id}`}
                        questionData={question}
                        answerIcon={getAnswerIcons(question)}
                        clickCard={() => {
                          setIsSR_detail(true);
                          window.localStorage.setItem("isImgSearch", "true");
                          navigate(getNavigateUrl(question));
                        }}
                      />
                    )
                  )
                )}
              </>
            </div>
          </div>
        </div>
      </Basic>
    </>
  );
};

export default QuestionBookSearch;
