const mainHeight = "calc(100vh - 100px)";
const style = {
  common: {
    block: {
      display: "flex",
      position: "fixed",
      minHeight: "50px",
      width: "100%",
      alignItems: "center",
      maxWidth: mainHeight,
    },
    select_fontSize: "14px",
    select_fontSize_2: "11px",
    select_fontSize_3: "12px",
    s_color: "#F5B21A",
    t_color: "#487FBA",
    t_color_msg: "#cbeaf1",
    s_color_msg: "#ffd893",
    borderColor: "rgb(200,200,200)",
    borderColor2: "#f6f6f6",
    borderColor3: "#fff",
    borderColor4: "rgb(230,230,230)",
    // talkRoomColor : "rgb(245,245,245)",
    talkRoomColor: "#F8F8F8",

    // frameBtnColor : "#8f8f8f",
    frameBtnColor: "#bbb",
    // borderColor2 : "rgb(235,235,235)",
    padding: "25px 15px 0px 15px",
    sub_padding: "20px 10px 0px 20px",
    mainHeight: mainHeight,
    maxWidth: "800px",
    fontColor_1: "rgb(80,80,80)",
    fontColor_2: "rgb(160,160,160)",
    fontColor_3: "#B42E2E", // 붉은색
    fontColor_4: "skyblue", // 파랑
    fontColor_5: "rgb(30,30,30)",
    fontColor_6: "#777777",
    fontColor_7: "#aaa",
    fontcolor_8: "#B3B3B3", // 회색 글자
    line_text: {
      display: "-webkit-box",
      WebkitLineClamp: 1,
      WebkitBoxOrient: "vertical",
      wordWrap: "break-word",
      lineHeight: "1.6em",
      maxHeight: "1.6em",
      minHeight: "1.6em",
      fontSize: "14px",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
  },
};

export default style;
