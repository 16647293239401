import { Delete, GppMaybe, ListAltRounded, ModeEditOutlineOutlined } from "@mui/icons-material";
import {
  useClassDetailInfo,
  useDeclarationBoard,
  useDeclarationBoardCancel,
  useDeleteClass,
} from "api/classDetail";
import { getUser } from "asset/storage";
import BottomSheet from "components/molecules/BottomSheet";
import useQueryString from "hooks/useQueryString";
import React, { useCallback, useMemo } from "react";
import { useNavigate, useParams } from "react-router";
import ClassViewLogModal from "./ClassViewLogModal";
const ModifyButtons = ({ onClose, open }: CommonModalControl) => {
  const params = useParams();
  const userInfo = getUser();
  const navigate = useNavigate();
  const parameters = {
    boardId: params["id"],
  };
  const { mutateAsync } = useDeleteClass();
  const { mutate: declarationBoardCancel } = useDeclarationBoardCancel();
  const { data: declarationData } = useDeclarationBoard(parameters);

  const { data } = useClassDetailInfo({
    boardId: params["id"],
  });

  const { getQueryString, setQueryString, removeQueryString } = useQueryString();
  const showViewLog = useMemo(() => getQueryString("showViewLog"), [getQueryString]);

  const isShowViewLog = useMemo(() => showViewLog === "true", [showViewLog]);

  const handleViewLogModal = useCallback(
    (show: boolean) => {
      if (show) {
        setTimeout(() => {
          setQueryString("showViewLog", "true");
        }, 100);
      } else {
        navigate(-1);
      }
    },
    [isShowViewLog]
  );

  const videoData = useMemo(() => data?.getBoard?.boards[0], [data]);
  const memberClass = useMemo(
    () => videoData?.cid_HIQDB_class.HIQDB_member_classes.find((item) => item.mid === userInfo?.id),
    [videoData]
  );

  const handleUpdate = useCallback(() => {
    onClose();
    setTimeout(() => {
      navigate(`/class/${videoData.cid}/video/update/${videoData.id}`);
    }, 100);
  }, [navigate]);

  const handleDelete = useCallback(async () => {
    try {
      if (confirm("글을 삭제하시겠어요?")) {
        onClose();
        await mutateAsync(parameters);
        navigate(-1);
      }
    } catch (e) {
      alert("삭제 중 오류가 발생했습니다");
    }
  }, [navigate]);

  const handleDeclaration = useCallback(() => {
    navigate(`/warn/${params["who"]}/${params["id"]}/write`);
  }, [navigate]);

  const handleDeclarationCancel = useCallback(() => {
    if (confirm("신고를 철회하시겠어요?")) {
      declarationBoardCancel(parameters);
    }
  }, [navigate]);

  const isDeclarationData = useMemo(
    () => declarationData?.list.length > 0,
    [declarationData?.list]
  );

  const isModifiable = useMemo(
    () => videoData.mid === userInfo?.id && memberClass?.type === 5,
    [videoData?.mid, userInfo?.id, memberClass?.type]
  );
  const isDeclarable = useMemo(
    () => videoData.mid !== userInfo?.id && memberClass?.type !== 5,
    [videoData?.mid, userInfo?.id, memberClass?.type]
  );
  return (
    <>
      <BottomSheet
        open={open}
        onClose={onClose}
        menuList={
          isModifiable
            ? [
                {
                  icon: <ModeEditOutlineOutlined />,
                  title: "영상수정",
                  onClick: handleUpdate,
                },
                {
                  icon: <Delete />,
                  title: "영상삭제",
                  onClick: handleDelete,
                },
                {
                  icon: <ListAltRounded />,
                  title: "영상 시청자 목록 보기",
                  onClick() {
                    onClose();
                    handleViewLogModal(true);
                  },
                },
              ]
            : [
                {
                  icon: <GppMaybe />,
                  title: isDeclarationData ? "신고 철회하기" : "신고하기",
                  onClick: isDeclarationData ? handleDeclarationCancel : handleDeclaration,
                },
              ]
        }
      />
      <ClassViewLogModal open={isShowViewLog} onClose={() => handleViewLogModal(false)} />
    </>
  );
};

export default ModifyButtons;
