import { useMutation } from "@apollo/client";
import { useClassDetailInfo, useDefaultBook, useIncreaseViewCount } from "api/classDetail";
import { CommonContext } from "asset/context";
import { getUser } from "asset/storage";
import Container from "components/atoms/Container";
import React, {
  createContext,
  Dispatch,
  Suspense,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import ReactPlayer from "react-player";
import { useNavigate, useParams } from "react-router";
import styled from "styled-components";
import { AskVideoQuestionDocument } from "types/graphql/graphql";
import UiLoading from "uiComponents/UiLoading/UiLoading";
import { VideoJsPlayer } from "video.js";
import ClassDetailHeader from "./components/ClassDetailHeader";
import ClassInfo from "./components/ClassInfo";
import ClassVideo from "./components/ClassVideo";
import QuestionRegistModal from "./components/QuestionRegistModal";
import ReplyArea, { ReplyStatusType } from "./components/ReplyArea";
import TeacherInfo from "./components/TeacherInfo";
import VideoSubInfo from "./components/VideoSubInfo";

interface IClassContext {
  playerRef: React.MutableRefObject<ReactPlayer | VideoJsPlayer>;
  showUploadPictureModal?: boolean;
  isShowQuestionButton?: boolean;
  handleQuestionClick: () => void;
  setShowUploadPictureModal: Dispatch<React.SetStateAction<boolean>>;
  openUploadPictureModal?: () => void;
}
export const ClassContext = createContext<IClassContext>(null);

const ClassDetail = () => {
  const playerRef = useRef<VideoJsPlayer | ReactPlayer>(null);
  const { fileObj } = useContext(CommonContext);
  const [replyStatus, setReplyStatus] = useState<ReplyStatusType>("simple");
  const [showUploadPictureModal, setShowUploadPictureModal] = useState(false);
  const params = useParams();
  const userInfo = getUser();
  const navigate = useNavigate();

  const [askVideoQuestion] = useMutation(AskVideoQuestionDocument, {
    errorPolicy: "all",
  });

  const parameters = {
    boardId: params["id"],
  };
  const { data } = useClassDetailInfo(parameters);
  const [updateVideoCount] = useIncreaseViewCount(parameters.boardId);
  const videoData = useMemo(() => data?.getBoard?.boards[0], [data]);

  const { data: defaultBook } = useDefaultBook(videoData?.cid);
  const openUploadPictureModal = useCallback(async () => {
    if (!defaultBook) {
      alert("기본 교재가 없어서 질문을 등록할 수 없습니다");
      return;
    }

    setShowUploadPictureModal(true);
    return;
  }, [defaultBook]);

  useEffect(() => {
    updateVideoCount();
  }, []);

  useEffect(() => {
    if (fileObj && Object.keys(fileObj).length > 0) {
      setShowUploadPictureModal(true);
    }
  }, [fileObj]);

  const isShowQuestionButton = useMemo(() => {
    return (userInfo?.type === 2 && userInfo?.id === videoData?.mid) || userInfo?.type === 1;
  }, [userInfo, videoData]);

  const handleQuestionClick = useCallback(async () => {
    const isLink = !!videoData?.video_link;
    if (userInfo?.type === 2 && userInfo?.id === videoData?.mid) {
      if (isLink) {
        const player = playerRef?.current as ReactPlayer;
        try {
          player.getInternalPlayer().pauseVideo();
        } catch (e) {
          player.getInternalPlayer().pause();
        }
      } else {
        const player = playerRef?.current as VideoJsPlayer;
        player?.pause();
      }
      openUploadPictureModal();
    } else if (userInfo?.type === 1) {
      const videoUrl = videoData?.video_link ?? `${videoData?.id}.mp4`;
      try {
        let startTime = 0;
        if (isLink) {
          const player = playerRef?.current as ReactPlayer;
          startTime = player?.getCurrentTime?.();
        } else {
          const player = playerRef?.current as VideoJsPlayer;
          startTime = player?.currentTime?.();
        }
        const studentId = userInfo?.id;
        const teacherId = videoData.mid;
        const bookId = parseInt(defaultBook.bid);
        const variables = {
          input: {
            bookId,
            studentId,
            teacherId,
            videoUrl,
            academyId: data.getBoard.boards[0].cid_HIQDB_class.pid.toString(),
            videoCheckPoint: startTime,
            title: videoData.title,
          },
        };
        const result = await askVideoQuestion({ variables });
        if (!result.errors) {
          const questionId = result.data.askVideoQuestion.id;
          navigate(`/talk/${params.who}/0/${questionId}/${studentId}/${teacherId}`);
        } else {
          alert(result.errors[0].message);
        }
      } catch (e) {
        alert((e as Error)?.message || "질문 등록에 실패했습니다.");
      }
    }
  }, [
    askVideoQuestion,
    defaultBook,
    navigate,
    openUploadPictureModal,
    params.who,
    userInfo?.id,
    videoData?.id,
    videoData?.mid,
    videoData?.title,
    videoData?.video_link,
  ]);

  return (
    <Suspense fallback={<UiLoading isCancle={true} text="수업 정보를 불러오고 있습니다." />}>
      <ClassContext.Provider
        value={{
          playerRef,
          showUploadPictureModal,
          setShowUploadPictureModal,
          handleQuestionClick,
          isShowQuestionButton,
          openUploadPictureModal,
        }}
      >
        <Container>
          <ClassDetailHeader />
          <GapContainer>
            <ClassVideo />
            {replyStatus === "list" ? (
              <Suspense
                fallback={
                  <UiLoading isFull={false} isCancle={true} text="댓글 정보를 불러오고 있습니다." />
                }
              >
                <ReplyArea setReplyStatus={setReplyStatus} replyStatus={replyStatus}>
                  <ReplyArea.ReplyListArea />
                </ReplyArea>
              </Suspense>
            ) : (
              <>
                <ClassInfo />
                {isShowQuestionButton && (
                  <RegistButton onClick={handleQuestionClick}>질문 등록</RegistButton>
                )}
                <VideoSubInfo />
                <TeacherInfo />
                <Suspense
                  fallback={
                    <UiLoading
                      isFull={false}
                      isCancle={true}
                      text="댓글 정보를 불러오고 있습니다."
                    />
                  }
                >
                  <ReplyArea setReplyStatus={setReplyStatus} replyStatus={replyStatus}>
                    <ReplyArea.ReplySimple />
                  </ReplyArea>
                </Suspense>
              </>
            )}
          </GapContainer>
        </Container>
        <QuestionRegistModal />
      </ClassContext.Provider>
    </Suspense>
  );
};

const GapContainer = styled(Container)`
  gap: 15px;
  display: flex;
  flex-direction: column;
  padding: 0 20px;
`;

const RegistButton = styled.button`
  border-radius: 6px;
  background: #ffa723;
  height: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none;
  border: none;
  color: var(--White, #fff);
  font-family: Pretendard;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 142.857% */
`;
export default ClassDetail;
