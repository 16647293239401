import { React , useState , useEffect } from 'react'
import { useParams , useNavigate } from 'react-router'
import style from '../../asset/style'
import Basic from '../../common/frame/Basic'
import RegiForm from '../before/register/RegiForm'
import aquery from '../../asset/Img/aquery.png'
import answer from '../../asset/Img/answer.png'
import { UiInput_title } from '../../uiComponents/UiInput/UiInput'
import { Register_One } from '../page_index'
import Register_One_style from '../before/register/Register_One_style'
import UiBtn from '../../uiComponents/UiBtn/UiBtn'
import DaumPostcode from 'react-daum-postcode'
import { UiSearchList } from '../../uiComponents/UiSearchList/UiSearchList'
import apiFn from '../../asset/apiClass'
import util from '../../asset/util'
import photopick_logo from '../../asset/Img/photopick_logo.png'
import store from '../../asset/store'

const Profile_lecture_regi_three = ({
    l_regiData,
    setL_regiData,
    l_time,
    userInfo,
    setFileObj,
    fileObj,
}) => {
    let params = useParams();
    let navigate = useNavigate();

    var [img,setImg] = useState("")
    var [isLoading,setIsLoading] = useState(false);

    const [isTeacher ,setIsTeacher] = useState(false);
    const [isChk , setIsChk] = useState(false);

    const [mapData,setMapData] = useState({})

    const apiPro_regi = async () => {
        try {
            let isOk = await apiFn.createClass({
                state : {
                    ...l_regiData,
                    mid : userInfo["id"],
                    pid : params["id"],
                    type : 1,
                }
            })
            // let isOk = await apiFn.comApi({
            //     state : {
            //         table : "class",
            //         ins : [{
            //             ...l_regiData,
            //             pid : 241, // 클래스룸 id 샘플
            //             type : 1,
            //         }]

            //     }
            // })

            // console.log("isOK => ",isOk);
            return isOk["id"]

        }
        catch(e){
            util.c_err_log(`apiPro_regi Error => ${e}`)
        }
    }

    const apiPro_regi_time = async (mid) => {
        let array = []
        Object.keys(l_time).map((v,i)=>{
            let obj = {}
            if(l_time[v]["slc"] == "Y"){
                obj["start_day"] = v
                obj["end_day"] = v
                obj["start_time"] = l_time[v]["startDay"]
                obj["end_time"] = l_time[v]["endDay"]
                obj["reg_date"] = 0
                obj["mid"] = mid

                array.push(obj)
            }
        })
        let isOk = await apiFn.comApi({
            state : {
                table : "time",
                ins : [
                    ...array
                ]

            }
        })
        // console.log(isOk);
        // if(isOk["id"]){
            // // console.log("$$$")
            return true
        // }
    }

    const apiPro = async () => {
        try {
            let mid = await apiPro_regi();
            let isOk = await apiPro_regi_time(mid);
            if(isOk){
                // console.log("@@ ",fileObj);
                if(fileObj["base64"]){
                    await store.putBucket(fileObj["base64"],`class/${mid}.jpg`, false, false)
                }
                window.alert("강의가 등록되었습니다.")
                setFileObj(fileObj = {})
                if(isOk){
                    navigate("/profile/"+params["who"]+"/"+params["id"]+"/de")
                }
            }
            setIsLoading(false)
        }
        catch(e){
            setIsLoading(false)
            // console.log("강의등록 에러 => ",e)
        }
    }

    const apiPro_img = async (e) => {
        try {
            setFileObj({
                file : e
            })
            navigate("/crop/teacher/set")
        }
        catch(e){
            // console.log("apiPro_img Error= >",e)
        }
    }

    useEffect(()=>{
        if(params["who"] == "teacher"){
            setIsTeacher(true)
        }
        else {
            setIsTeacher(false)
        }
    },[params])


    useEffect(()=>{
        // // console.log("@@ ",fileObj);
        if(fileObj["base64"]){
            setImg(fileObj["base64"])
        }
    },[])

    return (
        <Basic title={"새 강의 생성"}>
            <RegiForm
                num={3}
                isTeacher={isTeacher}
                title="프로필 입력"
                addTitle="(선택)"
                isTopTitle={false}
                dotCnt = {3}
            />
            {
            false &&
            <div style={{
                margin : "20px 0px",
                display : 'flex',
                flexDirection : "column",
                fontSize : "14px",
            }}>
                강의 로고 등록
                {/* <img src={}/> */}
                <div style={{
                    display:"flex",
                    alignItems:"center",
                    justifyContent:"center",
                    width : "100%",
                    height: "100%",
                }}>
                    <div style={{
                        width : '100px',
                        height : "100px",
                    }}>
                         <input
                            id="local_file"
                            type="file"
                            accept='image/*'
                            style={{ display : "none" }}
                            onChange={(e)=>{
                                util.fileOnchange(e,(event,r)=>{
                                    img = r
                                    apiPro_img(e);
                                })
                            }}
                        />
                         <label
                            htmlFor='local_file'
                        >
                            <img
                                style={{
                                    height : "100%"
                                }}
                                src={
                                    img ?
                                    img
                                    :
                                    photopick_logo
                                }
                            />
                        </label>

                    </div>

                </div>

            </div>
            }
            <UiInput_title
                title="강의를 소개하는 글"
                type="desc"
                placeholder="입력해주세요."
                fnChange={(e)=>{
                    setL_regiData({
                        ...l_regiData,
                        intro : e.target.value
                    })
                }}
                inputValue={l_regiData["intro"]}
                maxLength={60}
                dsecStyle={{
                    minHeight : "80px",
                }}
            />

            <div style={Register_One_style.b_c}>
                <UiBtn
                    btnStyle={{
                        ...Register_One_style.b,
                        borderColor :
                        ( isTeacher ? style.common.t_color : style.common.s_color )
                        ,
                        backgroundColor :
                        ( isTeacher ? style.common.t_color : style.common.s_color )
                    }}
                    title="등록 완료"
                    fnClick={()=>{
                        if(isLoading)return;
                        setIsLoading(true)
                        apiPro();
                    }}
                />
            </div>
        </Basic>
    )
}

export default Profile_lecture_regi_three
