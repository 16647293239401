import { useQuery } from "@tanstack/react-query";
import constants from "asset/constants";
import { getUser } from "asset/storage";
import { GetLectureQuery, GetUserByLectureQuery } from "types/graphql/graphql";
import { gqlClient } from "./common";
import { LECTURE_LIST } from "./graphql/lecture";
import { USER_BY_LECTURE } from "./graphql/user";

type UserQuestionSummaryRequest = {
  userId: string;
  targetId: string;
  userType: number;
};

type UserQuestionSummaryResponse = {
  success: boolean;
  questionInfo: {
    all_questions?: number;
    un_answered_questions?: number;
    average_response_time?: number;
    questions_with_me?: number;
    academy_enroll_date?: number;
  };
};

export const useUserQuestionSummary = ({
  userId,
  targetId,
  userType,
}: UserQuestionSummaryRequest) => {
  return useQuery<UserQuestionSummaryResponse>(
    [`/user/${userId}/question/summary`, { targetId: targetId, userType: userType }],
    { enabled: !!userId, suspense: false }
  );
};

type SelectSharedLectureResponse = {
  mid: string;
  class_name: string;
  intro: string;
  address: string;
  img: string;
  manager_name: string;
  classroom_name: string;
  cid: number;
  type: number;
  status: number;
  reg_date: number;
  pid: number;
  class_reg_date: number;
  student_count: number;
  teacher_count: number;
};

export const useSelectSharedLecture = (id: string) => {
  return useQuery<SelectSharedLectureResponse[]>(
    [`/user/${id}/class`, { classType: 1, isClassroomRequired: true }],
    {
      enabled: !!id,
      suspense: false,
    }
  );
};

export const useUserByLecture = (lectureId: string) => {
  const userInfo = getUser();
  const academyId = constants.classroomId;
  return useQuery<GetUserByLectureQuery>(
    ["useUserByLecture", { userId: userInfo.id, academyId, lectureId }],
    async () => {
      return gqlClient.request(USER_BY_LECTURE, {
        userId: userInfo.id,
        lectureId: lectureId === "" ? null : lectureId,
        academyId: academyId ?? null,
      });
    },
    {
      enabled: !!userInfo,
    }
  );
};

export const useMyLectures = () => {
  const userInfo = getUser();
  const academyId = constants.classroomId;
  return useQuery<GetLectureQuery>(
    ["useMyLectures", { academyId }],
    async () => {
      return gqlClient.request(LECTURE_LIST, {
        memberId: userInfo.id,
        academyId: academyId ?? "",
      });
    },
    {
      enabled: !!userInfo,
    }
  );
};
