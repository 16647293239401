import { React , useState , useEffect } from 'react'
import { useParams , useNavigate } from 'react-router'
import style from '../../asset/style'
import Basic from '../../common/frame/Basic'
import RegiForm from '../before/register/RegiForm'
import aquery from '../../asset/Img/aquery.png'
import answer from '../../asset/Img/answer.png'
import { UiInput_title } from '../../uiComponents/UiInput/UiInput'
import { Register_One } from '../page_index'
import Register_One_style from '../before/register/Register_One_style'
import UiBtn from '../../uiComponents/UiBtn/UiBtn'
import DaumPostcode from 'react-daum-postcode'
import { UiSearchList } from '../../uiComponents/UiSearchList/UiSearchList'
import UiSearchBox from '../../uiComponents/UiSearch/UiSearchBox'
import UiTextBtn from '../../uiComponents/UiTextBtn/UiTextBtn'
import constants from '../../asset/constants'
import apiFn from '../../asset/apiClass'
import util from '../../asset/util'

const Lecture_list = ({
    userInfo
}) => {
    let params = useParams();
    let navigate = useNavigate();

    const [isTeacher ,setIsTeacher] = useState(false);
    const [isChk , setIsChk] = useState(false);

    const [mapData,setMapData] = useState({})

    var [searchData,setSearchData] = useState([])
    const [title,setTitle] = useState("")
    const [search,setSearch] = useState("")

    const apiPro_read = async () => {
        try {
            let data = await apiFn.comApi({
                state : {
                    table : "class",
                    page : 1,
                    amount : 9999,
                    sort : "reg_date desc",
                    where:{
                        'HIQDB_class.type' : `='0'`,
                        [util.spaceTrim_qr("HIQDB_class.name")] : `like '%${util.spaceTrim(search)}%'`
                    },
                }
            })

            setSearchData([
                ...data["list"]
            ])
            setTitle(search)

        }
        catch(e){
        }
    }

    const apiPro_regi = async (data) => {
        try {
            let isOk = await apiFn.comApi({
                state : {
                    table : "member_class",
                    ins: [ 
                        {
                            mid : userInfo["id"], 
                            cid : data["id"],
                            type : isTeacher ? 6 : 0,
                            status : 0,
                            reg_date : 0,

                        } 
                    ],
                }
            })
        }
        catch(e){
            // console.log("regi error => ",e);
        }
    }

    useEffect(()=>{
        if(params["who"] == "teacher"){
            setIsTeacher(true)
        }
        else {
            setIsTeacher(false)
        }
    },[params])

    return (
        <Basic title={"기존 클래스룸 가입"}>
            <div style={{
                marginTop : "40px",
            }}>
                {/* <div style={{
                    fontSize : "12px",
                }}>
                    클래스룸 검색
                </div> */}
                <UiSearchBox 
                    title={"수업 검색"}
                    inputClass={isTeacher ? "input_t" : "input_s"}
                    fnChange={(e)=>{
                        setSearch(e.target.value)
                    }}
                    fnClick={()=>{
                        apiPro_read();
                    }}
                    isTeacher={isTeacher}
                />
            </div>
            {/* <div style={{
                display : "flex",
                justifyContent:"center",
                width : "100%",
                marginTop : "10px",
            }}>
                <UiTextBtn 
                    text={"내가 소속된 수업이 검색되지 않아요."} 
                    btnStyle={{
                        color : style.common.fontColor_1,
                        fontSize : "12px",
                    }}
                />
            </div> */}
            {
            searchData.length > 0 &&
            <div style={{
                marginTop : "30px",
            }}>
                <UiSearchList 
                    title={'"'+title +'"'+ " 검색결과"}
                    title_add={`(${searchData.length}건)`}
                    data={searchData}
                    fnClick={(d)=>{
                        if(isTeacher){
                            let isOk = window.confirm(`[ ${d["name"]} ]`+constants.confirm.cf_4)
                            if(isOk){
                                window.alert("등록이 신청되었어요.")
                                apiPro_regi(d);
                                navigate('/academy/'+params["who"]+"/academy")
                            }
                        }
                        else {
                            navigate(`/lecturelist/${params["who"]}/${d["id"]}`)
                            // window.confirm("김이박 선생님의[수학 만점왕]"+constants.confirm.cf_5)
                        }
                    }}
                />
            </div>
            }
           
        </Basic>
    )
}

export default Lecture_list
