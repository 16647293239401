import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import { Box } from "@mui/material";

export const StyledDialog = styled(Dialog)`
  z-index: 130001;
`;

export const Container = styled(Box)`
  width: 100%;
  background: #f9fafb;
  height: 100%;
  position: relative;
`;

export const ContentWrapper = styled("div")`
  height: calc(100vh - 64px);
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 0 24px;
  position: relative;
  max-width: 1180px;
  margin: 0 auto;

  @media (min-width: 720px) {
    flex-direction: row;
  }
`;

export const ImageSection = styled("div")`
  flex: 0;
  @media (min-width: 720px) {
    flex-basis: 50%;
  }
`;

export const ContentSection = styled("div")`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 8px;
  overflow: auto;

  @media (min-width: 720px) {
    flex-basis: 50%;
  }
`;

export const MultiWindowWrapper = styled("div")`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const MultiWindowButton = styled("button")<{ background?: string; color?: string }>`
  background: ${({ background }) => background || "#ffdedd"};
  color: ${({ color }) => color || "#fe544e"};
  padding: 8px 6px 10px 8px;
  border-radius: 0 10px 10px 0;
  font-size: 12px;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  writing-mode: vertical-rl;
  text-orientation: upright;
  box-shadow: 2px 2px 2px 0 rgba(0, 0, 0, 0.1%);
`;
