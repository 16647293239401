import { React , useState , useEffect } from 'react'
import { useParams , useNavigate } from 'react-router'
import style from '../../asset/style'
import Basic from '../../common/frame/Basic'
import RegiForm from '../before/register/RegiForm'
import photopic_book from '../../asset/Img/photopic_book.png'
import { UiInput_title } from '../../uiComponents/UiInput/UiInput'
import { Register_One } from '../page_index'
import Register_One_style from '../before/register/Register_One_style'
import UiBtn from '../../uiComponents/UiBtn/UiBtn'
import UiHiqDescBox from '../../uiComponents/UiHiqDescBox/UiHiqDescBox'
import Register_Four_style from '../before/register/Register_Four_style'
import util from '../../asset/util'
import store from '../../asset/store'
import apiFn from '../../asset/apiClass'
import UiBook from '../../uiComponents/UiBook/UiBook'
import constants from '../../asset/constants'

const Academy_book_regi = ({
    userInfo,
    c_regiData,
    setC_regiData,
    setFileObj,
    fileObj,
}) => {
    let params = useParams();
    let navigate = useNavigate();

    var [img,setImg] = useState("")

    const [isTeacher ,setIsTeacher] = useState(false);
    const [isChk , setIsChk] = useState(false);

    const [bookName,setBookName] = useState("")

    const [bookList,setBookList] = useState([])

    const apiPro_read = async () => {
        try {
            let isOk = await apiFn.comApi({
                state : {
                    table : "book",
                    page : 1,
                    amount : 999,
                    u_id : userInfo["id"],
                    sort : "reg_date desc",
                    where: {
                        'replace(name," ","")'
                        // `replace(${content}, ' ', '')`
                        :
                        `like '%${util.spaceTrim(bookName)}%'`,
                        "HIQDB_book.status" : `!= '9'`,
                        "HIQDB_book.reg_id" : `= '${userInfo["id"]}'`,
                    },
                }
            })

            // console.log("### ",isOk)
            if(isOk["list"].length > 0){

                setBookList([
                    ...isOk["list"]
                ])
                navigate("/academybookregi/"+params["who"]+"/2")
                return;
            }

            await apiPro_regi();
        }
        catch(e){
        }
    }

    const apiPro_regi = async () => {
        try {
            let isOk = await apiFn.comApi({
                state : {
                    table : "book",
                    // id : userInfo["id"],
                    ins: [{
                        name : bookName,
                        reg_date : 0,
                        reg_id : userInfo["id"],

                    }],
                }

            })

            // console.log("isOk => ",isOk);
            if(fileObj["base64"]){
                await store.putBucket(fileObj["base64"],`bookCover/${isOk["id"]}.jpg`, false, false)
            }
            window.alert(constants.confirm.cf_23)
            setFileObj(fileObj = {})
            // navigate(-1)
            navigate("/message/"+params["who"]+"/home")
            navigate("/academy/"+params["who"]+"/book")
        }
        catch(e){
            // console.log("@@@? " ,e)
        }
    }


    const apiPro_img = async (e) => {
        try {

            setFileObj({
                img : img,
                bookName : bookName,
                file : e
            })
            // navigate("/crop/teacher/rectangle")
            navigate("/crop/teacher/set")
        }
        catch(e){
            // console.log("apiPro_img Error= >",e)
        }
    }


    useEffect(()=>{
        if(bookName != ""){
            setIsChk(true)
        }
        else {
            setIsChk(false)
        }
    },[bookName])

    useEffect(()=>{
        if(params["who"] == "teacher"){
            setIsTeacher(true)
        }
        else {
            setIsTeacher(false)
        }
    },[params])

    useEffect(()=>{
        // setFileObj({})
        // // console.log("@@ ",fileObj);
        if(fileObj["base64"]){
            setImg(fileObj["base64"])
            setBookName(fileObj["bookName"])
        }
    },[])

    return (
        <Basic title={"교재 생성"}>
            {
            params["form"] == 1 ?
            <>
            <RegiForm
                num={null}
                isTeacher={isTeacher}
                // title="클래스룸 기본 정보"
                isTopTitle={false}
            />
            <UiHiqDescBox
                isTeacher={isTeacher}
                desc1="교재를 생성하여"
                desc2="선생님의 수업에 공유하세요."
                desc3="교재별 학생들의 질문을 받고"
                desc4="선생님의 모멈 답안을 공유할 수 있습니다."
            />
            <UiInput_title
                type="basic"
                title="교재 이름"
                placeholder="교재의 이름을 입력해주세요."
                inputClass={isTeacher ? "input_t" : "input_s"}
                inputValue={bookName}
                fnChange={(e)=>{
                    setBookName(e.target.value)
                }}
            />
             <div style={Register_Four_style.profile_c}>
                교재 이미지 등록
                <div style={Register_Four_style.profile_w}>
                    <div style={Register_Four_style.profile_b}>
                        <input
                            id="local_file"
                            accept='image/*'
                            type="file"
                            style={{ display : "none" }}
                            onChange={(e)=>{
                                util.fileOnchange(e,(event,r)=>{
                                    // setImg(r)
                                    // // console.log("ASDFASDF ? ? ? ? ? ",img)
                                    img = r
                                    apiPro_img(e);
                                })
                            }}
                        />
                        <label
                            htmlFor='local_file'
                            style={{
                                width : "110px",
                                height : "160px",
                            }}

                        >
                            <img
                                src={
                                    img ?
                                    img
                                    :
                                    photopic_book
                                }
                                style={{
                                    // marginTop : "20px",
                                    // ...Register_Four_style.profile_b_i,
                                    width : '100%',
                                    height : '100%',
                                    objectFit : "cover",
                                    objectPosition:"center",
                                    // width : "110px",
                                    // height : "160px",
                                    border : `1px solid ${style.common.borderColor4}`
                                }}
                            />
                        </label>
                    </div>

                </div>
            </div>
            <div style={Register_One_style.b_c}>
                <UiBtn
                    isTouch={isChk}
                    btnStyle={{
                        ...Register_One_style.b,
                        borderColor :
                        isChk ?
                        ( isTeacher ? style.common.t_color : style.common.s_color )
                        :
                        style.common.borderColor
                        ,
                        backgroundColor :
                        isChk ?
                        ( isTeacher ? style.common.t_color : style.common.s_color )
                        :
                        "rgb(200,200,200)"

                    }}
                    title="교재 생성"
                    fnClick={()=>{
                        let isOk = window.confirm(constants.confirm.cf_22)
                        if(isOk)apiPro_read();
                        // apiPro_regi()
                    }}
                />
            </div>
            </>
            :
            <>
            <RegiForm
                num={null}
                isTeacher={isTeacher}
                title="기본 정보"
                isTopTitle={false}
            />
            <div style={Register_Four_style.profile_c}>
                <div>
                    입력한 교재 이름과 같은 교재가 {bookList.length}건 있습니다.
                </div>
                <div>
                    교재를 생성하시겠어요?
                </div>
            </div>
            <div style={{
                display : "flex",
                flexWrap:"wrap",
                justifyContent:"space-between",
                paddingTop : "20px",
            }}>
                <div style={{
                    padding : "0px 20px 0px 20px",
                    display:"flex",
                    flexWrap:"wrap",
                    justifyContent:"space-between"
                }}>
                    {/* <UiBook /> */}
                    {
                    bookList.map((v,i)=>
                    <div style={{

                    }}>
                        <UiBook
                            isShare={false}
                            data={v}
                        />
                    </div>
                    )
                    }
                </div>
            </div>
            <div style={Register_One_style.b_c}>
                <UiBtn
                    btnStyle={{
                        ...Register_One_style.b,
                        color : "black"

                    }}
                    title="아니요"
                    fnClick={()=>{
                        navigate("/academy/teacher/book")
                    }}
                />
                <UiBtn
                    btnStyle={{
                        ...Register_One_style.b,
                        borderColor : ( isTeacher ? style.common.t_color : style.common.s_color ),
                        backgroundColor : ( isTeacher ? style.common.t_color : style.common.s_color )
                    }}
                    title="교재 생성"
                    fnClick={()=>{
                        let isOk = window.confirm(constants.confirm.cf_22)
                        if(isOk)apiPro_regi();
                    }}
                />
            </div>
            </>
            }

        </Basic>
    )
}

export default Academy_book_regi
