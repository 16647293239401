import { PictureAsPdf } from "@material-ui/icons";
import { React, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import API from "../../api";
import icon_roomexit from "../../asset/Img/icon_roomexit.png";
import apiFn from "../../asset/apiClass";
import constants from "../../asset/constants";
import style from "../../asset/style";
import Draggable from "../../common/Draggable";
import UiBook from "../../uiComponents/UiBook/UiBook";
import UiFolder from "../../uiComponents/UiFolder/UiFolder";
import Message_Style from "../Message/Message_Style";
import PdfUploadModal from "../StudyBookDetail/components/PdfUploadModal";
import { Frame } from "../page_index";
import Modal_ from "./Modal_";
import Modal_b from "./Modal_b";
import UiBook_sub_modal from "./UiBook_sub_modal";
import styles from "./style.module.scss";

const Book_Main = ({ userInfo, fileObj, setFileObj, isPremium }) => {
  let params = useParams();
  let navigate = useNavigate();

  var [bookList, setBookList] = useState([]);
  var [folderList, setFolderList] = useState([]);

  var [isModal_f, setIsModal_f] = useState(false);
  var [isEdit_f, setIsEdit_f] = useState(false);

  var [isEdit_b, setIsEdit_b] = useState(false);

  var [selectData_f, setSelectData_f] = useState({});
  var [selectData_b, setSelectData_b] = useState({});

  var [isModal_b, setIsModal_b] = useState(false);

  var [isModal_sub, setIsModal_sub] = useState({});

  const [showPdfUploadModal, setShowPdfUploadModal] = useState(false);
  const [basicBooks, setBasicBooks] = useState([]);

  // 폴더 리스트
  const apiPro_read_folder = async () => {
    try {
      let data = await apiFn.comApi({
        state: {
          table: "folder",
          page: 1,
          amount: 9999,
          sort: "name desc",
          where: {
            "HIQDB_folder.mid": `='${userInfo["id"]}'`,
            "HIQDB_folder.ftype": `='0'`,
          },
        },
      });
      setFolderList([{ name: "수업에 의한 열람 교재", id: 0 }, ...data["list"]]);
    } catch (e) {}
  };

  // 폴더 삭제
  const apiPro_del_folder = async () => {
    try {
      let confrim = window.confirm(constants.confirm.cf_3);
      if (!confrim) return;
      let delFid = [];
      let delArray = Object.keys(selectData_f).reduce((a, v, i) => {
        delFid.push(v);
        return [...a, { id: v }];
      }, []);

      let isOk = await apiFn.comApi({
        state: {
          delFid: delFid,
          table: "folder",
          del: delArray,
        },
      });

      setIsEdit_f(false);

      await apiPro_read_folder();
      await getParticipantBook();
    } catch (e) {
      console.log("@@ ", e);
    }
  };

  const getParticipantBook = async () => {
    try {
      const res = await API.getParticipantBookList(userInfo.id, "isInFolder=false");
      setBookList(res?.bookList);
    } catch (e) {
      console.error(e);
      alert("교재 목록을 가져오는데 실패 했습니다.");
    }
  };
  const getBasicBook = async () => {
    try {
      const res = await API.getCurrentClassBooks(userInfo.id, "isBasicBook=true");
      setBasicBooks(res.bookList.map(({ id }) => id));
    } catch (e) {
      console.error(e);
      alert("기본 교재 목록을 가져오는데 실패 했습니다.");
    }
  };

  // 폴더에 교재를 공유
  const apiPro_share_book = async (data) => {
    try {
      console.log("data : ", data);
      if (data.id === 0) {
        alert("해당 폴더에는 교재를 이동시킬 수 없습니다.");
        return;
      }
      let s_bookList = Object.keys(selectData_b);
      let confirm = window.confirm(
        `선택하신 ${s_bookList.length}개의 교재를 "${data["name"]}" 폴더로 이동 시키겠어요?`
      );

      if (!confirm) return;
      let isOk;
      let array = [];
      if (params["who"] == "teacher") {
        array = s_bookList.reduce((a, v, i) => {
          return [
            ...a,
            {
              key: `bid:${v}' and mid='${userInfo["id"]}`,
              value: {
                fid: data["id"],
              },
            },
          ];
        }, []);
        isOk = await apiFn.comApi({
          state: {
            table: "book_link",
            set: array,
          },
        });
      } else {
        array = s_bookList.reduce((a, v, i) => {
          return [
            ...a,
            {
              bid: v,
              mid: userInfo["id"],
              fid: data["id"],
            },
          ];
        }, []);
        // console.log(array)
        isOk = await apiFn.studentBookFolderSet({
          state: {
            set: array,
          },
        });
      }
      setIsEdit_b(false);
      await getParticipantBook();
    } catch (e) {
      // console.log(e)
    }
  };

  // 교재 삭제
  const apiPro_book_del = async () => {
    try {
      let promiseArray = Object.keys(selectData_b).map(async (v) => {
        const bookData = bookList.filter((book) => book.id === Number(v))[0];
        if (v === "0") {
          alert("미지정 교재는 제거할 수 없습니다.");
          return null;
        } else if (selectData_b[v] === 11) {
          alert(
            `${bookData.name}은 기본 교재 입니다.\n 수업 기본 교재 설정 해제 후 제거할 수 있습니다.`
          );
          return;
        }
        const response = await apiFn.deleteBook({
          state: {
            boid: v,
            orderMid: userInfo["id"],
          },
        });
        if (response.status === 30) {
          const targetBookIndex = bookList.findIndex((book) => book.id === Number(v));
          alert(`${bookList[targetBookIndex].name}책을 삭제할 수 있는 권한이 없습니다.`);
        }
      });
      await Promise.all(promiseArray);
      setSelectData_b({});
      setIsEdit_b(false);
    } catch (e) {
      console.log("************");
      console.log(e);
      console.log("************");
    } finally {
      await getParticipantBook();
    }
  };

  console.log("selectData_b", selectData_b);
  useEffect(() => {
    if (fileObj["base64"]) {
      setIsModal_b(true);
    }

    apiPro_read_folder();
  }, []);

  useEffect(() => {
    if (!isModal_f && userInfo.id) {
      apiPro_read_folder();
    }
  }, [isModal_f, userInfo]);

  useEffect(() => {
    if (!isModal_b && userInfo.id) {
      getParticipantBook();
      getBasicBook();
    }
  }, [isModal_b, userInfo]);

  return (
    <>
      <Frame
        isPremium={isPremium}
        userInfo={userInfo}
        // t_visible={false}
        // isTap={false}
        isBack={false}
        type="2"
        isSearch={false}
        title={
          <div
            style={{
              fontFamily: "Roboto",
              fontStyle: "normal",
              fontWeight: 400,
              fontSize: "24px",
              lineHeight: "28px",
              textAlign: "center",
              letterSpacing: "2px",
            }}
          >
            내 교재
          </div>
        }
      >
        <div
          style={{
            ...Message_Style.c,
          }}
        >
          <div className={`${styles.subContainer} ${styles.folder}`}>
            <div className={styles.titleSection}>
              <div
                style={{
                  fontSize: "18px",
                  fontWeight: 700,
                }}
              >
                내 폴더
              </div>
              <div
                style={{
                  display: "flex",
                  fontWeight: 400,
                  color: "#3D3A39",
                  fontSize: "14px",
                }}
              >
                {isEdit_f ? (
                  <>
                    <div
                      style={{}}
                      onClick={() => {
                        let obj = {};
                        folderList.map((v) => {
                          obj[v["id"]] = "Y";
                        });
                        setSelectData_f({
                          ...obj,
                        });
                      }}
                    >
                      전체 선택
                    </div>
                    <div
                      style={{
                        marginLeft: "10px",
                      }}
                      onClick={() => {
                        setSelectData_f({});
                      }}
                    >
                      선택 취소
                    </div>
                    <div
                      style={{
                        marginLeft: "10px",
                      }}
                      onClick={() => {
                        setIsEdit_f(false);
                        setSelectData_f({});
                      }}
                    >
                      취소
                    </div>
                    <div
                      style={{
                        marginLeft: "10px",
                        color: style.common.fontColor_3,
                      }}
                      onClick={() => {
                        apiPro_del_folder();
                      }}
                    >
                      삭제
                    </div>
                  </>
                ) : (
                  <>
                    <div
                      style={{}}
                      onClick={() => {
                        setIsModal_f(true);
                      }}
                    >
                      + 추가
                    </div>
                    <div
                      style={{
                        marginLeft: "10px",
                      }}
                      onClick={() => {
                        setIsEdit_f(true);
                      }}
                    >
                      편집
                    </div>
                  </>
                )}
              </div>
            </div>
            <div className={styles.folderWrapper}>
              {folderList.map((v, i) => (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    maxWidth: "120px",
                    maxHeight: "124px",
                    // width : "calc((100vw - 40px)/3)"
                  }}
                >
                  <UiFolder
                    data={v}
                    key={"mark_folder_" + i}
                    id={i}
                    navigate={navigate}
                    isNavi={isEdit_b ? false : true}
                    params={params}
                    setSelectData={setSelectData_f}
                    selectData={selectData_f}
                    isEdit={isEdit_f}
                    fnClick={(d) => {
                      if (isEdit_b) apiPro_share_book(d);
                    }}
                    navi={`/bookfolder/${params["who"]}/${v["id"]}/list`}
                  />
                </div>
              ))}
            </div>
          </div>
          <div className={`${styles.subContainer} ${styles.book}`}>
            <div className={styles.titleSection}>
              <div
                style={{
                  fontSize: "18px",
                  fontWeight: 700,
                }}
              >
                내 교재
              </div>
              {params["who"] == "teacher" && isEdit_b ? (
                <div
                  style={{
                    display: "flex",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      fontWeight: 400,
                      color: "#3D3A39",
                      fontSize: "14px",
                    }}
                  >
                    <div
                      style={{}}
                      onClick={() => {
                        let obj = {};
                        bookList.map((v) => {
                          obj[v["id"]] = v.type;
                        });
                        setSelectData_b({
                          ...obj,
                        });
                      }}
                    >
                      전체 선택
                    </div>
                  </div>
                  {params["who"] == "teacher" && Object.keys(selectData_b).length > 0 && (
                    <div
                      style={{
                        display: "flex",
                        fontWeight: 400,
                        color: style.common.fontColor_3,
                        fontSize: "14px",
                        marginLeft: "10px",
                      }}
                      onClick={() => {
                        let isOk = window.confirm(
                          `선택하신  ${Object.keys(selectData_b).length}개의 교재를 삭제 하시겠어요?`
                        );
                        if (isOk) apiPro_book_del();
                      }}
                    >
                      선택 삭제
                    </div>
                  )}
                  <div
                    style={{
                      display: "flex",
                      fontWeight: 400,
                      color: "#3D3A39",
                      fontSize: "14px",
                      marginLeft: "10px",
                    }}
                    onClick={() => {
                      setSelectData_b({});
                    }}
                  >
                    선택 취소
                  </div>
                  <div
                    style={{
                      display: "flex",
                      fontWeight: 400,
                      color: "#3D3A39",
                      fontSize: "14px",
                      marginLeft: "10px",
                    }}
                    onClick={() => {
                      setIsEdit_b(false);
                      setSelectData_f({});
                    }}
                  >
                    취소
                  </div>
                </div>
              ) : (
                <div
                  style={{
                    display: "flex",
                  }}
                >
                  <img
                    style={{
                      width: "20px",
                      height: "20px",
                      marginRight: "10px",
                    }}
                    src={icon_roomexit}
                    onClick={() => {
                      navigate(`/bookremove/${params["who"]}/0/0/del`);
                    }}
                  />
                  <div
                    style={{
                      display: "flex",
                      fontWeight: 400,
                      color: "#3D3A39",
                      fontSize: "14px",
                    }}
                  >
                    <div
                      style={{}}
                      onClick={() => {
                        setIsModal_b(true);
                      }}
                    >
                      + 새 교재 추가
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      fontWeight: 400,
                      color: "#3D3A39",
                      fontSize: "14px",
                      marginLeft: "10px",
                    }}
                    onClick={() => {
                      setIsEdit_b(true);
                    }}
                  >
                    편집
                  </div>
                </div>
              )}
            </div>
            <div className={styles.mainContentWrapper}>
              {bookList?.map((v, i) => {
                const normalLink =
                  params["who"] === "teacher"
                    ? `/book/${params["who"]}/${v["id"]}/0/de`
                    : `/book/${params["who"]}/${v["id"]}/${v["classID"] ? v["classID"] : "subs"}/de`;
                const pdfLink = `/study-book/${v.id}`;
                const link = v.book_type === 1 ? pdfLink : normalLink;
                return (
                  <div
                    style={{
                      display: "flex",
                      flexGrow: 1,
                      maxWidth: "120px",
                      justifyContent: "center",
                      alignItems: "center",
                      // width : "calc((100vw - 40px)/3)"
                    }}
                  >
                    <UiBook
                      setIsModal_sub={setIsModal_sub}
                      isEdit={isEdit_b}
                      setSelectData={setSelectData_b}
                      selectData={selectData_b}
                      id={i}
                      key={"mark_folder_" + i}
                      data={v}
                      isOuth={v["type"]}
                      inStatus={v["link_status"]}
                      navigate={navigate}
                      basicBooks={basicBooks}
                      navi={link}
                      params={params}
                      isShare={params["who"] === "teacher"}
                      isDefault={v.type === 11}
                    />
                  </div>
                );
              })}
            </div>
          </div>
          {isModal_f && (
            <Modal_
              title={"폴더 추가"}
              placeholder={"폴더명을 입력해주세요."}
              setIsModal={setIsModal_f}
              userInfo={userInfo}
            />
          )}
          {isModal_b && (
            <Modal_b
              userInfo={userInfo}
              fileObj={fileObj}
              setFileObj={setFileObj}
              title={"교재 생성"}
              placeholder={"교재명을 입력해주세요."}
              setIsModal={setIsModal_b}
            />
          )}
          {isModal_sub["id"] && (
            <UiBook_sub_modal
              userInfo={userInfo}
              setIsModal={setIsModal_sub}
              data={isModal_sub}
              setIsModal_sub={setIsModal_sub}
            />
          )}
        </div>

        <div style={{ height: 0, width: 0, position: "absolute", bottom: 0, top: "100%" }}>
          <Draggable>
            <button
              onClick={() => {
                setShowPdfUploadModal(true);
              }}
              style={{
                border: "none",
                width: 72,
                height: 72,
                translate: "calc(375%) -150px",
                borderRadius: 12,
                // height:"52px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                // boxShadow: "1px 1px 2px 2px rgba(90 ,90 ,90 , 0.2)",
                backgroundColor: style.common.s_color,
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                  height: "100%",
                  color: "white",
                }}
              >
                <PictureAsPdf />
                <div style={{ fontSize: 12, fontWeight: 500, marginTop: 6 }}>PDF 교재 생성</div>
              </div>
            </button>
          </Draggable>
        </div>
        {userInfo.type === 2 && showPdfUploadModal && (
          <PdfUploadModal open={showPdfUploadModal} onClose={() => setShowPdfUploadModal(false)} />
        )}
      </Frame>
    </>
  );
};

export default Book_Main;
