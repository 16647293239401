import React, { createContext, useContext, useEffect, useState } from "react";
import { io, Socket } from "socket.io-client";
import constants from "../../asset/constants";

const SEMOHAE_SOCKET_URL = constants.semohaeUrl;
const NODE_SOCKET_URL = constants.apiUrl;

interface SocketContextProps {
  semohaeSocket: Socket | null;
  nodeSocket: Socket | null;
}

const SocketContext = createContext<SocketContextProps>({ semohaeSocket: null, nodeSocket: null });

export const useSocket = () => useContext(SocketContext);

export const SocketProvider: React.FC<{ children: React.ReactNode; userId: string }> = ({
  children,
  userId,
}) => {
  const [semohaeSocket, setSemohaeSocket] = useState<Socket | null>(null);
  const [nodeSocket, setNodeSocket] = useState<Socket | null>(null);

  useEffect(() => {
    const semohaeSocketInstance = io(SEMOHAE_SOCKET_URL, {
      transports: ["websocket"],
      reconnection: true,
      reconnectionAttempts: 5,
      reconnectionDelay: 1000,
      reconnectionDelayMax: 5000,
      randomizationFactor: 0.5,
      timeout: 1000,
      autoConnect: true,
      path: "/ws",
    });

    semohaeSocketInstance.on("connect", () => {
      console.log("Connected to socket server");
    });

    semohaeSocketInstance.on("disconnect", (reason) => {
      console.log(`Disconnected: ${reason}`);
    });

    semohaeSocketInstance.on("connect_error", (error) => {
      console.error(`Connection error: ${error}`);
    });

    setSemohaeSocket(semohaeSocketInstance);

    return () => {
      semohaeSocketInstance.disconnect();
    };
  }, []);
  //
  // useEffect(() => {
  //   if (userId) {
  //     const nodeSocketInstance = io(NODE_SOCKET_URL, {
  //       path: "/sio",
  //       reconnection: true,
  //       reconnectionAttempts: 5,
  //       reconnectionDelay: 1000,
  //       reconnectionDelayMax: 5000,
  //       randomizationFactor: 0.5,
  //       timeout: 1000,
  //       autoConnect: true,
  //     });
  //
  //     nodeSocketInstance.on("connect", () => {
  //       console.log("node Connected to node socket server");
  //       nodeSocketInstance.emit("hello", { userid: userId });
  //     });
  //
  //     nodeSocketInstance.on("disconnect", (reason) => {
  //       console.log(`node Disconnected: ${reason}`);
  //     });
  //
  //     nodeSocketInstance.on("connect_error", (error) => {
  //       console.error(`node Connection error: ${error}`);
  //     });
  //
  //     setNodeSocket(nodeSocketInstance);
  //
  //     return () => {
  //       nodeSocketInstance.disconnect();
  //     };
  //   }
  // }, [userId]);

  return (
    <SocketContext.Provider value={{ semohaeSocket, nodeSocket }}>
      {children}
    </SocketContext.Provider>
  );
};
