import { useSelectSharedLecture, useUserQuestionSummary } from "api/people";
import { PaymentContext } from "asset/context";
import { getUser } from "asset/storage";
import Section from "components/atoms/Section";
import dayjs from "dayjs";
import React, { useContext, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router";
import student_ from "../../asset/Img/student_.png";
import teacher_ from "../../asset/Img/teacher_.png";
import constants from "../../asset/constants";
import util from "../../asset/util";
import UiMemo from "../../uiComponents/UiMemo/UiMemo";

interface Modal_peopleProps {
  data: any;
  setFileObj: any;
  setIsModal: any;
  setHiddenUrl: any;
  setQnaData: any;
}

const Modal_people = ({
  data,
  setFileObj,
  setIsModal,
  setHiddenUrl,
  setQnaData,
}: Modal_peopleProps) => {
  const params = useParams();
  const navigate = useNavigate();
  const userInfo = getUser();

  const [tab, setTab] = useState("basic");

  const { academyId, setSelectedTeacherId } = useContext(PaymentContext);

  const isTeacher = useMemo(() => data?.user_type === 2, [data?.user_type]);

  const { data: questionData } = useUserQuestionSummary({
    userId: userInfo.id,
    targetId: data.id,
    userType: data.user_type,
  });

  const { data: sharedLectureByTarget } = useSelectSharedLecture(data.id);
  const { data: sharedLectureByMe } = useSelectSharedLecture(userInfo.id);

  const {
    unAnsweredQuestions,
    questionsWithMe,
    allQuestions,
    averageResponseTime,
    academyEnrollDate,
  } = useMemo(() => {
    return {
      unAnsweredQuestions: questionData?.questionInfo.un_answered_questions,
      questionsWithMe: questionData?.questionInfo.questions_with_me,
      allQuestions: questionData?.questionInfo.all_questions,
      averageResponseTime: questionData?.questionInfo.average_response_time,
      academyEnrollDate: questionData?.questionInfo.academy_enroll_date,
    };
  }, [questionData]);

  const unAnsweredQuestionsColor = useMemo(() => {
    if (!unAnsweredQuestions || unAnsweredQuestions <= 10) {
      return "#A8E2B8";
    } else if (unAnsweredQuestions <= 50) {
      return "#FCDB92";
    } else {
      return "#F87A7A";
    }
  }, [unAnsweredQuestions]);

  const unAnsweredQuestionsText = useMemo(() => {
    if (!unAnsweredQuestions || unAnsweredQuestions <= 10) {
      return "지금 질문을 하면 빠르게 답변을 받을 수 있어요.";
    } else if (unAnsweredQuestions <= 50) {
      return "선생님의 답변을 주실 때까지 조금 시간이 걸릴수 있어요.";
    } else {
      return "선생님의 답변이 많이 밀려있어요.";
    }
  }, [unAnsweredQuestions]);

  // 선생님과 학생이 함께 공유 중인 수업을 찾아서 저장
  const sharingLecture = useMemo(() => {
    if (!sharedLectureByTarget || !sharedLectureByMe) {
      return [];
    }
    const matchedLecture = sharedLectureByTarget.filter((targetLecture) =>
      sharedLectureByMe.some(
        (myLecture) => targetLecture.cid === myLecture.cid && targetLecture.status === 1
      )
    );
    return matchedLecture;
  }, [sharedLectureByTarget, sharedLectureByMe]);

  const apiPro_img = async (e, data) => {
    try {
      setFileObj({
        name: ``,
        file: e,
      });
      setQnaData({
        tid: data["id"],
      });
      setHiddenUrl(window.location.pathname);
      navigate(`/crop/${params["who"]}/qnaRegi`);
    } catch (e) {
      console.log(e);
    }
  };

  const changeToTimeFormat = (time: number) => {
    if (!time) {
      return "0시간 0분";
    }
    const changeToHour = (time / (1000 * 60 * 60)) % 24;
    // 시간이 소수점으로 나오는 경우
    if (
      Number.isFinite(changeToHour) &&
      Number(changeToHour) === changeToHour &&
      changeToHour % 1 !== 0
    ) {
      const hours = Math.floor(changeToHour);
      const minutes = Math.round((changeToHour - hours) * 60);
      return `${hours}시간 ${minutes}분`;
    } else {
      return `${changeToHour}시간`;
    }
  };

  const people_style = {
    title: {
      fontWeight: 700,
      fontSize: "16px",
      color: "#3D3A39",
      marginTop: "5px",
    },
    desc: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      fontWeight: 400,
      fontSize: "14px",
      color: "#3D3A39",
      marginTop: "5px",
      marginBottom: "10px",
    },
    btn: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "25px",
      borderRadius: "15px",
      padding: "5px 15px",
      fontSize: "14px",
      color: "#FFFFFF",
      fontWeight: 400,
      margin: "0px 5px",
    },
  };

  const tabInnerContent = () => {
    switch (tab) {
      case "basic":
        return (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              flex: 1,
              alignItems: "center",
              gap: "20px",
            }}
          >
            <Section direction="column" align="center">
              <div
                style={{
                  width: "120px",
                  height: "120px",
                  borderRadius: "50%",
                  marginBottom: "10px",
                }}
              >
                {isTeacher ? (
                  // 선생님 프로필 이미지
                  <img
                    style={{
                      marginRight: "20px",
                      border: "1px solid #ADDADD",
                      borderRadius: "50%",
                      width: "100%",
                      height: "100%",
                    }}
                    onError={(e) => {
                      e.currentTarget.src = teacher_;
                    }}
                    src={
                      data
                        ? data["img"]
                          ? constants.s3_url + `/teacher/${data["img"]}` + ".jpg?"
                          : constants.s3_url +
                            `/teacher/${data["id"].normalize("NFD")}` +
                            ".jpg?" +
                            util.getRandomInt(1000, 9999)
                        : teacher_
                    }
                  />
                ) : (
                  // 학생 프로필 이미지
                  <img
                    style={{
                      border: "1px solid #F7B865",
                      borderRadius: "50%",
                      width: "100%",
                      height: "100%",
                    }}
                    onError={(e) => {
                      e.currentTarget.src = student_;
                    }}
                    src={
                      data
                        ? data["img"]
                          ? constants.s3_url + `/student/${data["img"]}` + ".jpg?"
                          : constants.s3_url +
                            `/student/${data["id"].normalize("NFD")}` +
                            ".jpg?" +
                            util.getRandomInt(1000, 9999)
                        : student_
                    }
                  />
                )}
              </div>
              <div style={people_style.title}>
                {isTeacher
                  ? `${data["name"]} 선생님`
                  : params["who"] == "student"
                    ? `${data["nick"]} 학생`
                    : `${data["name"]} (${data["nick"]}) 학생`}
              </div>
              {data.intro && (
                <div
                  style={{
                    ...people_style.desc,
                    maxWidth: "260px",
                    marginTop: "10px",
                  }}
                >
                  {data["intro"]}
                </div>
              )}
            </Section>
            {/* 선생님 */}
            {isTeacher ? (
              <>
                <Section direction="column" align="center">
                  <div
                    style={{
                      width: "80px",
                      height: "80px",
                      borderRadius: "50%",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      color: "#FFFFFF",
                      fontWeight: 400,
                      fontSize: "14px",
                      backgroundColor: unAnsweredQuestionsColor,
                      marginBottom: "10px",
                    }}
                  >
                    <div>미답변수</div>
                    <div>
                      {unAnsweredQuestions &&
                        (unAnsweredQuestions > 100 ? 99 + "+" : unAnsweredQuestions)}
                    </div>
                  </div>
                  <div style={people_style.desc}>{unAnsweredQuestionsText}</div>
                </Section>
                <Section direction="column" align="center">
                  <div
                    style={{
                      ...people_style.desc,
                    }}
                  >
                    <div
                      style={{
                        color: "#B0B0B0",
                        marginRight: "3px",
                      }}
                    >
                      전체 질문수
                    </div>
                    <div
                      style={{
                        fontWeight: 700,
                        color: "#3D3A39",
                        marginLeft: "3px",
                        marginBottom: "1px",
                      }}
                    >
                      {allQuestions && allQuestions.toLocaleString()}
                    </div>
                  </div>
                  <div
                    style={{
                      ...people_style.desc,
                    }}
                  >
                    <div
                      style={{
                        color: "#B0B0B0",
                        marginRight: "3px",
                      }}
                    >
                      전체 답변수
                    </div>
                    <div
                      style={{
                        fontWeight: 700,
                        color: "#3D3A39",
                        marginLeft: "3px",
                        marginBottom: "1px",
                      }}
                    >
                      {allQuestions &&
                        unAnsweredQuestions &&
                        (allQuestions - unAnsweredQuestions).toLocaleString()}
                    </div>
                  </div>
                  <div
                    style={{
                      ...people_style.desc,
                    }}
                  >
                    <div
                      style={{
                        color: "#B0B0B0",
                        marginRight: "3px",
                      }}
                    >
                      평균 응답 시간
                    </div>
                    <div
                      style={{
                        fontWeight: 700,
                        color: "#3D3A39",
                        marginLeft: "3px",
                        marginBottom: "1px",
                      }}
                    >
                      {changeToTimeFormat(averageResponseTime)}
                    </div>
                  </div>
                </Section>
              </>
            ) : (
              // 학생
              <>
                <Section direction="column" align="center">
                  <div style={people_style.title}>{`누적 활동`}</div>
                  <div
                    style={{
                      marginTop: "10px",
                    }}
                  >
                    <div style={people_style.desc}>
                      전체 질문수
                      <div
                        style={{
                          color: "#FFAE43",
                          marginBottom: "1px",
                          marginLeft: "3px",
                        }}
                      >
                        {allQuestions && allQuestions.toLocaleString()}
                      </div>
                    </div>
                    <div style={people_style.desc}>
                      나와의 질문 수
                      <div
                        style={{
                          color: "#FFAE43",
                          marginBottom: "1px",
                          marginLeft: "3px",
                        }}
                      >
                        {questionsWithMe && questionsWithMe.toLocaleString()}
                      </div>
                    </div>
                  </div>
                </Section>
                {academyEnrollDate && (
                  <Section direction="column" align="center">
                    <div style={people_style.title}>{"학원 가입일"}</div>
                    <div
                      style={{
                        marginTop: "10px",
                      }}
                    >
                      <div style={people_style.desc}>
                        {dayjs.unix(academyEnrollDate).format("YYYY년 MM월 DD일")}
                      </div>
                    </div>
                  </Section>
                )}
              </>
            )}
          </div>
        );
      case "memo":
        // 선생님
        return isTeacher ? (
          <div
            style={{
              display: "flex",
              flex: 1,
              flexDirection: "column",
              marginBottom: "15px",
            }}
          >
            <UiMemo isInput={true} lid={data["id"]} userInfo={data} />
          </div>
        ) : (
          // 학생
          <div
            style={{
              display: "flex",
              height: "100%",
              flex: 1,
              flexDirection: "column",
              marginBottom: "15px",
            }}
          >
            <UiMemo isInput={true} lid={data["id"]} userInfo={userInfo} />
          </div>
        );
      case "lecture":
        return (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              flex: 1,
              padding: "5px 10px",
            }}
          >
            {sharingLecture?.length == 0 || sharingLecture === undefined ? (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  fontSize: "16px",
                  fontWeight: "bold",
                  marginTop: "30px",
                }}
              >
                참여중인 수업이 없습니다.
              </div>
            ) : (
              <div>
                <div
                  style={{
                    fontSize: "14px",
                    fontWeight: 700,
                    marginBottom: "10px",
                  }}
                >
                  {`총 ${sharingLecture?.length}개 수강 중`}
                </div>
                {sharingLecture?.map((v, i) => <ShareSection index={i} data={v} />)}
              </div>
            )}
          </div>
        );
    }
  };
  return (
    <div
      style={{
        position: "fixed",
        left: 0,
        top: 0,
        zIndex: 130002,
        backgroundColor: "rgba(150, 150, 150,0.5)",
        width: "100%",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
      onClick={() => {
        util.postWeb("isModal_off");
        setIsModal(false);
      }}
    >
      <div
        style={{
          width: "350px",
          minHeight: "60%",
          maxHeight: "75%",
          backgroundColor: "white",
          padding: "25px 20px 40px 10px",
          borderRadius: 10,
          border: "1px solid rgb(200, 200, 200)",
          display: "flex",
          flexDirection: "column",
          filter: "drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.37))",
        }}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginBottom: "10px",
          }}
        >
          <div
            style={{
              borderBottom: tab == "basic" ? "1px solid" : "none",
              borderBottomColor: "#F09294",
              color: "#F09294",
              fontSize: "16px",
              padding: "5px 0px",
              width: "110px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "35px",
              fontWeight: 500,
            }}
            onClick={() => {
              setTab("basic");
            }}
          >
            기본 정보
          </div>
          {/* 학생 모달일 경우만 메모장 기능 존재 */}
          {isTeacher || (
            <div
              style={{
                borderBottom: tab == "memo" ? "1px solid" : "none",
                borderBottomColor: "#5EA3C6",
                color: "#5EA3C6",
                fontSize: "16px",
                padding: "5px 0px",
                width: "110px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "35px",
                fontWeight: 500,
              }}
              onClick={() => {
                setTab("memo");
              }}
            >
              메모장
            </div>
          )}
          <div
            style={{
              borderBottom: tab == "lecture" ? "1px solid" : "none",
              borderBottomColor: "#F7B865",
              padding: "5px 0px",
              width: "110px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "35px",
              color: "#F7B865",
              fontSize: "16px",
              fontWeight: 500,
            }}
            onClick={() => {
              setTab("lecture");
            }}
          >
            공유중인 수업
          </div>
        </div>
        <div style={{ overflowY: "auto", marginBottom: "20px", marginTop: "20px" }}>
          {tabInnerContent()}
        </div>
        <div
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "center",
            marginTop: "auto",
          }}
        >
          {params["who"] == "teacher" ? (
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "center",
              }}
            >
              <div
                style={{
                  ...people_style.btn,
                  backgroundColor: "#F5B3B5",
                }}
                onClick={() => {
                  setFileObj({
                    base64: "null",
                  });
                  navigate(`/searchresult/${params["who"]}/see&${data["id"]}/de`);
                }}
              >
                질문보기
              </div>
              <div
                style={{
                  ...people_style.btn,
                  backgroundColor: "#5EA3C6",
                }}
                onClick={() => {
                  navigate("/myinforeview/myInfo&" + data["id"]);
                }}
              >
                리뷰보기
              </div>
              <div
                style={{
                  ...people_style.btn,
                  backgroundColor: "#F7B865",
                }}
                onClick={() => {
                  if (params["who"] == "teacher") {
                    navigate(`/talk/${params["who"]}/0/0/${data["id"]}/${userInfo["id"]}`);
                  } else {
                    navigate(`/talk/${params["who"]}/0/0/${userInfo["id"]}/${data["id"]}`);
                  }
                }}
              >
                대화하기
              </div>
            </div>
          ) : (
            <div>
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "center",
                }}
              >
                <div
                  style={{
                    ...people_style.btn,
                    backgroundColor: "#F5B3B5",
                  }}
                  onClick={() => {
                    setFileObj({
                      base64: "null",
                    });
                    navigate(`/searchresult/${params["who"]}/see&${data["id"]}/de`);
                  }}
                >
                  질문보기
                </div>
                <div
                  style={{
                    ...people_style.btn,
                    backgroundColor: "#5EA3C6",
                  }}
                  onClick={() => {
                    navigate("/myinforeview/myInfo&" + data["id"]);
                  }}
                >
                  리뷰보기
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "center",
                  marginTop: "15px",
                }}
              >
                <div
                  style={{
                    ...people_style.btn,
                    backgroundColor: "#A8E2B8",
                  }}
                  onClick={() => {
                    setSelectedTeacherId(data["id"]);
                    navigate(`/searchresult/student/student/p/${data["id"]}/${academyId}`);
                  }}
                >
                  {/* <input
                    onChange={(e) => {
                      if (e.target.files.length <= 1) {
                        apiPro_img(e, data);
                      } else {
                        setFileObj(e.target.files);
                        setQnaData({
                          tid: data["id"],
                        });
                        setHiddenUrl(window.location.pathname);
                        navigate(`/searchresult/student/student/b`);
                      }
                    }}
                    type="file"
                    style={{ display: "none" }}
                    id="qna_btn"
                    multiple
                    accept={"image/jpeg"}
                  /> */}
                  <label htmlFor="qna_btn">질문 하기</label>
                </div>
                <div
                  style={{
                    ...people_style.btn,
                    backgroundColor: "#F7B865",
                  }}
                  onClick={() => {
                    if (params["who"] == "teacher") {
                      navigate(`/talk/${params["who"]}/0/0/${data["id"]}/${userInfo["id"]}`);
                    } else {
                      navigate(`/talk/${params["who"]}/0/0/${userInfo["id"]}/${data["id"]}`);
                    }
                  }}
                >
                  대화하기
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

// 공유 중인 수업
const ShareSection = ({ index, data }) => {
  return (
    <div
      style={{
        padding: "14px 5px",
        borderBottom: "1px solid #F6F6F6",
        backgroundColor: index % 2 == 0 ? "#FFFBF5" : "#FBFBFB",
        marginBottom: "2px",
        borderRadius: "5px",
      }}
    >
      <div
        style={{
          display: "flex",
          fontSize: "14px",
          fontWeight: 400,
        }}
      >
        <div style={{}}>{data["class_name"]}</div>
        <div
          style={{
            color: "#B0B0B0",
            marginLeft: "5px",
          }}
        >
          {`| ${data["classroom_name"]}`}
        </div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: "7px",
        }}
      >
        <div>
          <div
            style={{
              fontSize: "12px",
              fontWeight: 400,
              color: "#B0B0B0",
            }}
          >
            {data["manager_name"] &&
              (data["teacher_count"] == 0 ? (
                <></>
              ) : data["teacher_count"] == 1 ? (
                `${data["manager_name"]} 선생님`
              ) : (
                `${data["manager_name"]} 선생님 외 ${data["teacher_count"] - 1}명`
              ))}
          </div>
        </div>
        <div>
          <div
            style={{
              fontSize: "12px",
              fontWeight: 400,
              color: "#B0B0B0",
            }}
          >
            {`수강인원 ${data["student_count"]}명`}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modal_people;
