import React, { Dispatch, createContext, useMemo, useState } from "react";

export interface StudyBookContextState {
  isVisibleAnswer: boolean;
  toggleAnswer?: () => void;
  questionSlideIndex?: number | "";
  setQuestionSlideIndex?: Dispatch<React.SetStateAction<number | "">>;
  topHeight: number;
  bottomHeight: number;
  containerHeight: number;
  setHeightConfig?: Dispatch<
    React.SetStateAction<{ topHeight: number; bottomHeight: number; containerHeight: number }>
  >;
}

export const StudyBookContext = createContext<StudyBookContextState>({
  isVisibleAnswer: false,
  topHeight: 0,
  bottomHeight: 0,
  containerHeight: 0,
});

const StudyBookContextProvider = ({ children }) => {
  const [isVisibleAnswer, setIsVisibleAnswer] = useState<boolean>(false);
  const [questionSlideIndex, setQuestionSlideIndex] = useState<number | "">(1);

  const [{ topHeight, bottomHeight, containerHeight }, setHeightConfig] = useState({
    topHeight: 0,
    bottomHeight: 0,
    containerHeight: 0,
  });
  const actions = useMemo(() => {
    const toggleAnswer = () => {
      setIsVisibleAnswer((prev) => !prev);
    };
    return {
      isVisibleAnswer,
      toggleAnswer,
      questionSlideIndex,
      setQuestionSlideIndex,
      topHeight,
      bottomHeight,
      containerHeight,
      setHeightConfig,
    };
  }, [
    setIsVisibleAnswer,
    isVisibleAnswer,
    questionSlideIndex,
    setQuestionSlideIndex,
    topHeight,
    bottomHeight,
    containerHeight,
    setHeightConfig,
  ]);

  return <StudyBookContext.Provider value={actions}>{children}</StudyBookContext.Provider>;
};
export default StudyBookContextProvider;
