import React from "react"

import {ExpandMore} from "@material-ui/icons";
import styled from "styled-components";
import {Accordion, AccordionDetails, AccordionSummary} from "@mui/material";
import {Link} from "react-router-dom";
import Header from "./components/Header";

const ContactUs = () => {
    return (
        <ContactUsContainer>
          <Header/>
          <ContentBox>
            <Accordion>
              <AccordionSummaryStyle  expandIcon={<ExpandMore/>}>
                회원 탈퇴 방법 안내
              </AccordionSummaryStyle>
              <AccordionDetails>
                <div>
                  <h4>로그인한 상태</h4>
                  <p>
                    1. 로그인한 상태에서 하단 더보기 탭을 클릭합니다.
                  </p>
                  <p>
                    2. 내정보를 클릭합니다.
                  </p>
                  <p>
                    3. 회원탈퇴 버튼을 클릭합니다.
                  </p>
                </div>
                <div>
                  <h4>비 로그인 상태</h4>
                  <p>
                    아래 버튼을 클릭합니다.
                  </p>
                  <DeactiveAccountButton to={'/deactivate-account'}>회원 탈퇴</DeactiveAccountButton>
                </div>
              </AccordionDetails>
            </Accordion>
          </ContentBox>
        </ContactUsContainer>
    )
}

export default ContactUs

const ContactUsContainer = styled.div`
  width: 100%;
`
const AccordionSummaryStyle = styled(AccordionSummary)`
    display: flex;
    font-weight: 700;
    color: #1a1a1a;
`

const DeactiveAccountButton = styled(Link)`
  background: #f5b21a;
  padding: 8px;
  border-radius: 8px;
  color: #FFF;
`
const ContentBox = styled.div`
  padding: 12px;
`