import { React , useState , useEffect } from 'react'
import constants from '../../asset/constants'
import style from '../../asset/style'
import util from '../../asset/util'
import UiVideo_Style from './UiVideo_Style'
import de_column from '../../asset/Img/de_column.png'

const UiVideo = ({
    desc = "",
    fnClick,
    data = [1,2,3]
}) => {

    return (
        <div
            style={UiVideo_Style.c}
            onClick={()=>{
                fnClick(data)
            }}
        >
            <div style={{
                ...UiVideo_Style.video_w,
                border : "1px solid",
                borderColor : style.common.borderColor4
            }}>
                <img
                    style={{
                        width : '100%',
                        // height : '100%'
                        objectFit:'cover',
                        objectPosition:"center",
                    }}
                    // src={isTeacher ? face_teacher : face_student}
                    onError={(e)=>{
                        // setIsLoad(false)
                        e.currentTarget.src = de_column
                    }}
                    src={
                        data["video_link"] ?
                        (
                            data["video_link"].includes("watch") ?
                            `http://img.youtube.com/vi/${data["video_link"].split("=")[1].split("&")[0]}/mqdefault.jpg`
                            :
                            `http://img.youtube.com/vi/${data["video_link"].split("youtu.be/")[1].split("&")[0]}/mqdefault.jpg`
                        )
                        :
                        (
                            // data["img"] ?
                            // constants.s3_url
                            // +
                            // '/public'
                            // +
                            // `/board/${data["img"]}`
                            // +
                            // '.jpg?'
                            // :
                            data["id"] ?
                            (
                            constants.s3_url
                            +
                            // '/public'
                            // +
                            `/board/${data["id"]}`
                            +
                            '.jpg?'
                            +
                            util.getRandomInt(1000,9999)
                            )
                            :
                            de_column
                        )
                    }
                />
            </div>
            <div style={{
                ...UiVideo_Style.desc,
                color : style.common.fontColor_5,
                padding : "10px 0px 20px 0px",
                display:"flex",
                justifyContent:"center",
            }}
                // dangerouslySetInnerHTML={{__html : util.textTrim(desc,25) }}
            >

                {util.textTrim(data["title"],25)}
            </div>
        </div>
    )
}

export default UiVideo
