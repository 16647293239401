import constants from "asset/constants";
import axios, { AxiosProgressEvent } from "axios";

export interface uploadFileToStorageOptions {
  fileLocation: string;
  file: File;
  onUploadProgress?: (progressEvent: AxiosProgressEvent) => void;
}
export const uploadPdfFileToStorage = async ({
  fileLocation,
  file,
  onUploadProgress,
}: uploadFileToStorageOptions) => {
  try {
    const response = await axios.get(`${constants.apiUrl}/bucket/pdf/signedUrl`, {
      params: { fileName: fileLocation },
      headers: { accept: "application/json" },
    });
    await uploadStorage({ response, onUploadProgress, fileLocation, file });
  } catch (error) {
    throw error;
  }
};

export const uploadVideoFileToStorage = async ({
  fileLocation,
  file,
  onUploadProgress,
}: uploadFileToStorageOptions) => {
  try {
    const response = await axios.get(`${constants.apiUrl}/bucket/video/signedUrl`, {
      params: { fileName: fileLocation },
      headers: { accept: "application/json" },
    });
    await uploadStorage({ response, onUploadProgress, fileLocation, file });
  } catch (error) {
    throw error;
  }
};
export const uploadToStorage = async ({
  fileLocation,
  file,
  onUploadProgress,
}: uploadFileToStorageOptions) => {
  try {
    const response = await axios.get(`${constants.apiUrl}/bucket/upload/signedUrl`, {
      params: { fileName: fileLocation },
      headers: { accept: "application/json" },
    });
    await uploadStorage({ response, onUploadProgress, fileLocation, file });
  } catch (error) {
    throw error;
  }
};

const uploadStorage = async ({ response, onUploadProgress, fileLocation, file }) => {
  try {
    const { signedUrl } = response.data;

    // Perform the file upload using Axios
    const uploadResponse = await axios.put(signedUrl, file, {
      headers: { "Content-Type": "application/octet-stream" },
      onUploadProgress,
    });

    if (uploadResponse.status === 200) {
      console.log("업로드를 완료했습니다.", fileLocation);
      return fileLocation;
    } else {
      console.error(uploadResponse);
      alert("업로드에 실패했습니다.");
      throw new Error(`Upload failed with status: ${uploadResponse.status}`);
    }
  } catch (error) {
    console.error(error);
    alert("업로드에 실패했습니다.");
    throw error;
  }
};
