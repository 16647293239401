import { useStudyBookFolderRegist } from "api/studyBook";
import CommonModal from "components/atoms/CommonModal";
import { FontStyleMap } from "components/atoms/Font";
import HorizontalBlank from "components/atoms/HorizontalBlank";
import ReactiveButtonContainer from "components/atoms/ReactiveButtonContainer";
import { StyledCommonConfirmContainer } from "components/templates/Confirm";
import { StyledInput } from "pages/StudyBookDetail/components/PdfUploadModal/components/InputArea";
import React, { useCallback, useState } from "react";
import styled from "styled-components";

const FolderRegistModal = ({ open, onClose }: CommonModalControl) => {
  const { mutateAsync } = useStudyBookFolderRegist();
  const [folderName, setFolderName] = useState<string>("");
  const handleRegist = useCallback(() => {
    mutateAsync(folderName);
    onClose();
  }, [folderName]);

  return (
    <CommonModal open={open} onClose={onClose}>
      <Container>
        <TitleFont>폴더 추가</TitleFont>
        <DescriptionFont>추가할 폴더명을 입력해주세요.</DescriptionFont>
        <HorizontalBlank height={16} />
        <StyledInput
          label="폴더명"
          placeholder="폴더명을 입력해주세요."
          size={"small"}
          onChange={(event) => setFolderName(event.target.value)}
        />
        <HorizontalBlank height={32} />
        <ReactiveButtonContainer
          buttonList={[
            {
              title: "취소",
              variant: "text",
              onClick: () => {
                onClose();
              },
            },
            {
              title: "확인",
              onClick: handleRegist,
            },
          ]}
        />
      </Container>
    </CommonModal>
  );
};

export const Container = styled(StyledCommonConfirmContainer)`
  padding: 16px;
`;

export const TitleFont = styled.div`
  color: var(--neutral-color-natural-80, #141414);
  ${FontStyleMap["KR/bold/$KR.TS.BOL.20"]}
`;

export const DescriptionFont = styled.div`
  margin-top: 16px;
  color: var(--neutral-color-natural-60, #434343);
  word-break: keep-all;
  ${FontStyleMap["KR/regular/$KR.BM.REG.14"]}
`;

export default FolderRegistModal;
