module.exports = [
  { "name": "기업은행", "code": "INDUSTRIAL_BANK_OF_KOREA" },
  { "name": "국민은행", "code": "KOOKMIN_BANK" },
  { "name": "NH농협은행", "code": "NH_NONGHYUP_BANK" },
  { "name": "우리은행", "code": "WOORI_BANK" },
  { "name": "광주은행", "code": "GWANGJU_BANK" },
  { "name": "우체국", "code": "EPOST" },
  { "name": "하나은행", "code": "HANA_BANK" },
  { "name": "신한은행", "code": "SHINHAN_BANK" },
  { "name": "케이뱅크", "code": "K_BANK" },
]

// { "name": "SC제일은행", "code": "SC_BANK_KOREA" },
// { "name": "한국씨티은행", "code": "CITI_BANK_KOREA" },
// { "name": "대구은행", "code": "DAEGU_BANK" },
// { "name": "부산은행", "code": "BUSAN_BANK" },
// { "name": "제주은행", "code": "JEJU_BANK" },
// { "name": "전북은행", "code": "JEONBUK_BANK" },
// { "name": "경남은행", "code": "KYONGNAM_BANK" },
// { "name": "새마을금고", "code": "KFCC" },
// { "name": "신협", "code": "SHINHYUP" },
// { "name": "카카오뱅크", "code": "KAKAO_BANK" },
// { "name": "토스뱅크", "code": "TOSS_BANK" }
// { "name": "수협은행", "code": "SUHYUP_BANK" },