import React from "react";

import "./UiCheckBox.css";
import UiCheckBoxStyle from "./UiCheckBoxStyle";

type UiCheckBoxProps = {
  className?: string;
  value?: string;
  fnCheck?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  fnClick?: (e: React.MouseEvent<HTMLInputElement>) => void;
  readOnly?: boolean;
  checked?: boolean;
  containerStyle?: React.CSSProperties;
  labeStyle?: React.CSSProperties;
  checkBoxStyle?: React.CSSProperties;
  defaultChecked?: boolean;
};
const UiCheckBox = ({
  className,
  value,
  fnCheck,
  fnClick,
  readOnly,
  containerStyle,
  labeStyle,
  checkBoxStyle,
  defaultChecked,
}: UiCheckBoxProps) => {
  return (
    <div
      style={{
        ...UiCheckBoxStyle.container,
        ...containerStyle,
        flexDirection: "row",
      }}
    >
      <input
        disabled={readOnly ? readOnly : false}
        className={[className, UiCheckBoxStyle.checkbox].join(" ")}
        type="checkbox"
        id={value}
        checked={defaultChecked}
        onChange={fnCheck}
        onClick={(e) => {
          if (fnClick) {
            fnClick(e);
          }
        }}
        style={{
          ...checkBoxStyle,
        }}
      ></input>
      <label
        style={{
          ...labeStyle,
        }}
        htmlFor={value}
      ></label>
    </div>
  );
};

const UiCheckBoxList = ({ list, fnCheck, isColumn, containerStyle }) => {
  let createCheckBox = () => {
    try {
      return list.map((v, i) => (
        <UiCheckBox key={i} value={v.value} checked={v.checked} fnCheck={fnCheck} />
      ));
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <div
      style={{
        ...UiCheckBoxStyle.list_container,
        ...containerStyle,
        flexDirection: isColumn ? "column" : "row",
      }}
    >
      {createCheckBox()}
    </div>
  );
};

export { UiCheckBox, UiCheckBoxList };
