import { useStudyBookDelete } from "api/studyBook";
import { ReactComponent as IconTrash } from "asset/Img/icon_trash.svg";
import { ReactComponent as MoveIcon } from "asset/images/icon/move-to.svg";
import ItemSubMenu from "components/molecules/ItemSubMenu";
import { useConfirm } from "providers/ConfirmProvider";
import { showToast } from "providers/ToastProvider";
import React, { useCallback, useState } from "react";
import { CommonStudyBookItem } from "../context/StudyBookContext";
import StudyBookMoveModal from "./StudyBookMoveModal";
// import BookUpdateModal from "./BookUpdateModal";
type StudyBookSubMenuProps = {
  anchorElement?: HTMLElement | null;
  bookData: CommonStudyBookItem;
} & CommonModalControl;
const StudyBookSubMenu = ({ anchorElement, bookData, onClose, open }: StudyBookSubMenuProps) => {
  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);
  const handleUpdateModalClose = useCallback(() => {
    setIsUpdateModalOpen(false);
  }, [setIsUpdateModalOpen]);

  const { showConfirm } = useConfirm();
  const { mutateAsync } = useStudyBookDelete();

  const handleDelete = useCallback(() => {
    if (bookData?.id === 0) {
      showToast({
        message: "미지정 교재는 제거할 수 없습니다.",
        title: "교재 삭제",
        type: "warning",
      });
      return;
    } else if (bookData?.type === 11) {
      showToast({
        message: `${bookData.name}은 기본 교재 입니다.\n 수업 기본 교재 설정 해제 후 제거할 수 있습니다.`,
        title: "교재 삭제",
        type: "warning",
      });
    }
    showConfirm({
      message: "삭제된 교재는 휴지통에서 30일간 보관 후 영구삭제 됩니다.",
      title: "폴더 삭제",
      onConfirm() {
        mutateAsync(bookData?.id);
      },
    });
  }, [bookData]);

  return (
    <>
      <ItemSubMenu
        open={open}
        onClose={onClose}
        anchorElement={anchorElement}
        anchorOrigin={{ horizontal: "center", vertical: "top" }}
        style={{ transform: "translate(-7px, 36px)" }}
        menuList={[
          {
            icon: <MoveIcon />,
            text: "교재 이동",
            onClick: () => {
              setIsUpdateModalOpen(true);
              onClose();
            },
          },
          {
            icon: <IconTrash />,
            text: "교재 삭제",
            onClick: handleDelete,
          },
        ]}
      />
      <StudyBookMoveModal
        onClose={handleUpdateModalClose}
        open={isUpdateModalOpen}
        bookIds={[`${bookData?.id}`]}
      />
    </>
  );
};

export default StudyBookSubMenu;
