import { Add } from "@material-ui/icons";
import { React, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import apiFn from "../../asset/apiClass";
import style from "../../asset/style";
import util from "../../asset/util";
import Column_pick_Style from "../../pages/Column/Column_pick_Style";
import { Frame } from "../../pages/page_index";
import UiBtn from "../UiBtn/UiBtn";
import UiNoticeDetail_Style from "./UiNoticeDetail_Style";
import noBook from "../../asset/Img/book1.png";
import constants from "../../asset/constants";
// import '../../pages/Column/Column_detail.css'
import Academy_book_Style from "../../pages/Academy/Academy_book_Style";
import NoticeCommonItem from "./NoticeCommonItem";
import NoticeLectureItem from "./NoticeLectureItem";

const UiNoticeDetail = ({ userInfo, isPremium }) => {
  let navigate = useNavigate();
  let params = useParams();

  const [data, setData] = useState({});
  const [all_data, setAll_data] = useState([]);
  const [attach, setAttach] = useState({});

  const apiPro_read = async () => {
    try {
      let data = await apiFn.comApi({
        state: {
          table: "board",
          files: "y",
          page: 1,
          amount: 9999,
          sort: "important desc",
          ref: ["HIQDB_teacher.name|HIQDB_board.mid=HIQDB_teacher.id|tname"],
          or: {
            "HIQDB_board.cat_id": `='66'`, // 공지사항
            "HIQDB_board.cat_id#": `is null`, // 빈 카테고리
          },
          where: {
            "HIQDB_board.id": `='${params["id"]}'`,
          },
        },
      });

      // util.objLog(data["list"][0])
      // // console.log(data["list"])

      // if(data["list"][0]["files"].length > 0){
      //     let attachObj = {}
      //     data["list"][0]["files"].map((v,i)=>{
      //         attachObj[v["origin"]] = {
      //             file : v["id"],
      //             fileName : v["origin"],
      //             name : v["name"]
      //         }
      //     })
      //     setAttach({
      //         ...attachObj
      //     })
      // }

      setData({
        ...data["list"][0],
      });
    } catch (e) {
      // console.log(e)
    }
  };

  const apiPro_read_2 = async () => {
    try {
      const or_obj = {};
      let where_obj = {},
        ref = "";
      if (params["type"] == "book") {
        where_obj["HIQDB_board.bid"] = `='${params["id"]}'`;
      } else if (params.type === "lecture") {
        where_obj["HIQDB_board.cid"] = `='${params["id"]}'`;
        or_obj["HIQDB_board.cat_id"] = "= '66'";
        or_obj["HIQDB_board.cat_id#"] = "IS NULL";
      } else if (params.type === "lecture_video") {
        where_obj = {
          "HIQDB_board.cid": `='${params["id"]}'`,
          "HIQDB_board.cat_id": "= 7", // 수업 영상
        };
      }
      let data = await apiFn.comApi({
        state: {
          table: "board",
          files: "y",
          page: 1,
          amount: 9999,
          sort: "important desc",
          ref,
          or: Object.keys(or_obj).length === 0 ? undefined : or_obj,
          where: where_obj,
        },
      });

      // if(data["list"][0]["files"].length > 0){
      //     let attachObj = {}
      //     data["list"][0]["files"].map((v,i)=>{
      //         attachObj[v["origin"]] = {
      //             file : v["id"],
      //             fileName : v["origin"],
      //             name : v["name"]
      //         }
      //     })
      //     data["list"]
      //     // setAttach({
      //     //     ...attachObj
      //     // })
      // }
      // else {
      //     // setAttach({
      //     // })
      // }

      setAll_data([...data["list"]]);
    } catch (e) {}
  };

  useEffect(() => {
    if (params["type"].includes("&")) {
      apiPro_read();
    } else {
      apiPro_read_2();
      // if(params["type"].includes("de") || params["type"].includes("class") || params["type"].includes("book")){
      // }
      // else {
      //     apiPro_read_2();
      // }
    }
  }, []);

  return (
    <Frame
      userInfo={userInfo}
      isPremium={isPremium}
      title={
        <div style={UiNoticeDetail_Style.title_w}>
          <div style={UiNoticeDetail_Style.title_i}>
            <img
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
                objectPosition: "center",
                border: "1px solid",
                borderColor: style.common.borderColor4,
              }}
              // src={isTeacher ? face_teacher : face_student}
              onError={(e) => {
                // setIsLoad(false)
                e.currentTarget.src = noBook;
              }}
              src={
                params["id"]
                  ? params["type"] == "book"
                    ? constants.s3_url +
                      // ( constants.apiUrl.includes('dev.com') ? '/test' : '/public' )
                      // '/public'
                      // +
                      `/bookCover/${params["id"]}` +
                      // '/teacher/'+userInfo["id"].normalize('NFD')
                      ".jpg?" +
                      util.getRandomInt(1000, 9999)
                    : params["type"] == "class"
                      ? constants.s3_url +
                        // ( constants.apiUrl.includes('dev.com') ? '/test' : '/public' )
                        // '/public'
                        // +
                        `/board/${params["id"]}` +
                        // '/teacher/'+userInfo["id"].normalize('NFD')
                        ".jpg?" +
                        util.getRandomInt(1000, 9999)
                      : params["type"].includes("de")
                        ? constants.s3_url +
                          // ( constants.apiUrl.includes('dev.com') ? '/test' : '/public' )
                          // '/public'
                          // +
                          `/bookCover/${params["type"].split("&")[1]}` +
                          // '/teacher/'+userInfo["id"].normalize('NFD')
                          ".jpg?" +
                          util.getRandomInt(1000, 9999)
                        : params["type"].includes("class&")
                          ? constants.s3_url +
                            // ( constants.apiUrl.includes('dev.com') ? '/test' : '/public' )
                            // '/public'
                            // +
                            `/board/${params["type"].split("&")[1]}` +
                            // '/teacher/'+userInfo["id"].normalize('NFD')
                            ".jpg?" +
                            util.getRandomInt(1000, 9999)
                          : params["type"].includes("book&")
                            ? constants.s3_url +
                              // ( constants.apiUrl.includes('dev.com') ? '/test' : '/public' )
                              // '/public'
                              // +
                              `/bookCover/${params["type"].split("&")[1]}` +
                              // '/teacher/'+userInfo["id"].normalize('NFD')
                              ".jpg?" +
                              util.getRandomInt(1000, 9999)
                            : params["type"].includes("lecture&")
                              ? constants.s3_url +
                                // ( constants.apiUrl.includes('dev.com') ? '/test' : '/public' )
                                // '/public'
                                // +
                                `/board/${params["type"].split("&")[1]}` +
                                ".jpg?" +
                                util.getRandomInt(1000, 9999)
                              : params["type"].includes("lecture") &&
                                constants.s3_url +
                                  // ( constants.apiUrl.includes('dev.com') ? '/test' : '/public' )
                                  // '/public'
                                  // +
                                  `/board/${params["id"]}` +
                                  ".jpg?" +
                                  util.getRandomInt(1000, 9999)
                  : ""
              }
            />
          </div>
          <div>
            {params.type === "lecture_video" ? "수업 영상" : "공지사항"}
            <div style={UiNoticeDetail_Style.title_}>
              {params["type"].includes("class")
                ? "클래스룸에 대한 선생님의 공지입니다."
                : params["type"].includes("lecture")
                  ? "강의에 대한 선생님의 공지입니다."
                  : "교재에 관한 선생님의 공지입니다."}
            </div>
          </div>
        </div>
      }
      // isSearch={isEdit ? false : true}
      isSearch={false}
      isTap={false}
    >
      {params["type"] === "de" ||
      params["type"].includes("class&") ||
      params["type"].includes("lecture&") ||
      params["type"].includes("book&") ? (
        <UiNoticeSection
          params={params}
          userInfo={userInfo}
          data={data}
          apiPro_read={apiPro_read}
          apiPro_read_2={apiPro_read_2}
          attach={attach}
        />
      ) : (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            flex: 1,
            backgroundColor: style.common.borderColor2,
            marginTop: "20px",
          }}
        >
          {params["who"] == "teacher" ? (
            <div>
              <div
                style={{
                  ...Column_pick_Style.t_btn_w,
                  // margin : "20px 0px",
                  margin: "20px 5px 0px 5px",
                  width: "150px",
                }}
              >
                <UiBtn
                  btnStyle={Column_pick_Style.t_btn}
                  title={
                    <div style={Academy_book_Style.btn_title}>
                      <Add style={Academy_book_Style.btn_Add} />
                      <div
                        style={{
                          paddingLeft: "12px",
                        }}
                      >
                        등록하기
                      </div>
                    </div>
                    // <div style={{
                    //     width : "100%",
                    //     ...Column_pick_Style.t_btn_text,
                    //     paddingLeft : "5px"
                    // }}>
                    //     <Add
                    //         style={Column_pick_Style.t_btn_Add}
                    //     />
                    //     새 공지 작성
                    // </div>
                  }
                  fnClick={() => {
                    navigate(
                      `/write/${params["who"]}/${params.type === "lecture_video" ? 7 : "no"}/${params["id"]}/${params["type"]}/n`
                    );
                  }}
                />
              </div>
            </div>
          ) : (
            <div>
              <div
                style={{
                  ...Column_pick_Style.t_btn_w,
                  // margin : "20px 0px",
                  margin: "25px 5px 0px 5px",
                  // width : "150px"
                }}
              ></div>
            </div>
          )}
          {all_data.length > 0 ? (
            all_data.map((v, i) => {
              return (
                <div
                  style={{
                    width: "100%",
                  }}
                >
                  {v.cat_id === 7 ? (
                    <NoticeLectureItem
                      userInfo={userInfo}
                      data={v}
                      apiPro_read={apiPro_read}
                      apiPro_read_2={apiPro_read_2}
                      params={params}
                      attach={attach}
                    />
                  ) : (
                    <NoticeCommonItem
                      userInfo={userInfo}
                      data={v}
                      apiPro_read={apiPro_read}
                      apiPro_read_2={apiPro_read_2}
                      params={params}
                      attach={attach}
                    />
                  )}
                </div>
              );
            })
          ) : (
            <div
              style={{
                display: "flex",
                flex: 1,
                alignItems: "center",
                justifyContent: "center",
                fontSize: "16px",
                fontWeight: "bold",
              }}
            >
              등록된 공지사항이 없습니다.
            </div>
          )}
        </div>
      )}
    </Frame>
  );
};

export default UiNoticeDetail;
