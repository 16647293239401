import { Add, ArrowForwardIos, Create, HighlightOffOutlined } from '@material-ui/icons'
import photopick_logo from '../../asset/Img/photopick_logo.png'
import { Switch } from '@mui/material'
import { React , useState , useEffect } from 'react'
import { useNavigate, useParams } from 'react-router'
import apiFn from '../../asset/apiClass'
import constants from '../../asset/constants'
import style from '../../asset/style'
import util from '../../asset/util'
import Basic from '../../common/frame/Basic'
import UiBtn from '../../uiComponents/UiBtn/UiBtn'
import { UiCheckBox } from '../../uiComponents/UiCheckBox/UiCheckBox'
import UiEditor from '../../uiComponents/UiEditor/UiEditor'
import { UiInput, UiInput_title } from '../../uiComponents/UiInput/UiInput'
import UiTextBtn from '../../uiComponents/UiTextBtn/UiTextBtn'
import Lecture_modi_Style from '../Profile/Lecture_modi_Style'
import store from '../../asset/store'
import ReactPlayer from 'react-player/lazy'

const Book_subs_set = ({
    userInfo,
    setFileObj,
    fileObj
}) => {

    let navigate = useNavigate();
    let params = useParams();

    const [isTeacher ,setIsTeacher] = useState(true);

    // const [name,setName] = useState('')
    var [subsData, setSubsData] = useState({
        name : "",
        bid : params["bid"],
        public : "Y",
        pw : "",
        approve : "Y",
        subs_tel : null,
        subs_email : "",
        subs_pr : "",
        reg_date : 0,
    })

    var [addBookData, setAddBookData] = useState({})

    var [bookData,setBookData] = useState({})
    var [qnaCount,setQnaCount] = useState({})

    var [isBook, setIsBook] = useState(false)
    var [isPublic, setIsPublic] = useState(false);
    var [isApprove, setIsApprove] = useState(true);
    var [isPw, setIsPw] = useState(false);
    var [isCode, setIsCode] = useState(false);

    var [isBookApprove, setIsBookApprove] = useState(false);
    var [isBookPw, setIsBookPw] = useState(false);

    var [teacherForm, setTeacherForm] = useState({});
    var [t_l, setT_l] = useState(0);

    var [teacherForm_info, setTeacherForm_info] = useState({});
    var [t_l_info, setT_l_info] = useState(0);

    const fileOnChange = (e,key) => {
        // // console.log("222 ",e);
        try {
            let reader = new FileReader();
            let file = e.target.files[0];


            // video 시간 관련 코드
            var myVideos = [];
            window.URL = window.URL || window.webkitURL;
            myVideos.push(file);
            var video = document.createElement('video');
            video.preload = 'metadata';

            video.onloadedmetadata = function() {
               window.URL.revokeObjectURL(video.src);
               var duration = video.duration;
               myVideos[myVideos.length - 1].duration = duration;
            }
            video.src = URL.createObjectURL(file);;
            setFileObj({
                ...fileObj,
                isVideo : true,
                videoFile : file,
                myVideos : myVideos[0] ,
                duration : myVideos[myVideos.length - 1].duration
            })
            // navigate(-1)
            // setTimeout(() => {
            //     navigate(`/video/${params["who"]}/exlocal`)
            // }, 500);

            // reader.onloadend = (e) => {
            //     reader.readAsDataURL(file)
            // }

            // reader.onerror = (e) =>{
            // }
        }
        catch(e){
            // console.log("File on Change Error= > ",e);
        }
    }

    const apiPro_read = async () => {
        try {
            let state = {}
            let data = []

            let data_info_contents = []
            let data_info_teacher = []
            let s_Data = []
            if(params["who"] == "student"){
                state = {
                    id : params["bid"],
                    page : 1,
                    amount : 9999,
                    sort : "reg_date desc",
                    cid : params["cid"],
                    sid : userInfo["id"]
                }
                data = await apiFn.studentBooklist({
                    state : {

                        ...state
                    }
                })
            }
            else {
                data = await apiFn.comApi({
                    state : {
                        table : "book",
                        page : 1,
                        amount : 9999,
                        sort : "reg_date desc",
                        // ref : [
                        //     "HIQDB_book_link.cid|HIQDB_book_link.bid=HIQDB_book.id",
                        // ],
                        equal:{
                            id : params["bid"]
                        },
                    }
                })

                s_Data = await apiFn.comApi({
                    state : {
                        table : "book_subs",
                        page : 1,
                        amount : 9999,
                        sort : "reg_date desc",
                        where : {
                            "HIQDB_book_subs.bid" : `= '${params["bid"]}'`
                        }
                    }
                })

                console.log("s_Data : ",s_Data);

                data_info_contents = await apiFn.comApi({
                    state : {
                        table : "book_subs_info",
                        page : 1,
                        amount : 9999,
                        sort : "id asc",
                        where : {
                            "HIQDB_book_subs_info.bid" : `= '${params["bid"]}'`,
                            "HIQDB_book_subs_info.pr_type" : `= 'contents'`
                        }
                    }
                })

                data_info_teacher = await apiFn.comApi({
                    state : {
                        table : "book_subs_info",
                        page : 1,
                        amount : 9999,
                        sort : "id asc",
                        where : {
                            "HIQDB_book_subs_info.bid" : `= '${params["bid"]}'`,
                            "HIQDB_book_subs_info.pr_type" : `= 'teacher'`
                        }
                    }
                })
            }

            console.log("data_info_teacher : ", data_info_teacher["list"])
            console.log("data_info_contents : ",data_info_contents["list"])



            if(data_info_teacher["list"].length > 0){
                setT_l(data_info_teacher["list"][data_info_teacher["list"].length-1]["id"])
            }
            if(data_info_contents["list"].length > 0){
                setT_l_info(data_info_contents["list"][data_info_contents["list"].length-1]["id"])
            }

            // console.log("subsData ",s_Data['list'][0]);

            let info_teacher = data_info_teacher["list"].reduce((a, v, i)=>{
                return {
                        ...a,
                        [v["id"]] : {
                            ...v
                        }
                    }
            },{})

            let info_contents = data_info_contents["list"].reduce((a, v, i)=>{
                return {
                        ...a,
                        [v["id"]] : {
                            ...v
                        }
                    }
            },{})

            // console.log("info_teacher : ",info_teacher);
            setTeacherForm({
                ...info_teacher
            })

            setTeacherForm_info({
                ...info_contents
            })

            console.log("subsBookDAta => ",data["list"][0]);

            setBookData({
                ...data["list"][0]
            })
            if(data["list"].length > 0){
                let obj = {}
                if(data["list"][0]["approve"] == "Y"){
                    obj["approve"] = "Y"
                    setIsBookApprove(true)
                }
                else {
                    obj["approve"] = "N"
                }
                if(data["list"][0]["book_pw"]){
                    setIsBookPw(true)
                    obj["book_pw"] = data["list"][0]["book_pw"]
                }
                else{
                    obj["book_pw"] = 0
                }
                setAddBookData({
                    ...obj
                })
            }

            if(s_Data["list"].length > 0){
                let obj = s_Data["list"][0]
                setSubsData({
                    ...subsData,
                    ...obj
                })
                if(obj["public"] == "Y")setIsPublic(true)
                if(obj["approve"] == "Y"){
                    setIsApprove(true)
                }
                else {
                    setIsApprove(false)
                }
                if(obj["pw"])setIsPw(true)
            }
            else {
                setSubsData({
                    ...subsData,
                })
            }
        }
        catch(e){
            console.log("@#@# ",e)
        }
    }

    const apiPro_book_set = async () => {
        try {
            // console.log("addBookData : ",addBookData);
            // return;
            if(subsData["id"]){
                await apiFn.comApi({
                    state : {
                        table : "book_subs",
                        set : [{
                            key : `id:${subsData["id"]}`,
                            value : {
                                public : "N"
                            }
                        }]
                        // set: [ { key: 'prKey:15', value: {a:1,b:2}  } ]
                    }
                })
            }
            else {
                await apiFn.comApi({
                    state : {
                        table : "book_subs",
                        ins : [{
                            public : "N",
                            reg_date : 0,
                        }]
                        // set: [ { key: 'prKey:15', value: {a:1,b:2}  } ]
                    }
                })
            }

            await apiFn.comApi({
                state : {
                    table : "book",
                    set : [{
                        key : `id:${params["bid"]}`,
                        value : {
                            ...addBookData
                        }
                    }]
                }
            })

            const stack = store.get('stack')
            if(stack.length > 0)
                navigate(-1);
            else
                navigate('/');
        }
        catch(e){
        }
    }

    const apiPro_subs_set = async () => {
        try {
            // console.log("teacherForm => ",teacherForm);
            // return;
            await apiFn.comApi({
                state : {
                    table : "book_subs_info",
                    del : [
                        {
                            bid : params["bid"],
                        },
                    ],
                    delAll : "Y",
                }
            })

            // return;

            let teacherForm_array = Object.keys(teacherForm).reduce((a, v, i)=> {
                    // console.log("@@@ : : :  ",teacherForm[v])
                    let uuid = util.getUUid();
                    if(teacherForm[v]["tempImg"]){
                        let name = `/subs_info/${params["bid"]}/${uuid}.jpg`
                        let _file = teacherForm[v]["tempImg"]
                        store.putBucket(_file, name, false, false)
                        delete teacherForm[v]["img"]
                        // fileObj.push(teacherForm[v]["tempImg"])
                        delete teacherForm[v]["tempImg"]
                    }
                    delete teacherForm[v]["id"]
                    delete teacherForm[v]["tcnt"]
                    delete teacherForm[v]["tseq"]
                    return [
                        ...a,
                        {
                            ...teacherForm[v],
                            img : teacherForm[v]["img"] ?
                                teacherForm[v]["img"]
                                :
                                uuid
                            ,
                            bid : Number(params["bid"]),
                            reg_date : 0,
                            pr_type : "teacher"
                        }
                    ]
                }
            ,[])


            // console.log(teacherForm_array)

            let teacherForm_info_array = Object.keys(teacherForm_info).reduce((a, v, i)=> {
                delete teacherForm_info[v]["id"]
                delete teacherForm_info[v]["tcnt"]
                delete teacherForm_info[v]["tseq"]
                return [
                    ...a,
                    {
                        ...teacherForm_info[v],
                        bid : Number(params["bid"]),
                        reg_date : 0,
                        pr_type : "contents"
                    }
                ]
            },[])

            // console.log(teacherForm_info_array)
            // console.log(teacherForm_array)

            // return;

            await apiFn.comApi({
                state : {
                    table : "book_subs_info",
                    ins : [
                        ...teacherForm_array,
                        ...teacherForm_info_array
                    ]
                }
            })
            // return;

            // return;

            // console.log(subsData)
            if(subsData["id"]){
                let name_add = ""
                if(fileObj["videoFile"]){
                    new Date().getTime()+'_'+util.getRandomInt(100, 999)+'_'+ fileObj["videoFile"];
                }
                let name = `subs_info/${params["bid"]}/${name_add}`

                if(fileObj["videoFile"]){

                    subsData["video"] = name_add
                    await util.putVideo(fileObj["videoFile"], {}, "", name)
                }

                await apiFn.comApi({
                    state : {
                        table : "book_subs",
                        set : [{
                            key : `id:${subsData["id"]}`,
                            value : {
                                video : fileObj["videoFile"] ? name_add : "",
                                bid : subsData["bid"],
                                name : subsData["name"],
                                public : subsData["public"],
                                pw : subsData["pw"],
                                approve : subsData["approve"],
                                subs_tel : subsData["subs_tel"],
                                subs_email : subsData["subs_email"],
                                subs_pr : subsData["subs_pr"],
                                reg_date : 0,
                            }
                        }]
                        // set: [ { key: 'prKey:15', value: {a:1,b:2}  } ]
                    }
                })
            }
            else {
                // let uuid = util.getUUid();
                // subsData["video"] = uuid
                // if(fileObj["qid"] == 0){
                //     name = `general/${fileObj["tid"]}___${fileObj["sid"]}/${name_add}`
                // }
                // else {
                //     name = `qna/${qid}/${name_add}`
                // }
                // await util.putVideo(videoFile,{},"",name)

                if(fileObj["videoFile"]){
                    let name_add =  new Date().getTime()+'_'+util.getRandomInt(100, 999)+'_'+videoFile.name;
                    let name = `subs_info/${params["bid"]}/${name_add}`
                    // `/subs_info/${params["bid"]}/${uuid}.jpg`

                    // console.log("name => ",name)

                    subsData["video"] = name_add
                    await util.putVideo(fileObj["videoFile"], {}, "", name)
                }

                await apiFn.comApi({
                    state : {
                        table : "book_subs",
                        ins : [subsData]
                        // set: [ { key: 'prKey:15', value: {a:1,b:2}  } ]
                    }
                })
            }

        }
        catch(e){
            console.log("apiPro_subs_set error : ",e)
        }
        finally{

            const stack = store.get('stack')
            if(stack.length > 0)
                navigate(-1);
            else
                navigate('/');
        }
    }

    useEffect(()=>{
        // console.log("parmas => ",params)
        if(params["who"] == "teacher"){
            setIsTeacher(true)
        }
        else {
            setIsTeacher(false)
        }
    },[params])

    useEffect(()=>{
        // console.log("teacherForm : ", teacherForm) ;
    },[teacherForm])

    // useEffect(()=>{
    //     console.log("teacherForm_info : ", teacherForm_info) ;
    // },[teacherForm_info])

    useEffect(()=>{
        apiPro_read()
        setFileObj({})
    },[])

    // useEffect(()=>{
    //     console.log("subData : ",subsData)
    // },[subsData])

    return (
        <Basic title={"교재 설정"}>
            <div>
                <div style={{
                    // paddingRight : "5px",
                    display:"flex",
                    alignItems:"center",
                }}>
                    <div style={{
                        display:"flex",
                        flexDirection:"column",
                    }}>
                        <div style={{
                            fontSize:"12px",
                            color : style.common.fontColor_3,
                            paddingBottom:"3px",
                        }}>
                            * 교재 구독 비공개 시 교재 설정을 하실 수 있습니다.
                        </div>
                        <div style={{
                            display:"flex",
                            alignItems:"center",
                        }}>
                            <UiTextBtn
                                text={"교재 구독 공개"}
                                btnStyle={{
                                    color : "#464241",
                                    fontSize : "12px",
                                }}
                            />
                            <Switch
                                checked={isPublic}
                                onClick={(e)=>{
                                    if(!isPublic){
                                        setSubsData({
                                            ...subsData,
                                            public : "Y"
                                        })
                                    }
                                    else {
                                        setSubsData({
                                            ...subsData,
                                            public : "N"
                                        })
                                    }
                                    setIsPublic(!isPublic)
                                }}
                            />
                        </div>
                    </div>
                </div>
                {
                isPublic &&
                <>
                <div style={{
                    // paddingRight : "5px",
                    display:"flex",
                    alignItems:"center",
                }}>
                    <UiTextBtn
                        text={"질문 교재명 사용"}
                        btnStyle={{
                            color : "#464241",
                            fontSize : "12px",
                        }}
                    />
                    <Switch
                        checked={isBook}
                        onClick={(e)=>{
                            if(!isBook){
                                setSubsData({
                                    ...subsData,
                                    name : bookData["name"]
                                })
                            }
                            setIsBook(!isBook)
                        }}
                    />
                </div>
                <UiInput_title
                    c_style={{
                        paddingTop : 0,
                    }}
                    title="교재 구독명 (구독시 공개되는 이름)"
                    type="basic"
                    inputClass={isTeacher ? "input_t" : "input_s"}
                    placeholder={"이름을 입력해주세요."}
                    inputValue={subsData["name"]}
                    // maxLength="30"
                    fnChange={(e)=>{
                        if(isBook)return;
                        // setName(e.target.value)
                        setSubsData({
                            ...subsData,
                            name : e.target.value
                        })
                    }}
                />
                </>
                }
            </div>
            {
            isPublic ?
            <div style={{
                display:"flex",
            }}>
                <div style={{
                    marginRight:"3px",
                    display:"flex",
                    alignItems:"center",
                }}>
                    <UiTextBtn
                        text={"구독 비밀번호 사용"}
                        btnStyle={{
                            color : "#464241",
                            fontSize : "12px",
                        }}
                    />
                    <Switch
                        checked={isPw}
                        onClick={(e)=>{
                            setIsPw(!isPw)
                            if(isPublic){
                                if(isPw){
                                    setSubsData({
                                        ...subsData,
                                        pw : ""
                                    })
                                }
                            }
                            else {
                            }
                        }}
                    />
                </div>
                <div style={{
                    // paddingRight : "5px",
                    display:"flex",
                    alignItems:"center",
                }}>
                    <UiTextBtn
                        text={"구독 자동 승인"}
                        btnStyle={{
                            color : "#464241",
                            fontSize : "12px",
                        }}
                    />
                    <Switch
                        checked={isApprove}
                        onClick={(e)=>{
                            if(isPublic){
                                if(!isApprove){
                                    setSubsData({
                                        ...subsData,
                                        approve : "Y"
                                    })
                                }
                                else {
                                    setSubsData({
                                        ...subsData,
                                        approve : "N"
                                    })
                                }
                            }
                            else {

                            }
                            setIsApprove(!isApprove)
                        }}
                    />
                </div>
            </div>
            :
            <div style={{
                display:"flex",
            }}>
                <div style={{
                    marginRight:"3px",
                    display:"flex",
                    alignItems:"center",
                }}>
                    <UiTextBtn
                        text={"교재 비밀번호 사용"}
                        btnStyle={{
                            color : "#464241",
                            fontSize : "12px",
                        }}
                    />
                    <Switch
                        checked={isBookPw}
                        onClick={(e)=>{
                            setIsBookPw(!isBookPw)
                            if(isBookPw){
                                setAddBookData({
                                    ...addBookData,
                                    book_pw : 0
                                })
                            }
                        }}
                    />
                </div>
                <div style={{
                    // paddingRight : "5px",
                    display:"flex",
                    alignItems:"center",
                }}>
                    <UiTextBtn
                        text={"교재 자동 승인"}
                        btnStyle={{
                            color : "#464241",
                            fontSize : "12px",
                        }}
                    />
                    <Switch
                        checked={isBookApprove}
                        onClick={(e)=>{
                            if(!isBookApprove){
                                setAddBookData({
                                    ...addBookData,
                                    approve : "Y"
                                })
                            }
                            else {
                                setAddBookData({
                                    ...addBookData,
                                    approve : ""
                                })
                            }
                            setIsBookApprove(!isBookApprove)
                        }}
                    />
                </div>
            </div>
            }
            {
            isPublic ?
            isPw &&
            <UiInput_title
                title="비밀번호"
                type="basic"
                inputClass={isTeacher ? "input_t" : "input_s"}
                placeholder="4 ~ 8개 숫자 및 문자를 입력해주세요."
                // c_style={{
                //     paddingTop:0,
                // }}
                isPw={true}
                // maxLength="30"
                inputValue={subsData["pw"] }
                fnChange={(e)=>{
                    setSubsData({
                        ...subsData,
                        pw : e.target.value
                    })
                }}
            />
            :
            isBookPw &&
            <UiInput_title
                title="비밀번호"
                type="basic"
                inputClass={isTeacher ? "input_t" : "input_s"}
                placeholder="4 ~ 8개 숫자 및 문자를 입력해주세요."
                // c_style={{
                //     paddingTop:0,
                // }}
                isPw={true}
                // maxLength="30"
                inputValue={isPublic ? subsData["pw"] : addBookData["book_pw"]}
                fnChange={(e)=>{
                    setAddBookData({
                        ...addBookData,
                        book_pw : e.target.value
                    })
                }}
            />
            }
            {/* <div style={{
                // paddingRight : "5px",
                display:"flex",
                alignItems:"center",
            }}>
                <UiTextBtn
                    text={"교재 코드 사용"}
                    btnStyle={{
                        color : "#464241",
                        fontSize : "12px",
                    }}
                />
                <Switch
                    checked={isCode}
                    onClick={(e)=>{
                        setIsCode(!isCode)
                    }}
                />
            </div> */}
            {
            isPublic &&
            <div style={{
                marginTop:"5px",
            }}>
                <div style={{
                    fontWeight:"bold",
                    fontSize:"16px",
                    padding : "3px 0px 6px 0px",
                }}>
                    <div>
                        구독시 정보
                    </div>
                    <div style={{
                        color : style.common.fontColor_3,
                        fontWeight:400,
                        fontSize:"12px",
                        marginTop:"2px",
                    }}>
                        * 기입 정보를 입력 안할 시 구독정보란에 표시가 되지 않습니다.
                    </div>
                    {/* <div style={{
                        color : style.common.fontColor_3,
                        fontWeight:400,
                        fontSize:"12px",
                        marginBottom:"2px",
                    }}>
                        * 두 정보 중 하나는 반드시 기입하셔야 됩니다.
                    </div> */}
                </div>
                <div style={{
                    display:"flex",
                    fontWeight:500,
                    fontSize:"14px",
                    padding : "3px 0px 6px 0px",
                    alignItems:"center",
                }}>
                    <div>
                        [ 문제풀이 선생님 ]
                    </div>
                    <div style={{
                        width : "24px",
                        borderRadius : 50,
                        height:"24px",
                        display:"flex",
                        alignItems:"center",
                        justifyContent:"center",
                        marginLeft:"5px",
                        backgroundColor : style.common.t_color
                    }}
                        onClick={()=>{
                            if(Object.keys(teacherForm).length <= 2){
                                setTeacherForm({
                                    [t_l] : {
                                    },
                                    ...teacherForm,
                                })
                                setT_l(t_l+1)
                            }
                            // console.log("t_l : ",t_l)
                        }}
                    >
                        <Add
                            style={{
                                fontSize : "22px",
                                color : "white"
                            }}
                        />
                    </div>
                </div>
                {
                // Object.keys(teacherForm).length <= 3 &&
                Object.keys(teacherForm).map((v, i)=>{
                    // console.log("map key => ",v);
                    return (
                        <Teacher_form
                            setTeacherForm={setTeacherForm}
                            teacherForm={teacherForm}
                            data={v}
                            index={i}
                        />
                    )
                }
                )
                }
                 <div style={{
                    display:"flex",
                    fontWeight:500,
                    fontSize:"14px",
                    padding : "3px 0px 6px 0px",
                    alignItems:"center",
                }}>
                    <div>
                        [ 기입 정보 추가 ]
                    </div>
                    <div style={{
                        width : "24px",
                        borderRadius : 50,
                        height:"24px",
                        display:"flex",
                        alignItems:"center",
                        justifyContent:"center",
                        marginLeft:"5px",
                        backgroundColor : style.common.t_color
                    }}
                        onClick={()=>{
                            if(Object.keys(teacherForm_info).length <= 2){
                                setTeacherForm_info({
                                    [t_l_info] : {
                                    },
                                    ...teacherForm_info,
                                })
                                setT_l_info(t_l_info+1)
                            }
                        }}
                    >
                        <Add
                            style={{
                                fontSize : "22px",
                                color : "white"
                            }}
                        />
                    </div>
                </div>
                {
                Object.keys(teacherForm_info).map((v, i)=>
                    <Teacher_form_info
                        setTeacherForm_info={setTeacherForm_info}
                        teacherForm_info={teacherForm_info}
                        data={v}
                        index={i}
                    />
                )
                }
                {/* <div style={{
                    fontWeight:500,
                    fontSize:"14px",
                    padding : "3px 0px 6px 0px",
                    display:"flex",
                }}>
                    <div>
                        [ 구독 영상 샘플 ]
                    </div>
                    <div style={{
                        width : "24px",
                        borderRadius : 50,
                        height:"24px",
                        display:"flex",
                        alignItems:"center",
                        justifyContent:"center",
                        marginLeft:"5px",
                        backgroundColor : style.common.t_color
                    }}
                        onClick={()=>{
                            // console.log("t_l : ",t_l)
                        }}
                    >
                        <input
                            type={"file"}
                            // accept={"image/*"}
                            accept={"video/*"}
                            id="video_file"
                            onChange={(e)=>{
                                fileOnChange(e)
                            }}
                            style={{
                                display:"none"
                            }}
                        />
                        <label
                            htmlFor='video_file'
                            style={{
                                display:"flex",
                                flexDirection:"column",
                                alignItems:"center",
                            }}
                        >
                            <Add
                                style={{
                                    fontSize : "22px",
                                    color : "white"
                                }}
                            />

                        </label>
                    </div>
                </div>
                {
                fileObj["videoFile"] &&
                <div style={{
                    width : "100%",
                    height : "200px",
                    padding : "3px 0px 6px 0px",
                    // backgroundColor : "yellow",
                }}>
                    <ReactPlayer
                        // url={fileObj["videoFile"]}
                        url={fileObj["videoFile"] && URL.createObjectURL(fileObj["videoFile"])}
                        width="100%"
                        height={"100%"}
                        playing={false}
                        controls={true}
                        pip={false}
                        ref={(r)=>{
                            // setRv(r)
                        }}
                    />
                </div>
                }
                 */}
                <div style={{
                    fontWeight:500,
                    fontSize:"14px",
                    padding : "3px 0px 6px 0px",
                }}>
                    <div>
                        [ 구독 문의 연락처 ]
                    </div>
                </div>
                <UiInput_title
                    c_style={{
                        paddingTop : 0,
                    }}
                    title="핸드폰"
                    type="basic"
                    inputClass={isTeacher ? "input_t" : "input_s"}
                    inputValue={subsData["subs_tel"]}
                    // maxLength="30"
                    fnChange={(e)=>{
                        // setName(e.target.value)
                        setSubsData({
                            ...subsData,
                            subs_tel : e.target.value
                        })
                    }}
                />
                <UiInput_title
                    c_style={{
                        paddingTop : 0,
                    }}
                    title="이메일"
                    type="basic"
                    inputClass={isTeacher ? "input_t" : "input_s"}
                    inputValue={subsData["subs_email"]}
                    // maxLength="30"
                    fnChange={(e)=>{
                        // setName(e.target.value)
                        setSubsData({
                            ...subsData,
                            subs_email : e.target.value
                        })
                    }}
                />
            </div>

            }
            <div style={{
                display:"flex",
                flexDirection : "column",
                justifyContent:"flex-end",
                paddingBottom : "10px",
                width : "100%",
                flex:1,
            }}>
                <UiBtn
                    btnStyle={{
                        color : "white",
                        // !subsData["subs_tel"] && !subsData["subs_email"] ? "black" : "white",
                        backgroundColor :
                        (
                            isPublic ?
                            subsData["name"] == "" ? style.common.borderColor2 :
                            (isTeacher ?
                                style.common.t_color
                                :
                                style.common.s_color )
                            :
                            // !subsData["subs_tel"] && !subsData["subs_email"] ? style.common.borderColor2 :
                            (isTeacher ?
                            style.common.t_color
                            :
                            style.common.s_color )
                        )
                    }}
                    title="등록"
                    fnClick={()=>{
                        // if(!subsData["subs_tel"] && !subsData["subs_email"]){
                        //     alert("전화번호나 이메일은 반드시 하나 이상 입력해주세요.")
                        //     return;
                        // }
                        // if(isPw || isBookPw){
                            // if(isPublic){
                            //     if(!subsData["pw"]){
                            //         alert("비밀번호를 입력하지 않으셨어요.")
                            //         return;
                            //     }
                            // }
                            // else {
                            //     if(!addBookData["book_pw"]){
                            //         alert("비밀번호를 입력하지 않으셨어요.")
                            //         return;
                            //     }
                            // }
                        // }
                        if(isPublic){
                            if(subsData["name"] == "")return;
                            if(isPw){
                                if(!subsData["pw"]){
                                    alert("비밀번호를 입력하지 않으셨어요.")
                                    return;
                                }
                            }
                            let isOk = window.confirm("구독 설정을 저장하시겠습니까?")
                            if(!isOk)return;
                            apiPro_subs_set();
                        }
                        else {
                            if(isBookPw){
                                if(!addBookData["book_pw"]){
                                    alert("비밀번호를 입력하지 않으셨어요.")
                                    return;
                                }
                            }
                            let isOk = window.confirm("교재 설정을 저장하시겠습니까?")
                            if(!isOk)return;
                            apiPro_book_set();
                        }
                    }}
                />

            </div>
        </Basic>
    )
}

const Teacher_form = ({
    data,
    index,
    setTeacherForm,
    teacherForm,
}) => {
    let navigate = useNavigate();
    let params = useParams()

    var [img,setImg] = useState("")

    var [t_obj, setT_obj] = useState({

    })

    var [T_key, setT_key] = useState("");


    var fileOnchange = (e,cB) => {
        try {

            let reader = new FileReader();
            let file = e.target.files[0];
            reader.readAsDataURL(file)
            reader.onloadend = (e) => {
                // cB(e,reader.result);
                // console.log("onload Key => ",T_key);
                setTeacherForm({
                    ...teacherForm,
                    [data] : {
                        ...teacherForm[data],
                        tempImg : reader.result
                    }
                })
            }
        }
        catch(e){
            util.c_err_log("fileOnChange Error => ",e);
        }
    }

    // useEffect(()=>{
    //     console.log("teacherForm : ", teacherForm);
    //     console.log("data : ", data)
    //     setT_obj({
    //         ...teacherForm[data]
    //     })
    // },[teacherForm])

    // useEffect(()=>{
    //     console.log(t_obj)
    // },[t_obj])

    // useEffect(()=>{
    //     setTeacherForm({
    //         ...teacherForm,
    //         [data] : {
    //             ...t_obj
    //         }
    //     })
    // },[t_obj])

    useEffect(()=>{
        setT_key(data)
    },[data])

    return (
        <div>
            <div style={{
                fontSize:"12px",
                display : "flex",
                alignItems:"center",
                // backgroundColor:"purple"
                // justifyContent:"space-between",
            }}>
                <div style={{
                    display:"flex"
                }}>
                    {
                    <div style={{
                        marginRight:"3px",
                    }}>
                        ({index+1})
                    </div>
                    }
                    선생님 소개
                </div>
                <HighlightOffOutlined
                    style={{
                        fontSize:"22px",
                        color : style.common.fontColor_3,
                        marginLeft:"3px",
                    }}
                    onClick={()=>{
                        let object = { ...teacherForm }
                        delete object[data]
                        setTeacherForm({
                            ...object,
                        })
                        // console.log("D : ",data);
                    }}
                />
            </div>
            <div style={{
                display:"flex",
                marginTop:"10px",
                // backgroundColor:"yellow"
            }}>
                <div style={{
                    display:"flex",
                    // alignItems:"center",
                    alignItems:"flex-start",

                    // justifyContent:"center",
                    // backgroundColor:"green",
                    width : "100%",
                    // height: "100%",
                }}>
                    <div style={{
                        width : '100px',
                        height : "100px",
                        marginRight:"10px",

                        border : "1px solid #487FBA",
                        // backgroundColor:"orange",
                        // display:"flex",
                        // alignItems:"center",
                    }}>
                            <input
                                className={`img_${data}`}
                                id={`local_file_${data}`}
                                type="file"
                                accept='image/*'
                                style={{ display : "none" }}
                                onChange={(e)=>{
                                    fileOnchange(e,(event,r)=>{
                                        // img = r
                                        // setImg(r)
                                        // console.log("FO KEY => ", data);

                                        // apiPro_img(e);
                                    })


                                }}
                            />
                            <label
                                htmlFor={`local_file_${data}`}
                            >
                            <img
                                style={{
                                    height : "100%",
                                    width:'100%',
                                    objectFit:"cover"
                                }}
                                src={
                                    teacherForm[data]["tempImg"] ?
                                    teacherForm[data]["tempImg"]
                                    :
                                    (
                                        teacherForm[data]["img"] ?
                                        constants.s3_url + `/subs_info/${params["bid"]}/${teacherForm[data]["img"]}.jpg`
                                        :
                                        photopick_logo
                                    )
                                }
                            />
                        </label>
                    </div>
                    <div style={{
                        // width:"100%",
                        display:"flex",
                        flexDirection:"column",
                        flex:1,
                    }}>
                        <UiInput
                            // inputClass='input_no'
                            inputClass='input_t'
                            // basic_container_style={{
                            //     border : "none",
                            //     borderBottom : "1px solid",
                            //     borderBottomColor : style.common.borderColor,
                            //     borderRadius : 0,
                            // }}
                            placeholder="제목"
                            // inputValue={t_obj["title"] ? t_obj["title"] : ""}
                            inputValue={teacherForm[data]["title"] ? teacherForm[data]["title"] : ""}
                            fnChange={(e)=>{
                                // setT_obj({
                                //     ...t_obj,
                                //     title : e.target.value
                                // })

                                setTeacherForm({
                                    ...teacherForm,
                                    [data] : {
                                        ...teacherForm[data],
                                        title : e.target.value
                                    }
                                })
                            }}
                        />
                        <UiInput_title
                            title=""
                            inputValue={teacherForm[data]["subs_desc"] ? teacherForm[data]["subs_desc"] : ""}
                            type="desc"
                            inputClass={"input_t"}
                            fnChange={(e)=>{
                                // console.log(data)
                                // setT_obj({
                                //     ...t_obj,
                                //     subs_desc : e.target.value
                                // })

                                setTeacherForm({
                                    ...teacherForm,
                                    [data] : {
                                        ...teacherForm[data],
                                        subs_desc : e.target.value
                                    }
                                })

                                // setTeacherForm({
                                //     ...teacherForm,
                                //     [data] : {
                                //         title : e.target.value
                                //     }
                                // })

                                // setSubsData({
                                //     ...subsData,
                                //     subs_pr : e.target.value
                                // })
                            }}
                            dsecStyle={{
                                // width:"100%",
                                minHeight : '80px',
                            }}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

const Teacher_form_info = ({
    setTeacherForm_info,
    teacherForm_info,
    data,
    index,
}) => {
    var [t_obj, setT_obj] = useState({})

    // useEffect(()=>{;
    //     setT_obj({
    //         ...teacherForm_info[data]
    //     })
    // },[])


    // useEffect(()=>{
    //     // console.log("@@ : t_obj ", t_obj)
    //     setTeacherForm_info({
    //         ...teacherForm_info,
    //         [data] : {
    //             ...t_obj
    //         }
    //     })
    // },[t_obj])

    return (
        <div style={{
            marginBottom:"10px",
        }}>
            <div style={{
                fontSize:"12px",
                display : "flex",
                alignItems:"center",
                // justifyContent:"space-between",
            }}>
                <div style={{
                    display:"flex"
                }}>
                    {
                    <div style={{
                        marginRight:"3px",
                    }}>
                        ({index+1})
                    </div>
                    }
                    추가 정보
                </div>
                <HighlightOffOutlined
                    style={{
                        fontSize:"22px",
                        color : style.common.fontColor_3,
                        marginLeft:"3px",
                    }}
                    onClick={()=>{
                        let object = { ...teacherForm_info }
                        delete object[data]
                        setTeacherForm_info({
                            ...object,
                        })
                        // console.log("D : ",data);
                    }}
                />
            </div>
            <div style={{
                width : "100%",
                minHeight : "100px",
                borderRadius:5,
                marginTop:"5px",
                // border : "1px solid #487FBA",
                // padding : "5px",
            }}>
                <UiInput
                    inputClass='input_t'
                    // basic_container_style={{
                    //     border : "none",
                    //     borderBottom : "1px solid",
                    //     borderBottomColor : style.common.borderColor,
                    //     borderRadius : 0,
                    // }}
                    placeholder="제목"
                    // inputValue={t_obj["title"]}
                    inputValue={teacherForm_info[data]["title"] ? teacherForm_info[data]["title"] : ""}
                    fnChange={(e)=>{
                        // setT_obj({
                        //     ...t_obj,
                        //     title : e.target.value
                        // })

                        setTeacherForm_info({
                            ...teacherForm_info,
                            [data] : {
                                ...teacherForm_info[data],
                                title : e.target.value
                            }
                        })

                    }}
                />
                <div style={{
                    marginTop:"5px",
                }}>
                    <UiInput
                        type={"desc"}
                        // inputValue={t_obj["subs_desc"]}
                        inputValue={teacherForm_info[data]["subs_desc"] ? teacherForm_info[data]["subs_desc"] : ""}
                        dsecStyle={{
                            minHeight:"80px",
                        }}
                        fnChange={(e)=>{
                            // setT_obj({
                            //     ...t_obj,
                            //     subs_desc : e.target.value
                            // })

                            setTeacherForm_info({
                                ...teacherForm_info,
                                [data] : {
                                    ...teacherForm_info[data],
                                    subs_desc : e.target.value
                                }
                            })
                        }}
                    />
                </div>
            </div>
        </div>
    )
}


export default Book_subs_set
