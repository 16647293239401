import { Add, Clear } from "@material-ui/icons";
import { Switch } from "@mui/material";
import { React, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import apiFn from "../../asset/apiClass";
import constants from "../../asset/constants";
import store from "../../asset/store";
import style from "../../asset/style";
import util from "../../asset/util";
import whitelist from "../../asset/whitelist";
import Basic from "../../common/frame/Basic";
import UiBtn from "../../uiComponents/UiBtn/UiBtn";
import { UiCheckBox } from "../../uiComponents/UiCheckBox/UiCheckBox";
import UiEditor from "../../uiComponents/UiEditor/UiEditor";
import { UiInput_title } from "../../uiComponents/UiInput/UiInput";
import UiLoading from "../../uiComponents/UiLoading/UiLoading";
import UiSilde from "../../uiComponents/UiSilde/UiSilde";
import UiTextBtn from "../../uiComponents/UiTextBtn/UiTextBtn";
import Academy_book_Style from "../Academy/Academy_book_Style";
import Column_pick_Style from "../Column/Column_pick_Style";

let lengthChk = (obj) => {
  try {
    let list = [];
    Object.keys(obj).map((v, i) => {
      if (obj[v]["fileName"] != "del") {
        list.push(v);
      }
    });
    return list.length;
  } catch (e) {}
};

const Board_write = ({
  userInfo,
  setFileObj,
  fileObj,
  boardObj,
  setBoardObj,
  setHiddenUrl,
  cat,
  setCat,
  tempTitleData,
  setTempTitleData,
}) => {
  let params = useParams();
  let navigate = useNavigate();

  var [isLoading, setIsLoading] = useState(false);
  var [isUpload, setIsUpload] = useState(false);

  var [isModiImg, setIsModiImg] = useState(false);
  var [isModiVideo, setIsModiVideo] = useState(false);

  const [isTeacher, setIsTeacher] = useState(true);

  const [colData, setColData] = useState([]);

  const [img, setImg] = useState({});

  var [isChk, setIsChk] = useState(true);

  const apiPro_regi = async () => {
    try {
      // delete boardObj["videoFile"]
      // delete boardObj["base64"]
      // delete boardObj["fileName"]
      // delete boardObj["file"]
      // delete boardObj["name"]
      // delete boardObj["isImg"]
      // delete boardObj["isVideo"]
      // delete boardObj["myVideos"]

      // // console.log(userInfo)
      // // console.log(userInfo)
      // // console.log(userInfo)
      // // console.log(userInfo)
      // return;

      const insObj = {
        mosaic: "y",
        reg_date: 0,
        mid: userInfo["id"],
        cat_id: cat["id"] ?? 66,
        // cat_id 등록 위해선 섹션을 클릭 해야 되는데, notice는
        // 애초에 섹션 등록 UI가 display 되지 않으니 초기값인 66이 자동으로 사용
        video_end: boardObj.video_end,
        video_start: boardObj.video_start,
        video_link: boardObj.video_link,
        title: boardObj.title,
        content: boardObj.content,
        important: boardObj.important,
      };

      // if(params["type"] == "no"){
      //     insObj["cat_id"] = "66"
      // }
      // else {
      //     insObj["cat_id"] = params["type"]
      //     // if(params["type"] == "home"){

      //     // }
      //     // else {
      //     //     insObj["cat_id"] = params["type"]
      //     // }
      // }
      if (params["type"] == "8" || params["type"] == "158" || params["type"] == "7") {
        insObj["cat_id"] = params["type"];
      }
      if (
        params["where"] == "class" ||
        params["where"] == "lecture" ||
        params.where === "lecture_video"
      ) {
        insObj["cid"] = params["id"];
      } else if (params["where"] == "book") {
        insObj["bid"] = params["id"];
      } else {
        if (params["id"] != "0") {
          insObj["cid"] = params["id"];
        }
      }

      let attachList = [];
      let s3_attachList = [];
      Object.keys(attach).map((v, i) => {
        if (attach[v]["fileName"] == "del") return;
        let random = util.getRandomInt(100, 999);
        let _obj = {
          [v]: new Date().getTime() + "_" + random + "_" + v,
          // file : attach[v]["file"]
        };
        let _obj2 = {
          [v]: new Date().getTime() + "_" + random + "_" + v,
          file: attach[v]["file"],
        };
        s3_attachList.push(_obj2);
        attachList.push(_obj);
      });

      // // console.log("s3_attachList => ",s3_attachList)
      // // console.log("attachList => ",attachList)
      // // console.log("insObj => ",insObj)
      // setIsLoading(false)
      // return;
      // return;

      let state = {
        table: "board",
        ins: [
          insObj,
          // ...boardObj,
          // mid : userInfo["id"],
          // cat_id : params["type"] == "no" ? "66" : "0",
          // reg_date : 0,
          // cid : params["id"],
        ],
      };
      if (attachList.length > 0) {
        state["files"] = attachList;
      }

      console.log("state regi => ", state);
      let isOk = await apiFn.comApi({
        state: state,
      });
      // console.log(isOk)
      // await apiPro_img(img,isOk["id"])

      let name = "";
      if (fileObj["videoFile"] || boardObj["video_link"]) {
        name = `board/${isOk["id"]}.mp4`;
      }

      for (var i = 0; i < s3_attachList.length; i++) {
        // // console.log(s3_attachList[i])
        // // console.log(s3_attachList[i][0])
        let _key = Object.keys(s3_attachList[i])[0];
        // // console.log(_key)
        if (s3_attachList[i][_key] != "del") {
          let name = "board/" + isOk["id"] + "/" + s3_attachList[i][_key];
          console.log("name => ", name);
          let res = await store.putBucket(s3_attachList[i]["file"], name, false, false);
        }
      }
      // setIsLoading(false)
      // return;

      console.log(boardObj.base64);
      if (boardObj["base64"])
        await apiFn.uploadFile(
          `board/${isOk["id"]}/pic.jpg`,
          util.dataURLtoFile(fileObj["base64"], "pic.jpg")
        );
      if (fileObj["videoFile"]) await util.putVideo(fileObj["videoFile"], {}, isOk["id"]);
      alert("글이 등록되었습니다.");
      setBoardObj((boardObj = {}));
      setFileObj((fileObj = {}));
      setTempTitleData({
        전체: {
          name: "전체",
          id: 0,
        },
      });
      setIsLoading(false);
      // navigate("/column/teacher/home")
      // navigate(-1)
      if (params["where"] == "class") {
        navigate(`/profile/${params["who"]}/${params["id"]}/de`);
      }
      // else if (params["where"] == "book")){

      // }
      else {
        if (params["type"] == "no") {
          const stack = store.get("stack");
          if (stack.length > 0) navigate(-1);
          else navigate("/");
        } else {
          // navigate(`/column/${params["who"]}/home`)
          navigate(`/lecture/detail/${params["who"]}/${params.id}/de`);
        }
      }
    } catch (e) {
      setIsLoading(false);
      // console.log("Errpr => ",e);
    }
  };

  const apiPro_modi = async () => {
    try {
      console.log("modi start boardObj => ", boardObj);

      let attachList = [];
      let s3_attachList = [];

      // 기존 파일 리스트 이름 목록
      let prev_attachList = boardObj["files"].reduce((a, v, i) => {
        return [...a, v["name"]];
      }, []);

      // console.log("prev_attachList => ",prev_attachList)
      // console.log("attach => ",attach);

      let _attach = {};
      Object.keys(attach).map((v, i) => {
        // if(attach[v]["fileName"] != "del"){
        _attach = {
          ..._attach,
          [v]: attach[v],
        };
        // }
      });
      Object.keys(_attach).map((v, i) => {
        // if(_attach[v]["fileName"] == "del")return;
        // if(prev__attachList.includes())
        // console.log("prev_attachList => ",prev_attachList)
        // console.log("_attach => ",_attach)
        // console.log("v => ",v)
        // console.log("?? => ",_attach[v])
        try {
          // console.log("ASDFASDF");
          if (prev_attachList.includes(_attach[v]["name"]) && _attach[v]["fileName"] != "del") {
            // console.log("##########")
          } else {
            // console.log("?????????")
            let random = util.getRandomInt(100, 999);
            let _obj = {
              [v]: new Date().getTime() + "_" + random + "_" + v,
              // file : _attach[v]["file"]
            };
            if (_attach[v]["fileName"] == "del") {
              _obj[_attach[v]["file"]] = "del";
            }
            let _obj2 = {
              [v]: new Date().getTime() + "_" + random + "_" + v,
              file: _attach[v]["file"],
            };
            s3_attachList.push(_obj2);
            attachList.push(_obj);
          }
        } catch (e) {
          console.log("Attach Pro Error => ", e);
        }
      });

      // console.log("boardObj= > ",boardObj);

      delete boardObj["classList"];
      delete boardObj["files"];
      delete boardObj["writer"];
      delete boardObj["reg_date"];
      delete boardObj["readCnt"];
      delete boardObj["likeCnt"];
      delete boardObj["view_count"];

      // console.log("s3_attachList => ",s3_attachList);
      // console.log("attachList => ",attachList);

      // setIsLoading(false)
      // return;

      let state = {
        table: "board",
        set: [
          {
            key: `id:${params["modi"].split("&")[1]}`,
            value: { ...boardObj },
          },
        ],
      };
      if (attachList.length > 0) {
        state["files"] = attachList;
      }

      // console.log("mode boardObj => ",boardObj);
      // return;
      console.log("state modi => ", state);
      let isOk = await apiFn.comApi({
        state: state,
      });
      // console.log("isOk => ",isOk);
      // await apiPro_img(img)
      // await store.putBucket(fileObj["file"],fileObj["name"])

      for (var i = 0; i < s3_attachList.length; i++) {
        // console.log(s3_attachList[i])
        // // console.log(s3_attachList[i][0])
        let _key = Object.keys(s3_attachList[i])[0];
        // // console.log(_key)
        if (s3_attachList[i][_key] != "del") {
          let name = "/board/" + params["modi"].split("&")[1] + "/" + s3_attachList[i][_key];
          // // console.log("name => ",name)
          // // console.log("@#@#@# => ",s3_attachList[i]["file"])
          let res = await store.putBucket(s3_attachList[i]["file"], name, false, false);
        }
      }

      // return;
      // await store.putBucket(fileObj["base64"],`/board/${params["modi"].split("&")[1]}.jpg`, false, false)
      console.log("fileObj", fileObj);
      if (fileObj["base64"]) {
        await store.putBucket(
          fileObj["base64"],
          `board/${params["modi"].split("&")[1]}_t.jpg`,
          false,
          false
        );
      }
      if (fileObj["videoFile"]) {
        await util.putVideo(
          fileObj["videoFile"],
          { id: params["modi"].split("&")[1] },
          params["modi"].split("&")[1]
        );
      }
      alert("글이 수정 되었습니다.");
      setBoardObj((boardObj = {}));
      setFileObj((fileObj = {}));
      setTempTitleData({
        전체: {
          name: "전체",
          id: 0,
        },
      });
      setIsLoading(false);
      if (params["type"] == "no") {
        const stack = store.get("stack");
        if (stack.length > 0) navigate(-1);
        else navigate("/");
      } else {
        if (params["modi"].split("&")[2] == "de") {
          navigate(`/column/${params["who"]}/home`);
          navigate(`/columndetail/${params["who"]}/${params["modi"].split("&")[1]}` + "/de");
        } else {
          navigate(`/profile/${params["who"]}/${params["modi"].split("&")[2]}/de`);
          navigate(
            `/columndetail/${params["who"]}/${params["modi"].split("&")[1]}` +
              "/" +
              params["modi"].split("&")[2]
          );
        }
      }
    } catch (e) {
      // console.log(e)
      setIsLoading(false);
    }
  };

  const apiPro_img = async (e, id) => {
    try {
      let name = `/board/${params["modi"].split("&")[1]}.jpg`;
      if (id) {
        name = `/board/${id}.jpg`;
      }

      setFileObj({
        ...fileObj,
        name: name,
        file: e,
      });
      navigate("/crop/teacher/set");
    } catch (e) {
      // console.log("apiPro_img Error= >",e)
    }
  };

  const apiPro_chk = async () => {
    try {
      let video = await util.getCheck(
        `${util.getS3Folder()}/board/${params["modi"].split("&")[1]}.mp4`
      );
      if (video == 200) {
        setIsModiVideo(true);
      }
      let imgChk_url = `${util.getS3Folder()}/board/${params["modi"].split("&")[1]}.jpg`;
      if (params["type"] != "158") {
        imgChk_url = `${util.getS3Folder()}/board/${params["modi"].split("&")[1]}_t.jpg`;
      }
      let image = await util.getCheck(imgChk_url);
      if (image == 200) {
        setIsModiImg(true);
      }
    } catch (e) {}
  };

  const apiPro_read = async () => {
    try {
      let data = await apiFn.comApi({
        state: {
          files: "y",
          table: "board",
          page: 1,
          amount: 9999,
          sort: "reg_date desc",
          where: {
            id: `='${params["modi"].split("&")[1]}'`,
          },
        },
      });

      await apiPro_chk();
      delete data["list"][0]["tcnt"];
      delete data["list"][0]["tseq"];
      delete data["list"][0]["readCnt"];

      if (boardObj["video_link"]) {
        data["list"][0]["video_link"] = boardObj["video_link"];
      }
      if (boardObj["video_start"]) {
        data["list"][0]["video_start"] = boardObj["video_start"];
      }

      // console.log("read obj => ",data["list"][0])
      if (data["list"][0]["files"].length > 0) {
        let attachObj = {};
        data["list"][0]["files"].map((v, i) => {
          attachObj[v["origin"]] = {
            file: v["id"],
            fileName: v["origin"],
            name: v["name"],
          };
        });
        setAttach({
          ...attachObj,
        });
      }
      setBoardObj({
        ...data["list"][0],
      });
    } catch (e) {}
  };

  // column 리스트 호출
  const apiPro_read_2 = async (d) => {
    try {
      let data = await apiFn.comApi({
        state: {
          table: "board_cat",
          page: 1,
          amount: 9999,
          sort: "name desc",
          where: {
            "HIQDB_board_cat.type": `='2'`,
            "HIQDB_board_cat.pid": `='${d ? d["id"] : "0"}'`,
          },
        },
      });
      // console.log("Column Data => ",data["list"])
      setColData([...data["list"]]);
    } catch (e) {
      // console.log("",e)
    }
  };

  var [attach, setAttach] = useState({});

  const fileOnChange = (e, key) => {
    let reader = new FileReader();
    let file = e.target.files[0];
    try {
      let _type = file["type"];
      if (!whitelist.includes(_type)) {
        alert("첨부할 수 없는 종류의 파일입니다");
        setIsUpload(false);
        return;
      }
      if (file.size > constants.fileSize) {
        alert("파일 용량이 큽니다");
        setIsUpload(false);
        return;
      }
      reader.readAsDataURL(file);
      reader.onloadend = (e) => {
        let _fileObj = {
          fileName: file["name"],
          file: reader.result
            .replace(/^data:image\/png;base64,/, "")
            .replace(/^data:image\/jpeg;base64,/, ""),
        };
        setAttach({
          ...attach,
          [file["name"]]: _fileObj,
        });
        setIsUpload(false);
      };
      reader.onerror = (e) => {
        setIsUpload(false);
      };
    } catch (e) {
      setIsUpload(false);
      // console.log("File on Change Error= > ",e);
    } finally {
    }
  };

  useEffect(() => {
    // console.log("parmas => ",params["type"])

    if (params["who"] == "teacher") {
      setIsTeacher(true);
    } else {
      setIsTeacher(false);
    }
  }, [params]);

  useEffect(() => {}, [cat]);

  useEffect(() => {
    // util.objLog(boardObj)
  }, [boardObj]);

  useEffect(() => {
    // util.objLog(fileObj)
  }, [fileObj]);

  useEffect(() => {
    // console.log("attach file => ",attach);
  }, [attach]);

  useEffect(() => {
    console.log("$$$$$$$$$$$$$$$");
    // // console.log("@@ ",fileObj);
    if (params["modi"] != "n") {
      apiPro_read();
    } else {
    }
    if (fileObj["base64"]) {
      setBoardObj({
        ...boardObj,
        ...fileObj,
      });
    }
    if (params["type"] == "home") {
      apiPro_read_2();
    } else {
      setCat({ id: params["tye"] });
    }
  }, []);

  // useEffect(()=>{
  //     console.log("===============================")
  //     console.log(boardObj)
  //     console.log("===============================")
  // },[boardObj])

  useEffect(() => {
    // console.log("cat => ",cat)
  }, [cat]);

  return (
    <Basic
      title={"글 쓰기"}
      // isPadding={false}
      isSearch={false}
      backEvent={() => {
        setBoardObj((boardObj = {}));
        setFileObj({});
        setTempTitleData({
          전체: {
            name: "전체",
            id: 0,
          },
        });
      }}
      // isTap={true}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          position: "absolute",
          height: "50px",
          right: 10,
          top: 10,
          fontSize: "16px",
          color: style.common.t_color,
          fontWeight: "bold",
        }}
        onClick={() => {
          // // console.log(cat);
          // // console.log(cat);
          // // console.log(cat);
          // return;
          if (!boardObj["title"]) {
            alert("글 제목은 필수 입니다.");
            return;
          }
          if (params["type"] == "home") {
            if (cat["id"] == "66") {
              alert("카테고리를 등록해주세요.");
              return;
            }
          }
          // if(cat["id"] != "158"){
          //     if(!fileObj["file"] && params["type"] != 66 && params["modi"] == "n" && (params["type"] != "no")){
          //         alert("대표 이미지를 등록해주세요.")
          //         return;
          //     }
          // }
          if (params["modi"] == "n") {
            setIsLoading(true);
            apiPro_regi();
          } else {
            setIsLoading(true);
            apiPro_modi();
          }
        }}
      >
        {params["modi"] == "n" ? "등록" : "수정"}
      </div>
      <div
        style={{
          width: "100%",
          height: "100%",
        }}
      >
        <div
          style={{
            fontSize: "12px",
            color: style.common.fontColor_1,
          }}
        >
          <div>보조 설명이 필요할 때 해당 공간에 보조 문항이 표시됩니다.</div>
          <div>한 줄 이상이 기록될 수도 있습니다.</div>
        </div>
        {params["type"] == "no" ? (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              padding: "20px 0px 10px 0px",
            }}
          >
            <UiCheckBox
              value={"isImportant"}
              name="iI"
              className={isTeacher ? "t" : "s"}
              checked={boardObj["important"] == "y" ? true : false}
              fnCheck={(e) => {
                if (boardObj["important"] == "y") {
                  setBoardObj({
                    ...boardObj,
                    important: "n",
                  });
                } else {
                  setBoardObj({
                    ...boardObj,
                    important: "y",
                  });
                }
              }}
            />
            <div
              style={{
                fontSize: "12px",
                color: style.common.fontColor_1,
                marginLeft: "5px",
              }}
            >
              중요한 공지사항
            </div>
          </div>
        ) : params["type"] == "home" ? (
          <div
            style={{
              padding: "10px 0px",
            }}
          >
            <UiSilde
              isBoard={true}
              boardObj={boardObj}
              isFull={false}
              initData={cat}
              data={colData}
              apiPro={apiPro_read_2}
              tempTitleData={tempTitleData}
              setTempTitleData={setTempTitleData}
              fnClickAll={() => {
                // console.log("@@")
                setCat({
                  id: "",
                });
                setTempTitleData({
                  전체: {
                    name: "전체",
                    id: 0,
                  },
                });
                // setBoardObj({
                //     ...boardObj,
                //     cat_id : ""
                // })
              }}
              fnClick={(d) => {
                // console.log(d)
                // setBoardObj({
                //     ...boardObj,
                //     cat_id : d["id"]
                // })
                // console.log(d)
                setCat(d);
                setTempTitleData({
                  ...tempTitleData,
                  [d["name"]]: {
                    ...d,
                  },
                });
              }}
            />
          </div>
        ) : params["type"] == "158" ? (
          <div
            style={{
              padding: "10px 0px",
              fontWeight: "bold",
            }}
          >
            입시 컬럼
          </div>
        ) : params["type"] == "8" ? (
          <div
            style={{
              padding: "10px 0px",
              fontWeight: "bold",
            }}
          >
            모의고사 해설 강의
          </div>
        ) : (
          params["type"] == "7" && (
            <div
              style={{
                padding: "10px 0px",
                fontWeight: "bold",
              }}
            >
              개념 강의
            </div>
          )
        )}
        {!(params["type"] == "no") && !(params["type"] == "158") && cat["id"] != "158" && (
          <div>
            {fileObj["videoFile"] || isModiVideo || boardObj["video_link"] ? (
              <div
                style={{
                  ...Column_pick_Style.t_btn_w,
                  minWidth: "180px",
                }}
              >
                <UiBtn
                  // isFile={true}
                  btnStyle={{
                    ...Column_pick_Style.t_btn,
                    padding: "18px 0px",
                    backgroundColor: style.common.t_color,
                    color: "white",
                    width: "auto",
                  }}
                  title={
                    <div
                      style={{
                        width: "100%",
                        ...Column_pick_Style.t_btn_text,
                        marginLeft: "15px",
                      }}
                    >
                      {fileObj["videoFile"]
                        ? util.textTrim(fileObj["videoFile"]["name"], 35)
                        : boardObj["video_link"]
                          ? boardObj["video_link"]
                          : `${params["modi"].split("&")[1]}.mp4`}
                      <Clear
                        style={{
                          paddingRight: "10px",
                        }}
                      />
                    </div>
                  }
                  fnClick={() => {
                    setIsModiVideo(false);
                    setFileObj({
                      ...fileObj,
                      videoFile: null,
                    });
                    delete boardObj["video_link"];
                  }}
                />
              </div>
            ) : (
              <div
                style={{
                  width: "180px",
                  margin: "0px 5px",
                }}
              >
                <UiBtn
                  btnStyle={{
                    // ...Column_pick_Style.t_btn,
                    borderRadius: 20,
                    height: "40px",
                    padding: "4px 0px",
                    width: "100%",
                    // width : "100%",
                    // backgroundColor : "white",
                    // color : "black",
                    // borderRadius : 20,
                    // height : '30px',
                    // padding : "4px 0px",
                    // width : "auto",
                  }}
                  title={
                    <div
                      style={{
                        ...Academy_book_Style.btn_title,
                        width: "100%",
                        // ...Column_pick_Style.t_btn_text,
                        // paddingLeft : "5px"
                      }}
                    >
                      <Add
                        style={{
                          ...Academy_book_Style.btn_Add,
                          left: 10,
                        }}
                      />
                      <div
                        style={{
                          paddingLeft: "12px",
                        }}
                      >
                        영상 등록하기(필수)
                      </div>
                    </div>
                  }
                  fnClick={(e) => {
                    setHiddenUrl(window.location.pathname);
                    navigate(`/video/${params["who"]}/de`);
                  }}
                />
              </div>
            )}
          </div>
        )}

        {params["type"] != "no" && (
          <div>
            {fileObj["base64"] || isModiImg ? (
              <div
                style={{
                  ...Column_pick_Style.t_btn_w,
                  minWidth: "180px",
                  // width : "auto",
                }}
              >
                <UiBtn
                  // isFile={true}
                  // id={"btn_img"}
                  btnStyle={{
                    ...Column_pick_Style.t_btn,
                    borderRadius: 20,
                    // height : '30px',
                    padding: "18px 0px",
                    backgroundColor: style.common.t_color,
                    color: "white",
                    width: "auto",
                  }}
                  title={
                    <div
                      style={{
                        width: "100%",
                        ...Column_pick_Style.t_btn_text,
                        paddingLeft: "5px",
                        marginLeft: "15px",
                      }}
                    >
                      {fileObj["base64"]
                        ? util.textTrim(fileObj["fileName"], 35)
                        : `${params["modi"].split("&")[1]}.jpg`}
                      <Clear
                        style={{
                          paddingRight: "10px",
                        }}
                      />
                    </div>
                  }
                  fnClick={(e) => {
                    setIsModiImg(false);
                    setFileObj({
                      ...fileObj,
                      base64: null,
                    });
                  }}
                />
              </div>
            ) : (
              !(cat["id"] == "158" || params["type"] == "158") && (
                <div
                  style={{
                    width: "180px",
                    margin: "0px 5px",
                  }}
                >
                  <UiBtn
                    isFile={true}
                    accept="image/*"
                    id={"btn_img"}
                    btnStyle={{
                      // ...Column_pick_Style.t_btn,
                      borderRadius: 20,
                      height: "30px",
                      padding: "4px 0px",
                      width: "100%",
                      // backgroundColor : style.common.t_color,
                      // color : "white",
                    }}
                    title={
                      <div
                        style={{
                          ...Academy_book_Style.btn_title,
                          width: "100%",
                          // ...Column_pick_Style.t_btn_text,
                          // paddingLeft : "5px"
                        }}
                      >
                        <Add
                          style={{
                            ...Academy_book_Style.btn_Add,
                            left: 10,
                          }}
                        />
                        <div
                          style={{
                            paddingLeft: "12px",
                          }}
                        >
                          {"문제 이미지 등록"}
                        </div>
                      </div>
                    }
                    fnClick={(e) => {
                      setImg(e);
                      apiPro_img(e);
                    }}
                  />
                </div>
              )
            )}
          </div>
        )}

        {
          // (cat["id"] != "158" && params["type"] != "158") &&
          params["type"] != "no" && cat["id"] != "158" && params["type"] != "158" && (
            <div
              style={{
                // paddingRight : "5px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <UiTextBtn
                text={"문제 이미지 모자이크"}
                btnStyle={{
                  color: "#464241",
                  fontSize: "12px",
                }}
              />
              <Switch
                checked={isChk}
                onClick={(e) => {
                  if (isChk) {
                    setIsChk(false);
                  } else {
                    setIsChk(true);
                  }
                  let m = "y";
                  if (isChk) {
                    m = "n";
                  } else {
                    m = "y";
                  }
                  setBoardObj({
                    ...boardObj,
                    mosaic: m,
                  });
                }}
              />
            </div>
          )
        }
        <div>
          <UiInput_title
            title="제목"
            inputValue={boardObj["title"]}
            placeholder="제목"
            inputClass={params["who"] == "teacher" ? "input_t" : "input_s"}
            fnChange={(e) => {
              setBoardObj({
                ...boardObj,
                title: e.target.value,
              });
            }}
          />
        </div>
        <div>
          {/* UiEditor... */}
          {!isLoading && !isUpload && (
            <div
              style={{
                height: "250px",
              }}
            >
              <UiEditor
                initValue={boardObj["content"]}
                setHtml={(e) => {
                  // // console.log(e)
                  // // console.log(e)
                  // // console.log(e)
                  setBoardObj({
                    ...boardObj,
                    content: e,
                  });
                }}
              />
            </div>
          )}
          {/* <UiInput
                        type="desc"
                        inputValue={boardObj["content"]}
                        inputClass={params["who"] == "teacher" ? "input_t" : "input_s"}
                        dsecStyle={{
                            height : "120px"
                        }}
                        placeholder="UiEditor..."
                        fnChange={(e)=>{
                            setBoardObj({
                                ...boardObj,
                                content : e.target.value
                            })

                        }}
                    /> */}
        </div>
        <div>
          <div
            style={{
              width: "100%",
              borderTop: "1px sold",
              borderTopColor: style.common.borderColor,
              marginTop: "6px",
              paddingTop: "12px",
              paddingBottom: "10px",
              fontSize: "12px",
              marginTop: "20px",
              color: style.common.fontColor_6,
            }}
          >
            * 문서형태의 파일만 첨부할 수 있어요.
          </div>
          {
            //    (Object.keys(attach).length < 3) &&
            lengthChk(attach) < 3 && (
              <div
                style={{
                  ...Column_pick_Style.t_btn_w,
                  margin: "0px 0px",
                  width: "140px",
                  // width : "180px"
                }}
              >
                <UiBtn
                  isFile={true}
                  id={"attach_file"}
                  btnStyle={{
                    // ...Column_pick_Style.t_btn,
                    // backgroundColor : 'white',
                    // color : "black"

                    borderRadius: 20,
                    height: "30px",
                    padding: "4px 0px",
                    // width : "100%",
                  }}
                  title={
                    <div
                      style={{
                        ...Academy_book_Style.btn_title,
                        left: 10,
                      }}
                    >
                      <Add style={Academy_book_Style.btn_Add} />
                      <div
                        style={{
                          paddingLeft: "12px",
                        }}
                      >
                        파일 첨부
                      </div>
                    </div>
                  }
                  fnClick={(e) => {
                    setIsUpload(true);
                    fileOnChange(e);
                    e.target.value = "";
                    // navigate(`/write/${params["who"]}/de/0`)
                  }}
                />
              </div>
            )
          }
          {Object.keys(attach).map((v, i) => {
            if (attach[v]["fileName"] != "del") {
              return (
                <div
                  style={{
                    ...Column_pick_Style.t_btn_w,
                    minWidth: "180px",
                    margin: 0,
                    // width : "auto",
                  }}
                >
                  <UiBtn
                    // isFile={true}
                    // id={"btn_img"}
                    btnStyle={{
                      ...Column_pick_Style.t_btn,
                      borderRadius: 20,
                      // height : '30px',
                      padding: "18px 0px",
                      backgroundColor: style.common.t_color,
                      color: "white",
                      width: "auto",
                    }}
                    title={
                      <div
                        style={{
                          width: "100%",
                          ...Column_pick_Style.t_btn_text,
                          paddingLeft: "5px",
                          marginLeft: "15px",
                        }}
                      >
                        {v}
                        <Clear
                          style={{
                            paddingRight: "10px",
                          }}
                        />
                      </div>
                    }
                    fnClick={(e) => {
                      let obj = attach;
                      obj[v]["fileName"] = "del";
                      setAttach({
                        ...obj,
                      });
                    }}
                  />
                </div>
              );
            }
          })}
        </div>
      </div>
      {isLoading && <UiLoading text="글을 등록중입니다." />}
      {isUpload && <UiLoading text="파일을 업로드 중입니다." />}
      {/* <div style={{
                position:"fixed",
                top : 0,
                display:"flex",
                width:"100%",
                height : "100%",
                backgroundColor:"rgba(200,200,200,0.5)",
                zIndex:99999998
            }}>
                <Video
                    userInfo={userInfo}
                    setFileObj={setFileObj}
                    fileObj={fileObj}
                    boardObj={boardObj}
                    setBoardObj={setBoardObj}
                    hiddenUrl={hiddenUrl}
                />
            </div> */}
    </Basic>
  );
};

export default Board_write;
