import React from "react";
import styled from "styled-components";
import StudyBookFolderContextProvider from "../context/StudyBookFolderContext";
import FolderEdit from "./FolderEdit";
import FolderList from "./FolderList";

const FolderSection = () => {
  return (
    <StudyBookFolderContextProvider>
      <Container>
        <FolderEdit />
        <FolderList />
      </Container>
    </StudyBookFolderContextProvider>
  );
};

const Container = styled.div`
  margin-top: 24px;
`;

export default FolderSection;
