import style from "../../asset/style"

const UiMessage_Style = {
    noData_w : {
        marginTop : '30px',
        display:"flex",
        flexDirection:"column",
        justifyContent:"center",
        alignItems:"center",
    },
    noData_i : {
        padding : "15px",
        width : '220px',
    },
    text_w : {
        display:"flex",
        flexDirection : 'column',
        // justifyContent:"center",
        fontSize : "14px",
        padding : "15px",
        alignItems:"center"
    },
    textBtn_w : {
        width : "280px",
        display : 'flex',
        justifyContent : 'space-around',
        padding : "15px",
        fontSize : "14px",
    },
    section_c : {
        display:"flex",
        width:"100%",
        // backgroundColor:"red",
        // height : '56px',
        // ...style.common.line_text
        // overflow:"hidden",
        // textOverflow:'ellipsis',
        // wordWrap:'break-word',
    },
    section_i_w : {
        display:'flex',
        justifyContent:'center',
        alignItems:"center",
        width : "56px",
        height : "100%",
        // height : "60px",
        // backgroundColor : "rgb(230,230,230)",
        // borderRadius : 50,
    },
    section_i : {
        width : "100%",
        height:"100%",
        borderRadius : 12,
        objectFit : 'cover',
        objectPosition : "center"
        // width : "84px",
    },
    section_r_c : {
        display:"flex",
        // flexDirection:"column",
        justifyContent:"space-between",
        width : '81%',
        height : "100%",
        // backgroundColor:"yellow",
        // height : "57px",

        // minHeight : '56px',
    },
    section_r_t_w : {
        display:"flex",
        flexDirection : "column",
        justifyContent:"space-between",
        fontSize : '13px',
    },
    section_r_t_t_w : {
        fontSize : '15px',
        display:"flex",
    },
    section_r_t_t_text_1 : {
        marginRight : '5px',
        color : style.common.t_color,
        // fontWeight:"bold",
        fontWeight : 500
    },
    section_r_t_t_text_2 : {
        display:"flex",
        marginRight : '5px',
        // fontSize:"13px",
        // fontWeight : 'bold',
        fontWeight : 500
    },
    section_r_t_t_text_3 : {
        fontSize : '11px',
        marginTop:"2px",
        color : style.common.fontColor_7
    },
    section_r_m_w : {
        display:"flex",
        justifyContent:"flex-end",
        fontSize : '11px',
        color : style.common.fontColor_1,
        marginTop : '6px',

    },
    section_r_m_l_w : {
        display:"flex",
        fontSize : '10px',
        marginTop:"3px",
        color : style.common.fontColor_6,
    },
    section_r_m_r_w : {
        display:"flex",
        justifyContent:"center",
        alignItems:"center",
        fontWeight:"bold",
        padding : '4px 7px',
        width : '8px',
        backgroundColor : '#EFD9B4',
        borderRadius : 20,
        color : "white",
        fontSize : "10px"
    },
    section_r_b_w  : {
        display:"flex",
        // backgroundColor:"green",
        // width:"100%",
        // flex:1,
        justifyContent:"space-between",
        fontSize : '13px',
        color : style.common.fontColor_7,
        marginTop : '6px',
        paddingBottom : "2px",
    },
}

export default UiMessage_Style
