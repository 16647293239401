import { React , useState , useEffect, useRef } from 'react'
import { useNavigate, useParams } from 'react-router'
import icon_favorite_on from '../../asset/Img/icon_favorite_on.png'
import icon_favorite_off from '../../asset/Img/icon_favorite_off.png'
import icon_teacher_move from '../../asset/Img/icon_teacher_move.png'
import icon_link from '../../asset/Img/icon_link.png'
import icon_roomexit from '../../asset/Img/icon_roomexit.png'
import icon_export from '../../asset/Img/icon_export.png'
import util from '../../asset/util'
import { Switch } from '@mui/material'
import UiTextBtn from '../../uiComponents/UiTextBtn/UiTextBtn'
import { Add, AddBox, Star } from '@material-ui/icons'
import { Circle } from '@mui/icons-material'
import constants from '../../asset/constants'
import apiFn from '../../asset/apiClass'
import UiBtn from '../../uiComponents/UiBtn/UiBtn'
import style from '../../asset/style'
import UiPeople_Style from '../../uiComponents/UiPeople/UiPeople_Style'
import UiFolder from '../../uiComponents/UiFolder/UiFolder'
import UiBook from '../../uiComponents/UiBook/UiBook'

var fontStyle = {
    fontFamily: 'Noto Sans',
    fontStyle: "normal",
}

const Modal_qna_book = ({
    apiPro_read_qna,
    setIsModal,
    isModal,
    userInfo,
    qnaData,
    setIsPick,
    type = "move",
    selectData
}) => {
    let params = useParams();
    let navigate = useNavigate();

    var [list, setList] = useState([]);

      // 교재 리스트
      const apiPro_read_book = async () => {
        try {
            if(params["who"] == "teacher"){
                let isOk = await apiFn.comApi({
                    state : {
                        table : "book",
                        page : 1,
                        amount : 999,
                        u_id : userInfo["id"],
                        sort : "reg_date desc",
                        ref : [
                            'HIQDB_book_link.cid|HIQDB_book_link.bid=HIQDB_book.id',
                        ],
                        where : {
                            "HIQDB_book_link.mid" : `= '${userInfo["id"]}'`,
                            "HIQDB_book.status" : `!= '9'`,
                            // "HIQDB_book_link.fid" : `= 0`,
                            // "HIQDB_book_link.status" : `= '1'`,
                            "HIQDB_book_link.status" : "!= 0",
                            "HIQDB_book_link.type" : "!= 9",
                            // "HIQDB_book_link.cid" : `= 0`,
                        }
                        
                    }
                })
    
                // console.log("teacher Book Data ",isOk["list"])
                setList([
                    ...isOk["list"]
                ])
            }
        }
        catch(e){
        }
    }

    // 문항 교재 이동
    const apiPro_set_move = async (id) => {
        try {

            console.log("id : ",id)
            console.log("qid : ",selectData["id"])

            await apiFn.comApi({
                state : {
                    table : "book_link",
                    set: [{ 
                        key : `qid:${selectData["id"]}`,
                        value : { bid : id }
                        
                        // fid: params["id"], 
                        // qid : v,
                        // mid : userInfo["id"]
                    }]
                }
            })
            setIsModal(false)

            // return;
            // if(params["type"] == "n"){
            //     navigate(-1)
            //     apiFn.comApi({
            //         state : {
            //             table : "book_link",
            //             set: [{ 
            //                 key : `qid:${selectData["id"]}`,
            //                 value : { bid : id }
                            
            //                 // fid: params["id"], 
            //                 // qid : v,
            //                 // mid : userInfo["id"]
            //             }]
            //         }
            //     })
            // }
            // else {
                
            //     let promiseArray = Object.keys(qnaData).map((v,i)=>
            //         apiFn.comApi({
            //             state : {
            //                 table : "book_link",
            //                 set: [{ 
            //                     key : `qid:${v}`,
            //                     value : { bid : id }
                                
            //                     // fid: params["id"], 
            //                     // qid : v,
            //                     // mid : userInfo["id"]
            //                 }]
            //             }
            //         })
            //     )
            //     await Promise.all(promiseArray)

            //     setQnaData({})
            //     setIsEdit(false)
            //     navigate(`/academy/${params["who"]}/book`)
            //     navigate(`/book/${params["who"]}/${params["id"]}/0/de`)
            //     if(params["id"] == 0){
            //         navigate(`/book/${params["who"]}/${params["id"]}/0/de`)
            //     }
            //     else {
            //         navigate(`/explainmodel/${params["who"]}/${params["id"]}/${params["type"]}/book`)
            //     }
            // }
        }
        catch(e){
            // console.log(e)
        }
    }
    
    // 문항 교재 복사
    const apiPro_set_paste = async (id) => {
        try {
            console.log("id : ",id)
            console.log("qid : ",selectData["id"])
            let isOk = await apiFn.copyQna({
                state : {
                    tid : userInfo["id"],
                    bid : id,
                    qids : [selectData["id"]]
                }
            })
            console.log("isOk : ",isOk)

            setIsModal(false)
            // if(params["type"] == "n"){
                // let isOk = await apiFn.copyQna({
                //     state : {
                //         tid : userInfo["id"],
                //         bid : id,
                //         qids : [params["id"]]
                //     }
                // })
                // console.log("isOk : ",isOk)
            //     navigate(-1)
            // }
            // else{
            //     let array = Object.keys(qnaData).reduce((a,v,i)=>{
            //         return [
            //             ...a,
            //             v
            //         ]
            //     },[])
            //     // console.log(array);
            //     let isOk = await apiFn.copyQna({
            //         state : {
            //             tid : userInfo["id"],
            //             bid : id,
            //             qids : array
            //         }
            //     })
            //     setQnaData({})
            //     setIsEdit(false)
    
            //     const _form = params["form"]
            //     navigate(`/academy/${params["who"]}/book`)
            //     navigate(`/book/${params["who"]}/${params["id"]}/0/de`)
            //     if(params["id"] == 0){
            //         navigate(`/book/${params["who"]}/${params["id"]}/0/de`)
            //     }
            //     else {
            //         navigate(`/explainmodel/${params["who"]}/${params["id"]}/${params["type"]}/book`)
            //     }

            // }
        }
        catch(e){
            // console.log(e)
        }
    }

    useEffect(()=>{
        apiPro_read_book();
    },[])


    return (
        <div style={{
            position:"fixed",
            top:0,
            zIndex:130010,
            backgroundColor:"rgba(150, 150, 150,0.5)",
            // backgroundColor:"red",
            width:"100%",
            height:"100vh",
            display:"flex",
            justifyContent:"center",
            alignItems:"center"
        }}
            onClick={()=>{
                console.log("##")
                setIsModal(false)
            }}
        >
            <div style={{
                width : "75%",
                minHeight:"55%",
                maxHeight:"60%",
                backgroundColor:"white",
                padding : "15px 20px 30px 20px",
                borderRadius : 10,
                border : "1px solid rgb(200, 200, 200)",
                display:"flex",
                flexDirection:"column",
                justifyContent:"space-between",
                filter: "drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.37))",
            }}
                onClick={(e)=>{
                    e.stopPropagation();
                    e.preventDefault();
                }}
            >
                <div style={{
                    fontWeight:500,
                    display:"flex",
                    flexDirection:"column",
                    fontSize:"18px",
                    fontWeight:700,
                    alignItems:"center",
                }}>
                    다른 교재로 이동
                </div>
                <div style={{
                    marginTop:"10px",
                    // paddingTop:"5px",
                    overflowY:"scroll",
                    // flexDirection:"column",
                    display:"flex",
                    flexWrap:"wrap",
                    flex:1,
                }}>
                    {
                    list.map(v=>
                    <div style={{
                        display:"flex",
                        flexGrow:1,
                        maxWidth:"150px",
                        justifyContent:"center",
                        // width : "calc((100vw - 40px)/3)" 
                    }}>
                        <UiBook
                            isTeacher={true}
                            data={v}
                            isShare={false}
                            navigate={()=>{}}
                            fnClick={(data)=>{
                                console.log("selectData : ",selectData)
                                if(type == "move"){
                                    apiPro_set_move(data["id"])
                                }
                                else if (type == "paste"){
                                    apiPro_set_paste(data["id"])
                                }
                                // console.log("data => ",data)
                            }}
                        />
                    </div>
                    )
                    }
                   
                </div>
                {/* <div style={{
                    height:"40px",
                    display:"flex",
                    justifyContent:"center",
                }}>
                    <UiBtn 
                        btnStyle={{
                            width:"90px",
                            height:"36px",
                            backgroundColor:style.common.t_color,
                            color : "white",
                            fontStyle:"12px",
                        }}
                        title={"닫기"}
                        fnClick={()=>{
                            setIsModal_pick(false)
                        }}
                    />
                </div> */}
            </div>
        </div>
    )
}

export default Modal_qna_book