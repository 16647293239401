import React from "react";
import PlayerButtons from "../PlayerButtons";
import ProgressBarControl from "../ProgressBarControl";
import styles from "./style.module.scss";

const PlayerControls = () => {
  return (
    <div
      className={styles.wrapper}
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <ProgressBarControl />
      <PlayerButtons />
    </div>
  );
};

export default PlayerControls;
