import { StudyBookFolderListItem } from "api/studyBook";
import React, { createContext, useEffect, useMemo, useState } from "react";

export interface StudyBookFolderContextState {
  isDeleteMode: boolean;
  setIsDeleteMode?: (state: boolean) => void;
  checkedFolderList?: StudyBookFolderListItem[];
  addCheckedFolder?: (folder: StudyBookFolderListItem) => void;
  removeCheckedFolder?: (id: number) => void;
  allCheckedFolder?: (list: StudyBookFolderListItem[]) => void;
  allRemoveCheckedFolder?: () => void;
}

export const StudyBookFolderContext = createContext<StudyBookFolderContextState>({
  isDeleteMode: false,
});

const StudyBookFolderContextProvider = ({ children }) => {
  const [isDeleteMode, setIsDeleteMode] = useState<boolean>(false);
  const [checkedFolderList, setCheckedFolderList] = useState<StudyBookFolderListItem[]>([]);
  useEffect(() => {
    setCheckedFolderList([]);
  }, [isDeleteMode]);
  const actions = useMemo(() => {
    const addCheckedFolder = (folder: StudyBookFolderListItem) => {
      setCheckedFolderList((prev) => [...prev, folder]);
    };

    const removeCheckedFolder = (id: number) => {
      setCheckedFolderList((prev) => prev.filter((item) => item.id !== id));
    };
    const allCheckedFolder = (list: StudyBookFolderListItem[]) => {
      setCheckedFolderList(list);
    };
    const allRemoveCheckedFolder = () => {
      setCheckedFolderList([]);
    };
    return {
      isDeleteMode,
      setIsDeleteMode,
      checkedFolderList,
      addCheckedFolder,
      removeCheckedFolder,
      allCheckedFolder,
      allRemoveCheckedFolder,
    };
  }, [isDeleteMode, setIsDeleteMode, checkedFolderList, setCheckedFolderList]);

  return (
    <StudyBookFolderContext.Provider value={actions}>{children}</StudyBookFolderContext.Provider>
  );
};
export default StudyBookFolderContextProvider;
