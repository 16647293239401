import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { CircularProgress, circularProgressClasses } from "@mui/material";
import { showMessageLoadingAtom } from "../../../../../application/recoils/ui/atoms";
import { useSetRecoilState } from "recoil";

const Container = styled.div`
  background: #fff;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  margin-left: auto;
  margin-right: 16px;
  padding: 16px;
  width: 100%;
  max-width: 60vw;
  gap: 8px;
`;

const CancelButton = styled.button`
  background: #353535;
  color: #fff;
  border-radius: 5px;
  padding: 8px 16px;
`;

export default function LoadingView(): JSX.Element {
  const [elapsedTime, setElapsedTime] = useState(0);
  const setLoadingMessage = useSetRecoilState(showMessageLoadingAtom);

  useEffect(() => {
    setElapsedTime(0);

    const timer = setInterval(() => {
      setElapsedTime((prevTime) => prevTime + 1);
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  const formatTime = (seconds: number) => {
    if (seconds < 60) {
      return `${seconds}초`;
    } else {
      const minutes = Math.floor(seconds / 60);
      const remainingSeconds = seconds % 60;
      return `${minutes}분 ${remainingSeconds}초`;
    }
  };

  const handleCancelRequest = () => {
    setLoadingMessage(false);
  };

  return (
    <Container>
      <CircularProgress
        variant="indeterminate"
        disableShrink
        sx={(theme) => ({
          color: "#1a90ff",
          animationDuration: "550ms",
          [`& .${circularProgressClasses.circle}`]: {
            strokeLinecap: "round",
          },
          ...theme.applyStyles("dark", {
            color: "#308fe8",
          }),
        })}
        size={40}
        thickness={4}
      />
      <p>{elapsedTime > 10 ? "조금만 기다려주세요..." : "문제 풀이 중 이에요..."}</p>
      {formatTime(elapsedTime)}
      <CancelButton type={"button"} onClick={handleCancelRequest}>
        취소
      </CancelButton>
    </Container>
  );
}
