import { useQueryClient } from "@tanstack/react-query";
import { ReactComponent as LectureCreate } from "asset/images/icon/lectureCreate.svg";
import { ReactComponent as LectureVideo } from "asset/images/icon/lectureVideo.svg";
import { getUser } from "asset/storage";
import LightBallMenu from "components/molecules/LightBallMenu";
import Modal_regi from "pages/Profile/Modal_regi";
import React, { useCallback, useMemo, useState } from "react";
import { useNavigate } from "react-router";
type LectureConfigButtonProps = {
  menuType?: "single" | "multi";
};
const LectureConfigButton = ({ menuType = "multi" }: LectureConfigButtonProps) => {
  const navigate = useNavigate();

  const queryClient = useQueryClient();
  const userInfo = getUser();
  const [isNewClassModal, setIsNewClassModal] = useState(false);

  const handleNewClassModalOpen = useCallback(() => {
    setIsNewClassModal(true);
  }, [setIsNewClassModal]);

  const handleNewClassModalClose = useCallback(() => {
    setIsNewClassModal(false);
    queryClient.invalidateQueries([
      `/user/${userInfo.id}/class`,
      { classType: "1", isClassroomRequired: true },
    ]);
  }, [queryClient, userInfo]);

  const menuList = useMemo(() => {
    const list = [
      {
        title: "수업영상 업로드 하기",
        onClick: () => navigate("/class/null/video/regist"),
        icon: <LectureVideo />,
      },
    ];
    if (menuType === "multi") {
      list.unshift({
        title: "수업 생성하기",
        onClick: handleNewClassModalOpen,
        icon: <LectureCreate />,
      });
    }
    return list;
  }, [handleNewClassModalOpen, menuType, navigate]);
  return (
    <>
      <LightBallMenu menuList={menuList} />
      {isNewClassModal && <Modal_regi onClose={handleNewClassModalClose} />}
    </>
  );
};

export default LectureConfigButton;
