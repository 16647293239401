import { ReactComponent as SlideIcon } from "asset/images/icon/sort.svg";
import { StudyBookContext } from "pages/StudyBookDetail/context/StudyBookContextProvider";
import React, { Suspense, useCallback, useContext, useEffect, useRef } from "react";
import styled from "styled-components";
import UiLoading from "uiComponents/UiLoading/UiLoading";
import AnswerSlider from "./components/AnswerSlider";
import PageNavigater from "./components/PageNavigater";
import QuestionSlider from "./components/QuestionSlider";
function registMouseDownDrag(
  onDragChange: (deltaX: number, deltaY: number) => void,
  stopPropagation?: boolean
) {
  const handleMouse = (clickEvent: React.MouseEvent<Element, MouseEvent>) => {
    if (stopPropagation) clickEvent.stopPropagation();

    const mouseMoveHandler = (moveEvent: MouseEvent) => {
      const deltaX = moveEvent.screenX - clickEvent.screenX;
      const deltaY = moveEvent.screenY - clickEvent.screenY;
      onDragChange(deltaX, deltaY);
    };

    const mouseUpHandler = () => {
      document.removeEventListener("mousemove", mouseMoveHandler);
    };

    document.addEventListener("mousemove", mouseMoveHandler);
    document.addEventListener("mouseup", mouseUpHandler, { once: true });
  };
  const handleTouch = (clickEvent: React.TouchEvent) => {
    if (stopPropagation) clickEvent.stopPropagation();

    const mouseMoveHandler = (moveEvent: TouchEvent) => {
      const deltaX = moveEvent.targetTouches[0].screenY - clickEvent.targetTouches[0].screenX;
      const deltaY = moveEvent.targetTouches[0].screenY - clickEvent.targetTouches[0].screenY;
      onDragChange(deltaX, deltaY);
    };

    const mouseUpHandler = () => {
      document.removeEventListener("touchmove", mouseMoveHandler);
    };

    document.addEventListener("touchmove", mouseMoveHandler);
    document.addEventListener("touchend", mouseUpHandler, { once: true });
  };
  return {
    onMouseDown: handleMouse,
    onTouchStart: handleTouch,
  };
}

const PdfStudyBookDetail = () => {
  const { topHeight, bottomHeight, containerHeight, setHeightConfig, isVisibleAnswer } =
    useContext(StudyBookContext);

  const containerRef = useRef<HTMLDivElement>(null);

  const initPosition = useCallback(() => {
    const boundary = containerRef.current?.getBoundingClientRect();
    if (boundary) {
      const topHeight = isVisibleAnswer ? boundary.height / 2 : boundary.height;
      const bottomHeight = isVisibleAnswer ? boundary.height - topHeight : 0;
      setHeightConfig({
        topHeight,
        bottomHeight,
        containerHeight: boundary.height,
      });
    }
  }, [isVisibleAnswer, setHeightConfig]);

  const setDragPosition = useCallback(
    (deltaY: number) => {
      const dragedTopHeight = topHeight + deltaY;
      const dragedBottomHeight = containerHeight - dragedTopHeight;
      const maximumHeight = 100;
      if (dragedTopHeight < maximumHeight) {
        return;
      }
      if (dragedBottomHeight < maximumHeight) {
        return;
      }
      setHeightConfig((prev) => ({
        ...prev,
        topHeight: dragedTopHeight,
        bottomHeight: dragedBottomHeight,
      }));
    },
    [containerHeight, topHeight, setHeightConfig]
  );

  useEffect(() => {
    // 스크롤 잠금
    const enableScrollLock = () => {
      const { body } = document;

      if (!body.getAttribute("scrollY")) {
        const pageY = window.pageYOffset;

        body.setAttribute("scrollY", pageY.toString());

        body.style.overflow = "hidden";
        body.style.position = "fixed";
        body.style.left = "0px";
        body.style.right = "0px";
        body.style.bottom = "0px";
        body.style.top = `-${pageY}px`;
      }
    };

    // 스크롤 잠금 해제
    const disableScrollLock = () => {
      const { body } = document;

      if (body.getAttribute("scrollY")) {
        body.style.removeProperty("overflow");
        body.style.removeProperty("position");
        body.style.removeProperty("top");
        body.style.removeProperty("left");
        body.style.removeProperty("right");
        body.style.removeProperty("bottom");

        window.scrollTo(0, Number(body.getAttribute("scrollY")));

        body.removeAttribute("scrollY");
      }
    };
    enableScrollLock();
    return disableScrollLock;
  }, []);

  useEffect(() => {
    window.addEventListener("resize", initPosition);
    return () => {
      window.removeEventListener("resize", initPosition);
    };
  });

  useEffect(() => {
    setTimeout(initPosition, 500);
  }, [isVisibleAnswer]);
  return (
    <Container>
      <PageNavigater />
      <SlideContainer ref={containerRef}>
        <SlideItemContainer style={{ minHeight: topHeight, maxHeight: topHeight }}>
          <Suspense
            fallback={
              <UiLoading isTransparent={true} isText={false} isCircle={false} text="로딩중" />
            }
          >
            <QuestionSlider />
          </Suspense>
        </SlideItemContainer>
        {isVisibleAnswer && (
          <>
            <SlideButton
              {...registMouseDownDrag((deltaX, deltaY) => {
                setDragPosition(deltaY);
              })}
            >
              <SlideIcon />
            </SlideButton>
            <SlideItemContainer style={{ minHeight: bottomHeight, maxHeight: bottomHeight }}>
              <Suspense
                fallback={
                  <UiLoading isTransparent={true} isText={false} isCircle={false} text="로딩중" />
                }
              >
                <AnswerSlider />
              </Suspense>
            </SlideItemContainer>
          </>
        )}
      </SlideContainer>
    </Container>
  );
};

const Container = styled.div`
  padding-top: 27px;
  height: auto;
  display: flex;
  flex-direction: column;
`;

const SlideContainer = styled.div`
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 144px);
  min-height: calc(100vh - 144px);
  border-radius: 10px;
  overflow: hidden;
`;

const SlideItemContainer = styled.div`
  display: flex;
  will-change: max-height, min-height;
`;

const SlideButton = styled.button`
  padding: 4px 24px;
  border-radius: 99px;
  background: var(--Default-Gray-90, #262626);
  cursor: move;
  border: 0;
  margin: -14px auto;
  z-index: 1;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-height: 28px;
  width: 64px;
  @media screen and (min-width: 1180px) {
    min-height: 40px;
    width: 96px;
    margin: -20px auto;
  }
  > svg {
    width: 16px;
    height: 16px;
    @media screen and (min-width: 1180px) {
      width: 24px;
      height: 24px;
    }
  }
`;
export default PdfStudyBookDetail;
