const UiVideo_Style = {
    c : {
        display : "flex",
        flexDirection:'column',
        width : "150px",
        marginRight : "10px",
    },
    video_w : {
        display:"flex",
        justifyContent : "center",
        // width : "150px",
        height : "100px",
    },
    desc : {
        padding : "0px 2px",
        fontSize : "13px",
    }
}

export default UiVideo_Style