import { ArrowBackIos } from "@material-ui/icons";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import search_pic from "../../asset/Img/search_pic.png";
import search_text from "../../asset/Img/search_text.png";
import apiFn from "../../asset/apiClass";
import constants from "../../asset/constants";
import style from "../../asset/style";
import util from "../../asset/util";
import Frame from "../../common/frame/Frame";
import { UiInput } from "../../uiComponents/UiInput/UiInput";
import UiLoading from "../../uiComponents/UiLoading/UiLoading";
import UiSearchBox from "../../uiComponents/UiSearch/UiSearchBox";
import Message_Home_Style from "../Message/Message_Home_Style";
import Message_Style from "../Message/Message_Style";
import Lecture_book from "./Lecture_book";
import Lecture_lecture from "./Lecture_lecture";
import Lecture_people from "./Lecture_people";
import Modal_invite_suup from "./Modal_invite_suup";
import Modal_modi from "./Modal_modi";
import Modal_share_book from "./Modal_share_book";

const SearchBox = ({ setSearch, search, apiPro_read_student }) => {
  let params = useParams();
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        width: "180px",
      }}
    >
      <UiInput
        type="basic"
        basic_container_style={{
          padding: "0px 0px 0px 5px",
        }}
        fnChange={(e) => {
          if (setSearch) setSearch(e.target.value);
          if (search) search = e.target.value;

          apiPro_read_student("", e.target.value);
        }}
        placeholder={"전화번호나 이름을 입력해주세요. "}
        inputClass={params["who"] === "teacher" ? "input_t" : "input_c"}
      />
    </div>
  );
};

const Profile_lecture_detail = ({
  // isTeacher
  fn,
  userInfo,
  setUserInfo,
  fileObj,
  hiddenNav,
  setHiddenNav,
  setFileObj,
  setQnaData,
  setHiddenUrl,
  setAddSearch,
  hiddenUrl,
  explainData,
  setExplainData,
  explainPage,
  setExplainPage,
  isPremium,
  setIsLoading,
  isLoading,
}) => {
  let myInfo_init = {
    name: "",
    tel: "",
    email: "",
    collage: "",
    qtotal: "",
    anscount: 0,
    avgTime: "",
    intro: "",
    nick: "",
    content: "",
  };

  let params = useParams();
  let navigate = useNavigate();

  const [isSearch, setIsSearch] = useState(false);
  const [isText, setIsText] = useState(false);

  const [isTeacher, setIsTeacher] = useState(true);
  const [tap, setTap] = useState("lecture");

  const [qnaCount, setQnaCount] = useState({});

  const [lectureData, setLectureData] = useState({});
  const [lectureData_ori, setLectureData_ori] = useState({});
  const [isJoin, setIsJoin] = useState(true);

  const [myInfo, setMyInfo] = useState(myInfo_init);

  const [isModi, setIsModi] = useState(false);
  const [isModal_share, setIsModal_share] = useState(false);
  const [isModal_invite, setIsModal_invite] = useState(false);

  // 내 정보
  const apiPro_read_myInfo = async () => {
    try {
      let data = await apiFn.comApi({
        state: {
          table: "user_credential", // userInfo.type === 1 ? "student" : "teacher",
          ref: ["A.* |HIQDB_user A on A.index=HIQDB_user_credential.user_id"],
          key: `HIQDB_user_credential.name:${userInfo.id}`,
        },
      });
      setMyInfo(data);
    } catch (e) {
      console.error(e);
      throw e;
    }
  };

  // 교재 정보
  const apiPro_read = async () => {
    if (!userInfo.id) return;
    try {
      let qnaCount = await (
        await fetch(`${constants.apiUrl}/class/${params.id}/user/${userInfo.id}/questions/count`)
      ).json();
      const classData = await (
        await fetch(`${constants.apiUrl}/class?classId=${params.id}&isClassroomRequired=true`)
      ).json();
      setLectureData_ori(classData[0]);
      setQnaCount(qnaCount.count);
    } catch (e) {
      console.error(e);
      alert("수업정보, 문제 정보를 가져오는데 실패했습니다.");
    }
  };

  // 교재 정보 + 내 정보
  const apiPro_read_2 = async () => {
    try {
      let data = await apiFn.comApi({
        state: {
          table: "member_class",
          page: 1,
          amount: 9999,
          sort: `reg_date desc`,
          ref: [
            "HIQDB_class.name|HIQDB_class.id=HIQDB_member_class.cid",
            "HIQDB_class.intro|HIQDB_class.id=HIQDB_member_class.cid",
            "HIQDB_class.address|HIQDB_class.id=HIQDB_member_class.cid",
            "HIQDB_class.tel|HIQDB_class.id=HIQDB_member_class.cid",
            "HIQDB_class.email|HIQDB_class.id=HIQDB_member_class.cid",
            "HIQDB_class.owner|HIQDB_class.id=HIQDB_member_class.cid",
            "HIQDB_class.img|HIQDB_class.id=HIQDB_member_class.cid",
            "HIQDB_class.reg_id|HIQDB_class.id=HIQDB_member_class.cid",
          ],
          where: {
            "HIQDB_member_class.cid": `='${params.id}'`,
            "HIQDB_member_class.mid": `='${userInfo.id}'`,
            "HIQDB_class.type": `='1'`,
          },
        },
      });
      setLectureData({
        ...data.list[0],
      });
      if (data.list.length === 0) {
        setIsJoin(false);
      } else {
        setIsJoin(true);
      }
    } catch (e) {}
  };

  const apiPro_img = async (e) => {
    try {
      setQnaData({});
      setAddSearch({
        cid: params.id,
      });
      setFileObj({
        name: ``,
        file: e,
      });
      setHiddenUrl(window.location.pathname);
      navigate(`/crop/${params.who}/se`);
    } catch (e) {
      console.error(e);
    }
  };

  const apiPro_regi = async (data) => {
    try {
      let sendMsg = {
        type: "suup_apply",
        orderMid: userInfo.id,
        targetMid: userInfo.id,
        cid: params.id,
        channel: constants.channelId,
      };
      if (params.who === "student") {
        sendMsg.type = "student_apply";
      }
      let isOk = await apiFn.comApi({
        state: {
          table: "member_class",
          sendMsg: sendMsg,
          ins: [
            {
              mid: userInfo.id,
              cid: params.id,
              type: params.who == "teacher" ? 6 : 0,
              status: 0,
              reg_date: 0,
            },
          ],
        },
      });
      await apiPro_read();
      await apiPro_read_2();
    } catch (e) {
      console.error(e);
    }
  };

  const getLectureInfo = async () => {
    try {
      setIsLoading(true);
      await Promise.all([apiPro_read(), apiPro_read_2(), apiPro_read_myInfo()]);
    } catch (e) {
      alert("오류가 발생하였습니다.");
      console.error(e);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (params.who === "teacher" || userInfo.type === 2) {
      setIsTeacher(true);
    } else {
      setIsTeacher(false);
    }
    if (params.where.includes("search")) {
      setIsSearch(true);
    } else {
      setIsSearch(false);
    }
  }, [params.who, params.where, userInfo]);

  useEffect(() => {
    if ((!userInfo || Object.keys(userInfo).length === 0) && window.localStorage.getItem("user")) {
      setUserInfo(JSON.parse(window.localStorage.getItem("user")));
    } else if (!userInfo || Object.keys(userInfo).length === 0) {
      navigate("/");
    } else {
      getLectureInfo();
      if (hiddenNav) {
        if (hiddenNav === "lecture&") return;
        if (hiddenNav === "share&") return;
        if (hiddenNav === "profile_student") {
          setTap("student");
        } else if (hiddenNav === "profile_teacher") {
          setTap("teacher");
        } else if (hiddenNav === "profile_book") {
          setTap("book");
        } else {
          setTap(hiddenNav);
        }
        setHiddenNav("");
      }
    }
  }, [userInfo, hiddenNav]);

  useEffect(() => {
    if (isSearch) {
      setHiddenUrl("");
    }
  }, [isSearch]);

  useEffect(() => {
    if (!isModi) {
      apiPro_read();
    }
  }, [isModi]);

  return (
    <Frame
      isPremium={isPremium}
      setHiddenUrl={setHiddenUrl}
      backEvent={() => {
        setHiddenUrl("");
      }}
      navi={hiddenUrl ? hiddenUrl : `/lecture`}
      userInfo={userInfo}
      type="2"
      title={
        <div
          style={{
            fontFamily: "Roboto",
            fontStyle: "normal",
            fontWeight: 400,
            fontSize: "24px",
            lineHeight: "28px",
            textAlign: "center",
            letterSpacing: "2px",
          }}
        >
          {/* 수학 만점왕 */}
          {util.textTrim(lectureData_ori?.name, 17)}
        </div>
      }
      isSearch={false}
    >
      <div
        style={{
          width: "100%",
          marginTop: "5px",
        }}
      >
        <div
          style={{
            display: "flex",
            // paddingTop : "10px",
            padding: style.common.sub_padding,
          }}
        >
          <div
            style={{
              ...Message_Style.tap_text,
              fontSize: "18px",
              fontWeight: 700,
              // fontSize : '16px',
              marginRight: "30px",
              borderBottom: tap == "lecture" ? "2px solid" : "none",
              borderBottomColor: tap == "lecture" ? "#3D3A39" : "none",
              color: tap == "lecture" ? "#3D3A39" : style.common.fontColor_2,
            }}
            onClick={() => {
              setTap("lecture");
            }}
          >
            강의
          </div>
          {lectureData["status"] == 1 && (
            <>
              <div
                style={{
                  ...Message_Style.tap_text,
                  fontSize: "18px",
                  fontWeight: 700,
                  // fontSize : '16px',
                  marginRight: "30px",
                  borderBottom: tap == "book" ? "2px solid" : "none",
                  borderBottomColor: tap == "book" ? "#3D3A39" : "none",
                  color: tap == "book" ? "#3D3A39" : style.common.fontColor_2,
                }}
                onClick={() => {
                  setTap("book");
                }}
              >
                교재
              </div>
              <div
                style={{
                  ...Message_Style.tap_text,
                  fontSize: "18px",
                  fontWeight: 700,
                  // fontSize : '16px',
                  marginRight: "30px",
                  borderBottom: tap == "people" ? "2px solid" : "none",
                  borderBottomColor: tap == "people" ? "#3D3A39" : "none",
                  color: tap == "people" ? "#3D3A39" : style.common.fontColor_2,
                }}
                onClick={() => {
                  setTap("people");
                }}
              >
                참여자
              </div>
            </>
          )}
        </div>
        <div
          style={{
            marginTop: "10px",
            width: "100%",
            // padding : "0px 10px",
          }}
        >
          {tap == "lecture" ? (
            <Lecture_lecture
              setIsModi={setIsModi}
              explainPage={explainPage}
              setExplainPage={setExplainPage}
              explainData={explainData}
              setExplainData={setExplainData}
              fn={fn}
              userInfo={userInfo}
              isTeacher={isTeacher}
              lectureData={lectureData}
              lectureData_ori={lectureData_ori}
              myInfo={myInfo}
              isJoin={isJoin}
              apiPro_regi={apiPro_regi}
              apiPro_read_2={apiPro_read_2}
              apiPro_read_myInfo={apiPro_read_myInfo}
              setFileObj={setFileObj}
              fileObj={fileObj}
              qnaCount={qnaCount}
              hiddenNav={hiddenNav}
              setHiddenNav={setHiddenNav}
              isLoading={isLoading}
            />
          ) : tap == "book" ? (
            <Lecture_book
              isModal_share={isModal_share}
              fn={fn}
              isTeacher={isTeacher}
              userInfo={userInfo}
              hiddenNav={hiddenNav}
              setHiddenNav={setHiddenNav}
              lectureData={lectureData}
              setHiddenUrl={setHiddenUrl}
              setIsModal_share={setIsModal_share}
            />
          ) : tap == "people" ? (
            <Lecture_people
              fn={fn}
              isModal_invite={isModal_invite}
              setIsModal_invite={setIsModal_invite}
              setFileObj={setFileObj}
              userInfo={userInfo}
              isTeacher={isTeacher}
              hiddenNav={hiddenNav}
              setHiddenNav={setHiddenNav}
              lectureData_ori={lectureData_ori}
              lectureData={lectureData}
            />
          ) : (
            <></>
          )}
        </div>
      </div>
      {isText ? (
        <div
          style={{
            // display:"flex",
            // flexDirection:"column",
            // display : "flex",
            // flex:1,
            height: "100vh",
            // alignItems:"center",
            position: "fixed",
            top: 0,
            width: "100%",
            borderBottom: "1px solid",
            borderColor: style.common.borderColor,
            // height:"60px",
            backgroundColor: "rgba(200,200,200,0.5)",
          }}
        >
          <div
            style={{
              padding: "10px",
              backgroundColor: "white",
            }}
          >
            <div
              style={{
                // padding : style.common.padding,
                padding: "10px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
              }}
            >
              <div
                style={{
                  display: "flex",
                  fontSize: "8sp",
                  fontWeight: "bold",
                  alignItems: "center",
                  zIndex: 100,
                }}
              >
                <ArrowBackIos
                  onClick={() => {
                    // navigate(`/message/${params["who"]}/home`)
                    navigate(`/lecture/detail/${params["who"]}/${params["id"]}/de`);
                    setIsText(false);
                  }}
                />
                검색
              </div>
            </div>
            <div
              style={{
                // padding : "0px 20px",
                // display:"flex",
                // flex:1,

                width: "94%",
              }}
            >
              <UiSearchBox
                inputClass={isTeacher ? "input_t" : "input_s"}
                fnChange={(e) => {
                  // setSearch(e.target.value)
                  setFileObj({
                    text: e.target.value,
                    isText: true,
                  });
                }}
                fnClick={() => {
                  setAddSearch({
                    cid: params["id"],
                  });
                  navigate("/searchresult/" + params["who"] + "/text/de");
                  // apiPro_read();
                }}
                isTeacher={isTeacher}
              />
            </div>
            {/* 보조 설명이 필요할 때 해당 공간에 보조 글귀를 남깁니다. */}
          </div>
        </div>
      ) : (
        isSearch && (
          <div
            style={{
              // display:"flex",
              // flexDirection:"column",
              // display : "flex",
              // flex:1,
              height: "100vh",
              // alignItems:"center",
              position: "fixed",
              top: 0,
              width: "100%",
              borderBottom: "1px solid",
              borderColor: style.common.borderColor,
              // height:"60px",
              backgroundColor: "rgba(200,200,200,0.5)",
            }}
          >
            <div
              style={{
                padding: "10px",
                backgroundColor: "white",
              }}
            >
              <div
                style={{
                  // padding : style.common.padding,
                  padding: "10px",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    fontSize: "8sp",
                    fontWeight: "bold",
                    alignItems: "center",
                    zIndex: 100,
                  }}
                >
                  <ArrowBackIos
                    onClick={() => {
                      // navigate(`/lecture/detail/${params["who"]}/${params["id"]}/de`)
                      navigate(`/lecture/detail/${params["who"]}/${params["id"]}/de`);

                      setIsSearch(false);
                    }}
                  />
                  검색
                </div>
              </div>
              <div
                style={{
                  // padding : "0px 20px",
                  // display:"flex",
                  // flex:1,

                  width: "94%",
                }}
              >
                {/* <UiSearchBox
                            inputClass={isTeacher ? "input_t" : "input_s"}
                            fnChange={(e)=>{
                                // setSearch(e.target.value)
                                setFileObj({
                                    text : e.target.value,
                                    isText : true
                                })
                            }}
                            fnClick={()=>{
                                navigate("/searchresult/"+params["who"]+"/text/de")
                                // apiPro_read();
                            }}
                            isTeacher={isTeacher}
                        /> */}
                <div style={Message_Home_Style.search_w}>
                  <div style={Message_Home_Style.search_b}>
                    <input
                      id="picture_find"
                      type="file"
                      accept="image/*"
                      style={{
                        display: "none",
                      }}
                      onChange={apiPro_img}
                    />
                    <label
                      htmlFor="picture_find"
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        width: "100%",
                        height: "100%",
                      }}
                    >
                      <img src={search_pic} style={Message_Home_Style.search_i_1} />
                      <div style={Message_Home_Style.text_2}>사진으로 해답 찾기</div>
                    </label>
                  </div>
                  <div
                    style={Message_Home_Style.search_b}
                    onClick={() => {
                      setIsText(true);
                      // navigate(`/message/${params["who"]}/home&text`)
                    }}
                  >
                    <img src={search_text} style={Message_Home_Style.search_i_2} />
                    <div style={Message_Home_Style.text_2}>텍스트로 해답 찾기</div>
                  </div>
                </div>
              </div>
              {/* 보조 설명이 필요할 때 해당 공간에 보조 글귀를 남깁니다. */}
            </div>
          </div>
        )
      )}
      {isModi && (
        <Modal_modi
          lectureData={lectureData_ori}
          setIsModal={setIsModi}
          userInfo={userInfo}
          title="수업 정보 설정"
          // fileObj={fileObj}
          // setFileObj={setFileObj}
        />
      )}
      {isModal_share && <Modal_share_book userInfo={userInfo} setIsModal={setIsModal_share} />}
      {isModal_invite && (
        <Modal_invite_suup type="suup" userInfo={userInfo} setIsModal={setIsModal_invite} />
      )}

      {isLoading && <UiLoading isText={false} text={""} />}
    </Frame>
  );
};

export { Profile_lecture_detail, SearchBox };
