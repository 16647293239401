import { axiosInstance } from "./common";
import { useMutation, useQueryClient } from "@tanstack/react-query";

const saveSemohaeAnswer = async ({ questionId, ownerId, semohaeAnswer }) => {
  const response = await axiosInstance.post("/semohae/semohaeAnwer", {
    questionId,
    ownerId,
    semohaeAnswer,
  });
  return response.data;
};

export const useSaveSemohaeAnswer = () => {
  const queryClient = useQueryClient();

  return useMutation(saveSemohaeAnswer, {
    onSuccess: () => {
      queryClient.invalidateQueries(["questions"]);
    },
  });
};
