import { gql } from "graphql-request";

export const OX_NOTE_QNA_IN_FOLDER = gql`
  query GetQnaListInFolder($folderId: ID!, $memberId: ID!, $page: Int, $limit: Int) {
    getQnaListInFolder(folderId: $folderId, memberId: $memberId, page: $page, limit: $limit) {
      success
      qna {
        qid_HIQDB_online_qna {
          id
          img
          original
          original_img
          sid
          tid
          HIQDB_online_qna_msgs {
            content
          }
        }
      }
      currentPage
      totalPage
    }
  }
`;
