import { gql } from "@apollo/client";

export const GET_DEFAULT_ACADEMY = gql`
  query GetDefaultAcademy($memberId: ID!) {
    getDefaultAcademy(memberId: $memberId) {
      success
      error {
        code
        message
      }
      academy {
        id
        name
        pid
        reg_id
        reg_date
        intro
        address
        tel
        email
        stype
        del_date
        assign_date
        owner
        memberType
        lectureStatus
        joinCnt
      }
    }
  }
`;

export const GET_ON_PAYMENT_ACADEMY = gql`
  query GetOnPaymentAcademy($memberId: ID!) {
    getOnPaymentAcademy(memberId: $memberId) {
      academies {
        id
        name
        reg_id
        reg_date
        intro
        address
        tel
        email
        stype
        owner
      }
      error {
        code
        message
      }
      success
    }
  }
`;
