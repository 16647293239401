import { useBelongClassList } from "api/classMain";
import { ReactComponent as FilterIcon } from "asset/images/icon/filter.svg";
import Font from "components/atoms/Font";
import BottomSheet from "components/molecules/BottomSheet";
import LectureSearchItem from "pages/LectureVideoSearch/components/LectureSearchItem";
import React, { useCallback, useMemo } from "react";
import styled from "styled-components";

const ClassBelong = () => {
  const { data } = useBelongClassList();
  const [sort, setSort] = React.useState("");
  const list = useMemo(() => {
    let sortData = data;
    if (sort === "name")
      sortData = sortData.sort((a, b) => a.class_name?.localeCompare(b.class_name));
    else if (sort === "member")
      sortData = sortData.sort((a, b) => b.student_count - a.student_count);
    else if (sort === "reg_date")
      sortData = sortData.sort((a, b) => b.class_reg_date - a.class_reg_date);
    return sortData;
  }, [data, sort]);

  const [openFilter, setOpenFilter] = React.useState(false);
  const handleClose = useCallback(() => {
    setOpenFilter(false);
  }, []);

  return (
    <Container>
      <SortContainer>
        <Font
          color="var(--neutral-color-natural-70, var(--Default-Gray-90, #262626))"
          styleName="KR/bold/$KR.BL.BOL.16"
        >
          내가 속한 수업
        </Font>
        <FilterIcon onClick={() => setOpenFilter(true)} />
      </SortContainer>
      <ScrollContainer>
        {list?.map((item) => (
          <li key={item.cid}>
            <LectureSearchItem
              {...item}
              id={item.cid}
              classType="belong"
              isMainList
              img={item.img ?? item.bookId}
            />
          </li>
        ))}
      </ScrollContainer>
      <BottomSheet
        title={"상세필터"}
        open={openFilter}
        onClose={handleClose}
        menuList={[
          {
            title: "개설순",
            onClick() {
              setSort("reg_date");
              handleClose();
            },
          },
          {
            title: "이름순",
            onClick() {
              setSort("name");
              handleClose();
            },
          },
          {
            title: "수강 인원순",
            onClick() {
              setSort("member");
              handleClose();
            },
          },
        ]}
      ></BottomSheet>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0;
  margin-bottom: 20px;
`;

const SortContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
  @media screen and (min-width: 1180px) {
    margin-top: 24px;
    margin-bottom: 40px;
  }
`;

const ScrollContainer = styled.ul`
  padding-inline-start: 0;
  scroll-snap-type: y mandatory;
  gap: 24px;
  display: flex;
  flex-direction: column;
  height: 100%;
  @media screen and (min-width: 1180px) {
    overflow: auto;
    gap: 40px;
  }
`;

export default ClassBelong;
