import apiFn from "asset/apiClass";
import { ReactComponent as RecycleIcon } from "asset/images/icon/recycle.svg";
import { getUser } from "asset/storage";
import { useConfirm } from "providers/ConfirmProvider";
import { showToast } from "providers/ToastProvider";
import React from "react";
import { useNavigate, useParams } from "react-router";
import { WhoIs, WhoType } from "types/enum";
import { MenuItemContainer } from "..";

const StudyBookDelete = () => {
  const { bookId } = useParams();

  const userInfo = getUser();
  const navigate = useNavigate();
  const { showConfirm } = useConfirm();
  const handleDelete = async () => {
    try {
      let isOk = await apiFn.deleteBook({
        state: {
          boid: bookId,
          orderMid: userInfo["id"],
        },
      });
      navigate(
        `/bookmain/${userInfo.type === WhoType.student ? WhoIs.student : WhoIs.teacher}/main`
      );
    } catch (e) {
      showToast({
        message: "교재 삭제에 실패했습니다.",
        type: "error",
        title: "삭제 실패",
      });
    }
  };
  return (
    <MenuItemContainer
      onClick={() => {
        showConfirm({
          title: "교재를 삭제할까요?",
          message: "삭제된 교재는 휴지통에서 30일간 보관 후 영구삭제 됩니다.",
          onConfirm: handleDelete,
          icon: <RecycleIcon />,
        });
      }}
    >
      교재 삭제
      <RecycleIcon />
    </MenuItemContainer>
  );
};

export default StudyBookDelete;
