import { React , useState , useEffect } from 'react'
import style from '../../asset/style'
import { useNavigate, useParams } from 'react-router'
import file from '../../asset/Img/file.png'
import Academy_main_Style from './Academy_main_Style'
import { Add } from '@material-ui/icons'
import UiBtn from '../../uiComponents/UiBtn/UiBtn'
import Academy_book_Style from './Academy_book_Style'
import UiFolder from '../../uiComponents/UiFolder/UiFolder'
import UiBook from '../../uiComponents/UiBook/UiBook'
import UiTextBtn from '../../uiComponents/UiTextBtn/UiTextBtn'
import UiTopPopTextBtn from '../../uiComponents/UiTopPopTextBtn/UiTopPopTextBtn'
import constants from '../../asset/constants'
import apiFn from '../../asset/apiClass'
import util from '../../asset/util'


const Academy_book = ({
    scrollKey,
    setScrolllKey,
    userInfo,
    isTeacher,
    s_bookList,
    setS_BookList,
    setIsSearch,
    setCreateObj
}) => {

    let navigate = useNavigate()
    let params = useParams();

    var [selectData,setSelectData] = useState({})
    var [selectData_l,setSelectData_l] = useState(0)

    var [selectData_book,setSelectData_book] = useState({})
    var [selectData_book_l,setSelectData_book_l] = useState(0)

    const [isEdit , setIsEdit] = useState(false);
    const [isBookEdit,setIsBookEdit] = useState(false)

    var [bookList,setBookList] = useState([])
    
    var [folderList,setFolderList] = useState([]);

    // 폴더 생성
    const apiPro_set_folder = async (name) => {
        try {
            let isOk = await apiFn.comApi({
                state : {
                    table : "folder",
                    ins : [
                        { 
                            name : name ,
                            ftype : 0 ,
                            mid : userInfo["id"],
                            open : 1,
                            usee : 1,
                        }
                    ]
                }
            })
            await apiPro_read_folder();
        }
        catch(e){
        }
    }

    const apiPro_del_folder = async () => {
        try {
            let delFid = []
            let delArray = Object.keys(selectData).reduce((a,v,i)=>{
                delFid.push(v)
                return ([
                    ...a,
                    { id : v}
                ])
            },[])

            let isOk = await apiFn.comApi({
                state : {
                    delFid : delFid,
                    table : "folder",
                    del : delArray
                }
            })

            await apiPro_read_folder();
            await apiPro_read();
        }
        catch(e){
        }
    }

    // 폴더 리스트
    const apiPro_read_folder = async () => {
        try {
            let data = await apiFn.comApi({
                state : {
                    table : "folder",
                    page : 1,
                    amount : 9999,
                    sort : "name desc",
                    where : {
                        "HIQDB_folder.mid" : `='${userInfo["id"]}'`,
                        "HIQDB_folder.ftype" : `='0'`,
                    }
                }
            })
            // console.log(data)
            setFolderList([
                ...data["list"]
            ])
        }
        catch(e){
        }
    }

    // 교재 리스트
    const apiPro_read = async () => {
        try {
            if(params["who"] == "teacher"){
                let isOk = await apiFn.comApi({
                    state : {
                        table : "book",
                        page : 1,
                        amount : 999,
                        u_id : userInfo["id"],
                        sort : "reg_date desc",
                        ref : [
                            'HIQDB_book_link.cid|HIQDB_book_link.bid=HIQDB_book.id',
                            'HIQDB_book_link.type|HIQDB_book_link.bid=HIQDB_book.id',
                            'HIQDB_book_link.status as link_status|HIQDB_book_link.bid=HIQDB_book.id',
                            // 'HIQDB_book_subs.name as subs_name|HIQDB_book_subs.bid=HIQDB_book.id',
                        ],
                        out :[
                            'HIQDB_book_subs.name as subs_name:bid:id'
                        ],
                        where : {
                            "HIQDB_book_link.mid" : `= '${userInfo["id"]}'`,
                            "HIQDB_book.status" : `!= '9'`,
                            "HIQDB_book_link.fid" : `= 0`,
                            "HIQDB_book_link.status" : "!= 0",
                            "HIQDB_book_link.type" : "!= 9",
                            // "HIQDB_book_link.cid" : `= 0`,
                        }
                        
                    }
                })
    
                // console.log("list :", isOk["list"])

                let filter_bookList = isOk["list"].reduce((acc, current) => {
                    if (acc.findIndex(({ id }) => id === current.id) === -1) {
                      acc.push(current);
                    }
                    return acc;
                  }, []);
                   
                // console.log("list2 qq :", filter_bookList)

                let bookList = []
                filter_bookList.map((v, i)=>{
                    if(v["type"] == 4){
                        v["name"] = v["subs_name"]
                    }
                    if(v["type"] == 8){
                        if(v["link_status"] == 5){
                            v["status"] = v["link_status"]
                        }
                        else {  
                            v["name"] = v["subs_name"]
                        }
                    }
                    bookList.push(v)
                })

                console.log("bookList : ",bookList);
                setBookList([
                    // ...isOk["list"]
                    ...bookList
                ])
            }
            else {
                // let isOk = await apiFn.studentBooklist({
                //     state : {
                //         sid : userInfo["id"],
                //         page : 1,
                //         amount : 9999,
                //         sort : "reg_date desc",
                //         fid : 0,
                //     }
                // })

                let isOk = await apiFn.bookList({
                    state : {
                        sid : userInfo["id"],
                    }
                })

                let isOk_subs = await apiFn.comApi({
                    state : {
                        table : "book",
                        page : 1,
                        amount : 999,
                        u_id : userInfo["id"],
                        sort : "reg_date desc",
                        ref : [
                            'HIQDB_book_link.cid|HIQDB_book_link.bid=HIQDB_book.id',
                            'HIQDB_book_link.type|HIQDB_book_link.bid=HIQDB_book.id',
                            // 'HIQDB_book_subs.name as subs_name|HIQDB_book_subs.bid=HIQDB_book.id',
                        ],
                        out :[
                            'HIQDB_book_subs.name as subs_name:bid:id'
                        ],
                        where : {
                            "HIQDB_book_link.mid" : `= '${userInfo["id"]}'`,
                            "HIQDB_book.status" : `!= '9'`,
                            "HIQDB_book_link.fid" : `= 0`,
                            "HIQDB_book_link.status" : "!= 0",
                            // "HIQDB_book_link.cid" : `= 0`,
                        }
                        
                    }
                })
                console.log("student Book Data => ", isOk)
                console.log("student Subs Book Data => ",isOk_subs)

                setBookList([
                    ...isOk["list"],
                    ...isOk_subs["list"]

                ])
            }
        }
        catch(e){
            console.log("ACADEMY BOOK ERROR : ",e)
        }
    }

    // 교재 이름 변경
    const apiPro_modi = async (name) => {
        try {
            // console.log(name)
            let isOk = await apiFn.comApi({
                state : {
                    table : "book",
                    set: [ 
                        { 
                            key: `id:${Object.keys(selectData_book)[0]}`, 
                            value: { name : name } 
                        } 
                    ]
                }
            })

            window.alert("변경되었습니다.")
            setIsEdit(false)
            setIsBookEdit(false)
            util.postWeb("isModal_off")
            setIsSearch(true)
            setSelectData({})
            setSelectData_book({})
            await apiPro_read();
            // navigate(-1)

        }
        catch(e){
        }
    }


    // 교재 삭제
    const apiPro_del = async ( pw , bid) => {
        try {
            let isLogin = await apiFn.fnSignChk({
                state : {
                    id : userInfo["id"],
                    pw : pw
                }
            })

            // console.log("isLogin -=> ",isLogin)
            if(!isLogin)return;
            let bid_array = Object.keys(selectData_book).reduce((a,v,i)=>{
                return [
                    ...a,
                    { 
                        // key : `bid : ${v} and mid : ${userInfo["id"]}`
                        bid : v,
                        mid : userInfo["id"]
                    }
                ]
            },[])

            // console.log("bid_array => " ,bid_array)
            
            let isOK = await apiFn.comApi({
                state : {
                    table : "book_link",
                    del : bid_array

                }
            })


            setIsEdit(false)
            setIsBookEdit(false)
            util.postWeb("isModal_off")
            setIsSearch(true)
            setSelectData({})
            setSelectData_book({})
            await apiPro_read();
        }
        catch(e){
            util.c_err_log(`apiPro_del Error => ${e}`)
        }
    }


    useEffect(()=>{
        selectData_l = 0;
        // console.log("@@ ",selectData);
        Object.keys(selectData).map((v,i)=>{
            if(selectData[v] == "Y"){
                selectData_l = selectData_l + 1
            }
            else if (selectData[v] == "N") {
                if(selectData_l == 0)return;
                selectData_l = selectData_l - 1
            }
        })
        setSelectData_l(selectData_l)
    },[selectData])

    useEffect(()=>{
        selectData_book_l = 0;
        // console.log("value -> ",selectData_book)
        // console.log("value -> ",Object.entries(selectData_book))
        // console.log("value -> ",Object.keys(selectData_book))
        Object.keys(selectData_book).map((v,i)=>{
            if(selectData_book[v] == "Y"){
                selectData_book_l = selectData_book_l + 1
            }
            else if (selectData_book[v] == "N") {
                if(selectData_book_l == 0)return;
                selectData_book_l = selectData_book_l - 1
            }
        })
        setSelectData_book_l(selectData_book_l)
        // console.log(selectData_book)
    },[selectData_book])

    useEffect(()=>{
        apiPro_read();
        apiPro_read_folder();
        // alert(window.innerWidth)
        if(window.ReactNativeWebView){
            document.addEventListener("message", async (e)=>{
                if(e.data == "isModal"){
                    setIsEdit(false)
                    setIsBookEdit(false)
                }
            })
        }
        return ()=>{
            try {
                document.removeEventListener("message")
            }
            catch(e){
            }
        }
    },[])


    return (
        <div style={Academy_main_Style.c}>
             {
            (isEdit || isBookEdit) &&
            <UiTopPopTextBtn 
                title='편집 취소'
                isTeacher={isTeacher}
                fnClick={()=>{
                    setIsEdit(false)
                    setIsBookEdit(false)
                    util.postWeb("isModal_off")
                    setIsSearch(true)
                    setSelectData({})
                    setSelectData_book({})
                }}
            />
            }
            {
            isEdit ?
            (
            selectData_l > 0 &&
            <div style={{
                ...Academy_book_Style.edit_section_w,
                borderTop: isEdit ? "none" : "1px solid",
                borderColor: style.common.borderColor,
            }}>
                선택된 폴더를
                <div style={Academy_book_Style.textBtn_w}>
                    <UiTextBtn
                        text={"삭제"}
                        btnStyle={{
                            ...Academy_book_Style.textBtn,
                            marginRight : "7.5px",
                        }}
                        fnClick={()=>{
                            let isOk = window.confirm(constants.confirm.cf_3)
                            if(isOk){ 
                                setIsEdit(false)
                                setIsBookEdit(false)
                                util.postWeb("isModal_off")
                                setIsSearch(true)
                                setSelectData({})
                                setSelectData_book({})
                                apiPro_del_folder();
                            }
                        }}
                    />
                    {
                    selectData_l == 1 &&
                    <UiTextBtn
                        text={"이름 변경"}
                        btnStyle={{
                            ...Academy_book_Style.textBtn,
                            color : style.common.fontColor_1,
                            marginLeft : "7.5px"
                        }}
                        fnClick={()=>{
                            navigate("/academybook/"+params["who"]+"/"+Object.keys(selectData)[0]+"/modi")
                        }}
                    />
                    }
                </div>
            </div>
            )
            :
            <div style={Academy_book_Style.t_w}>
                <div style={Academy_book_Style.btn_w}>
                    <UiBtn
                        btnStyle={Academy_book_Style.btn}
                        title={
                            <div style={Academy_book_Style.btn_title}>
                                <Add style={Academy_book_Style.btn_Add} />
                                <div style={{
                                    paddingLeft : "12px"
                                }}>
                                    폴더 생성
                                </div>
                            </div>
                        }
                        fnClick={()=>{
                            setCreateObj({
                                api : apiPro_set_folder,
                                title : "폴더 생성",
                                input_title : '폴더 이름',
                            })
                            navigate(`/create/${params["who"]}`)
                            // let name = window.prompt("폴더 이름을 입력해주세요.")
                            // apiPro_regi(name)
                        }}
                        // fnClick={()=>{
                        //     let fName = window.prompt("폴더 이름을 입력해주세요.");
                        //     // console.log(fName)
                        //     if(fName){
                        //         apiPro_set_folder(fName)
                        //     }
                            
                        // }}
                    />
                </div>
                <div style={Academy_book_Style.btn_w}>
                    {
                    isTeacher &&
                    <UiBtn
                        btnStyle={Academy_book_Style.btn}
                        title={
                            <div style={Academy_book_Style.btn_title}>
                                <Add style={Academy_book_Style.btn_Add} />
                                <div style={{
                                    paddingLeft : "12px"
                                }}>
                                    교재 생성
                                </div>
                            </div>
                        }
                        fnClick={()=>{
                            navigate("/academybookregi/"+params["who"]+"/1")
                        }}
                    />
                    }
                </div>
            </div>
            }
            <div style={Academy_book_Style.list_folder_w}>
            {
            folderList.map((v,i) => 
                <UiFolder
                    data={v}
                    setSelectData={setSelectData}
                    selectData={selectData}
                    isEdit={isEdit}
                    isTeacher={isTeacher}
                    setIsSearch={setIsSearch}
                    fnLongClick={(id)=>{
                        setIsSearch(false)
                        setIsEdit(true)
                        util.postWeb("isModal")
                        setSelectData({
                            ...selectData,
                            [id] : "Y"
                        })
                    }}
                    fnCheck={(e)=>{
                        if(selectData[e.target.id] == "Y"){
                            delete selectData[e.target.id]
                            setSelectData({
                                ...selectData,
                            })
                        }
                        else {
                            setSelectData({
                                ...selectData,
                                [e.target.id] : "Y"
                            })
                        }
                    }}
                    key={"mark_folder_"+i}
                    id={i}
                    navigate={navigate}
                    navi={"/academybook/"+params["who"]+"/"+v["id"]+"/list"}
                    params={params}
                />
            )
            }
            </div>
            {
            isBookEdit &&
            (
            selectData_book_l > 0 &&
            <div style={{
                ...Academy_book_Style.edit_section_w,
                padding : "15px 0px",
                borderTop: isBookEdit ? "none" : "1px solid",
                borderColor: style.common.borderColor,
                fontSize : "14px",
            }}>
                {`선택된 ${Object.keys(selectData_book).length}개의 교재를`}
                <div style={Academy_book_Style.textBtn_w}>
                    {/* {
                    isTeacher &&
                    <UiTextBtn
                        text={"삭제"}
                        btnStyle={{
                            ...Academy_book_Style.textBtn,
                            marginRight : "7.5px",
                        }}
                        fnClick={()=>{
                            let isOk = window.prompt("교재 삭제를 위해 본인확인이 필요합니다.")
                            apiPro_del(isOk)
                        }}
                    />
                    } */}
                    <UiTextBtn
                        text={"폴더 이동"}
                        btnStyle={{
                            ...Academy_book_Style.textBtn,
                            color : style.common.fontColor_1,
                            marginLeft : "7.5px"
                        }}
                        fnClick={()=>{
                            let array = Object.keys(selectData_book).reduce((a,v,i)=>{
                                return [
                                    ...a,
                                    v
                                ]
                            },[])
                            setS_BookList([
                                ...array
                            ])
                            navigate(`/academyfolder/${params["who"]}`)
                        }}
                    />
                    {
                    // Object.keys(selectData_book)[0]
                    isTeacher &&
                    selectData_book_l == 1 &&
                    (
                        bookList.reduce((a,v,i)=>{
                            let obj = {
                                [v["id"]] : v["type"]
                            }
                            return {
                                ...a,
                                ...obj
                            }
                        },{})[Object.keys(selectData_book)[0]] == "5"
                    ) &&
                    <UiTextBtn
                        text={"이름 변경"}
                        btnStyle={{
                            ...Academy_book_Style.textBtn,
                            color : style.common.fontColor_1,
                            marginLeft : "7.5px"
                        }}
                        fnClick={()=>{
                            setCreateObj({
                                api : apiPro_modi,
                                title : "교재 이름 변경",
                                input_title : '교재 이름',
                            })
                            navigate(`/create/${params["who"]}`)
                            // let isOk = window.prompt("변결할 교재의 이름을 입력해주세요.")
                            // if(isOk){
                            //     apiPro_modi(isOk)
                            // }
                        }}
                    />
                    }
                </div>
            </div>
            )
            }
            {
            isTeacher &&
            <div style={{
                display:'flex',
                justifyContent:"center",
                width:"100%",
            }}>
                <div style={{
                    // ...Academy_book_Style.btn_w,
                    width : '160px',
                    // width : '260px',
                    paddingBottom:"17px",
                    marginRight:"10px"
                }}>
                    <UiBtn
                        btnStyle={{
                            ...Academy_book_Style.btn,
                            backgroundColor : style.common.t_color,
                            borderColor : style.common.t_color,
                        }}
                        title={
                            <div style={{
                                ...Academy_book_Style.btn_title,
                                fontSize : "14px",
                                color : "white"
                            }}>
                                교재 미지정 질문
                            </div>
                        }
                        fnClick={()=>{
                            navigate(`/book/${params["who"]}/0/0/de`)
                        }}
                    />
                </div>
                <div style={{
                    // ...Academy_book_Style.btn_w,
                    width : '120px',
                    paddingBottom:"17px",
                    marginLeft:"10px"
                }}>
                    <UiBtn
                        btnStyle={{
                            ...Academy_book_Style.btn,
                            backgroundColor : style.common.fontColor_3,
                            borderColor : style.common.fontColor_3,
                        }}
                        title={
                            <div style={{
                                ...Academy_book_Style.btn_title,
                                fontSize : "14px",
                                color : "white"
                            }}>
                                삭제된 질문
                            </div>
                        }
                        fnClick={()=>{
                            // book 컴포넌트 이동 삭제된 질문 리스트 페이지
                            navigate(`/book/${params["who"]}/0/0/del`)
                        }}
                    />
                </div>
            </div>
            }   
            <div style={{
                display:"flex",
                width:"100%",
                // justifyContent:"center",
                // backgroundColor:"green",
            }}>
                <div style={{
                    ...Academy_book_Style.list_book_w,
                    // justifyContent:util.isODD(bookList.length) ? "space-between" : "space-around",
                    // maxWidth : window.innerWidth < 400 && "320px",
                }}>
                    {
                    bookList.map((v,i)=>
                    <UiBook
                        isOuth={v["type"]}
                        inStatus={v["status"]}
                        setScrolllKey={setScrolllKey}
                        scrollKey={scrollKey}
                        data={v}
                        key={"mark_folder_"+i}
                        id={i}
                        fnClick={(d)=>{
                            setScrolllKey(d["id"])
                        }}
                        navigate={navigate}
                        navi={
                            params["who"] == "teacher" ?
                            `/book/${params["who"]}/${v["id"]}/0/de`
                            :
                            `/book/${params["who"]}/${v["id"]}/${v["classID"] ? v["classID"] : "subs"}/de`
                        }
                        params={params}
                        isShare={params["who"] == "teacher" ? true : false}
                        setSelectData={setSelectData_book}
                        selectData={selectData_book}

                        isEdit={isBookEdit}
                        isTeacher={isTeacher}
                        setIsSearch={setIsSearch}

                        fnLongClick={(data)=>{
                            setIsSearch(false)
                            setIsBookEdit(true)
                            util.postWeb("isModal")
                            setSelectData_book({
                                ...selectData_book,
                                [data["id"]] : "Y"
                            })
                        }}
                        fnCheck={(e,data)=>{
                            if(selectData_book[data["id"]] == "Y"){
                                delete selectData_book[data["id"]]
                                setSelectData_book({
                                    ...selectData_book,
                                })
                            }
                            else {
                                setSelectData_book({
                                    ...selectData_book,
                                    [data["id"]] : "Y"
                                })
                            }
                        }}
                        
                    />
                    )}
                </div>
            </div>
            {/* <div style={Academy_book_Style.empty_w}>
                <img  src={file} 
                    style={Academy_book_Style.empty_i}
                />
                <div style={Academy_book_Style.empty_desc}>
                    <div>
                        담당중인 수업이 없습니다.
                    </div>
                    <div>
                        가입된 클래스룸에서 수업을 직접 개설하시거나,
                    </div>
                    <div>
                        수업 관리자에게 가입을 신청해보세요.
                    </div>

                </div>
            </div> */}
        </div>
    )
}

export default Academy_book