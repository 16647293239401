import style from "../../asset/style"

const UiBook_Style = {
    c : {
        display : "flex",
        flexDirection : "column",
        justifyContent: "center",
        alignItems:"center",
        backgroundColor:"#FFFBF5",
        borderRadius:"15px",
        padding: 8,
        marginRight:"4px",
        marginBottom:"8px",

    },
    i_w : {
        width:"96px",
        height:"108px",
        display : "flex",
        alignItems:"center",
        justifyContent: "center",
        flexDirection: "column",
        position : 'relative',
        // width : "120px",
        // height : "160px",
    },
    folder_chk : {
        position : "absolute",
        left: 2,
        top : 4,
        zIndex : 9999,
    },
    text_1 : {
        // display:"flex",
        // justifyContent : 'flex-start',
        // textAlign : "center",
        // fontSize : "13px",
        // padding : "7px 0px 5px 0px",
        // maxWidth:"120px",

        marginTop:"10px",
        fontSize:"12px",
        fontWeight:400,
        display:"-webkit-box",
        whiteSpace: "normal",
        wordWrap:'break-word',
        lineHeight:"1.6em",
        height:"3.2em",
        overflow:"hidden",
        lineClamp: 2,
        boxOrient: "vertical",
        textOverflow:'ellipsis',
        width:"70px",
    },
    text_2 : {
        display : "flex",
        color : style.common.fontColor_1,
        fontSize : "11px"
    },
    text_3 : {
        display : "flex",
        color : style.common.fontColor_1,
        fontSize : "11px",
        marginTop : "3px",
    },

}

export default UiBook_Style
