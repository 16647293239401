import style from "../../asset/style"

const Lecture_modi_Style = {
    top_textBtn_w : {
        display:"flex",
        alignItems:"center",
        position : 'absolute',
        height:"50px",
        right : 80,
        top : 3,
        fontSize : "14px",
        color  : style.common.fontColor_3,
        fontWeight : "bold",
  }
}

export default Lecture_modi_Style