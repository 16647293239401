import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import styled from "styled-components";
import { axiosInstance } from "../../api/common";
import apiFn from "../../asset/apiClass";
import constants from "../../asset/constants";
import store from "../../asset/store";
import style from "../../asset/style";
import util from "../../asset/util";
import { UiInput } from "../../uiComponents/UiInput/UiInput";

const radioList = [
  {
    value: 0,
    label: "질문 교재",
    checked: (bookType) => {
      return 0 === bookType;
    },
  },
  {
    value: 1,
    label: "PDF 교재",
    checked: (bookType) => {
      return 1 === bookType;
    },
  },
  {
    value: 2,
    label: "내 필기 교재",
    checked: (bookType) => {
      return 2 === bookType;
    },
  },
  {
    value: 3,
    label: "시중 교재",
    checked: (bookType) => {
      return 3 === bookType;
    },
  },
  {
    value: 4,
    label: "미검색 교재",
    checked: (bookType) => {
      return 4 === bookType;
    },
  },
];

type Modal_modiProps = {
  bookData: any;
  fileObj: any;
  setFileObj: any;
  setIsModal: any;
  userInfo: any;
  title: string;
  placeholder: string;
};

const Modal_modi = ({
  bookData,
  fileObj,
  setFileObj,
  setIsModal,
  userInfo,
  title,
  placeholder,
}: Modal_modiProps) => {
  let params = useParams();
  let navigate = useNavigate();

  var [img, setImg] = useState("");
  var [bookName, setBookName] = useState("");
  var [bookPw, setBookPw] = useState();

  var [isApprove, setIsApprove] = useState(true);
  const [selectBookType, setSelectBookType] = useState(bookData?.book_type ?? 0);

  const apiPro_regi = async () => {
    try {
      let obj = {};
      if (isApprove) obj["book_pw"] = bookPw;
      let isOk = await apiFn.comApi({
        state: {
          table: "book",
          set: [
            {
              key: `id:${params["id"]}`,
              value: {
                name: bookName,
                ...obj,
              },
            },
          ],
        },
      });
      if (fileObj["base64"]) {
        await store.putBucket(fileObj["base64"], `bookCover/${isOk["id"]}.jpg`, false, false);
      }

      if (bookData?.book_type !== selectBookType) {
        const res = await axiosInstance.put(`/book/${params.id}/type`, {
          userId: userInfo.id,
          bookType: selectBookType,
        });
      }
      alert("교재 정보를 정상적으로 수정했습니다.");
      setFileObj((fileObj = {}));
      setIsModal(false);
    } catch (e) {
      console.log("@@@? ", e);
    }
  };

  const apiPro_del = async () => {
    try {
      let isOk = await apiFn.deleteBook({
        state: {
          boid: params["id"],
          orderMid: userInfo["id"],
        },
      });
      alert("교재를 정상적으로 삭제했습니다.");
      navigate(`/bookmain/${params["who"]}/main`);
    } catch (e) {
      console.log("@@@ ", e);
    }
  };

  const apiPro_img = async (e) => {
    try {
      setFileObj({
        img: img,
        bookName: bookName,
        file: e,
      });
      navigate("/crop/teacher/set");
    } catch (e) {
      console.log("apiPro_img Error= >", e);
    }
  };

  useEffect(() => {
    if (fileObj["base64"]) {
      setImg(fileObj["base64"]);
      setBookName(fileObj["bookName"]);
    } else {
      setBookName(bookData["name"]);
    }
  }, []);

  const handleChangeBookType = useCallback((bookType) => {
    setSelectBookType(bookType);
  }, []);

  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        zIndex: 130002,
        backgroundColor: "rgba(150, 150, 150,0.5)",
        width: "100%",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div
        style={{
          width: "300px",
          backgroundColor: "white",
          padding: "35px 20px 40px 20px",
          borderRadius: 10,
          border: "1px solid rgb(200, 200, 200)",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          filter: "drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.37))",
        }}
        onClick={(e) => {
          // e.stopPropagation();
          // e.preventDefault();
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            fontSize: "18px",
            fontWeight: 700,
          }}
        >
          {title}
        </div>
        <div
          style={{
            display: "flex",
            fontSize: "14px",
            fontWeight: 700,
            justifyContent: "center",
            padding: "10px 0px 25px 0px",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              width: "80px",
              height: "120px",
              marginRight: "10px",
              borderRadius: "10px",
            }}
          >
            <input
              id="lfb"
              accept="image/*"
              type="file"
              style={{ display: "none" }}
              onChange={(e) => {
                util.fileOnchange(e, (event, r) => {
                  img = r;
                  apiPro_img(e);
                });
              }}
            />
            <label
              htmlFor="lfb"
              style={{
                width: "80px",
                height: "120px",
              }}
            >
              <img
                src={img ? img : constants.s3_url + "" + `/bookCover/${bookData["img"]}` + ".jpg"}
                style={{
                  width: "100%",
                  height: "100%",
                  borderRadius: "10px",
                }}
              />
            </label>
            <div
              style={{
                marginTop: "5px",
                color: "#B5B5B5",
                fontWeight: 400,
              }}
            >
              이미지 변경
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div
              style={{
                marginBottom: "3px",
              }}
            >
              교재이름
            </div>
            <div
              style={{
                display: "flex",
                width: "180px",
                // height:"32px",
                flex: 1,
              }}
            >
              <UiInput
                inputClass={"input_t"}
                basic_container_style={{
                  height: "28px",
                }}
                inputValue={bookName}
                placeholder={placeholder}
                fnChange={(e) => {
                  setBookName(e.target.value);
                }}
              />
            </div>
            <div>
              교재 타입 변경
              <BookTypeForm>
                {radioList.map(({ label, value, checked }) => (
                  <label key={`bookType${value}`}>
                    {label}
                    <input
                      type={"radio"}
                      name={"bookType"}
                      value={value}
                      checked={checked(selectBookType)}
                      onChange={() => handleChangeBookType(value)}
                    />
                  </label>
                ))}
              </BookTypeForm>
            </div>

            {/* 구독 관련 임시 주석 0220 */}
            {/* <div style={{
                            display:"flex",
                            marginTop:"7px",
                            alignItems:"center",
                            justifyContent:"space-between",
                            marginBottom:"3px",
                        }}>
                            <div style={{
                            }}>
                                교재 구독 공개
                            </div>
                            <Switch
                                size='small'
                                // checked={isApprove}
                                // onClick={(e)=>{
                                //     if(isPublic){
                                //         if(!isApprove){
                                //             setSubsData({
                                //                 ...subsData,
                                //                 approve : "Y"
                                //             })
                                //         }
                                //         else {
                                //             setSubsData({
                                //                 ...subsData,
                                //                 approve : "N"
                                //             })
                                //         }
                                //     }
                                //     else {

                                //     }
                                //     setIsApprove(!isApprove)
                                // }}
                            />

                        </div> */}

            {/* 0221 임시 주석 */}
            {/* <div style={{
                            display:"flex",
                            marginTop:"5px",
                            alignItems:"center",
                            justifyContent:"space-between",
                            marginBottom:"3px",
                        }}>
                            <div style={{
                            }}>
                                교재 자동 승인
                            </div>
                            <Switch
                                size='small'
                                checked={isApprove}
                                onClick={(e)=>{
                                    setIsApprove(!isApprove)
                                }}
                            />

                        </div>
                        {isApprove ? <></>
                        :
                        <div style={{
                            display:"flex",
                            width:"180px",
                            // height:"32px",
                            flex:1,
                        }}>
                            <UiInput
                                isPw={true}
                                basic_container_style={{
                                    height:"28px",
                                }}
                                // placeholder={placeholder}
                                inputValue={bookPw}
                                placeholder="비밀번호를 입력해주세요."
                                fnChange={(e)=>{
                                    setBookPw(e.target.value)
                                }}
                                inputClass={"input_t"}
                            />

                        </div>
                        } */}
            <div
              style={{
                display: "flex",
                width: "180px",
                // height:"32px",
                flex: 1,
                justifyContent: "flex-end",
              }}
            >
              <div
                style={{
                  marginTop: "5px",
                  color: style.common.fontColor_3,
                  fontWeight: 400,
                }}
                onClick={() => {
                  let isOk = window.confirm(`[${bookName}] 교재를 삭제하시겠습니까?`);
                  if (isOk) {
                    apiPro_del();
                  }
                }}
              >
                교재 삭제
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "center",
            marginTop: "10px",
          }}
        >
          <div
            style={{
              display: "flex",
              width: "180px",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "87px",
                height: "40px",
                borderRadius: "15px",
                backgroundColor: "#D8D8D8",
                fontSize: "14px",
                color: "#FFFFFF",
                fontWeight: 700,
              }}
              onClick={() => {
                setIsModal(false);
                setFileObj((fileObj = {}));
              }}
            >
              취소
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "87px",
                height: "40px",
                borderRadius: "15px",
                backgroundColor: "#5EA3C6",
                fontSize: "14px",
                color: "#FFFFFF",
                fontWeight: 700,
              }}
              onClick={() => {
                apiPro_regi();
              }}
            >
              수정
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const BookTypeForm = styled.form({
  display: "flex",
  flexDirection: "column",
});

export default Modal_modi;
