import styled from "styled-components";

export const Container = styled.div`
  max-width: 90vw;
  margin-left: auto;
  margin-right: 16px;

  @media (min-width: 768px) {
    max-width: 60vw;
  }
`;
export const InfoWrapper = styled.div`
  background: #d9e7ff;
  margin-left: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 16px;
  border-radius: 99px;
  gap: 4px;
  font-size: 12px;
  color: #3b82f6;

  @media (min-width: 768px) {
    width: fit-content;
  }
`;

export const HorizontalLine = styled.div`
  height: 14px;
`;

export const ContentWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;
export const SideButtonSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding-left: 8px;
`;
export const SideButton = styled.button<{ background?: string }>`
  background: ${({ background }) => background || "#fe544e"};
  writing-mode: vertical-rl;
  text-orientation: upright;
  color: #fff;
  border-radius: 10px 0 0 10px;
  padding: 12px;
  position: relative;
  }
`;
export const MainSection = styled.div`
  background: #fff;
  flex: 1;
  overflow: hidden;
  border-radius: 0 10px 10px 10px;
`;

export const ContentBox = styled.div`
  padding: 16px;
`;

export const ActionButtonBlock = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ActionButtonsList = styled.div`
  display: flex;
  gap: 8px;
`;

export const ToggleButton = styled.button`
  background: #e4e7ec;
  width: 100%;
  padding: 8px;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  color: #434343;
`;

export const SendButton = styled.button`
  background: #fe544e;
  color: #fff;
  padding: 10px 15px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  gap: 4px;
`;

export const AnswerSectionSelectListBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin-bottom: 4px;
`;
export const SelectBox = styled.div`
  display: flex;
  gap: 4px;
`;
