import "katex/dist/katex.min.css";
import React, { type CSSProperties, useCallback } from "react";
import { getRenderedMarkdown } from "../../../common/markdown";
import { getCorrectedContents } from "@bnz-org/mdtex-parser";

type LatexViewProps = {
  children: any;
  className?: string;
  style?: CSSProperties;
};

export function LatexView(props: LatexViewProps) {
  const { className, children, style } = props;
  const replaceCharacter = useCallback((CorrectedContent: string): string => {
    // 원래의 변환: ''를 '≠'로 대체합니다.
    let replaced = CorrectedContent;
    replaced = replaced.replace(//g, "≠");

    // '<boxed>내용</boxed>'을 '$$\\boxed{내용}$$'으로 변환합니다.
    replaced = replaced.replace(/<boxed>([\s\S]*?)<\/boxed>/g, "$$\\boxed{$1}$$");

    // '<center>내용</center>'을 내용만 남기고 태그를 제거합니다.
    replaced = replaced.replace(/<center>([\s\S]*?)<\/center>/g, "$1");

    return replaced;
  }, []);

  const renderedMarkdown = getRenderedMarkdown(replaceCharacter(getCorrectedContents(children)));
  return (
    <div
      style={style}
      className={`latex-container${className ? ` ${className}` : ""}`}
      dangerouslySetInnerHTML={{ __html: renderedMarkdown }}
    />
  );
}
