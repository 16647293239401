import { FormControlLabel, Radio, RadioGroup, RadioGroupProps } from "@mui/material";
import React from "react";
import styled from "styled-components";
import { FontStyleMap } from "../Font";
interface RadioProps extends RadioGroupProps {
  itemList: { value: unknown; label: string }[];
}
const CommonRadio = ({ itemList, ...props }: RadioProps) => {
  return (
    <RadioGroup row aria-labelledby="demo-row-radio-buttons-group-label" {...props}>
      {itemList.map(({ value, label }) => (
        <FormControlLabel
          key={value as string}
          value={value}
          control={
            <Radio
              color="info"
              sx={{
                "& .MuiSvgIcon-root": {
                  fontSize: 16,
                },
                "& .MuiTypography-root": {
                  color: "var(--neutral-color-natural-60, #434343)",
                  fontFamily: "Spoqa Han Sans Neo",
                  fontSize: 14,
                  fontWeight: 400,
                },
              }}
            />
          }
          // label={label}
          label={<RadioLabel>{label}</RadioLabel>}
        />
      ))}
    </RadioGroup>
  );
};

const RadioLabel = styled.div`
  color: var(--neutral-color-natural-60, #434343);
  ${FontStyleMap["KR/regular/$KR.BtnM.REG.14"]};
`;

export default CommonRadio;
