import { fontWeight } from '@mui/system'
import { React , useState , useEffect, useRef } from 'react'
import { useNavigate, useParams } from 'react-router'
import apiFn from '../../asset/apiClass'
import { UiInput } from '../../uiComponents/UiInput/UiInput'

var fontStyle = {
    fontFamily: 'Noto Sans',
    fontStyle: "normal",
}

const Modal_ = ({
    setIsModal,
    isModal,
    userInfo,
    title,
    placeholder,
    apiPro_modi_folder
}) => {
    let params = useParams();
    let navigate = useNavigate();

    var [folderName, setFolderName] = useState("");

    const apiPro_regi = async () => {
        try {
            let isOk = await apiFn.comApi({
                state : {
                    table : "folder",
                    ins : [
                        { 
                            name : folderName ,
                            ftype : 0 ,
                            mid : userInfo["id"],
                            open : 1,
                            usee : 1,
                        }
                    ]
                }

            })

            setIsModal(false)
        }
        catch(e){
            // console.log("@@@? " ,e)
        }
    }


    useEffect(()=>{
    },[])


    return (
        <div style={{
            position:"fixed",
            top:0,
            zIndex:130002,
            backgroundColor:"rgba(150, 150, 150,0.5)",
            // backgroundColor:"red",
            width:"100%",
            height:"100vh",
            display:"flex",
            justifyContent:"center",
            alignItems:"center"
        }}
            onClick={()=>{
                setIsModal(false)
            }}
        >
            <div style={{
                // width : "75%",
                width:"300px",
                // minHeight:"55%",
                // maxHeight:"60%",
                backgroundColor:"white",
                padding : "40px 20px 40px 20px",
                borderRadius : 10,
                border : "1px solid rgb(200, 200, 200)",
                display:"flex",
                flexDirection:"column",
                justifyContent:"space-between",
                filter: "drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.37))",
            }}
                onClick={(e)=>{
                    e.stopPropagation();
                    e.preventDefault();
                }}
            >
                <div style={{
                    fontWeight:500,
                    display:"flex",
                    justifyContent:"center",
                    fontSize:"18px",
                    fontWeight:700
                }}>
                    {title}
                </div>
                <div style={{
                    marginTop:"20px",
                    // paddingTop:"5px",
                    overflowY:"scroll",
                    display:"flex",
                    // flexDirection:"column",
                    height:"24px",
                    flex:1,
                }}>
                    <UiInput 
                        inputClass='input_t'
                        basic_container_style={{
                            height:"32px",
                        }}
                        inputValue={folderName}
                        placeholder={placeholder}
                        fnChange={(e)=>{
                            setFolderName(e.target.value)
                        }}
                    />
                </div>
                <div style={{
                    display:"flex",
                    width:"100%",
                    justifyContent:"center",
                    marginTop:"20px",
                }}>
                    <div style={{
                        display:"flex",
                        width:"180px",
                        justifyContent:"space-between",
                    }}>
                        <div style={{
                            display:"flex",
                            justifyContent:"center",
                            alignItems:"center",
                            width:"87px",
                            height:"40px",
                            borderRadius:"15px",
                            backgroundColor:"#D8D8D8",
                            fontSize:"14px",
                            color : "#FFFFFF",
                            fontWeight:700
                        }}
                            onClick={()=>{
                                setIsModal(false)
                            }}
                        >
                            취소
                        </div>
                        {
                        apiPro_modi_folder ? 
                        <div style={{
                            display:"flex",
                            justifyContent:"center",
                            alignItems:"center",
                            width:"87px",
                            height:"40px",
                            borderRadius:"15px",
                            backgroundColor:"#5EA3C6",
                            fontSize:"14px",
                            color : "#FFFFFF",
                            fontWeight:700
                        }}
                            onClick={()=>{
                                apiPro_modi_folder(folderName);
                            }}
                        >
                            수정
                        </div>
                        :
                        <div style={{
                            display:"flex",
                            justifyContent:"center",
                            alignItems:"center",
                            width:"87px",
                            height:"40px",
                            borderRadius:"15px",
                            backgroundColor:"#5EA3C6",
                            fontSize:"14px",
                            color : "#FFFFFF",
                            fontWeight:700
                        }}
                            onClick={()=>{
                                apiPro_regi();
                            }}
                        >
                            등록

                        </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Modal_