//`/class/${boardInfo.cid}/video/edit/${boardInfo.id}`

import { Delete, ModeEditOutlineOutlined, RemoveRedEyeOutlined } from "@mui/icons-material";
import { LectureListItem, useLectureVideoDelete } from "api/classList";
import { ReactComponent as RecycleIcon } from "asset/images/icon/recycle.svg";
import { getUser } from "asset/storage";
import Section from "components/atoms/Section";
import dayjs from "dayjs";
import { useConfirm } from "providers/ConfirmProvider";
import React, { useCallback, useMemo } from "react";
import { useNavigate } from "react-router";
import styled from "styled-components";
import Thumbnail from "uiComponents/UiNotice/NoticeLectureItem/Thumbnail";
const VideoListItem = (videoData: LectureListItem) => {
  const userInfo = getUser();
  const { mutate: deleteBoard } = useLectureVideoDelete();
  const navigate = useNavigate();
  const { teacherName, regDate, viewCount, className, title, replyCount } = useMemo(() => {
    const { mid_HIQDB_user_credential, cid_HIQDB_class, reg_date, view_member_count, reply_count } =
      videoData;

    return {
      ...videoData,
      teacherName: `${mid_HIQDB_user_credential.user.name} 선생님`,
      regDate: dayjs.unix(reg_date).format("YYYY. MM. DD."),
      viewCount: view_member_count ?? 0,
      replyCount: reply_count ?? 0,
      className: cid_HIQDB_class.name,
    };
  }, [userInfo, videoData]);

  const handleUpdate = useCallback(() => {
    navigate(`/class/${videoData.cid}/video/update/${videoData.id}`);
  }, [videoData]);

  // const handleDelete = useCallback(async () => {
  //   if (confirm("정말 삭제하시겠습니까?")) {
  //     deleteBoard(videoData.id);
  //   }
  // }, [videoData]);
  const { showConfirm } = useConfirm();
  const handleDelete = useCallback(async () => {
    showConfirm({
      icon: <RecycleIcon />,
      title: "삭제",
      message: "정말 삭제하시겠습니까?",
      onConfirm() {
        deleteBoard(videoData.id);
      },
    });
  }, [videoData]);
  const isTeacher = useMemo(() => userInfo.type === 2, [userInfo]);
  return (
    <Container>
      <ThumbnailContainer>
        <Thumbnail data={videoData} who={userInfo.type === 1 ? "student" : "teacher"} />
        {isTeacher && (
          <ButtonContainer>
            <UpdateButton onClick={handleUpdate}>
              <ModeEditOutlineOutlined />
            </UpdateButton>
            <DeleteButton onClick={handleDelete}>
              <Delete />
            </DeleteButton>
          </ButtonContainer>
        )}
        <ViewCountContainer>
          <RemoveRedEyeOutlined />
          {viewCount}회
        </ViewCountContainer>
      </ThumbnailContainer>
      <ContentWrapper>
        <TeacherNameFont>{teacherName}</TeacherNameFont>
        <TitleFont>
          {title}
          <span>[{replyCount}]</span>
        </TitleFont>
        <Section justify="space-between">
          <ClassNameFont>{className} 수업</ClassNameFont>
          <ClassNameFont>{regDate}</ClassNameFont>
        </Section>
      </ContentWrapper>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  box-shadow: 4px 4px 5px 0 rgba(0, 0, 0, 0.05);
  overflow: hidden;
  margin: 0 20px;
  width: 100%;
`;

const ThumbnailContainer = styled.div`
  position: relative;
`;

const ContentWrapper = styled.div`
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const TeacherNameFont = styled.span`
  font-weight: 400;
  font-size: 12px;
  color: #313131;
`;

const TitleFont = styled.span`
  font-weight: 700;
  font-size: 16px;
  color: #313131;
  display: flex;
  align-items: center;
  & > span {
    margin-left: 4px;
    font-weight: 900;
    font-size: 18px;
  }
`;

const ClassNameFont = styled.span`
  font-weight: 500;
  font-size: 12px;
  background-color: #fff;
  color: #313131;
`;

const ViewCountContainer = styled.div`
  display: flex;
  align-items: center;
  color: #fff;
  gap: 8px;
  right: 8px;
  bottom: 16px;
  position: absolute;
  font-weight: 400;
  font-size: 14px;
  color: #fff;
  z-index: 1;
  & > svg {
    width: 16px;
    height: 16px;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  gap: 8px;
  position: absolute;
  top: 16px;
  right: 13px;
`;

const DeleteButton = styled.button`
  width: 24px;
  height: 24px;
  color: #ffa723;
  border: 1px solid #ffa723;
  background: none;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  & > svg {
    width: 16px;
    height: 16px;
  }
  &:hover {
    border: 1px solid #f67012;
    color: #f67012;
  }
`;

const UpdateButton = styled(DeleteButton)`
  color: #fff;
  background-color: #ffa723;
  &:hover {
    background-color: #f67012;
    color: #fff;
  }
`;

export default VideoListItem;
