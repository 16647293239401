import { React , useState , useEffect } from 'react'
import style from '../../asset/style'
import { useNavigate, useParams } from 'react-router'
import file from '../../asset/Img/file.png'
import Academy_main_Style from './Academy_main_Style'
import { Add } from '@material-ui/icons'
import UiBtn from '../../uiComponents/UiBtn/UiBtn'
import Academy_book_Style from './Academy_book_Style'
import UiFolder from '../../uiComponents/UiFolder/UiFolder'
import UiBook from '../../uiComponents/UiBook/UiBook'
import UiTextBtn from '../../uiComponents/UiTextBtn/UiTextBtn'
import UiTopPopTextBtn from '../../uiComponents/UiTopPopTextBtn/UiTopPopTextBtn'
import constants from '../../asset/constants'
import apiFn from '../../asset/apiClass'
import util from '../../asset/util'
import { UiInput } from '../../uiComponents/UiInput/UiInput'
import UiLoading from '../../uiComponents/UiLoading/UiLoading'
import {UiCheckBox} from '../../uiComponents/UiCheckBox/UiCheckBox'


const Academy_subs = ({
    hiddenNav,
    setHiddenUrl,
    tap,
    scrollKey,
    setScrolllKey,
    userInfo,
    isTeacher,
    s_bookList,
    setS_BookList,
    setIsSearch,
    setCreateObj
}) => {

    let navigate = useNavigate()
    let params = useParams();
     
    const [isBookEdit,setIsBookEdit] = useState(false)

    var [selectData_book,setSelectData_book] = useState({})
    var [bookList,setBookList] = useState([])

    var [search,setSearch] = useState("");

    var [isLoading, setIsLoading] = useState(true);
    var [isPw,setIsPw] = useState(false);

    // 교재 리스트
    const apiPro_read = async (search_, isPw) => {
        try {
            console.log("isPW : ", isPw)
            // console.log("search_ : ",search_);
            let where = {}
            if(search_ || search) {
                where["HIQDB_book.name"] = `like '%${search_ || search}%'`
            }

            if(isPw){
                where["HIQDB_book_subs.pw"] = `= ''`
            }


            // if(params["who"] == "teacher"){
            if(true){
                let isOk = await apiFn.comApi({
                    state : {
                        table : "book",
                        page : 1,
                        amount : 100,
                        u_id : userInfo["id"],
                        sort : "reg_date desc",
                        ref : [
                            'HIQDB_book_subs.public|HIQDB_book_subs.bid=HIQDB_book.id',
                            'HIQDB_book_subs.name as subs_name|HIQDB_book_subs.bid=HIQDB_book.id',
                            'HIQDB_book_subs.pw|HIQDB_book_subs.bid=HIQDB_book.id'
                        ],
                        // ref : [
                        //     'HIQDB_book_link.cid|HIQDB_book_link.bid=HIQDB_book.id',
                        //     'HIQDB_book_link.type|HIQDB_book_link.bid=HIQDB_book.id',
                        // ],
                        where : {
                            ...where,
                            "HIQDB_book.status" : `!= '9'`,
                            "HIQDB_book_subs.public" : `= 'Y'`
                            
                            // "HIQDB_book_link.mid" : `= '${userInfo["id"]}'`,
                            // "HIQDB_book_link.fid" : `= 0`,
                            // "HIQDB_book.name" : `like '%${search ? search : ''}%'`,
                            // "HIQDB_book_link.cid" : `= 0`,
                        }
                        
                    }
                })

                console.log("### ",isOk["list"]);
                isOk["list"].map((v, i)=>{
                    v["name"] = v["subs_name"]
                })

                setBookList([
                    ...isOk["list"]
                ])
            }
            else {
                let isOk = await apiFn.studentBooklist({
                    state : {
                        sid : userInfo["id"],
                        page : 1,
                        amount : 9999,
                        sort : "reg_date desc",
                        fid : 0,
                        isSubs : true,
                        isPw : isPw
                    }
                })
                console.log("student Book Data => ", isOk["list"])
                isOk["list"].map((v, i)=>{
                    v["name"] = v["subs_name"]
                })
                setBookList([
                    ...isOk["list"]
                ])
            }
        }
        catch(e){
            console.log(e)
        }
        finally{
            setIsLoading(false)
        }
    }

    useEffect(()=>{
        try {
            apiPro_read();
            setHiddenUrl("");
            // alert(window.innerWidth)
            if(window.ReactNativeWebView){
                document.addEventListener("message", async (e)=>{
                    if(e.data == "isModal"){
                    }
                })
            }
            return ()=>{
                try {
                    document.removeEventListener("message")
                }
                catch(e){
                }
            }
            
        }
        catch(e){
        }
    },[])


    return (
        <div style={Academy_main_Style.c}>
            {
            isLoading &&
            <UiLoading 
                isCancle={true}
                text="교재 정보를 불러오고 있습니다."
                container_style={{
                    zIndex : 9999999999999999
                }}
            />
            }
            <div style={{
                display:"flex",
                width:"100%",
                flexDirection:"column",
                // justifyContent:"center",
            }}>
                <div style={{
                    display:"flex",
                    justifyContent:"center",
                    paddingBottom:"10px",
                }}>
                    <div style={{
                        display:"flex",
                        alignItems:"center",
                        width:"230px",
                    }}>
                        <UiInput
                            type='basic' 
                            basic_container_style={{
                                // height : "94%",
                                padding : "0px 0px 0px 5px",
                                // padding : "0px 10px"
                            }}
                            fnChange={(e)=>{
                                setSearch(e.target.value)
                                search = e.target.value
                                apiPro_read(e.target.value)
                                // apiPro_read_2("",e.target.value)
                            }}
                            placeholder={"교재 이름을 입력해주세요. "}
                            // fnChange={fnChange}
                            inputClass={params["who"] == "teacher" ? "input_t" : "input_c"}
                        />
                    </div>
                </div>
                <div style={{
                    display:"flex",
                    alignItems:"center",
                }}>
                    <>
                    <UiCheckBox
                        value={"isImportant"}
                        name="iI"
                        className={params["who"] == "teacher" ? "t" : "s"}
                        checked={isPw}
                        fnCheck={(e)=>{
                            // setIsLoading(true)
                            // window.localStorage.setItem("msg_home",JSON.stringify(e.target.checked))
                            if(e.target.checked){
                                setIsPw(true)
                                apiPro_read(search, "Y")
                                // apiPro_counts("Y",1,true);
                            }
                            else {
                                setIsPw(false)
                                apiPro_read(search, "")
                                // apiPro_counts("",1,true);
                            }
                        }}
                    />
                    <div style={{
                        fontSize : "12px",
                        color : params["who"] == "teacher" ? style.common.t_color : style.common.s_color,
                        fontWeight : 500,
                        marginLeft : "5px",
                    }} >
                        오픈된교재만
                    </div>
                    </>

                </div>
                <div style={{
                    ...Academy_book_Style.list_book_w,
                    // justifyContent:util.isODD(bookList.length) ? "space-between" : "space-around",
                    // maxWidth : window.innerWidth < 400 && "320px",
                }}>
                    {
                    bookList.map((v,i)=>
                    <UiBook
                        isPw={v["pw"] ? true : false}
                        setScrolllKey={setScrolllKey}
                        scrollKey={scrollKey}
                        data={v}
                        key={"mark_folder_"+i}
                        id={i}
                        fnClick={(d)=>{
                            try {
                                if(tap == "subs"){
                                    setHiddenUrl("academy_book")
                                }
                                setScrolllKey(d["id"])
                            }
                            catch(e){
                            }
                        }}
                        navigate={navigate}
                        navi={
                            params["who"] == "teacher" ?
                            `/book/${params["who"]}/${v["id"]}/0/de`
                            :
                            `/book/${params["who"]}/${v["id"]}/${v["classID"] ? v["classID"] : "subs"}/de`
                        }
                        params={params}
                        isShare={params["who"] == "teacher" ? true : false}
                        setSelectData={setSelectData_book}
                        selectData={selectData_book}

                        isEdit={isBookEdit}
                        isTeacher={isTeacher}
                        setIsSearch={setIsSearch}

                        fnLongClick={(data)=>{
                            setIsSearch(false)
                            setIsBookEdit(true)
                            util.postWeb("isModal")
                            setSelectData_book({
                                ...selectData_book,
                                [data["id"]] : "Y"
                            })
                        }}
                        fnCheck={(e,data)=>{
                            if(selectData_book[data["id"]] == "Y"){
                                delete selectData_book[data["id"]]
                                setSelectData_book({
                                    ...selectData_book,
                                })
                            }
                            else {
                                setSelectData_book({
                                    ...selectData_book,
                                    [data["id"]] : "Y"
                                })
                            }
                        }}
                        
                    />
                    )}
                </div>
            </div>
        </div>
    )
}

export default Academy_subs