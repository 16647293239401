import { ArrowBackIos, HelpOutlineRounded } from "@material-ui/icons";
import { React, useEffect, useState } from "react";
import delete_off from "../../asset/Img/delete_off.png";
import delete_on from "../../asset/Img/delete_on.png";
import icon_setting_off from "../../asset/Img/icon_setting_off.png";
import oxnote_on from "../../asset/Img/oxnote_on.png";
import style from "../../asset/style";
import { Frame } from "../page_index";

import { useNavigate, useParams } from "react-router";
import UiSearchBox from "../../uiComponents/UiSearch/UiSearchBox";
import UiTextBtn from "../../uiComponents/UiTextBtn/UiTextBtn";
import Message_Home from "./Message_Home";
import Message_Mark from "./Message_Mark";
import Message_Style from "./Message_Style";
// import { CircularProgress } from '@mui/material'
import apiFn from "../../asset/apiClass";
import util from "../../asset/util";
import { UiCheckBox } from "../../uiComponents/UiCheckBox/UiCheckBox";
import UiLoading from "../../uiComponents/UiLoading/UiLoading";
import Academy_subs from "../Academy/Academy_subs";
import Modal_auto_set from "./Modal_auto_set";
import { useDeleteQuestion, useRemoveQuestionMembers } from "../../api/question";

const Btn = ({ title, fnClick, listStat, isImg = false }) => {
  return (
    <>
      {isImg ? (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            height: "24px",
          }}
        >
          <img
            style={{
              height: "15px",
              //    width:"15px",
              // marginRight:"15px",
            }}
            // src={params["where"] == "mark" ? oxnote_on : oxnote_off}
            src={icon_setting_off}
            onClick={fnClick}
          />
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            padding: "0px 10px",
            border: "1px solid #EAEAEA",
            marginRight: "5px",
            height: "24px",
            background:
              listStat === title
                ? listStat === "All"
                  ? "#6B6B6B"
                  : // listStat == "Group" ? "#B9B4EF"
                    //     :
                    listStat === "Question"
                    ? "#F09294"
                    : listStat === "Share"
                      ? "#2e7d32"
                      : listStat === "Answer"
                        ? "#EFD9B4"
                        : listStat === "Message" && "#97D8D7"
                : "white",
            borderRadius: "20px",
            fontSize: 12,
            fontWeight: 500,
            fontStyle: "normal",
            color: listStat === title ? "white" : "#B0B0B0",
          }}
          onClick={fnClick}
        >
          {title}
        </div>
      )}
    </>
  );
};

const Message = ({
  tcnt,
  setAddSearch,
  isSR_detail,
  setIsSR_detail,
  isDetail,
  setIsDetail,
  t_page,
  setT_page,
  setMessageList,
  userInfo = {},
  messageList,
  setFileObj,
  fileObj,
  hiddenUrl,
  apiPro_counts,
  setHiddenUrl,
  createObj,
  isLoading,
  setIsLoading,
  setCreateObj,
  setQnaData,
  scrollKey,
  setScrolllKey,
  message_page,
  setMessage_page,
  lectureId,
  setLectureId,
  listStat,
  setListStat,

  isPremium,
}) => {
  let params = useParams();
  let navigate = useNavigate();

  var [selectData, setSelectData] = useState({});
  var [selectData_l, setSelectData_l] = useState(0);
  const [isEdit, setIsEdit] = useState(false);

  var [lectureId, setLectureId] = useState("");

  const [isText, setIsText] = useState(false);
  const [isTeacher, setIsTeacher] = useState(true);
  const [isSearch, setIsSearch] = useState(true);
  const [tap, setTap] = useState("home");

  const [isAll, setIsAll] = useState(false);

  var [messageList_lecture, setMessageList_lecture] = useState([]);
  var [isLectureMsg, setIsLectureMsg] = useState(false);

  var [isAuto, setIsAuto] = useState(false);
  // 선택된 lectureList value 저장
  const [s_value, setS_value] = useState("");

  const init_message = (force, stat) => {
    try {
      setIsLoading(true);
      setMessageList([]);
      // setMessage_page(1)
      let chk;
      if (window.localStorage.getItem("msg_home")) {
        chk = JSON.parse(window.localStorage.getItem("msg_home"));
      }
      if (message_page > 1) {
        apiPro_counts(undefined, undefined, undefined, undefined, undefined, stat);
      } else if (params["who"] === "student") {
        if (chk) {
          apiPro_counts("Y", 1, force, false, null, stat);
        } else {
          apiPro_counts("", 1, force, false, null, stat);
        }
      } else {
        if (chk) {
          apiPro_counts("Y", 1, force, false, null, stat);
        } else {
          apiPro_counts("", 1, force, false, null, stat);
        }
      }
    } catch (e) {
      console.log("init Message error => ", e);
    } finally {
      setIsLoading(false);
    }
  };

  const all_select = () => {
    try {
      let obj = {};
      if (isLectureMsg) {
        messageList_lecture.map((v, i) => {
          if (isAll) {
            // obj[v["id"]] = "N"
            delete obj[v["id"]];
            setIsAll(false);
          } else {
            obj[v["id"]] = "Y";
            setIsAll(true);
          }
        });
      } else {
        messageList.map((v, i) => {
          if (isAll) {
            delete obj[v["id"]];
            // obj[v["id"]] = "N"
            setIsAll(false);
          } else {
            obj[v["id"]] = "Y";
            setIsAll(true);
          }
        });
      }
      // qnaData.map((v,i)=>{
      // if(isAll){
      //     obj[v["id"]] = "N"
      //     setIsAll(false)
      // }
      // else {
      //     obj[v["id"]] = "Y"
      //     setIsAll(true)
      // }
      // })
      setSelectData({
        ...obj,
      });
    } catch (e) {}
  };

  const fnLongClick = (data) => {
    try {
      return;
    } catch (e) {}
  };

  const fnCheck = (e) => {
    if (selectData[e.target.id] == "Y") {
      delete selectData[e.target.id];
      setSelectData({
        ...selectData,
      });
    } else {
      setSelectData({
        ...selectData,
        [e.target.id]: "Y",
      });
    }
  };

  const apiPro_del_msg = async () => {
    try {
      // console.log("select Keys :" , Object.keys(selectData));
      // console.log("messageList : ", messageList);

      let selectList = [];
      messageList.map((v, i) => {
        if (Object.keys(selectData).includes(v["id"].toString())) {
          selectList.push(v);
        }
      });

      // qid array
      let qBox = [];
      messageList.map((v, i) => {
        if (Object.keys(selectData).includes(v["id"].toString())) {
          qBox.push(v["qid"]);
        }
      });

      // console.log(qBox)

      // let promiseArray = Object.keys(selectData).map((v,i)=>

      let promiseArray = qBox.map((v, i) => useDeleteQuestion(v));

      let promiseArray_cnt = selectList.map((v, i) =>
        useRemoveQuestionMembers(v.qid, v?.qid_HIQDB_online_qna?.member_cnt)
      );

      await Promise.all(promiseArray);
      await Promise.all(promiseArray_cnt);

      setSelectData({});
      setSelectData_l(0);
      setIsEdit(false);
      // return;
      util.postWeb("isModal_off");
      let isChk = false;
      if (window.localStorage.getItem("msg_home")) {
        isChk = JSON.parse(window.localStorage.getItem("msg_home"));
      }

      setIsAll(false);

      await apiPro_counts(
        isChk ? "Y" : "",
        "",
        true,
        false,
        window.localStorage.getItem("msg_home_lc")
          ? window.localStorage.getItem("msg_home_lc") == "all"
            ? ""
            : window.localStorage.getItem("msg_home_lc")
          : ""
      );
    } catch (e) {
      console.log("************");
      console.log(e);
      console.log("************");
    }
  };

  const apiPro_del = async (isRe) => {
    try {
      let promiseArray = Object.keys(selectData).map((v, i) => {
        let hide = {};
        if (params["who"] == "teacher") {
          hide["t_hide_date"] = isRe ? "del" : 0;
        } else {
          hide["s_hide_date"] = isRe ? "del" : 0;
        }

        // console.log("hide : ", hide)
        // console.log("@@@ :", v);

        return apiFn.comApi({
          state: {
            table: "app_msg",
            set: [
              {
                key: `id:${v}`,
                value: hide,
              },
            ],
          },
        });
      });

      await Promise.all(promiseArray);

      setSelectData({});
      setSelectData_l(0);
      setIsEdit(false);
      setIsAll(false);
      // return;
      util.postWeb("isModal_off");
      let isChk = false;
      if (window.localStorage.getItem("msg_home")) {
        isChk = JSON.parse(window.localStorage.getItem("msg_home"));
      }

      await apiPro_counts(
        isChk ? "Y" : "",
        "",
        true,
        false,
        window.localStorage.getItem("msg_home_lc")
          ? window.localStorage.getItem("msg_home_lc") == "all"
            ? ""
            : window.localStorage.getItem("msg_home_lc")
          : ""
      );
      // let isOk = await apiFn.deleteQna({
      //     state : {
      //         qid :
      //     }
      // })
      // console.log("##");
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    // console.log("parmas => ",params)
    if (params["who"] == "teacher") {
      setIsTeacher(true);
    } else {
      setIsTeacher(false);
    }
    if (params["where"].includes("home")) {
      if (params["where"].includes("text")) {
        setIsText(true);
      } else {
        setIsText(false);
      }
      setTap("home");
    } else {
      setTap(params["where"]);
    }
  }, [params]);

  useEffect(() => {
    setIsSearch(true);
  }, [tap]);

  const default_switch = (stat) => {
    try {
      setMessage_page(1);
      window.localStorage.setItem("msg_home_section", stat);
      if (listStat === "d") {
        console.log("@@@@@");
      }
      if (listStat === stat) {
        init_message(true, stat);
      }
    } catch (e) {
      console.log("************");
      console.log(e);
      console.log("************");
    } finally {
      document.getElementById("msg_list_c_0")?.scrollIntoView({
        behavior: "auto",
        block: "start",
      });
      setListStat(stat);
      scrollKey = "";
      setScrolllKey("");
    }
  };

  useEffect(() => {
    const savedMessageStat = window.localStorage.getItem("msg_home_section");
    const messageStat = savedMessageStat ? savedMessageStat : "All";
    setListStat(messageStat);
    window.localStorage.removeItem("isImgSearch");
    // // console.log("userInfo => MESSSAGE ",userInfo)
    if (window.ReactNativeWebView) {
      document.addEventListener("message", async (e) => {
        if (e.data == "isModal") {
          setIsEdit(false);
          setIsBookEdit(false);
        }
      });
    }

    return () => {
      try {
        document.removeEventListener("message");
      } catch (e) {
        // console.log('************')
        // console.log(e)
        // console.log('************')
      }
    };
  }, []);

  // useEffect(()=>{
  //     console.log("scrollKey =>> ", scrollKey)
  // },[messageList])

  // useEffect(()=>{
  //     console.log("messageList => ",messageList);
  //     // if(messageList.length > 0){

  //     // }
  //     // else {
  //     // }

  // },[messageList])

  useEffect(() => {
    selectData_l = 0;
    // console.log("selectData => ",selectData);
    Object.keys(selectData).map((v, i) => {
      if (setSelectData[v] == "Y") {
        selectData_l = selectData_l + 1;
      } else if (selectData[v] == "N") {
        if (setSelectData_l == 0) return;
        selectData_l = selectData_l - 1;
      }
    });
    setSelectData_l(selectData_l);
  }, [selectData]);

  useEffect(() => {
    if (listStat) {
      init_message(true, listStat);
    }
  }, [listStat]);

  return (
    <Frame
      page="message"
      key={"message"}
      userInfo={userInfo}
      t_visible={false}
      isTap={true}
      // isSearch={isSearch}
      isSearch={false}
      tapStyle={{
        paddingTop: "20px",
      }}
      isPremium={isPremium}
      t_c_style={{}}
      isDubleTap={params["where"] == "mark" ? false : true}
      tapCompo={
        <>
          {
            isEdit ? (
              <div
                style={{
                  width: "100%",
                  borderBottom: "1px solid #f6f6f6",
                  paddingBottom: "10px",
                }}
              >
                <div
                  style={{
                    padding: "10px 0px",
                    // display:"flex",
                    fontSize: "14px",
                    fontWeight: "bold",
                  }}
                >
                  <div
                    style={{
                      backgroundColor: "white",
                      height: "100%",
                      display: "flex",
                      alignItems: "center",
                      width: "100%",
                      // justifyContent:"flex-end",
                      color: style.common.fontColor_3,
                    }}
                    isTeacher={params["who"] == "teacher" ? true : false}
                    onClick={() => {
                      util.postWeb("isModal_off");
                      setIsEdit(false);
                      setIsAll(false);
                      setSelectData({});
                    }}
                  >
                    편집 취소
                  </div>
                  <div
                    style={{
                      backgroundColor: "white",
                      height: "100%",
                      display: "flex",
                      alignItems: "center",
                      width: "100%",
                      justifyContent: "space-between",
                      marginTop: "5px",
                      // color : style.common.fontColor_3,
                    }}
                  >
                    {`선택된 ${Object.keys(selectData).length}개의 메세지를`}
                    <div
                      style={{
                        display: "flex",
                      }}
                    >
                      {listStat === "d" && (
                        <UiTextBtn
                          text={"삭제하기"}
                          isUnderline={false}
                          isTeacher={params["who"] == "teacher" ? true : false}
                          fnClick={() => {
                            let isOk = window.confirm(
                              `선택된 ${Object.keys(selectData).length}개의 메세지를 삭제 하시겠어요?`
                            );
                            if (!isOk) return;
                            apiPro_del_msg();
                            setIsEdit(false);
                          }}
                          btnStyle={{
                            fontWeight: "bold",
                            marginRight: "10px",
                            color: style.common.fontColor_3,
                          }}
                        />
                      )}
                      <UiTextBtn
                        text={listStat == "d" ? "복구하기" : "숨기기"}
                        isUnderline={false}
                        isTeacher={params["who"] == "teacher" ? true : false}
                        fnClick={() => {
                          if (listStat == "d") {
                            let isOk = window.confirm(
                              `선택된 ${Object.keys(selectData).length}개의 메세지를 복구 하시겠어요?`
                            );
                            if (!isOk) return;
                            apiPro_del(true);
                          } else {
                            let isOk = window.confirm(
                              `선택된 ${Object.keys(selectData).length}개의 메세지를 숨기시겠어요?`
                            );
                            if (!isOk) return;
                            apiPro_del();
                          }
                          setIsEdit(false);
                        }}
                        btnStyle={{
                          fontWeight: "bold",
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    // padding : "10px 0px 0px 0px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      fontSize: "12px",
                    }}
                    onClick={() => {
                      all_select();
                    }}
                  >
                    <UiCheckBox
                      className={params["who"] == "teacher" ? "t" : "s"}
                      checked={isAll}
                    />
                    <div
                      style={{
                        marginLeft: "10px",
                      }}
                    >
                      전체선택
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                  // height:"500px",
                  // height:"100%",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "space-between",
                  }}
                >
                  <div
                    style={{
                      width: "96px",
                      height: "100%",
                      fontFamily: "Roboto",
                      fontStyle: "normal",
                      fontWeight: 400,
                      fontSize: "24px",
                      lineHeight: "28px",
                      textAlign: "center",
                      letterSpacing: "2px",
                      display: "flex",
                      alignItems: "center",
                      color:
                        params["where"] == "mark" || listStat == "d"
                          ? "#B0B0B0"
                          : // params["who"] == "teacher" ? style.common.t_color : style.common.s_color
                            "#3D3A39",
                      // backgroundColor : "green",
                      borderBottom:
                        params["where"] == "mark" || listStat == "d" ? "none" : "2px solid #3D3A39",
                    }}
                    onClick={() => {
                      try {
                        window.localStorage.setItem("msg_home_section", "All");

                        setListStat("All");
                        init_message(true, "All");
                        navigate(`/message/${params["who"]}/home`);
                      } catch (e) {
                      } finally {
                        document.getElementById("msg_list_c_0")?.scrollIntoView({
                          behavior: "auto",
                          block: "start",
                        });
                        scrollKey = "";
                        setScrolllKey("");
                      }
                    }}
                  >
                    {`Q&A`}
                    {/* <img src={qna_on}/> */}

                    {userInfo.type === 2 && (
                      <button
                        style={{
                          color: "#adadad",
                          outline: "none",
                          border: "none",
                          background: "none",
                        }}
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          navigate("/tutorial");
                        }}
                      >
                        <HelpOutlineRounded />
                      </button>
                    )}
                  </div>

                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <img
                      style={{
                        // height:"25px",
                        width: "35px",
                        marginRight: "15px",
                      }}
                      src={oxnote_on}
                      onClick={() => {
                        navigate(`/oxNote`);
                      }}
                    />
                    {
                      // params["where"] == "mark" ?
                      // <></>
                      // :
                      <img
                        style={{
                          height: "32px",
                          // width:"35px",
                        }}
                        src={
                          listStat == "d"
                            ? params["where"] == "mark"
                              ? delete_off
                              : delete_on
                            : delete_off
                        }
                        onClick={() => {
                          navigate(`/message/${params["who"]}/home`);
                          setTimeout(() => {
                            document.getElementById("message_home").scrollIntoView({
                              behavior: "smooth",
                              block: "start",
                              inline: "start",
                            });
                          }, 100);
                          window.localStorage.setItem("msg_home_section", "d");
                          setMessage_page(1);
                          setListStat("d");
                          init_message(true, "d");
                        }}
                      />
                    }
                  </div>
                </div>
                {params["where"] == "mark" ? (
                  <></>
                ) : (
                  <div
                    style={{
                      display: "flex",
                      // paddingBottom:"12px",
                      // padding : "10px 10px 20px 10px",
                      padding: "20px 0px 15px 0px",
                      backgroundColor: "white",
                      borderBottom: "1px solid #f6f6f6",
                      width: "100%",
                    }}
                  >
                    <Btn
                      listStat={listStat}
                      title="All"
                      fnClick={() => {
                        default_switch("All");
                      }}
                    />
                    {/*<Btn*/}
                    {/*    listStat={listStat}*/}
                    {/*    title="Group"*/}
                    {/*    fnClick={() => {*/}
                    {/*        default_switch("Group")*/}
                    {/*    }}*/}
                    {/*/>*/}
                    <Btn
                      listStat={listStat}
                      title="Question"
                      fnClick={() => {
                        default_switch("Question");
                      }}
                    />
                    {params.who && (
                      <Btn
                        listStat={listStat}
                        title="Share"
                        fnClick={() => {
                          default_switch("Share");
                        }}
                      />
                    )}
                    <Btn
                      listStat={listStat}
                      title="Answer"
                      fnClick={() => {
                        default_switch("Answer");
                      }}
                    />
                    <Btn
                      listStat={listStat}
                      title="Message"
                      fnClick={() => {
                        default_switch("Message");
                      }}
                    />
                    <Btn
                      isImg={true}
                      listStat={listStat}
                      title="Message"
                      fnClick={() => {
                        setIsEdit(true);
                        util.postWeb("isModal");
                        if (listStat == "d") {
                          setSelectData({
                            ...selectData,
                            // [data["qid"]]: "Y",
                          });
                        } else {
                          setSelectData({
                            ...selectData,
                            // [data["id"]]: "Y",
                          });
                        }
                      }}
                    />
                  </div>
                )}
              </div>
            )
            // <>
            // <div style={{
            //     ...Message_Style.tap_text,
            //     fontSize : "20px",
            //     borderBottom : tap == "home" ? "2px solid" : "none",
            //     borderBottomColor : tap == "home" ?
            //     (isTeacher ? style.common.t_color : style.common.s_color)
            //     :
            //     "none"
            //     ,
            //     color : tap == "home" ?
            //     (isTeacher ? style.common.t_color : style.common.s_color)
            //     :
            //     style.common.fontColor_2

            // }}
            //     onClick={()=>{
            //         navigate("/message"+"/"+params["who"]+"/home")
            //     }}
            // >
            //     내 하이큐
            // </div>
            // <div style={{
            //     ...Message_Style.tap_text,
            //     fontSize : "20px",
            //     borderBottom : tap == "mark" ? "2px solid" : "none",
            //     borderBottomColor : tap == "mark" ?
            //     (isTeacher ? style.common.t_color : style.common.s_color)
            //     :
            //     "none"
            //     ,
            //     color :
            //     tap == "mark" ?
            //     (isTeacher ? style.common.t_color : style.common.s_color)
            //     :
            //     style.common.fontColor_2

            // }}
            //     onClick={()=>{
            // navigate("/message"+"/"+params["who"]+"/mark")
            //     }}
            // >
            //     즐겨 찾기
            // </div>
            // {
            // params["who"] == "teacher" &&
            // <div style={{
            //     ...Message_Style.tap_text,
            //     fontSize : "20px",
            //     borderBottom : tap == "subs" ? "2px solid" : "none",
            //     borderBottomColor : tap == "subs" ?
            //     (isTeacher ? style.common.t_color : style.common.s_color)
            //     :
            //     "none"
            //     ,
            //     color :
            //     tap == "subs" ?
            //     (isTeacher ? style.common.t_color : style.common.s_color)
            //     :
            //     style.common.fontColor_2

            // }}
            //     onClick={()=>{
            //         navigate("/message"+"/"+params["who"]+"/subs")
            //     }}
            // >
            //     교재 구독
            // </div>
            // }
            // </>
          }
        </>
      }
    >
      {
        <div
          style={{
            ...Message_Style.c,
            position: "relative",
          }}
        >
          {tap == "home" ? (
            <div
              style={{
                // padding : "10px 15px 0px 15px"
                display: "flex",
                flexDirection: "column",
                flexGrow: 1,
                flex: 1,
                padding: "0px 0px 0px 0px",
                backgroundColor: "white",
                height: "100%",
              }}
            >
              <Message_Home
                tcnt={tcnt}
                setAddSearch={setAddSearch}
                isSR_detail={isSR_detail}
                setIsSR_detail={setIsSR_detail}
                init_message={init_message}
                isDetail={isDetail}
                setIsDetail={setIsDetail}
                t_page={t_page}
                setT_page={setT_page}
                isLectureMsg={isLectureMsg}
                setIsLectureMsg={setIsLectureMsg}
                setMessageList_lecture={setMessageList_lecture}
                messageList_lecture={messageList_lecture}
                listStat={listStat}
                setListStat={setListStat}
                setIsEdit={setIsEdit}
                isEdit={isEdit}
                lectureId={lectureId}
                setLectureId={setLectureId}
                messageList={messageList}
                setMessageList={setMessageList}
                message_page={message_page}
                scrollKey={scrollKey}
                setScrolllKey={setScrolllKey}
                key={"message_home"}
                userInfo={userInfo}
                isTeacher={isTeacher}
                setFileObj={setFileObj}
                fileObj={fileObj}
                hiddenUrl={hiddenUrl}
                apiPro_counts={apiPro_counts}
                setHiddenUrl={setHiddenUrl}
                setIsText={setIsText}
                setQnaData={setQnaData}
                s_value={s_value}
                setS_value={setS_value}
                isLoading={isLoading}
                fnCheck={fnCheck}
                fnLongClick={fnLongClick}
                apiPro_del={apiPro_del}
                selectData={selectData}
                setIsLoading={setIsLoading}
                setSelectData={setSelectData}
                setSelectData_l={setSelectData_l}
              />
            </div>
          ) : tap == "mark" ? (
            <div
              style={{
                padding: "5px 10px",
              }}
            >
              <Message_Mark
                key={"message_mark"}
                userInfo={userInfo}
                setIsSearch={setIsSearch}
                isTeacher={isTeacher}
                createObj={createObj}
                setCreateObj={setCreateObj}
              />
            </div>
          ) : (
            tap == "subs" &&
            params["who"] == "teacher" && (
              <div
                style={{
                  padding: "5px 10px",
                }}
              >
                <Academy_subs
                  key={"message_subs"}
                  userInfo={userInfo}
                  setIsSearch={setIsSearch}
                  isTeacher={isTeacher}
                  createObj={createObj}
                  setCreateObj={setCreateObj}
                />
              </div>
            )
          )}
        </div>
        // )
      }
      {isText && (
        <div
          style={{
            // display:"flex",
            // flexDirection:"column",
            // display : "flex",
            // flex:1,
            height: "100vh",
            // alignItems:"center",
            position: "fixed",
            top: 0,
            width: "100%",
            borderBottom: "1px solid",
            borderColor: style.common.borderColor,
            // height:"60px",
            backgroundColor: "rgba(200,200,200,0.5)",
          }}
        >
          <div
            style={{
              padding: "10px",
              backgroundColor: "white",
            }}
          >
            <div
              style={{
                // padding : style.common.padding,
                padding: "10px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
              }}
            >
              <div
                style={{
                  display: "flex",
                  fontSize: "8sp",
                  fontWeight: "bold",
                  alignItems: "center",
                  zIndex: 100,
                }}
              >
                <ArrowBackIos
                  onClick={() => {
                    navigate(`/message/${params["who"]}/home`);
                    setIsText(false);
                  }}
                />
                검색
              </div>
            </div>
            <div
              style={{
                // padding : "0px 20px",
                // display:"flex",
                // flex:1,

                width: "94%",
              }}
            >
              <UiSearchBox
                inputClass={isTeacher ? "input_t" : "input_s"}
                fnChange={(e) => {
                  // setSearch(e.target.value)
                  setFileObj({
                    text: e.target.value,
                    isText: true,
                  });
                }}
                fnClick={() => {
                  navigate("/searchresult/" + params["who"] + "/text/de");
                  // apiPro_read();
                }}
                isTeacher={isTeacher}
              />
            </div>
            {/* 보조 설명이 필요할 때 해당 공간에 보조 글귀를 남깁니다. */}
          </div>
        </div>
      )}
      {isLoading && <UiLoading text="" isTransparent={true} />}
      {isAuto && (
        <Modal_auto_set setIsModal={setIsAuto} userInfo={userInfo} msgData={messageList} />
      )}
    </Frame>
  );
};

export default Message;
