import style from "../../asset/style"

const Message_talk_style = {
    c : {
        display : "flex",
        flexDirection:"column",
        flex : 1,
        // height : window.screen.height,
        height : window.innerHeight,
        width : window.screen.width,
    },
    s_1_c : {
        display : "flex",
        flexDirection:"column",
        // position : "fixed",
        boxShadow: "rgba(0, 0, 0, 0.05) 0px 2.5px 15px 0px",
    },
    s_1_w : {
        width : "100%",
        display:"flex",
        height : "80px",
        backgroundColor : "white"
    },
    s_1_l_w : {
        display : "flex",
        // flexGrow : 1,
        height:'100%',
        padding : '15px 10px 10px 10px',
        // padding : '15px 0px 10px 10px',
        maxWidth : "90px",
        // backgroundColor:"red",
    },
    s_1_l_icon : {
        display:"flex",
        alignItems:"center",
        // height : "56px",
        // backgroundColor:"orange",
    },
    s_1_l_icon_2 : {
        width : "44px",
        height : "56px",
        // backgroundColor:"green",
        // marginLeft:"10px"
        // marginRight : "10px",
    },
    s_1_r_w : {
        display : "flex",
        flexDirection:"column",
        alignItems:"center",
        flex:5,
        height:'100%',
      //   padding : '10px ',
    },
    s_1_r_v : {
        width:"100%",
        // height : "77px",
        height : "71px",
      //   flexGrow : 3,
          // height : '74%',
    },
    s_1_r_v_t_w : {
        display:"flex",
        width:"100%",
        height:"70%",
        justifyContent:"space-between",
        //   flexGrow : 1,
    },
    s_1_r_v_t_w_v : {
        paddingTop :"15px",
        display:"flex",
        flexDirection:"column",
        justifyContent:"space-between",
    },
    s_1_r_v_t_w_v_text_1 : {
        display:"flex",
        alignItems:"flex-end",
        fontSize:"14px",
        // backgroundColor:"Red",
    },
    s_1_r_v_t_w_v_text_2 : {
        display:"flex",
        fontSize:"11px",
        marginTop : "4px",
        color : style.common.fontColor_6
    },
    s_1_r_v_t_w_v_b : {
        // paddingRight:"5px",
        // backgroundColor:"orange",
    },
    s_1_r_v_t_w_v_b_img : {
        width : "50px",
    },
    s_1_r_v_b_w : {
        display : 'flex',
        width:"100%",
        height:"30%",
        fontSize :'12px',
        alignItems:"flex-end",
        justifyContent:"space-between",
        //   flexGrow : 1,
    },
    s_1_r_v_b_w_l_v : {
        display : 'flex',
        alignItems:"flex-end",
    },
    s_1_r_v_b_w_l_v_text_w_1 : {
        display:"flex",
        marginRight:"20px",
    },
    s_1_r_v_b_w_l_v_text : {
        marginRight : '5px',
        color : style.common.fontColor_6
    },
    s_1_r_v_b_w_l_v_text_2 : {
        color : style.common.t_color,
        fontWeight:"bold"
    },
    s_1_r_v_b_w_l_v_text_w_2 : {
        display:"flex",
    },
    s_1_r_v_b_w_r_v : {
        display:"flex",
        paddingRight:"12px",
        alignItems:"center",
    },
    s_1_r_v_b_w_r_v_icon_1 : {
        fontSize : '18px',
        color : 'orange'
    },
    s_1_r_v_b_w_r_v_text : {
        color : style.common.t_color,
        fontWeight:"bold",
        marginRight:"3px",
    },
    s_1_r_v_b_w_r_v_icon_2 : {
        fontSize : "16px",
        color : style.common.fontColor_6
    },
    s_2_c : {
        display : "flex",
        flexDirection:"column",
        // flexDirection : 'column-reverse',
        flexGrow : 4,
        overflowY : "scroll",
        backgroundColor : style.common.talkRoomColor,

    },
    s_3_c : {
        display : "flex",
        alignItems:"center",
        backgroundColor : style.common.talkRoomColor,
      //   flexGrow : 1,
        // maxHeight : "100px",
        // minHeight : "70px",
        // backgroundColor : "orange",
        // overflow:"scroll",
    },
    s_3_type_1_c : {
        display:"flex",
        width:"100%",
        // justifyContent:"space-around",
        justifyContent:"center",
        // borderTop : "1px solid",
        // borderColor : style.common.borderColor,
        // backgroundColor:"orange",   
        // padding : "10px 0px",
    },
    s_3_type_1_btn_w : {
        display : "flex",
        justifyContent:"center",
        width:"42%",
        alignItems:"center",
        border : "1px solid",
        borderColor : style.common.borderColor,
        backgroundColor:"white",
        borderRadius:15,
        padding : "5px 0px",
        fontSize : "14px",
        margin :"0px 7px",
    },
    s_3_type_1_btn_1 : {
        width : "44px",
        margin : '0px 10px'
        // width : "50px"
        // height : '50px'
    },
    s_3_type_1_btn_2 : {
        width : "32px",
        // width : "44px",
        margin : '0px 0px 3px 5px'
        // height : '50px'
    },
    s_3_type_2_c : {
        // width : "85%",
        flex:1,
        display:"flex",
        justifyContent:"space-between",
        alignItems:"center",
        height : "auto",
        padding : "8px 8px 8px 16px",
        backgroundColor:"#F8F8F8",
        borderTop : "1ps solid rgba(234, 234, 234, 0.5)"
    },
    s_3_type_2_desc : { 
        // width : "100%",
        // backgroundColor:"green",
        resize : 'none',
        display:'flex',
        flex:1,
        alignItems:"center",
        // border : 'none'
        border: "1px solid #EAEAEA",
        borderRadius: "15px",
        fontSize:"14px",
        padding :"2px",
        minHeight : "32px",

    },
    s_3_type_2_btn_w : {
        // width : "15%",
        // display:"flex",
        // flex:1,
        // backgroundColor:"orange",
        // padding : "5px 10px",

        // height:"44px",
        display:"flex",
        alignItems:"center",
        padding : "0px 8px",
        height:"32px",
        
    },
    s_3_type_2_btn : {
        width : "60px",
        height:"34px",
        backgroundColor : style.common.t_color,
        color : "white",
        fontSize : "12px",
        fontWeight : 600
    },
    attach_img_wrapper : {
        display:"flex",
        alignItems:"center",
        flexDirection:"column",
        fontSize : "14px",
        padding : "0px 20px",
        justifyContent : "flex-end",
        height:"100%",
        color : style.common.fontColor_7
    },
    attach_img : {
        width : "56px",
        marginBottom:"7px",
    }


    
}

export default Message_talk_style