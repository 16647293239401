import type { IcDefatultProps } from "../types";
import React from "react";

export function IcCheck({
  width = 16,
  height = 16,
  color = "white",
}: IcDefatultProps): JSX.Element {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.3218 2H5.07178C3.82914 2 2.82178 3.00735 2.82178 4.24999V11.75C2.82178 12.9926 3.82914 14 5.07178 14H12.5718C13.8144 14 14.8218 12.9926 14.8218 11.75V7.62497M14.0718 3.87499L8.82178 9.12496L7.32178 7.62497"
        stroke={color}
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
