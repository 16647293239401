import PauseRoundedIcon from "@mui/icons-material/PauseRounded";
import PlayArrowRoundedIcon from "@mui/icons-material/PlayArrowRounded";
import React, { useCallback, useContext } from "react";
import styled from "styled-components";
import { VideoContext } from "..";
const PlayerDisplayButtons = () => {
  const { player, playerState } = useContext(VideoContext);
  const handlePlayClick = useCallback(
    (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      playerState?.paused ? player?.play() : player?.pause();
      event.stopPropagation();
    },
    [player, playerState?.paused]
  );

  return (
    <Container>
      <div onClick={handlePlayClick}>
        {playerState.paused ? <PlayArrowRoundedIcon /> : <PauseRoundedIcon />}
      </div>
    </Container>
  );
};

const Container = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  /* transform: translateY(-40%); */
  gap: 20px;
  > div {
    background: rgba(0, 0, 0, 0.4);
    border: none;
    padding: 0;
    margin: 0;
    cursor: pointer;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    border-radius: 50%;
    display: flex;
    align-items: center;
    backdrop-filter: blur(4px);
    width: 79px;
    height: 79px;
    font-size: 58px;
    justify-content: center;
    & > svg {
      color: white;
      width: 46px;
      height: 46px;
    }
    @media screen and (max-width: 768px) {
      width: 49px;
      height: 49px;
      & > svg {
        color: white;
        width: 36px;
        height: 36px;
      }
    }
  }
`;

export default PlayerDisplayButtons;
