import style from "../../asset/style"

const UiFolder_Style = {
     folder_c : {
        // backgroundColor:"red",
        position : 'relative',
        alignItems:"center",
        // minWidth : "55px",
        // maxWidth : "75px",
        // height:"90px",
        display : 'flex',
        flexDirection : 'column',
        fontSize : "12px",
        color : style.common.fontColor_1,
        padding : "8px 20px",
        backgroundColor : "#FAFAFA",
        borderRadius : 15,
        marginRight:"4px",
        marginBottom:"4px"
    },
    folder_chk : {
        position : "absolute",
        left: 8,
    },
    folder_img : {
        width : "55px"
    },

}

export default UiFolder_Style