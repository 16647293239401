import { React, useEffect, useState } from 'react';
import { Frame } from '../page_index';
import { useNavigate, useParams } from 'react-router';
import Academy_book_Style from './Academy_book_Style';
import UiTextBtn from '../../uiComponents/UiTextBtn/UiTextBtn';
import style from '../../asset/style';
import UiBook from '../../uiComponents/UiBook/UiBook';
import aquery from '../../asset/Img/aquery.png'
import answer from '../../asset/Img/answer.png'
import UiHiqDescBox from '../../uiComponents/UiHiqDescBox/UiHiqDescBox';
import { UiInput_title } from '../../uiComponents/UiInput/UiInput';
import UiBtn from '../../uiComponents/UiBtn/UiBtn';
import Academy_book_List_Style from './Academy_book_List_Style';
import UiConfirm from '../../uiComponents/UiConfirm/UiConfirm';
import UiTopPopTextBtn from '../../uiComponents/UiTopPopTextBtn/UiTopPopTextBtn';
import constants from '../../asset/constants';
import apiFn from '../../asset/apiClass';
import UiFolder from '../../uiComponents/UiFolder/UiFolder';

const Academy_folder_list = ({
    userInfo,
    s_bookList,
    setS_BookList
}) => {
    let params = useParams();
    let navigate = useNavigate();

    const [isTeacher ,setIsTeacher] = useState(true);

    var [folderList,setFolderList] = useState([]);

    // 폴더 리스트
    const apiPro_read_folder = async () => {
        try {
            let data = await apiFn.comApi({
                state : {
                    table : "folder",
                    page : 1,
                    amount : 9999,
                    sort : "name desc",
                    where : {
                        "HIQDB_folder.mid" : `='${userInfo["id"]}'`,
                        "HIQDB_folder.ftype" : `='0'`,
                    }
                }
            })
            // console.log(data)
            setFolderList([
                ...data["list"]
            ])
        }
        catch(e){
        }
    }

    // 폴더에 교재를 공유
    const apiPro_regi = async (data) => {
        try {
            let isOk;
            let array = []
            if(params["who"] == "teacher"){
                array = s_bookList.reduce((a,v,i)=>{
                    return ([
                        ...a,
                        {
                            key : `bid:${v}' and mid='${userInfo["id"]}`,
                            value : {
                                fid : data["id"]
                            }  
                        }
                    ])
                },[])
                isOk = await apiFn.comApi({
                    state : {
                        table : 'book_link',
                        set : array
                        // set: [ { key: 'prKey:15', value: {a:1,b:2}  } ]
                        
                    }
                })
            }
            else {
                array = s_bookList.reduce((a,v,i)=>{
                    return ([
                        ...a,
                        {
                            bid : v,
                            mid : userInfo["id"],
                            fid :data["id"] ,
                        }
                    ])
                },[])
                // console.log(array)
                isOk = await apiFn.studentBookFolderSet({
                    state: {
                        set : array
                    }
                })
            }
            // console.log("@#@#@# ",isOk);
            navigate(`/academy/${params["who"]}/book`)
        }
        catch(e){
            // console.log(e)
        }
    }



    useEffect(()=>{
        apiPro_read_folder()
    },[])

    useEffect(()=>{
        // console.log("parmas => ",params)
        if(params["who"] == "teacher"){
            setIsTeacher(true)
        }
        else {
            setIsTeacher(false)
        }
    },[params])


    useEffect(()=>{
        // console.log("s_bookList => ",s_bookList);
    },[s_bookList])

    return (
        <Frame 
            title={"폴더 선택"}
            isSearch={false}
            userInfo={userInfo}
        >
            <div style={{
                padding : "0px 10px",
                // display:"inline",
                // display:"flex",
                // flexWrap : "wrap",
            }}>
                <div style={{
                    display : "flex",
                    // justifyContent:"space-between",
                    flexWrap : "wrap",
                }}>
                    <UiFolder
                        data={{
                            id : 0,
                            name : "/.."
                        }}
                        isTeacher={isTeacher}
                        key={"mark_folder_00"}
                        fnClick={(d)=>{
                            // console.log("## ",d)
                            apiPro_regi(d)
                        }}
                    />
                    {
                    folderList.map((v,i) => 
                        <UiFolder
                            data={v}
                            isTeacher={isTeacher}
                            key={"mark_folder_"+i}
                            id={i}
                            fnClick={(d)=>{
                                // console.log("## ",d)
                                let isOk = window.confirm(`[${d['name']}] 폴더로 이동하시겠어요?`)
                                if(isOk){
                                    apiPro_regi(d)
                                }
                            }}
                        />
                    )
                    }

                </div>
            </div>
        </Frame>
    )
}

export default Academy_folder_list