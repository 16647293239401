import { React, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router';
import style from '../../../asset/style'
import Basic from '../../../common/frame/Basic'
import face_student from '../../../asset/Img/face_student.png'
import face_teacher from '../../../asset/Img/defaultpic_teacher.png'
import Register_Intro_style from './Register_Intro_Style';
import Register_One_style from './Register_One_style';
import RegiForm from './RegiForm';
import { UiInput_title } from '../../../uiComponents/UiInput/UiInput';
import UiBtn from '../../../uiComponents/UiBtn/UiBtn';
import { AddCircle, AddCircleOutline, Close } from '@material-ui/icons';
import Register_Four_style from './Register_Four_style';
import UiSearchBox from '../../../uiComponents/UiSearch/UiSearchBox';
import Register_Five_style from './Register_Five_style';
import { UiSearchList, UiSearchSection } from '../../../uiComponents/UiSearchList/UiSearchList';
import constants from '../../../asset/constants';
import apiFn from '../../../asset/apiClass';
import util from '../../../asset/util';

const AcademySection = ({
    c_data,
    setC_data,
    isTeacher,
    data,
}) => {
    return (
        <div style={{
            display : "flex",
            justifyContent:"space-between",
            alignItems:"center",
            fontSize : 12,
            padding:'5px 12px',
            margin : "5px",
            color : "white",
            fontWeight : "bold",
            borderRadius : 20,
            background : isTeacher ? style.common.t_color : style.common.s_color,
        }}>
            {/* 시대인재 (대치) */}
            {data}
            <Close style={{
                fontSize : "14px",
                marginLeft : "20px",
                fontWeight:"normal"
            }}
                onClick={()=>{
                    let obj = c_data
                    delete obj[data]
                    setC_data({
                        ...obj
                    })

                }}
            />
        </div>
    )
}

const Register_After = ({
    userInfo,
    t_classData,
    setT_classData,
    regiData,
    setRegiData
}) => {
    let params = useParams();
    let navigate = useNavigate();

    const [isChk , setIsChk] = useState(false);
    const [isTeacher ,setIsTeacher] = useState(false);
    
    const [c_data,setC_data] = useState({})
    
    const [isSelect,setIsSelect] = useState(false);
    
    var [searchData,setSearchData] = useState([])

    const [search,setSearch] = useState("")
    const [title,setTitle] = useState("")

    var [isLecture,setIsLecture] = useState(false)

    const [s_name,setS_name] = useState("")
    const [s_title,setS_title] = useState("");
    const [s_classData,setS_classData] = useState({})
    const [s_lectureData,setS_lectureData] = useState([])

    const apiPro_regi = async () => {
        try {
            let ins = [];
            Object.keys(c_data).map((v,i)=>{
                let obj = {
                    mid : userInfo["id"],
                    cid : c_data[v],
                    type : isTeacher ? 6 : 0,
                    status : 0
                }
                ins.push(obj)
            })

            let isOk = await apiFn.comApi({
                state : {
                    table : "member_class",
                    ins: [ 
                        ...ins
                        // {col1:1, col2:2} 
                    ],
                }
            })
            // navigate("/message/teacher/home");
            navigate(`/message/${params["who"]}/home`);
        }
        catch(e){
        }
    }

    const apiPro_read = async () => {
        try {
            let data = await apiFn.comApi({
                state : {
                    table : "class",
                    page : 1,
                    amount : 9999,
                    sort : "reg_date desc",
                    where:{
                        'HIQDB_class.type' : `='0'`,
                        [util.spaceTrim_qr("HIQDB_class.name")] : `like '%${util.spaceTrim(search)}%'`
                    },
                }
            })

            setSearchData([
                ...data["list"]
            ])

            setTitle(search)

        }
        catch(e){
        }
    }

     // 수업 검색
     const apiPro_read_2 = async () => {
        let data = await apiFn.comApi({
            state : {
                table : 'class',
                page : 1,
                amount : 9999,
                sort : "reg_date desc",
                where : {
                    "HIQDB_class.pid" : `='${s_classData["id"]}'`,
                    "HIQDB_class.name" : `like '%${util.spaceTrim(s_name)}%'`,
                    // "HIQDB_class.name" : `='${s_name}'`,
                    // `like '%${util.spaceTrim(bookName)}%'`
                }
            }
        })
        // console.log(data)
        setS_lectureData([
            ...data["list"]
        ])
    }

    
    useEffect(()=>{
        if(params["who"] == "teacher"){
            setIsTeacher(true)
        }
        else {
            setIsTeacher(false)
        }
    },[params])

    useEffect(()=>{
        // console.log("t_classData => ",t_classData);
        
        let obj = {}
        t_classData.map((v,i)=>{
            obj[v["name"]] = v["id"]
            // let obj = {
            //     [v["name"]] : v["id"]
            // }
        })
        setC_data({
            ...obj
        })

        // console.log("obj = > ",obj )

    },[t_classData])


    useEffect(()=>{
        // console.log("s_classData => ",s_classData);
    },[s_classData])

    return (
        <Basic>
            { 
            isLecture ?
            <>
             <RegiForm 
                isTeacher={isTeacher} 
                title={
                    <div style={{
                        display:"flex",
                    }}>
                        수업선택
                    </div>
                }
            />
            <UiSearchSection 
                data={s_classData}
                isTeacher={isTeacher}
            />
            <div style={{
                ...Register_Five_style.s_c,
                marginTop : "10px",
            }}>
                <UiSearchBox 
                    isTeacher={isTeacher}
                    title={"수업 검색"}
                    fnClick={()=>{
                        apiPro_read_2();
                        setS_title(s_name)
                    }}
                    fnChange={(e)=>{
                        setS_name(e.target.value)
                    }}
                />
            </div>
            {
            s_lectureData.length > 0 &&
            <div style={{
                marginTop : "10px",
            }}>
                <UiSearchList 
                    title={'"'+s_title +'"'+ " 검색결과"}
                    title_add={`(${s_lectureData.length}건)`}
                    isTeacher={isTeacher}
                    form={2}
                    data={s_lectureData}
                    fnClick={(d)=>{
                        let isOk;
                        isOk = window.confirm(`[ ${d["name"]} ]`+constants.confirm.cf_5)
                        if(isOk){
                            setT_classData([
                                ...t_classData,
                                d,
                            ])
                            // navigate("/regiAfter/"+params["who"])
                            setIsLecture(false)
                            setSearchData([])
                        }
                    }}
                />
            </div>
            }
            </>
            :
            <>
            <RegiForm 
                num={5}
                isTeacher={isTeacher} 
                title={
                    <div style={{
                        display:"flex",
                    }}>
                        {isTeacher ? "나의 클래스룸 찾기" : "나의 수업 찾기"}
                        <div style={{
                            marginLeft : "5px",
                            color : isTeacher ?  style.common.t_color : style.common.s_color
                        }}>
                            (선택)
                        </div>
                    </div>
                }
            />
            <div style={Register_Five_style.s_c}>
                {isTeacher ?  "등록을 신청할 클래스룸" : "등록을 신청할 수업"}
                <div style={{
                    display:"flex",
                    width : '100%',
                    padding : '5px 0px',
                    flexWrap : 'wrap',
                }}>
                    {
                    Object.keys(c_data).map((v,i)=>
                    <AcademySection 
                        data={v}
                        isTeacher={isTeacher} 
                        setC_data={setC_data}
                        c_data={c_data}
                    />
                    )}
                </div>
            </div>
            <div style={Register_Five_style.s_c}>
                <UiSearchBox 
                    isTeacher={isTeacher}
                    title={isTeacher ? "클래스룸 검색" : "수업 검색"}
                    inputClass={isTeacher ? "input_t" : "input_s"}
                    fnChange={(e)=>{
                        setSearch(e.target.value)
                    }}
                    fnClick={()=>{
                        apiPro_read();
                    }}
                />
            </div>
            {/* <div style={Register_Five_style.s_t}>
                {isTeacher ? "내가 소속된 클래스룸이 검색되지 않아요" : "내가 소속된 클래스룸/수업이 검색되지 않아요."}
            </div> */}
            {
            searchData.length > 0 &&
            <div style={{
                marginTop : "30px",
            }}>
                <UiSearchList 
                    title={'"'+title +'"'+ " 검색결과"}
                    title_add={`(${searchData.length}건)`}
                    data={searchData}
                    setIsSelect={!isTeacher && setIsSelect}
                    fnClick={(d)=>{
                        let isOk;
                        if(isTeacher){
                            isOk = window.confirm(`[ ${d["name"]} ]`+constants.confirm.cf_4)
                            if(isOk){
                                setC_data({
                                    ...c_data,
                                    [d["name"]] : d["id"]
                                })
                                setSearchData([])
                                setTitle("")
                            }
                        }
                        else {
                            setIsLecture(true)
                            setS_classData({
                                ...d
                            })
                            // isOk = window.confirm(`[ ${d["name"]} ]`+constants.confirm.cf_5)
                            // if(isOk){
                            //     setC_data({
                            //         ...c_data,
                            //         [d["name"]] : d["id"]
                            //     })
                            // }
                        }
                    }}
                />
            </div>
            }
            </>
            }
            
            <div style={Register_One_style.b_c}>
                <UiBtn
                    title="나중에 할래요"
                    fnClick={()=>{
                        // navigate("/message/teacher/home")
                        navigate(`/message/${params["who"]}/home`);
                    }}
                />
                <UiBtn
                    btnStyle={{
                        ...Register_One_style.b,
                        borderColor : 
                        isChk ? 
                        ( isTeacher ? style.common.t_color : style.common.s_color )
                        :  
                        style.common.borderColor
                        ,
                        backgroundColor : isTeacher ? style.common.t_color : style.common.s_color 

                    }}
                    title={"가입완료"}
                    fnClick={()=>{
                        let isOk = window.confirm(constants.confirm.cf_15);
                        if(isOk){
                            apiPro_regi();
                        }
                        // if(isTeacher){
                        //     window.confirm("[시대인재 (대치)]"+constants.confirm.cf_4)
                        // }
                        // else {
                        //     window.confirm("김이박 선생님의[수학 만점왕]"+constants.confirm.cf_5)
                        // }
                        // navigate("/regiFive/"+params["who"])
                    }}
                />
            </div>
        </Basic>
    )
}

export default Register_After