// import constants from "../../../Assets/constants"

import style from "../../asset/style";
import util from "../../asset/util";

const InputStyle = {
  container: {
    display: "flex",
    flex: 1,
    height: "100%",
    width: "100%",
    // width: 350,
    // maxHeight:30
  },
  input_container: {
    display: "flex",
    flex: 1,
    height: "100%",
    width: "100%",
    // borderRadius:6,
  },
  select_container: {
    display: "flex",
    // flex:1,
    alignItems: "center",
    width: "100%",
    height: "100%",
    // height:"40px",
    // height: '28px',
    // textAlignLast: 'center' ,
    border: "none",
    // borderBottomColor: constants.style.color_10,
    borderBottomWidth: 1,
    borderBottomStyle: "solid",
    fontSize: "12px",
    cursor: "pointer",
  },
  basic_container: {
    flex: 1,
    display: "flex",
    height: "100%",
    width: "100%",
    // borderRadius:6,
    // borderRadius:6,
  },
  select_wrapper: {
    backgroundColor: "rgba(0,0,0,0)",
    textAlignLast: "center",
    border: "none",
    borderBottom: "none",
    width: "100%",
    // height: '28px',
    outline: "none",
  },
  search_container: {
    display: "flex",
    alignItems: "center",
    position: "relative",
    height: 30,
    width: "200px",
    textAlignLast: "center",
    // borderWidth: 1,
    // borderColor: '#a8b7c7',
    // borderStyle: 'solid',
    border: "1px solid rgba(63, 84, 152, 0.5)",
    fontSize: "1.2rem",
    cursor: "pointer",
  },
  search_wrapper: {
    height: 28,
    padding: 0,
    width: "170px",
    paddingLeft: 20,
    outline: "none",
    border: "none",
    // border : '1px solid rgba(63, 84, 152, 0.5)',
  },
  search_btn_wrapper: {
    display: "flex",
    height: 30,
    right: -51,
    top: -1,
    position: "absolute",
    justifyContent: "center",
    alignItems: "center",
    width: "50px",
    border: "1px solid rgba(63, 84, 152, 0.5)",
    cursor: "pointer",
    backgroundColor: "rgb(63,84,152)",
  },
  input_wrapper: {
    width: "calc(100% - 7px)", // border 2px + paddingLeft 5px
    height: "40px",
    borderRadius: 5,
    border: "1px solid",
    borderColor: style.common.borderColor,
    padding: 0,
    margin: 0,
    // height: '100%',
    // backgroundColor:"red",
    paddingLeft: 5,
    // outline : 'none',
    // border : 'none',
    // padding:"0px 0px 0px 10px",
  },
  time_wrapper: {
    display: "flex",
    padding: "2px 10px",
    height: "30px",
    fontSize: "12px",
    // width : "70px",
    borderRadius: 5,
    border: "1px solid",
    borderColor: style.common.borderColor,
    alignItems: "center",
    justifyContent: "center",
    // width : "100px",
    // margin : 0,
  },
  textArea_wrapper: {
    width: "calc(100% - 16px)", // border 2px + paddingLeft 5px
    height: "auto",
    // textAlignLast: 'center',
    outline: "none",
    paddingTop: 10,
    paddingLeft: 10,
    borderRadius: 5,
    borderColor: "#eeeeee",
    borderWidth: 2,
    borderStyle: "solid",
    resize: "none",
    wordSpacing: util.chk_platform() === "android" ? "-4px" : "0px",
  },
};

export default InputStyle;
