import { useMutation, useQueryClient } from "@tanstack/react-query";
import apiFn from "asset/apiClass";
import { getUser } from "asset/storage";
import { useInfiniteScroll } from "hooks/useInfiniteScroll";
import { GetOnlineQnaLogQuery, GetQnaListInFolderQuery } from "types/graphql/graphql";
import { gqlClient } from "./common";
import { ONLINE_QNA_LOG_LIST } from "./graphql/onlineQnaLog";
import { OX_NOTE_QNA_IN_FOLDER } from "./graphql/oxNote";

interface FolderListResponse {
  list: FolderItemInterface[];
}

export interface FolderItemInterface {
  id: number;
  ftype: number;
  open: number;
  usee: number;
  pid: number;
  depth: number;
  mid_index: number;
  tcnt: number;
  tseq: number;
  content: string;
  name: string;
  mid: string;
}
export const useMessageMarkFolderList = (folderId?: string) => {
  const userInfo = getUser();
  const folderFilter = folderId ? { "HIQDB_folder.id": `='${folderId}'` } : {};
  return useInfiniteScroll<FolderListResponse>({
    queryKey: ["useMessageMarkFolderList", { folderId, page: 1 }],
    queryFn({ pageParam }) {
      return apiFn.comApi({
        state: {
          table: "folder",
          page: pageParam?.page ?? 1,
          amount: 30,
          sort: "name desc",
          where: {
            "HIQDB_folder.mid": `='${userInfo["id"]}'`,
            "HIQDB_folder.ftype": `= '1'`,
            ...folderFilter,
          },
        },
      });
    },
    options: {
      enabled: folderId !== "1" && folderId !== "recent",
    },
  });
};

export const useMessageMarkFolderRegist = () => {
  const queryClient = useQueryClient();
  const userInfo = getUser();
  return useMutation({
    mutationFn: (name: string) => {
      return apiFn.comApi({
        state: {
          table: "folder",
          ins: [
            {
              name,
              ftype: 1,
              mid: userInfo["id"],
              open: 1,
              usee: 1,
            },
          ],
        },
      });
    },
    onSuccess(data, variables, context) {
      queryClient.invalidateQueries(["useMessageMarkFolderList"]);
    },
  });
};

export const useMessageMarkFolderDelete = () => {
  const queryClient = useQueryClient();
  const userInfo = getUser();
  return useMutation({
    mutationFn: (delArray: number[]) => {
      return apiFn.comApi({
        state: {
          table: "folder",
          del: delArray.map((item) => ({ id: item, mid: userInfo.id })),
        },
      });
    },
    onSuccess(data, variables, context) {
      queryClient.invalidateQueries(["useMessageMarkFolderList"]);
    },
  });
};

export const useMessageMarkFolderModify = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ folderId, name }: { folderId?: number; name: string }) => {
      return apiFn.comApi({
        state: {
          table: "folder",
          set: [
            {
              key: `id:${folderId}`,
              value: { name },
            },
          ],
        },
      });
    },
    onSuccess(data, variables, context) {
      queryClient.invalidateQueries(["useMessageMarkFolderList"]);
    },
  });
};

interface QnaListResponse {
  list: QnaItemInterface[];
}

export interface QnaItemInterface {
  bid: number;
  id: number;
  member_cnt: number;
  read_date: number;
  level: number;
  cal_amount: number;
  result: number;
  mid: number;
  link: number;
  sid: number;
  qtype: number;
  gicul_rate: number;
  isSubs: number;
  original: number;
  remove_date: number;
  is_answer: number;
  tid_index: number;
  sid_index: number;
  tcnt: number;
  tseq: number;
  readCnt: number;
  starAvg: number;
  starCnt: number;
  ctype: number;
  content: string;
  qna_date: string;
  ans_date: string;
  reg_date: string;
  mobum_date: string;
  title: string;
  tid: string;
  public: string;
  original_img: string;
  mosaic: string;
  understand: string;
  img: string;
  answered_by: string;
  teacherName: string;
  t_img: string;
  className: string;
  cid: string;
  bookName: string;
  lastword: string;
}
export const useQnaList = (folderId) => {
  const userInfo = getUser();
  return useInfiniteScroll<GetQnaListInFolderQuery>({
    queryKey: ["useQnaList", { folderId, page: 1 }],
    queryFn: ({ pageParam }) => {
      return gqlClient.request(OX_NOTE_QNA_IN_FOLDER, {
        memberId: userInfo["id"],
        folderId,
        page: pageParam?.page ?? 1,
      });
    },
  });
};

export const useRecentQnaList = () => {
  const userInfo = getUser();

  return useInfiniteScroll<GetOnlineQnaLogQuery>({
    queryKey: ["useRecentQnaList", { page: 1, wid: userInfo["id"] }],
    queryFn: async ({ pageParam }) => {
      return gqlClient.request(ONLINE_QNA_LOG_LIST, {
        wid: userInfo["id"],
        page: pageParam?.page ?? 1,
      });
    },
  });
};
