import { InputAdornment, StandardTextFieldProps, TextField } from "@mui/material";
import React, { Ref, forwardRef, useCallback, useEffect, useState } from "react";
import styled, { css } from "styled-components";
import { ReactComponent as RemoveButton } from "../../../asset/images/icon/close.svg";

interface IITextInputProps extends StandardTextFieldProps {
  // size?: SizeType;
}
const TextInput = forwardRef(
  ({ size, value, onChange, ...props }: IITextInputProps, ref: Ref<HTMLInputElement>) => {
    const [valueState, setValueState] = useState<string>(props.defaultValue as string);
    const handleChange = useCallback(
      (event: React.ChangeEvent<HTMLInputElement>) => {
        setValueState(event.target.value);
        onChange?.(event);
      },
      [onChange]
    );

    useEffect(() => {
      setValueState(value as string);
    }, [value]);

    return (
      <StyledTextInput
        size={size}
        inputRef={ref}
        InputProps={{
          style: {
            fontSize: 14,
          },
          ...props.InputProps,
          endAdornment: (
            <InputAdornment position="end">
              {valueState && (
                <ButtonContainer sizeStyled={size} onClick={() => setValueState("")}>
                  <RemoveButton />
                </ButtonContainer>
              )}
            </InputAdornment>
          ),
        }}
        sx={{
          "& MuiFilledInput-root": {
            borderColor: "blue",
          },
        }}
        InputLabelProps={{
          style: {
            fontSize: 14,
          },
          ...props.InputLabelProps,
        }}
        {...props}
        value={valueState}
        onChange={handleChange}
      />
    );
  }
);

const StyledTextInput = styled(TextField)`
  & .MuiInputBase-input {
    color: var(--neutral-color-natural-70);
    &::placeholder {
      color: var(--neutral-color-natural-30);
    }
  }
`;

const ButtonContainer = styled.div<{ sizeStyled: SizeType }>`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  ${({ sizeStyled }) =>
    sizeStyled === "small"
      ? css`
          right: 6px;
        `
      : sizeStyled === "large"
        ? css`
            right: 12px;
          `
        : css`
            right: 11px;
          `}
  & > svg {
    fill: var(--neutral-color-natural-40, #bfbfbf);
  }
  :hover {
    & > svg {
      fill: var(--neutral-color-natural-60, #434343);
    }
  }
`;

export default TextInput;
