export interface PaginationButtonsType {
  previous: boolean;
  next: boolean;
}

export interface ActionButtonsType {
  question: boolean;
  answer: boolean;
}

export enum DraggingModalEnum {
  EMPTY,
  QUESTION,
  ANSWER,
}
