import { React, useState } from "react";
import { useNavigate, useParams } from "react-router";
import store from "../../asset/store";
import style from "../../asset/style";
import Basic from "../../common/frame/Basic";
import UiBtn from "../UiBtn/UiBtn";
import UiConfrim_Style from "../UiConfirm/UiConfrim_Style";
import { UiInput_title } from "../UiInput/UiInput";

const UiCreate = ({ createObj }) => {
  let navigate = useNavigate();
  let params = useParams();

  var [input, setInput] = useState("");

  return (
    <Basic title={createObj["title"]}>
      <UiInput_title
        inputClass={params["who"] ? "input_t" : "input_c"}
        title={createObj["input_title"]}
        type="basic"
        fnChange={(e) => {
          setInput(e.target.value);
        }}
      />
      <div style={UiConfrim_Style.confrim_btn_w}>
        <UiBtn
          btnStyle={{
            color: "white",
            borderColor: params["who"] == "teacher" ? style.common.t_color : style.common.s_color,
            backgroundColor:
              params["who"] == "teacher" ? style.common.t_color : style.common.s_color,
          }}
          title="확인"
          fnClick={() => {
            if (input == "") return;
            createObj["api"](input);

            const stack = store.get("stack");
            if (stack.length > 0) navigate(-1);
            else navigate("/");
          }}
        />
      </div>
    </Basic>
  );
};

export default UiCreate;
