import { React , useState , useEffect } from 'react'
import { useParams , useNavigate } from 'react-router'
import style from '../../asset/style'
import UiTime from '../UiTime/UiTime'
import UiLectureTime_Style from './UiLectureTime_Style'

const UiLectureTime = ({
    isTeacher,
    isDay = true,
    day,
    fnChange,
    fnChange2,
    startTime,
    endTime,
}) => {
    return (
         <div style={UiLectureTime_Style.c}>
            {
            isDay &&
            <div style={UiLectureTime_Style.round_w}>
                {day}
            </div>
            }
            <div style={UiLectureTime_Style.time_w}>
                <UiTime
                    timeData={startTime}
                    inputClass={isTeacher ? "input_t" : "input_s"}
                    type="time"
                    timeStyle={{}}
                    fnChange={(d)=>{
                        // console.log("================================",d)
                        fnChange(d)
                    }}
                />
                ~
                <UiTime 
                    timeData={endTime}
                    inputClass={isTeacher ? "input_t" : "input_s"}
                    type="time"
                    timeStyle={{}}
                    fnChange={(d)=>{
                        fnChange2(d);

                    }}
                />
            </div>
        </div>
    )
}

export default UiLectureTime