import style from "../../asset/style"

const MyInfo_Style = {
    w : {
        width:"100%",
        backgroundColor : "white",
        // backgroundColor : style.common.borderColor2
    },
    t_section_w : {
        display:"flex",
        flexDirection : "column",
        // width:"100%",
        padding : "10px",
        backgroundColor : "white",
    },
    t_section : {
        display:"flex",
        // height : "100px",
        // alignItems:"center",
        // backgroundColor:"red",
        paddingBottom : "5px",
    },
    t_section_i_w : {
        display : "flex",
        alignItems : "center",
        justifyContent:"center",
        marginRight : "10px",
        width : '65px',
        height:'65px',
    } ,
    t_section_i : {
        width : "100%",
        height : "100%",
        objectFit : "cover",
        objectPosition:"center",
        borderRadius : 12,
        // width : "60px",
        // height : "100px",
        // backgroundColor:"red",
    },
    t_section_desc_c : {
        display:"flex",
        flex:1,
        flexDirection:"column",
        // justifyContent:"center",
        minHeight:'65px',
        // padding : "7.5px 0px",
    },
    t_section_desc_w : {
        display:"flex",
        alignItems:"center",
    },
    t_section_desc_text_1 : {
        fontSize : '16px',
        fontWeight : 600,
        marginRight : "8px",
        height : "100%",
        display : "flex",
        alignItems:"center",
    },
    t_section_desc_text_2 : {
        height : "100%",
        display:"flex",
        alignItems : "center",
        fontSize : '11px',
        color : style.common.fontColor_1,
        marginLeft : "2px",
    },
    t_section_desc_text_3 : {
        fontSize : '11px',
    },
    t_section_desc_text_4 : {
        fontSize : "14px",
        color : style.common.fontColor_1,
        // marginTop : "5px",
        padding : "10px 0px"
    },
    section_w : {
        width : "100%",
        // padding : "10px 0px 0px 0px",
        // backgroundColor : "white",
        // marginTop : "5px",
        // minHeight : "130px",
    },
    text_w_1 : {
        // padding : "10px",
        padding : '10px 0px',
        padding : "10px 10px 15px 10px",
        fontSize : "14px",
    },
    text_w_2 : {
        display:'flex',
        justifyContent:'space-around',
        height : "70px",
        alignItems : "center",
        width : "100%",
        marginTop : '10px'
    },
    text_w_3 : {
        display : "flex",
        flexDirection:"column",
        alignItems : "center",
    },
    text_1 : {
        marginTop : '12px',
        fontSize : "14px",
    },
    text_2 : {
        fontSize : "16px",
        fontWeight : "bold",
        marginTop : '5px'
    },
}

export default MyInfo_Style