import { PdfType, usePdfList } from "api/pdfView";
import Switch from "components/atoms/Switch";
import { StudyBookContext } from "pages/StudyBookDetail/context/StudyBookContextProvider";
import { showToast } from "providers/ToastProvider";
import React, { useContext, useEffect, useMemo } from "react";
import { useParams } from "react-router";

const SwitchAnswer = () => {
  const { isVisibleAnswer, toggleAnswer } = useContext(StudyBookContext);

  const { bookId } = useParams();
  const { data: answerData } = usePdfList({ bookId, isAnswer: PdfType.answer });

  const pdfAnswerList = useMemo(() => {
    return answerData?.qnaList ?? [];
  }, [answerData]);

  useEffect(() => {
    if (!pdfAnswerList?.length) {
      showToast({
        title: "이런,이 교재에는 아직 해설이 없어요.",
        message: "곧 볼 수있도록 빠르게 준비할께요!",
        type: "warning",
      });
    }
  }, [pdfAnswerList]);

  return (
    <Switch checked={isVisibleAnswer} onChange={toggleAnswer} disabled={!pdfAnswerList?.length} />
  );
};

export default SwitchAnswer;
