import { PlayArrow } from "@material-ui/icons";
import { TextareaAutosize } from "@mui/base";
import React, { useEffect, useState } from "react";
import ReactPlayer from "react-player";
import { useNavigate, useParams } from "react-router";
import apiFn from "../../asset/apiClass";
import constants from "../../asset/constants";
import store from "../../asset/store";
import style from "../../asset/style";
import util from "../../asset/util";
import UiBtn from "../UiBtn/UiBtn";
import UiTextBtn from "../UiTextBtn/UiTextBtn";

interface UiMemoProps {
  lid: number;
  userInfo: any;
  setHiddenNav?: any;
  isInput?: boolean;
}

const youtubeId = (content) => {
  if (!content) return;
  var tag = "";
  if (content) {
    var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#\&\?]*).*/;
    var matchs = content.match(regExp);
    if (matchs) {
      tag = matchs[7];
    }
    return tag;
  }
};

const Memo_type_2 = ({ mid, lid, data }) => {
  return (
    <div style={{}}>
      <img
        style={{
          maxWidth: "100%",
          height: "100%",
          objectFit: "contain",
        }}
        src={`${constants.s3_url}/memo/${mid}___${lid}/${data["content"]}`}
      />
    </div>
  );
};

const Memo_type_3 = ({ mid, lid, data, setHiddenNav }) => {
  let params = useParams();
  let navigate = useNavigate();
  var [isErr, setIsErr] = useState(false);

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: 10,
        position: "relative",
      }}
      onClick={() => {
        setHiddenNav("memo");
        navigate(
          `/view/${params["who"]}/memo&${mid}___${lid}/video&${util.replace_mp4(data["content"])}/n/0`
        );
      }}
    >
      {}
      {
        // !isErr &&
        data["content"] && (
          <img
            style={{
              borderRadius: 10,
              maxWidth: "100%",
              height: "100%",
              objectFit: "contain",
            }}
            onError={(e) => {
              setIsErr(true);
            }}
            src={`${constants.s3_url}/memo/${mid}___${lid}/${data["content"].split(".")[0]}.jpg`}
          />
        )
      }
      {isErr ? (
        <>{/* {data["content"]} */}</>
      ) : (
        <div
          style={{
            position: "absolute",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            // width:'30px',
            padding: "15px 10px",
            height: "20px",
            borderRadius: 10,
            backgroundColor:
              params["who"] == "teacher" ? style.common.t_color : style.common.s_color,
          }}
        >
          <PlayArrow
            style={{
              color: "white",
            }}
          />
          {/* {
                data["play_time"] &&
                <div style={{
                    color : "white",
                    width:"100%",
                    fontSize : "11px",
                    fontWeight : "normal"
                }}>
                    {
                    data["video_start"] != 0 ?
                    (util.formatDate(Number(data["video_end"]) - Number(data["video_start"])))
                    :
                    data["play_time"] != data["video_end"] ?
                    (
                        data["video_end"] == 0 ?
                        util.formatDate(data["play_time"])
                        :
                        (util.formatDate(Number(data["video_end"]) - Number(data["video_start"])))

                    )
                    :
                    util.formatDate(data["play_time"])
                    }
                </div>
                } */}
        </div>
      )}
    </div>
  );
};
const Memo_type_4 = ({ data }) => {
  return (
    <div
      // href={data["content"]}
      style={{
        // marginRight:"50px",
        display: "flex",
        borderRadius: 10,
        justifyContent: "center",
        // position:"relative",
      }}
      onClick={() => {}}
    >
      <ReactPlayer
        url={data["content"]}
        width="96%"
        // height={"100%"}
        playing={false}
        controls={true}
        pip={false}
        ref={(r) => {}}
      />
      {/* Youtube unlink form */}
      {/* <div style={{
                display:"flex",
                flex:1,
                width: '150px',
                height:"150px",
                backgroundSize: 'contain',
                alignItems:"center",
                justifyContent:"center",
                backgroundPosition: '50% 50%',
                backgroundRepeat : "no-repeat",
                backgroundImage: `url('https://img.youtube.com/vi/${youtubeId(data["content"])}/0.jpg')`,
                borderRadius : 10,

            }}>
                <div style={{
                    position:"absolute",
                    display:"flex",
                    alignItems:"center",
                    justifyContent:"center",
                    width:'30px',
                    padding:"10px",
                    height:"20px",
                    borderRadius:10,
                    backgroundColor : params["who"] == "teacher" ? style.common.t_color : style.common.s_color
                }}>
                    <PlayArrow
                        style={{
                            color : "white"
                        }}
                    />
                </div>
            </div> */}
    </div>
  );
};

const UiMemo_section = ({ setHiddenNav, apiPro_del, data, mid, lid }) => {
  let params = useParams();

  const [isRemove, setIsRemove] = useState(false);

  useEffect(() => {}, [data]);

  return (
    <div
      style={{
        margin: "5px 0px",
        padding: "5px",
      }}
    >
      <div
        style={{
          padding: "5px",
          borderRadius: 10,
          border: "1px solid",
          borderColor: params["who"] == "teacher" ? style.common.t_color : style.common.s_color,
          backgroundColor: "white",
          display: "flex",
          flexDirection: "column",
          flex: 1,
        }}
      >
        <div
          style={{
            display: "flex",
            fontSize: "11px",
            marginTop: "5px",
            justifyContent: "space-between",
            color: style.common.fontColor_1,
          }}
        >
          {util.convertToDateTime(data["reg_date"])}
          {
            // isRemove ?
            <div
              style={{
                color: style.common.fontColor_3,
              }}
              onClick={() => {
                let isOk = window.confirm("해당 메모를 삭제하시겠어요?");
                if (isOk) {
                  apiPro_del(data["id"]);
                  setIsRemove(false);
                } else {
                  setIsRemove(false);
                }
              }}
            >
              삭제
            </div>
            // :
            // <MoreHoriz
            //     style={{
            //         fontSize : "16px",
            //         marginRight:"2px",
            //     }}
            //     onClick={()=>{
            //         setIsRemove(true)
            //     }}
            // />
          }
        </div>
        <div
          style={{
            padding: "10px 0px",
          }}
        >
          {data["mtype"] == 1 ? (
            <div dangerouslySetInnerHTML={{ __html: data["content"] }} />
          ) : data["mtype"] == 2 ? (
            <Memo_type_2 mid={mid} lid={lid} data={data} />
          ) : data["mtype"] == 3 ? (
            <Memo_type_3 mid={mid} lid={lid} data={data} setHiddenNav={setHiddenNav} />
          ) : (
            data["mtype"] == 4 && <Memo_type_4 data={data} />
          )}
        </div>
      </div>
    </div>
  );
};

const UiMemo = ({
  lid, // link id
  userInfo,
  setHiddenNav,
  isInput = true,
}: UiMemoProps) => {
  let params = useParams();

  var [memoList, setMemoList] = useState([]);

  var [isText, setIsText] = useState(false);
  var [text, setText] = useState("");

  const [isLoading, setIsLoading] = useState(false);

  const fileOnChange = (e, key) => {
    try {
      let reader = new FileReader();
      let file = e.target.files[0];

      // video 시간 관련 코드
      var myVideos = [];
      window.URL = window.URL || window.webkitURL;
      myVideos.push(file);
      var video = document.createElement("video");
      video.preload = "metadata";

      video.onloadedmetadata = function () {
        window.URL.revokeObjectURL(video.src);
        var duration = video.duration;
        myVideos[myVideos.length - 1].duration = duration;
      };

      video.src = URL.createObjectURL(file);
      if (file["type"].includes("image")) {
        reader.readAsDataURL(file);
        reader.onloadend = (e) => {
          apiPro_send(2, reader.result, file);
        };
        reader.onerror = (e) => {
          setIsLoading(false);
        };
      } else {
        if (params["who"] == "student") {
          alert("이미지 파일만 등록할 수 있어요.");
          setIsLoading(false);
        } else {
          // setFileObj({
          //     // ...fileObj,
          //     isMsg : true,
          //     videoFile : file ,
          //     myVideos : myVideos[0] ,
          //     duration : myVideos[myVideos.length - 1].duration,
          //     qid : params["qid"],
          //     tid : params["tid"],
          //     sid : params["sid"]
          // })
          // setTimeout(() => {
          //     navigate(`/video/${params["who"]}/exlocal`)
          // }, (300));
          apiPro_send(3, null, file);
        }
      }
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      console.log("File on Change Error= > ", e);
    }
  };

  const apiPro_read = async () => {
    try {
      let data = await apiFn.memoList({
        state: {
          page: 1,
          amount: 9999,
          lid: lid,
          mid: userInfo["id"],
          sort: "reg_date desc",
        },
      });

      if (data["list"].length > 0) {
        setMemoList([...data["list"]]);
      } else {
        setMemoList([]);
      }
    } catch (e) {}
  };

  const apiPro_send = async (mtype: number, base64?: any, file?: File) => {
    try {
      let name = "";
      var name_add = text;
      if (mtype == 2) {
        name_add = new Date().getTime() + "_" + util.getRandomInt(100, 999) + "_" + file.name;
        name = `memo/${userInfo["id"]}___${lid}/${name_add}`;
        await store.putBucket(base64, name, false, false, false, true);
      }
      if (mtype == 3) {
        name_add = new Date().getTime() + "_" + util.getRandomInt(100, 999) + "_" + file.name;
        name = `memo/${userInfo["id"]}___${lid}/${util.replace_mp4(name_add)}`;
        await util.putVideo(file, {}, "", name);
      }
      let state = {
        table: "memo",
        ins: [
          {
            content: name_add,
            lid: lid,
            mid: userInfo["id"],
            mtype: mtype,
            reg_date: 0,
          },
        ],
      };

      await apiFn.comApi({
        state: state,
      });

      await apiPro_read();

      setText("");
    } catch (e) {}
  };

  const apiPro_del = async (id) => {
    try {
      let isOk = await apiFn.comApi({
        state: {
          table: "memo",
          del: [
            {
              id: id,
            },
          ],
        },
      });
      await apiPro_read();
    } catch (e) {}
  };

  useEffect(() => {
    apiPro_read();
  }, []);

  return (
    <>
      {isInput ? (
        <div
          style={{
            display: "flex",
            padding: "5px",
            backgroundColor: "white",
            flex: 1,
            justifyContent: "space-between",
            // alignItems:"center",
            height: "auto",
          }}
        >
          <TextareaAutosize
            className="aaaa"
            // ref={input_ref}
            onFocus={() => {
              // setIsFocus(true)
              // setTimeout(() => {
              //     talk_container.current.children[0].scrollIntoView({
              //         // behavior: "smooth",
              //         block: "center"
              //     });
              // }, (300));
            }}
            onBlur={() => {
              // setIsFocus(false)
            }}
            value={text.replace(/<br>/gi, "\n")}
            // className={util.chk_platform() ? "input_no" : "no"}
            aria-label="maximum height"
            placeholder="여기 메모를 입력하세요."
            style={{
              display: "flex",
              flex: 1,
              alignItems: "center",
              // backgroundColor:"green",
              resize: "none",
              border: "1px solid rgba(179, 179, 179, 0.3)",
              padding: "5px",
              borderRadius: "5px",
              // maxHeight:"60px",
              // height:"100%",
              overflowY: "scroll",
            }}
            onChange={(e) => {
              if (e.target.value == "") {
                setIsText(false);
                setText("");
              } else {
                setText(`${e.target.value.replace(/\n/g, `<br>`)}`);
                setIsText(true);
              }
            }}
          />
          {isText ? (
            <div
              style={{
                display: "flex",
                // backgroundColor:"orange",
                // alignItems:"center",
                // padding : "0px 10px",
                height: "24px",
                padding: "0px 10px",
                width: "60px",
              }}
            >
              <UiBtn
                title="입력"
                fnClick={() => {
                  setIsText(false);
                  if (text.includes("https://youtu")) {
                    apiPro_send(4);
                  } else {
                    apiPro_send(1);
                  }
                  window.scrollTo(0, 1500);
                }}
                btnStyle={{
                  display: "flex",
                  alignItems: "center",
                  padding: "0px 10px",
                  height: "38px",
                  // padding:0,
                  color: "white",
                  backgroundColor: style.common.t_color,
                  // backgroundColor :
                  // isText ?
                  // (params["who"] == "teacher" ? style.common.t_color : style.common.s_color )
                  // :
                  // style.common.borderColor4,
                  // border : isText ? "1px solid": "none",
                  border: "1px solid",
                  margin: 0,
                  flex: 1,
                }}
              />
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                padding: "2px 5px",
                marginLeft: "1px",
                justifyContent: "center",
                backgroundColor: "#EFEFEF",
                borderRadius: "5px",
                height: "24px",
                // height : "69px",
                // width : "70px",
              }}
            >
              <UiTextBtn
                text={"+ 파일 첨부"}
                isUnderline={false}
                isFile={true}
                accept={params["who"] == "student" ? "image/*" : "*"}
                btnStyle={{
                  // backgroundColor:"green",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  color: params["who"] == "teacher" ? style.common.t_color : style.common.s_color,
                  paddingRight: "5px",
                  fontSize: "0.7rem",
                  fontWeight: 600,
                  width: "60px",
                  height: "24px",
                }}
                fnClick={(e) => {
                  fileOnChange(e, e.target.name);
                  return;
                }}
                isTeacher={params["who"] == "teacher" ? true : false}
              />
            </div>
          )}
        </div>
      ) : (
        <></>
      )}
      {memoList.map((v) => (
        <UiMemo_section
          setHiddenNav={setHiddenNav}
          data={v}
          apiPro_del={apiPro_del}
          mid={userInfo["id"]}
          lid={lid}
        />
      ))}
    </>
  );
};

export default UiMemo;
