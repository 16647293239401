import style from "../../asset/style"

const UiHiqDescBox_Style = {
    c : {
        width:"100%",
        // height:"90px",
        borderRadius:5,
        // backgroundColor : "rgb(235,235,235)",
        backgroundColor : "#f1f1f1",
        fontSize:"14px",
        margin : "25px 0px",
        color : style.common.fontColor_5,
        // minHeight : "50px",
        position : "relative",
    },
    text_w : {
        padding : "20px 15px",
        // height:"100%",
    } ,
    img_w : {
        display:"flex",
        justifyContent:"flex-end",
        position : "absolute",
        right : -7,
        // right : -22,
        bottom : 0,
        // width:'40%',
        width : "120px"
        // backgroundColor:"red"
    },
    img : {
        width : "100%"
    }
}

export default  UiHiqDescBox_Style