import { CircularProgress } from '@material-ui/core'
import { React , useState , useEffect, useRef } from 'react'
import { useParams } from 'react-router'
import style from '../../asset/style'
import { UiInput } from '../UiInput/UiInput'
import UiTextBtn from '../UiTextBtn/UiTextBtn'

const UiModal = ({
    title ,
    setIsModal,
    apiPro,
}) => {
    var [input,setInput] = useState("")

    var params = useParams();
    useEffect(()=>{
       
        console.log("input => ",input);
    },[input])
    return (
        <div style={{
            position : "fixed",
            top : 0,
            left : 0,
            display:"flex",
            flex:1,
            width:'100vw',
            height:"100vh",
            flexDirection:"column",
            justifyContent:"center",
            alignItems:"center",
            zIndex : 9999999,
            backgroundColor : "rgba(200,200,200,0.5)"
        }}>
            <div style={{
                display:"flex",
                flexDirection:"column",
                width:"80vw",
                height:"120px",
                padding:"15px",
                backgroundColor:"white",
                justifyContent:"space-between",
                borderRadius:10,
            }}>
                <div>
                    {title}
                </div>
                <div style={{
                    // marginTop:'15px',
                }}>
                    <UiInput 
                        inputClass={params["who"] == "teacher" ? "input_t" : "input_s"}
                        fnChange={(e)=>{
                            setInput(e.target.value)
                        }}
                    />
                </div>
                <div style={{
                    display:"flex",
                    // marginTop:'15px',
                    justifyContent:"flex-end",
                }}>
                    <UiTextBtn
                        text={"취소"} 
                        btnStyle={{
                            marginRight:"15px",
                            color : style.common.t_color,
                            fontSize : "14px"
                        }}
                        isUnderline={false}
                        fnClick={()=>{
                            setIsModal(false)
                        }}
                    />
                    <UiTextBtn 
                        text={"확인"} 
                        btnStyle={{
                            color : style.common.t_color,
                            fontSize : "14px"
                        }}
                        isUnderline={false}
                        fnClick={()=>{
                            apiPro(input)
                            setIsModal(false)
                        }}
                    />
                </div>
            </div>
        </div>

    )
}

export default UiModal