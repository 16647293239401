import styled, { CSSProperties, css } from "styled-components";
const Container = styled.div`
  display: flex;
  min-height: 100vh;
  height: auto;
  width: 100%;
  max-width: 800px;
  flex-direction: column;
  box-sizing: border-box;
  background-color: #fbfcfe;
  margin: 0 auto;
`;

export const PaddingContainer = styled.div<{
  padding?: CSSProperties["padding"];
  paddingBottom?: CSSProperties["paddingBottom"];
  paddingTop?: CSSProperties["paddingTop"];
}>`
  display: flex;
  min-height: 100vh;
  height: auto;
  width: 100%;
  flex-direction: column;
  box-sizing: border-box;
  background-color: var(--neutral-color-natural-10);
  ${({ padding = "0 20px 88px", paddingBottom, paddingTop }) => css`
    padding: ${padding};
    padding-bottom: ${paddingBottom};
    padding-top: ${paddingTop};
  `}
  * {
    box-sizing: border-box;
  }
  max-width: 1180px;
`;
export default Container;
