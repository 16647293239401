import Forward10RoundedIcon from "@mui/icons-material/Forward10Rounded";
import Replay10RoundedIcon from "@mui/icons-material/Replay10Rounded";
import useDoubleTouch from "hooks/useDoubleTouch";
import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import styled, { css } from "styled-components";
import { VideoContext } from "..";
interface PrevNextDoubleTapsProps {
  resetTimer: () => void;
}
const PrevNextDoubleTaps = ({ resetTimer }: PrevNextDoubleTapsProps) => {
  const [showPrev, setShowPrev] = useState(false);
  const [showNext, setShowNext] = useState(false);
  const { player, playerState } = useContext(VideoContext);
  const timerRef = useRef<NodeJS.Timeout>(null);
  const goPrev = useCallback(
    (event?) => {
      event?.stopPropagation();
      player.currentTime((playerState?.currentTime ?? 0) - 10);
      setShowPrev(true);
      resetTimer();
    },
    [playerState?.currentTime]
  );

  const goNext = useCallback(
    (event?) => {
      event?.stopPropagation();
      setShowNext(true);
      player.currentTime((playerState?.currentTime ?? 0) + 10);
      resetTimer();
    },
    [playerState?.currentTime]
  );

  useEffect(() => {
    if (showPrev) {
      timerRef.current = setTimeout(() => setShowPrev(false), 300);
    }
  }, [showPrev]);

  useEffect(() => {
    if (showNext) {
      timerRef.current = setTimeout(() => setShowNext(false), 300);
    }
  }, [showNext]);

  useEffect(() => {
    const handleKeyEvents = (e) => {
      if (e.key === " ") {
        if (playerState?.playing) {
          player.pause();
        } else {
          player.play();
        }
      }
      if (e.key === "ArrowLeft") {
        goPrev();
      }
      if (e.key === "ArrowRight") {
        goNext();
      }
    };
    window.addEventListener("keydown", handleKeyEvents);
    return () => {
      window.removeEventListener("keydown", handleKeyEvents);
    };
  }, [player, playerState?.playing, goPrev, goNext]);

  const handleDoubleTabPrev = useDoubleTouch(goPrev);
  const handledoubleTabNext = useDoubleTouch(goNext);
  return (
    <>
      <PrevArea onTouchEnd={handleDoubleTabPrev} buttonVisible={showPrev}>
        <div>
          <Replay10RoundedIcon />
        </div>
      </PrevArea>
      <NextArea onTouchEnd={handledoubleTabNext} buttonVisible={showNext}>
        <div>
          <Forward10RoundedIcon />
        </div>
      </NextArea>
    </>
  );
};

const PrevArea = styled.div<{ buttonVisible: boolean }>`
  position: absolute;
  width: 50%;
  height: 100%;
  padding-left: 10%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  ${({ buttonVisible }) =>
    buttonVisible
      ? css`
          opacity: 1;
        `
      : css`
          opacity: 0;
        `}
  transition: opacity 0.2s;
  background: linear-gradient(
    90deg,
    rgba(0, 0, 0, 0.4803151334269663) 0%,
    rgba(0, 0, 0, 0.2359331109550562) 43%,
    rgba(255, 255, 255, 0) 63%,
    rgba(255, 255, 255, 0) 100%
  );
  & > div {
    color: white;
    font-size: 58px;
    display: flex;
    justify-content: center;
    align-items: center;
    & > svg {
      color: white;
      width: 36px;
      height: 36px;
    }
  }
`;

const NextArea = styled(PrevArea)`
  padding-right: 10%;
  background: linear-gradient(
    270deg,
    rgba(0, 0, 0, 0.4803151334269663) 0%,
    rgba(0, 0, 0, 0.2359331109550562) 43%,
    rgba(255, 255, 255, 0) 63%,
    rgba(255, 255, 255, 0) 100%
  );
  justify-content: flex-end;
  left: unset;
  right: 0;
`;

export default PrevNextDoubleTaps;
