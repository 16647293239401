import PauseRoundedIcon from "@mui/icons-material/PauseRounded";
import PlayArrowRoundedIcon from "@mui/icons-material/PlayArrowRounded";
import { VideoContext } from "components/molecules/VideoPlayer";
import React, { useCallback, useContext } from "react";
import styles from "./style.module.scss";

const PlayControl = () => {
  const { player, playerState } = useContext(VideoContext);
  const handlePlaying = useCallback(() => {
    playerState.playing ? player.pause() : player.play();
  }, [playerState.playing, player]);

  return (
    <button className={styles.button} onClick={handlePlaying}>
      {playerState.playing ? <PauseRoundedIcon /> : <PlayArrowRoundedIcon />}
    </button>
  );
};

export default PlayControl;
