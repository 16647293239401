import style from "../../asset/style"

const Profile_teacher_Style = {
    c : {
        // width : "100%",
        padding : "0px 10px",
    },
    text_w_1 : {
        fontSize : "14px",
        fontWeight : 'bold',
        color : style.common.fontColor_5,
        padding : "15px 0px 5px 0px",
    },
    text_w_2 : {
        fontSize : "14px",
        fontWeight:"bold"
    },
    textBtn_c : {
        display:"flex",
        justifyContent:"space-between",
        fontSize : style.common.select_fontSize,
        padding : "15px 0px 10px 0px",
    },
    textBtn_w : {
        display : "flex",
    },
    textBtn : {
        padding : "0px 5px",
        fontSize : style.common.select_fontSize,
        color : style.common.fontColor_3,
        textDecoration : "underline"
    },
    t_btn_w : {
        width : '160px',
        margin : "20px 5px 0px 5px"
    },
    t_btn_text : {
        display:"flex",
        height:'100%',
        padding : 0,
        alignItems:"center"
    },
    edit_w : {
        display:"flex",
        alignItems:"center",
        position : 'absolute',
        height:"50px",
        right : 10,
        top : 3,
        fontSize : "14px",
        color  : style.common.t_color,
        fontWeight : "bold"
    },
    t_btn_Add : {
        marginRight : "5px"
    },
    t_btn : {
        borderRadius : 20,
        height : '30px',
        padding : "18px 10px",
        fontSize : "14px",
    },
}

export default Profile_teacher_Style