import Container from "components/atoms/Container";
import React from "react";
import styled from "styled-components";
import DeleteButton from "./components/DeleteButton";
import FolderList from "./components/FolderList";
import MessageMarkHeader from "./components/MessageMarkHeader";
import MessageMarkContextProvider from "./context/MessageMarkContextProvider";

const MessageMark = () => {
  return (
    <MessageMarkContextProvider>
      <Container>
        <MessageMarkHeader />
        <SubTitle>나의 오답노트</SubTitle>
        <FolderList />
        <DeleteButton />
      </Container>
    </MessageMarkContextProvider>
  );
};

const SubTitle = styled.div`
  font-family: Pretendard;
  font-size: 18px;
  font-weight: 600;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  margin: 16px 20px 0px;
`;

export default MessageMark;
