import { StudyBookFolderListItem, useStudyBookFolderNameUpdate } from "api/studyBook";
import CommonModal from "components/atoms/CommonModal";
import HorizontalBlank from "components/atoms/HorizontalBlank";
import ReactiveButtonContainer from "components/atoms/ReactiveButtonContainer";
import { StyledInput } from "pages/StudyBookDetail/components/PdfUploadModal/components/InputArea";
import React, { useCallback, useEffect, useState } from "react";
import { Container, DescriptionFont, TitleFont } from "./FolderRegistModal";
type FolderUpdateModalProps = {
  folder: StudyBookFolderListItem;
} & CommonModalControl;
const FolderUpdateModal = ({ open, onClose, folder }: FolderUpdateModalProps) => {
  const [updateBookFolderName] = useStudyBookFolderNameUpdate();
  const [folderName, setFolderName] = useState<string>("");
  const handleRegist = useCallback(() => {
    updateBookFolderName({
      variables: {
        input: {
          id: folder.id,
          name: folderName,
        },
      },
    });
    onClose();
  }, [folderName]);

  useEffect(() => {
    setFolderName(folder.name);
  }, [folder.name]);
  return (
    <CommonModal open={open} onClose={onClose}>
      <Container>
        <TitleFont>폴더명을 변경할께요.</TitleFont>
        <DescriptionFont>폴더명을 입력해주세요.</DescriptionFont>
        <HorizontalBlank height={16} />
        <StyledInput
          label="폴더명"
          placeholder="폴더명을 입력해주세요."
          size={"small"}
          value={folderName}
          onChange={(event) => setFolderName(event.target.value)}
        />
        <HorizontalBlank height={32} />
        <ReactiveButtonContainer
          buttonList={[
            {
              title: "취소",
              variant: "text",
              onClick: () => {
                onClose();
              },
            },
            {
              title: "확인",
              onClick: handleRegist,
            },
          ]}
        />
      </Container>
    </CommonModal>
  );
};

export default FolderUpdateModal;
