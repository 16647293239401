import { React , useState , useEffect } from 'react'
import { useParams , useNavigate } from 'react-router'
import style from '../../asset/style'
import Basic from '../../common/frame/Basic'
import RegiForm from '../before/register/RegiForm'
import aquery from '../../asset/Img/aquery.png'
import answer from '../../asset/Img/answer.png'
import { UiInput_title } from '../../uiComponents/UiInput/UiInput'
import { Register_One } from '../page_index'
import Register_One_style from '../before/register/Register_One_style'
import UiBtn from '../../uiComponents/UiBtn/UiBtn'
import DaumPostcode from 'react-daum-postcode'
import apiFn from '../../asset/apiClass'
import util from '../../asset/util'

const Academy_regi_two = ({
    c_regiData,
    setC_regiData
}) => {
    let params = useParams();
    let navigate = useNavigate();

    const [isTeacher ,setIsTeacher] = useState(false);
    const [isChk , setIsChk] = useState(false);

    const [isDup,setIsDup] = useState(false);

    const [mapData,setMapData] = useState({})

    const apiPro_chk = async () => {
        try {
            let data = await apiFn.comApi({
                state : {
                    table : "class",
                    page : 1,
                    amount : 9999,
                    sort : "reg_date desc",
                    where: {
                        'replace(name," ","")'
                        // `replace(${content}, ' ', '')` 
                        : 
                        `like '%${util.spaceTrim(c_regiData["name"])}%'`
                    },
                    
                }
            })
            if(data["list"].length > 0){
                // navigate("/academyregitwo/"+params["who"])
                navigate("/academyregitwoadd/"+params["who"])
            }
            else{
                navigate("/academyregithree/"+params["who"])
            }
        }
        catch(e){
        }
    }

    useEffect(()=>{
        if(params["who"] == "teacher"){
            setIsTeacher(true)
        }
        else {
            setIsTeacher(false)
        }
    },[params])


    useEffect(()=>{
        // console.log("@ ",c_regiData);
        if(c_regiData["address"] && c_regiData["tel"] && c_regiData["email"]){
            setIsChk(true)
        }
        else {
            setIsChk(false)
        }
    },[c_regiData])


    return (
        <Basic title={"새 클래스룸 생성"}>
            {
            params['form'] == "map" ? 
            <div style={{
                display:"flex",
                width:"100%",
                height : "100vh",
                // backgroundColor : "red",
            }}>
                <DaumPostcode 
                    style={{
                        width : "100%",
                        height : "100%",
                    }}
                    autoClose={false}
                    onComplete={(data)=>{
                        // console.log(data)
                        setMapData({
                            ...data
                        })

                        setC_regiData({
                            ...c_regiData,
                            address : mapData["address"]
                        })
                        navigate("/academyregitwo/teacher/de")
                    }}
                />
            </div>
            :
            <>
            <RegiForm 
                num={2}
                isTeacher={isTeacher}
                title="부가 정보 입력"
                isTopTitle={false}
                dotCnt = {3}
            />
            <div style={{
                marginTop : "10px"
            }}>
                {/* <UiInput_title 
                    type="basic"
                    title="클래스룸 주소"
                    placeholder={mapData["address"] ? mapData["address"] : "클래스룸의 이름을 입력해주세요."}
                    inputClass={isTeacher ? "input_t" : "input_s"}
                    inputValue={mapData["address"] && mapData["address"]}
                    fnClick={()=>{
                        navigate("/academyregitwo/"+ "teacher" +"/map")
                    }}
                />
                <UiInput_title 
                    type="basic"
                    title="상세 주소"
                    placeholder="나머지 주소를 입력하세요."
                    inputClass={isTeacher ? "input_t" : "input_s"}
                    fnChange={(e)=>{
                        setC_regiData({
                            ...c_regiData,
                            address : `${mapData["address"]} ${e.target.value}`
                        })
                    }}
                /> */}
                <UiInput_title 
                    type="basic"
                    title="전화번호"
                    placeholder="전화번호 입력"
                    inputClass={isTeacher ? "input_t" : "input_s"}
                    fnChange={(e)=>{
                        setC_regiData({
                            ...c_regiData,
                            tel : e.target.value
                        })
                    }}
                />
                <UiInput_title 
                    type="basic"
                    title="이메일"
                    placeholder="이메일 입력"
                    inputClass={isTeacher ? "input_t" : "input_s"}
                    fnChange={(e)=>{
                        setC_regiData({
                            ...c_regiData,
                            email : e.target.value
                        })
                    }}
                />

            </div>
            <div style={Register_One_style.b_c}>
                <UiBtn
                    btnStyle={{
                        ...Register_One_style.b,
                        color : style.common.fontColor_5
                        // borderColor : 
                        // isChk ? 
                        // ( isTeacher ? style.common.t_color : style.common.s_color )
                        // :  
                        // style.common.borderColor
                        // ,
                        // backgroundColor : 
                        // isChk ? 
                        // ( isTeacher ? style.common.t_color : style.common.s_color )
                        // :  
                        // "rgb(200,200,200)"

                    }}
                    title="주소가 따로 없어요"
                    fnClick={()=>{
                        apiPro_chk();
                    }}
                />
                <UiBtn
                    isTouch={isChk}
                    btnStyle={{
                        ...Register_One_style.b,
                        borderColor : 
                        isChk ? 
                        ( isTeacher ? style.common.t_color : style.common.s_color )
                        :  
                        style.common.borderColor
                        ,
                        backgroundColor : 
                        isChk ? 
                        ( isTeacher ? style.common.t_color : style.common.s_color )
                        :  
                        "rgb(200,200,200)"

                    }}
                    title="다음"
                    fnClick={()=>{
                        // navigate("/academyregitwoadd/"+params["who"])
                        if(isChk){
                            apiPro_chk()
                        }
                    }}
                />
            </div>
            </>
            }
            

        </Basic>
    )
}

export default Academy_regi_two
