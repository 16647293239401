const Column_home_Style = {
    c : {
        display:"flex",
        flexDirection:"column",
        // width:'100%',
        // padding : "0px 10px"
    },
    nav_w : {
        display : "flex",
        whiteSpace : 'nowrap',
        overflowX : 'scroll' ,

    },
    tap_text : {
        fontWeight : 'bold',
        fontSize : '18px',
        marginLeft : "1px",
        marginRight : '15px',
        padding : "5px 0px",
    },
    t_section_w : {
        display:"flex",
        width:"100%",
        overflowX : 'scroll' ,
        padding : "20px 0px 0px 0px",
    },
    tag_w : {
        display:"flex",
        flexWrap : "wrap",
        paddingBottom : "15px",
    },
    tag_btn : {
        margin : "5px",
        width : 'auto',
        borderRadius : 20,
        padding : "10px 20px",
        height : "35px",
        fontSize : "13px",
    },
}

export default Column_home_Style