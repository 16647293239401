import style from "../../asset/style"

const UiTopPopTextBtn_Style = {
     edit_w : {
        display:"flex",
        alignItems:"center",
        position : 'absolute',
        height:"50px",
        right : 3,
        top : 12,
        justifyContent : "flex-end",
        fontSize : "14px",
        // padding : style.common.padding,
        color  : style.common.t_color,
        fontWeight : "bold",
        zIndex : 999999,
    },
}

export default UiTopPopTextBtn_Style