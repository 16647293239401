import React, { CSSProperties, ChangeEvent } from "react";
import style from "../../asset/style";
import UiBtn from "../UiBtn/UiBtn";
import { UiInput } from "../UiInput/UiInput";
type UiSearchBoxProps = {
  isTeacher?: boolean;
  title?: string;
  fnChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  fnClick?: () => void;
  btnStyle?: CSSProperties;
  isColor?: boolean;
  isTouch?: boolean;
  placeholder?: string;
};
const UiSearchBox = ({
  isTeacher,
  title,
  fnChange,
  fnClick,
  btnStyle,
  isColor = false,
  isTouch = true,
  placeholder = "검색어를 입력해주세요.",
}: UiSearchBoxProps) => {
  return (
    <div
      style={
        {
          // display:"flex",
        }
      }
    >
      {title}
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            width: "73%",
          }}
        >
          <UiInput
            type="basic"
            placeholder={placeholder}
            fnChange={fnChange}
            inputClass={isTeacher ? "input_t" : "input_c"}
            basic_container_style={{
              height: "32px",
            }}
          />
        </div>
        <UiBtn
          isTouch={isTouch}
          btnStyle={{
            ...btnStyle,
            width: "25%",
            backgroundColor: isTouch
              ? isColor
                ? style.common.borderColor2
                : isTeacher
                  ? style.common.t_color
                  : style.common.s_color
              : "#c7c7c7",
            color: "white",
            height: "34px",
          }}
          title={"검색"}
          fnClick={fnClick}
        />
      </div>
    </div>
  );
};

export default UiSearchBox;
