import axios from "axios";
import constants from "../asset/constants";

const getUserClassList = async (userInfo, addQuery) => {
  try {
    const classRoomQueryString = constants.classroomId
      ? `classroomId=${constants.classroomId}&`
      : "";
    const res = await fetch(
      `${constants.apiUrl}/user/${userInfo}/class/?${classRoomQueryString}${addQuery}`
    );
    if (res.status !== 200) {
      throw new Error(res.status);
    }
    const data = await res.json();
    return data;
  } catch (e) {
    console.error(e);
    alert("수업 목록을 가져오는데 실패했습니다.");
  }
};

const getClassList = async (addQuery) => {
  try {
    const classRoomQueryString = constants.classroomId
      ? `classroomId=${constants.classroomId}&`
      : "";
    const res = await fetch(`${constants.apiUrl}/class/?${classRoomQueryString}${addQuery}`);
    if (res.status !== 200) {
      throw new Error(res.status);
    }
    const data = await res.json();
    return data;
  } catch (e) {
    console.error(e);
    alert("수업 목록을 가져오는데 실패했습니다.");
  }
};

const getMyBookList = async (userId) => {
  try {
    const res = await fetch(`${constants.apiUrl}/user/${userId}/book`);
    if (res.status !== 200) {
      throw new Error(res.status);
    }
    const data = await res.json();
    return data;
  } catch (e) {
    console.error(e);
    alert("교재 목록을 가져오는데 실패했습니다.");
  }
};

const deleteQna = async (qnaId) => {
  const res = await axios.delete(`${constants.apiUrl}/qna/rooms/${qnaId}`, {
    "Content-Type": "application/json",
  });
  if (res.status !== 204) {
    throw new Error(res);
  }
  return res.data;
};

const getFolderBooks = async (folderId, userId) => {
  const res = await axios.get(`${constants.apiUrl}/folder/${folderId}/user/${userId}/book`);
  if (res.status !== 200) {
    throw new Error(res);
  }
  return res.data;
};

const getCurrentClassBooks = async (userId, addQuery = "") => {
  const res = await axios.get(`${constants.apiUrl}/user/${userId}/classes/book?${addQuery}`);
  if (res.status !== 200) {
    throw new Error(res);
  }
  return res.data;
};

const getParticipantBookList = async (userId, addQuery = "") => {
  const res = await axios.get(`${constants.apiUrl}/user/${userId}/books?${addQuery}`);
  if (res.status !== 200) {
    throw new Error(res);
  }
  return res.data;
};

const API = {
  getUserClassList,
  getClassList,
  getMyBookList,
  deleteQna,
  getFolderBooks,
  getCurrentClassBooks,
  getParticipantBookList,
};

export {
  deleteQna,
  getClassList,
  getCurrentClassBooks,
  getFolderBooks,
  getMyBookList,
  getParticipantBookList,
  getUserClassList,
};

export default API;
