import { CommonContext } from "asset/context";
import store from "asset/store";
import util from "asset/util";
import React, { useContext, useMemo } from "react";
import { VideoContext } from "..";
import FullScreen from "./FullScreen";
import PipControl from "./PipControl";
import PlayControl from "./PlayControl";
import PlaySpeed from "./PlaySpeed";
import VolumeControl from "./VolumeControl";
import styles from "./style.module.scss";

const PlayerButtons = () => {
  const { isMobile } = store;

  const { fullscreenable, playerState, handleFullScreen, addon } =
    useContext(VideoContext);
  const { isFull } = useContext(CommonContext);

  const timeText = useMemo(() => {
    const currentFormatTime = util.formatDate(playerState?.currentTime || 0);
    const durationFormatTime = util.formatDate(playerState?.duration || 0);
    return `${currentFormatTime} / ${durationFormatTime}`;
  }, [playerState?.currentTime, playerState?.duration]);

  return (
    <div className={styles.wrapper}>
      {/* 재생버튼 앞뒤 10초 건너뛰기 버튼 */}
      <PlayControl />
      {/* 음량조절버튼 (모바일은 휴대폰 음량조절로 대체되기 떄문에 보이지 않음) */}
      {isMobile() || <VolumeControl />}
      {/* 현재 재생시간 표시 */}
      <span className={styles.timeText}>{timeText}</span>
      {/* 추가 기능버튼 사용시 넘겨준 컴포넌트를 optional하게 적용 (전체화면시에만 나오게함)*/}
      {isFull && addon?.button}
      {/* 재생속도버튼 */}
      <PlaySpeed />
      {/* pip모드 버튼 */}
      {isMobile() || <PipControl />}
      {/* 전체화면버튼 */}
      {fullscreenable && <FullScreen />}
    </div>
  );
};

export default PlayerButtons;
