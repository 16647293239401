import { useMutation } from "@apollo/client";
import { React, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { CreateInitialTicketDocument } from "types/graphql/graphql";
import image_96 from "../../asset/Img/image_96.png";
import invitation from "../../asset/Img/invitation.png";
import student_ from "../../asset/Img/student_.png";
import teacher_ from "../../asset/Img/teacher_.png";
import wait from "../../asset/Img/wait.png";
import apiFn from "../../asset/apiClass";
import constants from "../../asset/constants";
import style from "../../asset/style";
import util from "../../asset/util";
import UiPeople_Type2 from "../../uiComponents/UiPeople/UiPeople_Type2";
import UiTextBtn from "../../uiComponents/UiTextBtn/UiTextBtn";
import UiTopPopTextBtn from "../../uiComponents/UiTopPopTextBtn/UiTopPopTextBtn";
import Lecture_book_Style from "./Lecture_book_Style";

const Lecture_people = ({
  userInfo,
  setIsModal_invite,
  isTeacher,
  lectureData_ori,
  lectureData,
  call_b,
  setCall_b,
  hiddenNav,
  setHiddenNav,
  qnaData,
  setFileObj,
  setQnaData,
  setHiddenUrl,
  isModal_invite,
}) => {
  let params = useParams();
  let navigate = useNavigate();

  var [selectData_invite, setSelectData_invite] = useState({});
  var [selectData_l_invite, setSelectData_l_invite] = useState(0);

  var [selectData, setSelectData] = useState({});
  var [selectData_l, setSelectData_l] = useState(0);

  var [selectData_two, setSelectData_two] = useState({});
  var [selectData_l_two, setSelectData_l_two] = useState(0);

  const [isEdit_invite, setIsEdit_invite] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isEdit_two, setIsEdit_two] = useState(false);

  var [invite_teacherData, setInvite_teacherData] = useState([]);
  var [pre_teacherData, setPre_teacherData] = useState([]);
  var [teacherData, setTeacherData] = useState([]);

  var [invite_studentData, setInvite_StudentData] = useState([]);
  var [pre_studentData, setPre_StudentData] = useState([]);
  var [studentData, setStudentData] = useState([]);

  var [search, setSearch] = useState("");
  var [sorting, setSorting] = useState("");

  // 질문권 생성
  const [createInitialTicket] = useMutation(CreateInitialTicketDocument);

  // 강의 학생 목록
  const apiPro_read_student = async (sort, inSearch) => {
    try {
      //teacher,정보 사용 안함.
      let data = await apiFn.comApi({
        state: {
          table: "class",
          page: 1,
          amount: 9999,
          sort: "reg_date desc",
          // sort : sort ? `${sort} desc` : "reg_date desc",
          ref: [
            "HIQDB_member_class.cid|HIQDB_class.id=HIQDB_member_class.cid",
            "HIQDB_member_class.mid|HIQDB_class.id=HIQDB_member_class.cid",
          ],
          where: {
            "HIQDB_class.id": `='${params["id"]}'`,
            "HIQDB_member_class.cid": `='${params["id"]}'`,
            // 'HIQDB_member_class.type':`='0'`,
            // 'HIQDB_member_class.mid':`!='${userInfo["id"]}'`,
          },
        },
      });
      let in_id = data["list"].reduce((a, v, i) => {
        // return v["mid"].toString()
        if (i == 0) {
          return (a = `'${v["mid"]}'`);
        } else {
          return (a = a + `,'${v["mid"]}'`);
        }
      }, "");

      let invite_array = [];
      let array = [];
      let pre_array = [];
      // util.objLog(data["list"][0])

      if (in_id.length == 0) {
        setStudentData([]);
        setPre_StudentData([]);
        setInvite_StudentData([]);
      }

      let where_obj = {};
      if (inSearch != "" && search != "") {
        where_obj[`A.name like "%${inSearch || search}%" or A.tel`] =
          `like '%${inSearch || search}%'`;
      }

      let data2 = await apiFn.comApi({
        state: {
          table: "user_credential",
          page: 1,
          amount: 9999,
          sort: `${sort || sorting ? sort || sorting : "name"} desc`,
          where: {
            "HIQDB_user_credential.name": `in (${in_id})`,
            ...where_obj,
          },
          ref: [
            `HIQDB_member_class.type ,HIQDB_member_class.reg_date as member_date ,HIQDB_member_class.cid, HIQDB_member_class.status as s_status |HIQDB_member_class.mid=HIQDB_user_credential.name and HIQDB_member_class.cid = '${params["id"]}' `,
            "A.* |HIQDB_user A on A.index=HIQDB_user_credential.user_id",
          ],
        },
      });

      console.log("data student => ", data2["list"]);

      data2["list"]
        .filter((member) => member.user_type === 1)
        .map((v, i) => {
          v["classList"] = [];
          if (v["s_status"] == 1) {
            array.push(v);
          } else if (v["s_status"] == 0) {
            v.name = `${v.name} 학생`;
            pre_array.push(v);
          } else if (v["s_status"] == 5) {
            if (v["type"] == 9) {
              invite_array.push(v);
            }
          }
        });

      console.log("array=> ", array);
      console.log("pre_array => ", pre_array);

      // array.map((v,i)=>{
      //     console.log(v["member_date"])
      //     console.log(v["name"])
      // })

      console.log("invite_array : ", invite_array);
      setInvite_StudentData([...invite_array]);
      setStudentData([...array]);

      setPre_StudentData([...pre_array]);
    } catch (e) {
      console.log("@@@@@@@@@@ ", e);
    }
  };

  const apiPro_set = async (d, isDel, isApprove, isMulti, isUp, isDown, isAssist) => {
    const authCheck = teacherData.filter((teacher) => teacher.id === userInfo.id)[0].type === 5;
    try {
      if (d.owner || authCheck) {
        let state = {
          typecheck: {
            cid: params["id"],
            id: userInfo["id"],
          },
        };

        // apiPro_set(d,true,false,false,true,false) up
        //         apiPro_set(d,true,false,false,false,true) down
        if (isDown) {
          state.sendMsg = {
            orderMid: userInfo.id,
            targetMid: d.id,
            cid: params.id,
            type: "suup_admindown",
            channel: constants.channelId,
          };
          state.set = [
            {
              key: `cid:${params.id}' and mid='${d.id}`,
              value: {
                type: 6,
              },
            },
          ];
        } else {
          if (isUp) {
            state["sendMsg"] = {
              orderMid: userInfo["id"],
              targetMid: d["id"],
              cid: params["id"],
              type: "suup_adminup",
              channel: constants.channelId,
            };
            state["set"] = [
              {
                key: `cid:${params["id"]}' and mid='${d["id"]}`,
                value: {
                  type: 5,
                },
              },
            ];
          }
          if (isAssist) {
            state.sendMsg = {
              orderMid: userInfo.id,
              targetMid: d.id,
              cid: params.id,
              type: "suup_adminup",
            };
            state.set = [
              {
                key: `cid:${params.id}' and mid='${d.id}`,
                value: {
                  type: 7,
                },
              },
            ];
          }
          if (isDel) {
            if (isMulti) {
              state["del"] = Object.keys(d).reduce((a, v, i) => {
                return [
                  ...a,
                  {
                    mid: v,
                    cid: params["id"],
                  },
                ];
              }, []);
            } else {
              state["del"] = [
                {
                  mid: d["id"],
                  cid: params["id"],
                },
              ];
            }
          }
          if (isApprove) {
            if (isMulti) {
              state["set"] = Object.keys(d).reduce((a, v, i) => {
                return [
                  ...a,
                  {
                    key: `cid:${params["id"]}' and mid='${v}`,
                    value: {
                      status: 1,
                    },
                  },
                ];
              }, []);
            } else {
              state["sendMsg"] = {
                type: "suup_approve",
                targetMid: d["id"],
                orderMid: userInfo["id"],
                cid: params["id"],
                channel: constants.channelId,
              };
              state["set"] = [
                {
                  key: `cid:${params["id"]}' and mid='${d["id"]}`,
                  value: {
                    status: 1,
                  },
                },
              ];
            }
          }
        }
        if (!d.owner) {
          let isOk = await apiFn.comApi({
            state: {
              table: "member_class",
              ...state,
            },
          });
          if (isOk["status"] == 30) {
            alert("해당 정보를 수정할 수 있는 권한이 없습니다.");
          }
        } else {
          alert("수업 소유자는 강등될 수 없습니다.");
        }

        setIsEdit(false);
        setIsEdit_two(false);
        setIsEdit_invite(false);
        util.postWeb("isModal_off");

        await apiPro_read_teacher();
        await apiPro_read_student();
      } else {
        alert("해당 정보를 수정할 수 있는 권한이 없습니다.");
      }
    } catch (e) {
      util.c_err_log(`apiPro_set Error => ${e}`);
    }
  };

  const apiPro_del = async (data) => {
    try {
      console.log("CALL APIPRO DEL ", data);
      // return;
      let isOk = await apiFn.exitClass({
        state: {
          orderMid: userInfo["id"],
          targetMid: data["id"],
          cid: params["id"],
        },
      });
      // console.log("apiPro delt isOk => ",isOk);

      setIsEdit(false);
      setIsEdit_two(false);
      setIsEdit_invite(false);
      util.postWeb("isModal_off");

      await apiPro_read_teacher();
      await apiPro_read_student();
    } catch (e) {
      console.log(e);
    }
  };

  const apiPro_del_array = async (data) => {
    try {
      let id_key = Object.keys(data);
      let promiseArray = id_key.map((v) =>
        apiFn.exitClass({
          state: {
            orderMid: userInfo["id"],
            targetMid: v,
            cid: params["id"],
          },
        })
      );
      await Promise.all([...promiseArray]);

      setIsEdit(false);
      setIsEdit_two(false);
      setIsEdit_invite(false);
      util.postWeb("isModal_off");

      await apiPro_read_teacher();
    } catch (e) {
      console.error(e);
    }
  };

  // 교재 선생님 목록
  const apiPro_read_teacher = async (sort, search) => {
    try {
      let data = await apiFn.comApi({
        state: {
          table: "member_class",
          page: 1,
          amount: 9999,
          sort: "reg_date desc",
          ref: [
            // 'HIQDB_class.name|HIQDB_class.id=HIQDB_member_class.cid',
            "HIQDB_class.intro|HIQDB_class.id=HIQDB_member_class.cid",
            "HIQDB_class.address|HIQDB_class.id=HIQDB_member_class.cid",
            "HIQDB_class.tel|HIQDB_class.id=HIQDB_member_class.cid",
            "HIQDB_class.email|HIQDB_class.id=HIQDB_member_class.cid",
            "HIQDB_class.owner|HIQDB_class.id=HIQDB_member_class.cid",
            "HIQDB_class.pid|HIQDB_class.id=HIQDB_member_class.cid",
            // 'HIQDB_teacher.qtotal|HIQDB_teacher.id=HIQDB_member_class.mid',
          ],
          where: {
            "HIQDB_member_class.cid": `='${params["id"]}'`, // 수업 ID
            // 'HIQDB_member_class.mid':`!='${userInfo["id"]}'`,
            // 'HIQDB_class.id':`='${params["id"]}'`,   // 교재 ID
            // 'HIQDB_member_class.status':`='0'`,
          },
        },
      });

      // util.objLog(data["list"][0])
      // console.log(data["list"])
      let in_id = data["list"].reduce((a, v, i) => {
        // return v["mid"].toString()
        if (i == 0) {
          return (a = `'${v["mid"]}'`);
        } else {
          return (a = a + `,'${v["mid"]}'`);
        }
      }, "");
      // console.log(in_id)
      let where_obj = {};
      if (search) {
        where_obj[`HIQDB_teacher.name like "%${search}%" or HIQDB_teacher.tel`] =
          `like '%${search}%'`;
      }

      let data2 = await apiFn.comApi({
        state: {
          // 0906 pid -> cid 로 변경
          // cid : data["list"][0]["pid"],
          cid: params["id"],

          table: "user_credential",
          page: 1,
          amount: 9999,
          sort: "reg_date desc",
          where: {
            "HIQDB_user_credential.name": `in (${in_id})`,
            ...where_obj,
          },
          ref: [
            `HIQDB_member_class.type,HIQDB_member_class.cid, HIQDB_member_class.status as t_status |HIQDB_member_class.mid=HIQDB_user_credential.name and HIQDB_member_class.cid = '${data["list"][0]["cid"]}' `,
            "A.* |HIQDB_user A on A.index=HIQDB_user_credential.user_id",
            // `HIQDB_member_class.status|HIQDB_member_class.mid=HIQDB_teacher.id `,
          ],
        },
      });

      console.log("data2 => ", data2);

      let invite_array = [];
      let array = [];
      let pre_array = [];

      data2["list"]
        .filter((member) => member.user_type === 2)
        .reduce((a, v, i) => {
          // console.log("### => ",v);
          // if(v["id"] == userInfo["id"])return;
          // if(v["mid"] == lectureData_ori["mid"])return;
          let obj = {
            id: v["id"],
            name: v["name"],
            cid: v["cid"],
            nick: v["nick"],
            status: v["t_status"],
            qtotal: v["qnacnt"]["total"], // 총 질문수
            type: v["type"],
            classList: v["classList"],
            tel: v["tel"],
            email: v["email"],
            intro: v["intro"],
            img: v["img"],
          };
          if (v.t_status === 0) {
            obj.name = `${obj.name} 선생님`;
            pre_array.push(obj);
          } else if (v["t_status"] == 1) {
            if (lectureData_ori?.reg_id === obj.id) {
              lectureData_ori.pid !== null
                ? (obj.owner = "수업 소유자")
                : (obj.owner = "클래스룸 소유자");
            }
            array.push(obj);
          } else if (v.t_status === 5) {
            if (v.type === 9) {
              invite_array.push(obj);
            }
          }
        }, []);

      // // console.log("pre_array => ",pre_array);
      // // console.log("array => ",array)
      setInvite_teacherData([...invite_array]);

      setTeacherData([...array]);

      setPre_teacherData([...pre_array]);
    } catch (e) {
      // console.log("@@@@@@@@@@ ",e);
    }
  };

  const apiPro_img = async (e, data) => {
    try {
      setFileObj({
        name: ``,
        file: e,
      });
      setQnaData({
        tid: data["id"],
      });
      setHiddenUrl(window.location.pathname);
      navigate(`/crop/${params["who"]}/se`);
    } catch (e) {
      // console.log("apiPro_img Error= >",e)
    }
  };

  useEffect(() => {
    if (!isModal_invite) {
      apiPro_read_teacher();
      apiPro_read_student();
    }
  }, [isModal_invite]);

  useEffect(() => {
    selectData_l_invite = 0;
    Object.keys(selectData_invite).map((v, i) => {
      if (selectData_invite[v] == "Y") {
        selectData_l_invite = selectData_l_invite + 1;
      } else if (selectData_invite[v] == "N") {
        if (selectData_l_invite == 0) return;
        selectData_l_invite = selectData_l_invite - 1;
      }
    });
    // // console.log(selectData_invite)
    // // console.log(selectData_l_invite)
    setSelectData_l_invite(selectData_l_invite);
  }, [selectData_invite]);

  useEffect(() => {
    selectData_l = 0;
    Object.keys(selectData).map((v, i) => {
      if (selectData[v] == "Y") {
        selectData_l = selectData_l + 1;
      } else if (selectData[v] == "N") {
        if (selectData_l == 0) return;
        selectData_l = selectData_l - 1;
      }
    });
    // // console.log(selectData)
    setSelectData_l(selectData_l);
  }, [selectData]);

  useEffect(() => {
    selectData_l_two = 0;
    // // console.log(selectData_two)
    Object.keys(selectData_two).map((v, i) => {
      if (selectData_two[v] == "Y") {
        selectData_l_two = selectData_l_two + 1;
      } else if (selectData_two[v] == "N") {
        if (selectData_l_two == 0) return;
        selectData_l_two = selectData_l_two - 1;
      }
    });
    setSelectData_l_two(selectData_l_two);
  }, [selectData_two]);

  useEffect(() => {
    try {
      if (window.ReactNativeWebView) {
        document.addEventListener("message", async (e) => {
          if (e.data == "isModal") {
            setIsEdit_invite(false);
            setIsEdit(false);
            setIsEdit_two(false);
          }
        });
      }
      return () => {
        try {
          document.removeEventListener("message");
        } catch (e) {}
      };
    } catch (e) {}
  }, []);

  var people_style = {
    sectionTitle: {
      fontSize: "18px",
      margin: "15px 0px",
      fontWeight: 700,
      color: style.common.s_color,
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
  };

  return (
    <div
      style={{
        marginTop: "20px",
        padding: "0px 10px 0px 0px",
      }}
    >
      {(isEdit_invite || isEdit || isEdit_two) && (
        <UiTopPopTextBtn
          title="편집 취소"
          isTeacher={isTeacher}
          fnClick={() => {
            setIsEdit(false);
            setIsEdit_two(false);
            setIsEdit_invite(false);
            util.postWeb("isModal_off");
            setSelectData({});
            setSelectData_two({});
          }}
        />
      )}
      {/* {!isTeacher &&
            <div style={{
                width : "100%",
                display:"flex",
                justifyContent:'flex-end',
            }}>
                <SearchBox apiPro_read_student={apiPro_read_teacher} />
            </div>
            } */}
      <div
        style={{
          padding: "0px 10px 15px 10px",
        }}
      >
        {isTeacher && (
          <>
            {/* {
                lectureData["type"] == 5 &&
                <div style={{
                    ...Lecture_book_Style.t_btn_w,
                    display:"flex",
                    alignItems:"center",
                    justifyContent:"space-between",
                    width : "100%",
                }}>
                    <UiBtn
                        btnStyle={{
                            ...Lecture_book_Style.t_btn,
                            width : '145px',
                        }}
                        title={
                            <div style={Academy_book_Style.btn_title}>
                                <Add style={Academy_book_Style.btn_Add} />
                                <div style={{
                                    paddingLeft : "12px"
                                }}>
                                    선생님 초대하기
                                </div>
                            </div>
                            // <div style={Lecture_book_Style.t_btn_text}>
                            //     <Add
                            //         style={Lecture_book_Style.t_btn_Add}
                            //     />
                            //     선생님 초대하기
                            // </div>
                        }
                        fnClick={()=>{
                            setHiddenNav("profile_teacher")
                            navigate(`/invite/${params["who"]}/de/lt/cid&${params["id"]}`)
                        }}
                    />
                    <SearchBox apiPro_read_student={apiPro_read_teacher} />
                </div>
                } */}
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "flex-end",
                alignItems: "center",
              }}
            >
              <img
                src={image_96}
                style={{
                  width: "18px",
                  height: "18px",
                  margin: "0px 5px",
                }}
                onClick={() => {
                  setIsModal_invite(true);
                  // setIsPeople(true)
                }}
              />
            </div>
            {/* {
                invite_teacherData.length > 0 &&
                <div style={{
                    fontSize : "14px",
                    marginTop : "15px",
                    fontWeight:"bold",
                    display : lectureData["type"] != 5 ? "none" : "flex"
                }}>
                     <img src={invitation} style={{
                        width:"32px",
                        height : "32px",
                        marginRight:"5px",
                    }}/>
                    {`초대 승낙 대기 ${invite_teacherData.length}명`}
                </div>
                } */}
            {isEdit_invite && selectData_l_invite > 0 && (
              <div
                style={{
                  ...Lecture_book_Style.edit_section_w,
                  borderTop: isEdit_invite ? "none" : "1px solid",
                  borderColor: style.common.borderColor,
                  display: lectureData["type"] != 5 ? "none" : "flex",
                }}
              >
                선택된 선생님을
                <div style={{}}>
                  {isTeacher && (
                    <div
                      style={{
                        display: "flex",
                      }}
                    >
                      <UiTextBtn
                        text={"초대 취소하기"}
                        btnStyle={{
                          ...Lecture_book_Style.textBtn,
                          color: style.common.fontColor_3,
                          marginRight: "7.5px",
                        }}
                        fnClick={() => {
                          let isOk = window.confirm(
                            "선택하신 선생님들의 초대 신청을 취소하시겠어요?"
                          );
                          if (isOk) {
                            // apiPro_set(selectData,true,false,true)
                            apiPro_del_array(selectData_invite);
                          }
                        }}
                      />
                    </div>
                  )}
                </div>
              </div>
            )}
            {/* {
                lectureData["type"] == 5 &&
                <UiPeople
                    // fnExit={}
                    setHiddenNav={()=>{
                        setHiddenNav("profile_teacher")
                    }}
                    // fnUp={}
                    isManager={lectureData["type"] == 5 ? true : false}
                    oriData={lectureData_ori}
                    isBlank={false}
                    data={invite_teacherData}
                    // isManager={true}
                    form={2}
                    navigate={navigate}
                    // params={params}
                    // navi={"/academybook/"+params["who"]+"/"+3}

                    selectData={selectData_invite}
                    isPeople={true}

                    isEdit={isEdit_invite}
                    isTeacher={isTeacher}

                    fnClick={()=>{

                    }}
                    fnApprove={(d)=>{
                        // apiPro_set(d,1,false,false)
                        apiPro_set(d,false,true,false)
                    }}
                    fnReject={(d)=>{
                        // apiPro_set(d,8,false,false)
                        // apiPro_set(d,true,false,false)
                        apiPro_del(d)
                    }}

                    fnLongClick={(id)=>{
                        // setIsSearch(false)
                        setIsEdit_invite(true)
                        util.postWeb("isModal")
                        setSelectData_invite({
                            ...selectData_invite,
                            [id] : "Y"
                        })
                    }}
                    fnCheck={(e)=>{
                        if(selectData_invite[e.target.id] == "Y"){
                            delete selectData_invite[e.target.id]
                            setSelectData_invite({
                                ...selectData_invite,
                            })
                        }
                        else {
                            setSelectData_invite({
                                ...selectData_invite,
                                [e.target.id] : "Y"
                            })
                        }
                    }}
                />
                } */}
          </>
        )}
        {pre_teacherData.length > 0 && (
          <div
            style={{
              fontSize: "14px",
              marginTop: "15px",
              fontWeight: "bold",
              display: lectureData["type"] != 5 ? "none" : "flex",
            }}
          >
            {`가입 승인 대기 ${pre_teacherData.length}명`}
          </div>
        )}
        {isEdit && selectData_l > 0 && (
          <div
            style={{
              ...Lecture_book_Style.edit_section_w,
              borderTop: isEdit ? "none" : "1px solid",
              borderColor: style.common.borderColor,
              display: lectureData["type"] != 5 ? "none" : "flex",
            }}
          >
            선택된 선생님을
            <div style={{}}>
              {isTeacher && (
                <div
                  style={{
                    display: "flex",
                  }}
                >
                  <UiTextBtn
                    text={"거절하기"}
                    btnStyle={{
                      ...Lecture_book_Style.textBtn,
                      color: style.common.fontColor_3,
                      marginRight: "7.5px",
                    }}
                    fnClick={() => {
                      let isOk = window.confirm("선택하신 선생님들의 신청을 거절하시겠어요?");
                      if (isOk) {
                        // apiPro_set(selectData,true,false,true)
                        apiPro_del_array(selectData);
                      }
                    }}
                  />
                  <UiTextBtn
                    text={"승인하기"}
                    btnStyle={{
                      ...Lecture_book_Style.textBtn,
                      color: style.common.fontColor_5,
                      marginRight: "7.5px",
                    }}
                    fnClick={() => {
                      let isOk = window.confirm("선택하신 선생님들의 신청을 승인하시겠어요?");

                      if (isOk) {
                        apiPro_set(selectData, false, true, true);
                      }
                    }}
                  />
                </div>
              )}
            </div>
          </div>
        )}
        {/* {
                lectureData["type"] == 5 &&
                <UiPeople
                    // fnExit={}
                    // fnUp={}
                    isManager={lectureData["type"] == 5 ? true : false}
                    oriData={lectureData_ori}
                    isBlank={false}
                    data={pre_teacherData}
                    // isManager={true}
                    form={2}
                    navigate={navigate}
                    // params={params}
                    // navi={"/academybook/"+params["who"]+"/"+3}

                    selectData={selectData}
                    isPeople={true}

                    isEdit={isEdit}
                    isTeacher={isTeacher}

                    fnClick={()=>{

                    }}
                    fnApprove={(d)=>{
                        // apiPro_set(d,1,false,false)
                        apiPro_set(d,false,true,false)
                    }}
                    fnReject={(d)=>{
                        // apiPro_set(d,8,false,false)
                        // apiPro_set(d,true,false,false)
                        apiPro_del(d)
                    }}

                    setHiddenNav={()=>{
                        setHiddenNav("profile_teacher")
                    }}

                    fnLongClick={(id)=>{
                        // setIsSearch(false)
                        setIsEdit(true)
                        util.postWeb("isModal")
                        setSelectData({
                            ...selectData,
                            [id] : "Y"
                        })
                    }}
                    fnCheck={(e)=>{
                        if(selectData[e.target.id] == "Y"){
                            delete selectData[e.target.id]
                            setSelectData({
                                ...selectData,
                            })
                        }
                        else {
                            setSelectData({
                                ...selectData,
                                [e.target.id] : "Y"
                            })
                        }
                    }}
                />
                } */}
        {/* <UiSorting
                    title={`전체 강사진 ${teacherData.length}명`}
                    data={[]}
                    isTeacher={isTeacher}
                /> */}
        {isEdit_two && selectData_l_two > 0 && (
          <div
            style={{
              ...Lecture_book_Style.edit_section_w,
              padding: 0,
              borderColor: style.common.borderColor,
            }}
          >
            {isTeacher ? "선택된 선생님을" : "선택된 학생을"}
            <div style={{}}>
              {isTeacher && (
                <div
                  style={{
                    display: "flex",
                  }}
                >
                  <UiTextBtn
                    text={"탈퇴처리"}
                    btnStyle={{
                      ...Lecture_book_Style.textBtn,
                      color: style.common.fontColor_3,
                      marginRight: "7.5px",
                    }}
                    fnClick={() => {
                      let isOk = window.confirm(constants.confirm.cf_17);
                      if (isOk) {
                        // apiPro_set(selectData,true,false,true)
                        apiPro_del_array(selectData_two);
                      }
                    }}
                  />
                </div>
              )}
            </div>
          </div>
        )}
        {invite_teacherData.length + invite_studentData.length > 0 && lectureData["type"] == 5 && (
          <div
            style={{
              ...people_style.sectionTitle,
              color: "#4479A9",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <img
                src={invitation}
                style={{
                  width: "30px",
                  height: "30px",
                  marginRight: "5px",
                  marginBottom: "2px",
                }}
              />
              {`초대 승낙 대기 ${invite_teacherData.length + invite_studentData.length}명`}
            </div>
            {/* <img
                        src={icon_setting_gray}
                        style={{
                            width:"18px",
                            height:"18px",
                        }}
                        onClick={()=>{
                        }}
                    /> */}
          </div>
        )}
        {invite_teacherData.length + invite_studentData.length > 0 && lectureData["type"] == 5 && (
          <>
            <UiPeople_Type2
              // fnExit={}
              setHiddenNav={() => {
                setHiddenNav("profile_teacher");
              }}
              // fnUp={}
              isManager={lectureData["type"] == 5 ? true : false}
              isBlank={false}
              data={invite_teacherData}
              // isManager={true}
              form={2}
              navigate={navigate}
              // params={params}
              // navi={"/academybook/"+params["who"]+"/"+3}

              selectData={selectData_invite}
              isPeople={true}
              isEdit={isEdit_invite}
              isTeacher={isTeacher}
              fnClick={() => {}}
              fnApprove={(d) => {
                // apiPro_set(d,1,false,false)
                apiPro_set(d, false, true, false);
              }}
              fnReject={(d) => {
                // apiPro_set(d,8,false,false)
                // apiPro_set(d,true,false,false)
                apiPro_del(d);
              }}
              fnLongClick={(id) => {
                // setIsSearch(false)
                setIsEdit_invite(true);
                util.postWeb("isModal");
                setSelectData_invite({
                  ...selectData_invite,
                  [id]: "Y",
                });
              }}
              fnCheck={(e) => {
                if (selectData_invite[e.target.id] == "Y") {
                  delete selectData_invite[e.target.id];
                  setSelectData_invite({
                    ...selectData_invite,
                  });
                } else {
                  setSelectData_invite({
                    ...selectData_invite,
                    [e.target.id]: "Y",
                  });
                }
              }}
            />
            <UiPeople_Type2
              userInfo={userInfo}
              fnReject={(d) => {
                // apiPro_set(d,true,false)
                apiPro_del(d);
              }}
              fnApprove={(d) => {
                apiPro_set(d, false, true);
              }}
              isBlank={false}
              data={invite_studentData}
              isManager={true}
              form={2}
              isPeople={true}
              navigate={navigate}
              // params={params}
              // navi={"/academybook/"+params["who"]+"/"+3}

              selectData={selectData_invite}
              isEdit={isEdit_invite}
              isTeacher={isTeacher}
              fnClick={() => {}}
              setHiddenNav={() => {
                setHiddenNav("teacher");
              }}
              fnLongClick={(id) => {
                // setIsSearch(false)
                setIsEdit_invite(true);
                util.postWeb("isModal");
                setSelectData_invite({
                  ...selectData_invite,
                  [id]: "Y",
                });
              }}
              fnCheck={(e) => {
                if (selectData_invite[e.target.id] == "Y") {
                  delete selectData[e.target.id];
                  setSelectData_invite({
                    ...selectData_invite,
                  });
                } else {
                  setSelectData_invite({
                    ...selectData_invite,
                    [e.target.id]: "Y",
                  });
                }
              }}
            />
          </>
        )}
        {lectureData["type"] == 5 && (
          <>
            <div
              style={{
                ...people_style.sectionTitle,
                color: "#F09294",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <img
                  src={wait}
                  style={{
                    width: "32px",
                    height: "32px",
                    marginRight: "5px",
                  }}
                />
                {`수강 신청 대기 ${pre_teacherData.length + pre_studentData.length}명`}
              </div>
              {/* <img
                        src={icon_setting_gray}
                        style={{
                            width:"18px",
                            height:"18px",
                        }}
                        onClick={()=>{
                        }}
                    /> */}
            </div>
            <UiPeople_Type2
              // fnExit={}
              // fnUp={}
              isManager={lectureData["type"] == 5 ? true : false}
              isBlank={false}
              data={pre_teacherData}
              // isManager={true}
              form={2}
              // params={params}
              // navi={"/academybook/"+params["who"]+"/"+3}

              selectData={selectData}
              isPeople={true}
              isEdit={isEdit}
              isTeacher={isTeacher}
              fnClick={() => {}}
              fnApprove={async (d) => {
                await createInitialTicket({
                  variables: {
                    userId: d.id,
                    academyId: params.id,
                  },
                });
                apiPro_set(d, false, true, false);
              }}
              fnReject={(d) => {
                // apiPro_set(d,8,false,false)
                // apiPro_set(d,true,false,false)
                apiPro_del(d);
              }}
              setHiddenNav={() => {
                setHiddenNav("profile_teacher");
              }}
              fnLongClick={(id) => {
                // setIsSearch(false)
                setIsEdit(true);
                util.postWeb("isModal");
                setSelectData({
                  ...selectData,
                  [id]: "Y",
                });
              }}
              fnCheck={(e) => {
                if (selectData[e.target.id] == "Y") {
                  delete selectData[e.target.id];
                  setSelectData({
                    ...selectData,
                  });
                } else {
                  setSelectData({
                    ...selectData,
                    [e.target.id]: "Y",
                  });
                }
              }}
            />
            <UiPeople_Type2
              // fnExit={}
              // fnUp={}
              isManager={lectureData["type"] == 5 ? true : false}
              isBlank={false}
              data={pre_studentData}
              // isManager={true}
              form={2}
              navigate={navigate}
              // params={params}
              // navi={"/academybook/"+params["who"]+"/"+3}
              selectData={selectData}
              isPeople={true}
              isEdit={isEdit}
              isTeacher={isTeacher}
              fnClick={() => {}}
              fnApprove={async (d) => {
                await createInitialTicket({
                  variables: {
                    userId: d.id,
                    academyId: params.id,
                  },
                });
                apiPro_set(d, false, true, false);
              }}
              fnReject={(d) => {
                // apiPro_set(d,8,false,false)
                // apiPro_set(d,true,false,false)
                apiPro_del(d);
              }}
              setHiddenNav={() => {
                setHiddenNav("profile_teacher");
              }}
              fnLongClick={(id) => {
                // setIsSearch(false)
                setIsEdit(true);
                util.postWeb("isModal");
                setSelectData({
                  ...selectData,
                  [id]: "Y",
                });
              }}
              fnCheck={(e) => {
                if (selectData[e.target.id] == "Y") {
                  delete selectData[e.target.id];
                  setSelectData({
                    ...selectData,
                  });
                } else {
                  setSelectData({
                    ...selectData,
                    [e.target.id]: "Y",
                  });
                }
              }}
            />
          </>
        )}
        <div
          style={{
            ...people_style.sectionTitle,
            color: "#49A5B1",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <img
              src={teacher_}
              style={{
                width: "32px",
                height: "32px",
                marginRight: "3px",
              }}
            />
            {`참여중인 선생님 ${teacherData.length}명`}
          </div>
          {/* {
                    lectureData["type"] == 5 &&
                    <img
                        src={icon_setting_gray}
                        style={{
                            width:"18px",
                            height:"18px",
                        }}
                        onClick={()=>{
                        }}
                    />
                    } */}
        </div>
        <UiPeople_Type2
          // fnExit={}
          // fnUp={}
          form={2}
          userInfo={userInfo}
          isManager={lectureData["type"] == 5 ? true : false}
          isBlank={false}
          data={teacherData}
          isOnly={params["who"] == "teacher" ? (lectureData["type"] == 6 ? true : false) : false}
          // isManager={isTeacher ? true : false}
          manageSub={true}
          navigate={navigate}
          isTeacherPage={isTeacher}
          fnUp={(d) => {
            // apiPro_set(d,5,true,false)
            apiPro_set(d, false, false, false, true, false);
          }}
          fnDown={(d) => {
            // apiPro_set(d,6,true,false)
            apiPro_set(d, true, false, false, false, true);
          }}
          fnExit={(d) => {
            // // console.log(d,"del")
            apiPro_del(d);
            // apiPro_set(d,true,false,false,false,false)
            // apiPro_set(d,9,false,false)
          }}
          fnAssist={(d) => {
            apiPro_set(d, false, false, false, false, false, true);
          }}
          // params={params}
          // navi={"/academybook/"+params["who"]+"/"+3}

          selectData={selectData_two}
          isEdit={isEdit_two}
          isTeacher={isTeacher}
          fnClick={() => {}}
          setHiddenNav={() => {
            setHiddenNav("profile_teacher");
          }}
          fnTalk={(e, data) => {
            // console.log(e,data)
            // setHiddenNav("teacher")
            if (params["who"] == "teacher") {
              navigate(`/talk/${params["who"]}/0/0/${data["id"]}/${userInfo["id"]}`);
            } else {
              navigate(`/talk/${params["who"]}/0/0/${userInfo["id"]}/${data["id"]}`);
            }
          }}
          fnQan_see={(e, data) => {
            setFileObj({
              base64: "null",
            });
            navigate(`/searchresult/${params["who"]}/see&${data["id"]}/de`);
            // navigate(`/searchresult/student/see/de`)
          }}
          fnQna={(e, data) => {
            apiPro_img(e, data);
          }}
          fnLongClick={(id) => {
            // setIsSearch(false)
            setIsEdit_two(true);
            util.postWeb("isModal");
            setSelectData_two({
              ...selectData_two,
              [id]: "Y",
            });
          }}
          fnCheck={(e) => {
            if (selectData_two[e.target.id] == "Y") {
              delete selectData_two[e.target.id];
              setSelectData_two({
                ...selectData_two,
              });
            } else {
              setSelectData_two({
                ...selectData_two,
                [e.target.id]: "Y",
              });
            }
          }}
        />
        <div style={people_style.sectionTitle}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <img
              src={student_}
              style={{
                width: "32px",
                height: "32px",
                marginRight: "3px",
              }}
            />
            {`참여중인 학생 ${studentData.length}명`}
          </div>
          {/* {
                    lectureData["type"] == 5 &&
                    <img
                        src={icon_setting_gray}
                        style={{
                            width:"18px",
                            height:"18px",
                        }}
                        onClick={()=>{
                        }}
                    />
                    } */}
        </div>
        <UiPeople_Type2
          isOnly={params["who"] == "student" ? true : false}
          userInfo={userInfo}
          isBlank={false}
          data={studentData}
          isManager={true}
          manageSub={true}
          // isInfo={true}
          form={2}
          navigate={navigate}
          // params={params}
          // navi={"/academybook/"+params["who"]+"/"+3}

          selectData={selectData_two}
          setHiddenNav={() => {
            setHiddenNav("profile_student");
          }}
          fnExit={(d) => {
            let isOk = window.confirm("해당 학생을 탈퇴처리 하시겠어요?");
            if (isOk) {
              // apiPro_set(d,true,false,false)
              apiPro_del(d);
            }
          }}
          isEdit={isEdit_two}
          isTeacher={isTeacher}
          fnClick={isTeacher ? () => {} : false}
          fnQan_see={(e, data) => {
            // console.log("Qna_see ",data)
            setFileObj({
              base64: "null",
            });
            navigate(`/searchresult/${params["who"]}/see&${data["id"]}/de`);
            // navigate(`/searchresult/student/see/de`)
          }}
          // fnQan_see={(e,data)=>{
          //     // console.log("Qna_see")
          // }}
          fnTalk={(e, data) => {
            // console.log(e,data)
            if (params["who"] == "teacher") {
              navigate(`/talk/${params["who"]}/0/0/${data["id"]}/${userInfo["id"]}`);
            } else {
              navigate(`/talk/${params["who"]}/0/0/${userInfo["id"]}/${data["id"]}`);
            }
          }}
          fnLongClick={(id) => {
            // setIsSearch(false)
            setIsEdit_two(true);
            util.postWeb("isModal");
            setSelectData_two({
              ...selectData_two,
              [id]: "Y",
            });
          }}
          fnCheck={(e) => {
            if (selectData_two[e.target.id] == "Y") {
              delete selectData_two[e.target.id];
              setSelectData_two({
                ...selectData_two,
              });
            } else {
              setSelectData_two({
                ...selectData_two,
                [e.target.id]: "Y",
              });
            }
          }}
        />
      </div>
    </div>
  );
};

export default Lecture_people;
