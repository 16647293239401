import { React, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import apiFn from "../../asset/apiClass";
import store from "../../asset/store";
import style from "../../asset/style";
import Basic from "../../common/frame/Basic";
import UiBtn from "../../uiComponents/UiBtn/UiBtn";
import UiFolder from "../../uiComponents/UiFolder/UiFolder";
import Message_Mark_Style from "./Message_Mark_Style";

const Message_Folder = ({ userInfo }) => {
  let params = useParams();
  let navigate = useNavigate();

  var [selectData, setSelectData] = useState({});
  var [selectData_l, setSelectData_l] = useState(0);

  var [folderList, setFolderList] = useState([]);

  // 찜
  const apiPro_pick = async (data) => {
    try {
      // // console.log(data)
      // // console.log(data)
      // // console.log(data)
      // // console.log(data)
      let isOk = await apiFn.comApi({
        state: {
          table: "folder_link",
          ins: [
            {
              fid: data["id"],
              mid: userInfo["id"],
              type: 1,
              qid: params["qid"],
              reg_date: 0,
            },
          ],
        },
      });

      const stack = store.get("stack");
      if (stack.length > 0) navigate(-1);
      else navigate("/");
    } catch (e) {}
  };

  // const apiPro_regi = async (name) => {
  //     try {
  //         let isOk = await apiFn.comApi({
  //             state : {
  //                 table : "folder",
  //                 ins : [
  //                     {
  //                         name : name ,
  //                         ftype : 1 ,
  //                         mid : userInfo["id"],
  //                         open : 1,
  //                         usee : 1,
  //                     }
  //                 ]
  //             }
  //         })

  //         await apiPro_read();
  //     }
  //     catch(e){
  //     }
  // }

  const apiPro_read = async () => {
    try {
      let data = await apiFn.comApi({
        state: {
          table: "folder",
          page: 1,
          amount: 9999,
          sort: "name desc",
          where: {
            "HIQDB_folder.mid": `='${userInfo["id"]}'`,
            "HIQDB_folder.ftype": `= '1'`,
          },
        },
      });

      setFolderList([...data["list"]]);
    } catch (e) {}
  };

  useEffect(() => {
    selectData_l = 0;
    Object.keys(selectData).map((v, i) => {
      if (selectData[v] == "Y") {
        selectData_l = selectData_l + 1;
      } else if (selectData[v] == "N") {
        if (selectData_l == 0) return;
        selectData_l = selectData_l - 1;
      }
    });
    setSelectData_l(selectData_l);
  }, [selectData]);

  useEffect(() => {
    apiPro_read();
  }, []);

  return (
    <Basic title={"즐겨찾기 폴더 선택"}>
      <div style={Message_Mark_Style.textBtn_add_c}>
        <div style={Message_Mark_Style.textBtn_add_w}>
          <UiBtn
            btnStyle={{
              ...Message_Mark_Style.textBtn_add,
              backgroundColor:
                params["who"] == "teacher" ? style.common.t_color : style.common.s_color,
            }}
            title="나중에 볼 질문"
            fnClick={() => {
              let data = {
                id: "1",
              };
              apiPro_pick(data);
            }}
          />
        </div>
      </div>
      <div style={Message_Mark_Style.list_w}>
        {folderList.map((v, i) => (
          <UiFolder
            fnClick={(data) => {
              // console.log("@@ ",data);
              apiPro_pick(data);
            }}
            data={v}
            // setSelectData={setSelectData}
            // selectData={selectData}
            // isEdit={isEdit}
            // isTeacher={isTeacher}
            // setIsSearch={setIsSearch}
            // fnLongClick={(id)=>{
            //     setIsSearch(false)
            //     setIsEdit(true)
            //     setSelectData({
            //         ...selectData,
            //         [id] : "Y"
            //     })
            // }}
            // fnCheck={(e)=>{
            //     if(selectData[e.target.id] == "Y"){
            //         delete selectData[e.target.id]
            //         setSelectData({
            //             ...selectData,
            //         })
            //     }
            //     else {
            //         setSelectData({
            //             ...selectData,
            //             [e.target.id] : "Y"
            //         })
            //     }
            // }}
            // key={"mark_folder_"+i}
            // id={i}
            // navigate={navigate}
            // navi={"/messageMark/"+params["who"]+"/"+v["id"]+"/default"}
            // params={params}
          />
        ))}
      </div>
    </Basic>
  );
};

export default Message_Folder;
