import {React, useEffect, useState} from 'react'
import {useNavigate, useParams} from 'react-router';
import style from '../../../asset/style'
import Basic from '../../../common/frame/Basic'
import aquery from '../../../asset/Img/aquery.png'
import answer from '../../../asset/Img/answer.png'
import Register_Intro_style from './Register_Intro_Style';
import Register_One_style from './Register_One_style';
import RegiForm from './RegiForm';
import {UiInput_title} from '../../../uiComponents/UiInput/UiInput';
import UiBtn from '../../../uiComponents/UiBtn/UiBtn';
import apiFn from '../../../asset/apiClass';
import util from '../../../asset/util';
import crypto from "crypto-js";
import axios from "axios";
import constants from "../../../asset/constants";

const Register_chk = ({
                          regiData,
                          setRegiData,
                          setUserInfo
                      }) => {

    let params = useParams();
    let navigate = useNavigate();

    const [isTeacher, setIsTeacher] = useState(false);
    const [isChk, setIsChk] = useState(false);

    const [isCode, setIsCode] = useState(false);

    // const [tel,setTel] = useState("");
    const [chk, setChk] = useState("");

    const [chk_count, setChk_count] = useState('03:00')

    const apiPro_chk = async (code) => {
        try {
            let state = {
                tel: regiData["tel"],
                oid: regiData["oid"]
            }
            if (code) {
                state["code"] = code
            }
            // oid = user // apple
            let isOk = await apiFn.checkHP({
                state: {
                    ...state
                }
            })
            // console.log(isOk)
            if (isOk["code"]) {
                setIsCode(true)
                count_interval = setInterval(() => {
                    if (start_count == 0) {
                        stop_interval();
                    }
                    let _timeStamp = new Date(start_count).getTime();
                    setChk_count(util.converToSecond(_timeStamp))
                    start_count = start_count - 1
                }, 1000);
                count_time();
            } else if (isOk.status === 12) {
                const pw = crypto.enc.Base64.stringify(crypto.SHA256(state["oid"]))
                const {data} = await axios.post(`${constants.apiUrl}/auth/${isOk?.userInfo?.id}/userCredential`, {
                    user_id: isOk.userInfo.index,
                    provider: 'apple',
                    oid: state.oid,
                    channelId: constants?.channelId,
                    pw
                }, {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                if (data) {
                    let isOk = await apiFn.fnNewSignIn({
                        state: {
                            id: data.id,
                            pw: state.oid
                        }
                    })
                    if (!isOk) {
                        alert('로그인에 실패했습니다. 문제가 계속 발생하면 문의해주세요.')
                        return
                    }
                    setUserInfo({
                        ...isOk
                    })
                    alert('로그인 정보를 기록하는데 성공했습니다.')
                    navigate(`/message/${isOk.type === 1 ? 'student' : 'teacher'}/home`)
                }
            } else if (isOk === -1) {
                navigate('/', {replace: true})
            } else {
                alert("인증 되었습니다.")
                setIsChk(true)
            }
        } catch (e) {
            // console.log("@@ ",e);
        }
    }

    let start_count = 180
    var count_interval;


    var stop_interval = () => {
        clearInterval(count_interval)
        count_interval = null
        start_count = 180
        setIsCode(false)
    }

    const count_time = () => {
        try {
            count_interval();
        } catch (e) {
            // console.log(e)
        }
    }

    useEffect(() => {
        if (params["who"] == "teacher") {
            setIsTeacher(true)
        } else {
            setIsTeacher(false)
        }
    }, [params])

    useEffect(() => {
        // // console.log("regiData => ",regiData);
    }, [regiData])

    useEffect(() => {
        setRegiData({
            ...regiData,
            provider: "apple"
        })
    }, [])

    return (
        <Basic>
            <RegiForm
                // num={3}
                isTeacher={isTeacher}
                title="전화번호 인증"
            />
            <UiInput_title
                c_style={{
                    width: "100%",
                    paddingRight: "10px",
                }}
                type='basic'
                title="[필수] 이름"
                placeholder="이름을 입력해주세요."
                inputClass={isTeacher ? "input_t" : "input_s"}
                inputValue={regiData["name"] ? regiData["name"] : ""}
                fnChange={(e) => {
                    setRegiData({
                        ...regiData,
                        name: e.target.value
                    })
                }}
            />
            <UiInput_title
                c_style={{
                    width: "100%",
                    paddingRight: "10px",
                }}
                type='basic'
                title="[필수] 이메일"
                inputValue={regiData["email"] ? regiData["email"] : ""}
                placeholder="이메일을 입력해주세요."
                inputClass={isTeacher ? "input_t" : "input_s"}
                fnChange={(e) => {
                    setRegiData({
                        ...regiData,
                        email: e.target.value
                    })
                }}
            />
            <div style={{
                display: "flex",
                alignItems: "center",
                marginTop: "20px",
            }}>

                <UiInput_title
                    c_style={{
                        width: "100%",
                        paddingRight: "10px",
                    }}
                    type='basic'
                    title="[필수] 전화번호"
                    placeholder="전화번호를 입력해주세요."
                    inputClass={isTeacher ? "input_t" : "input_s"}
                    fnChange={(e) => {
                        // setTel()
                        setRegiData({
                            ...regiData,
                            tel: e.target.value
                        })
                    }}
                />
                <UiBtn
                    btnStyle={{
                        width: "83px",
                        height: "42px",
                        marginTop: "30px",
                        backgroundColor:
                            isCode ?
                                style.common.borderColor
                                :
                                (isTeacher ? style.common.t_color : style.common.s_color)
                        ,

                        color: "white",
                    }}
                    type={"small"}
                    title={isCode ? chk_count : "발급"}
                    fnClick={() => {
                        if (isCode) return;
                        let regex = /[0-9]/
                        if (!regex.test(regiData["tel"])) {
                            alert("숫자만 입력해주세요.")
                            return;
                        } else {
                            apiPro_chk();
                        }
                    }}
                />


            </div>
            <div style={{
                display: "flex",
                alignItems: "center",
                marginTop: "20px",
            }}>

                <UiInput_title
                    c_style={{
                        width: "100%",
                        paddingRight: "10px",
                    }}
                    type='basic'
                    title="인증번호"
                    placeholder="인증번호를 입력해주세요."
                    inputClass={isTeacher ? "input_t" : "input_s"}
                    fnChange={(e) => {
                        setChk(e.target.value)
                    }}
                />
                <UiBtn
                    btnStyle={{
                        width: "83px",
                        height: "42px",
                        marginTop: "30px",
                        backgroundColor: isTeacher ? style.common.t_color : style.common.s_color,
                        color: "white",
                    }}
                    type={"small"}
                    title="확인"
                    fnClick={() => {
                        apiPro_chk(chk);
                    }}
                />


            </div>

            <div style={Register_One_style.b_c}>
                <UiBtn
                    btnStyle={{
                        ...Register_One_style.b,
                        borderColor:
                            isChk ?
                                (isTeacher ? style.common.t_color : style.common.s_color)
                                :
                                style.common.borderColor
                        ,
                        backgroundColor:
                            isChk ?
                                (isTeacher ? style.common.t_color : style.common.s_color)
                                :
                                "rgb(200,200,200)"

                    }}
                    title="다음"
                    fnClick={() => {
                        if (isChk) {
                            navigate("/regiThree/" + params["who"])
                            // apiPro_regi();
                        }
                    }}
                />
            </div>
        </Basic>
    )
}

export default Register_chk
