import { ArrowDropDown, ArrowDropUp, LocalActivity } from "@material-ui/icons";
import {
  Image,
  KeyboardDoubleArrowLeft,
  KeyboardDoubleArrowRight,
  Link,
  Videocam,
} from "@mui/icons-material";
import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router";
import noBook from "../../asset/Img/book1.png";
import gridview from "../../asset/Img/gridview.png";
import imageview from "../../asset/Img/imageview.png";
import listview from "../../asset/Img/listview.png";
import style from "../../asset/style";
import Basic from "../../common/frame/Basic";
import { UiCheckBox } from "../../uiComponents/UiCheckBox/UiCheckBox";
import "./Search_result.css";
import styles from "./postSearch.module.scss";
import { getUser } from "../../asset/storage";
import {
  questionSearchOption,
  questionSearchResponseData,
  useGetOcrData,
  useQuestionSearch,
} from "../../api/questionSearch";
import QuestionGridItem from "./components/QuestionGridItem";
import QuestionListItem from "./components/QuestionListItem";
import QuestionImageItem from "./components/QuestionImageItem";
import { CircularProgress } from "@mui/material";
import Slider from "react-slick";
import { CommonContext } from "../../asset/context";
import Search_result_style from "./Search_result_style";

type QuestionImageSearch = {
  ori_data: any;
  setIsSR_detail: any;
  setHiddenUrl: any;
  setScrolllKey: any;
};

export const iconTypeMapping = {
  1: <Image style={{ color: "#487fba", fontSize: "18px", marginRight: "2px" }} />,
  2: <Videocam style={{ color: "#487fba", fontSize: "18px", marginRight: "3px" }} />,
  3: <Videocam style={{ color: "#487fba", fontSize: "18px", marginRight: "3px" }} />,
  4: <Link style={{ color: "#487fba", fontSize: "18px", marginRight: "3px" }} />,
  8: <Link style={{ color: "#487fba", fontSize: "18px", marginRight: "3px" }} />,
};
const QuestionImageSearch = ({
  ori_data,
  setIsSR_detail,
  setHiddenUrl,
  setScrolllKey,
}: QuestionImageSearch) => {
  let params = useParams();
  let navigate = useNavigate();
  const userInfo = getUser();

  let sort = [
    { name: "이름순", value: "name" },
    { name: "개설순", value: "reg_date" },
    { name: "수강인원순", value: "n03" },
  ];

  let sort_count = [
    { name: "5", value: 5 },
    { name: "10", value: 10 },
    { name: "20", value: 20 },
    { name: "50", value: 50 },
  ];
  let sort_qna = [
    { name: "모범 문항", value: "mobum" },
    { name: "완료 문항", value: "qna" },
    { name: "질문 문항", value: "live" },
    { name: "전체 문항", value: "all" },
  ];

  const [sortingCount, setSortingCount] = useState<number>(0);
  const [sortingQna, setSortingQna] = useState("모범 문항");

  const [isSort, setIsSort] = useState(false);
  const [isSort_qna, setIsSort_qna] = useState(false);

  const [isMy, setIsMy] = useState(
    window.localStorage.getItem("search_result_my")
      ? JSON.parse(window.localStorage.getItem("search_result_my"))
      : false
  );

  const [listStat, setListStat] = useState("mobum");

  const [listForm, setListForm] = useState("listview");
  const [currentSlide, setCurrentSlider] = useState(0);
  const sliderRef = useRef(null);
  const [isSearchDataLoading, setIsSearchDataLoading] = useState(false);
  const { fileObj, setQnaData, qnaData } = useContext(CommonContext);

  const fileObjectBase64: string = useMemo(() => {
    return fileObj["base64"]
      ?.replace(/^data:image\/png;base64,/, "")
      ?.replace(/^data:image\/jpeg;base64,/, "");
  }, [fileObj]);

  const { data: postQuestionResponse } = useQuestionSearch({
    userId: userInfo.id,
    userType: userInfo.type,
    option: questionSearchOption[listStat],
    bookTypes: [currentSlide],
    limit: Number(sortingCount),
    searchData: localStorage.getItem("ocrData"),
    searchType: "text",
    myQuestion: isMy,
  });

  const { data: ocrInfo } = useGetOcrData(
    fileObjectBase64,
    localStorage.getItem("ocrData") === null
  );

  useEffect(() => {
    if (!ocrInfo) setIsSearchDataLoading(true);
    else {
      setIsSearchDataLoading(false);
      localStorage.setItem("ocrData", JSON.stringify(ocrInfo.result));
    }
  }, [ocrInfo]);

  const postQuestionList = useMemo(() => postQuestionResponse, [postQuestionResponse]);
  const getAnswerIcons = (questionData: questionSearchResponseData) => {
    if ([1, 2].includes(questionData?.HIQDB_book_links?.[0]?.bid_HIQDB_book?.book_type))
      return [<></>];
    if (questionData?.HIQDB_online_qna_msgs?.length === 0) return [<div>답변 없음</div>];

    const icons = new Set(
      questionData?.HIQDB_online_qna_msgs?.map((msg) => iconTypeMapping[msg.ctype]).filter(Boolean)
    );

    return [...icons];
  };

  const titleContent = useMemo(() => {
    let text = "문제검색결과";
    if (currentSlide === 1) text = "PDF 검색결과";
    else if (currentSlide === 2) text = "내 필기 검색결과";
    else if (currentSlide === 3) text = "시중 교재 검색 결과";
    return text;
  }, [currentSlide]);

  useEffect(() => {
    if (window.localStorage.getItem("search_result_my")) {
      setIsMy(JSON.parse(window.localStorage.getItem("search_result_my")));
    }
    if (window.localStorage.getItem("s_result_amount")) {
      setSortingCount(Number(JSON.parse(window.localStorage.getItem("s_result_amount"))) ?? 5);
    }
    if (window.localStorage.getItem("s_result_sort")) {
      setListStat(JSON.parse(window.localStorage.getItem("s_result_sort"))["value"]);
      setSortingQna(JSON.parse(window.localStorage.getItem("s_result_sort"))["name"]);
    }
    if (window.localStorage.getItem("explain_section")) {
      setListForm(window.localStorage.getItem("explain_section"));
    }
  }, [params]);

  useEffect(
    () => () => {
      window.localStorage.removeItem("isAnswer");
      window.localStorage.removeItem("talk_bookId");
      window.localStorage.removeItem("postImg");
    },
    []
  );

  return (
    <>
      <Basic
        isPadding={false}
        title={titleContent}
        containerStyle={{ overflow: "hidden", maxHeight: "100vh" }}
        pastSearchQna={true}
        backEvent={() => {
          localStorage.removeItem("ocrData");
        }}
      >
        <div
          style={{
            display: "flex",
            flex: 1,
            paddingBottom: "10px",
            flexDirection: "column",
            marginTop: "15px",
            height: "calc(100% - 50px)",
          }}
        >
          <div
            style={{
              display: "flex",
              width: "100%",
              maxHeight: "30%",
              justifyContent: "center",
              overflowY: "auto",
            }}
          >
            <div
              className="solCrop__drag-handle"
              style={{
                display: "flex",
                justifyContent: "center",
                width: "85vw",
                height: "fit-content",
                position: "relative",
              }}
            >
              <img
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "contain",
                  objectPosition: "center",
                  border: "1px solid",
                  borderColor: style.common.borderColor4,
                  borderRadius: "5px",
                }}
                onError={(e) => {
                  if (ori_data) {
                    e.currentTarget.src = noBook;
                  }
                }}
                src={fileObj.base64}
              />
              <div
                style={{
                  width: "60px",
                  height: "60px",
                  position: "absolute",
                  top: "0",
                  left: 0,
                  borderLeft: Search_result_style.border_,
                  borderTop: Search_result_style.border_,
                }}
              ></div>
              <div
                style={{
                  width: "60px",
                  height: "60px",
                  position: "absolute",
                  top: "0",
                  right: 0,
                  borderRight: Search_result_style.border_,
                  borderTop: Search_result_style.border_,
                }}
              ></div>
              <div
                style={{
                  width: "60px",
                  height: "60px",
                  position: "absolute",
                  bottom: "0",
                  left: 0,
                  borderLeft: Search_result_style.border_,
                  borderBottom: Search_result_style.border_,
                }}
              ></div>
              <div
                style={{
                  width: "60px",
                  height: "60px",
                  position: "absolute",
                  bottom: "0",
                  right: 0,
                  borderRight: Search_result_style.border_,
                  borderBottom: Search_result_style.border_,
                }}
              ></div>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              flex: 1,
              marginTop: "10px",
              height: "60%",
            }}
          >
            <div
              style={{
                backgroundColor: "white",
                padding: "10px 10px 0px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                    }}
                  >
                    {
                      <div
                        style={{
                          position: "relative",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            fontSize: "14px",
                            fontWeight: 400,
                            alignItems: "center",
                          }}
                        >
                          {`${sortingCount ? sortingCount : "5"}개씩`}
                          {isSort ? (
                            <ArrowDropUp
                              style={{
                                fontSize: "40px",
                                marginBottom: "3px",
                              }}
                              onClick={() => {
                                setIsSort(false);
                              }}
                            />
                          ) : (
                            <ArrowDropDown
                              style={{
                                fontSize: "40px",
                                marginBottom: "3px",
                              }}
                              onClick={() => {
                                setIsSort(true);
                              }}
                            />
                          )}
                        </div>
                        {isSort ? (
                          <div
                            style={{
                              position: "absolute",
                              left: 0,
                              // top:10,
                              borderRadius: "20px",
                              border: "1px solid #F6F6F6",
                              boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                              padding: "3px 0px",
                              backgroundColor: "white",
                              zIndex: 10,
                            }}
                          >
                            {sort_count.map((v, i) => (
                              <div
                                style={{
                                  padding: "15px 0px",
                                  width: "120px",
                                  backgroundColor: "white",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  fontWeight: 400,
                                  fontSize: "14px",
                                  color: "#3D3A39",
                                  borderRadius: "20px",
                                }}
                                onClick={(e) => {
                                  setSortingCount(v["value"]);
                                  setIsSort(false);
                                  window.localStorage.setItem(
                                    "s_result_amount",
                                    v["value"].toString()
                                  );
                                }}
                              >
                                {v["name"]}
                              </div>
                            ))}
                          </div>
                        ) : (
                          <></>
                        )}
                      </div>
                    }

                    <div
                      style={{
                        position: "relative",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          fontSize: "14px",
                          fontWeight: 400,
                          alignItems: "center",
                        }}
                      >
                        {`${sortingQna ? sortingQna : "모범문항"}`}
                        {isSort_qna ? (
                          <ArrowDropUp
                            style={{
                              fontSize: "40px",
                              marginBottom: "3px",
                            }}
                            onClick={() => {
                              setIsSort_qna(false);
                            }}
                          />
                        ) : (
                          <ArrowDropDown
                            style={{
                              fontSize: "40px",
                              marginBottom: "3px",
                            }}
                            onClick={() => {
                              setIsSort_qna(true);
                            }}
                          />
                        )}
                      </div>
                      {isSort_qna ? (
                        <div
                          style={{
                            position: "absolute",
                            left: 0,
                            borderRadius: "20px",
                            border: "1px solid #F6F6F6",
                            boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                            padding: "3px 0px",
                            backgroundColor: "white",
                            zIndex: 10,
                          }}
                        >
                          {sort_qna.map((v, i) => (
                            <div
                              style={{
                                padding: "15px 0px",
                                width: "120px",
                                backgroundColor: "white",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                fontWeight: 400,
                                fontSize: "14px",
                                color: "#3D3A39",
                                borderRadius: "20px",
                              }}
                              onClick={(e) => {
                                window.localStorage.setItem("s_result_sort", JSON.stringify(v));
                                setListStat(v.value);
                                setSortingQna(v["name"]);
                                setIsSort_qna(false);
                              }}
                            >
                              {v["name"]}
                            </div>
                          ))}
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                </div>

                <div
                  style={{
                    fontSize: "12px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginRight: "15px",
                    }}
                  >
                    {listForm === "listview" ? (
                      <img
                        src={listview}
                        style={{
                          width: "22px",
                          height: "22px",
                        }}
                        onClick={() => {
                          setListForm("gridview");
                          window.localStorage.setItem("explain_section", "gridview");
                        }}
                      />
                    ) : listForm === "gridview" ? (
                      <img
                        src={gridview}
                        style={{
                          width: "22px",
                          height: "22px",
                        }}
                        onClick={() => {
                          setListForm("imageview");
                          window.localStorage.setItem("explain_section", "imageview");
                        }}
                      />
                    ) : (
                      <img
                        src={imageview}
                        style={{
                          width: "22px",
                          height: "22px",
                        }}
                        onClick={() => {
                          setListForm("listview");
                          window.localStorage.setItem("explain_section", "listview");
                        }}
                      />
                    )}
                  </div>
                  {!window.localStorage.getItem("isAnswer") && (
                    <>
                      <UiCheckBox
                        value={"isImportant"}
                        className={params["who"] == "teacher" ? "t" : "s"}
                        checked={isMy}
                        fnCheck={(e) => {
                          window.localStorage.setItem("search_result_my", JSON.stringify(!isMy));
                          setIsMy((prev) => !prev);
                        }}
                      />
                      <div
                        style={{
                          marginLeft: 3,
                        }}
                      >
                        내 {currentSlide === 0 ? "문항" : "PDF"}
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
            <div
              style={{
                // backgroundColor:"white",
                display: "flex",
                flexDirection: "column",
                padding: "0px 5px",
                // overflow:"sco"
                overflowY: "auto",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div>
                  <span
                    style={{
                      fontSize: "16px",
                      fontWeight: 400,
                    }}
                  >
                    검색결과
                  </span>
                  <span
                    style={{
                      fontSize: "16px",
                      fontWeight: 700,
                      marginLeft: "6px",
                    }}
                  >
                    {`총 ${postQuestionList?.length ?? 0} 문항`}
                  </span>
                </div>
                {!window.localStorage.getItem("isAnswer") && (
                  <div className={styles.buttonSection}>
                    <button
                      className={styles.btn}
                      onClick={() => {
                        sliderRef.current.slickGoTo(currentSlide - 1);
                      }}
                    >
                      <KeyboardDoubleArrowLeft />
                      {currentSlide === 0
                        ? "시중 교재 검색 결과 보기"
                        : currentSlide === 1
                          ? "문제 검색 보기"
                          : currentSlide === 2
                            ? "PDF 검색 결과 보기"
                            : "내 필기 검색 보기"}
                    </button>
                    <button
                      className={styles.btn}
                      onClick={() => {
                        sliderRef.current.slickGoTo(currentSlide + 1);
                      }}
                    >
                      {currentSlide === 0
                        ? "PDF 검색 결과 보기"
                        : currentSlide === 1
                          ? "내 필기 검색 보기"
                          : currentSlide === 2
                            ? "시중 교재 검색 결과 보기"
                            : "문제 검색 보기"}
                      <KeyboardDoubleArrowRight />
                    </button>
                  </div>
                )}
              </div>
              <Slider
                dots={false}
                infinite={true}
                slidesToScroll={1}
                slidesToShow={1}
                afterChange={(current) => {
                  setCurrentSlider(current);
                }}
                ref={sliderRef}
                arrows={false}
              >
                {Array.from({ length: 4 }).map((_, index) => (
                  <div key={index}>
                    {!isSearchDataLoading ? (
                      <>
                        {postQuestionList?.length === 0 && (
                          <div
                            style={{
                              display: "flex",
                              flex: 1,
                              width: "100%",
                              height: "100%",
                              justifyContent: "center",
                              alignItems: "center",
                              fontSize: "14px",
                            }}
                          >
                            검색 결과가 없습니다.
                          </div>
                        )}
                        {listForm === "gridview" ? (
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              flexWrap: "wrap",
                            }}
                          >
                            {postQuestionList?.map((question, i) => (
                              <div
                                key={question.id + i}
                                style={{
                                  display: "flex",
                                  flexGrow: 1,
                                  maxWidth: "200px",
                                  justifyContent: "center",
                                }}
                              >
                                <QuestionGridItem
                                  questionData={question}
                                  answerIcon={getAnswerIcons(question)}
                                  clickCard={() => {
                                    setIsSR_detail(true);
                                    window.localStorage.setItem("isImgSearch", "true");
                                    if ([0, 3].includes(currentSlide)) {
                                      navigate(
                                        "/talk/" +
                                          (userInfo.type === 2 ? "teacher" : "student") +
                                          "/" +
                                          "searchresult" +
                                          "/" +
                                          question["id"] +
                                          "/" +
                                          question["sid"] +
                                          "/" +
                                          question["tid"]
                                      );
                                    } else if ([1, 2].includes(currentSlide)) {
                                      navigate(
                                        `/pdfView/${question?.HIQDB_book_links?.[0]?.bid}?questionId=${question?.id}&prevStep=answer&type=question&prevQuestion=${params.qid}`
                                      );
                                    }
                                  }}
                                />
                              </div>
                            ))}
                          </div>
                        ) : (
                          postQuestionList?.map((question, i) =>
                            listForm === "listview" ? (
                              <QuestionListItem
                                key={`${question.id}+${i}`}
                                questionData={question}
                                answerIcon={getAnswerIcons(question)}
                                clickCard={() => {
                                  setIsSR_detail(true);
                                  // console.log("UiExpalin data => ",d)
                                  setHiddenUrl(-1);
                                  window.localStorage.setItem("isImgSearch", "true");
                                  if ([0, 3].includes(currentSlide)) {
                                    navigate(
                                      "/talk/" +
                                        (userInfo.type === 2 ? "teacher" : "student") +
                                        "/" +
                                        "searchresult" +
                                        "/" +
                                        question["id"] +
                                        "/" +
                                        question["sid"] +
                                        "/" +
                                        question["tid"]
                                    );
                                  } else if ([1, 2].includes(currentSlide)) {
                                    navigate(
                                      `/pdfView/${question?.HIQDB_book_links?.[0]?.bid}?questionId=${question?.id}&prevStep=answer&type=question&prevQuestion=${params.qid}`
                                    );
                                  }
                                }}
                              />
                            ) : (
                              <QuestionImageItem
                                key={`questionImageItem${question.id}`}
                                questionData={question}
                                answerIcon={getAnswerIcons(question)}
                                clickCard={() => {
                                  setIsSR_detail(true);
                                  setScrolllKey(question["id"]);
                                  window.localStorage.setItem("isImgSearch", "true");
                                  if ([0, 3].includes(currentSlide)) {
                                    navigate(
                                      "/talk/" +
                                        (userInfo.type === 2 ? "teacher" : "student") +
                                        "/" +
                                        "searchresult" +
                                        "/" +
                                        question["id"] +
                                        "/" +
                                        question["sid"] +
                                        "/" +
                                        question["tid"]
                                    );
                                  } else if ([1, 2].includes(currentSlide)) {
                                    navigate(
                                      `/pdfView/${question?.HIQDB_book_links?.[0]?.bid}?questionId=${question?.id}&prevStep=answer&type=question&prevQuestion=${params.qid}`
                                    );
                                  }
                                }}
                              />
                            )
                          )
                        )}
                      </>
                    ) : (
                      <div style={{ textAlign: "center" }}>
                        <CircularProgress
                          style={{
                            color: style.common.t_color,
                          }}
                        />
                      </div>
                    )}
                  </div>
                ))}
              </Slider>
              <div
                style={{
                  display: "flex",
                  fontSize: "14px",
                  alignItems: "center",
                  justifyContent: "center",
                  padding: "10px 0px",
                  height: "30px",
                  backgroundColor: "white",
                  zIndex: 99,
                  width: "100%",
                  position: "fixed",
                  borderTop: "1px solid",
                  borderColor: style.common.borderColor,
                  bottom: 0,
                  maxWidth: style.common.maxWidth,
                }}
                onClick={() => {
                  setQnaData({
                    ...qnaData,
                    new: "y",
                    qna_date: "y",
                    sid: userInfo["id"],
                    img: fileObj["base64"]
                      .replace(/^data:image\/png;base64,/, "")
                      .replace(/^data:image\/jpeg;base64,/, ""),
                  });
                  navigate(`/searchresult/teacher/teacher/b`);
                }}
              >
                <LocalActivity
                  style={{
                    marginRight: "5px",
                    color: "orange",
                  }}
                />
                검색한 사진으로 새 질문 만들기
              </div>
            </div>
          </div>
        </div>
      </Basic>
    </>
  );
};

export default QuestionImageSearch;
