import { PdfType, PdfTypeName, usePdfList } from "api/pdfView";
import { useQuestionSearch } from "api/questionSearch";
import { getUser } from "asset/storage";
import { StudyBookContext } from "pages/StudyBookDetail/context/StudyBookContextProvider";
import { showToast } from "providers/ToastProvider";
import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router";
import { StudybookSlider } from "./QuestionSlider";

const AnswerSlider = () => {
  const { bookId } = useParams();
  const { data: answerData } = usePdfList({ bookId, isAnswer: PdfType.answer });
  const { data: questionData } = usePdfList({ bookId, isAnswer: PdfType.question });
  const { questionSlideIndex } = useContext(StudyBookContext);

  const [answerSlideIndex, setAnswerSlideIndex] = useState<number>(1);

  const pdfAnswerList = useMemo(() => {
    return answerData.qnaList;
  }, [answerData]);
  const pdfQuestionList = useMemo(() => {
    return questionData.qnaList;
  }, [questionData]);

  const userInfo = getUser();

  const activeQuestion = useMemo(() => {
    if (questionSlideIndex === "") {
      return;
    }
    const activeArrayIndex = questionSlideIndex - 1;
    return pdfQuestionList?.[activeArrayIndex];
  }, [pdfQuestionList, questionSlideIndex]);

  const { data: postAnswerResponse } = useQuestionSearch({
    userId: userInfo.id,
    userType: userInfo.type,
    option: "exemplary",
    bookId: bookId,
    limit: 1,
    searchData: activeQuestion?.qid,
    searchType: "question",
    isSolution: true,
  });

  useEffect(() => {
    if (!postAnswerResponse) return;
    if (postAnswerResponse.length > 0) {
      const answerId = postAnswerResponse[0]?.id;
      if (answerId) {
        pdfAnswerList.find((pdfItem, index) => {
          if (pdfItem.qid === answerId) {
            setAnswerSlideIndex(index + 1);
          }
        });
      }
    } else {
      showToast({
        title: "풀이의 정확한 위치를 찾기가 어려워요.",
        message: "첫페이지로 돌아가 수동으로 풀이를 찾아주세요.",
        type: "warning",
      });
      setAnswerSlideIndex(1);
    }
  }, [postAnswerResponse]);

  useEffect(() => {
    const slideItem = document.querySelector(`#pdf-answer-row-${answerSlideIndex}`);
    if (slideItem) {
      slideItem.scrollIntoView({ block: "start" });
    }
  }, [answerSlideIndex]);

  const handleActive = useCallback(
    (index: number) => {
      setAnswerSlideIndex(index);
    },
    [answerSlideIndex]
  );

  return (
    <>
      <StudybookSlider
        pdfList={pdfAnswerList}
        slideIndex={answerSlideIndex}
        handleActive={handleActive}
        type={PdfTypeName.answer}
        setPageIndex={setAnswerSlideIndex}
      />
    </>
  );
};

export default AnswerSlider;
