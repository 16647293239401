import { PlusOne } from "@material-ui/icons";
import { getUser } from "asset/storage";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import apiFn from "../../asset/apiClass";
import constants from "../../asset/constants";
import style from "../../asset/style";
import util from "../../asset/util";
import UiBook from "../../uiComponents/UiBook/UiBook";
import { UiInput } from "../../uiComponents/UiInput/UiInput";

const Modal_regi = ({ onClose }) => {
  const userInfo = getUser();
  let params = useParams();

  var [lectureTitle, setLectureTitle] = useState("");
  var [lectureAddress, setLectureAddress] = useState("");
  var [lectureIntro, setLectureIntro] = useState("");

  const apiPro_regi = async () => {
    try {
      let isOk = await apiFn.createClass({
        state: {
          name: lectureTitle,
          address: lectureAddress,
          intro: lectureIntro,
          mid: userInfo["id"],
          pid: constants.isAcademy ? constants.classroomId : classId,
          type: 1,
        },
      });

      // 관리자로 등록
      if (constants.isAcademy) {
        await apiFn.comApi({
          state: {
            table: "member_class",
            sendMsg: {
              orderMid: userInfo["id"],
              targetMid: userInfo["id"],
              cid: isOk.id,
              type: "suup_okay",
              channel: constants.channelId,
            },
            set: [
              {
                key: `cid:${isOk.id}' and mid='${userInfo["id"]}`,
                value: {
                  type: 5,
                  status: 1,
                },
              },
            ],
          },
        });
      }
      const result = await fetch(`${constants.apiUrl}/class/${isOk.id}/default`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          userId: userInfo.id,
          bookId: Object.keys(selectBook)[0],
          classroomId: constants.classroomId,
        }),
      });
      if (result.status === 200) {
        alert("성공적으로 수업을 생성했습니다.");
        onClose();
        return;
      } else if (result.status === 403) {
        const targetName = constants.isAcademy ? "학원장 소유" : "수업 소유자";
        alert(`수업 관리자와 소유자만 설정 할 수 있으며, ${targetName}의 교재만 설정 가능합니다.`);
        return;
      } else if (result.status === 404) {
        alert("교재를 찾을 수 없습니다");
        return;
      }
      // return isOk["id"]
    } catch (e) {
      console.error(e);
      util.c_err_log(`apiPro_regi Error => ${e}`);
      alert("수업 생성에 실패했습니다\n학원 관리자인지 먼저 확인해주세요");
    }
  };

  const [classId, setClassId] = useState(null);
  const [myBookList, setMyBookList] = useState(null);
  const [selectBook, setSelectBook] = useState({});

  const apiPro_readClass = async () => {
    try {
      let data = await apiFn.comApi({
        state: {
          table: "class",
          page: 1,
          amount: 9999,
          sort: "reg_date desc",
          where: {
            "HIQDB_class.reg_id": `= '${userInfo.id}'`,
            "HIQDB_class.pid": `is null`,
          },
        },
      });
      console.log("DATA : ", data);
      if (data.list.length === 0) {
        alert("클래스룸이 존재하지 않습니다\n관리자에게 문의해주세요");
      }
      setClassId(data["list"][0]["id"]);
    } catch (e) {
      console.error("************");
      console.error(e);
      console.error("************");
      throw e;
    }
  };

  const getMyBooks = async () => {
    const { list } = await apiFn.comApi({
      state: {
        table: "book",
        page: 1,
        amount: 999,
        u_id: userInfo["id"],
        sort: "reg_date desc",
        where: {
          "HIQDB_book.status": `!= '9'`,
          "HIQDB_book.reg_id": `= '${constants.isAcademy ? constants.ownerId : userInfo["id"]}'`,
          // "HIQDB_book_link.status" : "!= 0",
          // "HIQDB_book_link.type": "!= 9",
          // "HIQDB_book_link.cid" : `= 0`,
        },
      },
    });

    setMyBookList(list);
  };

  useEffect(() => {
    !constants.isAcademy && apiPro_readClass();
    if (constants.isAcademy) setClassId(constants.classroomId);
    getMyBooks();
  }, []);

  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        zIndex: 130002,
        backgroundColor: "rgba(150, 150, 150,0.5)",
        // backgroundColor:"red",
        width: "100vw",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
      onClick={() => {
        // setIsModal(false)
      }}
    >
      <div
        style={{
          // width : "75%",
          width: "300px",
          // minHeight:"55%",
          // maxHeight:"60%",
          backgroundColor: "white",
          padding: "35px 20px 40px 20px",
          borderRadius: 10,
          border: "1px solid rgb(200, 200, 200)",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          filter: "drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.37))",
        }}
        onClick={(e) => {
          // e.stopPropagation();
          // e.preventDefault();
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            fontSize: 18,
            fontWeight: 700,
            alignItems: "center",
            marginBottom: 10,
          }}
        >
          수업 생성
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            fontSize: 14,
            fontWeight: 700,
            justifyContent: "center",
            padding: "10px 0px 25px 0px",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              marginBottom: "10px",
            }}
          >
            <div
              style={{
                marginBottom: "3px",
              }}
            >
              수업명
            </div>
            <div
              style={{
                display: "flex",
                width: "100%",
                // height:"32px",
                flex: 1,
              }}
            >
              <UiInput
                inputClass={"input_t"}
                basic_container_style={{
                  height: "28px",
                }}
                inputValue={lectureTitle}
                placeholder={"수업명을 입력해 주세요."}
                fnChange={(e) => {
                  setLectureTitle(e.target.value);
                }}
              />
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              marginBottom: "10px",
            }}
          >
            <div
              style={{
                marginBottom: "3px",
              }}
            >
              수업 장소
            </div>
            <div
              style={{
                display: "flex",
                width: "100%",
                // height:"32px",
                flex: 1,
              }}
            >
              <UiInput
                inputClass={"input_t"}
                basic_container_style={{
                  height: "28px",
                }}
                inputValue={lectureAddress}
                placeholder={"수업 장소를 입력해 주세요."}
                fnChange={(e) => {
                  setLectureAddress(e.target.value);
                }}
              />
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              marginBottom: "10px",
            }}
          >
            <div
              style={{
                marginBottom: "3px",
              }}
            >
              수업 소개 글
            </div>
            <div
              style={{
                display: "flex",
                width: "100%",
                // height:"32px",
                flex: 1,
              }}
            >
              <UiInput
                inputClass={"input_t"}
                type="desc"
                maxLength={60}
                // basic_container_style={{
                //     height:"28px",
                // }}
                inputValue={lectureIntro}
                placeholder={"수업 소개를 작성해 보세요."}
                fnChange={(e) => {
                  setLectureIntro(e.target.value);
                }}
              />
            </div>
          </div>

          <div
            style={{
              marginBottom: "3px",
              fontWeight: 700,
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              fontSize: 14,
            }}
          >
            <div>수업 기본 교재</div>
            <button
              style={{
                backgroundColor: style.common.t_color,
                border: "none",
                borderRadius: 8,
                color: "#FFF",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: 32,
                width: 80,
              }}
              onClick={async () => {
                if (!lectureTitle || lectureTitle === "") {
                  alert("수업명을 먼저 입력해주세요.");
                  return;
                }

                try {
                  await apiFn.comApi({
                    state: {
                      table: "book",
                      // id : userInfo["id"],
                      ins: [
                        {
                          name: `${lectureTitle} 수업 기본 교재`,
                          reg_date: 0,
                          reg_id: constants.isAcademy ? constants.ownerId : userInfo["id"],
                        },
                      ],
                    },
                  });

                  window.alert(constants.confirm.cf_23);

                  getMyBooks();
                } catch (e) {
                  console.log("new book default error", e);
                }
              }}
            >
              새 교재&nbsp;
              <PlusOne />
            </button>
          </div>
          <div style={{ height: "30vh", overflow: "auto", display: "flex", alignItems: "center" }}>
            {myBookList?.map((book, i) => {
              return (
                <div style={{ width: 120 }}>
                  <UiBook
                    isEdit={true}
                    selectOnlyOne={true}
                    setSelectData={setSelectBook}
                    selectData={selectBook}
                    key={"mark_folder_" + i}
                    data={book}
                    isOuth={book.type}
                  />
                </div>
              );
            })}
          </div>
        </div>
        <div
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "center",
            marginTop: "10px",
          }}
        >
          <div
            style={{
              display: "flex",
              width: "180px",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "87px",
                height: "40px",
                borderRadius: "15px",
                backgroundColor: "#D8D8D8",
                fontSize: "14px",
                color: "#FFFFFF",
                fontWeight: 700,
              }}
              onClick={() => {
                onClose();
              }}
            >
              취소
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "87px",
                height: "40px",
                borderRadius: "15px",
                backgroundColor: "#5EA3C6",
                fontSize: "14px",
                color: "#FFFFFF",
                fontWeight: 700,
              }}
              onClick={() => {
                if (classId === 0 || !classId) {
                  alert("클래스룸(학원)을 찾을 수 없습니다\n관리자에게 문의해주세요");
                  onClose();
                  return;
                } else if (!lectureTitle || lectureTitle === "") {
                  alert("수업명을 먼저 입력해주세요");
                  return;
                } else if (Object.keys(selectBook).length === 0) {
                  alert("기본 교재를 선택해주세요");
                  return;
                }
                apiPro_regi();
              }}
            >
              등록
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modal_regi;
