import { Create , HistoryRounded} from '@material-ui/icons'
// import { HistoryEdu,HistoryEduOutlined, HistoryEduRounded, HistoryEduSharp } from '@mui/icons-material'
import { React , useState , useEffect, useRef } from 'react'
import { useNavigate, useParams } from 'react-router'
import apiFn from '../../asset/apiClass'
import style from '../../asset/style'
import UiBtn from '../../uiComponents/UiBtn/UiBtn'
import UiSorting from '../../uiComponents/UiSorting/UiSorting'
import UiTextBtn from '../../uiComponents/UiTextBtn/UiTextBtn'
import UiVideo from '../../uiComponents/UiVideo/UiVideo'
import UiVideoSection from '../../uiComponents/UiVideoSectionList/UiVideoSection'
import Message_Style from '../Message/Message_Style'
import Column_home_Style from './Column_home_Style'
import './Column.css'
import Academy_main_Style from '../Academy/Academy_main_Style'
import aquery from '../../asset/Img/aquery.png'

const Column_home = ({
    isTeacher,
    userInfo,
    scrollKey,
    setScrolllKey,
    setHiddenNav,
    hiddenNav
}) => {
    
    let params = useParams()
    let navigate = useNavigate();

    var [isListLoad,setIsListLoad] = useState(false);

    const [tap,setTap] = useState("home")
    const [catData,setCatData] = useState([])
    const [smallCatData,setSmallCatData] = useState([])

    const [colData,setColData] = useState([]);
    const [colData_top3,setColData_top3] = useState([]);

    // cat 리스트 호출
    const apiPro_read = async () => {
        try {
            let data = await apiFn.comApi({
                state : {
                    table : "board_cat",
                    page : 1,
                    amount : 9999,
                    sort : "name desc",
                    where : {
                        "HIQDB_board_cat.type" : `='2'`,
                        "HIQDB_board_cat.pid" : `='0'`,
                    }
                }
            })
            // // console.log("Column Data => ",data["list"])
            setCatData([
                ...data["list"]
            ])
        }
        catch(e){
            // console.log("",e)
        }
    }

    // 세부 cat 리스트 호출
    const apiPro_read_4 = async () => {
        try {
            let pid = `='${tap}'`
            if(tap == "home"){
                pid = `!='0'`
            }
            let data = await apiFn.comApi({
                state : {
                    table : "board_cat",
                    page : 1,
                    amount : 9999,
                    sort : "name desc",
                    where : {
                        "HIQDB_board_cat.type" : `='2'`,
                        "HIQDB_board_cat.pid" : pid,
                    }
                }
            })
            // // console.log("small Column Data => ",data["list"])

            setSmallCatData([
                ...data["list"]
            ])
        }
        catch(e){
            // console.log("",e)
        }
    }

    // column 리스츠 호출
    const apiPro_read_2 = async ( sort , isSmall ) => {
        try {
            let myInfo = await apiPro_read_myInfo();
            let class_id;
            // console.log("myinfo => ",myInfo)
            // console.log("myinfo => ",myInfo["hakwonList"])

            if(myInfo["hakwonList"].length > 0){
                class_id = myInfo["hakwonList"].reduce((a,v,i)=>{
                    return ([
                        ...a,
                        v["id"]
                    ])
                },[])
            }
            
            // console.log("class_id => ",class_id)

            let where_obj = {}
            let top3_obj = {
            }
            where_obj['HIQDB_board.cid'] = `in (${class_id})`   

            // let in_id = catData.reduce((a,v,i)=>{
            //     if(i == 0){
            //         return (a = a + v["id"] );
            //     }
            //     else {
            //         return (a = a +  "," + v["id"] );
            //     }
            // },"")

            // // console.log("#  ",in_id)

            top3_obj["cid"] = class_id
            if(tap == "home"){
                top3_obj["type"] = "2"
                where_obj["HIQDB_board_cat.type"] = `= '2'`
                if(isSmall){
                    top3_obj["cat_id"] = isSmall
                    where_obj["HIQDB_board.cat_id"] = `= '${isSmall}'`
                }
            }
            else {
                top3_obj["cat_id"] = tap
                where_obj["HIQDB_board.cat_id"] = `= '${tap}'`
            }

            // console.log("top3_obj => ",top3_obj);


            let data = await apiFn.comApi({
                state : {
                    // files : "y",
                    top3 : top3_obj,
                    table : 'board',
                    page : 1,
                    amount : 9999,
                    sort : sort ? sort : "reg_date",
                    ref : [
                        "HIQDB_board_cat.id as xx|HIQDB_board_cat.id=HIQDB_board.cat_id",
                    ],
                    where : where_obj,
                    count : [
                        `HIQDB_board_log.bid:bid:id|and countTable.btype=\'1\'  |readCnt`,
                        `HIQDB_board_log.bid:bid:id|and countTable.btype=\'2\' |likeCnt`,
                    ]
                }
            })
            // top3 : { cid, id, type }

            // cid = 보드의 카테고리 ID

            // id = 보드카테고리의 ID

            // type : 보드카테고리 타입
            // // console.log("@@@ ",data);
            // // console.log("@@@ ",data);
            // // console.log("@@@ ",data);

            setColData([
                ...data["list"]
            ])
            setColData_top3([
                ...data["top3"]
            ])
        }
        catch(e){
            // console.log("E ",e)
        }
        finally{
            setIsListLoad(true)
        }
    }

    const apiPro_read_myInfo = async () =>{
        try {
            // let data = await apiFn.comApi({
            //     state : {
            //         table : userInfo["type"] == 1 ? "student" : "teacher",
            //         key : `id:${params["id"]}`
            //     }
            // })
            // return data
            let data = await apiFn.comApi({
                state : {
                    table : "user_credential" ,
                    page : 1,
                    amount : 9999,
                    sort : "reg_date desc",
                    ref: ['A.* |HIQDB_user A on A.index=HIQDB_user_credential.user_id and A.user_type = 1'],
                    where : {
                        "HIQDB_user_credential.name" : `= '${userInfo["id"]}'`
                    }
                    // key : `id:${params["id"]}`
                }
            })


            // console.log("1 chk data => ",data["list"])
            if(data["list"].length == 0){
                data = await apiFn.comApi({
                    state : {
                        table : "user_credential" ,
                        page : 1,
                        amount : 9999,
                        sort : "reg_date desc",
                        where : {
                            "HIQDB_user_credential.name" : `= '${userInfo["id"]}'`
                        },
                        ref: ['A.* |HIQDB_user A on A.index=HIQDB_user_credential.user_id and A.user_type = 2'],
                        count : [
                            // `HIQDB_online_qna.tid:cccc:id|and countTable.id=\'id\`| asdfasdfasd`,
                            'HIQDB_online_qna.tid:tid:id|and countTable.mobum_date is not null |mobumCnt',
                            'HIQDB_book_link.mid:mid:id|and countTable.type > 4 and countTable.bid != 0 and countTable.cid =0 and countTable.status !=9 |bookCnt',

                        ]
                        // key : `id:${params["id"]}`
                    }
                    // online_qna
                    // 조건 : tid = 선생님 ID, mobum_date is not null

                    // book_link
                    // 조건 : mid = 선생님 ID, type >4, bid !=0, cid=0
                })
            }

            // console.log("2 chk data => ",data["list"])

            return data["list"][0]
        }
        catch(e){
            // console.log("?? ",e);
        }
    }

    useEffect(()=>{
        apiPro_read();
        apiPro_read_4();
    },[userInfo])

    useEffect(()=>{
        apiPro_read_2();
        apiPro_read_4();
    },[tap])
    
    useEffect(()=>{
        if(hiddenNav){
            setTap(hiddenNav)
            setHiddenNav("")
        }
    },[])

    return (
        <div style={{
            ...Column_home_Style.c,
        }}>
            <div style={{
                 ...Column_home_Style.nav_w,
                marginBottom:"16px",
                whiteSpace : 'nowrap',
                overflowX : 'scroll' ,
            }}>
                <div style={{
                    ...Column_home_Style.tap_text,
                    borderBottom : tap == "home" ? "2px solid" : "none",
                    borderBottomColor : tap == "home" ?
                    (isTeacher ? style.common.t_color : style.common.s_color)
                    :
                    "none"
                    ,
                    color : tap == "home" ?
                    (isTeacher ? style.common.t_color : style.common.s_color)
                    :
                    style.common.fontColor_2
                    
                }}
                    onClick={()=>{
                        setHiddenNav("home")
                        setTap("home")
                    }}
                >
                    전체
                </div>
                {
                catData.map((v,i)=>
                <div style={{
                    ...Column_home_Style.tap_text,
                    borderBottom : tap == v["id"] ? "2px solid" : "none",
                    borderBottomColor : tap == v["id"] ?
                    (isTeacher ? style.common.t_color : style.common.s_color)
                    :
                    "none"
                    ,
                    color : 
                    tap == v["id"] ?
                    (isTeacher ? style.common.t_color : style.common.s_color)
                    :
                    style.common.fontColor_2

                }}
                    onClick={()=>{
                        setHiddenNav(v["id"])
                        setTap(v["id"])
                        // navigate("/column"+"/"+params["who"]+"/pick")
                    }}
                >
                    {/* 입시컬럼 */}
                    {v["name"]}
                </div>
                )
                }
          
            </div> 
            <div 
                className='scroll_horizon'
                style={Column_home_Style.t_section_w}
            >
                {
                colData_top3.map((v,i)=>
                <UiVideo 
                    desc={v["content"]}
                    data={v}
                    fnClick={(data)=>{
                        navigate("/columndetail/"+params["who"]+"/"+data["id"]+"/de")
                    }}
                />
                )}
            </div>
            <div style={Column_home_Style.tag_w}>
                {
                smallCatData.map((v,i)=>
                <UiBtn
                    btnStyle={Column_home_Style.tag_btn}
                    title={`# ${v["name"]}`}
                    fnClick={()=>{
                        apiPro_read_2("reg_date desc",v["id"])
                    }}
                />
                )}
                
            </div>
            <UiSorting 
                isTeacher={isTeacher}
                title="실시간 컬럼"
                data={[
                    {name : "등록일순" , value : "reg_date desc"},
                    {name : "좋아요순" , value : "likeCnt desc"},
                    {name : "열람순" , value : "readCnt desc"},
                ]}
                fnClick={(v)=>{
                    apiPro_read_2(v);
                }}
            />
            {
            isListLoad &&
            (

                colData.length > 0 ?
                colData.map((v,i)=>
                <UiVideoSection 
                    data={v}
                    scrollKey={scrollKey}
                    setScrolllKey={setScrolllKey}
                    fnClick={(data)=>{
                        setScrolllKey(data["id"])
                        navigate("/columndetail/"+params["who"]+"/"+data["id"]+"/de")
                    }}
                />
                )
                :
                <div style={{
                    display:"flex",
                    flexDirection:"column",
                    justifyContent:"center",
                    alignItems:"center",
                    fontSize : "14px",
                    fontWeight:"bold",
                    color : style.common.fontColor_1,
                    width:"100%",
                    height : "100%",
                }}>
                    <img  
                        src={aquery} 
                        style={{
                            width:"50%",
                            height : "5%",
                            paddingRight:"35px",
                            marginTop:"100px",
                        }}
                    />
                    <div style={{
                        display:"flex",
                        flexDirection:"column",
                        alignItems:"center",
                        paddingTop : "20px",
                    }}>
                        <div>
                            현재 열람 가능한 글이 없어요.
                        </div>
                    </div>
                </div>
            )
            }
          
            {/* {
            isTeacher &&
            <div style={{
                position : "fixed",
                bottom:80,
                right: 20,
                width : "52px",
                borderRadius : 50,
                height:"52px",
                display:"flex",
                alignItems:"center",
                justifyContent:"center",
                backgroundColor : isTeacher ? style.common.t_color : style.common.s_color,
                boxShadow: "1px 1px 2px 2px rgba(90 ,90 ,90 , 0.2)",
            }}
                onClick={()=>{
                    navigate(`/write/${params["who"]}/${tap}/0/0/n`)
                }}
            >
                <Create 
                    style={{
                        color : "white"
                    }}
                />
            </div>
            } */}
        </div>
    )
}

export default Column_home