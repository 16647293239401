import { ReactComponent as StudybookAnswerUpload } from "asset/images/studybook-answer-upload.svg";
import { ReactComponent as StudybookQuestionUpload } from "asset/images/studybook-question-upload.svg";
import { FontStyleMap } from "components/atoms/Font";
import HorizontalBlank from "components/atoms/HorizontalBlank";
import React, { MouseEvent, useCallback, useContext, useRef, useState } from "react";
import { useController, useFormContext } from "react-hook-form";
import styled, { css } from "styled-components";
import { PdfUploadForm, PdfUploadModalContext } from "..";
import InputArea from "./InputArea";
import PdfViewer from "./PdfViewer";

const PdfBoundaryViewer = ({ onClose }: { onClose: () => void }) => {
  const { isSolution } = useContext(PdfUploadModalContext);

  const { setValue, watch } = useFormContext<PdfUploadForm>();
  const [isDragOver, setIsDragOver] = useState(false);
  const fileInputRef = useRef(null);
  const pdfFile = watch("pdfFile");

  const handleStartPageChanged = (selectedPage: number) => {
    setValue("startPage", selectedPage, { shouldValidate: true });
  };
  const handleEndPageChanged = (selectedPage: number) => {
    setValue("endPage", selectedPage, { shouldValidate: true });
  };

  const handleFileChange = (event) => {
    // 드래그 앤 드롭 이벤트에서 파일을 가져오기
    let files = event.dataTransfer ? event.dataTransfer.files : event.target.files;

    // 파일이 존재하는 경우 상태 업데이트
    if (files.length > 0 && files[0].type === "application/pdf") {
      setValue("pdfFile", files[0]);
      const fileTitle = files[0].name.replace(/\.[^/.]+$/, ""); // 확장자 제거
      setValue("title", fileTitle);
      setValue("sourceName", fileTitle);
    }
  };

  const handlePlaceholderClick = () => {
    fileInputRef.current.click();
  };

  const handleDragOver = (event) => {
    event.preventDefault();
    setIsDragOver(true);
  };

  const handleDragLeave = () => {
    setIsDragOver(false);
  };

  const handleDrop = (event) => {
    event.preventDefault();
    handleFileChange(event);
    setIsDragOver(false);
  };

  const startPage = watch("startPage");
  const endPage = watch("endPage");
  useController({
    name: "startPage",
    rules: {
      validate(value) {
        if (value === "" || value === null) {
          return "시작페이지는 필수로 입력해야 해요.";
        } else if (value > endPage) {
          return "시작페이지는 종료페이지보다 앞에 위치한 페이지여야 해요.";
        } else {
          return true;
        }
      },
    },
  });
  useController({
    name: "endPage",
    rules: {
      validate(value) {
        if (value === "" || value === null) {
          return "종료페이지는 필수로 입력해야 해요.";
        } else if (value < startPage) {
          return "시작페이지는 종료페이지보다 앞에 위치한 페이지여야 해요.";
        } else {
          return true;
        }
      },
    },
  });

  const handleCancel = useCallback(
    (event: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>) => {
      event.stopPropagation();
      setValue("pdfFile", null);

      if (fileInputRef.current) {
        fileInputRef.current.value = null;
      }
    },
    []
  );
  return (
    <Container>
      <input
        type="file"
        accept="application/pdf"
        onChange={handleFileChange}
        ref={fileInputRef}
        style={{ display: "none" }} // 파일 입력 숨김
      />

      {pdfFile ? (
        <>
          <FileNameContainer>
            <div>파일명 : {pdfFile.name}</div>
            <button type="button" onClick={handleCancel}>
              취소
            </button>
          </FileNameContainer>
          <PdfViewerContainer>
            <PdfViewer
              onPageChanged={handleStartPageChanged}
              file={pdfFile}
              pageNumber={1}
              title={"시작"}
            />
            <PdfViewer
              onPageChanged={handleEndPageChanged}
              file={pdfFile}
              pageNumber={-1}
              title={"종료"}
            />
          </PdfViewerContainer>
          <InputArea />
          <HorizontalBlank height={52} />
        </>
      ) : (
        <IconContainer
          onClick={handlePlaceholderClick}
          onDragOver={handleDragOver}
          onDragLeave={handleDragLeave}
          onDrop={handleDrop}
          dragOver={isDragOver}
        >
          {isSolution ? <StudybookAnswerUpload /> : <StudybookQuestionUpload />}
          <DescriptionFont>파일을 업로드 해주세요.</DescriptionFont>
        </IconContainer>
      )}
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
`;

const FileNameContainer = styled.div`
  margin-top: 32px;
  border-radius: 15px;
  border: var(--none, 1px) solid rgba(0, 0, 0, 0.23);
  color: var(--neutral-color-natural-60, #434343);
  padding: 16px 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  > div {
    ${FontStyleMap["KR/regular/$KR.BL.REG.16"]}
    width: 100%;
    flex: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  > button {
    border: none;
    color: white;
    cursor: pointer;
    display: flex;
    padding: 8px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 99px;
    background: var(--Default-Gray-90, #262626);
    color: var(--black-white-color-white, #fff);
    ${FontStyleMap["KR/regular/$KR.CAP.REG.12"]}
  }
`;

const IconContainer = styled.div<{ dragOver: boolean }>`
  padding: 24px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 28px;
  color: #ffa723;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  & img {
    width: 108px;
    height: 78px;
  }
  ${({ dragOver }) =>
    dragOver &&
    css`
      background-color: #ffa72333;
    `}
`;

const DescriptionFont = styled.div`
  color: var(--neutral-color-natural-60, #434343);
  ${FontStyleMap["KR/regular/$KR.BM.REG.14"]}
`;

const PdfViewerContainer = styled.div`
  display: flex;
  gap: 30px;
  margin-top: 32px;
`;
export default PdfBoundaryViewer;
