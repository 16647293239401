import React, { ReactNode, useMemo } from "react";
import style from "../../../asset/style";
import noBook from "../../../asset/Img/book1.png";
import constants from "../../../asset/constants";
import { questionSearchResponseData } from "../../../api/questionSearch";
import { formatToThreeDecimals } from "../../../asset/util";

type QuestionItemProps = {
  questionData: questionSearchResponseData;
  clickCard?: () => void;
  answerIcon?: ReactNode;
};
const QuestionGridItem = ({ questionData, clickCard, answerIcon }: QuestionItemProps) => {
  const getImageUrl = (questionData: questionSearchResponseData) => {
    return questionData["original"]
      ? questionData["original_img"]
        ? `${constants.s3_url}/qna/${questionData["original_img"]}.jpg`
        : `${constants.s3_url}/qna/${questionData["original"]}.jpg`
      : questionData["img"]
        ? `${constants.s3_url}/qna/${questionData["img"]}.jpg`
        : `${constants.apiUrl}/qna/${questionData["id"]}.jpg`;
  };

  const questionImageUrl = useMemo(() => getImageUrl(questionData), [questionData]);
  const questionScore = useMemo(() => formatToThreeDecimals(questionData?.score), [questionData]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        backgroundColor: "#FFFBF5",
        width: "150px",
        padding: "10px 10px",
        marginBottom: "5px",
        borderRadius: "15px",
      }}
      onClick={clickCard}
    >
      <div
        style={{
          display: "flex",
          width: "100%",
          height: "110px",
          borderRadius: "5px",
        }}
      >
        <img
          className={questionData["mosaic"] == "y" ? "mosaic" : null}
          style={{
            width: "99.5%",
            height: "100%",
            objectFit: "cover",
            objectPosition: "top",
            border: "1px solid",
            borderColor: style.common.borderColor4,
            borderRadius: "5px",
          }}
          loading={"lazy"}
          onError={(e) => {
            e.currentTarget.src = noBook;
          }}
          src={questionImageUrl}
        />
      </div>
      <div
        style={{
          display: "flex",
          flex: 1,
          marginTop: "5px",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <div
            style={{
              color: "#3D3A39",
              maxWidth: "150px",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {questionData["title"] ?? "출처 없음"}
          </div>
          <div>{answerIcon}</div>
          <div>score: {questionScore}</div>
          <div>문제ID: {questionData.id}</div>
        </div>
      </div>
    </div>
  );
};

export default QuestionGridItem;
