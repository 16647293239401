import style from "../../../asset/style"

const Register_Intro_style = {
    // wrapper
    w_1 : {
        ...style.common.block,
        position:"absolute",
        zIndex : 2,
    },
    w_2 : {
        width:"100%",
        height:"calc(100vh - 30px)",
        display:"flex",
        flexDirection:"column",
        justifyContent:"center",
        position:"relative",
        zIndex : 1,
    },
    // section
    s_c: {
        width:"100%",
        height:"90px",
        borderRadius:5,
        // backgroundColor : "rgb(235,235,235)",
        backgroundColor : "#f1f1f1",
        fontSize:"14px",
        margin : "25px 0px",
    },
    s_w : {
        position : 'relative',
        display:"flex",
        flexDirection:"column",
        justifyContent:"center",
        height:"100%",
        padding:"0px 10px",
        
    },
    // img
    i : {
        display:"flex",
        justifyContent:"flex-end",
        // marginRight:"30px",
        // marginLeft : "60px",
        position : "absolute",
        right : -7,
        // right : -22,
        bottom : 0,
        // width:'40%',
        height : "120px",
    },
    // footer
    f : {
        position:"absolute",    
        // marginTop:"10px",
        bottom:10,
        width:"100%",
        display:"flex",
        justifyContent:"center",
        textDecoration:'underline'
    },
}

export default Register_Intro_style;
    