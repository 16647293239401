import { useQuery } from "@tanstack/react-query";

export enum PdfType {
  answer = "1",
  question = "0",
}

export enum PdfTypeName {
  answer = "answer",
  question = "question",
}

export interface IFetchIsCheckResponse {
  success: true;
  qnaList: PdfItem[];
}

export interface PdfItem {
  bid: number;
  qid: number;
  qid_HIQDB_online_qna: {
    title: string;
    img: string;
    original_img: string;
    id: number;
    original: number;
    is_answer: number;
    sid: string;
    tid: string;
  };
}
export const usePdfList = ({
  bookId,
  isAnswer,
}: {
  isAnswer?: PdfType;
  bookId: string | number;
}) => {
  return useQuery<IFetchIsCheckResponse>([`/book/${bookId}/qna-list`, { isAnswer }]);
};
