import { PdfTypeName } from "api/pdfView";
import { StudyBookContext } from "pages/StudyBookDetail/context/StudyBookContextProvider";
import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import QuickPinchZoom, { make2dTransformValue } from "react-quick-pinch-zoom";
import styled from "styled-components";
type PinchImageProps = {
  isActive: boolean;
  imageSrc: string;
  setIsLoaded: (isLoaded: boolean) => void;
  isLoaded: boolean;
  type: PdfTypeName;
};
const PinchImage = ({ isActive, imageSrc, setIsLoaded, type, isLoaded }: PinchImageProps) => {
  const [imageTransform, setImageTransform] = useState("");

  const [verticalPadding, setVerticalPadding] = useState(0);
  const { bottomHeight, topHeight } = useContext(StudyBookContext);

  const calcPadding = useCallback(() => {
    const { current: image } = imageRef;
    const imageHeight = image?.getBoundingClientRect().height;
    const parentHeight = type === PdfTypeName.question ? topHeight : bottomHeight;
    const difference = imageHeight - parentHeight;
    if (difference > 0) {
      const defferenceHalf = difference;
      setVerticalPadding(defferenceHalf);
    }
  }, [bottomHeight, topHeight, type]);

  useEffect(() => {
    calcPadding();
  }, [calcPadding, isLoaded]);

  const onUpdate = useCallback(({ x, y, scale }) => {
    const value = make2dTransformValue({ x, y, scale });
    setImageTransform(value);
  }, []);

  useEffect(() => {
    if (!isActive) {
      setImageTransform("");
    }
  }, [isActive]);

  const imageRef = useRef<HTMLImageElement>(null);
  const pinchRef = useRef<QuickPinchZoom>(null);
  const toggleWillChange = () => {
    const { current: image } = imageRef;

    if (image) {
      requestAnimationFrame(() => {
        image.style.setProperty("will-change", "auto");

        requestAnimationFrame(() => {
          image.style.setProperty("will-change", "transform");
        });
      });
    }
  };

  return (
    <ImageContainer>
      <QuickPinchZoom
        ref={pinchRef}
        onUpdate={onUpdate}
        doubleTapToggleZoom
        onZoomEnd={toggleWillChange}
        onDragEnd={toggleWillChange}
        centerContained
        draggableUnZoomed
        containerProps={{ style: { width: "100%" } }}
        verticalPadding={verticalPadding}
      >
        <Image
          ref={imageRef}
          draggable={false}
          src={imageSrc}
          alt="placeholder"
          visible={isActive}
          onLoad={() => setIsLoaded(true)}
          style={{
            transform: imageTransform,
          }}
        />
      </QuickPinchZoom>
    </ImageContainer>
  );
};

const Image = styled.img<{ visible?: boolean }>`
  width: 100%;
  overflow: hidden;
  object-fit: contain;
  ${({ visible }) => !visible && "display: none;"}
`;
const ImageContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: auto;
  height: fit-content;
`;

export default PinchImage;
