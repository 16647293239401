import { React, useEffect, useState } from 'react';
import { Frame } from '../page_index';
import { useNavigate, useParams } from 'react-router';
import { UiInput } from '../../uiComponents/UiInput/UiInput';
import apiFn from '../../asset/apiClass';
import UiPeople_Style from '../../uiComponents/UiPeople/UiPeople_Style';
import constants from '../../asset/constants';
import face_student from '../../asset/Img/defaultpic_student.png'
import face_teacher from '../../asset/Img/defaultpic_teacher.png'
import util from '../../asset/util';
import style from '../../asset/style';
import { UiCheckBox } from '../../uiComponents/UiCheckBox/UiCheckBox';
import UiMessage_Style from '../../uiComponents/UiMessage/UiMessage_Style';
import UiBtn from '../../uiComponents/UiBtn/UiBtn';
import UiBasicSelect from '../../uiComponents/UiSelect/UiBasicSelect'
import UiLoading from '../../uiComponents/UiLoading/UiLoading'
import store from "../../asset/store";

const Message_invite = ({
    userInfo,
}) => {
    let params = useParams();
    let navigate = useNavigate();

    const [tap,setTap] = useState((params["who"] == "student" || params["tid"] != userInfo["id"]) ? "a" : "b")

    var [search,setSearch] = useState("");
    var [sorting,setSorting] = useState("all");

    var [isAll,setIsAll] = useState(false)
    var [friendsList,setFriendsList] = useState([])
    var [friendsList_teacher,setFriendsList_teacher] = useState([])
    var [selectData,setSelectData] = useState({});

    var [bookName, setBookName] = useState("");
    var [bookId, setBookId] = useState("");

    var [trans_teacher, setTrans_teacher] = useState([]);


    const apiPro_invite_teacher = async (isReturn ,inSearch ) => {
        try {
            let where_obj = {}
            if(inSearch || search){
                where_obj[`A.name`] = `like '%${inSearch || search}%'`
            }
            if(inSearch == ""){
                // where_obj["search"] = ""
            }

            let data = await apiFn.comApi({
                state : {
                    table : "member_class",
                    page : 1,
                    amount : 9999,
                    sort : "mid desc",
                    // ref: [
                    //     "HIQDB_teacher.name|HIQDB_teacher.id==HIQDB_member_class.mid",
                    // ],
                    where : {
                        "HIQDB_member_class.mid" : `= '${userInfo["id"]}'`
                    },
                }
            })


            // console.log("DATA : ", data)

            let in_id = data["list"].reduce((a,v,i)=>{
                if(i == 0 ){
                    return a =`'${v["cid"]}'`
                }
                else {
                    return a = a + `,'${v["cid"]}'`
                }
            },"")

            let data_2 = await apiFn.comApi({
                state : {
                    table : "member_class",
                    page : 1,
                    amount : 9999,
                    sort : "mid desc",
                    where : {
                        "HIQDB_member_class.cid" : `in (${in_id})`
                    }
                }
            })

            let t_data = data_2["list"].map((v)=>v["mid"])

            t_data = [... new Set(t_data)]

            let in_id_teacher = t_data.reduce((a,v,i)=>{
                if(i == 0 ){
                    return a =`'${v}'`
                }
                else {
                    return a = a + `,'${v}'`
                }
            },"")

            console.log("where_obj : ",where_obj);

            let teacherData = await apiFn.comApi({
                state : {
                    table : "user_credential",
                    page : 1,
                    amount: 9999,
                    sort : "reg_date desc",
                    ref: [
                        'A.*|HIQDB_user A on A.index=HIQDB_user_credential.user_id and A.user_type = 2'
                    ],
                    where : {
                        "user_credential.name" : `in (${in_id_teacher})`,
                        ...where_obj
                    }
                }
            })

            console.log("@@# : ",teacherData);

            if(isReturn){
                if(teacherData["list"].length == 0){
                    return []
                }
                else {
                    return teacherData["list"]
                }
            }
            else {
                if(teacherData["list"].length == 0){
                    setFriendsList([])
                }
                else {
                    setFriendsList([
                        ...teacherData["list"]
                    ])
                }
            }


        }
        catch(e){
            console.log("**")
            console.log(e)
            console.log("**")
        }
    }

    const apiPro_read_book = async () => {
        try {
            let data = await apiFn.comApi({
                state : {
                    table : "book_link",
                    page : 1,
                    amount : 9999,
                    sort : "mid desc",
                    ref : [
                        'HIQDB_book.name|HIQDB_book.id=HIQDB_book_link.bid',
                    ],
                    where : {
                        "HIQDB_book_link.qid" : `= '${params["qid"]}'`
                    }
                }

            })
            console.log("data : ",data)
            if(data["list"].length > 0){
                setBookName(data["list"][0]["name"])
            }
            if(params["where"] == "transfer"){
                console.log("transfer bid : ", data["list"][0]);
                setBookId(data["list"][0]["bid"])
                await apiPro_transfer_teacher(data["list"][0]["bid"]);
            }
        }
        catch(e){
        }
    }


    const apiPro_transfer_teacher = async (d, inSearch, ) => {
        try {

            let state = {};
            if(inSearch || search){
                state["HIQDB_teacher.name" ] = `= '${inSearch || search}'`
            }
            if(inSearch == ""){
                // state["HIQDB_teacher.name" ] = ""
            }
            let t_data = await apiFn.comApi({
                state : {
                    table : "teacher",
                    page : 1,
                    sort : "name desc",
                    amount : 9999,
                    ref : [
                        'HIQDB_book_link.bid|HIQDB_book_link.mid=HIQDB_teacher.id',
                        'HIQDB_book_link.type|HIQDB_book_link.mid=HIQDB_teacher.id',
                        'HIQDB_book_link.status as bstatus|HIQDB_book_link.mid=HIQDB_teacher.id',
                    ],
                    where : {
                        "HIQDB_book_link.bid" : `='${d ? d : bookId}'` ,
                        "HIQDB_book_link.status" : `!='9'` ,
                        "HIQDB_book_link.type" : `!='0'` ,
                        ...state
                    }
                    // table : "book_link",
                    // page : 1,
                    // sort : "mid desc",
                    // amount : 9999,
                    // ref : [
                    //     'HIQDB_teacher'
                    // ],
                }
            })

            let pre_array = []
            let array = []
            t_data["list"].map((v,i)=>{
                console.log("# ->",v);
                if(v["id"] == userInfo["id"])return;
                if(v["bstatus"] == 5){
                    pre_array.push(v)
                }
                else {
                    array.push(v)
                }
            })

            setTrans_teacher([
                ...array
            ])

            console.log(" array : : : : ", array);


            // setPre_data([
            //     ...pre_array
            // ])

            // setData([
            //     ...array
            // ])
        }
        catch(e){
            console.log('*******apiPro_transfer_teacher*****')
            console.log(e)
            console.log('*******apiPro_transfer_teacher*****')
        }
    }

    const apiPro_invite = async () => {
        try {

            let peopleObj = friendsList.reduce((a, v, i)=>{
                return ({
                    ...a,
                    [v["id"]] : {
                        ...v
                    }
                })
            }, {})

            // console.log("peopleObj => ",peopleObj)

            // return;

            await Promise.all(
                Object.keys(selectData).map((v, i)=>
                    apiFn.qnaInvite({
                        state : {
                            wtype : peopleObj[v].user_type,
                            wid:v,
                            qid:params["qid"],
                            ctype : 9, // 1 | 10 not source, 11 source
                            content : `${peopleObj[v].user_type === 1 ? peopleObj[v]["nick"] : peopleObj[v]["name"]}`,
                        }
                    })
                )
            )

            // await apiFn.onlineQnaMsgSet({
            //     state : {
            //         wtype : params["who"] == "student" ? 1 : 2,
            //         wid:userInfo["id"],
            //         qid:params["qid"],
            //         ctype : 0, // 1 | 10 not source, 11 source
            //         content : `${userInfo["name"]}님이 질문방에 참여하셨어요.`,
            //     }
            // })


            const stack = store.get('stack')
            if(stack.length > 0)
                navigate(-1);
            else
                navigate('/');
        }
        catch(e){
            console.log("apiPro_invite error -> ", e);
        }
    }

    const apiPro_read = async (d, inSearch, isReturn ) => {
        try {
            let invited = await apiFn.comApi({
                state : {
                    table : "app_msg",
                    page : 1,
                    amount : 9999,
                    sort : "reg_date desc",
                    where : {
                        "HIQDB_app_msg.qid" : `= '${params["qid"]}'`
                    }
                }
            })

            let in_id = invited["list"].reduce((a,v,i)=>{
                // return v["mid"].toString()
                // if(v["sid"] == userInfo["id"])return;
                if(i == 0 ){
                    return a =`'${v["sid"]}'`
                }
                else {
                    return a = a + `,'${v["sid"]}'`
                }
            },"")

            let state = {
                page : 1,
                amount : 9999,
                sort : "reg_date desc",
                except : in_id
            }
            // // console.log("** ",d);
            if(params["who"] == "teacher"){
                state["tid"] = userInfo["id"]
            }
            else {
                state["sid"] = userInfo["id"]
            }

            if(d ){
                if(params["who"] == "teacher"){
                    state["cid"] = d["cid"]
                }
                else {
                    state["cid"] = d["id"]
                }
            }

            if(inSearch || search){
                state["search"] = inSearch || search
            }
            if(inSearch == ""){
                state["search"] = ""
            }
            console.log("inSearch : ",inSearch);
            // console.log("state => ",state);
            let data = await apiFn.friendsList({
                state : state
            })

            console.log("People data => ",data["list"]);
            // setIsLoad(true)
            if(isReturn){
                if(data["list"].length == 0){
                    return []
                }
                else {
                    return data["list"]
                }
            }
            else {
                if(data["list"].length == 0){
                    setFriendsList([])
                    return []
                }
                else {
                    setFriendsList([
                        ...data["list"]
                    ])
                    return data["list"]
                }
            }

        }
        catch(e){
            // setIsLoad(true)
            // console.log("@@ ",e)
        }
    }

    const apiPro_in = async (sort, inSearch, r) => {
        try {
            let data = await apiFn.comApi({
                state : {
                    table : "online_qna_log",
                    page : 1,
                    amount : 9999,
                    sort : "reg_date desc",
                    where : {
                        "HIQDB_online_qna_log.qid" : `= '${params["qid"]}'`
                    }
                }
            })


            let wid = data["list"].reduce((a, v, i)=>{
                return [
                    ...a,
                    v["wid"]
                ]
            },[])

            let findPeople = await apiFn.findPeople({
                state : {
                    wid : wid
                }
            });


            if(findPeople.length == 0 ){
                setFriendsList([])
            }
            else {
                setFriendsList([
                    ...findPeople["list"]
                ])
                if(r)return findPeople["list"]
            }


        }
        catch(e){
            console.log("apiPro_in : ", e)
        }
        finally{
            setIsLoading(false)
        }
    }

    const call_expel = async (id, data) => {
        try {
            await apiFn.onlineQnaMsgSet({
                state : {
                    qid : params["qid"],
                    wtype : 2,
                    wid : userInfo["id"],
                    targetId : id,
                    content : `${data.user_type === 1 ? data["nick"] : data["name"]}님이 질문방을 나가셨어요.`,
                    ctype : 7,
                }
            })

            let del_id = {
                "HIQDB_app_msg.sid" : id,
            }
            if(data.user_type === 1){

            }
            else {
                del_id = { "HIQDB_app_msg.tid" : id, }
            }

            await apiFn.comApi({
                state : {
                    table : "app_msg",
                    delAll : "Y",
                    del : [{
                        "HIQDB_app_msg.qid" : params["qid"],
                        ...del_id
                    }]

                }
            })

            await apiFn.comApi({
                state : {
                    table : "online_qna_log",
                    delAll : "Y",
                    del : [{
                        "HIQDB_online_qna_log.qid" : params["qid"],
                        "HIQDB_online_qna_log.wid" : id,
                    }]

                }
            })



            const stack = store.get('stack')
            if(stack.length > 0)
                navigate(-1);
            else
                navigate('/');
        }
        catch(e){
            console.log("CALL EXPEL ERROR : ",e)
        }
    }
    const apiPro_expel = async () => {
        try {
            let peopleObj = friendsList.reduce((a, v, i)=>{
                return ({
                    ...a,
                    [v["id"]] : {
                        ...v
                    }
                })
            }, {})

            await Promise.all(Object.keys(selectData).map((v, i)=> call_expel(v, peopleObj[v])))

            // navigate(-1)

        }
        catch(e){
            console.log("APOPRO EXPEL ERROR : ",e)
        }
    }

    const apiPro_invite_all = async (d, search) => {
        try {
            let student = await apiPro_read("", search, true);
            let teacher = await apiPro_invite_teacher(true, search);

            console.log("student : ",student);
            console.log("teacher : ",teacher);

            let inviter = await apiPro_in(false, false, true)
            let inviter_id = inviter.reduce((a, v, i)=>{
                return [
                    ...a,
                    v["id"]
                ]
            },[])

            console.log("inviter : ",inviter_id)

            let cc = teacher.concat(student)

            let fr_arr = []

            cc.map((v)=>{
                if(inviter_id.includes(v["id"])){
                }
                else fr_arr.push(v)
            })

            setFriendsList([
                ...fr_arr
                // ...teacher,
                // ...student,
            ])
        }
        catch(e){
            console.log('************')
            console.log(e)
            console.log('************')
        }
        finally{
            setIsLoading(false)
        }
    }

    // useEffect(()=>{
    //    console.log("selectData : ", selectData) ;
    // },[selectData])

    useEffect(()=>{
        setSearch("");
        console.log("TAP : ",tap);
    },[tap])

    useEffect(()=>{
        if(params["where"] == "transfer") {
        }
        else {
            if(tap == "b"){
                // apiPro_read();
                apiPro_invite_all();
            }
            else if (tap == "a"){
                apiPro_in();
            }
        }
        apiPro_read_book();
    },[])

    var [isLoading, setIsLoading] = useState(true);

    return (
        <Frame
            // f_style={{

            // }}
            title={
                <div style={{
                    display:"flex",
                    alignItems:"center",
                    justifyContent:"space-between",
                    // backgroundColor:"green",
                    flex:1,

                    // marginTop : "10px",
                    // paddingBottom:"20px",
                }}>
                    <div style={{
                        display:"flex-direction"
                    }}>
                        {
                        params["where"] == "transfer" ?
                        "이관할 멤버 목록"
                        :
                        (params["who"] == "student" || params["tid"] != userInfo["id"]) ?
                        "멤버 목록"
                        :
                        "멤버 선택"
                        }
                        {
                        <div style={{
                            fontSize:"12px",
                        }}>
                            {bookName ? bookName : "출처가 없는 질문"}
                        </div>
                        }
                    </div>
                    {
                    Object.keys(selectData).length > 0 &&
                    <div style={{
                        display:"flex",
                        padding : "0px 10px",
                        justifyContent:"flex-end",
                        // backgroundColor:"red",
                        // width:"100%",
                    }}>
                        <UiBtn
                            title={tap == "a" ? "내보내기" : "초대하기"}
                            btnStyle={{
                                height:"30px",
                                width:"70px"
                            }}
                            fnClick={()=>{
                                if(tap == "a"){
                                    let isOk = window.confirm(`선택하신 ${Object.keys(selectData).length}명을 질문에서 내보내시겠어요?`)
                                    if(!isOk)return;
                                    apiPro_expel();
                                }
                                else {
                                    let isOk = window.confirm(`선택하신 ${Object.keys(selectData).length}명을 질문에 초대하시겠어요?`)
                                    if(!isOk)return;
                                    apiPro_invite();
                                }
                            }}
                        />
                    </div>
                    }
                </div>
            }
            isSearch={false}
            userInfo={userInfo}
        >
            {
            <div style={{
                width:"100%",
            }}>
                {
                params["where"] == "transfer" ?
                <></>
                :

                params["who"] == "teacher" &&
                <div style={{
                    marginTop:"10px",
                    display:"flex",
                }}>
                    <div style={{
                        width:"50%",
                        padding:tap == "a" ? "10px 0px" : "12px 0px",
                        display:"flex",
                        justifyContent:"center",
                        alignItems:"center",
                        fontWeight : tap == "a" ? "bold" : "normal",
                        fontSize : tap == "a" ? "16px" : "14px",
                        borderBottom : tap == "a" ? "2px solid #F5B21A" : "none"
                    }}
                        onClick={()=>{
                            setIsLoading(true)
                            setTap("a")
                            setSearch("")
                            setIsAll(false)
                            setFriendsList([]);
                            setSelectData({})
                            apiPro_in("", "");
                        }}
                    >
                        현재 멤버
                    </div>
                    <div style={{
                        width:"50%",
                        display:"flex",
                        padding:tap == "b" ? "10px 0px" : "12px 0px",
                        justifyContent:"center",
                        alignItems:"center",
                        fontWeight : tap == "b" ? "bold" : "normal",
                        fontSize : tap == "b" ? "16px" : "14px",
                        borderBottom : tap == "b" ? "2px solid #F5B21A" : "none"
                    }}
                        onClick={()=>{
                            setIsLoading(true)
                            setTap("b")
                            setSearch("")
                            setIsAll(false)
                            setFriendsList([]);
                            setSelectData({})
                            // apiPro_read();
                            apiPro_invite_all("","");
                        }}
                    >
                        초대할 멤버
                    </div>
                </div>
                }
                <div style={{
                    padding : "0px 10px",
                }}>
                    {
                    params["where"] == "transfer" ?
                    <SearchBox
                        search={search}
                        setSearch={setSearch}
                        apiPro_read={apiPro_transfer_teacher}
                    />
                    :
                    (
                        tap == "a" ?
                        <SearchBox
                            search={search}
                            setSearch={setSearch}
                            apiPro_read={apiPro_in}
                        />
                        :
                        (
                            sorting == "all" ?
                            <SearchBox
                                search={search}
                                setSearch={setSearch}
                                apiPro_read={apiPro_invite_all}
                            />
                            :
                            sorting == "t" ?
                            <SearchBox
                                search={search}
                                setSearch={setSearch}
                                apiPro_read={apiPro_invite_teacher}
                            />
                            :
                            <SearchBox
                                search={search}
                                setSearch={setSearch}
                                apiPro_read={apiPro_read}
                            />
                        )
                    )
                    }
                </div>
                {/* <div style={{
                    padding : "15px 10px 10px 10px",
                }}>
                    <div>
                        멤버
                    </div>
                </div> */}
                <div style={{
                    display:"flex",
                    padding : "15px 10px 5px 10px",
                    justifyContent:"space-between",
                    // backgroundColor:"red",
                    // width:"100%",
                }}>
                    {
                    (params["who"] == "student" || params["tid"] != userInfo["id"]) ? <></>
                    :
                    params["where"] != "transfer" &&
                    <div style={{
                        display:"flex",
                        alignItems:"center",
                        width:"100%",
                        justifyContent:"space-between",
                    }}>
                        {
                        (tap == "a" && friendsList.length <= 1) ? <></>
                        :
                        <div style={{
                            display:"flex",
                            alignItems:"center",
                        }}>
                            <div style={{
                                display : "flex",
                                justifyContent : 'center',
                                alignItems : "center",
                                marginRight : "10px",
                            }}>
                                <UiCheckBox
                                    className={"t"}
                                    // value={"?"}
                                    // className={"message_?"}
                                    checked={isAll}
                                    value={"@@"}
                                    fnClick={()=>{
                                        setIsAll(!isAll)
                                        if(isAll){
                                            setSelectData({})
                                        }
                                        else {
                                            setSelectData(friendsList.reduce((a, v,)=>{
                                                return ({
                                                    ...a,
                                                    [v["id"]] : "Y"
                                                })
                                            },{}))
                                        }
                                    }}
                                    containerStyle={{
                                        padding : '0px'
                                    }}
                                />
                            </div>
                            <div>
                                모두 선택
                            </div>
                        </div>
                        }
                        {
                        tap == "b" &&
                        <UiBasicSelect
                            // defaultValue={lectureId}
                            // defaultValue={window.localStorage.getItem("msg_home_lc") ? window.localStorage.getItem("msg_home_lc") : ""}
                            data={[
                                {name : "전체" , value : 'all'},
                                {name : "선생님" , value : 't'},
                                {name : "학생" , value : 's'},

                            ]}
                            fnChange={(v)=>{
                                // console.log("## ",v)
                                // console.log("tap : ",tap)
                                setSorting(v)
                                setSelectData({});
                                setIsAll(false)
                                if(v == "t"){
                                    apiPro_invite_teacher();
                                }
                                else if(v == "s"){
                                    apiPro_read();
                                }
                                else{
                                    apiPro_invite_all();
                                }
                            }}
                            // fontSize={"10px"}
                            title={"전체"}
                            c_style={{
                                minWidth : "100px",
                                fontSize : "10px",
                            }}
                        />
                        }
                    </div>
                    }
                </div>
                {/* {
                tap == "a" &&
                <Member
                    isChk={false}
                    params={params}
                    userInfo={userInfo}
                    data={{}}
                    id={v["id"]}
                    selectData={selectData}
                    fnCheck={(e)=>{
                        if(selectData[e.target.id] == "Y"){
                            delete selectData[e.target.id]
                            setSelectData({
                                ...selectData,
                            })
                        }
                        else {
                            setSelectData({
                                ...selectData,
                                [e.target.id] : "Y"
                            })
                        }
                    }}
                />
                } */}
                {
                // tap == "a" ?
                // <></>
                // :
                <Friend
                    tap={tap}
                    isChk={
                        params["where"] == "transfer" ? false
                        :
                        ((tap == "a" && friendsList.length == 1) ? false : true)
                    }
                    params={params}
                    userInfo={userInfo}
                    data={params["where"] == "transfer" ? trans_teacher : friendsList}
                    setSelectData={setSelectData}
                    selectData={selectData}
                />
                }
            </div>
            }
            {
            isLoading &&
            <UiLoading
                text='사용자 정보를 불러오고 있습니다.'
            />
            }
        </Frame>
    )
}

const SearchBox = ({
    search,
    setSearch,
    apiPro_read,
}) => {
    return (
        <div style={{
            display:"flex",
            alignItems:"center",
            height:"40px",
            marginTop:"10px",
            // width:"230px",
        }}>
            <UiInput
                type='basic'
                basic_container_style={{
                    height : "94%",
                    padding : "0px 0px 0px 5px",
                    // padding : "0px 10px"
                }}
                inputValue={search}
                fnChange={(e)=>{
                    setSearch(e.target.value)
                    search = e.target.value
                    apiPro_read("",e.target.value)
                }}
                placeholder={"검색"}
                // fnChange={fnChange}
            />
        </div>
    )
}

const Friend = ({
    data,
    setSelectData,
    selectData,
    params,
    userInfo,
    isChk,
    tap
}) => {
    return (
        <>
        {
        data.map((v)=> {
            if(tap == "b" && v["id"] == userInfo["id"] ){

            }
            else {
                return <Member
                    isChk={isChk}
                    params={params}
                    userInfo={userInfo}
                    data={v}
                    id={v["id"]}
                    selectData={selectData}
                    fnCheck={(e)=>{
                        if(selectData[e.target.id] == "Y"){
                            delete selectData[e.target.id]
                            setSelectData({
                                ...selectData,
                            })
                        }
                        else {
                            setSelectData({
                                ...selectData,
                                [e.target.id] : "Y"
                            })
                        }
                    }}
                />
            }

        })
        }
        </>
    )
}

const Member = ({
    id,
    isChk,
    selectData,
    isTeacher = true,
    fnCheck,
    data,
    params,
    userInfo,
}) => {
    let navigate = useNavigate();
    // useEffect(()=>{
    //     console.log("MEMBER : ", data["id"]);
    // },[data])

    // useEffect(()=>{
    //     console.log("selectData => ",selectData);
    // },[selectData])

    const apiPro_transfer = async (d) => {
        try {
            // 1.이관 시키기 (현재 질문의 tid data의 id로 변경)
            await apiFn.comApi({
                state : {
                    table : "online_qna",
                    set : [{
                        key : `id:${params["qid"]}`,
                        value : {
                            tid : id,
                            // reg_id : id,
                        }
                    }],
                }
            })

            // 2.현재 질문의 이관 전 tid 관련 값 모두 삭제
            // 2-1.app_msg table 정보 삭제
            // ******* 보류 2-1
            await apiFn.comApi({
                state : {
                    table : "app_msg",
                    delAll : "Y",
                    del : [{
                        "HIQDB_app_msg.qid" : params["qid"],
                        "HIQDB_app_msg.tid" : userInfo["id"],
                    }]
                }
            })

            // 2-2.online_qna_log 이관 전 tid 를 이관 후 tid로 교체
            // online_qna_log primarykey로 인해 삭제 먼저 진행
            await apiFn.comApi({
                state : {
                    table : "online_qna_log",
                    // delAll : "Y",
                    del : [{
                        "HIQDB_online_qna_log.wid" : userInfo["id"],
                        "HIQDB_online_qna_log.qid" : params["qid"],
                    }]
                }
            })

            // 2-2-2 online_qna_log primarykey로 인해 삭제 먼저 진행 후 이관 tid 등록

            await apiFn.qnaInvite({
                state : {
                    wtype : 2,
                    wid : id,
                    qid : params["qid"],
                    ctype : 6	,
                    content : `${d["name"]}`,
                }
            })

            // await apiFn.comApi({
            //     state : {
            //         table : "online_qna_log",
            //         ins : [{
            //             wid : id,
            //             qid : params["qid"],
            //             ltype : 1,
            //             reg_date : 0,
            //         }]
            //     }
            // })

            navigate(`/message/${params["who"]}/home`)
        }
        catch(e){
            console.log('************')
            console.log(e)
            console.log('************')
        }
    }

    return (
        <div style={{
            display:"flex",
            flexDirection : "column",
            alignItems : "center",
            flex:1,
            maxWidth:style.common.maxWidth,
            padding : "0px 10px",
        }}
        >
            <div style={{
                display:"flex",
                margin : "5px 0px",
                padding : "7px 0px",
                width:"100%",
                alignItems:"center",
                borderBottom : "1px solid #f6f6f6"
            }}>
                {
                (params["who"] == "student" || params["tid"] != userInfo["id"]) ? <></>
                :
                isChk &&
                data["id"] != userInfo["id"] &&
                <div style={{
                    display : "flex",
                    justifyContent : 'center',
                    alignItems : "center",
                    marginRight : "10px",
                }}>
                    <UiCheckBox
                        className={isTeacher ? "t" : "s"}
                        // value={"?"}
                        // className={"message_?"}
                        checked={selectData[id] == "Y" ? true : false}
                        value={id}
                        fnClick={fnCheck}
                        containerStyle={{
                            padding : '0px'
                        }}
                    />
                </div>
                }
                <div
                    style={{
                        display:"flex",
                    }}
                    onClick={()=>{
                        if(data["id"] == userInfo["id"])return;
                        if(params["where"] == "초대transfer"){
                            console.log("DATA : ",data)
                            let isOk = window.confirm(`${data["name"]}(${data["nick"]}) 선생님께 질문방을 이관하시겠어요?`)
                            if(!isOk)return;
                            apiPro_transfer(data)
                        }
                        else{
                            // navigate(`/myinfo/${params["who"]}/${data["id"]}`)
                        }
                }}>
                    <div style={{
                        // width : '20%',
                        display:"flex",
                        alignItems:"center",
                        marginRight : "10px",
                    }}
                        onClick={()=>{

                        }}
                    >
                        <div style={{
                            ...UiPeople_Style.section_i_w,
                            width:"44px",
                            height:"44px",
                        }}>
                            <img
                                style={UiMessage_Style.section_i}
                                onError={(e)=>{
                                    // setIsLoad(false)
                                    e.currentTarget.src = (data?.user_type === 2 ) ? face_teacher : face_student
                                }}
                                src={
                                    data ?
                                    (
                                        data["img"] ?
                                        (
                                        // constants.apiUrl
                                        constants.s3_url
                                        +
                                        // '/public'
                                        // +
                                        // 0729 img cache 관련 코드
                                        `${(data?.user_type === 2 ) ? "/teacher/" : "/student/"}${data["img"]}`
                                        // `${(data?.user_type === 2 ) ? "/teacher/" : "/student/"}${data["img"].normalize('NFD')}`
                                        +
                                        '.jpg?'
                                        // +
                                        // util.getRandomInt(1000,9999)
                                        )
                                        :
                                        (
                                        constants.apiUrl
                                        +
                                        // '/public'
                                        // +
                                        `${(data?.user_type === 2 ) ? "/teacher/" : "/student/"}${data["id"].normalize('NFD')}`
                                        +
                                        '.jpg?'
                                        +
                                        util.getRandomInt(1000,9999)
                                        )
                                    )
                                    :
                                    ((data?.user_type === 2 ) ? face_teacher : face_student )
                                }
                            />
                        </div>
                    </div>
                    <div style={{
                        display:"flex",
                        alignItems:"center",
                        width : '100%',
                        height : "100%",
                    }}
                        onClick={()=>{
                        }}
                    >
                        <div style={UiPeople_Style.section_r_t_t_text_2}>
                            {`${data["name"] ? data["name"] : "이름없음"} (${data["nick"] ? data["nick"] : ""})`}
                        </div>
                    </div>

                </div>
            </div>
            {

            }
        </div>
    )
}

export default Message_invite
