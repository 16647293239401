import { Modal, ModalTypeMap } from "@mui/material";
import { DefaultComponentProps } from "@mui/material/OverridableComponent";
import React from "react";
import styled from "styled-components";
interface CommonModalProps extends DefaultComponentProps<ModalTypeMap> {}
const CommonModal = ({ children, open, onClose }: CommonModalProps) => {
  return (
    <Modal
      open={open}
      onClose={onClose}
      slotProps={{
        backdrop(ownerState) {
          return {
            ...ownerState,
            style: {
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            },
            children,
          };
        },
      }}
    >
      <></>
    </Modal>
  );
};

export const CommonModalContainer = styled.div`
  display: flex;
  width: auto;
  height: auto;
  flex-direction: column;
  background-color: white;
  padding: 24px;
  border-radius: 8px;
  max-width: calc(100vw - 22.5px - 22.5px);
  box-sizing: border-box;
  box-shadow: 0px 16px 24px 0px rgba(0, 0, 0, 0.07);
`;

export default CommonModal;
