import { getUser } from "asset/storage";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import styled from "styled-components";

import { ReactComponent as HomeFillIcon } from "asset/images/icon/home-fill.svg";
import { ReactComponent as HomeIcon } from "asset/images/icon/home.svg";
import { ReactComponent as PlayFillIcon } from "asset/images/icon/play-fill.svg";
import { ReactComponent as PlayIcon } from "asset/images/icon/play.svg";
import { ReactComponent as ProfileFillIcon } from "asset/images/icon/profile-fill.svg";
import { ReactComponent as ProfileIcon } from "asset/images/icon/profile.svg";
import { ReactComponent as StudyFillIcon } from "asset/images/icon/studybook-fill.svg";
import { ReactComponent as StudyIcon } from "asset/images/icon/studybook.svg";
import { ReactComponent as TalkFillIcon } from "asset/images/icon/talk-fill.svg";
import { ReactComponent as TalkIcon } from "asset/images/icon/talk.svg";

type ActiveTabType = "people" | "lecture" | "message" | "studyBook" | "myinfo";

const Footer = () => {
  const userInfo = getUser();

  const [activeTabName, setActiveTabName] = useState<ActiveTabType>();
  const getActiveTabName = (pathname) => {
    const paths = [
      { key: "people", value: "people" },
      { key: "lecture", value: "lecture" },
      { key: "message", value: "message" },
      { key: "study-book", value: "studyBook" },
      { key: "youtube", value: "studyBook" },
      { key: "bookmain", value: "studyBook" },
      { key: "myinfo", value: "myinfo" },
    ] as {
      key: string;
      value: ActiveTabType;
    }[];

    const active = paths.find((path) => pathname.includes(path.key));
    return active ? active.value : undefined;
  };

  useEffect(() => {
    const activeTabName = getActiveTabName(window.location.pathname);
    setTimeout(() => setActiveTabName(activeTabName), 0);
  }, []);
  return (
    <AlignCenter>
      <Container>
        <InnerContainer>
          <Wrapper
            to={
              userInfo &&
              (userInfo["type"] == 1
                ? "/people/" + "student" + "/home"
                : "/people/" + "teacher" + "/home")
            }
          >
            {activeTabName === "people" ? (
              <FillIconContainer>
                <HomeFillIcon />
              </FillIconContainer>
            ) : (
              <HomeIcon />
            )}
            {
              <TitleFont show={activeTabName === "people"}>
                {userInfo["type"] == 1 ? "선생님" : "학원"}
              </TitleFont>
            }
          </Wrapper>
          <Wrapper to={"/lecture"}>
            {activeTabName === "lecture" ? (
              <FillIconContainer>
                <PlayFillIcon />
              </FillIconContainer>
            ) : (
              <PlayIcon />
            )}
            {<TitleFont show={activeTabName === "lecture"}>수업</TitleFont>}
          </Wrapper>
          <Wrapper
            to={
              userInfo &&
              (userInfo["type"] == 1
                ? "/message/" + "student" + "/" + "home"
                : "/message/" + "teacher" + "/" + "home")
            }
          >
            {activeTabName === "message" ? (
              <FillIconContainer>
                <TalkFillIcon />
              </FillIconContainer>
            ) : (
              <TalkIcon />
            )}
            {<TitleFont show={activeTabName === "message"}>Q&A</TitleFont>}
          </Wrapper>
          <Wrapper
            to={userInfo && (userInfo["type"] === 1 ? "/youtube/student/main" : "/study-book")}
          >
            {activeTabName === "studyBook" ? (
              <FillIconContainer>
                <StudyFillIcon />
              </FillIconContainer>
            ) : (
              <StudyIcon />
            )}
            {
              <TitleFont show={activeTabName === "studyBook"}>
                {userInfo["type"] === 1 ? "기출" : "교재"}
              </TitleFont>
            }
          </Wrapper>
          <Wrapper to={userInfo ? "/myinfo" : "/"}>
            {activeTabName === "myinfo" ? (
              <FillIconContainer>
                <ProfileFillIcon />
              </FillIconContainer>
            ) : (
              <ProfileIcon />
            )}
            {<TitleFont show={activeTabName === "myinfo"}>더 보기</TitleFont>}
          </Wrapper>
        </InnerContainer>
      </Container>
    </AlignCenter>
  );
};

const AlignCenter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
`;

const Container = styled.div`
  display: flex;
  justify-content: center;
  padding: 16px 16px 16px;
  background-color: #fff;
  box-sizing: border-box;
  box-shadow: 0px -6px 4px 0px rgba(181, 183, 202, 0.15);
  width: 100%;
  max-width: 1180px;
`;

const FillIconContainer = styled.div`
  display: flex;
  fill: var(--primary);
`;
const Wrapper = styled(Link)`
  display: flex;
  align-items: center;
  gap: 6px;
  padding: 8px 12px;
  flex: 1;
  justify-content: center;
  text-decoration: none;
`;

const InnerContainer = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  max-width: 488px;
  width: 100%;
`;

const TitleFont = styled.div<{ show: boolean }>`
  color: var(--primary);
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%; /* 12px */
  color: var(--primary);
  transition: all 0.6s;
  width: auto;
  max-width: ${({ show }) => (show ? "100%" : "0")};
  opacity: ${({ show }) => (show ? "1" : "0")};
  /* transform: ${({ show }) => (show ? "translateX(0)" : "translateX(10px)")}; */
  white-space: nowrap;
  overflow: hidden;
`;
export default Footer;
