import FullscreenExitRoundedIcon from "@mui/icons-material/FullscreenExitRounded";
import FullscreenRoundedIcon from "@mui/icons-material/FullscreenRounded";
import { CommonContext } from "asset/context";
import { VideoContext } from "components/molecules/VideoPlayer";
import React, { useCallback, useContext, useEffect } from "react";
import styles from "./style.module.scss";

const FullScreen = () => {
  const { handleFullScreen } = useContext(VideoContext);
  const { isFull, setIsFull } = useContext(CommonContext);

  const handleClick = useCallback(() => {
    if (handleFullScreen) {
      handleFullScreen();
    } else {
      setIsFull((prev) => !prev);
    }
  }, [handleFullScreen, isFull]);

  useEffect(() => {
    const anyWindow = window as any;
    if (anyWindow.ReactNativeWebView) {
      if (isFull) {
        anyWindow.ReactNativeWebView.postMessage("isVideo_on");
      } else {
        anyWindow.ReactNativeWebView.postMessage("isVideo_off");
      }
    }
  }, [isFull]);

  useEffect(() => {
    const handleOrientationChange = (event) => {
      if (window.matchMedia("(orientation: portrait)").matches) {
        setIsFull(true);
      } else {
        setIsFull(false);
      }
    };
    window.addEventListener("orientationchange", handleOrientationChange);
    return () => {
      window.removeEventListener("orientationchange", handleOrientationChange);
    };
  }, []);
  return (
    <button className={styles.button} onClick={handleClick}>
      {isFull ? <FullscreenExitRoundedIcon /> : <FullscreenRoundedIcon />}
    </button>
  );
};

export default FullScreen;
