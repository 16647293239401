import { Add, Create } from '@material-ui/icons'
import { React , useState , useEffect } from 'react'
import { useNavigate, useParams } from 'react-router'
import apiFn from '../../asset/apiClass'
import constants from '../../asset/constants'
import style from '../../asset/style'
import util from '../../asset/util'
import UiBtn from '../../uiComponents/UiBtn/UiBtn'
import UiPeople from '../../uiComponents/UiPeople/UiPeople'
import UiPeoPle_ProfileTeacher from '../../uiComponents/UiPeoPle_ProfileTeacher/UiPeoPle_ProfileTeacher'
import UiSearchBox from '../../uiComponents/UiSearch/UiSearchBox'
import { UiSearchList } from '../../uiComponents/UiSearchList/UiSearchList'
import UiSorting from '../../uiComponents/UiSorting/UiSorting'
import UiTopPopTextBtn from '../../uiComponents/UiTopPopTextBtn/UiTopPopTextBtn'
import UiVideo from '../../uiComponents/UiVideo/UiVideo'
import UiVideoSection from '../../uiComponents/UiVideoSectionList/UiVideoSection'
import Column_home_Style from '../Column/Column_home_Style'
import Profile_teacher_Style from './Profile_teacher_Style'
import aquery from '../../asset/Img/aquery.png'

const Profile_column = ({
    setFileObj,
    academyData,
    isTeacher,
    setHiddenNav,
    scrollKey,
    setScrolllKey,
}) => {
    let navigate = useNavigate();
    let params = useParams(); 

    var [isListLoad,setIsListLoad] = useState(false);

    const [tap,setTap] = useState("home")
    const [catData,setCatData] = useState([])
    const [smallCatData,setSmallCatData] = useState([])

    const [colData,setColData] = useState([]);
    const [colData_top3,setColData_top3] = useState([]);

    // cat 리스트 호출
    const apiPro_read = async () => {
        try {
            let data = await apiFn.comApi({
                state : {
                    table : "board_cat",
                    page : 1,
                    amount : 9999,
                    sort : "name desc",
                    where : {
                        "HIQDB_board_cat.type" : `='2'`,
                        "HIQDB_board_cat.pid" : `='0'`,
                    }
                }
            })
            // console.log("Column Data => ",data["list"])
            setCatData([
                ...data["list"]
            ])
        }
        catch(e){
            // console.log("",e)
        }
    }

    // 세부 cat 리스트 호출
    const apiPro_read_4 = async () => {
        try {
            let pid = `='${tap}'`
            if(tap == "home"){
                pid = `!='0'`
            }
            let data = await apiFn.comApi({
                state : {
                    table : "board_cat",
                    page : 1,
                    amount : 9999,
                    sort : "name desc",
                    where : {
                        "HIQDB_board_cat.type" : `='2'`,
                        "HIQDB_board_cat.pid" : pid,
                    }
                }
            })
            // // console.log("small Column Data => ",data["list"])
            setSmallCatData([
                ...data["list"]
            ])
            // setCatData([
            //     ...data["list"]
            // ])
        }
        catch(e){
            // console.log("",e)
        }
    }

    // column 리스츠 호출
    const apiPro_read_2 = async ( sort , isSmall ) => {
        try {
            let where_obj = {}
            let top3_obj = {
                cid : params["id"]
            }

            // let in_id = catData.reduce((a,v,i)=>{
            //     if(i == 0){
            //         return (a = a + v["id"] );
            //     }
            //     else {
            //         return (a = a +  "," + v["id"] );
            //     }
            // },"")

            // // console.log("#  ",in_id)

            where_obj["HIQDB_board.cid"] = `in (SELECT id FROM HIQDB_class WHERE pid = '${params["id"]}')`
            if(tap == "home"){
                top3_obj["type"] = "2"
                where_obj["HIQDB_board_cat.type"] = `= '2'`
                if(isSmall){
                    top3_obj["cat_id"] = isSmall
                    where_obj["HIQDB_board.cat_id"] = `= '${isSmall}'`
                }
            }
            else {
                top3_obj["cat_id"] = tap
                where_obj["HIQDB_board.cat_id"] = `= '${tap}'`
            }

            let data = await apiFn.comApi({
                state : {
                    top3 : top3_obj,
                    table : 'board',
                    page : 1,
                    amount : 9999,
                    sort : sort ? sort : "reg_date",
                    ref : [
                        "HIQDB_board_cat.id as xx|HIQDB_board_cat.id=HIQDB_board.cat_id",
                    ],
                    where : where_obj,
                    count : [
                        `HIQDB_board_log.bid:bid:id|and countTable.btype=\'1\'  |readCnt`,
                        `HIQDB_board_log.bid:bid:id|and countTable.btype=\'2\' |likeCnt`,
                    ]
                }
            })
            // console.log("@#@# ",data);
            setColData([
                ...data["list"]
            ])
            setColData_top3([
                ...data["top3"]
            ])
        }
        catch(e){
        }
        finally{
            setIsListLoad(true)
        }
    }

    useEffect(()=>{
        setFileObj({})
        apiPro_read();
        apiPro_read_4();
    },[])

    useEffect(()=>{
        apiPro_read_4();
        apiPro_read_2();
    },[tap])



    return (
        <div style={Profile_teacher_Style.c}>
             <div style={Column_home_Style.nav_w}>
                <div style={{
                    ...Column_home_Style.tap_text,
                    borderBottom : tap == "home" ? "2px solid" : "none",
                    borderBottomColor : tap == "home" ?
                    (isTeacher ? style.common.t_color : style.common.s_color)
                    :
                    "none"
                    ,
                    color : tap == "home" ?
                    (isTeacher ? style.common.t_color : style.common.s_color)
                    :
                    style.common.fontColor_2
                    
                }}
                    onClick={()=>{
                        setTap("home")
                    }}
                >
                    전체
                </div>
                {
                catData.map((v,i)=>
                <div style={{
                    ...Column_home_Style.tap_text,
                    borderBottom : tap == v["id"] ? "2px solid" : "none",
                    borderBottomColor : tap == v["id"] ?
                    (isTeacher ? style.common.t_color : style.common.s_color)
                    :
                    "none"
                    ,
                    color : 
                    tap == v["id"] ?
                    (isTeacher ? style.common.t_color : style.common.s_color)
                    :
                    style.common.fontColor_2

                }}
                    onClick={()=>{
                        setTap(v["id"])
                    }}
                >
                    {v["name"]}
                </div>
                )
                }
          
            </div> 
            <div style={Column_home_Style.t_section_w}>
                {
                colData_top3.map((v,i)=>
               <UiVideo
                    desc={v["content"]}
                    data={v}
                    fnClick={(data)=>{
                        setHiddenNav("column")
                        navigate("/classdetail/"+params["who"]+"/"+v["id"]+"/"+params["id"])
                    }}
                />
                )}
            </div>
            <div style={Column_home_Style.tag_w}>
                {
                smallCatData.map((v,i)=>
                <UiBtn
                    btnStyle={Column_home_Style.tag_btn}
                    title={`# ${v["name"]}`}
                    fnClick={()=>{
                        apiPro_read_2("reg_date desc",v["id"])
                    }}
                />
                )}
                
            </div>
            <div>
                <UiSorting
                    isTeacher={isTeacher}
                    title="실시간 컬럼"
                    data={[
                        {name : "등록일순" , value : "reg_date desc"},
                        {name : "좋아요순" , value : "likeCnt desc"},
                        {name : "열람순" , value : "readCnt desc"},
                    ]}
                    fnClick={(v)=>{
                        apiPro_read_2(v);
                    }}
                />
            </div>
            {
            isListLoad &&
            (
                colData.length > 0 ?
                colData.map((v,i)=>
                <UiVideoSection
                    data={v}
                    setScrolllKey={setScrolllKey}
                    scrollKey={scrollKey}
                    fnClick={(data)=>{
                        setHiddenNav("column")
                        setScrolllKey(data["id"])
                        navigate("/classdetail/"+params["who"]+"/"+data["id"]+"/"+params["id"])
                    }}
                />
                )
                :
                <div style={{
                    display:"flex",
                    flexDirection:"column",
                    justifyContent:"center",
                    alignItems:"center",
                    fontSize : "14px",
                    fontWeight:"bold",
                    color : style.common.fontColor_1,
                    width:"100%",
                    height : "100%",
                }}>
                    <img  
                        src={aquery} 
                        style={{
                            width:"50%",
                            height : "5%",
                            paddingRight:"35px",
                            marginTop:"100px",
                        }}
                    />
                    <div style={{
                        display:"flex",
                        flexDirection:"column",
                        alignItems:"center",
                        paddingTop : "20px",
                    }}>
                        <div>
                            현재 열람 가능한 글이 없어요.
                        </div>
                    </div>
                </div>
            )
            }
            {
            (isTeacher && academyData["type"] == 5 ) &&
            <div style={{
                position : "fixed",
                bottom:80,
                right: 20,
                width : "52px",
                borderRadius : 50,
                height:"52px",
                display:"flex",
                alignItems:"center",
                justifyContent:"center",
                boxShadow: "1px 1px 2px 2px rgba(90 ,90 ,90 , 0.2)",
                backgroundColor : isTeacher ? style.common.t_color : style.common.s_color
            }}
                onClick={()=>{
                    setHiddenNav("column")
                    navigate(`/write/${params["who"]}/${tap}/${params["id"]}/class/n`)
                }}
            >
                <Create
                    style={{
                        color : "white"
                    }}
                />
            </div>
            }
        </div>
    )
}

export default Profile_column