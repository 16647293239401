import { React, useEffect, useRef } from "react";
import style from "../../asset/style";
import UiVideoSectionList_Style from "./UiVideoSectionList_Style";
import { useLongPress } from "use-long-press";
import { UiCheckBox } from "../UiCheckBox/UiCheckBox";
import util from "../../asset/util";
import constants from "../../asset/constants";
import de_column from "../../asset/Img/noLogo.png";

// const UiVideoSectionList = ({
//     isTeacher,
//     data,
//     fnClick,
//     fnLongClick,
//     isEdit = false,
//     fnCheck,
//     selectData = {},
// }) => {
//     return (
//         <>
//         {data.map((v,i)=>
//         <UiVideoSection
//             data={v}
//             fnClick={fnClick}
//             fnLongClick={fnLongClick}
//             isTeacher={isTeacher}
//             isEdit ={isEdit}
//             fnCheck={fnCheck}
//             selectData={selectData}
//         />
//         )}
//         </>
//     )
// }

const UiVideoSection = ({
  isTeacher,
  data,
  fnClick,
  // setIsSearch,
  fnLongClick,
  isEdit = false,
  fnCheck,
  selectData = {},
  scrollKey,
  setScrolllKey,
}) => {
  const bind = useLongPress(
    () => {
      try {
        fnLongClick(data);
      } catch (e) {
        // console.log("Long Press Error => ",e)
      }
    },
    {
      threshold: 1000,
    }
  );

  useEffect(() => {
    // // console.log("data @ @ @ @ @ @ ",data);
  }, [data]);

  var scrollRef = useRef(null);

  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollIntoView({
        // behavior: "smooth",
        block: "center",
      });
      // scrollKey = ""
      if (setScrolllKey) {
        setScrolllKey("");
      }
    }
  }, [scrollRef]);

  return (
    <div
      style={UiVideoSectionList_Style.section_w}
      ref={scrollKey == data["id"] ? scrollRef : null}
      {...bind()}
      onClick={() => {
        if (!isEdit) {
          fnClick(data);
        }
      }}
    >
      <div style={UiVideoSectionList_Style.section_i_w}>
        {isEdit && (
          <div style={UiVideoSectionList_Style.section_folder_chk}>
            <UiCheckBox
              fnClick={fnCheck}
              checked={selectData[data["id"]] == "Y" ? true : false}
              className={isTeacher ? "t" : "s"}
              value={data["id"]}
            />
          </div>
        )}
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
        }}
      >
        <div style={UiVideoSectionList_Style.section_t_w}>
          {data["cat_id"] != "158" && (
            <div style={UiVideoSectionList_Style.section_t_img_w}>
              <img
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                  objectPosition: "center",
                }}
                // src={isTeacher ? face_teacher : face_student}
                onError={(e) => {
                  // setIsLoad(false)
                  e.currentTarget.src = de_column;
                }}
                src={
                  data["video_link"]
                    ? data["video_link"].includes("watch")
                      ? `http://img.youtube.com/vi/${data["video_link"].split("=")[1].split("&")[0]}/mqdefault.jpg`
                      : `http://img.youtube.com/vi/${data["video_link"].split("youtu.be/")[1].split("&")[0]}/mqdefault.jpg`
                    : // data["img"] ?
                      // constants.s3_url
                      // +
                      // '/public'
                      // +
                      // `/board/${data["img"]}`
                      // +
                      // '.jpg?'
                      // :
                      data["id"]
                      ? constants.s3_url +
                        // '/public'
                        // +
                        `/board/${data["id"]}` +
                        ".jpg?"
                      : // +
                        // util.getRandomInt(1000,9999)
                        ""
                }
              />
            </div>
          )}
          <div
            style={{
              ...UiVideoSectionList_Style.section_r_w,
              paddingLeft: data["cat_id"] != "158" ? "10px" : "0px",
            }}
          >
            <div style={UiVideoSectionList_Style.section_r_text_1}>
              <div
                style={{
                  marginRight: "3px",
                }}
              >
                {data["writer"] && data["writer"]["name"]} 선생님
              </div>
              {data["classList"] &&
                data["classList"].length > 0 &&
                " | " + data["classList"][0]["name"]}
              {data["classList"] &&
                data["classList"].length > 1 &&
                " 외 " + (data["classList"].length - 1) + "건"}
            </div>
            <div style={UiVideoSectionList_Style.section_r_text_2}>
              {/* 발렌타인데발렌타인데발렌타인데발렌타인데발렌타인42  */}
              {data["title"]}
            </div>
          </div>
        </div>
        <div style={UiVideoSectionList_Style.section_b_w}>
          <div style={UiVideoSectionList_Style.section_b_text_1}>
            {/* 2022.02.14 */}
            {util.convertToDateTime_form2(data["reg_date"])}
          </div>
          <div style={UiVideoSectionList_Style.section_b_text_2}>
            {`${data["readCnt"]}명 열람`} | 좋아요
            <div
              style={{
                color: style.common.fontColor_3,
                marginLeft: "3px",
              }}
            >
              {data["likeCnt"]}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UiVideoSection;
