
// const whitelist = ".zip, .alz,.7Z ,.dwg, .jpeg , .png , .jpg , .png , .bmp , .tiff , .tif , .svg , .mp4, .avi , .mp3 , .wav, .wma , .txt,.hwp,.hwt,.hml,.hwpx,.show,.hpt,.hsdt,.htheme,.cell,.nxl,.hcdt,.nxt, .pdf, .csv, .docx, .docm, .dotx, .dotm, .xlsx, .xlsm, .xltx, .xltm, .xlsb, .xlam, .pptx, .pptm, .ppsx, .ppsm, .potx,.potm, .ppam, .sldx, .sldm, .one, .onetoc2, .onetmp, .onepkg, .thmx"
const whitelist = 
`
text/plain,
application/vnd.hancom.hwp,
application/vnd.hancom.hwt,
application/vnd.hancom.hml,
application/vnd.hancom.hwpx,
application/vnd.hancom.hpt,
application/vnd.hancom.hcdt,
application/haansoftjustslidehsdt,
application/haansofthwp,
application/pdf,
text/csv, 
application/vnd.ms-excel,
application/vnd.openxmlformats-officedocument.wordprocessingml.document,
application/vnd.ms-word.document.macroEnabled.12, 
application/vnd.openxmlformats-officedocument.wordprocessingml.template, 
application/vnd.ms-word.template.macroEnabled.12, 
application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, 
application/vnd.ms-excel.sheet.macroEnabled.12, 
application/vnd.openxmlformats-officedocument.spreadsheetml.template, 
application/vnd.ms-excel.template.macroEnabled.12, 
application/vnd.ms-excel.sheet.binary.macroEnabled.12, 
application/vnd.ms-excel.addin.macroEnabled.12, 
application/vnd.openxmlformats-officedocument.presentationml.presentation, 
application/vnd.ms-powerpoint.presentation.macroEnabled.12, 
application/vnd.openxmlformats-officedocument.presentationml.slideshow, 
application/vnd.ms-powerpoint.slideshow.macroEnabled.12, 
application/vnd.openxmlformats-officedocument.presentationml.template,
application/vnd.ms-powerpoint.template.macroEnabled.12, 
application/vnd.ms-powerpoint.addin.macroEnabled.12, 
application/vnd.openxmlformats-officedocument.presentationml.slide, 
application/vnd.ms-powerpoint.slide.macroEnabled.12, 
application/onenote,
application/vnd.ms-officetheme,
`



export default whitelist
