import React from "react";
import styled, { CSSProperties, css } from "styled-components";

export const FontStyleMap = {
  "EN/bold/$EN.H1.BOL.60": `
    font-family: "Apple SD Gothic Neo";
    font-size: 60px;
    font-weight: 700;
    line-height: 130%; /* 78px */
  `,
  "KR/bold/$KR.H1.BOL.60": `
    font-family: Spoqa Han Sans Neo;
    font-size: 60px;
    font-weight: 700;
    line-height: 78px;
  `,
  "EN/bold/$EN.H2.BOL.48": `
    font-family: "Apple SD Gothic Neo";
    font-size: 48px;
    font-weight: 700;
    line-height: 130%; /* 62.4px */
  `,
  "KR/bold/$KR.H2.BOL.48": `
    font-family: "Spoqa Han Sans Neo";
    font-size: 48px;
    font-weight: 700;
    line-height: 130%; /* 62.4px */
  `,
  "KR/bold/$KR.H3.BOL.36": `
    font-family: "Spoqa Han Sans Neo";
    font-size: 36px;
    font-weight: 700;
    line-height: 130%; /* 46.8px */
  `,
  "EN/bold/$EN.TL.BOL.32": `
    font-family: "Apple SD Gothic Neo";
    font-size: 32px;
    font-weight: 700;
    line-height: 130%; /* 41.6px */
  `,
  "KR/bold/$KR.TL.BOL.32": `
    font-family: "Spoqa Han Sans Neo";
    font-size: 32px;
    font-weight: 700;
    line-height: 130%; /* 41.6px */
  `,
  "EN/bold/$EN.TM.BOL.24": `
    font-family: "Apple SD Gothic Neo";
    font-size: 24px;
    font-weight: 700;
    line-height: 130%; /* 31.2px */
  `,
  "KR/bold/$KR.TM.BOL.24": `
    font-family: "Spoqa Han Sans Neo";
    font-size: 24px;
    font-weight: 700;
    line-height: 130%; /* 31.2px */
  `,
  "EN/bold/$EN.TS.BOL.20": `
    font-family: "Apple SD Gothic Neo";
    font-size: 20px;
    font-weight: 700;
    line-height: 130%; /* 26px */
  `,
  "KR/bold/$KR.TS.BOL.20": `
    font-family: "Spoqa Han Sans Neo";
    font-size: 20px;
    font-weight: 700;
    line-height: 130%; /* 26px */
  `,
  "EN/bold/$EN.BL.BOL.16": `
    font-family: "Apple SD Gothic Neo";
    font-size: 16px;
    font-weight: 700;
    line-height: 130%; /* 20.8px */
  `,
  "KR/bold/$KR.BL.BOL.16": `
    font-family: "Spoqa Han Sans Neo";
    font-size: 16px;
    font-weight: 700;
    line-height: 130%; /* 20.8px */
  `,
  "EN/bold/$EN.BM.BOL.14": `
    font-family: "Apple SD Gothic Neo";
    font-size: 14px;
    font-weight: 700;
    line-height: 130%; /* 18.2px */
  `,
  "KR/bold/$KR.BM.BOL.14": `
    font-family: "Spoqa Han Sans Neo";
    font-size: 14px;
    font-weight: 700;
    line-height: 130%; /* 18.2px */
  `,
  "EN/bold/$EN.BS.BOL.12": `
    font-family: "Apple SD Gothic Neo";
    font-size: 12px;
    font-weight: 700;
    line-height: 130%; /* 15.6px */
  `,
  "KR/bold/$KR.BS.BOL.12": `
    font-family: "Spoqa Han Sans Neo";
    font-size: 12px;
    font-weight: 700;
    line-height: 130%; /* 15.6px */
  `,
  "EN/bold/$EN.BtnL.BOL.16": `
    font-family: "Apple SD Gothic Neo";
    font-size: 16px;
    font-weight: 700;
    line-height: 100%; /* 16px */
  `,
  "KR/bold/$KR.BtnL.BOL.16": `
    font-family: "Spoqa Han Sans Neo";
    font-size: 16px;
    font-weight: 700;
    line-height: 100%; /* 16px */
  `,
  "EN/bold/$EN.BtnM.BOL.14": `
    font-family: "Apple SD Gothic Neo";
    font-size: 14px;
    font-weight: 700;
    line-height: 100%; /* 14px */
  `,
  "KR/bold/$KR.BtnM.BOL.14": `
    font-family: "Spoqa Han Sans Neo";
    font-size: 14px;
    font-weight: 700;
    line-height: 100%; /* 14px */
  `,
  "EN/bold/$EN.CAP.BOL.12": `
    font-family: "Apple SD Gothic Neo";
    font-size: 12px;
    font-weight: 700;
    line-height: 100%; /* 12px */
  `,
  "KR/bold/$KR.CAP.BOL.12": `
    font-family: "Spoqa Han Sans Neo";
    font-size: 12px;
    font-weight: 700;
    line-height: 100%; /* 12px */
  `,
  "EN/regular/$EN.H1.REG.60": `
    font-family: "Apple SD Gothic Neo";
    font-size: 60px;
    font-weight: 400;
    line-height: 130%; /* 78px */
  `,
  "KR/regular/$KR.H1.REG.60": `
    font-family: Spoqa Han Sans Neo;
    font-size: 60px;
    font-weight: 400;
    line-height: 78px;
  `,
  "EN/regular/$EN.H2.REG.48": `
    font-family: "Apple SD Gothic Neo";
    font-size: 48px;
    font-weight: 400;
    line-height: 130%; /* 62.4px */
  `,
  "KR/regular/$KR.H2.REG.48": `
    font-family: "Spoqa Han Sans Neo";
    font-size: 48px;
    font-weight: 400;
    line-height: 130%; /* 62.4px */
  `,
  "KR/regular/$KR.H3.REG.36": `
    font-family: "Spoqa Han Sans Neo";
    font-size: 36px;
    font-weight: 400;
    line-height: 130%; /* 46.8px */
  `,
  "EN/regular/$EN.TL.REG.32": `
    font-family: "Apple SD Gothic Neo";
    font-size: 32px;
    font-weight: 400;
    line-height: 130%; /* 41.6px */
  `,
  "KR/regular/$KR.TL.REG.32": `
    font-family: "Spoqa Han Sans Neo";
    font-size: 32px;
    font-weight: 400;
    line-height: 130%; /* 41.6px */
  `,
  "EN/regular/$EN.TM.REG.24": `
    font-family: "Apple SD Gothic Neo";
    font-size: 24px;
    font-weight: 400;
    line-height: 130%; /* 31.2px */
  `,
  "KR/regular/$KR.TM.REG.24": `
    font-family: "Spoqa Han Sans Neo";
    font-size: 24px;
    font-weight: 400;
    line-height: 130%; /* 31.2px */
  `,
  "EN/regular/$EN.TS.REG.20": `
    font-family: "Apple SD Gothic Neo";
    font-size: 20px;
    font-weight: 400;
    line-height: 130%; /* 26px */
  `,
  "KR/regular/$KR.TS.REG.20": `
    font-family: "Spoqa Han Sans Neo";
    font-size: 20px;
    font-weight: 400;
    line-height: 130%; /* 26px */
  `,
  "EN/regular/$EN.BL.REG.16": `
    font-family: "Apple SD Gothic Neo";
    font-size: 16px;
    font-weight: 400;
    line-height: 130%; /* 20.8px */
  `,
  "KR/regular/$KR.BL.REG.16": `
    font-family: "Spoqa Han Sans Neo";
    font-size: 16px;
    font-weight: 400;
    line-height: 130%; /* 20.8px */
  `,
  "EN/regular/$EN.BM.REG.14": `
    font-family: "Apple SD Gothic Neo";
    font-size: 14px;
    font-weight: 400;
    line-height: 130%; /* 18.2px */
  `,
  "KR/regular/$KR.BM.REG.14": `
    font-family: "Spoqa Han Sans Neo";
    font-size: 14px;
    font-weight: 400;
    line-height: 130%; /* 18.2px */
  `,
  "EN/regular/$EN.BS.REG.12": `
    font-family: "Apple SD Gothic Neo";
    font-size: 12px;
    font-weight: 400;
    line-height: 130%; /* 15.6px */
  `,
  "KR/regular/$KR.BS.REG.12": `
    font-family: "Spoqa Han Sans Neo";
    font-size: 12px;
    font-weight: 400;
    line-height: 130%; /* 15.6px */
  `,
  "EN/regular/$EN.Btn.REG.BOL.16": `
    font-family: "Apple SD Gothic Neo";
    font-size: 16px;
    font-weight: 400;
    line-height: 100%; /* 16px */
  `,
  "KR/regular/$KR.Btn.REG.BOL.16": `
    font-family: "Spoqa Han Sans Neo";
    font-size: 16px;
    font-weight: 400;
    line-height: 100%; /* 16px */
  `,
  "EN/regular/$EN.Btn.REG.BOL.14": `
    font-family: "Apple SD Gothic Neo";
    font-size: 14px;
    font-weight: 400;
    line-height: 100%; /* 14px */
  `,
  "KR/regular/$KR.Btn.REG.BOL.14": `
    font-family: "Spoqa Han Sans Neo";
    font-size: 14px;
    font-weight: 400;
    line-height: 100%; /* 14px */
  `,
  "EN/regular/$EN.CAP.REG.12": `
    font-family: "Apple SD Gothic Neo";
    font-size: 12px;
    font-weight: 400;
    line-height: 100%; /* 12px */
  `,
  "KR/regular/$KR.CAP.REG.12": `
    font-family: "Spoqa Han Sans Neo";
    font-size: 12px;
    font-weight: 400;
    line-height: 100%; /* 12px */
  `,
  "EN/medium/$EN.H1.MD.60": `
    font-family: "Apple SD Gothic Neo";
    font-size: 60px;
    font-weight: 500;
    line-height: 130%; /* 78px */
  `,
  "KR/medium/$KR.H1.MD.60": `
    font-family: Spoqa Han Sans Neo;
    font-size: 60px;
    font-weight: 500;
    line-height: 78px;
  `,
  "EN/medium/$EN.H2.MD.48": `
    font-family: "Apple SD Gothic Neo";
    font-size: 48px;
    font-weight: 500;
    line-height: 130%; /* 62.4px */
  `,
  "KR/medium/$KR.H2.MD.48": `
    font-family: "Spoqa Han Sans Neo";
    font-size: 48px;
    font-weight: 500;
    line-height: 130%; /* 62.4px */
  `,
  "KR/medium/$KR.H3.MD.36": `
    font-family: "Spoqa Han Sans Neo";
    font-size: 36px;
    font-weight: 500;
    line-height: 130%; /* 46.8px */
  `,
  "EN/medium/$EN.TL.MD.32": `
    font-family: "Apple SD Gothic Neo";
    font-size: 32px;
    font-weight: 500;
    line-height: 130%; /* 41.6px */
  `,
  "KR/medium/$KR.TL.MD.32": `
    font-family: "Spoqa Han Sans Neo";
    font-size: 32px;
    font-weight: 500;
    line-height: 130%; /* 41.6px */
  `,
  "EN/medium/$EN.TM.MD.24": `
    font-family: "Apple SD Gothic Neo";
    font-size: 24px;
    font-weight: 500;
    line-height: 130%; /* 31.2px */
  `,
  "KR/medium/$KR.TM.MD.24": `
    font-family: "Spoqa Han Sans Neo";
    font-size: 24px;
    font-weight: 500;
    line-height: 130%; /* 31.2px */
  `,
  "EN/medium/$EN.TS.MD.20": `
    font-family: "Apple SD Gothic Neo";
    font-size: 20px;
    font-weight: 500;
    line-height: 130%; /* 26px */
  `,
  "KR/medium/$KR.TS.MD.20": `
    font-family: "Spoqa Han Sans Neo";
    font-size: 20px;
    font-weight: 500;
    line-height: 130%; /* 26px */
  `,
  "EN/medium/$EN.BL.MD.16": `
    font-family: "Apple SD Gothic Neo";
    font-size: 16px;
    font-weight: 500;
    line-height: 130%; /* 20.8px */
  `,
  "KR/medium/$KR.BL.MD.16": `
    font-family: "Spoqa Han Sans Neo";
    font-size: 16px;
    font-weight: 500;
    line-height: 130%; /* 20.8px */
  `,
  "EN/medium/$EN.BM.MD.14": `
    font-family: "Apple SD Gothic Neo";
    font-size: 14px;
    font-weight: 500;
    line-height: 130%; /* 18.2px */
  `,
  "KR/medium/$KR.BM.MD.14": `
    font-family: "Spoqa Han Sans Neo";
    font-size: 14px;
    font-weight: 500;
    line-height: 130%; /* 18.2px */
  `,
  "EN/medium/$EN.BS.MD.12": `
    font-family: "Apple SD Gothic Neo";
    font-size: 12px;
    font-weight: 500;
    line-height: 130%; /* 15.6px */
  `,
  "KR/medium/$KR.BS.MD.12": `
    font-family: "Spoqa Han Sans Neo";
    font-size: 12px;
    font-weight: 500;
    line-height: 130%; /* 15.6px */
  `,
  "EN/medium/$EN.BtnL.MD.16": `
    font-family: "Apple SD Gothic Neo";
    font-size: 16px;
    font-weight: 500;
    line-height: 100%; /* 16px */
  `,
  "KR/medium/$KR.BtnL.MD.16": `
    font-family: "Spoqa Han Sans Neo";
    font-size: 16px;
    font-weight: 500;
    line-height: 100%; /* 16px */
  `,
  "EN/medium/$EN.BtnM.MD.14": `
    font-family: "Apple SD Gothic Neo";
    font-size: 14px;
    font-weight: 500;
    line-height: 100%; /* 14px */
  `,
  "KR/medium/$KR.BtnM.MD.14": `
    font-family: "Spoqa Han Sans Neo";
    font-size: 14px;
    font-weight: 500;
    line-height: 100%; /* 14px */
  `,
  "EN/regular/$EN.BtnM.REG.14": `
    font-family: Apple SD Gothic Neo;
    font-size: 14px;
    font-weight: 400;
    line-height: 14px;
    text-align: left;
  `,
  "KR/regular/$KR.BtnM.REG.14": `
    font-family: Spoqa Han Sans Neo;
    font-size: 14px;
    font-weight: 400;
    line-height: 14px;
    text-align: left;
  `,
  "EN/medium/$EN.CAP.MD.12": `
    font-family: "Apple SD Gothic Neo";
    font-size: 12px;
    font-weight: 500;
    line-height: 100%; /* 12px */
  `,
  "KR/medium/$KR.CAP.MD.12": `
    font-family: "Spoqa Han Sans Neo";
    font-size: 12px;
    font-weight: 500;
    line-height: 100%; /* 12px */
  `,
} as const;

export type FontProps = {
  children: React.ReactNode;
  styleName?: keyof typeof FontStyleMap;
  tagName?: keyof JSX.IntrinsicElements;
  color?: CSSProperties["color"];
};

const Font = ({ children, styleName, tagName = "span", color }: FontProps) => {
  const innerStyle = FontStyleMap[styleName];
  return (
    <FontComponent $innerStyle={innerStyle} as={tagName} color={color}>
      {children}
    </FontComponent>
  );
};

const FontComponent = styled.div<{ $innerStyle: string; color: CSSProperties["color"] }>`
  ${({ $innerStyle }) => css`
    ${$innerStyle}
  `}
  ${({ color }) =>
    color &&
    css`
      color: ${color};
    `}
`;

export default Font;
