import { useClassDetailInfo } from "api/classDetail";
import { ClassRegistRequset, useClassUpdate } from "api/classRegist";
import Container from "components/atoms/Container";
import React, { Suspense, useCallback, useEffect, useMemo, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router";
import UiLoading from "uiComponents/UiLoading/UiLoading";
import { GapContainer } from ".";
import AdditionalFile from "./components/AdditionalFile";
import ClassContentEditor from "./components/ClassContentEditor";
import LectureRegistForm from "./components/LectureRegistForm";
import LectureRegistHeader from "./components/LectureRegistHeader";
import VideoFileUpload from "./components/VideoFileUpload";

const LectureUpdate = (defaultValues: Partial<ClassRegistRequset>) => {
  const methods = useForm<ClassRegistRequset>({
    defaultValues,
  });
  const { mutateAsync } = useClassUpdate(defaultValues.id);
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    trigger,
    unregister,
  } = methods;

  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = useCallback(async (formData: ClassRegistRequset) => {
    // const res = await mutateAsync(formData);
    setIsLoading(true);
    try {
      const res = await mutateAsync(formData);
      setIsLoading(false);
      navigate(-1);
    } catch (e) {
      console.log(e);
    } finally {
      setIsLoading(false);
    }
  }, []);

  const onError = useCallback((error) => {
    console.log(error);
  }, []);

  useEffect(() => () => unregister(), []);

  return (
    <FormProvider {...methods}>
      {isLoading && <UiLoading isFull />}
      <Container>
        <form onSubmit={handleSubmit(onSubmit, onError)}>
          <LectureRegistHeader title="수업영상 수정" />
          <GapContainer>
            <LectureRegistForm />
            <VideoFileUpload />
            <ClassContentEditor />
            <AdditionalFile />
          </GapContainer>
        </form>
      </Container>
    </FormProvider>
  );
};

const Wrapper = () => {
  const param = useParams();
  const { data } = useClassDetailInfo({ boardId: param.boardId });
  const videoData = useMemo(() => data?.getBoard?.boards[0], [data]);
  return (
    <Suspense
      fallback={
        <UiLoading
          isTransparent={true}
          isText={false}
          isCircle={false}
          text="수업 내용을 불러오는 중입니다."
        />
      }
    >
      <LectureUpdate {...videoData} files={videoData.files} />
    </Suspense>
  );
};

export default Wrapper;
