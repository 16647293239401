import { React, useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router";
import { ArrowRight } from "@material-ui/icons";
import UiOrderLog_Style from "./UiOrderLog_Style";
import util from "asset/util";

const UiOrderLog = ({ data, fnClick, index }) => {
  return (
    <div
      style={{
        // ...UiTicketLog_Style.c,
        padding: "14px 10px",
        backgroundColor: index % 2 == 0 ? "#FFFBF5" : "#FBFBFB",
        marginBottom: "2px",
        borderRadius: "5px",
        display: "flex",
        alignItems: "center",
      }}
      onClick={() => fnClick(data)}
    >
      <div
        style={{
          flex: 1,
        }}
      >
        <div
          style={{
            display: "flex",
            fontSize: "14px",
            fontWeight: 400,
            color: "#3D3A39",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              display: "flex",
            }}
          >
            {data.name}
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <div
              style={{
                fontSize: "12px",
                marginLeft: "8px",
                color: "#B0B0B0",
              }}
            >
              {data.payMethod}
            </div>
            <div
              style={{
                fontSize: "14px",
                marginLeft: 8,
                fontWeight: 400,
              }}
            >
              {util.formatCurrency(data.totalAmount)}
            </div>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            fontSize: "14px",
            fontWeight: 400,
            color: "#3D3A39",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div style={UiOrderLog_Style.text_w_2}>{data.state}</div>
          <div
            style={{
              ...UiOrderLog_Style.text_2,
              fontSize: "12px",
              fontWeight: 400,
              color: "#B0B0B0",
            }}
          >
            {/* 2022/02/05  */}
            {data?.datetime}
          </div>
        </div>
      </div>
      <ArrowRight />
    </div>
  );
};

export default UiOrderLog;
