import { TextField, useMediaQuery } from "@mui/material";
import { FontStyleMap } from "components/atoms/Font";
import HorizontalBlank from "components/atoms/HorizontalBlank";
import Section from "components/atoms/Section";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useFormContext } from "react-hook-form";
import { Document, Page, pdfjs } from "react-pdf";
import { PageCallback } from "react-pdf/dist/cjs/shared/types";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import styled, { keyframes } from "styled-components";
import { PdfUploadForm } from "..";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;
interface PdfViewerProps {
  onPageChanged: (pageNumber: number) => void;
  file: File;
  pageNumber: number;
  title: string;
}

const PdfViewer = ({ onPageChanged, file, pageNumber, title }: PdfViewerProps) => {
  const {
    formState: { errors },
  } = useFormContext<PdfUploadForm>();

  const errorMessage = useMemo(() => {
    if (title === "시작") {
      return errors.startPage?.message;
    } else if (title === "종료") {
      return errors.endPage?.message;
    }
  }, [title, errors.startPage?.message, errors.endPage?.message]);
  const [numPages, setNumPages] = useState(null);
  const [currentPage, setCurrentPage] = useState<null | number>(pageNumber);
  const [dragStartX, setDragStartX] = useState(0);
  const pdfRef = useRef<HTMLDivElement>(null);
  const [pdfWidthSize, setPdfWidthSize] = useState(0);
  const [pdfHeightSize, setPdfHeightSize] = useState(0);

  // 인접한 페이지를 위한 상태
  const [prevPageNumber, setPrevPageNumber] = useState(currentPage > 1 ? currentPage - 1 : null);
  const [nextPageNumber, setNextPageNumber] = useState(
    currentPage < numPages ? currentPage + 1 : null
  );

  const onDocumentLoadSuccess = useCallback(
    ({ numPages }) => {
      if (pageNumber === -1) {
        setCurrentPage(numPages);
      }
      setNumPages(numPages);
    },
    [setCurrentPage, setNumPages]
  );

  function onPageLoadSuccess(page: PageCallback) {
    setPdfHeightSize(page.height);
  }

  function onPageLoadError() {}

  // 현재 페이지 또는 마지막 페이지를 결정하는 로직
  const displayPageNumber = useMemo(
    () => (currentPage !== null ? (currentPage > 0 ? currentPage : numPages) : null),
    [currentPage, numPages]
  );

  const onMouseDown = (e) => {
    setDragStartX(e.clientX);
  };

  const onMouseUp = (e) => {
    const dragEndX = e.clientX;
    const dragDistance = dragStartX - dragEndX;

    // 페이지 변경 로직
    if (dragDistance > 50) {
      setCurrentPage((currentPage) => (currentPage < numPages ? currentPage + 1 : 1));
    } else if (dragDistance < -50) {
      setCurrentPage((currentPage) => (currentPage > 1 ? currentPage - 1 : numPages));
    }
  };

  const onTouchStart = (e) => {
    // 터치 시작 지점의 X 좌표를 저장
    const touchStartX = e.touches[0].clientX;
    setDragStartX(touchStartX);
  };

  const onTouchEnd = (e) => {
    // 터치 종료 지점의 X 좌표를 가져옴
    const touchEndX = e.changedTouches[0].clientX;
    const dragDistance = dragStartX - touchEndX;

    // 페이지 변경 로직
    if (dragDistance > 50) {
      // 오른쪽으로 스와이프
      setCurrentPage((currentPage) => (currentPage < numPages ? currentPage + 1 : 1));
    } else if (dragDistance < -50) {
      // 왼쪽으로 스와이프
      setCurrentPage((currentPage) => (currentPage > 1 ? currentPage - 1 : numPages));
    }
  };

  const handlePageChange = useCallback(
    (event) => {
      const page = parseInt(event.target.value, 10);
      if (!page || isNaN(page)) {
        setCurrentPage(null);
      } else if (page >= 1 && page <= numPages) {
        setCurrentPage(page);
      } else if (page > numPages) {
        setCurrentPage(numPages);
      }
    },
    [setCurrentPage, numPages]
  );

  useEffect(() => {
    // 현재 페이지가 변경될 때 인접한 페이지 번호도 업데이트
    setPrevPageNumber(currentPage > 1 ? currentPage - 1 : null);
    setNextPageNumber(currentPage < numPages ? currentPage + 1 : null);
    onPageChanged(displayPageNumber);
  }, [currentPage, numPages, displayPageNumber]);

  useEffect(() => {
    const { width } = pdfRef.current.getBoundingClientRect();
    setPdfWidthSize(width);
  }, [pdfRef.current]);

  const isMobileWidth = useMediaQuery("(max-width: 450px)");

  const labelText = useMemo(() => {
    return isMobileWidth ? title : `${title} 페이지`;
  }, [isMobileWidth]);
  return (
    <Container
      ref={pdfRef}
      width={pdfWidthSize}
      onMouseDown={onMouseDown}
      onMouseUp={onMouseUp}
      onTouchStart={onTouchStart}
      onTouchEnd={onTouchEnd}
    >
      <PageInputContainer>
        <label>{labelText}</label>
        <Section direction="column">
          <TextField
            type="number"
            size={"small"}
            value={displayPageNumber}
            onChange={handlePageChange}
            error={!!errorMessage}
            InputProps={{
              inputProps: { min: 1, max: numPages },
              endAdornment: <span>Page</span>,
            }}
          />
        </Section>
      </PageInputContainer>
      <PdfContainer height={pdfHeightSize}>
        <Document file={file} onLoadSuccess={onDocumentLoadSuccess}>
          {prevPageNumber && (
            <Page pageNumber={prevPageNumber} width={pdfWidthSize} loading={<LoadingSpinner />} />
          )}
          <Page
            pageNumber={displayPageNumber ?? 1}
            onLoadSuccess={onPageLoadSuccess}
            onLoadError={onPageLoadError}
            width={pdfWidthSize}
            loading={<LoadingSpinner />}
          />
          {nextPageNumber && (
            <Page pageNumber={nextPageNumber} width={pdfWidthSize} loading={<LoadingSpinner />} />
          )}
        </Document>
      </PdfContainer>
      <PageLabel>
        {displayPageNumber ?? 1} / {numPages}
      </PageLabel>
      <HorizontalBlank height={52} />
    </Container>
  );
};

const Container = styled.div<{ width: number }>`
  display: flex;
  position: relative;
  /* justify-content: center; */
  align-items: center;
  flex-direction: column;
  flex: 1;
  overflow: hidden;
  position: relative;
  width: ${(props) => props.width}px;
`;

const PageInputContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 14px;
  gap: 8px;
  padding: 0 8px;
  > label {
    color: var(--neutral-color-natural-70, var(--Default-Gray-90, #262626));
    ${FontStyleMap["KR/regular/$KR.CAP.REG.12"]}
  }
  .MuiTextField-root {
    flex: 1;
    > .MuiOutlinedInput-root {
      border-radius: 10px;
      color: var(--neutral-color-natural-60, #434343);
      ${FontStyleMap["KR/regular/$KR.CAP.REG.12"]}
      padding-right: 6px;
      input {
        padding-right: 0;
      }
    }
  }
`;

const PdfContainer = styled.div<{ height: number }>`
  height: ${(props) => props.height}px;
  border-radius: 5px;
  overflow: hidden;
  position: relative;
  display: flex;
  justify-content: center;
`;

const SpinAnimation = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

const LoadingSpinner = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 340px;
  width: 200px;
  position: absolute;
  ::after {
    content: "";
    width: 30px;
    height: 30px;
    border: 4px solid #f3f3f3;
    border-top: 4px solid #3498db;
    border-radius: 50%;
    animation: ${SpinAnimation} 2s linear infinite;
  }
`;

const PageLabel = styled.div`
  display: inline-flex;
  margin-top: 16px;
  display: flex;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 99px;
  opacity: 0.5;
  background: var(--Default-Gray-90, #262626);
  color: #fff;
  ${FontStyleMap["KR/regular/$KR.CAP.REG.12"]}
`;

export default PdfViewer;
