import React from "react";
import { Provider } from "react-redux";
import configureStore from "../redux";
const store = configureStore({});

const ReduxProvider = ({ children }) => {
  return <Provider store={store}>{children}</Provider>;
};

export default ReduxProvider;
