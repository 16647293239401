import { Button, IconButton, Toolbar, Typography } from "@mui/material";
import { Close } from "@material-ui/icons";
import { Appbar } from "../../../../components/molecules/appbar";
import React from "react";

type EditAppbarProps = {
  onCloseModal?: () => void;
  onCompleteEdit?: () => void;
  appBarTitle?: string;
};

export default function EditAppbar({
  onCloseModal,
  onCompleteEdit,
  appBarTitle,
}: EditAppbarProps): JSX.Element {
  return (
    <Appbar>
      <Toolbar
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <IconButton onClick={onCloseModal}>
          <Close />
        </IconButton>
        <Typography
          sx={{
            fontSize: "16px",
            fontWeight: "500",
            color: "#262626",
          }}
        >
          {appBarTitle}
        </Typography>
        <Button
          variant={"contained"}
          sx={{
            background: "#3B82F6",
            borderRadius: "99px",
            padding: "6px 16px",
            minWidth: "unset",
            height: "fit-content",
          }}
          onClick={onCompleteEdit}
        >
          <Typography sx={{ fontSize: "12px" }}>완료</Typography>
        </Button>
      </Toolbar>
    </Appbar>
  );
}
