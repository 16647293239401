import { StandardTextFieldProps, TextField } from "@mui/material";
import React, { Ref, forwardRef } from "react";
interface IInputProps extends StandardTextFieldProps {}
const Input = forwardRef(({ ...props }: IInputProps, ref: Ref<HTMLInputElement>) => {
  return (
    <TextField
      inputRef={ref}
      style={{
        color: "#5A5D6C",
        width: "100%",
        backgroundColor: "#FFF",
      }}
      InputProps={{
        style: {
          fontSize: 14,
        },
        ...props.InputProps,
      }}
      InputLabelProps={{
        style: {
          fontSize: 14,
        },
        ...props.InputLabelProps,
      }}
      id="outlined-size-small"
      size="small"
      {...props}
    />
  );
});

export default Input;
