import style from "../../asset/style"

const Reply_Style = {
    t_btn_w : {
        display:"flex",
        alignItems:"center",
        position : 'absolute',
        height:"50px",
        right : 10,
        top : 12,
        fontSize : "14px",
        color  : style.common.t_color,
        fontWeight : "bold",
    },
    text_1 : {
        padding : "15px 0px 5px 0px",
        fontSize : "14px",
    },
    section_c : {
        display:"flex",
        padding : "10px 0px",
        // borderBottom : "1px solid",
        // borderColor : "rgb(200,200,200)",
    },
    section_l_w : {
        display:"flex",
        alignItems:"flex-start",
        marginRight : "10px",
    },
    section_r_w : {
        display:"flex",
        flex:1,
        flexDirection:"column",
    },
    section_i : {
        width : "40px",
        height:"40px",
    },
    section_t_w : {
        fontSize : "14px",
        fontWeight:"bold",
        color : style.common.fontColor_1
    },
    section_m_w : {
        display:"flex",
        alignItems:"center",
        justifyContent:"space-between",
        fontSize : "11px",
        padding : "3px 0px"
    },
    section_b_w : {
        display:"flex",
        alignItems:"center",
        fontSize : "14px",
        padding : "5px 0px",
    },
}
export default Reply_Style