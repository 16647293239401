import {useState, useEffect} from 'react'
import {useNavigate, useParams} from 'react-router'
import aquery from '../../asset/Img/aquery.png'
import Academy_main_Style from './Academy_main_Style'
import UiSilde from '../../uiComponents/UiSilde/UiSilde'
import UiSorting from '../../uiComponents/UiSorting/UiSorting'
import constants from "../../asset/constants";
import ClassListSection from "./ClassListSection";
import API from "../../api";

const Academy_class = ({
                           isTeacher,
                           userInfo,
                           scrollKey,
                           setScrolllKey,
                       }) => {

    let navigate = useNavigate()
    let params = useParams();
    let sort = [
        {name: "개설순", value: "reg_date"},
        {name: "이름순", value: "name"},
        {name: "수강인원순", value: "member"},
    ]

    const [classList, setClassList] = useState([]);
    const [isLoad, setIsLoad] = useState(false);
    const [classroomList, setClassroomList] = useState([]);
    const [originalClassData, setOriginalClassData] = useState([])

    const handleSortChange = (sort) => {
        setClassList(prev => {
            let sortData = [...prev]
            if (sort === 'name') sortData = sortData.sort((a, b) => a.class_name.localeCompare(b.class_name));
            else if (sort === 'member') sortData = sortData.sort((a, b) => b.student_count - a.student_count);
            else if (sort === 'reg_date') sortData = sortData.sort((a, b) => b.class_reg_date - a.class_reg_date);
            return sortData
        })
    }
    const filterClassInClassroom = (classroomId) => {
        const classIncludedClassroom = originalClassData.filter((classInfo) => classInfo.pid === classroomId);
        setClassList(classIncludedClassroom)
    }
    useEffect(() => {
        const getUserJoinClassList = async () => {
            const userClassList = await API.getUserClassList(userInfo.id,'classType=1&isClassroomRequired=true')
            const classroomDataList = userClassList.filter((classroom, index, callback) => index === callback.findIndex((classroomData) => classroomData.pid === classroom.pid))
            setClassList(userClassList)
            setOriginalClassData(userClassList);
            setClassroomList(classroomDataList.map(classroom => {
                return {id: classroom.pid, name: classroom.classroom_name}
            }))
            setIsLoad(true)
        }
        getUserJoinClassList();
    }, [])

    return (
        <div style={Academy_main_Style.c}>
            <UiSilde
                data={classroomList}
                apiPro={(v) => {
                    filterClassInClassroom(v.id)
                }}
            />
            {
                isLoad &&
                (
                    classList.length > 0
                        ?
                        <div style={{
                            padding: "0px 15px 0px 15px",
                        }}>
                            <UiSorting
                                title='강의 '
                                cnt={classList.length + "개"}
                                data={sort}
                                isTeacher={isTeacher}
                                fnClick={(e) => {
                                    handleSortChange(e)
                                }}
                            />
                            <ClassListSection
                                data={classList}
                                setScrolllKey={setScrolllKey}
                                scrollKey={scrollKey}
                            />
                        </div>
                        :
                        (
                            params["who"] == "teacher" &&
                            <div style={Academy_main_Style.searchList_empty_c}>
                                <img src={aquery}
                                     style={Academy_main_Style.searchList_empty_img}
                                />
                                <div style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center",
                                    paddingTop: "20px"
                                }}>
                                    <div>
                                        담당중인 수업이 없습니다.
                                    </div>
                                    <div>
                                        가입된 클래스룸에서 수업을 직접 개설하시거나,
                                    </div>
                                    <div>
                                        수업 관리자에게 가입을 신청해보세요.
                                    </div>
                                </div>
                            </div>
                        )
                )
            }
        </div>
    )
}

export default Academy_class