import { React, useEffect, useState } from 'react';
import { Frame } from '../page_index';
import { useNavigate, useParams } from 'react-router';
import Academy_book_Style from './Academy_book_Style';
import UiTextBtn from '../../uiComponents/UiTextBtn/UiTextBtn';
import style from '../../asset/style';
import UiBook from '../../uiComponents/UiBook/UiBook';
import aquery from '../../asset/Img/aquery.png'
import answer from '../../asset/Img/answer.png'
import UiHiqDescBox from '../../uiComponents/UiHiqDescBox/UiHiqDescBox';
import { UiInput_title } from '../../uiComponents/UiInput/UiInput';
import UiBtn from '../../uiComponents/UiBtn/UiBtn';
import Academy_book_List_Style from './Academy_book_List_Style';
import UiConfirm from '../../uiComponents/UiConfirm/UiConfirm';
import UiTopPopTextBtn from '../../uiComponents/UiTopPopTextBtn/UiTopPopTextBtn';
import constants from '../../asset/constants';
import apiFn from '../../asset/apiClass';
import util from '../../asset/util';
import store from "../../asset/store";

const Academy_book_List = ({
    userInfo,
    setS_BookList
}) => {
    let params = useParams();
    let navigate = useNavigate();

    const [isTeacher ,setIsTeacher] = useState(true);

    var [bookList,setBookList] = useState([])

    var [selectData_book,setSelectData_book] = useState({})
    var [selectData_book_l,setSelectData_book_l] = useState(0)

    const [isEdit , setIsEdit] = useState(false);
    const [isBookEdit,setIsBookEdit] = useState(false)

    const [name , setName] = useState("")

    const [title,setTitle] = useState("");


    // 교재 이름 변경
    const apiPro_modi = async (name) => {
        try {
            // console.log(name)
            let isOk = await apiFn.comApi({
                state : {
                    table : "book",
                    set: [
                        {
                            key: `id:${Object.keys(selectData_book)[0]}`,
                            value: { name : name }
                        }
                    ]
                }
            })

            window.alert("변경되었습니다.")
            setIsBookEdit(false)
            setSelectData_book({})
            await apiPro_read();

        }
        catch(e){
        }
    }


    // 교재 리스트
    const apiPro_read = async () => {
        try {
            // console.log("@@@@@@@@@ ")
            let isOk;
            // let fid_value =
            if(params["who"] == "teacher"){
                let where_obj = {
                    "HIQDB_book_link.mid" : `= '${userInfo["id"]}'`,
                    "HIQDB_book.status" : `!= '9'`,
                    // "HIQDB_book_link.cid" : `!= 0`,
                }
                if(params["where"] == "default"){
                    where_obj["HIQDB_book_link.fid"] = `= '${ params["id"]}'`
                }
                if(params["where"] == "list"){
                    where_obj["HIQDB_book_link.fid"] = `= '${params["id"]}'`
                }
                isOk = await apiFn.comApi({
                    state : {
                        table : "book",
                        page : 1,
                        amount : 999,
                        u_id : userInfo["id"],
                        sort : "reg_date desc",
                        ref : [
                            'HIQDB_book_link.cid|HIQDB_book_link.bid=HIQDB_book.id',
                        ],
                        where : where_obj

                    }
                })
            }
            else {
                isOk = await apiFn.studentBooklist({
                    state : {
                        sid : userInfo["id"],
                        page : 1,
                        amount : 9999,
                        sort : "reg_date desc",
                        fid : params["id"],
                    }
                })
                // console.log("### ",isOk);
            }

            setBookList([
                ...isOk["list"]
            ])
        }
        catch(e){
            // console.log("** => ",e )
        }
    }

    const apiPro_read_folder = async () => {
        try {
            let isOk = await apiFn.comApi({
                state : {
                    table : "folder",
                    page : 1,
                    sort : "name desc",
                    amount : 9999,
                    where : {
                        "HIQDB_folder.id" : `= '${params["id"]}'`
                    }
                }
            })
            setTitle(isOk["list"][0]["name"])
        }
        catch(e){
        }
    }

    const apiPro_modi_folder = async () => {
        try {
            let isOk = await apiFn.comApi({
                state : {
                    table : "folder",
                    set: [
                        {
                            key: `id:${params["id"]}`,
                            value: { name : name }
                        }
                    ]
                }
            })

            window.alert("변경되었습니다.")

            const stack = store.get('stack')
            if(stack.length > 0)
                navigate(-1);
            else
                navigate('/')

        }
        catch(e){
        }
    }


    // 교재 삭제
    const apiPro_del = async ( pw , bid) => {
        try {
            // let isLogin = await apiFn.fnSignChk({
            //     state : {
            //         id : userInfo["id"],
            //         pw : pw
            //     }
            // })

            // // console.log("isLogin -=> ",isLogin)
            // if(!isLogin)return;
            let bid_array = Object.keys(selectData_book).reduce((a,v,i)=>{
                return [
                    ...a,
                    {
                        // key : `bid : ${v} and mid : ${userInfo["id"]}`
                        bid : v,
                        mid : userInfo["id"]
                    }
                ]
            },[])

            // console.log("bid_array => " ,bid_array)

            let isOK = await apiFn.comApi({
                state : {
                    table : "book_link",
                    del : bid_array

                }
            })
            setIsBookEdit(false)
            setSelectData_book({})
            await apiPro_read();
        }
        catch(e){
            util.c_err_log(`apiPro_del Error => ${e}`)
        }
    }


    useEffect(()=>{
        // console.log("parmas => ",params)
        if(params["who"] == "teacher"){
            setIsTeacher(true)
        }
        else {
            setIsTeacher(false)
        }
    },[params])

    useEffect(()=>{
        selectData_book_l = 0;
        Object.keys(selectData_book).map((v,i)=>{
            if(selectData_book[v] == "Y"){
                selectData_book_l = selectData_book_l + 1
            }
            else if (selectData_book[v] == "N") {
                if(selectData_book_l == 0)return;
                selectData_book_l = selectData_book_l - 1
            }
        })
        setSelectData_book_l(selectData_book_l)
    },[selectData_book])

    useEffect(()=>{
        apiPro_read_folder();
        apiPro_read();
    },[])

    return (
        <Frame
            title={
                params["where"] == "default" ?
                // "교재___" + params["id"]
                "교재 공유 추가"
                :
                params["where"] == "confirm" ?
                "확인"
                :
                params["where"] == "list" ?
                // params["id"]
                title
                :
                "이름 변경"
            }
            isSearch={false}
            userInfo={userInfo}
        >
            {
            params["where"] == "default" || params["where"] == "list" || params["where"] == "all"  ?
            <>
            {
            (isEdit || isBookEdit) &&
            <UiTopPopTextBtn
                title="편집 취소"
                isTeacher={isTeacher}
                fnClick={()=>{
                    setIsBookEdit(false)
                    setSelectData_book({})
                }}
            />
            }
            <div style={{
                padding : "0px 10px",
                width : "100%",
            }}>
                <div style={{
                    display : "flex",
                    width : "100%",
                }}>
                    {
                    isBookEdit &&
                    (
                    selectData_book_l > 0 &&
                    <div style={{
                        ...Academy_book_Style.edit_section_w,
                        width : "100%",
                        padding : "15px 0px",
                        borderTop: isBookEdit ? "none" : "1px solid",
                        borderColor: style.common.borderColor,
                    }}>
                        {`선택된 교재 ${Object.keys(selectData_book).length }개 `}
                        <div style={Academy_book_Style.textBtn_w}>
                            {
                            isTeacher &&
                            <UiTextBtn
                                text={"삭제"}
                                btnStyle={{
                                    ...Academy_book_Style.textBtn,
                                    marginRight : "7.5px",
                                }}
                                fnClick={()=>{
                                    let isOk = window.confirm(constants.confirm.cf_2)
                                    if(isOk){
                                        // navigate("/academybook/"+params["who"]+"/"+params["id"]+"/"+"confrim")
                                        // let isOk = window.prompt("교재 삭제를 위해 본인확인이 필요합니다.")
                                        // let isOk = window.confirm(`선택된 교재 ${Object.keys(selectData_book).length}개를 삭제하시겠어요?`)
                                        apiPro_del(isOk)
                                    }
                                }}
                            />
                            }
                            <UiTextBtn
                                text={"이동"}
                                btnStyle={{
                                    ...Academy_book_Style.textBtn,
                                    color : style.common.fontColor_1,
                                    marginLeft : "7.5px"
                                }}
                                fnClick={()=>{
                                    let array = Object.keys(selectData_book).reduce((a,v,i)=>{
                                        return [
                                            ...a,
                                            v
                                        ]
                                    },[])
                                    setS_BookList([
                                        ...array
                                    ])
                                    navigate(`/academyfolder/${params["who"]}`)
                                }}
                            />
                            {
                            isTeacher &&
                            selectData_book_l == 1 &&
                            <UiTextBtn
                                text={"이름 변경"}
                                btnStyle={{
                                    ...Academy_book_Style.textBtn,
                                    color : style.common.fontColor_1,
                                    marginLeft : "7.5px"
                                }}
                                fnClick={()=>{
                                    let isOk = window.prompt("변결할 교재의 이름을 입력해주세요.")
                                    if(isOk){
                                        apiPro_modi(isOk)
                                    }
                                }}

                            />
                            }
                        </div>
                    </div>
                    )
                    }
                </div>
                <div style={Academy_book_Style.list_book_w}>
                    {
                    bookList.map((v,i)=>
                    <UiBook
                        data={v}
                        key={"mark_folder_"+i}
                        id={i}
                        navigate={navigate}
                        params={params}
                        // navi={"/academybook/"+params["who"]+"/"+3}
                        navi={
                            params["who"] == "teacher" ?
                            `/book/${params["who"]}/${v["id"]}/0/de`
                            :
                            `/book/${params["who"]}/${v["id"]}/${v["classID"]}/de`
                        }

                        setSelectData={setSelectData_book}
                        selectData={selectData_book}

                        isEdit={isBookEdit}
                        isTeacher={isTeacher}
                        // setIsSearch={setIsSearch}

                        fnLongClick={(data)=>{
                            // setIsSearch(false)
                            setIsBookEdit(true)
                            setSelectData_book({
                                ...selectData_book,
                                [data["id"]] : "Y"
                            })
                        }}
                        fnCheck={(e,data)=>{
                            if(selectData_book[data["id"]] == "Y"){
                                delete selectData_book[data["id"]]
                                setSelectData_book({
                                    ...selectData_book,
                                })
                            }
                            else {
                                setSelectData_book({
                                    ...selectData_book,
                                    [data["id"]] : "Y"
                                })
                            }
                        }}

                    />
                    )}
                </div>
            </div>
            </>
            :
            params["where"] == "cofirm" ?
            <UiConfirm />
            // <div style={Academy_book_List_Style.confrim_c}>
            //     <UiHiqDescBox
            //         isTeacher={isTeacher}
            //         desc1 = "최종 확인을 위해"
            //         desc2 = "비밀번호를 다시 한번 입력해주세요."
            //         desc3 = "비밀번호 입력 후 확인을 누르면"
            //         desc4 = "진행이 완료됩니다."

            //     />
            //     <UiInput_title
            //         inputClass={isTeacher ? "input_t" : "input_c"}
            //         title="비밀번호 확인"
            //         type="basic"
            //     />
            //     <div style={Academy_book_List_Style.confrim_btn_w}>
            //         <UiBtn
            //             btnStyle={{
            //                 color : "white",
            //                 borderColor : ( isTeacher ? style.common.t_color : style.common.s_color )
            //                 ,
            //                 backgroundColor : ( isTeacher ? style.common.t_color : style.common.s_color )

            //             }}
            //             title="확인"
            //             fnClick={()=>{
            //                 window.alert("삭제되었습니다.")
            //                 navigate(-1)
            //             }}
            //         />
            //     </div>
            // </div>
            :
            <div style={Academy_book_List_Style.confrim_c}>
                <UiInput_title
                    title="이름"
                    placeholer="받아서 기입"
                    inputClass={"input_t"}
                    fnChange={(e)=>{
                        setName(e.target.value)
                    }}
                />
                <div style={Academy_book_List_Style.confrim_btn_w}>
                    <UiBtn
                        btnStyle={{
                            color : "white",
                            borderColor : ( isTeacher ? style.common.t_color : style.common.s_color )
                            ,
                            backgroundColor : ( isTeacher ? style.common.t_color : style.common.s_color )

                        }}
                        title="확인"
                        fnClick={()=>{
                            apiPro_modi_folder();
                        }}
                    />
                </div>
            </div>
            }

        </Frame>
    )
}

export default Academy_book_List
