import { useMutation } from "@apollo/client";
import { ThumbUp } from "@material-ui/icons";
import { CircularProgress } from "@mui/material";
import { PaymentContext } from "asset/context";
import { React, useContext, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router";
import Arrow_Left from "../../asset/Img/Arrow_Left.png";
import apiFn from "../../asset/apiClass";
import { getUser } from "../../asset/storage";
import store from "../../asset/store";
import style from "../../asset/style";
import { StudentCancelQuestionDocument } from "../../types/graphql/graphql";
import Message_talk_style from "./Message_talk_style";
import btn_more from "../../asset/Img/btn_more.png";
import { useQuestionSearch } from "../../api/questionSearch";

const btn_style = {
  boxSizing: "border-box",
  width: "115px",
  height: "30px",
  background: "#FFFFFF",
  borderRadius: "5px",
  fontFamily: "Noto Sans",
  fontStyle: "normal",
  fontWeight: 700,
  fontSize: "11px",
  lineHeight: "14px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  letterSpacing: "0.5px",
  marginRight: "8px",
};

const Message_talk_header_type_qna = ({
  isMobum,
  setIsMobum,
  apiPro_understant,
  apiPro_understant_del,
  post_search,
  ans_cancel,
  ans_complete,
  headerData = {},
  isPick,
  userInfo,
  hiddenUrl,
  apiPro_read,
  endWs,
  talkList,
  setIsModal_pick,
  onCancelSuccess,
  solutionList,
  setSolutionList,
  setIsSide,
}) => {
  let params = useParams();
  let navigate = useNavigate();
  const { academyId } = useContext(PaymentContext);

  const [cancelQuestion] = useMutation(StudentCancelQuestionDocument, {
    context: {
      headers: {
        authorization: `Bearer ${getUser()?.jwt}`,
      },
    },
  });
  const [info, setInfo] = useState({});
  const [isInfoLoading, setIsInfoLoading] = useState(false);
  const [searchQuestion, setSearchQuestion] = useState(null);

  const { data: postQuestionResponse, isLoading } = useQuestionSearch({
    userId: userInfo.id,
    userType: userInfo.type,
    option: "exemplary",
    bookTypes: [0],
    limit: Number(5),
    searchData: params["qid"],
    searchType: "question",
    myQuestion: false,
  });

  // 찜 제거
  const apiPro_del_pick = async () => {
    try {
      let isOk = await apiFn.comApi({
        state: {
          table: "folder_link",
          del: [
            {
              qid: params["qid"],
              mid: userInfo["id"],
            },
          ],
        },
      });
      await apiPro_read();
    } catch (e) {}
  };

  // 모범문항 등록/해제
  const apiPro_mobum = async () => {
    try {
      let isOk = await apiFn.comApi({
        state: {
          table: "online_qna",
          set: [
            {
              key: `id:${params["qid"]}`,
              value: {
                mobum_date: !isMobum ? "0" : "del",
              },
            },
          ],
        },
      });
      setIsMobum(!isMobum);
    } catch (e) {
      console.error(e);
    }
  };

  const apiPro_find = async () => {
    try {
      setIsInfoLoading(true);
      let find = await apiFn.comApi({
        state: {
          table: "user_credential",
          page: 1,
          amount: 999,
          sort: "id desc",
          ref: ["A.* |HIQDB_user A on A.index=HIQDB_user_credential.user_id"],
          where:
            params["who"] == "teacher"
              ? {
                  "HIQDB_user_credential.name": `= "${headerData["sid"]}"`,
                }
              : {
                  "HIQDB_user_credential.name": `= "${headerData["tid"]}"`,
                },
        },
      });
      setInfo({
        ...find["list"][0],
      });
    } catch (e) {
      console.error(e);
    } finally {
      setIsInfoLoading(false);
    }
  };

  useEffect(() => {
    if (params?.where !== "post") setSolutionList(null);
  }, [params.where]);

  useEffect(() => {
    if (params["where"] !== "post" && userInfo.id && userInfo.type === 2 && !isLoading)
      setSearchQuestion(postQuestionResponse);

    return () => {
      setSolutionList(null);
    };
  }, [params["where"], postQuestionResponse, isLoading]);

  useEffect(() => {
    apiPro_find();
    if (headerData["mobum_date"]) {
      setIsMobum(true);
    }
  }, [headerData]);

  const solutionListMemo = useMemo(() => {
    if (isLoading) {
      return (
        <>
          중.. <CircularProgress size={12} />
        </>
      );
    }
    if (searchQuestion?.length > 0) {
      const filteredList = searchQuestion.filter((item) => item.score >= 0.95);
      return filteredList.length > 0 ? `결과 ${filteredList.length}+` : "";
    } else {
      return "";
    }
  }, [searchQuestion, isLoading]);

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        backgroundColor: "white",
      }}
    >
      <div
        style={{
          display: "flex",
          height: "100%",
          padding: "10px 5px 10px 10px",
          maxWidth: "50px",
          paddingRight: headerData["bid"] !== 0 ? "10px" : "0px",
          flexGrow: headerData["bid"] !== 0 ? 1 : 0,
          marginRight: "3px",
        }}
      >
        <div style={Message_talk_style.s_1_l_icon}>
          <img
            src={Arrow_Left}
            style={{
              width: "24px",
              height: "24px",
            }}
            onClick={() => {
              endWs();
              const stack = store.get("stack");
              if (window.localStorage.getItem("postImg")) window.localStorage.removeItem("postImg");
              if (params["where"] === "home") {
                navigate(`/message/${params["who"]}/home`);
              } else if (params["where"] === "searchresult") {
                if (stack.length > 0) navigate(-1);
                else navigate("/");
              } else {
                if (hiddenUrl) {
                  navigate(hiddenUrl);
                } else {
                  const stack = store.get("stack");
                  if (stack?.length > 0 && stack[stack.length - 2]?.indexOf("/searchresult") === -1)
                    navigate(-1);
                  else if (
                    stack?.length > 0 &&
                    stack[stack.length - 2]?.indexOf("/searchresult") > -1
                  )
                    navigate(`/message/${params["who"]}/home`);
                  else navigate("/");
                }
              }
            }}
            alt={"문제사진"}
          />
        </div>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          width: "100%",
          paddingRight: "20px",
        }}
      >
        <div
          style={{
            width: "100%",
          }}
        >
          <div
            style={{
              display: "flex",
              width: "100%",
              alignItems: "flex-end",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{
                paddingTop: "15px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "flex-end",
                }}
              >
                <div
                  style={{
                    position: "relative",
                    width: "28px",
                    height: "28px",
                    backgroundColor:
                      headerData["member_cnt"] > 2
                        ? headerData["ans_date"]
                          ? "#EFD9B4"
                          : "#B9B4EF"
                        : headerData["ans_date"]
                          ? !headerData["original"] || headerData["original"] === 0
                            ? "#EFD9B4"
                            : "#EFD9B4"
                          : "#F09294",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    borderRadius: "5px",
                    marginRight: "5px",
                  }}
                >
                  <div
                    style={{
                      fontFamily: "Noto Sans",
                      fontStyle: "normal",
                      fontWeight: 500,
                      fontSize: "14px",
                      lineHeight: "16px",
                      textAlign: "center",
                      color: "#FFFFFF",
                    }}
                  >
                    {headerData["member_cnt"] > 2
                      ? headerData["ans_date"]
                        ? "A"
                        : "G"
                      : headerData["ans_date"]
                        ? !headerData["original"] || headerData["original"] === 0
                          ? "A"
                          : "A+"
                        : !headerData["original"] || headerData["original"] === 0
                          ? "Q"
                          : "Q+"}
                  </div>
                </div>
                <div
                  style={{
                    fontFamily: "Noto Sans",
                    fontStyle: "normal",
                    fontWeight: 500,
                    fontSize: "18px",
                    display: "flex",
                    color: "#3D3A39",
                  }}
                  onClick={() => {
                    if (headerData?.sid) {
                      navigate(
                        `/myinfo/${info.user_type === 1 ? "student" : "teacher"}/${info["id"]}`
                      );
                    }
                  }}
                >
                  {!isInfoLoading && (
                    <>
                      {info?.name && (
                        <>
                          {info.name}
                          <span
                            style={{
                              fontSize: "12px",
                              alignSelf: "self-end",
                              marginBottom: "2px",
                              marginLeft: "5px",
                            }}
                          >
                            {info.user_type === 1 ? "학생" : "선생님"}
                          </span>
                        </>
                      )}
                      {!info?.name && <>모범 등록</>}
                      {headerData.understand !== "n" && (
                        <ThumbUp
                          style={{
                            color: style.common.s_color,
                            fontSize: "20px",
                            marginLeft: "4px",
                            paddingTop: "1px",
                          }}
                        />
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
            <div
              style={{
                marginTop: "5px",
              }}
              onClick={() => {
                setIsSide(true);
              }}
            >
              <img
                style={{
                  height: "18px",
                }}
                src={btn_more}
              />
            </div>
          </div>
          <div
            style={{
              display: "flex",
              width: "100%",
              paddingTop: "10px",
            }}
          >
            {params["who"] === "teacher" ? (
              <>
                <div
                  style={{
                    ...btn_style,
                    background: headerData["ans_date"] ? "#C96264" : "#FFFFFF",
                    filter: "drop-shadow(1px 1px 0px #C02226)",
                    border: headerData["ans_date"] ? "0.5px solid #FFFFFF" : "0.5px solid #C96264",
                    boxShadow: "1px 1px 0px #C96264",
                    color: headerData["ans_date"] ? "#FFFFFF" : "#C96264",
                  }}
                  onClick={() => {
                    if (headerData["ans_date"]) {
                      ans_cancel();
                    } else {
                      ans_complete();
                    }
                  }}
                >
                  {headerData["ans_date"] ? "답변 완료 취소" : "답변 완료"}
                </div>
                {Boolean(headerData["ans_date"]) && (
                  <div
                    style={{
                      ...btn_style,
                      filter: "drop-shadow(1px 1px 0px #B9B4EF)",
                      border: isMobum ? "0.5px solid #FFFFFF" : "0.5px solid #B9B4EF",
                      boxShadow: "1px 1px 0px #B9B4EF",
                      backgroundColor: isMobum ? "#B9B4EF" : "white",
                      color: isMobum ? "white" : "#B9B4EF",
                    }}
                    onClick={() => {
                      apiPro_mobum();
                    }}
                  >
                    {isMobum ? "모범문항 해제" : "모범문항 등록"}
                  </div>
                )}
                {headerData?.is_answer !== 1 && (
                  <>
                    {[1, 2].includes(headerData?.book_type) ? (
                      <div
                        style={{
                          ...btn_style,
                          width: "auto",
                          padding: "0 10px",
                          background: "#FFFFFF",
                          filter: "drop-shadow(1px 1px 0px #F7B865)",
                          border: "0.5px solid #F7B865",
                          boxShadow: "1px 1px 0px #F7B865",
                          color: "#F7B865",
                        }}
                        onClick={() => {
                          if (params.where === "post") {
                            window.localStorage.setItem(
                              "postImg",
                              headerData.original_img === "0"
                                ? headerData.img
                                : headerData.original_img
                            );
                          }
                          post_search("answer");
                        }}
                      >
                        해설 찾기
                      </div>
                    ) : (
                      <div
                        style={{
                          ...btn_style,
                          width: "auto",
                          padding: "0 10px",
                          background: "#FFFFFF",
                          filter: "drop-shadow(1px 1px 0px #F7B865)",
                          border: "0.5px solid #F7B865",
                          boxShadow: "1px 1px 0px #F7B865",
                          color: "#F7B865",
                        }}
                        onClick={() => {
                          if (params.where === "post") {
                            window.localStorage.setItem(
                              "postImg",
                              headerData.original_img === "0"
                                ? headerData.img
                                : headerData.original_img
                            );
                          }
                          post_search();
                        }}
                      >
                        답변 검색 {solutionListMemo}
                      </div>
                    )}
                  </>
                )}
              </>
            ) : (
              <>
                {headerData["ans_date"]
                  ? headerData["member_cnt"] <= 2 &&
                    params["sid"] === userInfo["id"] && (
                      <div
                        style={{
                          ...btn_style,
                          filter: `drop-shadow(1px 1px 0px ${style.common.s_color})`,
                          border:
                            headerData["understand"] === "y"
                              ? "0.5px solid #FFFFFF"
                              : `0.5px solid ${style.common.s_color}`,
                          boxShadow: `1px 1px 0px ${style.common.s_color}`,
                          backgroundColor:
                            headerData["understand"] === "y" ? `${style.common.s_color}` : "white",
                          color:
                            headerData["understand"] === "y" ? "white" : `${style.common.s_color}`,
                        }}
                        onClick={() => {
                          if (headerData["understand"] === "y") {
                            // apiPro_del_pick();
                            apiPro_understant_del();
                          } else {
                            let isOk = window.confirm("해당 질문을 이해하셨어요?");
                            if (!isOk) return;
                            apiPro_understant();
                          }
                        }}
                      >
                        {headerData["understand"] === "y" ? "이해 완료!" : "이해 완료"}
                        {headerData.understand === "n" && (
                          <ThumbUp
                            style={{
                              color: style.common.s_color,
                              fontSize: "16px",
                              marginLeft: "4px",
                              paddingBottom: "3px",
                            }}
                          />
                        )}
                      </div>
                    )
                  : params["who"] !== "teacher" &&
                    params["sid"] === userInfo["id"] &&
                    headerData["member_cnt"] <= 2 && (
                      <div
                        style={{
                          ...btn_style,
                          display:
                            talkList.reduce((a, v) => {
                              if (v["wtype"] == 2 && v.ctype !== 70) {
                                return [...a, v];
                              } else {
                                return [...a];
                              }
                            }, []).length > 0
                              ? "none"
                              : "flex",
                          filter: `drop-shadow(1px 1px 0px #C96264)`,
                          border: "0.5px solid #C96264",
                          boxShadow: "1px 1px 0px #C96264",
                          backgroundColor: "white",
                          color: "#C96264",
                        }}
                        onClick={async () => {
                          let isOk = window.confirm("해당 질문을 취소 하시겠어요?");
                          if (isOk) {
                            try {
                              const variables = {
                                input: {
                                  questionId: parseInt(params["qid"]),
                                  studentId: userInfo["id"],
                                  academyId,
                                },
                              };
                              await cancelQuestion({ variables });
                              onCancelSuccess();
                            } catch (e) {
                              const errorMsg =
                                e?.message ||
                                "취소하는 도중 오류가 발생했습니다. 다시 한번 시도해주세요.";
                              alert(errorMsg);
                            }
                          }
                        }}
                      >
                        {"질문 취소하기"}
                      </div>
                    )}
                <div
                  style={{
                    ...btn_style,
                    filter: "drop-shadow(1px 1px 0px #B9B4EF)",
                    border: isPick ? "0.5px solid #FFFFFF" : "0.5px solid #B9B4EF",
                    boxShadow: "1px 1px 0px #B9B4EF",
                    backgroundColor: isPick ? "#B9B4EF" : "white",
                    color: isPick ? "white" : "#B9B4EF",
                  }}
                  onClick={() => {
                    if (isPick) {
                      apiPro_del_pick();
                    } else {
                      setIsModal_pick(true);
                    }
                  }}
                >
                  {isPick ? "오답노트 해제" : "오답노트"}
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default Message_talk_header_type_qna;
