import { uploadPdfFileToStorage } from "api/fileUpload";
import crypto from "crypto-js";
import constants from "./constants";
import status from "./status";
import store from "./store";
import util from "./util";
// import crypto from 'crypto-js'
// var crypto = require('crypto');

// import { Cookies } from 'react-cookie'

// var cookie = new Cookies

function ApiClass() {
  this.pro_one = () =>
    new Promise((r, j) => {
      let q = "pro_one";
      setTimeout(() => {
        if (q == "!!@") {
          j(new Error("ERROR !"));
        } else {
          r(q);
        }
      }, 3000);
    });

  this.pro_two = () =>
    new Promise((r, j) => {
      let q = "pro_two";
      setTimeout(() => {
        r(q);
      }, 2000);
    });
  this.pro_three = () =>
    new Promise((r, j) => {
      let q = "pro_three";
      setTimeout(() => {
        r(q);
      }, 4000);
    });
  this.providerSignIn = ({ state }) =>
    new Promise((r, j) => {
      util.c_gropulog("providerSignIn", state, "state");
      store
        .axiosPost({
          cat: "hiq",
          cmd: "providerSignIn",
          channelId: constants?.channelId,
          ...state,
        })
        .then((res) => {
          util.c_gropulog("providerSignIn", res, "res");
          if (res) {
            r(res);
          }
        })
        .catch((e) => {
          j(e);
          //// console.log('[providerSignIn] API Error => ',e)
        });
    });
  this.providerSignUp = ({ state }) =>
    new Promise((r, j) => {
      util.c_gropulog("providerSignUp", state, "state");
      store
        .axiosPost({
          cat: "hiq",
          cmd: "providerSignUp",
          channelId: constants?.channelId,
          ...state,
          pw: crypto.enc.Base64.stringify(crypto.SHA256(state["oid"])),
        })
        .then((res) => {
          util.c_gropulog("providerSignUp", res, "res");
          if (res) {
            if (res["status"] === 12) {
              j("해당 전화번호를 사용하여 이미 가입된 회원이 있어요.");
            } else if (res["status"] === 13) {
              j("해당 닉네임을 사용하여 이미 가입된 회원이 있어요.");
            } else {
              r(res);
            }
          }
        })
        .catch((e) => {
          j(e);
          //// console.log('[providerSignUp] API Error => ',e)
        });
    });
  this.bookList = ({ state }) =>
    new Promise((r, j) => {
      util.c_gropulog("bookList", state, "state");
      store
        .axiosPost({
          cat: "hiq",
          cmd: "bookList",
          ...state,
        })
        .then((res) => {
          util.c_gropulog("bookList", res, "res");
          if (res) {
            r(res);
          }
        })
        .catch((e) => {
          j(e);
          //// console.log('[bookList] API Error => ',e)
        });
    });
  this.friendsList = ({ state }) =>
    new Promise((r, j) => {
      util.c_gropulog("friendsList", state, "state");
      store
        .axiosPost({
          cat: "hiq",
          cmd: "friendsList",
          ...state,
        })
        .then((res) => {
          util.c_gropulog("friendsList", res, "res");
          if (res) {
            r(res);
          }
        })
        .catch((e) => {
          j(e);
          //// console.log('[friendsList] API Error => ',e)
        });
    });
  this.studentBooklist = ({ state }) =>
    new Promise((r, j) => {
      util.c_gropulog("studentBooklist", state, "state");
      store
        .axiosPost({
          cat: "hiq",
          cmd: "studentBooklist",
          ...state,
        })
        .then((res) => {
          util.c_gropulog("studentBooklist", res, "res");
          if (res) {
            r(res);
          }
        })
        .catch((e) => {
          j(e);
          //// console.log('[studentBooklist] API Error => ',e)
        });
    });
  this.studentBookFolderSet = ({ state }) =>
    new Promise((r, j) => {
      util.c_gropulog("studentBookFolderSet", state, "state");
      store
        .axiosPost({
          cat: "hiq",
          cmd: "studentBookFolderSet",
          ...state,
        })
        .then((res) => {
          util.c_gropulog("studentBookFolderSet", res, "res");
          if (res) {
            r(res);
          }
        })
        .catch((e) => {
          j(e);
          //// console.log('[studentBookFolderSet] API Error => ',e)
        });
    });
  this.onlineQnaQuery = ({ state }) =>
    new Promise((r, j) => {
      //// console.log(state)
      util.c_gropulog(`compApi ${state["table"]}`, state, "state", false);
      store
        .axiosPost({
          cat: "com",
          cmd: "onlineQnaQuery",
          ...state,
        })
        .then((res) => {
          util.c_gropulog("comApi", res, "res");
          if (res) {
            r(res);
          }
        })
        .catch((e) => {
          j(e);
          //// console.log('[comApi] API Error => ',e)
        });
    });
  this.qnaInvite = ({ state }) =>
    new Promise((r, j) => {
      // console.log(state)
      util.c_gropulog(`qnaInvite ${state["table"]}`, state, "state", false);
      store
        .axiosPost({
          cat: "hiq",
          cmd: "qnaInvite",
          ...state,
        })
        .then((res) => {
          util.c_gropulog("qnaInvite", res, "res");
          if (res) {
            r(res);
          }
        })
        .catch((e) => {
          j(e);
          //// console.log('[qnaInvite] API Error => ',e)
        });
    });
  this.findPeople = ({ state }) =>
    new Promise((r, j) => {
      // console.log(state)
      util.c_gropulog(`findPeople ${state["table"]}`, state, "state", false);
      store
        .axiosPost({
          cat: "hiq",
          cmd: "findPeople",
          ...state,
        })
        .then((res) => {
          util.c_gropulog("findPeople", res, "res");
          if (res) {
            r(res);
          }
        })
        .catch((e) => {
          j(e);
          //// console.log('[findPeople] API Error => ',e)
        });
    });
  this.onlineQnaMsgSet = ({ state }) =>
    new Promise((r, j) => {
      // console.log(state)
      util.c_gropulog(`onlineQnaMsgSet ${state["table"]}`, state, "state", false);
      store
        .axiosPost({
          cat: "hiq",
          cmd: "onlineQnaMsgSet",
          ...state,
        })
        .then((res) => {
          util.c_gropulog("onlineQnaMsgSet", res, "res");
          if (res) {
            r(res);
          }
        })
        .catch((e) => {
          j(e);
          //// console.log('[onlineQnaMsgSet] API Error => ',e)
        });
    });
  this.onlineQnaSet = ({ state }) =>
    new Promise((r, j) => {
      console.log(state);
      util.c_gropulog(`onlineQnaSet ${state["table"]}`, state, "state", false);
      store
        .axiosPost({
          cat: "hiq",
          cmd: "onlineQnaSet",
          ...{
            ...state,
            academyId: constants.academyId,
          },
        })
        .then((res) => {
          util.c_gropulog("onlineQnaSet", res, "res");
          if (res) {
            r(res);
          }
        })
        .catch((e) => {
          j(e);
          //// console.log('[onlineQnaSet] API Error => ',e)
        });
    });
  this.copyQna = ({ state }) =>
    new Promise((r, j) => {
      //// console.log(state)
      util.c_gropulog(`copyQna ${state["table"]}`, state, "state", false);
      store
        .axiosPost({
          cat: "hiq",
          cmd: "copyQna",
          ...state,
        })
        .then((res) => {
          util.c_gropulog("copyQna", res, "res");
          if (res) {
            r(res);
          }
        })
        .catch((e) => {
          j(e);
          //// console.log('[copyQna] API Error => ',e)
        });
    });
  this.friendInfo = ({ state }) =>
    new Promise((r, j) => {
      // //// console.log(state)
      util.c_gropulog(`compApi ${state["table"]}`, state, "state", false);
      store
        .axiosPost({
          cat: "hiq",
          cmd: "friendInfo",
          ...state,
        })
        .then((res) => {
          util.c_gropulog("comApi", res, "res");
          if (res) {
            r(res);
          }
        })
        .catch((e) => {
          j(e);
          //// console.log('[comApi] API Error => ',e)
        });
    });
  this.qnaSubs = ({ state }) =>
    new Promise((r, j) => {
      // util.c_gropulog(`qnaSubs ${state["table"]}`,state,"state",false)
      store
        .axiosPost({
          cat: "hiq",
          cmd: "qnaSubs",
          ...state,
        })
        .then((res) => {
          util.c_gropulog("qnaSubs", res, "res");
          if (res) {
            r(res);
          }
        })
        .catch((e) => {
          // console.log('[qnaSubs] API Error => ',e)
          j(e);
        });
    });
  this.qnaCount = ({ state }) =>
    new Promise((r, j) => {
      util.c_gropulog(`qnaCount ${state["table"]}`, state, "state", false);
      store
        .axiosPost({
          cat: "hiq",
          cmd: "qnaCount",
          ...state,
        })
        .then((res) => {
          util.c_gropulog("qnaCount", res, "res");
          if (res) {
            r(res);
          }
        })
        .catch((e) => {
          // console.log('[qnaCount] API Error => ',e)
          j(e);
        });
    });

  this.qnaList = ({ state }) =>
    new Promise((r, j) => {
      util.c_gropulog(`qnaList ${state["table"]}`, state, "state", false);
      // console.log("QNALIST STATE : ",state);
      store
        .axiosPost({
          cat: "hiq",
          cmd: "qnaList",
          ...state,
        })
        .then((res) => {
          util.c_gropulog("qnaList", res, "res");
          if (res) {
            r(res);
          }
        })
        .catch((e) => {
          // console.log('[qnaList] API Error => ',e)
          j(e);
        });
    });

  this.checkAuthStatus = () =>
    new Promise((resolve, reject) => {
      store
        .axiosPost({
          cat: "auth",
          cmd: "checkAuthStatus",
        })
        .then((response) => {
          resolve(response);
        })
        .catch((e) => {
          reject(e);
        });
    });
  this.checkHP = ({ state }) =>
    new Promise((r, j) => {
      // console.log("checkHP => ",state)
      util.c_gropulog(`checkHP ${state["table"]}`, state, "state", false);
      store
        .axiosPost({
          cat: "etc",
          cmd: "checkHP",
          ...state,
        })
        .then((res) => {
          util.c_gropulog("checkHP", res, "res");
          if (res) {
            if (res["status"] == "40") {
              alert("");
              j("");
            }
            if (res["status"] == 12) {
              // alert("해당 전화번호를 사용하여 이미 가입된 회원이 있어요.");
              const isAddUserCredential = confirm(
                "해당 전화번호를 사용하여 이미 가입된 회원이 있어요. \n 해당 로그인 정보를 추가하시겠습니까?"
              );
              if (isAddUserCredential) {
                r(res);
              } else {
                r(-1);
              }
            } else {
              r(res);
            }
          }
        })
        .catch((e) => {
          j(e);
          //// console.log('[metooSet] API Error => ',e)
        });
    });

  this.deactivateAccount = ({ state }) =>
    new Promise((r, j) => {
      // console.log("checkHP => ",state)
      // util.c_gropulog(`checkHP ${state["table"]}`, state, "state", false);
      store
        .axiosPost({
          cat: "etc",
          cmd: "checkHP",
          ...state,
        })
        .then((res) => {
          // util.c_gropulog("checkHP", res, "res");
          if (res) {
            if (res["status"] == "40") {
              alert("");
              j("");
            } else {
              r(res);
            }
          }
        })
        .catch((e) => {
          j(e);
          //// console.log('[metooSet] API Error => ',e)
        });
    });

  this.exitClass = ({ state }) =>
    new Promise((r, j) => {
      // console.log("exitClass => ",state)
      util.c_gropulog(`exitClass ${state["table"]}`, state, "state", false);
      store
        .axiosPost({
          cat: "hiq",
          cmd: "exitClass",
          ...state,
        })
        .then((res) => {
          util.c_gropulog("exitClass", res, "res");
          if (res) {
            if (res["status"]) {
              let st = res["status"];
              if (st == 30) {
                alert("해당 이벤트에 대한 권한이 없습니다.");
              }
              if (st == 31) {
                alert("클래스룸 내 수업중 관리자인 수업이 있습니다.");
              }
              if (st == 32) {
                alert("해당 수업에 관리자가 없습니다.");
              }
              if (st == 33) {
                alert(
                  "클래스룸 탈퇴 불가 합니다. (클래스룸 관리자는 반드시 한명 이상이 있어야합니다.)"
                );
              }
              j(e);
            } else {
              r(res);
            }
          }
        })
        .catch((e) => {
          j(e);
          //// console.log('[metooSet] API Error => ',e)
        });
    });
  this.exitBook = ({ state }) =>
    new Promise((r, j) => {
      // console.log("exitBook => ",state)
      util.c_gropulog(`exitBook ${state["table"]}`, state, "state", false);
      store
        .axiosPost({
          cat: "hiq",
          cmd: "exitBook",
          ...state,
        })
        .then((res) => {
          util.c_gropulog("exitBook", res, "res");
          if (res) {
            if (res["status"]) {
              let st = res["status"];
              if (st == 30) {
                alert("해당 이벤트에 대한 권한이 없습니다.");
              }
              if (st == 31) {
                alert("클래스룸 내 수업중 관리자인 수업이 있습니다.");
              }
              if (st == 32) {
                alert("해당 수업에 관리자가 없습니다.");
              }
              if (st == 33) {
                alert(
                  "클래스룸 탈퇴 불가 합니다. (클래스룸 관리자는 반드시 한명 이상이 있어야합니다.)"
                );
              }
              j(e);
            } else {
              r(res);
            }
          }
        })
        .catch((e) => {
          j(e);
          //// console.log('[metooSet] API Error => ',e)
        });
    });
  this.metooSet = ({ state }) =>
    new Promise((r, j) => {
      //// console.log(state)
      util.c_gropulog(`metooSet ${state["table"]}`, state, "state", false);
      store
        .axiosPost({
          cat: "hiq",
          cmd: "metooSet",
          ...state,
        })
        .then((res) => {
          util.c_gropulog("metooSet", res, "res");
          if (res) {
            r(res);
          }
        })
        .catch((e) => {
          j(e);
          //// console.log('[metooSet] API Error => ',e)
        });
    });
  this.comApi = ({ state }) =>
    new Promise((r, j) => {
      // console.log("comapi state : ",state);
      // util.c_gropulog(`compApi ${state["table"]}`,state,"state",false)
      if (constants.isAcademy && ["class", "teacher", "student"].includes(state.table)) {
        state.classId = constants.classroomId;
        state.channel = constants.channelId;
      }
      store
        .axiosPost({
          cat: "com",
          cmd: "api",
          ...state,
          table: "HIQDB_" + state["table"],
        })
        .then((res) => {
          util.c_gropulog("comApi", res, "res");
          if (res) {
            r(res);
          }
        })
        .catch((e) => {
          j(e);
          //// console.log('[comApi] API Error => ',e)
        });
    });
  this.memoList = ({ state }) =>
    new Promise((r, j) => {
      // util.c_gropulog("memoList",state,"state")
      store
        .axiosPost({
          cat: "hiq",
          cmd: "memoList",
          ...state,
        })
        .then((res) => {
          // util.c_gropulog("memoList",res,"res");
          if (res) {
            r(res);
          }
        })
        .catch((e) => {
          j(e);
          //// console.log('[memoList] API Error => ',e)
        });
    });
  this.deleteBook = ({ state }) =>
    new Promise((r, j) => {
      // util.c_gropulog("fnStarcreateClassList",state,"state")
      store
        .axiosPost({
          cat: "hiq",
          cmd: "deleteBook",
          ...state,
        })
        .then((res) => {
          util.c_gropulog("deleteBook", res, "res");
          if (res) {
            r(res);
          }
        })
        .catch((e) => {
          j(e);
          //// console.log('[createClass] API Error => ',e)
        });
    });
  this.deleteQna = ({ state }) =>
    new Promise((r, j) => {
      // util.c_gropulog("fnStarcreateClassList",state,"state")
      store
        .axiosPost({
          cat: "hiq",
          cmd: "deleteQna",
          ...state,
        })
        .then((res) => {
          util.c_gropulog("deleteQna", res, "res");
          // console.log('[deleteQna] API res=> ',res)
          if (res["status"]) {
            if (res["status"] == 30) {
              alert("교재를 만든 선생님만 질문을 삭제하실 수 있어요.");
            }
          }
          if (res) {
            r(res);
          }
        })
        .catch((e) => {
          // console.log('[deleteQna] API Error => ',e)
          j(e);
        });
    });
  this.deleteClass = ({ state }) =>
    new Promise((r, j) => {
      // util.c_gropulog("fnStarcreateClassList",state,"state")
      store
        .axiosPost({
          cat: "hiq",
          cmd: "deleteClass",
          ...state,
        })
        .then((res) => {
          util.c_gropulog("deleteClass", res, "res");
          if (res) {
            r(res);
          }
        })
        .catch((e) => {
          j(e);
          //// console.log('[createClass] API Error => ',e)
        });
    });
  this.deleteBoard = ({ state }) =>
    new Promise((r, j) => {
      // util.c_gropulog("fnStarcreateClassList",state,"state")
      store
        .axiosPost({
          cat: "hiq",
          cmd: "deleteBoard",
          ...state,
        })
        .then((res) => {
          util.c_gropulog("deleteBoard", res, "res");
          if (res) {
            r(res);
          }
        })
        .catch((e) => {
          j(e);
          //// console.log('[createClass] API Error => ',e)
        });
    });
  this.deleteTeacher = ({ state }) =>
    new Promise((r, j) => {
      // util.c_gropulog("fnStarcreateClassList",state,"state")
      store
        .axiosPost({
          cat: "hiq",
          cmd: "deleteTeacher",
          ...state,
        })
        .then((res) => {
          util.c_gropulog("deleteTeacher", res, "res");
          if (res) {
            r(res);
          }
        })
        .catch((e) => {
          j(e);
          //// console.log('[createClass] API Error => ',e)
        });
    });
  this.deleteStudent = ({ state }) =>
    new Promise((r, j) => {
      // util.c_gropulog("fnStarcreateClassList",state,"state")
      store
        .axiosPost({
          cat: "hiq",
          cmd: "deleteStudent",
          ...state,
        })
        .then((res) => {
          util.c_gropulog("deleteStudent", res, "res");
          if (res) {
            r(res);
          }
        })
        .catch((e) => {
          j(e);
          //// console.log('[createClass] API Error => ',e)
        });
    });
  this.createClass = ({ state }) =>
    new Promise((r, j) => {
      // util.c_gropulog("fnStarcreateClassList",state,"state")
      store
        .axiosPost({
          cat: "hiq",
          cmd: "createClass",
          ...{
            ...state,
            academy_code: constants.academyId,
          },
        })
        .then((res) => {
          util.c_gropulog("createClass", res, "res");
          if (res) {
            r(res);
          }
        })
        .catch((e) => {
          j(e);
          //// console.log('[createClass] API Error => ',e)
        });
    });
  this.fnStarList = ({ state }) =>
    new Promise((r, j) => {
      util.c_gropulog("fnStarList", state, "state");
      store
        .axiosPost({
          ...state,
        })
        .then((res) => {
          util.c_gropulog("fnStarList", res, "res");
          if (res) {
            r(res);
          }
        })
        .catch((e) => {
          j(e);
          //// console.log('[fnStarList] API Error => ',e)
        });
    });
  this.fnDupID = ({ state }) =>
    new Promise((r, j) => {
      util.c_gropulog("fnDupID", state, "state");
      store
        .axiosPost({
          cat: "hiq",
          cmd: "dupID",
          ...state,
        })
        .then((res) => {
          util.c_gropulog("fnDupID", res, "res");
          if (res["status"] == "11") {
            alert(status.st_11);
          } else {
            r(true);
          }
        })
        .catch((e) => {
          j(false);
          //// console.log('[fnDupID] API Error => ',e)
          // alert(e);
        });
    });
  this.fnNewSignUp = ({ state }) =>
    new Promise((r, j) => {
      util.c_gropulog("fnNewSignUp", state, "state");
      store
        .axiosPost({
          cat: "hiq",
          cmd: "newSignUp",
          ...state,
          pw: crypto.enc.Base64.stringify(crypto.SHA256(state["pw"])),
        })
        .then((res) => {
          util.c_gropulog("fnNewSignUp", res, "res");
          if (res["status"] == "11") {
            alert(status.st_11);
          } else {
            r(true);
          }
        })
        .catch((e) => {
          j(false);
          //// console.log('[fnNewSignUp] API Error => ',e)
          // alert(e);
        });
    });
  this.fnSignChk = ({ state }) =>
    new Promise((r, j) => {
      util.c_gropulog("fnNewSignIn", state, "state");
      store
        .axiosPost({
          cat: "hiq",
          cmd: "newSignIn",
          ...state,
          pw: crypto.enc.Base64.stringify(crypto.SHA256(state["pw"])),
        })
        .then((res) => {
          util.c_gropulog("fnNewSignIn", res, "res");
          if (res) {
            r(res);
          }
        })
        .catch((e) => {
          r(false);
          //// console.log('[fnNewSignIn] API Error => ',e)
          // alert(e);
        });
    });
  this.fnNewSignIn = ({ state }) =>
    new Promise((r, j) => {
      util.c_gropulog("fnNewSignIn", state, "state");
      //// console.log("fnNewSignIn => ",state);
      // alert(crypto.enc.Base64.stringify(crypto.SHA256(state["pw"])))
      store
        .axiosPost({
          cat: "hiq",
          cmd: "newSignIn",
          ...state,
          pw: crypto.enc.Base64.stringify(crypto.SHA256(state["pw"])),
        })
        .then((res) => {
          util.c_gropulog("fnNewSignIn", res, "res");
          if (res) {
            store.set("user", {
              ...res,
            });
            if (res["provider"] == "kakao") {
              window.localStorage.setItem("provider", "kakao");
            } else {
              window.localStorage.setItem("provider", "naver");
            }
            window.localStorage.setItem("user", JSON.stringify(res));
            window.localStorage.setItem(
              "pw",
              crypto.enc.Base64.stringify(crypto.SHA256(state["pw"]))
            );
            // window.localStorage.setItem('autoLogin', store.get('autoLogin').toString());
            r(res);
          }
        })
        .catch((e) => {
          // alert(JSON.stringify(e))
          j(false);
          //// console.log('[fnNewSignIn] API Error => ',e)
          // alert(e);
        });
    });
  this.logout = ({ state }) =>
    new Promise((r, j) => {
      util.c_gropulog("logout", state, "state");
      //// console.log("logout => ",state);
      store
        .axiosPost({
          cat: "hiq",
          cmd: "logout",
          ...state,
        })
        .then((res) => {
          util.c_gropulog("logout", res, "res");
          if (res) {
            r(res);
          }
        })
        .catch((e) => {
          j(false);
          //// console.log('[logout] API Error => ',e)
          // alert(e);
        });
    });
  // this.fnPutQnaImage = ({state}) => new Promise((r, j) => {
  //     try {
  //         util.c_gropulog("putQnaImage", state, "state")
  //         apiFn.uploadFile(`public/qna/${state.name}.jpg`, util.dataURLtoFile(state.img, state.name+'.jpg'))
  //             .then((res) => {
  //                 // //// console.log("res => ",res);
  //                 r(res);
  //                 util.c_gropulog("putQnaImage", res, "res");
  //             })
  //             .catch((e) => {
  //                 j(e)
  //                 //// console.log('[putQnaImage] API Error => ',e)
  //                 // alert(e);
  //             })
  //     } catch (e) {
  //         j(e)
  //         alert(e);
  //         ////////// console.log('[putQnaImage] Error => ',e)
  //     }
  // })
  this.fnHome = ({ state }) =>
    new Promise((r, j) => {
      try {
        util.c_gropulog("fnHome", state, "state");
        store
          .axiosPost({
            cat: "com",
            cmd: "home",
            ...state,
          })
          .then((res) => {
            // //// console.log("res => ",res);
            util.c_gropulog("fnHome", res, "res");
          })
          .catch((e) => {
            j(e);
            //// console.log('[fnHome] API Error => ',e)
            // alert(e);
          });
      } catch (e) {
        j(e);
        alert(e);
        ////////// console.log('[fnVpAnalyze] Error => ',e)
      }
    });
  this.fnMessageList = ({ state }) =>
    new Promise((r, j) => {
      try {
        util.c_gropulog("fnMessageList", state, "state");
        store
          .axiosPost({
            cat: "com",
            cmd: "home",
            ...state,
          })
          .then((res) => {
            util.c_gropulog("fnMessageList", res, "res");
            r(res);
          })
          .catch((e) => {
            j(e);
            //// console.log('[fnMessageList] API Error => ',e)
            // alert(e);
          });
      } catch (e) {
        j(e);
        //// console.log('[fnMessageList] Error => ',e)
        alert(e);
      }
    });
  this.fnMessageList = ({ state }) =>
    new Promise((r, j) => {
      try {
        util.c_gropulog("fnMessageList", state, "state");
        store
          .axiosPost({
            cat: "com",
            cmd: "home",
            ...state,
          })
          .then((res) => {
            util.c_gropulog("fnMessageList", res, "res");
            r(res);
          })
          .catch((e) => {
            j(e);
            //// console.log('[fnMessageList] API Error => ',e)
            // alert(e);
          });
      } catch (e) {
        j(e);
        //// console.log('[fnMessageList] Error => ',e)
        alert(e);
      }
    });

  // ** SAMPLE **

  this.uploadFile = async (fileLocation, file, signal) => {
    const { url: signedUrl } = await (
      await fetch(`${constants.apiUrl}/upload/signedUrl`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ url: fileLocation }),
      })
    ).json();

    const uploadXML = () => {
      return new Promise((resolve, reject) => {
        const xhr = new XMLHttpRequest();
        xhr.open("PUT", signedUrl, true);
        xhr.onload = () => {
          if (xhr.status === 200 && xhr.readyState === XMLHttpRequest.DONE) {
            console.log("업로드를 완료했습니다.", fileLocation);
            resolve(fileLocation);
          } else {
            console.log(xhr);
            alert("업로드에 실패했습니다.");
            reject({ status: xhr.status });
          }
        };

        xhr.onerror = () => {
          console.log(xhr);
          alert("업로드에 실패했습니다.");
          throw new Error(xhr.status);
        };
        xhr.setRequestHeader("Content-Type", "application/octet-stream");
        xhr.send(file);
      });
    };

    return await uploadXML();
  };

  this.uploadFileToStorage = async (bucketName, fileLocation, file) => {
    // Get the signed URL using Axios
    try {
      const result = await uploadPdfFileToStorage({
        bucketName,
        fileLocation,
        file,
      });
      return result;
    } catch (error) {
      console.error(`[ERROR] uploadFileToStorage = ${JSON.stringify(error)}`);
      throw error;
    }
  };
}

var apiFn = new ApiClass();

export default apiFn;
