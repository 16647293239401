import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Paper from "@mui/material/Paper";
import Slide from "@mui/material/Slide";
import useMediaQuery from "@mui/material/useMediaQuery";
import React, { forwardRef } from "react";
import Styles from "./UiSlideModal_Style";

const Transition = forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

export default function UiSlideModal({ open, setOpen, children }) {
  const isMobile = useMediaQuery("(max-width:768px)");
  return (
    <React.Fragment>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        PaperComponent={(props) => (
          <Paper style={isMobile ? Styles.mobileContainer : Styles.container} {...props} />
        )}
        keepMounted
        onClose={() => setOpen(false)}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent>{children}</DialogContent>
      </Dialog>
    </React.Fragment>
  );
}
