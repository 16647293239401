import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import constants from "asset/constants";
import { getUser } from "asset/storage";
import util from "asset/util";
import { AxiosProgressEvent } from "axios";
import { useDispatch } from "react-redux";
import { upload } from "redux/reducers/upload";
import { useClassDetailInfo } from "./classDetail";
import { axiosInstance } from "./common";
interface ClassListResponse {
  cid: number;
  type: number;
  status: number;
  reg_date: number;
  pid: number;
  class_reg_date: number;
  img: string;
  mid: string;
  class_name: string;
  intro: string;
  address: string;
  reg_name: string;
}
export const useClassList = (userId: string) => {
  const classroomIdParam = constants.classroomId ? { classroomId: constants.classroomId } : {};
  return useQuery<ClassListResponse[]>([
    `${constants.apiUrl}/user/${userId}/class`,
    { classType: "1", ...classroomIdParam },
  ]);
};

export interface ClassRegistRequset {
  cid: number;
  type: number;
  video_end: number;
  video_start: number;
  content: string;
  mid?: string;
  title: string;
  video_link: string;
  files: (File | ClassResponseFileItem)[];
  videoFile: File;
  videoFiles: FileList;
  loadVideoUrl: string;
  id: string | number;
  img: string;
  thumbnail_id: string | number;
  thumbnail: string;
}

interface ClassRegistResponse {
  pub: number;
  id: number;
  cid: number;
  video_start: number;
  video_end: number;
  reg_date: number;
  important: string;
  mosaic: string;
  title: string;
  content: string;
  mid: string;
}

export const useClassRegist = (onUploadProgress?: (progressEvent?: AxiosProgressEvent) => void) => {
  const userInfo = getUser();
  const userId = userInfo.id;
  const { mutateAsync: thumbnailRegist } = useThumnailFileRegist();
  const { mutate } = useFileRegist();
  const dispatch = useDispatch();
  return useMutation(async (requestParameters: ClassRegistRequset) => {
    const file_volume: number = requestParameters?.videoFile?.size ?? 0;
    const result = await axiosInstance.post<ClassRegistResponse>(`${constants.apiUrl}/board`, {
      ...requestParameters,
      type: 7,
      mid: userId,
      file_volume,
      academyId: constants.academyId,
    });
    const uploadAny = upload as any;
    const fileObject = util.dataURLtoFile(requestParameters.thumbnail, "thumbnail.jpg");
    const boardId = result?.data?.id;
    await thumbnailRegist({ boardId, file: fileObject });
    if (requestParameters.videoFile) {
      const bucketName =
        process.env.NODE_ENV === "production" && process.env.REACT_APP_BUILD_MODE === "live"
          ? "hiqsum-video"
          : "hiqsum-test-video";
      const fileExtension = requestParameters.videoFile.name.split(".").pop();
      dispatch(
        uploadAny({
          path: `board/${boardId}.${fileExtension}`,
          file: requestParameters.videoFile,
          bucketName,
        })
      )
        .unwrap()
        .catch((e) => {
          alert("비디오 업로드에 실패했습니다");
        });
    }

    requestParameters.files?.map((item) => {
      mutate({
        boardId,
        fileName: item.name,
        cid: requestParameters.cid,
        file: item as File,
      });
    });
    return result;
  });
};

export const useClassUpdate = (
  boardId: number | string,
  onUploadProgress?: (progressEvent?: AxiosProgressEvent) => void
) => {
  const userInfo = getUser();
  const userId = userInfo.id;
  const { mutateAsync: fileUpload } = useFileRegist();
  const { mutateAsync: fileDelete } = useFileDelete();
  const { mutateAsync: thumbnailRegist } = useThumnailFileRegist();
  const { data: originalData } = useClassDetailInfo({ boardId });
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  return useMutation(
    async (requestParameters: ClassRegistRequset) => {
      const file_volume: number = requestParameters?.videoFile?.size ?? 0;
      if (requestParameters.videoFile) {
        requestParameters.video_link = null;
      }
      const result = await axiosInstance.put<ClassRegistResponse>(
        `${constants.apiUrl}/board/${boardId}`,
        {
          ...requestParameters,
          type: 7,
          mid: userId,
          file_volume,
        }
      );
      if (requestParameters.thumbnail) {
        const fileObject = util.dataURLtoFile(requestParameters.thumbnail, "thumbnail.jpg");
        await thumbnailRegist({ boardId, file: fileObject });
      }
      if (requestParameters.videoFile) {
        const uploadAny = upload as any;
        const bucketName =
          process.env.NODE_ENV === "production" && process.env.REACT_APP_BUILD_MODE === "live"
            ? "hiqsum-video"
            : "hiqsum-test-video";

        const fileExtension = requestParameters.videoFile.name.split(".").pop();
        dispatch(
          uploadAny({
            path: `board/${boardId}.${fileExtension}`,
            file: requestParameters.videoFile,
            bucketName,
          })
        )
          .unwrap()
          .catch((e) => {
            alert("비디오 업로드에 실패했습니다");
          });
      }
      const originalFileList = originalData?.getBoard?.boards[0].files;
      const newFileList = requestParameters.files?.filter((item) => {
        return !originalFileList?.find(
          (originalItem) => originalItem.id === (item as ClassResponseFileItem).id
        );
      });

      const deletedFileList = originalFileList?.filter((item) => {
        return !requestParameters.files?.find(
          (originalItem) => (originalItem as ClassResponseFileItem).id === item.id
        );
      });

      await Promise.all(
        newFileList?.map(async (item) => {
          return fileUpload({
            boardId,
            fileName: item.name,
            cid: requestParameters.cid,
            file: item as File,
          });
        })
      );

      await Promise.all(
        deletedFileList?.map(async (item) => {
          return fileDelete({
            boardId,
            fileId: Number(item.id),
          });
        })
      );
      return result;
    },
    {
      onSuccess: async (data, variables, context) => {
        queryClient.invalidateQueries({
          queryKey: ["useClassDetailInfo", { boardId }],
        });
      },
    }
  );
};

interface FileRegistRequset {
  boardId: number | string;
  fileName: string;
  cid: number;
  file: File;
}
export const useFileRegist = () => {
  const dispatch = useDispatch();
  return useMutation(({ boardId, fileName, cid, file }: FileRegistRequset) => {
    const fileLocation = `board/${boardId}/${fileName}`;
    const uploadAny = upload as any;
    const file_volume = file?.size ?? 0;
    const bucketName =
      process.env.NODE_ENV === "production" && process.env.REACT_APP_BUILD_MODE === "live"
        ? "hiqsum-upload"
        : "hiqsum-test-upload";
    dispatch(uploadAny({ path: fileLocation, file, bucketName }))
      .unwrap()
      .catch((e) => {});
    return axiosInstance.post(`/board/${boardId}/file`, {
      origin: fileName,
      name: fileLocation,
      cid,
      file_volume,
      academyId: constants.academyId,
    });
  });
};

export const useThumnailFileRegist = () => {
  const dispatch = useDispatch();
  return useMutation(({ boardId, file }: { boardId: number | string; file: File }) => {
    const fileLocation = `board/${boardId}.jpg`;
    const uploadAny = upload as any;
    const bucketName =
      process.env.NODE_ENV === "production" && process.env.REACT_APP_BUILD_MODE === "live"
        ? "hiqsum-upload"
        : "hiqsum-test-upload";
    return dispatch(uploadAny({ path: fileLocation, file, bucketName }))
      .unwrap()
      .catch((e) => {});
  });
};

export const useFileDelete = () => {
  return useMutation(({ boardId, fileId }: { fileId: number; boardId: number | string }) => {
    return axiosInstance.delete(`${constants.apiUrl}/board/${boardId}/file/${fileId}`);
  });
};

export interface ClassUpdateRespnse {
  id: number;
  video_start: number;
  video_end: number;
  cat_id: number;
  cid: number;
  reg_date: number;
  pub: number;
  bid: number;
  aid: number;
  aaid: number;
  mid_index: number;
  title: string;
  important: string;
  content: string;
  video_link: string;
  mid: string;
  mosaic: string;
  img: string;
  files: ClassResponseFileItem[];
}

export interface ClassResponseFileItem {
  id?: number | string;
  cid?: number;
  bid?: number;
  name?: string;
  origin?: string;
}
