import React, { Suspense } from "react";
import UiLoading from "uiComponents/UiLoading/UiLoading";

const RootSuspense = ({ children }) => {
  return (
    <Suspense
      fallback={
        <UiLoading
          isTransparent={true}
          isText={false}
          isCircle={false}
          text="로딩중"
        />
      }
    >
      {children}
    </Suspense>
  );
};

export default RootSuspense;
