import React, { useContext, useEffect, useCallback, useState } from "react";
import { useNavigate } from "react-router";
import { useSearchParams, Link } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { PaymentContext } from "asset/context";
import { ValidatePaymentDocument } from "types/graphql/graphql";
import UiLoading from "uiComponents/UiLoading/UiLoading";

export default () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { setForceUpdate } = useContext(PaymentContext);
  const code = searchParams.get("code");
  const message = searchParams.get("message");
  const paymentId = searchParams.get("paymentId");
  const transactionType = searchParams.get("transactionType");
  const txId = searchParams.get("txId");

  const [
    validateOrder,
    {
      data: validateOrderData,
      loading: validateOrderLoading,
      error: validateOrderError,
    },
  ] = useMutation(ValidatePaymentDocument);

  useEffect(() => {
    if (paymentId) {
      validateOrder({
        variables: {
          orderId: paymentId,
        },
      });
    }
    // (code, message) exist => problem occured during transaction
    // But, validate result by sending paymentId to server
  }, [code, message, paymentId, validateOrder]);

  useEffect(async () => {
    if (!!validateOrderData) {
      const success = validateOrderData?.validatePayment?.success || false;
      const result = validateOrderData?.validatePayment?.result;
      const error = validateOrderData?.validatePayment?.error?.message;
      const alertMsg = message || result.message || error;
      const virtualAccount = result?.virtualAccount;

      // console.log(
      //   `message:${message}, success:${success}, result:${result}, error:${error}, alertMsg:${alertMsg}`
      // );
      alert(alertMsg);
      setForceUpdate(new Date().getTime());
      if (virtualAccount) {
        navigate(`/myinfo/order/${paymentId}`) ;
      } else {
        navigate("/myinfo");
      }
    }
  }, [
    validateOrderData,
    validateOrderError,
    navigate,
    setForceUpdate,
    code,
    message,
  ]);

  return <UiLoading isText={false} text="" isTransparent={true} />;
};
