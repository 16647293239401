import React, {useCallback, useEffect, useRef, useState} from 'react'

const Draggable = ({children}) => {
    const draggable = useRef(null)
    const [mouseCoord, setMouseCoord] = useState(null)
    const [deltaCoord, setDeltaCoord] = useState(null)

    const handleDown = (e) => {
        let clientX, clientY
        if(e.changedTouches) {
            clientX = e.changedTouches[0].pageX
            clientY = e.changedTouches[0].pageY

        } else {
            clientX = e.clientX
            clientY = e.clientY
        }
        setMouseCoord({x: clientX, y: clientY})
        // e.preventDefault()
    }

    const handleMove = useCallback((e) => {
        let clientX, clientY
        if (e.changedTouches) {
            clientX = e.changedTouches[0].pageX
            clientY = e.changedTouches[0].pageY
        } else {
            clientX = e.clientX
            clientY = e.clientY
        }
        if (mouseCoord && !deltaCoord) {
            const {x, y} = mouseCoord
            setDeltaCoord({x: clientX - x, y: clientY - y})
        } else {
            // console.log(draggable, e.changedTouches[0])
        }
        e.preventDefault()
    }, [mouseCoord])

    const handleUp = useCallback((e) => {
        if (deltaCoord) {
            setMouseCoord(null)
        }
    }, [deltaCoord])

    useEffect(() => {
        if(draggable?.current) {
            draggable.current.addEventListener('mousedown', handleDown)
            draggable.current.addEventListener('mousemove', handleMove)
            draggable.current.addEventListener('mouseup', handleUp)

            draggable.current.addEventListener('touchstart', handleDown)
            draggable.current.addEventListener('touchmove', handleMove)
            draggable.current.addEventListener('touchend', handleUp)
        }

        return () => {
            try {
                draggable.current.removeEventListener('mousedown', handleDown)
                draggable.current.removeEventListener('mousemove', handleMove)
                draggable.current.removeEventListener('mouseup', handleUp)


                draggable.current.removeEventListener('touchstart', handleDown)
                draggable.current.removeEventListener('mousemove', handleMove)
                draggable.current.removeEventListener('touchend', handleUp)
            } catch (e) {

            }
        }
    }, [draggable, mouseCoord])

    return <div ref={draggable}
                style={deltaCoord ? {transform: `translate(${deltaCoord.x}px, ${deltaCoord.y}px)`} : null}>
        {children}
    </div>
}

export default Draggable
