import { Add } from "@material-ui/icons";
import { React, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import empty_folder from "../../asset/Img/empty-folder.png";
import apiFn from "../../asset/apiClass";
import store from "../../asset/store";
import style from "../../asset/style";
import util from "../../asset/util";
import Basic from "../../common/frame/Basic";
import UiBtn from "../../uiComponents/UiBtn/UiBtn";
import { UiCheckBox } from "../../uiComponents/UiCheckBox/UiCheckBox";
import UiExplain from "../../uiComponents/UiExplain/UiExplain";
import UiFolder from "../../uiComponents/UiFolder/UiFolder";
import UiBasicSelect from "../../uiComponents/UiSelect/UiBasicSelect";
import UiTextBtn from "../../uiComponents/UiTextBtn/UiTextBtn";
import UiTopPopTextBtn from "../../uiComponents/UiTopPopTextBtn/UiTopPopTextBtn";
import Academy_book_Style from "../Academy/Academy_book_Style";
import { Frame } from "../page_index";
import Message_Mark_List_Style from "./Message_Mark_List_Style";
import Message_Mark_Style from "./Message_Mark_Style";

const Message_Mark_List = ({ userInfo, setHiddenUrl, setCreateObj, scrollKey, setScrolllKey }) => {
  let params = useParams();
  let navigate = useNavigate();

  const [isEdit, setIsEdit] = useState(false);
  const [isTeacher, setIsTeacher] = useState(false);
  const [isAll, setIsAll] = useState(false);

  var [selectData, setSelectData] = useState({});
  var [selectData_l, setSelectData_l] = useState(0);

  var [folderInfo, setFolderInfo] = useState({});
  var [messageList, setMessageList] = useState([]);

  var [folderList, setFolderList] = useState([]);

  // 폴더 정보
  const apiPro_read = async () => {
    try {
      let data = await apiFn.comApi({
        state: {
          table: "folder",
          page: 1,
          amount: 9999,
          sort: "name desc",
          where: {
            "HIQDB_folder.id": `='${params["id"]}'`,
            "HIQDB_folder.ftype": `= '1'`,
          },
        },
      });

      setFolderInfo({
        ...data["list"][0],
      });
      // console.log(data["list"])
    } catch (e) {
      // console.log(e)
    }
  };

  // 질문 목록
  const apiPro_read_2 = async (data) => {
    try {
      // let data = await apiFn.comApi({
      //     state : {
      //         table : "folder_link",
      //         page : 1,
      //         amount : 9999,
      //         sort : "reg_date",
      //         where : {
      //             "HIQDB_folder_link.fid" : `= '${params["id"]}'`
      //         },
      //     }
      // })
      // let array = []
      // data.mpa((v,i)=>{

      // })

      let data = await apiFn.qnaList({
        state: {
          id: userInfo["id"],
          fid: params["id"],
          page: 1,
          amount: 9999,
          sort: "reg_date desc",
          // ...add_state
        },
      });

      // return;
      // let array = data["list"].reduce((a,v,i)=>{
      //     // console.log("###### ",v);
      //     return ([
      //         ...a,
      //         {
      //             ...v["qna"],
      //             qid : v["qna"]["id"],
      //             bookName : v["bookName"],
      //             id : v["qna"]["id"],
      //             tid : v["qna"]["tid"],
      //             sid : v["qna"]["sid"],
      //             // senderName : v["qna"]["studentName"],
      //             senderName : v["qna"]["teacherName"],
      //             senderNick : v["qna"]["studentNick"],
      //             content : v["qna"]["content"],
      //             reg_date : v["qna"]["reg_date"],
      //             qna_date : v["qna"]["qna_date"],
      //             // studentName : v["studentName"],
      //             // senderNick : v["studentNick"],
      //             // senderName : v["teacherName"],

      //         }
      //     ])
      // },[])

      // console.log("data => ",data["list"])
      // console.log("data => ",data["list"])
      // console.log("data => ",data["list"])
      // console.log("data => ",data["list"])
      setMessageList([...data["list"]]);
    } catch (e) {
      // console.log("err ",e)
    }
  };

  // 최근 질문 목록
  const apiPro_recent = async (amount) => {
    try {
      let data = await apiFn.comApi({
        state: {
          table: "online_qna_log",
          page: 1,
          amount: amount ? amount : 10,
          sort: "reg_date desc",
          where: {
            "HIQDB_online_qna_log.wid": `= '${userInfo["id"]}'`,
            "HIQDB_online_qna_log.ltype": `= '1'`,
          },
        },
      });
      // let data = await apiFn.qnaList({
      //     state : {
      //         id : userInfo["id"],
      //         page : 1,
      //         amount : 10,
      //         sort : "recent_date desc",
      //         recent : "Y"
      //         // status : status
      //     }
      // })

      // console.log("data => ",data)
      let qnaArray = data["list"].map((v, i) => v["qid"]);

      // console.log("qnaArray => ",qnaArray);

      // return;
      let qnaData = await apiFn.qnaList({
        state: {
          id: userInfo["id"],
          qid: qnaArray,
          page: 1,
          amount: amount ? amount : 10,
          sort: "recent_date desc",
          recent: userInfo["id"],
        },
      });

      setMessageList([...qnaData["list"]]);

      console.log("amount : ", amount);
      console.log("qnaData : ", qnaData);

      // let array = data["list"].reduce((a,v,i)=>{
      //     if(v["qna"]["id"]){
      //         return ([
      //             ...a,
      //             {
      //                 qid : v["qna"]["id"],
      //                 tid : v["qna"]["tid"],
      //                 sid : v["qna"]["sid"],
      //                 title : v["qna"]["title"],
      //                 senderName : v["qna"]["studentName"],
      //                 senderNick : v["qna"]["senderNick"],
      //                 content : v["qna"]["content"],
      //                 reg_date : v["qna"]["reg_date"],
      //                 original : v["qna"]["original"],
      //                 original_img : v["qna"]["original_img"],
      //                 ans_date : v["qna"]["ans_date"],
      //                 mobum_date : v["qna"]["mobum_date"],
      //                 bookName : v["bookName"]
      //             }
      //         ])
      //     }
      //     else {
      //         return a
      //     }
      // },[])
      // // console.log(array)
      // setMessageList([
      //     ...array
      // ])
    } catch (e) {
      // console.log(e)
    }
  };

  const apiPro_modi = async (name) => {
    try {
      let isOk = await apiFn.comApi({
        state: {
          table: "folder",
          set: [
            {
              key: `id:${params["id"]}`,
              value: { name: name },
            },
          ],
        },
      });

      await apiPro_read();
    } catch (e) {}
  };

  const apiPro_del_pick = async () => {
    try {
      // let del = Object.keys(selectData).reduce((a,v,i)=>{
      //     return [
      //         ...a,
      //         {
      //             fid : params["id"],
      //             qid : v
      //         }
      //     ]
      // },[])

      // let isOk = await apiFn.comApi({
      //     state : {
      //         table : "folder_link",
      //         del : del
      //     }
      // })

      let promiseArray = Object.keys(selectData).map((v, i) =>
        apiFn.comApi({
          state: {
            table: "folder_link",
            del: [
              {
                fid: params["id"],
                qid: v,
                mid: userInfo["id"],
              },
            ],
          },
        })
      );
      await Promise.all(promiseArray);

      await apiPro_read_2();
      //
      const stack = store.get("stack");
      if (stack.length > 0) navigate(-1);
      else navigate("/");
    } catch (e) {}
  };

  const apiPro_del = async () => {
    try {
      let isOk = await apiFn.comApi({
        state: {
          table: "folder",
          del: [
            {
              "HIQDB_folder.id": params["id"],
            },
          ],
        },
      });

      const stack = store.get("stack");
      if (stack.length > 0) navigate(-1);
      else navigate("/");
    } catch (e) {
      // console.log("del Error => ",e)
    }
  };

  // 폴더 리스트
  const apiPro_read_folder = async () => {
    try {
      let data = await apiFn.comApi({
        state: {
          table: "folder",
          page: 1,
          amount: 9999,
          sort: "name desc",
          where: {
            "HIQDB_folder.mid": `='${userInfo["id"]}'`,
            "HIQDB_folder.ftype": `= '1'`,
          },
        },
      });

      setFolderList([...data["list"]]);
    } catch (e) {}
  };

  const apiPro_pick = async (data) => {
    try {
      let set = Object.keys(selectData).reduce((a, v, i) => {
        return [
          ...a,
          {
            key: `fid:${params["id"]}' and qid='${v}`,
            value: {
              fid: data["id"],
              qid: v,
            },
          },
        ];
      }, []);
      // console.log(set)
      let isOk = await apiFn.comApi({
        state: {
          table: "folder_link",
          set: set,
        },
      });

      const stack = store.get("stack");
      if (stack.length > 0) navigate(-1);
      else navigate("/");
    } catch (e) {}
  };

  const all_select = () => {
    try {
      let obj = {};
      messageList.map((v, i) => {
        if (isAll) {
          obj[v["id"]] = "N";
          setIsAll(false);
        } else {
          obj[v["id"]] = "Y";
          setIsAll(true);
        }
      });
      setSelectData({
        ...obj,
      });
    } catch (e) {}
  };

  useEffect(() => {
    if (params["who"] == "teacher") {
      setIsTeacher(true);
    } else {
      setIsTeacher(false);
    }
  }, [params]);

  useEffect(() => {
    selectData_l = 0;
    Object.keys(selectData).map((v, i) => {
      if (selectData[v] == "Y") {
        selectData_l = selectData_l + 1;
      } else if (selectData[v] == "N") {
        if (selectData_l == 0) return;
        selectData_l = selectData_l - 1;
      }
    });
    setSelectData_l(selectData_l);
  }, [selectData]);

  useEffect(() => {
    if (params["form"] == "default") {
      setSelectData([]);
      setSelectData_l(0);
      setIsEdit(false);
      apiPro_read();
      apiPro_read_2();
    } else if (params["form"] == "recent") {
      apiPro_recent();
    } else if (params["form"] == "move") {
      apiPro_read_folder();
    }
  }, [params["form"]]);

  return (
    <>
      {params["form"] == "move" ? (
        <Basic title={"즐겨찾기 폴더 선택"}>
          <div style={Message_Mark_Style.list_w}>
            {folderList.map((v, i) => (
              <UiFolder
                fnClick={(data) => {
                  apiPro_pick(data);
                }}
                data={v}
              />
            ))}
          </div>
        </Basic>
      ) : (
        <Frame
          // isFooter={false}
          userInfo={userInfo}
          navi={-1}
          title={params["form"] == "recent" ? "최근 본 질문" : folderInfo["name"]}
          isSearch={false}
        >
          {params["form"] == "default" || params["form"] == "recent" ? (
            <>
              {isEdit && (
                <UiTopPopTextBtn
                  title="편집 취소"
                  isTeacher={isTeacher}
                  fnClick={() => {
                    setIsEdit(false);
                  }}
                />
              )}
              <div
                style={{
                  ...Message_Mark_List_Style.c,
                  padding: "15px 0px 0px 0px",
                  marginTop: 0,
                }}
              >
                <div
                  style={{
                    marginLeft: "10px",
                    marginBottom: "10px",
                  }}
                >
                  <UiBasicSelect
                    defaultValue={
                      window.localStorage.getItem("s_result_amount")
                        ? JSON.parse(window.localStorage.getItem("s_result_amount"))
                        : "5"
                    }
                    data={[
                      { name: "5", value: "5" },
                      { name: "10", value: "10" },
                      { name: "20", value: "20" },
                      { name: "50", value: "50" },
                    ]}
                    fnChange={(v) => {
                      apiPro_recent(v);
                      window.localStorage.setItem("s_result_amount", v);
                    }}
                    title={
                      // window.localStorage.getItem("s_result_amount") ?
                      //     JSON.parse(window.localStorage.getItem("s_result_amount"))
                      //     :
                      "결과 수"
                    }
                    c_style={{
                      minWidth: "100px",
                      marginRight: "10px",
                      fontSize: "10px",
                      marginRight: 5,
                      verticalAlign: "center",
                    }}
                  />
                </div>
                {isEdit ? (
                  // selectData_l > 0 &&
                  <div style={{ padding: "0px 10px" }}>
                    <div
                      style={{
                        ...Message_Mark_List_Style.t_c,
                        ...Message_Mark_List_Style.t_c_add,
                        borderTop: isEdit ? "none" : "1px solid",
                        borderColor: style.common.borderColor,
                        // padding: "0px 10px",
                        // backgroundColor:"orange"
                      }}
                    >
                      선택된 질문을
                      <div style={Message_Mark_List_Style.btn_w}>
                        <UiTextBtn
                          text={"즐겨찾기 해제"}
                          btnStyle={{
                            ...Message_Mark_List_Style.btn,
                            marginRight: "7.5px",
                          }}
                          fnClick={() => {
                            let isOk = window.confirm("선택한 질문을 즐겨찾기 해제 하시겠어요?");
                            if (isOk) {
                              apiPro_del_pick();
                            }
                          }}
                        />
                        <UiTextBtn
                          text={"다른 폴더로 이동"}
                          btnStyle={{
                            ...Message_Mark_List_Style.btn,
                            color: style.common.fontColor_1,
                            marginLeft: "7.5px",
                          }}
                          fnClick={() => {
                            navigate(
                              "/messageMark/" + params["who"] + "/" + params["id"] + "/move"
                            );
                          }}
                        />
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        padding: "10px 0px 5px 0px",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          fontSize: "12px",
                        }}
                        onClick={() => {
                          all_select();
                        }}
                      >
                        <UiCheckBox className={isTeacher ? "t" : "s"} checked={isAll} />
                        <div
                          style={{
                            marginLeft: "10px",
                          }}
                        >
                          전체선택
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  params["form"] == "default" &&
                  params["id"] != "1" && (
                    <div
                      style={{
                        ...Message_Mark_List_Style.t_c,
                        borderTop: "1px solid",
                        borderColor: style.common.borderColor,
                      }}
                    >
                      <div
                        style={{
                          ...Message_Mark_List_Style.btn_w,
                          width: "60%",
                        }}
                      >
                        <UiTextBtn
                          isUnderline={false}
                          text={"삭제하기"}
                          btnStyle={Message_Mark_List_Style.btn}
                          fnClick={() => {
                            let isOk = window.confirm(
                              `[${folderInfo["name"]}] 폴더를 삭제하시겠어요?`
                            );
                            if (isOk) {
                              apiPro_del();
                            }
                          }}
                        />
                        <UiTextBtn
                          text={"이름 변경"}
                          isUnderline={false}
                          btnStyle={{
                            ...Message_Mark_List_Style.btn,
                            color: style.common.fontColor_1,
                          }}
                          fnClick={() => {
                            setCreateObj({
                              api: apiPro_modi,
                              title: "이름 변경",
                              input_title: "폴더 이름",
                            });
                            navigate(`/create/${params["who"]}`);
                            // let name = window.prompt("변경할 폴더 이름을 입력해주세요.")
                            // if(name == "")return;
                            // apiPro_modi(name)
                          }}
                        />
                      </div>
                    </div>
                  )
                )}
                <div
                  style={{
                    backgroundColor: style.common.borderColor2,
                  }}
                >
                  {messageList.map((v, i) => (
                    <UiExplain
                      scrollKey={scrollKey}
                      setScrolllKey={setScrolllKey}
                      isTeacher={isTeacher}
                      data={v}
                      id={v["id"]}
                      isMsg={false}
                      isMsgSection={false}
                      // isMessage={true}
                      // isQna={params["form"] == "proc" ? true : false}
                      fnClick={(d) => {
                        setScrolllKey(d["id"]);
                        // setHiddenUrl(window.location.pathname)
                        setHiddenUrl(-1);
                        let id = 0;
                        navigate(
                          "/talk/" +
                            params["who"] +
                            "/" +
                            id +
                            "/" +
                            d["id"] +
                            "/" +
                            d["sid"] +
                            "/" +
                            d["tid"]
                        );
                        // if(d["id"]){
                        //     id = d["id"]
                        // }
                        // if(d["qid"]){
                        //     navigate("/talk/"+params["who"]+"/"+id+"/"+d["qid"]+"/"+d["sid"]+"/"+d["tid"])
                        // }
                        // else{
                        //     navigate("/talk/"+params["who"]+"/"+id+"/"+"0"+"/"+d["sid"]+"/"+d["tid"])
                        // }
                      }}
                      isEdit={isEdit}
                      selectData={selectData}
                      fnLongClick={(id) => {
                        setIsEdit(true);
                        setSelectData({
                          ...selectData,
                          [id]: "Y",
                        });
                      }}
                      fnCheck={(e) => {
                        // // console.log("e.tar => ",e.target.id)
                        if (selectData[e.target.id] == "Y") {
                          delete selectData[e.target.id];
                          setSelectData({
                            ...selectData,
                            // [e.target.id] : "N"
                          });
                        } else {
                          setSelectData({
                            ...selectData,
                            [e.target.id]: "Y",
                          });
                        }
                      }}
                    />
                  ))}
                  {/* <UiMessage
                    data={messageList}
                    selectData={selectData}
                    isTeacher={isTeacher}
                    fnLongClick={(id)=>{
                        setIsEdit(true)
                        setSelectData({
                            ...selectData,
                            [id] : "Y"
                        })
                    }}
                    fnCheck={(e)=>{
                        // console.log("e.tar => ",e.target.id)
                        if(selectData[e.target.id] == "Y"){
                            delete selectData[e.target.id]
                            setSelectData({
                                ...selectData,
                                // [e.target.id] : "N"
                            })
                        }
                        else {
                            setSelectData({
                                ...selectData,
                                [e.target.id] : "Y"
                            })
                        }
                    }}
                    fnClick={(d)=>{
                        // setHiddenUrl(window.location.pathname)
                        setHiddenUrl(-1)
                        let id = 0
                        if(d["id"]){
                            id = d["id"]
                        }
                        if(d["qid"]){
                            navigate("/talk/"+params["who"]+"/"+id+"/"+d["qid"]+"/"+d["sid"]+"/"+d["tid"])
                        }
                        else{
                            navigate("/talk/"+params["who"]+"/"+id+"/"+"0"+"/"+d["sid"]+"/"+d["tid"])
                        }
                    }}
                    isEdit={isEdit}
                /> */}
                </div>
              </div>
            </>
          ) : (
            <div
              style={{
                marginTop: "50px",
                display: "flex",
                flexDirection: "column",
                width: "100%",
              }}
            >
              <div style={Message_Mark_Style.textBtn_add_c}>
                <div style={Message_Mark_Style.textBtn_add_w}>
                  <UiBtn
                    btnStyle={{
                      ...Message_Mark_Style.textBtn_add,
                      backgroundColor: isTeacher ? style.common.t_color : style.common.s_color,
                    }}
                    title="최근 본 질문"
                  />
                </div>
                <div style={Message_Mark_Style.textBtn_add_w}>
                  <UiBtn
                    btnStyle={{
                      ...Message_Mark_Style.textBtn_add,
                      backgroundColor: isTeacher ? style.common.t_color : style.common.s_color,
                    }}
                    title="나중에 볼 질문"
                  />
                </div>
              </div>
              <div style={Message_Mark_Style.list_w}>
                {[1, 2, 3, 4].map((v, i) => (
                  <Folder_
                    setSelectData={setSelectData}
                    selectData={selectData}
                    isEdit={isEdit}
                    isTeacher={isTeacher}
                    fnLongClick={(id) => {
                      setIsEdit(true);
                      setSelectData({
                        ...selectData,
                        [id]: "Y",
                      });
                    }}
                    fnCheck={(e) => {
                      if (selectData[e.target.id] == "Y") {
                        delete selectData[e.target.id];
                        setSelectData({
                          ...selectData,
                        });
                      } else {
                        setSelectData({
                          ...selectData,
                          [e.target.id]: "Y",
                        });
                      }
                    }}
                    key={"mark_folder_" + i}
                    id={i}
                    navigate={navigate}
                    params={params}
                  />
                ))}
              </div>
              <div style={Message_Mark_Style.b_c}>
                <div style={Message_Mark_Style.b_w}>
                  <UiBtn
                    btnStyle={Message_Mark_Style.b_btn}
                    title={
                      <div style={Academy_book_Style.btn_title}>
                        <Add style={Academy_book_Style.btn_Add} />
                        <div
                          style={{
                            paddingLeft: "12px",
                          }}
                        >
                          폴더 추가
                        </div>
                      </div>
                      // <div style={Message_Mark_Style.b_btn_title}>
                      //     <Add
                      //         style={{
                      //             marginRight : "5px"
                      //         }}
                      //     />

                      //     폴더 추가
                      // </div>
                    }
                  />
                </div>
              </div>
            </div>
          )}
        </Frame>
      )}
    </>
  );
};

const Folder_ = ({ id, isEdit, isTeacher, selectData, params, navigate, fnLongClick, fnCheck }) => {
  return (
    <div
      style={Message_Mark_Style.folder_c}
      onClick={() => {
        if (isEdit) return;
        navigate("/messageMark/" + params["who"] + "/" + id + "/default");
      }}
    >
      {/* {isEdit &&
            <div style={Message_Mark_Style.folder_chk}>
                <UiCheckBox
                    fnClick={fnCheck}
                    checked={selectData[id] == "Y" ? true : false}
                    className={isTeacher ? "t" : "s"}
                    value={id}
                />

            </div>
            } */}
      <img src={empty_folder} style={Message_Mark_Style.folder_img} />
      <div>{util.textTrim("ABCDE TEXT ABCDE TEXT", 18)}</div>
    </div>
  );
};

export default Message_Mark_List;
