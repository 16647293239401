import { SwipeableDrawer } from "@mui/material";
import React, { ReactNode, useEffect, useState } from "react";
import styled from "styled-components";

export interface IBottomSheet extends CommonModalControl {
  menuList?: IMenuItem[];
  keepMounted?: boolean;
  children?: ReactNode;
  title?: ReactNode;
}

export interface IMenuItem {
  title: ReactNode;
  icon?: ReactNode;
  onClick: () => void;
}
const BottomSheet = ({ onClose, open, menuList, children, title, keepMounted }: IBottomSheet) => {
  const [openState, setOpenState] = useState<boolean>(open);
  useEffect(() => {
    setOpenState(open);
  }, [open, setOpenState]);

  return (
    <SwipeableDrawer
      onOpen={() => setOpenState(true)}
      anchor={"bottom"}
      open={openState}
      onClose={() => {
        setOpenState(false);
        setTimeout(() => {
          onClose();
        }, 500);
      }}
      onClick={(event) => event.stopPropagation()}
      swipeAreaWidth={20}
      ModalProps={{
        keepMounted: false,
      }}
      sx={{
        zIndex: 1300001,
      }}
      PaperProps={{
        sx: {
          borderRadius: "10px 10px 0px 0px",
          maxHeight: "90vh",
          maxWidth: 546,
          margin: "0 auto",
        },
      }}
    >
      <Container>
        {title && <TitleFont>{title}</TitleFont>}
        {menuList && (
          <MenuContainer>
            {menuList.map(({ title, onClick, icon }, index) => (
              <MenuItem onClick={onClick} key={title.toString()}>
                {icon}
                <span>{title}</span>
              </MenuItem>
            ))}
          </MenuContainer>
        )}
        {(keepMounted || open) && children}
      </Container>
    </SwipeableDrawer>
  );
};
const Container = styled.div`
  padding: 24px 32px 34px 32px;
  @media screen and (min-width: 820px) {
    padding: 32px 48px 72px 48px;
  }
`;

const MenuContainer = styled.div`
  margin-top: 16px;
  display: flex;
  height: min-content;
  width: 100%;
  position: relative;
  justify-content: space-between;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  flex-shrink: 0;
  box-sizing: border-box;
`;

const TitleFont = styled.div`
  color: var(--neutral-color-natural-70);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%; /* 19.2px */
  letter-spacing: -0.16px;
`;

const MenuItem = styled.div`
  color: var(--neutral-color-natural-70);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 16.8px */
  letter-spacing: -0.14px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  flex: 1;
  padding: 16px 0;
  box-sizing: border-box;
  border-bottom: 1px solid var(--neutral-color-natural-30);
  &:last-child {
    border-bottom: none;
  }
  & > svg {
    font-size: 32px;
    color: #ffa723;
  }
`;

export default BottomSheet;
