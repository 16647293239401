import { ReactComponent as WarningIcon } from "asset/images/icon/alert-triangle.svg";
import { ReactComponent as ErrorIcon } from "asset/images/icon/error.svg";
import { ReactComponent as SuccessIcon } from "asset/images/icon/success.svg";
import { FontStyleMap } from "components/atoms/Font";
import React from "react";
import toast, { Toaster } from "react-hot-toast";
import styled from "styled-components";

type ToastType = "success" | "error" | "warning";
type ShowToastProps = {
  message: string;
  type: ToastType;
  title: string;
};
export const showToast = ({ type, message, title }: ShowToastProps) => {
  toast.custom((toastInfo) => {
    return (
      <ToastContainer
        onClick={() => toast.dismiss(toastInfo.id)}
        className={toastInfo.visible ? "animate-enter" : "animate-leave"}
      >
        {type === "success" ? <SuccessIcon /> : type === "error" ? <ErrorIcon /> : <WarningIcon />}
        <TextContainer>
          <TitleFont>{title}</TitleFont>
          <ContentsFont>{message}</ContentsFont>
        </TextContainer>
      </ToastContainer>
    );
  });
};

const ToastContainer = styled.button`
  padding: 16px;
  border-radius: 8px;
  gap: 16px;
  display: flex;
  color: white;
  background-color: var(--Default-Gray-90, #262626);
  box-shadow:
    0px 16px 24px 0px rgba(0, 0, 0, 0.07),
    0px 6px 30px 0px rgba(0, 0, 0, 0.07),
    0px 8px 10px 0px rgba(0, 0, 0, 0.15);
  @keyframes enter {
    0% {
      transform: scale(0.9);
      opacity: 0;
    }

    to {
      transform: scale(1);
      opacity: 1;
    }
  }

  &.animate-enter {
    animation: enter 0.2s ease-out;
  }

  @keyframes leave {
    0% {
      transform: scale(1);
      opacity: 1;
    }

    to {
      transform: scale(0.9);
      opacity: 0;
    }
  }

  &.animate-leave {
    animation: leave 0.15s ease-in forwards;
  }
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: flex-start;
`;

const TitleFont = styled.span`
  ${FontStyleMap["KR/medium/$KR.BtnL.MD.16"]}
  color: white;
`;
const ContentsFont = styled.span`
  ${FontStyleMap["KR/regular/$KR.BtnM.REG.14"]}
  color: white;
  text-align: justify;
`;

const ToastProvider = ({ children }) => {
  return (
    <>
      {children}
      <Toaster
        position="bottom-center"
        toastOptions={{
          duration: 6000,
        }}
        containerStyle={{
          zIndex: 1300002,
        }}
      />
    </>
  );
};

export default ToastProvider;
