import Container from "components/atoms/Container";
import React from "react";
import LectureSearchHeader from "./components/LectureSearchHeader";
import LectureSearchResult from "./components/LectureSearchResult";

const LectureVideoSearch = () => {
  const [searchValue, setSearchValue] = React.useState("");

  return (
    <Container>
      <LectureSearchHeader setSearchValue={setSearchValue} />
      {searchValue && <LectureSearchResult searchValue={searchValue} />}
    </Container>
  );
};

export default LectureVideoSearch;
