import {
  UseInfiniteQueryOptions,
  useInfiniteQuery,
} from "@tanstack/react-query";
interface UseInfiniteScrollProps {
  queryKey: [string, Record<string, any> & { page: number }];
  queryFn?: ({ pageParam }) => Promise<any>;
  options?: UseInfiniteQueryOptions;
}
export const useInfiniteScroll = <T>({
  queryKey,
  queryFn,
  options,
}: UseInfiniteScrollProps) => {
  const [, parameters] = queryKey;
  const parameter = {
    queryKey,
    getNextPageParam(lastPage, list) {
      return {
        ...parameters,
        page: list.length + 1,
      };
    },
    ...options,
  } as Record<string, any>;
  if (queryFn) {
    parameter.queryFn = queryFn;
  }
  return useInfiniteQuery<T>(parameter);
};
