import { React , useState , useEffect } from 'react'
import { useParams , useNavigate } from 'react-router'
import apiFn from '../../asset/apiClass';
import style from '../../asset/style';
import util from '../../asset/util';
// import style from '../../asset/style'
import Basic from '../../common/frame/Basic';
import UiBtn from '../../uiComponents/UiBtn/UiBtn';
import UiHiqDescBox from '../../uiComponents/UiHiqDescBox/UiHiqDescBox';
import { UiInput, UiInput_title } from '../../uiComponents/UiInput/UiInput';
import UiLectureTime from '../../uiComponents/UiLectureTime/UiLectureTime';
import UiLectureTime_Style from '../../uiComponents/UiLectureTime/UiLectureTime_Style';
import { UiSearchList } from '../../uiComponents/UiSearchList/UiSearchList';
import UiTime from '../../uiComponents/UiTime/UiTime';
import RegiForm from '../before/register/RegiForm';
import Register_One_style from '../before/register/Register_One_style';

const Profile_lecture_regi_two = ({
    userInfo,
    l_regiData,
    setL_regiData,
    l_time,
    setL_time,
}) => {
    let params = useParams();
    let navigate = useNavigate();

    const [isTeacher ,setIsTeacher] = useState(false);
    const [isChk , setIsChk] = useState(false);

    const [data,setData] = useState([]);

    const apiPro_read = async () => {
        try {
            let data = await apiFn.comApi({
                state : {
                    table : "class",
                    page : 1,
                    amount : 9999,
                    sort : "reg_date desc",
                    where:{
                        'HIQDB_class.status':`='1'`,   
                        'HIQDB_member_class.status':`!='9'`, 
                        'HIQDB_member_class.mid':`='${userInfo["id"]}'`,    
                        [util.spaceTrim_qr("HIQDB_class.name")] : `like '%${util.spaceTrim(l_regiData["name"])}%'`
                    },
                    count : [
                        'HIQDB_member_class.mid:cid:id|and countTable.type =\'0\'|lcnt',
                        "HIQDB_member_class.mid:cid:id|and countTable.type in ('5','6','7','8','9')|t_cnt"
                    ],
                    ref : [
                        'HIQDB_member_class.cid|HIQDB_class.id=HIQDB_member_class.cid',
                        'HIQDB_member_class.mid|HIQDB_class.id=HIQDB_member_class.cid',
                        `B.name as academyName|HIQDB_class B on B.id=HIQDB_class.pid`,
                    ],
                }
            })

            // // console.log("@@@@@@@@@ ",data);

            
            if(data["list"].length > 0){
                setData([
                    ...data["list"]
                ])
                navigate('/lectureregitwo/'+params["who"]+"/"+"confirm"+"/"+params["id"])
            }
            else {
                navigate("/lectureregithree/"+params["who"]+"/"+params["id"])
            }

        }
        catch(e){
        }
    }

    useEffect(()=>{
        if(params["who"] == "teacher"){
            setIsTeacher(true)
        }
        else {
            setIsTeacher(false)
        }
    },[params])

    useEffect(()=>{
       try {
        if(l_regiData["address"] != ""){
            let data = Object.values(l_time)
            for(var i = 0; i < data.length; i++){
                if(data[i]["slc"] == "Y"){
                    if(data[i]["startDay"] != "" && data[i]["endDay"] !== ""){
                        setIsChk(true)
                        break;
                    }
                }
                else {
                    setIsChk(false)   
                }
            }
        }
        else {
            setIsChk(false)
        }
       }
       catch(e){
           // console.log("LECTURE ISCHK ERROR => ",e)
       }
    },[l_time,l_regiData])

    useEffect(()=>{
        setL_time({
            "월" : {
                slc : "N",
                startDay : "",
                endDay : "",
            },
            "화" : {
                slc : "N",
                startDay : "",
                endDay : "",
            },
            "수" : {
                slc : "N",
                startDay : "",
                endDay : "",
            },
            "목" : {
                slc : "N",
                startDay : "",
                endDay : "",
            },
            "금" : {
                slc : "N",
                startDay : "",
                endDay : "",
            },
            "토" : {
                slc : "N",
                startDay : "",
                endDay : "",
            },
            "일" : {
                slc : "N",
                startDay : "",
                endDay : "",
            },
      })
    },[])

    // useEffect(()=>{
    //     // console.log("l_time => ",l_time)
    //     util.c_gropulog("l_time",l_time,)
    // },[l_time])

    return (
        <Basic 
            title="새 강의 생성"
        >
            <RegiForm 
                num={2}
                dotCnt={3}
                isTopTitle={false}
                isTeacher={isTeacher}
                title={ params["form"] == "de" ? "부가 정보" : "수업 정보 입력"}
            />
            {
            params["form"] == "de" ?
            <>
            <div style={{
                marginTop : "15px"
            }}>
                <UiInput_title 
                    title="강의 장소"
                    inputClass={isTeacher ? "input_t" : "input_s"}
                    // placeholder="강의실을 입력해주세요."
                    placeholder="강의 하는 클래스룸과 강의실을 입력해주세요."
                    fnChange={(e)=>{
                        // setLectruePlace(e.target.value);
                        setL_regiData({
                            ...l_regiData,
                            address : e.target.value
                        })
                    }}
                />
            </div>
            <div style={{
                marginTop : "20px",
                fontSize : "14px",
                marginBottom : "5px",
            }}>
                강의 시간
                <div style={{
                    display:"flex",
                    justifyContent:"space-between",
                    margin : "15px 0px 30px 0px",
                }}>
                    {Object.keys(l_time).map((v,i)=>
                    <div style={{
                        ...UiLectureTime_Style.round_w,
                        marginRight:0,
                        backgroundColor : l_time[v]["slc"] == "Y" ? style.common.t_color : style.common.borderColor
                    }}
                        onClick={()=>{
                            if(l_time[v]["slc"] == "N"){
                                setL_time({
                                    ...l_time,
                                    [v] : {
                                        ...l_time[v],
                                        slc : "Y",
                                        startDay : "",
                                        endDay : "",
                                    }
                                })
                            }
                            else {
                                setL_time({
                                    ...l_time,
                                    [v] : {
                                        ...l_time[v],
                                        slc : "N",
                                        startDay : "",
                                        endDay : "",
                                    }
                                })
                            }
                        }}
                    >
                        {v}
                    </div>
                    )}
                </div>
                {Object.keys(l_time).map((v,i)=>{
                    if(l_time[v]["slc"] == "Y"){
                        return (
                            <UiLectureTime 
                                day={v}
                                isTeacher={isTeacher}
                                fnChange={(d)=>{
                                    setL_time({
                                        ...l_time,
                                        [v] : {
                                            ...l_time[v],
                                            "startDay" : d,
                                        }
                                    })
                                }}
                                fnChange2={(d)=>{
                                    setL_time({
                                        ...l_time,
                                        [v] : {
                                            ...l_time[v],
                                            "endDay" : d
                                        }
                                    })
                                }}
                            />
                        )
                    }
                })}
            </div>
            <div style={{
                display:"flex",
                flexDirection : "column",
                justifyContent:"flex-end",
                paddingBottom : "20px",
                width : "100%",
                flex:1,
            }}>
                <UiBtn
                    btnStyle={{
                        color : "white",
                        borderColor : 
                        // isChk ? 
                        ( isTeacher ? style.common.t_color : style.common.s_color )
                        // :  
                        // style.common.borderColor
                        ,
                        backgroundColor : 
                        // isChk ? 
                        ( isTeacher ? style.common.t_color : style.common.s_color )
                        // :  
                        // "rgb(200,200,200)"

                    }}
                    title="다음"
                    fnClick={()=>{
                        apiPro_read();
                    }}
                />
            </div>
            </>
            :
            <>
            <div style={{
                fontSize : "12px",
                margin : "20px 0px"
            }}>
                <div>
                    {`입력한 정보와 동일한 곳으로 보이는 수업이 ${data.length}개 있어요.`}
                </div>
                <div>
                    수업 생성을 계속 진행하시겠어요?
                </div>
            </div>
            <UiSearchList
                data={data}
                isTitle={false}
                form={2}
            />
            <div style={Register_One_style.b_c}>
                <UiBtn
                    btnStyle={{
                        ...Register_One_style.b,
                        color : style.common.fontColor_5,
                        border : "1px solid",
                        borderColor : style.common.borderColor

                    }}
                    title="아니요"
                    fnClick={()=>{
                        navigate("/profile/"+params["who"]+"/"+params["id"]+"/de")
                    }}
                />
                <UiBtn
                    btnStyle={{
                        ...Register_One_style.b,
                        borderColor : 
                        ( isTeacher ? style.common.t_color : style.common.s_color )
                        ,
                        backgroundColor : 
                        ( isTeacher ? style.common.t_color : style.common.s_color )
                    }}
                    title="계속 진행"
                    fnClick={()=>{
                        navigate("/lectureregithree/"+params["who"]+"/"+params["id"])
                    }}
                />
            </div>
            </>
            }

        </Basic>



    )
}

export default Profile_lecture_regi_two