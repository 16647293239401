import { PdfItem, PdfType, PdfTypeName, usePdfList } from "api/pdfView";
import { ReactComponent as Arrow } from "asset/images/icon/light-arrow.svg";
import { getStudybookPageIndex, setStudybookPageIndex } from "asset/storage";
import { StudyBookContext } from "pages/StudyBookDetail/context/StudyBookContextProvider";
import React, { Dispatch, useCallback, useContext, useEffect, useMemo, useRef } from "react";
import { useParams } from "react-router";
import styled from "styled-components";
import PdfRowtem from "./PdfRowtem";

const QuestionSlider = () => {
  const { bookId } = useParams();
  const { data } = usePdfList({ bookId, isAnswer: PdfType.question });
  const { setQuestionSlideIndex, questionSlideIndex, topHeight } = useContext(StudyBookContext);

  const pdfList = useMemo(() => {
    return data?.qnaList ?? [];
  }, [data?.qnaList]);

  useEffect(() => {
    const slideItem = document.querySelector(`#pdf-question-row-${questionSlideIndex}`);
    if (slideItem) {
      slideItem.scrollIntoView({ block: "start" });
    }
  }, [questionSlideIndex]);

  const handleActive = useCallback(
    (index: number) => {
      if (questionSlideIndex === "") {
        return;
      }
      setQuestionSlideIndex(index);
    },
    [questionSlideIndex]
  );

  useEffect(() => {
    const index = getStudybookPageIndex({ bookId });
    const isLoadTopContent = topHeight >= 100;
    if (index && isLoadTopContent) {
      setQuestionSlideIndex(parseInt(index));
    }
  }, [topHeight]);

  const isFirst = useRef<boolean>(true);
  useEffect(() => {
    if (isFirst.current) {
      isFirst.current = false;
      return;
    }
    setStudybookPageIndex({ bookId, index: questionSlideIndex });
  }, [questionSlideIndex]);

  return (
    <StudybookSlider
      pdfList={pdfList}
      slideIndex={questionSlideIndex}
      handleActive={handleActive}
      type={PdfTypeName.question}
      setPageIndex={setQuestionSlideIndex}
    />
  );
};

type StudybookSliderProps = {
  pdfList: PdfItem[];
  slideIndex: number | "";
  handleActive: (index: number) => void;
  type: PdfTypeName;
  setPageIndex: Dispatch<React.SetStateAction<number | "">>;
  isAnswer?: boolean;
};

export const StudybookSlider = ({
  pdfList,
  handleActive,
  slideIndex,
  type,
  setPageIndex,
}: StudybookSliderProps) => {
  const pdfTotalPage = useMemo(() => pdfList.length, [pdfList]);
  const handleScrollNext = useCallback(() => {
    setPageIndex((prev) => {
      if (prev === "" || prev >= pdfTotalPage) {
        return 1;
      }
      return prev + 1;
    });
  }, [pdfTotalPage]);

  const handleScrollPrev = useCallback(() => {
    setPageIndex((prev) => {
      if (prev === "" || prev === 1) {
        return 1;
      }
      return prev - 1;
    });
  }, []);
  return (
    <Container>
      <XScrollContainer>
        <ArrowLeft onClick={handleScrollPrev}>
          <Arrow />
        </ArrowLeft>
        <ArrowRight onClick={handleScrollNext}>
          <Arrow />
        </ArrowRight>
        {pdfList.map((pdfItem, index) => {
          const isVisible =
            index <= (slideIndex as number) + 6 && index >= (slideIndex as number) - 8;
          const slideNumber = index + 1;
          if (isVisible) {
            return (
              <ItemContainer id={`pdf-${type}-row-${slideNumber}`} key={pdfItem.qid}>
                <PdfRowtem
                  currentSliderInfo={pdfItem}
                  slideNumber={slideNumber}
                  onActive={handleActive}
                  isActive={slideIndex === slideNumber}
                  type={type}
                />
              </ItemContainer>
            );
          } else {
            return <ItemContainer key={pdfItem.qid}></ItemContainer>;
          }
        })}
      </XScrollContainer>
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  overflow: hidden;
  max-height: inherit;
  min-height: inherit;
`;

export const XScrollContainer = styled.div`
  display: flex;
  overflow: auto hidden;
  width: auto;
  max-height: inherit;
  /* min-height: inherit; */
  scroll-snap-type: x mandatory;
  -ms-overflow-style: none;
  scrollbar-width: none;
  ::-webkit-scrollbar {
    display: none;
  }
`;
export const ItemContainer = styled.div`
  scroll-snap-align: start;
  position: relative;
  min-width: 100%;
  border: 1px solid #fff0;
  overflow: hidden;
  height: inherit;
  max-height: inherit;
  min-height: inherit;
`;

const ArrowLeft = styled.button`
  background: #26262620;
  border: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  color: #fff;
  width: 24px;
  height: 100%;
  cursor: pointer;
  position: absolute;
  left: 0;
  z-index: 2;
  opacity: 0;
  transition: opacity 0.3s;
  :hover {
    opacity: 1;
  }
  @media screen and (min-width: 820px) {
    width: 32px;
  }
  @media screen and (min-width: 1180px) {
    width: 48px;
  }
`;

const ArrowRight = styled(ArrowLeft)`
  left: unset;
  right: 0;
  > svg {
    transform: rotate(180deg);
  }
`;

export default QuestionSlider;
