import { useQuery } from "@apollo/client";
import { PaymentContext } from "asset/context";
import { getUser } from "asset/storage";
import util from "asset/util";
import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router";
import { UserTicketInventoriesDocument } from "types/graphql/graphql";
import UiLoading from "uiComponents/UiLoading/UiLoading";
import UiSlideModal from "uiComponents/UiSlideModal/UiSlideModal";
import styles from "./selectTicket.module.scss";

export default ({ selectedAcademyId }) => {
  const userInfo = getUser();
  const isTeacher = useMemo(() => userInfo.type == 2, [userInfo]);

  const [isPurchaseMode, setPurchaseMode] = useState(false);
  const [selectedAmount, setSelectedAmount] = useState({});
  const navigate = useNavigate();
  const {
    academyId,
    setAcademyId,
    isPaymentModalOpen,
    setPaymentModalOpen,
    paymentOpen,
    tickets,
    setPickResult,
    setIsSelectTeacherModalOpen,
  } = useContext(PaymentContext);

  useEffect(() => {
    if (selectedAcademyId) {
      setAcademyId(selectedAcademyId);
    }
  }, [selectedAcademyId]);

  // 질문권 재고 조회
  const { data: inventoryData, loading } = useQuery(UserTicketInventoriesDocument, {
    fetchPolicy: "network-only",
    variables: {
      studentId: userInfo.id,
      academyId: academyId,
    },
    skip: isTeacher || !academyId,
  });

  const inventories = useMemo(() => {
    if (inventoryData?.userTicketInventories?.inventories) {
      const inventories = inventoryData.userTicketInventories.inventories || [];
      const ticketInventories = inventories.reduce((acc, item) => {
        let ticketType = item.ticket.saleType;
        if (acc?.[ticketType]) {
          acc[ticketType]["quantity"] = acc[ticketType]["quantity"] + item["quantity"];
        } else {
          acc[ticketType] = {
            quantity: item.quantity,
            ticketId: item.ticket.id,
            ticketType: item.ticket.saleType,
            name: item.ticket.name,
          };
        }
        return acc;
      }, {});
      return ticketInventories;
    }
  }, [inventoryData]);

  const onSelectTicket = useCallback(
    (ticketType, purchase) => (e) => {
      // 구매 버튼을 눌렀을 때
      if (purchase) {
        setSelectedAmount({ [ticketType]: 1 });
        setPurchaseMode(true);
      } else {
        setIsSelectTeacherModalOpen(true);
        setPickResult(ticketType);
        setPaymentModalOpen(false);
      }
    },
    [setPaymentModalOpen]
  );

  const onClickPurchase = useCallback(() => {
    const params = Object.keys(selectedAmount).reduce((acc, type) => {
      const amount = selectedAmount[type];
      if (amount > 0) {
        return acc + `${acc ? "&" : ""}${type}=${amount}`;
      } else {
        return acc;
      }
    }, "");
    setSelectedAmount({});
    setPurchaseMode(false);
    setPaymentModalOpen(false);
    navigate(`/order?${params}`);
  }, [selectedAmount, navigate, setPaymentModalOpen]);

  const onClickAmount = useCallback(
    (type, diff) => (e) => {
      const prevAmount = selectedAmount?.[type] || 0;
      const newAmount = prevAmount + diff;
      if (newAmount >= 0) {
        setSelectedAmount({
          ...selectedAmount,
          [type]: newAmount,
        });
      }
    },
    [selectedAmount]
  );

  const totalAmount = tickets
    .map((item) => {
      const unitPrice = item.price;
      const amount = selectedAmount?.[item.saleType] || 0;
      return unitPrice * amount;
    })
    .reduce((acc, cur) => acc + cur, 0);

  const showSelect = paymentOpen == "both" || paymentOpen == "select";

  if (loading) {
    return <UiLoading text="" />;
  }
  return (
    <UiSlideModal open={isPaymentModalOpen} setOpen={setPaymentModalOpen}>
      {isPurchaseMode ? (
        <>
          <div className={styles.selectTicket}>
            <button
              className={styles.closeButton}
              onClick={() => {
                setSelectedAmount({});
                setPurchaseMode(false);
              }}
            >
              X
            </button>
            {tickets.map((item) => (
              <div className={styles.item} key={item.id}>
                <div className={styles.titleContainer}>
                  <span className={styles.title}>{item.title}</span>
                  <span className={styles.desc}>{item.desc}</span>
                  <span className={styles.price}>{util.formatCurrency(item.price)}</span>
                </div>
                <div className={styles.amountContainer}>
                  <button className={styles.cell} onClick={onClickAmount(item.saleType, -1)}>
                    -
                  </button>
                  <span className={styles.cell}>{selectedAmount?.[item.saleType] || 0}</span>
                  <button className={styles.cell} onClick={onClickAmount(item.saleType, 1)}>
                    +
                  </button>
                </div>
              </div>
            ))}
            <span className={styles.totalAmount}>{util.formatCurrency(totalAmount)}</span>
            <button className={styles.purchaseButton} onClick={onClickPurchase}>
              구매하기
            </button>
          </div>
        </>
      ) : (
        <div className={styles.selectTicket}>
          {tickets.map(
            (item) =>
              item.saleType === "BASIC" &&
              ((inventories?.[item.saleType]?.quantity || 0) > 0 ? (
                <div className={styles.item} key={item.id}>
                  <div className={styles.titleContainer}>
                    <span className={styles.title}>{item.title}</span>
                    <span className={styles.desc}>
                      {(inventories?.[item.saleType]?.quantity || 0) > 0
                        ? `남은 질문권 : ${inventories?.[item.saleType]?.quantity}회`
                        : item.desc}
                    </span>
                  </div>
                  <div className={styles.buttonContainer}>
                    {(inventories?.[item.saleType]?.quantity || 0) > 0 && showSelect && (
                      <button
                        className={styles.selectButton}
                        onClick={onSelectTicket(item.saleType, false)}
                      >
                        선택
                      </button>
                    )}
                    {/* {(showPurchase || (inventories?.[item.saleType]?.quantity || 0) <= 0) && (
                  <button
                    className={styles.selectButton}
                    onClick={onSelectTicket(item.saleType, true)}
                  >
                    구매
                  </button>
                )} */}
                  </div>
                </div>
              ) : (
                <div className={styles.item} key={item.id}>
                  <div className={styles.titleContainer}>
                    <span className={styles.title}>오늘 사용 가능한 질문권을 모두 소진했어요.</span>
                    <span className={styles.desc}>더 질문 하고 싶다면 선생님께 요청해주세요.</span>
                  </div>
                </div>
              ))
          )}
        </div>
      )}
    </UiSlideModal>
  );
};
