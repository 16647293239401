import style from "../../asset/style"

const Academy_main_Style = {
    c : {
        display:"flex",
        flexDirection : "column",
    },
    t_btn_c : {
        display:"flex",
        // justifyContent : "center",
        width : "100%",
    },
    t_btn_w : {
        // justifyContent : "space-between",
        display:"flex",
        // width : "100%",
    },
    t_btn : {
        marginRight : "20px",
        height : "35px",
        width : "145px",
        borderRadius : 20,
        padding : "0px 10px"
    },
    searchList_w : {
        display:"flex",
        marginTop : "20px",
    } ,
    searchList_empty_c : {
        display:"flex",
        flexDirection:"column",
        justifyContent:"center",
        alignItems:"center",
        fontSize : "14px",
        fontWeight:"bold",
        color : style.common.fontColor_1,
        width:"100%",
        height : "100%",
    },
    searchList_empty_img : {
        // width : "60%",
        height: "45%"
    },
}   

export default Academy_main_Style