import style from "../../asset/style"

const UiNotice_Style = {
    c : {
        display : "flex",
        flexDirection:"column",
        padding : "16px 15px", ////
        backgroundColor : "white",
        marginTop : "5px",
        fontSize : "13px",
        backgroundColor : "#FFFBF5",
        borderRadius:5
    },
    title_w : {
        display:"flex",
        justifyContent:"space-between",
        alignItems:"center",
        fontSize:"16px", 
        marginBottom : "10px", //
        fontWeight:700,
    },
    section_c : {
        display : "flex",
        padding : "4px 0px",
        alignItems:"center",
        // borderBottom : "1px solid",
        // borderColor : style.common.borderColor4
    },
    section_w : {
        fontWeight:"bold",
        marginRight : "3px",
    },
    btn_w : {
        display : 'flex',
        justifyContent : "flex-end",
        // padding : "15px 0px 5px 0px"
    },
    btn : {
        fontSize : "12px",
        fontWeight : 400
    }
}

export default UiNotice_Style