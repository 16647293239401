import noImage from "asset/Img/hi.png";
import constants from "asset/constants";
import { getUser } from "asset/storage";
import util from "asset/util";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { QnaImage } from "./QnaList";

interface QnaItemProps {
  original_img: string;
  original?: string | number;
  videoContent?: string;
  img?: string;
  id?: string | number;
  sid?: string | number;
  tid?: string | number;
}

const QnaItem = (item: QnaItemProps) => {
  const userInfo = getUser();
  const who = useMemo(
    () => (userInfo.type === 2 ? "teacher" : "student"),
    [userInfo]
  );

  const [imageSrc, setImageSrc] = useState("");
  const navigate = useNavigate();
  const params = useParams();
  const getThumbnailUrl = useCallback(async () => {
    let imageKeyword = "";
    let videoUrl = "";
    if (item.original) {
      if (item.original_img) {
        imageKeyword = item.original_img;
      } else {
        imageKeyword = `${item.original}`;
      }
    } else {
      if (item.img) {
        imageKeyword = item.img;
      } else {
        const videoContent = item.videoContent;
        if (videoContent?.includes("youtu")) {
          videoUrl = `https://img.youtube.com/vi/${util.getYoutubeId(
            videoContent
          )}/0.jpg`;
        } else if (videoContent?.startsWith("https://vimeo")) {
          videoUrl = (
            await (
              await fetch(
                `https://vimeo.com/api/oembed.json?url=${videoContent}`
              )
            ).json()
          ).thumbnail_url;
        } else {
          videoUrl = `${constants.apiUrl}/board/${videoContent?.replace(
            "mp4",
            "jpg"
          )}`;
        }
      }
    }

    setImageSrc(
      imageKeyword ? `${constants.s3_url}/qna/${imageKeyword}.jpg` : videoUrl
    );
  }, [item]);

  useEffect(() => {
    getThumbnailUrl();
  }, [getThumbnailUrl]);
  return (
    <QnaImage
      onClick={() => {
        navigate(`/talk/${who}/0/${item.id}/${item.sid}/${item.tid}`);
      }}
      src={imageSrc}
      alt="question image"
      key={item.id}
      onError={(e) => {
        e.currentTarget.src = noImage;
      }}
    />
  );
};

export default QnaItem;
