import { React, useCallback, useContext } from "react";
import { useNavigate, useParams } from "react-router";
import UiSearchBox from "../../uiComponents/UiSearch/UiSearchBox";
import { CommonContext } from "../../asset/context";

const Modal_text = ({ isTeacher, setIsText }) => {
  let params = useParams();
  let navigate = useNavigate();
  const { setFileObj } = useContext(CommonContext);

  const handleChangeInput = useCallback((e) => {
    setFileObj({ text: e.target.value });
  }, []);

  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        zIndex: 130002,
        backgroundColor: "rgba(150, 150, 150,0.5)",
        width: "100%",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
      onClick={(e) => {
        e.stopPropagation();
        setIsText(false);
      }}
    >
      <div
        style={{
          width: "300px",
          backgroundColor: "white",
          padding: "40px 20px 40px 20px",
          borderRadius: 10,
          border: "1px solid rgb(200, 200, 200)",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          filter: "drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.37))",
        }}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            fontSize: "18px",
            fontWeight: 700,
            marginBottom: "20px",
          }}
        >
          {"텍스트 검색"}
        </div>
        <UiSearchBox
          inputClass={isTeacher ? "input_t" : "input_s"}
          fnChange={(e) => {
            handleChangeInput(e);
          }}
          fnClick={(e) => {
            navigate(`/search/question/book/${params.id}/text`);
            setIsText(false);
          }}
          isTeacher={isTeacher}
        />
        <span>10글자 이상 입력해야 검색이 가능합니다.</span>
      </div>
    </div>
  );
};

export default Modal_text;
