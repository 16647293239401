import style from "../../asset/style"

const Message_Mark_List_Style = {
    c : {
        display: "flex",
        flexDirection: "column",
        width: "100%",
        height: "100%",
        padding: style.common.padding,
        marginTop: "50px",
    },
    edit_w : {
        display:"flex",
        alignItems:"center",
        position : 'absolute',
        height:"50px",
        right : 10,
        top : 3,
        fontSize : "14px",
        color  : style.common.t_color,
        fontWeight : "bold"
    },
    t_c : {
        display: "flex",
        width: "100%",
        padding: "12px 0px 15px 0px",
        justifyContent: "center",
    },
    t_c_add : {
        justifyContent: "space-between",
        alignItems : "center",
        fontSize : style.common.select_fontSize,
    },
    btn_w : {
        display: "flex",
        justifyContent: "space-between",
    },
    btn : {
        color: style.common.fontColor_3,
        fontSize : style.common.select_fontSize,
    },

}

export default Message_Mark_List_Style