import { React, useEffect, useState } from "react";
import { UiCheckBox } from "../../uiComponents/UiCheckBox/UiCheckBox";
import { UiInput } from "../../uiComponents/UiInput/UiInput";
import face_student from "../../asset/Img/defaultpic_student.png";
import face_teacher from "../../asset/Img/defaultpic_teacher.png";
import { useNavigate, useParams } from "react-router";
import constants from "../../asset/constants";
import apiFn from "../../asset/apiClass";
import style from "../../asset/style";
import UiPeople_Style from "../../uiComponents/UiPeople/UiPeople_Style";
import UiMessage_Style from "../../uiComponents/UiMessage/UiMessage_Style";
import UiLoading from "../../uiComponents/UiLoading/UiLoading";
import { useAddQuestionMembers } from "../../api/question";

const fontStyle = {
  fontFamily: "Noto Sans",
  fontStyle: "normal",
};

const Modal_invite = ({ setIsModal, setQnaData, isModal, userInfo, qnaData }) => {
  let params = useParams();

  const [isAll, setIsAll] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const [oriList, setOriList] = useState([]);
  const [list, setList] = useState([]);
  const [search, setSearch] = useState("");

  const [selectData, setSelectData] = useState({});

  const apiPro_invite = async () => {
    try {
      let peopleObj = list.reduce((a, v, i) => {
        return {
          ...a,
          [v["id"]]: {
            ...v,
          },
        };
      }, {});

      await Promise.all([
        ...Object.keys(selectData).map((v, i) =>
          apiFn.qnaInvite({
            state: {
              wtype: peopleObj[v].user_type,
              wid: v,
              qid: params["qid"],
              ctype: 9, // 1 | 10 not source, 11 source
              content: `${peopleObj[v].user_type === 1 ? peopleObj[v]["nick"] : peopleObj[v]["name"]}`,
            },
          })
        ),
        useAddQuestionMembers(params["qid"], qnaData.member_cnt, Object.keys(selectData).length),
      ]);

      let qna = await apiFn.comApi({
        state: {
          table: "book_link",
          page: 1,
          amount: 9999,
          sort: "reg_date desc",
          ref: [
            // "HIQDB_book.id|HIQDB_book_link.bid=HIQDB_book.id",
            // "HIQDB_book.name|HIQDB_book_link.bid=HIQDB_book.id",
            // "HIQDB_book.reg_date|HIQDB_book_link.bid=HIQDB_book.id",
            "HIQDB_online_qna.img|HIQDB_book_link.qid=HIQDB_online_qna.id",
            "HIQDB_online_qna.title|HIQDB_book_link.qid=HIQDB_online_qna.id",
            "HIQDB_online_qna.answered_by|HIQDB_book_link.qid=HIQDB_online_qna.id",
            "HIQDB_online_qna.tid|HIQDB_book_link.qid=HIQDB_online_qna.id",
            "HIQDB_online_qna.sid|HIQDB_book_link.qid=HIQDB_online_qna.id",
            "HIQDB_online_qna.public|HIQDB_book_link.qid=HIQDB_online_qna.id",
            "HIQDB_online_qna.original|HIQDB_book_link.qid=HIQDB_online_qna.id",
            "HIQDB_online_qna.original_img|HIQDB_book_link.qid=HIQDB_online_qna.id",
            "HIQDB_online_qna.mosaic|HIQDB_book_link.qid=HIQDB_online_qna.id",
            "HIQDB_online_qna.understand|HIQDB_book_link.qid=HIQDB_online_qna.id",
            "HIQDB_online_qna.ans_date|HIQDB_book_link.qid=HIQDB_online_qna.id",
            "HIQDB_online_qna.mobum_date|HIQDB_book_link.qid=HIQDB_online_qna.id",
            "HIQDB_online_qna.reg_date|HIQDB_book_link.qid=HIQDB_online_qna.id",
            "HIQDB_online_qna.qna_date|HIQDB_book_link.qid=HIQDB_online_qna.id",
            "HIQDB_online_qna.level|HIQDB_book_link.qid=HIQDB_online_qna.id",
            "HIQDB_online_qna.cal_amount|HIQDB_book_link.qid=HIQDB_online_qna.id",
            "HIQDB_online_qna.member_cnt|HIQDB_book_link.qid=HIQDB_online_qna.id",
          ],
          left: [
            "HIQDB_book.id|HIQDB_book_link.bid=HIQDB_book.id",
            "HIQDB_book.name|HIQDB_book_link.bid=HIQDB_book.id",
            "HIQDB_book.reg_date as book_reg_date|HIQDB_book_link.bid=HIQDB_book.id",
            "HIQDB_book.reg_id|HIQDB_book_link.bid=HIQDB_book.id",
            "HIQDB_book.img as b_img|HIQDB_book_link.bid=HIQDB_book.id",
          ],
          where: {
            "HIQDB_book_link.qid": `= '${params["qid"]}'`,
          },
          avg: ["HIQDB_online_qna_log.rating:qid:qid|and avgTable.ltype='3'|starAvg"],
          count: [
            // 'HIQDB_online_qna.tid:tid:id|and countTable.mobum_date is not null |mobumCnt',
            "HIQDB_online_qna_log.qid:qid|and countTable.ltype ='3'|starCnt",
            "HIQDB_online_qna_log.qid:qid|and countTable.ltype ='4'|metooCnt",
            "HIQDB_online_qna_log.qid:qid|and countTable.ltype ='1'|readCnt",
            // 'HIQDB_book_link.mid:mid:id|and countTable.type > 4 and countTable.bid != 0 and countTable.cid =0 |bookCnt',
            // "HIQDB_member_class.mid:cid:id|and countTable.type in ('5','6','7','8','9')|t_cnt"
          ],
          // 'HIQDB_online_qna_log.qid:qid:id|and ltype =\'4\'| metooCnt' ],
          // 저도요 숫자
          // qid = qid , ltype = ‘4’

          // 열람숫자
          // qid=qid, ltype = ‘1
        },
      });

      setQnaData({
        ...qna["list"][0],
        member_cnt: qna["list"][0]["member_cnt"],
      });

      setIsModal(false);
      // navigate(-1);
    } catch (e) {
      console.log("apiPro_invite error -> ", e);
    }
  };

  const apiPro_invite_teacher = async (inSearch) => {
    try {
      let where_obj = {};
      if (inSearch || search) {
        where_obj[`A.name`] = `like '%${inSearch || search}%'`;
      }
      if (inSearch == "") {
        // where_obj["search"] = ""
      }

      let data = await apiFn.comApi({
        state: {
          table: "member_class",
          page: 1,
          amount: 9999,
          sort: "mid desc",
          // ref: [
          //     "HIQDB_teacher.name|HIQDB_teacher.id==HIQDB_member_class.mid",
          // ],
          // ref: [
          //     'A.*|HIQDB_user_credential X on X.name = HIQDB_member_class.mid INNER JOIN HIQDB_user A ON A.index=X.user_id'
          // ],
          where: {
            "HIQDB_member_class.mid": `= '${userInfo["id"]}'`,
          },
        },
      });

      // console.log("DATA : ", data)

      let in_id = data["list"].reduce((a, v, i) => {
        if (i == 0) {
          return (a = `'${v["cid"]}'`);
        } else {
          return (a = a + `,'${v["cid"]}'`);
        }
      }, "");

      let data_2 = await apiFn.comApi({
        state: {
          table: "member_class",
          page: 1,
          amount: 9999,
          sort: "mid desc",
          where: {
            "HIQDB_member_class.cid": `in (${in_id})`,
          },
        },
      });

      let t_data = data_2["list"].map((v) => v["mid"]);

      t_data = [...new Set(t_data)];

      let in_id_teacher = t_data.reduce((a, v, i) => {
        if (i == 0) {
          return (a = `'${v}'`);
        } else {
          return (a = a + `,'${v}'`);
        }
      }, "");

      console.log("where_obj : ", where_obj);

      let teacherData = await apiFn.comApi({
        state: {
          table: "user_credential",
          page: 1,
          amount: 9999,
          sort: "reg_date desc",
          ref: ["A.* |HIQDB_user A on A.index=HIQDB_user_credential.user_id and A.user_type = 2"],
          where: {
            "HIQDB_user_credential.name": `in (${in_id_teacher})`,
            ...where_obj,
          },
        },
      });

      console.log("@@# : ", teacherData);
      setList(teacherData["list"]);

      return teacherData["list"];
    } catch (e) {
      console.log("**");
      console.log(e);
      console.log("**");
    } finally {
    }
  };

  const apiPro_read = async (d, inSearch, isReturn) => {
    try {
      let invited = await apiFn.comApi({
        state: {
          table: "app_msg",
          page: 1,
          amount: 9999,
          sort: "reg_date desc",
          where: {
            "HIQDB_app_msg.qid": `= '${params["qid"]}'`,
          },
        },
      });

      let in_id = invited["list"].reduce((a, v, i) => {
        // return v["mid"].toString()
        // if(v["sid"] == userInfo["id"])return;
        if (i == 0) {
          return (a = `'${v["sid"]}'`);
        } else {
          return (a = a + `,'${v["sid"]}'`);
        }
      }, "");

      let state = {
        page: 1,
        amount: 9999,
        sort: "reg_date desc",
        except: in_id,
      };
      // // console.log("** ",d);
      if (params["who"] == "teacher") {
        state["tid"] = userInfo["id"];
      } else {
        state["sid"] = userInfo["id"];
      }

      if (d) {
        if (params["who"] == "teacher") {
          state["cid"] = d["cid"];
        } else {
          state["cid"] = d["id"];
        }
      }

      if (inSearch || search) {
        state["search"] = inSearch || search;
      }
      if (inSearch == "") {
        state["search"] = "";
      }
      console.log("inSearch : ", inSearch);
      // console.log("state => ",state);
      let data = await apiFn.friendsList({
        state: state,
      });

      console.log("People data => ", data["list"]);
      // setIsLoad(true)
      if (isReturn) {
        if (data["list"].length == 0) {
          return [];
        } else {
          return data["list"];
        }
      } else {
        if (data["list"].length == 0) {
          setList([]);
          return [];
        } else {
          setList([...data["list"]]);
          return data["list"];
        }
      }
    } catch (e) {
      // setIsLoad(true)
      // console.log("@@ ",e)
    }
  };

  const apiPro_in = async (sort, inSearch, r) => {
    try {
      let data = await apiFn.comApi({
        state: {
          table: "online_qna_log",
          page: 1,
          amount: 9999,
          sort: "reg_date desc",
          where: {
            "HIQDB_online_qna_log.qid": `= '${params["qid"]}'`,
          },
        },
      });

      let wid = data["list"].reduce((a, v, i) => {
        return [...a, v["wid"]];
      }, []);

      let findPeople = await apiFn.findPeople({
        state: {
          wid: wid,
        },
      });

      console.log("findPeople : ", findPeople);
      if (findPeople.length == 0) {
        return [];
        // setFriendsList([])
      } else {
        // setFriendsList([
        //     ...findPeople["list"]
        // ])
        if (r) return findPeople["list"];
      }
    } catch (e) {
      console.log("apiPro_in : ", e);
    } finally {
      // setIsLoading(false)
    }
  };

  const apiPro_invite_all = async (d, search) => {
    try {
      let reqTime = new Date();
      // let student = await apiPro_read("", search, true);
      // let teacher = await apiPro_invite_teacher(search);

      let result = await Promise.all([
        apiPro_read("", search, true),
        apiPro_invite_teacher(search),
      ]);

      let student = result[0];
      let teacher = result[1];

      console.log("student : ", student);
      console.log("teacher : ", teacher);

      let inviter = await apiPro_in(false, false, true);
      console.log("inviter : ", inviter);
      let inviter_id = inviter.reduce((a, v, i) => {
        return [...a, v["id"]];
      }, []);

      console.log("inviter : ", inviter_id);

      let cc = teacher.concat(student);

      let fr_arr = [];

      cc.map((v) => {
        if (inviter_id.includes(v["id"])) {
        } else fr_arr.push(v);
      });

      setList([
        ...fr_arr,
        // ...teacher,
        // ...student,
      ]);

      setOriList([...fr_arr]);

      console.log("End reqTime : ", (new Date() - reqTime) / 1000);
    } catch (e) {
      console.log("************");
      console.log(e);
      console.log("************");
    } finally {
      setIsLoading(false);
    }
  };

  const filter_ = async (d, inSearch) => {
    try {
      let result = oriList.filter((v) => {
        // if(v["name"].includes(inSearch) || v["nick"].includes(inSearch) || v["tel"].includes(inSearch)){
        if (v["name"].includes(inSearch) || v["nick"].includes(inSearch)) {
          return v;
        }
      });
      // console.log("search result : ",result)
      setList([...result]);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    // setIsLoading(true)
    // console.log("MODAL qnaData : ", qnaData)
    apiPro_invite_all();
  }, []);

  useEffect(() => {
    console.log("selectData : ", selectData);
  }, [selectData]);

  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        zIndex: 130002,
        backgroundColor: "rgba(150, 150, 150,0.5)",
        // backgroundColor:"red",
        width: "100%",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
      onClick={() => {
        setIsModal(false);
      }}
    >
      <div
        style={{
          width: "75%",
          height: "70%",
          // minHeight:"55%",
          // maxHeight:"80%",
          backgroundColor: "white",
          padding: "15px 20px 30px 20px",
          borderRadius: 10,
          border: "1px solid rgb(200, 200, 200)",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          filter: "drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.37))",
        }}
        onClick={(e) => {
          console.log("###");
          e.stopPropagation();
          // e.preventDefault();
        }}
      >
        <div
          style={{
            fontWeight: 500,
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          질문 초대 목록
          {!isLoading && oriList.length > 0 && (
            <div
              style={{
                display: "flex",
              }}
            >
              <div
                style={{
                  display: "flex",
                  fontSize: "12px",
                  color: style.common.t_color,
                  marginRight: "7px",
                  fontWeight: "bold",
                }}
                onClick={() => {
                  if (isAll) {
                    setIsAll(false);
                    setSelectData({});
                  } else {
                    if (Object.keys(selectData).length) {
                      setIsAll(false);
                      setSelectData({});
                    } else {
                      setIsAll(true);
                      setSelectData(
                        list.reduce((a, v) => {
                          return {
                            ...a,
                            [v["id"]]: "Y",
                          };
                        }, {})
                      );
                    }
                  }
                }}
              >
                {isAll
                  ? "선택 취소"
                  : Object.keys(selectData).length > 0
                    ? "선택 취소"
                    : "모두 선택"}
                {/* 모두 선택 */}
              </div>
              <div
                style={{
                  display: "flex",
                  fontSize: "12px",
                  color: style.common.s_color,
                }}
                onClick={() => {
                  let isOk = window.confirm(
                    `선택하신 ${Object.keys(selectData).length}명을 질문에 초대하시겠어요?`
                  );
                  if (!isOk) return;
                  apiPro_invite();
                }}
              >
                질문 초대
              </div>
            </div>
          )}
        </div>
        {isLoading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flex: 1,
            }}
          >
            <UiLoading isTransparent={true} isFull={false} text="초대 가능한 사람을 찾고 있어요." />
          </div>
        ) : oriList.length == 0 ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flex: 1,
              fontWeight: 600,
              color: style.common.t_color,
            }}
          >
            초대 가능한 사람이 없어요.
          </div>
        ) : (
          <>
            <SearchBox
              filter_={filter_}
              search={search}
              setSearch={setSearch}
              apiPro_read={apiPro_invite_all}
            />
            <div
              style={{
                paddingTop: "5px",
                overflowY: "scroll",
                display: "flex",
                justifyContent: "flex-start",
                flexDirection: "column",
                flex: 1,
              }}
            >
              {list.map((v) => (
                <Member
                  selectData={selectData}
                  params={params}
                  data={v}
                  id={v["id"]}
                  userInfo={userInfo}
                  fnCheck={(e) => {
                    if (selectData[e.target.id] == "Y") {
                      delete selectData[e.target.id];
                      setSelectData({
                        ...selectData,
                      });
                    } else {
                      setSelectData({
                        ...selectData,
                        [e.target.id]: "Y",
                      });
                    }
                  }}
                />
              ))}
            </div>
          </>
        )}
        {/* <div style={{
                    height:"40px",
                    display:"flex",
                    justifyContent:"center",
                }}>
                    <UiBtn
                        btnStyle={{
                            width:"90px",
                            height:"36px",
                            backgroundColor:style.common.t_color,
                            color : "white",
                            fontStyle:"12px",
                        }}
                        title={"닫기"}
                        fnClick={()=>{
                            setIsModal(false)
                        }}
                    />
                </div> */}
      </div>
    </div>
  );
};

const Member = ({ id, isChk, selectData, isTeacher = true, fnCheck, data, params, userInfo }) => {
  let navigate = useNavigate();

  // useEffect(()=>{
  //     console.log("DATA : ",data)
  // },[data])

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        maxWidth: style.common.maxWidth,
        // flex:1,
        // padding : "0px 10px",
      }}
    >
      <div
        style={{
          display: "flex",
          // margin : "5px 0px",
          padding: "7px 0px",
          width: "100%",
          alignItems: "center",
          // borderBottom : "1px solid #f6f6f6"
        }}
      >
        {params["who"] == "student" || params["tid"] != userInfo["id"] ? (
          <></>
        ) : (
          // isChk &&
          data["id"] != userInfo["id"] && (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginRight: "10px",
              }}
            >
              <UiCheckBox
                className={isTeacher ? "t" : "s"}
                // value={"?"}
                // className={"message_?"}
                checked={selectData[id] == "Y" ? true : false}
                value={id}
                fnClick={fnCheck}
                containerStyle={{
                  padding: "0px",
                }}
              />
            </div>
          )
        )}
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
          onClick={() => {
            // if(data["id"] == userInfo["id"])return;
            // console.log("DATA : ",data)
            // let isOk = window.confirm(`${data["name"]}(${data["nick"]}) 선생님께 질문방을 이관하시겠어요?`)
            // if(!isOk)return;
            // apiPro_transfer(data)
          }}
        >
          <div
            style={{
              // width : '20%',
              display: "flex",
              alignItems: "center",
              marginRight: "10px",
            }}
            onClick={() => {}}
          >
            <div
              style={{
                ...UiPeople_Style.section_i_w,
                width: "40px",
                height: "40px",
              }}
            >
              <img
                style={UiMessage_Style.section_i}
                onError={(e) => {
                  // setIsLoad(false)
                  e.currentTarget.src = data?.user_type === 2 ? face_teacher : face_student;
                }}
                src={
                  data
                    ? data["img"]
                      ? constants.s3_url +
                        `${data?.user_type === 2 ? "/teacher/" : "/student/"}${data["img"]}` +
                        ".jpg?"
                      : constants.apiUrl +
                        `${data?.user_type === 2 ? "/teacher/" : "/student/"}${data["id"].normalize("NFD")}` +
                        ".jpg?"
                    : data?.user_type === 2
                      ? face_teacher
                      : face_student
                }
              />
            </div>
          </div>
          <div
            style={{
              display: "flex",

              alignItems: "flex-end",
              // alignItems:"center",
              // width : '100%',
              // height : "100%",
            }}
            onClick={() => {}}
          >
            <div
              style={{
                marginRight: "5px",
                fontSize: "14px",
                fontWeight: 500,
              }}
            >
              {`${data["name"] ? data["name"] : "이름없음"} (${data["nick"] ? data["nick"] : ""})`}
            </div>
            <div
              style={{
                fontWeight: 300,
                fontSize: "12px",
              }}
            >
              {data?.user_type === 2 ? "선생님" : "학생"}
            </div>
          </div>
        </div>
      </div>
      {}
    </div>
  );
};

const SearchBox = ({ search, setSearch, apiPro_read, filter_ }) => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        height: "28px",
        marginTop: "5px",
        // width:"230px",
      }}
    >
      <UiInput
        type="basic"
        basic_container_style={{
          height: "94%",
          padding: "0px 0px 0px 5px",
          // padding : "0px 10px"
        }}
        inputValue={search}
        fnChange={(e) => {
          setSearch(e.target.value);
          search = e.target.value;
          filter_("", e.target.value);
          // apiPro_read("",e.target.value)
        }}
        placeholder={"검색"}
        // fnChange={fnChange}
      />
    </div>
  );
};

export default Modal_invite;
