import React from 'react'
import styles from './style.module.scss'
import style from "../../../asset/style";
import {CameraAlt} from "@material-ui/icons";
import UiTextBtn from "../../../uiComponents/UiTextBtn/UiTextBtn";
import UiMessage_Style from "../../../uiComponents/UiMessage/UiMessage_Style";
import {useNavigate} from "react-router";

const UploadQuestionModal = ({fileObj, onChange, onSubmit, onCancel}) => {

    const navigate = useNavigate()

    return <button
        className={styles.ToSAgreementModalContainer}
        style={{...UiMessage_Style.section_r_t_t_text_1, border: "none"}}
        onClick={(e) => {
        }}
    >
        <div
            className={styles.ToSAgreementModalContent}
        >
            <div>
                <input
                    id="picture_find"
                    type="file"
                    accept="image/*"
                    style={{
                        display: "none"
                    }}
                    onChange={onChange}
                />
                <label
                    htmlFor='picture_find'
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                        width: '100%',
                        height: 40,
                        backgroundColor: style.common.t_color,
                        color: 'white',
                        margin: '32px 0',
                        borderRadius: 8
                    }}
                >
                    <CameraAlt/>
                    <div style={{fontSize: 12, fontWeight: 500, marginLeft: 6}}>문제 사진 업로드</div>
                </label>
            </div>

            {
                fileObj?.base64 && <>
                    <img src={fileObj.base64} style={{maxHeight: 300}}/>
                    <button
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "center",
                            alignItems: "center",
                            height: 40,
                            backgroundColor: style.common.s_color,
                            border: 'none',
                            color: 'white',
                            margin: '32px 0',
                            borderRadius: 8,
                            position: 'absolute', left: 10, right: 10, bottom: 60,
                        }}
                        onClick={() => onSubmit(fileObj)}
                    >
                        <div style={{fontSize: 12, fontWeight: 500, marginLeft: 6}}>해설 등록</div>
                    </button>
                </>
            }

            <div style={{position: 'absolute', left: 0, right: 0, bottom: 30}}>
                <UiTextBtn
                    text="취소"
                    btnStyle={{textAlign: 'center'}}
                    isTeacher={false}
                    isUnderline={false}
                    fnClick={onCancel}
                />
            </div>
        </div>
    </button>
}

export default UploadQuestionModal
