import React, { useRef } from "react";
import { LatexView } from "../../../../../components/atoms/latexView";
import IShare from "../../../../../components/atoms/icons/share";
import {
  ActionButtonBlock,
  ActionButtonsList,
  AnswerSectionSelectListBox,
  Container,
  ContentBox,
  ContentWrapper,
  MainSection,
  SelectBox,
  SendButton,
  SideButton,
  SideButtonSection,
  ToggleButton,
} from "./styled";
import { IcEdit } from "../../../../../components/atoms/icons/edit";
import { IcUp } from "../../../../../components/atoms/icons/up";
import { IcCheck } from "../../../../../components/atoms/icons/check";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { editShowModalAtom } from "../../../../../application/recoils/modal/atoms";
import { EditModalEnum } from "application/recoils/modal/types";
import { answerContentAtom } from "../../../../../application/recoils/answer/atom";
import { IcArrowRotateLeft } from "components/atoms/icons/arrowRotateLeft";
import ICopyRight from "../../../../../components/atoms/icons/copyRight";
import { captureSizeSheetAtom } from "../../../../../application/recoils/sheet/atoms";

const theme = {
  primaryColor: "#fe544e",
  secondaryColor: "#ffa04f",
  iconColor: "#8c8c8c",
};

type PreAnswerViewProps = {
  onSendAnswer: (answerContent: string) => void;
  onHidePreAnswerView?: () => void;
  onRefreshAnswer?: () => void;
};

export default function PreAnswerView({
  onSendAnswer,
  onHidePreAnswerView,
  onRefreshAnswer,
}: PreAnswerViewProps): JSX.Element {
  const content = useRecoilValue(answerContentAtom);
  const setShowEditModal = useSetRecoilState(editShowModalAtom);
  const setShowCaptureSizeSheet = useSetRecoilState(captureSizeSheetAtom);
  const AnswerRef = useRef<HTMLDivElement | null>(null);
  const initialSelectAnswerSection: boolean[] = JSON.parse(
    window.localStorage.getItem("selectAnswerSection") || "[true, true, true]"
  );
  const [selectAnswerSection, setSelectAnswerSection] = React.useState<boolean[]>(
    initialSelectAnswerSection
  );

  const handleShowQuestionEditModal = () => {
    setShowEditModal(EditModalEnum.QUESTION);
  };

  const handleShowAnswerEditModal = () => {
    setShowEditModal(EditModalEnum.ANSWER);
  };

  const handleCopyAnswer = () => {
    setShowCaptureSizeSheet({ isOpen: true, targetRef: AnswerRef });
  };
  interface Section {
    title: string;
    content: string;
  }
  const parseContent = (text: string): Section[] => {
    const sections = text.split(/##\s/).filter(Boolean);
    return sections.map((section) => {
      const [title, ...content] = section.split("\n");
      return {
        title: title.trim(),
        content: content.join("\n").trim(),
      };
    });
  };

  const handleSelectAnswerSend = () => {
    const parsedContent = parseContent(content);

    if (selectAnswerSection.every((section) => !section)) {
      alert("보낼 내용을 선택하지 않았습니다.");
    } else {
      onSendAnswer(
        parsedContent
          .filter((_, i) => selectAnswerSection[i])
          .map((section) => `## ${section.title}\n${section.content}`)
          .join("\n\n")
      );
    }
  };

  const handleSelectAnswerSection = (index: number) => {
    const newSelectAnswerSection = [...selectAnswerSection];
    newSelectAnswerSection[index] = !newSelectAnswerSection[index];
    setSelectAnswerSection(newSelectAnswerSection);
    window.localStorage.setItem("selectAnswerSection", JSON.stringify(newSelectAnswerSection));
  };
  return (
    <Container>
      <ContentWrapper>
        <SideButtonSection>
          <SideButton
            aria-label="edit-question"
            background={theme.primaryColor}
            onClick={handleShowQuestionEditModal}
          >
            <IcCheck />
            문제인식수정
          </SideButton>
          {/*TODO: 힌트 기능이 붙는다면 사용할 버튼 */}
          {/*  <SideButton background={theme.secondaryColor}>힌트전달</SideButton>*/}
        </SideButtonSection>
        <MainSection>
          <ContentBox>
            <div ref={AnswerRef}>
              <LatexView>{content}</LatexView>
            </div>
            <AnswerSectionSelectListBox>
              <SelectBox>
                {["분석", "방법", "해설"].map((type, index) => (
                  <label
                    key={index}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "4px",
                    }}
                  >
                    <input
                      type={"checkbox"}
                      checked={selectAnswerSection[index]}
                      onChange={() => handleSelectAnswerSection(index)}
                    />
                    {type}
                  </label>
                ))}
              </SelectBox>
            </AnswerSectionSelectListBox>
            <ActionButtonBlock>
              <SendButton aria-label="send-message" onClick={handleSelectAnswerSend}>
                <IShare width={18} height={18} color={"#FFF"} />
                답변전송
              </SendButton>
              <ActionButtonsList>
                <button aria-label="Share" onClick={handleShowAnswerEditModal}>
                  <IcEdit width={18} height={18} color={theme.iconColor} />
                </button>
                <button aria-label="copy" onClick={handleCopyAnswer}>
                  <ICopyRight width={18} height={18} color={theme.iconColor} />
                </button>

                <button aria-label="refresh" onClick={onRefreshAnswer}>
                  <IcArrowRotateLeft width={18} height={18} color={theme.iconColor} />
                </button>
              </ActionButtonsList>
            </ActionButtonBlock>
          </ContentBox>
          <ToggleButton aria-label="close-pre-nswer" onClick={onHidePreAnswerView}>
            접기 <IcUp />
          </ToggleButton>
        </MainSection>
      </ContentWrapper>
    </Container>
  );
}
