import { useClassDetailInfo } from "api/classDetail";
import constants from "asset/constants";
import util from "asset/util";
import HorizontalBlank from "components/atoms/HorizontalBlank";
import BottomSheet from "components/molecules/BottomSheet";
import dayjs from "dayjs";
import React, { useMemo } from "react";
import { useParams } from "react-router";
import styled from "styled-components";

const ClassViewLog = ({ open, onClose }: CommonModalControl) => {
  const params = useParams();
  const parameters = {
    boardId: params["id"],
  };
  const { data } = useClassDetailInfo(parameters);
  const videoData = useMemo(() => data?.getBoard?.boards[0], [data]);
  const viewLogs = useMemo(() => {
    const logs = videoData?.logs;
    const viewLogs = logs?.filter((log) => log.btype === 1);
    return viewLogs;
  }, [videoData]);
  return (
    <BottomSheet open={open} onClose={onClose}>
      <Container>
        <TitleFont>{"시청자 목록"}</TitleFont>
        <LogListContainer>
          {viewLogs?.map((log) => {
            const who = log.viewer?.user_type === 2 ? "teacher" : "student";
            const imageUrl = `${constants.s3_url}/${who}/${log?.viewer?.id}.jpg`;
            const timeInPlayFormated = util.formatTimeKr(log.class_video_play_time);
            let progressPercent = Math.round(
              (log.class_video_play_time / videoData.video_end) * 100
            );
            if (progressPercent > 100) progressPercent = 100;
            return (
              <LogItemContainer key={log.id}>
                <LogViewerPicture src={imageUrl} />
                <LogFontContainer>
                  <LogViewerNameFont>{log.viewer.name}</LogViewerNameFont>
                  <span>
                    {`재생 시작: ${dayjs(log.class_video_start_date_time).format(
                      "YYYY/MM/DD hh:mm:ss"
                    )}`}
                  </span>
                  <span>시청시간: {timeInPlayFormated}</span>
                  <span>{`최대재생속도: ${log?.class_video_max_play_speed}x`}</span>
                </LogFontContainer>
                <LogPercentFont>{progressPercent}%</LogPercentFont>
              </LogItemContainer>
            );
          })}
        </LogListContainer>
        <HorizontalBlank height={24} />
        <Button onClick={onClose} type={"button"}>
          닫기
        </Button>
      </Container>
    </BottomSheet>
  );
};

const Container = styled.div`
  padding: 36px 19px 18px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const TitleFont = styled.span`
  color: #21272a;
  text-align: center;
  font-family: Pretendard;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 130%; /* 26px */
  letter-spacing: 0.4px;
  text-transform: uppercase;
`;
const Button = styled.button`
  outline: none;
  border: none;
  background: #fff;
  display: flex;
  height: 39px;
  padding: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
  color: #a2a9b0;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;

const LogListContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const LogItemContainer = styled.div`
  padding: 20px 0;
  color: var(--neutral-neutral_06, #697077);
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%; /* 18.2px */
  letter-spacing: 0.28px;
  text-transform: capitalize;
  display: flex;
  gap: 16px;
  align-items: center;
  border-bottom: 1px solid #e5e5e5;
  :last-child {
    border-bottom: none;
  }
`;

const LogFontContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  justify-content: center;
  align-items: flex-start;
`;

const LogPercentFont = styled.div`
  flex: 1;
  align-items: center;
  display: flex;
  justify-content: flex-end;
`;
const LogViewerNameFont = styled.div`
  color: var(--neutral-neutral_06, #21272a);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 130%; /* 18.2px */
  letter-spacing: 0.28px;
  text-transform: capitalize;
`;

const LogViewerPicture = styled.img`
  border-radius: 40px;
  width: 40px;
  height: 40px;
`;

export default ClassViewLog;
