import { React , useState , useEffect } from 'react'
import face_teacher from '../../asset/Img/defaultpic_teacher.png'
import face_student from '../../asset/Img/defaultpic_student.png'
import Message_talk_style from './Message_talk_style'
import { useNavigate, useParams } from 'react-router'
import { ArrowBackIos, ArrowForward, ArrowForwardIos, LocalActivity, MoreVert, Star, StarBorder } from '@material-ui/icons'
import bookmark_off from '../../asset/Img/bookmark_off.png'
import bookmark_on from '../../asset/Img/bookmark_on.png'
import metoo_off from '../../asset/Img/metoo_off.png'
import metoo_on from '../../asset/Img/metoo_on.png'
import review from '../../asset/Img/review.png'
import defaultpic_student from '../../asset/Img/defaultpic_student.png'
import defaultpic_teacher from '../../asset/Img/defaultpic_teacher.png'
import style from '../../asset/style'
import apiFn from '../../asset/apiClass'
import constants from '../../asset/constants'
import util from '../../asset/util'
import UiTextBtn from '../../uiComponents/UiTextBtn/UiTextBtn'
import noBook from '../../asset/Img/book1.png'
import main_logo from '../../asset/Img/main_logo.png'
import intro_logo from '../../asset/Img/intro_logo.png'
import store from "../../asset/store";

const Message_talk_header_type_b = ({
    userInfo,
    headerData,
    setQnaData_app,
    setFileObj,
    setHiddenUrl,
    endWs,
    headerInfo,
    talkList,
    // setHeaderInfo
}) => {

    let params = useParams();
    let navigate = useNavigate();

    // const [headerInfo,setHeaderInfo] = useState({});

    var [isVert,setIsVert] = useState(false);

    const apiPro_img = async (e,data) => {
        try {
            setFileObj({
                name : ``,
                file : e
            })
            setQnaData_app({
                tid : headerInfo["id"]
            })
            setHiddenUrl(window.location.pathname)
            navigate(`/crop/${params["who"]}/se`)
        }
        catch(e){
            // console.log("apiPro_img Error= >",e)
        }
    }

    const apiPro_del = async (isRe) => {
        try {
            // console.log("talklist : ",talkList[0])
            // return;
            let hide = {}
            if(params["who"] == "teacher"){
                hide["t_hide_date"] = isRe ? "del" : 0
            }
            else {
                hide["s_hide_date"] = isRe ? "del" : 0
            }

            await apiFn.comApi({
                state : {
                    table : "app_msg",
                    set: [{
                        key: `id:${talkList[0]["id"]}`,
                        value: hide
                    }]
                }
            })

            const stack = store.get('stack')
            if(stack.length > 0)
                navigate(-1);
            else
                navigate('/')

        }
        catch(e){
            console.log(e);
        }
    }

    // useEffect(()=>{
    //     apiPro_read()
    // },[])

    useEffect(()=>{
        // console.log("headerInfo => ",headerInfo);
    },[headerInfo])

    useEffect(()=>{
        // console.log("headerData => ",headerData);
    },[headerData])

    return (
        <div style={{
            width : "100%",
            display:"flex",
            flexDirection:"column",
            boxShadow: "rgba(0, 0, 0, 0.05) 0px 2.5px 15px 0px",
            // height : '60px',
            backgroundColor:"white",
        }}>
            <div style={{
                display:"flex",
                padding : '15px 10px 10px 10px',
                justifyContent:"space-between",
            }}>
                <div style={{
                    display:"flex",
                }}>
                    <div style={{
                        display : "flex",
                        // flexGrow : 1,
                        height:'100%',
                        // maxWidth : "70px",
                    }}>
                        <div style={Message_talk_style.s_1_l_icon}>
                            <ArrowBackIos
                                style={{
                                    backgroundColor : ''
                                }}
                                onClick={()=>{
                                    endWs();

            const stack = store.get('stack')
            if(stack.length > 0)
                navigate(-1);
            else
                navigate('/')
                                }}
                            />
                        </div>
                        <div style={{
                            minWidth : "44px",
                            maxWidth:"56px",
                            height : "56px",
                        }} >
                            <img
                                style={{
                                    width:'100%',
                                    height:"100%",
                                    objectFit:'cover',
                                    objectPosition:'center'
                                }}
                                onError={(e)=>{
                                    // setIsLoad(false)
                                    // e.currentTarget.src =  face_teacher
                                    if(params["who"] == "student"){
                                        e.currentTarget.src =  face_teacher
                                    }
                                    else {
                                        e.currentTarget.src =  face_student
                                    }
                                }}
                                src={
                                    (params["sid"] == "hiqsum_admin" || params["tid"] == "hiqsum_admin" )
                                    ?
                                    intro_logo
                                    :
                                    (
                                        headerInfo["img"] || headerInfo["id"] ?
                                        (
                                            params["who"] == "teacher" ?
                                            (
                                                headerInfo["img"] ?
                                                `${constants.s3_url}/student/${headerInfo["img"]}.jpg`
                                                :
                                                `${constants.s3_url}/student/${headerInfo["id"].normalize('NFD')}.jpg?${util.getRandomInt(1000,9999)}`
                                            )
                                            :
                                            (
                                                headerInfo["img"] ?
                                                `${constants.s3_url}/teacher/${headerInfo["img"]}.jpg`
                                                :
                                                `${constants.apiUrl}/teacher/${headerInfo["id"] && headerInfo["id"].normalize('NFD')}.jpg?${util.getRandomInt(1000,9999)}`
                                            )
                                        )
                                        :
                                        params["who"] == "teacher" ? face_student : face_teacher
                                    )
                                    // params["who"] == "teacher" ?
                                    // (
                                    // userInfo["id"] == data["tid"] && data["wtype"] == '2'
                                    // ? `${constants.apiUrl}/public/teacher/${userInfo["id"].normalize('NFD')}.jpg?${util.getRandomInt(1000,9999)}`
                                    // : `${constants.apiUrl}/public/student/${data["sid"].normalize('NFD')}.jpg?${util.getRandomInt(1000,9999)}`
                                    // )
                                    // :
                                    // (
                                    // userInfo["id"] == data["sid"] && data["wtype"] == '1'
                                    // ? `${constants.apiUrl}/public/student/${userInfo["id"].normalize('NFD')}.jpg?${util.getRandomInt(1000,9999)}`
                                    // : `${constants.apiUrl}/public/teacher/${data["tid"].normalize('NFD')}.jpg?${util.getRandomInt(1000,9999)}`
                                    // )
                                }
                                // style={{
                                //     width : "60px",
                                //     // height:'100%',
                                // }}
                                onClick={()=>{
                                    if(params["sid"] == "hiqsum_admin" || params["tid"] == "hiqsum_admin" ){
                                        return;
                                    }
                                    else {
                                        navigate(`/myinfo/${params["who"]}/${headerInfo["id"]}`)
                                    }
                                }}
                            />
                        </div>
                    </div>
                    <div style={{
                        display : "flex",
                        flexDirection:"column",
                        justifyContent:"space-between",
                        marginLeft : "15px",
                        // padding : '15px 10px 10px 10px',
                        // flexGrow : 1,
                        // height:'100%',
                        height : "56px",
                    }}>
                        <div style={{
                            fontSize:"16px"
                        }}>
                            {/* 학생닉 (김학생) */}
                            {
                            (params["sid"] == "hiqsum_admin" || params["tid"] == "hiqsum_admin" )
                            ?
                                "시스템 메세지"
                            :
                            (
                                params["who"] == "teacher" ? `${headerInfo["nick"]} (${headerInfo["name"]})`
                                :
                                `${headerInfo["name"] ? headerInfo["name"] : "(탈퇴한 회원)"}`
                            )
                            }
                        </div>
                        <div style={{
                            fontSize : "12px",
                            color : style.common.fontColor_6,
                        }}>
                            <div style={{
                                marginBottom:"2px"
                            }}>
                                {/* 시대인재(강나) */}
                                {
                                headerInfo["class"] &&
                                (
                                headerInfo["class"].length > 1  ?
                                `${headerInfo["class"][0]["academy"]} 외 ${headerInfo["class"].length - 1}건`
                                :
                                headerInfo["class"][0] && `${headerInfo["class"][0]["academy"]}`
                                )
                                }
                            </div>
                            <div>
                                일반 대화
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <MoreVert
                        style={{
                            position:"relative"
                        }}
                        onClick={()=>{
                            setIsVert(!isVert)
                        }}
                    />
                    {
                    isVert &&
                    (
                        (
                            (params["who"] == "teacher" && talkList[0]["t_hide_date"])
                            ||
                            (params["who"] == "student" && talkList[0]["s_hide_date"])
                        )
                        ?
                        <div style={{
                            padding:"3px",
                            fontSize:"10px",
                            border : "1px solid",
                            borderColor : style.common.borderColor,
                            position:"absolute",
                            backgroundColor:"white",
                            top:40,
                            right:10
                        }} onClick={()=>{
                            apiPro_del(true);
                        }}>
                            복구하기
                        </div>
                        :
                        <div style={{
                            padding:"3px",
                            fontSize:"10px",
                            border : "1px solid",
                            borderColor : style.common.borderColor,
                            position:"absolute",
                            backgroundColor:"white",
                            top:40,
                            right:10
                        }} onClick={()=>{
                            apiPro_del();
                        }}>
                            숨기기
                        </div>
                    )
                    }
                </div>
            </div>
            {
            (
                params["who"] == "student" &&
                (params["sid"] != "hiqsum_admin" && params["tid"] != "hiqsum_admin" )
            )
            &&
            <UiTextBtn
                isFile={true}
                accept="image/*"
                text={
                    <div
                        style={{
                            display:"flex",
                            width :"96%",
                            justifyContent:"center",
                            fontSize : "12px",
                            padding : "10px 0px",
                            borderTop : "1px solid",
                            borderColor : style.common.borderColor,
                            alignItems:"center",
                            color : "black"
                        }}
                    >
                        <LocalActivity
                            style={{
                                marginBottom:"2px",
                                padding : "0px 5px",
                                color : "orange"
                            }}
                        />
                        선생님에게 문제 질문하기
                    </div>
                }
                fnClick={(e)=>{
                    apiPro_img(e)
                }}
                isUnderline={false}
            />
            // <div style={{
            //     display:"flex",
            //     width :"100%",
            //     justifyContent:"center",
            //     fontSize : "14px",
            // }}
            //     onClick={()=>{
            //         apiPro_img()
            //     }}
            // >

            // </div>
            }
        </div>
    )
}

export default Message_talk_header_type_b
