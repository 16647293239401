import { QueryClient } from "@tanstack/react-query";
import { getUser } from "asset/storage";
import { PaddingContainer } from "components/atoms/Container";
import Font from "components/atoms/Font";
import Section from "components/atoms/Section";
import Footer from "components/molecules/Footer";
import React, { Suspense, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { WhoType } from "types/enum";
import UiLoading from "uiComponents/UiLoading/UiLoading";
import ClassBelong from "./components/ClassBelong";
import LectureHeader from "./components/LectureHeader";
import LectureConfigButton from "./components/LectureRegistButton";
import LectureVideoSlider from "./components/LectureVideoSlider";

const LectureVideo = () => {
  const navigate = useNavigate();
  const userInfo = getUser();
  const isTeacher = useMemo(() => userInfo?.type === WhoType.teacher, [userInfo]);
  const queryClient = new QueryClient();
  console.log("queryClient", queryClient);
  return (
    <StyledPaddingContainer>
      <Suspense
        fallback={
          <UiLoading
            isTransparent={true}
            isText={false}
            isCircle={false}
            text="수업 내용을 불러오는 중입니다."
          />
        }
      >
        <LectureHeader />
        <ReactiveContainer>
          <Section direction="column">
            <TitleContainer>
              <Font
                color="var(--neutral-color-natural-70)"
                tagName="div"
                styleName="KR/bold/$KR.BL.BOL.16"
              >
                최근 수업영상
              </Font>
              <a onClick={() => navigate("/class/video/list")}>
                <Font
                  styleName="KR/regular/$KR.CAP.REG.12"
                  color="var(--neutral-color-natural-50, #8C8C8C)"
                >
                  전체보기
                </Font>
              </a>
            </TitleContainer>
            <LectureVideoSlider />
          </Section>
          <ClassBelong />
        </ReactiveContainer>
        {isTeacher && <LectureConfigButton />}
      </Suspense>
      <Footer />
    </StyledPaddingContainer>
  );
};

const ReactiveContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 34px;
  @media screen and (min-width: 1180px) {
    flex-direction: row;
    gap: 110px;
    height: calc(100vh - 63px - 24px - 24px - 40px);
    justify-content: space-between;
    > div:first-child {
      flex: 55;
    }
    > div:last-child {
      flex: 45;
    }
  }
`;

const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 24px 0 16px;
  @media screen and (min-width: 1180px) {
    margin-bottom: 43px;
  }
`;

const StyledPaddingContainer = styled(PaddingContainer)`
  @media screen and (min-width: 1180px) {
    height: 100vh;
    margin-bottom: 0;
  }
`;

export default LectureVideo;
