import React from "react";
import styled from "styled-components";
interface ErrorMessageProps {
  children: string;
}
const ErrorMessage = ({ children }: ErrorMessageProps) => {
  return children ? <MessageText>{children}</MessageText> : null;
};

const MessageText = styled.span`
  color: #d32f2f;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  margin-top: 8px;
`;

export default ErrorMessage;
