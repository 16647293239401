import { z } from "zod";

export enum ActionEnum {
  SET = "set",
  DELETE = "delete",
}

const actionEnum = z.nativeEnum(ActionEnum);

const commandSchema = z.object({
  key: z.string(),
  value: z.string(),
  action: actionEnum,
});

export type StatStoreDTO = z.infer<typeof commandSchema>;

export type MetadataPramsType = {
  key?: string;
  value: string;
  action?: ActionEnum;
};
