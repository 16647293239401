interface IUserInfo {
  jwt: string;
  id: string;
  provider: string;
  name: string;
  img: string;
  type: number;
}
const getUser = () => {
  try {
    if (window.localStorage.getItem("user")) {
      return JSON.parse(window.localStorage.getItem("user")) as IUserInfo;
    }
  } catch (e) {}
};
const getPw = () => {
  try {
    if (window.localStorage.getItem("pw")) {
      return window.localStorage.getItem("pw");
    }
  } catch (e) {}
};

const setStudybookPageIndex = ({ bookId, index }) => {
  const studybookStorageObject = JSON.parse(localStorage.getItem("study_book_pdf_index")) ?? {};

  studybookStorageObject[bookId] = index;
  localStorage.setItem("study_book_pdf_index", JSON.stringify(studybookStorageObject));
};

const getStudybookPageIndex = ({ bookId }) => {
  const studybookStorageObject = JSON.parse(localStorage.getItem("study_book_pdf_index")) ?? {};

  return studybookStorageObject[bookId] as string;
};

export { getPw, getStudybookPageIndex, getUser, setStudybookPageIndex };
