import { TransformComponent, TransformWrapper } from "@kokarn/react-zoom-pan-pinch";
import { ArrowBackIos } from "@material-ui/icons";
import React, { CSSProperties, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { WhoIs } from "types/enum";
import noBook from "../../asset/Img/book1.png";
import apiFn from "../../asset/apiClass";
import constants from "../../asset/constants";
import store from "../../asset/store";
import style from "../../asset/style";
import util from "../../asset/util";
import UiReactPlayer from "../../uiComponents/UiReactPlayer/UiReactPlayer";
import UiTextBtn from "../../uiComponents/UiTextBtn/UiTextBtn";
import Message_talk_style from "./Message_talk_style";

const ViewQna = ({
  setFileObj,
  youtube_url,
  senderInfo,
  setSenderInfo,
  videoInfo,
  userInfo,
  fileObj,
  qnaData_app,
  setHiddenUrl,
}) => {
  const params = useParams();
  const navigate = useNavigate();

  const [qnaData, setQnaData] = useState<Record<string, any>>({});
  const [profile, setProfile] = useState({});

  const [isGetAuth, setIsGetAuth] = useState(false);

  const apiPro_read_qna = async () => {
    try {
      let data = await apiFn.comApi({
        state: {
          table: "book_link",
          page: 1,
          amount: 9999,
          sort: "reg_date desc",
          ref: [
            "HIQDB_online_qna.title|HIQDB_book_link.qid=HIQDB_online_qna.id",
            "HIQDB_online_qna.img|HIQDB_book_link.qid=HIQDB_online_qna.id",
            "HIQDB_online_qna.original|HIQDB_book_link.qid=HIQDB_online_qna.id",
            "HIQDB_online_qna.original_img|HIQDB_book_link.qid=HIQDB_online_qna.id",
            "HIQDB_online_qna.tid|HIQDB_book_link.qid=HIQDB_online_qna.id",
          ],
          left: [
            "HIQDB_book.img as b_img|HIQDB_book_link.bid=HIQDB_book.id",
            "HIQDB_book.id|HIQDB_book_link.bid=HIQDB_book.id",
            "HIQDB_book.name|HIQDB_book_link.bid=HIQDB_book.id",
            "HIQDB_book.reg_date|HIQDB_book_link.bid=HIQDB_book.id",
            "HIQDB_book.reg_id|HIQDB_book_link.bid=HIQDB_book.id",
          ],
          where: {
            "HIQDB_book_link.qid": `= '${params["qid"]}'`,
          },
        },
      });

      console.log("apiPro read qna => ", data);
      if (data["list"].length > 0) {
        setQnaData({
          ...data["list"][0],
        });
        apiPro_book_type(data["list"][0]["bid"]);
      }
    } catch (e) {}
  };

  const apiPro_book_type = async (bid) => {
    try {
      let isOk = await apiFn.comApi({
        state: {
          table: "book_link",
          page: 1,
          amount: 9999,
          sort: "mid",
          where: {
            "HIQDB_book_link.mid": `= '${userInfo["id"]}'`,
            "HIQDB_book_link.bid": `= '${bid}'`,
          },
        },
      });

      console.log("type Chk Data : ", isOk);
      if (isOk["list"].length > 0) {
        if (isOk["list"][0]["type"] == "5") {
          setIsGetAuth(true);
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  const apiPro_img = async (e) => {
    try {
      setFileObj({
        name: `qna/${params["qid"]}.jpg`,
        file: e,
        isRe: true,
      });
      navigate("/crop/teacher/d");
      // let isOk = await apiFn.onlineQnaSet({
      //     state : {
      //         qid : params["qid"],
      //         img :
      //     }
      // })
    } catch (e) {
      // console.log("apiPro_img Error= >",e)
    }
  };

  const apiPro_profile = async () => {
    try {
      let profile = await apiFn.comApi({
        state: {
          table: "user_credential",
          page: 1,
          amount: 9999,
          sort: "reg_date desc",
          ref: ["A.* |HIQDB_user A on A.index=HIQDB_user_credential.user_id"],
          where: {
            "HIQDB_user_credential.name": `= '${params["tid"]}'`,
          },
        },
      });

      if (profile["list"].length > 0) {
        setProfile({
          ...profile["list"][0],
        });
      }
    } catch (e) {
      console.log("************");
      console.log(e);
      console.log("************");
    }
  };

  useEffect(() => {
    apiPro_read_qna();
    apiPro_profile();
    // setTimeout(() => {
    //     setIsHeader(false)
    // }, 3000);
  }, []);

  // useEffect(()=>{
  //     console.log("videoInfo => ",videoInfo);
  // },[videoInfo])

  // useEffect(()=>{
  //     console.log("senderInfo =>",senderInfo)
  //     // console.log(`${constants.s3_url}/public/general/${senderInfo["tid"]}___${senderInfo["sid"]}/${params["type"]}?${util.getRandomInt(1000,9999)}`)
  // },[senderInfo])

  // useEffect(()=>{
  //     console.log("View Qna qnaData => ",qnaData) ;
  // },[qnaData])

  // useEffect(()=>{
  //     // console.log("userInfo => ",userInfo) ;
  // },[userInfo])

  var [msgPop, setMsgPop] = useState(false);
  var [isHeader, setIsHeader] = useState(true);

  useEffect(() => {
    const windowAny = window as any;
    console.log(params);
    if (params["type"].includes("video") || params["type"].includes("youtube")) {
      if (windowAny.ReactNativeWebView) {
        windowAny.ReactNativeWebView.postMessage("isVideo_on");
      }
    }
    return () => {
      // setSenderInfo({})
    };
  }, []);

  useEffect(() => {
    const modifyImage = async () => {
      // const img = await apiFn.uploadFile(`qna/${params["qid"]}.jpg`, fileObj.base64)

      const location = await store.putBucket(
        fileObj.base64,
        `qna/${params["qid"]}.jpg`,
        false,
        false
      );
      await apiFn.onlineQnaSet({
        state: {
          id: params.qid,
          img: `${params["qid"]}`,
        },
      });
      setFileObj({});
      // navigate(-1)
    };

    console.log(fileObj?.base64);
    if (fileObj?.base64 && params.type === "de") modifyImage();
  }, [fileObj]);

  useEffect(() => {
    console.log("senders", senderInfo, qnaData);
  }, [senderInfo, qnaData]);

  const imageDownloadHref = useMemo(() => {
    let filePath;
    let fileName;
    // 타입이 "de" 이거나 타입이 "searchresult"인 경우
    if (params["type"] === "de" || params.type === "searchresult") {
      // qnaData에 이미지가 있는지 확인
      if (qnaData["img"]) {
        // 이미지가 있는 경우 이미지 다운로드 경로 생성
        fileName = `${qnaData["img"]}.jpg`;
        filePath = `${constants.s3_url}/qna/${fileName}`;
      } else {
        // 이미지가 없는 경우 기본 이미지 다운로드 경로 생성
        fileName = `${qnaData["qid"]}.jpg`;
        filePath = `${constants.s3_url}/qna/${fileName}`;
      }
    } else {
      const paramType = params["type"];
      const extension = paramType.split(".")[1];
      // 이미지가 있는 경우 타입에 따른 이미지 다운로드 경로 생성
      fileName = extension ? paramType : `${paramType}.jpg`;
      filePath = `${constants.s3_url}/qna/${params["qid"]}/${fileName}`;
    }

    const imageUrl = store.isMobile()
      ? `http://native_download?filename=${fileName}?filepath=${filePath}`
      : filePath;
    return imageUrl;
  }, [qnaData, params]);

  const { videoDownloadHref, fileName } = useMemo(() => {
    // 파일 이름 및 확장자 설정
    const fileName = `${params["qid"]}.avi`;
    // 파일 경로 설정
    let filePath;
    if (params["qid"].includes("memo")) {
      filePath = `${constants.s3_url}memo/${params["qid"].split("&")[1]}/${params["type"].split("&")[1].normalize("NFD")}`;
    } else if (params["qid"] === "0") {
      filePath = `${constants.s3_url}/general/${senderInfo["general"]}/${params["type"].split("&")[1].normalize("NFD")}`;
    } else {
      filePath = qnaData.original
        ? `${constants.video_bucket}/qna/${qnaData.original}/${params["type"].split("&")[1]}`
        : `${constants.video_bucket}/qna/${params["qid"]}/${params["type"].split("&")[1]}`;
    }
    // 동영상 URL 생성
    const videoDownloadHref = store.isMobile()
      ? `http://native_download?filename=${fileName}?filepath=${filePath}`
      : filePath;

    // 생성된 동영상 URL 반환
    return { videoDownloadHref, fileName };
  }, [qnaData, params]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        // flex:1,
        width: "100vw",
        height: "100vh",
        backgroundColor: "black",
      }}
      onClick={() => {
        setIsHeader(!isHeader);
      }}
    >
      {params["mo"] != "full" && (
        <div
          style={{
            ...Message_talk_style.s_1_w,
            zIndex: 999992939239239,
            position: "absolute",
            backgroundColor: isHeader ? "rgba(0,0,0,0.5)" : "rgba(0,0,0,0)",
          }}
        >
          <div
            style={{
              ...Message_talk_style.s_1_l_w,
              display: isHeader ? "flex" : "none",
              flexGrow: qnaData["bid"] != 0 ? 1 : 0,
            }}
          >
            <div
              style={{
                ...Message_talk_style.s_1_l_icon,
                zIndex: 99999999999999,
                // marginRight:"10px",
              }}
            >
              <ArrowBackIos
                style={{
                  color: "white",
                  paddingRight: "10px",
                }}
                onClick={(e) => {
                  const stack = store.get("stack");
                  setSenderInfo({});
                  if (stack.length > 0) navigate(-1);
                  // console.log("@@@@@@@")
                  e.preventDefault();
                  e.stopPropagation();
                }}
              />
            </div>
            {qnaData["bid"] && qnaData["bid"] != 0 && (
              <div
                style={Message_talk_style.s_1_l_icon_2}
                onClick={() => {
                  console.log("qnaData : ", qnaData);
                  console.log("profile : ", profile);
                  if (params["type"].includes("video")) {
                    navigate(`/book/${params["who"]}/${qnaData["bid"]}/0/de`);
                  } else {
                    if (params["type"] == "de" || params.type === "searchresult") {
                      // if(qnaData["b_img"]){
                      navigate(`/book/${params["who"]}/${qnaData["bid"]}/0/de`);
                      setHiddenUrl("message_");
                      // }
                    } else {
                      if (senderInfo["id"]) {
                        navigate(`/myinfo/${params["who"]}/${senderInfo["id"]}`);
                      } else if (profile["id"]) {
                        navigate(`/myinfo/${params["who"]}/${profile["id"]}`);
                      }
                    }
                  }
                }}
              >
                <img
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                    objectPosition: "center",
                    border: "1px solid",
                    borderColor: style.common.borderColor4,
                  }}
                  onError={(e) => {
                    e.currentTarget.src = noBook;
                  }}
                  // src={`${constants.apiUrl}/public/bookCover/${qnaData["bid"]}.jpg?${util.getRandomInt(1000,9999)}`}
                  src={
                    params["type"].includes("video")
                      ? // `${constants.s3_url}/teacher/${profile["img"]}.jpg`
                        `${constants.s3_url}/bookCover/${qnaData["bid"]}.jpg`
                      : senderInfo["qid"] == 0
                        ? senderInfo["id"]
                          ? senderInfo["type"] == 1
                            ? `${constants.apiUrl}/student/${senderInfo["id"]}.jpg?${util.getRandomInt(1000, 9999)}`
                            : `${constants.apiUrl}/teacher/${senderInfo["id"]}.jpg?${util.getRandomInt(1000, 9999)}`
                          : qnaData["b_img"]
                            ? `${constants.s3_url}/bookCover/${qnaData["b_img"]}.jpg`
                            : `${constants.apiUrl}/bookCover/${qnaData["bid"]}.jpg?${util.getRandomInt(1000, 9999)}`
                        : senderInfo["id"]
                          ? senderInfo["type"] == 1
                            ? `${constants.apiUrl}/student/${senderInfo["id"]}.jpg?${util.getRandomInt(1000, 9999)}`
                            : `${constants.apiUrl}/teacher/${senderInfo["id"]}.jpg?${util.getRandomInt(1000, 9999)}`
                          : qnaData["b_img"]
                            ? `${constants.s3_url}/bookCover/${qnaData["b_img"]}.jpg`
                            : `${constants.apiUrl}/bookCover/${qnaData["bid"]}.jpg?${util.getRandomInt(1000, 9999)}`
                  }
                />
              </div>
            )}
          </div>
          <div
            style={
              {
                ...Message_talk_style.s_1_r_w,
                justifyContent: "center",
                display: isHeader ? "flex" : "none",
              } as CSSProperties
            }
          >
            <div
              style={{
                ...Message_talk_style.s_1_r_v_t_w,
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  color: "white",
                }}
              >
                <div
                  style={{
                    ...Message_talk_style.s_1_r_v_t_w_v_text_1,
                    marginBottom: "5px",
                    fontSize: "16px",
                    fontWeight: "bold",
                  }}
                >
                  <div>
                    {params["type"].includes("video")
                      ? profile["name"]
                      : senderInfo["id"]
                        ? senderInfo["type"] == 1
                          ? senderInfo["nick"]
                          : senderInfo["name"]
                        : qnaData["title"]}
                  </div>
                </div>
                <div style={Message_talk_style.s_1_r_v_t_w_v_text_2}>
                  <div>
                    {/* 쎈수학 (20201) */}
                    {qnaData["bid"] == 0 ? "출처가 없는 질문" : qnaData["name"]}
                  </div>
                </div>
              </div>
            </div>
          </div>

          {params["qid"] !== "0" && params["type"].includes("video") && (
            <>
              {!qnaData_app.isVideoQuestion && (
                <div
                  style={{
                    height: "100%",
                    alignItems: "center",
                    // fontSize : "14px",
                    paddingRight: "30px",
                    color: "white",
                    display: isHeader ? "flex" : "none",
                  }}
                  onClick={(e) => {
                    // // console.log("@@@ ",isPop);
                    setMsgPop((prev) => !prev);
                    e.stopPropagation();
                    e.preventDefault();
                  }}
                >
                  {msgPop ? "문제 닫기" : "문제 보기"}
                </div>
              )}
              {params.who === "teacher" &&
                params.type.includes("video") &&
                ([senderInfo.id, qnaData.reg_id, qnaData.tid].includes(userInfo.id) ||
                  isGetAuth) && (
                  <a
                    style={{
                      display: isHeader ? "flex" : "none",
                      color: "white",
                      fontSize: 16,
                      fontWeight: 400,
                      justifyContent: "center",
                      alignItems: "center",
                      marginRight: 15,
                    }}
                    download
                    onClick={() => {
                      alert("다운로드가 시작되었습니다.");
                    }}
                    href={videoDownloadHref}
                  >
                    다운로드
                  </a>
                )}
            </>
          )}
          {params.who === WhoIs.teacher &&
            params["type"] !== "de" &&
            !params["type"].includes("video") && (
              <a
                id="new"
                style={{
                  display: isHeader ? "flex" : "none",
                  color: "white",
                  fontSize: 16,
                  fontWeight: 400,
                  justifyContent: "center",
                  alignItems: "center",
                  marginRight: 15,
                }}
                download
                onClick={() => {
                  alert("다운로드가 시작되었습니다.");
                }}
                // href={constants.apiUrl+"/"+util.getS3Folder()+'/board/'+v["bid"]+'/'+v["name"]}
                href={imageDownloadHref}
                // href={"http://s3_file?filename="+v["origin"]+"?filepath="+util.getS3Folder()+'/board/'+v["bid"]+'/'+v["name"]}
                // href={`${constants.apiUrl}/public/qna/${params["qid"]}.jpg?${util.getRandomInt(1000,9999)}`}
              >
                다운로드
              </a>
            )}
        </div>
      )}
      {params["type"].includes("video") ? (
        <div
          style={{
            flex: 1,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            flexDirection: "column",
          }}
        >
          <UiReactPlayer
            // setMsgPop={setMsgPop}
            noQuestion={true}
            userInfo={userInfo}
            msgPop={msgPop}
            setIsHeader={setIsHeader}
            senderInfo={senderInfo}
            data={
              // params["qid"] == 0 ?
              // :
              {
                ...qnaData,
                video_start: videoInfo["video_start"],
                video_end: videoInfo["video_end"],
                video_link:
                  videoInfo.video_link ?? params.qid.includes("board")
                    ? `${constants.video_bucket}/board/${params["type"].split("&")[1].normalize("NFD")}`
                    : videoInfo.video_link ?? params.qid.includes("board")
                      ? `${constants.video_bucket}/board/${params["type"].split("&")[1].normalize("NFD")}`
                      : params["qid"].includes("memo")
                        ? constants.s3_url +
                          // `/qna/${qnaData["qid"]}/${params["type"].split("&")[1]}.mp4`
                          `/memo/${params["qid"].split("&")[1]}/${params["type"].split("&")[1].normalize("NFD")}`
                        : params["qid"] === "0"
                          ? constants.s3_url +
                            "" +
                            // `/qna/${qnaData["qid"]}/${params["type"].split("&")[1]}.mp4`
                            `/general/${senderInfo["general"]}/${params["type"].split("&")[1].normalize("NFD")}`
                          : `${constants.video_bucket}/qna/${params["qid"]}/${params["type"].split("&")[1]}`,
              }
            }
            isMsg={true}
            isPlayer={videoInfo.video_link}
            containerStyle={
              {
                // height : '100%'
              }
            }
          />
        </div>
      ) : params["type"].includes("youtube") ? (
        <div
          style={{
            // maxHeight:"600px",
            position: "fixed",
            top: 0,
            width: "100vw",
            height: "100vh",
            // flex:1,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <UiReactPlayer
            userInfo={userInfo}
            noQuestion={true}
            isSubs={false}
            data={{
              ...qnaData,
              video_link: youtube_url.startsWith("https://vimeo.com/")
                ? youtube_url.split("&")[0]
                : youtube_url,
              // video_start: youtube_url.startsWith('https://vimeo.com/')? youtube_url.split('&')[1]:null,
              video_start: youtube_url.startsWith("https://vimeo.com/")
                ? youtube_url.split("&")[1].split("=")[1]
                : null,
              video_end: youtube_url.startsWith("https://vimeo.com/")
                ? youtube_url.split("&")[2].split("=")[1]
                : null,
              // video_end: youtube_url.startsWith('https://vimeo.com/')? youtube_url.split('&')[0]:null,
            }}
            msgPop={msgPop}
            isPlayer={true}
            isMsg={true}
            containerStyle={{
              display: "flex",
              width: "100%",
              height: "100%",
              alignItems: "center",
              // paddingBottom:"100px",
              // backgroundColor : "green"
            }}
          />
        </div>
      ) : (
        <>
          <div
            style={{
              position: "fixed",
              top: 0,
              width: "100vw",
              height: "100vh",
              // height :
              //     (params["who"] == "teacher" || params["type"] == "de") ?
              //     (
              //     params["who"] == "student" ?
              //     "calc(100vh - 60px)"
              //     :
              //     "calc(100vh - 110px)"
              //     )
              //     :
              //     "calc(100vh - 60px)"
              // ,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: "black",
            }}
          >
            <div
              style={{
                backgroundColor: "white",
              }}
            >
              <TransformWrapper>
                <TransformComponent
                  wrapperStyle={{
                    backgroundColor: "black",
                  }}
                >
                  <img
                    className={params["mo"] == "y" ? "mosaic" : null}
                    style={{
                      height: "100vh",
                      width: "100vw",
                      // maxHeight : (params["who"] =="teacher" || params["type"] == "de") ? "calc(100vh - 110px)" : "calc(100vh - 60px)",
                      // height:"100%",
                      objectFit: "contain",
                    }}
                    onError={(e) => {
                      // e.currentTarget.src = noBook
                    }}
                    src={
                      params["type"] == "de" ||
                      params.type === "searchresult" ||
                      params["type"] == "ori"
                        ? qnaData["img"]
                          ? `${constants.s3_url}/qna/${qnaData["img"]}.jpg?${util.getRandomInt(1000, 9999)}`
                          : `${constants.s3_url}/qna/${qnaData["original_img"]}.jpg?${util.getRandomInt(1000, 9999)}`
                        : senderInfo["qid"] == 0
                          ? `${constants.s3_url}/general/${senderInfo["tid"]}___${senderInfo["sid"]}/${params["type"]}?${util.getRandomInt(1000, 9999)}`
                          : `${constants.s3_url}/qna/${params["qid"]}/${params["type"]}?${util.getRandomInt(1000, 9999)}`
                      // qnaData["img"] ?
                      // `${constants.s3_url}/public/qna/${qnaData["img"]}/${params["type"]}`
                      // :
                      // `${constants.s3_url}/public/general/${senderInfo["tid"]}___${senderInfo["sid"]}/${params["type"]}`
                    }
                  />
                </TransformComponent>
              </TransformWrapper>
            </div>
          </div>
          {params["who"] == "teacher" &&
            (params["type"] == "de" || params.type === "searchresult") && (
              <div
                style={
                  {
                    position: "absolute",
                    bottom: 0,
                    height: "50px",
                    backgroundColor: "white",
                    width: "100vw",
                    alignItems: "center",
                    justifyContent: "center",
                    display: isHeader ? "flex" : "none",
                  } as CSSProperties
                }
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    width: "72%",
                    // backgroundColor:"red",
                  }}
                >
                  {params["tid"] == userInfo["id"] && (
                    <UiTextBtn
                      text={" 문제사진 재등록 "}
                      isUnderline={false}
                      btnStyle={{
                        color: "black",
                        fontSize: "14px",
                        borderLeft: `2px solid ${style.common.t_color}`,
                        borderRight: `1px solid ${style.common.t_color}`,
                        display: "flex",
                        justifyContent: "center",
                        flexGrow: 1,
                      }}
                      isFile={true}
                      fnClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        apiPro_img(e);
                      }}
                      accept="image/*"
                    />
                  )}
                  <a
                    style={{
                      color: "black",
                      fontSize: "14px",
                      display: "flex",
                      flexGrow: 1,
                      justifyContent: "center",
                      borderLeft: `1px solid ${style.common.t_color}`,
                      borderRight: `1px solid ${style.common.t_color}`,
                    }}
                    download
                    onClick={() => {
                      alert("다운로드가 시작되었습니다.");
                    }}
                    href={imageDownloadHref}
                  >
                    다운로드
                  </a>
                  {(params["tid"] == userInfo["id"] || isGetAuth) && (
                    <UiTextBtn
                      text={" 이미지 수정 "}
                      isUnderline={false}
                      btnStyle={{
                        color: "black",
                        fontSize: "14px",
                        display: "flex",
                        flexGrow: 1,
                        justifyContent: "center",
                        borderLeft: `1px solid ${style.common.t_color}`,
                        borderRight: `2px solid ${style.common.t_color}`,
                      }}
                      // isFile={true}
                      fnClick={async (e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        // const config = {
                        //     method: "GET",
                        //     responseType: "blob",
                        //     url : qnaData["img"] ?
                        //         `${constants.s3_url}/qna/${qnaData["img"]}.jpg?${util.getRandomInt(1000,9999)}`
                        //         :
                        //         `${constants.s3_url}/qna/${params["qid"]}.jpg?${util.getRandomInt(1000,9999)}`
                        // url : // encodeURI(`https://dshiq2.s3.ap-northeast-2.amazonaws.com/public/qna/2243ec84-0bc0-4205-a0d5-2c6ccfbf7da0.jpg`)

                        // };
                        // axios(config)
                        //     .then((res)=>{
                        //         setQnaData({})
                        //         setFileObj({
                        //             isMsgImg : true,
                        //             name : "qna/"+qnaData["img"],
                        //             file : { target : { files : [ new File([res["data"]], "" , ) ] } }
                        //         })
                        //         setHiddenUrl(window.location.pathname)
                        //         navigate(`/crop/${params["who"]}/d`)
                        //     })
                        //     .catch((e)=>{
                        //         console.log("DOWN ERROR => ",e);
                        //     })
                        try {
                          console.log(qnaData["img"], params["qid"]);
                          const response = await (
                            await fetch(
                              qnaData["img"]
                                ? `${constants.s3_url}/url/qna/${qnaData["img"]}.jpg`
                                : `${constants.s3_url}/url/qna/${params["qid"]}.jpg`,
                              {
                                method: "GET",
                              }
                            )
                          ).json();

                          console.log(response);

                          const imageResponse = await fetch(response.bucketUrl);

                          const imageBlob = await imageResponse.blob();
                          setFileObj({
                            isMsgImg: true,
                            name: "qna/" + qnaData["img"],
                            file: {
                              target: {
                                files: [new File([imageBlob], "qna/" + qnaData["img"] + ".jpg")],
                              },
                            },
                          });
                          setHiddenUrl(window.location.pathname);
                          setQnaData({});
                          navigate(`/crop/${params["who"]}/d`);
                        } catch (e) {
                          console.log("Connection failed");
                          console.error(e);
                        }
                      }}
                      accept="image/*"
                    />
                  )}
                  {/* <UiTextBtn
                        text={"다운로드"}
                        isUnderline={false}
                        btnStyle={{
                            color : "black",
                        }}
                    /> */}
                </div>
              </div>
            )}
        </>
      )}
    </div>
  );
};

export default ViewQna;
