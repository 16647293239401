import React from "react";

import UiBtnStyle from "./UiBtnStyle";

import "./UiBtn.css";
// import UiStyle from './UiStyle'
// import './UiComponent.css'

interface UiBtnProps {
  isTouch?: boolean;
  fnEnter?: () => void;
  backgroundColor?: string;
  type?: string;
  btnStyle?: object;
  title?: string;
  fnClick?: () => void;
  isFile?: boolean;
  id?: string;
  accept?: string;
}

const UiBtn = ({
  isTouch = true,
  fnEnter,
  backgroundColor,
  type = null,
  btnStyle,
  title,
  fnClick,
  isFile = false,
  id,
  accept = "*",
}: UiBtnProps) => {
  return (
    <>
      {isFile ? (
        <>
          <input
            id={id}
            type={"file"}
            onChange={fnClick || (() => {})}
            accept={accept}
            style={{
              display: "none",
            }}
          />
          <label
            htmlFor={id}
            style={
              type == "A"
                ? { ...UiBtnStyle.default_wrapper, ...UiBtnStyle.typeA_wrapper, ...btnStyle }
                : type == "Xsmall"
                  ? {
                      ...UiBtnStyle.default_wrapper,
                      width: "83px",
                      height: "28px",
                      marginRight: 0,
                      ...btnStyle,
                    }
                  : type == "small"
                    ? { ...UiBtnStyle.default_wrapper, width: "83px", ...btnStyle }
                    : type == "medium"
                      ? { ...UiBtnStyle.default_wrapper, width: "126px", ...btnStyle }
                      : type == "large"
                        ? { ...UiBtnStyle.default_wrapper, width: 200, ...btnStyle }
                        : type == "Xlarge"
                          ? {
                              ...UiBtnStyle.default_wrapper,
                              width: 250,
                              backgroundColor: isTouch ? "#fff" : "#c7c7c7",
                            }
                          : (type == null || type == undefined) && {
                              ...UiBtnStyle.default_wrapper,
                              ...btnStyle,
                            }
            }
          >
            {title}
          </label>
        </>
      ) : (
        <button
          style={
            type == "A"
              ? { ...UiBtnStyle.default_wrapper, ...UiBtnStyle.typeA_wrapper, ...btnStyle }
              : type == "Xsmall"
                ? {
                    ...UiBtnStyle.default_wrapper,
                    width: "83px",
                    height: "28px",
                    marginRight: 0,
                    ...btnStyle,
                  }
                : type == "small"
                  ? { ...UiBtnStyle.default_wrapper, width: "83px", ...btnStyle }
                  : type == "medium"
                    ? { ...UiBtnStyle.default_wrapper, width: "126px", ...btnStyle }
                    : type == "large"
                      ? { ...UiBtnStyle.default_wrapper, width: 200, ...btnStyle }
                      : type == "Xlarge"
                        ? {
                            ...UiBtnStyle.default_wrapper,
                            width: 250,
                            backgroundColor: isTouch ? "#fff" : "#c7c7c7",
                          }
                        : (type == null || type == undefined) && {
                            ...UiBtnStyle.default_wrapper,
                            ...btnStyle,
                          }
          }
          id={id}
          onClick={isTouch ? fnClick || (() => {}) : null}
          onKeyUp={(e) => {
            if (e.key == "13") {
              fnEnter();
            }
          }}
        >
          {title}
        </button>
      )}
    </>
  );
};

export default UiBtn;
