import { ReactComponent as AddQuestionIcon } from "asset/images/icon/add-question.svg";
import PdfUploadModal from "pages/StudyBookDetail/components/PdfUploadModal";
import React, { useState } from "react";
import { useParams } from "react-router";
import { MenuItemContainer } from "..";

const QuestionAdd = () => {
  const [open, setOpen] = useState(false);
  const { bookId } = useParams();

  return (
    <>
      <MenuItemContainer
        onClick={() => {
          setOpen(true);
        }}
      >
        문제 추가
        <AddQuestionIcon />
      </MenuItemContainer>
      {open && (
        <PdfUploadModal
          onClose={() => {
            setOpen(false);
          }}
          open={open}
          isSolution={false}
          bookId={bookId}
        />
      )}
    </>
  );
};

export default QuestionAdd;
