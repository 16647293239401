import { Check, Clear } from "@material-ui/icons";
import { React, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { useLongPress } from "use-long-press";
import constants from "../../asset/constants";
import face_student from "../../asset/Img/face_student.png";
import face_teacher from "../../asset/Img/defaultpic_teacher.png";
import style from "../../asset/style";
import util from "../../asset/util";
import { UiCheckBox } from "../UiCheckBox/UiCheckBox";
import UiTextBtn from "../UiTextBtn/UiTextBtn";
import UiPeople_ProfileTeacher_Style from "./UiPeople_ProfileTeacher_Style";

const UiPeoPle_ProfileTeacher = ({
  selectData,
  setHiddenNav,
  isNoOpen = false,
  isEdit = false,
  isBlank = true,
  isTeacher = false,
  fnLongClick,
  fnCheck,
  isOpenOk,
  isManager,
  isRegi = false,
  data = [1, 2],
  fnClick_rejcet,
  fnClick_approve,
  fnClick_exit,
  fnClick_up,
  fnClick_down,
}) => {
  // let data = []
  let navigate = useNavigate();
  return (
    <div>
      {data.length > 0
        ? data.map((v, i) => (
            <UiPeopleSection_ProfileTeacher
              selectData={selectData}
              setHiddenNav={setHiddenNav}
              fnCheck={fnCheck}
              // id={i}
              id={v["id"]}
              data={v}
              isEdit={isEdit}
              isManager={isManager}
              isTeacher={isTeacher}
              fnLongClick={fnLongClick}
              isOpenOk={isOpenOk}
              isRegi={isRegi}
              fnClick_rejcet={fnClick_rejcet}
              fnClick_approve={fnClick_approve}
              fnClick_exit={fnClick_exit}
              fnClick_up={fnClick_up}
              isNoOpen={isNoOpen}
              fnClick_down={fnClick_down}
            />
          ))
        : isBlank && (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                fontWeight: "bold",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
                height: "calc(65vh + 20px)",
                fontSize: "14px",
                color: style.common.fontColor_1,
              }}
            >
              {isTeacher ? (
                <>
                  <div>등록된 학생이 없습니다.</div>
                  <div>수업을 개설하고, 학생들을 초대해보세요.</div>
                  <UiTextBtn
                    btnStyle={{
                      display: "flex",
                      alignItems: "center",
                      marginTop: "20px",
                      color: style.common.t_color,
                    }}
                    text={"클래스룸 가입하기"}
                  />
                </>
              ) : (
                <>
                  <div>등록된 선생님이 없습니다.</div>
                  <div>수업에 등록하고 선생님에게 모르는 문제를 물어보세요.</div>
                  <UiTextBtn
                    btnStyle={{
                      display: "flex",
                      alignItems: "center",
                      marginTop: "20px",
                    }}
                    text={"수업 등록하기"}
                  />
                </>
              )}
            </div>
          )}
    </div>
  );
};

const UiPeopleSection_ProfileTeacher = ({
  id,
  isNoOpen,
  setHiddenNav,
  data,
  selectData,
  isEdit,
  isTeacher,
  fnLongClick,
  fnCheck,
  isManager,
  isOpenOk = true,
  isRegi,
  fnClick_rejcet,
  fnClick_approve,
  fnClick_exit,
  fnClick_up,
  fnClick_down,
}) => {
  let navigate = useNavigate();
  let params = useParams();

  const [isOpen, setIsOpen] = useState(false);

  const bind = useLongPress(
    () => {
      try {
        fnLongClick(id);
        setIsOpen(false);
      } catch (e) {
        // console.log("Long Press Error => ",e)
      }
    },
    {
      threshold: 1000,
    }
  );

  useEffect(() => {
    // console.log("UiPeopleSection_ProfileTeacher data => ",data) ;
  }, [data]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <div
        style={{
          ...UiPeople_ProfileTeacher_Style.section_c,
          width: "100%",
        }}
        {...bind()}
        onClick={(e) => {
          // // e.preventDefault();
          // if(!isOpenOk)return;
          // if(isManager)setIsOpen(!isOpen)
          if (!isManager) return;
          if (isNoOpen) return;
          if (!isEdit) setIsOpen(!isOpen);
        }}
      >
        {isEdit && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginRight: "10px",
            }}
          >
            <UiCheckBox
              className={isTeacher ? "t" : "s"}
              // value={"?"}
              // className={"message_?"}
              checked={selectData[id] == "Y" ? true : false}
              value={id}
              fnClick={fnCheck}
              containerStyle={{
                padding: "0px",
              }}
            />
          </div>
        )}
        <div
          style={{
            // width : '20%',
            marginRight: "10px",
          }}
          onClick={() => {
            if (setHiddenNav) {
              setHiddenNav();
            }
            navigate(`/myinfo/${params["who"]}/${data["id"]}`);
          }}
        >
          <div style={UiPeople_ProfileTeacher_Style.section_i_w}>
            {/* <img style={UiPeople_ProfileTeacher_Style.section_i}
                            src={data["type"] == 0 ? face_student : face_teacher }
                        /> */}
            <img
              style={UiPeople_ProfileTeacher_Style.section_i}
              onError={(e) => {
                // setIsLoad(false)
                e.currentTarget.src = face_teacher;
                // e.currentTarget.src = isTeacher ? face_student : face_teacher
              }}
              src={
                data
                  ? data["img"]
                    ? constants.s3_url +
                      // '/public'
                      // +
                      `/teacher/${data["img"]}` +
                      ".jpg"
                    : constants.apiUrl +
                      // '/public'
                      // +
                      `/teacher/${data["id"].normalize("NFD")}` +
                      ".jpg?" +
                      util.getRandomInt(1000, 9999)
                  : data?.user_type === 2
                    ? face_teacher
                    : face_student
              }
            />
          </div>
        </div>
        <div style={UiPeople_ProfileTeacher_Style.section_r_c}>
          <div style={UiPeople_ProfileTeacher_Style.section_r_t_w}>
            <div style={UiPeople_ProfileTeacher_Style.section_r_t_t_w}>
              {data["type"] == 5 && (
                <div
                  style={{
                    color: style.common.t_color,
                    fontWeight: "bold",
                    marginRight: "3px",
                  }}
                >
                  {"[관리자]"}
                </div>
              )}
              <div style={UiPeople_ProfileTeacher_Style.section_r_t_t_text_2}>
                {/* 김학생  */}
                {data["name"]}
              </div>
              {data["type"] == 0 && <div>(고2)</div>}
            </div>
          </div>
          <div style={UiPeople_ProfileTeacher_Style.section_r_m_w}>
            <div style={UiPeople_ProfileTeacher_Style.section_r_m_l_w}>
              <div>
                {/* 시대인(강남),수학만점왕 외  2개 강의 */}
                {/* {
                                 (data["classList"] &&
                                 data["classList"].length > 0 ?
                                 (
                                     data["classList"].reduce((a,v,i)=>{
                                         if(i == 0){
                                             a = v["name"]
                                         }
                                         else if(i < 2){
                                             a = a + " , " + v["name"]
                                         }
                                         // console.log("###" ,a);
                                         return a
                                     },"")
                                     +
                                     ( data["classList"].length > 2 ? "외 " +(data["classList"].length - 2) +"개 강의"  : "")
                                 )
                                 :
                                 "담당 수업 없음"
                                 )
                                } */}
                {/* 0906 Project 4에 의해 변경 */}
                {data["intro"]}
              </div>
            </div>
          </div>
          <div style={UiPeople_ProfileTeacher_Style.section_r_b_w}>
            {`총 질문수 ${data["qnaCount"]}개`}
          </div>
        </div>
      </div>
      {isOpen && (
        <div style={UiPeople_ProfileTeacher_Style.openAdd_c}>
          {isTeacher ? (
            <div style={UiPeople_ProfileTeacher_Style.openAdd_btn_w}>
              {isRegi
                ? isManager &&
                  (data["type"] == 9 ? (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        width: "100%",
                      }}
                    >
                      <UiTextBtn
                        text={
                          <div
                            style={{
                              display: "flex",
                            }}
                          >
                            <Clear
                              style={{
                                fontSize: "16px",
                                marginRight: "2px",
                              }}
                            />
                            초대 취소하기
                          </div>
                        }
                        isUnderline={false}
                        btnStyle={{
                          ...UiPeople_ProfileTeacher_Style.openAdd_btn,
                          color: style.common.fontColor_3,
                        }}
                        fnClick={() => {
                          fnClick_rejcet(data);
                          setIsOpen(false);
                        }}
                      />
                    </div>
                  ) : (
                    <>
                      <UiTextBtn
                        text={
                          <div
                            style={{
                              display: "flex",
                            }}
                          >
                            <Clear
                              style={{
                                fontSize: "16px",
                                marginRight: "2px",
                              }}
                            />
                            거절하기
                          </div>
                        }
                        isUnderline={false}
                        btnStyle={{
                          ...UiPeople_ProfileTeacher_Style.openAdd_btn,
                          color: style.common.fontColor_3,
                        }}
                        fnClick={() => {
                          fnClick_rejcet(data);
                          setIsOpen(false);
                        }}
                      />
                      <UiTextBtn
                        text={
                          <div
                            style={{
                              display: "flex",
                            }}
                          >
                            <Check
                              style={{
                                fontSize: "16px",
                                marginRight: "2px",
                              }}
                            />
                            승인하기
                          </div>
                        }
                        isUnderline={false}
                        btnStyle={UiPeople_ProfileTeacher_Style.openAdd_btn}
                        fnClick={() => {
                          fnClick_approve(data);
                          setIsOpen(false);
                        }}
                      />
                    </>
                  ))
                : isManager &&
                  (data["type"] == 5 ? (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        width: "100%",
                      }}
                    >
                      <UiTextBtn
                        text={"관리자에서 해지"}
                        isUnderline={false}
                        btnStyle={UiPeople_ProfileTeacher_Style.openAdd_btn}
                        fnClick={() => {
                          fnClick_down(data);
                          setIsOpen(false);
                        }}
                      />
                    </div>
                  ) : (
                    <>
                      <UiTextBtn
                        text={"탈퇴처리"}
                        isUnderline={false}
                        btnStyle={{
                          ...UiPeople_ProfileTeacher_Style.openAdd_btn,
                          color: style.common.fontColor_3,
                        }}
                        fnClick={() => {
                          fnClick_exit(data);
                          setIsOpen(false);
                        }}
                      />
                      <UiTextBtn
                        text={"관리자로 승격"}
                        isUnderline={false}
                        btnStyle={UiPeople_ProfileTeacher_Style.openAdd_btn}
                        fnClick={() => {
                          fnClick_up(data);
                          setIsOpen(false);
                        }}
                      />
                    </>
                  ))}
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                width: "80%",
                justifyContent: "space-between",
                padding: "10px 0px",
              }}
            >
              <UiTextBtn
                text={"질문 보기"}
                isUnderline={false}
                btnStyle={UiPeople_ProfileTeacher_Style.openAdd_btn}
              />
              <UiTextBtn
                text={"질문하기"}
                isUnderline={false}
                btnStyle={UiPeople_ProfileTeacher_Style.openAdd_btn}
              />
              <UiTextBtn
                text={"대화하기"}
                isUnderline={false}
                btnStyle={UiPeople_ProfileTeacher_Style.openAdd_btn}
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default UiPeoPle_ProfileTeacher;
