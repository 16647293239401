import apiFn from "../asset/apiClass";
import { axiosInstance } from "./common";
import constants from "../asset/constants";

export const useDeleteQuestion = (question: number) => {
  return apiFn.comApi({
    state: {
      table: "online_qna",
      set: [
        {
          key: `id:${question}`,
          value: {
            remove_date: 0,
          },
        },
      ],
    },
  });
};

export const useRestoreQuestion = (question: number) => {
  return apiFn.comApi({
    state: {
      table: "online_qna",
      set: [
        {
          key: `id:${question}`,
          value: {
            remove_date: "del",
          },
        },
      ],
    },
  });
};

export const useResolveQuestion = (question: number | string, userId: string) => {
  return apiFn.comApi({
    state: {
      table: "online_qna",
      set: [
        {
          key: `id:${question}`,
          value: { ans_date: 0, answered_by: userId, mobum_date: 0 },
        },
      ],
    },
  });
};

export const useDeleteQuestionList = (whereObj: any) => {
  return apiFn.comApi({
    state: {
      table: "online_qna",
      page: 1,
      amount: 99999,
      sort: "remove_date desc",
      where: {
        ...whereObj,
        "HIQDB_online_qna.remove_date": `is not null`,
      },
    },
  });
};

export const useToggleExemplary = (questionId: number, state: any) => {
  return apiFn.comApi({
    state: {
      table: "online_qna",
      set: [
        {
          key: `id:${questionId}`,
          value: {
            mobum_date: state,
          },
        },
      ],
    },
  });
};

export const useAddQuestionMembers = (
  questionId: string,
  currentMemberCount: number,
  addMemberCount: number
) => {
  return apiFn.comApi({
    state: {
      table: "online_qna",
      set: [
        {
          key: `id:${questionId}`,
          value: {
            member_cnt: `${currentMemberCount + addMemberCount}`,
          },
        },
      ],
    },
  });
};

export const useUserAllReceivedQuestionList = (userId: string) => {
  return apiFn.comApi({
    state: {
      table: "online_qna",
      page: 1,
      amount: 999,
      sort: "reg_date desc",
      equal: {
        tid: userId,
      },
    },
  });
};

export const useRemoveQuestionMembers = (questionId: number, currentMemberCount: number) => {
  return apiFn.comApi({
    state: {
      table: "online_qna",
      set: [
        {
          key: `id:${questionId}`,
          value: {
            member_cnt: currentMemberCount - 1,
          },
        },
      ],
    },
  });
};

export const useTransferQuestion = (questionId: string, transferUserId: string) => {
  return apiFn.comApi({
    state: {
      table: "online_qna",
      set: [
        {
          key: `id:${questionId}`,
          value: {
            tid: transferUserId,
          },
        },
      ],
    },
  });
};

export const useSetQuestionTitle = (questionId: string, title: string) => {
  return apiFn.comApi({
    state: {
      table: "online_qna",
      set: [
        {
          key: `id:${questionId}`,
          value: {
            title: title,
          },
        },
      ],
    },
  });
};

export const useSetQuestionUnderstand = (questionId: string, state: string) => {
  return apiFn.comApi({
    state: {
      table: "online_qna",
      set: [
        {
          key: `id:${questionId}`,
          value: {
            understand: state,
          },
        },
      ],
    },
  });
};

export const useGetQuestionData = (questionId: string) => {
  return apiFn.comApi({
    state: {
      table: "online_qna",
      sort: "reg_date desc",
      page: 1,
      amount: 9999,
      where: {
        "HIQDB_online_qna.id": `='${questionId["qid"]}'`,
      },
    },
  });
};
export const useCancelSolveQuestion = (questionId: string) => {
  return apiFn.comApi({
    state: {
      table: "online_qna",
      set: [
        {
          key: `id:${questionId}`,
          value: {
            mobum_date: "del",
            ans_date: "del",
            answered_by: "NULL",
          },
        },
      ],
    },
  });
};

export const useSetPublicQuestion = (questionId: string, state: string) => {
  return apiFn.comApi({
    state: {
      table: "online_qna",
      set: [
        {
          key: `id:${questionId}`,
          value: {
            public: state,
          },
        },
      ],
    },
  });
};

export const useSetMosaicQuestion = (questionId: string, state: string) => {
  return apiFn.comApi({
    state: {
      table: "online_qna",
      set: [
        {
          key: `id:${questionId}`,
          value: {
            mosaic: state,
          },
        },
      ],
    },
  });
};

export const useSetEvaluateQuestion = (questionId: string) => {
  return apiFn.comApi({
    state: {
      table: "online_qna",
      set: [
        {
          key: `id:${questionId}`,
          value: {
            level: 2,
            cal_amount: 2,
          },
        },
      ],
    },
  });
};

export const useSetEvaluateLevel = (questionId: string, state: number) => {
  return apiFn.comApi({
    state: {
      table: "online_qna",
      set: [
        {
          key: `id:${questionId}`,
          value: {
            evaluate_level: state,
          },
        },
      ],
    },
  });
};

export const useSetEvaluateCalculationsQuestion = (questionId: string, state: number) => {
  return apiFn.comApi({
    state: {
      table: "online_qna",
      set: [
        {
          key: `id:${questionId}`,
          value: {
            cal_amount: state,
          },
        },
      ],
    },
  });
};

export const resolveQuestionApi = ({
  questionId,
  userId,
}: {
  questionId: string;
  userId: string;
}) => {
  return axiosInstance.put(`${constants.apiUrl}/qna/rooms/resolve/question`, {
    questionId,
    userId,
  });
};
