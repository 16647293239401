import style from "../../asset/style"

const Lecture_book_Style = {
    t_btn_w : {
        width : '145px',
        margin : "0px 5px",
        // paddingLeft : "10px",
    },
    t_btn_text : {
        display:"flex",
        height:'100%',
        padding : 0,
        alignItems:"center"
    },
    t_btn_Add : {
        marginRight : "5px"
    },
    t_btn : {
        borderRadius : 20,
        height : '30px',
        padding : "18px 10px",
        fontSize : "14px",
    },
    edit_section_w : {
        // ...Academy_book_Style.t_c,
        // ...Academy_book_Style.t_c_add,
        display: "flex",
        width: "100%",
        padding: "12px 0px 15px 0px",
        justifyContent: "center",
        justifyContent: "space-between",
        alignItems : "center",
        fontSize : style.common.select_fontSize,
    },
    edit_w : {
        display:"flex",
        alignItems:"center",
        position : 'absolute',
        height:"50px",
        right : 10,
        top : 3,
        fontSize : "14px",
        color  : style.common.t_color,
        fontWeight : "bold"
    },
    textBtn : {
        fontSize : style.common.select_fontSize,
    },
    t_btn_text : {
        display:"flex",
        height:'100%',
        padding : 0,
        alignItems:"center"
    },
    t_btn_Add : {
        marginRight : "5px"
    },
}

export default Lecture_book_Style 