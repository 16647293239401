import style from "../../asset/style"

const Column_pick_Style = {
    t_btn_w : {
        // width : '140px',
        // height:"44px",
        // margin : "0px 5px"
    },
    t_btn_text : {
        display:"flex",
        height:'100%',
        padding : 0,
        alignItems:"center"
    },
    t_btn_Add : {
        marginRight : "5px"
    },
    t_btn : {
        borderRadius : 20,
        height : '36px',
        width:"140px",
        padding : "4px 10px",
        // backgroundColor : style.common.t_color,
        // color : "white",
        
    },
    edit_w : {
        display:"flex",
        alignItems:"center",
        position : 'absolute',
        height:"50px",
        right : 10,
        top : 3,
        fontSize : "14px",
        color  : style.common.t_color,
        fontWeight : "bold"
    },
    section_w : {
        display:"flex",
        padding : '10px 0px',
        borderBottom : "1px solid",
        borderColor : style.common.borderColor4
    },
    section_i_w : {
        width : "50px",
        height : "50px",
        paddingRight : '3px'
    },
    section_text_w : {
        paddingLeft : "5px",
        display : "flex",
        flexDirection : "column",
        justifyContent:"space-between",
    },
    section_text_1 : {
        fontSize : "14px",
        fontWeight:"bold",
        color : style.common.fontColor_5
    },
    section_text_2 : {
        fontSize : "11px",
        color : style.common.fontColor_1
    },
}

export default Column_pick_Style
