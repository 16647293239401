import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { uploadToStorage, uploadVideoFileToStorage } from "api/fileUpload";

export const upload = createAsyncThunk(
  "upload/handleFile",
  async ({ path, file, bucketName }, { dispatch, rejectWithValue }) => {
    const abortController = new AbortController();
    await dispatch(
      uploadSlice.actions.add({
        path,
        controller: abortController,
        status: "hanging",
      })
    );
    try {
      // const resultPath = await apiFn.uploadFile(
      //   path,
      //   file,
      //   abortController.signal
      // );
      let resultPath;
      if (bucketName === "hiqsum-test-upload" || bucketName === "hiqsum-upload") {
        resultPath = uploadToStorage({
          bucketName,
          file: file,
          fileLocation: path,
          async onUploadProgress(e) {
            const progress = Math.round((e.loaded * 100) / e.total);
            await dispatch(
              uploadSlice.actions.update({
                path,
                controller: abortController,
                status: "hanging",
                progress,
              })
            );
          },
        });
      } else {
        resultPath = uploadVideoFileToStorage({
          bucketName,
          file: file,
          fileLocation: path,
          async onUploadProgress(e) {
            const progress = Math.round((e.loaded * 100) / e.total);
            await dispatch(
              uploadSlice.actions.update({
                path,
                controller: abortController,
                status: "hanging",
                progress,
              })
            );
          },
        });
      }

      return resultPath;
    } catch (e) {
      rejectWithValue(path);
    }
  }
);

export const uploadSlice = createSlice({
  name: "upload",
  initialState: {
    jobs: {},
    isOpen: false,
    isVisible: true,
  },
  reducers: {
    add: (state, action) => {
      const { path, controller, status } = action.payload;
      return {
        jobs: {
          ...state.jobs,
          [path]: { status, controller },
        },
        isOpen: state.isOpen,
        isVisible: true,
      };
    },
    update: (state, action) => {
      const { path, controller, status, progress } = action.payload;
      return {
        jobs: {
          ...state.jobs,
          [path]: { status, controller, progress },
        },
        isOpen: state.isOpen,
        isVisible: true,
      };
    },
    cancel: (state, action) => {
      const path = action.payload;
      if (state.jobs[path]?.status === "pending") state.jobs[path]?.abortManager?.abort();

      const temp = {
        jobs: { ...state.jobs },
        isOpen: state.isOpen,
        isVisible: state.isVisible,
      };

      return;
    },
    toggle: (state) => {
      return {
        jobs: { ...state.jobs },
        isOpen: !state.isOpen,
        isVisible: state.isVisible,
      };
    },
    visible: (state) => {
      return {
        jobs: { ...state.jobs },
        isOpen: state.isOpen,
        isVisible: false,
      };
    },
  },
  extraReducers(builder) {
    builder
      .addCase(upload.fulfilled, (state, action) => {
        const path = action.payload;
        // state.jobs[path].status = "success";
      })
      .addCase(upload.pending, (state, action) => {
        const { path } = action.meta.arg;
        state.jobs[path] = {};
        // state.jobs[path].status = "pending";
      })
      .addCase(upload.rejected, (state, action) => {
        const path = action.payload;
        // state.jobs[path].status = "rejected";
      });
  },
});

export const { complete, cancel, toggle, visible } = uploadSlice.actions;

export default uploadSlice.reducer;
