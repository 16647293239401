const UiTeacherList_Style = {
    c : {
        display : "flex",
        flexDirection:"column",
        padding : "16px 15px", ////
        backgroundColor : "white",
        marginTop : "10px",
        fontSize : "13px",
    },
    title_w : {
        marginBottom : "10px", //
        fontWeight:"bold",
    },
    section_c : {
        display:"flex",
        width : "100%",
        marginTop:"20px",
        // justifyContent:"space-between",
    },
    section_w : {
        display :"flex",
        flexDirection : "column",
        alignItems : "center",
        // padding : "10px 0px",
        width : "33.3%",
        alignItems:"center",
    },
    section_i : {
        // width : "50px",
        width : "100%",
        height : "100%",

    },
    btn_w : {
        display : 'flex',
        justifyContent : "flex-end",
        padding : "15px 0px 5px 0px"
    },
    btn : {
        fontSize : "11px",
        fontWeight : "bold"
    }
}

export default UiTeacherList_Style