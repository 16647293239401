import constants from "asset/constants";
import { PaymentContext } from "asset/context";
import EmptyStoryBook from "asset/images/emptyStoryBook.svg";
import { getUser } from "asset/storage";
import { FontStyleMap } from "components/atoms/Font";
import React, { useCallback, useContext, useMemo } from "react";
import { useNavigate } from "react-router";
import styled from "styled-components";
import { WhoIs, WhoType } from "types/enum";

type ClassType = "search" | "belong";
interface LectureSearchItemProps {
  classType?: ClassType;
  class_name?: string;
  classroom_name?: string;
  address?: string;
  intro?: string;
  teacher_count?: number;
  student_count?: number;
  manager_name?: string;
  id: number;
  img?: string;
  isMainList?: boolean;
}
const LectureSearchItem = ({
  class_name,
  classroom_name,
  address,
  intro,
  teacher_count,
  student_count,
  manager_name,
  id,
  img,
  classType = "search",
  isMainList,
}: LectureSearchItemProps) => {
  const navigate = useNavigate();
  const { setSelectedLectureId } = useContext(PaymentContext);
  const contextualMemberText = useMemo(() => {
    let innerText = `수강인원 ${student_count ?? 0}명`;

    if (teacher_count === 1) innerText = `${manager_name ?? "이름 없는"} 선생님 · ${innerText}`;
    else if (teacher_count >= 2)
      innerText = `${manager_name ?? "이름 없는"} 선생님 외 ${teacher_count - 1}명 ·  ${innerText}`;

    return innerText;
  }, [student_count, teacher_count, manager_name]);

  const who = useMemo(() => {
    const userInfo = getUser();
    return userInfo.type === WhoType.student ? WhoIs.student : WhoIs.teacher;
  }, []);

  const handleClick = useCallback(() => {
    navigate(`/lecture/detail/${who}/${id}/de`);
    setSelectedLectureId(id);
  }, [id]);

  const bookcovorUrl = useMemo(() => {
    return `${constants.s3_url}/bookCover/${img}.jpg`;
  }, [constants.s3_url, id]);
  return (
    <Container onClick={handleClick} isMainList={isMainList}>
      <img
        src={bookcovorUrl}
        alt="class"
        onError={(event) => {
          event.currentTarget.src = EmptyStoryBook;
          event.currentTarget.classList.add("empty");
        }}
      />
      <ContentWrapper>
        <FontWrapper>
          <div>
            <TitleFont>{class_name ?? "이름 없는 수업"}</TitleFont>
            <Seperator />
            <SubTitleFont>{classroom_name ?? "이름 없는 학원"}</SubTitleFont>
          </div>
          {classType === "search" && <SubTitleFont>장소 : {address}</SubTitleFont>}
          {classType === "belong" && <SubTitleFont>{contextualMemberText}</SubTitleFont>}
        </FontWrapper>
      </ContentWrapper>
    </Container>
  );
};

const Container = styled.div<{ isMainList?: boolean }>`
  scroll-snap-align: start;
  overflow: hidden;
  cursor: pointer;
  display: flex;
  border-radius: 10px;
  background: #fff;
  box-shadow: 4px 4px 5px 0px rgba(0, 0, 0, 0.05);
  border-bottom: 1px solid #e0e0e0;
  height: 100px;
  @media screen and (min-width: 820px) {
    height: 108px;
  }
  @media screen and (min-width: 1180px) {
    height: 142px;
  }
  > img {
    object-fit: cover;
  }
  > img.empty {
    object-fit: contain;
    background: var(--neutral-color-natural-20);
    padding: 20px;
    @media screen and (min-width: 820px) {
      padding: 30px 50px;
    }
    @media screen and (min-width: 1180px) {
      padding: 10px;
    }
  }

  > img {
    aspect-ratio: 1/1;
    @media screen and (min-width: 820px) {
      aspect-ratio: 192/108;
    }
    @media screen and (min-width: 1180px) {
      aspect-ratio: 110/142;
    }
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 8px;
`;

const FontWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 8px;
  > div {
    display: flex;
    align-items: center;
    flex: 1;
  }
`;

const TitleFont = styled.div`
  color: var(--neutral-color-natural-70);
  ${FontStyleMap["KR/medium/$KR.BtnM.MD.14"]}
  @media screen and (min-width: 820px) {
    ${FontStyleMap["KR/medium/$KR.BL.MD.16"]}
  }
`;

const Seperator = styled.div`
  width: 1px;
  height: 14px;
  background: var(--neutral-color-natural-70);
  margin: 0 4px;
`;

const SubTitleFont = styled.div`
  color: var(--neutral-color-natural-70, var(--Default-Gray-90, #262626));
  ${FontStyleMap["KR/medium/$KR.CAP.MD.12"]}
  @media screen and (min-width: 820px) {
    ${FontStyleMap["KR/medium/$KR.BM.MD.14"]}
  }
`;

const CountFont = styled.div`
  color: var(--neutral-color-natural-70, var(--Default-Gray-90, #262626));
  ${FontStyleMap["KR/regular/$KR.CAP.REG.12"]}
`;
export default LectureSearchItem;
