import { Skeleton } from "@mui/material";
import { PdfItem, PdfTypeName } from "api/pdfView";
import constants from "asset/constants";
import { useIntersectionObserver } from "hooks/useIntersectionObserver";
import React, { useCallback, useMemo, useState } from "react";
import styled from "styled-components";
import PdfItemSubMenu from "./PdfItemSubMenu";
import PinchImage from "./PinchImage";
type QuestionRowProps = {
  slideNumber: number;
  onActive?: (activeIndex: number) => void;
  isActive?: boolean;
  type?: PdfTypeName;
  currentSliderInfo: PdfItem;
};

const PdfRowtem = ({
  slideNumber,
  onActive,
  isActive,
  type,
  currentSliderInfo,
}: QuestionRowProps) => {
  const [imageTime, setImageTime] = useState<number>(new Date().getTime());
  const { elementRef } = useIntersectionObserver<HTMLDivElement>(
    {
      threshold: [0.95],
    },
    (entries) => {
      entries.forEach((entry) => {
        if (entry.intersectionRatio > 0.8) {
          onActive?.(slideNumber);
        }
      });
    }
  );
  const [isLoaded, setIsLoaded] = useState(false);
  const imageSrc = useMemo(() => {
    const imageUrl = `${constants.s3_url}/qna/${currentSliderInfo.qid_HIQDB_online_qna.img}.jpg`;
    return `${imageUrl}?${imageTime}`;
  }, [currentSliderInfo, imageTime]);
  const handleReloadImage = useCallback(() => {
    setImageTime(new Date().getTime());
  }, [setImageTime]);
  return (
    <>
      <ActiveTargetDummy ref={elementRef} />
      <PdfItemSubMenu
        handleReloadImage={handleReloadImage}
        imageSrc={imageSrc}
        currentSliderInfo={currentSliderInfo}
        slideNumber={slideNumber}
        type={type}
      />
      <ImageScrollContainer>
        {isActive && (
          <PinchImage
            imageSrc={imageSrc}
            isActive={isActive}
            setIsLoaded={setIsLoaded}
            isLoaded={isLoaded}
            type={type}
          />
        )}
        {isLoaded || <Skeleton variant="rectangular" width={"100%"} height={"500px"} />}
      </ImageScrollContainer>
    </>
  );
};

const ImageScrollContainer = styled.div`
  display: flex;
  overflow: auto;
  width: auto;
  height: inherit;
  max-height: inherit;
  min-height: inherit;
  -ms-overflow-style: none;
  scrollbar-width: none;
  /* align-items: center; */
  ::-webkit-scrollbar {
    display: none;
  }
`;

const ActiveTargetDummy = styled.div`
  width: 100%;
  position: absolute;
  top: 0;
  height: 1px;
`;

export default PdfRowtem;
