import style from "../../asset/style"

const Message_Home_Style = {
    search_c : {
        paddingBottom : "15px",
        display:"flex",
        flexDirection : 'column',
        width : '100%',
        backgroundColor : "white",
    },
    search_w : {
        padding : "20px 0px",
        display : "flex",
        justifyContent:"space-around",
        flex : 1,
    },
    search_b : {
        width : "40%",
        display:"flex",
        alignItems:"center",
        flexDirection : "column",
        justifyContent:"center",
    },
    search_i_1 : {
        width : "100px"
    },
    search_i_2 : {
        // width : "65%"
        width : "100px"
    },
    text_1 : {
        display:"flex",
        fontWeight : "bold",
        fontSize : '15px'                    
    },
    text_2 : {
        display:"flex",
        fontWeight:600,
        color : style.common.fontColor_6,
        // fontWeight:"bold",
        fontSize : '13px',
        marginTop : "15px"
    },
    line_ : {
        position : "fixed",
        top : 200,
        left : 0,
        backgroundColor : style.common.borderColor2,
        width : "100vw",
        height : "5px"
    },

}

export default Message_Home_Style