import React, {useState, useEffect, useMemo} from 'react'
import styles from './style.module.scss'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import slide1 from './학생초대1.jpg'
import slide2 from './학생초대2.jpg'
import slide3 from './답변등록하기1.jpg'
import slide4 from './답변등록하기2.jpg'
import slide5 from './과거답변이용하기1.jpg'
import slide6 from './과거답변이용하기2.jpg'
import slide7 from './과거답변이용하기3.jpg'
import {Close} from "@material-ui/icons";

const Title = ({index}) => {
    let title = ''
    if (index < 2) {
        title = '학생 초대'
    } else if (index < 4) {
        title = '답변 등록'
    } else {
        title = '과거 답변 검색'
    }
    return <h1>사용방법 안내 - {title}</h1>
}

const Tutorial = ({onClose}) => {
    const [currentIndex, setCurrentIndex] = useState(0)

    return <div
        className={styles.TutorialContainer}
    >
        <div className={styles.titleWrapper}>
            <Title index={currentIndex}/>
            <button onClick={onClose}>
                <Close size="large"/>
            </button>
        </div>
        <Slider
            dots
            infinite
            slidesToShow={1}
            slidesToScroll={1}
            afterChange={(index) => setCurrentIndex(index)}
        >
            <div>
                <img src={slide1}/>
            </div>
            <div>
                <img src={slide2}/>
            </div>
            <div>
                <img src={slide3}/>
            </div>
            <div>
                <img src={slide4}/>
            </div>
            <div>
                <img src={slide5}/>
            </div>
            <div>
                <img src={slide6}/>
            </div>
            <div>
                <img src={slide7}/>
            </div>
        </Slider>
    </div>
}

export default Tutorial
