import { ArrowBackIos } from "@material-ui/icons";
import { LectureVideoListFilterRequset } from "api/classList";
import { getUser } from "asset/storage";
import Container from "components/atoms/Container";
import Header from "components/molecules/Header";
import LectureConfigButton from "pages/LectureVideo/components/LectureRegistButton";
import React, { Suspense, useMemo } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate } from "react-router";
import { WhoType } from "types/enum";
import UiLoading from "uiComponents/UiLoading/UiLoading";
import store from "../../asset/store";
import SearchFilter from "./components/SearchFilter";
import VideoListWrapper from "./components/VideoListWrapper";

const LectureVideoList = () => {
  const methods = useForm<LectureVideoListFilterRequset>({
    defaultValues: {
      type: 7,
      amount: 5,
      cid: "",
      order: "recents",
      keyword: "",
    },
  });
  const navigate = useNavigate();
  const stack = store.get("stack");
  const userInfo = getUser();
  const isTeacher = useMemo(() => userInfo?.type === WhoType.teacher, [userInfo]);
  return (
    <FormProvider {...methods}>
      <Container>
        <Header
          title={"수업 영상"}
          leftIcon={<ArrowBackIos />}
          onLeftClick={() => {
            if (stack.length > 0) navigate(-1);
            else navigate("/", { replace: true });
          }}
        />
        <SearchFilter />
        <Suspense fallback={() => <UiLoading isFull={false} />}>
          <VideoListWrapper />
        </Suspense>
      </Container>
      {isTeacher && <LectureConfigButton menuType="single" />}
    </FormProvider>
  );
};

export default LectureVideoList;
