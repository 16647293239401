import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import "./index.css";
import Providers from "./providers/Providers";
import reportWebVitals from "./reportWebVitals";

const container = document.getElementById("root");
if (container.hasChildNodes()) {
  ReactDOM.hydrate(
    <Providers>
      <App />
    </Providers>,
    container
  );
} else {
  ReactDOM.render(
    <Providers>
      <App />
    </Providers>,
    container
  );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(// console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
