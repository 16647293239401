import { RefObject, useCallback } from "react";
import useDevice from "./use_device";
import { showToast } from "../../providers/ToastProvider";
import { toBlob } from "html-to-image";
import { saveAs } from "file-saver";

type onCapturePropsType = {
  elementRef: RefObject<HTMLElement>;
  captureSize: string;
  onSuccess?: () => void;
};

type CaptureResult = {
  onCapture: ({ elementRef, captureSize, onSuccess }: onCapturePropsType) => void;
};

export default function useCapture(): CaptureResult {
  const { isApp, isPC } = useDevice();

  const showToastMessage = (type: "success" | "error", message: string): void => {
    showToast({
      message,
      type,
      title: type === "success" ? "캡쳐 성공" : "캡쳐 실패",
    });
  };

  const convertElementToBlob = async (
    element: HTMLElement,
    captureSize: string
  ): Promise<Blob | null> => {
    let originalId = element.id;
    let tempIdAssigned = false;
    if (!originalId) {
      originalId = `temp-capture-id-${Math.random().toString(36).substring(2, 11)}`;
      element.id = originalId;
      tempIdAssigned = true;
    }
    const clonedParent = element.parentNode?.cloneNode(true) as HTMLElement;
    const clonedElement = clonedParent?.querySelector<HTMLElement>(`#${CSS.escape(originalId)}`);

    clonedElement.style.cssText = `
        padding: 20px;
         overflow: visible;
         height: auto;
         maxHeight: none;
      `;

    const container = document.createElement("div");
    container.style.cssText = ` 
        position: fixed;
        top: -9999px; 
        left: -9999px; 
        width: ${captureSize === "mobile" ? "375px" : "900px"};
        height: auto;
        overflow: visible;
      `;
    container.appendChild(clonedParent);
    document.body.appendChild(container);
    try {
      if (document.fonts && document.fonts.ready) {
        await document.fonts.ready;
      }

      return await toBlob(clonedElement, {
        cacheBust: true,
        backgroundColor: "#FFF",
        quality: 1,
        pixelRatio: 2,
        skipFonts: true,
        preferredFontFormat: "woff2",
      });
    } catch (e: any) {
      console.error(e);
    } finally {
      document.body.removeChild(container);
      if (tempIdAssigned) {
        element.id = "";
      }
    }
  };

  const onCapture = useCallback(
    async ({ elementRef, captureSize, onSuccess }: onCapturePropsType) => {
      if (!elementRef?.current) {
        showToastMessage("error", "캡쳐 실패: 유효하지 않은 요소입니다.");
        return;
      }

      const element = elementRef.current;

      const blob = await convertElementToBlob(element, captureSize);
      if (!blob) {
        showToastMessage("error", "캡쳐 실패: 이미지 변환에 실패했습니다.");
        return;
      }
      try {
        if (isApp) {
          if (window.ReactNativeWebView) {
            const reader = new FileReader();
            reader.onloadend = () => {
              const base64data = reader.result?.toString().split(",")[1];
              window.ReactNativeWebView.postMessage(
                JSON.stringify({ type: "capture", data: base64data })
              );
            };
            reader.readAsDataURL(blob);
          }
        } else if (isPC && "clipboard" in navigator && "write" in navigator.clipboard) {
          const clipboardItem = new ClipboardItem({ "image/png": blob });
          await navigator.clipboard.write([clipboardItem]);
        } else {
          saveAs(blob, "capture.png");
        }
        showToastMessage("success", "캡쳐에 성공했습니다.");
      } catch (e) {
        console.error(e);
        showToastMessage("error", "클립보드 복사에 실패했습니다.");
      } finally {
        if (onSuccess) {
          onSuccess();
        }
      }
    },
    [isApp, isPC]
  );

  return { onCapture };
}
