import { ArrowBackIos, SearchRounded } from "@mui/icons-material";
import { InputAdornment, debounce } from "@mui/material";
import Input from "components/atoms/Input";
import Header from "components/molecules/Header";
import React, { useCallback } from "react";
import { useNavigate } from "react-router";

interface LectureSearchHeaderProps {
  setSearchValue: React.Dispatch<React.SetStateAction<string>>;
}
const LectureSearchHeader = ({ setSearchValue }: LectureSearchHeaderProps) => {
  const navigate = useNavigate();

  const handleInput = useCallback(
    debounce((event: React.ChangeEvent<HTMLInputElement>) => {
      setSearchValue(event.target.value);
    }, 300),
    []
  );
  return (
    <Header
      title={
        <Input
          onChange={handleInput}
          placeholder="검색어를 입력해주세요."
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchRounded />
              </InputAdornment>
            ),
          }}
        />
      }
      leftIcon={<ArrowBackIos />}
      onLeftClick={() => navigate("/lecture")}
    />
  );
};

export default LectureSearchHeader;
