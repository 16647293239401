import axios from "axios";
import { gql } from "graphql-request";
import constants from "../asset/constants";

const askImageQuestionDoc = gql`
  mutation AskImageQuestion($input: AskImageQuestionInput!) {
    askImageQuestion(input: $input) {
      id
    }
  }
`;
const askVideoQuestionDoc = gql`
  mutation AskVideoQuestion($input: AskVideoQuestionInput!) {
    askVideoQuestion(input: $input) {
      id
    }
  }
`;
const askSerialQuestionDoc = gql`
  mutation AskSerialQuestion($input: AskSerialQuestionInput!) {
    askSerialQuestion(input: $input) {
      id
    }
  }
`;
const registerQuestionDoc = gql`
  mutation RegisterQuestion($input: RegisterQuestionInput!) {
    registerQuestion(input: $input) {
      id
    }
  }
`;

const registerBulkQuestionsDoc = gql`
  mutation registerBulkQuestions($input: RegisterBulkQuestionInput!) {
    registerBulkQuestions(input: $input) {
      successTasks
    }
  }
`;

const studentCancelQuestionDoc = gql`
  mutation StudentCancelQuestion($input: StudentCancelQuestionInput!) {
    studentCancelQuestion(input: $input)
  }
`;

const teacherCancelQuestionDoc = gql`
  mutation TeacherCancelQuestion($input: TeacherCancelQuestionInput!) {
    teacherCancelQuestion(input: $input)
  }
`;

export const extractTextFromImage = async (bucketPath: string) => {
  let result: any;
  try {
    const response = await axios.post(
      `${constants.apiUrl}/ocr/image`,
      { bucketUri: bucketPath },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    result = { success: true, content: response.data.result, bucketFilePath: bucketPath };
  } catch (e: any) {
    result = {
      success: false,
      message:
        e?.message || "문제 사진으로 적합하지 않은 이미지에요. 사진을 다시 한번 확인해주세요.",
    };
  } finally {
    return result;
  }
};
