import React from "react";
import BottomSheet from "../../../../components/molecules/BottomSheet";
import { useRecoilState, useSetRecoilState } from "recoil";
import { captureSizeSheetAtom } from "../../../../application/recoils/sheet/atoms";
import styled from "styled-components";
import useCapture from "../../../../hooks/common/use_capture";
import { captureStateModalAtom } from "../../../../application/recoils/modal/atoms";

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
  margin-top: 16px;
`;

const SizeButton = styled.button`
  border: 1px solid #fe544e;
  padding: 8px 16px;
  border-radius: 10px;
  font-weight: 500;
`;

export default function CaptureSizeSheet(): JSX.Element {
  const [sheetData, setSheetData] = useRecoilState(captureSizeSheetAtom);
  const setCaptureStateModal = useSetRecoilState(captureStateModalAtom);
  const { onCapture } = useCapture();

  const handleClose = () => {
    setSheetData({ ...sheetData, isOpen: false });
  };

  const handleCapture = async (option: "mobile" | "tablet"): Promise<void> => {
    handleClose();
    setCaptureStateModal(true);
    await new Promise((resolve) => setTimeout(resolve, 0));

    onCapture({
      elementRef: sheetData.targetRef,
      captureSize: option,
      onSuccess: () => {
        setCaptureStateModal(false);
      },
    });
  };

  return (
    <BottomSheet
      open={sheetData.isOpen}
      onClose={handleClose}
      title={"이미지 사이즈를 선택해 주세요."}
    >
      <Container>
        <SizeButton onClick={() => handleCapture("mobile")}>모바일 사이즈</SizeButton>
        <SizeButton onClick={() => handleCapture("tablet")}>태블릿 사이즈</SizeButton>
      </Container>
    </BottomSheet>
  );
}
