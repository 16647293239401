import style from "../../asset/style"

const UiVideoSectionList_Style = {
    section_w : {
        display:"flex",
        // flexDirection : "column",
        width : "100%",
        padding : "18px 0px 15px 0px",
        borderBottom : '1px solid',
        borderColor : style.common.borderColor4,
        // backgroundColor : "orange",
    },
    section_i_w : {
        // backgroundColor : "green",
        display:"flex",
        alignItems : "center",
        // paddingRight : "10px",
        // width : "100%",
    }, 
    section_folder_chk : {
        marginRight : "10px"
        // left: 2,
        // top : 4,
    },
    section_t_w : {
        display:"flex",
        width : "100%",
    },
    section_t_img_w : {
        width  : "60px",
        height : '60px',
    },
    section_r_w : {
        display : "flex",
        flex:1,
        flexDirection:"column",
        paddingLeft : "10px",
    },
    section_r_text_1 : {
        display:"flex",
        // width : "100%",
        // color : "#555555",
        color : style.common.fontColor_6,
        fontSize : "10px",
    },
    section_r_text_2 : {
        fontSize : "16px",
        marginTop : "4px",
    },
    section_b_w : {
        display:"flex",
        width : "100%",
        marginTop : "10px",
        fontSize : "11px",
        justifyContent:"space-between",
        color : style.common.fontColor_1
    },
    section_b_text_1 : {
        paddingLeft:'2px',
        color : style.common.fontColor_6,
    },
    section_b_text_2 : {
        display:"flex",
        color : style.common.fontColor_6,
    },
    // section_b_w : ,

}

export default UiVideoSectionList_Style