import { useRecentQnaList } from "api/messageMark";
import React, { useMemo } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { GetOnlineQnaLogQuery } from "types/graphql/graphql";
import EmptyArea from "./EmptyArea";
import QnaItem from "./QnaItem";
import { ImageContainer } from "./QnaList";

const RecentList = () => {
  const { data: recentData, fetchNextPage } = useRecentQnaList();
  const { totalCount, recentList } = useMemo(() => {
    const newList = recentData?.pages.reduce(
      (newList: GetOnlineQnaLogQuery["getOnlineQnaLog"]["qnaLogs"], page) => [
        ...newList,
        ...(page?.getOnlineQnaLog?.qnaLogs ?? []),
      ],
      []
    );

    return {
      recentList: newList.map((item) => item.qid_HIQDB_online_qna),
      totalCount: recentData?.pages[0]?.getOnlineQnaLog?.totalCount ?? 0,
    };
  }, [recentData]);

  const isEmpty = useMemo(() => {
    return recentList.length === 0;
  }, [recentList]);

  return isEmpty ? (
    <EmptyArea message={`최근 본 질문이 없어요.`} />
  ) : (
    <InfiniteScroll
      dataLength={recentList?.length ?? 0}
      next={fetchNextPage}
      loader={<></>}
      scrollableTarget="scrollableDiv"
      hasMore={recentList?.length > 0 && totalCount > recentList?.length}
    >
      <ImageContainer>
        {recentList.map((item) => {
          return (
            <QnaItem
              videoContent={item.HIQDB_online_qna_msgs?.[0]?.content}
              {...item}
            />
          );
        })}
      </ImageContainer>
    </InfiniteScroll>
  );
};

export default RecentList;
