import { useStudyBookFolderList, useStudyBookInfo, useStudyBookMove } from "api/studyBook";
import { ReactComponent as ArrowDown } from "asset/images/icon/arrow-down.svg";
import { ReactComponent as MoveIcon } from "asset/images/icon/move-to.svg";
// import { ReactComponent as TalkIcon } from "asset/images/icon/talk.svg";
import CommonModal from "components/atoms/CommonModal";
import { FontStyleMap } from "components/atoms/Font";
import ReactiveButtonContainer from "components/atoms/ReactiveButtonContainer";
import { StyledCommonConfirmContainer } from "components/templates/Confirm";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import styled, { css } from "styled-components";

type StudyBookMoveModalItem = {
  title: string;
  value: string;
};

type StudyBookMoveModalProps = {
  bookIds: string[];
  onSuccess?: () => void;
} & CommonModalControl;

const StudyBookMoveModal = ({ bookIds, onClose, open, onSuccess }: StudyBookMoveModalProps) => {
  const { data: folderData } = useStudyBookFolderList();
  const { data: bookData } = useStudyBookInfo(open ? bookIds[0] : null);
  const [selectedStudyBookMoveModal, setSelectedStudyBookMoveModal] =
    useState<StudyBookMoveModalItem>();

  useEffect(() => {
    if (bookData && open) {
      const originFolder = bookData.getBook.book.HIQDB_book_links[0].fid_HIQDB_folder;
      setSelectedStudyBookMoveModal(
        folderList.find((item) => item.value === originFolder?.id) || folderList[0]
      );
    }
  }, [bookData, open]);
  const folderList = useMemo(() => {
    return folderData?.list.map((folder) => ({
      title: folder.name,
      value: `${folder.id}`,
    }));
  }, [folderData]);

  const [isOpenSelect, setIsOpenSelect] = useState(false);

  const LeftTypeList = useMemo(
    () => folderList?.filter((item) => item.value !== selectedStudyBookMoveModal?.value) ?? [],
    [folderList, selectedStudyBookMoveModal]
  );

  const handleSelectStudyBookMoveModal = useCallback(
    (item: StudyBookMoveModalItem) => {
      setSelectedStudyBookMoveModal(item);
      setIsOpenSelect(false);
    },
    [setSelectedStudyBookMoveModal]
  );

  const { mutate } = useStudyBookMove();

  const handleSubmit = useCallback(async () => {
    await mutate({ bidList: bookIds, fid: selectedStudyBookMoveModal?.value });
    onSuccess ? onSuccess() : onClose();
  }, [onClose, bookIds, selectedStudyBookMoveModal, onSuccess]);

  return (
    <CommonModal open={open} onClose={onClose}>
      <StyledCommonConfirmContainer>
        <FontContainer>
          <MoveIcon width={32} height={32} />
          <TitleFont>교재 유형</TitleFont>
          <SelectListContainer>
            {
              <SelectListFirstItemContainer onClick={() => setIsOpenSelect((prev) => !prev)}>
                {selectedStudyBookMoveModal?.title}
                <ArrowIconContainer isOpen={isOpenSelect}>
                  <ArrowDown />
                </ArrowIconContainer>
              </SelectListFirstItemContainer>
            }
            {isOpenSelect && (
              <SelectListScrollContainer>
                {LeftTypeList.map((item, index) => {
                  return (
                    <SelectListItemContaner onClick={() => handleSelectStudyBookMoveModal(item)}>
                      {item.title}
                    </SelectListItemContaner>
                  );
                })}
              </SelectListScrollContainer>
            )}
          </SelectListContainer>
        </FontContainer>
        <ReactiveButtonContainer
          buttonList={[
            {
              onClick: onClose,
              variant: "text",
              title: "취소",
            },
            {
              onClick: handleSubmit,
              variant: "contained",
              title: "확인",
            },
          ]}
        />
      </StyledCommonConfirmContainer>
    </CommonModal>
  );
};

const FontContainer = styled.div`
  gap: 16px;
  display: flex;
  flex-direction: column;
  margin-bottom: 42px;
`;
const TitleFont = styled.div`
  color: var(--neutral-color-natural-80, #141414);
  ${FontStyleMap["KR/bold/$KR.TS.BOL.20"]}
`;

const SelectListContainer = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  border: 1.3px solid var(--neutral-color-natural-30, #d9d9d9);
  background: var(--background-paper-elevation-0, #fff);
`;

const SelectListScrollContainer = styled.div`
  max-height: 130px;
  overflow-y: auto;
  scroll-snap-type: y mandatory;
`;

const SelectListItemContaner = styled.div`
  border-top: 1px solid var(--neutral-color-natural-30, #d9d9d9);
  justify-content: space-between;
  display: flex;
  align-items: center;
  padding: 11px 16px;
  scroll-snap-align: start;
`;

const SelectListFirstItemContainer = styled(SelectListItemContaner)`
  padding: 13px 16px;
  border-top: none;
`;

const ArrowIconContainer = styled.div<{ isOpen: boolean }>`
  ${({ isOpen }) =>
    isOpen &&
    css`
      transform: rotate(180deg);
    `}
`;
export default StudyBookMoveModal;
