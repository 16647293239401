import { KeyboardArrowDown, KeyboardArrowUp } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { axiosInstance } from "../../api/common";
import apiFn from "../../asset/apiClass";
import UiSearchBox from "../../uiComponents/UiSearch/UiSearchBox";

type Modal_shareProps = {
  setFileObj: Function;
  setIsModal: (modal: boolean) => void;
  userInfo: any;
};

const Modal_share = ({ setFileObj, setIsModal, userInfo }: Modal_shareProps) => {
  let params = useParams();

  var [lectureList, setLectureList] = useState([]);

  const apiPro_read = async (id = null, sort = null) => {
    try {
      let where_obj = {};
      if (id) {
        where_obj["HIQDB_class.pid"] = `='${id}'`;
      }
      // // console.log("@@@ ",id);
      let data = await apiFn.comApi({
        state: {
          table: "class",
          page: 1,
          amount: 9999,
          u_id: userInfo["id"],
          sort: sort ? sort : `name desc`,
          ref: [
            "HIQDB_member_class.cid|HIQDB_member_class.cid=HIQDB_class.id",
            "HIQDB_member_class.mid|HIQDB_member_class.cid=HIQDB_class.id",
            `B.name as academyName|HIQDB_class B on B.id=HIQDB_class.pid`,
          ],
          where: {
            "HIQDB_member_class.mid": `='${userInfo["id"]}'`,
            "HIQDB_class.status": `='1'`,
            "HIQDB_member_class.status": `!='9'`,
            // 'HIQDB_class.status':`=!'9'`,
            ...where_obj,
          },
          count: [
            "HIQDB_member_class.mid:cid:id|and countTable.type ='0' and countTable.status='1'|lcnt",
            // 'HIQDB_member_class.mid:cid:id|and countTable.type =\'0\'|lcnt',
            // "HIQDB_member_class.mid:cid:id|and countTable.type in ('5','6','7','8','9')|t_cnt"
          ],
        },
      });

      // console.log("AAA ? ? ? ?",data)

      let no_share_array = [];

      data["list"].map((v, i) => {
        if (v["book"].length == 0) {
          no_share_array.push(v);
        } else {
          let bookList = v["book"].reduce((a, v, i) => {
            return [...a, v["id"]];
          }, []);

          // console.log("$$$$$$$$$$$$$$$$$$$ ",params["id"])
          if (bookList.includes(Number(params["id"]))) {
          } else {
            no_share_array.push(v);
          }
          // console.log("## ",v);
          // console.log("bookList => ",bookList);

          // for(var i = 0; i < v["book"].length; i++){
          //     if(v["book"][i]["id"] == params["id"])return false;
          //     else {
          //         no_share_array.push(v)
          //     }
          // }
        }
      });

      console.log("@@@@@@@@@@@@@ ", no_share_array);

      setLectureList([...no_share_array]);

      // setClassData([
      //     ...data["list"]
      // ])
    } catch (e) {
      console.log(e);
    }
  };

  // 교재 -> 강의 공유
  const apiPro_set = async (classData) => {
    console.log(classData);
    try {
      const { data } = await axiosInstance.post("/book/share", {
        bookId: params.id,
        classId: classData.cid,
        sharedId: userInfo.id,
      });
      apiPro_read();
    } catch (e) {
      console.error(e);
      const error = e as any;
      if (error?.response?.status === 409) {
        alert("이미 공유되어있는 교재입니다.");
      } else if (error?.response?.status === 403) {
        alert(`권한이 없습니다. \n ${error?.response?.data?.message}`);
      } else if (error?.response?.status === 404) {
        alert("수업 정보가 없습니다.");
      } else if (error?.response?.status === 400) {
        alert("정보가 잘못 되었습니다.");
      }
    }
  };

  useEffect(() => {
    apiPro_read();
  }, []);

  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        zIndex: 130002,
        backgroundColor: "rgba(150, 150, 150,0.5)",
        // backgroundColor:"red",
        width: "100%",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
      onClick={() => {
        setIsModal(false);
      }}
    >
      <div
        style={{
          // width : "75%",
          width: "300px",
          minHeight: "55%",
          maxHeight: "75%",
          backgroundColor: "white",
          padding: "35px 20px 40px 20px",
          borderRadius: 10,
          border: "1px solid rgb(200, 200, 200)",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          filter: "drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.37))",
        }}
        onClick={(e) => {
          e.stopPropagation();
          // e.preventDefault();
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            fontSize: "18px",
            fontWeight: 700,
            marginBottom: "10px",
          }}
        >
          {"공유할 수업 추가"}
        </div>
        <UiSearchBox
          placeholder={"수업명을 입력해주세요."}
          fnChange={(e) => {}}
          fnClick={() => {}}
          isTeacher={true}
        />
        <div
          style={{
            // paddingTop:"15px",
            margin: "15px 0px",
            overflowY: "scroll",
            display: "flex",
            justifyContent: "flex-start",
            flexDirection: "column",
            flex: 1,
          }}
        >
          {lectureList.map((v, i) => (
            <LectureSection
              data={v}
              onClick={(d) => {
                let isOk = window.confirm(`[${d["name"]}] 강의에 교재를 공유 하시겠습니까?`);
                if (isOk) {
                  apiPro_set(d);
                }
              }}
            />
          ))}
        </div>
        <div
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "center",
            marginTop: "10px",
          }}
        >
          <div
            style={{
              display: "flex",
              // width:"180px",
              // justifyContent:"space-between",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "87px",
                height: "40px",
                borderRadius: "15px",
                backgroundColor: "#D8D8D8",
                fontSize: "14px",
                color: "#FFFFFF",
                fontWeight: 700,
              }}
              onClick={() => {
                setIsModal(false);
                setFileObj({});
              }}
            >
              취소
            </div>
            {/* <div style={{
                            display:"flex",
                            justifyContent:"center",
                            alignItems:"center",
                            width:"87px",
                            height:"40px",
                            borderRadius:"15px",
                            backgroundColor:"#5EA3C6",
                            fontSize:"14px",
                            color : "#FFFFFF",
                            fontWeight:700
                        }}
                            onClick={()=>{
                                apiPro_regi();
                            }}
                        >
                            등록

                        </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

const LectureSection = ({ data, onClick }) => {
  var [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    console.log("DATA : ", data);
  }, [data]);

  return (
    <div
      style={{
        padding: "8px 0px",
        borderBottom: "1px solid #F6F6F6",
      }}
      onClick={(e) => {
        onClick(data);
        e.stopPropagation();
        e.preventDefault();
      }}
    >
      <div
        style={{
          display: "flex",
          marginBottom: "5px",
        }}
      >
        <div
          style={{
            fontSize: "14px",
            fontWeight: 700,
          }}
        >
          {data["name"]}
        </div>
        {data["owner"] ? (
          <>
            <div
              style={{
                color: "#B0B0B0",
                fontSize: "10px",
                marginLeft: "3px",
                fontWeight: 700,
              }}
            >
              |
            </div>
            <div
              style={{
                color: "#B0B0B0",
                fontSize: "14px",
                fontWeight: 700,
                marginLeft: "3px",
              }}
            >
              {`${data["owner"]} 선생님`}
            </div>
          </>
        ) : (
          <>
            <div
              style={{
                color: "#B0B0B0",
                fontSize: "10px",
                fontWeight: 700,
                marginLeft: "3px",
              }}
            >
              |
            </div>
            <div
              style={{
                color: "#B0B0B0",
                fontSize: "12px",
                fontWeight: 400,
                marginLeft: "3px",
              }}
            >
              {data["teachers"] ? ` ${data["teachers"][0]["name"]} 선생님` : ""}
            </div>
          </>
        )}
      </div>
      <div
        style={{
          display: "flex",
          color: "#B0B0B0",
          fontSize: "12px",
          fontWeight: 400,
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            display: "flex",
          }}
        >
          <div style={{}}>{data["academyName"]}</div>
          <div
            style={{
              margin: "0px 3px",
            }}
          >
            -
          </div>
          <div style={{}}>{data["lcnt"] ? data["lcnt"] : 0}명</div>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "flex-start",
          }}
          onClick={(e) => {
            setIsOpen(!isOpen);
            e.stopPropagation();
          }}
        >
          {isOpen ? "닫기" : "펼치기"}
          {isOpen ? (
            <KeyboardArrowUp
              style={{
                fontSize: "16px",
              }}
            />
          ) : (
            <KeyboardArrowDown
              style={{
                fontSize: "16px",
              }}
            />
          )}
        </div>
      </div>
      {isOpen && (
        <div
          style={{
            display: "flex",
            fontSize: "12px",
            fontWeight: 400,
            marginTop: "10px",
          }}
        >
          <div style={{}}>{data["intro"]}</div>
        </div>
      )}
    </div>
  );
};

export default Modal_share;
