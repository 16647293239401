import { PopoverOrigin } from "@mui/material";
import Menu from "@mui/material/Menu";
import React, { ReactNode, useEffect, useState } from "react";
import styled from "styled-components";
interface ItemSubMenuProps {
  anchorElement?: HTMLElement | null;
  onClose?: () => void;
  open: boolean;
  menuList: {
    icon: ReactNode;
    text: string;
    onClick: () => void;
  }[];
  transformOrigin?: PopoverOrigin;
  anchorOrigin?: PopoverOrigin;
  style?: React.CSSProperties;
}
const ItemSubMenu = ({
  anchorElement,
  onClose,
  menuList,
  open,
  transformOrigin = { horizontal: "center", vertical: "top" },
  anchorOrigin = { horizontal: "center", vertical: "bottom" },
  style = {},
}: ItemSubMenuProps) => {
  const [anchorWidth, setAnchorWidth] = useState<number>();

  useEffect(() => {
    setAnchorWidth((prev) => prev || anchorElement?.clientWidth);
  }, [anchorElement]);
  return (
    <>
      <Menu
        id="basic-menu"
        anchorEl={anchorElement}
        open={open}
        transformOrigin={transformOrigin}
        anchorOrigin={anchorOrigin}
        onClose={onClose}
        PaperProps={{
          style: {
            backgroundColor: "#252525",
            boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
            padding: "0 !important",
            color: "#fff",
            display: "flex",
            flexDirection: "column",
            width: anchorWidth,
            ...style,
          },
        }}
        MenuListProps={{
          "aria-labelledby": "test2",
        }}
      >
        {menuList.map((menu, index) => (
          <MenuItem key={index} onClick={menu.onClick}>
            {menu.text}
            {menu.icon}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

const MenuItem = styled.li`
  cursor: pointer;
  background-color: #252525;
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  padding: 0px 8px;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1.5px solid #fff;
  :first-child {
    padding-bottom: 8px;
  }
  :last-child {
    border-bottom: unset;
    padding-top: 8px;
  }
`;

export default ItemSubMenu;
