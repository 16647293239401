import style from "../../asset/style"

const UiPeople_ProfileTeacher_Style = {
    section_c : {
        display:"flex",
        height : '58px',
        margin : "15px 0px"
        // backgroundColor:"purple",
    },   
    section_i_w : {
        display:'flex',
        justifyContent:'center',
        alignItems:"center",
        width : "55px",
        height : "55px",
        // backgroundColor : "rgb(230,230,230)",
        borderRadius : 12,
    },
    section_i : {
        // width : "84px",
        borderRadius : 12,
        width:"100%",
        height:"100%",
        objectFit : "cover",
        objectFitPosition : "center"
    },
    section_r_c : {
        display:"flex",
        flexDirection:"column",
        justifyContent:"space-between",
        width : '80%',
        height : "100%",
    },
    section_r_t_w : {
        display:"flex",
        justifyContent:"space-between",
        // fontSize : '13px',
        fontSize : "16px",
        marginBottom : "2px"
    },
    section_r_t_t_w : {
        display:"flex"
    },
    section_r_t_t_text_1 : {
        marginRight : '5px',
        color : style.common.t_color
    },
    section_r_t_t_text_2 : {
        marginRight : '5px',
        // fontWeight : 'bold',
    },
    section_r_t_t_text_3 : {
        color : style.common.fontColor_1
    },
    section_r_m_w : {
        display:"flex",
        justifyContent:"space-between",
        fontSize : '11px',
        color : style.common.fontColor_1,
        marginBottom:"2px",
        // marginTop : '2px',
    },
    section_r_m_l_w : {
        display:"flex"
    },
    section_r_m_r_w : {
        display:"flex",
        justifyContent:"center",
        alignItems:"center",
        fontWeight:"bold",
        padding : '1px 5px',
        width : '12px',
        backgroundColor : 'red',
        borderRadius : 10,
        color : "white",
        fontSize : "11px"
    },
    section_r_b_w  : {
        display:"flex",
        justifyContent:"space-between",
        fontSize : '11px',
        color : style.common.fontColor_1,
        // marginTop : '2px',
        paddingBottom : "2px",
    },
    openAdd_c : {
        display:'flex',
        justifyContent:"center",
        width :"100%",
        // height : "30px",
        borderTop : "1px solid",
        borderColor : style.common.borderColor,
        // backgroundColor : "purple",
    },
    openAdd_btn_w : {
        width : "70%",
        display:"flex",
        justifyContent:"space-between",
        padding : "10px 0px",
    }, 
    openAdd_btn : {
        fontSize : "12px",
        color : style.common.fontColor_1
    },
}

export default UiPeople_ProfileTeacher_Style