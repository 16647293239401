import { ReactComponent as FolderIcon } from "asset/images/icon/folder.svg";
import { ReactComponent as StudyFillIcon } from "asset/images/icon/studybook-fill.svg";
import { ReactComponent as PdfIcon } from "asset/images/icon/pdf-icon.svg";
import LightBallMenu from "components/molecules/LightBallMenu";
import React, { useCallback } from "react";
import FolderRegistModal from "./FolderRegistModal";
import StudyBookRegistModal from "./StudyBookRegistModal";
import PdfUploadModal from "../../StudyBookDetail/components/PdfUploadModal";

const RegistTemplate = () => {
  const [isOpenFolderRegistModal, setIsOpenFolderRegistModal] = React.useState(false);
  const [isOpenBookRegistModal, setIsOpenBookRegistModal] = React.useState(false);
  const [isOpenFileUploadModal, setIsOpenFileUploadModal] = React.useState(false);
  const handleFolderRegistClose = useCallback(() => {
    setIsOpenFolderRegistModal(false);
  }, [setIsOpenFolderRegistModal]);
  return (
    <>
      <LightBallMenu
        menuList={[
          {
            icon: <StudyFillIcon fill="#fe544e" />,
            title: "교재 생성",
            onClick() {
              setIsOpenBookRegistModal(true);
            },
          },
          {
            icon: <PdfIcon />,
            title: "파일 업로드",
            onClick() {
              setIsOpenFileUploadModal(true);
            },
          },
          {
            icon: <FolderIcon />,
            title: "폴더 생성",
            onClick() {
              setIsOpenFolderRegistModal(true);
            },
          },
        ]}
      />
      <FolderRegistModal open={isOpenFolderRegistModal} onClose={handleFolderRegistClose} />
      <StudyBookRegistModal
        open={isOpenBookRegistModal}
        onClose={() => setIsOpenBookRegistModal(false)}
      />
      <PdfUploadModal
        open={isOpenFileUploadModal}
        onClose={() => setIsOpenFileUploadModal(false)}
      />
    </>
  );
};

export default RegistTemplate;
