import { React, useEffect, useState } from "react";
import { useParams } from "react-router";
import API from "../../api";
import gridview from "../../asset/Img/gridview.png";
import icon_setting_gray from "../../asset/Img/icon_setting_gray.png";
import imageview from "../../asset/Img/imageview.png";
import listview from "../../asset/Img/listview.png";
import apiFn from "../../asset/apiClass";
import style from "../../asset/style";
import Frame from "../../common/frame/Frame";
import UiQna from "../../pages/QNA/UiQna";
import UiQna_grid from "../../pages/QNA/UiQna_grid";
import UiQna_image from "../../pages/QNA/UiQna_image";
import { useDeleteQuestionList, useRestoreQuestion } from "../../api/question";

const Book_remove = ({ userInfo, setFileObj, setQnaData }) => {
  let params = useParams();

  var [qnaData, setQnaData] = useState([]);

  var [listStat, setListStat] = useState("listview");

  var [selectData, setSelectData] = useState({});

  var [isEdit, setIsEdit] = useState(false);

  const apiPro_remove = async (qid) => {
    try {
      await useRestoreQuestion(qid);
      let bookChk = await apiFn.comApi({
        state: {
          table: "book_link",
          page: 1,
          amount: 9999,
          sort: "mid desc",
          where: {
            "HIQDB_book_link.qid": `= '${qid}'`,
            "HIQDB_book_link.bid": `!= '0'`,
          },
        },
      });

      if (bookChk["list"].length > 0) {
      } else {
        await apiFn.comApi({
          state: {
            table: "book_link",
            ins: [
              {
                fid: 0,
                // mid : 0,
                qid: qid,
                cid: 0,
                bid: 0,
              },
            ],
          },
        });
      }
    } catch (e) {
      console.log("************");
      console.log(e);
      console.log("************");
    }
  };
  const apiPro_del_indi = async (data) => {
    try {
      let qidArr = Object.keys(selectData);
      Promise.all(qidArr.map(async (qna) => await API.deleteQna(qna))).then(async () => {
        setSelectData({});
        alert("정상적으로 삭제했습니다.");
        await apiPro_read_del();
      });
    } catch (e) {
      console.error(e);
    }
  };
  const apiPro_remove_all = async () => {
    try {
      let qidArr = Object.keys(selectData);
      let promiseArray = qidArr.map((v) => apiPro_remove(v));
      Promise.all([...promiseArray]);

      // util.postWeb("isModal_off")
      // setIsPop(false)
      setIsEdit(false);
      await apiPro_read_del();
    } catch (e) {
      console.log("************");
      console.log(e);
      console.log("************");
    }
  };
  // 삭제 목록 호출
  const apiPro_read_del = async (sort, bid) => {
    try {
      let whereObj = {};
      if (params["who"] == "teacher") {
        whereObj["HIQDB_online_qna.tid"] =
          `= '${userInfo["id"] ? userInfo["id"] : JSON.parse(window.localStorage.getItem("user"))?.id}'`;
      } else {
        whereObj["HIQDB_online_qna.sid"] =
          `= '${userInfo["id"] ? userInfo["id"] : JSON.parse(window.localStorage.getItem("user"))?.id}'`;
      }

      const data = await useDeleteQuestionList(whereObj);

      console.log("data : ", data);

      let del_box = [];
      Date.prototype.ymd = function () {
        if (this.getTime() < 1000000000000) this.setTime(this.getTime() * 1000);
        var mm = this.getMonth() + 1;
        var dd = this.getDate();

        return [this.getFullYear(), (mm > 9 ? "" : "0") + mm, (dd > 9 ? "" : "0") + dd].join("");
      };
      let today_date = new Date().ymd();
      let today_day = today_date.substr(6, 2);
      let today_month = today_date.substr(4, 2);
      // let today_month = 10;

      // console.log("@ day ",today_day)
      // console.log("# month ",today_month)

      // (30 - current day) + removeeDay
      // let today_date = 20221020

      let removeList = [];
      data["list"].map((v, i) => {
        // let month_ = v["remove_date"].substr(0,8)
        let month_ = v["remove_date"].substr(0, 8).substr(4, 2);
        // console.log("month_ : ",month_)
        if (today_month == month_) {
          // console.log("@@ ",month_);
          // console.log("equal month")
          // // console.log(deadLine)
          // console.log("REMOVE DATE : ",v["remove_date"].substr(0,8));
          // console.log("REMOVE DATE : ",v["remove_date"].substr(0,8).substr(6,8));
          // console.log("Current DATE - REMOVE DATE : ", new Date().ymd() - Number(v["remove_date"].substr(0,8)));

          if (today_date - Number(v["remove_date"].substr(0, 8)) > 30) {
            del_box.push({
              id: v["id"],
            });
          } else {
            v["leftover"] = today_date - Number(v["remove_date"].substr(0, 8));
            removeList.push(v);
          }
        } else if (Math.abs(today_month - month_) >= 2) {
          del_box.push({
            id: v["id"],
          });
        } else {
          // console.log("month_ : ",month_)
          // console.log("today_month - month_ : ",Math.abs(today_month - month_))
          // console.log("REMOVE DATE : ",v["remove_date"].substr(0,8));
          // console.log("1 : ",today_date.substr(6,2))
          // console.log("2 : ", Number(v["remove_date"].substr(0,8).substr(6,2)))
          // console.log("3 : ",30 - today_date.substr(6,2))
          // console.log("4 : ",Number(v["remove_date"].substr(0,8).substr(6,2)))

          // console.log((Number(v["remove_date"].substr(0,8).substr(6,2))) + Number(today_date.substr(6,2)))
          // return;
          // let deadLine = (30 - (Number(v["remove_date"].substr(0,8).substr(6,2))) + Number(today_date.substr(6,2)))
          let deadLine =
            Number(v["remove_date"].substr(0, 8).substr(6, 2)) + Number(today_date.substr(6, 2));
          // console.log("not equal month")
          // console.log("Deadline : ",deadLine );
          if (deadLine > 30) {
            del_box.push({
              id: v["id"],
            });
          } else {
            v["leftover"] = deadLine;
            removeList.push(v);
          }
        }
      });

      // console.log(del_box)
      // console.log("removeList : ",removeList);

      // if(del_box.length > 0){
      //     await apiFn.comApi({
      //         state : {
      //             table : "online_qna",
      //             del : del_box

      //         }
      //     })
      // }

      data["list"].map((v, i) => {
        v["reg_date"] = v["remove_date"];
      });

      // console.log("qnadata => ",data["list"])

      setQnaData([...removeList]);

      // setDel_bookId("0")
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    setQnaData([]);
    apiPro_read_del();
    return () => {
      // setQnaData([])
    };
  }, []);

  return (
    <Frame
      userInfo={userInfo}
      // isBack={false}
      type="2"
      isSearch={false}
      title={
        <div
          style={{
            fontFamily: "Roboto",
            fontStyle: "normal",
            fontWeight: 400,
            fontSize: "24px",
            lineHeight: "28px",
            textAlign: "center",
            letterSpacing: "2px",
            display: "flex",
          }}
        >
          삭제된 질문
          <div style={{ marginLeft: "5px" }}>{`(${qnaData.length}개)`}</div>
        </div>
      }
      backEvent={() => {
        setFileObj({});
      }}
    >
      <div
        style={{
          width: "100%",
          padding: "0px 10px",
        }}
      >
        <div
          style={{
            padding: "10px 0px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div
            style={{
              fontSize: "12px",
              color: "black",
              display: "flex",
              flexDirection: "column",
              marginTop: "2px",
            }}
          >
            {isEdit ? (
              <>{`${Object.keys(selectData).length}개 선택`}</>
            ) : (
              <>
                <div>임시 보관중인 목록 입니다.</div>
                <div
                  style={{
                    color: style.common.fontColor_3,
                  }}
                >
                  * 30일 보관후 자동 삭제 됩니다.
                </div>
              </>
            )}
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            {isEdit ? (
              <div
                style={{
                  display: "flex",
                  fontSize: "12px",
                  fontWeight: 500,
                }}
              >
                <div
                  style={{
                    marginRight: "6px",
                  }}
                  onClick={() => {
                    let obj = {};
                    qnaData.map((v) => {
                      obj[v["id"]] = "Y";
                    });
                    setSelectData({
                      ...obj,
                    });
                  }}
                >
                  전체 선택
                </div>
                <div
                  style={{
                    marginRight: "6px",
                  }}
                  onClick={() => {
                    setSelectData({});
                  }}
                >
                  선택 취소
                </div>
                <div
                  style={{
                    marginRight: "6px",
                    color: "red",
                    padding: "0px 4px",
                  }}
                  onClick={() => {
                    apiPro_del_indi();
                  }}
                >
                  선택 삭제
                </div>
                <div
                  style={{
                    marginRight: "6px",
                    color: style.common.t_color,
                    padding: "0px 4px",
                  }}
                  onClick={() => {
                    apiPro_remove_all();
                  }}
                >
                  선택 복구
                </div>
                <div
                  style={{
                    marginRight: "6px",
                    color: style.common.fontColor_3,
                  }}
                  onClick={() => {
                    setSelectData({});
                    setIsEdit(false);
                  }}
                >
                  취소
                </div>
              </div>
            ) : (
              <>
                <img
                  src={icon_setting_gray}
                  style={{
                    width: "24px",
                    height: "24px",
                    marginRight: "10px",
                  }}
                  onClick={() => {
                    setIsEdit(true);
                  }}
                />
                <div
                  style={{}}
                  onClick={() => {
                    if (listStat == "listview") {
                      setListStat("gridview");
                      window.localStorage.setItem("explain_section", "gridview");
                    } else if (listStat == "gridview") {
                      setListStat("imageview");
                      window.localStorage.setItem("explain_section", "imageview");
                    } else {
                      setListStat("listview");
                      window.localStorage.setItem("explain_section", "listview");
                    }
                  }}
                >
                  {listStat == "listview" ? (
                    <img
                      src={listview}
                      style={{
                        width: "26px",
                        height: "26px",
                      }}
                    />
                  ) : listStat == "gridview" ? (
                    <img
                      src={gridview}
                      style={{
                        width: "26px",
                        height: "26px",
                      }}
                    />
                  ) : (
                    // listStat == "imageview" &&
                    <img
                      src={imageview}
                      style={{
                        width: "26px",
                        height: "26px",
                      }}
                    />
                  )}
                </div>
              </>
            )}
          </div>
        </div>

        <div
          style={{
            width: "100%",
            padding: "10px 0px",
          }}
        >
          {listStat == "gridview" ? (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
              }}
            >
              {qnaData.map((v, i) => (
                <div
                  style={{
                    display: "flex",
                    flexGrow: 1,
                    maxWidth: "200px",
                    justifyContent: "center",
                    // width : "calc((100vw - 40px)/3)"
                  }}
                >
                  <UiQna_grid
                    // scrollKey={scrollKey}
                    // statInfo={statInfo}
                    // isDot={stat["type"] != 5 ? false : true}
                    setSelectData={setSelectData}
                    selectData={selectData}
                    isEdit={isEdit}
                    index={i}
                    data={v}
                    fnCheck={(d) => {
                      setSelectData((prev) => {
                        const prevObject = { ...prev };
                        console.log(prevObject);
                        if (Object.keys(prevObject).includes(String(d.id))) {
                          delete prevObject[d.id];
                        } else {
                          prevObject[d.id] = d.id;
                        }
                        return prevObject;
                      });
                    }}
                    apiPro={() => apiPro_read_del()}
                  />
                </div>
              ))}
            </div>
          ) : (
            qnaData.map((v, i) =>
              listStat == "listview" ? (
                <UiQna
                  statInfo={{ type: "remove" }}
                  setSelectData={setSelectData}
                  selectData={selectData}
                  isEdit={isEdit}
                  // setIsModal={setIsModal_book}
                  // setIsModal_paste={setIsModal_book_paste}
                  // scrollKey={scrollKey}
                  // isDot={stat["type"] != 5 ? false : true}
                  index={i}
                  data={v}
                  fnCheck={(d) => {
                    setSelectData((prev) => {
                      const prevObject = { ...prev };
                      if (Object.keys(prevObject).includes(String(d.id))) {
                        delete prevObject[d.id];
                      } else {
                        prevObject[d.id] = d.id;
                      }
                      return prevObject;
                    });
                  }}
                  apiPro={() => apiPro_read_del()}
                  // apiPro={()=>{apiPro_read(ex_sort, true, "", true)}}
                />
              ) : (
                <UiQna_image
                  // scrollKey={scrollKey}
                  // statInfo={statInfo}
                  // isDot={stat["type"] != 5 ? false : true}
                  setSelectData={setSelectData}
                  selectData={selectData}
                  isEdit={isEdit}
                  index={i}
                  data={v}
                  fnCheck={(d) => {
                    setSelectData((prev) => {
                      const prevObject = { ...prev };
                      if (Object.keys(prevObject).includes(String(d.id))) {
                        delete prevObject[d.id];
                      } else {
                        prevObject[d.id] = d.id;
                      }
                      return prevObject;
                    });
                  }}
                  apiPro={() => apiPro_read_del()}
                />
              )
            )
          )}
        </div>
      </div>
    </Frame>
  );
};

export default Book_remove;
