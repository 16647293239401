import { CircularProgress } from "@material-ui/core";
import { useQnaList } from "api/messageMark";
import React, { useMemo } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useParams } from "react-router";
import styled from "styled-components";
import EmptyArea from "./EmptyArea";
import QnaItem from "./QnaItem";

const QnaList = () => {
  const params = useParams();
  const { data, fetchNextPage } = useQnaList(params.id);

  const {
    list: qnaList,
    currentPage,
    totalPage,
  } = useMemo(() => {
    const lastPage = data?.pages && [...data?.pages].pop();
    return {
      list: data?.pages?.flatMap((page) => page.getQnaListInFolder.qna) ?? [],
      currentPage: lastPage.getQnaListInFolder.currentPage,
      totalPage: lastPage.getQnaListInFolder.totalPage,
    };
  }, [data]);

  const isEmpty = useMemo(() => {
    return qnaList.length === 0;
  }, [qnaList]);

  return isEmpty ? (
    <EmptyArea
      message={`현재 노트가 비워져있어요!
노트에 문제들을 채워주세요.`}
    />
  ) : (
    <InfiniteScroll
      dataLength={qnaList?.length ?? 0}
      next={fetchNextPage}
      loader={
        currentPage > 0 &&
        currentPage < totalPage && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              width: "100%",
              paddingBottom: 40,
              height: 24,
            }}
          >
            <CircularProgress
              style={{
                fontSize: "12px",
              }}
            />
          </div>
        )
      }
      scrollableTarget="scrollableDiv"
      hasMore={currentPage < totalPage}
    >
      <ImageContainer>
        {qnaList.map((item) => (
          <QnaItem
            {...item?.qid_HIQDB_online_qna}
            videoContent={item.qid_HIQDB_online_qna?.HIQDB_online_qna_msgs?.[0]?.content}
          />
        ))}
      </ImageContainer>
    </InfiniteScroll>
  );
};

export const ImageContainer = styled.div`
  display: flex;
  gap: 40px;
  margin-bottom: 20px;
  flex-direction: column;
  padding: 0 20px;
  height: 100%;
  @media screen and (max-width: 568px) {
    gap: 24px;
  }
`;

export const QnaImage = styled.img`
  cursor: pointer;
  width: 100%;
  height: 320px;
  object-fit: cover;
  border-radius: 10px;
  box-shadow: 4px 4px 6px 2px #0000001a;
  @media screen and (max-width: 568px) {
    height: 220px;
  }
`;
export default QnaList;
