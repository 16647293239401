import React from "react";
import styled from "styled-components";
import StudyBookContextProvider from "../context/StudyBookContext";
import StudyBookEdit from "./StudyBookEdit";
import StudyBookList from "./StudyBookList";

const StudyBookSection = () => {
  return (
    <StudyBookContextProvider>
      <Container>
        <StudyBookEdit />
        <StudyBookList />
      </Container>
    </StudyBookContextProvider>
  );
};

const Container = styled.div`
  min-height: 300px;
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

export default StudyBookSection;
