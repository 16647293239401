import { ClassRegistRequset } from "api/classRegist";
import CommonModal, { CommonModalContainer } from "components/atoms/CommonModal";
import ReactiveButtonContainer from "components/atoms/ReactiveButtonContainer";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useFormContext } from "react-hook-form";
import styled from "styled-components";
import { StyledVideo } from "./ClassVideoArea";
interface ThumbnailSelectModalProps extends CommonModalControl {
  videoUrl: string;
  onSubmit?: () => void;
}
const ThumbnailSelectModal = ({ onClose, open, videoUrl, onSubmit }: ThumbnailSelectModalProps) => {
  const { setValue, unregister } = useFormContext<ClassRegistRequset>();
  const videoRef = useRef<HTMLVideoElement>(null);
  const [playerTime, setPlayerTime] = useState<number>(0);

  const handleVideoStateChange = useCallback(
    (event) => {
      setPlayerTime(event.target.value);
    },
    [setPlayerTime]
  );

  const exportThumbnail = useCallback(() => {
    setTimeout(() => {
      if (videoRef?.current) {
        const endTime = videoRef.current.duration;
        const calcCurrentTime = playerTime ? (playerTime / 10000) * endTime : 0;
        videoRef.current.currentTime = calcCurrentTime;
      }
    }, 200);
  }, [playerTime, videoRef.current]);

  useEffect(() => {
    open && exportThumbnail();
  }, [exportThumbnail, open, playerTime, videoRef?.current]);

  const gradient = `linear-gradient(to right, rgba(200,200,200,0.8) 0%, rgba(200,200,200,0.8) ${playerTime / 100}%, rgba(1,1,1,0.5) ${playerTime / 100}%, rgba(1,1,1,0.5)  100%)`;

  const handleSelectThumbnail = useCallback(() => {
    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");
    const width = videoRef.current?.videoWidth;
    const height = videoRef.current?.videoHeight;
    canvas.width = width;
    canvas.height = height;
    context.drawImage(videoRef.current, 0, 0, width, height);
    const dataURL = canvas.toDataURL();
    setValue("thumbnail", dataURL, { shouldValidate: true });
    onSubmit();
  }, [, setValue]);

  useEffect(
    () => () => {
      unregister("thumbnail");
    },
    []
  );

  return (
    <CommonModal open={open} onClose={onClose} keepMounted>
      <VideoContainer>
        <TitleFont>썸네일 선택</TitleFont>
        <StyledVideo
          crossOrigin="anonymous"
          // onProgress={handleVideoStateChange}
          ref={videoRef}
          src={`${videoUrl}#t=0.001`}
          // src={videoUrl}
          muted
          controls={false}
        />
        <ProgressContianer>
          <ProgressInput
            max={10000}
            type="range"
            value={playerTime}
            style={{ background: gradient }}
            onChange={handleVideoStateChange}
          />
        </ProgressContianer>
        <ReactiveButtonContainer
          buttonList={[
            {
              onClick: onClose,
              variant: "text",
              title: "취소",
            },
            {
              onClick: handleSelectThumbnail,
              variant: "contained",
              title: "확인",
            },
          ]}
        />
      </VideoContainer>
    </CommonModal>
  );
};

const TitleFont = styled.div`
  color: #242424;
  font-family: Pretendard;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%; /* 16px */
  margin: 20px 0;
`;

const VideoContainer = styled(CommonModalContainer)`
  max-width: 700px;
  display: block;
  margin: 20px;
  align-items: center;
  justify-content: center;
  max-height: 90vh;
  overflow-y: auto;
  video {
    max-height: 70vh;
  }
`;

const ProgressContianer = styled.div`
  padding: 10px;
`;

const ProgressInput = styled.input`
  cursor: pointer;
  height: 8px;
  width: 100%;
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0.6) 0%,
    rgba(255, 255, 255, 0.6) 0%,
    rgba(0, 0, 0, 0.4) 0%,
    rgba(0, 0, 0, 0.4) 100%
  );
  border-radius: 8px;
  outline: none;
  transition: background 450ms ease-in;
  -webkit-appearance: none;
  accent-color: rgba(255, 255, 255, 0.6);
  accent-color: #ffa723;
`;

const ButtonContainer = styled.div`
  display: flex;
  gap: 10px;
  margin: 20px 0;
  width: 100%;
  justify-content: flex-end;
  & button {
    padding: 10px 20px;
    border-radius: 8px;
    outline: none;
    border: none;
    cursor: pointer;
  }

  & button:last-child {
    background-color: #ffa723;
  }
`;
export default ThumbnailSelectModal;
