import { React, useState, useEffect, useCallback } from "react";
import { useQuery } from "@apollo/client";
import { useNavigate, useParams } from "react-router";
import { Frame } from "../page_index";
import UiSorting from "uiComponents/UiSorting/UiSorting";
import UiOrderLog from "uiComponents/UiOrderLog/UiOrderLog";
import UiLoading from "uiComponents/UiLoading/UiLoading";
import { UserOrdersDocument } from "types/graphql/graphql";

const MyInfo_orderlog = ({ userInfo, isCancel }) => {
  let params = useParams();
  let navigate = useNavigate();
  const userId = params["id"];
  const { loading, error, data, refetch } = useQuery(UserOrdersDocument, {
    fetchPolicy: "network-only",
    variables: {
      userId,
    },
  });

  useEffect(() => {
    if (error) {
      alert("주문 내역을 가져오는데 실패했습니다.");
      navigate(-1);
    }
  }, [error, navigate]);

  if (loading) {
    return <UiLoading text="로딩 중입니다." isText={false} />;
  }
  const orders = data?.userOrders?.orders || [];

  return (
    <Frame
      title={isCancel ? "취소 내역" : "주문 내역"}
      userInfo={userInfo}
      // isPadding={false}
      isSearch={false}
      // isTap={true}
    >
      <div
        style={{
          width: "100%",
        }}
      >
        <div
          style={{
            padding: "5px 12px",
          }}
        >
          <UiSorting
            title={`${isCancel ? "취소" : "주문"} 내역 ${orders.length}개`}
          />
        </div>
        <div
          style={{
            padding: "10px 12px",
          }}
        >
          {orders.map((order) => (
            <UiOrderLog
              key={order.id}
              data={order}
              fnClick={(log) => navigate(`/myinfo/order/${log.id}`)}
            />
          ))}
        </div>
      </div>
    </Frame>
  );
};

export default MyInfo_orderlog;
