import { useMemo } from "react";

type DeviceResult = {
  isPC: boolean;
  isMobileWeb: boolean;
  isApp: boolean;
};

export default function useDevice(): DeviceResult {
  const userAgent = useMemo<string>(() => {
    return navigator.userAgent.toLowerCase();
  }, []);

  const isPC = useMemo<boolean>(() => {
    return /windows|macintosh|linux/.test(userAgent);
  }, [userAgent]);

  const isMobileWeb = useMemo<boolean>(() => {
    return /android|iphone|ipad/.test(userAgent) && !/hiqsumapp/.test(userAgent) && !window.isInApp;
  }, [userAgent]);

  const isApp = useMemo<boolean>(() => {
    return /hiqsumapp/.test(userAgent) || window.isInApp;
  }, [userAgent]);

  return { isPC, isMobileWeb, isApp };
}
