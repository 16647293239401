import EmptyBox from "asset/Img/empty_box.svg";
import React from "react";
import styled from "styled-components";
const EmptyArea = ({ message }: { message: string }) => {
  return (
    <Container>
      <img src={EmptyBox} alt="empty" />
      <EmptyFont>{message}</EmptyFont>
    </Container>
  );
};
const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

const EmptyFont = styled.div`
  white-space: pre;
  font-family: Pretendard;
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0.02em;
  text-align: center;
`;
export default EmptyArea;
