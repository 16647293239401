import { React, useEffect, useMemo, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router";
import style from "../../asset/style";
import noBook from "../../asset/Img/book1.png";
import constants from "../../asset/constants";
import util from "../../asset/util";
import "./UiQna.css";
import API from "../../api";
import { useToggleExemplary } from "../../api/question";

const UiQna = ({
  statInfo,
  setSelectData,
  setIsModal,
  setIsModal_paste,
  index,
  scrollKey,
  isTeacher,
  isQna = false,
  data = {
    tid: "",
    sid: "",
  },
  fnClick = () => {},
  isEdit,
  selectData,
  fnLongClick,
  isMultiSelect,
  fnCheck = () => {},
  id,
  isMsg = false,
  setScrolllKey,
  isMsgSection = true,
  isDot = false,
  apiPro,
  apiPro_del,
  isSearch = false,
  // isManager,
  // isTeacherPage,
  // manageSub,
}) => {
  // 관리자
  let sort = [
    { name: "모범풀이 해제", value: "a" },
    { name: "교재 이동", value: "b" },
    { name: "교재 복사", value: "c" },
    { name: "질문 삭제", value: "d" },
  ];

  let sort_2 = [
    { name: "모범풀이 등록", value: "e" },
    { name: "교재 이동", value: "b" },
    { name: "질문 삭제", value: "d" },
  ];

  // 참여자
  let sort_3 = [
    { name: "모범풀이 해제", value: "a" },
    { name: "교재 복사", value: "c" },
    { name: "질문 삭제", value: "d" },
  ];

  let sort_4 = [
    { name: "모범풀이 등록", value: "e" },
    { name: "질문 삭제", value: "d" },
  ];

  let navigate = useNavigate();
  let params = useParams();

  var [isPop, setIsPop] = useState(false);

  var scrollRef = useRef(null);

  useEffect(() => {
    // console.log("data : ",data)
    if (scrollRef.current) {
      scrollRef.current.scrollIntoView({
        // behavior: "smooth",
        block: "center",
      });
      if (setScrolllKey) {
        setScrolllKey("");
      }
    }
  }, [scrollRef]);

  const selectQnaList = useMemo(() => {
    if (typeof selectData === "object" && !Array.isArray(selectData)) {
      return Object.keys(selectData).map((id) => parseInt(id));
    } else if (Array.isArray(selectData)) {
      return selectData;
    } else return [];
  }, [selectData]);

  return (
    <div
      style={{
        display: "flex",
        // flex:1,
        // width:"100%",
        backgroundColor: index % 2 == 0 ? "#FFFBF5" : "#FBFBFB",
        padding: "15px 10px",
        marginBottom: "2px",
        borderRadius: "15px",
        border: `${selectQnaList?.includes(data.id) ? "1px solid red" : ""}`,
        // height:"120px",
      }}
      ref={scrollKey == data["id"] ? scrollRef : null}
      onClick={() => {
        if (isEdit) {
          fnCheck(data);
        } else {
          fnClick(data);
        }
      }}
    >
      <div
        style={{
          display: "flex",
          flexGrow: 1,
          minWidth: "110px",
          maxWidth: "110px",
          height: "96px",
          borderRadius: "5px",
          marginRight: "7px",
        }}
      >
        {Object.keys(data).length > 0 && (
          <img
            className={data["mosaic"] == "y" ? "mosaic" : null}
            style={{
              width: "99.5%",
              height: "100%",
              objectFit: "cover",
              objectPosition: "top",
              border: "1px solid",
              borderColor: style.common.borderColor4,
              borderRadius: "5px",
            }}
            onError={(e) => {
              if (data["original"]) {
                try {
                  // e.currentTarget.src = `${constants.apiUrl}/public/qna/${data["original"]}.jpg`
                } catch (e) {}
                // e.currentTarget.src = `${constants.apiUrl}/public/qna/${data["original"]}.jpg?${util.getRandomInt(1000,9999)}`
              } else {
                e.currentTarget.src = noBook;
              }
            }}
            src={
              data["original"]
                ? data["original_img"]
                  ? `${constants.s3_url}/qna/${data["original_img"]}.jpg`
                  : `${constants.s3_url}/qna/${data["original"]}.jpg`
                : data["img"]
                  ? `${constants.s3_url}/qna/${data["img"]}.jpg`
                  : `${constants.apiUrl}/qna/${data["id"]}.jpg`
            }
          />
        )}
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          flex: 1,
          flexGrow: 10,
          height: "96px",
          justifyContent: "space-around",
          // alignItems:"space-between",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div
            style={{
              // ...UiExplain_Style.textline_1,
              // backgroundColor:"orange",
              // display:"inline-flex",
              display: "inline-flex",
              alignItems: "center",
              fontSize: "14px",
              fontWeight: 500,
              marginRight: "3px",
              color: data["gicul_rate"]
                ? style.common.s_color
                : data["ans_date"]
                  ? "#FCB65D"
                  : "#EB7071",
              // isQna ?
              // style.common.t_color
              // :
              // style.common.fontColor_3
            }}
          >
            <div
              style={{
                ...style.common.line_text,
                display: "flex",
                // backgroundColor:"green"
              }}
            >
              <div
                style={{
                  minWidth:
                    Object.keys(data).length > 0
                      ? data["gicul_rate"]
                        ? "70px"
                        : data["qid"] == 0
                          ? "65px"
                          : data["ans_date"]
                            ? !data["original"] || data["original"] == 0
                              ? "65px"
                              : "85px"
                            : data["original"] == 0
                              ? data["member_cnt"]
                                ? data["member_cnt"] <= 2
                                  ? "40px"
                                  : "65px"
                                : "40px"
                              : data["member_cnt"]
                                ? data["member_cnt"] <= 2
                                  ? "65px"
                                  : "65px"
                                : "65px"
                      : "auto",
                }}
              >
                {Object.keys(data).length > 0 &&
                  (data["gicul_rate"]
                    ? "[기출]"
                    : data["qid"] == 0
                      ? "[일반]"
                      : data["ans_date"]
                        ? !data["original"] || data["original"] == 0
                          ? "[답변완료]"
                          : "[추가질문완료]"
                        : data["original"] == 0
                          ? data["member_cnt"]
                            ? data["member_cnt"] <= 2
                              ? "[질문]"
                              : "[단체질문]"
                            : "[질문]"
                          : data["member_cnt"]
                            ? data["member_cnt"] <= 2
                              ? "[추가질문]"
                              : "[단체질문]"
                            : "[추가질문]")}
              </div>
              <div
                style={{
                  ...style.common.line_text,
                  color: "#3D3A39",
                  marginLeft: "1px",
                }}
              >
                {data["title"]}
              </div>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              width: "35px",
              fontSize: "14px",
              fontWeight: 400,
              color: "#A5A5A5",
              position: "relative",
            }}
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            {!isMultiSelect && (
              <div
                style={{
                  width: "33px",
                }}
                onClick={(e) => {
                  setIsPop(!isPop);
                  e.stopPropagation();
                }}
              >
                {!isPop ? "수정" : "닫기"}
              </div>
            )}
            {isPop ? (
              <div
                style={{
                  position: "absolute",
                  // right:0,
                  top: 25,
                  borderRadius: "20px",
                  border: "1px solid #F6F6F6",
                  boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                  padding: "3px 0px",
                  backgroundColor: "white",
                  zIndex: 130009,
                }}
              >
                {
                  statInfo?.type == "5"
                    ? data["mobum_date"] && data["ans_date"]
                      ? sort.map((v, i) => (
                          <QTYPE
                            data={{ ...v, ...data }}
                            type={"manage_mobum"}
                            setSelectData={setSelectData}
                            setIsModal_paste={setIsModal_paste}
                            setIsPop={setIsPop}
                            apiPro={apiPro}
                          />
                        ))
                      : sort_2.map((v, i) => (
                          <QTYPE
                            data={{ ...v, ...data }}
                            type={"manage"}
                            setSelectData={setSelectData}
                            setIsModal_paste={setIsModal_paste}
                            setIsPop={setIsPop}
                            apiPro={apiPro}
                          />
                        ))
                    : data["mobum_date"] && data["ans_date"]
                      ? sort_3.map((v, i) => (
                          <QTYPE
                            data={{ ...v, ...data }}
                            type={"general_mobum"}
                            setSelectData={setSelectData}
                            setIsModal_paste={setIsModal_paste}
                            setIsPop={setIsPop}
                            apiPro={apiPro}
                          />
                        ))
                      : sort_4.map((v, i) => (
                          <QTYPE
                            data={{ ...v, ...data }}
                            type={"general"}
                            setSelectData={setSelectData}
                            setIsModal_paste={setIsModal_paste}
                            setIsPop={setIsPop}
                            apiPro={apiPro}
                          />
                        ))
                  // <div style={{
                  //     padding : "15px 0px",
                  //     width:"120px",
                  //     backgroundColor:"white",
                  //     display:"flex",
                  //     alignItems:"center",
                  //     justifyContent:"center",
                  //     fontWeight:400,
                  //     fontSize:"14px",
                  //     color : "#3D3A39",
                  //     borderRadius:"20px",
                  // }}
                  //     onClick={(e)=>{

                  //     }}
                  // >
                  //     {v["name"]}
                  // </div>
                  // )
                }
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
        {data["bookName"] && (
          <div
            style={{
              fontWeight: 400,
              fontSize: "12px",
            }}
          >
            {data["bookName"]}
          </div>
        )}
        {(data["ans_date"] || data["reg_date"]) && (
          <div
            style={{
              fontWeight: 400,
              fontSize: "12px",
            }}
          >
            {data["ans_date"]
              ? util.convertToDateTime(data["ans_date"])
              : data["reg_date"]
                ? util.convertToDateTime(data["reg_date"])
                : ""}
          </div>
        )}
      </div>
    </div>
  );
};

const DotPop = ({ text, fnClick }) => {
  let params = useParams();

  return (
    <div
      style={{
        padding: "2px 0px 1px 0px",
        fontSize: "16px",
        color: params["who"] == "teacher" ? style.common.t_color : style.common.s_color,
        fontWeight: 500,
      }}
      onClick={(e) => {
        fnClick(e);
        e.stopPropagation();
        e.preventDefault();
      }}
    >
      {text}
    </div>
  );
};

const QTYPE = ({ data, type = "manager", setSelectData, setIsModal_paste, setIsPop, apiPro }) => {
  let navigate = useNavigate();
  const params = useParams();
  const apiPro_del_indi = async (data) => {
    try {
      const res = await API.deleteQna(data.id);
      await apiPro();
    } catch (e) {
      console.error(e);
    }
  };

  const apiPro_mobum = async (isModi) => {
    try {
      await useToggleExemplary(data?.id, isModi ? "del" : 0);
      // navigate(-1);
      if (isModi) {
        alert("선택된 질문이 모범풀이 해제되었어요.");
      } else {
        alert("선택된 질문이 모범풀이에 등록되었어요.");
      }
      util.postWeb("isModal_off");
      setIsPop(false);
      await apiPro();
    } catch (e) {
      console.log(e);
    }
  };
  const manage_mobum = (e) => {
    try {
      if (data["value"] === "a") {
        let isOk = window.confirm("모범 풀이 해제를 하시겠어요?");
        if (isOk) {
          apiPro_mobum(true);
        }
      }
      // 교재 이동
      else if (data["value"] === "b") {
        // console.log(data["id"])
        setSelectData({ ...data });
        // setIsModal(true)
        // util.postWeb("isModal_off")
        navigate(`/explainmodel/${params["who"]}/${data["id"]}/move/n`);
        setIsPop(false);
      }
      // 교재 복사
      else if (data["value"] === "c") {
        // console.log(data["id"])
        setSelectData({ ...data });
        setIsModal_paste(true);
        // util.postWeb("isModal_off")
        navigate(`/explainmodel/${params["who"]}/${data["id"]}/copy/n`);
        setIsPop(false);
      }
      // 질문 삭제
      else if (data["value"] === "d") {
        let isOk = window.confirm("질문을 삭제하시겠어요?");
        if (isOk) {
          apiPro_del_indi(data);
          setIsPop(false);
        }
        e.preventDefault();
        e.stopPropagation();
      }
    } catch (e) {
      console.log("************");
      console.log(e);
      console.log("************");
    } finally {
      setIsPop(false);
    }
  };

  const manage = () => {
    try {
      // 모범풀이 등록
      if (data["value"] === "e") {
        let isOk = window.confirm("모범 풀이 등록을 하시겠어요?");
        if (isOk) {
          apiPro_mobum(false);
        }
      }
      // 교재 이동
      else if (data["value"] === "b") {
        console.log(data["id"]);
        setSelectData({ ...data });
        // util.postWeb("isModal_off")
        navigate(`/explainmodel/${params["who"]}/${data["id"]}/move/n`);
        // setIsPop(false)
      }

      // 질문 삭제
      else if (data["value"] === "d") {
        let isOk = window.confirm("질문을 삭제하시겠어요?");
        if (isOk) {
          // apiPro_del(data)
          apiPro_del_indi(data);
          setIsPop(false);
        }
        e.preventDefault();
        e.stopPropagation();
      }
    } catch (e) {
      console.log("************");
      console.log(e);
      console.log("************");
    } finally {
      setIsPop(false);
    }
  };
  const general_mobum = (e) => {
    try {
      if (data["value"] === "a") {
        let isOk = window.confirm("모범 풀이 해제를 하시겠어요?");
        if (isOk) {
          apiPro_mobum(true);
        }
      }
      // 교재 이동
      else if (data["value"] === "b") {
        // console.log(data["id"])
        setSelectData({ ...data });
        setIsModal(true);
        // util.postWeb("isModal_off")
        // navigate(`/explainmodel/${params["who"]}/${data["id"]}/move/n`)
        // setIsPop(false)
      }
      // 교재 복사
      else if (data["value"] === "c") {
        // console.log(data["id"])
        setSelectData({ ...data });
        setIsModal_paste(true);
        // util.postWeb("isModal_off")
        // navigate(`/explainmodel/${params["who"]}/${data["id"]}/copy/n`)
        // setIsPop(false)
      }
      // 질문 삭제
      else if (data["value"] === "d") {
        let isOk = window.confirm("질문을 삭제하시겠어요?");
        if (isOk) {
          // apiPro_del(data)
          apiPro_del_indi(data);
          setIsPop(false);
        }

        e.preventDefault();
        e.stopPropagation();
      }
    } catch (e) {
      console.log("************");
      console.log(e);
      console.log("************");
    } finally {
      setIsPop(false);
    }
  };

  const general = () => {
    try {
      // 모범풀이 등록
      if (data["value"] === "e") {
        let isOk = window.confirm("모범 풀이 등록을 하시겠어요?");
        if (isOk) {
          apiPro_mobum(false);
        }
      }
      // 교재 이동
      else if (data["value"] === "b") {
        console.log(data["id"]);
        setSelectData({ ...data });
        setIsModal(true);
        // util.postWeb("isModal_off")
        // navigate(`/explainmodel/${params["who"]}/${data["id"]}/move/n`)
        // setIsPop(false)
      }

      // 질문 삭제
      else if (data["value"] === "d") {
        let isOk = window.confirm("질문을 삭제하시겠어요?");
        if (isOk) {
          // apiPro_del(data)
          apiPro_del_indi(data);
          setIsPop(false);
        }
        e.preventDefault();
        e.stopPropagation();
      }
    } catch (e) {
      console.log("************");
      console.log(e);
      console.log("************");
    } finally {
      setIsPop(false);
    }
  };

  return (
    <div
      style={{
        padding: "15px 0px",
        width: "120px",
        backgroundColor: "white",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        fontWeight: 400,
        fontSize: "14px",
        color: "#3D3A39",
        borderRadius: "20px",
        // zIndex:130009
      }}
      onClick={(e) => {
        // 모범풀이 해제
        console.log(data);
        if (type == "manage_mobum") {
          manage_mobum();
        } else if (type == "manage") {
          manage();
        } else if (type == "general_mobum") {
          general_mobum(e);
        } else if (type == "general") {
          general();
        }
      }}
    >
      {data["name"]}
    </div>
  );
};

export default UiQna;
