import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import styled from "styled-components";

export const Accordion = styled(MuiAccordion)`
  all: unset !important;
`;

export const AccordionSummary = styled(MuiAccordionSummary)`
  all: unset !important;
  > .MuiAccordionSummary-content {
    margin: 0;
    &.Mui-expanded {
      margin-top: 0;
    }
  }
`;

export const AccordionDetails = styled(MuiAccordionDetails)`
  all: unset !important;
`;
