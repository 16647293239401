import { StudyBookFolderListItem, useStudyBookFolderDelete } from "api/studyBook";
import { ReactComponent as IconEdit } from "asset/Img/icon_edit.svg";
import { ReactComponent as IconTrash } from "asset/Img/icon_trash.svg";
import constants from "asset/constants";
import ItemSubMenu from "components/molecules/ItemSubMenu";
import { useConfirm } from "providers/ConfirmProvider";
import React, { useCallback, useState } from "react";
import FolderUpdateModal from "./FolderUpdateModal";
type FolderSubMenuProps = {
  anchorElement?: HTMLElement | null;
  folder: StudyBookFolderListItem;
} & CommonModalControl;
const FolderSubMenu = ({ anchorElement, folder, open, onClose }: FolderSubMenuProps) => {
  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);
  const handleUpdateModalClose = useCallback(() => {
    setIsUpdateModalOpen(false);
  }, [setIsUpdateModalOpen]);

  const { showConfirm } = useConfirm();
  const handleDelete = useCallback(() => {
    showConfirm({
      message: constants.confirm.cf_3,
      title: "폴더 삭제",
      onConfirm() {
        mutateAsync([`${folder.id}`]);
      },
    });
  }, []);

  const { mutateAsync } = useStudyBookFolderDelete();
  return (
    <>
      <ItemSubMenu
        open={open}
        onClose={onClose}
        anchorElement={anchorElement}
        anchorOrigin={{ horizontal: "center", vertical: "top" }}
        style={{ transform: "translate(-7px, 36px)" }}
        menuList={[
          {
            icon: <IconEdit />,
            text: "폴더 이름변경",
            onClick: () => {
              setIsUpdateModalOpen(true);
              onClose();
            },
          },
          {
            icon: <IconTrash />,
            text: "폴더 삭제",
            onClick: handleDelete,
          },
        ]}
      />
      <FolderUpdateModal
        onClose={handleUpdateModalClose}
        open={isUpdateModalOpen}
        folder={folder}
      />
    </>
  );
};

export default FolderSubMenu;
