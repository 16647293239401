import { React, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";

import util from "./asset/util";

import store from "./asset/store";
import Frame_Style from "./common/frame/Frame_Style";
import Intro from "./pages/before/login/Intro";

const Before = ({ setUserInfo, setRegiData }) => {
  let navigate = useNavigate();
  let params = useParams();

  var [isIntro, setIsIntro] = useState(false);

  const apiPro = async ({ obj }) => {
    try {
      let data = await store.axiosPost({
        cat: "hiq",
        cmd: "newSignIn",
        id: obj["id"],
        pw: obj["pw"],
      });

      // // console.log(data)
      if (data) {
        setUserInfo({
          ...data,
        });
        if (data["type"] == 1) {
          navigate("/message/" + "student" + "/" + "home");
        } else {
          navigate("/message/" + "teacher" + "/" + "home");
        }
      }
    } catch (e) {
      alert("로그인 실패했습니다.");
      util.removeLocalStorage();
      navigate("/", { replace: true });
      console.error(e);
      // console.log("ApiPro Error => ",e)
    }
  };

  useEffect(() => {
    util.c_log("Before.jsx");

    if (process.env.NODE_ENV === "production" && process.env.REACT_APP_BUILD_MODE === "live") {
      // 본서버 적용 //
      if (JSON.parse(window.localStorage.getItem("user"))) {
        let obj = {
          id: JSON.parse(window.localStorage.getItem("user"))["id"],
          pw: window.localStorage.getItem("pw"),
        };
        apiPro({ obj });
      } else {
        setIsIntro(true);
      }
      // / 본서버 적용 //
    } else if (process.env.NODE_ENV === "production") {
      /// test 적용 ///
      setIsIntro(true);
      /// test 적용 ///
    } else {
      setIsIntro(true);
    }
  }, []);

  return (
    <div style={Frame_Style.c}>
      {isIntro && (
        <Intro setRegiData={setRegiData} setUserInfo={setUserInfo} setIsIntro={setIsIntro} />
      )}
    </div>
  );
};

export default Before;
