import style from "../../asset/style"

const UiLectureTime_Style = {
    c : {
        display : "flex",
        alignItems:"center",
        padding : "0px 20px",
        margin : "10px 0px",
    },
    round_w : {
        display:"flex",
        width : "36px",
        height : "36px",
        justifyContent:"center",
        alignItems:"center",
        marginRight : "20px",
        backgroundColor : style.common.t_color,
        borderRadius:20,
        fontSize : "14px",
        fontWeight : "bold",
        color : "white"
    },
    time_w : {
        display:"flex",
        alignItems : "center",
        justifyContent:"space-between",
        width:"80%",
    },
}

export default UiLectureTime_Style