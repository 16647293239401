import Container from "components/atoms/Container";
import React, { useMemo } from "react";
import { useParams } from "react-router";
import MessageMarkListHeader from "./components/MessageMarkListHeader";
import QnaList from "./components/QnaList";
import RecentQnaList from "./components/RecentQnaList";

const MessageMarkList = () => {
  const params = useParams();
  const isRecent = useMemo(() => {
    return params.id === "recent";
  }, [params.id]);

  return (
    <Container>
      <MessageMarkListHeader />
      {isRecent ? <RecentQnaList /> : <QnaList />}
    </Container>
  );
};

export default MessageMarkList;
