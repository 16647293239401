import { fontWeight } from '@mui/system'
import { React , useState , useEffect, useRef } from 'react'
import { useNavigate, useParams } from 'react-router'
import { UiInput } from '../../uiComponents/UiInput/UiInput'
import aquery from '../../asset/Img/aquery.png'
import photopic_book from '../../asset/Img/photopic_book.png'
import { Switch } from '@mui/material'
import util from '../../asset/util'
import constants from '../../asset/constants'
import apiFn from '../../asset/apiClass'
import store from '../../asset/store'
import style from '../../asset/style'

var fontStyle = {
    fontFamily: 'Noto Sans',
    fontStyle: "normal",
}

const Modal_modi = ({
    lectureData,
    setIsModal,
    isModal,
    userInfo,
    title,
    placeholder
}) => {
    let params = useParams();
    let navigate = useNavigate();

    var [lectureTitle, setLectureTitle] = useState("");
    var [lectureAddress, setLectureAddress] = useState("");
    var [lectureIntro, setLectureIntro] = useState("");

    const apiPro_regi = async () => {
        try {
            let isOk = await apiFn.comApi({
                state : {
                    table : "class",
                    set : [{
                        key : `id:${params["id"]}`,
                            value : {
                                name : lectureTitle,
                                address : lectureAddress,
                                intro : lectureIntro,
                            }
                    }]
                }

            })

            window.alert("수업 정보가 수정 되었어요.")

            setIsModal(false)
        }
        catch(e){
            console.log("@@@? " ,e)
        }
    }

    useEffect(()=>{
        // setFileObj({})
        // // console.log("@@ ",fileObj);

        // if(lectureData["apporve"] == "N"){
        //     setIsApprove(false)
        // }
        console.log("LD : ",lectureData)
        setLectureTitle(lectureData["name"])
        setLectureAddress(lectureData["address"])
        setLectureIntro(lectureData["intro"])
    },[])


    return (
        <div style={{
            position:"fixed",
            top:0,
            zIndex:130002,
            backgroundColor:"rgba(150, 150, 150,0.5)",
            // backgroundColor:"red",
            width:"100%",
            height:"100vh",
            display:"flex",
            justifyContent:"center",
            alignItems:"center"
        }}
            onClick={()=>{
                // setIsModal(false)
            }}
        >
            <div style={{
                // width : "75%",
                width:"300px",
                // minHeight:"55%",
                // maxHeight:"60%",
                backgroundColor:"white",
                padding : "35px 20px 40px 20px",
                borderRadius : 10,
                border : "1px solid rgb(200, 200, 200)",
                display:"flex",
                flexDirection:"column",
                justifyContent:"space-between",
                filter: "drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.37))",
            }}
                onClick={(e)=>{
                    // e.stopPropagation();
                    // e.preventDefault();
                }}
            >
                <div style={{
                    fontWeight:500,
                    display:"flex",
                    justifyContent:"space-between",
                    fontSize:"18px",
                    fontWeight:700
                }}>
                    {title}
                    {/* <div style={{
                        fontWeight:400,
                        fontSize:"12px",
                        color : style.common.fontColor_3,
                        display:"flex",
                    }}>
                        <div style={{
                            marginRight:"10px"
                        }}>
                            수업 탈퇴
                        </div>
                        <div>
                            수업 삭제
                        </div>
                    </div> */}
                </div>
                <div style={{
                    display:"flex",
                    flexDirection:"column",
                    fontSize:"14px",
                    fontWeight:700,
                    justifyContent:"center",
                    padding:"10px 0px 25px 0px"
                }}>
                    <div style={{
                        display:"flex",
                        flexDirection:"column",
                        width:"100%",
                        marginBottom : "10px"
                    }}>
                        <div style={{
                            marginBottom:"3px",
                        }}>
                            수업명
                        </div>
                        <div style={{
                            display:"flex",
                            width:"100%",
                            // height:"32px",
                            flex:1,
                        }}>
                            <UiInput 
                                inputClass={"input_t"}
                                basic_container_style={{
                                    height:"28px",
                                }}
                                inputValue={lectureTitle}
                                placeholder={placeholder}
                                fnChange={(e)=>{
                                    setLectureTitle(e.target.value)
                                }}
                            />
                        </div>
                    </div>
                    <div style={{
                        display:"flex",
                        flexDirection:"column",
                        width:"100%",
                        marginBottom : "10px"
                    }}>
                        <div style={{
                            marginBottom:"3px",
                        }}>
                            수업 장소
                        </div>
                        <div style={{
                            display:"flex",
                            width:"100%",
                            // height:"32px",
                            flex:1,
                        }}>
                            <UiInput 
                                inputClass={"input_t"}
                                basic_container_style={{
                                    height:"28px",
                                }}
                                inputValue={lectureAddress}
                                placeholder={placeholder}
                                fnChange={(e)=>{
                                    setLectureAddress(e.target.value)
                                }}
                            />
                        </div>
                    </div>
                    <div style={{
                        display:"flex",
                        flexDirection:"column",
                        width:"100%",
                        marginBottom : "10px"
                    }}>
                        <div style={{
                            marginBottom:"3px",
                        }}>
                            수업 소개 글
                        </div>
                        <div style={{
                            display:"flex",
                            width:"100%",
                            // height:"32px",
                            flex:1,
                        }}>
                            <UiInput 
                                inputClass={"input_t"}
                                inputType="desc"
                                // basic_container_style={{
                                //     height:"28px",
                                // }}
                                inputValue={lectureIntro}
                                placeholder={placeholder}
                                fnChange={(e)=>{
                                    setLectureIntro(e.target.value)
                                }}
                            />
                        </div>
                    </div>

                </div>
                <div style={{
                    display:"flex",
                    width:"100%",
                    justifyContent:"center",
                    marginTop:"10px",
                }}>
                    <div style={{
                        display:"flex",
                        width:"180px",
                        justifyContent:"space-between",
                    }}>
                    <div style={{
                        display:"flex",
                        justifyContent:"center",
                        alignItems:"center",
                        width:"87px",
                        height:"40px",
                        borderRadius:"15px",
                        backgroundColor:"#D8D8D8",
                        fontSize:"14px",
                        color : "#FFFFFF",
                        fontWeight:700
                    }}
                        onClick={()=>{
                            setIsModal(false)
                        }}
                    >
                        취소
                    </div>
                    <div style={{
                        display:"flex",
                        justifyContent:"center",
                        alignItems:"center",
                        width:"87px",
                        height:"40px",
                        borderRadius:"15px",
                        backgroundColor:"#5EA3C6",
                        fontSize:"14px",
                        color : "#FFFFFF",
                        fontWeight:700
                    }}
                        onClick={()=>{
                            apiPro_regi();
                        }}
                    >
                        등록

                    </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Modal_modi