import {useNavigate} from "react-router";
import apiFn from "../../../asset/apiClass";
import store from "../../../asset/store";
import {React, useEffect} from "react";
import UiNoticeDetail_Style from "../UiNoticeDetail_Style";
import style from "../../../asset/style";
import util from "../../../asset/util";
import UiTextBtn from "../../UiTextBtn/UiTextBtn";
import constants from "../../../asset/constants";
import UiEditor from "../../UiEditor/UiEditor";
import Column_pick_Style from "../../../pages/Column/Column_pick_Style";
import UiBtn from "../../UiBtn/UiBtn";
import Thumbnail from "./Thumbnail";

const NoticeLectureItem = ({
                               data = {},
                               userInfo,
                               apiPro_read,
                               apiPro_read_2,
                               params,
                               attach
                           }) => {

    let navigate = useNavigate();

    const apiPro_del = async () => {
        try {
            let isOk = await apiFn.comApi({
                state: {
                    table: 'board',
                    del: [{
                        id: data["id"]
                    }]
                }
            })

            if (params["type"].includes("&")) {

                const stack = store.get('stack')
                if (stack.length > 0)
                    navigate(-1);
                else
                    navigate('/')
            } else {
                if (params["type"].includes("de")) {
                    apiPro_read();
                } else {
                    apiPro_read_2();
                }
            }
        } catch (e) {
        }
    }


    useEffect(() => {
        // console.log("data ",data)
    }, [data])


    return (
        <div style={UiNoticeDetail_Style.c}>
            <div style={UiNoticeDetail_Style.w}>
                <div style={{...UiNoticeDetail_Style.text_2, float: "right"}}>
                    {
                        userInfo["id"] == data["mid"] &&
                        <div style={{
                            display: "flex",
                            marginLeft: "10px",
                        }}>
                            <UiTextBtn
                                btnStyle={{
                                    color: style.common.fontColor_3,
                                    padding: "0px 5px"
                                }}
                                text={"삭제"}
                                fnClick={() => {
                                    let isOk = window.confirm("글을 삭제하시겠어요?")
                                    if (isOk) {
                                        apiPro_del();
                                    }
                                }}
                            />
                            <UiTextBtn
                                btnStyle={{
                                    color: style.common.fontColor_5,
                                    padding: "0px 5px"
                                }}
                                text={"수정"}
                                fnClick={() => {
                                    navigate(`/write/${params["who"]}/${params.where === "lecture_video" ? '7' : 'no'}/${params["id"]}/${params["type"]}/y&${data["id"]}`)
                                }}
                            />
                        </div>

                    }
                </div>

                {/* <div style={UiNoticeDetail_Style.editor}> */}

                {
                    <Thumbnail who={params.who} data={data}/>
                }
                <div style={{
                    ...style.common.line_text,
                    padding: 5,
                    display: 'flex',
                    justifyContent: 'space-between'
                }}>
                    {/* 3장 23번 문제 모법 답안이 업데이트 되었습니다. */}
                    {data["important"] == "y" &&
                        <div style={{
                            color: style.common.fontColor_3,
                            marginRight: "3px",
                            fontWeight: "bold",
                            display: "inline-flex",
                        }}>
                            [중요]
                        </div>
                    }
                    <div style={{fontWeight: 'bold', fontSize: 17}}>
                        {data["title"]}
                    </div>
                    {/* 클래스룸 운영을 잘해야 합니다.클래스룸 운영을 잘해야 합니다.클래스룸 운영을 잘해야 합니다. */}


                    <div>
                        <span style={{fontWeight: '500'}}>{data["writer"] && data["writer"]["name"]}</span> 선생님
                    </div>
                </div>
                <div style={{
                    ...style.common.line_text,
                    padding: 5 ,
                    display: 'flex',
                    justifyContent: 'space-between',
                }}>
                    <div style={{color: style.common.s_color, fontWeight: 'bold'}}>
                        조회 수 {data.readCnt}회
                    </div>
                    {/* 클래스룸 운영을 잘해야 합니다.클래스룸 운영을 잘해야 합니다.클래스룸 운영을 잘해야 합니다. */}


                    <div style={{fontSize: 12}}>
                        {util.convertToDateTime(data["reg_date"])}
                    </div>
                </div>
                {
                    data.content && <div
                        style={{
                            // padding : "10px 0px"
                            // padding: "10px 15px"
                            margin: '10px 16px',
                        }}
                        className="editor_"
                    >
                        {/* UIEDITOR */}
                        {/* {data["content"]} */}
                        <UiEditor
                            readOnly={true}
                            initValue={data["content"]}

                        />
                    </div>
                }
                <div style={{
                    // padding : "10px 0px"
                    padding: "10px 15px"
                }}
                >
                    {
                        data["files"] &&
                        data["files"].map((v, i) =>
                            <div style={{
                                ...Column_pick_Style.t_btn_w,
                                // minWidth : "180px",
                                margin: 0,
                                // width : "auto",
                            }}>
                                <UiBtn
                                    // isFile={true}
                                    // id={"btn_img"}
                                    btnStyle={{
                                        ...Column_pick_Style.t_btn,
                                        borderRadius: 20,
                                        // height : '30px',
                                        padding: "18px 15px",
                                        backgroundColor: style.common.t_color,
                                        color: "white",
                                        width: "auto",
                                    }}
                                    title={
                                        <div style={{
                                            // width : "100%",
                                            // ...Column_pick_Style.t_btn_text,
                                            // paddingLeft : "5px",
                                            // marginLeft:"15px"
                                        }}>

                                            {/* {v["origin"]} */}
                                            <a
                                                style={{
                                                    color: "white",
                                                }}
                                                download
                                                // href={"http://s3_file?filename="+v.origin+"?filepath="+getS3Folder()+'/board/'+v.bid+'/'+v.name}
                                                // href={encodeURI("http://s3_file?filename="+v["origin"]+"?filepath="+util.getS3Folder()+'/board/'+v["bid"]+'/'+v["name"])}
                                                // href={"http://s3_file?filename="+v["origin"]+"?filepath="+util.getS3Folder()+'/board/'+v["bid"]+'/'+v["name"].normalize('NFD')}
                                                // href={constants.apiUrl+"/"+util.getS3Folder()+'/board/'+v["bid"]+'/'+v["name"]}
                                                href={v.url}
                                            >
                                                {util.textTrim(v["origin"], 35)}
                                            </a>
                                        </div>
                                    }
                                />
                            </div>
                        )
                    }
                </div>
            </div>
        </div>
    )
}

export default NoticeLectureItem
