import { Circle } from "@mui/icons-material";
import { Switch } from "@mui/material";
import { React, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import allinone_logo from "../../asset/Img/allinone.svg";
import costudy_logo from "../../asset/Img/costudy.svg";
import dawon_logo from "../../asset/Img/dawon-intro.png";
import hansuwi_logo from "../../asset/Img/hansuwi-intro.png";
import icon_export from "../../asset/Img/icon_export.png";
import icon_favorite_off from "../../asset/Img/icon_favorite_off.png";
import icon_favorite_on from "../../asset/Img/icon_favorite_on.png";
import icon_invite_pink from "../../asset/Img/icon_invite_pink.png";
import icon_link from "../../asset/Img/icon_link.png";
import icon_movebook from "../../asset/Img/icon_movebook.png";
import icon_roomexit from "../../asset/Img/icon_roomexit.png";
import icon_teacher_move from "../../asset/Img/icon_teacher_move.png";
import intro_logo from "../../asset/Img/intro_logo.png";
import jongro_logo from "../../asset/Img/jongro-intro.jpg";
import math_in_everyday_logo from "../../asset/Img/math-in-everyday.jpg";
import myeongseong_logo from "../../asset/Img/myeongseong.png";
import seoro_logo from "../../asset/Img/seoro.png";
import sne_logo from "../../asset/Img/sne_logo.png";
import wmm_logo from "../../asset/Img/wmm-logo.png";
import apiFn from "../../asset/apiClass";
import constants from "../../asset/constants";
import store from "../../asset/store";
import style from "../../asset/style";
import util from "../../asset/util";
import UiTextBtn from "../../uiComponents/UiTextBtn/UiTextBtn";
import LinkShareModal from "./LinkShareModal";
import {
  useDeleteQuestion,
  useGetQuestionData,
  useRemoveQuestionMembers,
} from "../../api/question";

var fontStyle = {
  fontFamily: "Noto Sans",
  fontStyle: "normal",
};

const Message_talk_side = ({
  apiPro_mosaic,
  setIsModal_book,
  isPick,
  setIsPick,
  setIsModal,
  setIsModal_pick,
  setIsModal_invite,
  userInfo,
  qnaData,
  apiPro_public,
  isChk,
  setIsSide,
  apiPro_evaluate_cal,
  apiPro_evaluate_lv,
  isDetail,
  setIsDetail,
  isModal_invite,
  isMosaic,
  setQnaData,
  isBookAdmin,
}) => {
  let params = useParams();
  let navigate = useNavigate();

  // var [friendsList,setFriendsList] = useState([])

  var [t_list, setT_list] = useState([]);
  var [s_list, setS_list] = useState([]);
  const [isLinkShareModal, setIsLinkShareModal] = useState(false);

  // 질문 문항 삭제
  const apiPro_del_msg = async () => {
    try {
      await useDeleteQuestion(params.qid);
      // navigate(`/message/${params["who"]}/home`)

      const stack = store.get("stack");
      if (stack.length > 0) navigate(-1);
    } catch (e) {
      console.log(e);
    }
  };

  const chkPcik = async () => {
    try {
      let isChk = await apiFn.comApi({
        state: {
          table: "folder_link",
          page: 1,
          amount: 9999,
          sort: "reg_date desc",
          where: {
            "HIQDB_folder_link.qid": `='${params["qid"]}'`,
            "HIQDB_folder_link.mid": `='${userInfo["id"]}'`,
          },
        },
      });

      console.log("@@ ", isChk["list"]);

      if (isChk["list"].length > 0) {
        setIsPick(true);
      } else {
        setIsPick(false);
      }
    } catch (e) {
      console.log(e);
    }
  };

  // 찜 제거
  const apiPro_del_pick = async () => {
    try {
      let isOk = await apiFn.comApi({
        state: {
          table: "folder_link",
          delAll: "Y",
          del: [
            {
              qid: params["qid"],
              mid: userInfo["id"],
            },
          ],
        },
      });
      await chkPcik();
    } catch (e) {}
  };

  const apiPro_in = async (sort, inSearch, r) => {
    try {
      let data = await apiFn.comApi({
        state: {
          table: "online_qna_log",
          page: 1,
          amount: 9999,
          sort: "reg_date desc",
          where: {
            "HIQDB_online_qna_log.qid": `= '${params["qid"]}'`,
          },
        },
      });

      let wid = data["list"].reduce((a, v, i) => {
        return [...a, v["wid"]];
      }, []);
      wid = [...wid, qnaData.sid, qnaData.tid];

      let findPeople = await apiFn.findPeople({
        state: {
          wid,
        },
      });

      // console.log(findPeople)
      setS_list([...findPeople["s_list"]]);
      setT_list([...findPeople["t_list"]]);

      // if(findPeople.length == 0 ){
      //     setFriendsList([])
      // }
      // else {
      //     setFriendsList([
      //         ...findPeople["list"]
      //     ])
      //     if(r)return findPeople["list"]
      // }
    } catch (e) {
      console.log("apiPro_in : ", e);
    } finally {
    }
  };

  // useEffect(()=>{
  //     console.log("MSG SID COMPO : ",qnaData)
  // },[qnaData])

  useEffect(() => {
    // if(isDetail)return;
    // apiPro_in();
    chkPcik();
  }, []);

  useEffect(() => {
    if (!isModal_invite) {
      apiPro_in();
    }
  }, [isModal_invite]);

  // useEffect(()=>{
  //    console.log("s_list : ",s_list);
  //    console.log("t_list : ",t_list);
  // },[t_list, s_list])

  return (
    <div
      style={{
        position: "absolute",
        right: 0,
        top: 0,
        zIndex: 130001,
        backgroundColor: "rgba(150, 150, 150,0.5)",
        width: "100%",
        height: "100vh",
        display: "flex",
        // flexDirection:"row-reverse"
        justifyContent: "flex-end",
      }}
      onClick={() => {
        setIsSide(false);
      }}
    >
      <div
        style={{
          backgroundColor: "white",
          maxWidth: "550px",
          width: "65%",
          height: "100%",
          boxShadow: "-5px 0px 10px 1px #aaaaaa",
          // position:"absolute",
          // right:0,
          // top:0,
        }}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
      >
        <div
          style={{
            width: "100%",
            height: "calc(100% - 64px)",
          }}
        >
          <div
            style={{
              ...fontStyle,
              fontSize: "12px",
              padding: "15px 15px 6px 15px",
              display: "flex",
            }}
          >
            <div
              style={{
                fontSize: "14px",
                marginRight: "5px",
                fontWeight: 500,
              }}
            >
              문제 접수일
            </div>
            <div
              style={{
                color: "#3E3E3E",
                fontWeight: 300,
              }}
            >
              {qnaData?.qid_HIQDB_online_qna?.qna_date
                ? util.convertToDateTime_ymdhm_dot(qnaData?.qid_HIQDB_online_qna?.qna_date)
                : qnaData["reg_date"]
                  ? util.convertToDateTime_ymdhm_dot(qnaData["reg_date"])
                  : ""}
            </div>
          </div>
          <div
            style={{
              ...fontStyle,
              fontSize: "12px",
              display: "flex",
              padding: "3px 15px",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{
                width: "100%",
                justifyContent: "space-between",
                display: "flex",
                flexDirection: "column",
              }}
            >
              {params["who"] == "teacher" ? (
                qnaData["ans_date"] ? (
                  <></>
                ) : qnaData.tid === userInfo.id || isBookAdmin ? (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <div
                      style={{
                        fontWeight: 500,
                      }}
                    >
                      조교에게 공개
                    </div>
                    <Switch
                      checked={isChk}
                      onClick={(e) => {
                        apiPro_public(isChk);
                      }}
                    />
                  </div>
                ) : (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  ></div>
                )
              ) : (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                ></div>
              )}
              {params["who"] == "teacher" && qnaData["tid"] == userInfo["id"] && (
                <div
                  style={{
                    // paddingRight : "5px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      fontWeight: 500,
                    }}
                  >
                    문제 이미지 모자이크
                  </div>
                  <Switch
                    checked={isMosaic}
                    onClick={(e) => {
                      let ori;
                      if (qnaData["original_img"] != "0") {
                        apiPro_mosaic(true, qnaData["original_img"]);
                      } else {
                        apiPro_mosaic(true);
                      }
                    }}
                  />
                </div>
              )}

              {/* <div style={{
                                display:"flex",
                                alignItems:"center",
                            }}>
                                <div style={{
                                    marginRight:"5px",
                                    fontWeight:500,
                                }}>
                                    열람
                                </div>
                                <div style={{
                                    color : "#3E3E3E",
                                    fontWeight:300,
                                }}>
                                    {qnaData["readCnt"]}
                                </div>
                            </div> */}
            </div>
          </div>
          <div
            style={{
              ...fontStyle,
              fontSize: "12px",
              display: "flex",
              padding: "0px 15px",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{
                width: "100%",
                // justifyContent:"space-between",
                display: "flex",
              }}
            >
              {qnaData["b_img"] || qnaData["bid"] ? (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    width: "95px",
                    height: "130px",
                    // border: "0.5px solid rgba(128, 128, 128, 0.5)",
                    // borderRadius: "10px",
                    marginRight: "9px",
                  }}
                >
                  <img
                    onError={(e) => {
                      // e.currentTarget.src = intro_logo;
                      switch (constants.channelId) {
                        case "jym":
                          e.currentTarget.src = jongro_logo;
                          break;
                        case "msm":
                          e.currentTarget.src = myeongseong_logo;
                          break;
                        case "aio":
                          e.currentTarget.src = allinone_logo;
                          break;
                        case "csd":
                          e.currentTarget.src = costudy_logo;
                          break;
                        case "wmm":
                          e.currentTarget.src = wmm_logo;
                          break;
                        case "srk":
                          e.currentTarget.src = seoro_logo;
                          break;
                        case "sne":
                          e.currentTarget.src = sne_logo;
                          break;
                        case "mie":
                          e.currentTarget.src = math_in_everyday_logo;
                          break;
                        case "mds":
                          e.currentTarget.src = dawon_logo;
                          break;
                        case "hsw":
                          e.currentTarget.src = hansuwi_logo;
                          break;
                        default:
                          e.currentTarget.src = intro_logo;
                          break;
                      }
                      console.log("EERERERERERERER ", e);
                    }}
                    style={{
                      border: "0.5px solid rgba(128, 128, 128, 0.5)",
                      borderColor: style.common.s_color,
                      borderRadius: "10px",
                      width: "100%",
                      height: "100%",
                    }}
                    src={
                      qnaData["b_img"]
                        ? `${constants.s3_url}/bookCover/${qnaData["b_img"]}.jpg`
                        : `${constants.s3_url}/bookCover/${qnaData["bid"]}.jpg?${util.getRandomInt(1000, 9999)}`
                    }
                    onClick={() => {
                      navigate(`/book/${params["who"]}/${qnaData["bid"]}/0/de`);
                    }}
                  />
                </div>
              ) : (
                <></>
              )}
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  fontSize: "14px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: "2px",
                  }}
                >
                  <div
                    style={{
                      marginRight: "5px",
                      fontWeight: 500,
                      width: "27px",
                    }}
                  >
                    교재
                  </div>
                  <div
                    style={{
                      color: "#3E3E3E",
                      fontWeight: 300,
                    }}
                  >
                    {qnaData["name"] ? qnaData["name"] : "미지정"}
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    marginBottom: "2px",
                  }}
                >
                  <div
                    style={{
                      marginRight: "5px",
                      fontWeight: 500,
                      minWidth: "28px",
                      width: "fit-content",
                    }}
                  >
                    출처
                  </div>
                  <div
                    style={{
                      color: "#3E3E3E",
                      fontWeight: 300,
                      display: "flex",
                      flexWrap: "wrap",
                    }}
                  >
                    {qnaData["title"] ? qnaData["title"] : "없음"}
                  </div>
                </div>
                {qnaData["ans_date"] ? (
                  <>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginBottom: "2px",
                        // marginBottom:"3px",
                      }}
                    >
                      <div
                        style={{
                          marginRight: "5px",
                          fontWeight: 500,
                        }}
                      >
                        난이도
                      </div>
                      <PopStar
                        defaultValue={qnaData["level"] ? qnaData["level"] : 0}
                        fnChange={(e) => {
                          if (params["who"] == "teacher") {
                            if (qnaData["tid"] == userInfo["id"]) {
                              apiPro_evaluate_lv(e);
                            }
                          }
                        }}
                        isAction={params["who"] == "teacher" ? true : false}
                      />
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginBottom: "2px",
                        // marginBottom:"3px",
                      }}
                    >
                      <div
                        style={{
                          marginRight: "5px",
                          fontWeight: 500,
                        }}
                      >
                        계산량
                      </div>
                      <PopStar
                        // title="계산량"

                        defaultValue={qnaData["cal_amount"] ? qnaData["cal_amount"] : 0}
                        fnChange={(e) => {
                          if (params["who"] == "teacher") {
                            if (qnaData["tid"] == userInfo["id"]) {
                              apiPro_evaluate_cal(e);
                            }
                          }
                        }}
                        isAction={params["who"] == "teacher" ? true : false}
                      />
                    </div>
                  </>
                ) : (
                  <>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        // marginBottom:"3px",
                      }}
                    ></div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        // marginBottom:"3px",
                      }}
                    ></div>
                  </>
                )}
                {/* {
                                !qnaData["ans_date"] &&
                                params["who"] == "teacher" ?
                                qnaData["tid"] == userInfo["id"] &&
                                    <div style={{
                                    display:"flex",
                                    alignItems:"center",
                                }}>
                                    <UiTextBtn
                                        text={
                                            <>
                                           <img
                                                src={icon_movebook}
                                                style={{
                                                    width:"18px",
                                                    height:"18px",
                                                    marginRight:"4px",
                                                }}
                                            />
                                            다른 교재로 이동
                                            </>
                                        }
                                        btnStyle={{
                                            color : "#B3B3B3",
                                            fontSize:"13px",
                                            // fontWeight:500,
                                        }}
                                        fnClick={()=>{
                                            setIsModal_book(true)
                                            // setIsDetail(true)
                                            // navigate(`/list/${params["who"]}/${params["qid"]}/${params["sid"]}/${params["tid"]}`)
                                        }}
                                    />
                                </div>
                                :
                                <></>
                                } */}
              </div>
            </div>
          </div>
          {
            // qnaData["ans_date"] &&
            params["who"] == "teacher" && qnaData["tid"] == userInfo["id"] && (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  // padding : "10px 5px 5px 5px",
                  padding: "15px 5px 0px 15px",
                  // justifyContent:"center",
                  // marginBottom:"3px",
                }}
              >
                <UiTextBtn
                  isUnderline={false}
                  text={
                    <div
                      style={{
                        display: "flex",
                      }}
                    >
                      <img
                        src={icon_movebook}
                        style={{
                          width: "18px",
                          height: "18px",
                          marginRight: "4px",
                          // marginLeft:"8px",
                        }}
                      />
                      <div
                        style={{
                          fontWeight: 500,
                          color: "#4496F0",
                        }}
                      >
                        다른 교재로 이동
                      </div>
                    </div>
                  }
                  btnStyle={{
                    color: style.common.fontcolor_8,
                    fontSize: "14px",
                    // fontWeight:500,
                  }}
                  fnClick={() => {
                    setIsDetail(true);
                    navigate(
                      `/list/${params["who"]}/${params["qid"]}/${params["sid"]}/${params["tid"]}`
                    );
                  }}
                />
              </div>
            )
          }
          <Invite
            setQnaData={setQnaData}
            userInfo={userInfo}
            qnaData={qnaData}
            setIsModal_invite={setIsModal_invite}
            s_list={s_list}
            t_list={t_list}
            apiPro_in={apiPro_in}
          />
        </div>
        <div
          style={{
            width: "100%",
            height: "64px",
            // paddingLeft:"15px",
            justifyContent: "space-between",
            backgroundColor: "#F8F8F8",
            display: "flex",
          }}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
        >
          <div
            style={{
              display: "flex",
              marginTop: "14px",
              marginLeft: "15px",
            }}
          >
            {params["who"] == "teacher" ? (
              qnaData["tid"] == userInfo["id"] && (
                <img
                  style={{
                    width: "24px",
                    height: "24px",
                  }}
                  src={icon_roomexit}
                  onClick={() => {
                    let isOk = window.confirm(
                      "현재 질문방을 삭제 하시겠습니까? (주의 : 삭제시 질문 복구가 불가 합니다.)"
                    );
                    if (isOk) {
                      apiPro_del_msg();
                    }
                  }}
                />
              )
            ) : (
              <></>
            )}
          </div>
          <div
            style={{
              display: "flex",
              marginTop: "14px",
              paddingRight: "15px",
            }}
          >
            <img
              style={{
                marginRight: "12px",
                width: "19px",
                height: "25px",
              }}
              src={isPick ? icon_favorite_on : icon_favorite_off}
              onClick={() => {
                if (isPick) {
                  let isOk = window.confirm("오답노트를 해제 하시겠어요?");
                  apiPro_del_pick();
                } else {
                  // setIsDetail(true)
                  // navigate(`/messageFolder/${params["who"]}/${params["qid"]}/de`)
                  setIsModal_pick(true);
                }
              }}
            />
            {params["who"] == "teacher" ? (
              qnaData["tid"] == userInfo["id"] && (
                <img
                  style={{
                    marginRight: "12px",
                    width: "24px",
                    height: "24px",
                  }}
                  src={icon_teacher_move}
                  onClick={() => {
                    setIsModal(true);
                  }}
                />
              )
            ) : (
              <></>
            )}
            <img
              style={{
                // marginRight:"12px",
                width: "24px",
                height: "24px",
              }}
              src={icon_link}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setIsLinkShareModal(true);
              }}
            />
          </div>
        </div>
      </div>
      {isLinkShareModal && (
        <LinkShareModal
          qnaData={qnaData}
          isLinkShareModal={isLinkShareModal}
          setIsLinkShareModal={setIsLinkShareModal}
        />
      )}
    </div>
  );
};

const PopStar = ({ title, fnChange, defaultValue, isAction = true }) => {
  let params = useParams();

  var [star_1, setStar_1] = useState(false);
  var [star_2, setStar_2] = useState(false);
  var [star_3, setStar_3] = useState(false);
  var [star_4, setStar_4] = useState(false);
  var [star_5, setStar_5] = useState(false);

  const pro_start = (num, isForce) => {
    try {
      let pass = true;
      if (!isAction) pass = false;
      if (isForce) pass = true;
      if (!pass) return;
      if (num == 1) {
        setStar_1(true);
        setStar_2(false);
        setStar_3(false);
        setStar_4(false);
        setStar_5(false);
      }

      if (num == 2) {
        setStar_1(true);
        setStar_2(true);
        setStar_3(false);
        setStar_4(false);
        setStar_5(false);
      }
      if (num == 3) {
        setStar_1(true);
        setStar_2(true);
        setStar_3(true);
        setStar_4(false);
        setStar_5(false);
      }
      if (num == 4) {
        setStar_1(true);
        setStar_2(true);
        setStar_3(true);
        setStar_4(true);
        setStar_5(false);
      }
      if (num == 5) {
        setStar_1(true);
        setStar_2(true);
        setStar_3(true);
        setStar_4(true);
        setStar_5(true);
      }
      if (isForce) return;
      fnChange(num);
    } catch (e) {}
  };

  useEffect(() => {
    pro_start(defaultValue, true);
  }, [defaultValue]);

  let size = {
    fontSize: "14px",
  };
  return (
    <div
      style={{
        display: "flex",
        // width:'100%',
        justifyContent: "center",
        alignItems: "center",
        // padding : "5px 0px",
      }}
    >
      <div
        style={{
          fontSize: "14px",
          marginRight: "3px",
        }}
      >
        {title}
      </div>
      <Circle
        className="review_star"
        style={{
          color: star_1 ? "black" : "rgb(200, 200, 200)",
          ...size,
        }}
        onClick={() => {
          pro_start(1);
        }}
      />
      <Circle
        className="review_star"
        style={{
          color: star_2 ? "black" : "rgb(200, 200, 200)",
          ...size,
        }}
        onClick={() => {
          pro_start(2);
        }}
      />
      <Circle
        className="review_star"
        style={{
          color: star_3 ? "black" : "rgb(200, 200, 200)",
          ...size,
        }}
        onClick={() => {
          pro_start(3);
        }}
      />
      <Circle
        className="review_star"
        style={{
          color: star_4 ? "black" : "rgb(200, 200, 200)",
          ...size,
        }}
        onClick={() => {
          pro_start(4);
        }}
      />
      <Circle
        className="review_star"
        style={{
          color: star_5 ? "black" : "rgb(200, 200, 200)",
          ...size,
        }}
        onClick={() => {
          pro_start(5);
        }}
      />
    </div>
  );
};

const Invite = ({
  s_list,
  t_list,
  setIsModal_invite,
  qnaData,
  apiPro_in,
  userInfo,
  setQnaData,
}) => {
  return (
    <div
      style={{
        marginTop: "10px",
      }}
    >
      {qnaData["tid"] == userInfo["id"] && (
        <div
          style={{
            ...fontStyle,
            fontSize: "12px",
            padding: "5px 15px 0px 15px",
            display: "flex",
          }}
        >
          {qnaData["ans_date"] ? (
            <></>
          ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                width: "100%",
              }}
            >
              <div
                style={{
                  marginRight: "5px",
                  fontWeight: 500,
                  display: "flex",
                  // padding : "2px 10px",
                  borderRadius: 20,
                  // justifyContent:"center",
                  color: "#F09294",
                  alignItems: "center",
                }}
                onClick={() => {
                  setIsModal_invite(true);
                }}
              >
                <div
                  style={{
                    display: "flex",
                    fontSize: "14px",
                  }}
                >
                  {/* <div style={{
                                display:"flex",
                                alignItems:"center",
                                justifyContent:"center",
                                width:"12px",
                                height:"12px",
                                border : "1px solid white",
                                padding : "2px",
                                borderRadius : 20,
                                marginRight:"5px",
                            }}>
                            </div> */}
                  <img
                    src={icon_invite_pink}
                    style={{
                      width: "18px",
                      height: "18px",
                      marginRight: "2px",
                    }}
                  />
                  대화 상대 초대하기
                </div>
              </div>
            </div>
          )}
        </div>
      )}
      <div
        style={{
          ...fontStyle,
          fontSize: "12px",
          padding: "15px 15px 0px 15px",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            borderBottom: "0.5px solid #ECECEC",
            width: "100%",
            marginRight: "5px",
            fontSize: "14px",
            fontWeight: 500,
            display: "flex",
            color: style.common.t_color,
            padding: "3px 0px",
          }}
        >
          선생님
          {t_list.length > 1 && ` (${t_list.length}명)`}
        </div>
        <div
          style={{
            marginTop: "10px",
            fontSize: "12px",
            // fontWeight:500
          }}
        >
          {t_list.map((v, i) => (
            <People
              setQnaData={setQnaData}
              mCount={t_list.length + s_list.length}
              qnaData={qnaData}
              userInfo={userInfo}
              apiPro_in={apiPro_in}
              isTeacher={true}
              data={v}
            />
          ))}
        </div>
      </div>
      <div
        style={{
          ...fontStyle,
          fontSize: "12px",
          padding: "15px 15px 0px 15px",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            borderBottom: "0.5px solid #ECECEC",
            width: "100%",
            marginRight: "5px",
            fontWeight: 500,
            display: "flex",
            color: style.common.s_color,
            fontSize: "14px",
            padding: "3px 0px",
          }}
        >
          학생
          {s_list.length > 1 && ` (${s_list.length}명)`}
        </div>
        <div
          style={{
            marginTop: "10px",
            fontSize: "12px",
            // fontWeight:500
          }}
        >
          {s_list.map((v, i) => (
            <People
              setQnaData={setQnaData}
              mCount={t_list.length + s_list.length}
              qnaData={qnaData}
              userInfo={userInfo}
              apiPro_in={apiPro_in}
              sTeacher={false}
              data={v}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

const People = ({ isTeacher = false, data, apiPro_in, userInfo, qnaData, mCount, setQnaData }) => {
  let params = useParams();
  let navigate = useNavigate();

  const call_expel = async (id, data) => {
    try {
      // console.log("mCount : ",mCount)
      // console.log("mCount : ",qnaData)

      // return;
      // navigate(-1);
      apiFn.onlineQnaMsgSet({
        state: {
          qid: params["qid"],
          wtype: 2,
          wid: userInfo["id"],
          targetId: id,
          content: `${data.user_type === 1 ? data["nick"] : data["name"]}님이 질문방을 나가셨어요.`,
          ctype: 7,
        },
      });

      let del_id = {
        "HIQDB_app_msg.sid": id,
      };
      if (data.hasOwnProperty("stype")) {
      } else {
        del_id = { "HIQDB_app_msg.tid": id };
      }

      await Promise.all([
        apiFn.comApi({
          state: {
            table: "app_msg",
            delAll: "Y",
            del: [
              {
                "HIQDB_app_msg.qid": params["qid"],
                ...del_id,
              },
            ],
          },
        }),
        apiFn.comApi({
          state: {
            table: "online_qna_log",
            delAll: "Y",
            del: [
              {
                "HIQDB_online_qna_log.qid": params["qid"],
                "HIQDB_online_qna_log.wid": id,
              },
            ],
          },
        }),
        useRemoveQuestionMembers(Number(params.qid), Number(mCount)),
      ]);

      let [qna, ...rest] = await Promise.all([useGetQuestionData(params.qid), apiPro_in()]);

      // setQnaData({
      //     ...qna["list"][0],
      //     member_cnt: qna["list"][0]["member_cnt"]
      // })

      // navigate(-1);
    } catch (e) {
      console.log("CALL EXPEL ERROR : ", e);
    }
  };

  return (
    <div
      onClick={() => {
        navigate(`/myinfo/${data.user_type === 1 ? "student" : "teacher"}/${data["id"]}`);
        // if(data["tid"] == "hiqsum_admin" || data["wid"] == "hiqsum_admin" || data["sid"] == "hiqsum_admin" ){

        // }
        // else {
        //     if(params["qid"] == 0){
        //         if(data["wtype"] == 1){
        //             navigate(`/myinfo/${params["who"]}/${data["sid"]}`)
        //         }
        //         else {
        //             navigate(`/myinfo/${params["who"]}/${data["tid"]}`)
        //         }
        //     }
        //     else {
        //         navigate(`/myinfo/${params["who"]}/${data["wid"]}`)
        //     }
        // }
      }}
      style={{
        padding: "4px 0px",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      {isTeacher ? data["name"] : data["nick"]}
      {data["id"] !== userInfo["id"] &&
        params["who"] === "teacher" &&
        qnaData["tid"] === userInfo["id"] &&
        qnaData["sid"] !== data["id"] && (
          <img
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              if (data["id"] === userInfo["id"]) return;
              // console.log(data["id"], data);
              // return;
              if (data.stype) {
                let isOk = window.confirm(`${data["nick"]} 학생을 질문방에서 내보내시겠어요?`);
                if (isOk) {
                  call_expel(data["id"], data);
                }
              } else {
                let isOk = window.confirm(`${data["name"]} 선생님을 질문방에서 내보내시겠어요?`);
                if (isOk) {
                  call_expel(data["id"], data);
                }
              }
            }}
            src={icon_export}
            style={{
              width: "14px",
              height: "14px",
            }}
          />
        )}
    </div>
  );
};
export default Message_talk_side;
