import { useMemo } from "react";
import { useSelector } from "react-redux";

const useClassVideoUploadStatus = (boardId?: number | string) => {
  const uploadStatus = useSelector((state) => (state as any).upload);
  const statusList = useMemo(() => {
    return Object.entries(uploadStatus.jobs)
      .filter(([path, data]) => {
        const fileExtension = path.split(".").pop();
        if (fileExtension === "jpg") {
          return false;
        } else {
          return true;
        }
      })
      .map(([path, data]) => {
        const { status, progress } = data as any;
        const match = path.match(/\b\d+\b/);
        const uploadBoardId = match ? match[0] : null;

        return { uploadBoardId, status, progress };
      });
  }, [uploadStatus]);

  return statusList.find((item) => item.uploadBoardId === `${boardId}`);
};

export default useClassVideoUploadStatus;
