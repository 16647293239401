import { useEffect } from "react";

const GlobalButtonClickListener = ({ onClick }) => {
  useEffect(() => {
    // Define the handler function
    const handleClick = (event: MouseEvent) => {
      const target = event.target as HTMLElement;
      if (["button"].includes(target.tagName.toLowerCase())) {
        onClick(event);
      }
    };

    // Add the event listener
    document.addEventListener("click", handleClick);

    // Cleanup the event listener on component unmount
    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, [onClick]);

  return null; // This component does not render anything
};

export default GlobalButtonClickListener;
