import style from "../../asset/style"

const UiPeople_Style = {
    section_c : {
        display:"flex",
        height : '55px',
        margin : "15px 0px",
        // backgroundColor:"white",
    },   
    section_i_w : {
        display:'flex',
        justifyContent:'center',
        alignItems:"center",
        width : "55px",
        height : "55px",
        // backgroundColor : "rgb(230,230,230)",
        borderRadius : 12,
    },
    section_i : {
        width : "100%",
        height : "100%",
        objectFit:'cover',
        objectPosition:'center',
        borderRadius : 50,
    },
    section_r_c : {
        display:"flex",
        flexDirection:"column",
        justifyContent:"space-between",
        // width : '80%',
        height : "100%",
    },
    section_r_t_w : {
        display:"flex",
        justifyContent:"space-between",
        fontSize : '13px',
    },
    section_r_t_t_w : {
        display:"flex",
        fontWeight:"bold",
        // backgroundColor : 'red',
    },
    section_r_t_t_text_1 : {
        marginRight : '5px',
        color : style.common.t_color
    },
    section_r_t_t_text_2 : {
        width:"100px",
        marginRight : '5px',
        fontSize : "14px",
        fontWeight:400,
        // fontWeight : 'bold',
    },
    section_r_t_t_text_3 : {
        color : style.common.fontColor_1
    },
    section_r_m_w : {
        display:"flex",
        justifyContent:"space-between",
        fontSize : '11px',
        color : style.common.fontColor_1,
        // marginTop : '2px',
    },
    section_r_m_l_w : {
        display:"flex",
        color : style.common.fontColor_6
    },
    section_r_m_r_w : {
        display:"flex",
        justifyContent:"center",
        alignItems:"center",
        fontWeight:"bold",
        padding : '1px 5px',
        width : '12px',
        // backgroundColor : 'red',
        borderRadius : 10,
        color : "white",
        fontSize : "11px"
    },
    section_r_b_w  : {
        display:"flex",
        justifyContent:"space-between",
        fontSize : '11px',
        color : style.common.fontColor_6,
        // marginTop : '2px',
        paddingBottom : "2px",
    },
    openAdd_c : {
        display:'flex',
        justifyContent:"center",
        width :"100%",
        // height : "30px",
        borderTop : "1px solid",
        borderColor : style.common.borderColor,
        // maxWidth : style.common.maxWidth,
        // backgroundColor : "purple",
    },
    openAdd_btn_w : {
        display:"flex",
        justifyContent:"space-around",
        width : "100%",
        // width : "80%",
        // maxWidth : style.common.maxWidth,
        padding : "10px 0px",
    }, 
    openAdd_btn_w_2 : {
        display:"flex",
        justifyContent:"space-between",
        width : "100%",
        // width : "80%",
        padding : "10px 0px",
    }, 
    openAdd_btn_w_2_text_w : {
        display : "flex",
        alignItems : "center",
        justifyContent:"center",
        flexGrow : 1,

    },
    openAdd_btn_w_2_text : {
        fontSize : "14px",
        marginRight : "2px",
    },
    openAdd_btn : {
        fontSize : "12px",

        display:"flex",
        justifyContent:"center",
        flexGrow : 1,
        color : style.common.fontColor_1
    },
}

export default UiPeople_Style