import { React, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import util from "../../asset/util";
import constants from "../../asset/constants";
import apiFn from "../../asset/apiClass";
import style from "../../asset/style";
import face_student from "../../asset/Img/defaultpic_student.png";
import face_teacher from "../../asset/Img/defaultpic_teacher.png";
import UiPeople_Style from "../../uiComponents/UiPeople/UiPeople_Style";
import UiMessage_Style from "../../uiComponents/UiMessage/UiMessage_Style";
import UiLoading from "../../uiComponents/UiLoading/UiLoading";
import { useTransferQuestion } from "../../api/question";

var fontStyle = {
  fontFamily: "Noto Sans",
  fontStyle: "normal",
};

const Modal = ({ setIsModal, isModal, userInfo, qnaData }) => {
  let params = useParams();
  let navigate = useNavigate();

  var [isLoading, setIsLoading] = useState(true);

  var [oriList, setOriList] = useState([]);
  var [list, setList] = useState([]);
  var [search, setSearch] = useState("");

  // 이관 리스트
  const apiPro_transfer_teacher = async (d, inSearch) => {
    try {
      let state = {};
      if (inSearch || search) {
        state["A.name"] = `= '${inSearch || search}'`;
      }
      if (inSearch == "") {
        // state["HIQDB_teacher.name" ] = ""
      }
      let t_data = await apiFn.comApi({
        state: {
          table: "user_credential",
          page: 1,
          sort: "name desc",
          amount: 9999,
          ref: [
            "HIQDB_book_link.bid|HIQDB_book_link.mid=HIQDB_user_credential.name",
            "HIQDB_book_link.type|HIQDB_book_link.mid=HIQDB_user_credential.name",
            "HIQDB_book_link.status as bstatus|HIQDB_book_link.mid=HIQDB_user_credential.name",
            "A.* |HIQDB_user A on A.index=HIQDB_user_credential.user_id and A.user_type = 2",
          ],
          where: {
            "HIQDB_book_link.bid": `='${d ? d : qnaData["bid"]}'`,
            "HIQDB_book_link.status": `!='9'`,
            "HIQDB_book_link.type": `!='0'`,
            ...state,
          },
        },
      });

      let pre_array = [];
      let array = [];
      t_data["list"].map((v, i) => {
        if (v["id"] == userInfo["id"]) return;
        if (v["bstatus"] == 5) {
          pre_array.push(v);
        } else if (v.bstatus === 1) {
          array.push(v);
        }
      });

      setList([...array]);
      setOriList([...array]);

      console.log(" array : : : : ", array);
    } catch (e) {
      console.log(e);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    // console.log("MODAL qnaData : ", qnaData)
    apiPro_transfer_teacher();
  }, []);

  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        zIndex: 130002,
        backgroundColor: "rgba(150, 150, 150,0.5)",
        // backgroundColor:"red",
        width: "100%",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
      onClick={() => {
        setIsModal(false);
      }}
    >
      <div
        style={{
          width: "75%",
          minHeight: "55%",
          maxHeight: "60%",
          backgroundColor: "white",
          padding: "15px 20px 30px 20px",
          borderRadius: 10,
          border: "1px solid rgb(200, 200, 200)",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          filter: "drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.37))",
        }}
        onClick={(e) => {
          console.log("###");
          e.stopPropagation();
          e.preventDefault();
        }}
      >
        <div>
          <div
            style={{
              fontWeight: 500,
            }}
          >
            질문 이관
          </div>
          <div
            style={{
              fontSize: "12px",
            }}
          >
            (질문을 넘겨드릴 선생님을 선택해주세요.)
          </div>
        </div>
        {isLoading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flex: 1,
            }}
          >
            <UiLoading
              isTransparent={true}
              isFull={false}
              text="이관 가능한 선생님을 목록을 찾고 있어요."
            />
          </div>
        ) : list.length == 0 ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flex: 1,
              fontWeight: 600,
              color: style.common.t_color,
            }}
          >
            이관 가능한 선생님이 없어요.
          </div>
        ) : (
          <div
            style={{
              paddingTop: "5px",
              overflowY: "scroll",
              display: "flex",
              flexDirection: "column",
              flex: 1,
            }}
          >
            {list.map((v) => (
              <Member params={params} data={v} id={v["id"]} userInfo={userInfo} />
            ))}
          </div>
        )}
        {/*
                <div style={{
                    height:"40px",
                    display:"flex",
                    justifyContent:"center",
                }}>
                    <UiBtn
                        btnStyle={{
                            width:"90px",
                            height:"36px",
                            backgroundColor:style.common.t_color,
                            color : "white",
                            fontStyle:"12px",
                        }}
                        title={"닫기"}
                        fnClick={()=>{
                            setIsModal(false)
                        }}
                    />
                </div> */}
      </div>
    </div>
  );
};

const Member = ({ id, isChk, selectData, isTeacher = true, fnCheck, data, params, userInfo }) => {
  let navigate = useNavigate();

  const apiPro_transfer = async (d) => {
    try {
      // 1.이관 시키기 (현재 질문의 tid data의 id로 변경)

      await useTransferQuestion(params.qid, id);

      // 2.현재 질문의 이관 전 tid 관련 값 모두 삭제
      // 2-1.app_msg table 정보 삭제
      // ******* 보류 2-1
      await apiFn.comApi({
        state: {
          table: "app_msg",
          delAll: "Y",
          del: [
            {
              "HIQDB_app_msg.qid": params["qid"],
              "HIQDB_app_msg.tid": userInfo["id"],
            },
          ],
        },
      });

      // 2-2.online_qna_log 이관 전 tid 를 이관 후 tid로 교체
      // online_qna_log primarykey로 인해 삭제 먼저 진행
      await apiFn.comApi({
        state: {
          table: "online_qna_log",
          // delAll : "Y",
          del: [
            {
              "HIQDB_online_qna_log.wid": userInfo["id"],
              "HIQDB_online_qna_log.qid": params["qid"],
            },
          ],
        },
      });

      // 2-2-2 online_qna_log primarykey로 인해 삭제 먼저 진행 후 이관 tid 등록

      await apiFn.qnaInvite({
        state: {
          wtype: 2,
          wid: id,
          qid: params["qid"],
          ctype: 6,
          content: `${d["name"]}`,
        },
      });

      // await apiFn.comApi({
      //     state : {
      //         table : "online_qna_log",
      //         ins : [{
      //             wid : id,
      //             qid : params["qid"],
      //             ltype : 1,
      //             reg_date : 0,
      //         }]
      //     }
      // })

      navigate(`/message/${params["who"]}/home`);
    } catch (e) {
      console.log("************");
      console.log(e);
      console.log("************");
    }
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        flex: 1,
        maxWidth: style.common.maxWidth,
        // padding : "0px 10px",
      }}
    >
      <div
        style={{
          display: "flex",
          // margin : "5px 0px",
          padding: "7px 0px",
          width: "100%",
          alignItems: "center",
          // borderBottom : "1px solid #f6f6f6"
        }}
      >
        {/* {
                (params["who"] == "student" || params["tid"] != userInfo["id"]) ? <></>
                :
                isChk &&
                data["id"] != userInfo["id"] &&
                <div style={{
                    display : "flex",
                    justifyContent : 'center',
                    alignItems : "center",
                    marginRight : "10px",
                }}>
                    <UiCheckBox
                        className={isTeacher ? "t" : "s"}
                        // value={"?"}
                        // className={"message_?"}
                        checked={selectData[id] == "Y" ? true : false}
                        value={id}
                        fnClick={fnCheck}
                        containerStyle={{
                            padding : '0px'
                        }}
                    />
                </div>
                } */}
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
          onClick={() => {
            if (data["id"] == userInfo["id"]) return;
            console.log("DATA : ", data);
            let isOk = window.confirm(
              `${data["name"]}(${data["nick"]}) 선생님께 질문방을 이관하시겠어요?`
            );
            if (!isOk) return;
            apiPro_transfer(data);
            // if(params["where"] == "초대transfer"){
            // }
            // else{
            //     // navigate(`/myinfo/${params["who"]}/${data["id"]}`)
            // }
          }}
        >
          <div
            style={{
              // width : '20%',
              display: "flex",
              alignItems: "center",
              marginRight: "10px",
            }}
            onClick={() => {}}
          >
            <div
              style={{
                ...UiPeople_Style.section_i_w,
                width: "40px",
                height: "40px",
              }}
            >
              <img
                style={UiMessage_Style.section_i}
                onError={(e) => {
                  // setIsLoad(false)
                  e.currentTarget.src = data?.user_type === 2 ? face_teacher : face_student;
                }}
                src={
                  data
                    ? data["img"]
                      ? // constants.apiUrl
                        constants.s3_url +
                        // '/public'
                        // +
                        // 0729 img cache 관련 코드
                        `${data?.user_type === 2 ? "/teacher/" : "/student/"}${data["img"]}` +
                        // `${(data?.user_type === 2 ) ? "/teacher/" : "/student/"}${data["img"].normalize('NFD')}`
                        ".jpg?"
                      : // +
                        // util.getRandomInt(1000,9999)
                        constants.apiUrl +
                        // '/public'
                        // +
                        `${data?.user_type === 2 ? "/teacher/" : "/student/"}${data["id"].normalize("NFD")}` +
                        ".jpg?" +
                        util.getRandomInt(1000, 9999)
                    : data?.user_type === 2
                      ? face_teacher
                      : face_student
                }
              />
            </div>
          </div>
          <div
            style={{
              display: "flex",

              alignItems: "flex-end",
              // alignItems:"center",
              // width : '100%',
              // height : "100%",
            }}
            onClick={() => {}}
          >
            <div style={{ ...UiPeople_Style.section_r_t_t_text_2, fontWeight: 500 }}>
              {`${data["name"] ? data["name"] : "이름없음"} (${data["nick"] ? data["nick"] : ""})`}
            </div>
            <div
              style={{
                fontWeight: 300,
                fontSize: "12px",
              }}
            >
              선생님
            </div>
          </div>
        </div>
      </div>
      {}
    </div>
  );
};

export default Modal;
