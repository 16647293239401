import { PdfType, usePdfList } from "api/pdfView";
import { ReactComponent as GoFar } from "asset/images/icon/go-start.svg";
import { ReactComponent as ArrowIcon } from "asset/images/icon/left.svg";
import { FontStyleMap } from "components/atoms/Font";
import Section from "components/atoms/Section";
import { StudyBookContext } from "pages/StudyBookDetail/context/StudyBookContextProvider";
import React, { ChangeEvent, useCallback, useContext, useMemo } from "react";
import { useParams } from "react-router";
import styled, { css } from "styled-components";
import SwitchAnswer from "./SwitchAnswer";

const PageNavigater = () => {
  const { bookId } = useParams();
  const { data } = usePdfList({ bookId, isAnswer: PdfType.question });
  const { data: answerData } = usePdfList({ bookId, isAnswer: PdfType.answer });
  const { questionSlideIndex, setQuestionSlideIndex } = useContext(StudyBookContext);

  const pdfTotalPage = useMemo(() => data?.qnaList.length ?? 0, [data]);

  const handlePageChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target;

      const slideNumber = parseInt(value);
      if (isNaN(slideNumber)) {
        setQuestionSlideIndex("");
        return;
      }
      if (slideNumber > pdfTotalPage) {
        setQuestionSlideIndex(pdfTotalPage);
      } else {
        setQuestionSlideIndex(slideNumber);
      }
    },
    [data, questionSlideIndex, setQuestionSlideIndex, pdfTotalPage]
  );

  const handleGoPrev = useCallback(() => {
    setQuestionSlideIndex((prev) => {
      if (prev === "") {
        return prev;
      } else {
        if (prev === 1) return prev;
        return prev - 1;
      }
    });
  }, []);

  const handleGoNext = useCallback(() => {
    setQuestionSlideIndex((prev) => {
      if (prev === "") {
        return prev;
      } else {
        if (prev === pdfTotalPage) return prev;
        return prev + 1;
      }
    });
  }, []);

  const handleGoStart = useCallback(() => {
    setQuestionSlideIndex(1);
  }, []);

  const handleGoLast = useCallback(() => {
    setQuestionSlideIndex(pdfTotalPage);
  }, []);

  const isFirstDisabled = useMemo(
    () => questionSlideIndex === 1 || questionSlideIndex === "",
    [questionSlideIndex]
  );

  const isLastDisabled = useMemo(
    () => questionSlideIndex === pdfTotalPage || questionSlideIndex === "",
    [questionSlideIndex, pdfTotalPage]
  );

  return (
    <AlignCenter>
      <Container>
        <LeftSection>
          <GoStartIconContainer onClick={handleGoStart} disabled={isFirstDisabled}>
            <GoFar />
          </GoStartIconContainer>
          <LeftIconContainer onClick={handleGoPrev} disabled={isFirstDisabled}>
            <ArrowIcon />
          </LeftIconContainer>
          <Section align="center" gap={8}>
            <PageInput type="number" onChange={handlePageChange} value={questionSlideIndex} />
            <PageFont>/{pdfTotalPage}</PageFont>
          </Section>
          <RightIconContainer onClick={handleGoNext} disabled={isLastDisabled}>
            <ArrowIcon />
          </RightIconContainer>
          <GoEndIconContainer onClick={handleGoLast} disabled={isLastDisabled}>
            <GoFar />
          </GoEndIconContainer>
        </LeftSection>
        <RightSection>
          <span>해설보기</span>
          <SwitchAnswer />
        </RightSection>
      </Container>
    </AlignCenter>
  );
};
const AlignCenter = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Container = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: var(--neutral-color-natural-70, var(--Default-Gray-90, #262626));
  ${FontStyleMap["KR/regular/$KR.CAP.REG.12"]}
  max-width: 768px;
`;

const LeftSection = styled.div<{ disabled?: boolean }>`
  display: inherit;
  align-items: inherit;
  gap: 4px;
`;

const RightSection = styled(LeftSection)`
  gap: 8px;
`;

const IconContainer = styled.a<{ disabled?: boolean }>`
  ${({ disabled }) =>
    disabled &&
    css`
      pointer-events: none;
    `}
  display: inline-flex;
  > svg {
    height: 16px;
    width: 16px;
  }
`;

const LeftIconContainer = styled(IconContainer)<{ disabled?: boolean }>`
  > svg {
    stroke: ${({ disabled }) =>
      disabled ? "var(--neutral-color-natural-40)" : "var(--neutral-color-natural-70)"};
  }
`;

const RightIconContainer = styled(LeftIconContainer)`
  transform: rotate(180deg);
`;

const GoStartIconContainer = styled(IconContainer)<{ disabled?: boolean }>`
  > svg {
    fill: ${({ disabled }) =>
      disabled ? "var(--neutral-color-natural-40)" : "var(--neutral-color-natural-70)"};
  }
`;

const GoEndIconContainer = styled(GoStartIconContainer)`
  transform: rotate(180deg);
`;

const PageInput = styled.input`
  display: flex;
  width: 56px;
  padding: 8px 4px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  text-align: center;
  flex-shrink: 0;
  border: unset;
  border-radius: 10px;
  background: var(--black-white-color-white, #fff);
  :focus-visible {
    outline: var(--primary) auto 1px;
  }
`;

const PageFont = styled.div``;
export default PageNavigater;
