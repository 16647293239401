import HighlightOffTwoToneIcon from "@mui/icons-material/HighlightOffTwoTone";
import { ClassRegistRequset } from "api/classRegist";
import Input from "components/atoms/Input";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useFormContext } from "react-hook-form";
import ReactPlayer from "react-player";
import { OnProgressProps } from "react-player/base";
import styled from "styled-components";
import VideoTimeArea from "./VideoTimeArea";
interface IClassVideoLinkAreaProps {
  onCancel: () => void;
}
const ClassVideoLinkArea = ({ onCancel }: IClassVideoLinkAreaProps) => {
  const {
    watch,
    setValue,
    control,
    formState: { errors },
    register,
    trigger,
    unregister,
  } = useFormContext<ClassRegistRequset>();

  const handleCancel = useCallback(() => {
    onCancel();
  }, []);

  const [durationTime, setDurationTime] = useState<number>(0);
  const video_link = watch("video_link");
  const [link, setLink] = useState<string>(video_link);

  const videoRef = useRef<ReactPlayer>(null);
  useEffect(
    () => () => {
      setValue("video_link", null);
      setValue("loadVideoUrl", null);
      unregister(["video_link", "loadVideoUrl"]);
    },
    []
  );

  const startTime = watch("video_start");
  const endTime = watch("video_end");
  const handleVideoProgress = useCallback(
    (progress: OnProgressProps) => {
      const { playedSeconds } = progress;

      if (playedSeconds > endTime || playedSeconds < startTime) {
        videoRef.current.seekTo(startTime);
      }
    },
    [startTime, endTime, videoRef.current]
  );

  return (
    <div>
      <LinkInput
        InputProps={{
          endAdornment: (
            <CancelButton onClick={handleCancel} type="button">
              <HighlightOffTwoToneIcon />
            </CancelButton>
          ),
        }}
        defaultValue={video_link}
        onChange={(e) => {
          setLink(e.target.value);
        }}
      />
      {link && (
        <ReactPlayer
          onProgress={handleVideoProgress}
          ref={videoRef}
          controls
          pip={false}
          url={link}
          width={"100%"}
          onReady={(player) => {
            setValue("video_link", link);
            setValue("loadVideoUrl", link);
            setValue("video_end", player.getDuration());
            setDurationTime(player.getDuration());
            trigger("loadVideoUrl");
          }}
          onError={(e) => {
            setValue("video_link", null);
            setValue("loadVideoUrl", null);
            setValue("video_end", 0);
            setDurationTime(0);
          }}
        />
      )}
      <VideoTimeArea durationTime={durationTime} />
    </div>
  );
};

const CancelButton = styled.button`
  outline: none;
  background-color: transparent;
  border: 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  color: #242424;
  svg {
    color: #807e8a;
    font-size: 20px;
  }
`;

const LinkInput = styled(Input)`
  width: 100%;
  margin: 34px 0 16px !important;
`;

export default ClassVideoLinkArea;
