import DEEMER from "asset/Img/deemer.png";
import find_picture from "asset/Img/find_picture.png";
import constants from "asset/constants";
import useClassVideoUploadStatus from "hooks/useClassVideoUploadStatus";
import React, { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import UiLoading from "uiComponents/UiLoading/UiLoading";

const Thumbnail = ({ who, data, isDeemer = true }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [thumbnail, setThumbnail] = useState("");
  const [isExistThumbnail, setIsExistThumbnail] = useState(false);
  // const { data: videoData, refetch } = useClassDetailInfoWithoutSuspense({
  //   boardId: data.id,
  // });
  const youtubeId = (content) => {
    // console.log("content : ",content);
    if (!content) return;
    var tag = "";
    if (content) {
      var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#\&\?]*).*/;
      var matchs = content.match(regExp);
      if (matchs) {
        tag = matchs[7];
      }
      return tag;
    }
  };

  useEffect(() => {
    const getThumbnail = async () => {
      if (data?.video_link?.includes("youtu")) {
        setThumbnail(`https://img.youtube.com/vi/${youtubeId(data?.video_link)}/0.jpg`);
      } else if (data?.video_link?.startsWith("https://vimeo")) {
        setThumbnail(
          (await (await fetch(`https://vimeo.com/api/oembed.json?url=` + data?.video_link)).json())[
            "thumbnail_url"
          ]
        );
      } else {
        const thumbnailUrl = `${constants.s3_url}/board/${data["id"]}.jpg`;
        setThumbnail(thumbnailUrl);
      }
    };

    getThumbnail();
  }, [data]);

  const status = useClassVideoUploadStatus(data.id);

  const uploadProgress = useMemo(() => status?.progress ?? 100, [status?.progress]);
  return (
    <>
      <Container
        onClick={() => {
          if (data.video_end || data.video_link) {
            // navigate(`/columndetail/${who}/${data.id}/lecture_video`)
            navigate(`/classdetail/${who}/${data.id}/lecture_video`);
          }
        }}
      >
        <>
          <ThumbnailImg
            src={`${thumbnail}#t=${new Date().getTime()}`}
            onError={(e) => {
              setThumbnail(find_picture);
            }}
          />
          <Deemer src={DEEMER} />

          {uploadProgress !== 100 && (
            <ProgressContainer>
              <UiLoading
                isTransparent={true}
                isFull={false}
                text={`영상이 업로드 중입니다. (${uploadProgress ?? 0}%)
새로고침을 하면 업로드가 취소됩니다.`}
              />
            </ProgressContainer>
          )}
        </>
      </Container>
    </>
  );
};

const Container = styled.div`
  cursor: pointer;
  display: flex;
  background-color: transparent;
  border: none;
  width: 100%;
  overflow-y: hidden;
  position: relative;
`;

const ThumbnailImg = styled.img`
  width: 100%;
  height: auto;
  object-fit: cover;
  background-color: black;
  aspect-ratio: 322/204;
`;

const ProgressContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  position: absolute;
  justify-content: center;
  align-items: center;
  left: 0;
  top: 0;
  background-color: rgba(255, 255, 255, 0.5);
`;

const Deemer = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 1;
`;
export default Thumbnail;
