import React from 'react'

import styles from './style.module.scss'
import {ArrowRight} from "@material-ui/icons";

const Menu = ({children, onClick, postfix})=>{
    return <button className={styles.MenuContainer} onClick={onClick}>
        <div className={styles.ContentSection}>
            {children}
        </div>
        {postfix ?? <ArrowRight/>}
    </button>
}

export default Menu
