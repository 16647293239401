import CommonModal, { CommonModalContainer } from "components/atoms/CommonModal";
import ReactiveButtonContainer, { ButtonConfig } from "components/atoms/ReactiveButtonContainer";
import React, { ReactNode, useMemo } from "react";
import styled from "styled-components";
import { FontStyleMap } from "../../atoms/Font";
export type ConfirmProps = {
  title: ReactNode;
  message: ReactNode;
  type?: "alert" | "confirm";
  onConfirm?: () => void;
  onClose?: () => void;
  icon?: ReactNode;
};

const Confirm = ({ onClose, message, onConfirm, title, type, icon }: ConfirmProps) => {
  const buttonList = useMemo(() => {
    const list: ButtonConfig[] = [
      {
        onClick: () => {
          onConfirm?.();
          onClose();
        },
        variant: "contained",
        title: "확인",
      },
    ];
    if (type === "confirm") {
      list.unshift({
        onClick: () => {
          onClose();
        },
        variant: "text",
        title: "취소",
      });
    }
    return list;
  }, []);

  return (
    <CommonModal open={true} onClose={onClose}>
      <StyledCommonConfirmContainer>
        {icon}
        <FontContainer>
          <TitleFont>{title}</TitleFont>
          <DescriptionFont>{message}</DescriptionFont>
        </FontContainer>
        <ReactiveButtonContainer buttonList={buttonList} />
      </StyledCommonConfirmContainer>
    </CommonModal>
  );
};

export const StyledCommonConfirmContainer = styled(CommonModalContainer)`
  width: 400px;
  @media screen and (max-width: 450px) {
    width: 330px;
  }
`;

const FontContainer = styled.div`
  margin: 16px 0 52px;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const TitleFont = styled.div`
  color: var(--neutral-color-natural-80, #141414);
  ${FontStyleMap["KR/bold/$KR.TS.BOL.20"]}
`;

const DescriptionFont = styled.div`
  color: var(--neutral-color-natural-60, #434343);
  ${FontStyleMap["KR/regular/$KR.BM.REG.14"]}
`;
export default Confirm;
