import { React, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import style from "../../asset/style";
import { UiCheckBox } from "../../uiComponents/UiCheckBox/UiCheckBox";
import { UiSearchSection_form2 } from "../../uiComponents/UiSearchList/UiSearchList";
import Profile_class_Style from "../Profile/Profile_class_Style";
import { useLongPress } from "use-long-press";
import UiTextBtn from "../../uiComponents/UiTextBtn/UiTextBtn";
import Academy_book_Style from "../Academy/Academy_book_Style";
import UiTopPopTextBtn from "../../uiComponents/UiTopPopTextBtn/UiTopPopTextBtn";
import constants from "../../asset/constants";
import apiFn from "../../asset/apiClass";

const Book_share = ({
  isTeacher,
  linkData,
  setHiddenNav,
  userInfo,
  setIsShare,
  isShare,
  setHiddenUrl,
}) => {
  let params = useParams();
  let navigate = useNavigate();

  const [isEdit, setIsEdit] = useState(false);

  var [selectData, setSelectData] = useState({});

  var [shareData, setShareData] = useState([]);

  const apiPro_read = async (sort) => {
    try {
      let data = await apiFn.comApi({
        state: {
          table: "class",
          page: 1,
          amount: 9999,
          sort: sort ? sort : "reg_date desc",
          ref: [
            "HIQDB_book_link.cid|HIQDB_book_link.cid=HIQDB_class.id",
            "HIQDB_book_link.bid|HIQDB_book_link.cid=HIQDB_class.id",
            `B.name as academyName|HIQDB_class B on B.id=HIQDB_class.pid`,
          ],
          where: {
            "HIQDB_book_link.bid": `= '${params["id"]}'`,
          },
          count: [
            "HIQDB_member_class.mid:cid:id|and countTable.type ='0' and countTable.status='1'|lcnt",
          ],
        },
      });
      console.log("apiPro_read => ", data);
      setShareData([...data["list"]]);
    } catch (e) {}
  };

  const apiPro_del = async () => {
    try {
      let array = Object.keys(selectData).reduce((a, v, i) => {
        return [
          ...a,
          {
            bid: params["id"],
            cid: v,
          },
        ];
      }, []);
      let isOk = await apiFn.comApi({
        state: {
          table: "book_link",
          del: array,
        },
      });
      setIsEdit(false);
      setSelectData({});
      await apiPro_read();
    } catch (e) {
      // console.log(e)
    }
  };

  useEffect(() => {
    if (!isShare) {
      apiPro_read();
    }
  }, [isShare]);

  useEffect(() => {
    // console.log("selectData => ",selectData);
  }, [selectData]);

  return (
    <div
      style={{
        ...Profile_class_Style.c,
        padding: "0px 20px",
        backgroundColor: "white",
      }}
    >
      {isEdit && (
        <UiTopPopTextBtn
          title="편집 취소"
          isTeacher={isTeacher}
          fnClick={() => {
            setIsEdit(false);
            setSelectData({});
          }}
        />
      )}
      {isEdit ? (
        <div
          style={{
            ...Academy_book_Style.edit_section_w,
            width: "auto",
            padding: "15px 0px 10px 10px",
            borderTop: isEdit ? "none" : "1px solid",
            borderColor: style.common.borderColor,
          }}
        >
          선택된 수업에서
          <div style={Academy_book_Style.textBtn_w}>
            {isTeacher && (
              <UiTextBtn
                text={"공유 해제"}
                btnStyle={Academy_book_Style.textBtn}
                fnClick={() => {
                  let isOk = window.confirm(constants.confirm.cf_10);
                  if (isOk) {
                    apiPro_del();
                  }
                }}
              />
            )}
          </div>
        </div>
      ) : (
        <>
          {
            linkData["mid"] && linkData["status"] == 1 && (
              <div
                style={{
                  width: "100%",
                  // paddingRight:"30px",
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <div
                  style={{
                    backgroundColor: "#ACD0CB",
                    color: "#FFFFFF",
                    fontSize: "14px",
                    fontWeight: 400,
                    padding: "2px 10px 3px 10px",
                    width: "112px",
                    height: "20px",
                    borderRadius: "15px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  onClick={() => {
                    setIsShare(true);
                  }}
                >
                  + 수업 추가하기
                </div>
              </div>
            )
            // <div style={{
            //     ...Lecture_book_Style.t_btn_w,
            //     width : "160px",
            // }}>
            //     <UiBtn
            //         btnStyle={Lecture_book_Style.t_btn}
            //         title={
            //             <div style={Academy_book_Style.btn_title}>
            //                 <Add style={Academy_book_Style.btn_Add} />
            //                 <div style={{
            //                     paddingLeft : "12px"
            //                 }}>
            //                     공유할 수업 추가
            //                 </div>
            //             </div>
            //             // <div style={Lecture_book_Style.t_btn_text}>
            //             //     <Add
            //             //         style={Lecture_book_Style.t_btn_Add}
            //             //     />
            //             //     공유할 수업 추가
            //             // </div>
            //         }
            //         fnClick={()=>{
            //             setHiddenNav("share")
            //             navigate(`/booksharelist/${params["who"]}/${params["id"]}/de`)
            //         }}
            //     />
            // </div>
          }
          {/* { 
           shareData.length > 0 &&
           <div style={{
                padding : "0px 10px",
            }}>
                <UiSorting 
                    title={`${shareData.length}개 수업 공유중`}
                    data={[
                        { name : "이름순" , value : "academyName asc"},
                        { name : "클래스룸순" , value : "reg_date desc"},
                    ]}
                    isTeacher={isTeacher}
                    fnClick={(d)=>{
                        // // console.log(d)
                        apiPro_read(d)
                    }}
                />
            </div>
            } */}
        </>
      )}
      <div
        style={{
          width: "100%",
          marginTop: "10px",
        }}
      >
        {shareData.map((v, i) => (
          <div
            style={{
              padding: "15px 10px",
              borderRadius: "5px",
              border: "1px solid #F6F6F6",
              fontSize: "16px",
              fontWeight: 400,
              marginBottom: "2px",
            }}
            onClick={(d) => {
              try {
                setHiddenUrl(window.location.pathname);
                setHiddenNav("share&");
                navigate("/lecture/detail/" + params["who"] + "/" + v["cid"] + "/de");
              } catch (e) {
                console.log("************");
                console.log(e);
                console.log("************");
              }
            }}
          >
            <div
              style={{
                display: "flex",
              }}
            >
              {v["name"]}
              <div
                style={{
                  marginLeft: "3px",
                  color: "#B0B0B0",
                }}
              >
                {`${v["owner"] ? v["owner"] + " 선생님" : ""} `}
                {v["teachers"] &&
                  " | " + v["teachers"].length > 1 &&
                  `외 ${v["teachers"].length - 1}명`}
                {/* {
                                data["teachers"] &&
                                (data["teachers"].length > 1 && `외 ${data["teachers"].length - 1 }명`)
                                } */}
              </div>
            </div>
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "flex-end",
              }}
            >
              <div
                style={{
                  color: "#B0B0B0",
                  fontSize: "12px",
                }}
              >
                {v["academyName"]}
              </div>
            </div>
          </div>
        ))}
      </div>
      {/* <div style={{
                display : 'flex',
                flex : 1,
                flexDirection : "column",
                height : "100%",
                minHeight : "100vh",
                backgroundColor : style.common.borderColor2,
            }}>
                <div style={{
                    padding :'0px 10px'
                }}>
                    {
                    shareData.map((v,i)=>
                        <ShareSection_chk
                            data={v} 
                            id={v}
                            isTeacher={isTeacher}
                            isEdit={isEdit}
                            fnLongClick={(data)=>{
                                setIsEdit(true);
                                setSelectData({
                                    ...selectData,
                                    [data["id"]] : "Y"
                                })
                            }}
                            fnClick={(d)=>{
                                navigate("/lecture/detail/"+params["who"]+"/"+d["cid"]+"/de")
                            }}
                            
                            fnCheck={(e)=>{
                                if(selectData[e.target.id] == "Y"){
                                    delete selectData[e.target.id]
                                    setSelectData({
                                        ...selectData,
                                    })
                                }
                                else {
                                    setSelectData({
                                        ...selectData,
                                        [e.target.id] : "Y"
                                    })
                                }
                            }}
                            selectData={selectData}
                        />
                    )}
                </div>

            </div> */}
    </div>
  );
};

const ShareSection_chk = ({
  data,
  id,
  isTeacher,
  isEdit,
  selectData,
  fnLongClick,
  fnCheck,
  fnClick,
}) => {
  const bind = useLongPress(
    () => {
      try {
        if (isTeacher) {
          fnLongClick(data);
        }
      } catch (e) {
        // console.log("Long Press Error => ",e)
      }
    },
    {
      threshold: 1000,
    }
  );

  return (
    <div
      style={{
        display: "flex",
      }}
      {...bind()}
      onClick={() => {
        if (isEdit) return;
        fnClick(data);
      }}
    >
      {isEdit && (
        <UiCheckBox
          fnClick={fnCheck}
          containerStyle={{
            marginRight: "10px",
          }}
          checked={selectData[data["id"]] == "Y" ? true : false}
          className={isTeacher ? "t" : "s"}
          value={data["id"]}
        />
      )}
      <UiSearchSection_form2 data={data} isTeacher={isTeacher} />
    </div>
  );
};

export default Book_share;
