import { useQuery } from "@tanstack/react-query";
import constants from "asset/constants";
import { getUser } from "asset/storage";
import { useInfiniteScroll } from "hooks/useInfiniteScroll";

export interface ClassSearchResponse {
  id: number;
  pid: number;
  type: number;
  status: number;
  reg_date: number;
  name: string;
  intro: string;
  address: string;
  reg_id: string;
  HIQDB_member_classes: [
    {
      cid: number;
      type: number;
      member_count: number;
      status: number;
      mid_HIQDB_user_credential: {
        name: string;
        user_id: number;
        user: {
          name: string;
          nick: string;
          id: string;
        };
      };
    },
  ];
  student_count: number;
  teacher_count: number;
  class_name: string;
  manager_name: string;
  classroom_name: string;
}

export interface SearchClassListResponse {
  totalPage: number;
  currentPage: number;
  signupClassList: SearchClassListItem[];
}

export interface SearchClassListItem {
  name: string;
  address: string;
  id: number;
  pid: number;
  intro: string;
  pid_HIQDB_class: {
    id: number;
    name: string;
  };
}

export const useSearchClassList = (className: string) => {
  const userInfo = getUser();

  const academyId = constants.classroomId;
  const queryString = {
    page: 1,
  };
  if (academyId) {
    queryString["academyId"] = academyId;
  }
  return useInfiniteScroll<SearchClassListResponse>({
    queryKey: [`lecture/search/${className}/user/${userInfo.id}`, queryString],
    options: {
      enabled: !!className,
      suspense: false,
    },
  });
};

interface ClassBelongListResponse {
  mid: string;
  class_name: string;
  intro: string;
  address: string;
  img: string;
  manager_name: string;
  classroom_name: string;
  cid: number;
  type: number;
  status: number;
  reg_date: number;
  pid: number;
  class_reg_date: number;
  student_count: number;
  teacher_count: number;
  bookId: string;
}

export const useBelongClassList = () => {
  const userInfo = getUser();
  const classroomIdParam = constants.classroomId ? { classroomId: constants.classroomId } : {};
  return useQuery<ClassBelongListResponse[]>([
    `/user/${userInfo.id}/class`,
    { classType: "1", isClassroomRequired: true, ...classroomIdParam },
  ]);
};
