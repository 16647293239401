import style from "../../asset/style"

const Frame_Style = {
    c : {
        display:"flex",
        width:"100%",
        justifyContent:"center",
        // flexDirection:"column",
        maxWidth : style.common.maxWidth,
        maxHeight : "100vh",

    },
    t_c : {
        // padding : style.common.padding,
        // padding : "12px 15px 12px 15px",
        padding : "12px 15px 12px 0px",
        marginLeft : "15px",
        // marginBottom : "15px",
        display:"flex",
        justifyContent:"space-between",
        alignItems:"center",
        width:"100%",
        
        // height:"40px",
        // backgroundColor:"yellow"
        // backgroundColor:"white",
    },
    t_l_w : {
        display:"flex",
        // fontSize : "8sp",
        fontSize : "21px",
        fontWeight : 600,
        color : style.common.fontColor_1,
        // fontWeight : "bold",
        alignItems:"center",
        zIndex : 99,
    },
    children_c : {
        display:'flex',
        width:"100%",
        // marginTop : "70px",
        // height : "calc(100vh - 113.5px)",
        overflow:"scroll",
    },
    icon_c : {
        display:"flex",
        justifyContent:"center",
        flexGrow : 1,
    },
}

export default Frame_Style