import { useStudyBookListInCurrentClass, useStudyBookListInFolder } from "api/studyBook";
import { FontStyleMap } from "components/atoms/Font";
import StudyBookItem from "pages/StudyBook/components/StudyBookItem";
import { StudyBookContext } from "pages/StudyBook/context/StudyBookContext";
import React, { useCallback, useContext, useMemo, useState } from "react";
import { useParams } from "react-router";
import styled from "styled-components";
import { useLongPress } from "use-long-press";

const StudyBookList = () => {
  const [longPressHandle, setLongPressHandle] = useState(false);
  const { setIsConfigMode } = useContext(StudyBookContext);

  const handleLongPress = useCallback(() => {
    setLongPressHandle((prev) => !prev);
    setIsConfigMode(true);
  }, [longPressHandle, setIsConfigMode]);
  const longPress = useLongPress(handleLongPress, {});

  const { folderId } = useParams();
  const { data: folderData } = useStudyBookListInFolder(folderId);
  const { data: bookData } = useStudyBookListInCurrentClass(folderId);

  const list = useMemo(() => {
    if (folderId === "0") {
      return bookData.bookList;
    } else if (folderData) {
      return folderData.folderData.HIQDB_book_links;
    } else {
      return [];
    }
  }, [folderData, bookData, folderId]);

  return (
    <>
      <CountFont>총 {list.length}개의 교재</CountFont>
      <Container {...longPress()}>
        {list.map((item, index) => {
          return (
            <StudyBookItem key={item.id} {...item} index={index} isConfigable={folderId !== "0"} />
          );
        })}
      </Container>
    </>
  );
};

const Container = styled.div`
  margin-top: 16px;
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
  align-items: center;
  justify-content: center;
  overflow-y: auto;
  padding-bottom: 18px;
`;

const CountFont = styled.div`
  margin-top: 32px;
  color: var(--neutral-color-natural-70, var(--Default-Gray-90, #262626));
  ${FontStyleMap["KR/medium/$KR.BtnM.MD.14"]}
`;

export default StudyBookList;
