import {Add} from '@material-ui/icons'
import {React, useState, useEffect} from 'react'
import {useNavigate, useParams} from 'react-router'
import apiFn from '../../asset/apiClass'
import style from '../../asset/style'
import UiBtn from '../../uiComponents/UiBtn/UiBtn'
import {UiSearchList} from '../../uiComponents/UiSearchList/UiSearchList'
import UiSorting from '../../uiComponents/UiSorting/UiSorting'
import Academy_book_Style from '../Academy/Academy_book_Style'
import Profile_teacher_Style from './Profile_teacher_Style'
import constants from "../../asset/constants";
import util from '../../asset/util'
import API from "../../api";

const Profile_lecture = ({
                             isTeacher,
                             userInfo,
                             academyData,
                             setHiddenUrl,
                             setHiddenNav,
                             hiddenNav,
                             scrollKey,
                             setScrolllKey,
                         }) => {
    let params = useParams();
    let navigate = useNavigate();
    let sort = [
        {name: "이름순", value: "name"},
        {name: "개설순", value: "reg_date"},
        {name: "수강인원순", value: "member"},
    ]

    var [lectureData, setLectureData] = useState([]);


    var [isMy, setIsMy] = useState(true)

    const apiPro_read = async () => {
        const classList = await API.getClassList(`classType=1&isClassroomRequired=true&classroomId=${params.id}`)
        setLectureData(classList)
    }
    const handleSortClassList = (sort) => {
        setLectureData(prev => {
            let sortData = [...prev];
            if (sort === 'name') sortData = sortData.sort((a, b) => a.class_name.localeCompare(b.class_name));
            else if (sort === 'member') sortData = sortData.sort((a, b) => b.student_count - a.student_count);
            else if (sort === 'reg_date') sortData = sortData.sort((a, b) => b.reg_date - a.reg_date);
            return sortData
        })
    }
    const apiPro_read_2 = async (id) => {
        const userClassList = await API.getUserClassList(userInfo.id,`classType=1&isClassroomRequired=true&classroomId=${params.id}`)
        setLectureData(userClassList)
    }

    useEffect(() => {
        if (isMy) {
            apiPro_read_2();
        } else {
            apiPro_read();
        }
    }, [isMy])


    useEffect(() => {
        // // console.log("academyData => ",academyData);
    }, [])

    return (
        // <div style={Profile_teacher_Style.c}>
        <div style={{
            display: "flex",
            flexDirection: "column",
            flex: 1,
            // paddingTop : "10px",
            marginTop: "10px",
            backgroundColor: style.common.borderColor2,
            minHeight: "100%",
            // width : "100%",
            // height : "100%",
            // height : "50px",
        }}>
            <div style={{
                backgroundColor: "white",
                padding: "0px 10px",
            }}>
                <div style={{
                    ...Profile_teacher_Style.t_btn_w,
                    margin: "0px 5px 0px 5px",
                    display: "flex",
                    width: "100%",
                }}>
                    {
                        isTeacher ?
                            <>
                                {
                                    //academyData["type"] == 5 &&
                                    //<UiBtn
                                    //    btnStyle={{
                                    //        ...Profile_teacher_Style.t_btn,
                                    //        width: "130px",
                                    //        padding: "18px 0px",
                                    //        fontSize: "12px",
                                    //        alignItems: "center",
                                    //        marginRight: "10px",
                                    //    }}
                                    //    title={
                                    //        <div style={Academy_book_Style.btn_title}>
                                    //            <Add style={Academy_book_Style.btn_Add}/>
                                    //            <div style={{
                                    //                paddingLeft: "12px"
                                    //            }}>
                                    //                {/* 선생님 초대하기 */}
                                    //                새 강의 생성
                                    //            </div>
                                    //        </div>
                                    // //<div style={Profile_teacher_Style.t_btn_text}>
                                    //     //<Add
                                    //         //style={Profile_teacher_Style.t_btn_Add}
                                    //     //     />
                                    //     //새 강의 생성
                                    // //</div>
                                    //    }
                                    //    fnClick={() => {
                                    //        setHiddenNav("lecture")
                                    //        navigate('/lectureregi/' + params["who"] + "/" + params["id"]);
                                    //    }}
                                    // />
                                }
                                <UiBtn
                                    btnStyle={{
                                        ...Profile_teacher_Style.t_btn,
                                        width: "160px",
                                        fontSize: "12px",
                                        alignItems: "center",
                                    }}
                                    title={
                                        <div style={Profile_teacher_Style.t_btn_text}>
                                            {/* 클래스룸 전체 강의 보기 or 내 강의 보기 */}
                                            {isMy ? "클래스룸 전체 강의 보기" : "내 강의 보기"}
                                        </div>
                                    }
                                    fnClick={() => {
                                        setIsMy(!isMy)
                                        // isMy = !isMy
                                        // apiPro_read()
                                    }}
                                />
                            </>
                            :
                            <UiBtn
                                btnStyle={{
                                    ...Profile_teacher_Style.t_btn,
                                    width: "160px",
                                    fontSize: "12px",
                                    alignItems: "center",
                                }}
                                title={
                                    <div style={Profile_teacher_Style.t_btn_text}>
                                        {/* 클래스룸 전체 강의 보기 or 내 강의 보기 */}
                                        {isMy ? "클래스룸 전체 강의 보기" : "수강중인 강의 보기"}
                                    </div>
                                }
                                fnClick={() => {
                                    setIsMy(!isMy)
                                    // isMy = !isMy
                                    // if(isMy){
                                    //     apiPro_read_2();
                                    // }
                                    // else {
                                    //     apiPro_read();
                                    // }

                                }}
                            />
                    }
                </div>
                <UiSorting
                    isTeacher={isTeacher}
                    title={
                        (isMy ? "내 강의 " : "클래스룸 전체 강의 ")
                        + `${lectureData.length}개`
                    }
                    data={sort}
                    fnClick={(e) => {
                        handleSortClassList(e)
                    }}
                />
            </div>
            <div style={{
                padding: "10px 10px",
                // height : "100vh",
            }}>
                <UiSearchList
                    setScrolllKey={setScrolllKey}
                    isImg={false}
                    scrollKey={scrollKey}
                    data={lectureData}
                    isTeacher={isTeacher}
                    isTitle={false}
                    form={2}
                    fnClick={(data) => {
                        // /profile/teacher/343/de
                        setHiddenNav("lecture&")
                        setHiddenUrl(`/profile/${params["who"]}/${params["id"]}/de`)
                        setScrolllKey(data["id"])
                        navigate("/lecture/detail/" + params["who"] + "/" + data["id"] + "/de")
                    }}
                />

            </div>
        </div>
    )
}

export default Profile_lecture