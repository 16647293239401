import { atom } from "recoil";
import { AtomKeys } from "../constants";
import { CaptureSizeSheet } from "./types";

export const captureSizeSheetAtom = atom<CaptureSizeSheet>({
  key: AtomKeys.CAPTURE_SIZE_SHEET,
  default: {
    isOpen: false,
    targetRef: null,
  },
});
