import ExpandLessRoundedIcon from "@mui/icons-material/ExpandLessRounded";
import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";
import { useClassDetailInfo, useTeacherInfo } from "api/classDetail";
import { getUser } from "asset/storage";
import { Accordion, AccordionDetails, AccordionSummary } from "components/atoms/Accordion";
import React, { useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router";
import styled from "styled-components";

const ClassInfo = () => {
  const params = useParams();
  const { data } = useClassDetailInfo({
    boardId: params["id"],
  });
  const userInfo = getUser();
  const videoData = useMemo(() => data?.getBoard?.boards[0], [data]);
  const { data: teacherList } = useTeacherInfo(videoData.mid);
  const teacherData = useMemo(() => teacherList?.list[0], [teacherList?.list]);
  const navigate = useNavigate();
  const [expanded, setExpanded] = useState<boolean>(false);
  return (
    <Container>
      <TitleFont
        onClick={() => {
          navigate(
            `/lecture/detail/${userInfo.type === 1 ? "student" : "teacher"}/${videoData.cid}/de`
          );
        }}
      >
        {videoData?.title}
      </TitleFont>
      <Accordion expanded={expanded} onChange={() => setExpanded((prev) => !prev)}>
        <AccordionSummary>
          <SpaceBetween>
            <TeacherNameFont>{teacherData?.name} 선생님</TeacherNameFont>
            <MoreButton>
              {expanded ? (
                <>
                  접기
                  <ExpandLessRoundedIcon />
                </>
              ) : (
                <>
                  더보기
                  <ExpandMoreRoundedIcon />
                </>
              )}
            </MoreButton>
          </SpaceBetween>
        </AccordionSummary>
        <AccordionDetails>
          <TeacherIntroduce dangerouslySetInnerHTML={{ __html: videoData?.content }} />
        </AccordionDetails>
      </Accordion>
    </Container>
  );
};

const Container = styled.div`
  gap: 8px;
  display: flex;
  flex-direction: column;
`;

const SpaceBetween = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const TitleFont = styled.span`
  color: #292929;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%;
`;

const TeacherNameFont = styled.div`
  color: #bbb;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
`;

const MoreButton = styled(TeacherNameFont)`
  color: #8b8b8b;
  font-weight: 400;
  display: flex;
  align-items: center;
`;

const TeacherIntroduce = styled.span`
  color: #292929;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 166.667% */
`;
export default ClassInfo;
