import MenuItem from "../../../../components/MenuItem";
import React from "react";
import style from "../../../../asset/style";
import {Chip} from "@mui/material";
import moment from "moment/moment";
import {useNavigate} from "react-router";

const StudentQuestionItem = ({id, isAnswered, title, questionImage}) => {
    return <MenuItem>
        <img src={questionImage} alt={`${id}번 문제 사진`}/>
        {isAnswered && <span>[답변 완료]</span>}
        {title}
    </MenuItem>
}

const TeacherQuestionItem = ({
                                 id,
                                 questionImage,
                                 student,
                                 isAnswered,
                                 title,
                                 isOwn,
                                 isSharing,
                                 isShared,
                                 reg_date,
                                 teacher
                             }) => {

    const navigate = useNavigate()

    return <MenuItem onClick={() => {
        navigate(`/talk/teacher/null/${id}/${student?.id ?? 'null'}/${teacher?.id}`)
    }}>
        <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
            <div style={
                {display: 'flex'}
            }>
                <div style={{
                    display: "inline-flex",
                    width: 36,
                    height: 36,
                    borderRadius: 8,
                    alignItems: 'center',
                    justifyContent: 'center',
                    backgroundColor: isAnswered ? style.common.s_color : style.common.t_color,
                    color: '#fff'
                }}>
                    {isAnswered ? 'A' : 'Q'}
                </div>

                <div style={{
                    display: 'flex',
                    padding: '0 10px',
                    justifyContent: 'space-between',
                    alignItems: 'flex-start',
                    flexDirection: 'row'
                }}>
                    <div>
                        <div style={{
                            height: 12,
                            fontSize: 12,
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            textAlign: 'left',
                            maxWidth: 130
                        }}>{title}</div>
                        <div style={{
                            height: 12,
                            fontSize: 12,
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            marginTop: 4,
                            color: "#adadad",
                            textAlign: 'left'
                        }}>{student ? `${student.name}(${student.nick}) > ${teacher?.name}` : '모범답안'}</div>
                    </div>
                </div>

                <div style={{
                    display: 'flex', justifyContent: 'flex-end',
                    height: 12,
                    fontSize: 12
                }}>
                    {moment.unix(reg_date).format('YY/MM/DD')}
                </div>
            </div>
            <div style={{display: 'flex', flexDirection: 'column', height: 64, justifyContent: 'space-around'}}>
                {isOwn && <Chip size={"small"} color="error" label="지목"/>}
                {isSharing && <Chip size={"small"} color="info" label="공유함"/>}
                {isShared && <Chip size={"small"} color="success" label="공유 받음"/>}
            </div>
        </div>
    </MenuItem>
}

export const QuestionItem = {TeacherQuestionItem, StudentQuestionItem}

export default QuestionItem
