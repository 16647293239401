import { React , useState , useEffect, useRef } from 'react'
import style from '../../asset/style'
import { useNavigate, useParams } from 'react-router'
import Basic from '../../common/frame/Basic'
import { UiInput } from '../../uiComponents/UiInput/UiInput'
import Message_Mark_List_Style from '../Message/Message_Mark_List_Style'
import UiTextBtn from '../../uiComponents/UiTextBtn/UiTextBtn'
import Column_Pick_classify_Style from './Column_Pick_classify_Style'
import UiSorting from '../../uiComponents/UiSorting/UiSorting'
import UiVideoSection from '../../uiComponents/UiVideoSectionList/UiVideoSection'
import UiTopPopTextBtn from '../../uiComponents/UiTopPopTextBtn/UiTopPopTextBtn'
import apiFn from '../../asset/apiClass'
import UiModal from '../../uiComponents/UiModal/UiModal'
import store from "../../asset/store";

const Column_pick_classify = ({
    userInfo,
    scrollKey,
    setScrolllKey,
}) => {
    let params = useParams()
    let navigate = useNavigate();

    var [isModal,setIsModal] = useState(false);

    var [selectData,setSelectData] = useState({})
    var [selectData_l,setSelectData_l] = useState(0)

    const [isTeacher ,setIsTeacher] = useState(true);

    const [isEdit , setIsEdit] = useState(false);

    var [colData,setColData] = useState([]);
    var [f_data,setF_data] = useState({})


    // 찜 제거
    const apiPro_del_pick = async () => {
        try {
            let delArray = Object.keys(selectData).reduce((a,v,i)=>{
                return [
                    ...a,
                    {
                        boid : v,
                        mid : userInfo["id"],
                    }
                ]
            },[])
            let isOk = await apiFn.comApi({
                state : {
                    table : "folder_link",
                    del : delArray
                }
            })
            await apiPro_read();
        }
        catch(e){
        }
    }


    // 폴더 정보 수정 / 삭제
    const apiPro_regi_del = async (name,isDel) => {
        try {
            let state = {
                table : "folder"
            }

            if(isDel){
                state["del"] = [
                    { id : params["id"] }
                ]
            }
            else {
                state["set"] = [
                    {
                        key : `id:${params["id"]}`,
                        value : { name : name }
                    }
                ]
                // data["list"][0]
                // set: [ { key: 'prKey:15', value: {a:1,b:2}  } ]
            }
            let isOk = await apiFn.comApi({
                state : state
            })
            if(isDel){

            const stack = store.get('stack')
            if(stack.length > 0)
                navigate(-1);
            else
                navigate('/')
                return;
            }
            await apiPro_read_2();
        }
        catch(e){
        }
    }
    // 폴더 정보
    const apiPro_read_2 = async () => {
        try {
            let data = await apiFn.comApi({
                state : {
                    table : "folder",
                    amount : 9999,
                    page : 1,
                    sort : "id desc" ,
                    where : {
                        id : `= '${params["id"]}'`
                    }
                }
            })

            setF_data({
                ...data["list"][0]
            })

        }
        catch(e){
        }
    }

    // 컬럼 호출
    const apiPro_read = async (sort) => {
        try {
            let data = await apiFn.comApi({
                state : {
                    table : "board",
                    amount : 9999,
                    page : 1,
                    sort : sort ? sort : "r_date desc" ,
                    ref : [
                        'HIQDB_folder_link.fid|HIQDB_folder_link.boid=HIQDB_board.id',
                        'HIQDB_folder_link.reg_date as r_date|HIQDB_folder_link.boid=HIQDB_board.id',
                    ],
                    where : {
                        'HIQDB_folder_link.fid' : `='${params["id"]}'`
                    },
                    count : [
                        `HIQDB_board_log.bid:bid:id|and countTable.btype=\'1\'  |readCnt`,
                        `HIQDB_board_log.bid:bid:id|and countTable.btype=\'2\' |likeCnt`,
                    ]
                }
            })
            if(data["list"].length > 0){
                data["list"].map((v,i)=>{
                    v["reg_date"] = v["r_date"]
                })
            }
            // // console.log("ZAAZ  ",data)
            setColData([
                ...data["list"]
            ])
        }
        catch(e){
        }
    }

    useEffect(()=>{
        apiPro_read();
        apiPro_read_2();
    },[])

    useEffect(()=>{
        // console.log("parmas => ",params)
        if(params["who"] == "teacher"){
            setIsTeacher(true)
        }
        else {
            setIsTeacher(false)
        }
    },[params])

    useEffect(()=>{
        selectData_l = 0;
        // // console.log("@@ ",selectData);
        Object.keys(selectData).map((v,i)=>{
            if(selectData[v] == "Y"){
                selectData_l = selectData_l + 1
            }
            else if (selectData[v] == "N") {
                if(selectData_l == 0)return;
                selectData_l = selectData_l - 1
            }
        })
        setSelectData_l(selectData_l)
    },[selectData])


    return (
        <Basic
            title={f_data["name"]}
        >
            {
            isEdit  &&
            <UiTopPopTextBtn
                title="편집 취소"
                isTeacher={isTeacher}
                fnClick={()=>{
                    setIsEdit(false)
                    // setIsSearch(true)
                    setSelectData({})
                }}
            />
            }
            {
            isEdit ?
            <div style={{
                ...Column_Pick_classify_Style.edit_section_w,
                fontWeight:'bold',
                borderTop: isEdit ? "none" : "1px solid",
                borderColor: style.common.borderColor,
            }}>
                선택된 폴더를
                <div style={Column_Pick_classify_Style.textBtn_w}>
                    <UiTextBtn
                        text={"찜하기 취소"}
                        btnStyle={Column_Pick_classify_Style.textBtn}
                        fnClick={()=>{
                            let isOk = window.confirm("선택한 컬럼을 찜 취소 하시겠어요?")
                            if(isOk){
                                setIsEdit(false)
                                setSelectData({})
                                apiPro_del_pick();
                            }
                        }}
                    />
                </div>
            </div>
            :
            <>
            <div style={{
                ...Column_Pick_classify_Style.t_c,
                borderTop: "1px solid",
                borderColor: style.common.borderColor,
            }}>
                <div style={{
                    ...Column_Pick_classify_Style.btn_w,
                    width: "60%",
                }}>
                    <UiTextBtn
                        isUnderline={false}
                        text={"삭제하기"}
                        btnStyle={Column_Pick_classify_Style.btn}
                        fnClick={()=>{
                            let isOk = window.confirm(`[${f_data["name"]}] (을)를 삭제하시겠어요?`)
                            if(isOk){
                                apiPro_regi_del("",true);
                            }
                        }}
                    />
                    <UiTextBtn
                        text={"이름 변경"}
                        isUnderline={false}
                        btnStyle={{
                            ...Column_Pick_classify_Style.btn,
                            color : style.common.fontColor_1
                        }}
                        fnClick={()=>{
                            setIsModal(true)
                            // let name = window.prompt("변경하실 폴더의 이름을 입력해주세요.")
                            // if(name){
                            //     apiPro_regi_del(name)
                            // }

                        }}
                    />
                </div>
            </div>
            <UiSorting
                isTeacher={isTeacher}
                title='총 '
                cnt={`${colData.length}개 컬럼`}
                data={[
                    { name : "찜한 날짜순" , value : 'r_date desc'},
                    { name : "등록일자순" , value : 'reg_date desc'},
                ]}
                fnClick={(d)=>{
                    apiPro_read(d)
                }}
            />
            </>
            }
            {
            colData.map((v,i)=>
            <UiVideoSection
                setScrolllKey={setScrolllKey}
                scrollKey={scrollKey}
                data={v}
                setSelectData={setSelectData}
                selectData={selectData}
                isEdit={isEdit}
                isTeacher={isTeacher}
                // setIsSearch={setIsSearch}
                fnLongClick={(data)=>{
                    // setIsSearch(false)
                    setIsEdit(true)
                    setSelectData({
                        ...selectData,
                        [data["id"]] : "Y"
                    })
                }}
                fnCheck={(e)=>{
                    if(selectData[e.target.id] == "Y"){
                        delete selectData[e.target.id]
                        setSelectData({
                            ...selectData,
                        })
                    }
                    else {
                        setSelectData({
                            ...selectData,
                            [e.target.id] : "Y"
                        })
                    }
                }}
                fnClick={(data)=>{
                    setScrolllKey(data["id"])
                    navigate("/columndetail/"+params["who"]+"/"+data["id"]+"/act")
                }}
                key={"mark_folder_"+i}
                id={i}
                navigate={navigate}
                params={params}

            />
            )}
            {
            isModal &&
            <UiModal
                title={"변경하실 폴더의 이름을 입력해주세요."}
                setIsModal={setIsModal}
                apiPro={apiPro_regi_del}
            />
            }
        </Basic>

    )
}


export default Column_pick_classify
