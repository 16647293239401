import { Star, StarBorder } from '@material-ui/icons'
import { React , useState , useEffect } from 'react'
import { useNavigate, useParams } from 'react-router'
import style from '../../asset/style'
import util from '../../asset/util'
import UiReview_Style from './UiReview_Style'

const UiReview = ({
    data,
    fnClick,
    index
}) => {
    return (
        <div style={{
            // ...UiReview_Style.c,
            padding : "14px 10px",
            backgroundColor : index%2 == 0 ? "#FFFBF5" : "#FBFBFB",
            marginBottom:"2px",
            borderRadius:"5px"
        }}
            onClick={()=>{
                fnClick(data)
            }}
        >
            <div style={{
                display:"flex",
                fontSize :"14px",
                fontWeight:400,
                color : "#3D3A39",
                justifyContent:"space-between",
            }}>
                <div style={{
                    display:"flex",
                }}>
                    {/* 닉네이무 */}
                    {data["nick"]}
                    <div style={{
                        marginLeft : "5px"
                    }}>
                        {
                        [1,2,3,4,5].map((v,i)=>{
                            if(v <= data["rating"]){
                                return (
                                    <Star style={{
                                        // marginRight : '-2px',
                                        fontSize : "18px",
                                        color: "#f5b21a",
                                    }}/>
                                )
                            }
                            else {
                                return (
                                    <Star style={{
                                        // marginRight : '-2px',
                                        fontSize : "18px",
                                        color : style.common.borderColor
                                    }}/>
                                )
                            }
                        })
                        }
                    </div>
                </div>
                <div style={{
                    ...UiReview_Style.text_2,
                    fontSize:"14px",
                    fontWeight:400,
                    color:"#B0B0B0" 
                }}>
                    {/* 2022/02/05 */}
                    {util.convertToDateTime_day(data["reg_date"])}
                </div>
            </div>
            <div style={UiReview_Style.text_w_1}>
            </div>
            <div style={UiReview_Style.text_w_2}>
                <div style={{
                    fontSize:"14px",
                    fontWeight:400,
                }}>
                    {util.textTrim(data["content"],66)}
                </div>
            </div>
            <div style={UiReview_Style.text_w_3}>
                <div style={{
                    display:"flex",
                }}>
                    <div style={{
                        fontSize:"14px",
                        fontWeight:400,
                    }}>
                        {data["bookName"]}
                    </div>
                    {
                    data["qnaTitle"] &&
                    <div style={{ 
                        fontSize:"14px",
                        marginLeft : "3px",
                        color:"#B0B0B0" 
                    }}>
                        {` | ${data["qnaTitle"]}`}
                    </div>
                    }
                </div>
            </div>
        </div>
        
    )
}

export default UiReview