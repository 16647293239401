import { useApolloClient } from "@apollo/client";
import { BOOK_INFO } from "api/graphql/studyBook";
import { useStudyBookInfo } from "api/studyBook";
import apiFn from "asset/apiClass";
import { ReactComponent as ModifyIcon } from "asset/images/icon/modify.svg";
import CommonModal from "components/atoms/CommonModal";
import { FontStyleMap } from "components/atoms/Font";
import ReactiveButtonContainer from "components/atoms/ReactiveButtonContainer";
import TextInput from "components/atoms/TextInput";
import { StyledCommonConfirmContainer } from "components/templates/Confirm";
import { showToast } from "providers/ToastProvider";
import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router";
import styled from "styled-components";
import { MenuItemContainer } from "..";

const StudyBookRename = () => {
  const { bookId } = useParams();
  const { data } = useStudyBookInfo(bookId);
  const [bookName, setBookName] = useState("");
  const [open, setOpen] = useState(false);
  const apolloClient = useApolloClient();
  const handleRename = useCallback(async () => {
    try {
      let isOk = await apiFn.comApi({
        state: {
          table: "book",
          set: [
            {
              key: `id:${bookId}`,
              value: {
                name: bookName,
              },
            },
          ],
        },
      });
      await apolloClient.refetchQueries({
        include: [BOOK_INFO],
      });
      handleClose();
    } catch (e) {
      showToast({
        message: "교재명 변경에 실패했습니다.",
        type: "error",
        title: "변경 실패",
      });
    }
  }, [bookId, bookName]);

  useEffect(() => {
    setBookName(data?.getBook.book.name);
  }, [data]);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);
  return (
    <>
      <MenuItemContainer
        onClick={() => {
          setOpen(true);
        }}
      >
        교재명 변경
        <ModifyIcon />
      </MenuItemContainer>
      <CommonModal open={open} onClose={handleClose}>
        <StyledCommonConfirmContainer>
          <FontContainer>
            <ModifyIcon width={22} height={22} />
            <TitleFont>교재명을 변경할께요.</TitleFont>
            <TextInput
              size={"small"}
              value={bookName}
              placeholder="교재명을 입력해주세요."
              onChange={(event) => setBookName(event.target.value)}
              style={{ width: "100%" }}
            />
          </FontContainer>
          <ReactiveButtonContainer
            buttonList={[
              {
                onClick: handleClose,
                variant: "text",
                title: "취소",
              },
              {
                onClick: handleRename,
                variant: "contained",
                title: "확인",
              },
            ]}
          />
        </StyledCommonConfirmContainer>
      </CommonModal>
    </>
  );
};

const FontContainer = styled.div`
  gap: 16px;
  display: flex;
  flex-direction: column;
  margin-bottom: 42px;
`;
const TitleFont = styled.div`
  color: var(--neutral-color-natural-80, #141414);
  ${FontStyleMap["KR/bold/$KR.TS.BOL.20"]}
`;

export default StudyBookRename;
