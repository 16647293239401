const Profile_lecture_regi_Style = {
    lectureType_c : {
        marginTop : "20px",
        fontSize : "14px",
        marginBottom : "5px",
    },
    lectureType_w : {
        display:"flex"
    },
    lectureType_btn : {
        display:"flex",
        padding : "2px 5px",
        width : "50px",
        height : "30px",
        marginTop : "10px",
        borderRadius : 20,
        color : "white",
        alignItems:"center",
        justifyContent:"center",
        marginRight : "10px",
    },
    bottom_btn_w : {
        display:"flex",
        flexDirection : "column",
        justifyContent:"flex-end",
        paddingBottom : "20px",
        width : "100%",
        flex:1,
    },
}

export default Profile_lecture_regi_Style