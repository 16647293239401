import {
  useStudyBookDelete,
  useStudyBookListInCurrentClass,
  useStudyBookListInFolder,
} from "api/studyBook";
import { ReactComponent as LeftArrowIcon } from "asset/images/icon/left.svg";
import { FontStyleMap } from "components/atoms/Font";
import { BlackButton, GrayButton } from "pages/StudyBook/components/FolderEdit";
import StudyBookConfig from "pages/StudyBook/components/StudyBookConfig";
import { StudyBookContext } from "pages/StudyBook/context/StudyBookContext";
import { useConfirm } from "providers/ConfirmProvider";
import { showToast } from "providers/ToastProvider";
import React, { useCallback, useContext, useMemo } from "react";
import { useNavigate, useParams } from "react-router";
import styled from "styled-components";

const StudyBookFolderHeader = () => {
  const { isConfigMode, setIsConfigMode, allCheckedBook, checkedBookList } =
    useContext(StudyBookContext);

  const navigate = useNavigate();
  const { folderId } = useParams();
  const { data: folderData } = useStudyBookListInFolder(folderId);
  const { data: bookData } = useStudyBookListInCurrentClass(folderId);

  const list = useMemo(() => {
    if (folderId === "0") {
      return bookData.bookList;
    } else if (folderData) {
      return folderData.folderData.HIQDB_book_links;
    } else {
      return [];
    }
  }, [folderData, bookData, folderId]);

  const title = useMemo(() => {
    const defaultTitle = "수업에 의한 열람";
    return folderId !== "0" ? folderData?.folderData.name : defaultTitle;
  }, [folderData?.folderData.name]);

  const { mutateAsync } = useStudyBookDelete();

  const handleDelete = useCallback((bookData) => {
    if (bookData?.id === 0) {
      showToast({
        message: "미지정 교재는 제거할 수 없습니다.",
        title: "교재 삭제",
        type: "warning",
      });
      return;
    } else if (bookData?.type === 11) {
      showToast({
        message: `${bookData.name}은 기본 교재 입니다.\n 수업 기본 교재 설정 해제 후 제거할 수 있습니다.`,
        title: "교재 삭제",
        type: "warning",
      });
    }
    mutateAsync(bookData?.id);
  }, []);

  const { showConfirm } = useConfirm();
  const handleConfirm = useCallback(() => {
    showConfirm({
      message: "삭제된 교재는 휴지통에서 30일간 보관 후 영구삭제 됩니다.",
      title: "폴더 삭제",
      onConfirm() {
        checkedBookList.forEach((book) => {
          handleDelete(book);
        });
      },
    });
  }, [showConfirm]);

  const handleAllCheck = useCallback(() => {
    allCheckedBook(list);
  }, [allCheckedBook, list]);
  return (
    <>
      <Container>
        <BackIconContainer onClick={() => navigate(-1)}>
          <LeftArrowIcon stroke="" />
        </BackIconContainer>
        {title}
        {isConfigMode ? (
          <BlackButton onClick={() => setIsConfigMode(false)}>취소</BlackButton>
        ) : (
          <GrayButton onClick={() => setIsConfigMode(true)}>편집</GrayButton>
        )}
      </Container>
      {isConfigMode && <StudyBookConfig />}
    </>
  );
};

const Container = styled.header`
  color: var(--neutral-color-natural-70, var(--Default-Gray-90, #262626));
  ${FontStyleMap["KR/medium/$KR.BtnL.MD.16"]}
  padding: 8.5px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const BackIconContainer = styled.a`
  display: inline-flex;
  > svg {
    stroke: var(--neutral-color-natural-70, var(--Default-Gray-90, #262626));
  }
`;

const ConfigContainer = styled.div``;

export default StudyBookFolderHeader;
