import { React, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import apiFn from "../../asset/apiClass";
import UiFolder from "../../uiComponents/UiFolder/UiFolder";

var fontStyle = {
  fontFamily: "Noto Sans",
  fontStyle: "normal",
};

const Modal_pick = ({ setIsModal_pick, isModal, userInfo, qnaData, setIsPick }) => {
  let params = useParams();
  let navigate = useNavigate();

  var [list, setList] = useState([]);
  var [search, setSearch] = useState("");

  var [folderList, setFolderList] = useState([]);

  // 찜
  const apiPro_pick = async (data) => {
    try {
      let isOk = await apiFn.comApi({
        state: {
          table: "folder_link",
          ins: [
            {
              fid: data["id"],
              mid: userInfo["id"],
              type: 1,
              qid: params["qid"],
              reg_date: 0,
            },
          ],
        },
      });
      // navigate(-1)
      setIsPick(true);
      setIsModal_pick(false);
    } catch (e) {}
  };

  const apiPro_read = async () => {
    try {
      let data = await apiFn.comApi({
        state: {
          table: "folder",
          page: 1,
          amount: 9999,
          sort: "name desc",
          where: {
            "HIQDB_folder.mid": `='${userInfo["id"]}'`,
            "HIQDB_folder.ftype": `= '1'`,
          },
        },
      });

      // console.log("## ",data);

      setFolderList([...data["list"]]);
    } catch (e) {}
  };

  // useEffect(()=>{
  //     selectData_l = 0;
  //     Object.keys(selectData).map((v,i)=>{
  //         if(selectData[v] == "Y"){
  //             selectData_l = selectData_l + 1
  //         }
  //         else if (selectData[v] == "N") {
  //             if(selectData_l == 0)return;
  //             selectData_l = selectData_l - 1
  //         }
  //     })
  //     setSelectData_l(selectData_l)
  // },[selectData])

  useEffect(() => {
    apiPro_read();
  }, []);

  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        zIndex: 130002,
        backgroundColor: "rgba(150, 150, 150,0.5)",
        // backgroundColor:"red",
        width: "100%",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
      onClick={() => {
        setIsModal_pick(false);
      }}
    >
      <div
        style={{
          width: "75%",
          minHeight: "55%",
          maxHeight: "60%",
          backgroundColor: "white",
          padding: "15px 20px 30px 20px",
          borderRadius: 10,
          border: "1px solid rgb(200, 200, 200)",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          filter: "drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.37))",
        }}
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
        }}
      >
        <div
          style={{
            fontWeight: 500,
          }}
        >
          오답노트 폴더
        </div>
        <div
          style={{
            paddingTop: "5px",
            overflowY: "scroll",
            display: "flex",
            flexWrap: "wrap",
            // flexDirection:"column",
            flex: 1,
          }}
        >
          <div style={{}}>
            <UiFolder
              data={{
                id: 1,
                name: "나중에 볼 질문",
              }}
              fnClick={() => {
                let data = {
                  id: "1",
                };
                apiPro_pick(data);
              }}
            />
          </div>
          {folderList.map((v) => (
            <div style={{}}>
              <UiFolder
                fnClick={(data) => {
                  apiPro_pick(data);
                }}
                data={v}
              />
            </div>
          ))}
        </div>
        {/* <div style={{
                    height:"40px",
                    display:"flex",
                    justifyContent:"center",
                }}>
                    <UiBtn 
                        btnStyle={{
                            width:"90px",
                            height:"36px",
                            backgroundColor:style.common.t_color,
                            color : "white",
                            fontStyle:"12px",
                        }}
                        title={"닫기"}
                        fnClick={()=>{
                            setIsModal_pick(false)
                        }}
                    />
                </div> */}
      </div>
    </div>
  );
};

export default Modal_pick;
