import { Close } from "@material-ui/icons";
import { Modal } from "@mui/material";
import React, { useContext } from "react";
import { createPortal } from "react-dom";
import { TransformComponent, TransformWrapper } from "react-zoom-pan-pinch";
import styled from "styled-components";
import constants from "../../../../asset/constants";
import { PdfViewActionsContextState, PdfViewContext } from "../PdfViewContext";

const ImgViewModal = () => {
  const { imgViewModalShow, changeImgViewModalShow, currentSlideInfo } =
    useContext<PdfViewActionsContextState>(PdfViewContext);
  if (!imgViewModalShow) {
    return null;
  }
  return createPortal(
    <Container
      open={imgViewModalShow}
      onClose={() => {
        changeImgViewModalShow(false);
      }}
    >
      <div>
        <CloseButton onClick={() => changeImgViewModalShow(false)}>
          <Close />
        </CloseButton>
        <ModalContent>
          <TransformWrapper>
            <TransformComponent>
              <ImageContainer>
                <img
                  src={`${constants.s3_url}/qna/${currentSlideInfo?.qid_HIQDB_online_qna?.img}.jpg`}
                  width={"100%"}
                  height={"100%"}
                  alt={"문제 사진"}
                />
              </ImageContainer>
            </TransformComponent>
          </TransformWrapper>
        </ModalContent>
      </div>
    </Container>,
    document.body
  );
};

export default ImgViewModal;

const Container = styled(Modal)``;
const ModalContent = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100vw;
`;
const CloseButton = styled.button`
  position: absolute;
  top: 0;
  right: 0;
  background: none;
  border: 0;
  svg {
    color: white;
    width: 36px;
    height: 36px;
  }
`;

const ImageContainer = styled.div`
  max-width: 90vw;
  max-height: 90vh;
  > img {
    object-fit: contain;
  }
`;
