import { useStudyBookFolderDelete, useStudyBookFolderList } from "api/studyBook";
import constants from "asset/constants";
import { FontStyleMap } from "components/atoms/Font";
import Section from "components/atoms/Section";
import { useConfirm } from "providers/ConfirmProvider";
import React, { useCallback, useContext, useMemo } from "react";
import styled from "styled-components";
import { StudyBookFolderContext } from "../context/StudyBookFolderContext";
const FolderEdit = () => {
  const {
    isDeleteMode,
    setIsDeleteMode,
    checkedFolderList,
    allRemoveCheckedFolder,
    allCheckedFolder,
  } = useContext(StudyBookFolderContext);

  const { showConfirm } = useConfirm();
  const { mutateAsync } = useStudyBookFolderDelete();

  const handleDelete = useCallback(() => {
    showConfirm({
      message: constants.confirm.cf_3,
      title: "폴더 삭제",
      onConfirm: async () => {
        const checkedFolderIdList = checkedFolderList.map((folder) => folder.id);
        await mutateAsync(checkedFolderIdList);
        allRemoveCheckedFolder();
      },
    });
  }, [checkedFolderList, mutateAsync, showConfirm]);
  const { data } = useStudyBookFolderList();
  const folderList = useMemo(() => data.list, [data]);
  const handleAllCheck = useCallback(() => {
    allCheckedFolder(folderList);
  }, [allCheckedFolder, folderList]);
  return (
    <Container>
      <Section justify="space-between">
        폴더
        {isDeleteMode ? (
          <BlackButton onClick={() => setIsDeleteMode(false)}>취소</BlackButton>
        ) : (
          <GrayButton onClick={() => setIsDeleteMode(true)}>편집</GrayButton>
        )}
      </Section>
      {isDeleteMode && (
        <ButtonContainer>
          <GrayButton onClick={handleAllCheck}>전체선택</GrayButton>
          <GrayButton onClick={handleDelete} disabled={!checkedFolderList.length}>
            삭제
          </GrayButton>
        </ButtonContainer>
      )}
    </Container>
  );
};

export const Container = styled.div`
  color: var(--neutral-color-natural-70, var(--Default-Gray-90, #262626));
  ${FontStyleMap["KR/medium/$KR.BtnM.MD.14"]}
  display: flex;
  flex-direction: column;
`;

export const GrayButton = styled.button`
  display: flex;
  padding: 8px;
  justify-content: center;
  align-items: center;
  border-radius: 99px;
  border: 0;
  background: var(--neutral-color-natural-20, #f0f0f0);
`;

export const BlackButton = styled(GrayButton)`
  background: var(--Default-Gray-90, #262626);
  color: white;
`;

export const ButtonContainer = styled.div`
  display: flex;
  gap: 16px;
  width: 100%;
  justify-content: flex-end;
  padding-top: 16px;
`;

export default FolderEdit;
