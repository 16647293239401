import UiSearchSection_Style from "../../../uiComponents/UiSearchList/UiSearchSection_Style";
import {UiCheckBox} from "../../../uiComponents/UiCheckBox/UiCheckBox";
import {React, useEffect, useRef, useState} from "react";
import util from '../../../asset/util'
import {useNavigate, useParams} from "react-router";
import {KeyboardArrowDown, KeyboardArrowUp} from "@material-ui/icons";
import style from '../../../asset/style'

const ClassListSection = ({
                              data = [],
                              scrollKey = "",
                              setScrolllKey,
                          }) => {
    let params = useParams();
    const [isSimpleView, setIsSimpleView] = useState(
        window.localStorage.getItem('class_omit') === 'true' ? true : false);

    return (
        <div style={UiSearchSection_Style.f}>
            <div style={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
            }}>
                <UiCheckBox
                    value={"isImportant"}
                    name="iI"
                    className={params["who"] == "teacher" ? "t" : "s"}
                    checked={isSimpleView}
                    fnCheck={(e) => {
                        setIsSimpleView(e.target.checked)
                        window.localStorage.setItem("class_omit", e.target.checked)
                    }}
                />
                <div style={{
                    fontSize: "12px",
                    color: style.common.fontColor_1,
                    marginLeft: "5px",
                }}>
                    간략히 보기
                </div>
            </div>
            {data.map((classData, i) => (
                <ClassDataCard
                    key={'classCard' + i}
                    isSimpleView={isSimpleView}
                    scrollKey={scrollKey}
                    setScrolllKey={setScrolllKey}
                    data={classData}
                />
            ))
            }
        </div>
    )
}
const ClassDataCard = ({
                           isSimpleView,
                           setIsSelect,
                           data = {},
                           isBorder = true,
                           isLine,
                           scrollKey,
                           setScrolllKey
                       }) => {
    const navigate = useNavigate();
    const params = useParams();
    var scrollRef = useRef(null);
    const [isShowContent, setIsShowContent] = useState(false);

    useEffect(() => {
        if (scrollRef.current) {
            scrollRef.current.scrollIntoView(
                {
                    block: "center"
                }
            );
            if (setScrolllKey) {
                setScrolllKey("")
            }
        }
    }, [scrollRef])
    const ContextualTeacherText = () => {
        const {teacher_count, manager_name} = data
        let innerText = ``

        if (teacher_count === 1) innerText = `${manager_name ?? '이름 없는'} 선생님 `
        else if (teacher_count >= 2) innerText = `${manager_name ?? '이름 없는'} 선생님 외 ${teacher_count - 1}명`

        return <div> {innerText} </div>
    }

    return (
        <div
            ref={scrollKey == data["id"] ? scrollRef : null}
            id={data["id"]}
            key={"search_" + data["id"]}
            style={{
                ...UiSearchSection_Style.section_c,
                backgroundColor: "white",
                border: isBorder ? "1px solid" : "none",
            }
            }
            onClick={() => {
                navigate("/lecture/detail/" + params["who"] + "/" + data?.cid + '/de')
                if (setIsSelect) setIsSelect(true)
            }}
        >
            <div style={{
                ...UiSearchSection_Style.section_w,
                padding: isSimpleView ? "5px 10px" : "10px"
            }}>
                <div style={{
                    ...UiSearchSection_Style.section_t_w,
                    borderBottom:  !isSimpleView && isShowContent ? '1px solid' : "none",
                    borderColor: !isSimpleView && isShowContent ?  "rgb(200,200,200)"  : "none",
                    minHeight: !isSimpleView ? "85px" : "0px"
                }}>
                    <div style={UiSearchSection_Style.section_t_t_c}>
                        <div style={{
                            ...UiSearchSection_Style.section_t_t_r_w,
                            marginLeft: "0px",
                        }}>
                            <div style={{
                                ...UiSearchSection_Style.section_t_t_r_w_text_1,
                                display: "flex",
                                marginBottom: "0px",
                                ...style.common.line_text
                            }}>
                                {data?.class_name ?? '이름 없는'}
                                <div style={{
                                    marginLeft: "3px",
                                    fontSize: "15px",
                                    fontWeight: 'lighter',
                                    color: style.common.fontColor_6,
                                    display: "inline"
                                }}>
                                    | {data?.classroom_name ?? '이름 없는 학원'}
                                </div>
                            </div>
                            {
                                !isSimpleView &&
                                <>
                                    <div style={UiSearchSection_Style.section_t_t_r_w_text_2}>
                                        <ContextualTeacherText/>
                                    </div>
                                    <div style={UiSearchSection_Style.section_t_t_r_w_text_2}>
                                        {data?.address ?? ''}
                                    </div>
                                    <div style={{
                                        ...UiSearchSection_Style.section_t_t_r_w_text_2,
                                        display: "flex",
                                        flexDirection: isLine ? "column" : "row",
                                    }}>
                                    </div>
                                </>
                            }
                        </div>

                    </div>
                    <div style={{
                        ...UiSearchSection_Style.section_t_b_w,
                        marginTop: isSimpleView ? "5px" : "15px",
                        marginBottom: isSimpleView ? "3px" : "10px",
                        justifyContent: "flex-end",
                    }}>
                        <div style={UiSearchSection_Style.section_t_b_text}>
                            수강인원 {data?.student_count ?? 0}명
                        </div>
                    </div>
                </div>
                {!isSimpleView && <>
                    {isShowContent ? <div style={UiSearchSection_Style.section_b_w}>
                            <div style={UiSearchSection_Style.section_b_w_text}>
                                <div style={{
                                    display: "flex",
                                    width: '100%',
                                    justifyContent: "flex-end",
                                }}>
                                    <KeyboardArrowUp
                                        style={{
                                            color: style.common.t_color,
                                            fontSize: "22px",
                                            padding: 0,
                                            margin: 0
                                        }}
                                        onClick={(e) => {
                                            setIsShowContent(false);
                                            e.preventDefault();
                                            e.stopPropagation();
                                        }}
                                    />
                                </div>
                                {util.line(data?.intro) ?? ''}
                            </div>
                        </div>
                        :
                        <div style={{
                            display: "flex",
                            width: '100%',
                            justifyContent: "flex-end",
                        }}>
                            <KeyboardArrowDown
                                style={{
                                    color: style.common.t_color,
                                    fontSize: "22px",
                                    padding: 0,
                                    margin: 0
                                }}
                                onClick={(e) => {
                                    setIsShowContent(true)
                                    e.preventDefault();
                                    e.stopPropagation();
                                }}
                            />
                        </div>
                    }
                </>
                }
            </div>
        </div>
    )
}

export default ClassListSection
