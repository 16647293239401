import { React , useState , useEffect } from 'react'
import { ArrowBackIos, ArrowForward, ArrowForwardIos, Star, StarBorder, StarRateRounded } from '@material-ui/icons'
import Basic from '../../common/frame/Basic';
import { useNavigate, useParams } from 'react-router';
import UiTopPopTextBtn from '../../uiComponents/UiTopPopTextBtn/UiTopPopTextBtn';
import './Message_talk_review.css'
import { UiInput_title } from '../../uiComponents/UiInput/UiInput';
import apiFn from '../../asset/apiClass';
import store from "../../asset/store";

const Message_talk_review = ({
    userInfo,
    setQnaData,
    setFileObj,
    setHiddenUrl
}) => {
    let params = useParams();
    let navigate = useNavigate();

    var [star_1,setStar_1] = useState(false);
    var [star_2,setStar_2] = useState(false);
    var [star_3,setStar_3] = useState(false);
    var [star_4,setStar_4] = useState(false);
    var [star_5,setStar_5] = useState(false);

    var [content,setContent] = useState("")

    const [isModi,setIsModi] = useState(false);

    const pro_start = (num) => {
        try {
            if(num == 1 ){
                setStar_1(true)
                setStar_2(false)
                setStar_3(false)
                setStar_4(false)
                setStar_5(false)
            }

            if(num == 2 ){
                setStar_1(true)
                setStar_2(true)
                setStar_3(false)
                setStar_4(false)
                setStar_5(false)
            }
            if(num == 3 ){
                setStar_1(true)
                setStar_2(true)
                setStar_3(true)
                setStar_4(false)
                setStar_5(false)
            }
            if(num == 4){
                setStar_1(true)
                setStar_2(true)
                setStar_3(true)
                setStar_4(true)
                setStar_5(false)
            }
            if(num == 5){
                setStar_1(true)
                setStar_2(true)
                setStar_3(true)
                setStar_4(true)
                setStar_5(true)
            }

        }
        catch(e){
        }
    }

    const proc_rate = () => {
        let rate = 0;
        if(star_1){
            rate = 1
        }
        if(star_2){
            rate = 2
        }
        if(star_3){
            rate = 3
        }
        if(star_4){
            rate = 4
        }
        if(star_5){
            rate = 5
        }
        return rate
    }

    const apiPro_modi = async () => {
        try {
            let isOk = await apiFn.comApi({
                state : {
                    table : "online_qna_log",
                    set : [{
                        // and mid='${userInfo["id"]}`,
                        // key : `wid:${userInfo["id"]} and qid='${params["qid"]}`,
                        key : `wid:${userInfo["id"]}' and qid='${params["qid"]}' and ltype = '3`,
                        value : {
                            rating : proc_rate(),
                            content : content
                        }
                    }]
                    // ins: [ {col1:1, col2:2} ],
                }
            })

            const stack = store.get('stack')
            if(stack.length > 0)
                navigate(-1);
            else
                navigate('/')
        }
        catch(e){
        }
    }

    const apiPro_regi = async () => {
        try {
            let isOk = await apiFn.comApi({
                state : {
                    table : "online_qna_log",
                    ins : [{
                        wid : userInfo["id"],
                        qid : params["qid"],
                        reg_date : 0,
                        ltype : 3,
                        rating : proc_rate(),
                        content : content
                    }]
                    // ins: [ {col1:1, col2:2} ],
                }
            })

            const stack = store.get('stack')
            if(stack.length > 0)
                navigate(-1);
            else
                navigate('/')
        }
        catch(e){
        }
    }

    const apiPro_read = async () => {
        try {
            let data = await apiFn.comApi({
                state : {
                    table : "online_qna_log",
                    page : 1,
                    amount : 9999,
                    sort : "reg_date desc",
                    where : {
                        'HIQDB_online_qna_log.wid' : `= '${userInfo["id"]}'`,
                        'HIQDB_online_qna_log.qid' : `= '${params["qid"]}'`,
                        'HIQDB_online_qna_log.ltype' : `= '3'`,

                    }
                }
            })

            // console.log(data)
            if(data["list"].length > 0){
                setIsModi(true)
                pro_start(data["list"][0]["rating"])
                setContent(data["list"][0]["content"])
            }
        }
        catch(e){
        }
    }

    useEffect(()=>{
        apiPro_read();
    },[])

    return (
     <Basic
        title={"평가 등록하기"}
     >
         <UiTopPopTextBtn
            isTeacher={params["who"] == "teacher" ? true : false}
            title="등록"
            fnClick={()=>{
                if(isModi){
                    // console.log("##");
                    apiPro_modi();
                }
                else {
                    apiPro_regi();
                }
            }}
         />
         <div style={{
            display : "flex",
            width:'100%',
            justifyContent:"center",
            padding : "20px 0px",
         }}>
            <Star
                className='review_star'
                style={{
                    color: star_1 ? "orange" : "rgb(200, 200, 200)",
                    fontSize: "44px"
                }}
                onClick={()=>{
                    pro_start(1)
                }}
            />
            <Star
                className='review_star'
                style={{
                    color : star_2 ? "orange" :  "rgb(200, 200, 200)",
                    fontSize: "44px"
                }}
                onClick={()=>{
                    pro_start(2)
                }}
            />
            <Star
                className='review_star'
                style={{
                    color: star_3 ? "orange" : "rgb(200, 200, 200)",
                    fontSize: "44px"
                }}
                onClick={()=>{
                    pro_start(3)
                }}
            />
            <Star
                className='review_star'
                style={{
                    color: star_4 ? "orange" :  "rgb(200, 200, 200)",
                    fontSize: "44px"
                }}
                onClick={()=>{
                    pro_start(4)
                }}
            />
            <Star
                className='review_star'
                style={{
                    color: star_5 ? "orange" : "rgb(200, 200, 200)",
                    fontSize: "44px"
                }}
                onClick={()=>{
                    pro_start(5)
                }}
            />
         </div>
         <UiInput_title
            type="desc"
            maxLength={30}
            title="내용"
            dsecStyle={{
                minHeight : "80px",
            }}
            inputValue={content}
            fnChange={(e)=>{
                // console.log(e.target.value)
                setContent(e.target.value)
            }}
            placeholder="평가 내용을 입력해주세요."

        />
     </Basic>
    )
}

export default Message_talk_review
