import { ButtonOwnProps } from "@mui/material";
import React, { ButtonHTMLAttributes, MouseEventHandler, ReactNode } from "react";
import styled from "styled-components";
import Button from "../Button";
type ReactiveButtonContainerProps = {
  buttonList: ButtonConfig[];
};

export type ButtonConfig = {
  title: ReactNode;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  variant?: ButtonOwnProps["variant"];
  type?: ButtonHTMLAttributes<HTMLButtonElement>["type"];
  disabled?: boolean;
};
const ReactiveButtonContainer = ({ buttonList }: ReactiveButtonContainerProps) => {
  return (
    <ButtonContainer>
      {buttonList.map(
        ({ title, onClick, variant = "contained", type = "button", disabled }, index) => (
          <Button
            size="large"
            onClick={onClick}
            variant={variant}
            key={title.toString()}
            type={type}
            disabled={disabled}
          >
            {title}
          </Button>
        )
      )}
    </ButtonContainer>
  );
};

const ButtonContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  gap: 12px;
  > button {
    flex: 1;
  }
  @media screen and (max-width: 450px) {
    flex-direction: column-reverse;
    > button {
      flex: unset;
    }
  }
`;
export default ReactiveButtonContainer;
