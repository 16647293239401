import Slider from "react-slick";
import constants from "../../../../asset/constants";
import React, {useCallback} from "react";
import styles from './style.module.scss'
import styled from "styled-components";
import {useNavigate, useParams} from "react-router";

const PdfImgPreview = ({title, imgList}) => {
  const params = useParams();
  const navigate = useNavigate();
  const handleGoPdfViewer = useCallback((imgData, index) => {
    navigate(`/pdfView/${params.id}/?type=${imgData?.qid_HIQDB_online_qna?.is_answer === 0 ? 'question' :'answer'}&page=${index}&prevStep=book`)
  }, [])
  return (
    <PdfImgPreviewContainer>
      <Title>
        {title}
      </Title>
      {
        imgList?.length > 0 &&
        <StyledSlider arrows={true} slidesToShow={3} slidesToScroll={3} infinite={false} className={styles.slider}
                dots={false} variableWidth={false} lazyLoad={true}>
          {imgList?.map((imgData, index) => {
            const imageSrc = `${constants.s3_url}/qna/${imgData.qid_HIQDB_online_qna.img}.jpg`;
            return <ImgContentBox onClick={() => handleGoPdfViewer(imgData, index)}>
              <img src={`${imageSrc}`} alt={`문제 사진`} width={'100%'} loading={'lazy'}/>
              <div style={{textAlign: 'center'}}>
                {index + 1}
              </div>
            </ImgContentBox>
          })}
        </StyledSlider>
      }
      {
        imgList?.length === 0 &&
        <div>
          등록된 정보가 없습니다.
        </div>
      }
    </PdfImgPreviewContainer>
  )
}

export default PdfImgPreview

const PdfImgPreviewContainer = styled.div`
  background: rgb(250, 250, 250);;
  margin: 5px 0;
  border-radius: 5px;
  padding: 16px;
`
const Title = styled.div`
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 10px;
`

const StyledSlider = styled(Slider)`
  height: 100%;

  .slick-list {
    height: 100%;
    object-fit: cover;
    display: flex;
    align-items: center;
  }

  .slick-track {
    display: flex;
    align-items: center;
  }

  .slick-prev:before, .slick-next:before {
    color: #bedaff;
  }

  .slick-prev {
    left: 0;
    z-index: 999;
  }

  .slick-next {
    right: 0;
    z-index: 999;
  }
`
const ImgContentBox = styled.div`
  img{
    height: 27vw;
    max-height: 320px;
  }
`