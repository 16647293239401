import { Rotate90DegreesCcw } from "@material-ui/icons";
import { Rotate90DegreesCw } from "@mui/icons-material";
import { Modal } from "@mui/material";
import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import constants from "../../../../asset/constants";
import store from "../../../../asset/store";
import UiLoading from "../../../../uiComponents/UiLoading/UiLoading";
import { PdfViewActionsContextState, PdfViewContext } from "../PdfViewContext";

interface pdfImgEditModalProps {
  onSuccess: () => void;
}
const PdfImgEditModal = ({ onSuccess }: pdfImgEditModalProps) => {
  const { pdfImgEditModalShow, changePdfImgEditModalShow, currentSlideInfo } =
    useContext<PdfViewActionsContextState>(PdfViewContext);
  const [imageResponseInfo, setImageResponseInfo] = useState(null);
  const [rotation, setRotation] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const canvasRef = useRef<HTMLCanvasElement>(null);

  const getStorageImageInfo = useCallback(async () => {
    try {
      const response = await (
        await fetch(
          `${constants.s3_url}/url/qna/${currentSlideInfo?.qid_HIQDB_online_qna?.img}.jpg`
        )
      ).json();
      const imageResponse = await fetch(response.bucketUrl[0]);
      if (imageResponse.status !== 200) {
        alert("문제를 가져오는데 실패했습니다.");
        resetSetting();
        return;
      }
      setImageResponseInfo(imageResponse);
    } catch (e) {
      console.error(e);
    }
  }, [currentSlideInfo, pdfImgEditModalShow]);

  const handleRotate = useCallback((rotate) => {
    setRotation((prev) => {
      let currentRotation = prev + rotate;
      if (currentRotation === rotate * 4) {
        currentRotation = 0;
      }
      return currentRotation;
    });
  }, []);

  const uploadImageToServer = async (blob) => {
    const location = await store.putBucket(blob, `qna/${currentSlideInfo?.qid}.jpg`, false, false);
    if (location) {
      setIsLoading(false);
      changePdfImgEditModalShow(false);
      onSuccess();
    }
  };

  const handleCompleteEdit = useCallback(async () => {
    const response = confirm("사진을 변경하시겠습니까? 이전 사진으로 돌아갈 수 없습니다.");
    if (!response) {
      return;
    }
    try {
      setIsLoading(true);
      const image = new Image();
      image.crossOrigin = "anonymous";
      image.src = imageResponseInfo.url;
      image.onload = async () => {
        const canvas = canvasRef.current;
        const context = canvas.getContext("2d");
        const radians = (rotation * Math.PI) / 180;
        const cos = Math.cos(radians);
        const sin = Math.sin(radians);

        // 회전된 바운딩 박스의 크기 계산
        const rotatedWidth = Math.abs(image.width * cos) + Math.abs(image.height * sin);
        const rotatedHeight = Math.abs(image.width * sin) + Math.abs(image.height * cos);

        // 캔버스 크기 조정하여 회전된 이미지의 바운딩 박스에 맞춤
        canvas.width = rotatedWidth;
        canvas.height = rotatedHeight;

        // 캔버스 클리어
        context.clearRect(0, 0, canvas.width, canvas.height);

        // 캔버스의 중심으로 이미지를 이동
        context.translate(rotatedWidth / 2, rotatedHeight / 2);

        // 이미지 회전
        context.rotate(radians);

        // 회전된 이미지의 원본 비율 유지하여 그림
        context.drawImage(image, -image.width / 2, -image.height / 2, image.width, image.height);

        // Data URL 생성 및 서버 업로드
        const dataUrl = canvas.toDataURL("image/jpeg", 1.0);
        await uploadImageToServer(dataUrl);
      };
    } catch (e) {
      console.error(e);
      setIsLoading(false);
    }
  }, [imageResponseInfo, rotation]);

  useEffect(() => {
    if (imageResponseInfo && pdfImgEditModalShow && imageResponseInfo.status === 200) {
      const image = new Image();
      image.src = imageResponseInfo.url;
      image.onload = () => {
        const canvas = canvasRef.current;
        const context = canvas.getContext("2d");

        // 뷰포트(브라우저 창)의 너비에 맞추어 캔버스 크기 설정
        const viewportWidth = window.innerWidth;
        const scale = viewportWidth / image.width; // 이미지를 뷰포트 너비에 맞추기 위한 스케일 계산
        const scaledHeight = image.height * scale; // 스케일에 따른 조정된 이미지 높이

        // 캔버스 크기 조정
        canvas.width = viewportWidth;
        canvas.height = scaledHeight;

        // 캔버스에 이미지 그리기
        context.drawImage(image, 0, 0, viewportWidth, scaledHeight);
      };
    }
  }, [imageResponseInfo]);

  useEffect(() => {
    if (pdfImgEditModalShow && currentSlideInfo) {
      getStorageImageInfo();
    }
  }, [pdfImgEditModalShow]);

  useEffect(() => {
    if (imageResponseInfo && pdfImgEditModalShow) {
      const image = new Image();
      image.src = imageResponseInfo.url;
      image.onload = () => {
        const canvas = canvasRef.current;
        const context = canvas.getContext("2d");
        const viewportWidth = window.innerWidth;

        // 회전된 이미지의 바운딩 박스 계산
        const radians = (rotation * Math.PI) / 180;
        const cos = Math.abs(Math.cos(radians));
        const sin = Math.abs(Math.sin(radians));
        const scaledWidth = image.width * cos + image.height * sin;
        const scaledHeight = image.width * sin + image.height * cos;

        // 화면 너비에 맞는 스케일 계산
        const scale = viewportWidth / scaledWidth;

        // 캔버스 크기 조정
        canvas.width = viewportWidth;
        canvas.height = scaledHeight * scale;

        // 캔버스 클리어
        context.clearRect(0, 0, canvas.width, canvas.height);

        // 캔버스의 중심으로 이미지를 이동
        context.translate(viewportWidth / 2, canvas.height / 2);

        // 이미지 회전
        context.rotate(radians);

        // 스케일에 맞게 이미지를 그림
        context.drawImage(
          image,
          (-image.width * scale) / 2,
          (-image.height * scale) / 2,
          image.width * scale,
          image.height * scale
        );

        // 변환 리셋
        context.setTransform(1, 0, 0, 1, 0, 0);
      };
    }
  }, [rotation]);

  const resetSetting = () => {
    changePdfImgEditModalShow(false);
    setRotation(0);
    if (canvasRef.current) {
      const context = canvasRef.current.getContext("2d");
      context.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
    }
  };

  if (!pdfImgEditModalShow) {
    return null;
  }

  return (
    <Container open={pdfImgEditModalShow}>
      <Wrapper>
        <HeaderBox>
          <button
            onClick={() => {
              if (rotation !== 0) {
                const isClose = confirm(
                  "저장하지 않고 나가시겠습니까? 변경사항이 저장되지 않습니다."
                );
                if (!isClose) {
                  return;
                }
              }
              resetSetting();
            }}
            className={"close"}
          >
            닫기
          </button>
          <button className={"complete"} disabled={rotation === 0} onClick={handleCompleteEdit}>
            완료
          </button>
        </HeaderBox>
        <ContentBox>
          <CanvasBox>
            <canvas ref={canvasRef}></canvas>
          </CanvasBox>
          <ControlBox>
            <button
              onClick={() => {
                handleRotate(-90);
              }}
            >
              <Rotate90DegreesCcw />
            </button>
            <button
              onClick={() => {
                handleRotate(90);
              }}
            >
              <Rotate90DegreesCw />
            </button>
          </ControlBox>
        </ContentBox>
        {isLoading && <UiLoading />}
      </Wrapper>
    </Container>
  );
};
export default PdfImgEditModal;

const Container = styled(Modal)`
  background: black;
`;
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100%;
`;

const HeaderBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;

  button {
    font-size: 16px;
    border: 0;
    font-weight: bold;
    padding: 4px 16px;
    border-radius: 16px;
  }

  button.close {
    background: #d0d0d0;
  }

  button.complete {
    background: #ffa723;
    color: white;

    &:disabled {
      opacity: 0.5;
    }
  }
`;

const ContentBox = styled.div`
  height: calc(100vh - 60px);
`;
const CanvasBox = styled.div`
  height: calc(100% - 30px);
  display: flex;
  align-items: center;
  > canvas {
    width: 100%;
    height: 100%;
  }
`;
const ControlBox = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;

  button {
    background: none;
    border: 0;

    svg {
      width: 24px;
      height: 24px;
      color: white;
    }
  }
`;
