import { PaddingContainer } from "components/atoms/Container";
import Footer from "components/molecules/Footer";
import React from "react";
import styled from "styled-components";
import FolderSection from "./components/FolderSection";
import RegistTemplate from "./components/RegistTemplate";
import StudyBookHeader from "./components/StudyBookHeader";
import StudyBookSection from "./components/StudyBookSection";

const StudyBook = () => {
  return (
    <Container>
      <StudyBookHeader />
      <FolderSection />
      <StudyBookSection />
      <RegistTemplate />
      <Footer />
    </Container>
  );
};

const Container = styled(PaddingContainer)`
  max-height: 100vh;
  overflow: hidden;
  padding-bottom: 72px;
  overflow-y: auto;
`;

export default StudyBook;
