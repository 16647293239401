import { React, useEffect, useState } from 'react';
import { Frame } from '../page_index';
import { useNavigate, useParams } from 'react-router';
import { UiSearchList } from '../../uiComponents/UiSearchList/UiSearchList';
import UiSorting from '../../uiComponents/UiSorting/UiSorting';
import UiSilde from '../../uiComponents/UiSilde/UiSilde';
import Academy_main_Style from '../Academy/Academy_main_Style';
import apiFn from '../../asset/apiClass';
import util from '../../asset/util';
import aquery from '../../asset/Img/aquery.png'


const Book_share_list = ({
    userInfo,
}) => {
    let params = useParams();
    let navigate = useNavigate();
    let sort = [
        { name : "이름순" , value : "name desc"},
        { name : "개설순" , value : "reg_date desc"},
        { name : "수강인원순" , value : "lcnt desc"},
    ]


    var [classData,setClassData] = useState([]);
    var [sorting,setSorting] = useState("name")

    var [academyData,setAcademyData] = useState([]);

    const apiPro_read = async (id,sort) => {
        try {
            let where_obj = {}
            if(id){
                where_obj["HIQDB_class.pid"] = `='${id}'`
            }
            // // console.log("@@@ ",id);
            let data = await apiFn.comApi({
                state : {
                    table : "class",
                    page : 1,
                    amount : 9999,
                    u_id : userInfo["id"],
                    sort : sort ? sort : `name desc`,
                    ref : [
                        'HIQDB_member_class.cid|HIQDB_member_class.cid=HIQDB_class.id',
                        'HIQDB_member_class.mid|HIQDB_member_class.cid=HIQDB_class.id',
                        `B.name as academyName|HIQDB_class B on B.id=HIQDB_class.pid`,
                    ],
                    where : {
                        'HIQDB_member_class.mid':`='${userInfo["id"]}'`,   
                        'HIQDB_class.status':`='1'`,   
                        'HIQDB_member_class.status':`!='9'`,  
                        // 'HIQDB_class.status':`=!'9'`,   
                        ...where_obj
                    },
                    count : [
                        'HIQDB_member_class.mid:cid:id|and countTable.type =\'0\' and countTable.status=\'1\'|lcnt'
                        // 'HIQDB_member_class.mid:cid:id|and countTable.type =\'0\'|lcnt',
                        // "HIQDB_member_class.mid:cid:id|and countTable.type in ('5','6','7','8','9')|t_cnt"
                    ]

                }
            })


            // console.log("AAA ? ? ? ?",data)

            let no_share_array = []

            data["list"].map((v,i)=>{
                if(v["book"].length == 0){  
                    no_share_array.push(v)
                }
                else {
                    let bookList = v["book"].reduce((a,v,i)=>{
                        return [
                            ...a,
                            v["id"]
                        ]
                    },[])

                    // console.log("$$$$$$$$$$$$$$$$$$$ ",params["id"])
                    if(bookList.includes(Number(params["id"]))){
                    }
                    else {
                        no_share_array.push(v)
                    }
                    // console.log("## ",v);
                    // console.log("bookList => ",bookList);


                    // for(var i = 0; i < v["book"].length; i++){
                    //     if(v["book"][i]["id"] == params["id"])return false;
                    //     else {
                    //         no_share_array.push(v)
                    //     }
                    // }
                }
            })


            // console.log("@@@@@@@@@@@@@ ",no_share_array);

            setClassData([
                ...no_share_array
            ])
            
            // setClassData([
            //     ...data["list"]
            // ])
        }
        catch(e){
            // console.log(e);
        }
    }

    // 내 클래스룸 목록
    const apiPro_read_2  = async () => {
        try {
            let data = await apiFn.comApi({
                state : {
                    table : "member_class",
                    page : 1,
                    amount : 9999,
                    sort : "reg_date desc",
                    ref : [
                        'HIQDB_class.name|HIQDB_class.id=HIQDB_member_class.cid',
                        'HIQDB_class.intro|HIQDB_class.id=HIQDB_member_class.cid',
                        'HIQDB_class.address|HIQDB_class.id=HIQDB_member_class.cid',
                        'HIQDB_class.tel|HIQDB_class.id=HIQDB_member_class.cid',
                        'HIQDB_class.email|HIQDB_class.id=HIQDB_member_class.cid',
                        'HIQDB_class.owner|HIQDB_class.id=HIQDB_member_class.cid',
                        'HIQDB_class.pid|HIQDB_class.id=HIQDB_member_class.cid',
                    ],
                    where : {
                        'HIQDB_member_class.mid':`='${userInfo["id"]}'`,   
                        'HIQDB_class.status':`='1'`,   
                        'HIQDB_class.type':`='${params["who"] == "teacher" ? "0" : "1"}'`,   
                    }
                }
            })

            // console.log("data => ",data)

            let array = []
            let s_array = []
            if(params["who"] == "teacher"){
                data["list"].map(v=>{
                    // // console.log(v)
                    v["id"] = v["cid"]
                    if(v["status"] != 2){
                        array.push(v)
                    }
                })
                setAcademyData([
                    ...array
                ])
            }
            else {
                let reduceArray = data["list"].reduce((a,v,i)=>{
                    return [
                        ...a,
                        v["pid"]
                    ]
                },[])

                // console.log("reduceArray => ",reduceArray);
                let data2 = await apiFn.comApi({
                    state : {
                        table : "class",
                        page : 1,
                        amount : 9999,
                        sort : "reg_date desc",
                        where : {
                            'HIQDB_class.id':`in (${reduceArray})`,   
                        }
                    }
                })


                setAcademyData([
                    ...data2["list"]
                ])
            }
        }
        catch(e){
        }
    }

      // 교재 -> 강의 공유 
    const apiPro_set = async (data) => {
        try {
            let isOk = await apiFn.comApi({
                state : {
                    table : "book_link",
                    ins : [
                        {
                            fid : 0,
                            mid : 0,
                            qid : 0,
                            cid : data["id"],
                            bid : params["id"],
                        }
                    ]
                }
            })
            // /lecture/detail/teacher/260
            navigate(`/lecture/detail/${params["who"]}/${data["id"]}/de`)
        }
        catch(e){
        }
    }

    useEffect(()=>{
        apiPro_read();
        apiPro_read_2();
    },[])

    useEffect(()=>{
        // console.log("academyData => ",academyData)
    },[academyData])


    return (
        <Frame 
            title={"공유할 수업 추가"}
            isSearch={false}
            userInfo={userInfo}
        >
            <div style={Academy_main_Style.c}>
                <UiSilde
                    data={academyData}
                    apiPro={(v)=>{
                        apiPro_read(v["id"])
                    }}
                    // isPadding={false}
                    // isFull={false}
                />
                {
                classData.length > 0
                ?
                <div style={{
                    padding : "0px 15px 0px 15px",
                }}>
                <UiSorting
                    title='강의 '
                    cnt={classData.length+"개"}
                    data={sort} 
                    isTeacher={true}
                    fnClick={(e)=>{
                        // setSorting(e)
                        apiPro_read(null,e);
                    }}
                />
                <UiSearchList
                    data={classData}
                    form={2}
                    isClassStat={false}
                    isTeacher={true}
                    isAcademy={true}
                    isTitle={false}
                    fnClick={(d)=>{
                        let isOk = window.confirm(`[${d["name"]}] 강의에 교재를 공유 하시겠습니까?`)
                        if(isOk){
                            apiPro_set(d)
                        }
                        // navigate("/lecture/detail/"+params["who"]+"/"+d["cid"])
                    }}
                    
                />
                </div>
                :
                <div style={{
                    ...Academy_main_Style.searchList_empty_c,
                    // backgroundColor:"orange",
                }}>
                    <img  src={aquery} 
                        style={{
                            // ...Academy_main_Style.searchList_empty_img,
                            height : "25%",
                            // backgroundColor:"green",
                            paddingRight:"35px",
                            marginTop:"-100px",
                        }}
                    />
                    <div style={{
                        display:"flex",
                        flexDirection:"column",
                        alignItems:"center",
                        paddingTop : "20px",
                        // backgroundColor:"red",
                    }}>
                        <div>
                            공유할 수업이 없습니다.
                        </div>
                        {/* <div>
                            담당중인 수업이 없습니다.
                        </div>
                        <div>
                            가입된 클래스룸에서 수업을 직접 개설하시거나,
                        </div>
                        <div>
                            수업 관리자에게 가입을 신청해보세요.
                        </div> */}
                    </div>
                </div>
                }
            </div>
        </Frame>
    )
}

export default Book_share_list