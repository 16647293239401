import {React, useCallback, useEffect, useMemo, useState} from 'react'
import {useNavigate, useParams} from 'react-router';
import style from '../../../asset/style'
import Basic from '../../../common/frame/Basic'
import Register_One_style from './Register_One_style';
import DeactivateForm from './DeactivateForm';
import UiBtn from '../../../uiComponents/UiBtn/UiBtn';
import apiFn from '../../../asset/apiClass';
import styled from "styled-components";
import useTimer from "../../../hooks/UseTimer";
import {axiosInstance} from "../../../api/common";
import constants from "../../../asset/constants";

const DeactivateAccount = ({setUserInfo}) => {
    let navigate = useNavigate();

    const [inputAuthCode, setInputAuthCode] = useState('')
    const [phoneNumber, setPhoneNumber] = useState('')
    const [authCode, setAuthCode] = useState('')
    const [isAuthenticated, setIsAuthenticated] = useState(false)
    const {startTimer, resetTimer, formattedTime, isRunning} = useTimer(180);
    const [userData, setUserData] = useState({})

    const handelChangePhoneNumber = useCallback((event) => {
        const onlyNumber = event.target.value.replace(/[^0-9]/g, '')
        setPhoneNumber(onlyNumber)
    }, []);

    const handleSendPhoneNumber = useCallback(async () => {
        setUserData({})
        setUserInfo({})
        if (phoneNumber.length === 0) {
            return alert('전화번호를 입력해주세요.')
        } else if (phoneNumber.length < 10) {
            return alert('전화번호를 다시 확인해 주세요.')
        }
        try {
            let {code} = await apiFn.deactivateAccount({
                state: {
                    tel: phoneNumber,
                    userCheck: true,
                }
            });
            startTimer()
            setAuthCode(code.toString())
        } catch (e) {
            resetTimer()
            console.error(e)
        }
    }, [phoneNumber])

    const handleChangeAuthCode = useCallback((event) => {
        const onlyNumber = event.target.value.replace(/[^0-9]/g, '')
        setInputAuthCode(onlyNumber)
    }, [])

    const handleCheckAuthCode = useCallback(async () => {
        if (inputAuthCode !== authCode) {
            return alert('인증번호를 다시 확인해주세요.')
        } else if (inputAuthCode === authCode) {
            resetTimer()
            try {
                const {data} = await axiosInstance.get(`/user/phone/${phoneNumber}`)
                setUserInfo(data?.userInfo)
                setUserData(data?.userInfo)
                setIsAuthenticated(true);
            } catch (e) {
                console.error(e)
                setIsAuthenticated(false)
                if (e.response.status === 404) {
                    alert('해당 정보로 가입한 정보가 없습니다.')
                } else {
                    alert('정보를 가져오는데 실패했습니다.')
                }
            }

        }
    }, [inputAuthCode])

    useEffect(() => {
        if (!isRunning) {
            setInputAuthCode('')
        }
    }, [isRunning])

    const AcademyCode = {
        aio: '올인원 수학 학원',
        jym: '종로 영수 전문 학원',
        hsw: '부산 한수위 수학',
        msm: '명성 수학 학원',
        mds: '다원MDS',
        csd: '코스터디',
        wmm: '명작 수학',
        srk: '서로 국어',
        sne: 'SNEdu',
        mie: '매일의 수학'
    }

    const AcademyName = useMemo(() => {
        return AcademyCode[constants?.channelId] ?? 'HIQ(하이큐썸)'
    }, [])

    return (
        <Basic title={''}>
            <DeactivateForm
                title={`${AcademyName} 회원 탈퇴를 위한 정보 조회`}
            />
            <DeactiveAccountContent>
                현재 사용중인 <b>박주호의 {AcademyName}앱 </b>에서 계정 및 관련 데이터의 삭제를 요청 하시겠습니까? <br/>
                현재 앱에 가입중인지 확인이 필요합니다.<br/>
                전화번호 인증을 통하여 사용자인지 확인해주세요.<br/>
                한번 삭제하면 데이터는 영구적으로 날아가기 때문에 복구할 수 없습니다.<br/>
            </DeactiveAccountContent>
            <InputLabel htmlFor={'phone-number'}>
                [필수] 전화번호
            </InputLabel>
            <div style={{
                display: "flex",
                alignItems: "center",
            }}>
                <Input
                    type={'tel'}
                    id={'phone-number'}
                    placeholder={'전화번호를 입력해주세요.'}
                    onChange={handelChangePhoneNumber}
                    value={phoneNumber}
                    required
                />
                <Button onClick={handleSendPhoneNumber} type={'button'} disabled={isRunning}
                        className={isRunning ? 'disabled' : ''}>
                    {isRunning ? formattedTime : "발급"}
                </Button>
            </div>
            <InputLabel htmlFor={'auth-number'}>
                인증번호
            </InputLabel>
            <div style={{
                display: "flex",
                alignItems: "center",
            }}>
                <Input
                    type={'tel'}
                    id={'auth-number'}
                    placeholder={'인증 번호를 입력해주세요.'}
                    onChange={handleChangeAuthCode}
                    value={inputAuthCode}
                    required
                    disabled={!isRunning}
                />
                <Button onClick={handleCheckAuthCode} type={'button'} disabled={!isRunning}
                        className={!isRunning ? 'disabled' : ''}>
                    확인
                </Button>
            </div>
            {
                Object.keys(userData).length !== 0 &&
                <div>
                    <p>
                        <b>이름</b> : {userData?.name}
                    </p>
                    <p>
                        <b>닉네임</b> : {userData?.nick}
                    </p>
                    <p>
                        <b>전화번호</b> : {userData?.tel}
                    </p>
                    <p>
                        <b>사용자 타입</b> : {userData?.user_type === 2 ? '선생님' : '학생'}
                    </p>
                </div>
            }


            <div style={{...Register_One_style.b_c, paddingBottom: 0}}>
                <UiBtn
                    btnStyle={{
                        ...Register_One_style.b,
                        borderColor:
                            isAuthenticated ?
                                style.common.s_color
                                :
                                style.common.borderColor
                        ,
                        backgroundColor:
                            isAuthenticated ?
                                style.common.s_color
                                :
                                "rgb(200,200,200)"

                    }}
                    title="회원 탈퇴"
                    fnClick={() => {
                        if (isAuthenticated && (Object.keys(userData).length !== 0)) {
                            navigate(`/myinfom/${userData?.user_type === 2 ? 'teacher' : 'student'}/confirm`)
                        }
                    }}
                />
            </div>
            <footer style={{textAlign: "center"}}>
                <p>&copy; 2024 박주호. All rights reserved.</p>
            </footer>
        </Basic>
    )
}

export default DeactivateAccount

const Input = styled.input`
  width: calc(100% - 7px);
  height: 40px;
  border-radius: 5px;
  border: 1px solid rgb(200, 200, 200);
  padding: 0 0 0 5px;
  margin: 0;
`

const InputLabel = styled.label`
  display: block;
  margin: 16px 0;
`

const Button = styled.button`
  width: 83px;
  height: 42px;
  background-color: #f5b21a;
  border: 0;
  border-radius: 5px;
  color: #FFF;
  margin-left: 8px;

  &.disabled {
    background-color: #c8c8c8;
    cursor: default;
  }
`

const DeactiveAccountContent = styled.div`
  font-size: 16px;
  line-height: 1.5;

  b {
    font-size: 20px;
    color: #f5b21a;
  }
`