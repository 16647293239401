import { TouchEvent, useCallback, useRef } from "react";

const useDoubleTouch = (yourCustomFunction: (event?: TouchEvent<HTMLDivElement>) => void) => {
  const lastTabTimeRef = useRef(0);
  const onDoubleTap = useCallback(
    (event: TouchEvent<HTMLDivElement>) => {
      const currentTime = new Date().getTime();
      const tapLength = currentTime - lastTabTimeRef.current;
      if (tapLength < 500 && tapLength > 0) {
        yourCustomFunction(event);
      }
      lastTabTimeRef.current = currentTime;
    },
    [yourCustomFunction, lastTabTimeRef.current]
  );
  return onDoubleTap;
};

export default useDoubleTouch;
