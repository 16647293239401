import {useEffect, useState} from "react";

const UseTimer = (initialTime = 180) => {

    const [timeLeft, setTimeLeft] = useState(initialTime);
    const [isRunning, setIsRunning] = useState(false);

    useEffect(() => {
        let interval;
        if (isRunning && timeLeft > 0) {
            interval = setInterval(() => {
                setTimeLeft(prevTimeLeft => prevTimeLeft - 1)
            }, 1000);
        } else if (!isRunning || timeLeft === 0) {
            clearInterval(interval)
            resetTimer()
        }

        return () => clearInterval(interval);
    }, [isRunning, timeLeft])

    const startTimer = () => {
        setIsRunning(true);
    }
    const resetTimer = () => {
        setTimeLeft(initialTime);
        setIsRunning(false);
    };
    const formatTime = () => {
        const minutes = Math.floor(timeLeft / 60);
        const seconds = timeLeft % 60;

        return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
    }

    return {timeLeft, startTimer, resetTimer, formattedTime: formatTime(), isRunning}
}

export default UseTimer