export enum EndPoints {
  // book
  BOOK = `/book`,
  GET_QUESTIONS_BY_BOOK_ID = "qna-list?isAnswer=0",
  GET_ANSWERS_BY_ID = "qna-list?isAnswer=1",

  // search
  SEARCH_ANSWER = "/search/question",
}

export enum AccountApiEndpoints {
  SET_STAT_STORE = "/account/stat_store/:userId/set",
  GET_STAT_STORE = "/account/stat_store/:userId/:search_key",
}

export enum QueryKeys {
  USER_METADATA = "user_metadata",
}
