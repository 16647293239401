import style from "../../../asset/style";
import noBook from "../../../asset/Img/book1.png";
import constants from "../../../asset/constants";
import React, { ReactNode, useMemo } from "react";
import { formatToThreeDecimals } from "../../../asset/util";
import { questionSearchResponseData } from "../../../api/questionSearch";

type QuestionItemProps = {
  questionData: questionSearchResponseData;
  clickCard?: () => void;
  answerIcon?: ReactNode;
};
const QuestionListItem = ({ questionData, clickCard, answerIcon }: QuestionItemProps) => {
  const getImageUrl = (questionData: questionSearchResponseData) => {
    return questionData["original"]
      ? questionData["original_img"]
        ? `${constants.s3_url}/qna/${questionData["original_img"]}.jpg`
        : `${constants.s3_url}/qna/${questionData["original"]}.jpg`
      : questionData["img"]
        ? `${constants.s3_url}/qna/${questionData["img"]}.jpg`
        : `${constants.apiUrl}/qna/${questionData["id"]}.jpg`;
  };

  const questionImageUrl = useMemo(() => getImageUrl(questionData), [questionData]);
  const questionScore = useMemo(() => formatToThreeDecimals(questionData?.score), [questionData]);

  return (
    <>
      <div
        style={{
          display: "flex",
          backgroundColor: "#FFFBF5",
          padding: "15px 10px",
          marginBottom: "2px",
          borderRadius: "15px",
        }}
        onClick={clickCard}
      >
        <div
          style={{
            display: "flex",
            flexGrow: 1,
            minWidth: "110px",
            maxWidth: "110px",
            height: "96px",
            borderRadius: "5px",
            marginRight: "7px",
          }}
        >
          <img
            style={{
              width: "99.5%",
              height: "100%",
              objectFit: "cover",
              objectPosition: "top",
              border: "1px solid",
              borderColor: style.common.borderColor4,
              borderRadius: "5px",
            }}
            onError={(e) => {
              e.currentTarget.src = noBook;
            }}
            src={questionImageUrl}
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            flex: 1,
            flexGrow: 10,
            height: "96px",
            justifyContent: "space-around",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div
              style={{
                display: "inline-flex",
                fontSize: "14px",
                fontWeight: 500,
                marginRight: "3px",
                flexDirection: "column",
              }}
            >
              <div
                style={{
                  color: "#3D3A39",
                  marginLeft: "1px",
                }}
              >
                {questionData["title"]}
              </div>
              <div>문제ID: {questionData.id}</div>
              <div>score: {questionScore}</div>
              <div>{answerIcon}</div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default QuestionListItem;
