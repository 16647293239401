import type { IcDefatultProps } from "../types";
import React from "react";

export function IcUp({ width = 16, height = 16, color = "#434343" }: IcDefatultProps): JSX.Element {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.1667 10.0002L8.50004 5.3335L3.83337 10.0002"
        stroke={color}
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
