import { React, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router";
import style from "../../asset/style";
import UiExplain_Style from "./UiExplain_Style";
import noBook from "../../asset/Img/book1.png";
import constants from "../../asset/constants";
import util from "../../asset/util";
import { Image as IM, Link, MoreVert, PeopleAlt, Star, Videocam } from "@material-ui/icons";
import { useLongPress } from "use-long-press";
import { UiCheckBox } from "../UiCheckBox/UiCheckBox";
import "./UiExplain.css";
import { useDeleteQuestion, useToggleExemplary } from "../../api/question";

const UiExplain = ({
  scrollKey,
  isTeacher,
  isQna = false,
  data = {
    tid: "",
    sid: "",
  },
  fnClick,
  isEdit,
  selectData,
  fnLongClick,
  fnCheck,
  id,
  isMsg = false,
  setScrolllKey,
  isMsgSection = true,
  isDot = false,
  apiPro,
  apiPro_del,
  isSearch = false,
  index,
  // isManager,
  // isTeacherPage,
  // manageSub,
}) => {
  let navigate = useNavigate();
  let params = useParams();

  var [isPop, setIsPop] = useState(false);

  const apiPro_del_indi = async (data) => {
    try {
      // console.log("DD ", data)
      await useDeleteQuestion(data?.id);
      await apiPro();
    } catch (e) {
      console.log(e);
    }
  };

  const apiPro_mobum = async (isModi) => {
    try {
      await useToggleExemplary(data?.id, isModi ? "del" : 0);
      if (isModi) {
        alert("선택된 질문이 모범풀이 해제되었어요.");
      } else {
        alert("선택된 질문이 모범풀이에 등록되었어요.");
      }
      util.postWeb("isModal_off");
      setIsPop(false);
      await apiPro();
    } catch (e) {
      console.log(e);
    }
  };

  const bind = useLongPress(
    () => {
      try {
        // console.log("???");
        if (isTeacher) {
          fnLongClick(id);
          // if(isDot){
          //     console.log("$$66")
          //     setIsPop(true)
          // }
        }
      } catch (e) {
        console.log("Long Press Error => ", e);
      }
    },
    {
      threshold: 1000,
    }
  );

  // useEffect(()=>{
  //     console.log("UiExplain data => ",data)
  // },[data])

  var scrollRef = useRef(null);

  useEffect(() => {
    // console.log("data : ",data)
    if (scrollRef.current) {
      scrollRef.current.scrollIntoView({
        // behavior: "smooth",
        block: "center",
      });
      if (setScrolllKey) {
        setScrolllKey("");
      }
    }
  }, [scrollRef]);

  return (
    <div
      // id={}
      id={`msg_list_${index}`}
      ref={scrollKey == data["id"] ? scrollRef : null}
      style={{
        ...UiExplain_Style.c,
        // zIndex:0,
        position: "relative",
        // borderRadius:15,
        padding: isMsg ? "0px 0px 0px 0px" : isMsgSection ? "0px 0px 10px 0px" : "10px 0px",
        // boxShadow: "1px 1px 2px 2.5px rgba(90 ,90 ,90 , 0.2)",
        marginBottom: "10px",
        // borderTop : "1px solid",
        // borderColor : params["who"] == "teacher" ? style.common.t_color : style.common.s_color,
        // backgroundColor :
        // isEdit ?
        // (selectData[id] == "Y" ? style.common.t_color : "white")
        // :
        // "white"
      }}
      onClick={() => {
        if (isEdit) {
          // fnCheck();
        } else {
          fnClick(data);
        }
      }}
    >
      {isEdit && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginRight: "10px",
            position: "absolute",
            top: 5,
            left: 5,
          }}
        >
          <UiCheckBox
            className={isTeacher ? "t" : "s"}
            // value={"?"}
            // className={"message_?"}
            checked={selectData[id] == "Y" ? true : false}
            value={id}
            fnClick={fnCheck}
            containerStyle={{
              padding: "0px",
            }}
          />
        </div>
      )}
      <div
        style={
          isEdit
            ? {
                ...UiExplain_Style.w_1,
                backgroundColor: "white",
                transitionDuration: "0s",
                minHeight: isMsgSection ? "0px" : "54px",
              }
            : {
                ...UiExplain_Style.w_1,
                transitionDuration: "2s",
                minHeight: isMsgSection ? "0px" : "54px",
              }
        }
        {...bind()}
        className="header_ex"
      >
        {/* 제거 주석 (project 6) */}
        {/* {
                    !isMsgSection &&
                    <div style={UiExplain_Style.w_1_i_w}>
                        {
                        Object.keys(data).length > 0 &&
                        <img 
                            onError={(e)=>{
                                e.currentTarget.src = isTeacher ? face_student : face_teacher
                            }}
                            src={
                                data &&
                                (
                                !data["sid"]  ?
                                    (
                                        data["img"] ?
                                        constants.s3_url
                                        +
                                        '/public/teacher/'+data["t_img"]+".jpg?"
                                        :
                                        constants.apiUrl
                                        +
                                        '/public/teacher/'+data["tid"]+".jpg?"
                                    )
                                :
                                (
                                    params["who"] == "student" ? 
                                    data["tid"] &&
                                    (
                                        data["img"] ?
                                        constants.s3_url
                                        +
                                        '/public/teacher/'+data["t_img"]+".jpg?"
                                        :
                                        constants.apiUrl
                                        +
                                        '/public/teacher/'+data["tid"]+".jpg?"
                                    )
                                    :
                                    data["sid"] &&
                                    (
                                        data["img"] ?
                                        constants.s3_url
                                        +
                                        '/public/student/'+data["s_img"]+".jpg?"
                                        :
                                        constants.apiUrl
                                        +
                                        '/public/student/'+data["sid"]+".jpg?"
                                    )
                                )
                                )
                            }
                            style={UiExplain_Style.w_1_i}
                        />
                        }
                    </div>
                    } */}
        <div
          style={{
            ...UiExplain_Style.w_1_desc_w,
            // padding : !isMsgSection ? "0px" : "0px 5px",
            padding: isDot ? "0px 0px 0px 5px" : "0px 5px",
          }}
        >
          <div style={UiExplain_Style.w_1_desc_v}>
            <div style={UiExplain_Style.w_1_desc_v_t_w}>
              <div
                style={{
                  ...UiExplain_Style.w_1_desc_v_t_w_l_w,
                }}
              >
                <div
                  style={{
                    // ...style.common.line_text,
                    display: "flex",
                    fontWeight: "bold",
                    verticalAlign: "center",
                    // textAlign:"center",
                    // marginLeft: "5px",
                    fontSize: "14px",
                    // paddingRight:"30px",
                  }}
                >
                  <div
                    style={{
                      // ...UiExplain_Style.textline_1,
                      // backgroundColor:"orange",
                      // display:"inline-flex",
                      display: "inline",
                      alignItems: "center",
                      fontSize: "14px",
                      marginRight: "3px",
                      color: data["gicul_rate"]
                        ? style.common.s_color
                        : data["ans_date"]
                          ? style.common.fontColor_3
                          : style.common.t_color,
                      // isQna ?
                      // style.common.t_color
                      // :
                      // style.common.fontColor_3
                    }}
                  >
                    {Object.keys(data).length > 0 &&
                      (data["gicul_rate"]
                        ? "[기출]"
                        : data["qid"] == 0
                          ? "[일반]"
                          : data["ans_date"]
                            ? !data["original"] || data["original"] == 0
                              ? "[답변완료]"
                              : "[추가질문완료]"
                            : data["original"] == 0
                              ? data["member_cnt"]
                                ? data["member_cnt"] == 1
                                  ? "[질문]"
                                  : "[단체질문]"
                                : "[질문]"
                              : data["member_cnt"]
                                ? data["member_cnt"] == 1
                                  ? "[추가질문]"
                                  : "[단체질문]"
                                : "[추가질문]")}
                  </div>
                  <div
                    style={{
                      display: "flex",
                      lineHeight: "1.6em",
                      maxHeight: "1.6em",
                      minHeight: "1.6em",
                      marginLeft: "3px",
                      fontSize: "14px",
                      maxWidth: "80%",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {data["title"]}
                  </div>
                  {/* {
                                        isMsg ?
                                        util.textTrim(data["title"],9)
                                        :
                                        (
                                            (data["mobum_date"] && data["mobum_date"] != 0) ?
                                            util.textTrim(data["title"],10)
                                            :
                                            util.textTrim(data["title"],20)

                                        )
                                        } */}
                </div>
                {data["member_cnt"] ? (
                  data["member_cnt"] > 1 && (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        fontSize: "14px",
                        marginLeft: "3px",
                        color: style.common.t_color,
                        // color : params["who"] == "teacher" ? style.common.t_color : style.common.s_color,
                      }}
                    >
                      <PeopleAlt
                        style={{
                          fontSize: "14px",
                        }}
                      />
                      <div
                        style={{
                          fontSize: "12px",
                        }}
                      >
                        {data["member_cnt"]}
                      </div>
                    </div>
                  )
                ) : (
                  <></>
                )}
              </div>
              <div
                style={{
                  display: "flex",
                }}
              >
                {data["leftover"] ? (
                  <div
                    style={{
                      minWidth: "40px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        // width : "40px",
                        fontSize: "11px",
                        padding: "2px 3px",
                        backgroundColor: style.common.fontColor_3,
                        color: "white",
                        borderRadius: 5,
                      }}
                    >
                      {data["leftover"] + "일"}
                    </div>
                  </div>
                ) : data["mobum_date"] && data["mobum_date"] != 0 ? (
                  <div
                    style={{
                      minWidth: "58px",
                    }}
                  >
                    <div
                      style={{
                        ...UiExplain_Style.w_1_desc_v_t_w_r_w,
                        // width:"20%",
                        // maxWidth:'100px',

                        // flex:1,
                        marginLeft: isMsg ? "5px" : "0px",
                        // marginLeft:"40px",
                        backgroundColor:
                          params["who"] == "teacher" ? style.common.t_color : style.common.s_color,
                      }}
                    >
                      모범 답안
                    </div>
                  </div>
                ) : (
                  <></>
                )}
                {isDot && (
                  <MoreVert
                    style={{
                      fontSize: "20px",
                      marginRight: "-5px",
                      // marginLeft:"2px",
                    }}
                    onClick={(e) => {
                      setIsPop(true);
                      e.stopPropagation();
                      e.defaultPrevented();
                    }}
                  />
                )}
              </div>
            </div>
            <div>
              <div
                style={{
                  ...UiExplain_Style.text_3,
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: isMsgSection ? "0px" : "3px",
                  paddingRight: "5px",
                }}
              >
                {/* 쎈 고등수학 1 (2021) */}
                {data["bookName"]}
                {(isMsgSection || isSearch) && (
                  <div style={{}}>
                    {data["ans_date"]
                      ? util.convertToDateTime(data["ans_date"])
                      : util.convertToDateTime(data["reg_date"])}
                  </div>
                )}
              </div>
              {/* 제거 주석 (project 6) */}
              {/* {
                                !isMsgSection &&
                                <div style={{
                                    ...UiExplain_Style.w_1_desc_v_b_w,
                                    minWidth : "100px",
                                    flexDirection: !isMsg ? "row" : "column",
                                    alignItems : !isMsg ? "center" : "flex-start"
                                }}>
                                    {
                                        Object.keys(data).length > 0 &&
                                        <>
                                        <div style={{
                                            display : "flex",
                                        }}>
                                            {
                                            !data['sid'] ?
                                            <div style={{
                                            }}>
                                                {`${data["teacherName"]} 선생님`}
                                            </div>
                                            :
                                            <div style={{
                                            }}>
                                                {params["who"] == "student" ?  `${data["teacherName"]} 선생님` : `${data["nick"]} `}
                                            </div>
                                            }
                                            {
                                            data["className"] &&
                                            <div style={{
                                                marginLeft : "3px"
                                            }}>
                                                ({data["className"]})
                                            </div>
                                            }
                                        </div>
                                        <div>
                                            {util.convertToDateTime(data["qna_date"])}
                                        </div>
                                        </>
                                    }
                                </div>
                                } */}
            </div>
          </div>
        </div>
      </div>
      <div style={UiExplain_Style.w_2}>
        {Object.keys(data).length > 0 && (
          <img
            className={data["mosaic"] == "y" ? "mosaic" : null}
            style={{
              width: "99.5%",
              // height:"100%",
              objectFit: "cover",
              objectPosition: "top",
              border: "1px solid",
              borderColor: style.common.borderColor4,
            }}
            onError={(e) => {
              if (data["original"]) {
                try {
                  // e.currentTarget.src = `${constants.apiUrl}/public/qna/${data["original"]}.jpg`
                } catch (e) {}
                // e.currentTarget.src = `${constants.apiUrl}/public/qna/${data["original"]}.jpg?${util.getRandomInt(1000,9999)}`
              } else {
                e.currentTarget.src = noBook;
              }
            }}
            src={
              data["original"]
                ? data["original_img"]
                  ? `${constants.s3_url}/qna/${data["original_img"]}.jpg`
                  : `${constants.s3_url}/qna/${data["original"]}.jpg`
                : data["img"]
                  ? `${constants.s3_url}/qna/${data["img"]}.jpg`
                  : `${constants.apiUrl}/qna/${data["id"]}.jpg`
            }
          />
        )}
      </div>
      {!isMsg && (
        <div
          style={{
            ...UiExplain_Style.w_3,
            // height : isMsgSection ? "auto" : "60px",
            height: "auto",
            display: data["ctype"] == 0 && !data["lastword"] && "none",
            // backgroundColor:"orange",
          }}
        >
          <div
            style={{
              padding: "10px 5px",
            }}
          >
            {/* 풀이의 이해가 잘 됐으면 한다. */}
            {/* {data["lastword"]} */}
            {isMsgSection ? (
              data["content"].includes("&contentType") ? (
                "새 질문이 등록되었습니다."
              ) : data["content"].includes("ctype=0&") ? (
                util.textTrim(
                  data["content"].replace(/<br>/gi, "\n").split("ctype=0&content=")[1],
                  35
                )
              ) : data["content"].includes("ctype=1&") ? (
                <div style={{ display: "flex" }}>
                  <IM
                    style={{
                      color: style.common.t_color,
                      fontSize: "18px",
                      marginRight: "2px",
                    }}
                  />
                  {/* image.jpg */}
                  이미지
                </div>
              ) : data["content"].includes("ctype=2&") ? (
                <div style={{ display: "flex" }}>
                  <Videocam
                    style={{
                      color: style.common.t_color,
                      fontSize: "18px",
                      marginRight: "3px",
                    }}
                  />
                  영상
                </div>
              ) : data["content"].includes("ctype=3&") ? (
                <div style={{ display: "flex" }}>
                  <Videocam
                    style={{
                      color: style.common.t_color,
                      fontSize: "18px",
                      marginRight: "3px",
                    }}
                  />
                  {/* video.mp4 */}
                  영상
                </div>
              ) : data["content"].includes("ctype=6&") ? (
                <div style={{ display: "flex" }}>
                  {`${data["content"].split("=")[2]} 선생님께 질문이 이관되었어요.`}
                </div>
              ) : data["content"].includes("ctype=7&") ? (
                <div style={{ display: "flex" }}>{`${data["content"].split("=")[2]}`}</div>
              ) : data["content"].includes("ctype=8&") ? (
                <div style={{ display: "flex" }}>
                  <Link
                    style={{
                      color: style.common.t_color,
                      fontSize: "18px",
                      marginRight: "3px",
                    }}
                  />
                  {/* video.mp4 */}
                  질문방 링크
                </div>
              ) : data["content"].includes("ctype=9&") ? (
                <div style={{ display: "flex" }}>
                  {`${data["content"].split("=")[2]}님이 질문방에 참여하셨어요.`}
                </div>
              ) : data["content"].includes("ctype=10&") ? (
                <div style={{ display: "flex" }}>답변이 완료되었습니다.</div>
              ) : (
                util.textTrim(data["content"].replace(/<br>/gi, "\n"), 35)
              )
            ) : data["ctype"] == 0 ? (
              <div dangerouslySetInnerHTML={{ __html: data["lastword"] }}></div>
            ) : data["ctype"] == 1 ? (
              <div style={{ display: "flex" }}>
                <IM
                  style={{
                    color: style.common.t_color,
                    fontSize: "18px",
                    marginRight: "2px",
                  }}
                />
                이미지
              </div>
            ) : data["ctype"] == 2 ? (
              <div style={{ display: "flex" }}>
                <Videocam
                  style={{
                    color: style.common.t_color,
                    fontSize: "18px",
                    marginRight: "3px",
                  }}
                />
                영상
              </div>
            ) : data["ctype"] == 3 ? (
              <div style={{ display: "flex" }}>
                <Videocam
                  style={{
                    color: style.common.t_color,
                    fontSize: "18px",
                    marginRight: "3px",
                  }}
                />
                영상
              </div>
            ) : (
              data["ctype"] == 8 && (
                <div style={{ display: "flex" }}>
                  <Link
                    style={{
                      color: style.common.t_color,
                      fontSize: "18px",
                      marginRight: "3px",
                    }}
                  />
                  질문방 링크
                </div>
              )
            )}
          </div>
        </div>
      )}
      {!isMsg && (
        <div style={UiExplain_Style.w_4}>
          <div style={UiExplain_Style.w_4_v}>
            {data["starAvg"] ? (
              <div
                style={{
                  display: "flex",
                }}
              >
                <Star
                  style={{
                    color: "orange",
                    fontSize: "14px",
                    marginRight: "2px",
                  }}
                />
                {/* 4.0 (2) */}
                {`${data["starAvg"]} (${data["starCnt"]})`}
              </div>
            ) : (
              ``
            )}
            <div style={{}}>
              {/* 12,345명 열람 | 저도요 9,876 */}
              {isMsgSection
                ? // data["metooCnt"] &&
                  // `저도요 ${data["metooCnt"]}`
                  // :
                  // `${data["readCnt"]}명 열람 | 저도요 ${data["metooCnt"]}`
                  data["readCnt"] && `${data["readCnt"]}명 열람`
                : ``}
            </div>
          </div>
        </div>
      )}
      {isPop && (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            position: "absolute",
            top: 10,
            right: 10,
            border: "1px solid",
            borderColor: style.common.borderColor,
            padding: "5px 10px",
            fontSize: "12px",
            backgroundColor: "white",
          }}
        >
          {data["mobum_date"] ? (
            <DotPop
              text="모범 해제"
              fnClick={() => {
                let isOk = window.confirm("모범 풀이 해제를 하시겠어요?");
                if (isOk) {
                  apiPro_mobum(true);
                }
              }}
            />
          ) : (
            <DotPop
              text="모범 등록"
              fnClick={() => {
                let isOk = window.confirm("모범 풀이 등록을 하시겠어요?");
                if (isOk) {
                  apiPro_mobum(false);
                }
              }}
            />
          )}
          <DotPop
            text="교재 이동"
            fnClick={() => {
              console.log(data["id"]);
              util.postWeb("isModal_off");
              navigate(`/explainmodel/${params["who"]}/${data["id"]}/move/n`);
              setIsPop(false);
            }}
          />
          {data["mobum_date"] ? (
            <DotPop
              text="교재 복사"
              fnClick={() => {
                console.log(data["id"]);
                util.postWeb("isModal_off");
                navigate(`/explainmodel/${params["who"]}/${data["id"]}/copy/n`);
                setIsPop(false);
              }}
            />
          ) : (
            <></>
          )}
          <DotPop
            text="질문 삭제"
            fnClick={(e) => {
              let isOk = window.confirm("질문을 삭제하시겠어요?");
              if (isOk) {
                // apiPro_del(data)
                apiPro_del_indi(data);
                setIsPop(false);
              }
              e.preventDefault();
              e.stopPropagation();
            }}
          />
          <DotPop
            text="메뉴 닫기"
            fnClick={() => {
              setIsPop(false);
            }}
          />
        </div>
      )}
    </div>
  );
};

const DotPop = ({ text, fnClick }) => {
  let params = useParams();

  return (
    <div
      style={{
        padding: "2px 0px 1px 0px",
        fontSize: "16px",
        color: params["who"] == "teacher" ? style.common.t_color : style.common.s_color,
        fontWeight: 500,
      }}
      onClick={(e) => {
        fnClick(e);
        e.stopPropagation();
        e.preventDefault();
      }}
    >
      {text}
    </div>
  );
};

export default UiExplain;
