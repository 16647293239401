import { gql } from "@apollo/client";

export const BOOK_INFO = gql`
  query getBook($bookId: ID!) {
    getBook(bookId: $bookId) {
      success
      error {
        message
        code
      }
      book {
        id
        name
        content
        open
        usee
        reg_id
        reg_date
        status
        img
        book_pw
        approve
        book_type
        reg_id_index
        HIQDB_book_links {
          fid_HIQDB_folder {
            id
            name
            ftype
            mid
            content
            open
            usee
            pid
            depth
            mid_index
          }
        }
      }
    }
  }
`;

export const BOOK_UPDATE = gql`
  mutation updateBook($bookId: ID!, $input: BookInput) {
    updateBook(bookId: $bookId, input: $input) {
      success
      error {
        code
        message
      }
      book {
        id
        name
        content
        open
        usee
        reg_id
        reg_date
        status
        img
        book_pw
        approve
        book_type
        reg_id_index
      }
    }
  }
`;

export const UPDATE_BOOK_FOLDER = gql`
  mutation updateBookFolder($input: BookFolderInput) {
    updateBookFolder(input: $input) {
      success
      error {
        code
        message
      }
      bookFolder {
        id
        name
        ftype
        mid
        content
        open
        usee
        pid
        depth
        mid_index
      }
    }
  }
`;
