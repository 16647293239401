import React, { useEffect, useState } from "react";
import { useRecoilState, useSetRecoilState } from "recoil";
import { editShowModalAtom } from "../../../../../application/recoils/modal/atoms";
import { EditModalEnum } from "../../../../../application/recoils/modal/types";
import {
  Container,
  ContentSection,
  ContentWrapper,
  MultiWindowButton,
  MultiWindowWrapper,
  StyledDialog,
} from "./styled";
import EditAppbar from "../../appbar";
import { useParams } from "react-router";
import { useGetRefreshOcr } from "../../../../../api/questionSearch";
import LatexEditor from "../../LatexEditor";
import { Refresh } from "@mui/icons-material";
import { questionContentAtom } from "../../../../../application/recoils/question/atoms";
import { showToast } from "../../../../../providers/ToastProvider";
import { useSocket } from "../../../../../providers/socket";
import {
  DraggingModalAtom,
  showMessageLoadingAtom,
} from "../../../../../application/recoils/ui/atoms";
import { DraggingModalEnum } from "../../../../../application/recoils/ui/types";
import DraggableModal from "../../draggableModal";

export default function EditQuestionModal(): JSX.Element {
  const [EditModalData, setEditModalData] = useRecoilState(editShowModalAtom);
  const [questionContent, setQuestionContent] = useRecoilState(questionContentAtom);
  const setLoadingMessage = useSetRecoilState(showMessageLoadingAtom);
  const setShowMultimodal = useSetRecoilState(DraggingModalAtom);

  const { mutate, data, isError, isSuccess } = useGetRefreshOcr();
  const params = useParams();
  const [editQuestionContent, setEditQuestionContent] = useState<string>(questionContent);
  const [editDisabled, setEditDisabled] = useState<boolean>(false);
  const { semohaeSocket } = useSocket();

  const handleClose = () => {
    setEditModalData(EditModalEnum.EMPTY);
    setShowMultimodal(DraggingModalEnum.EMPTY);
  };

  const handleRefreshOcr = async () => {
    mutate(`qna/${params.qid}.jpg`);
    setEditQuestionContent("문제를 재인식 중입니다...");
    setEditDisabled(true);
  };

  const handleCloseModal = () => {
    setEditModalData(EditModalEnum.EMPTY);
    setShowMultimodal(DraggingModalEnum.EMPTY);
  };

  const handleEditQuestionContent = (event) => {
    setEditQuestionContent(event.target.value);
  };

  const handleComplete = () => {
    if (semohaeSocket) {
      setLoadingMessage(true);
      semohaeSocket.emit("message", {
        id: params.qid,
        message: editQuestionContent,
      });
    }
    handleCloseModal();
  };

  const handleOpenModal = (modalType: DraggingModalEnum) => {
    setShowMultimodal(modalType);
  };

  useEffect(() => {
    if (isSuccess) {
      setEditQuestionContent(data.result);
      showToast({
        title: "성공",
        message: "문제 재인식에 성공했습니다.",
        type: "success",
      });
      setEditDisabled(false);
    }
  }, [isSuccess]);

  useEffect(() => {
    if (questionContent) setEditQuestionContent(questionContent);
  }, [questionContent]);

  return (
    <StyledDialog open={EditModalData === EditModalEnum.QUESTION} onClose={handleClose} fullScreen>
      <Container>
        <EditAppbar
          appBarTitle={"문제 수정"}
          onCloseModal={handleCloseModal}
          onCompleteEdit={handleComplete}
        />
        <ContentWrapper>
          <ContentSection>
            <LatexEditor
              titleActionButtonContent={<Refresh width={16} height={16} sx={{ color: "#FFF" }} />}
              onTitleActionButtonClick={handleRefreshOcr}
              content={editQuestionContent}
            />
          </ContentSection>
          <ContentSection>
            <LatexEditor
              mode={"edit"}
              themeType={"dark"}
              onEditMessage={handleEditQuestionContent}
              content={editQuestionContent}
              editDisabled={editDisabled}
            />
          </ContentSection>
          <MultiWindowWrapper>
            <MultiWindowButton onClick={() => handleOpenModal(DraggingModalEnum.QUESTION)}>
              문제이미지
            </MultiWindowButton>
          </MultiWindowWrapper>
        </ContentWrapper>
        <DraggableModal />

        {isError &&
          showToast({
            title: "에러",
            message: "문제 재인식에 실패했습니다.",
            type: "error",
          })}
      </Container>
    </StyledDialog>
  );
}
