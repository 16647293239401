import { useMessageMarkFolderList } from "api/messageMark";
import React, { useContext, useEffect, useMemo } from "react";
import styled, { css } from "styled-components";
// import FolderRegistModal from "./FolderRegistModal";
import InfiniteScroll from "react-infinite-scroll-component";
import { MessageMarkContext } from "../context/MessageMarkContextProvider";
import FolderItem, { LazyFolderItem, RecentFolderItem } from "./FolderItem";
import FolderRegistItem from "./FolderRegistItem";
import FolderRegistModal from "./FolderRegistModal";

const FolderList = () => {
  const { visibleMenuId, modifyFolderId, setModifyFolderId, setIsDeleteMode, isDeleteMode } =
    useContext(MessageMarkContext);
  const { data, fetchNextPage } = useMessageMarkFolderList();

  const folderList = useMemo(() => {
    return data?.pages?.flatMap((page) => page.list) ?? [];
  }, [data]);

  const selectedFolder = useMemo(() => {
    return folderList.find((folder) => folder.id === modifyFolderId);
  }, [folderList, modifyFolderId]);

  useEffect(() => {
    if (!folderList || folderList.length === 0) {
      setIsDeleteMode(false);
    }
  }, [folderList]);
  return (
    <>
      <Container isDeleteMode={isDeleteMode}>
        <InfiniteScroll
          dataLength={folderList?.length ?? 0}
          next={fetchNextPage}
          loader={<></>}
          scrollableTarget="scrollableDiv"
          hasMore={folderList?.length > 0 && folderList?.[0].tcnt > folderList?.length}
        >
          <FolderListContainer>
            <FolderRegistItem />
            <RecentFolderItem />
            <LazyFolderItem />
            {folderList?.map((folder, index) => (
              <FolderItem key={folder.id} {...folder} index={index + 2} />
            ))}
            {visibleMenuId && <FilterBackdrop />}
          </FolderListContainer>
        </InfiniteScroll>
      </Container>

      {!!modifyFolderId && (
        <FolderRegistModal
          type="modify"
          open={!!modifyFolderId}
          onClose={() => setModifyFolderId(null)}
          folderId={modifyFolderId}
          originFolderName={selectedFolder.name}
        />
      )}
    </>
  );
};

const Container = styled.div<{ isDeleteMode: boolean }>`
  flex: 1;
  transition: all 0.3s;
  ${({ isDeleteMode }) =>
    isDeleteMode &&
    css`
      margin-bottom: 60px;
    `}
`;

const FolderListContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, minmax(100px, 1fr));
  gap: 10px 16px;
  padding: 20px;
  position: relative;
`;

const FilterBackdrop = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  flex: 1;
  background-color: rgba(0, 0, 0, 0);
  backdrop-filter: blur(5px);
  z-index: 1;
`;
export default FolderList;
