import OpenInNewRoundedIcon from "@mui/icons-material/OpenInNewRounded";
import PictureInPictureAltRoundedIcon from "@mui/icons-material/PictureInPictureAltRounded";
import { VideoContext } from "components/molecules/VideoPlayer";
import React, { useCallback, useContext } from "react";
import styles from "./style.module.scss";

const PipControl = () => {
  const { player, playerState } = useContext(VideoContext);
  const handlePlaying = useCallback(() => {
    if (playerState.isInPictureInPicture) {
      player.exitPictureInPicture();
    } else {
      player.requestPictureInPicture();
    }
  }, [playerState.isInPictureInPicture, player]);

  return (
    <button className={styles.button} onClick={handlePlaying}>
      {playerState.isInPictureInPicture ? (
        <OpenInNewRoundedIcon />
      ) : (
        <PictureInPictureAltRoundedIcon />
      )}
    </button>
  );
};

export default PipControl;
