import { ArrowBackIos, Bookmark, BookmarkBorder } from "@material-ui/icons";

import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import { useClassDetailInfo, useIsPick, useRemovePick } from "api/classDetail";
import { getUser } from "asset/storage";
import Header from "components/molecules/Header";
import useQueryString from "hooks/useQueryString";
import React, { useCallback, useMemo } from "react";
import { useLocation, useNavigate, useParams } from "react-router";
import styled from "styled-components";
import ModifyButtons from "./ModifyButtons";

const ClassDetailHeader = () => {
  const params = useParams();
  const { data: classDetail } = useClassDetailInfo({
    boardId: params["id"],
  });

  const { mutate } = useRemovePick();
  const { data } = useIsPick({
    boardId: params["id"],
  });
  const location = useLocation();

  const userInfo = getUser();
  const classData = useMemo(() => classDetail?.getBoard?.boards[0]?.cid_HIQDB_class, [classDetail]);
  const isModify = useMemo(
    () => userInfo?.type === 2 && userInfo?.id === classDetail?.getBoard?.boards[0]?.mid,
    [classDetail]
  );
  const isPick = useMemo(() => data?.list.length > 0, [data?.list.length]);
  const navigate = useNavigate();
  const handleFavorite = useCallback(() => {
    if (isPick) {
      let isOk = window.confirm("찜 취소를 하시겠어요?");
      if (isOk) {
        mutate({
          boardId: params["id"],
        });
      }
    } else {
      navigate(`/picklist/${params["who"]}/${params["id"]}`);
    }
  }, []);

  const { getQueryString, setQueryString, removeQueryString } = useQueryString();
  const showModify = useMemo(() => getQueryString("showModify"), [getQueryString]);

  const isShowModify = useMemo(() => showModify === "true", [showModify]);

  const handleModifyModal = useCallback(
    (show: boolean) => {
      if (show) {
        setQueryString("showModify", "true");
      } else {
        navigate(-1);
      }
    },
    [isShowModify]
  );
  return (
    <>
      <Header
        title={classData?.name}
        leftIcon={<ArrowBackIos />}
        onLeftClick={() => {
          navigate(-1);
        }}
        rightIcon={
          <RightIconContainer>
            {isModify && (
              <a onClick={() => handleModifyModal(true)}>
                <ModeEditOutlineOutlinedIcon />
              </a>
            )}
            {userInfo && (
              <a onClick={handleFavorite}>
                {isPick ? (
                  <Icon>
                    <Bookmark />
                  </Icon>
                ) : (
                  <BookmarkBorder />
                )}
              </a>
            )}
          </RightIconContainer>
        }
      />
      <ModifyButtons
        open={isShowModify}
        onClose={() => {
          handleModifyModal(false);
        }}
      />
    </>
  );
};

const Icon = styled.i`
  > svg {
    color: #ffa723;
  }
`;

const RightIconContainer = styled.div`
  display: flex;
  gap: 8px;
`;

export default ClassDetailHeader;
