import { Check, Clear } from "@material-ui/icons";
import { CircularProgress } from "@mui/material";
import { React, useEffect, useRef, useState } from "react";
import { useParams } from "react-router";
import style from "../../asset/style";
import { UiCheckBox } from "../UiCheckBox/UiCheckBox";
import UiTextBtn from "../UiTextBtn/UiTextBtn";
import UiPeople_Style from "./UiPeople_Style";

const UiPeople_Type2 = ({
  isStat,
  scrollKey,
  setScrolllKey,
  setHiddenNav,
  myInfo = {},
  isPeople = false,
  userInfo,
  data = [],
  selectData,
  isOnly = false,
  isEdit = false,
  isTeacher = false,
  fnClick,
  fnLongClick,
  isBlank = true,
  fnCheck,
  isOpenOk,
  isTeacherPage = false,
  manageSub = false,
  form = 1,
  isInfo = false,
  isExpel = false,
  isSearch = false,
  isBookManager = false,
  fnApprove,
  fnReject,
  fnExit,
  fnUp,
  fnAssist,
  fnDown,
  fnBookUp,
  fnExpel,
  fnQna,
  fnTalk,
  fnQan_see,
}) => {
  useEffect(() => {
    console.log("@@ ", data);
  }, [data]);

  return (
    <div
      style={{
        width: "100%",
        // display:"flex",
        // flexDirection:"column",
        // flex:1,
      }}
    >
      {data === null ? (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <CircularProgress />
        </div>
      ) : data.length > 0 ? (
        data.map((v, i) => (
          <UiPeopleSection
            key={v.id}
            isStat={isStat}
            scrollKey={scrollKey}
            setScrolllKey={setScrolllKey}
            setHiddenNav={setHiddenNav}
            fnQna={fnQna}
            fnQan_see={fnQan_see}
            fnBookUp={fnBookUp}
            fnExpel={fnExpel}
            isBookManager={isBookManager}
            userInfo={userInfo}
            fnAssist={fnAssist}
            fnDown={fnDown}
            fnExit={fnExit}
            fnUp={fnUp}
            isSearch={isSearch}
            isOnly={isOnly}
            data={v}
            selectData={selectData}
            fnCheck={fnCheck}
            id={v["id"]}
            isEdit={isEdit}
            isTeacher={isTeacher}
            fnClick={fnClick}
            fnLongClick={fnLongClick}
            isOpenOk={isOpenOk}
            isTeacherPage={isTeacherPage}
            manageSub={manageSub}
            isPeople={isPeople}
            form={form}
            isInfo={isInfo}
            isExpel={isExpel}
            fnApprove={fnApprove}
            fnReject={fnReject}
            fnTalk={fnTalk}
          />
        ))
      ) : (
        ""
      )}
    </div>
  );
};

const UiPeopleSection = ({
  isStat,
  scrollKey,
  setScrolllKey,
  setHiddenNav,
  isPeople,
  isBookManager,
  userInfo = {},
  data,
  id,
  selectData,
  isEdit,
  isOnly,
  isTeacher,
  isTeacherPage,
  manageSub,
  fnClick,
  fnLongClick,
  fnCheck,
  isOpenOk = true,
  form,
  isInfo = false,
  isExpel,
  isSearch,
  fnApprove,
  fnReject,
  fnExit,
  fnUp,
  fnAssist,
  fnDown,
  fnBookUp,
  fnExpel,
  fnQna,
  fnTalk,
  fnQan_see,
}) => {
  let params = useParams();

  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    // console.log("UiPeopleSection  =>",data)
    // console.log("UiPeopleSection type =>",data["type"])
    // console.log("UiPeopleSection status =>",data["status"])
  }, [data]);

  // useEffect(()=>{
  //     // console.log("isOnly => " ,isOnly)
  // },[isOnly])

  var scrollRef = useRef(null);

  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollIntoView({
        // behavior: "smooth",
        block: "center",
      });
      // scrollKey = ""
      if (setScrolllKey) {
        setScrolllKey("");
      }
    }
  }, [scrollRef]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        flex: 1,
        maxWidth: style.common.maxWidth,
        minHeight: "24px",
        padding: "10px 0px",
      }}
      ref={scrollKey == data["id"] ? scrollRef : null}
    >
      <div
        style={{
          // ...UiPeople_Style.section_c,
          width: "100%",
          display: "flex",
          // justifyContent:"space-between",
          alignItems: "center",
        }}
      >
        {userInfo["id"] != data["id"] && isEdit && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginRight: "10px",
            }}
          >
            <UiCheckBox
              className={isTeacher ? "t" : "s"}
              // value={"?"}
              // className={"message_?"}
              checked={selectData[id] == "Y" ? true : false}
              value={id}
              fnClick={fnCheck}
              containerStyle={{
                padding: "0px",
              }}
            />
          </div>
        )}
        <div
          style={{ ...UiPeople_Style.section_r_c, justifyContent: "center" }}
          onClick={() => {
            //  if(userInfo["id"] == data["id"])return;
            if (isOnly) {
              fnClick(data);
            } else {
              // if(userInfo["id"] == data["name"])return;
              // if(!isOpenOk)return;
              // if(!isManager)return;
              // if(!fnClick)return;
              if (!isEdit) {
                setIsOpen(!isOpen);
              }
            }
          }}
        >
          {form == 1 ? (
            <div
              style={{
                ...UiPeople_Style.section_r_t_w,
                paddingBottom: "10px",
              }}
            >
              <div style={UiPeople_Style.section_r_t_t_w}>
                {/* {data["type"] == 5 && (
                  <div
                    style={{
                      width: "43px",
                      height: "20px",
                      borderRadius: 20,
                      padding: "0px 5px",
                      backgroundColor: "#ACD0CB",
                      // color : style.common.t_color,
                      // fontWeight : "bold",
                      // marginRight : "3px"
                    }}
                  >
                    [관리자]
                  </div>
                )} */}
                <div
                  style={{
                    ...UiPeople_Style.section_r_t_t_text_2,
                    width: data?.user_type === 2 ? "110px" : "auto",
                  }}
                >
                  {/* 김학생  */}
                  {data?.user_type === 2
                    ? `${data["name"]} 선생님`
                    : params["who"] == "student"
                      ? `${data["nick"]} 학생`
                      : `${data["name"]} (${data["nick"]}) 학생`}
                  {/* {data["name"]}{( data?.user_type === 2 ) && " 선생님"} */}
                </div>
                <div
                  style={{
                    ...style.common.line_text,
                    fontWeight: 400,
                    fontSize: "12px",
                    color: "#A5A5A5",
                  }}
                >
                  {data["intro"]}
                </div>
                {/* {
                            (data["stype"] == 0 && data["school"]) &&
                            <div>
                                ({data["school"]})
                            </div>
                            } */}
              </div>
            </div>
          ) : (
            <div
              style={{
                ...UiPeople_Style.section_r_t_w,
                paddingBottom: "10px",
              }}
            >
              <div style={UiPeople_Style.section_r_t_t_w}>
                <div
                  style={{
                    display: "flex",
                  }}
                >
                  {data.owner && (
                    <div
                      style={{
                        width: 43,
                        height: 20,
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        lineClamp: 1,
                        borderRadius: 20,
                        padding: "0px 10px",
                        backgroundColor: "#487FBA",
                        fontWeight: 400,
                        fontSize: 10,
                        color: "white",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        marginRight: "6px",
                        whiteSpace: "nowrap",
                      }}
                    >
                      <span style={{ overflow: "hidden", textOverflow: "ellipsis" }}>
                        학원 관리자
                      </span>
                    </div>
                  )}
                  {data["type"] == 5 && !data.owner && (
                    <div
                      style={{
                        width: "43px",
                        height: "20px",
                        borderRadius: 20,
                        padding: "0px 5px",
                        backgroundColor: "#ACD0CB",
                        fontWeight: 400,
                        fontSize: 10,
                        color: "white",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        // color : style.common.t_color,
                        // fontWeight : "bold",
                        marginRight: "6px",
                        whiteSpace: "nowrap",
                      }}
                    >
                      <span style={{ overflow: "hidden", textOverflow: "ellipsis" }}>관리자</span>
                    </div>
                  )}

                  {data["type"] == 7 && !data.owner && (
                    <div
                      style={{
                        width: "43px",
                        height: "20px",
                        borderRadius: 20,
                        padding: "0px 5px",
                        backgroundColor: style.common.s_color,
                        fontWeight: 400,
                        fontSize: 10,
                        color: "white",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        // color : style.common.t_color,
                        // fontWeight : "bold",
                        marginRight: "6px",
                        whiteSpace: "nowrap",
                      }}
                    >
                      <span style={{ overflow: "hidden", textOverflow: "ellipsis" }}>조교</span>
                    </div>
                  )}
                  {
                    // isTeacher ?
                    data?.user_type === 2 ? (
                      <div
                        style={{
                          fontWeight: 400,
                          fontSize: "14px",
                          display: "flex",
                          minWidth: "110px",
                          color: "#3D3A39",
                        }}
                      >
                        {`${data["name"]} 선생님`}
                      </div>
                    ) : (
                      <>
                        <div
                          style={{
                            fontWeight: 400,
                            fontSize: "14px",
                            display: "flex",
                            minWidth: "110px",
                            color: "#3D3A39",
                          }}
                        >
                          {/* 학생(선생님)닉네임  */}
                          {params["who"] == "student"
                            ? `${data["nick"]}`
                            : `${data["name"]} (${data["nick"]})`}
                        </div>
                        {isInfo && (
                          <div>
                            {/* (학생이름(선생님)) */}
                            {`( ${data["name"]} )`}
                          </div>
                        )}
                      </>
                    )
                  }
                </div>
                <div
                  style={{
                    fontWeight: 400,
                    fontSize: "12px",
                    color: "#A5A5A5",
                  }}
                >
                  {data["intro"]}
                </div>
              </div>
            </div>
          )}
        </div>

        {isStat && params["who"] == "student" && (
          <div
            style={{
              flex: 1,
              // width : '20%',
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "flex-end",
              marginRight: "10px",
              height: "100%",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {data["qnacnt"] && (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "16px",
                    height: "16px",
                    alignItems: "center",
                    justifyContent: "center",
                    borderRadius: 50,
                    backgroundColor:
                      data["qnacnt"]["total"] - data["qnacnt"]["answer"] <= 10
                        ? "#FCDB92"
                        : data["qnacnt"]["total"] - data["qnacnt"]["answer"] <= 50
                          ? "#A8E2B8"
                          : "#F87A7A",
                  }}
                ></div>
              )}
            </div>
          </div>
        )}
      </div>
      {isOpen &&
        (isTeacher ? (
          <div style={UiPeople_Style.openAdd_c}>
            {!isBookManager ? (
              isTeacherPage ? (
                data["type"] == 5 || data["type"] == 7 ? (
                  <div
                    style={{
                      ...UiPeople_Style.openAdd_btn_w,
                      justifyContent: "center",
                    }}
                  >
                    <UiTextBtn
                      text={data.type === 5 ? "관리자에서 해제" : "조교에서 해제"}
                      isUnderline={false}
                      btnStyle={UiPeople_Style.openAdd_btn}
                      fnClick={() => {
                        fnDown(data);
                        setIsOpen(false);
                      }}
                    />
                  </div>
                ) : (
                  <div style={UiPeople_Style.openAdd_btn_w}>
                    <UiTextBtn
                      text={"탈퇴처리"}
                      isUnderline={false}
                      btnStyle={{
                        ...UiPeople_Style.openAdd_btn,
                        color: style.common.fontColor_3,
                      }}
                      fnClick={() => {
                        fnExit(data);
                        setIsOpen(false);
                      }}
                    />
                    <UiTextBtn
                      text={"조교로 승격"}
                      isUnderline={false}
                      btnStyle={UiPeople_Style.openAdd_btn}
                      fnClick={() => {
                        fnAssist(data);
                        setIsOpen(false);
                      }}
                    />
                    <UiTextBtn
                      text={"관리자로 승격"}
                      isUnderline={false}
                      btnStyle={UiPeople_Style.openAdd_btn}
                      fnClick={() => {
                        fnUp(data);
                        setIsOpen(false);
                      }}
                    />
                  </div>
                )
              ) : manageSub ? (
                <div style={UiPeople_Style.openAdd_btn_w}>
                  <UiTextBtn
                    text={"탈퇴처리"}
                    isUnderline={false}
                    btnStyle={{
                      ...UiPeople_Style.openAdd_btn,
                      color: style.common.fontColor_3,
                    }}
                    fnClick={() => {
                      fnExit(data);
                      setIsOpen(false);
                    }}
                  />

                  <UiTextBtn
                    text={"질문보기"}
                    isUnderline={false}
                    btnStyle={UiPeople_Style.openAdd_btn}
                    fnClick={(e) => {
                      fnQan_see(e, data);
                    }}
                  />
                  <UiTextBtn
                    text={"대화하기"}
                    isUnderline={false}
                    btnStyle={UiPeople_Style.openAdd_btn}
                    fnClick={(e) => {
                      fnTalk(e, data);
                    }}
                  />
                </div>
              ) : isPeople ? (
                <div
                  style={{
                    width: "100%",
                  }}
                >
                  {data["type"] == 9 ? (
                    <div style={UiPeople_Style.openAdd_btn_w}>
                      <UiTextBtn
                        text={
                          <div style={UiPeople_Style.openAdd_btn_w_2_text_w}>
                            <Clear style={UiPeople_Style.openAdd_btn_w_2_text} />
                            초대 취소하기
                          </div>
                        }
                        isUnderline={false}
                        btnStyle={{
                          ...UiPeople_Style.openAdd_btn,
                          color: style.common.fontColor_3,
                        }}
                        fnClick={() => {
                          fnReject(data);
                          setIsOpen(false);
                        }}
                      />
                    </div>
                  ) : (
                    <div
                      style={{
                        display: "flex",
                        width: "100%",
                        justifyContent: "space-around",
                        padding: "10px 0px",
                      }}
                    >
                      <UiTextBtn
                        text={
                          <div style={UiPeople_Style.openAdd_btn_w_2_text_w}>
                            <Clear style={UiPeople_Style.openAdd_btn_w_2_text} />
                            거절하기
                          </div>
                        }
                        isUnderline={false}
                        btnStyle={{
                          ...UiPeople_Style.openAdd_btn,
                          color: style.common.fontColor_3,
                        }}
                        fnClick={() => {
                          fnReject(data);
                          setIsOpen(false);
                        }}
                      />
                      <UiTextBtn
                        text={
                          <div style={UiPeople_Style.openAdd_btn_w_2_text_w}>
                            <Check style={UiPeople_Style.openAdd_btn_w_2_text} />
                            승인하기
                          </div>
                        }
                        isUnderline={false}
                        btnStyle={UiPeople_Style.openAdd_btn}
                        fnClick={() => {
                          fnApprove(data);
                          setIsOpen(false);
                        }}
                      />
                    </div>
                  )}
                </div>
              ) : (
                <div style={UiPeople_Style.openAdd_btn_w_2}>
                  <UiTextBtn
                    text={
                      <div
                        style={{
                          ...UiPeople_Style.openAdd_btn_w_2_text_w,
                        }}
                      >
                        질문보기
                      </div>
                    }
                    isUnderline={false}
                    btnStyle={UiPeople_Style.openAdd_btn}
                    fnClick={(e) => {
                      fnQan_see(e, data);
                    }}
                  />
                  <UiTextBtn
                    text={
                      <div
                        style={{
                          ...UiPeople_Style.openAdd_btn_w_2_text_w,
                        }}
                      >
                        대화하기
                      </div>
                    }
                    isUnderline={false}
                    btnStyle={UiPeople_Style.openAdd_btn}
                    fnClick={(e) => {
                      fnTalk(e, data);
                    }}
                  />
                </div>
              )
            ) : (
              isExpel && (
                <div style={UiPeople_Style.openAdd_btn_w}>
                  {data["type"] == 9 || data["type"] == 8 ? (
                    <UiTextBtn
                      text={"초대 취소하기"}
                      isUnderline={false}
                      btnStyle={{
                        ...UiPeople_Style.openAdd_btn,
                        color: style.common.fontColor_3,
                      }}
                      fnClick={() => {
                        fnExpel(data);
                        setIsOpen(false);
                      }}
                    />
                  ) : data["type"] == 6 && data["status"] == 0 ? (
                    <div
                      style={{
                        display: "flex",
                        width: "100%",
                        justifyContent: "space-around",
                        padding: "10px 0px",
                      }}
                    >
                      <UiTextBtn
                        text={
                          <div style={UiPeople_Style.openAdd_btn_w_2_text_w}>
                            <Clear style={UiPeople_Style.openAdd_btn_w_2_text} />
                            거절하기
                          </div>
                        }
                        isUnderline={false}
                        btnStyle={{
                          ...UiPeople_Style.openAdd_btn,
                          color: style.common.fontColor_3,
                        }}
                        fnClick={() => {
                          fnReject(data);
                          setIsOpen(false);
                        }}
                      />
                      <UiTextBtn
                        text={
                          <div style={UiPeople_Style.openAdd_btn_w_2_text_w}>
                            <Check style={UiPeople_Style.openAdd_btn_w_2_text} />
                            승인하기
                          </div>
                        }
                        isUnderline={false}
                        btnStyle={UiPeople_Style.openAdd_btn}
                        fnClick={() => {
                          fnApprove(data);
                          setIsOpen(false);
                        }}
                      />
                    </div>
                  ) : (
                    <>
                      <UiTextBtn
                        text={"내보내기"}
                        isUnderline={false}
                        btnStyle={{
                          ...UiPeople_Style.openAdd_btn,
                          color: style.common.fontColor_3,
                        }}
                        fnClick={() => {
                          fnExpel(data);
                          setIsOpen(false);
                        }}
                      />
                      <>
                        {data["type"] == 5 && (
                          <UiTextBtn
                            text={"관리자에서 해제"}
                            isUnderline={false}
                            btnStyle={UiPeople_Style.openAdd_btn}
                            fnClick={() => {
                              fnDown(data);
                              setIsOpen(false);
                            }}
                          />
                        )}
                      </>
                      <>
                        {data["type"] != 5 && (
                          <UiTextBtn
                            text={"관리자로 승격"}
                            isUnderline={false}
                            btnStyle={UiPeople_Style.openAdd_btn}
                            fnClick={() => {
                              fnBookUp(data);
                              setIsOpen(false);
                            }}
                          />
                        )}
                      </>
                    </>
                  )}
                </div>
              )
            )}
          </div>
        ) : (
          <div style={UiPeople_Style.openAdd_c}>
            <div style={UiPeople_Style.openAdd_btn_w}>
              <UiTextBtn
                text={"질문 보기"}
                isUnderline={false}
                btnStyle={{
                  ...UiPeople_Style.openAdd_btn,
                  // backgroundColor : "red",
                }}
                fnClick={(e) => {
                  fnQan_see(e, data);
                }}
              />

              {!isTeacher && (
                <UiTextBtn
                  isFile={true}
                  accept={"image/*"}
                  text={"질문 하기"}
                  isUnderline={false}
                  btnStyle={{
                    ...UiPeople_Style.openAdd_btn,
                    // backgroundColor : "orange",
                  }}
                  fnClick={(e) => {
                    fnQna(e, data);
                  }}
                />
              )}
              <UiTextBtn
                text={"대화하기"}
                isUnderline={false}
                btnStyle={{
                  ...UiPeople_Style.openAdd_btn,
                  // backgroundColor : "purple",
                }}
                fnClick={(e) => {
                  fnTalk(e, data);
                }}
              />
            </div>
          </div>
        ))}
    </div>
  );
};

export default UiPeople_Type2;
