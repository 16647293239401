import { ClassRegistRequset } from "api/classRegist";
import { ReactComponent as ImageUpload } from "asset/Img/image_upload.svg";
import { ReactComponent as VideoPlay } from "asset/Img/video_play.svg";
import constants from "asset/constants";
import ErrorMessage from "components/atoms/ErrorMessage";
import { fileToBase64 } from "file64";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useController, useFormContext } from "react-hook-form";
import styled, { css } from "styled-components";
import ThumbnailSelectModal from "./ThumbnailSelectModal";

const ThumbnailSelectTemplates = ({ videoUrl }: { videoUrl: string }) => {
  const {
    watch,
    control,
    setValue,
    formState: { errors, submitCount },
  } = useFormContext<ClassRegistRequset>();
  const [open, setOpen] = useState(false);
  const fileRef = useRef<HTMLInputElement>(null);
  const onClose = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  const onSubmit = useCallback(() => {
    setOpen(false);
    setImageFile(null);
    fileRef.current.files = null;
    fileRef.current.value = "";
  }, [setOpen]);

  const handleShowModal = useCallback(() => {
    setOpen(true);
  }, [setOpen]);

  const [imageFile, setImageFile] = useState<File | null>(null);
  useEffect(() => {
    const translateImage = async () => {
      const imageBase64 = imageFile && (await fileToBase64(imageFile));
      imageBase64 && setValue("thumbnail", imageBase64, { shouldValidate: true });
    };
    translateImage();
  }, [imageFile]);

  const boardId = watch("id");
  const thumbnail = watch("thumbnail");
  const [timeForImageCache] = useState(new Date().getTime());

  const imageUrl = useMemo(() => {
    if (thumbnail && imageFile) {
      return thumbnail;
    }
    if (!thumbnail && boardId) {
      const thumbnailUrl = `${constants.s3_url}/board/${boardId}.jpg?t=${timeForImageCache}`;
      return thumbnailUrl;
    }
    return null;
  }, [thumbnail, boardId, imageFile]);

  useController({
    name: "thumbnail",
    control,
    rules: { required: !boardId },
  });

  const buttonRef = useRef<HTMLLabelElement>(null);
  useEffect(() => {
    if (errors.thumbnail) {
      window.scrollTo({ top: buttonRef.current?.offsetTop ?? 0, left: 0, behavior: "smooth" });
    }
  }, [errors, submitCount]);
  // console.log("thumbnail", thumbnail);
  return (
    <>
      <AlignContainer>
        <TitleFont>썸네일</TitleFont>
        <DescriptionFont>
          수업내용을 알아보는 수 있는 썸네일 이미지를 업로드해주세요.
        </DescriptionFont>
        <ButtonContainer>
          <UploadButton isImage={!!imageUrl} ref={buttonRef}>
            <input
              type="file"
              ref={fileRef}
              accept="image/*"
              name="thumbnail"
              onChange={(event) => {
                const file = event.target.files?.[0];
                setImageFile(file);
                event.target.value = "";
              }}
            />
            {imageUrl && <img src={imageUrl} alt="직접올린 이미지" />}
            <ImageUpload />
            <span>이미지 업로드</span>
          </UploadButton>
          <UploadButton onClick={handleShowModal} isImage={!!thumbnail && !imageFile}>
            {thumbnail && !imageFile && (
              <img src={thumbnail as string} alt="영상추출 썸네일 이미지" />
            )}
            <VideoPlay />
            <span>영상에서 추출</span>
          </UploadButton>
        </ButtonContainer>
        {errors.thumbnail && <ErrorMessage>썸네일을 선택해 주세요.</ErrorMessage>}
      </AlignContainer>
      <ThumbnailSelectModal open={open} onClose={onClose} videoUrl={videoUrl} onSubmit={onSubmit} />
    </>
  );
};

const AlignContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 16px;
`;

const ThumbnailSelectButton = styled.button`
  outline: none;
  border: none;
  background: #ffa723;
  color: white;
  padding: 8px 10px;
  border-radius: 8px;
  margin-left: auto;
`;

const TitleFont = styled.div`
  color: #242424;
  font-family: Pretendard;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%; /* 16px */
`;
const DescriptionFont = styled.div`
  color: #242424;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%; /* 12px */
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 16px;
  margin: 0 auto;
  width: 60%;
`;

const UploadButton = styled.label<{ isImage?: boolean }>`
  ::before {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 2;
    opacity: 0.4;
    ${({ isImage }) =>
      isImage
        ? css`
            background:
              linear-gradient(0deg, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0.4) 100%),
              url(<path-to-image>),
              lightgray 50% / cover no-repeat;
          `
        : css`
            background: transparent;
          `}
  }

  position: relative;
  outline: none;
  border: 1px dashed #787878;
  border-radius: 5px;
  height: 70px;
  flex-shrink: 0;
  flex: 1;
  font-family: Pretendard;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%; /* 12px */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 1;
  gap: 5px;
  & > input {
    display: none;
  }
  & > svg {
    z-index: 2;
    stroke: ${({ isImage }) => (isImage ? "#fff;" : "#787878;")};
  }
  & > span {
    color: ${({ isImage }) => (isImage ? "#fff;" : "#787878;")};
    z-index: 2;
  }
  & > img {
    object-fit: cover;
    flex: 1;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 1;
  }
`;

export default ThumbnailSelectTemplates;
