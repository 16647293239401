import React from "react"
import {ArrowBackIos} from "@material-ui/icons";
import styled from "styled-components";
import {useNavigate} from "react-router";

const Header = () => {
  const navigate = useNavigate()
  return (
    <HeaderContainer>
      <Button onClick={()=>{navigate(-1)}}>
        <ArrowBackIos/>
      </Button>
      문의하기
    </HeaderContainer>
  )
}

export default Header

const Button = styled.button`
  background: none;
  border: 0;
`

const HeaderContainer = styled.div`
  padding: 16px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`