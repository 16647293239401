import { CircularProgress } from "@mui/material";
import { LectureListItem, LectureVideoListFilterRequset, useLectureVideoList } from "api/classList";
import React, { useMemo } from "react";
import { useFormContext } from "react-hook-form";
import InfiniteScroll from "react-infinite-scroll-component";
import styled from "styled-components";
import VideoListItem from "./VideoListItem";

const VideoListWrapper = () => {
  const methods = useFormContext<LectureVideoListFilterRequset>();
  const { watch } = methods;
  const filteredData = watch();
  const { data, fetchNextPage } = useLectureVideoList(filteredData);

  const totalCount = useMemo(() => data?.pages?.[0]?.tcnt ?? 0, [data]);
  const articleList = useMemo(() => {
    const newList = data?.pages.reduce(
      (newList: LectureListItem[], page) => [...newList, ...(page?.articleList ?? [])],
      []
    );
    return newList;
  }, [data]);
  // const { articleList, tcnt } = data.pages[0];
  return (
    <Container
      dataLength={articleList?.length ?? 0}
      next={fetchNextPage}
      loader={
        articleList?.length > 0 &&
        articleList?.length < totalCount && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              width: "100%",
              paddingBottom: 40,
              height: 24,
            }}
          >
            <CircularProgress
              style={{
                fontSize: "12px",
              }}
            />
          </div>
        )
      }
      scrollableTarget="scrollableDiv"
      hasMore={articleList?.length < totalCount}
    >
      {articleList?.map((video) => {
        return <VideoListItem {...video} key={video.id} />;
      })}
    </Container>
  );
};

const Container = styled(InfiniteScroll)`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 16px 20px;
  box-sizing: border-box;
  height: 100%;
  gap: 16px;
`;

export default VideoListWrapper;
