import { gql } from "@apollo/client";

export const BOARD_INFO = gql`
  query GetBoard($boardId: ID!) {
    getBoard(boardId: $boardId) {
      success
      error {
        message
        code
      }
      boards {
        id
        title
        important
        content
        video_start
        video_end
        video_link
        view_count
        mid
        cat_id
        cid
        bid
        reg_date
        pub
        aid
        aaid
        mosaic
        img
        thumbnail_id
        user {
          id
          name
          status
          content
          intro
          school
          img
        }
        replies {
          id
          boid
          wid
          wtype
          content
          reg_date
          rtype
          parent_id
          replies {
            id
            wid
            wtype
            content
            reg_date
            rtype
            wid_index
            parent_id
            replyUser {
              id
              name
              nick
            }
          }
          replyUser {
            id
            name
            nick
          }
        }
        files {
          id
          name
          cid
          bid
          origin
        }
        thumbnails {
          id
          name
          path
          timestamp
          sequence
        }
        cid_HIQDB_class {
          name
          pid
          HIQDB_member_classes {
            mid
            type
          }
        }
        logs {
          id
          wid
          bid
          reg_date
          btype
          content
          class_video_start_date_time
          class_video_play_time
          class_video_max_play_speed
          class_video_time_in_play
          viewer {
            id
            name
            nick
            img
            user_type
          }
        }
      }
    }
  }
`;

export const BOARD_UPDATE = gql`
  mutation BoardUpdate($boardId: ID!, $input: BoardInput) {
    updateBoard(boardId: $boardId, input: $input) {
      success
      error {
        code
        message
      }
      board {
        id
        title
        important
        content
        video_start
        video_end
        video_link
        mid
        cat_id
        cid
        bid
        reg_date
        pub
        aid
        aaid
        mosaic
        img
        thumbnail_id
        mid_index
      }
    }
  }
`;

export const REPLY_LIST = gql`
  query GetReply($boardId: ID, $replyId: ID) {
    getBoard(boardId: $boardId, replyId: $replyId) {
      success
      boards {
        replies {
          id
          boid
          wid
          wtype
          content
          reg_date
          rtype
          wid_index
          replies {
            id
            boid
            wid
            wtype
            content
            reg_date
            rtype
            wid_index
            parent_id
            replyUser {
              id
              name
              nick
            }
          }
          replyUser {
            id
            name
            nick
          }
          parent_id
        }
      }
    }
  }
`;

export const REGIST_REPLY = gql`
  mutation CreateBoardReply($input: BoardReplyInput) {
    createBoardReply(input: $input) {
      success
      error {
        code
        message
      }
      reply {
        id
        boid
      }
    }
  }
`;

export const DELETE_REPLY = gql`
  mutation DeleteBoardReply($replyId: ID!) {
    deleteBoardReply(replyId: $replyId) {
      success
      reply {
        id
        boid
      }
      error {
        code
        message
      }
    }
  }
`;

export const CREATE_BOARD_LOG = gql`
  mutation CreateBoardLog($input: BoardLogInput) {
    createBoardLog(input: $input) {
      success
      error {
        code
        message
      }
      log {
        wid
        bid
        btype
      }
    }
  }
`;

export const DELETE_BOARD_LOG = gql`
  mutation DeleteBoardLog($bid: ID!, $wid: ID!, $btype: Int!) {
    deleteBoardLog(bid: $bid, wid: $wid, btype: $btype) {
      success
      error {
        code
        message
      }
      log {
        id
      }
    }
  }
`;

export const UPSERT_BOARD_LOG = gql`
  mutation UpsertBoardLog($input: BoardLogInput) {
    upsertBoardLog(input: $input) {
      error {
        code
        message
      }
      log {
        id
        wid
        bid
        btype
        reg_date
        content
        wid_index
        class_video_start_date_time
        class_video_play_time
        class_video_max_play_speed
        class_video_time_in_play
        viewer {
          id
          name
          nick
        }
      }
      success
    }
  }
`;

export const BOARD_ADD_VIEW_COUNT = gql`
  mutation UpdateBoardCount($boardId: ID!) {
    updateBoardCount(boardId: $boardId) {
      error {
        code
        message
      }
      success
    }
  }
`;
