const Register_One_style = {
    dot  : {
        width : "8px",
        height : "8px",
        borderRadius : 10,
        backgroundColor:"rgb(200,200,200)",
        margin : "0px 2px",
    },
    a_c : {
        display:"flex",
        justifyContent:"space-between",
        alignItems:"center",
        marginTop:"10px",
    },
    a_t : {
        display : 'flex',
        fontSize : "15px",
        fontWeight : "bold",
    },
    section_a_c : {
        display:"flex",
        alignItems:"center",
        padding : "15px 0px",
        justifyContent:"space-between",
    },
    section_a_w : {
        display : 'flex',
    },
    section_a_t : {
        display:"flex",
        fontSize : '12px',
        alignItems:"center",
        paddingLeft : "15px",
    },
    b_c : {
        display:"flex",
        flexDirection : "column",
        justifyContent:"flex-end",
        paddingBottom : "20px",
        width : "100%",
        flex:1,
    },
    b : {
        // backgroundColor : "rgb(200,200,200)",
        color : "white",
    },



} 
export default Register_One_style;