import { ArrowDropDown, ArrowDropUp, KeyboardArrowDown, KeyboardArrowUp } from "@material-ui/icons";
import { React, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import API from "../../../api";
import bookIcon from "../../../asset/Img/book_43_black.png";
import util from "../../../asset/util";
import UiSearchBox from "../../../uiComponents/UiSearch/UiSearchBox";
import Message_Style from "../../Message/Message_Style";
import { Frame } from "../../page_index";
import Modal_regi from "../Modal_regi";
import styles from "../style.module.scss";
import LectureVideo from "./LectureVideo";

const Lecture_Main = ({ userInfo, isPremium }) => {
  let params = useParams();
  let navigate = useNavigate();

  let sort = [
    { name: "개설순", value: "reg_date" },
    { name: "이름순", value: "name" },
    { name: "수강 인원순", value: "member" },
  ];

  const [isClassListSortTab, setIsClassListSortTab] = useState(false);
  const [classList, setClassList] = useState([]);
  const [isSearch, setIsSearch] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [searchedClassList, setSearchedClassList] = useState([]);
  const [isNewClassModal, setIsNewClassModal] = useState(false);

  const handleSortChange = (sort) => {
    setClassList((prev) => {
      let sortData = [...prev];
      if (sort.value === "name")
        sortData = sortData.sort((a, b) => a.class_name?.localeCompare(b.class_name));
      else if (sort.value === "member")
        sortData = sortData.sort((a, b) => b.student_count - a.student_count);
      else if (sort.value === "reg_date")
        sortData = sortData.sort((a, b) => b.class_reg_date - a.class_reg_date);
      return sortData;
    });
    setIsClassListSortTab(false);
  };

  const getSearchedClassList = async () => {
    try {
      if (!util.spaceTrim(searchValue)) {
        setIsSearch(false);
        setSearchedClassList([]);
        return;
      }
      const resClassList = await API.getClassList(
        `classType=1&isClassroomRequired=true&className=${util.spaceTrim(searchValue)}`
      );
      const filterUnregisteredClassList = resClassList.filter(
        (classData) => !classList.some((lecture) => lecture.cid === classData.id)
      );
      setSearchedClassList(filterUnregisteredClassList);
      setIsSearch(true);
    } catch (e) {
      console.error(e);
      alert("수업 검색을 실패했습니다.");
    }
  };

  useEffect(() => {
    if (!isNewClassModal && userInfo.id) {
      const getUserJoinClassList = async () => {
        const userClassList = await API.getUserClassList(
          userInfo.id,
          "classType=1&isClassroomRequired=true"
        );
        setClassList(userClassList);
      };
      getUserJoinClassList();
    }
  }, [isNewClassModal, userInfo]);

  return (
    <Frame
      userInfo={userInfo}
      isPremium={isPremium}
      isBack={false}
      type="2"
      isSearch={false}
      title={
        <div
          style={{
            fontFamily: "Roboto",
            fontStyle: "normal",
            fontWeight: 400,
            fontSize: "24px",
            lineHeight: "28px",
            textAlign: "center",
            letterSpacing: "2px",
          }}
        >
          수업
        </div>
      }
    >
      <div
        style={{
          ...Message_Style.c,
        }}
      >
        <LectureVideo userInfo={userInfo} />
        <div
          style={{
            padding: "0px 5px",
            marginTop: "5px",
          }}
        >
          <div
            style={{
              padding: "10px 10px",
              backgroundColor: "#F0F0F0",
              borderRadius: "5px",
            }}
          >
            <UiSearchBox
              placeholder="참여할 수업명을 입력해주세요."
              isTeacher={params["who"] == "teacher" ? true : false}
              fnClick={() => {
                getSearchedClassList();
              }}
              fnChange={(e) => {
                setSearchValue(e.target.value);
              }}
            />
          </div>
        </div>
        {isSearch && (
          <>
            <div
              style={{
                padding: "16px 20px 0px 20px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginBottom: "6px",
                }}
              >
                <div
                  style={{
                    position: "relative",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      fontSize: "18px",
                      alignItems: "center",
                      fontWeight: 700,
                    }}
                  >
                    참여 가능한 수업
                  </div>
                </div>
              </div>
            </div>
            <div
              style={{
                padding: "0px 22px 0px 22px",
              }}
            >
              <div>
                {searchedClassList.length === 0 && (
                  <div style={{ textAlign: "center" }}>참여 가능한 수업이 없습니다.</div>
                )}
                {searchedClassList?.map((v, i) => (
                  <LectureSection
                    isSearch={true}
                    data={v}
                    onClick={() => {
                      navigate(`/lecture/detail/${params["who"]}/${v["id"]}/de`);
                    }}
                  />
                ))}
              </div>
            </div>
          </>
        )}
        <div
          style={{
            padding: "16px 20px 0px 20px",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: "6px",
            }}
          >
            <div
              style={{
                position: "relative",
              }}
            >
              <div
                style={{
                  display: "flex",
                  fontSize: "18px",
                  alignItems: "center",
                  fontWeight: 700,
                }}
              >
                내가 속한 수업
                {isClassListSortTab ? (
                  <ArrowDropUp
                    style={{
                      fontSize: "40px",
                      marginBottom: "3px",
                    }}
                    onClick={() => {
                      setIsClassListSortTab(false);
                    }}
                  />
                ) : (
                  <ArrowDropDown
                    style={{
                      fontSize: "40px",
                      marginBottom: "3px",
                    }}
                    onClick={() => {
                      setIsClassListSortTab(true);
                    }}
                  />
                )}
              </div>
              {isClassListSortTab ? (
                <div
                  style={{
                    position: "absolute",
                    left: 0,
                    // top:10,
                    borderRadius: "20px",
                    border: "1px solid #F6F6F6",
                    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                    padding: "3px 0px",
                    backgroundColor: "white",
                  }}
                >
                  {sort.map((v, i) => (
                    <div
                      key={"sorting" + i}
                      style={{
                        padding: "15px 0px",
                        width: "120px",
                        backgroundColor: "white",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        fontWeight: 400,
                        fontSize: "14px",
                        color: "#3D3A39",
                        borderRadius: "20px",
                      }}
                      onClick={() => {
                        handleSortChange(v);
                      }}
                    >
                      {v["name"]}
                    </div>
                  ))}
                </div>
              ) : (
                <></>
              )}
            </div>
            {params["who"] == "teacher" && (
              <div
                style={{
                  display: "flex",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "113px",
                    height: "24px",
                    padding: "2px 7px",
                    borderRadius: "15px",
                    backgroundColor: "#ACD0CB",
                  }}
                >
                  <div
                    style={{
                      fontSize: "14px",
                      color: "white",
                      fontWeight: 400,
                    }}
                    onClick={() => {
                      setIsNewClassModal(true);
                    }}
                  >
                    + 수업 생성하기
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        <div
          style={{
            padding: "0px 22px 0px 22px",
          }}
        >
          <div style={{}}>
            {classList && classList.length > 0 ? (
              classList.map((v, i) => (
                <LectureSection
                  data={v}
                  onClick={() => {
                    navigate(`/lecture/detail/${params["who"]}/${v["cid"]}/de`);
                  }}
                />
              ))
            ) : (
              <div
                style={{
                  height: 300,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <div style={{ marginBottom: 12 }}>
                  <img src={bookIcon} />
                </div>
                <div>참여 중인 수업이 없습니다.</div>
              </div>
            )}
          </div>
        </div>
      </div>
      {isNewClassModal && <Modal_regi onClose={() => setIsNewClassModal(false)} />}
    </Frame>
  );
};

const LectureSection = ({ data, onClick, isSearch = false }) => {
  const [isOpen, setIsOpen] = useState(false);

  const ContextualMemberText = () => {
    const { teacher_count, student_count, manager_name } = data;
    let innerText = `수강인원 ${student_count ?? 0}명`;

    if (teacher_count === 1) innerText = `${manager_name ?? "이름 없는"} 선생님 - ${innerText}`;
    else if (teacher_count >= 2)
      innerText = `${manager_name ?? "이름 없는"} 선생님 외 ${teacher_count - 1}명 -  ${innerText}`;

    return <div> {innerText} </div>;
  };

  const ContextualClassAndClassroomText = () => {
    let classText = "이름 없는 수업";
    let classroomText = "이름 없는 학원";
    const { class_name, classroom_name } = data;
    if (class_name) classText = class_name;
    if (classroom_name) classroomText = classroom_name;

    return (
      <div className={styles.classNameText}>
        {classText}
        <span className={styles.classRoomNameText}>| {classroomText}</span>
      </div>
    );
  };

  return (
    <div
      style={{
        padding: "9px 0px",
        marginBottom: "2px",
        borderBottom: "1px solid #F6F6F6",
      }}
      onClick={(e) => {
        onClick(data);
        e.stopPropagation();
        e.preventDefault();
      }}
    >
      <div className={styles.titleWrapper}>
        <ContextualClassAndClassroomText />
      </div>
      <div
        style={{
          display: "flex",
          color: "#B0B0B0",
          fontSize: "12px",
          fontWeight: 400,
          justifyContent: "space-between",
        }}
      >
        {isSearch ? (
          <div
            style={{
              display: "flex",
            }}
          >
            <div style={{}}>장소 : {data?.address ?? ""}</div>
          </div>
        ) : (
          <ContextualMemberText />
        )}
        <div
          style={{
            display: "flex",
            alignItems: "flex-start",
          }}
          onClick={(e) => {
            setIsOpen(!isOpen);
            e.stopPropagation();
          }}
        >
          {isOpen ? "닫기" : "펼치기"}
          {isOpen ? (
            <KeyboardArrowUp style={{ fontSize: "16px" }} />
          ) : (
            <KeyboardArrowDown style={{ fontSize: "16px" }} />
          )}
        </div>
      </div>
      {isOpen && (
        <div
          style={{
            display: "flex",
            fontSize: "12px",
            fontWeight: 400,
            marginTop: "10px",
          }}
        >
          <div style={{}}>{data?.intro ?? ""}</div>
        </div>
      )}
    </div>
  );
};

export default Lecture_Main;
