import {React, useState, useEffect} from 'react'
import {useNavigate, useParams} from 'react-router'
import style from '../../asset/style'
import {Frame} from '../page_index'
import {UiInput_title} from '../../uiComponents/UiInput/UiInput'
import UiBtn from '../../uiComponents/UiBtn/UiBtn'
import Register_One_style from '../before/register/Register_One_style'
import MyInfo_PW_Style from './MyInfo_PW_Style'
import apiFn from '../../asset/apiClass'
import util from '../../asset/util'
import constants from '../../asset/constants'
import store from "../../asset/store";
// import crypto from 'crypto-js'

const MyInfo_PW = ({
                       userInfo
                   }) => {
    let params = useParams()
    let navigate = useNavigate();

    const [isTeacher, setIsTeacher] = useState(true);
    const [tap, setTap] = useState("home")

    const [c_pw, setC_pw] = useState("");

    const [m_pw, setM_pw] = useState("");
    const [mc_pw, setMc_pw] = useState("");

    const apiPro_modi = async () => {
        try {
            if (m_pw != mc_pw) {
                alert(constants.confirm.cf_12)
                return;
            }
            let state = {
                page: 1,
                amount: 999,
                sort: "reg_date desc",
                table : "user_credential",
                where : {
                    'HIQDB_user_credential.name': `= '${userInfo["id"]}'`,
                    'HIQDB_user_credential.pw': `= '${util.crypto(c_pw)}'`,
                },
                ref: ['A.* |HIQDB_user A on A.index=HIQDB_user_credential.user_id and A.user_type = 2'],
            }
            let data = await apiFn.comApi({
                state
            })

            // console.log("data => ",data)

            if (data["list"].length == 1) {
                let isOk = await apiFn.comApi({
                    state: {
                        table: 'user_credential',
                        set: [
                            {
                                key: `name:${userInfo["id"]}`,
                                value: {pw: util.crypto(m_pw)}
                            }
                        ]

                    }
                })
                // console.log(isOk)
                window.alert(constants.confirm.cf_14)

                const stack = store.get('stack')
                if (stack.length > 0)
                    navigate(-1);
                else
                    navigate('/');
            } else {
                window.alert(constants.confirm.cf_13);
                return;
            }
        } catch (e) {
        }
    }


    useEffect(() => {
        // console.log("parmas => ",params)
        if (params["who"] == "teacher") {
            setIsTeacher(true)
        } else {
            setIsTeacher(false)
        }

    }, [params])

//    1. table student -> id / pw  => req.length == 1 /

//    2 => set

//    3 => 뒤로가기 (비밀번호가 성공적으로 변경되었습니다.)


    return (
        <Frame
            title="비밀번호 변경"
            isSearch={false}
            userInfo={userInfo}
        >
            <div style={MyInfo_PW_Style.c}>
                <div style={MyInfo_PW_Style.w_1}>
                    <UiInput_title
                        title="현재 비밀번호"
                        type="basic"
                        isPw={true}
                        placeholder="6자리 이상의 영어 및 특수문자를 입력해주세요."
                        fnChange={(e) => {
                            setC_pw(e.target.value)
                        }}
                    />
                    <UiInput_title
                        title="변경할 비밀번호"
                        type="basic"
                        isPw={true}
                        placeholder="6자리 이상의 영어 및 특수문자를 입력해주세요."
                        fnChange={(e) => {
                            setM_pw(e.target.value)
                        }}
                    />
                    <UiInput_title
                        title="비밀번호 재입력"
                        type="basic"
                        isPw={true}
                        placeholder="비밀번호를 다시 한번 입력해주세요."
                        fnChange={(e) => {
                            setMc_pw(e.target.value)
                        }}
                    />
                </div>
                <div style={MyInfo_PW_Style.w_2}>
                    <UiBtn
                        btnStyle={{
                            ...Register_One_style.b,
                            borderColor:
                                (isTeacher ? style.common.t_color : style.common.s_color)
                            ,
                            backgroundColor:
                                (isTeacher ? style.common.t_color : style.common.s_color)

                        }}
                        title={"확인"}
                        fnClick={() => {
                            let isOk = window.confirm("비밀번호를 변경하시겠어요?")
                            if (isOk) {
                                apiPro_modi();
                            }
                        }}
                    />
                </div>
            </div>
        </Frame>
    )
}

export default MyInfo_PW
