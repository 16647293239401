import { getUser } from "../../../asset/storage";
import { ActionEnum, MetadataPramsType, StatStoreDTO } from "./types";
import { pathToUrl } from "../../../helpers/url";
import { axiosInstance } from "../../common";
import { useMutation, useQuery } from "@tanstack/react-query";
import { QUESTION_META_KEY } from "./metadata_key";
import { AccountApiEndpoints, QueryKeys } from "../../constants";

//사용자 메타데이터 가져오는 함수
const getUserMetadata = async (key: string): Promise<any> => {
  const userId = getUser().id;
  const url = pathToUrl(AccountApiEndpoints.GET_STAT_STORE, { userId, search_key: key });
  try {
    const response = await axiosInstance.get(url);
    return response.data;
  } catch (error) {
    console.error("Error getting user metadata:", error);
    throw new Error("Failed to get user metadata");
  }
};

// 메타데이터 가져오기 함수 생성기
const metadataGetter = (baseKey: string, key?: string) => {
  const metaDataKey = key ? `${baseKey}_${key}` : baseKey;
  const { data } = useQuery({
    queryKey: [QueryKeys.USER_METADATA, metaDataKey],
    queryFn: async () => {
      return getUserMetadata(metaDataKey);
    },
  });

  return data.metadatas;
};

// 사용자 메타데이터 설정 함수 (에러 처리 포함)
const setUserMetadata = async (metaData: StatStoreDTO): Promise<any> => {
  const userId = getUser().id;
  const url = pathToUrl(AccountApiEndpoints.SET_STAT_STORE, { userId });

  try {
    const response = await axiosInstance.post(url, { commands: [metaData] });
    return response.data;
  } catch (error) {
    console.error("Error setting user metadata:", error);
    throw new Error("Failed to update user metadata");
  }
};

// 메타데이터 업데이트 함수 생성기
const metadataUpdater = (baseKey: string) => {
  const mutation = useMutation(
    async ({ key, value, action = ActionEnum.SET }: MetadataPramsType): Promise<any> => {
      const metadataKey = key ? `${baseKey}_${key}` : baseKey;
      return setUserMetadata({ key: metadataKey, value, action });
    }
  );

  return ({ key, value, action }: MetadataPramsType): void => {
    if (!mutation.isLoading) {
      mutation.mutate({ key, value, action });
    }
  };
};

// 사용자 메타데이터 업데이트를 위한 커스텀 훅
export const useSetSemohaeFoldMetaData = () =>
  metadataUpdater(QUESTION_META_KEY.QUESTION_SEMOHAE_FOLD);

export const useGetIsSemohaeFolded = (key?: string) =>
  metadataGetter(QUESTION_META_KEY.QUESTION_SEMOHAE_FOLD, key);
