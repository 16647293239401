import { React , useState , useEffect } from 'react'
import style from '../../asset/style'
import { useNavigate, useParams } from 'react-router'
import UiBtn from '../../uiComponents/UiBtn/UiBtn'
import { Add } from '@material-ui/icons'
import UiSorting from '../../uiComponents/UiSorting/UiSorting'
import Column_pick_Style from './Column_pick_Style'
import UiVideoSection from '../../uiComponents/UiVideoSectionList/UiVideoSection'
import Column_Pick_classify_Style from './Column_Pick_classify_Style'
import UiTextBtn from '../../uiComponents/UiTextBtn/UiTextBtn'
import UiTopPopTextBtn from '../../uiComponents/UiTopPopTextBtn/UiTopPopTextBtn'
import apiFn from '../../asset/apiClass'
import Academy_book_Style from '../Academy/Academy_book_Style'

const Column_my = ({
    userInfo,
    isTeacher,
    setIsSearch,
    scrollKey,
    setScrolllKey,
}) => {
    let params = useParams()
    let navigate = useNavigate();


    var [selectData,setSelectData] = useState({})
    var [selectData_l,setSelectData_l] = useState(0)

    const [isEdit , setIsEdit] = useState(false);

    var [colData,setColData] = useState([]);

    const apiPro_del = async () => {
        try {
            let delArray = Object.keys(selectData).reduce((a,v,i)=>{
                return [
                    ...a,
                    { id : v}
                ]
            },[])
            let isOk = await apiFn.comApi({
                state : {
                    table : "board",
                    del : delArray

                }
            })
            setIsEdit(false)
            // setIsSearch(true)
            setSelectData({})
            await apiPro_read();
        }
        catch(e){
        }
    }

     // column 리스츠 호출
     const apiPro_read = async ( sort ) => {
        try {
            let where_obj = {}

            where_obj["HIQDB_board_cat.type"] = `= '2'`
            where_obj["HIQDB_board.mid"] = `= '${userInfo["id"]}'`

            let data = await apiFn.comApi({
                state : {
                    table : 'board',
                    page : 1,
                    amount : 9999,
                    sort : sort ? sort : "reg_date",
                    ref : [
                        "HIQDB_board_cat.id as xx|HIQDB_board_cat.id=HIQDB_board.cat_id",
                    ],
                    where : where_obj,
                    count : [
                        `HIQDB_board_log.bid:bid:id|and countTable.btype=\'1\'  |readCnt`,
                        `HIQDB_board_log.bid:bid:id|and countTable.btype=\'2\' |likeCnt`,
                    ]
                }
            })

            setColData([
                ...data["list"]
            ])
        }
        catch(e){
        }
    }

    useEffect(()=>{
        apiPro_read();
    },[])

    useEffect(()=>{
        selectData_l = 0;
        // console.log("@@ ",selectData);
        Object.keys(selectData).map((v,i)=>{
            if(selectData[v] == "Y"){
                selectData_l = selectData_l + 1
            }
            else if (selectData[v] == "N") {
                if(selectData_l == 0)return;
                selectData_l = selectData_l - 1
            }
        })
        setSelectData_l(selectData_l)
    },[selectData])
    
    return (
        <div style={{
            // width:'100%',
            // padding : "0px 10px"
        }}>
            {
            isEdit  &&
            <UiTopPopTextBtn
                title="편집 취소" 
                isTeacher={isTeacher}
                fnClick={()=>{
                    setIsEdit(false)
                    setIsSearch(true)
                    setSelectData({})
                }}
            />
            }
            {
            isEdit ?
            <div style={{
                ...Column_Pick_classify_Style.edit_section_w,
                borderTop: isEdit ? "none" : "1px solid",
                borderColor: style.common.borderColor,
            }}>
                선택된 교재를
                <div style={Column_Pick_classify_Style.textBtn_w}>
                    <UiTextBtn
                        text={"삭제하기"}
                        btnStyle={Column_Pick_classify_Style.textBtn}
                        fnClick={()=>{
                            let isOk = window.confirm(`선택한 컬럼 삭제하시겠어요? \n(삭제시 폴더 내의 교재는 모두 폴더 바깥으로 이동됩니다.)`)
                            if(isOk){ 
                                apiPro_del();
                            }
                        }}
                    />
                </div>
            </div>
            :
            <>
            <div style={Column_pick_Style.t_btn_w}>
                <UiBtn
                    btnStyle={Column_pick_Style.t_btn}
                    title={
                        <div style={Academy_book_Style.btn_title}>
                            <Add style={Academy_book_Style.btn_Add} />
                            <div style={{
                                paddingLeft : "12px"
                            }}>
                                새 컬럼 쓰기
                            </div>
                        </div>
                        // <div style={Column_pick_Style.t_btn_text}>
                        //     <Add
                        //         style={Column_pick_Style.t_btn_Add}
                        //     />
                        //     새 컬럼 쓰기
                        // </div>
                    }
                    fnClick={()=>{
                        navigate(`/write/${params["who"]}/home/0/0/n`)
                        
                    }}
                />
            </div>
            <UiSorting 
                isTeacher={isTeacher}
                cnt={`총 ${colData.length}개 컬럼`}
                data={[
                    {name : "등록일순" , value : "reg_date desc"},
                    {name : "좋아요순" , value : "likeCnt desc"},
                    {name : "열람순" , value : "readCnt desc"},
                ]}
                fnClick={(v)=>{
                    apiPro_read(v);
                }}
            />
            </>
            }
            {
            colData.map((v,i)=>
            <UiVideoSection
                setScrolllKey={setScrolllKey}
                scrollKey={scrollKey}
                data={v}
                setSelectData={setSelectData}
                selectData={selectData}
                isEdit={isEdit}
                isTeacher={isTeacher}
                setIsSearch={setIsSearch}
                fnClick={(data)=>{
                    setScrolllKey(data["id"])
                    navigate("/columndetail/"+params["who"]+"/"+data["id"]+"/act")
                }}
                fnLongClick={(data)=>{
                    setIsSearch(false)
                    setIsEdit(true)
                    setSelectData({
                        ...selectData,
                        [data["id"]] : "Y"
                    })
                }}
                fnCheck={(e)=>{
                    if(selectData[e.target.id] == "Y"){
                        delete selectData[e.target.id]
                        setSelectData({
                            ...selectData,
                        })
                    }
                    else {
                        setSelectData({
                            ...selectData,
                            [e.target.id] : "Y"
                        })
                    }
                }}
                key={"mark_folder_"+i}
                id={i}
                navigate={navigate}
                params={params}
                
            />
            )}
            
        </div>
    )
}

const PickSection = ({
    data,
    fnClick
}) => {
    return (
        <div style={Column_pick_Style.section_w}
            onClick={()=>{
                fnClick(data)
            }}
        >
            <div style={Column_pick_Style.section_i_w}>
            </div>
            <div style={Column_pick_Style.section_text_w}>
                <div style={Column_pick_Style.section_text_1}>
                    {data}
                </div>
                <div style={Column_pick_Style.section_text_2}>
                    컬럼 9개
                </div>
            </div>
        </div>
    )
}

export default Column_my