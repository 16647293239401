import { React , useState , useEffect } from 'react'
import style from '../../asset/style'
import { useNavigate, useParams } from 'react-router'
import file from '../../asset/Img/file.png'
import Academy_main_Style from '../Academy/Academy_main_Style'
import { Add } from '@material-ui/icons'
import UiBtn from '../../uiComponents/UiBtn/UiBtn'
import Academy_book_Style from '../Academy/Academy_book_Style'
import UiFolder from '../../uiComponents/UiFolder/UiFolder'
import UiBook from '../../uiComponents/UiBook/UiBook'
import UiTextBtn from '../../uiComponents/UiTextBtn/UiTextBtn'
import UiTopPopTextBtn from '../../uiComponents/UiTopPopTextBtn/UiTopPopTextBtn'
import constants from '../../asset/constants'
import apiFn from '../../asset/apiClass'
import util from '../../asset/util'
import store from "../../asset/store";

const Copy = ({
        userInfo ,
        isTeacher ,
        qnaData,
        setQnaData,
        setIsEdit,
        isMove = false,
    }) => {
    let navigate = useNavigate()
    let params = useParams();

    var [selectData,setSelectData] = useState({})
    var [selectData_l,setSelectData_l] = useState(0)

    var [selectData_book,setSelectData_book] = useState({})
    var [selectData_book_l,setSelectData_book_l] = useState(0)

    // const [isEdit , setIsEdit] = useState(false);
    const [isBookEdit,setIsBookEdit] = useState(false)

    var [bookList,setBookList] = useState([])

    var [folderList,setFolderList] = useState([]);


    var [folderId,setFolderId] = useState(0);

    // 폴더 리스트
    const apiPro_read_folder = async () => {
        try {
            let data = await apiFn.comApi({
                state : {
                    table : "folder",
                    page : 1,
                    amount : 9999,
                    sort : "name desc",
                    where : {
                        "HIQDB_folder.mid" : `='${userInfo["id"]}'`,
                        "HIQDB_folder.ftype" : `='0'`,
                    }
                }
            })
            // console.log(data)
            setFolderList([
                ...data["list"]
            ])
        }
        catch(e){
            // console.log("! ",e)
        }
    }

    // 교재 리스트
    const apiPro_read = async () => {
        try {
            // let where = {
            //     "HIQDB_book_link.fid" : `= 0`,
            // }
            // if(params['type'] == "folder"){
            //     "HIQDB_book_link.fid" = `= ${folderId}`,
            // }
            if(params["who"] == "teacher"){
                // let isOk = await apiFn.comApi({
                //     state : {
                //         table : "book",
                //         page : 1,
                //         amount : 999,
                //         u_id : userInfo["id"],
                //         sort : "reg_date desc",
                //         ref : [
                //             'HIQDB_book_link.cid|HIQDB_book_link.bid=HIQDB_book.id',
                //         ],
                //         where : {
                //             "HIQDB_book_link.mid" : `= '${userInfo["id"]}'`,
                //             "HIQDB_book.status" : `!= '9'`,
                //             "HIQDB_book_link.type" : `= '5'`,
                //             "HIQDB_book_link.fid" : `= ${folderId}`,
                //             // ...where
                //             // "HIQDB_book_link.cid" : `= 0`,
                //         }

                //     }
                // })

                let isOk = await apiFn.comApi({
                    state : {
                        table : "book",
                        page : 1,
                        amount : 999,
                        u_id : userInfo["id"],
                        sort : "reg_date desc",
                        ref : [
                            'HIQDB_book_link.cid|HIQDB_book_link.bid=HIQDB_book.id',
                        ],
                        where : {
                            "HIQDB_book_link.mid" : `= '${userInfo["id"]}'`,
                            "HIQDB_book_link.status" : `= '1'`,
                            "HIQDB_book.status" : `!= '9'`,
                            "HIQDB_book_link.fid" : `= 0`,
                            // "HIQDB_book_link.cid" : `= 0`,
                        }

                    }
                })


                // console.log("teacher Book Data ",isOk["list"])
                setBookList([
                    ...isOk["list"]
                ])
            }
            else {
                let isOk = await apiFn.studentBooklist({
                    state : {
                        sid : userInfo["id"],
                        page : 1,
                        amount : 9999,
                        sort : "reg_date desc",
                        fid : folderId,
                    }
                })
                // console.log("student Book Data => ", isOk)
                setBookList([
                    ...isOk["list"]
                ])
            }
        }
        catch(e){
            // console.log("? ",e)
        }
    }

    const apiPro_set = async (id) => {
        try {

            if(params["type"] == "n"){
                let isOk = await apiFn.copyQna({
                    state : {
                        tid : userInfo["id"],
                        bid : id,
                        qids : [params["id"]]
                    }
                })
                console.log("isOk : ",isOk)

            const stack = store.get('stack')
            if(stack.length > 0)
                navigate(-1);
            else
                navigate('/')
            }
            else{
                let array = Object.keys(qnaData).reduce((a,v,i)=>{
                    return [
                        ...a,
                        v
                    ]
                },[])
                // console.log(array);
                let isOk = await apiFn.copyQna({
                    state : {
                        tid : userInfo["id"],
                        bid : id,
                        qids : array
                    }
                })
                setQnaData({})
                setIsEdit(false)

                const _form = params["form"]
                navigate(`/academy/${params["who"]}/book`)
                navigate(`/book/${params["who"]}/${params["id"]}/0/de`)
                if(params["id"] == 0){
                    navigate(`/book/${params["who"]}/${params["id"]}/0/de`)
                }
                else {
                    navigate(`/explainmodel/${params["who"]}/${params["id"]}/${params["type"]}/book`)
                }

            }
        }
        catch(e){
            // console.log(e)
        }
    }

    const apiPro_set_move = async (id) => {
        try {
            console.log("qnaData => ",qnaData);
            // let array = Object.keys(qnaData).reduce((a,v,i)=>{
            //     return [
            //         ...a,
            //         v
            //     ]
            // },[])
            // // // console.log(array);
            // // return;
            // // return;
            // let isOk = await apiFn.comApi({
            //     state : {
            //         table : "book_link",
            //         set : [{
            //             // key : `bid:${data["id"]} and qid='${params["qid"]}`,
            //             key : `qid:${array[0]}`,
            //             value : { bid : id }
            //         }]
            //     }
            //     // key : `bid:${v}' and mid='${userInfo["id"]}`,
            //     // set: [ { key: 'prKey:15', value: {a:1,b:2}  } ]
            // })

            if(params["type"] == "n"){

            const stack = store.get('stack')
            if(stack.length > 0)
                navigate(-1);
            else
                navigate('/')
                apiFn.comApi({
                    state : {
                        table : "book_link",
                        set: [{
                            key : `qid:${params["id"]}`,
                            value : { bid : id }

                            // fid: params["id"],
                            // qid : v,
                            // mid : userInfo["id"]
                        }]
                    }
                })
            }
            else {

                let promiseArray = Object.keys(qnaData).map((v,i)=>
                    apiFn.comApi({
                        state : {
                            table : "book_link",
                            set: [{
                                key : `qid:${v}`,
                                value : { bid : id }

                                // fid: params["id"],
                                // qid : v,
                                // mid : userInfo["id"]
                            }]
                        }
                    })
                )
                await Promise.all(promiseArray)

                setQnaData({})
                setIsEdit(false)
                navigate(`/academy/${params["who"]}/book`)
                navigate(`/book/${params["who"]}/${params["id"]}/0/de`)
                if(params["id"] == 0){
                    navigate(`/book/${params["who"]}/${params["id"]}/0/de`)
                }
                else {
                    navigate(`/explainmodel/${params["who"]}/${params["id"]}/${params["type"]}/book`)
                }
            }
        }
        catch(e){
            // console.log(e)
        }
    }

    useEffect(()=>{
        apiPro_read();
    },[])

    useEffect(()=>{
        if(params["id"] == 0){
            apiPro_read()
        }
    },[params["id"]])

    useEffect(()=>{
        if(params["type"] == "folder"){
            apiPro_read()
        }
        else if(params["type"] == "de") {
            folderId = 0
            setFolderId(0)
            apiPro_read_folder();
            apiPro_read()
        }
    },[params["type"]])

    return (
           <div style={{
               ...Academy_main_Style.c,
               padding : "0px 15px"
            }}>
            <div style={Academy_book_Style.list_folder_w}>
            {
            folderId == 0 &&
            folderList.map((v,i) =>
                <UiFolder
                    data={v}
                    setSelectData={setSelectData}
                    selectData={selectData}
                    // isEdit={isEdit}
                    isTeacher={isTeacher}
                    // setIsSearch={setIsSearch}
                    key={"mark_folder_"+i}
                    id={i}
                    navigate={navigate}
                    fnClick={()=>{
                        setBookList([])
                        // console.log(v)
                        setFolderId(v["id"])
                    }}
                    navi={`/explainmodel/${params["who"]}/${params["id"]}/copy/folder`}
                    params={params}
                />
            )
            }
            </div>
            <div style={Academy_book_Style.list_book_w}>
                {
                bookList.map((v,i)=>
                <UiBook
                    data={v}
                    key={"mark_folder_"+i}
                    id={i}
                    navigate={navigate}
                    // navi={
                    //     params["who"] == "teacher" ?
                    //     `/book/${params["who"]}/${v["id"]}/0`
                    //     :
                    //     `/book/${params["who"]}/${v["id"]}/${v["classID"]}`
                    // }
                    fnClick={()=>{
                        let isOk;
                        if(isMove){
                            if(params["id"] == 0){
                                isOk = window.confirm("선택하신 문항을 해당 교재로 이동하시겠습니까?")
                            }
                            else {
                                isOk = window.confirm("선택하신 모범문항을 해당 교재로 이동하시겠습니까?")
                            }
                        }
                        else {
                            if(params["id"] == 0){
                                isOk = window.confirm("선택하신 문항을 해당 교재로 복사하시겠습니까?")
                            }
                            else {
                                isOk = window.confirm("선택하신 모범문항을 해당 교재로 복사하시겠습니까?")
                            }
                        }
                        if(!isOk)return;
                        if(isMove){
                            apiPro_set_move(v["id"])
                        }
                        else {
                            apiPro_set(v["id"])
                        }
                    }}
                    params={params}
                    isShare={params["who"] == "teacher" ? true : false}
                />
                )}
            </div>
        </div>
    )
}

export default Copy
