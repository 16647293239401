import React, { ReactNode } from "react";
import styled from "styled-components";

interface IHeaderProps {
  title: ReactNode;
  leftIcon?: ReactNode;
  rightIcon?: ReactNode;
  onLeftClick?: () => void;
  onRightClick?: () => void;
}
const Header = ({
  title,
  leftIcon,
  rightIcon,
  onLeftClick,
  onRightClick,
}: IHeaderProps) => {
  return (
    <Container>
      <div onClick={onLeftClick}>{leftIcon}</div>
      {title}
      <div onClick={onRightClick}>{rightIcon}</div>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 44px;
  color: #21272a;
  padding: 12px 20px;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%;
  letter-spacing: 0.32px;
  position: sticky;
  top: 0;
  z-index: 2;
  background-color: #fbfcfe;
  /* background-color: #fbfcfe88; */
  backdrop-filter: blur(5px);
`;

export default Header;
