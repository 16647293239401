import { useStudyBookDelete, useStudyBookList } from "api/studyBook";
import { useConfirm } from "providers/ConfirmProvider";
import { showToast } from "providers/ToastProvider";
import React, { useCallback, useContext, useMemo, useState } from "react";
import { StudyBookContext } from "../context/StudyBookContext";
import { ButtonContainer, GrayButton } from "./FolderEdit";
import StudyBookMoveModal from "./StudyBookMoveModal";

const StudyBookConfig = () => {
  const { allCheckedBook } = useContext(StudyBookContext);
  const { data } = useStudyBookList();

  const folderList = useMemo(() => data.bookList, [data]);
  const handleAllCheck = useCallback(() => {
    allCheckedBook(folderList);
  }, [allCheckedBook, folderList]);

  return (
    <ButtonContainer>
      <GrayButton onClick={handleAllCheck}>전체선택</GrayButton>
      {/* <GrayButton>공유</GrayButton> */}
      <StudyBookMove />
      <StudyBookDelete />
    </ButtonContainer>
  );
};

const StudyBookDelete = () => {
  const { checkedBookList } = useContext(StudyBookContext);

  const { showConfirm } = useConfirm();
  const { mutateAsync } = useStudyBookDelete();

  const handleDelete = useCallback((bookData) => {
    if (bookData?.id === 0) {
      showToast({
        message: "미지정 교재는 제거할 수 없습니다.",
        title: "교재 삭제",
        type: "warning",
      });
      return;
    } else if (bookData?.type === 11) {
      showToast({
        message: `${bookData.name}은 기본 교재 입니다.\n 수업 기본 교재 설정 해제 후 제거할 수 있습니다.`,
        title: "교재 삭제",
        type: "warning",
      });
    }
    mutateAsync(bookData?.id);
  }, []);

  const handleConfirm = useCallback(() => {
    showConfirm({
      message: "삭제된 교재는 휴지통에서 30일간 보관 후 영구삭제 됩니다.",
      title: "폴더 삭제",
      onConfirm() {
        checkedBookList.forEach((book) => {
          handleDelete(book);
        });
      },
    });
  }, [showConfirm]);
  return (
    <GrayButton onClick={handleConfirm} disabled={!checkedBookList.length}>
      삭제
    </GrayButton>
  );
};

const StudyBookMove = () => {
  const [open, setOpen] = useState(false);
  const { checkedBookList, setIsConfigMode } = useContext(StudyBookContext);
  const bookIds = useMemo(() => checkedBookList.map((book) => `${book.id}`), [checkedBookList]);
  const handleClose = useCallback(() => {
    setOpen(false);
  }, [setOpen]);
  const handleSuccess = useCallback(() => {
    setIsConfigMode(false);
    handleClose();
  }, [setIsConfigMode, handleClose]);
  return (
    <>
      <GrayButton onClick={() => setOpen(true)} disabled={!checkedBookList.length}>
        이동
      </GrayButton>
      <StudyBookMoveModal
        open={open}
        onClose={() => setOpen(false)}
        bookIds={bookIds}
        onSuccess={handleSuccess}
      />
    </>
  );
};

export default StudyBookConfig;
