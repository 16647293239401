import { Box } from "@mui/material";
import React from "react";
import { BackButton } from "../backButton";
import { MenuButton } from "../menuButton";
import { AppbarDummy } from "./dummy";
import { AppbarTitle } from "./title";
import type { AppbarProps } from "./types";

export function Appbar({ children }: AppbarProps): JSX.Element {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        position: "sticky",
        top: 0,
        left: 0,
        height: "45px",
        maxHeight: "45px",
        width: "100%",
        zIndex: 10,
        backgroundColor: "surface.default",
        maxWidth: " 1180px",
        margin: "0 auto",
      }}
    >
      {children}
    </Box>
  );
}

Appbar.BackButton = BackButton;
Appbar.MenuButton = MenuButton;
Appbar.Title = AppbarTitle;
Appbar.Dummy = AppbarDummy;
