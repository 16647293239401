import { React , useState , useEffect, useRef } from 'react'
import style from '../../asset/style'
import { useNavigate, useParams } from 'react-router'
import UiBtn from '../../uiComponents/UiBtn/UiBtn'
import { Add } from '@material-ui/icons'
import UiSorting from '../../uiComponents/UiSorting/UiSorting'
import Column_pick_Style from './Column_pick_Style'
import apiFn from '../../asset/apiClass'
import empty_folder from '../../asset/Img/empty-folder.png'
import util from '../../asset/util'
import constants from '../../asset/constants'
import Academy_book_Style from '../Academy/Academy_book_Style'

const Column_pick = ({
    isTeacher,
    userInfo,
    createObj,
    setCreateObj,
    scrollKey,
    setScrolllKey,
}) => {
    let navigate = useNavigate();
    let params = useParams();

    const [folderList,setFolderList] = useState([])

    // 폴더 리스트
    const apiPro_read_folder = async (sorting) => {
        try {
            let data = await apiFn.comApi({
                state : {
                    thumb : "y",
                    table : "folder",
                    page : 1,
                    amount : 9999,
                    sort : sorting ? sorting : "id desc",
                    where : {
                        "HIQDB_folder.mid" : `='${userInfo["id"]}'`,
                        "HIQDB_folder.ftype" : `='2'`,

                    }
                }
            })
            // console.log(data["list"])
            setFolderList([
                ...data["list"]
            ])
        }
        catch(e){
        }
    }

    // 폴더 생성
    const apiPro_set = async (name) => {
        try {
            let isOk = await apiFn.comApi({
                state : {
                    table : "folder",
                    ins: [
                        {
                            name : name,
                            ftype: "2" ,
                            mid : userInfo["id"],
                            open : 1,
                            usee : 1,
                        }
                    ]
                }
            })
            // await apiPro_read_folder()
        }
        catch(e){
        }
    }

    useEffect(()=>{
        apiPro_read_folder();
    },[])

    return (
        <div style={{
            // width:'100%',
            // padding : "0px 10px"
        }}>
            <div style={Column_pick_Style.t_btn_w}>
                <UiBtn
                    btnStyle={Column_pick_Style.t_btn}
                    title={
                        <div style={Academy_book_Style.btn_title}>
                            <Add style={Academy_book_Style.btn_Add} />
                            <div style={{
                                paddingLeft : "12px"
                            }}>
                                찜 폴더 추가
                            </div>
                        </div>
                        // <div style={Column_pick_Style.t_btn_text}>
                        //     <Add
                        //         style={Column_pick_Style.t_btn_Add}
                        //     />
                        //     찜 폴더 추가
                        // </div>
                    }
                    fnClick={()=>{
                        setCreateObj({
                            api : apiPro_set,
                            title : "찜 폴더 추가",
                            input_title : '폴더 이름',
                        })
                        navigate(`/create/${params["who"]}`)
                        // let fName = window.prompt("폴더 이름을 입력해주세요.")
                        // // console.log(fName)
                        // if(fName){
                        //     apiPro_set(fName)
                        // }
                    }}
                />
            </div>
            <UiSorting
                title='찜 폴더'
                isTeacher={isTeacher}
                data={[
                    { name : "최신순" , value : "id desc"},
                    { name : "글자순" , value : "name desc"},
                ]}
                fnClick={(v)=>{
                    // console.log(v)
                    apiPro_read_folder(v)
                }}
            />
            {
            folderList.map((v,i)=>{
                try {
                    return (
                        <PickSection
                            setScrolllKey={setScrolllKey}
                            scrollKey={scrollKey}
                            fnClick={(data)=>{
                                setScrolllKey(data["id"])
                                navigate("/columnclassify/"+params["who"]+"/"+data["id"])
                            }}
                            data={v}

                        />
                    )
                }
                catch(e){
                    // console.log(e)
                }
            }
            )}

        </div>
    )
}

const PickSection = ({
    data,
    fnClick,
    scrollKey,
    setScrolllKey,
}) => {

    var scrollRef = useRef(null);

    useEffect(()=>{
        if(scrollRef.current){
            scrollRef.current.scrollIntoView(
                {
                    // behavior: "smooth",
                    block: "center"
                }
            );
            if(setScrolllKey){setScrolllKey("")}
        }
    },[scrollRef])

    useEffect(()=>{
        // console.log("data => ",data)
    },[data])
    return (
        <div style={Column_pick_Style.section_w}
            onClick={()=>{
                fnClick(data)
            }}
            ref={scrollKey == data["id"] ? scrollRef : null}
        >
            <div style={Column_pick_Style.section_i_w}>
                <img
                    onError={(e)=>{
                        // setIsLoad(false)
                        e.currentTarget.src = empty_folder
                    }}
                     src={
                        data["thumb"]["video_link"] ?
                        (
                            data["thumb"]["video_link"] .includes("watch") ?
                            `http://img.youtube.com/vi/${data["thumb"]["video_link"] .split("=")[1].split("&")[0]}/mqdefault.jpg`
                            :
                            `http://img.youtube.com/vi/${data["thumb"]["video_link"] .split("youtu.be/")[1].split("&")[0]}/mqdefault.jpg`
                        )
                        // `http://img.youtube.com/vi/${data["thumb"]["video_link"].split("=")[1]}/mqdefault.jpg`
                        // `${data["video_link"]}/default.jpg`
                        :
                        data["thumb"]["id"] ?
                        (
                        constants.apiUrl
                        +
                        // '/public'
                        // +
                        `/board/${data["thumb"]["id"]}`
                        +
                        '.jpg?'
                        +
                        util.getRandomInt(1000,9999)
                        )
                        :
                        empty_folder
                    }
                    style={{
                        width : '100%',
                        height : "100%",
                    }}
                />
            </div>
            <div style={Column_pick_Style.section_text_w}>
                <div style={Column_pick_Style.section_text_1}>
                    {data["name"]}
                </div>
                <div style={Column_pick_Style.section_text_2}>
                    {`컬럼 ${data["thumb"]["cnt"]}개`}
                </div>
            </div>
        </div>
    )
}

export default Column_pick
