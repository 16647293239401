import ControlPointIcon from "@mui/icons-material/ControlPoint";
import React, { useContext, useState } from "react";
import styled from "styled-components";
import { MessageMarkContext } from "../context/MessageMarkContextProvider";
import { FolderItemContainer } from "./FolderItem";
import FolderRegistModal from "./FolderRegistModal";
const FolderRegistItem = () => {
  const [open, setOpen] = useState(false);
  const { setIsDeleteMode } = useContext(MessageMarkContext);
  return (
    <>
      <EmptyFolder
        onClick={() => {
          setIsDeleteMode(false);
          setOpen(true);
        }}
      >
        <ControlPointIcon />
        노트생성
      </EmptyFolder>
      {open && (
        <FolderRegistModal
          type="create"
          open={open}
          onClose={() => setOpen(false)}
        />
      )}
    </>
  );
};

const EmptyFolder = styled(FolderItemContainer)`
  align-items: center;
  justify-content: center;
  background-color: #eeeeee;
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  letter-spacing: 0em;
  color: #8e8e8e;
  gap: 8px;
  box-shadow: 4px 4px 6px 0px #0000001a;
  border-radius: 12px;
  aspect-ratio: 110/142;
  > svg {
    font-size: 40px;
  }
`;

export default FolderRegistItem;
