import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { getUser } from "asset/storage";
import { useInfiniteScroll } from "hooks/useInfiniteScroll";
import { useMemo } from "react";
import { useClassList } from "./classRegist";
import { axiosInstance } from "./common";

export interface LectureVideoListFilterRequset {
  keyword?: string;
  order?: string;
  cid?: string;
  type?: number;
  amount?: number;
}

export interface LectureVideoListResponse {
  articleList: LectureListItem[];
  tcnt: number;
}

export interface LectureListItem {
  id: number;
  reg_date: number;
  video_end: number;
  cid: number;
  view_count: number;
  reply_count: number;
  view_member_count: number;
  content: string;
  img: string;
  title: string;
  video_link: string;
  mid_HIQDB_user_credential: {
    name: string;
    user_id: number;
    user: {
      name: string;
      nick: string;
      id: string;
    };
  };
  cid_HIQDB_class: {
    name: string;
    id: number;
  };
}

export const useLectureMainVideoList = () => {
  const userInfo = getUser();
  const { data } = useClassList(userInfo.id);

  const cidList = useMemo(() => data?.map((item) => item.cid), [data]);

  return useQuery<LectureVideoListResponse>(
    [`/user/${userInfo.id}/class/board`, { page: 1, cid: cidList, amount: 3, type: 7 }],
    { enabled: !!cidList?.length }
  );
};

export const useLectureVideoList = (parameters: LectureVideoListFilterRequset) => {
  const userInfo = getUser();
  const { data } = useClassList(userInfo.id);

  const cidList = useMemo(() => data?.map((item) => item.cid), [data]);

  return useInfiniteScroll<LectureVideoListResponse>({
    queryKey: [
      `/user/${userInfo.id}/class/board`,
      { ...parameters, page: 1, cid: parameters.cid || cidList },
    ],
  });
};

export const useLectureVideoDelete = () => {
  const queryClient = useQueryClient();
  const userInfo = getUser();
  return useMutation(
    (id: number) => {
      return axiosInstance.delete(`/board/${id}`);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([`/user/${userInfo.id}/class/board`]);
      },
    }
  );
};
