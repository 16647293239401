export enum WhoType {
  teacher = 2,
  student = 1,
}

export enum WhoIs {
  teacher = "teacher",
  student = "student",
}

export enum BookType {
  질문교재 = 0,
  PDF교재 = 1,
  내필기교재 = 2,
  시중교재 = 3,
  미검색교재 = 4,
}
