import { ThemeProvider, createTheme } from "@mui/material";
import React from "react";

const theme = createTheme({
  components: {
    MuiButton: {
      variants: [
        {
          props: { size: "small" },
          style: {
            padding: "0 8px",
            minWidth: 56,
            height: 24,
            fontSize: 14,
          },
        },
        {
          props: { size: "large" },
          style: {
            padding: "0 32px",
            minWidth: 106,
            height: 46,
            fontSize: 16,
          },
        },
        {
          props: { size: "medium" },
          style: {
            padding: "0 16px",
            minWidth: 82,
            height: 36,
            fontSize: 14,
          },
        },
      ],
    },
  },
  palette: {
    primary: {
      main: "#FE544E",
      "100": "#FEF3F1",
      "200": "#FEBCB1",
      "300": "#FEB5A7",
      "400": "#FEA69B",
      "500": "#FE8F86",
      "600": "#FE746E",
      "700": "#FE544E",
      "800": "#CD403D",
    },
    grey: {
      "100": "#FAFAFA",
      "200": "#F0F0F0",
      "300": "#D9D9D9",
      "400": "#BFBFBF",
      "500": "#8C8C8C",
      "600": "#434343",
      "700": "#262626",
      "800": "#141414",
    },
    error: {
      main: "#CF1322",
      "100": "#FFF1F0",
      "200": "#FFCCC7",
      "300": "#FFA39E",
      "400": "#FF7875",
      "500": "#FF4D4F",
      "600": "#F5222D",
      "700": "#CF1322",
      "800": "#A8071A",
    },
    warning: {
      main: "#FFAD0D",
      "100": "#FFFDFA",
      "200": "#FFF9EE",
      "300": "#FFF7E1",
      "400": "#FFEAB3",
      "500": "#FFDD82",
      "600": "#FFC62B",
      "700": "#FFAD0D",
      "800": "#FE9B0E",
    },
    success: {
      main: "#47B881",
      "100": "#FBFEFC",
      "200": "#F2FAF6",
      "300": "#E5F5EC",
      "400": "#C0E5D1",
      "500": "#97D4B4",
      "600": "#6BC497",
      "700": "#47B881",
      "800": "#0C9D61",
    },
    info: {
      main: "#3B82F6",
      "100": "#F8FCFF",
      "200": "#F1F8FF",
      "300": "#E4F2FF",
      "400": "#BDDDFF",
      "500": "#93C8FF",
      "600": "#4BA1FF",
      "700": "#3B82F6",
      "800": "#3A70E2",
    },
  },
});
const RootThemeProvider = ({ children }) => {
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};

export default RootThemeProvider;
