import React, {createContext, useMemo, useState} from "react";
import {PdfItem} from "../../../../api/pdfView";

export interface PdfViewActionsContextState {
  currentSlideInfo: PdfItem;
  changeCurrentSlideInfo: (currentInfo: PdfItem) => void;
  changeModalState: (state: boolean) => void;
  pdfAllViewModalShow: boolean,
  changeCurrentSlideIndex: (index: number) => void,
  currentSlide: number,
  changeImgViewModalShow: (state: boolean) => void;
  imgViewModalShow: boolean,
  changePdfImgEditModalShow: (state: boolean) => void;
  pdfImgEditModalShow: boolean
}

export const PdfViewContext = createContext<PdfViewActionsContextState>({
  currentSlideInfo: null,
  changeCurrentSlideInfo: (currentInfo: PdfItem) => {
  },
  changeModalState: () => {
  },
  pdfAllViewModalShow: false,
  changeCurrentSlideIndex: (index: number) => {
  },
  currentSlide: 0,
  changeImgViewModalShow: () => {
  },
  imgViewModalShow: false,
  changePdfImgEditModalShow: () => {
  },
  pdfImgEditModalShow: false,
});

const PdfViewContextProvider = ({children}) => {
  const [currentSlideInfo, setCurrentSlideInfo] = useState<PdfItem>(null);
  const [pdfAllViewModalShow, setPdfAllViewModalShow] = useState<boolean>(false);
  const [currentSlide, setCurrentSlide] = useState<number>(0)
  const [imgViewModalShow, setImgViewModalShow] = useState<boolean>(false);
  const [pdfImgEditModalShow, setPdfImgEditModalShow] = useState<boolean>(false);

  const actions = useMemo(() => ({
    changeCurrentSlideInfo(currentInfo) {
      setCurrentSlideInfo(currentInfo)
    },
    currentSlideInfo,
    changeModalState(state) {
      setPdfAllViewModalShow(state)
    },
    pdfAllViewModalShow,
    changeCurrentSlideIndex(index) {
      setCurrentSlide(index)
    },
    currentSlide,
    changeImgViewModalShow(state) {
      setImgViewModalShow(state)
    },
    imgViewModalShow,
    changePdfImgEditModalShow(state) {
      setPdfImgEditModalShow(state)
    },
    pdfImgEditModalShow,
  }), [currentSlideInfo, pdfAllViewModalShow, currentSlide, imgViewModalShow, pdfImgEditModalShow])

  return (
    <PdfViewContext.Provider value={actions}>
      {children}
    </PdfViewContext.Provider>
  )
}
export default PdfViewContextProvider