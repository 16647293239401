import store from "asset/store";
import { VideoContext } from "components/molecules/VideoPlayer";
import React, { useContext, useEffect, useState } from "react";
import styles from "./style.module.scss";

const PlaySpeed = () => {
  const { player } = useContext(VideoContext);
  const [isHovered, setIsHovered] = useState(false);
  const { isMobile } = store;

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const handleSpeedChange = (speed: number) => {
    player.playbackRate(speed);
    setIsHovered(false);
  };

  useEffect(() => {
    if (isMobile()) {
      setTimeout(() => setIsHovered(false), 5000);
    }
  }, [isHovered]);
  return (
    <div
      className={styles.container}
      onMouseEnter={() => {
        isMobile() || handleMouseEnter();
      }}
      onMouseLeave={() => {
        isMobile() || handleMouseLeave();
      }}
      onTouchEnd={() => {
        handleMouseEnter();
      }}
    >
      {isHovered && (
        <div className={styles.selectWrapper}>
          <button onClick={() => handleSpeedChange(0.25)}>0.25</button>
          <button onClick={() => handleSpeedChange(0.5)}>0.5</button>
          <button onClick={() => handleSpeedChange(0.75)}>0.75</button>
          <button onClick={() => handleSpeedChange(1)}>1</button>
          <button onClick={() => handleSpeedChange(1.25)}>1.25</button>
          <button onClick={() => handleSpeedChange(1.5)}>1.5</button>
          <button onClick={() => handleSpeedChange(1.75)}>1.75</button>
          <button onClick={() => handleSpeedChange(2)}>2</button>
        </div>
      )}
      <div className={styles.speedText}>{player.playbackRate()}x</div>
    </div>
  );
};

export default PlaySpeed;
