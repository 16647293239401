import VolumeDownRoundedIcon from "@mui/icons-material/VolumeDownRounded";
import VolumeOffRoundedIcon from "@mui/icons-material/VolumeOffRounded";
import VolumeUpRoundedIcon from "@mui/icons-material/VolumeUpRounded";
import store from "asset/store";
import { VideoContext } from "components/molecules/VideoPlayer";
import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import styles from "./style.module.scss";

const VolumeControl = () => {
  const { player } = useContext(VideoContext);
  const { isMobile } = store;
  useEffect(() => {
    isMobile() && player?.volume(1);
  }, [player, isMobile]);

  // const volume = useMemo(() => player?.volume, [player?.volume]);
  const [muted, setMuted] = useState(false);
  const [isShowProgress, setIsShowProgress] = useState(false);

  const handleVolumeChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (player) {
        const volume = Number(e.target.value) / 100;
        player.volume(volume);
      }
    },
    [player]
  );

  const handleMute = useCallback(() => {
    if (player) {
      setMuted((prev) => {
        player.muted(!prev);
        return !prev;
      });
    }
  }, [player, muted, setMuted]);

  const visibleVolume = useMemo(
    () => (muted ? 0 : player.volume() * 100),
    [muted, player.volume()]
  );

  useEffect(() => {
    const handleKeyEvents = (e: KeyboardEvent) => {
      if (e.key === "ArrowUp") {
        if (player.volume() < 1) {
          player.volume(player.volume() + 0.05);
        } else {
          player.volume(1);
        }
      }
      if (e.key === "ArrowDown") {
        if (player.volume() > 0) {
          player.volume(player.volume() - 0.05);
        } else {
          player.volume(0);
        }
      }
      if (e.key === "m" || e.key === "M" || e.key === "ㅡ") {
        handleMute();
      }
    };
    window.addEventListener("keydown", handleKeyEvents);
    return () => {
      window.removeEventListener("keydown", handleKeyEvents);
    };
  }, [player]);

  const gradient = `linear-gradient(to right, white 0%, white ${visibleVolume}%, rgba(255, 255, 255, 0.3) ${visibleVolume}%, rgba(255, 255, 255, 0.3) 100%)`;

  return (
    <div
      className={styles.wrapper}
      onMouseEnter={() => setIsShowProgress(true)}
      onMouseLeave={() => setIsShowProgress(false)}
    >
      <button className={styles.button} onClick={handleMute}>
        {player.volume() === 0 || muted ? (
          <VolumeOffRoundedIcon />
        ) : player.volume() > 0.5 ? (
          <VolumeUpRoundedIcon />
        ) : (
          <VolumeDownRoundedIcon />
        )}
      </button>
      <input
        type="range"
        className={[styles.valumeProgress, isShowProgress ? "" : styles.hide].join(" ")}
        onChange={handleVolumeChange}
        value={visibleVolume}
        style={{
          background: gradient,
        }}
      />
    </div>
  );
};

export default VolumeControl;
