import style from "../../asset/style"

const UiNoticeDetail_Style = {
    title_w : {
        display:"flex",
        alignItems:"center",
        // marginTop : "10px",
        // height:'100%',
        backgroundColor:"white",
    },
    title_i : {
        width : "36px",
        height : "38px",
        marginRight : "10px",
    },
    title_ : {
        fontSize : "10px",
        marginTop : "3px",
    },
    c : {
        marginTop : "10px",
        width : "100%",
    },
    w : {
        display:"flex",
        flexDirection:'column',
        width : "100%",
        // height : "50px",
        // backgroundColor : style.common.borderColor2
        backgroundColor : "white",
        borderTop : "1px solid",
        borderBottom : "1px solid",
        borderColor : "rgb(200,200,200)",
    },
    text_w : {
        // width : "100%",
        minHeight : "40px",
        padding : "15px 15px 5px 15px",
    },
    text_1 : {
        display:"flex",
        fontWeight : 600,
        fontSize : "15px",
    },
    text_2 : {
        display:"flex",
        justifyContent:"flex-end",
        fontSize : "11px",
        // width : "100%",
        marginTop : "5px",
        color : style.common.fontColor_1
    },
    editor : {
        width : "100%",
        minHeight : "40px",
        padding : "15px",
        backgroundColor : "white"
    }
}

export default UiNoticeDetail_Style