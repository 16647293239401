import type { IcDefatultProps } from "../types";
import React from "react";

export default function ICopyRight({
  width = 24,
  height = 24,
  color = "#363636",
}: IcDefatultProps): JSX.Element {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.8333 8.75L13.8333 4.99995C13.8333 3.34309 12.4902 1.99994 10.8333 1.99995L7.08334 2M9.83334 14L5.33334 14C4.50491 14 3.83334 13.3284 3.83334 12.5L3.83334 6C3.83334 5.17157 4.50491 4.5 5.33333 4.5L9.83334 4.5C10.6618 4.5 11.3333 5.17157 11.3333 6L11.3333 12.5C11.3333 13.3284 10.6618 14 9.83334 14Z"
        stroke={color}
        strokeWidth="1.2"
        strokeLinecap="round"
      />
    </svg>
  );
}
