import React, { ChangeEvent, useEffect, useState } from "react";
import {
  Container,
  ContentSection,
  ContentWrapper,
  MultiWindowButton,
  MultiWindowWrapper,
  StyledDialog,
} from "../Question/styled";
import { useRecoilState, useSetRecoilState } from "recoil";
import { editShowModalAtom } from "../../../../../application/recoils/modal/atoms";
import { EditModalEnum } from "../../../../../application/recoils/modal/types";
import EditAppbar from "../../appbar";
import LatexEditor from "../../LatexEditor";
import { answerContentAtom } from "../../../../../application/recoils/answer/atom";
import DraggableModal from "../../draggableModal";
import { DraggingModalAtom } from "../../../../../application/recoils/ui/atoms";
import { DraggingModalEnum } from "../../../../../application/recoils/ui/types";

type EditAnswerModalProps = {
  requestEditAnswer?: (answerContent: string) => void;
};

export default function EditAnswerModal({ requestEditAnswer }: EditAnswerModalProps): JSX.Element {
  const [EditModalData, setEditModalData] = useRecoilState(editShowModalAtom);
  const [answerContent, setAnswerContent] = useRecoilState(answerContentAtom);
  const [editAnswerContent, setEditAnswerContent] = useState<string>(answerContent);
  const setShowMultimodal = useSetRecoilState(DraggingModalAtom);
  const handleCloseModal = () => {
    setEditModalData(EditModalEnum.EMPTY);
    setShowMultimodal(DraggingModalEnum.EMPTY);
  };

  const handleComplete = () => {
    handleCloseModal();
    setAnswerContent(editAnswerContent);
    if (requestEditAnswer) requestEditAnswer(editAnswerContent);
  };
  const handleEditAnswerContent = (event: ChangeEvent<HTMLInputElement>) => {
    setEditAnswerContent(event.target.value);
  };

  const handleOpenModal = (modalType: DraggingModalEnum) => {
    setShowMultimodal(modalType);
  };

  useEffect(() => {
    if (EditModalData === EditModalEnum.ANSWER && answerContent) {
      setEditAnswerContent(answerContent);
    }
  }, [EditModalData, answerContent]);

  return (
    <StyledDialog open={EditModalData === EditModalEnum.ANSWER} fullScreen>
      <Container>
        <EditAppbar
          appBarTitle={"풀이수정"}
          onCloseModal={handleCloseModal}
          onCompleteEdit={handleComplete}
        />
        <ContentWrapper>
          <ContentSection>
            <LatexEditor content={editAnswerContent} themeType={"yellow"} />
          </ContentSection>
          <ContentSection>
            <LatexEditor
              mode={"edit"}
              themeType={"dark"}
              content={editAnswerContent}
              onEditMessage={handleEditAnswerContent}
            />
          </ContentSection>
          <MultiWindowWrapper>
            <MultiWindowButton onClick={() => handleOpenModal(DraggingModalEnum.QUESTION)}>
              문제이미지
            </MultiWindowButton>
            <MultiWindowButton
              background={"#FFead8"}
              color={"#ff7f09"}
              onClick={() => handleOpenModal(DraggingModalEnum.ANSWER)}
            >
              풀이내용
            </MultiWindowButton>
          </MultiWindowWrapper>
        </ContentWrapper>
        <DraggableModal usedEditButton={true} />
      </Container>
    </StyledDialog>
  );
}
