import { useStudyBookInfo } from "api/studyBook";
import { ReactComponent as LeftArrowIcon } from "asset/images/icon/left.svg";
import { getUser } from "asset/storage";
import { FontStyleMap } from "components/atoms/Font";
import SideMenu from "pages/StudyBookDetail/components/SideMenu";
import React, { useMemo } from "react";
import { useNavigate, useParams } from "react-router";
import styled from "styled-components";

const StudyBookHeader = () => {
  const { bookId } = useParams();
  const { data } = useStudyBookInfo(bookId);
  const title = useMemo(() => data?.getBook.book.name, [data]);
  const navigate = useNavigate();
  const userInfo = getUser();
  const isTeacher = useMemo(() => userInfo?.type === 2, [userInfo]);
  return (
    <Container>
      <BackIconContainer onClick={() => navigate(-1)}>
        <LeftArrowIcon stroke="" />
      </BackIconContainer>
      <TitleFont>{title}</TitleFont>
      {isTeacher && <SideMenu />}
    </Container>
  );
};

const Container = styled.header`
  padding: 8px 0;
  display: flex;
  align-items: center;
  background-color: var(--neutral-color-natural-10);
  justify-content: space-between;
  position: sticky;
  top: 0;
  z-index: 1;
`;

const TitleFont = styled.div`
  ${FontStyleMap["KR/medium/$KR.BtnL.MD.16"]}
  color: var(--neutral-color-natural-70, var(--Default-Gray-90, #262626));
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const BackIconContainer = styled.a`
  display: inline-flex;
  > svg {
    stroke: var(--neutral-color-natural-70, var(--Default-Gray-90, #262626));
  }
`;

export default StudyBookHeader;
