import { KeyboardArrowDown, KeyboardArrowUp } from "@material-ui/icons";
import { React, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import icon_setting_gray from "../../asset/Img/icon_setting_gray.png";
import apiFn from "../../asset/apiClass";
import constants from "../../asset/constants";
import store from "../../asset/store";
import UiNotice from "../../uiComponents/UiNotice/UiNotice";
import UiQuestion from "../../uiComponents/UiQuestion/UiQuestion";
import UiTextBtn from "../../uiComponents/UiTextBtn/UiTextBtn";
import Profile_class_Style from "./Profile_class_Style";

const Lecture_lecture = ({
  setIsModi,
  fn,
  lectureData,
  lectureData_ori,
  isTeacher,
  myInfo,
  apiPro_regi,
  apiPro_read_2,
  apiPro_read_myInfo,
  isJoin,
  userInfo,
  setFileObj,
  fileObj,
  qnaCount,
  setExplainData,
  explainPage,
  setExplainPage,
  isLoading,
}) => {
  let navigate = useNavigate();
  let params = useParams();

  var [noticeData, setNoticeData] = useState([]);
  var [videoNoticeData, setVideoNoticeData] = useState([]);
  var [isOpen, setIsOpen] = useState(false);

  // 공지사항
  const apiPro_read = async () => {
    try {
      const res = await fetch(`${constants.apiUrl}/class/${params.id}/board/?cat_id=66`);
      if (res.status !== 200) {
        alert("게시글을 가져올 수 없습니다");
      }
      const threadList = await res.json();

      // Adapter
      setNoticeData(threadList);
    } catch (e) {}
  };

  const getBoardArticle = async (category) => {
    const res = await fetch(`${constants.apiUrl}/class/${params.id}/board/?cat_id=${category}`);
    if (res.status !== 200) {
      alert("게시글을 가져올 수 없습니다");
    }
    const threadList = await res.json();
    setVideoNoticeData(threadList);
  };

  const apiPro_del = async (data) => {
    try {
      let isOk = await apiFn.exitClass({
        state: {
          orderMid: userInfo["id"],
          targetMid: userInfo["id"],
          cid: params["id"],
        },
      });
      apiPro_read_2();
    } catch (e) {
      console.error(e);
    }
  };

  const apiPro_img = async (e) => {
    try {
      // 0708 주석 이미지 s3 폴더 변경
      // board -> class
      const name = `/class/${params["id"]}.jpg`;
      setFileObj({
        name: name,
        file: e,
      });
      navigate("/crop/teacher/d");
    } catch (e) {
      console.error(e);
    }
  };

  const apiPro_approve = async () => {
    try {
      let isOk = await apiFn.comApi({
        state: {
          table: "member_class",
          sendMsg: {
            orderMid: userInfo["id"],
            targetMid: userInfo["id"],
            cid: params["id"],
            type: params["who"] == "teacher" ? "suup_okay" : "student_okay",
            channel: constants.channelId
          },
          set: [
            {
              key: `cid:${params["id"]}' and mid='${userInfo["id"]}`,
              value: {
                type: params["who"] == "teacher" ? 6 : 0,
                status: 1,
              },
            },
          ],
        },
      });

      await apiPro_read_2();
    } catch (e) {
      // console.log("error => ",e);
    }
  };

  const [isLectureOwner, setIsLectureOwner] = useState(false);
  const [isClassroomManager, setIsClassroomManager] = useState(false);

  const getCheckClassroomManager = async () => {
    try {
      const userClassroomInfo = await (
        await fetch(`${constants.apiUrl}/user/${userInfo.id}/classroom/${lectureData_ori.pid}`)
      ).json();
      if (Object.keys(userClassroomInfo).length >= 1)
        setIsClassroomManager(userClassroomInfo.type === 5);
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    if (
      lectureData_ori &&
      Object.keys(lectureData_ori).length >= 1 &&
      userInfo?.type === 2 &&
      lectureData?.status === 1
    ) {
      setIsLectureOwner(lectureData_ori?.reg_id === userInfo.id);
      getCheckClassroomManager();
    }
  }, [lectureData_ori, lectureData]);

  useEffect(() => {
    apiPro_read();
    getBoardArticle(7); // category id 7 === 영상 강의
    setExplainData([]);
    setExplainPage(1);
  }, []);

  let lectureStyle = {
    intro_wrapper: {
      display: "flex",
      justifyContent: "space-between",
    },
    intro_font: {
      display: "flex",
      fontWeight: 400,
      fontSize: "14px",
    },
    intro_font_700: {
      display: "flex",
      fontWeight: 700,
      fontSize: "14px",
    },
  };
  const ContextualTeacherText = () => {
    const { teacher_count, student_count, manager_name } = lectureData_ori;
    let innerText = ``;

    if (teacher_count === 1) innerText = `${manager_name ?? "이름 없는"} 선생님 `;
    else if (teacher_count >= 2)
      innerText = `${manager_name ?? "이름 없는"} 선생님 외 ${teacher_count - 1}명`;

    return <div> {innerText} </div>;
  };

  return (
    <>
      {!isLoading && (
        <div style={Profile_class_Style.c}>
          {isJoin ? (
            <div style={Profile_class_Style.gradeBox_c}>
              <div style={Profile_class_Style.gradeBox_w}>
                <div style={Profile_class_Style.gradebox_v}>
                  {isTeacher ? (
                    <div style={{ marginLeft: "5px" }}>
                      <div style={Profile_class_Style.gradebox_text_w}>
                        {`${myInfo["name"]} 선생님은`}
                        <div style={Profile_class_Style.gradebox_text}>
                          {lectureData["status"] == 0
                            ? "가입신청중"
                            : lectureData["type"] == "5"
                              ? "메인 강사"
                              : lectureData["type"] == "6"
                                ? "수업 참여자"
                                : lectureData["type"] == "9" && "초대 승낙 대기 상태 "}
                        </div>
                        입니다.
                      </div>
                      {lectureData["status"] == 0 && (
                        <UiTextBtn
                          text={"신청취소"}
                          btnStyle={Profile_class_Style.gradebox_text_btn}
                          fnClick={() => {
                            let isOk = window.confirm("강의 등록 신청을 취소하시겠어요?");
                            if (isOk) {
                              apiPro_del();
                            }
                          }}
                        />
                      )}
                      {lectureData["type"] == 9 && (
                        <div
                          style={{
                            display: "flex",
                          }}
                        >
                          <UiTextBtn
                            text={"초대 승낙"}
                            btnStyle={Profile_class_Style.gradebox_text_btn}
                            fnClick={() => {
                              let isOk = window.confirm("강의 등록 초대를 승낙하시겠어요?");
                              if (isOk) {
                                apiPro_approve();
                              }
                            }}
                          />
                          <UiTextBtn
                            text={"초대 거절"}
                            btnStyle={{
                              ...Profile_class_Style.gradebox_text_btn,
                              marginLeft: "12px",
                            }}
                            fnClick={() => {
                              let isOk = window.confirm("강의 등록 초대을 거절하시겠어요?");
                              if (isOk) {
                                apiPro_del();
                              }
                            }}
                          />
                        </div>
                      )}
                    </div>
                  ) : (
                    <div>
                      {lectureData["status"] == 0 ? (
                        <div
                          style={{
                            ...Profile_class_Style.gradebox_text_w,
                            // alignItems:"center"
                          }}
                        >
                          현재
                          <div
                            style={{
                              ...Profile_class_Style.gradebox_text,
                              // fontSize : "13px",
                              marginRight: "0px",
                            }}
                          >
                            가입 승인 대기중
                          </div>
                          인 수업입니다.
                        </div>
                      ) : lectureData["status"] == 5 ? (
                        lectureData["type"] == 9 && (
                          <div style={Profile_class_Style.gradebox_text_w}>
                            현재
                            <div
                              style={{
                                ...Profile_class_Style.gradebox_text,
                                // fontSize : "13px",
                                marginRight: "0px",
                              }}
                            >
                              초대 승낙 대기 상태
                            </div>
                            인 수업입니다.
                          </div>
                        )
                      ) : (
                        <div style={Profile_class_Style.gradebox_text_w}>
                          현재
                          <div
                            style={{
                              ...Profile_class_Style.gradebox_text,
                              // fontSize : "13px",
                              marginRight: "0px",
                            }}
                          >
                            수강중
                          </div>
                          인 수업입니다.
                        </div>
                      )}
                      {lectureData["status"] == 0 ? (
                        <UiTextBtn
                          text={"신청취소"}
                          btnStyle={Profile_class_Style.gradebox_text_btn}
                          fnClick={() => {
                            let isOk = window.confirm("강의 등록 신청을 취소하시겠어요?");
                            if (isOk) {
                              apiPro_del();
                            }
                          }}
                        />
                      ) : lectureData["type"] == 9 ? (
                        <div
                          style={{
                            display: "flex",
                          }}
                        >
                          <UiTextBtn
                            text={"초대 승낙"}
                            btnStyle={Profile_class_Style.gradebox_text_btn}
                            fnClick={() => {
                              let isOk = window.confirm("강의 등록 초대를 승낙하시겠어요?");
                              if (isOk) {
                                apiPro_approve();
                              }
                            }}
                          />
                          <UiTextBtn
                            text={"초대 거절"}
                            btnStyle={{
                              ...Profile_class_Style.gradebox_text_btn,
                              marginLeft: "12px",
                            }}
                            fnClick={() => {
                              let isOk = window.confirm("강의 등록 초대을 거절하시겠어요?");
                              if (isOk) {
                                apiPro_del();
                              }
                            }}
                          />
                        </div>
                      ) : (
                        <></>
                      )
                      // <UiTextBtn
                      //     text="수업 탈퇴"
                      //     btnStyle={{
                      //         ...Profile_class_Style.gradebox_text_btn,
                      //     }}
                      //     isUnderline={false}
                      //     fnClick={()=>{
                      //         let isOk = window.confirm("수업 탈퇴를 하시겠어요?")
                      //         if(isOk){
                      //             apiPro_del();
                      //             navigate(-1)
                      //         }
                      //     }}
                      // />
                      }
                    </div>
                  )}
                </div>
                {lectureData["status"] == 1 && (
                  <>
                    {(isClassroomManager || isLectureOwner) && (
                      <img
                        src={icon_setting_gray}
                        style={{
                          width: "18px",
                          height: "18px",
                          margin: "0px 5px",
                        }}
                        onClick={() => {
                          setIsModi(true);
                          // setIsPeople(true)
                        }}
                      />
                    )}
                    {!isLectureOwner && (
                      <UiTextBtn
                        text="수업 탈퇴"
                        btnStyle={{
                          ...Profile_class_Style.gradebox_text_btn,
                          marginTop: "0px",
                        }}
                        isUnderline={false}
                        fnClick={() => {
                          let isOk = window.confirm("수업 탈퇴를 하시겠어요?");
                          if (isOk) {
                            apiPro_del();

                            const stack = store.get("stack");
                            if (stack.length > 0) navigate(-1);
                          }
                        }}
                      />
                    )}

                    {(isClassroomManager || isLectureOwner) && (
                      <UiTextBtn
                        text="폐강"
                        btnStyle={{
                          ...Profile_class_Style.gradebox_text_btn,
                          marginTop: "0px",
                        }}
                        isUnderline={false}
                        fnClick={async () => {
                          let isOk = window.confirm(
                            "폐강 후 되돌이킬 수 없습니다\n폐강 하시겠어요?"
                          );
                          if (isOk) {
                            try {
                              // const result = await fetch(`${constants.apiUrl}/class/${lectureData_ori.cid}`,
                              //     {
                              //         method: 'DELETE',
                              //         headers: {'Content-Type': 'application/json'},
                              //         body: JSON.stringify({id: userInfo.id})
                              //     })

                              let isOk = await apiFn.deleteClass({
                                state: {
                                  cid: params["id"],
                                  orderMid: userInfo["id"],
                                },
                              });

                              alert("폐강 했습니다");
                              const stack = store.get("stack");
                              if (stack.length > 0) navigate(-1);
                            } catch (e) {
                              console.error(e);
                              alert("폐강에 실패했습니다");
                            }
                          }
                        }}
                      />
                    )}
                  </>
                )}
              </div>
            </div>
          ) : (
            <div style={Profile_class_Style.gradeBox_c}>
              <div style={Profile_class_Style.gradeBox_w}>
                <div
                  style={{
                    ...Profile_class_Style.gradebox_v,
                    width: "100%",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      width: "100%",
                      flex: 1,
                    }}
                  >
                    <div style={Profile_class_Style.gradebox_text_w}>
                      현재
                      <div
                        style={{
                          ...Profile_class_Style.gradebox_text,
                          // fontSize : "13px",
                          marginRight: "0px",
                        }}
                      >
                        미참여중
                      </div>
                      인 수업입니다.
                    </div>

                    <UiTextBtn
                      text="참여 신청"
                      btnStyle={{
                        fontSize: "14px",
                        ...Profile_class_Style.gradebox_text_btn,
                        marginTop: 0,
                      }}
                      isUnderline={false}
                      fnClick={() => {
                        let isOk = window.confirm(
                          `${lectureData_ori?.name ?? "(이름 없음)"} 수업에 등록을 신청하시겠어요?`
                        );
                        if (isOk) apiPro_regi();
                      }}
                    />
                  </div>
                </div>
                {lectureData["status"] == 1 && lectureData["type"] == 5 && (
                  <img
                    src={icon_setting_gray}
                    style={{
                      width: "18px",
                      height: "18px",
                      margin: "0px 5px",
                    }}
                    onClick={() => {
                      setIsModi(true);
                      // setIsPeople(true)
                    }}
                  />
                )}
              </div>
            </div>
          )}

          <div style={Profile_class_Style.gradeBox_c}>
            <div
              style={{
                ...Profile_class_Style.gradeBox_w,
                padding: "20px 10px",
                alignItems: "flex-start",
                justifyContent: "flex-start",
                flexDirection: "column",
                minHeight: "55px",
                height: "auto",
                backgroundColor: "#FFF7F5",
              }}
            >
              <div
                style={{
                  fontWeight: 700,
                  fontSize: "16px",
                }}
              >
                수업 소개
              </div>
              <div
                style={{
                  padding: "10px 0px",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "100%",
                  flex: 1,
                  borderBottom: "1px solid rgba(189, 189, 189, 0.1)",
                }}
              >
                <div
                  style={{
                    fontWeight: 400,
                    fontSize: "14px",
                  }}
                >
                  {/* 올인원 수강생으 ㄹ위한 온라인 질문실입니다. */}
                  {lectureData_ori?.intro ?? ""}
                </div>
                <div
                  style={{
                    width: isOpen ? "48px" : "55px",
                    fontSize: "12px",
                    fontWeight: 400,
                    color: "#B3B3B3",
                    display: "flex",
                    alignItems: "center",
                  }}
                  onClick={() => {
                    setIsOpen(!isOpen);
                  }}
                >
                  {isOpen ? (
                    <>
                      접기
                      <KeyboardArrowUp
                        style={{
                          fontSize: "16px",
                        }}
                      />
                    </>
                  ) : (
                    <>
                      펼치기
                      <KeyboardArrowDown
                        style={{
                          fontSize: "16px",
                        }}
                      />
                    </>
                  )}
                </div>
              </div>
              {isOpen ? (
                <div
                  style={{
                    width: "100%",
                  }}
                >
                  <div
                    style={{
                      ...lectureStyle.intro_wrapper,
                      padding: "10px 0px 5px 0px",
                    }}
                  >
                    <div style={lectureStyle.intro_font}>
                      강사
                      <div
                        style={{
                          ...lectureStyle.intro_font_700,
                          marginLeft: "5px",
                        }}
                      >
                        <ContextualTeacherText />
                      </div>
                    </div>
                    <div style={lectureStyle.intro_font}>
                      학원
                      <div
                        style={{
                          ...lectureStyle.intro_font_700,
                          marginLeft: "5px",
                        }}
                      >
                        {lectureData_ori?.classroom_name ?? "이름 없는 학원"}
                      </div>
                    </div>
                  </div>
                  <div style={lectureStyle.intro_wrapper}>
                    <div style={lectureStyle.intro_font}>
                      수강인원
                      <div
                        style={{
                          ...lectureStyle.intro_font_700,
                          marginLeft: "5px",
                        }}
                      >
                        {lectureData_ori?.student_count ?? 0}
                      </div>
                    </div>
                    <div style={lectureStyle.intro_font}>
                      장소
                      <div
                        style={{
                          ...lectureStyle.intro_font_700,
                          marginLeft: "5px",
                        }}
                      >
                        {lectureData_ori?.address ?? ""}
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>
          {lectureData["status"] === 1 && (
            <UiNotice isTeacher={isTeacher} data={noticeData} where={"lecture"} />
          )}

          {videoNoticeData && (
            <UiNotice
              title={"수업영상"}
              isTeacher={isTeacher}
              data={videoNoticeData}
              where={"lecture_video"}
            />
          )}
          {lectureData["status"] === 1 && (
            <UiQuestion
              userInfo={userInfo}
              isBook={false}
              isTeacher={isTeacher}
              qnaCount={qnaCount}
              isComplete={isTeacher ? true : false}
              data={[1, 2, 3, 4, 5]}
            />
          )}
        </div>
      )}
    </>
  );
};

export default Lecture_lecture;
