const Register_Four_style = {
    profile_c : {
        display:"flex",
        flexDirection:"column",
        fontSize : '14px',
        marginTop : "24px",
    },
    profile_w : {
        display:"flex",
        justifyContent:"center",
    },
    profile_b : {
        display : "flex",
        alignItems:"center",
        width : "110px",
        height : "160px",
        marginTop:"40px",
    },
    profile_b_i : {
        margin : "10px 0px",
        width : "100px",
        // height : "200px",
    },
    profile_b_circle : {
        display:"flex",
        justifyContent:"center",
        alignItems:"center",
        fontSize : "24px",
        position : "absolute",
        width : '24px',
        height : '24px',
        bottom : 0 ,
        right : 5,
    },
    desc_c : {
        display:"flex",
        flexDirection:"column",
        fontSize : '14px',
        // marginTop : "24px",
    },
}

export default Register_Four_style