const Register_Five_style = {
    s_c : {
        display:"flex",
        flexDirection:"column",
        fontSize : '14px',
        marginTop : "24px",
    },   
    s_t : {
        display:"flex",
        flexDirection:"column",
        fontSize : '14px',
        marginTop : "16px",
        alignItems:"center",
        textDecoration : 'underline'
    }
}

export default Register_Five_style