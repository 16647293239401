import { React , useState , useEffect, useRef } from 'react'
import { useNavigate, useParams } from 'react-router'
import style from '../../asset/style'
import { Frame } from '../page_index'
import Column_home from './Column_home'
import Message_Style from '../Message/Message_Style'
import Column_pick from './Column_pick'
import Column_my from './Column_my'
import { Create } from '@material-ui/icons'

const Column = ({
    userInfo,
    setFileObj,
    setBoardObj,
    createObj,
    setCreateObj,
    scrollKey,
    setScrolllKey,
    hiddenNav,
    setHiddenNav,
}) => {
    
    let params = useParams()
    let navigate = useNavigate();

    const [isTeacher ,setIsTeacher] = useState(true);
    const [isSearch,setIsSearch] = useState(true)
    const [tap,setTap] = useState("home")

    useEffect(()=>{
        // // console.log("parmas => ",params)
        if(params["who"] == "teacher"){
            setIsTeacher(true)
        }
        else {
            setIsTeacher(false)
        }
        setTap(params["where"])
    },[params])

    useEffect(()=>{
        setIsSearch(true)
    },[tap])

    useEffect(()=>{
        setFileObj({})
        setBoardObj({})
    },[])

    return (
        <Frame
            userInfo={userInfo}
            t_visible={false}
            isTap={true}
            // isSearch={isSearch}
            isSearch={false}
            tapCompo={
                <>
                <div style={{
                    ...Message_Style.tap_text,
                    fontSize : "20px",
                    // borderBottom : tap == "home" ? "2px solid" : "none",
                    // borderBottomColor : tap == "home" ?
                    // (isTeacher ? style.common.t_color : style.common.s_color)
                    // :
                    // "none"
                    // ,
                    color : tap == "home" ?
                    (isTeacher ? style.common.t_color : style.common.s_color)
                    :
                    style.common.fontColor_2
                    
                }}
                    onClick={()=>{
                        navigate("/column"+"/"+params["who"]+"/home")
                    }}
                >
                    컬럼
                </div>
                <div style={{
                    ...Message_Style.tap_text,
                    fontSize : "20px",
                    borderBottom : tap == "pick" ? "2px solid" : "none",
                    borderBottomColor : tap == "pick" ?
                    (isTeacher ? style.common.t_color : style.common.s_color)
                    :
                    "none"
                    ,
                    color : 
                    tap == "pick" ?
                    (isTeacher ? style.common.t_color : style.common.s_color)
                    :
                    style.common.fontColor_2

                }}
                    onClick={()=>{
                        navigate("/column"+"/"+params["who"]+"/pick")
                    }}
                >
                    찜한 컬럼
                </div>
                {isTeacher &&
                <div style={{
                    ...Message_Style.tap_text,
                    fontSize : "20px",
                    borderBottom : tap == "my" ? "2px solid" : "none",
                    borderBottomColor : tap == "my" ?
                    (isTeacher ? style.common.t_color : style.common.s_color)
                    :
                    "none"
                    ,
                    color : 
                    tap == "my" ?
                    (isTeacher ? style.common.t_color : style.common.s_color)
                    :
                    style.common.fontColor_2

                }}  
                    onClick={()=>{
                        navigate("/column"+"/"+params["who"]+"/my")
                    }}
                >
                    내가 쓴 컬럼
                </div>
                }
                </>
            }
        >
            <div style={{
                display:"flex",
                flexDirection:"column",
                width:"100%",
                height:"100%",
            }}>
                <div style={{
                    // width:"100%",
                    // padding : "10px 5px"
                    padding : 
                    // tap == "home" ? "5px 15px 0px 15px"
                    // :
                    "0px 15px 0px 15px"
                    ,
                }}>
                    {
                    tap == "home" ?
                    <Column_home 
                        scrollKey={scrollKey}
                        setScrolllKey={setScrolllKey}
                        isTeacher={isTeacher}
                        userInfo={userInfo}
                        hiddenNav={hiddenNav}
                        setHiddenNav={setHiddenNav}
                    />
                    :
                    tap == "pick" ?
                    <Column_pick 
                        scrollKey={scrollKey}
                        setScrolllKey={setScrolllKey}
                        userInfo={userInfo}
                        isTeacher={isTeacher}
                        createObj={createObj}
                        setCreateObj={setCreateObj}
                        hiddenNav={hiddenNav}
                        setHiddenNav={setHiddenNav}
                    />
                    :
                    tap == "my" &&
                    <Column_my 
                        scrollKey={scrollKey}
                        setScrolllKey={setScrolllKey}
                        isTeacher={isTeacher}
                        setIsSearch={setIsSearch}
                        userInfo={userInfo}
                        hiddenNav={hiddenNav}
                        setHiddenNav={setHiddenNav}
                    />
                    }
                </div>
            </div>
        </Frame>
    )
}

export default Column