import { useMutation, useQuery } from "@apollo/client";
import constants from "asset/constants";
import { PaymentContext } from "asset/context";
import { getUser } from "asset/storage";
import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import {
  GetOnPaymentAcademyDocument,
  SetTeacherTicketQuotaDocument,
  TeacherTicketQuotaDocument,
  UserTicketInventoriesDocument,
} from "types/graphql/graphql";
import UiBasicSelect from "uiComponents/UiSelect/UiBasicSelect";

const TICKET_TYPES = [
  {
    type: "BASIC",
    title: "일일 질문권",
  },
  {
    type: "MONTH",
    title: "월간 질문권",
  },
  {
    type: "PREMIUM",
    title: "프리미엄 질문권",
  },
  {
    type: "ADDITIONAL",
    title: "추가 질문권",
  },
];

const QuotaContainer = styled.div`
  position: relative;
  width: 320px;
  margin: auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 12px;
`;
const QuotaBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  border: 1px solid black;
  width: 100px;
  height: 82px;
`;
const Text = styled.span`
  font-size: 14px;
  font-weight: 500;
  align-self: center;
`;
const Space = styled.div`
  height: 12px;
`;
const Row = styled.div`
  display: flex;
  flex-direction: row;
  gap: 4px;
`;
const SubmitButton = styled.button`
  position: absolute;
  bottom: 0;
  right: 30px;
  align-items: flex-end;
`;
const ChangeButton = styled.button`
  font-size: 18px;
  font-weight: 800;
  align-self: center;
`;

const MyInfoTicket = () => {
  const userInfo = getUser();
  const isTeacher = useMemo(() => userInfo.type == 2, [userInfo]);

  const { academyId, setAcademyId } = useContext(PaymentContext);
  console.log(academyId);
  const [editMode, setEditMode] = useState(false);
  const [maxVals, setMaxVals] = useState([]);

  const { data: academies } = useQuery(GetOnPaymentAcademyDocument, {
    fetchPolicy: "network-only",
    variables: { memberId: userInfo.id },
  });

  const academyOptions = useMemo(
    () =>
      academies?.getOnPaymentAcademy?.academies.map((item) => ({
        value: item.id,
        name: item.name,
      })),
    [academies]
  );

  // 학생일 때
  const { data: inventoryData } = useQuery(UserTicketInventoriesDocument, {
    fetchPolicy: "network-only",
    variables: { studentId: userInfo.id, academyId },
    skip: isTeacher,
  });
  const studentTicket = useMemo(() => {
    const inventories = inventoryData?.userTicketInventories.inventories || [];
    const ticketInventories = inventories.map((item) => ({
      monthQuantity: item.monthQuantity,
      quantity: item.quantity,
      ticketId: item.ticket.id,
      ticketType: item.ticket.saleType,
      name: item.ticket.name,
    }));
    return ticketInventories;
  }, [inventoryData]);

  const inventoryStates = useMemo(() => {
    return TICKET_TYPES.map((item) => ({
      ...item,
      quantity: studentTicket.find((ticket) => ticket.ticketType == item.type)?.quantity || 0,
      monthQuantity: studentTicket.find((ticket) => item.type == "MONTH")?.monthQuantity,
    }));
  }, [studentTicket]);
  // 선생님일 때
  const { data: quotaData, refetch } = useQuery(TeacherTicketQuotaDocument, {
    fetchPolicy: "network-only",
    variables: { teacherId: userInfo.id, academyId },
    skip: !isTeacher,
  });
  const [updateTicketQuota] = useMutation(SetTeacherTicketQuotaDocument);

  const teacherTicket = useMemo(() => quotaData?.teacherTicketQuota, [quotaData]);

  const classroomId = constants.classroomId;

  useEffect(() => {
    if (!isTeacher) {
      return;
    }
    const targetData = teacherTicket;
    if (targetData) {
      const { policy } = targetData;
      const maxVals = TICKET_TYPES.map((item) => ({
        type: item.type,
        title: item.title,
        max: policy?.[item.type] || 10,
      }));
      setMaxVals(maxVals);
    }
  }, [teacherTicket]);

  const quotaStates = useMemo(() => {
    const { policy, dashboard } = teacherTicket || {};

    return TICKET_TYPES.map((item) => ({
      ...item,
      max: policy?.[item.type] || 10,
      now: dashboard?.[item.type] || 0,
    })).map((item) => ({
      ...item,
      state: item.now == item.max ? "(마감)" : item.now / item.max > 0.6 ? "(마감임박)" : "(여유)",
    }));
  }, [teacherTicket]);

  const changeMaxVal = useCallback(
    (type, plus) => () => {
      const updatedMaxVal = maxVals.map((item) => {
        if (item.type == type) {
          return {
            ...item,
            max: plus ? item.max + 1 : Math.max(item.max - 1, 0),
          };
        } else {
          return item;
        }
      });
      setMaxVals(updatedMaxVal);
    },
    [maxVals]
  );

  const saveMaxVal = useCallback(async () => {
    const policy = maxVals.reduce((acc, cur) => {
      return {
        ...acc,
        [cur.type]: cur.max,
      };
    }, {});
    const variables = {
      id: teacherTicket.id,
      teacherId: userInfo.id,
      policy,
    };
    await updateTicketQuota({ variables });
    setEditMode(false);
    refetch();
  }, [maxVals, userInfo.id, refetch]);

  const TicketInventory = () => {
    return isTeacher ? (
      <>
        {quotaStates.map(
          (quota) =>
            quota.type === "BASIC" && (
              <QuotaBox key={quota.type}>
                <Text>{quota.title}</Text>
                <Text>{quota.state}</Text>
                <Space />
                <Text>
                  {quota.now}/{quota.max}
                </Text>
              </QuotaBox>
            )
        )}
        <SubmitButton onClick={() => setEditMode(true)}>수정</SubmitButton>
      </>
    ) : (
      <>
        {/* FIXME: 결제 기능 도입 시 다른 질문권도 표시 */}
        {inventoryStates.map((inventory) =>
          inventory.type === "BASIC" || inventory.type === "MONTH" ? (
            <QuotaBox key={inventory.type}>
              <Text>{inventory.title}</Text>
              <Space />
              <Text>
                {inventory.type === "MONTH" ? inventory.monthQuantity : inventory.quantity}
              </Text>
            </QuotaBox>
          ) : null
        )}
      </>
    );
  };

  return (
    <QuotaContainer>
      <div style={{ display: "flex", flexDirection: "column" }}>
        {/* 하이큐썸앱일 경우에만 학원 선택 드롭다운 보이기 */}
        {classroomId || (
          <UiBasicSelect
            data={academyOptions}
            title="학원"
            fnChange={(value) => {
              setAcademyId(value);
            }}
            c_style={{
              minWidth: "100px",
              fontSize: "10px",
            }}
            defaultValue={academyId}
          />
        )}
        <div style={{ display: "flex", justifyContent: "center" }}>
          {editMode ? (
            <>
              {maxVals.map(
                (val) =>
                  val.type === "BASIC" && (
                    <QuotaBox key={val.type}>
                      <Text>{val.title}</Text>
                      <Row>
                        <ChangeButton onClick={changeMaxVal(val.type, false)}>-</ChangeButton>
                        <Text>{val.max}</Text>
                        <ChangeButton onClick={changeMaxVal(val.type, true)}>+</ChangeButton>
                      </Row>
                    </QuotaBox>
                  )
              )}
              <SubmitButton onClick={saveMaxVal}>저장</SubmitButton>
            </>
          ) : (
            TicketInventory()
          )}
        </div>
      </div>
    </QuotaContainer>
  );
};

export default MyInfoTicket;
