import constants from "../../../asset/constants";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";

const VideoQuestionThumbnail = ({ videoQuestion, onClick }) => {
  const navigate = useNavigate();

  const [thumbnail, setThumbnail] = useState("");

  const youtubeId = (content) => {
    // console.log("content : ",content);
    if (!content) return;
    var tag = "";
    if (content) {
      var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#\&\?]*).*/;
      var matchs = content.match(regExp);
      if (matchs) {
        tag = matchs[7];
      }
      return tag;
    }
  };

  useEffect(() => {
    const getThumbnail = async () => {
      if (videoQuestion?.content?.includes("youtu")) {
        setThumbnail(`https://img.youtube.com/vi/${youtubeId(videoQuestion?.content)}/0.jpg`);
      } else if (videoQuestion?.content?.startsWith("https://vimeo")) {
        setThumbnail(
          (
            await (
              await fetch(`https://vimeo.com/api/oembed.json?url=` + videoQuestion?.content)
            ).json()
          ).thumbnail_url
        );
      } else {
        setThumbnail(`${constants.s3_url}/board/${videoQuestion?.content?.replace("mp4", "jpg")}`);
      }
    };
    getThumbnail();
  }, [videoQuestion]);

  return (
    <button
      style={{
        border: "none",
        backgroundColor: "#4a4a4a",
        width: "100%",
      }}
      onClick={() => onClick(videoQuestion)}
    >
      <img style={{ width: "100%" }} src={thumbnail} />
    </button>
  );
};

export default VideoQuestionThumbnail;
