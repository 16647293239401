import { ReactComponent as SearchIcon } from "asset/images/icon/search-normal.svg";
import { FontStyleMap } from "components/atoms/Font";
import React from "react";
import styled from "styled-components";

const StudyBookHeader = () => {
  return (
    <Container>
      교재
      <SearchIcon />
    </Container>
  );
};

const Container = styled.header`
  color: var(--neutral-color-natural-80, #141414);
  ${FontStyleMap["KR/bold/$KR.TM.BOL.24"]}
  padding: 8.5px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export default StudyBookHeader;
