import { fontWeight } from '@mui/system'
import { React , useState , useEffect, useRef } from 'react'
import { useNavigate, useParams } from 'react-router'
import { UiInput } from '../../uiComponents/UiInput/UiInput'
import aquery from '../../asset/Img/aquery.png'
import photopic_book from '../../asset/Img/photopic_book.png'
import { Switch } from '@mui/material'
import util from '../../asset/util'
import constants from '../../asset/constants'
import apiFn from '../../asset/apiClass'
import store from '../../asset/store'
import style from '../../asset/style'

var fontStyle = {
    fontFamily: 'Noto Sans',
    fontStyle: "normal",
}

const UiBook_sub_modal = ({
    fileObj,
    setFileObj,
    setIsModal,
    isModal,
    userInfo,
    title,
    placeholder,
    data,
    setIsModal_sub
}) => {
    let params = useParams();
    let navigate = useNavigate();

    var [qnaCount, setQnaCount] = useState({})
    var [bookData, setBookData] = useState({})

    const apiPro_read = async () => {
        try {
            let b_data = await  apiFn.comApi({
                state : {
                    table : "book",
                    page : 1,
                    amount : 9999,
                    sort : "reg_date desc",
                    equal:{
                        id : data["id"]
                    },
                }
            })


            setBookData({...b_data["list"][0]})

        }
        catch(e){
            console.log('************')
            console.log(e)
            console.log('************')
        }
    }

    const apiPro_count = async () => {
        try {
            let q_data = await apiFn.qnaCount({
                state : {
                    id : userInfo["id"],
                    page : 1,
                    amount : 9999,
                    sort : "reg_date desc",
                    bid : data["id"],
                    count : "Y",
                    // status : status
                }
            })


            setQnaCount({...q_data["count"]})

            console.log("q_data : ", q_data);

        }
        catch(e){
            console.log('************')
            console.log(e)
            console.log('************')
        }
    }


    const apiPro_regi = async () => {
        try {
            // let isOk = await apiFn.comApi({
            //     state : {
            //         table : "book_link",
            //         page : 1,
            //         amount : 9999,
            //         sort : "reg_date desc",
            //         where : {
            //             "HIQDB_book_link.mid" : `= '${userInfo["id"]}'`,
            //             "HIQDB_book_link.bid" : `= '${data["id"]}}'`,

            //         }
            //     }
            // })
            // if(isOk["list"].length > 0){

            // }
            // else {
            // }
            await apiFn.comApi({
                state : {
                    table : "book_link",
                    ins: [
                        {
                            mid : data["id"],
                            bid : bid,
                            type :  6 ,
                            status : 0,
                        }
                    ],
                }
            })
            
            window.alert("신청 되었습니다.")
            setIsModal_sub({})
        }
        catch(e){
            // console.log("@@ ",e)
        }
    }

    useEffect(()=>{
        console.log("SUB MODAL DATA : ", data);
    },[data])

    useEffect(()=>{
        apiPro_count();
        apiPro_read();
    },[])



    return (
        <div style={{
            position:"fixed",
            top:0,
            zIndex:130002,
            backgroundColor:"rgba(150, 150, 150,0.5)",
            // backgroundColor:"red",
            width:"100%",
            height:"100vh",
            display:"flex",
            justifyContent:"center",
            alignItems:"center"
        }}
            onClick={()=>{
                // setIsModal(false)
                setIsModal_sub({})
            }}
        >
            <div style={{
                // width : "75%",
                width:"300px",
                // minHeight:"55%",
                // maxHeight:"60%",
                backgroundColor:"white",
                padding : "35px 20px 40px 20px",
                borderRadius : 10,
                border : "1px solid rgb(200, 200, 200)",
                display:"flex",
                flexDirection:"column",
                justifyContent:"space-between",
                filter: "drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.37))",
            }}
                onClick={(e)=>{
                    // e.stopPropagation();
                    // e.preventDefault();
                }}
            >
                <div style={{
                    fontWeight:500,
                    display:"flex",
                    justifyContent:"center",
                    fontSize:"18px",
                    fontWeight:700
                }}>
                    {data["name"] ? data["name"] : ""}
                </div>
                <div style={{
                    display:"flex",
                    fontSize:"14px",
                    fontWeight:700,
                    justifyContent:"center",
                    padding:"10px 0px 25px 0px"
                }}>
                    <div style={{
                        display:"flex",
                        flexDirection:"column",
                        alignItems:"center",
                        width : "78px",
                        height:"96px",
                        marginRight:"10px",
                        borderRadius:"10px"
                    }}>
                        <img
                            src={
                                bookData["img"] ?
                                (
                                constants.s3_url
                                +
                                // '/public'
                                // +
                                `/bookCover/${bookData["img"]}`
                                +
                                '.jpg'
                                )
                                :
                                (
                                    params["id"] ?
                                    (
                                    constants.apiUrl
                                    +
                                    // ( constants.apiUrl.includes('dev.com') ? '/test' : '/public' )
                                    // '/public'
                                    // +
                                    `/bookCover/${data["id"]}`
                                    // '/teacher/'+userInfo["id"].normalize('NFD')
                                    +
                                    '.jpg?'
                                    +
                                    util.getRandomInt(1000,9999)
                                    )
                                    :
                                    ""
                                )
                            }
                            style={{
                                width:"100%",
                                height:"100%",
                                borderRadius:"10px",
                            }}
                        />

                    </div>
                    <div style={{
                        display:"flex",
                        flexDirection:"column",
                        fontSize:"14px",
                        fontWeight:400,
                    }}>
                        <div style={{
                            display:"flex",
                        }}>
                            전체 질문 미답변
                            <div style={{
                                margin:"0px 3px",
                                color : style.common.fontColor_3,
                            }}>
                                {qnaCount["live"]}
                            </div>
                            / 전체
                            <div style={{
                                marginLeft :"3px",
                            }}>
                                {qnaCount["live"] + qnaCount["qna"] + qnaCount["mobum"]}
                            </div>
                        </div>
                        <div style={{
                            display:"flex"
                        }}>
                            모범 풀이수 {qnaCount["mobum"] ? qnaCount["mobum"] : ""}
                        </div>

                        <div style={{
                            display:"flex"
                        }}>
                            {/* 공유 중 수업 {bookData["cntClass"] ? bookData["cntClass"] : ""} */}
                        </div>
                    </div>
                </div>
                <div style={{
                    display:"flex",
                    width:"100%",
                    justifyContent:"center",
                    marginTop:"10px",
                }}>
                    <div style={{
                        display:"flex",
                        justifyContent:"center",
                        alignItems:"center",
                        width:"87px",
                        height:"40px",
                        borderRadius:"15px",
                        backgroundColor:"#E7AAAA",
                        fontSize:"14px",
                        color : "#FFFFFF",
                        fontWeight:700
                    }}
                        onClick={()=>{
                            apiPro_regi();
                        }}
                    >
                        참여 신청
                    </div>
                </div>
            </div>
        </div>
    )
}

export default UiBook_sub_modal
