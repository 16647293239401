import { PlayArrow, ShareRounded, ThumbUp, Visibility } from "@material-ui/icons";
import allinone_logo from "asset/Img/allinone.svg";
import costudy_logo from "asset/Img/costudy.svg";
import dawon_logo from "asset/Img/dawon-intro.png";
import face_teacher from "asset/Img/defaultpic_teacher.png";
import face_student from "asset/Img/face_student.png";
import hansuwi_logo from "asset/Img/hansuwi-intro.png";
import intro_logo from "asset/Img/intro_logo.png";
import jongro_logo from "asset/Img/jongro-intro.jpg";
import main_logo from "asset/Img/main_logo.png";
import math_in_everyday_logo from "asset/Img/math-in-everyday.jpg";
import myeongseong_logo from "asset/Img/myeongseong.png";
import seoro_logo from "asset/Img/seoro.png";
import sne_logo from "asset/Img/sne_logo.png";
import wmm_logo from "asset/Img/wmm-logo.png";
import apiFn from "asset/apiClass";
import constants from "asset/constants";
import style from "asset/style";
import util from "asset/util";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { useLongPress } from "use-long-press";
import { UiCheckBox } from "../../uiComponents/UiCheckBox/UiCheckBox";
import UiExplain from "../../uiComponents/UiExplain/UiExplain";
import { LatexView } from "../../components/atoms/latexView";

const Talk = ({
  index,
  data,
  senderInfo,
  setSenderInfo,
  userInfo,
  selectData,
  fnCheck,
  isEdit,
  fnClick,
  fnLongClick,
  isRef = false,
  youtube_rul,
  setYoutube_url,
  videoInfo,
  setVideoInfo,
  setHiddenUrl,
}) => {
  let params = useParams();
  let navigate = useNavigate();

  const scrollRef = useRef(null);
  const thumbnailRef = useRef(null);

  const [isOpen, setIsOpen] = useState(false);
  const [isErr, setIsErr] = useState(false);

  const [qnaData, setQnaData] = useState({});

  const [youtubeLikes, setYoutubeLikes] = useState(null);
  const [vimeoInfo, setVimeoInfo] = useState(null);

  useEffect(() => {
    const getLikes = async () => {
      const {
        items: [
          {
            statistics: { likeCount, viewCount },
          },
        ],
      } = await (
        await fetch(
          `https://www.googleapis.com/youtube/v3/videos?id=${youtubeId(data["content"])}&key=AIzaSyBO9sA0xZipWyMBHDDcAARgnIvSpYPqymk&part=statistics`
        )
      ).json();

      const commaLikeCount = parseInt(likeCount).toLocaleString();
      const commaViewCount = parseInt(viewCount).toLocaleString();
      setYoutubeLikes({ likeCount: commaLikeCount, viewCount: commaViewCount });
    };
    if (youtubeId(data["content"])?.length > 0) {
      getLikes();
    }

    if (data.ctype === 2 && data.content.startsWith("https://vimeo.com/")) {
      getVimeoThumbnail(data.content);
    }
  }, [data["content"]]);

  const bind = useLongPress(
    () => {
      try {
        // console.log(data);
        // if(isTeacher){
        // if(params["qid"] == 0 || data["ctype"] == 0){
        if (params["who"] == "student" && data["ctype"] == 1) return;
        if (params["who"] == "student" && data["ctype"] == 2) return;
        if (params["who"] == "student" && data["ctype"] == 3) return;
        fnLongClick(data);
        // }
        // }
      } catch (e) {
        // console.log("Long Press Error => ",e)
      }
    },
    {
      threshold: 2000,
    }
  );

  const youtubeId = (content) => {
    // console.log("content : ",content);
    if (!content) return;
    var tag = "";
    if (content) {
      var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#\&\?]*).*/;
      var matchs = content.match(regExp);
      if (matchs) {
        tag = matchs[7];
      }
      return tag;
    }
  };

  const getVimeoThumbnail = async (url) => {
    setVimeoInfo(await (await fetch(`https://vimeo.com/api/oembed.json?url=` + url)).json());
  };

  const apiPro_qna = async (qid) => {
    try {
      let qnaData = await apiFn.qnaList({
        state: {
          id: data["wid"],
          qid: qid,
          page: 1,
          amount: 9999,
          sort: "reg_date desc",
          onChat: "Y",
        },
      });

      console.log("qnaData :", qnaData);
      if (qnaData["list"].length > 0) {
        setQnaData(qnaData["list"][0]);
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    if (isRef) {
      setTimeout(() => {
        scrollRef?.current?.scrollIntoView({
          block: "end",
          inline: "end",
        });
      }, 300);
    }
    if (data["ori_id"]) {
    }
    if (data["ctype"] == 3) {
      // console.log("Type 3 Talk Data => ",data)
    }
    setIsErr(false);
    if (data["ctype"] == 8) {
      apiPro_qna(data["content"]);
    } else if (data["ctype"] == 4) {
      let link = data["content"];
      link = link
        .replace(`${constants.redirect_url}talk`, "")
        .replace("/teacher", "")
        .replace("/student", "");
      console.log("link", link);
      apiPro_qna(link.split("/")[2]);
    }
    // console.log("Talk Data => ",data)
    // // console.log("Talk t_img  , s_img => ",data["t_img"],data["s_img"])
    // // console.log("Talk img => ",data["img"])
  }, [isRef, data]);

  const logo = useMemo(() => {
    switch (constants.channelId) {
      case "jym":
        return jongro_logo;
      case "msm":
        return myeongseong_logo;
      case "aio":
        return allinone_logo;
      case "csd":
        return costudy_logo;
      case "wmm":
        return wmm_logo;
      case "srk":
        return seoro_logo;
      case "sne":
        return sne_logo;
      case "mie":
        return math_in_everyday_logo;
      case "mds":
        return dawon_logo;
      case "hsw":
        return hansuwi_logo;
      default:
        return intro_logo;
    }
  }, [constants.channelId]);
  return (
    <>
      <div
        key={"talk_" + data["id"]}
        // key={"talk_"+index}
        ref={isRef ? scrollRef : null}
        style={{
          display: "flex",
          // flex:1,
          width: "100%",
          justifyContent:
            params["qid"] == 0
              ? params["who"] == "teacher"
                ? userInfo["id"] == data["tid"] && data["wtype"] == "2"
                  ? "flex-end"
                  : "flex-start"
                : userInfo["id"] == data["sid"] && data["wtype"] == "1"
                  ? "flex-end"
                  : "flex-start"
              : params["who"] == "teacher"
                ? userInfo["id"] == data["wid"] && data["wtype"] == "2"
                  ? "flex-end"
                  : "flex-start"
                : userInfo["id"] == data["wid"] && data["wtype"] == "1"
                  ? "flex-end"
                  : "flex-start",
        }}
      >
        {isEdit && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              // marginRight : "10px",
              margin: "0px 5px",
            }}
          >
            <UiCheckBox
              className={params["who"] == "teacher" ? "t" : "s"}
              // value={"?"}
              // className={"message_?"}
              checked={selectData && (selectData[id] == "Y" ? true : false)}
              value={data["id"]}
              fnClick={fnCheck}
              containerStyle={{
                padding: "0px",
              }}
            />
          </div>
        )}
        <div
          style={{
            display: "flex",

            // width:"100px",
            padding: "10px 16px",
            // minHeight : "30px",
          }}
        >
          <div
            onClick={() => {
              if (
                data["tid"] == "hiqsum_admin" ||
                data["wid"] == "hiqsum_admin" ||
                data["sid"] == "hiqsum_admin"
              ) {
              } else {
                if (params["qid"] == 0) {
                  if (data["wtype"] == 1) {
                    navigate(`/myinfo/${params["who"]}/${data["sid"]}`);
                  } else {
                    navigate(`/myinfo/${params["who"]}/${data["tid"]}`);
                  }
                } else {
                  navigate(`/myinfo/${data["wtype"] === 1 ? "student" : "teacher"}/${data["wid"]}`);
                }
              }
            }}
          >
            {data && (
              <img
                onError={(e) => {
                  // setIsLoad(false)
                  if (data["wtype"] == "2") {
                    e.currentTarget.src = face_teacher;
                  } else {
                    e.currentTarget.src = face_student;
                  }
                }}
                src={
                  params["qid"] == 0
                    ? data["tid"] == "hiqsum_admin" ||
                      data["wid"] == "hiqsum_admin" ||
                      data["sid"] == "hiqsum_admin"
                      ? logo
                      : // data["wid"] &&
                        params["who"] == "teacher"
                        ? userInfo["id"] == data["tid"] && data["wtype"] == "2"
                          ? userInfo["img"]
                            ? `${constants.s3_url}/teacher/${userInfo["img"]}.jpg`
                            : `${constants.s3_url}/teacher/${util.normalize(userInfo["id"])}.jpg`
                          : data["s_img"]
                            ? `${constants.s3_url}/student/${data["s_img"]}.jpg`
                            : `${constants.s3_url}/student/${util.normalize(data["sid"])}.jpg`
                        : userInfo["id"] == data["sid"] && data["wtype"] == "1"
                          ? userInfo["img"]
                            ? `${constants.s3_url}/student/${userInfo["img"]}.jpg`
                            : `${constants.s3_url}/student/${util.normalize(userInfo["id"])}.jpg`
                          : data["t_img"]
                            ? `${constants.s3_url}/teacher/${data["t_img"]}.jpg`
                            : `${constants.s3_url}/teacher/${util.normalize(data["tid"])}.jpg`
                    : params["who"] == "teacher"
                      ? userInfo["id"] == data["wid"] && data["wtype"] == "2"
                        ? userInfo["img"]
                          ? `${constants.s3_url}/teacher/${userInfo["img"]}.jpg`
                          : `${constants.s3_url}/teacher/${util.normalize(userInfo["id"])}.jpg`
                        : data["t_img"]
                          ? `${constants.s3_url}/teacher/${data["t_img"]}.jpg`
                          : data["s_img"]
                            ? `${constants.s3_url}/student/${data["s_img"]}.jpg`
                            : `${constants.s3_url}/student/${util.normalize(data["wid"])}.jpg`
                      : userInfo["id"] == data["wid"] && data["wtype"] == "1"
                        ? userInfo["img"]
                          ? `${constants.s3_url}/student/${userInfo["img"]}.jpg`
                          : `${constants.s3_url}/student/${util.normalize(userInfo["id"])}.jpg`
                        : data["t_img"]
                          ? `${constants.s3_url}/teacher/${data["t_img"]}.jpg`
                          : data["s_img"]
                            ? `${constants.s3_url}/student/${data["s_img"]}.jpg`
                            : `${constants.s3_url}/teacher/${util.normalize(data["wid"])}.jpg`
                  // face_student
                }
                style={{
                  // backgroundColor:"white",
                  width: "40px",
                  height: "40px",
                  objectFit: "cover",
                  borderRadius: 10,
                  objectPosition: "center",
                  display:
                    params["qid"] == 0
                      ? params["who"] == "teacher"
                        ? userInfo["id"] == data["tid"] && data["wtype"] == "2"
                          ? "none"
                          : "block"
                        : userInfo["id"] == data["sid"] && data["wtype"] == "1"
                          ? "none"
                          : "block"
                      : params["who"] == "teacher"
                        ? userInfo["id"] == data["wid"] && data["wtype"] == "2"
                          ? "none"
                          : "block"
                        : userInfo["id"] == data["wid"] && data["wtype"] == "1"
                          ? "none"
                          : "block",
                  border: "none",
                }}
              />
            )}
          </div>
          <div
            style={{
              margin: "0px 5px 0px 10px",
              // minHeight : "30px",
              // maxWidth : "10%",
              // maxWidth : "calc(window.screen.width * 0.5)",
              maxWidth: data["ctype"] != 8 && "60vw",

              wordBreak: "break-all",
            }}
            className={
              data.ctype === 71 &&
              (userInfo["id"] == data["wid"] ? "semohaeSendTalk" : "semohaeReceiveTalk")
            }
          >
            <div
              style={{
                paddingBottom: "4px",
                marginLeft: "2px",
                fontSize: "12px",
                color: style.common.fontColor_6,
                display: userInfo["id"] == data["wid"] ? "none" : "block",
              }}
            >
              {params["tid"] == "hiqsum_admin" || params["sid"] == "hiqsum_admin"
                ? "[HiQSUM] 알림"
                : params["qid"] == 0
                  ? //         params["who"] == "teacher" ?
                    // ( (userInfo["id"] == data["tid"] && data["wtype"] == '2' ) ? "flex-end" : "flex-start")
                    // :
                    // ( (userInfo["id"] == data["sid"] && data["wtype"] == '1' ) ? "flex-end" : "flex-start")
                    params["who"] == "teacher"
                    ? userInfo["id"] == data["tid"] && data["wtype"] == "2"
                      ? data["name"]
                        ? ""
                        : "(탈퇴한 회원)"
                      : // (data["name"]  ? data["name"]  : '(탈퇴한 회원)')
                        data["wtype"] == "2"
                        ? data["name"]
                          ? data["name"]
                          : "(탈퇴한 회원)"
                        : data["nick"]
                          ? data["nick"]
                          : "(탈퇴한 회원)"
                    : userInfo["id"] == data["sid"] && data["wtype"] == "1"
                      ? // (data["nick"] ? data["nick"] : '(탈퇴한 회원)')
                        data["nick"]
                        ? ""
                        : "(탈퇴한 회원)"
                      : data["name"]
                        ? data["name"]
                        : "(탈퇴한 회원)"
                  : params["who"] == "teacher"
                    ? userInfo["id"] == data["wid"] && data["wtype"] == "2"
                      ? data["name"]
                        ? data["name"]
                        : "(탈퇴한 회원)"
                      : data["wtype"] == "2"
                        ? data["name"]
                          ? data["name"]
                          : "(탈퇴한 회원)"
                        : data["nick"]
                          ? data["nick"]
                          : "(탈퇴한 회원)"
                    : userInfo["id"] == data["wid"] && data["wtype"] == "1"
                      ? data["nick"]
                      : data["name"]
                        ? data["name"]
                        : data["nick"]}
            </div>
            <div
              style={{
                backgroundColor:
                  params["qid"] == 0
                    ? data["ctype"] == 1
                      ? "transparent"
                      : params["who"] == "teacher"
                        ? userInfo["id"] == data["tid"] && data["wtype"] == "2"
                          ? "#dddddd"
                          : style.common.t_color_msg
                        : userInfo["id"] == data["sid"] && data["wtype"] == "1"
                          ? "#dddddd"
                          : style.common.s_color_msg
                    : data["ctype"] == 1
                      ? "transparent"
                      : params["who"] == "teacher"
                        ? userInfo["id"] == data["wid"] && data["wtype"] == "2"
                          ? "#dddddd"
                          : style.common.s_color_msg
                        : userInfo["id"] == data["wid"] && data["wtype"] == "1"
                          ? "#dddddd"
                          : style.common.t_color_msg,
                // params["who"] == "teacher" ?
                // ( userInfo["id"] == data["wid"] && data["wtype"] == '2'  ? "#ffd893" : "#dddddd")
                // :
                // ( userInfo["id"] == data["wid"] && data["wtype"] == '1' ? "#ffd893" : "#dddddd")

                // "10px"
                borderRadius: 10,
                fontSize: "14px",
                // fontWeight:"lighter"
              }}
              {...bind()}
              onClick={() => {
                fnClick(data);
              }}
              onMouseDown={(e) => {
                if (e.button === 2) {
                  if (params.who === "student" && [1, 2, 3].includes(data.ctype)) {
                    return;
                  }
                  fnLongClick(data);
                }
              }}
            >
              {
                // 0914 Project 6 기능 추가 관련 주석
                // params["qid"] == 0 ?
                // <div id={"talk_0"} dangerouslySetInnerHTML={{__html :data["content"] }}>
                //         {/* {data["content"]} */}
                //     </div>
                // :
                !data["ctype"] && data["ctype"] != 0 ? (
                  params["qid"] == 0 && (
                    <div id={"talk_0"} style={{ padding: 12 }}>
                      {/* {data["content"]} */}
                      <div dangerouslySetInnerHTML={{ __html: data["content"] }} />
                    </div>
                  )
                ) : data["ctype"] == 0 ? (
                  <div id={"talk_0"} style={{ padding: 12 }}>
                    {/* {data["content"]} */}
                    <div dangerouslySetInnerHTML={{ __html: data["content"] }} />
                  </div>
                ) : data["ctype"] == 1 ? (
                  <img
                    style={{
                      width: "100%",
                      borderRadius: 5,
                    }}
                    onLoad={() => {
                      if (isRef) {
                        setTimeout(() => {
                          scrollRef.current.scrollIntoView({
                            block: "end",
                            inline: "end",
                          });
                        }, 300);
                      }
                    }}
                    onClick={() => {
                      // console.log("data -> ",data)
                      setSenderInfo({
                        nick: data["nick"],
                        name: data["name"],
                        id:
                          params["qid"] == 0
                            ? data["wtype"] == 2
                              ? params["tid"]
                              : params["sid"]
                            : data["wid"],
                        type: data["wtype"],
                        tid: params["tid"],
                        sid: params["sid"],
                        qid: params["qid"],
                      });
                      if (data["ori_id"]) {
                        // navigate(`/view/${params["who"]}/${data["ori_id"].split("&")[0]}/${data["ori_id"].split("&")[1]}/0`)
                        navigate(
                          `/view/${params["who"]}/${data["ori_id"].split("&")[0]}/${data["content"]}/n/0`
                        );
                      } else {
                        navigate(`/view/${params["who"]}/${data["qid"]}/${data["content"]}/n/0`);
                      }
                    }}
                    loading={"lazy"}
                    src={
                      params["qid"] == 0
                        ? `${constants.s3_url}/general/${params["tid"]}___${params["sid"]}/${data["content"]}?${util.getRandomInt(1000, 9999)}`
                        : data["ori_id"]
                          ? // `${constants.apiUrl}/public/qna/${data["ori_id"].split("&")[0]}/${data["ori_id"].split("&")[1]}.jpg?${util.getRandomInt(1000,9999)}`
                            `${constants.s3_url}/qna/${data["ori_id"].split("&")[0]}/${data["content"]}?${util.getRandomInt(1000, 9999)}`
                          : `${constants.s3_url}/qna/${data["qid"]}/${data["content"]}?${util.getRandomInt(1000, 9999)}`
                    }
                    // onError={(e)=>{e.currentTarget.src = `${constants.apiUrl}/qna/${params["qid"]}/${data["id"]}.jpg?${util.getRandomInt(1000,9999)}`}}
                    // src={`${constants.apiUrl}/public/qna/${params["qid"]}/${data["id"]}.jpg?${util.getRandomInt(1000,9999)}`}
                  />
                ) : data["ctype"] == 2 ? (
                  <div
                    // href={data["content"]}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      borderRadius: 10,
                      position: "relative",
                      height: "100%",
                      flexDirection: "column",
                    }}
                    onClick={() => {
                      // console.log("data -> ",data)
                      setSenderInfo({
                        nick: data["nick"],
                        name: data["name"],
                        id: data["wid"],
                        type: data["wtype"],
                      });
                      setYoutube_url(data["content"]);
                      if (data["ori_id"]) {
                        // navigate(`/view/${params["who"]}/${data["ori_id"].split("&")[0]}/video&${data["ori_id"].split("&")[1]}/0`);
                        navigate(
                          `/view/${params["who"]}/${data["ori_id"].split("&")[0]}/youtube/n/0`
                        );
                      } else {
                        navigate(`/view/${params["who"]}/${params["qid"]}/youtube/n/0`);
                      }
                    }}
                  >
                    <img
                      style={{
                        width: "100%",
                        height: "100%",
                        borderRadius: 10,
                      }}
                      onError={(e) => {
                        setIsErr(true);
                      }}
                      // src={`${constants.apiUrl}/public/qna/${params["qid"]}/${data["id"]}.jpg?`}
                      src={
                        data["content"].startsWith("https://vimeo.com/")
                          ? vimeoInfo?.thumbnail_url
                          : `https://img.youtube.com/vi/${youtubeId(data["content"])}/0.jpg`
                      }
                    />
                    <div
                      style={{
                        position: "absolute",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        width: "30px",
                        padding: "15px 10px",
                        height: "20px",
                        borderRadius: 10,
                        backgroundColor:
                          params["who"] == "teacher"
                            ? userInfo["id"] == data["wid"] && data["wtype"] == "2"
                              ? style.common.t_color
                              : style.common.s_color
                            : userInfo["id"] == data["wid"] && data["wtype"] == "1"
                              ? style.common.t_color
                              : style.common.s_color,
                      }}
                    >
                      <PlayArrow
                        style={{
                          color: "white",
                        }}
                      />
                    </div>

                    {youtubeLikes && (
                      <div
                        style={{
                          fontSize: 9,
                          display: "flex",
                          alignItems: "center",
                          gap: 10,
                          marginRight: "auto",
                          marginLeft: 10,
                        }}
                      >
                        <ThumbUp
                          style={{
                            color: style.common.s_color,
                          }}
                        />
                        {youtubeLikes?.likeCount?.toLocaleString()}
                        <Visibility
                          style={{
                            color: style.common.s_color,
                          }}
                        />
                        {youtubeLikes?.viewCount?.toLocaleString()}
                      </div>
                    )}
                  </div>
                ) : data["ctype"] === 3 ? (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      borderRadius: 10,
                      position: "relative",
                      // width: "40vw",
                      // minWidth: "240px",
                      // width:"100%",
                      // width:"60%",
                      // minWidth:0px",
                      // flex:1,
                    }}
                    onClick={() => {
                      console.log("Data => ", data);
                      // return;
                      if (params["qid"] === 0) {
                        setSenderInfo({
                          nick: data["nick"],
                          name: data["name"],
                          id: data["wid"],
                          type: data["wtype"],
                          general: params["tid"] + "___" + params["sid"],
                        });
                      } else {
                        setSenderInfo({
                          nick: data["nick"],
                          name: data["name"],
                          id: data["wid"],
                          type: data["wtype"],
                        });
                      }
                      setVideoInfo({
                        video_start: data["video_start"],
                        video_end: data["video_end"],
                      });
                      // return;
                      if (data["ori_id"]) {
                        // navigate(`/view/${params["who"]}/${data["ori_id"].split("&")[0]}/video&${data["ori_id"].split("&")[1]}/0`);
                        navigate(
                          `/view/${params["who"]}/${data["ori_id"].split("&")[0]}/video&${util.replace_mp4(data["content"])}/n/${data["wid"]}`
                        );
                      } else {
                        navigate(
                          `/view/${params["who"]}/${data["qid"]}/video&${util.replace_mp4(data["content"])}/n/${data["wid"]}`
                        );
                      }
                    }}
                  >
                    {data.wid === userInfo.id && !isEdit && (
                      <button
                        style={{
                          position: "absolute",
                          left: -50,
                          border: "none",
                          borderRadius: "100%",
                          width: 32,
                          height: 32,
                          backgroundColor: "#dadada",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                        onClick={async (e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          if (!navigator.share) {
                            const videoUrl = data["ori_id"]
                              ? // navigate(`/view/${params["who"]}/${data["ori_id"].split("&")[0]}/video&${data["ori_id"].split("&")[1]}/0`);
                                `${constants.redirect_url}view/${params["who"]}/${data["ori_id"].split("&")[0]}/video&${util.replace_mp4(data["content"])}/n/${data["wid"]}`
                              : `${constants.redirect_url}view/${params["who"]}/${params["qid"]}/video&${util.replace_mp4(data["content"])}/n/${data["wid"]}`;
                            await util.copyClip(encodeURI(videoUrl));
                            alert("클립보드에 동영상 링크를 복사했습니다");
                            return;
                          }
                          try {
                            const response = await fetch(
                              params["qid"] == 0
                                ? data["ori_id"]
                                  ? `${constants.s3_url}/general/${params["tid"]}___${params["sid"]}/${data["content"].split(".")[0]}.jpg?${util.getRandomInt(1000, 9999)}`
                                  : `${constants.s3_url}/general/${params["tid"]}___${params["sid"]}/${util.parseSplit(data["content"])}.jpg?${util.getRandomInt(1000, 9999)}`
                                : data["ori_id"]
                                  ? `${constants.s3_url}/qna/${data["ori_id"].split("&")[0]}/${data["content"].split(".")[0]}.jpg?${util.getRandomInt(1000, 9999)}`
                                  : `${constants.s3_url}/qna/${data["qid"]}/${util.parseSplit(data["content"])}.jpg?${util.getRandomInt(1000, 9999)}`
                            );
                            const blob = await response.blob();
                            const files = [new File([blob], data.content, { type: "video/mp4" })];
                            await navigator.share({
                              title: "[HiQSUM]",
                              text: `${userInfo.name} 님이 동영상을 공유했습니다.`,
                              url: location.href,
                              files,
                            });
                          } catch (e) {
                            alert("동영상 공유를 실패했습니다");
                            console.error(e);
                          }
                        }}
                      >
                        <ShareRounded fontSize={"small"} />
                      </button>
                    )}

                    <img
                      style={{
                        width: "100%",
                        // maxWidth:"60vw",
                        // objectFit:"cover",
                        borderRadius: 10,
                      }}
                      ref={thumbnailRef}
                      onError={(e) => {
                        // setIsErr(true)
                        // e.currentTarget.src = `${constants.apiUrl}/qna/${params["qid"]}/${data["id"]}.jpg?${util.getRandomInt(1000,9999)}`
                        e.currentTarget.src = constants.isAcademy ? logo : main_logo;
                        setTimeout(() => {
                          if (thumbnailRef.current)
                            thumbnailRef.current.src =
                              params["qid"] == 0
                                ? data["ori_id"]
                                  ? `${constants.s3_url}/general/${params["tid"]}___${params["sid"]}/${data["content"].split(".")[0]}.jpg?${util.getRandomInt(1000, 9999)}`
                                  : `${constants.s3_url}/general/${params["tid"]}___${params["sid"]}/${util.parseSplit(data["content"])}.jpg?${util.getRandomInt(1000, 9999)}`
                                : data["ori_id"]
                                  ? `${constants.s3_url}/qna/${data["ori_id"].split("&")[0]}/${data["content"].split(".")[0]}.jpg?${util.getRandomInt(1000, 9999)}`
                                  : `${constants.s3_url}/qna/${data["qid"]}/${util.parseSplit(data["content"])}.jpg?${util.getRandomInt(1000, 9999)}`;
                        }, 3000);
                      }}
                      // src={`${constants.apiUrl}/public/qna/${params["qid"]}/${data["id"]}.jpg?`}
                      src={
                        params["qid"] == 0
                          ? data["ori_id"]
                            ? `${constants.s3_url}/general/${params["tid"]}___${params["sid"]}/${data["content"].split(".")[0]}.jpg?${util.getRandomInt(1000, 9999)}`
                            : `${constants.s3_url}/general/${params["tid"]}___${params["sid"]}/${util.parseSplit(data["content"])}.jpg?${util.getRandomInt(1000, 9999)}`
                          : data["ori_id"]
                            ? `${constants.s3_url}/qna/${data["ori_id"].split("&")[0]}/${data["content"].split(".")[0]}.jpg?${util.getRandomInt(1000, 9999)}`
                            : `${constants.s3_url}/qna/${data["qid"]}/${util.parseSplit(data["content"])}.jpg?${util.getRandomInt(1000, 9999)}`
                      }
                    />

                    {
                      // isErr ?
                      //     <>
                      //     </>
                      // <div style={{
                      //     position:"absolute",
                      //     display:"flex",
                      //     flexDirection:"column",
                      //     alignItems:"center",
                      //     justifyContent:"center",
                      //     width:'150px',
                      //     // height:"40px",
                      //     padding:"10px",
                      //     borderRadius:10,
                      //     backgroundColor : "white",
                      //     color : style.common.t_color,
                      // }}>
                      //         <CircularProgress
                      //             style={{
                      //                 // fontSize:"18px",
                      //                 width:"30px",
                      //                 color : style.common.t_color
                      //             }}
                      //         />
                      //         파일 업로드 중입니다...
                      //         {/* <PlayArrow
                      //             style={{
                      //                 color : "white"
                      //             }}
                      //         /> */}
                      // </div>
                      // :
                      <div
                        style={{
                          position: "absolute",
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          justifyContent: "center",
                          // width:'30px',
                          padding: "15px 10px",
                          height: "20px",
                          borderRadius: 10,
                          backgroundColor:
                            params["who"] == "teacher"
                              ? userInfo["id"] == data["wid"] && data["wtype"] == "2"
                                ? style.common.t_color
                                : style.common.s_color
                              : userInfo["id"] == data["wid"] && data["wtype"] == "1"
                                ? style.common.t_color
                                : style.common.s_color,
                        }}
                      >
                        <PlayArrow
                          style={{
                            color: "white",
                          }}
                        />
                        {data["play_time"] && (
                          <div
                            style={{
                              color: "white",
                              width: "100%",
                              fontSize: "11px",
                              fontWeight: "normal",
                            }}
                          >
                            {data["video_start"] != 0
                              ? util.formatDate(
                                  Number(data["video_end"]) - Number(data["video_start"])
                                )
                              : data["play_time"] != data["video_end"]
                                ? data["video_end"] == 0
                                  ? util.formatDate(data["play_time"])
                                  : util.formatDate(
                                      Number(data["video_end"]) - Number(data["video_start"])
                                    )
                                : util.formatDate(data["play_time"])}
                          </div>
                        )}
                      </div>
                    }
                  </div>
                ) : data["ctype"] == 4 ? (
                  <div
                    style={{
                      // minWidth:"400px",
                      // minWidth:"65vw",
                      minWidth: "250px",
                      maxWidth: "500px",
                      // borderRadius:20,
                      // width : "100%",
                      // backgroundColor:"green",
                    }}
                  >
                    <UiExplain
                      data={qnaData}
                      fnClick={(d) => {
                        try {
                          // setHiddenUrl(`/message/${params["who"]}/home`)
                          navigate(
                            "/talk/" +
                              (params["who"] ? params["who"] : "student") +
                              "/" +
                              "0" +
                              "/" +
                              d["id"] +
                              "/" +
                              d["sid"] +
                              "/" +
                              d["tid"]
                          );
                        } catch (e) {
                          // console.log("@@ ",e);
                        }
                      }}
                      isMsg={true}
                    />
                  </div>
                ) : data["ctype"] == 8 ? (
                  <div
                    style={{
                      // width : "100%",
                      // minWidth:"65vw",
                      minWidth: "250px",
                      maxWidth: "500px",
                      // borderRadius:20,
                      // backgroundColor:"green",
                    }}
                  >
                    <UiExplain
                      data={qnaData}
                      fnClick={(d) => {
                        try {
                          // setHiddenUrl(`/message/${params["who"]}/home`)
                          navigate(
                            "/talk/" +
                              params["who"] +
                              "/" +
                              "0" +
                              "/" +
                              d["id"] +
                              "/" +
                              d["sid"] +
                              "/" +
                              d["tid"]
                          );
                        } catch (e) {
                          // console.log("@@ ",e);
                        }
                      }}
                      isMsg={true}
                    />
                  </div>
                ) : data["ctype"] == 9 ? (
                  <div
                    style={{
                      width: "100%",
                      // backgroundColor:"green",
                    }}
                  >
                    {`${data["content"].split("=")[2]} 님이 채팅방에 참여하셨어요.`}
                  </div>
                ) : data.ctype === 91 ? (
                  <div style={{ padding: 12 }}>
                    {parseInt(data.video_start / 60)}분 {parseInt(data.video_start % 60)}초 질문
                    있어요
                  </div>
                ) : data.ctype === 12 ? (
                  <button
                    onClick={() => {
                      setVideoInfo({
                        video_start: data.video_start,
                        video_link: data.content,
                      });
                      navigate(
                        `/view/${params.who}/${data.qid}/video&${encodeURIComponent(data.content)}/n/${data.tid}`
                      );
                    }}
                  >
                    해설 보러 가기
                  </button>
                ) : (
                  data.ctype === 71 && (
                    <div
                      style={{
                        padding: "16px",
                        width: "100%",
                        overflowY: "auto",
                      }}
                    >
                      <LatexView>{data.content}</LatexView>
                    </div>
                  )
                )
              }
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-end",
              fontSize: "10px",
              color: style.common.fontColor_7,
              padding: "0px 0px 0px 3px",
            }}
          >
            <div>
              {params["qid"] == 0
                ? params["who"] == "teacher"
                  ? userInfo["id"] == data["tid"] && data["wtype"] == "2"
                    ? data["readd"] == 0 && "안읽음"
                    : ""
                  : userInfo["id"] == data["sid"] && data["wtype"] == "1"
                    ? data["readd"] == 0 && "안읽음"
                    : ""
                : params["who"] == "teacher"
                  ? userInfo["id"] == data["wid"] && data["wtype"] == "2"
                    ? data["readd"] == 0 && "안읽음"
                    : ""
                  : userInfo["id"] == data["wid"] && data["wtype"] == "1"
                    ? data["readd"] == 0 && "안읽음"
                    : ""}
            </div>
            <div>{util.convertToDateTime_form3(data["reg_date"])}</div>
            <div>{util.convertToDateTime_form4(data["reg_date"])}</div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Talk;
