import style from "../../asset/style"

const Basic_Style = {
    c : {
        display:"flex",
        width:"100%",
        // justifyContent:"center",
        flexDirection:"column",
        minHeight : "100vh",
        maxWidth:style.common.maxWidth
        // position : "relative",
        // maxWidth : "100vw",
    },
    t_w : {
        
        display:"flex",
        justifyContent:"space-between",
        alignItems:"center",
        width:"100%",
    },
    t_text : {
        fontSize : "18px",
        fontWeight : "bold",
    },
    children_w :{
        // padding : style.common.padding,
        display:"flex",
        flex:1,
        flexDirection : 'column',
    }
}

export default Basic_Style