import { Delete, GppMaybe, ModeEditOutlineOutlined } from "@mui/icons-material";
import { useDeclarationReply, useDeclarationReplyCancel, useReplyDelete } from "api/classDetail";
import { getUser } from "asset/storage";
import BottomSheet from "components/molecules/BottomSheet";
import React, { useCallback, useContext, useMemo } from "react";
import { useNavigate, useParams } from "react-router";
import { BoardReply } from "types/graphql/graphql";
import { ReplyContext } from "./ReplyArea";

interface IReplyMoreMenu extends BoardReply {
  open: boolean;
  onClose: () => void;
}

const ReplyMoreMenu = ({ onClose, open, id: replyId, wid: writerId }: IReplyMoreMenu) => {
  const { setReplyId } = useContext(ReplyContext);
  const params = useParams();
  const userInfo = getUser();
  const navigate = useNavigate();

  const { mutate: replyDelete } = useReplyDelete();

  const handleDeclaration = useCallback(() => {
    navigate(`/warn/${params["who"]}/${replyId}/reply`);
  }, []);

  const { data: declarationReplyData } = useDeclarationReply({
    replyId,
    userId: userInfo.id,
  });

  const isDeclarationData = useMemo(
    () => declarationReplyData?.list.length > 0,
    [declarationReplyData?.list]
  );

  const { mutate } = useDeclarationReplyCancel();

  const handleDeclarationCancel = useCallback(() => {
    if (confirm("신고를 철회하시겠어요?")) {
      mutate({
        replyId,
        userId: userInfo.id,
      });
    }
  }, [navigate, mutate]);

  const isModifiable = useMemo(() => userInfo.id === writerId, [userInfo.id, writerId]);

  const handleDelete = useCallback(async () => {
    if (confirm("댓글을 삭제하시겠어요?")) {
      await replyDelete({ replyId });
      setReplyId(null);
      onClose();
    }
  }, [replyDelete]);

  const handleUpdate = useCallback(() => {
    navigate(`/reply/${params["who"]}/${params["id"]}/modi&${replyId}`);
  }, []);
  return (
    <BottomSheet
      open={open}
      onClose={onClose}
      menuList={
        isModifiable
          ? [
              {
                icon: <Delete />,
                title: "삭제하기",
                onClick: handleDelete,
              },
              {
                icon: <ModeEditOutlineOutlined />,
                title: "수정하기",
                onClick: handleUpdate,
              },
            ]
          : [
              {
                icon: <GppMaybe />,
                title: isDeclarationData ? "신고 철회하기" : "댓글 신고하기",
                onClick: isDeclarationData ? handleDeclarationCancel : handleDeclaration,
              },
            ]
      }
    />
  );
};

export default ReplyMoreMenu;
