import { Container } from "@material-ui/core";
import Section from "components/atoms/Section";
import React, { useContext } from "react";
import { StudyBookContext } from "../context/StudyBookContext";
import { BlackButton, GrayButton } from "./FolderEdit";
import StudyBookConfig from "./StudyBookConfig";
const StudyBookEdit = () => {
  const { isConfigMode, setIsConfigMode, checkedBookList, allCheckedBook } =
    useContext(StudyBookContext);

  return (
    <Container>
      <Section justify="space-between">
        교재
        {isConfigMode ? (
          <BlackButton onClick={() => setIsConfigMode(false)}>취소</BlackButton>
        ) : (
          <GrayButton onClick={() => setIsConfigMode(true)}>편집</GrayButton>
        )}
      </Section>
      {isConfigMode && <StudyBookConfig />}
    </Container>
  );
};

export default StudyBookEdit;
