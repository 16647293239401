import { React, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import icon_setting_off from "../../asset/Img/icon_setting_off.png";
import image_96 from "../../asset/Img/image_96.png";
import teacher_ from "../../asset/Img/teacher_.png";
import wait from "../../asset/Img/wait.png";
import apiFn from "../../asset/apiClass";
import style from "../../asset/style";
import { UiInput } from "../../uiComponents/UiInput/UiInput";
import UiPeople_Type2 from "../../uiComponents/UiPeople/UiPeople_Type2";
import Profile_class_Style from "../Profile/Profile_class_Style";

const Book_teacher = ({
  userInfo,
  isTeacher,
  linkData,
  hiddenNav,
  setHiddenNav,
  setIsPeople,
  isPeople,
}) => {
  let params = useParams();
  let navigate = useNavigate();

  var [selectData_l, setSelectData_l] = useState(0);

  var [selectData_two, setSelectData_two] = useState({});
  var [selectData_l_two, setSelectData_l_two] = useState(0);

  const [isEdit_two, setIsEdit_two] = useState(false);

  var [pre_data, setPre_data] = useState([]);
  var [pre_data_ori, setPre_data_ori] = useState([]);
  var [isEdit_pre, setIsEdit_pre] = useState(false);
  var [selectData_pre, setSelectData_pre] = useState({});

  var [pre_data_8, setPre_data_8] = useState([]);
  var [pre_data_8_ori, setPre_data_8_ori] = useState([]);
  var [isEdit_pre_8, setIsEdit_pre_8] = useState(false);
  var [selectData_pre_8, setSelectData_pre_8] = useState({});

  var [data, setData] = useState([]);
  var [data_ori, setData_ori] = useState([]);
  var [isEdit, setIsEdit] = useState(false);
  var [selectData, setSelectData] = useState({});

  // 사람 검색
  const filter_people = async (inSearch) => {
    try {
      // console.log("&& ",d);
      console.log("IN SEARCH : ", inSearch);
      // console.log("friendsList : ", friendsList);
      let result = pre_data_ori.filter((v) => {
        // console.log(v)
        if (
          v["name"].includes(inSearch) ||
          v["nick"].includes(inSearch) ||
          v["tel"].includes(inSearch)
        ) {
          return v;
        }
      });
      let result2 = pre_data_8_ori.filter((v) => {
        // console.log(v)
        if (
          v["name"].includes(inSearch) ||
          v["nick"].includes(inSearch) ||
          v["tel"].includes(inSearch)
        ) {
          return v;
        }
      });
      let result3 = data_ori.filter((v) => {
        // console.log(v)
        if (
          v["name"].includes(inSearch) ||
          v["nick"].includes(inSearch) ||
          v["tel"].includes(inSearch)
        ) {
          return v;
        }
      });

      // console.log("search result 1 : ",result)
      setPre_data([...result]);
      // console.log("search result 2 : ",result2)
      setPre_data_8([...result2]);
      // console.log("search result 3 : ",result3)
      setData([...result3]);
      // setFriendsList([
      //     ...result
      // ])
    } catch (e) {
      console.log(e);
    }
  };

  const apiPro_read = async () => {
    try {
      let data = await apiFn.comApi({
        state: {
          table: "user_credential",
          page: 1,
          sort: "name desc",
          amount: 9999,
          ref: [
            "HIQDB_book_link.bid|HIQDB_book_link.mid=HIQDB_user_credential.name",
            "HIQDB_book_link.type|HIQDB_book_link.mid=HIQDB_user_credential.name",
            "HIQDB_book_link.status as bstatus|HIQDB_book_link.mid=HIQDB_user_credential.name",
            "A.* |HIQDB_user A on A.index=HIQDB_user_credential.user_id and A.user_type = 2",
          ],
          where: {
            "HIQDB_book_link.bid": `='${params["id"]}'`,
            "HIQDB_book_link.status": `!='9'`,
            "HIQDB_book_link.type": `!='0'`,
            "HIQDB_book_link.mid": `IS NOT NULL`,
          },
          // table : "book_link",
          // page : 1,
          // sort : "mid desc",
          // amount : 9999,
          // ref : [
          //     'HIQDB_teacher'
          // ],
        },
      });

      let pre_array = [];
      let pre_array_8 = [];
      let array = [];
      data["list"].map((v, i) => {
        console.log("# ->", v);
        v["status"] = v["bstatus"];
        if (
          (v.type === 6 && v.bstatus === 0) ||
          (v.type === 8 && (v.bstatus === 5 || v.bstatus === 0))
        ) {
          pre_array_8.push(v);
        } else if (v.type !== 8 && (v.bstatus === 5 || v.bstatus === 6)) {
          pre_array.push(v);
        } else {
          array.push(v);
        }
        // if (v["bstatus"] == 5 || v["bstatus"] == 0) {
        //     if (v["type"] == 8) {
        //         pre_array_8.push(v)
        //     } else {
        //         pre_array.push(v)
        //     }
        // } else {
        //     array.push(v)
        // }
      });

      setPre_data_8(pre_array_8);

      setPre_data(pre_array);

      setData(array);

      setPre_data_8_ori(pre_array_8);

      setPre_data_ori(pre_array);

      setData_ori(array);
    } catch (e) {
      console.error(e);
      alert("참여자 목록을 가져오는데 실패했습니다.");
    }
  };

  const apiPro_approve = async (data) => {
    try {
      await apiFn.comApi({
        state: {
          table: "book_link",
          set: [
            {
              key: `mid:${data["id"]}' and bid='${params["id"]}`,
              value: {
                status: 1,
              },
            },
          ],
        },
      });

      await apiPro_read();
    } catch (e) {
      console.log("************");
      console.log(e);
      console.log("************");
    }
  };

  const apiPro_set = async (data, isDel, isDown, isMulti) => {
    try {
      let state = {
        table: "book_link",
      };

      if (isDel) {
        if (isMulti) {
          let del = [];
          Object.keys(data).map((v, i) => {
            let _obj = { mid: v, bid: params["id"] };
            del.push(_obj);
          });
          state["del"] = del;
        } else {
          state["del"] = [{ mid: data["id"], bid: params["id"] }];
        }
      } else {
        let type = 5;
        let sendMsg = {
          type: "book_adminup",
          orderMid: userInfo["id"],
          targetMid: data["id"],
          boid: params["id"],
        };
        if (isDown) {
          sendMsg["type"] = "book_admindown";
          type = 6;
        }
        state["sendMsg"] = sendMsg;
        state["set"] = [
          {
            key: `mid:${data["id"]}' and bid='${params["id"]}`,
            value: { type: type },
          },
        ];
      }
      let isOk = await apiFn.comApi({
        state: state,
      });
      if (isMulti) {
        setIsEdit(false);
        setIsEdit_two(false);
        setSelectData({});
        setSelectData_two({});
      }
      await apiPro_read();
    } catch (e) {
      // console.log("## ",e)
    }
  };

  const apiPro_del = async (data) => {
    try {
      let isOk = await apiFn.exitBook({
        state: {
          orderMid: userInfo["id"],
          targetMid: data["id"],
          boid: params["id"],
        },
      });
      await apiPro_read();
    } catch (e) {}
  };

  const apiPro_del_array = async (data) => {
    try {
      let id_key = Object.keys(data);
      // console.log(id_key)
      // return;
      let promiseArray = id_key.map((v) =>
        apiFn.exitBook({
          state: {
            orderMid: userInfo["id"],
            targetMid: v,
            boid: params["id"],
          },
        })
      );
      await Promise.all([...promiseArray]);

      setIsEdit(false);
      setSelectData({});

      setIsEdit_pre(false);
      setSelectData_pre({});

      setIsEdit_pre_8(false);
      setSelectData_pre_8({});
    } catch (e) {
      // console.log("@@ ",e)
    } finally {
      await apiPro_read();
    }
  };
  useEffect(() => {
    if (!isPeople) {
      apiPro_read();
    }
  }, [isPeople]);

  useEffect(() => {
    try {
      selectData_l = 0;
      // // console.log("selectData => ",selectData)
      Object.keys(selectData).map((v, i) => {
        if (selectData[v] == "Y") {
          selectData_l = selectData_l + 1;
        } else if (selectData[v] == "N") {
          if (selectData_l == 0) return;
          selectData_l = selectData_l - 1;
        }
      });
      setSelectData_l(selectData_l);
    } catch (e) {
      // console.log(e)
    }
  }, [selectData]);

  return (
    <div
      style={{
        ...Profile_class_Style.c,
        padding: "0px 20px",
        backgroundColor: "white",
      }}
    >
      {/* {
            (isEdit || isEdit_two) &&
            <UiTopPopTextBtn
                title="편집 취소"
                isTeacher={isTeacher}
                fnClick={()=>{
                    setIsEdit(false)
                    setIsEdit_two(false);
                    setSelectData({})
                    setSelectData_two({})
                }}
            />
            } */}
      <div
        style={{
          display: "flex",
          width: "100%",
          justifyContent: "flex-end",
          alignItems: "center",
        }}
      >
        <div
          style={{
            display: "flex",
          }}
        >
          <UiInput
            inputClass="input_t"
            basic_container_style={{
              borderRadius: "15px",
              width: "190px",
              height: "11px",
              padding: "10px",
            }}
            placeholder="이름/닉네임/전화번호 를 입력해주세요."
            fnChange={(e) => {
              filter_people(e.target.value);
            }}
          />
        </div>
        {/* <Search
                    style={{
                        margin:"0px 4px 0px 5px",
                        color : "rgb(100, 100, 100)"
                    }}
                /> */}
        <img
          src={image_96}
          style={{
            width: "18px",
            height: "18px",
            margin: "0px 5px",
          }}
          onClick={() => {
            setIsPeople(true);
          }}
        />
      </div>
      {/* {
            (
            linkData["mid"] &&
            linkData["type"] == 5
            )
            &&
            <div style={Lecture_book_Style.t_btn_w}>
                <UiBtn
                    btnStyle={Lecture_book_Style.t_btn}
                    title={
                        <div style={Academy_book_Style.btn_title}>
                            <Add style={Academy_book_Style.btn_Add} />
                            <div style={{
                                paddingLeft : "12px"
                            }}>
                                선생님 초대하기
                            </div>
                        </div>
                        // <div style={Lecture_book_Style.t_btn_text}>
                        //     <Add
                        //         style={Lecture_book_Style.t_btn_Add}
                        //     />
                        //     선생님 초대하기
                        // </div>
                    }
                    fnClick={()=>{
                        setHiddenNav("teacher")
                        navigate(`/invite/${params["who"]}/de/b/bid&${params["id"]}`)
                    }}
                />
            </div>
            } */}
      {pre_data_8.length > 0 && linkData["mid"] && linkData["type"] == 5 && (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div
            style={{
              fontSize: "18px",
              margin: "15px 0px",
              fontWeight: 700,
              color: "#F09294",
              display: "flex",
              alignItems: "center",
            }}
          >
            <img
              src={wait}
              style={{
                width: "32px",
                height: "32px",
                marginRight: "5px",
              }}
            />
            {`참여 신청 대기 총${pre_data_8.length}명`}
          </div>
          {isEdit_pre_8 ? (
            <div
              style={{
                display: "flex",
              }}
            >
              <div
                style={{
                  fontSize: "14px",
                  // margin : "15px 0px",
                  fontWeight: 400,
                  color: style.common.fontColor_3,
                  marginRight: "10px",
                }}
                onClick={() => {
                  let isOk = window.confirm(
                    `선택하신 ${Object.keys(selectData_pre_8).length}명을 교재 탈퇴 시키겠습어요?`
                  );
                  apiPro_del_array(selectData_pre_8);
                }}
              >
                {`탈퇴`}
              </div>
              <div
                style={{
                  fontSize: "14px",
                  // margin : "15px 0px",
                  fontWeight: 400,
                  color: style.common.fontColor_3,
                }}
                onClick={() => {
                  setSelectData_pre_8({});
                  setIsEdit_pre_8(false);
                }}
              >
                {`선택 취소`}
              </div>
            </div>
          ) : (
            linkData["mid"] &&
            linkData["type"] == 5 && (
              <img
                src={icon_setting_off}
                style={{
                  width: "18px",
                  height: "18px",
                  margin: "0px 5px",
                }}
                onClick={() => {
                  setIsEdit_pre_8(true);
                }}
              />
            )
          )}
        </div>
      )}
      {linkData["mid"] && linkData["type"] == 5 && (
        <UiPeople_Type2
          fnReject={(d) => {
            // 교재 공유 신청 거절하기
            let isConfirm = window.confirm("교재 공유신청을 거절하시겠어요?");
            if (!isConfirm) return;
            // apiPro_del(d)
            apiPro_set(d, true);
          }}
          fnApprove={(d) => {
            // 교재 공유 신청 승낙
            let isConfirm = window.confirm("교재 공유신청을 승낙하시겠어요?");
            if (!isConfirm) return;
            apiPro_approve(d);
          }}
          isBlank={false}
          form={2}
          isInfo={true}
          isExpel={linkData["type"] == 5 ? true : false}
          data={pre_data_8}
          isOnly={linkData["mid"] && (linkData["type"] == 5 ? false : true)}
          isSearch={true}
          userInfo={userInfo}
          isTeacher={isTeacher}
          isBookManager={true}
          isTeacherPage={false}
          isEdit={isEdit_pre_8}
          setSelectData={setSelectData_pre_8}
          selectData={selectData_pre_8}
          fnBookUp={(d) => {
            apiPro_set(d);
          }}
          // fnLongClick={(id)=>{
          //     setIsEdit_pre_8(true)
          //     setSelectData_pre_8({
          //         ...selectData_pre_8,
          //         [id] : "Y"
          //     })
          // }}
          fnCheck={(e) => {
            if (selectData_pre_8[e.target.id] == "Y") {
              delete selectData_pre_8[e.target.id];
              setSelectData_pre_8({
                ...selectData_pre_8,
              });
            } else {
              setSelectData_pre_8({
                ...selectData_pre_8,
                [e.target.id]: "Y",
              });
            }
          }}
          // fnDown={(d)=>{

          // }}
          fnExpel={(d) => {
            let isOk = window.confirm("선생님의 초대신청을 취소하시겠어요?");
            if (isOk) {
              apiPro_del(d);
            }
            // apiPro_set(d,true);
          }}
          // fnExpel={fnExpel}
          fnClick={() => {}}
        />
      )}
      {pre_data.length > 0 && linkData["mid"] && linkData["type"] == 5 && (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div
            style={{
              fontSize: "18px",
              margin: "15px 0px",
              fontWeight: 700,
              color: "#4479A9",
            }}
          >
            {`초대 승낙 대기 총${pre_data.length}명`}
          </div>
          {isEdit_pre ? (
            <div
              style={{
                display: "flex",
              }}
            >
              <div
                style={{
                  fontSize: "14px",
                  // margin : "15px 0px",
                  fontWeight: 400,
                  color: style.common.fontColor_3,
                  marginRight: "10px",
                }}
                onClick={() => {
                  let isOk = window.confirm(
                    `선택하신 ${Object.keys(selectData_pre).length}명을 교재 가입 거절 시키겠습어요?`
                  );
                  apiPro_del_array(selectData_pre);
                }}
              >
                {`거절`}
              </div>
              <div
                style={{
                  fontSize: "14px",
                  // margin : "15px 0px",
                  fontWeight: 400,
                  color: style.common.fontColor_3,
                }}
                onClick={() => {
                  setIsEdit_pre(false);
                  setSelectData_pre({});
                }}
              >
                {`선택 취소`}
              </div>
            </div>
          ) : (
            linkData["mid"] &&
            linkData["type"] == 5 && (
              <img
                src={icon_setting_off}
                style={{
                  width: "18px",
                  height: "18px",
                  margin: "0px 5px",
                }}
                onClick={() => {
                  setIsEdit_pre(true);
                }}
              />
            )
          )}
        </div>
      )}
      {/* {
            isEdit &&
            (
            selectData_l > 0 &&
            <div style={{
                ...Lecture_book_Style.edit_section_w,
                borderTop: isEdit ? "none" : "1px solid",
                borderColor: style.common.borderColor,
            }}>
                선택된 선생님을
                <div style={{

                }}>
                    {
                    isTeacher &&
                    <div style={{
                        display : "flex"
                    }}>
                    <UiTextBtn
                        text={"초대 취소하기"}
                        btnStyle={{
                            ...Lecture_book_Style.textBtn,
                            color : style.common.fontColor_3,
                            marginRight : "7.5px",
                        }}
                        fnClick={()=>{
                            let isOk = window.confirm("선택하신 선생님들의 초대신청을 취소하시겠어요?")
                            if(isOk){
                                apiPro_del_array(selectData)

                                // apiPro_set(selectData,true,false,true)
                                // apiPro_del(selectData,true);
                                // apiPro_set(selectData,true,false,true)
                                // apiPro_del_array(selectData)
                            }
                        }}
                    />
                    </div>
                    }
                </div>
            </div>
            )
            }
             */}
      {linkData["mid"] && linkData["type"] == 5 && (
        <UiPeople_Type2
          fnReject={(d) => {
            // 교재 공유 신청 거절하기
            let isConfirm = window.confirm("교재 공유신청을 거절하시겠어요?");
            if (!isConfirm) return;
            // apiPro_del(d)
            apiPro_set(d, true);
          }}
          fnApprove={(d) => {
            // 교재 공유 신청 승낙
            let isConfirm = window.confirm("교재 공유신청을 승낙하시겠어요?");
            if (!isConfirm) return;
            apiPro_approve(d);
          }}
          isBlank={false}
          form={2}
          isInfo={true}
          isExpel={linkData["type"] == 5 ? true : false}
          data={pre_data}
          isOnly={linkData["mid"] && (linkData["type"] == 5 ? false : true)}
          isSearch={true}
          userInfo={userInfo}
          isTeacher={isTeacher}
          isBookManager={true}
          isTeacherPage={false}
          isEdit={isEdit_pre}
          setSelectData={setSelectData_pre}
          selectData={selectData_pre}
          fnBookUp={(d) => {
            apiPro_set(d);
          }}
          // fnLongClick={(id)=>{
          //     setIsEdit(true)
          //     setSelectData({
          //         ...selectData,
          //         [id] : "Y"
          //     })
          // }}
          fnCheck={(e) => {
            if (selectData_pre[e.target.id] == "Y") {
              delete selectData_pre[e.target.id];
              setSelectData_pre({
                ...selectData_pre,
              });
            } else {
              setSelectData_pre({
                ...selectData_pre,
                [e.target.id]: "Y",
              });
            }
          }}
          // fnDown={(d)=>{

          // }}
          fnExpel={(d) => {
            let isOk = window.confirm("선생님의 초대신청을 취소하시겠어요?");
            if (isOk) {
              apiPro_del(d);
            }
            // apiPro_set(d,true);
          }}
          // fnExpel={fnExpel}
          fnClick={() => {}}
        />
      )}
      {/* <UiSorting
                title={`전체 강사진 ${data.length}명`}
                data={[
                    // { name : "출처순" , value : "B01"},
                    // { name : "평점순" , value : "B02"},
                    // { name : "저도요순" , value : "B03"},
                    // { name : "날짜순" , value : "B04"},
                ]}
                isTeacher={params["who"] == "teacher" ? true : false}
            /> */}
      {data.length > 0 ? (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div
            style={{
              fontSize: "18px",
              margin: "15px 0px",
              fontWeight: 700,
              color: "#49A5B1",
              display: "flex",
              alignItems: "center",
            }}
          >
            <img
              src={teacher_}
              style={{
                width: "32px",
                height: "32px",
                marginRight: "3px",
              }}
            />
            {`참여중인 선생님 총${data.length}명`}
          </div>
          {isEdit ? (
            <div
              style={{
                display: "flex",
              }}
            >
              <div
                style={{
                  fontSize: "14px",
                  // margin : "15px 0px",
                  fontWeight: 400,
                  color: style.common.fontColor_3,
                  marginRight: "10px",
                }}
                onClick={() => {
                  let isOk = window.confirm(
                    `선택하신 ${Object.keys(selectData).length}명을 교재 탈퇴 시키겠습어요?`
                  );
                  apiPro_del_array(selectData);
                }}
              >
                {`탈퇴`}
              </div>
              <div
                style={{
                  fontSize: "14px",
                  // margin : "15px 0px",
                  fontWeight: 400,
                  color: style.common.fontColor_3,
                }}
                onClick={() => {
                  setIsEdit(false);
                  setSelectData({});
                }}
              >
                {`선택 취소`}
              </div>
            </div>
          ) : (
            linkData["mid"] &&
            linkData["type"] == 5 && (
              <img
                src={icon_setting_off}
                style={{
                  width: "18px",
                  height: "18px",
                  margin: "0px 5px",
                }}
                onClick={() => {
                  setIsEdit(true);
                }}
              />
            )
          )}
        </div>
      ) : (
        <></>
      )}
      {data.length > 0 ? (
        <UiPeople_Type2
          isBlank={false}
          form={2}
          isInfo={true}
          isExpel={linkData["type"] == 5 ? true : false}
          data={data}
          isOnly={linkData["mid"] && (linkData["type"] == 5 ? false : true)}
          selectData={selectData}
          isSearch={true}
          userInfo={userInfo}
          isTeacher={isTeacher}
          isBookManager={true}
          isTeacherPage={false}
          fnBookUp={(d) => {
            apiPro_set(d);
          }}
          fnDown={(d) => {
            apiPro_set(d, false, true);
          }}
          fnExpel={(d) => {
            let isOk = window.confirm("선생님을 교재에서 내보내시겠습니까?");
            if (isOk) {
              // apiPro_set(d,true);
              apiPro_del(d);
            }
          }}
          fnCheck={(e) => {
            if (selectData[e.target.id] == "Y") {
              delete selectData[e.target.id];
              setSelectData({
                ...selectData,
              });
            } else {
              setSelectData({
                ...selectData,
                [e.target.id]: "Y",
              });
            }
          }}
          isEdit={isEdit}
          // fnExpel={fnExpel}
          fnClick={() => {}}
        />
      ) : (
        <></>
      )}
    </div>
  );
};

export default Book_teacher;
