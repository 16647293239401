import { ReactComponent as FabActiveIcon } from "asset/images/icon/fab-active.svg";
import { ReactComponent as FabIcon } from "asset/images/icon/fab-default.svg";
import { FontStyleMap } from "components/atoms/Font";
import React, { ReactNode, useEffect, useState } from "react";
import styled from "styled-components";

interface LightBallMenuProps {
  menuList: LightBallMenuItemProps[];
}

interface LightBallMenuItemProps {
  title: string;
  onClick: () => void;
  icon: ReactNode;
}
const LightBallMenu = ({ menuList }: LightBallMenuProps) => {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (open) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = null;
    }
    return () => {
      document.body.style.overflow = null;
    };
  }, [open]);

  return (
    <>
      {open && <Mask onClick={() => setOpen(false)} />}
      <FixedContainer>
        {open && (
          <MenuContainer>
            {menuList.map((menu, index) => (
              <MenuItem
                key={index}
                onClick={() => {
                  menu.onClick();
                  setOpen(false);
                }}
              >
                {menu.icon}
                {menu.title}
              </MenuItem>
            ))}
          </MenuContainer>
        )}
        <IconContainer onClick={() => setOpen((prev) => !prev)}>
          {open ? <FabActiveIcon /> : <FabIcon />}
        </IconContainer>
      </FixedContainer>
    </>
  );
};

const FixedContainer = styled.div`
  position: fixed;
  right: 0;
  bottom: 88px;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  flex-direction: column;
  z-index: 3;
`;

const IconContainer = styled.a`
  margin: 0 32px 16px 0;
`;

const Mask = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 2;
`;

const MenuContainer = styled.a`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  padding: 16px 24px;
  align-items: flex-start;
  border-radius: 15px;
  background: var(--warning-color-warning-10, #fffdfa);
  margin-bottom: 48px;
  margin-right: 32px;
`;

const MenuItem = styled.div`
  display: flex;
  align-items: center;
  color: var(--neutral-color-natural-70, var(--Default-Gray-90, #262626));
  width: 100%;
  gap: 14px;
  padding: 16px 0;
  :not(:first-child) {
    border-top: 1px solid var(--neutral-color-natural-30, #d9d9d9);
  }
  :first-child {
    padding-top: 0;
  }
  :last-child {
    padding-bottom: 0;
  }
  ${FontStyleMap["KR/medium/$KR.BL.MD.16"]}
`;

export default LightBallMenu;
