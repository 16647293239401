import { React, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router';
import style from '../../../asset/style'
import Basic from '../../../common/frame/Basic'
import aquery from '../../../asset/Img/aquery.png'
import answer from '../../../asset/Img/answer.png'
import Register_Intro_style from './Register_Intro_Style';
import Register_One_style from './Register_One_style';
import RegiForm from './RegiForm';
import { UiInput_title } from '../../../uiComponents/UiInput/UiInput';
import UiBtn from '../../../uiComponents/UiBtn/UiBtn';
import apiFn from '../../../asset/apiClass';

const Register_Two = ({
    regiData,
    setRegiData    
}) => {

    let params = useParams();
    let navigate = useNavigate();

    const [isTeacher ,setIsTeacher] = useState(false);
    const [isChk , setIsChk] = useState(false);

    const [pwChk,setPwChk] = useState("")

    const apiPro_chk = async () => {
        try {
            if(regiData["pw"] != pwChk){
                alert("입력하신 비밀번호가 다릅니다. 다시 확인해주세요.")
                return;
            }
            let isOk = await apiFn.fnDupID({
                state : {
                    id : regiData["id"]
                }
            })

            // // console.log("## ",isOk)
            if(isOk){
                navigate("/regiThree/"+params["who"])
            }
        }
        catch(e){
        }
    }

    useEffect(()=>{
        if(params["who"] == "teacher"){
            setIsTeacher(true)
        }
        else {
            setIsTeacher(false)
        }
    },[params])


    useEffect(()=>{
        if(regiData["id"] && regiData["pw"] ){
            setIsChk(true)
        }
        else {
            setIsChk(false)
        }
    },[regiData])

    useEffect(()=>{
        setRegiData({});
    },[])
    
    return (
        <Basic>
            <RegiForm 
                num={2}
                isTeacher={isTeacher} 
                title="계정정보 입력" 
            />
            <div style={{
                marginTop : "20px",
            }}>
                <UiInput_title
                    type='basic'
                    title="아이디"
                    placeholder="영문 또는 숫자 6~20자리를 입력해주세요."
                    inputClass={isTeacher ? "input_t" : "input_s"}
                    fnChange={(e)=>{
                        // setId(e.target.value)
                        setRegiData({
                            ...regiData,
                            id : e.target.value
                        })
                    }}
                />
                <UiInput_title
                    type='basic'
                    title="비밀번호"
                    placeholder="6자리 이상의 영어 및 특수문자를 입력해주세요."
                    isPw={true}
                    inputClass={isTeacher ? "input_t" : "input_s"}
                    fnChange={(e)=>{
                        // setPw(e.target.value)
                        setRegiData({
                            ...regiData,
                            pw : e.target.value
                        })
                    }}
                />
                <UiInput_title
                    type='basic'
                    title="비밀번호 재입력"
                    placeholder="비밀번호를 다시 한번 입력해주세요."
                    isPw={true}
                    inputClass={isTeacher ? "input_t" : "input_s"}
                    fnChange={(e)=>{
                        setPwChk(e.target.value)
                    }}
                />
                {/* <UiInput_title
                    type='basic'
                    title={
                    <div>
                        비밀번호 찾기 질문
                        <div style={{
                            display : "flex",
                            width : "100%",
                            justifyContent:"center",
                            textDecoration:'underline',
                            padding : "5px 0px 10px 0px"
                        }}>
                        "가장 기억에 남는 장소는?"
                        </div>
                    </div>
                    }
                    placeholder="답변을 입력해주세요."
                    // isPw={true}
                    inputClass={isTeacher ? "input_t" : "input_s"}
                    fnChange={(e)=>{
                        setRegiData({
                            ...regiData,
                            pw_ans : e.target.value
                        })
                    }}
                /> */}
            </div>
            <div style={Register_One_style.b_c}>
                <UiBtn
                    btnStyle={{
                        ...Register_One_style.b,
                        borderColor : 
                        isChk ? 
                        ( isTeacher ? style.common.t_color : style.common.s_color )
                        :  
                        style.common.borderColor
                        ,
                        backgroundColor : 
                        isChk ? 
                        ( isTeacher ? style.common.t_color : style.common.s_color )
                        :  
                        "rgb(200,200,200)"

                    }}
                    title="다음"
                    fnClick={()=>{
                        if(isChk){
                            apiPro_chk();
                        }
                    }}
                />
            </div>
        </Basic>
    )
}

export default Register_Two