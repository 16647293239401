import { React, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router'
import style from '../../asset/style'
import DaumPostcode from 'react-daum-postcode'
import Basic from '../../common/frame/Basic'
import UiBtn from '../../uiComponents/UiBtn/UiBtn'
import UiConfirm from '../../uiComponents/UiConfirm/UiConfirm'
import { UiInput_title } from '../../uiComponents/UiInput/UiInput'
import UiLectureTime from '../../uiComponents/UiLectureTime/UiLectureTime'
import UiLectureTime_Style from '../../uiComponents/UiLectureTime/UiLectureTime_Style'
import Lecture_modi_Style from './Lecture_modi_Style'
import Profile_lecture_regi_Style from './Profile_lecture_regi_Style'
import constants from '../../asset/constants'
import apiFn from '../../asset/apiClass'
import util from '../../asset/util'

const Lecture_modi = ({
    userInfo
}) => {
    
    useEffect(()=>{
        // util.objLog(userInfo)
    },[userInfo])

    let navigate = useNavigate();
    let params = useParams();

    const [isTeacher ,setIsTeacher] = useState(true);

    const [lectureType,setLectureType] = useState({});

    const [lectureDay,setLectureDay] = useState({
        "월" : {
            slc : "N",
            startDay : "",
            endDay : "",
        },
        "화" : {
            slc : "N",
            startDay : "",
            endDay : "",
        },
        "수" : {
            slc : "N",
            startDay : "",
            endDay : "",
        },
        "목" : {
            slc : "N",
            startDay : "",
            endDay : "",
        },
        "금" : {
            slc : "N",
            startDay : "",
            endDay : "",
        },
        "토" : {
            slc : "N",
            startDay : "",
            endDay : "",
        },
        "일" : {
            slc : "N",
            startDay : "",
            endDay : "",
        },
    })

    const [delTimeId,setDelTimeId] = useState([])

    var [lectureData,setLectureData] = useState({})

    const apiPro = async () => {
        try {
            await apiPro_regi();

            let isOk = await apiPro_regi_time();

            if(isOk){
            }
                // lecture/detail/teacher/280
                navigate("/lecture/detail/"+params["who"]+"/"+params["id"]+"/de")
                // navigate("/profile/"+params["who"])
        }
        catch(e){
        }
    }

    // 강의 정보변경에서 "실강","인강" 글씨 자체가 회색바탕에 흰색이라 잘 보이지 않음. 둘다가 선택되어지고, 변경처리 했을때 변경도 되지 않음

    const apiPro_regi = async () => {
        try {
            let obj = {
                id : params["id"]
            }
            delete lectureData["mid"]
            delete lectureData["cid"]
            delete lectureData["push"]
            delete lectureData["tcnt"]
            delete lectureData["tseq"]
            delete lectureData["cnt"]
            delete lectureData["qnaCount"]


        //     setLectureType({
        //         r : "Y"
        //     })
        // }
        // else if(data["list"][0]["stype"] == 0){
        //     setLectureType({
        //         i : "Y"
        //     })

        // // console.log(lectureType)
            let lectureTypeObj = {}
            if(lectureType["i"] == "Y"){
                lectureTypeObj["stype"] = 0
            }
            if(lectureType["r"] == "Y"){
                lectureTypeObj["stype"] = 1
            }

            let isOk = await apiFn.comApi({
                state : {
                    table : "class",
                    set : [
                        {
                            key : `id:${params["id"]}`,
                            value : {
                                ...lectureData,
                                ...lectureTypeObj,
                                type : 1,
                            }
                        }
                    ]
                }
            })

            // console.log("isOK => ",isOk);
            
        }
        catch(e){
            // console.log(e)
            util.c_err_log(`apiPro_regi Error => ${e}`)
            return false
        }
    }

    const apiPro_del = async (id) => {
        try {
            await apiFn.comApi({
                state : {
                    table : "time",
                    del : [{ id : id }]
                }
            })
        }
        catch(e){
        }
    }

    const apiPro_class_del = async (type) => {
        try {
            // let isOk = await apiFn.comApi({
            //     state : {
            //         table : "class",
            //         set : [{
            //             key : `id:${params["id"]}`,
            //             value : { status : type },
            //         }]
            //     }
            // })

            let isOk = await apiFn.deleteClass({
                state : {
                    cid : params["id"],
                    orderMid : userInfo["id"]
                }
            })
            navigate(`/academy/${params['who']}/class`)
            // navigate("/profile/teacher/"+lectureData["pid"])
        }
        catch(e){
        }
    }

    const apiPro_regi_time = async (mid) => {
        try {
            let array = []  
            Object.keys(lectureDay).map((v,i)=>{
                let obj = {}
                if(lectureDay[v]["slc"] == "Y"){
                    // console.log("A => ",lectureDay[v]);
                    obj["start_day"] = v
                    obj["end_day"] = v
                    obj["start_time"] = lectureDay[v]["startDay"] ? lectureDay[v]["startDay"] : "00:00"
                    obj["end_time"] = lectureDay[v]["endDay"]
                    obj["reg_date"] = 0
                    obj["mid"] = params["id"]
                    
                    array.push(obj)
                }
            })

            let promiseArray = delTimeId.map((v)=>apiPro_del(v))
            await Promise.all([
                ...promiseArray
            ])

            let isOk = await apiFn.comApi({
                state : {
                    table : "time",
                    ins : [
                        ...array
                    ]

                }
            })

            if(isOk["id"]){
                return true
            }
        }
        catch(e){
            // console.log("ASDF ",e)
        }
    }

    const apiPro_read_time = async (d) => {
        try {
            let time = await apiFn.comApi({
                state : {
                    table : "time",
                    page : 1,
                    amount : 9999,
                    sort : "start_day desc",
                    where:{
                        'HIQDB_time.mid':`='${d["cid"]}'`
                    },

                }
            })
            // util.objLog(time["list"][0])
            let time_text = ""
            let time_obj = {}
            time["list"].map((v,i)=>{
                delTimeId.push(v["id"])
                // // console.log(v["start_time"])
                // time_obj[v["start_time"]] = {
                //     ...time_obj[v["start_time"]],
                //     [v["start_day"]] : v["end_time"]
                // }
                time_obj[[v["start_day"]]] = {
                    ...time_obj[[v["start_day"]]],
                    slc : "Y",
                    startDay : v["start_time"],
                    endDay : v["end_time"]
                }
            })
            setLectureDay({
                ...lectureDay,
                ...time_obj
            })
        }
        catch(e){
            util.c_err_log(`apiPro Read Error => ${e}`);
        }
    }

     // 교재 정보
     const apiPro_read = async () => {
        try {
            let data = await apiFn.comApi({
                state : {
                    table : "member_class",
                    page : 1,
                    amount : 9999,
                    sort : `reg_date desc`,
                    ref : [
                        'HIQDB_class.name|HIQDB_class.id=HIQDB_member_class.cid',
                        'HIQDB_class.intro|HIQDB_class.id=HIQDB_member_class.cid',
                        'HIQDB_class.address|HIQDB_class.id=HIQDB_member_class.cid',
                        'HIQDB_class.tel|HIQDB_class.id=HIQDB_member_class.cid',
                        'HIQDB_class.email|HIQDB_class.id=HIQDB_member_class.cid',
                        'HIQDB_class.owner|HIQDB_class.id=HIQDB_member_class.cid',
                        'HIQDB_class.pid|HIQDB_class.id=HIQDB_member_class.cid',
                        'HIQDB_class.stype|HIQDB_class.id=HIQDB_member_class.cid',
                    ],
                    where : {
                        'HIQDB_member_class.cid':`='${params["id"]}'`,   
                        // 'HIQDB_member_class.mid':`='${userInfo["id"]}'`,   
                        'HIQDB_class.type':`='1'`,   
                    }

                }
            })
            if(data["list"].length == 0){
                alert("잘못된 접근 방식입니다.")
                navigate(`/academy/${params['who']}/class`)

            }
            else {
                setLectureData({
                    ...data["list"][0]
                })
                if(data["list"][0]["stype"] == 1){
                    setLectureType({
                        r : "Y"
                    })
                }
                else if(data["list"][0]["stype"] == 0){
                    setLectureType({
                        i : "Y"
                    })
                }
                await apiPro_read_time(data["list"][0]);
            }

        }
        catch(e){
        }
    }

    useEffect(()=>{
        if(params["who"] == "teacher"){
            setIsTeacher(true)
        }
        else {
            setIsTeacher(false)
        }
    },[params])

    useEffect(()=>{
        apiPro_read();
    },[])

    useEffect(()=>{
        // util.objLog(lectureData)
    },[lectureData])
    

    useEffect(()=>{
        // console.log(lectureDay)
    },[lectureDay])

    useEffect(()=>{
        // // console.log(lectureType)
        // // console.log(lectureType)
        // // console.log(lectureType)
        // if(lectureType["i"] == "Y"){
        //     setLectureType({
        //         ...lectureType,
        //         r : "N"
        //     })
        // }
        // else if(lectureType["r"] == "Y"){
        //     setLectureType({
        //         ...lectureType,
        //         i : "N"
        //     })
        // }
        // if(lectureType["stype"] == 1 || l_regiData["stype"] == 0 ){
        //     if(l_regiData["name"]){
        //         setIsChk(true)
        //     }
        //     else {
        //         setIsChk(false)
        //     }
        // }
        // else {
        //     setIsChk(false)
        // }
    },[lectureType])

    return (
        <>
        {
        params["form"] == "de" ? 
        <Basic
            title="정보변경"
        >
        <>
         {/* <div style={{...Lecture_modi_Style.top_textBtn_w,
            top : 11,
        }}
            onClick={()=>{
                let isOk = window.confirm(`[${lectureData["name"]}] 강의`+ constants.confirm.cf_6)
                if(isOk){
                    // navigate("/profile/"+params["who"])
                    // navigate("/lecture/detail/"+params["who"]+"/"+params["id"])
                    apiPro_class_del(4);
                }
                
            }}
        >
            강의 폐강
        </div> */}
         <div style={{
             ...Lecture_modi_Style.top_textBtn_w,
             top : 11,
            right : 10,
        }}
            onClick={()=>{
                let isOk = window.confirm(`[${lectureData["name"]}] 강의`+constants.confirm.cf_9)
                if(isOk){
                    // navigate("/lecturemodi/"+params["who"]+"/"+params["id"]+"/confirm")
                    apiPro_class_del(9);
                }
                
            }}
        >
            강의 삭제
        </div>
        <UiInput_title 
            inputClass={isTeacher ? "input_t" : "input_s"}
            title="강의 이름"
            placeholder="강의 이름을 등록해주세요."
            type="basic"
            inputValue={lectureData["name"]}
            fnChange={(e)=>{
                setLectureData({
                    ...lectureData,
                    name : e.target.value
                })
            }}
        />
        <div style={{...Profile_lecture_regi_Style.lectureType_c,marginTop:'10px'}}>
            강의 타입
            <div style={Profile_lecture_regi_Style.lectureType_w}>
                <div style={{
                    ...Profile_lecture_regi_Style.lectureType_btn,
                    backgroundColor : 
                        lectureType["r"] != "Y" ?
                        style.common.borderColor
                        :
                        (isTeacher ? style.common.t_color : style.common.s_color)
                    ,
                }}
                    onClick={()=>{
                        if(lectureType["r"] == "Y"){
                            
                            setLectureType({
                                ...lectureType,
                                r : "N"
                            })
                        }
                        else {
                            let obj = {
                                
                            }
                            if(lectureType["i"] == "Y"){
                                obj["i"] == "N"
                            }
                            setLectureType({
                                // ...lectureType,
                                ...obj,
                                r : "Y"
                            })
                        }
                    }}
                >
                    실강
                </div>
                <div style={{
                    ...Profile_lecture_regi_Style.lectureType_btn,
                    backgroundColor : 
                        lectureType["i"] != "Y" ?
                        style.common.borderColor
                        :
                        (isTeacher ? style.common.t_color : style.common.s_color)
                    ,
                }}
                    onClick={()=>{
                        if(lectureType["i"] == "Y"){
                            setLectureType({
                                ...lectureType,
                                i : "N"
                            })
                        }
                        else {
                            let obj = {
                                
                            }
                            if(lectureType["r"] == "Y"){
                                obj["r"] == "N"
                            }
                            setLectureType({
                                // ...lectureType,
                                ...obj,
                                i : "Y"
                            })
                        }
                    }}
                >
                    인강
                </div>
            </div>
        </div>
        <UiInput_title 
            inputClass={isTeacher ? "input_t" : "input_s"}
            title="강의 장소"
            placeholder="강의 장소를 입력해주세요."
            type="basic"
            inputValue={lectureData["address"]}
            fnChange={(e)=>{
                setLectureData({
                    ...lectureData,
                    address : e.target.value
                })
            }}
        />
        <UiInput_title 
            inputClass={isTeacher ? "input_t" : "input_s"}
            title="강의를 소개하는글"
            placeholder=""
            dsecStyle={{
                height : "70px"
            }}
            type="desc"
            maxLength={60}
            inputValue={lectureData["intro"]}
            fnChange={(e)=>{
                // console.log(e.target.value)
                // console.log(e.target.value.length)
                if(e.target.value.length <= 60){
                    setLectureData({
                        ...lectureData,
                        intro : e.target.value
                    })
                }
            }}
        />
        <div style={{
            marginTop : "20px",
            fontSize : "14px",
            marginBottom : "5px",
        }}>
            강의 시간
            <div style={{
                display:"flex",
                justifyContent:"space-between",
                margin : "15px 0px 30px 0px",
            }}>
                {Object.keys(lectureDay).map((v,i)=>
                <div style={{
                    ...UiLectureTime_Style.round_w,
                    marginRight:0,
                    backgroundColor : lectureDay[v]["slc"] == "Y" ? style.common.t_color : style.common.borderColor
                }}
                    onClick={()=>{
                        if(lectureDay[v]["slc"] == "N"){
                            setLectureDay({
                                ...lectureDay,
                                [v] : {
                                    ...lectureDay[v],
                                    slc : "Y",
                                    startDay : "",
                                    endDay : "",
                                }
                            })
                        }
                        else {
                            setLectureDay({
                                ...lectureDay,
                                [v] : {
                                    ...lectureDay[v],
                                    slc : "N",
                                    startDay : "",
                                    endDay : "",
                                }
                            })
                        }
                    }}
                >
                    {v}
                </div>
                )}
            </div>
            {
            Object.keys(lectureDay).map((v,i)=>{
                if(lectureDay[v]["slc"] == "Y"){
                    return (
                        <UiLectureTime
                            startTime={lectureDay[v]["startDay"]}
                            endTime={lectureDay[v]["endDay"]}
                            day={v}
                            isTeacher={isTeacher}
                            fnChange={(d)=>{
                                setLectureDay({
                                    ...lectureDay,
                                    [v] : {
                                        ...lectureDay[v],
                                        "startDay" : d,
                                    }
                                })
                            }}
                            fnChange2={(d)=>{
                                setLectureDay({
                                    ...lectureDay,
                                    [v] : {
                                        ...lectureDay[v],
                                        "endDay" : d,
                                    }
                                })
                            }}
                        />
                    )
                }
            })}
        </div>
        <UiBtn 
            title={"확인"}
            btnStyle={{
                marginTop : "30px",
                backgroundColor : style.common.t_color,
                color : "white"
            }}
            fnClick={()=>{
                // navigate("/lecturemodi/"+params["who"]+"/"+params["id"]+"/confirm")
                apiPro();
            }}
        />
        </>

        </Basic>
        :
        params["form"] == "confirm" &&
        <Basic
            title="확인"
        >
            <UiConfirm
                isTeacher={isTeacher}
                // navi={"/lecture/detail/"+params["who"]+"/"+params["id"]+"/de"}
                fnClick={()=>{
                    apiPro();
                }}
            />
        </Basic>

        }
        </>
        
    )
}

export default Lecture_modi