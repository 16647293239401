import { ClassRegistRequset } from "api/classRegist";
import ErrorMessage from "components/atoms/ErrorMessage";
import Section from "components/atoms/Section";
import React, { useEffect } from "react";
import { Controller, useFormContext } from "react-hook-form";
import styled from "styled-components";
import UiTime from "uiComponents/UiTime/UiTime";
interface IVideoTimeAreaProps {
  durationTime: number;
}
const VideoTimeArea = ({ durationTime }: IVideoTimeAreaProps) => {
  const {
    watch,
    control,
    formState: { errors },
    unregister,
  } = useFormContext<ClassRegistRequset>();
  useEffect(
    () => () => {
      unregister(["video_start", "video_end"]);
    },
    []
  );
  return (
    <>
      <Section justify="space-between">
        <TimeContainer>
          시작시간
          <Section align="center" gap={8}>
            <Controller
              control={control}
              name="video_start"
              rules={{
                validate: (value) => {
                  const endTimeValue = watch("video_end");
                  return value <= endTimeValue || "시작시간은 종료시간 이전이어야 합니다.";
                },
              }}
              render={({ field }) => {
                const timeString = `${parseInt(
                  `${field.value / 60 || 0}`
                )}:${parseInt(`${field.value % 60 || 0}`)}`;
                return (
                  <UiTime
                    timeStyle={"time"}
                    initValue={0}
                    timeData={timeString}
                    isMin={true}
                    inputClass={"input_t"}
                    fnChange={(timeString) => {
                      // timeString  00:00 형식 초로변환
                      const second = timeString.split(":").reduce((acc, cur, i) => {
                        return acc + Number(cur) * Math.pow(60, 1 - i);
                      }, 0);
                      field.onChange(second);
                    }}
                  />
                );
              }}
            ></Controller>
          </Section>
        </TimeContainer>
        <TimeContainer>
          종료시간
          <Section align="center" gap={8}>
            <Controller
              control={control}
              name="video_end"
              rules={{
                validate: (value) => {
                  return value <= durationTime || "종료시간은 영상시간보다 이전이어야 합니다.";
                },
              }}
              render={({ field }) => {
                const timeString = `${parseInt(
                  `${field.value / 60 || 0}`
                )}:${parseInt(`${field.value % 60 || 0}`)}`;

                return (
                  <UiTime
                    timeStyle={"time"}
                    initValue={0}
                    timeData={timeString}
                    isMin={true}
                    inputClass={"input_t"}
                    fnChange={(timeString) => {
                      // timeString  00:00 형식 초로변환
                      const second = timeString.split(":").reduce((acc, cur, i) => {
                        return acc + Number(cur) * Math.pow(60, 1 - i);
                      }, 0);

                      field.onChange(second);
                    }}
                  />
                );
              }}
            ></Controller>
          </Section>
        </TimeContainer>
      </Section>
      <ErrorMessage>{errors.video_start?.message || errors.video_end?.message}</ErrorMessage>
    </>
  );
};

const TimeContainer = styled.div`
  margin-top: 16px;
  color: #292929;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding: 0 20px;
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export default VideoTimeArea;
