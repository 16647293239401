import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import * as React from "react";

export default function BasicSelect({
  fnChange = (string) => {},
  c_style,
  fontSize = "10px",
  title,
  data = [], // [{ name : "" , value : ""}, ... ]
  defaultValue,
}) {
  const [age, setAge] = React.useState(defaultValue);

  const handleChange = (event: SelectChangeEvent) => {
    setAge(event.target.value as string);
    fnChange(event.target.value as string);
  };

  React.useEffect(() => {
    console.log("defaultValue : ", defaultValue);
  }, [defaultValue]);

  return (
    <FormControl sx={{ minWidth: 120 }} style={c_style} size="small">
      <InputLabel
        style={{
          fontSize: "12px",
        }}
        id="demo-select-small"
        htmlFor="uncontrolled-native"
        // defaultValue={defaultValue}
      >
        {title}
      </InputLabel>
      <Select
        defaultValue={defaultValue}
        labelId="demo-select-small"
        id="demo-select-small"
        value={age}
        label="Age"
        onChange={handleChange}
        style={{
          fontSize: "12px",
        }}
      >
        {data.map((v) => (
          <MenuItem
            key={v.value}
            style={{
              // fontSize : fontSize,
              fontSize: "12px",
            }}
            value={v["value"]}
          >
            {v["name"]}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
    // <FormControl
    // sx={{ minWidth: 120 }}
    //     // style={c_style}
    //     size="small"
    // >
    //     <InputLabel
    //         id="demo-select-small"
    //         style={{
    //             // top:-12,
    //         }}
    //     >
    //             {title}
    //     </InputLabel>
    //     <Select
    //         style={{
    //             fontSize : fontSize,
    //             maxWidth:"120px",
    //             // height:"30px",
    //         }}
    //         labelId="demo-select-small"
    //         id="demo-select-small"
    //         value={age}
    // onChange={handleChange}
    //         label="Aege"
    //         >
    //             {
    //             data.map((v,i)=>
    //             <MenuItem
    //                 style={{
    //                     fontSize : fontSize,
    //                 }}
    //                 value={v["value"]}>
    //                     {v["name"]}
    //             </MenuItem>
    //             )}
    //       {/* <MenuItem value={10}>Ten</MenuItem>
    //       <MenuItem value={20}>Twenty</MenuItem>
    //       <MenuItem value={30}>Thirty</MenuItem> */}
    //     </Select>
    //   </FormControl>
  );
}
