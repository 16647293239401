import { ArrowBackIos, FileUploadOutlined } from "@mui/icons-material";
import { ClassRegistRequset } from "api/classRegist";
import Header from "components/molecules/Header";
import React from "react";
import { useFormContext } from "react-hook-form";
import { useNavigate } from "react-router";
import styled from "styled-components";
interface ILectureRegistHeaderProps {
  title: string;
}
const LectureRegistHeader = ({ title }: ILectureRegistHeaderProps) => {
  const {
    trigger,
    formState: { isValid, errors, isValidating },
  } = useFormContext<ClassRegistRequset & { videoValid: boolean }>();
  const navigate = useNavigate();
  return (
    <Header
      title={title}
      leftIcon={<ArrowBackIos />}
      onLeftClick={() => navigate(-1)}
      rightIcon={
        <>
          {isValid ? (
            <FillIconContainer type={"submit"}>
              <FileUploadOutlined />
            </FillIconContainer>
          ) : (
            <IconContainer type={"submit"}>
              <FileUploadOutlined />
            </IconContainer>
          )}
        </>
      }
    />
  );
};

const IconContainer = styled.button`
  outline: none;
  background-color: transparent;
  border: 0;
  width: 39px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  border: 1px solid #ffa723;
  background-color: #fff;
  > svg {
    color: #ffa723;
    font-size: 16px;
  }
`;

const FillIconContainer = styled(IconContainer)`
  background-color: #ffa723;
  > svg {
    color: #fff;
  }
`;
export default LectureRegistHeader;
