import { LectureVideoListFilterRequset } from "api/classList";
import { useClassList } from "api/classRegist";
import { getUser } from "asset/storage";
import Input from "components/atoms/Input";
import Section from "components/atoms/Section";
import Selectbox from "components/atoms/Selectbox";
import React, { useMemo } from "react";
import { useFormContext } from "react-hook-form";
import { useNavigate } from "react-router";
import styled from "styled-components";

const SearchFilter = () => {
  const navigate = useNavigate();
  const methods = useFormContext<LectureVideoListFilterRequset>();
  const {
    register,
    control,
    formState: { errors },
  } = methods;
  const userInfo = getUser();
  const { data } = useClassList(userInfo.id);
  const selectOptions = useMemo(
    () =>
      data?.map((item) => ({
        name: item.class_name,
        value: item.cid,
      })),
    [data]
  );

  return (
    <Container>
      <Selectbox label={"강의"} options={selectOptions} {...register("cid")} />
      <Section gap={14} width={"100%"}>
        <Section flex={3}>
          <Selectbox
            label={"정렬"}
            defaultValue={"recents"}
            options={[
              { name: "최신순", value: "recents" },
              { name: "조회수 순", value: "views" },
            ]}
            {...register("order")}
          />
        </Section>
        <Section flex={7}>
          <Input label="선생님 이름/제목" {...register("keyword")} />
        </Section>
      </Section>
    </Container>
  );
};

const Container = styled.div`
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  gap: 14px;
  margin-top: 20px;
`;

export default SearchFilter;
