import { useClassDetailInfo, useDefaultBook } from "api/classDetail";
import apiFn from "asset/apiClass";
import { getUser } from "asset/storage";
import store from "asset/store";
import util from "asset/util";
import ImageCropModal from "components/templates/ImageCropModal";
import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import ReactPlayer from "react-player";
import { useNavigate, useParams } from "react-router";
import UploadQuestionModal from "uiComponents/UiReactPlayer/UploadQuestionModal";
import { VideoJsPlayer } from "video.js";
import { ClassContext } from "..";
import { useResolveQuestion } from "../../../../api/question";

const QuestionRegistModal = () => {
  const navigate = useNavigate();
  const { playerRef, setShowUploadPictureModal, showUploadPictureModal } = useContext(ClassContext);
  const [fileObj, setFileObj] = useState<Record<string, any>>({});
  const [qnaData, setQnaData] = useState<{ video_start?: number }>({});
  const [isOpenCropModal, setIsOpenCropModal] = useState(false);

  const params = useParams();
  const userInfo = getUser();
  const parameters = {
    boardId: params["id"],
  };

  const { data } = useClassDetailInfo(parameters);

  const videoData = useMemo(() => data?.getBoard?.boards[0], [data]);
  const { data: defaultBook } = useDefaultBook(videoData?.cid);
  const isLink = useMemo(() => !!videoData.video_link, [videoData.video_link]);

  const movePage = useCallback((qid, studentId, teacherId) => {
    const confirmMessage =
      "등록이 완료되었습니다.\n해당 페이지로 이동하시겠습니까? \n취소 시 현재 화면에 남아 질문을 더 등록 할 수 있습니다.";
    if (confirm(confirmMessage)) {
      navigate(`/talk/${params.who}/0/${qid}/${studentId}/${teacherId}`);
    }
  }, []);

  const handleSubmitQuestion = async (file) => {
    const writerId = userInfo.id;
    let teacherId = null,
      studentId = null;
    const videoUrl = videoData?.video_link ?? `${videoData?.id}.mp4`;

    if (userInfo.type === 2 && userInfo.id === videoData.mid) {
      teacherId = userInfo.id;

      let filepath = `qna/temp-${teacherId}-${new Date().getMilliseconds()}.jpg`;
      const img = await apiFn.uploadFile(filepath, util.dataURLtoFile(file.base64));
      const { qid } = await apiFn.onlineQnaSet({
        state: {
          title: videoData.title + "." + qnaData?.video_start,
          tid: teacherId,
          bid: defaultBook?.bid,
          qna_date: "y",
          ans_date: "y",
          mobum_date: "y",
          new: "y",
          mobum: "y",
          img,
        },
      });
      await store.putBucket(util.dataURLtoFile(file.base64), `qna/${qid}.jpg`, false, false);

      await apiFn.onlineQnaMsgSet({
        state: {
          qid: qid,
          wtype: 2,
          wid: teacherId,
          content: videoUrl,
          video_start: Number(qnaData?.video_start?.toFixed(0)),
          ctype: 12,
        },
      });
      await Promise.all([
        useResolveQuestion(qid, userInfo.id),
        apiFn.onlineQnaMsgSet({
          state: {
            qid: qid,
            wtype: 2,
            wid: teacherId,
            content: "답변이 완료되었습니다.",
            ctype: 10,
          },
        }),
      ]);
      movePage(qid, studentId, teacherId);
    } else if (userInfo.type === 1) {
    } else {
      alert("유효하지 않은 사용자입니다");
      return;
    }
  };

  useEffect(() => {
    let checkpoint = null;

    if (!isLink) {
      const player = playerRef.current as VideoJsPlayer;
      player?.pause();
      checkpoint = player?.currentTime();
    } else {
      const player = playerRef.current as ReactPlayer;
      checkpoint = player?.getCurrentTime();
    }

    setQnaData({ video_start: checkpoint });
  }, [playerRef.current, showUploadPictureModal]);

  return (
    <>
      {showUploadPictureModal && (
        <UploadQuestionModal
          fileObj={fileObj}
          onChange={(e) => {
            setFileObj({
              name: ``,
              file: e,
            });
            // navigate(`/crop/teacher/set`);
            setIsOpenCropModal(true);
          }}
          onSubmit={async (fileObj) => {
            try {
              await handleSubmitQuestion(fileObj);
              setFileObj({});
              setShowUploadPictureModal(false);
            } catch (e) {
              console.error(e);
              alert("문제 등록을 실패했습니다");
            } finally {
            }
          }}
          onCancel={() => {
            setShowUploadPictureModal(false);
            setFileObj({});
          }}
        />
      )}
      {isOpenCropModal && (
        <ImageCropModal
          open={isOpenCropModal}
          onClose={() => setIsOpenCropModal(false)}
          fileObj={fileObj}
          setFileObj={setFileObj}
        />
      )}
    </>
  );
};

export default QuestionRegistModal;
