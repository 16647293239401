import style from "../../asset/style"

const Profile_class_Style = {
    c : {
        display:"flex",
        flexDirection : "column",
        flex:1,
        // paddingTop : "10px",
        marginTop : "10px",
        padding : "0px 10px",
        // backgroundColor : style.common.borderColor2,
        // width : "100%",
        // height : "50px",
    },
    gradeBox_c : {
        display:"flex",
        alignItems : "center",
        width : "100%",
        marginBottom:"5px",
        // height : "60px",
    },
    gradeBox_w : {
        display:"flex",
        justifyContent:"space-between",
        backgroundColor:"#FAFAFA",
        padding : "0px 10px",
        alignItems:"center",
        color : "black",
        width : "100%",
        height : "55px",
        borderRadius : 5,
    },
    gradebox_v : {
        display : "flex",
        alignItems : "center",
        fontSize : "11px",
        color : "black",
    },
    gradeBox_i : {
        width : "40px",
        height : "40px",
        objectFit:'cover',
        objectPosition : "center",
        // width : "60px",
    },
    gradebox_text_w : {
        display:"flex",
        fontSize : "13px",
        // marginLeft : "5px"
    },
    gradebox_text : {
        fontWeight : "bold",
        margin : "0px 3px"
    },
    gradebox_text_btn : {
        fontWeight : "bold",
        marginTop : "7px",
        fontSize : "13px",
        color : style.common.fontColor_3,
    },
    w : {
        display : "flex",
        flexDirection : 'column',
        padding : "0px 10px",
        // marginTop : "5px",
        borderTop : "1px solid",
        borderColor : style.common.borderColor4,
        marginTop:"5px",
        backgroundColor : "white",
    },
    middle_btn_w : {
        display:"flex",
        justifyContent:"space-around",
        // fontSize : "14px",
        padding : "15px 0px",
        borderTop : '1px solid',
        borderColor : style.common.borderColor4
    },
    middle_btn : {
        fontSize : "12px",
        color : style.common.fontColor_5
    }
}

export default Profile_class_Style