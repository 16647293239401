import { Check, Clear } from "@material-ui/icons";
import { React, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { useLongPress } from "use-long-press";
import constants from "../../asset/constants";
import face_student from "../../asset/Img/defaultpic_student.png";
import face_teacher from "../../asset/Img/defaultpic_teacher.png";
import style from "../../asset/style";
import util from "../../asset/util";
import { UiCheckBox } from "../UiCheckBox/UiCheckBox";
import UiMessage_Style from "../UiMessage/UiMessage_Style";
import UiTextBtn from "../UiTextBtn/UiTextBtn";
import UiPeople_Style from "./UiPeople_Style";

const UiPeople = ({
  // isManager,
  isStat,
  scrollKey,
  setScrolllKey,
  setHiddenNav,
  myInfo = {},
  isPeople = false,
  userInfo,
  // oriData,
  data = [],
  selectData,
  isOnly = false,
  isEdit = false,
  isTeacher = false,
  fnClick,
  fnLongClick,
  isBlank = true,
  fnCheck,
  isOpenOk,
  isManager = false,
  isTeacherPage = false,
  manageSub = false,
  form = 1,
  isInfo = false,
  isExpel = false,
  isSearch = false,
  isBookManager = false,
  fnApprove,
  fnReject,
  fnExit,
  fnUp,
  fnDown,
  fnBookUp,
  fnExpel,
  fnQna,
  fnTalk,
  fnQan_see,
}) => {
  let navigate = useNavigate();
  let params = useParams();

  // useEffect(()=>{
  //     // console.log("@@ ",data);
  // },[data])

  return (
    <div
      style={{
        width: "100%",
        // display:"flex",
        // flexDirection:"column",
        // flex:1,
      }}
    >
      {
        // data.length > 0 ?
        data.map((v, i) => (
          <UiPeopleSection
            isStat={isStat}
            scrollKey={scrollKey}
            setScrolllKey={setScrolllKey}
            setHiddenNav={setHiddenNav}
            fnQna={fnQna}
            fnQan_see={fnQan_see}
            fnBookUp={fnBookUp}
            fnExpel={fnExpel}
            isBookManager={isBookManager}
            userInfo={userInfo}
            fnDown={fnDown}
            fnExit={fnExit}
            fnUp={fnUp}
            isSearch={isSearch}
            isOnly={isOnly}
            // oriData={oriData}
            // isManager={isManager}
            data={v}
            selectData={selectData}
            fnCheck={fnCheck}
            id={v["id"]}
            isEdit={isEdit}
            isTeacher={isTeacher}
            fnClick={fnClick}
            fnLongClick={fnLongClick}
            isOpenOk={isOpenOk}
            isManager={isManager}
            isTeacherPage={isTeacherPage}
            manageSub={manageSub}
            isPeople={isPeople}
            form={form}
            isInfo={isInfo}
            isExpel={isExpel}
            fnApprove={fnApprove}
            fnReject={fnReject}
            fnTalk={fnTalk}
          />
        ))
        // :
        // isBlank &&
        // <div style={{
        //     display:"flex",
        //     flexDirection : "column",
        //     fontWeight : "bold",
        //     alignItems:'center',
        //     justifyContent:"center",
        //     width : "100%",
        //     height : "calc(65vh + 20px)",
        //     fontSize : "14px",
        //     color : style.common.fontColor_1
        // }}>
        //     {
        //     isTeacher ?
        //     <>
        //     <div>
        //         등록된 학생이 없습니다.
        //     </div>
        //     <div>
        //         수업을 개설하고, 학생들을 초대해보세요.
        //     </div>
        //     <UiTextBtn
        //         btnStyle={{
        //             display : "flex",
        //             alignItems : "center",
        //             marginTop : "20px",
        //             color : style.common.t_color
        //         }}
        //         text={"새 수업 생성하기"}
        //         fnClick={()=>{
        //             if(params["who"] == "teacher"){
        //                 if(myInfo["hakwonList"].length == 0){
        //                     alert("가입하신 클래스룸이 없습니다.")
        //                 }
        //                 else {
        //                     // console.log(myInfo["hakwonList"])
        //                     let p_array = []
        //                     myInfo["hakwonList"].map((v,i)=>{
        //                         if(v["type"] == 5){
        //                             p_array.push(v)
        //                         }
        //                     })
        //                     // console.log("p_array => ",p_array)
        //                     if(p_array.length > 0){
        //                         navigate(`/lectureregi/teacher/${p_array[0]["id"]}`)
        //                     }
        //                     else {
        //                         alert("가입하신 클래스룸에 대한 수업 개설 권한이 없습니다.")
        //                     }
        //                 }
        //                 // /lectureregi/teacher/316
        //             }
        //             // navigate(`/academyexist/${params["who"]}`)
        //         }}
        //     />
        //     </>
        //     :
        //     <>
        //     <div>
        //         등록된 선생님이 없습니다.
        //     </div>
        //     <div>
        //         수업에 등록하고 선생님에게 모르는 문제를 물어보세요.
        //     </div>
        //     {
        //     myInfo["hakwonList"] &&
        //     <UiTextBtn
        //         btnStyle={{
        //             display : "flex",
        //             alignItems : "center",
        //             marginTop : "20px",
        //         }}
        //         text={"수업 등록하기"}
        //         fnClick={()=>{
        //             // navigate("/academyexist/student")
        //             navigate(`/lectureregi/${params["who"]}/${myInfo["hakwonList"][0]["id"]}`)
        //         }}
        //     />
        //     }
        //     </>
        //     }
        // </div>
      }
    </div>
  );
};

const UiPeopleSection = ({
  // isManager,
  isStat,
  scrollKey,
  setScrolllKey,
  setHiddenNav,
  isPeople,
  isBookManager,
  userInfo = {},
  // oriData,
  data,
  id,
  selectData,
  isEdit,
  isOnly,
  isTeacher,
  isManager,
  isTeacherPage,
  manageSub,
  fnClick,
  fnLongClick,
  fnCheck,
  isOpenOk = true,
  form,
  isInfo = false,
  isExpel,
  isSearch,
  fnApprove,
  fnReject,
  fnExit,
  fnUp,
  fnDown,
  fnBookUp,
  fnExpel,
  fnQna,
  fnTalk,
  fnQan_see,
}) => {
  let navigate = useNavigate();
  let params = useParams();

  const [isOpen, setIsOpen] = useState(false);

  const bind = useLongPress(
    () => {
      try {
        if (isTeacher) {
          fnLongClick(id);
        }
      } catch (e) {
        // console.log("Long Press Error => ",e)
      }
    },
    {
      threshold: 1000,
    }
  );

  useEffect(() => {
    // console.log("UiPeopleSection  =>",data)
    // console.log("UiPeopleSection type =>",data["type"])
    // console.log("UiPeopleSection status =>",data["status"])
  }, [data]);

  // useEffect(()=>{
  //     // console.log("isOnly => " ,isOnly)
  // },[isOnly])

  var scrollRef = useRef(null);

  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollIntoView({
        // behavior: "smooth",
        block: "center",
      });
      // scrollKey = ""
      if (setScrolllKey) {
        setScrolllKey("");
      }
    }
  }, [scrollRef]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        flex: 1,
        maxWidth: style.common.maxWidth,
      }}
      ref={scrollKey == data["id"] ? scrollRef : null}
    >
      <div
        style={{
          ...UiPeople_Style.section_c,
          width: "100%",
        }}
      >
        {isEdit && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginRight: "10px",
            }}
          >
            <UiCheckBox
              className={isTeacher ? "t" : "s"}
              // value={"?"}
              // className={"message_?"}
              checked={selectData[id] == "Y" ? true : false}
              value={id}
              fnClick={fnCheck}
              containerStyle={{
                padding: "0px",
              }}
            />
          </div>
        )}
        <div
          style={{
            // width : '20%',
            marginRight: "10px",
          }}
          onClick={() => {
            if (setScrolllKey) {
              setScrolllKey(data["id"]);
            }
            if (setHiddenNav) {
              setHiddenNav();
            }
            // return;
            navigate(`/myinfo/${params["who"]}/${data["id"]}`);
          }}
        >
          <div style={UiPeople_Style.section_i_w}>
            <img
              style={UiMessage_Style.section_i}
              onError={(e) => {
                // setIsLoad(false)
                e.currentTarget.src = data?.user_type === 2 ? face_teacher : face_student;
              }}
              src={
                data
                  ? data["img"]
                    ? // constants.apiUrl
                      constants.s3_url +
                      // 0729 img cache 관련 코드
                      `${data?.user_type === 2 ? "/teacher/" : "/student/"}${data["img"]}` +
                      // `${(data?.user_type === 2 ) ? "/teacher/" : "/student/"}${data["img"].normalize('NFD')}`
                      ".jpg?"
                    : // +
                      // util.getRandomInt(1000,9999)
                      constants.apiUrl +
                      `${data?.user_type === 2 ? "/teacher/" : "/student/"}${data["id"].normalize("NFD")}` +
                      ".jpg?" +
                      util.getRandomInt(1000, 9999)
                  : data?.user_type === 2
                    ? face_teacher
                    : face_student
              }
            />
          </div>
        </div>
        <div
          style={UiPeople_Style.section_r_c}
          {...bind()}
          onClick={() => {
            if (userInfo["id"] == data["id"]) return;
            if (isOnly) {
              fnClick(data);
            } else {
              // if(userInfo["id"] == data["name"])return;
              // if(!isOpenOk)return;
              // if(!isManager)return;
              // if(!fnClick)return;
              if (!isEdit) {
                setIsOpen(!isOpen);
              }
            }
          }}
        >
          {form == 1 ? (
            <div style={UiPeople_Style.section_r_t_w}>
              <div style={UiPeople_Style.section_r_t_t_w}>
                {data["type"] == 5 && (
                  <div
                    style={{
                      color: style.common.t_color,
                      fontWeight: "bold",
                      marginRight: "3px",
                    }}
                  >
                    [관리자]
                  </div>
                )}
                <div style={UiPeople_Style.section_r_t_t_text_2}>
                  {/* 김학생  */}
                  {data?.user_type === 2
                    ? `${data["name"]} 선생님`
                    : params["who"] == "student"
                      ? `${data["nick"]}`
                      : `${data["name"]} (${data["nick"]})`}
                  {/* {data["name"]}{( data?.user_type === 2 ) && " 선생님"} */}
                </div>

                {data["stype"] == 0 && data["school"] && <div>({data["school"]})</div>}
              </div>
            </div>
          ) : (
            <div style={UiPeople_Style.section_r_t_w}>
              <div style={UiPeople_Style.section_r_t_t_w}>
                {data["type"] == 5 && (
                  <div
                    style={{
                      color: style.common.t_color,
                      fontWeight: "bold",
                      marginRight: "3px",
                    }}
                  >
                    [관리자]
                  </div>
                )}
                {
                  // isTeacher ?
                  data?.user_type === 2 ? (
                    <div>{`${data["name"]} 선생님`}</div>
                  ) : (
                    <>
                      <div style={UiPeople_Style.section_r_t_t_text_2}>
                        {/* 학생(선생님)닉네임  */}
                        {params["who"] == "student"
                          ? `${data["nick"]}`
                          : `${data["name"]} (${data["nick"]})`}
                      </div>
                      {isInfo && (
                        <div>
                          {/* (학생이름(선생님)) */}
                          {`( ${data["name"]} )`}
                        </div>
                      )}
                    </>
                  )
                }
              </div>
            </div>
          )}
          <div style={UiPeople_Style.section_r_m_w}>
            <div style={UiPeople_Style.section_r_m_l_w}>
              <div>
                {/* {
                                ( data?.user_type === 2 ) ?
                                (
                                form == 1 ?
                                // "시대인(강남),수학만점왕 외  2개 강의"
                                    ( data?.user_type === 2 ) &&
                                        (
                                            (data["classList"] && data["classList"].length > 0) &&
                                                (
                                                    data["classList"].reduce((a,v,i)=>{
                                                        if(i == 0){
                                                            a = v["name"]
                                                        }
                                                        else if(i < 2){
                                                            a = a + " , " + v["name"]
                                                        }
                                                        // // console.log("###" ,a);
                                                        return a
                                                    },"")
                                                    +
                                                    ( data["classList"].length > 2 ? "외 " +(data["classList"].length - 2) +"개 강의"  : "")
                                                )
                                        )
                                :
                                form == 2 &&
                                (
                                    isSearch &&
                                        data["classList"] &&
                                            (
                                                data["classList"].length == 0 ?
                                                "(소속없음)"
                                                :
                                                (
                                                    data["classList"].reduce((a,v,i)=>{
                                                        if(i == 0){
                                                            return a = a + v["name"]
                                                        }
                                                        else if(i < 2){
                                                            return a = a + " , " + v["name"]
                                                        }
                                                        else{
                                                            return a
                                                        }
                                                    },"")
                                                    // "자기소개 공간입니다."
                                                    +
                                                    ( data["classList"].length > 2 ? "외 " +(data["classList"].length - 2) +"개 클래스룸"  : "")
                                                )
                                            )
                                )
                                )
                                :
                                data["intro"]
                                } */}
                {/* 0906 project 4번 task에 의해 선생/학생 intro 정보 노출 */}
                {data["intro"]}
              </div>
            </div>
          </div>
          <div style={UiPeople_Style.section_r_b_w}>
            {isInfo
              ? // "010-1234-5678 | ZEP@ccc.com"
                // `${data["tel"]} | ${data["email"]}`
                `${data["tel"]}`
              : form == 1
                ? // "총 질문수 1,234개"
                  `총 질문수 ${data["qtotal"]}개`
                : isInfo &&
                  // "010-1234-5678 | ZEP@ccc.com"
                  // `${data["tel"]} ${data["email"] ? " | "+ data["email"] : ""}`
                  // 0113 전화번호 주석
                  // `${data["tel"]}`
                  ``}
          </div>
        </div>

        {isStat && params["who"] == "student" && (
          <div
            style={{
              // width : '20%',
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              marginRight: "10px",
              // backgroundColor:"orange",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {data["qnacnt"] && (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "18px",
                    height: "18px",
                    alignItems: "center",
                    justifyContent: "center",
                    borderRadius: 50,
                    backgroundColor:
                      data["qnacnt"]["total"] - data["qnacnt"]["answer"] <= 10
                        ? style.common.t_color
                        : data["qnacnt"]["total"] - data["qnacnt"]["answer"] <= 50
                          ? style.common.s_color
                          : style.common.fontColor_3,
                  }}
                ></div>
              )}
            </div>
          </div>
        )}
      </div>
      {isOpen &&
        (isTeacher ? (
          <div style={UiPeople_Style.openAdd_c}>
            {!isBookManager ? (
              isTeacherPage ? (
                data["type"] == 5 ? (
                  <div
                    style={{
                      ...UiPeople_Style.openAdd_btn_w,
                      justifyContent: "center",
                    }}
                  >
                    <UiTextBtn
                      text={"관리자에서 해제"}
                      isUnderline={false}
                      btnStyle={UiPeople_Style.openAdd_btn}
                      fnClick={() => {
                        fnDown(data);
                        setIsOpen(false);
                      }}
                    />
                  </div>
                ) : (
                  <div style={UiPeople_Style.openAdd_btn_w}>
                    <UiTextBtn
                      text={"탈퇴처리"}
                      isUnderline={false}
                      btnStyle={{
                        ...UiPeople_Style.openAdd_btn,
                        color: style.common.fontColor_3,
                      }}
                      fnClick={() => {
                        fnExit(data);
                        setIsOpen(false);
                      }}
                    />

                    <UiTextBtn
                      text={"관리자로 승격"}
                      isUnderline={false}
                      btnStyle={UiPeople_Style.openAdd_btn}
                      fnClick={() => {
                        fnUp(data);
                        setIsOpen(false);
                      }}
                    />
                  </div>
                )
              ) : manageSub ? (
                <div style={UiPeople_Style.openAdd_btn_w}>
                  <UiTextBtn
                    text={"탈퇴처리"}
                    isUnderline={false}
                    btnStyle={{
                      ...UiPeople_Style.openAdd_btn,
                      color: style.common.fontColor_3,
                    }}
                    fnClick={() => {
                      fnExit(data);
                      setIsOpen(false);
                    }}
                  />

                  <UiTextBtn
                    text={"질문보기"}
                    isUnderline={false}
                    btnStyle={UiPeople_Style.openAdd_btn}
                    fnClick={(e) => {
                      fnQan_see(e, data);
                    }}
                  />
                  <UiTextBtn
                    text={"대화하기"}
                    isUnderline={false}
                    btnStyle={UiPeople_Style.openAdd_btn}
                    fnClick={(e) => {
                      fnTalk(e, data);
                    }}
                  />
                </div>
              ) : isPeople ? (
                <div
                  style={{
                    width: "100%",
                  }}
                >
                  {data["type"] == 9 ? (
                    <div style={UiPeople_Style.openAdd_btn_w}>
                      <UiTextBtn
                        text={
                          <div style={UiPeople_Style.openAdd_btn_w_2_text_w}>
                            <Clear style={UiPeople_Style.openAdd_btn_w_2_text} />
                            초대 취소하기
                          </div>
                        }
                        isUnderline={false}
                        btnStyle={{
                          ...UiPeople_Style.openAdd_btn,
                          color: style.common.fontColor_3,
                        }}
                        fnClick={() => {
                          fnReject(data);
                          setIsOpen(false);
                        }}
                      />
                    </div>
                  ) : (
                    <div
                      style={{
                        display: "flex",
                        width: "100%",
                        justifyContent: "space-around",
                        padding: "10px 0px",
                      }}
                    >
                      <UiTextBtn
                        text={
                          <div style={UiPeople_Style.openAdd_btn_w_2_text_w}>
                            <Clear style={UiPeople_Style.openAdd_btn_w_2_text} />
                            거절하기
                          </div>
                        }
                        isUnderline={false}
                        btnStyle={{
                          ...UiPeople_Style.openAdd_btn,
                          color: style.common.fontColor_3,
                        }}
                        fnClick={() => {
                          fnReject(data);
                          setIsOpen(false);
                        }}
                      />
                      <UiTextBtn
                        text={
                          <div style={UiPeople_Style.openAdd_btn_w_2_text_w}>
                            <Check style={UiPeople_Style.openAdd_btn_w_2_text} />
                            승인하기
                          </div>
                        }
                        isUnderline={false}
                        btnStyle={UiPeople_Style.openAdd_btn}
                        fnClick={() => {
                          fnApprove(data);
                          setIsOpen(false);
                        }}
                      />
                    </div>
                  )}
                </div>
              ) : (
                <div style={UiPeople_Style.openAdd_btn_w_2}>
                  <UiTextBtn
                    text={
                      <div
                        style={{
                          ...UiPeople_Style.openAdd_btn_w_2_text_w,
                        }}
                      >
                        질문보기
                      </div>
                    }
                    isUnderline={false}
                    btnStyle={UiPeople_Style.openAdd_btn}
                    fnClick={(e) => {
                      fnQan_see(e, data);
                    }}
                  />
                  <UiTextBtn
                    text={
                      <div
                        style={{
                          ...UiPeople_Style.openAdd_btn_w_2_text_w,
                        }}
                      >
                        대화하기
                      </div>
                    }
                    isUnderline={false}
                    btnStyle={UiPeople_Style.openAdd_btn}
                    fnClick={(e) => {
                      fnTalk(e, data);
                    }}
                  />
                </div>
              )
            ) : (
              isExpel && (
                <div style={UiPeople_Style.openAdd_btn_w}>
                  {data["type"] == 9 ? (
                    <UiTextBtn
                      text={"초대 취소하기"}
                      isUnderline={false}
                      btnStyle={{
                        ...UiPeople_Style.openAdd_btn,
                        color: style.common.fontColor_3,
                      }}
                      fnClick={() => {
                        fnExpel(data);
                        setIsOpen(false);
                      }}
                    />
                  ) : data["type"] == 6 && data["status"] == 0 ? (
                    <div
                      style={{
                        display: "flex",
                        width: "100%",
                        justifyContent: "space-around",
                        padding: "10px 0px",
                      }}
                    >
                      <UiTextBtn
                        text={
                          <div style={UiPeople_Style.openAdd_btn_w_2_text_w}>
                            <Clear style={UiPeople_Style.openAdd_btn_w_2_text} />
                            거절하기
                          </div>
                        }
                        isUnderline={false}
                        btnStyle={{
                          ...UiPeople_Style.openAdd_btn,
                          color: style.common.fontColor_3,
                        }}
                        fnClick={() => {
                          fnReject(data);
                          setIsOpen(false);
                        }}
                      />
                      <UiTextBtn
                        text={
                          <div style={UiPeople_Style.openAdd_btn_w_2_text_w}>
                            <Check style={UiPeople_Style.openAdd_btn_w_2_text} />
                            승인하기
                          </div>
                        }
                        isUnderline={false}
                        btnStyle={UiPeople_Style.openAdd_btn}
                        fnClick={() => {
                          fnApprove(data);
                          setIsOpen(false);
                        }}
                      />
                    </div>
                  ) : (
                    <>
                      <UiTextBtn
                        text={"내보내기"}
                        isUnderline={false}
                        btnStyle={{
                          ...UiPeople_Style.openAdd_btn,
                          color: style.common.fontColor_3,
                        }}
                        fnClick={() => {
                          fnExpel(data);
                          setIsOpen(false);
                        }}
                      />
                      <>
                        {data["type"] == 5 && (
                          <UiTextBtn
                            text={"관리자에서 해제"}
                            isUnderline={false}
                            btnStyle={UiPeople_Style.openAdd_btn}
                            fnClick={() => {
                              fnDown(data);
                              setIsOpen(false);
                            }}
                          />
                        )}
                      </>
                      <>
                        {data["type"] != 5 && (
                          <UiTextBtn
                            text={"관리자로 승격"}
                            isUnderline={false}
                            btnStyle={UiPeople_Style.openAdd_btn}
                            fnClick={() => {
                              fnBookUp(data);
                              setIsOpen(false);
                            }}
                          />
                        )}
                      </>
                    </>
                  )}
                </div>
              )
            )}
          </div>
        ) : (
          <div style={UiPeople_Style.openAdd_c}>
            <div style={UiPeople_Style.openAdd_btn_w}>
              <UiTextBtn
                text={"질문 보기"}
                isUnderline={false}
                btnStyle={{
                  ...UiPeople_Style.openAdd_btn,
                  // backgroundColor : "red",
                }}
                fnClick={(e) => {
                  fnQan_see(e, data);
                }}
              />

              {!isTeacher && (
                <UiTextBtn
                  isFile={true}
                  accept={"image/*"}
                  text={"질문 하기"}
                  isUnderline={false}
                  btnStyle={{
                    ...UiPeople_Style.openAdd_btn,
                    // backgroundColor : "orange",
                  }}
                  fnClick={(e) => {
                    fnQna(e, data);
                  }}
                />
              )}
              <UiTextBtn
                text={"대화하기"}
                isUnderline={false}
                btnStyle={{
                  ...UiPeople_Style.openAdd_btn,
                  // backgroundColor : "purple",
                }}
                fnClick={(e) => {
                  fnTalk(e, data);
                }}
              />
            </div>
          </div>
        ))}
    </div>
  );
};

export default UiPeople;
