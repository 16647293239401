import { useApolloClient } from "@apollo/client";
import { useChangeStudyBookType, useStudyBookInfo } from "api/studyBook";
import { ReactComponent as ArrowDown } from "asset/images/icon/arrow-down.svg";
import { ReactComponent as WrittenIcon } from "asset/images/icon/book-type-written.svg";
import { ReactComponent as GearIcon } from "asset/images/icon/gear.svg";
import { ReactComponent as StudyIcon } from "asset/images/icon/studybook.svg";
// import { ReactComponent as TalkIcon } from "asset/images/icon/talk.svg";
import CommonModal from "components/atoms/CommonModal";
import { FontStyleMap } from "components/atoms/Font";
import ReactiveButtonContainer from "components/atoms/ReactiveButtonContainer";
import { StyledCommonConfirmContainer } from "components/templates/Confirm";
import { showToast } from "providers/ToastProvider";
import React, { ReactNode, useCallback, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router";
import styled, { css } from "styled-components";
import { MenuItemContainer } from "..";

type StudyBookTypeItem = {
  icon: ReactNode;
  title: string;
  value: number;
};

const StudyBookType = () => {
  const { bookId } = useParams();
  const { data } = useStudyBookInfo(bookId);
  const [open, setOpen] = useState(false);

  const apolloClient = useApolloClient();
  useEffect(() => {
    if (data && open) {
      setSelectedStudyBookType(
        studyBookTypeList.find((item) => item.value === data.getBook.book.book_type) ||
          studyBookTypeList[0]
      );
    }
  }, [data, open]);
  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  const studyBookTypeList = [
    // {
    //   icon: <TalkIcon />,
    //   title: "질문교재",
    //   value: 0,
    // },
    {
      icon: <WrittenIcon />,
      title: "필기교재",
      value: 2,
    },
    // {
    //   icon: <StudyIcon />,
    //   title: "기본교재",
    // },
    {
      icon: <WrittenIcon />,
      title: "PDF교재",
      value: 1,
    },
  ];
  const [selectedStudyBookType, setSelectedStudyBookType] = useState<StudyBookTypeItem>(
    studyBookTypeList[0]
  );

  const [isOpenSelect, setIsOpenSelect] = useState(false);

  const LeftTypeList = useMemo(
    () => studyBookTypeList.filter((item) => item.value !== selectedStudyBookType.value),
    [studyBookTypeList]
  );

  const handleSelectStudyBookType = useCallback(
    (item: StudyBookTypeItem) => {
      setSelectedStudyBookType(item);
      setIsOpenSelect(false);
    },
    [setSelectedStudyBookType]
  );
  const { mutate } = useChangeStudyBookType();

  const handleSubmit = useCallback(async () => {
    try {
      await mutate({ bookType: selectedStudyBookType.value, bookId });
    } catch (e) {
      showToast({
        message: "교재타입 변경에 실패했습니다.",
        type: "error",
        title: "변경 실패",
      });
    }

    handleClose();
  }, [apolloClient, bookId, selectedStudyBookType.value, handleClose]);

  return (
    <>
      <MenuItemContainer
        onClick={() => {
          setOpen(true);
        }}
      >
        교재 유형
        <GearIcon />
      </MenuItemContainer>
      <CommonModal open={open} onClose={handleClose}>
        <StyledCommonConfirmContainer>
          <FontContainer>
            <StudyIcon width={22} height={22} />
            <TitleFont>교재 유형</TitleFont>
            <SelectListContainer>
              {
                <SelectListItemContaner onClick={() => setIsOpenSelect((prev) => !prev)}>
                  <StudyBookTypeContainer>
                    {selectedStudyBookType.icon} {selectedStudyBookType.title}
                  </StudyBookTypeContainer>
                  <ArrowIconContainer isOpen={isOpenSelect}>
                    <ArrowDown />
                  </ArrowIconContainer>
                </SelectListItemContaner>
              }
              {isOpenSelect &&
                LeftTypeList.map((item, index) => {
                  return (
                    <SelectListItemContaner onClick={() => handleSelectStudyBookType(item)}>
                      <StudyBookTypeContainer>
                        {item.icon} {item.title}
                      </StudyBookTypeContainer>
                    </SelectListItemContaner>
                  );
                })}
            </SelectListContainer>
          </FontContainer>
          <ReactiveButtonContainer
            buttonList={[
              {
                onClick: handleClose,
                variant: "text",
                title: "취소",
              },
              {
                onClick: handleSubmit,
                variant: "contained",
                title: "확인",
              },
            ]}
          />
        </StyledCommonConfirmContainer>
      </CommonModal>
    </>
  );
};

const FontContainer = styled.div`
  gap: 16px;
  display: flex;
  flex-direction: column;
  margin-bottom: 42px;
`;
const TitleFont = styled.div`
  color: var(--neutral-color-natural-80, #141414);
  ${FontStyleMap["KR/bold/$KR.TS.BOL.20"]}
`;

const SelectListContainer = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  border: 1.3px solid var(--neutral-color-natural-30, #d9d9d9);
  background: var(--background-paper-elevation-0, #fff);
`;

const SelectListItemContaner = styled.div`
  border-top: 1px solid var(--neutral-color-natural-30, #d9d9d9);
  justify-content: space-between;
  display: flex;
  align-items: center;
  padding: 11px 16px;
  :first-child {
    padding: 13px 16px;
    border-top: none;
  }
`;

const StudyBookTypeContainer = styled.div`
  display: flex;
  gap: 4px;
  align-items: center;
  > svg {
    width: 16px;
    height: 16px;
  }
`;

const ArrowIconContainer = styled.div<{ isOpen: boolean }>`
  ${({ isOpen }) =>
    isOpen &&
    css`
      transform: rotate(180deg);
    `}
`;
export default StudyBookType;
