import { useClassDetailInfo } from "api/classDetail";
import { ClassRegistRequset } from "api/classRegist";
import constants from "asset/constants";
import util from "asset/util";
import React, { useMemo } from "react";
import { useFormContext } from "react-hook-form";
import { useParams } from "react-router";
import styled, { css } from "styled-components";

const ThumbnailSelect = () => {
  const params = useParams();
  const { data } = useClassDetailInfo({ boardId: params.boardId });
  const thumbnailList = useMemo(
    () =>
      data?.getBoard?.boards[0].thumbnails?.filter(
        (item) => !!item.timestamp
      ) ?? [],
    [data]
  );
  const { watch, setValue } = useFormContext<ClassRegistRequset>();
  const thumbnailId = watch("thumbnail_id");
  return (
    <Container>
      <TitleContainer isSelected={!!thumbnailId}>
        대표썸네일 {!thumbnailId ? "지정" : ""}
      </TitleContainer>
      <ScrollContainer>
        {thumbnailList?.map((item) => (
          <ThumbnailItemContainer
            isSelected={thumbnailId === item.id}
            onClick={() => setValue("thumbnail_id", item.id)}
          >
            <img src={`${constants.apiUrl}/${item.path}`} />
            <ThumbnailTimeStamp>
              {util.converToSecond(item.timestamp)}
            </ThumbnailTimeStamp>
          </ThumbnailItemContainer>
        ))}
      </ScrollContainer>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 15px 0;
`;

const TitleContainer = styled.div<{ isSelected: boolean }>`
  display: flex;
  width: 100%;
  padding: 8px 0;
  justify-content: center;
  align-items: center;
  background: #ffa723;
  color: #fff;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 100%; /* 12px */
  ${({ isSelected }) =>
    isSelected &&
    css`
      background: #d3d3d3;
      color: #555;
    `}
`;

const ScrollContainer = styled.div`
  display: flex;
  width: 100%;
  overflow-x: scroll;
  overflow-y: hidden;
  scroll-snap-type: x mandatory;
  ::-webkit-scrollbar {
    height: 8px;
    width: 100%;
  }
  ::-webkit-scrollbar-track {
    height: 8px;
    background: rgba(41, 41, 41, 0.3);
    border-radius: 4px;
  }
  ::-webkit-scrollbar-thumb {
    height: 8px;
    background: #f7f7f7;
    border-radius: 4px;
  }
`;

const ThumbnailItemContainer = styled.div<{ isSelected: boolean }>`
  ${({ isSelected }) =>
    isSelected &&
    css`
      outline: 2px solid #ffa723;
      scroll-snap-align: start;
      outline-offset: -2px;
    `}
  box-sizing: border-box;
  cursor: pointer;
  min-width: 110px;
  height: 70px;
  position: relative;
  scroll-snap-align: start;
  > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const ThumbnailTimeStamp = styled.div`
  display: flex;
  padding: 2px;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%; /* 12px */
  background: rgba(41, 41, 41, 0.8);
  position: absolute;
  bottom: 0;
  right: 0;
`;
export default ThumbnailSelect;
