


import style from "../../asset/style"

const Column_Pick_classify_Style = {
    t_c : {
        display: "flex",
        width: "100%",
        padding: "12px 0px 15px 0px",
        justifyContent: "center",
    },
    t_c_add : {
        justifyContent: "space-between",
        alignItems : "center",
        fontSize : "12px",
    },
    edit_section_w : {
        // ...Academy_book_Style.t_c,
        // ...Academy_book_Style.t_c_add,
        display: "flex",
        width: "100%",
        padding: "7px 0px 15px 0px",
        // justifyContent: "center",
        justifyContent: "space-between",
        alignItems : "center",
        fontSize : style.common.select_fontSize,
    },
    edit_w : {
        display:"flex",
        alignItems:"center",
        position : 'absolute',
        height:"50px",
        right : 10,
        top : 3,
        fontSize : style.common.select_fontSize,
        color  : style.common.t_color,
        fontWeight : "bold"
    },
    textBtn_w : {
        display: "flex",
        justifyContent: "space-between",
        // backgroundColor : "red",
        // paddingRight : "10px",
    },
    textBtn : {
        color: style.common.fontColor_3,
        fontWeight:"bold",
        fontSize : style.common.select_fontSize,
    },
    btn_w : {
        display: "flex",
        justifyContent: "space-between",
    },
    btn : {
        color: style.common.fontColor_3,
        fontSize: "12px",
    },

}

export default Column_Pick_classify_Style