import { React , useState , useEffect } from 'react'
import { useParams , useNavigate } from 'react-router'
import style from '../../asset/style'
import Basic from '../../common/frame/Basic'
import RegiForm from '../before/register/RegiForm'
import aquery from '../../asset/Img/aquery.png'
import answer from '../../asset/Img/answer.png'
import { UiInput_title } from '../../uiComponents/UiInput/UiInput'
import { Register_One } from '../page_index'
import Register_One_style from '../before/register/Register_One_style'
import UiBtn from '../../uiComponents/UiBtn/UiBtn'
import DaumPostcode from 'react-daum-postcode'
import { UiSearchList } from '../../uiComponents/UiSearchList/UiSearchList'
import photopick_logo from '../../asset/Img/photopick_logo.png'
import util from '../../asset/util'
import apiFn from '../../asset/apiClass'
import store from '../../asset/store'

const Academy_regi_three = ({
    userInfo,
    c_regiData,
    setC_regiData,
    setFileObj,
    fileObj,
}) => {
    let params = useParams();
    let navigate = useNavigate();

    var [img,setImg] = useState("")

    const [isTeacher ,setIsTeacher] = useState(false);
    const [isChk , setIsChk] = useState(false);


    const apiPro_regi = async () => {
        try {
            const isOk = await apiFn.createClass({
                state : {
                    ...c_regiData,
                    mid : userInfo["id"]
                    // table : "class",
                    // ins : [
                    //     {
                    //         status : 1,
                    //         reg_date : 0,
                    //     }
                    // ]
                }
            })
            // console.log("@@@ ",isOk);
            await store.putBucket(fileObj["base64"],`board/${isOk["id"]}.jpg`, false, false)
            window.alert("클래스룸이 등록되었습니다.")
            setFileObj(fileObj = {})
            if(isOk){
                navigate("/academy/teacher/academy")
            }
        }
        catch(e){
            util.c_err_log(`apiPro_regi => ${e}`)
        }
    }


    const apiPro_img = async (e) => {
        try {
            setFileObj({
                file : e
            })
            navigate("/crop/teacher/set")
        }
        catch(e){
            // console.log("apiPro_img Error= >",e)
        }
    }


    useEffect(()=>{
        if(params["who"] == "teacher"){
            setIsTeacher(true)
        }
        else {
            setIsTeacher(false)
        }
    },[params])

    useEffect(()=>{
        // // console.log("@@ ",fileObj);
        if(fileObj["base64"]){
            setImg(fileObj["base64"])
        }
    },[])

    return (
        <Basic title={"새 클래스룸 생성"}>
            <RegiForm
                num={3}
                isTeacher={isTeacher}
                title="프로필 입력"
                addTitle="(선택)"
                isTopTitle={false}
                dotCnt = {3}
            />
            <div style={{
                margin : "20px 0px",
                display : 'flex',
                flexDirection : "column",
                fontSize : "14px",
            }}>
                클래스룸 로고 등록
                {/* <img src={}/> */}
                <div style={{
                    display:"flex",
                    alignItems:"center",
                    justifyContent:"center",
                    width : "100%",
                    height: "100%",
                }}>
                    <div style={{
                        width : '100px',
                        height : "100px",
                    }}>
                        <input
                            id="local_file"
                            accept='image/*'
                            type="file"
                            style={{ display : "none" }}
                            onChange={(e)=>{
                                util.fileOnchange(e,(event,r)=>{
                                    img = r
                                    apiPro_img(e);
                                })
                            }}
                        />
                         <label
                            htmlFor='local_file'
                        >
                            <img
                                style={{
                                    height : "100%"
                                }}
                                src={
                                    img ?
                                    img
                                    :
                                    photopick_logo
                                }
                            />
                        </label>

                    </div>

                </div>

            </div>
            <UiInput_title
                title="클래스룸을 소개하는 글"
                type="desc"
                placeholder="입력해주세요."
                maxLength={30}
                dsecStyle={{
                    minHeight : "80px",
                }}
                inputValue={c_regiData["intro"]}
                fnChange={(e)=>{
                    setC_regiData({
                        ...c_regiData,
                        intro : e.target.value
                    })
                }}
            />

            <div style={Register_One_style.b_c}>
                <UiBtn
                    btnStyle={{
                        ...Register_One_style.b,
                        borderColor :
                        ( isTeacher ? style.common.t_color : style.common.s_color )
                        ,
                        backgroundColor :
                        ( isTeacher ? style.common.t_color : style.common.s_color )
                    }}
                    title="등록 완료"
                    fnClick={()=>{
                        apiPro_regi();
                    }}
                />
            </div>
        </Basic>
    )
}

export default Academy_regi_three
