import { ArrowDropDown, ArrowDropUp } from "@material-ui/icons";
import { PaymentContext } from "asset/context";
import { React, useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import apiFn from "../../asset/apiClass";
import constants from "../../asset/constants";
import store from "../../asset/store";
import util from "../../asset/util";
import App from "../../uiComponents/UiCrop/App";
import UiLoading from "../../uiComponents/UiLoading/UiLoading";
import UiReview from "../../uiComponents/UiReview/UiReview";
import UiTopPopTextBtn from "../../uiComponents/UiTopPopTextBtn/UiTopPopTextBtn";
import { ReplySection } from "../Reply/Reply";
import { Frame } from "../page_index";
import Modal_modi from "./Modal_modi";
import MyInfo_Style from "./MyInfo_Style";
import Myinfo_Basis_student from "./Myinfo_Basis_student";
import Myinfo_Basis_teacher from "./Myinfo_Basis_teacher";
import Myinfo_momo from "./Myinfo_momo";
import MyInfoTicket from "./Myinfo_ticket";
import { useUserAllReceivedQuestionList } from "../../api/question";

const MyInfo_Profile = ({
  userInfo,
  setFileObj,
  fileObj,
  hiddenUrl,
  hiddenNav,
  setHiddenNav,
  setHiddenUrl,
  setQnaData,
  isPremium,
}) => {
  let params = useParams();
  let navigate = useNavigate();

  let myInfo_init = {
    name: "",
    tel: "",
    email: "",
    collage: "",
    qtotal: "",
    anscount: 0,
    avgTime: "",
    intro: "",
    nick: "",
    content: "",
    img: 0,
  };

  const sort = [
    { name: "날짜순", value: "reg_date desc" },
    { name: "평점순", value: "rating desc" },
    { name: "출처순", value: "nick asc" },
  ];

  const { paymentOpen } = useContext(PaymentContext);

  const [isSort, setIsSort] = useState(false);

  const [isLoading, setIsLoading] = useState(true);
  const [isLoad, setIsLoad] = useState(false);
  const [isTeacher, setIsTeacher] = useState(true);

  const [r_class, setR_class] = useState(false);
  const [r_lecture, setR_lecture] = useState(false);

  const [tap, setTap] = useState("basic");
  const [myInfo, setMyInfo] = useState(myInfo_init);

  const [lectureData, setLectureData] = useState([]);
  const [commentData, setCommentData] = useState([]);
  const [gradeData, setGardeData] = useState({
    list: [],
  });

  const [isOpen, setIsOpen] = useState(true);

  const [isModi, setIsModi] = useState(false);

  // 댓글 삭제
  const apiPro_del = async (id) => {
    try {
      let is = window.confirm("해당 댓글을 삭제하시겠어요?");
      if (!is) return;
      let isOk = await apiFn.comApi({
        state: {
          table: "board_reply",
          del: [
            {
              "HIQDB_board_reply.id": id,
            },
          ],
        },
      });
      await apiPro_read_4();
    } catch (e) {}
  };

  const apiPro_recent_regi = async (isRecent) => {
    try {
      await apiFn.comApi({
        state: {
          table: "teacher",
          set: [
            {
              key: `id:${userInfo["id"]}`,
              value: { update_date: isRecent },
            },
          ],
        },
      });
    } catch (e) {}
  };
  const apiPro_read = async (isMe, isRecent) => {
    try {
      let api_id = params["id"];
      if (isMe) {
        api_id = userInfo["id"];
      }
      let s_state = {
        table: "user_credential",
        page: 1,
        amount: 9999,
        sort: "reg_date desc",
        ref: ["A.*|HIQDB_user A on A.index=HIQDB_user_credential.user_id"],
        where: {
          "HIQDB_user_credential.name": `= '${api_id}'`,
        },
      };

      let data = await apiFn.comApi({
        state: {
          ...s_state,
          // key : `id:${params["id"]}`
        },
      });

      // // console.log("1 chk data => ",data["list"])
      // let set = []
      if (isRecent) {
        // await apiPro_recent_regi(isRecent)
        let isOk = await apiFn.comApi({
          state: {
            table: "user_credential",
            set: [
              {
                key: `name:${userInfo["id"]}`,
                value: { update_date: 0 },
              },
            ],
          },
        });
        console.log(isOk);
      }
      if (data["list"].length == 0) {
        data = await apiFn.comApi({
          state: {
            table: "user_credential",
            page: 1,
            amount: 9999,
            sort: "reg_date desc",
            // isRecent : isRecent ? isRecent : 1,
            // isRecent : 1661955532,
            ref: ["A.*|HIQDB_user A on A.index=HIQDB_user_credential.user_id and A.user_type = 1"],
            where: {
              "HIQDB_user_credential.name": `= '${api_id}'`,
            },
            count: [
              // `HIQDB_online_qna.tid:cccc:id|and countTable.id=\'id\`| asdfasdfasd`,
              "HIQDB_online_qna.tid:tid:id|and countTable.mobum_date is not null |mobumCnt",
              "HIQDB_book_link.mid:mid:id|and countTable.type > 4 and countTable.bid != 0 and countTable.cid =0 and countTable.status !=9 |bookCnt",
            ],
            // key : `id:${params["id"]}`
            // online_qna
            // 조건 : tid = 선생님 ID, mobum_date is not null

            // book_link
            // 조건 : mid = 선생님 ID, type >4, bid !=0, cid=0
          },
        });
      }

      // // console.log("2 chk data => ",data["list"])

      return data["list"][0];
    } catch (e) {
      console.log("?? ", e);
    }
  };

  const apiPro_read_2 = async () => {
    try {
      let data = await useUserAllReceivedQuestionList(params.id);
      return data;
    } catch (e) {
      // util.c_err_log(`apiPro_read_2 Error => ${e}`)
    }
  };

  const apiPro_read_3 = async () => {
    try {
      let lectureData = await apiFn.comApi({
        state: {
          table: "class",
          page: 1,
          amount: 9999,
          u_id: userInfo["id"],
          sort: `reg_date desc`,
          ref: [
            "HIQDB_member_class.cid|HIQDB_class.id=HIQDB_member_class.cid",
            "HIQDB_member_class.mid|HIQDB_class.id=HIQDB_member_class.cid",
            `B.name as academyName|HIQDB_class B on B.id=HIQDB_class.pid`,
          ],
          where: {
            "HIQDB_member_class.mid": `= '${myInfo["id"]}'`,
            "HIQDB_class.status": `= '1'`,
            "HIQDB_member_class.status": `= '1'`,
          },
          count: [
            "HIQDB_member_class.mid:cid:id|and countTable.type ='0' and countTable.status='1'|lcnt",
          ],
        },
      });

      let myLectureList = await apiFn.comApi({
        state: {
          table: "member_class",
          page: 1,
          amount: 9999,
          sort: `reg_date desc`,
          where: {
            "HIQDB_member_class.status": `= '1'`,
            "HIQDB_member_class.mid": `= '${userInfo["id"]}'`,
          },
        },
      });

      let yourLectureList = await apiFn.comApi({
        state: {
          table: "member_class",
          page: 1,
          amount: 9999,
          sort: `reg_date desc`,
          where: {
            "HIQDB_member_class.status": `= '1'`,
            "HIQDB_member_class.mid": `= '${myInfo["id"]}'`,
          },
        },
      });

      let cidArray = [];
      let m_cidArray = [];
      let y_cidArray = [];

      myLectureList["list"].map((v, i) => {
        m_cidArray.push(v["cid"]);
      });

      yourLectureList["list"].map((v, i) => {
        y_cidArray.push(v["cid"]);
      });
      m_cidArray.map((v, i) => {
        if (y_cidArray.includes(v)) cidArray.push(v);
      });

      cidArray.filter((v, i) => cidArray.indexOf(v) === i);
      let in_id = cidArray.reduce((a, v, i) => {
        if (i == 0) {
          return (a = `'${v}'`);
        } else {
          return (a = a + `,'${v}'`);
        }
      }, "");

      let l_data = await apiFn.comApi({
        state: {
          table: "class",
          page: 1,
          amount: 9999,
          sort: "reg_date desc",
          where: {
            "HIQDB_class.id": `in (${in_id})`,
            "HIQDB_class.status": `= '1'`,
          },
          ref: [`B.name as academyName|HIQDB_class B on B.id=HIQDB_class.pid`],
          count: ["HIQDB_member_class.mid:cid:id|and countTable.type ='0'|lcnt"],
        },
      });

      // console.log("l_data : ",l_data["list"])

      setLectureData([...l_data["list"]]);
    } catch (e) {
      console.log(e);
    }
  };

  const apiPro_read_4 = async () => {
    try {
      let data = await apiFn.comApi({
        state: {
          table: "board_reply",
          page: 1,
          amount: 9999,
          sort: "reg_date desc",
          where: {
            "HIQDB_board_reply.wid": `= '${params["id"]}'`,
          },
        },
      });

      // console.log(data["list"])
      setCommentData([...data["list"]]);
    } catch (e) {}
  };

  const apiPro_read_5 = async (sort) => {
    try {
      let data = await apiFn.fnStarList({
        state: {
          cat: "hiq",
          cmd: "starList",
          page: 1,
          amount: 9999,
          sort: sort ? sort : "reg_date desc",
          sid: userInfo["id"],
        },
      });
      // console.log("data => ",data)
      setGardeData({
        ...data,
      });
    } catch (e) {
      // console.log(e)
    }
  };

  const parseId = async (data) => {
    try {
      // data.map((v,i)=>);
      return data.reduce((a, v, i) => {
        return [...a, v["id"]];
      }, []);
    } catch (e) {
      // console.log("@@ " ,e)
    }
  };

  const apiPro_ = async (isRecent) => {
    try {
      let [data1, data2, data3] = await Promise.all([
        apiPro_read(false, isRecent),
        apiPro_read_2(),
        apiPro_read(true),
      ]);
      let profile_suup = await parseId(data1["suupList"]);
      let profile_hakwon = await parseId(data1["hakwonList"]);

      let my_suup = await parseId(data3["suupList"]);
      let my_hakwon = await parseId(data3["hakwonList"]);

      let interact_suup = profile_suup.filter((x) => my_suup.includes(x));
      let interact_hakwon = profile_hakwon.filter((x) => my_hakwon.includes(x));

      if (interact_suup.length > 0) {
        if (userInfo["type"] == 1) {
          if (userInfo["id"] == data1["id"]) {
            setR_lecture(true);
          } else {
            setR_lecture(false);
          }
        } else {
          setR_lecture(true);
        }
      } else {
        setR_lecture(false);
      }
      if (interact_hakwon.length > 0) {
        if (userInfo["type"] == 1) {
          if (userInfo["id"] == data1["id"]) {
            setR_class(true);
          } else {
            setR_class(false);
          }
        } else {
          setR_class(true);
        }
      } else {
        setR_class(false);
      }

      data1["anscount"] = 0;
      if (userInfo["type"] == 2) {
        data1["anscount"] = data2["list"].length;
        let avgTime = 0;
        let avgCount = 0;
        if (data2["list"].length > 0) {
          data2["list"].reduce((a = 0, v, i) => {
            if (v["sid"]) {
              if (v["ans_date"]) {
                avgTime = avgTime + (v["ans_date"] - v["reg_date"]);
                avgCount++;
              }
            }
          });
        }

        if (avgTime != 0) {
          data1["avgTime"] = new Date(Math.floor(avgTime / avgCount) * 1000)
            .toISOString()
            .substr(11, 8);
        } else {
          data1["avgTime"] = 0;
        }
      }
      if (util.isKeyExists(data1, "stype" || params["who"] === "student")) {
        setIsTeacher(false);
      } else {
        setIsTeacher(true);
      }
      console.log("Myinfo Data1 => ", data1);
      setMyInfo({
        ...data1,
      });
      setIsLoad(false);
    } catch (e) {
      setIsLoad(false);
      // util.c_err_log(`ApiPro_ Error => ${e}`)
      // console.log(e)
    } finally {
      setIsLoading(false);
    }
  };

  const apiPro_img = async (e) => {
    try {
      setFileObj({
        name: `/${params["who"]}/${params["id"]}.jpg`,
        file: e,
      });

      navigate(`/crop/${params["who"]}/d`);
    } catch (e) {
      // console.log("apiPro_img Error= >",e)
    }
  };

  // 질문 하기
  const apiPro_img_qna = async (e, id) => {
    try {
      setFileObj({
        name: ``,
        file: e,
      });
      setQnaData({
        tid: id,
      });
      setHiddenUrl(window.location.pathname);
      navigate(`/crop/${params["who"]}/qnaRegi`);
    } catch (e) {
      console.log("apiPro_img Error= >", e);
    }
  };

  // useEffect(()=>{
  // // console.log("r_lecture => ",r_lecture)
  // // console.log("r_class => ",r_class)
  // },[r_class,r_lecture])

  // useEffect(()=>{
  // console.log("myInfo => ",myInfo);

  // if(util.isKeyExists(myInfo,"stype")){
  //     // console.log("@@@@@@@@@")
  //     setIsTeacher(false)
  // }
  // else {
  //     setIsTeacher(true)
  // }

  // },[myInfo])

  useEffect(() => {
    if (hiddenNav == "reset_myinfo") {
      setTap("basic");
      setHiddenNav("");
    }

    console.log("tap", tap);
    if (tap == "basic" || !isModi) {
      setIsLoad(true);
      apiPro_();
    }
    if (tap == "lecture") {
      // console.log("lecture !")
      apiPro_read_3();
    }
    if (tap == "grade") {
      // console.log("garde !")
      apiPro_read_5();
    }
    if (tap == "comment") {
      apiPro_read_4();
    }
  }, [tap, params, isModi]);

  useEffect(() => {
    if (hiddenNav) {
      if (hiddenNav == "profile_student") return;
      if (hiddenNav == "profile_teacher") return;
      if (hiddenNav == "lecture&") {
        setTap("lecture");
      } else {
        setTap(hiddenNav);
      }
      setHiddenNav("");
    }
  }, []);

  var myInfo_style = {
    tap: {
      fontWeight: 500,
      fontSize: "16px",
      borderBottom: "2px solid",
      padding: "5px 5px",
    },
    title: {
      fontWeight: 700,
      fontSize: "16px",
      color: "#3D3A39",
      marginTop: "5px",
    },
    desc: {
      display: "flex",
      justifyContent: "cneter",
      alignItems: "center",
      fontWeight: 400,
      fontSize: "14px",
      color: "#3D3A39",
      marginTop: "5px",
      marginBottom: "10px",
    },
    btn: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "25px",
      borderRadius: "15px",
      padding: "5px 15px",
      fontSize: "14px",
      color: "#FFFFFF",
      fontWeight: 400,
      margin: "0px 5px",
    },
    circle: {
      width: "80px",
      height: "80px",
      borderRadius: "50%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      color: "#FFFFFF",
      fontWeight: 400,
      fontSize: "14px",
    },
  };

  var [isCrop, setIsCrop] = useState(false);

  return (
    <Frame
      // title={myInfo && myInfo["name"]}
      isPremium
      type={"2"}
      title={
        myInfo &&
        (myInfo["id"] == userInfo["id"]
          ? "내 정보"
          : util.isKeyExists(myInfo, "stype")
            ? myInfo["nick"]
            : myInfo["name"])
      }
      // isBack={myInfo["id"] == userInfo["id"] ? false : true}
      isBack
      // navi={hiddenUrl}
      // isPadding={false}
      userInfo={userInfo}
      isSearch={false}
      setHiddenNav={setHiddenNav}
      // isTap={true}
    >
      {!isCrop && myInfo["id"] == userInfo["id"] && (
        <UiTopPopTextBtn
          title="로그아웃"
          fnClick={async () => {
            // // console.log("myInfo => ",myInfo);
            const userLoginData = JSON.parse(window.localStorage.getItem("user"));
            let isOk = window.confirm(constants.confirm.cf_7);
            if (isOk) {
              if (userLoginData["provider"] == "naver") {
                // store.logout_n("n");
                let aa = await apiFn.logout({
                  state: {
                    provider: "naver",
                  },
                });
                window.localStorage.removeItem("user");
                window.localStorage.removeItem("pw");
                window.localStorage.removeItem("com.naver.nid.access_token");
                window.localStorage.removeItem("com.naver.nid.oauth.state_token");
                store.set("user", {});
                navigate("/");
                // window.location.replace("/")
                // navigate('/')
              } else {
                // store.logout_k("k");
                window.localStorage.removeItem("user");
                window.localStorage.removeItem("pw");
                store.set("user", {});
                navigate("/");
                // window.location.replace("/")
              }
            }
            return;
          }}
          isTeacher={isTeacher}
          c_style={{
            top: 7,
          }}
          t_style={{
            backgroundColor: "#FBFBFB",
            color: "#B3B3B3",
            fontWeight: 400,
            fontSize: "12px",
            top: 7,
          }}
        />
      )}

      <div style={MyInfo_Style.w}>
        {
          <div
            style={{
              display: "flex",
              backgroundColor: "white",
              padding: "15px 10px 10px 10px",
              justifyContent: "center",
            }}
          >
            {myInfo["id"] && (
              <div
                style={{
                  ...myInfo_style.tap,
                  borderBottomColor: tap == "basic" ? "#F09294" : "rgba(176, 176, 176, 0.2)",
                  color: "#F09294",
                }}
                onClick={() => {
                  // navigate("/profile"+"/"+params["who"]+"/teacher")
                  setTap("basic");
                }}
              >
                기본 정보
              </div>
            )}
            {util.isKeyExists(myInfo, "stype") &&
              params["who"] == "teacher" &&
              util.isKeyExists(myInfo, "stype") &&
              myInfo["id"] != userInfo["id"] && (
                <div
                  style={{
                    ...myInfo_style.tap,
                    borderBottomColor: tap == "memo" ? "#49A5B1" : "rgba(176, 176, 176, 0.2)",
                    color: "#49A5B1",
                  }}
                  onClick={() => {
                    // navigate("/profile"+"/"+params["who"]+"/lecture")
                    setTap("memo");
                  }}
                >
                  메모장
                </div>
              )}
            {
              // (r_class || r_lecture ) &&
              myInfo["id"] &&
                (!util.isKeyExists(myInfo, "stype") || myInfo["id"] == userInfo["id"]) && (
                  <div
                    style={{
                      ...myInfo_style.tap,
                      borderBottomColor: tap == "lecture" ? "#49A5B1" : "rgba(176, 176, 176, 0.2)",
                      color: "#49A5B1",
                    }}
                    onClick={() => {
                      // navigate("/profile"+"/"+params["who"]+"/lecture")
                      setTap("lecture");
                    }}
                  >
                    공유중인 수업
                  </div>
                )
            }
            {
              // !isTeacher &&
              params["who"] == "student" && userInfo["id"] == myInfo["id"] && (
                <>
                  <div
                    style={{
                      ...myInfo_style.tap,
                      borderBottomColor: tap == "grade" ? "#487FBA" : "rgba(176, 176, 176, 0.2)",
                      color: "#487FBA",
                    }}
                    onClick={() => {
                      // navigate("/profile"+"/"+params["who"]+"/lecture")
                      setTap("grade");
                    }}
                  >
                    평점 목록
                  </div>
                  {params["who"] == "student" && (
                    <div
                      style={{
                        ...myInfo_style.tap,
                        borderBottomColor:
                          tap == "comment" ? "#F7B865" : "rgba(176, 176, 176, 0.2)",
                        color: "#F7B865",
                      }}
                      onClick={() => {
                        // navigate("/profile"+"/"+params["who"]+"/lecture")
                        setTap("comment");
                      }}
                    >
                      댓글 목록
                    </div>
                  )}
                </>
              )
            }
          </div>
        }
        {myInfo["id"] &&
          (tap == "basic" ? (
            <>
              {params["who"] !== "teacher" ? (
                <Myinfo_Basis_student
                  setIsModi={setIsModi}
                  apiPro={apiPro_}
                  userInfo={userInfo}
                  isTeacher={false}
                  myInfo={myInfo}
                  r_class={r_class}
                  r_lecture={r_lecture}
                />
              ) : (
                <Myinfo_Basis_teacher
                  setIsModi={setIsModi}
                  apiPro={apiPro_}
                  userInfo={userInfo}
                  isTeacher={true}
                  myInfo={myInfo}
                  r_class={r_class}
                  r_lecture={r_lecture}
                />
              )}
              {paymentOpen != "none" && <MyInfoTicket />}
            </>
          ) : tap == "memo" ? (
            <div
              style={{
                padding: "10px 0px",
              }}
            >
              <Myinfo_momo setHiddenNav={setHiddenNav} userInfo={userInfo} myInfo={myInfo} />
            </div>
          ) : tap == "lecture" ? (
            <div
              style={{
                padding: "5px 10px",
              }}
            >
              {
                lectureData.length == 0 ? (
                  <div
                    style={{
                      width: "100%",
                      height: "300px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      fontSize: "16px",
                      fontWeight: "bold",
                    }}
                  >
                    참여중인 수업이 없습니다.
                  </div>
                ) : (
                  <div>
                    <div
                      style={{
                        fontSize: "14px",
                        fontWeight: 700,
                        marginBottom: "10px",
                      }}
                    >
                      {lectureData.length == 0 || !lectureData
                        ? "총 0개 수강중"
                        : `총 ${lectureData.length}개 수강 중`}
                    </div>
                    {lectureData.map((v, i) => (
                      <ShareSection index={i} data={v} />
                    ))}
                  </div>
                )
                // <UiSearchList
                //     isSearch={true}
                //     isTitle={false}
                //     isTeacher={isTeacher}
                //     form={2}
                //     data={lectureData}
                //     fnClick={(d)=>{
                //         setHiddenNav("lecture&")
                //         setHiddenUrl(`/myinfo/${params["who"]}/${myInfo["id"]}`)
                //         navigate("/lecture/detail/"+params["who"]+"/"+d["cid"]+"/de")
                //     }}
                // />
              }
            </div>
          ) : tap == "grade" ? (
            <div
              style={{
                padding: "5px 10px",
              }}
            >
              {gradeData.length == 0 ? (
                <div
                  style={{
                    width: "100%",
                    height: "300px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    fontSize: "16px",
                    fontWeight: "bold",
                  }}
                >
                  등록하신 평점이 없습니다.
                </div>
              ) : (
                <div
                  style={{
                    width: "100%",
                    backgroundColor: "white",
                    // padding : "0px 10px"
                  }}
                >
                  <div
                    style={
                      {
                        // width:"100%",
                        // padding : "0px 10px",
                      }
                    }
                  >
                    <div
                      style={{
                        position: "relative",
                      }}
                    >
                      <div
                        style={{
                          // padding:"10px 0px 5px 0px",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <div
                          style={{
                            fontSize: "14px",
                            fontWeight: 700,
                            // marginBottom:"10px",
                          }}
                        >
                          {`리뷰 ${gradeData["list"].length}개`}
                        </div>
                        {isSort ? (
                          <ArrowDropUp
                            style={{
                              fontSize: "40px",
                              marginBottom: "3px",
                            }}
                            onClick={() => {
                              setIsSort(false);
                            }}
                          />
                        ) : (
                          <ArrowDropDown
                            style={{
                              fontSize: "40px",
                              marginBottom: "3px",
                            }}
                            onClick={() => {
                              setIsSort(true);
                            }}
                          />
                        )}
                      </div>
                      {isSort ? (
                        <div
                          style={{
                            position: "absolute",
                            // left:0,
                            // top:10,
                            borderRadius: "20px",
                            border: "1px solid #F6F6F6",
                            boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                            padding: "3px 0px",
                            backgroundColor: "white",
                          }}
                        >
                          {sort.map((v, i) => (
                            <div
                              style={{
                                padding: "15px 0px",
                                width: "120px",
                                backgroundColor: "white",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                fontWeight: 400,
                                fontSize: "14px",
                                color: "#3D3A39",
                                borderRadius: "20px",
                              }}
                              onClick={() => {
                                setIsSort(false);
                                apiPro_read_5(v["value"]);
                              }}
                            >
                              {v["name"]}
                            </div>
                          ))}
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                    {gradeData["list"].map((v, i) => (
                      <UiReview
                        index={i}
                        data={v}
                        fnClick={(d) => {
                          setHiddenNav("grade");
                          // console.log(d);
                          // navigate("/talk/"+params["who"]+"/"+d["id"]+"/"+d["qid"]+"/"+d["sid"]+"/"+d["tid"])
                          navigate(
                            "/talk/" +
                              params["who"] +
                              "/" +
                              "0" +
                              "/" +
                              d["qid"] +
                              "/" +
                              userInfo["id"] +
                              "/" +
                              d["tid"]
                          );
                        }}
                      />
                    ))}
                  </div>
                </div>
              )}
            </div>
          ) : (
            tap == "comment" && (
              <div
                style={{
                  padding: "5px 10px",
                  // backgroundColor : "white"
                }}
              >
                {commentData.length == 0 ? (
                  <div
                    style={{
                      width: "100%",
                      height: "300px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      fontSize: "16px",
                      fontWeight: "bold",
                    }}
                  >
                    등록하신 댓글이 없습니다.
                  </div>
                ) : (
                  <div>
                    <div
                      style={{
                        fontSize: "14px",
                        fontWeight: 700,
                        marginBottom: "10px",
                      }}
                    >
                      {`댓글 ${commentData.length}개`}
                    </div>
                    {commentData.map((v, i) => (
                      <div
                        style={{
                          // margin : "5px 0px",
                          borderRadius: 5,
                          // padding : "0px 5px",
                          backgroundColor: "white",
                        }}
                      >
                        <ReplySection
                          index={i}
                          data={v}
                          userInfo={userInfo}
                          apiPro_del={apiPro_del}
                        />
                      </div>
                    ))}
                  </div>
                )}
              </div>
            )
          ))}
      </div>
      {isLoading && <UiLoading isText={false} text="" isTransparent={true} />}
      {isCrop && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            display: "flex",
            // flex:1,
            width: "100vw",
            height: "100vh",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "rgba(200,200,200,0.5)",
            zIndex: 130003,
          }}
        >
          <App setFileObj={setFileObj} fileObj={fileObj} setIsCrop={setIsCrop} />
        </div>
      )}
      {isModi && (
        <Modal_modi
          setIsCrop={setIsCrop}
          lectureData={{}}
          setIsModal={setIsModi}
          userInfo={userInfo}
          myInfo={myInfo}
          title="기본 정보 변경"
          fileObj={fileObj}
          setFileObj={setFileObj}
        />
      )}
    </Frame>
  );
};
const ShareSection = ({ index, data }) => {
  // useEffect(()=>{
  //     console.log("data : ", data)
  // },[data])

  return (
    <div
      style={{
        padding: "14px 5px",
        borderBottom: "1px solid #F6F6F6",
        backgroundColor: index % 2 == 0 ? "#FFFBF5" : "#FBFBFB",
        marginBottom: "2px",
        borderRadius: "5px",
      }}
    >
      <div
        style={{
          display: "flex",
          fontSize: "14px",
          fontWeight: 400,
        }}
      >
        <div style={{}}>{data["name"]}</div>
        <div
          style={{
            color: "#B0B0B0",
            marginLeft: "5px",
          }}
        >
          {`| ${data["academyName"]}`}
        </div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: "7px",
        }}
      >
        <div>
          <div
            style={{
              fontSize: "12px",
              fontWeight: 400,
              color: "#B0B0B0",
            }}
          >
            {data["teachers"] &&
              (data["teachers"].length == 0 ? (
                <></>
              ) : data["teachers"].length == 1 ? (
                `${data["teachers"][0]["name"]} 선생님`
              ) : (
                `${data["teachers"][0]["name"]} 선생님 외 ${data["teachers"].length - 1}명`
              ))}
          </div>
        </div>
        <div>
          <div
            style={{
              fontSize: "12px",
              fontWeight: 400,
              color: "#B0B0B0",
            }}
          >
            {`수강인원 ${data["lcnt"]}명`}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyInfo_Profile;
