import React from "react";
import type { IcDefatultProps } from "../types";

export default function IAi({ width = 24, height = 24 }: IcDefatultProps): JSX.Element {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.9933 14.5141C14.9933 14.6968 14.8883 14.8607 14.7252 14.9286L12.2072 15.9405C11.3202 16.2966 10.6276 17.0391 10.3014 17.9784L9.34519 20.7447C9.33848 20.7658 9.32284 20.7798 9.31391 20.7986C9.30497 20.815 9.29827 20.829 9.2871 20.8431C9.27146 20.8641 9.25582 20.8829 9.23794 20.9016C9.2089 20.9297 9.17539 20.9532 9.13964 20.9695C9.1173 20.9789 9.09495 20.9883 9.07038 20.9906C9.04804 20.9977 9.02569 21 9.00112 21L8.92739 20.9906C8.90505 20.9883 8.8827 20.9789 8.85813 20.9695C8.82015 20.9532 8.78663 20.9274 8.75759 20.8993C8.73971 20.8829 8.72407 20.8641 8.70843 20.8431C8.6995 20.8314 8.69279 20.8173 8.68609 20.8056C8.67492 20.7845 8.65705 20.7681 8.65034 20.7447L7.6941 17.9784C7.37014 17.0414 6.67753 16.2966 5.79054 15.9405L3.27257 14.9286C3.10948 14.8631 3 14.6991 3 14.5141C3 14.322 3.12065 14.151 3.29492 14.0948L5.5984 13.3335C6.59486 13.0032 7.38131 12.1951 7.71867 11.1575L8.65705 8.25532C8.66822 8.22252 8.68386 8.19207 8.6995 8.16631C8.71067 8.14757 8.72184 8.13117 8.73971 8.11478C8.77323 8.07496 8.81791 8.04685 8.8693 8.02577C8.8827 8.01874 8.90058 8.01405 8.91845 8.01171C8.94526 8.00234 8.97207 8 9.00112 8C9.03016 8 9.05921 8.00234 9.08378 8.01171C9.10166 8.01405 9.1173 8.02108 9.13294 8.02577C9.17762 8.0445 9.21784 8.07261 9.25358 8.10775C9.26699 8.1218 9.28039 8.1382 9.29157 8.15225C9.31391 8.18505 9.33402 8.21784 9.34519 8.25766L10.2836 11.1598C10.6187 12.1975 11.4074 13.0056 12.4016 13.3359L14.7051 14.0971C14.8816 14.1533 15 14.3243 15 14.5164L14.9933 14.5141Z"
        fill="url(#paint0_linear_1541_87)"
      />
      <path
        d="M20 9.50922C20 9.6083 19.9381 9.69585 19.8429 9.73272L18.3744 10.2788C17.8579 10.47 17.4532 10.871 17.2628 11.3756L16.7035 12.8641C16.7035 12.8641 16.6916 12.8825 16.6845 12.894C16.6797 12.9032 16.6749 12.9101 16.6702 12.9171C16.6607 12.9286 16.6511 12.9378 16.6416 12.947C16.625 12.9608 16.6059 12.9747 16.5845 12.9839C16.5702 12.9885 16.5583 12.9931 16.544 12.9954C16.5298 12.9977 16.5179 13 16.5036 13L16.4607 12.9954C16.4464 12.9954 16.4345 12.9885 16.4203 12.9839C16.3988 12.9747 16.3798 12.9608 16.3608 12.947C16.3489 12.9378 16.3417 12.9286 16.3322 12.9171C16.3274 12.9101 16.3227 12.9032 16.3179 12.8963C16.3108 12.8848 16.3013 12.8756 16.2965 12.8641L15.7372 11.3756C15.5491 10.871 15.1445 10.47 14.628 10.2788L13.1595 9.73272C13.0643 9.69816 13 9.6083 13 9.50922C13 9.40553 13.069 9.31336 13.1714 9.28341L14.5138 8.87327C15.0945 8.69585 15.5539 8.26037 15.7514 7.70277L16.2989 6.13825C16.306 6.11982 16.3132 6.10369 16.3227 6.08986C16.3298 6.08065 16.337 6.07143 16.3465 6.06221C16.3655 6.04147 16.3917 6.02535 16.4226 6.01382C16.4322 6.01152 16.4417 6.00691 16.4512 6.00691C16.4655 6.0023 16.4821 6 16.4988 6C16.5155 6 16.5321 6 16.5464 6.00691C16.5559 6.00691 16.5655 6.01152 16.575 6.01382C16.6012 6.02304 16.625 6.03917 16.644 6.0576C16.6535 6.06452 16.6583 6.07373 16.6654 6.08295C16.6797 6.10138 16.6892 6.11751 16.6964 6.14055L17.2438 7.70507C17.439 8.26267 17.8983 8.69816 18.4791 8.87558L19.8215 9.28571C19.9238 9.31567 19.9929 9.40783 19.9929 9.51152L20 9.50922Z"
        fill="url(#paint1_linear_1541_87)"
      />
      <path
        d="M13.9948 5.50748C13.9948 5.57735 13.9509 5.63972 13.8838 5.66717L12.8352 6.05639C12.4659 6.19361 12.1767 6.47804 12.0398 6.83982L11.642 7.90269C11.642 7.90269 11.6317 7.91517 11.6291 7.92265C11.6265 7.92764 11.6214 7.93513 11.6188 7.94012C11.6136 7.9476 11.6059 7.95509 11.5981 7.96257C11.5852 7.97256 11.5723 7.98253 11.5568 7.99002C11.5465 7.99251 11.5387 7.99751 11.5284 7.99751C11.5181 7.99751 11.5103 8 11.5 8H11.469C11.469 8 11.4509 7.99251 11.4406 7.98752C11.4251 7.98004 11.4122 7.97256 11.3993 7.96008C11.3915 7.9526 11.3864 7.94761 11.3786 7.93762C11.376 7.93263 11.3709 7.92764 11.3683 7.92265C11.3631 7.91517 11.3554 7.90768 11.3528 7.9002L10.9551 6.83733C10.8208 6.47804 10.5315 6.19112 10.1622 6.05389L9.11364 5.66467C9.04649 5.63972 9 5.57485 9 5.50499C9 5.43014 9.04907 5.36527 9.12397 5.34281L10.0847 5.0509C10.5005 4.92365 10.8285 4.61427 10.968 4.21507L11.358 3.0998C11.3631 3.08733 11.3683 3.07485 11.376 3.06487C11.3812 3.05739 11.3864 3.0499 11.3915 3.04491C11.407 3.02994 11.4225 3.01747 11.4458 3.00998C11.4509 3.00998 11.4587 3.00499 11.4664 3.00499C11.4768 3.0025 11.4897 3 11.5 3C11.5103 3 11.5232 3 11.5336 3.00499C11.5413 3.00499 11.5491 3.00749 11.5542 3.00998C11.5723 3.01747 11.5904 3.02745 11.6033 3.03992C11.6085 3.04491 11.6136 3.0524 11.6188 3.05739C11.6291 3.06986 11.6369 3.08234 11.642 3.09731L12.032 4.21257C12.1715 4.61178 12.4995 4.92116 12.9153 5.0484L13.876 5.34032C13.9509 5.36277 14 5.42764 14 5.5025L13.9948 5.50748Z"
        fill="url(#paint2_linear_1541_87)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1541_87"
          x1="6.5"
          y1="13.5"
          x2="15"
          y2="15"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#916FFF" />
          <stop offset="1" stopColor="#5073FF" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_1541_87"
          x1="13"
          y1="9.5"
          x2="19"
          y2="10.5"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#597BF2" />
          <stop offset="1" stopColor="#8EC1FF" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_1541_87"
          x1="13.5"
          y1="5.5"
          x2="9.5"
          y2="5.5"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#69ABF2" />
          <stop offset="1" stopColor="#80D4ED" />
        </linearGradient>
      </defs>
    </svg>
  );
}
