import React, { CSSProperties, useEffect, useState } from "react";

// ----- UiStyle ----- //
import "./UiInput.css";
import UiInputStyle from "./UiInputStyle";
// import constants  from '../../asset/constants'
import { Search, Visibility, VisibilityOff } from "@material-ui/icons";
import drop from "../../asset/Img/down.png";

type UiInputProps = {
  fnFocus?: (e?: any) => void;
  title?: string;
  timeStyle?: CSSProperties;
  inputClass?: string;
  fnEnter?: () => void;
  isPw?: boolean;
  type?: "basic" | "select" | "desc" | "time" | "search";
  maxLength?: number;
  id?: string;
  inputValue?: string;
  readOnly?: boolean;
  basic_container_style?: CSSProperties;
  placeholder?: string;
  fnChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  fnBlur?: () => void;
  containerStyle?: CSSProperties;
  dsecStyle?: CSSProperties;
  selectStyle?: CSSProperties;
  searchContainerStyle?: CSSProperties;
};

const UiInput = ({
  fnFocus,
  // title,
  timeStyle,
  inputClass = "",
  fnEnter = () => {},
  isPw,
  type = "basic",
  maxLength,
  id,
  inputValue,
  readOnly = false,
  basic_container_style,
  placeholder,
  fnChange,
  fnBlur,
  containerStyle,
  dsecStyle,
  selectStyle,
  searchContainerStyle,
}: UiInputProps) => {
  const [isSelect, setIsSelect] = useState(false);
  var [optionList, setOptionList] = useState([]);

  var [isPwVis, setIsPwVis] = useState(false);

  // let createOption = () => {
  //     try {
  //         let list_ = [
  //             <option value='placeholder' disabled selected hidden style={{
  //                 cursor:"pointer",
  //             }}>
  //                 {/* Select an option */}
  //                 {placeholder}
  //             </option>
  //         ]
  //         if(selectOption.length > 0){
  //             selectOption.map((v, i) => {
  //                 if(v["value"] == initValue){
  //                     setIsSelect(true);
  //                     list_.push(<option key={i} value={v.value} selected style={{color:constants.style.color_9}}>{v.name}</option>)
  //                 }
  //                 else {
  //                     list_.push(<option key={i} value={v.value} style={{ cursor:"pointer"}} >{v.name}</option>);
  //                 }
  //             })
  //             setOptionList([
  //                 ...list_
  //             ])
  //         }
  //         else {
  //             setOptionList([
  //                 ...list_
  //             ])
  //         }
  //     }
  //     catch (e) {
  //         //// console.log('CreateOption fn Error => ' + e)
  //     }
  // }

  // useEffect(()=>{
  //     // setOptionList(optionList = [])
  //     setIsSelect(false);
  //     setTimeout(()=>{
  //         createOption();
  //     },0)
  // },[!isOnce && selectOption])

  // useEffect(()=>{
  //     // setOptionList(optionList = [])
  //     setTimeout(()=>{
  //         createOption();
  //     },0)
  // },[placeholder])

  useEffect(() => {
    if (isPw) {
      setIsPwVis(true);
    }
  }, [isPw]);

  return (
    <div
      style={{
        ...UiInputStyle.container,
        ...containerStyle,
      }}
    >
      <div
        style={
          type == "select"
            ? ({
                display: "flex",
                alignItems: "center",
                ...UiInputStyle.select_container,
                ...selectStyle,
              } as CSSProperties)
            : type == "basic"
              ? ({
                  ...UiInputStyle.basic_container,
                } as CSSProperties)
              : type == "time"
                ? ({
                    display: "flex",
                    flex: 1,
                    height: "100%",
                    width: "100%",
                    justifyContent: "center",
                  } as CSSProperties)
                : ({
                    ...UiInputStyle.input_container,
                  } as CSSProperties)
        }
      >
        {type == "select" ? (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
              background: `url(${drop}) no-repeat right`,
              backgroundSize: "18px",
            }}
          ></div>
        ) : type == "search" ? (
          <div
            style={
              {
                ...UiInputStyle.search_container,
                ...searchContainerStyle,
              } as CSSProperties
            }
          >
            <input
              id={id}
              type={isPw ? "password" : undefined}
              readOnly={readOnly}
              style={UiInputStyle.search_wrapper}
              onKeyUp={(e) => {
                if (e.keyCode == 13) {
                  fnEnter();
                }
              }}
              onChange={fnChange}
              placeholder={placeholder}
              value={inputValue}
            />
            <div style={UiInputStyle.search_btn_wrapper as CSSProperties} onClick={fnEnter}>
              <Search
                style={{
                  color: "white",
                  fontSize: 30,
                }}
              />
            </div>
          </div>
        ) : type == "basic" ? (
          <div
            style={{
              position: "relative",
              width: "100%",
            }}
          >
            <input
              className={inputClass}
              maxLength={maxLength}
              id={id}
              type={isPwVis ? "password" : undefined}
              readOnly={readOnly}
              style={{
                ...UiInputStyle.input_wrapper,
                ...basic_container_style,
                // boxShadow: "0px 2px 4px 0px rgba(50, 50, 50, 0.1)",
                // WebkitBoxShadow : "3px 3px 6px 6px rgba(0, 0, 0, 0.1)"
              }}
              onKeyUp={(e) => {
                if (e.keyCode == 13) {
                  fnEnter();
                }
              }}
              onFocus={fnFocus}
              onChange={fnChange}
              onBlur={fnBlur}
              placeholder={placeholder}
              value={inputValue}
            ></input>
            {isPwVis ? (
              <Visibility
                style={{
                  display: "flex",
                  height: UiInputStyle.input_wrapper.height,
                  alignItems: "center",
                  width: "20px",
                  top: 0,
                  right: 7.5,
                  position: "absolute",
                  fontSize: "12px",
                }}
                onClick={() => {
                  setIsPwVis(false);
                }}
              />
            ) : (
              isPw && (
                <VisibilityOff
                  style={{
                    display: "flex",
                    height: UiInputStyle.input_wrapper.height,
                    alignItems: "center",
                    width: "20px",
                    top: 0,
                    right: 7.5,
                    position: "absolute",
                    fontSize: "12px",
                  }}
                  onClick={() => {
                    setIsPwVis(true);
                  }}
                />
              )
            )}
          </div>
        ) : type == "desc" ? (
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <textarea
              readOnly={readOnly}
              id={id}
              style={
                {
                  ...UiInputStyle.textArea_wrapper,
                  ...dsecStyle,
                } as CSSProperties
              }
              maxLength={maxLength}
              onChange={(e) => {
                fnChange(e);
              }}
              onBlur={fnBlur}
              value={inputValue}
              placeholder={placeholder}
            ></textarea>
            {maxLength && (
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "flex-end",
                  fontSize: "10px",
                  marginTop: "5px",
                }}
              >
                {inputValue ? maxLength - inputValue.length : maxLength}
              </div>
            )}
          </div>
        ) : type == "time" ? (
          <div
            style={{
              display: "flex",
            }}
          >
            <input
              className={inputClass}
              type="time"
              style={{
                ...UiInputStyle.time_wrapper,
                ...timeStyle,
                // display:"none",
              }}
              onChange={fnChange}
              id="timePcik"
              name="timePcik"
              // locale={"24-hour"}
            />
          </div>
        ) : (
          <input
            id={id}
            type={isPw ? "password" : undefined}
            style={UiInputStyle.input_wrapper}
            onChange={fnChange}
            onBlur={fnBlur}
            value={inputValue}
            placeholder={placeholder}
          ></input>
        )}
      </div>
    </div>
  );
};

const UiInput_title = (props) => {
  return (
    <div
      style={{
        padding: "10px 0px",
        ...props.c_style,
      }}
      onClick={props.fnClick}
    >
      <div
        style={{
          fontSize: "14px",
          // fontSize : "6sp",
          marginBottom: "5px",
          ...props.t_style,
        }}
      >
        {props.title}
      </div>
      <UiInput {...props} />
    </div>
  );
};

export { UiInput, UiInput_title };
