import { useNavigate } from "react-router";

const useQueryString = () => {
  const navigate = useNavigate();
  const getQueryString = (key: string) => {
    const searchParams = new URLSearchParams(window.location.search);
    return searchParams.get(key);
  };

  const setQueryString = (key: string, value: string, replace = false) => {
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.set(key, value);
    navigate(
      {
        search: searchParams.toString(),
      },
      {
        replace,
      }
    );
  };

  const removeQueryString = (key: string) => {
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.delete(key);
    navigate(
      {
        search: searchParams.toString(),
      },
      {
        replace: true,
      }
    );
  };
  return { getQueryString, setQueryString, removeQueryString };
};

export default useQueryString;
