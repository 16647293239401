import { Check, Clear, MoreVert, Star } from "@material-ui/icons";
import { React, useEffect, useRef, useState } from "react";
import { useLongPress } from "use-long-press";
import style from "../../asset/style";
import { UiCheckBox } from "../UiCheckBox/UiCheckBox";
import UiTextBtn from "../UiTextBtn/UiTextBtn";
import UiPeople_Style from "../UiPeople/UiPeople_Style";
import util from "../../asset/util";
import { useNavigate, useParams } from "react-router";
import noBook from "../../asset/Img/book1.png";
import constants from "../../asset/constants";
import { useToggleExemplary } from "../../api/question";

const UiExplain_type_b = ({
  isSearch = false,
  scrollKey,
  setScrolllKey,
  isTeacher,
  data,
  isQna,
  id,
  fnClick,
  selectData,
  isEdit,
  isManager,
  isTeacherPage,
  manageSub,
  fnLongClick,
  fnCheck,
  isOpenOk = true,
  form,
  isExplaoinModel = false,
  isDot = false,
  apiPro,
  apiPro_del,
}) => {
  let params = useParams();
  let navigate = useNavigate();

  var [isPop, setIsPop] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const apiPro_mobum = async (isModi) => {
    try {
      await useToggleExemplary(data?.id, isModi ? "del" : 0);
      if (isModi) {
        alert("선택된 질문이 모범풀이 해제되었어요.");
      } else {
        alert("선택된 질문이 모범풀이에 등록되었어요.");
      }
      util.postWeb("isModal_off");
      setIsPop(false);
      await apiPro();
    } catch (e) {
      console.log(e);
    }
    console.log(e);
  };

  const bind = useLongPress(
    () => {
      try {
        if (isTeacher) {
          fnLongClick(id);
        }
      } catch (e) {
        // console.log("Long Press Error => ",e)
      }
    },
    {
      threshold: 1000,
    }
  );

  // useEffect(()=>{
  //     console.log("QNA DATA > > > > ??? ",data)
  // },[data])

  // useEffect(()=>{
  // },[])

  var scrollRef = useRef(null);

  useEffect(() => {
    // console.log("data : ",data)
    if (scrollRef.current) {
      scrollRef.current.scrollIntoView({
        // behavior: "smooth",
        block: "center",
      });
      if (setScrolllKey) {
        setScrolllKey("");
      }
    }
  }, [scrollRef]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        backgroundColor: "white",
        padding: isDot ? "0px" : "0px 10px",
        position: "relative",
      }}
      ref={scrollKey == data["id"] ? scrollRef : null}
    >
      <div
        style={{
          ...UiPeople_Style.section_c,
          width: "100%",
          height: "auto",
          // height : "48px",
        }}
        {...bind()}
        onClick={() => {
          // if(!isOpenOk)return;
          if (!fnClick) return;
          else {
            if (!isEdit) {
              fnClick(data);
            }
          }
          if (!isEdit) {
            if (isExplaoinModel) return;
            setIsOpen(!isOpen);
          }
        }}
      >
        {isEdit && selectData && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginRight: "10px",
            }}
          >
            <UiCheckBox
              className={isTeacher ? "t" : "s"}
              // value={"?"}
              // className={"message_?"}
              checked={selectData[id] == "Y" ? true : false}
              value={id}
              fnClick={fnCheck}
              containerStyle={{
                padding: "0px",
              }}
            />
          </div>
        )}
        <div
          style={{
            // width : '20%',
            marginRight: "10px",
          }}
        >
          <div
            style={{
              ...UiPeople_Style.section_i_w,
              borderRadius: 0,
              width: "60px",
              // height : "45px"
            }}
          >
            <img
              style={{
                // width:'100%',
                maxWidth: "60px",
                height: "100%",
                objectFit: "contain",
                border: "1px solid",
                borderColor: style.common.borderColor4,
                // objectPosition:'top'
              }}
              onError={(e) => {
                e.currentTarget.src = noBook;
              }}
              src={
                data["original"]
                  ? `${constants.s3_url}/qna/${data["original_img"]}.jpg`
                  : `${constants.s3_url}/qna/${data["img"]}.jpg`
                // `${constants.apiUrl}/public/qna/${data["id"]}.jpg?${util.getRandomInt(1000,9999)}`
              }
            />
          </div>
        </div>
        <div
          style={{
            ...UiPeople_Style.section_r_c,
            width: "100%",
            // paddingLeft : "10px",
          }}
        >
          <div style={UiPeople_Style.section_r_t_w}>
            <div
              style={{
                ...UiPeople_Style.section_r_t_t_w,

                // display:"inline-flex",
                // alignItems:"center",
                // maxWidth:'40vw',
                paddingRight: "20px",
                verticalAlign: "center",
                fontSize: "14px",
                lineHeight: "1.6em",
                // borderBlockColor:"orange",
                // maxHeight:"1.6em",
                // minHeight:"1.6em",
              }}
            >
              <div
                style={{
                  // color : isQna ? style.common.t_color : style.common.fontColor_3,
                  color: data["gicul_rate"]
                    ? style.common.s_color
                    : data["ans_date"]
                      ? style.common.fontColor_3
                      : style.common.t_color,
                  fontWeight: "bold",
                  marginRight: "3px",
                  display: "flex",
                  minWidth: data["gicul_rate"]
                    ? "40px"
                    : data["ans_date"]
                      ? data["original"] == 0
                        ? "63px"
                        : "88px"
                      : data["original"] == 0
                        ? "40px"
                        : "63px",
                  // display:"inline-flex",
                }}
              >
                {data["gicul_rate"]
                  ? "[기출]"
                  : data["ans_date"]
                    ? data["original"] == 0
                      ? "[답변완료]"
                      : "[추가질문완료]"
                    : data["original"] == 0
                      ? "[질문]"
                      : "[추가질문]"}
              </div>
              <div
                style={{
                  ...UiPeople_Style.section_r_t_t_text_2,
                  display: "flex",
                  // width:"70%",
                  // lineHeight:"1.6em",
                  // maxHeight:"1.6em",
                  // minHeight:"1.6em",
                  // fontSize:"14px",
                  // maxWidth:"70%",
                  marginLeft: "3px",
                  // overflow:"hidden",
                  // textOverflow:'ellipsis',
                }}
              >
                {/* 김학생 김학생 김학생 김학생 김학생 김학생 김학생 김학생 김학생 김학생 김학생 김학생 김학생 김학생 김학생 김학생  */}
                {data["title"]}
                {/* {util.textTrim(data["title"],16)} */}
              </div>
            </div>
            <div
              style={{
                display: "flex",
                paddingRight: "10px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  // alignItems:"center",
                  fontSize: "11px",
                  color: style.common.fontColor_1,
                }}
              >
                {/* 21/12/23 */}
                {isSearch && (
                  <div style={{}}>
                    {data["ans_date"]
                      ? util.convertToDateTime(data["ans_date"])
                      : util.convertToDateTime(data["qna_date"])}
                  </div>
                )}
              </div>
              {isDot && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <MoreVert
                    style={{
                      fontSize: "20px",
                      marginRight: "-7px",
                      // marginLeft:"2px",
                    }}
                    onClick={(e) => {
                      setIsPop(true);
                      e.stopPropagation();
                      e.defaultPrevented();
                    }}
                  />
                </div>
              )}
            </div>
          </div>

          <div
            style={{
              ...UiPeople_Style.section_r_m_w,
              marginBottom: "4px",
              fontSize: "10px",
            }}
          >
            {data["sid"] ? (
              <div style={UiPeople_Style.section_r_m_l_w}>
                <div>
                  {/* 김학생(닉네임) | 수학만점왕 */}
                  {params["who"] == "student"
                    ? `${data["teacherName"] ? data["teacherName"] : ""} ${data["className"] ? `(${data["className"]})` : ""}`
                    : `${data["nick"] ? data["nick"] : ""} ${data["className"] ? "(" + data["className"] + ")" : ""}`}
                </div>
              </div>
            ) : (
              <div style={UiPeople_Style.section_r_m_l_w}>
                <div>{data["teacherName"] ? `${data["teacherName"]} 선생님` : ""}</div>
              </div>
            )}
          </div>
          <div
            style={{
              ...UiPeople_Style.section_r_b_w,
              fontSize: "12px",
              // justifyContent : !data["sid"] && 'flex-end'
            }}
          >
            {data["starAvg"] ? (
              <div
                style={{
                  display: "flex",
                }}
              >
                <Star
                  style={{
                    color: "orange",
                    fontSize: "14px",
                    marginRight: "2px",
                  }}
                />
                {/* 4.0 (2) */}
                {`${data["starAvg"]} (${data["starCnt"]})`}
              </div>
            ) : (
              ``
            )}
            <div
              style={{
                color: style.common.fontColor_6,
              }}
            >
              {`${data["readCnt"] ? data["readCnt"] + "명 열람" : ""} ${data["metooCnt"] ? `| 저도요 ${data["metooCnt"]}` : ""}`}
            </div>
          </div>
        </div>
      </div>
      {isOpen && (
        <div style={UiPeople_Style.openAdd_c}>
          {isManager ? (
            isTeacherPage ? (
              true ? (
                <div
                  style={{
                    ...UiPeople_Style.openAdd_btn_w,
                    justifyContent: "center",
                  }}
                >
                  <UiTextBtn
                    text={"관리자에서 해제"}
                    isUnderline={false}
                    btnStyle={UiPeople_Style.openAdd_btn}
                  />
                </div>
              ) : (
                <div style={UiPeople_Style.openAdd_btn_w}>
                  <UiTextBtn
                    text={"탈퇴처리"}
                    isUnderline={false}
                    btnStyle={{
                      ...UiPeople_Style.openAdd_btn,
                      color: style.common.fontColor_3,
                    }}
                  />

                  <UiTextBtn
                    text={"관리자로 승격"}
                    isUnderline={false}
                    btnStyle={UiPeople_Style.openAdd_btn}
                  />
                </div>
              )
            ) : manageSub ? (
              <div style={UiPeople_Style.openAdd_btn_w}>
                <UiTextBtn
                  text={"탈퇴처리"}
                  isUnderline={false}
                  btnStyle={{
                    ...UiPeople_Style.openAdd_btn,
                    color: style.common.fontColor_3,
                  }}
                />

                <UiTextBtn
                  text={"질문보기"}
                  isUnderline={false}
                  btnStyle={UiPeople_Style.openAdd_btn}
                />
                <UiTextBtn
                  text={"대화하기"}
                  isUnderline={false}
                  btnStyle={UiPeople_Style.openAdd_btn}
                />
              </div>
            ) : (
              <div style={UiPeople_Style.openAdd_btn_w_2}>
                <UiTextBtn
                  text={
                    <div style={UiPeople_Style.openAdd_btn_w_2_text_w}>
                      <Clear style={UiPeople_Style.openAdd_btn_w_2_text} />
                      거절하기
                    </div>
                  }
                  isUnderline={false}
                  btnStyle={{
                    ...UiPeople_Style.openAdd_btn,
                    color: style.common.fontColor_3,
                  }}
                />
                <UiTextBtn
                  text={
                    <div style={UiPeople_Style.openAdd_btn_w_2_text_w}>
                      <Check style={UiPeople_Style.openAdd_btn_w_2_text} />
                      승인하기
                    </div>
                  }
                  isUnderline={false}
                  btnStyle={UiPeople_Style.openAdd_btn}
                />
              </div>
            )
          ) : (
            <div style={UiPeople_Style.openAdd_btn_w}>
              <UiTextBtn
                text={"질문 보기"}
                isUnderline={false}
                btnStyle={UiPeople_Style.openAdd_btn}
              />

              {!isTeacher && (
                <UiTextBtn
                  text={"질문 하기"}
                  isUnderline={false}
                  btnStyle={UiPeople_Style.openAdd_btn}
                />
              )}
              <UiTextBtn
                text={"대화하기"}
                isUnderline={false}
                btnStyle={UiPeople_Style.openAdd_btn}
              />
            </div>
          )}
        </div>
      )}
      {isPop && (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            position: "absolute",
            top: 10,
            right: 10,
            border: "1px solid",
            borderColor: style.common.borderColor,
            padding: "5px 10px",
            fontSize: "12px",
            backgroundColor: "white",
            zIndex: 999999999999,
          }}
        >
          {data["mobum_date"] ? (
            <DotPop
              text="모범 해제"
              fnClick={() => {
                let isOk = window.confirm("모범 풀이 해제를 하시겠어요?");
                if (isOk) {
                  apiPro_mobum(true);
                }
              }}
            />
          ) : (
            <DotPop
              text="모범 등록"
              fnClick={() => {
                let isOk = window.confirm("모범 풀이 등록을 하시겠어요?");
                if (isOk) {
                  apiPro_mobum(false);
                }
              }}
            />
          )}
          <DotPop
            text="교재 이동"
            fnClick={() => {
              console.log(data["id"]);
              util.postWeb("isModal_off");
              navigate(`/explainmodel/${params["who"]}/${data["id"]}/move/n`);
              setIsPop(false);
            }}
          />
          {data["mobum_date"] ? (
            <DotPop
              text="교재 복사"
              fnClick={() => {
                console.log(data["id"]);
                util.postWeb("isModal_off");
                navigate(`/explainmodel/${params["who"]}/${data["id"]}/copy/n`);
                setIsPop(false);
              }}
            />
          ) : (
            <></>
          )}
          <DotPop
            text="질문 삭제"
            fnClick={() => {
              let isOk = window.confirm("질문을 삭제하시겠어요?");
              if (isOk) {
                apiPro_del(data);
                setIsPop(false);
              }
            }}
          />
          <DotPop
            text="메뉴 닫기"
            fnClick={() => {
              setIsPop(false);
            }}
          />
        </div>
      )}
    </div>
  );
};

const DotPop = ({ text, fnClick }) => {
  let params = useParams();

  return (
    <div
      style={{
        padding: "2px 0px 1px 0px",
        fontSize: "16px",
        color: params["who"] == "teacher" ? style.common.t_color : style.common.s_color,
        fontWeight: 500,
      }}
      onClick={(e) => {
        fnClick();
        e.stopPropagation();
        e.defaultPrevented();
      }}
    >
      {text}
    </div>
  );
};

export default UiExplain_type_b;
