import style from "../../asset/style"

const Message_Mark_Style = {
    textBtn_add_c : {
        display : 'flex',
        marginBottom:"5px",
        backgroundColor : "white"
    },
    textBtn_add_w : {
        width : '120px',
        margin : "0px 5px"

    },
    textBtn_add : {
        
        borderRadius : 20,
        color : "white",
        height : '30px',
        padding : "18px 10px",
        fontSize : "14px",
        // alignItems : 'center',
    },
    list_w : {
        display : 'flex',
        flexWrap : 'wrap',
        paddingLeft : "10px",
        marginTop:"10px",
        // justifyContent:"space-between",
    },
    b_c : {
        display:"flex",
        justifyContent:"center",    
    },
    b_w : {
        width : '120px',
        margin : "0px 5px"
    },
    b_btn : {
        borderRadius : 20,
        height : '30px',
        padding : "18px 10px",
        fontSize : "14px",
        // alignItems : 'center',
    },
    b_btn_title : {
        display:"flex",
        height:'100%',
        padding : 0,
        alignItems:"center"
    },
    folder_c : {
        position : 'relative',
        display : 'flex',
        alignItems:"center",
        width : "75px",
        flexDirection : 'column',
        fontSize : "12px",
        color : style.common.fontColor_1,
        padding : "10px"
    },
    folder_chk : {
        position : "absolute",
        left: 8,
    },
    folder_img : {
        width : "55px"
    },
}

export default Message_Mark_Style