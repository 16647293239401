import { React, useEffect, useMemo, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router";
import style from "../../asset/style";
import noBook from "../../asset/Img/book1.png";
import constants from "../../asset/constants";
import util from "../../asset/util";
import "./UiQna.css";
import { useDeleteQuestion, useToggleExemplary } from "../../api/question";

const UiQna_grid = ({
  index,
  scrollKey,
  isTeacher,
  isQna = false,
  data = {
    tid: "",
    sid: "",
  },
  fnClick,
  isEdit,
  selectData,
  fnLongClick,
  fnCheck = () => {},
  id,
  isMsg = false,
  setScrolllKey,
  setSelectData = [],
  isMsgSection = true,
  isDot = false,
  apiPro,
  apiPro_del,
  isSearch = false,
  // isManager,
  // isTeacherPage,
  // manageSub,
  type = 5,
}) => {
  // 관리자
  let sort = [
    { name: "모범풀이 해제", value: "a" },
    { name: "교재 이동", value: "b" },
    { name: "교재 복사", value: "c" },
    { name: "질문 삭제", value: "d" },
  ];

  let sort_2 = [
    { name: "모범풀이 등록", value: "e" },
    { name: "교재 이동", value: "b" },
    { name: "질문 삭제", value: "d" },
  ];

  // 참여자
  let sort_3 = [
    { name: "모범풀이 해제", value: "a" },
    { name: "교재 복사", value: "c" },
    { name: "질문 삭제", value: "d" },
  ];

  let sort_4 = [
    { name: "모범풀이 등록", value: "e" },
    { name: "질문 삭제", value: "d" },
  ];

  let params = useParams();

  var [isPop, setIsPop] = useState(false);

  var scrollRef = useRef(null);

  useEffect(() => {
    // console.log("data : ",data)
    if (scrollRef.current) {
      scrollRef.current.scrollIntoView({
        // behavior: "smooth",
        block: "center",
      });
      if (setScrolllKey) {
        setScrolllKey("");
      }
    }
  }, [scrollRef]);
  const selectQnaList = useMemo(() => {
    if (typeof selectData === "object" && !Array.isArray(selectData)) {
      return Object.keys(selectData).map((id) => parseInt(id));
    } else if (Array.isArray(selectData)) {
      return selectData;
    } else return [];
  }, [selectData]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        backgroundColor: "#FFFBF5",
        width: "150px",
        padding: "10px 10px",
        marginBottom: "5px",
        borderRadius: "15px",
        border: `${selectQnaList?.includes(data.id) ? "1px solid red" : ""}`,
      }}
      onClick={() => {
        if (isEdit) {
          fnCheck(data);
        } else {
          fnClick(data);
        }
      }}
    >
      <div
        style={{
          display: "flex",
          width: "100%",
          height: "110px",
          borderRadius: "5px",
        }}
      >
        {Object.keys(data).length > 0 && (
          <img
            className={data["mosaic"] == "y" ? "mosaic" : null}
            style={{
              width: "99.5%",
              height: "100%",
              objectFit: "cover",
              objectPosition: "top",
              border: "1px solid",
              borderColor: style.common.borderColor4,
              borderRadius: "5px",
            }}
            onError={(e) => {
              if (data["original"]) {
                try {
                  // e.currentTarget.src = `${constants.apiUrl}/public/qna/${data["original"]}.jpg`
                } catch (e) {}
                // e.currentTarget.src = `${constants.apiUrl}/public/qna/${data["original"]}.jpg?${util.getRandomInt(1000,9999)}`
              } else {
                e.currentTarget.src = noBook;
              }
            }}
            src={
              data["original"]
                ? data["original_img"]
                  ? `${constants.s3_url}/qna/${data["original_img"]}.jpg`
                  : `${constants.s3_url}/qna/${data["original"]}.jpg`
                : data["img"]
                  ? `${constants.s3_url}/qna/${data["img"]}.jpg`
                  : `${constants.apiUrl}/qna/${data["id"]}.jpg`
            }
          />
        )}
      </div>
      <div
        style={{
          display: "flex",
          flex: 1,
          justifyContent: "center",
          marginTop: "5px",
          // flexGrow:10,
          // height : "96px",
          // alignItems:"space-between",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div
            style={{
              ...style.common.line_text,
              color: "#3D3A39",
            }}
          >
            {data["title"]}
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            {isDot && (
              <div
                style={{
                  marginLeft: "7px",
                  width: "30px",
                  fontSize: "14px",
                  fontWeight: 400,
                  color: "#A5A5A5",
                  position: "relative",
                }}
                onClick={(e) => {
                  setIsPop(!isPop);
                  e.stopPropagation();
                }}
              >
                {!isPop ? "수정" : "닫기"}
                {isPop ? (
                  <div
                    style={{
                      position: "absolute",
                      right: 0,
                      top: 25,
                      borderRadius: "20px",
                      border: "1px solid #F6F6F6",
                      boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                      padding: "3px 0px",
                      backgroundColor: "white",
                      zIndex: 130009,
                    }}
                  >
                    {
                      type === 5
                        ? data["mobum_date"] && data["ans_date"]
                          ? sort.map((v, i) => (
                              <QTYPE
                                data={{ ...v, ...data }}
                                type={"manage_mobum"}
                                setIsPop={setIsPop}
                                apiPro={apiPro}
                              />
                            ))
                          : sort_2.map((v, i) => (
                              <QTYPE
                                data={{ ...v, ...data }}
                                type={"manage"}
                                setIsPop={setIsPop}
                                apiPro={apiPro}
                              />
                            ))
                        : data["mobum_date"] && data["ans_date"]
                          ? sort_3.map((v, i) => (
                              <QTYPE
                                data={{ ...v, ...data }}
                                type={"general_mobum"}
                                setIsPop={setIsPop}
                                apiPro={apiPro}
                              />
                            ))
                          : sort_4.map((v, i) => (
                              <QTYPE
                                data={{ ...v, ...data }}
                                type={"general"}
                                setIsPop={setIsPop}
                                apiPro={apiPro}
                              />
                            ))
                      // <div style={{
                      //     padding : "15px 0px",
                      //     width:"120px",
                      //     backgroundColor:"white",
                      //     display:"flex",
                      //     alignItems:"center",
                      //     justifyContent:"center",
                      //     fontWeight:400,
                      //     fontSize:"14px",
                      //     color : "#3D3A39",
                      //     borderRadius:"20px",
                      // }}
                      //     onClick={(e)=>{

                      //     }}
                      // >
                      //     {v["name"]}
                      // </div>
                      // )
                    }
                  </div>
                ) : (
                  <></>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const DotPop = ({ text, fnClick }) => {
  let params = useParams();

  return (
    <div
      style={{
        padding: "2px 0px 1px 0px",
        fontSize: "16px",
        color: params["who"] == "teacher" ? style.common.t_color : style.common.s_color,
        fontWeight: 500,
      }}
      onClick={(e) => {
        fnClick(e);
        e.stopPropagation();
        e.preventDefault();
      }}
    >
      {text}
    </div>
  );
};

const QTYPE = ({ data, type = "manager", setIsPop, apiPro }) => {
  let navigate = useNavigate();
  let params = useParams();

  const apiPro_del_indi = async (data) => {
    try {
      // console.log("DD ", data)
      await useDeleteQuestion(data?.id);
      await apiPro();
    } catch (e) {
      console.log(e);
    }
  };

  const apiPro_mobum = async (isModi) => {
    try {
      await useToggleExemplary(data?.id, isModi ? "del" : 0);
      // navigate(-1);
      if (isModi) {
        alert("선택된 질문이 모범풀이 해제되었어요.");
      } else {
        alert("선택된 질문이 모범풀이에 등록되었어요.");
      }
      util.postWeb("isModal_off");
      setIsPop(false);
      await apiPro();
    } catch (e) {
      console.log(e);
    }
  };
  const manage_mobum = (e) => {
    try {
      if (data["value"] === "a") {
        let isOk = window.confirm("모범 풀이 해제를 하시겠어요?");
        if (isOk) {
          apiPro_mobum(true);
        }
      }
      // 교재 이동
      else if (data["value"] === "b") {
        // console.log(data["id"])
        // setIsModal(true)
        // util.postWeb("isModal_off")
        navigate(`/explainmodel/${params["who"]}/${data["id"]}/move/n`);
        setIsPop(false);
      }
      // 교재 복사
      else if (data["value"] === "c") {
        // console.log(data["id"])
        // util.postWeb("isModal_off")
        navigate(`/explainmodel/${params["who"]}/${data["id"]}/copy/n`);
        setIsPop(false);
      }
      // 질문 삭제
      else if (data["value"] === "d") {
        let isOk = window.confirm("질문을 삭제하시겠어요?");
        if (isOk) {
          // apiPro_del(data)
          apiPro_del_indi(data);
          setIsPop(false);
        }
        e.preventDefault();
        e.stopPropagation();
      }
    } catch (e) {
      console.log("************");
      console.log(e);
      console.log("************");
    } finally {
      setIsPop(false);
    }
  };

  const manage = () => {
    try {
      // 모범풀이 등록
      if (data["value"] === "e") {
        let isOk = window.confirm("모범 풀이 등록을 하시겠어요?");
        if (isOk) {
          apiPro_mobum(false);
        }
      }
      // 교재 이동
      else if (data["value"] === "b") {
        console.log(data["id"]);
        setSelectData({ ...data });
        // util.postWeb("isModal_off")
        // navigate(`/explainmodel/${params["who"]}/${data["id"]}/move/n`)
        // setIsPop(false)
      }

      // 질문 삭제
      else if (data["value"] === "d") {
        let isOk = window.confirm("질문을 삭제하시겠어요?");
        if (isOk) {
          // apiPro_del(data)
          apiPro_del_indi(data);
          setIsPop(false);
        }
        e.preventDefault();
        e.stopPropagation();
      }
    } catch (e) {
      console.log("************");
      console.log(e);
      console.log("************");
    } finally {
      setIsPop(false);
    }
  };
  const general_mobum = () => {
    try {
      if (data["value"] === "a") {
        let isOk = window.confirm("모범 풀이 해제를 하시겠어요?");
        if (isOk) {
          apiPro_mobum(true);
        }
      }
      // 교재 이동
      else if (data["value"] === "b") {
        // console.log(data["id"])
        // util.postWeb("isModal_off")
        // navigate(`/explainmodel/${params["who"]}/${data["id"]}/move/n`)
        // setIsPop(false)
      }
      // 교재 복사
      else if (data["value"] === "c") {
        // console.log(data["id"])
        // util.postWeb("isModal_off")
        // navigate(`/explainmodel/${params["who"]}/${data["id"]}/copy/n`)
        // setIsPop(false)
      }
      // 질문 삭제
      else if (data["value"] === "d") {
        let isOk = window.confirm("질문을 삭제하시겠어요?");
        if (isOk) {
          // apiPro_del(data)
          apiPro_del_indi(data);
          setIsPop(false);
        }
        e.preventDefault();
        e.stopPropagation();
      }
    } catch (e) {
      console.log("************");
      console.log(e);
      console.log("************");
    } finally {
      setIsPop(false);
    }
  };

  const general = () => {
    try {
      // 모범풀이 등록
      if (data["value"] === "e") {
        let isOk = window.confirm("모범 풀이 등록을 하시겠어요?");
        if (isOk) {
          apiPro_mobum(false);
        }
      }
      // 교재 이동
      else if (data["value"] === "b") {
        console.log(data["id"]);
        // util.postWeb("isModal_off")
        // navigate(`/explainmodel/${params["who"]}/${data["id"]}/move/n`)
        // setIsPop(false)
      }

      // 질문 삭제
      else if (data["value"] === "d") {
        let isOk = window.confirm("질문을 삭제하시겠어요?");
        if (isOk) {
          // apiPro_del(data)
          apiPro_del_indi(data);
          setIsPop(false);
        }
        e.preventDefault();
        e.stopPropagation();
      }
    } catch (e) {
      console.log("************");
      console.log(e);
      console.log("************");
    } finally {
      setIsPop(false);
    }
  };

  return (
    <div
      style={{
        padding: "15px 0px",
        width: "120px",
        backgroundColor: "white",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        fontWeight: 400,
        fontSize: "14px",
        color: "#3D3A39",
        borderRadius: "20px",
        // zIndex:130009
      }}
      onClick={(e) => {
        // 모범풀이 해제
        console.log(data);
        if (type == "manage_mobum") {
          manage_mobum(e);
        } else if (type == "manage") {
          manage();
        } else if (type == "general_mobum") {
          general_mobum();
        } else if (type == "general") {
          general();
        }
      }}
    >
      {data["name"]}
    </div>
  );
};

export default UiQna_grid;
