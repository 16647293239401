import { ReactComponent as AddAnswerIcon } from "asset/images/icon/add-answer.svg";
import PdfUploadModal from "pages/StudyBookDetail/components/PdfUploadModal";
import React, { useState } from "react";
import { useParams } from "react-router";
import { MenuItemContainer } from "..";

const AnswerAdd = () => {
  const [open, setOpen] = useState(false);
  const { bookId } = useParams();

  return (
    <>
      <MenuItemContainer
        onClick={() => {
          setOpen(true);
        }}
      >
        해설 추가
        <AddAnswerIcon />
      </MenuItemContainer>
      {open && (
        <PdfUploadModal
          onClose={() => {
            setOpen(false);
          }}
          open={open}
          isSolution={true}
          bookId={bookId}
        />
      )}
    </>
  );
};

export default AnswerAdd;
