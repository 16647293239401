import { PdfType, usePdfList } from "api/pdfView";
import apiFn from "asset/apiClass";
import constants from "asset/constants";
import { getUser } from "asset/storage";
import CommonModal from "components/atoms/CommonModal";
import CommonRadio from "components/atoms/CommonRadio";
import { FontStyleMap } from "components/atoms/Font";
import HorizontalBlank from "components/atoms/HorizontalBlank";
import ReactiveButtonContainer from "components/atoms/ReactiveButtonContainer";
import { StyledCommonConfirmContainer } from "components/templates/Confirm";
import { showToast } from "providers/ToastProvider";
import React, { createContext, useCallback, useEffect, useMemo } from "react";
import { Controller, FormProvider, useController, useForm } from "react-hook-form";
import { useNavigate } from "react-router";
import styled from "styled-components";
import UiLoading from "uiComponents/UiLoading/UiLoading";
import PdfBoundaryViewer from "./components/PdfBoundaryViewer";

interface PdfUploadModalProps extends CommonModalControl {
  open: boolean;
  onClose: () => void;
  bookId?: number | string;
  isSolution?: boolean;
}

export interface PdfUploadForm {
  pdfFile: File;
  title: string;
  type: 1 | 2;
  isSolution: boolean;
  startPage: number;
  endPage: number;
  sourceName: string;
  startSourcePage: number;
}
export interface PdfUploadModalContextType {
  bookId?: number | string;
  isSolution?: boolean;
}

export const PdfUploadModalContext = createContext<PdfUploadModalContextType>({});

const PdfUploadModal = ({ open, onClose, bookId, isSolution }: PdfUploadModalProps) => {
  const methods = useForm<PdfUploadForm>();
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    control,
    trigger,
    unregister,
    formState: { errors, isValid, isLoading, isSubmitting },
  } = methods;
  const navigate = useNavigate();
  const userInfo = getUser();
  const { data: questionList } = usePdfList({ bookId, isAnswer: PdfType.question });
  const { data: answerList } = usePdfList({ bookId, isAnswer: PdfType.answer });

  const startSourcePage = useMemo(() => {
    const questionTotalPageNumber = questionList.qnaList.length + 1;
    const answerTotalPageNumber = answerList.qnaList.length + 1;
    if (!bookId) {
      return undefined;
    } else if (isSolution) {
      return answerTotalPageNumber;
    } else if (!isSolution) {
      return questionTotalPageNumber;
    } else {
      return undefined;
    }
  }, []);
  useController({
    name: "pdfFile",
    control: control,
    rules: { required: "파일을 선택해주세요" },
  });

  const pdfFile = watch("pdfFile");

  const onSubmit = useCallback(
    async ({ pdfFile, title, type, isSolution, startPage, endPage, sourceName }: PdfUploadForm) => {
      try {
        const uploadPath = `pdf/${userInfo.id}_${new Date().getTime()}.pdf`;
        let paramBookId = bookId;
        if (!bookId) {
          const book = await apiFn.comApi({
            state: {
              table: "book",
              ins: [
                {
                  name: title,
                  reg_date: 0,
                  reg_id: userInfo["id"],
                  book_type: type,
                },
              ],
            },
          });
          paramBookId = book.id;
        }

        const pdfResponse = await fetch(`${constants.apiUrl}/pdf`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            mid: userInfo.id,
            bid: paramBookId,
            path: uploadPath,
            name: sourceName,
            isSolution,
            startPage,
            endPage,
            ...(constants.academyId ? { academy_id: constants.academyId } : {}),
            startSourcePage,
          }),
        });

        if (pdfResponse.status !== 200) {
          alert("pdf 정보 등록 중 오류가 발생했습니다");
          onClose();
          return;
        }

        const pdf = await pdfResponse.json();

        /**
         * Commented out by Gordon Ahn.
         */
        //await apiFn.uploadFile(uploadPath, pdfFile)
        const bucketName =
          process.env.NODE_ENV === "production" && process.env.REACT_APP_BUILD_MODE === "live"
            ? "hiqsum-pdf"
            : "hiqsum-test-pdf";
        await apiFn.uploadFileToStorage(bucketName, uploadPath, pdfFile);

        const callback = async () => {
          const res = await fetch(`${constants.apiUrl}/pdf/${pdf.id}`);
          if (res.status !== 200) {
            return;
          }

          clearInterval(interval);

          onClose();
          navigate(`/explainmodel/teacher/${paramBookId}/de/book`);
        };

        const startInterval = (seconds, callback) => {
          callback();
          return setInterval(callback, seconds * 1000);
        };

        const interval = startInterval(3000, callback);
      } catch (e) {
        console.error(e);
        alert("PDF에서 교재 생성에 실패했습니다");
        onClose();
      }
    },
    [onClose, navigate]
  );

  const onError = useCallback(() => {
    (errors?.startPage?.message || errors?.startPage?.message) &&
      showToast({
        type: "error",
        title: "페이지수를 확인해 주세요.",
        message: errors.startPage.message,
      });
  }, []);

  useEffect(() => {
    trigger("pdfFile");
  }, [pdfFile]);

  useEffect(() => {
    setValue("isSolution", !!isSolution);
    return () => {
      unregister();
    };
  }, []);

  const title = useMemo(() => {
    if (bookId) {
      if (isSolution) {
        return "해설 추가";
      } else {
        return "문제 추가";
      }
    } else {
      return "교재 생성";
    }
  }, [bookId, isSolution]);

  return (
    <PdfUploadModalContext.Provider value={{ bookId, isSolution }}>
      <CommonModal open={open} onClose={onClose}>
        <ScrollContainer>
          {isSubmitting && <UiLoading text="문제 정보를 불러오고 있습니다." />}
          <FormProvider {...methods}>
            <form onSubmit={handleSubmit(onSubmit, onError)}>
              <Container>
                <TitleFont>{title}</TitleFont>
                <HorizontalBlank height={24} />
                {bookId ? null : (
                  <>
                    <Controller
                      name="type"
                      control={control}
                      defaultValue={1}
                      render={({ field }) => (
                        <CommonRadio
                          {...field}
                          itemList={[
                            {
                              value: 1,
                              label: "문제집",
                            },
                            {
                              value: 2,
                              label: "손풀이 교재",
                            },
                          ]}
                        />
                      )}
                    />
                  </>
                )}
                <PdfBoundaryViewer onClose={onClose} />
                <ReactiveButtonContainer
                  buttonList={[
                    {
                      onClick() {
                        onClose();
                      },
                      variant: "text",
                      title: "취소",
                    },
                    {
                      variant: "contained",
                      title: "확인",
                      type: "submit",
                      disabled: !pdfFile,
                    },
                  ]}
                />
              </Container>
            </form>
          </FormProvider>
        </ScrollContainer>
      </CommonModal>
    </PdfUploadModalContext.Provider>
  );
};

const Container = styled(StyledCommonConfirmContainer)`
  align-items: center;
  width: auto;
  min-width: 400px;
  max-width: 500px;
  @media screen and (max-width: 550px) {
    width: auto;
    min-width: 330px;
    max-width: 340px;
  }
`;

const ScrollContainer = styled.div`
  max-height: 90vh;
  overflow: auto;
`;
const TitleFont = styled.div`
  color: var(--neutral-color-natural-80, #141414);
  ${FontStyleMap["KR/bold/$KR.BL.BOL.16"]}
  @media screen and (min-width: 450px) {
    ${FontStyleMap["KR/bold/$KR.TS.BOL.20"]}
  }
`;

export default PdfUploadModal;
