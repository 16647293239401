import { useQuery } from "@tanstack/react-query";
import { getUser } from "asset/storage";
import { GetDefaultAcademyQuery } from "types/graphql/graphql";
import { gqlClient } from "./common";
import { GET_DEFAULT_ACADEMY } from "./graphql/academy";

export const useMyDefaultAcademy = () => {
  const user = getUser();
  return useQuery<GetDefaultAcademyQuery>(
    ["useMyDefaultAcademy", { memberId: user?.id }],
    async () => {
      return gqlClient.request(GET_DEFAULT_ACADEMY, { memberId: user?.id });
    },
    {
      enabled: !!user?.id,
      suspense: false,
    }
  );
};
