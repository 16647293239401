import {applyMiddleware, combineReducers, compose, createStore} from 'redux'
import thunkMiddleware from 'redux-thunk'

import monitorReducersEnhancer from './enhencers/monitorReducerEnhencer'
import {composeWithDevTools} from 'redux-devtools-extension'

import loggerMiddleware from './middleware/logger'

import rootReducer from "./reducers";


export default function configureStore(preloadedState) {
    const middlewares = [loggerMiddleware, thunkMiddleware]
    const middlewareEnhancer = applyMiddleware(...middlewares)

    const enhancers = [middlewareEnhancer, monitorReducersEnhancer]
    const composedEnhancers = process.env.NODE_ENV === 'production' ?
        compose(...enhancers)
        : composeWithDevTools(...enhancers)

    const store = createStore(rootReducer,
        preloadedState,
        composedEnhancers
    )

    if (process.env.NODE_ENV !== 'production' && module.hot) {
        module.hot.accept('./reducers', () => store.replaceReducer(rootReducer))
    }

    return store
}
