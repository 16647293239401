import { React, useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router";
import util from "../../asset/util";
import UiTicketLog_Style from "./UiTicketLog_Style";

const UiTicketLog = ({ data, isTeacher, fnClick, index }) => {
  return (
    <div
      style={{
        // ...UiTicketLog_Style.c,
        padding: "14px 10px",
        backgroundColor: index % 2 == 0 ? "#FFFBF5" : "#FBFBFB",
        marginBottom: "2px",
        borderRadius: "5px",
      }}
    >
      <div
        style={{
          display: "flex",
          fontSize: "14px",
          fontWeight: 400,
          color: "#3D3A39",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            display: "flex",
          }}
        >
          {isTeacher
            ? `${data?.student?.name || ''} 학생`
            : `${data?.teacher?.name || ''} 선생님`}
        </div>
        <div
          style={{
            ...UiTicketLog_Style.text_2,
            fontSize: "14px",
            fontWeight: 400,
            color: "#B0B0B0",
          }}
        >
          {/* 2022/02/05  */}
          {data?.datetime}
        </div>
      </div>
      <div style={UiTicketLog_Style.text_w_1}></div>
      <div style={UiTicketLog_Style.text_w_2}>
        <div
          style={{
            fontSize: "14px",
            fontWeight: 400,
          }}
        >
          {util.textTrim(data?.question?.content, 66)}
        </div>
      </div>
      <div style={UiTicketLog_Style.text_w_3}>
        <div
          style={{
            display: "flex",
          }}
        >
          <div
            style={{
              fontSize: "14px",
              fontWeight: 400,
            }}
          >
            {data?.ticket?.name}
          </div>
          {data?.quantity && (
            <div
              style={{
                fontSize: "14px",
                marginLeft: "8px",
                color: "#B0B0B0",
              }}
            >
              {`${Math.abs(parseInt(data.quantity))}개 ${
                data.quantity < 0 ? "사용" : "취소"
              }`}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default UiTicketLog;
