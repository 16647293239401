import { React, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { axiosInstance } from "../../api/common";
import apiFn from "../../asset/apiClass";
import constants from "../../asset/constants";
import style from "../../asset/style";
import util from "../../asset/util";
import UiBook from "../../uiComponents/UiBook/UiBook";
import UiTextBtn from "../../uiComponents/UiTextBtn/UiTextBtn";
import UiTopPopTextBtn from "../../uiComponents/UiTopPopTextBtn/UiTopPopTextBtn";
import Lecture_book_Style from "./Lecture_book_Style";

const Lecture_book = ({
  userInfo,
  qnaCount,
  hiddenNav,
  setHiddenNav,
  setHiddenUrl,
  lectureData_ori,
  lectureData,
  setIsModal_share,
  isModal_share,
}) => {
  let params = useParams();
  let navigate = useNavigate();

  const [isTeacher, setIsTeacher] = useState(true);

  var [selectData_book, setSelectData_book] = useState({});
  var [selectData_book_l, setSelectData_book_l] = useState(0);

  const [isEdit, setIsEdit] = useState(false);
  const [isBookEdit, setIsBookEdit] = useState(false);

  var [bookData, setBookData] = useState([]);

  var [isAuto, setIsAuto] = useState(false);

  const apiPro_read = async () => {
    try {
      let data = await apiFn.comApi({
        state: {
          table: "book",
          page: 1,
          amount: 9999,
          u_id: userInfo["id"],
          sort: `reg_date desc`,
          ref: [
            "HIQDB_book_subs.public as subs_public|HIQDB_book_link.bid=HIQDB_book_subs.bid",
            "HIQDB_book_link.mid|HIQDB_book_link.bid=HIQDB_book.id",
            "HIQDB_book_link.type|HIQDB_book_link.bid=HIQDB_book.id",
          ],
          out: ["HIQDB_book_subs.name as subs_name:bid:id"],
          where: {
            "HIQDB_book_link.cid": `= '${params["id"]}'`,
            "HIQDB_book.status": "= 1",
            // "HIQDB_book_link.status" : "!= 0",
          },
        },
      });

      console.log("BOOK DATA => ", data);

      // let bookList = []
      data["list"].map((v, i) => {
        if (v["type"] == 8 || v["type"] == 4) {
          v["name"] = v["subs_name"];
        }
        // bookList.push(v)
      });
      setBookData([...data["list"]]);
    } catch (e) {}
  };

  const apiPro_del = async () => {
    try {
      // bid : bid
      // point : cid
      let array = Object.keys(selectData_book).reduce((a, v, i) => {
        if (selectData_book[v] === 11) {
          alert("기본 교재는 다른 교재를 기본 설정 후 공유 취소할 수 있습니다.");
          return a;
        }
        return [...a, Number(v)];
      }, []);

      const { data } = await axiosInstance.delete("/book/share", {
        data: {
          classId: params.id,
          bookList: array,
          userId: userInfo.id,
        },
      });
      util.postWeb("isModal_off");
      await apiPro_read();
    } catch (e) {
      console.error(e);
    } finally {
      setIsEdit(false);
      setSelectData_book({});
    }
  };

  // useEffect(()=>{
  //     apiPro_read();
  // },[])

  useEffect(() => {
    if (!isModal_share) {
      apiPro_read();
    }
  }, [isModal_share]);

  useEffect(() => {
    setHiddenUrl("");
    // console.log("parmas => ",params)
    if (params["who"] == "teacher") {
      setIsTeacher(true);
    } else {
      setIsTeacher(false);
    }
  }, [params]);

  useEffect(() => {
    selectData_book_l = 0;

    Object.keys(selectData_book).map((v, i) => {
      if (selectData_book[v] == "Y") {
        selectData_book_l = selectData_book_l + 1;
      } else if (selectData_book[v] == "N") {
        if (selectData_book_l == 0) return;
        selectData_book_l = selectData_book_l - 1;
      }
    });

    // console.log("selectData_book => ",selectData_book);
    setSelectData_book_l(selectData_book_l);
  }, [selectData_book]);

  useEffect(() => {
    try {
      if (window.localStorage.getItem("isAuto")) {
        setIsAuto(JSON.parse(window.localStorage.getItem("isAuto")));
      }
      if (window.ReactNativeWebView) {
        document.addEventListener("message", async (e) => {
          if (e.data == "isModal") {
            setIsBookEdit(false);
          }
        });
      }
      return () => {
        try {
          document.removeEventListener("message");
        } catch (e) {}
      };
    } catch (e) {}
  }, []);

  return (
    <div
      style={{
        // width: "100%",
        marginTop: "20px",
        padding: "0px 20px 0px 20px",
      }}
    >
      {isBookEdit && (
        <UiTopPopTextBtn
          c_style={
            {
              // top : 30
            }
          }
          title="편집 취소"
          isTeacher={isTeacher}
          fnClick={() => {
            setIsBookEdit(false);
            setSelectData_book({});
            util.postWeb("isModal_off");
          }}
        />
      )}
      {isTeacher &&
        lectureData["type"] == 5 &&
        (isBookEdit ? (
          <div
            style={{
              padding: "0px 10px",
              // padding:"0px 20px 0px 20px",
            }}
          >
            <div
              style={{
                display: "flex",
              }}
            >
              {isBookEdit && selectData_book_l > 0 && (
                <div
                  style={{
                    ...Lecture_book_Style.edit_section_w,
                    borderTop: isBookEdit ? "none" : "1px solid",
                    borderColor: style.common.borderColor,
                  }}
                >
                  선택된 교재를
                  <div style={Lecture_book_Style.textBtn_w}>
                    {isTeacher && (
                      <UiTextBtn
                        text={"공유 취소"}
                        btnStyle={{
                          ...Lecture_book_Style.textBtn,
                          fontSize: style.common.select_fontSize,
                          color: style.common.fontColor_3,
                          marginRight: "7.5px",
                        }}
                        fnClick={() => {
                          let isOk = window.confirm(constants.confirm.cf_8);
                          if (isOk) {
                            apiPro_del();
                          }
                        }}
                      />
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        ) : (
          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "flex-end",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: "12px",
              }}
            >
              {params["who"] == "teacher" && isEdit ? (
                <div
                  style={{
                    display: "flex",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      fontWeight: 400,
                      color: "#3D3A39",
                      fontSize: "14px",
                    }}
                  >
                    <div
                      onClick={async () => {
                        if (selectData_book.length > 1 || selectData_book.length === 0) {
                          alert("기본 교재는 하나만 설정 가능 합니다");
                          return;
                        }
                        let isOk = window.confirm(
                          "수업 기본 교재로 설정하시겠어요?\n(해당 교재로 질문이 소속 됩니다)"
                        );
                        if (!isOk) return;
                        try {
                          console.log("selectedBook", selectData_book);
                          const result = await fetch(
                            `${constants.apiUrl}/class/${params.id}/default`,
                            {
                              method: "POST",
                              headers: { "Content-Type": "application/json" },
                              body: JSON.stringify({
                                userId: userInfo.id,
                                bookId: Object.keys(selectData_book)[0],
                                classroomId: constants.classroomId,
                              }),
                            }
                          );
                          if (result.status === 200) {
                            alert("교재가 기본 교재로 설정 되었습니다");
                          } else if (result.status === 403) {
                            const targetName = constants.isAcademy ? "학원장 소유" : "수업 소유자";
                            alert(
                              `수업 관리자와 소유자만 설정 할 수 있으며, ${targetName}의 교재만 설정 가능합니다.`
                            );
                          } else if (result.status === 404) {
                            alert("교재를 찾을 수 없습니다");
                          }
                        } catch (e) {
                          alert("교재를 기본 교재로 설정하는 도중에 오류가 발생했습니다.");
                          console.error(e);
                        } finally {
                          setIsEdit(false);
                          setSelectData_book([]);
                          apiPro_read();
                        }
                      }}
                    >
                      기본 교재 설정
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      fontWeight: 400,
                      color: "#3D3A39",
                      fontSize: "14px",
                      marginLeft: "10px",
                    }}
                  >
                    <div
                      style={{}}
                      onClick={() => {
                        let obj = {};
                        bookData.map((v) => {
                          obj[v["id"]] = v.type ?? String(v.type);
                        });
                        setSelectData_book({
                          ...obj,
                        });
                      }}
                    >
                      전체 선택
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      fontWeight: 400,
                      color: "#3D3A39",
                      fontSize: "14px",
                      marginLeft: "10px",
                    }}
                    onClick={() => {
                      setSelectData_book({});
                    }}
                  >
                    선택 취소
                  </div>
                  <div
                    style={{
                      display: "flex",
                      fontWeight: 400,
                      color: "#3D3A39",
                      fontSize: "14px",
                      marginLeft: "10px",
                    }}
                    onClick={() => {
                      // setIsEdit(false)
                      let isOk = window.confirm(constants.confirm.cf_8);
                      if (isOk) {
                        apiPro_del();
                      }
                    }}
                  >
                    공유취소
                  </div>
                  <div
                    style={{
                      display: "flex",
                      fontWeight: 400,
                      color: "#3D3A39",
                      fontSize: "14px",
                      marginLeft: "10px",
                    }}
                    onClick={() => {
                      setIsEdit(false);
                    }}
                  >
                    취소
                  </div>
                </div>
              ) : (
                <div
                  style={{
                    display: "flex",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      fontWeight: 400,
                      color: "#3D3A39",
                      fontSize: "14px",
                    }}
                  >
                    <div
                      style={{}}
                      onClick={() => {
                        setIsModal_share(true);
                      }}
                    >
                      교재공유
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      fontWeight: 400,
                      color: "#3D3A39",
                      fontSize: "14px",
                      marginLeft: "10px",
                    }}
                    onClick={() => {
                      // setIsEdit(true)
                      if (isTeacher && lectureData["type"] == 5) {
                        // setIsSearch(false)
                        setIsEdit(true);
                        util.postWeb("isModal");
                      }
                    }}
                  >
                    편집
                  </div>
                </div>
              )}
            </div>
            {/* <div style={Lecture_book_Style.t_btn_w}>
                    <UiBtn
                        btnStyle={Lecture_book_Style.t_btn}
                        title={
                            <div style={Academy_book_Style.btn_title}>
                                <Add style={Academy_book_Style.btn_Add} />
                                <div style={{
                                    paddingLeft : "12px"
                                }}>
                                    교재 공유 추가
                                </div>
                            </div>
                            // <div style={Lecture_book_Style.t_btn_text}>
                            //     <Add
                            //         style={Lecture_book_Style.t_btn_Add}
                            //     />
                            //     교재 공유 추가
                            // </div>
                        }
                        fnClick={()=>{
                            try {
                                setHiddenNav("book")
                                navigate(`/academyall/${params["who"]}/${userInfo["id"]}/all/${params["id"]}`)
                            }
                            catch(e){
                                // console.log("??? @@",e)
                            }
                        }}
                    />
                </div> */}
            {/* <div style={{
                    display:"flex",
                    alignItems:"center",
                }}>
                    <UiTextBtn
                        text={"권한교재 자동 참여"}
                        btnStyle={{
                            color : "#464241",
                            fontSize : "12px",
                        }}
                    />
                    <Switch
                        checked={isAuto}
                        onClick={(e)=>{
                            if(isAuto){
                                setIsAuto(false)
                                window.localStorage.setItem("isAuto", false)
                            }
                            else{
                                setIsAuto(true)
                                window.localStorage.setItem("isAuto", true)
                            }
                        }}
                    />
                </div> */}
          </div>
        ))}
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          width: "100%",
        }}
      >
        <div
          style={{
            display: "flex",
            // padding : "0px 10px",
            flexWrap: "wrap",
            // width:"80%",
            // justifyContent:"space-between"
          }}
        >
          {bookData.map((v, i) => {
            const normalLink =
              params["who"] === "teacher"
                ? `/book/${params["who"]}/${v["id"]}/0/de`
                : `/book/${params["who"]}/${v["id"]}/${v["classID"] ? v["classID"] : "subs"}/de`;
            const pdfLink = `/study-book/${v.id}`;
            const link = v.book_type === 1 ? pdfLink : normalLink;
            return (
              <div
                style={{
                  display: "flex",
                  flexGrow: 1,
                  maxWidth: "120px",
                  // justifyContent:"center",
                  // width : "calc((100vw - 40px)/3)"
                }}
              >
                <UiBook
                  data={v}
                  basicBook={v.type === 11}
                  //  key={"mark_folder_"+i}
                  //  id={i}
                  // isShare={isTeacher ? true : false}
                  navigate={navigate}
                  params={params}
                  navi={link}
                  setSelectData={setSelectData_book}
                  selectData={selectData_book}
                  isDefault={v.type === 11}
                  fnClick={() => {
                    setHiddenUrl("lecture_");
                    setHiddenNav("profile_book");
                  }}
                  isEdit={isEdit}
                  isTeacher={isTeacher}
                  // setIsSearch={setIsSearch}

                  fnLongClick={(d) => {
                    if (isTeacher && lectureData["type"] == 5) {
                      // setIsSearch(false)
                      setIsBookEdit(true);
                      util.postWeb("isModal");
                      setSelectData_book({
                        ...selectData_book,
                        [d["id"]]: d.type,
                      });
                    }
                  }}
                />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Lecture_book;
