import { useStudyBookRegist } from "api/studyBook";
import EmptyStoryBook from "asset/images/emptyStoryBook.svg";
import { ReactComponent as PlusIcon } from "asset/images/icon/plus-red-icon.svg";
import CommonModal from "components/atoms/CommonModal";
import { FontStyleMap } from "components/atoms/Font";
import Input from "components/atoms/Input";
import ReactiveButtonContainer from "components/atoms/ReactiveButtonContainer";
import React, { ChangeEvent, useCallback, useRef, useState } from "react";
import styled, { css } from "styled-components";
import { Container, DescriptionFont, TitleFont } from "./FolderRegistModal";

type StudyBookRegistModalProps = {} & CommonModalControl;
const StudyBookRegistModal = ({ open, onClose }: StudyBookRegistModalProps) => {
  const fileInputRef = useRef(null);
  const [isDragOver, setIsDragOver] = useState(false);
  const [file, setFile] = useState<File>(null);
  const [bookName, setBookName] = useState<string>("");
  const [imageFile, setImageFile] = useState<string>(null);

  const handlePlaceholderClick = () => {
    fileInputRef.current.click();
  };

  const handleDragOver = (event) => {
    event.preventDefault();
    setIsDragOver(true);
  };

  const handleDragLeave = () => {
    setIsDragOver(false);
  };

  const handleDrop = (event) => {
    event.preventDefault();
    handleFileChange(event);
    setIsDragOver(false);
  };
  const handleFileChange = (event) => {
    let files = event.dataTransfer ? event.dataTransfer.files : event.target.files;
    if (files.length > 0) {
      setFile(files[0]);

      const file = fileInputRef.current.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        setImageFile(reader.result as string);
      };
    }
  };

  const handleInputChange = useCallback(
    (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setBookName(event.target.value);
    },
    [setBookName]
  );

  const { mutateAsync } = useStudyBookRegist();
  const handleRegist = useCallback(async () => {
    await mutateAsync({
      bookName,
      file,
    });
    onClose();
  }, [bookName, file, mutateAsync]);
  return (
    <CommonModal open={open} onClose={onClose} title="교재 생성">
      <Container>
        <input
          type="file"
          accept="image/*"
          onChange={handleFileChange}
          ref={fileInputRef}
          style={{ display: "none" }} // 파일 입력 숨김
        />
        <TitleFont>교재 생성</TitleFont>
        <DescriptionFont>
          교재생성 후 수업에 공유해보세요.학생들의 질문을 받고 모범 답안을 공유할 수 있습니다.
        </DescriptionFont>
        <DragContainer
          onClick={handlePlaceholderClick}
          onDragOver={handleDragOver}
          onDragLeave={handleDragLeave}
          onDrop={handleDrop}
          isEmpty={!imageFile}
          dragOver={isDragOver}
        >
          <div>
            <img src={imageFile ?? EmptyStoryBook} alt="emptyStoryBook" />
          </div>
          <PlusIcon />
        </DragContainer>
        <ImageRegistFont />
        <InputContainer>
          <Input
            placeholder="교재명을 입력해주세요."
            label="교재이름"
            onChange={handleInputChange}
          />
        </InputContainer>
        <ReactiveButtonContainer
          buttonList={[
            {
              title: "취소",
              variant: "text",
              onClick: () => {
                onClose();
              },
            },
            {
              title: "확인",
              onClick: handleRegist,
            },
          ]}
        />
      </Container>
    </CommonModal>
  );
};

const DragContainer = styled.div<{ dragOver: boolean; isEmpty: boolean }>`
  width: 110px;
  height: 155px;
  margin: 24px auto 14px;
  position: relative;
  ${({ isEmpty }) =>
    isEmpty
      ? css`
          width: 110px;
          height: 155px;
        `
      : css`
          height: auto;
        `}
  >div {
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--neutral-color-natural-20, #f0f0f0);
    overflow: hidden;
    height: inherit;
    width: inherit;
    > img {
      ${({ isEmpty }) => css`
        width: ${isEmpty ? "85px" : "100%"};
      `}
    }
  }
  > svg {
    position: absolute;
    bottom: -10px;
    right: -10px;
  }
  ${({ dragOver }) =>
    dragOver &&
    css`
      background: var(--neutral-color-natural-30);
    `}
`;

const ImageRegistFont = styled.div`
  color: var(--neutral-color-natural-70, var(--Default-Gray-90, #262626));
  ${FontStyleMap["KR/medium/$KR.CAP.MD.12"]}
`;

const InputContainer = styled.div`
  width: 280px;
  margin: 0 auto 32px;
`;

export default StudyBookRegistModal;
