import { React , useState , useEffect } from 'react'
import { useParams , useNavigate } from 'react-router'
import style from '../../asset/style'
import Basic from '../../common/frame/Basic'
import RegiForm from '../before/register/RegiForm'
import aquery from '../../asset/Img/aquery.png'
import answer from '../../asset/Img/answer.png'
import { UiInput_title } from '../../uiComponents/UiInput/UiInput'
import { Register_One } from '../page_index'
import Register_One_style from '../before/register/Register_One_style'
import UiBtn from '../../uiComponents/UiBtn/UiBtn'
import DaumPostcode from 'react-daum-postcode'
import { UiSearchList, UiSearchSection } from '../../uiComponents/UiSearchList/UiSearchList'
import UiSearchBox from '../../uiComponents/UiSearch/UiSearchBox'
import UiTextBtn from '../../uiComponents/UiTextBtn/UiTextBtn'
import constants from '../../asset/constants'
import apiFn from '../../asset/apiClass'
import util from '../../asset/util'

const Academy_exist = ({
    userInfo
}) => {
    let params = useParams();
    let navigate = useNavigate();

    const [isTeacher ,setIsTeacher] = useState(false);
    const [isChk , setIsChk] = useState(false);

    const [mapData,setMapData] = useState({})

    var [searchData,setSearchData] = useState([])
    
    const [title,setTitle] = useState("")
    const [search,setSearch] = useState("")

    var [isLecture,setIsLecture] = useState(false)
    const [s_name,setS_name] = useState("")
    const [s_title,setS_title] = useState("");
    const [s_classData,setS_classData] = useState({})
    const [s_lectureData,setS_lectureData] = useState([])
    

    const apiPro_read = async () => {
        try {
            let data = await apiFn.comApi({
                state : {
                    table : "class",
                    u_id : userInfo["id"],
                    page : 1,
                    amount : 9999,
                    sort : "reg_date desc",
                    where:{
                        'HIQDB_class.type' : `='0'`,
                        'HIQDB_class.status':`!='9'`,  
                        [util.spaceTrim_qr("HIQDB_class.name")] : `like '%${util.spaceTrim(search)}%'`,
                        // [util.spaceTrim_qr("HIQDB_class.name")] : `= '${util.spaceTrim(search)}'`,
                    },
                    count : ['HIQDB_member_class.mid:cid:id|and countTable.type =\'0\'|lcnt']
                }
            })

            // // console.log("@@@ ",data["list"]);

            setSearchData([
                ...data["list"]
            ])
            setTitle(search)

        }
        catch(e){
        }
    }

     // 수업 검색
     const apiPro_read_2 = async () => {
        let data = await apiFn.comApi({
            state : {
                table : 'class',
                u_id : userInfo["id"],
                page : 1,
                amount : 9999,
                sort : "reg_date desc",
                where : {
                    "HIQDB_class.pid" : `='${s_classData["id"]}'`,
                    "HIQDB_class.name" : `like '%${util.spaceTrim(s_name)}%'`,
                    'HIQDB_class.status':`!='9'`,   
                    // "HIQDB_class.name" : `='${s_name}'`,
                    // `like '%${util.spaceTrim(bookName)}%'`
                },
                ref : [
                    // 'HIQDB_member_class.cid|HIQDB_member_class.cid=HIQDB_class.id',
                    // 'HIQDB_member_class.mid|HIQDB_member_class.cid=HIQDB_class.id',
                    `B.name as academyName|HIQDB_class B on B.id=HIQDB_class.pid`,
                ],
                count : ['HIQDB_member_class.mid:cid:id|and countTable.type =\'0\'|lcnt']
            }
        })
        // // console.log(data)
        // // console.log(data)
        setS_lectureData([
            ...data["list"]
        ])
    }


    const apiPro_regi = async (data) => {
        try {
            // console.log(data)
            // // console.log(data)
            // return;

            let sendMsg = {
                type : "hakwon_apply",
                orderMid : userInfo["id"],
                targetMid : userInfo["id"],
                cid : data["id"],
            }

            if(params["who"] == "student"){
                sendMsg["type"] = "student_apply"
            }
            let isOk = await apiFn.comApi({
                state : {
                    table : "member_class",
                    sendMsg : {
                        ...sendMsg
                    },
                    ins: [ 
                        {
                            mid : userInfo["id"], 
                            cid : data["id"],
                            type : isTeacher ? 6 : 0,
                            status : 0,
                            reg_date : 0,

                        } 
                    ],
                }
            })
            navigate('/academy/'+params["who"]+"/academy")
            // navigate(-1)
        }
        catch(e){
            // console.log("regi error => ",e);
        }
    }

    useEffect(()=>{
        if(params["who"] == "teacher"){
            setIsTeacher(true)
        }
        else {
            setIsTeacher(false)
        }
    },[params])
    
    useEffect(()=>{
        if(isLecture){
            apiPro_read_2();
        }
    },[isLecture])

    return (
        <Basic title={params["who"] == "teacher" ? "기존 클래스룸 가입" : "수업 가입 신청"}>
            {
            isLecture ?
            <>
            <div style={{
                display:"flex",
            }}>
                수업선택
            </div>
            <UiSearchSection
                data={s_classData}
                isTeacher={isTeacher}
            />
            <div style={{
                display:"flex",
                flexDirection:"column",
                fontSize : '14px',
                marginTop : "10px",
            }}>
                <UiSearchBox 
                    isTeacher={isTeacher}
                    title={"수업 검색"}
                    isColor={s_name ? false : true}
                    fnClick={()=>{
                        apiPro_read_2();
                        setS_title(s_name) 
                        // if(constants.isDev){
                        //     apiPro_read_2();
                        //     setS_title(s_name)  
                        // }
                        // else {
                        //     if(s_name){
                        //         apiPro_read_2();
                        //         setS_title(s_name)
                        //     }
                        // }
                    }}
                    fnChange={(e)=>{
                        setS_name(e.target.value)
                    }}
                />
            </div>
            {
            s_lectureData.length > 0 &&
            <div style={{
                marginTop : "10px",
            }}>
                <UiSearchList 
                    title={s_title == "" ? "전체 검색결과"  : '"'+s_title +'"'+ " 검색결과"}
                    title_add={`(${s_lectureData.length}건)`}
                    isTeacher={isTeacher}
                    form={2}
                    isSearch={true}
                    data={s_lectureData}
                    fnClick={(d)=>{
                        if(d["joinStatus"] == "1"){
                            window.alert("이미 수강중인 수업입니다.")
                        }
                        else{
                            let isOk;
                            isOk = window.confirm(`[ ${d["name"]} ]`+constants.confirm.cf_5)
                            if(isOk){
                                apiPro_regi(d);
                            }
                        }
                    }}
                />
            </div>
            }
            </>
            :
            <>
            <div style={{
                marginTop : "40px",
            }}>
                {/* <div style={{
                    fontSize : "12px",
                }}>
                    클래스룸 검색
                </div> */}
                <UiSearchBox 
                    title={"클래스룸 검색"}
                    isColor={search ? false : true}
                    inputClass={isTeacher ? "input_t" : "input_s"}
                    fnChange={(e)=>{
                        setSearch(e.target.value)
                    }}
                    fnClick={()=>{
                        if(constants.isDev){
                            apiPro_read();
                        }
                        else{
                            if(search){
                                apiPro_read();
                            }
                        }
                    }}
                    isTeacher={isTeacher}
                />
            </div>
            {/* <div style={{
                display : "flex",
                justifyContent:"center",
                width : "100%",
                marginTop : "10px",
            }}>
                <UiTextBtn 
                    text={"내가 소속된 클래스룸이 검색되지 않아요."} 
                    btnStyle={{
                        color : style.common.fontColor_1,
                        fontSize : "12px",
                    }}
                />
            </div> */}

            {
            searchData.length > 0 &&
            <div style={{
                marginTop : "30px",
            }}>
                <UiSearchList 
                    isSearch={true}
                    title={'"'+title +'"'+ " 검색결과"}
                    title_add={`(${searchData.length}건)`}
                    data={searchData}
                    isTeacher={params["who"] == "teacher" ? true : false}
                    fnClick={(d)=>{
                        if(isTeacher){
                            if(d["joinStatus"] == "1"){
                                window.alert("이미 가입된 클래스룸입니다.")
                            }
                            else {
                                let isOk = window.confirm(`[ ${d["name"]} ]`+constants.confirm.cf_4)
                                if(isOk){
                                    window.alert("등록이 신청되었어요.")
                                    apiPro_regi(d);
                                }
                                else {
                                    return;
                                    // window.alert("###############################")
                                }
                            }
                        }
                        else {
                            // window.confirm("김이박 선생님의[수학 만점왕]"+constants.confirm.cf_5)
                            // navigate(`/lecturelist/${params["who"]}/${d["id"]}`)
                            setS_classData({
                                ...d
                            })
                            setIsLecture(true)
                        }
                    }}
                />
            </div>
            }
            </>
            }
           
        </Basic>
    )
}

export default Academy_exist
