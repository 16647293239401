import { React, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import API from "../../api";
import icon_setting_gray from "../../asset/Img/icon_setting_gray.png";
import apiFn from "../../asset/apiClass";
import style from "../../asset/style";
import util from "../../asset/util";
import UiBook from "../../uiComponents/UiBook/UiBook";
import { Frame } from "../page_index";
import Modal_ from "./Modal_";

const Book_folder = ({ userInfo }) => {
  let params = useParams();
  let navigate = useNavigate();

  const [isTeacher, setIsTeacher] = useState(true);

  var [bookList, setBookList] = useState([]);

  var [selectData_book, setSelectData_book] = useState({});

  var [isModiFolder, setIsModiFolder] = useState(false);

  const [isBookEdit, setIsBookEdit] = useState(false);

  // const [name , setName] = useState("")

  const [title, setTitle] = useState("");

  // 교재 리스트
  const apiPro_read = async () => {
    try {
      // console.log("@@@@@@@@@ ")
      let isOk;
      // let fid_value =
      if (params["who"] == "teacher") {
        let where_obj = {
          "HIQDB_book_link.mid": `= '${userInfo["id"]}'`,
          "HIQDB_book.status": `!= '9'`,
          "HIQDB_book_link.fid": `= '${params["id"]}'`,
        };
        isOk = await apiFn.comApi({
          state: {
            table: "book",
            page: 1,
            amount: 999,
            u_id: userInfo["id"],
            sort: "reg_date desc",
            ref: ["HIQDB_book_link.cid|HIQDB_book_link.bid=HIQDB_book.id"],
            where: where_obj,
          },
        });
      } else {
        isOk = await apiFn.studentBooklist({
          state: {
            sid: userInfo["id"],
            page: 1,
            amount: 9999,
            sort: "reg_date desc",
            fid: params["id"],
          },
        });
      }
      console.log("### ", isOk);

      setBookList(
        isOk.list.reduce((acc, cur) => {
          if (!acc.map(({ id }) => id)?.includes(cur.id)) acc.push(cur);
          return acc;
        }, [])
      );
    } catch (e) {
      console.log("** => ", e);
    }
  };

  const apiPro_read_folder = async () => {
    try {
      let isOk = await apiFn.comApi({
        state: {
          table: "folder",
          page: 1,
          sort: "name desc",
          amount: 9999,
          where: {
            "HIQDB_folder.id": `= '${params["id"]}'`,
          },
        },
      });
      setTitle(isOk["list"][0]["name"]);
    } catch (e) {}
  };

  const apiPro_modi_folder = async (name) => {
    try {
      let isOk = await apiFn.comApi({
        state: {
          table: "folder",
          set: [
            {
              key: `id:${params["id"]}`,
              value: { name: name },
            },
          ],
        },
      });

      setIsModiFolder(false);
      await apiPro_read_folder();
      // window.alert("변경되었습니다.")
      // navigate(-1)
    } catch (e) {}
  };

  // 교재 삭제
  const apiPro_book_del = async () => {
    try {
      let promiseArray = Object.keys(selectData_book).map((v) =>
        apiFn.deleteBook({
          state: {
            boid: v,
            orderMid: userInfo["id"],
          },
        })
      );
      await Promise.all(promiseArray);

      setSelectData_book({});
      setIsBookEdit(false);
    } catch (e) {
      console.log("************");
      console.log(e);
      console.log("************");
    } finally {
      await apiPro_read();
    }
  };

  // 폴더 내 교재 삭제
  const apiPro_del_folder = async () => {
    try {
      let bid_array = Object.keys(selectData_book);
      console.log(bid_array);
      let confrim = window.confirm(
        `선택하신 ${bid_array.length}개의 교재를 폴더에서 삭제하시겠어요? (*선택하신 교재는 폴더 밖으로 이동됩니다.)`
      );
      if (!confrim) return;

      bid_array = bid_array.reduce((a, v, i) => {
        return [
          ...a,
          {
            // key : `bid : ${v} and mid : ${userInfo["id"]}`
            key: `bid:${v}' and mid='${userInfo["id"]}`,
            value: {
              fid: 0,
            },
          },
        ];
      }, []);

      // console.log("bid_array => " ,bid_array)

      let isOK = await apiFn.comApi({
        state: {
          table: "book_link",
          set: bid_array,
        },
      });

      await apiPro_read();
    } catch (e) {}
  };

  // 교재 삭제
  const apiPro_del = async (pw, bid) => {
    try {
      // let isLogin = await apiFn.fnSignChk({
      //     state : {
      //         id : userInfo["id"],
      //         pw : pw
      //     }
      // })

      // // console.log("isLogin -=> ",isLogin)
      // if(!isLogin)return;
      let bid_array = Object.keys(selectData_book).reduce((a, v, i) => {
        return [
          ...a,
          {
            // key : `bid : ${v} and mid : ${userInfo["id"]}`
            bid: v,
            mid: userInfo["id"],
          },
        ];
      }, []);

      // console.log("bid_array => " ,bid_array)

      let isOK = await apiFn.comApi({
        state: {
          table: "book_link",
          del: bid_array,
        },
      });
      setIsBookEdit(false);
      setSelectData_book({});
      await apiPro_read();
    } catch (e) {
      util.c_err_log(`apiPro_del Error => ${e}`);
    }
  };

  const getFolderBook = async (folderId, userId) => {
    try {
      const { folderData } = await API.getFolderBooks(folderId, userId);
      setBookList(folderData?.HIQDB_book_links);
      setTitle(folderData.name);
    } catch (e) {
      console.error(e);
      alert("교재 목록을 가져오는데 실패했습니다.");
    }
  };
  const getCurrentClassBook = async (userId) => {
    try {
      const { bookList } = await API.getCurrentClassBooks(userId, "participation=true");
      setBookList(bookList);
      setTitle("수업에 의한 열람");
    } catch (e) {
      console.error(e);
      alert("교재 목록을 가져오는데 실패했습니다.");
    }
  };

  useEffect(() => {
    if (params["who"] == "teacher") {
      setIsTeacher(true);
    } else {
      setIsTeacher(false);
    }
  }, [params]);

  // useEffect(() => {
  //     selectData_book_l = 0;
  //     Object.keys(selectData_book).map((v, i) => {
  //         if (selectData_book[v] == "Y") {
  //             selectData_book_l = selectData_book_l + 1
  //         } else if (selectData_book[v] == "N") {
  //             if (selectData_book_l == 0) return;
  //             selectData_book_l = selectData_book_l - 1
  //         }
  //     })
  //     setSelectData_book_l(selectData_book_l)
  // }, [selectData_book])

  useEffect(() => {
    if (userInfo.id && params.id !== "0") {
      getFolderBook(params.id, userInfo.id);
    } else if (params.id === "0") {
      getCurrentClassBook(userInfo.id);
    }
  }, []);

  return (
    <Frame
      title={
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "flex-end",
            flex: 1,
          }}
        >
          {title ? title : "(이름 없음)"}
          <div
            style={{
              fontWeight: 400,
              fontSize: "14px",
              color: "#8F8F8F",
            }}
            onClick={() => {
              setIsModiFolder(true);
            }}
          >
            폴더 이름 변경
          </div>
        </div>
      }
      type="3"
      isSearch={false}
      userInfo={userInfo}
    >
      <>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            flex: 1,
            width: "100%",
            height: "100%",
            padding: "16px 20px 20px 20px",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginBottom: "12px",
            }}
          >
            {
              // params["who"] == "teacher" &&
              isBookEdit ? (
                <div
                  style={{
                    display: "flex",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      color: "#3D3A39",
                      fontWeight: 400,
                      fontSize: "14px",
                    }}
                  >
                    <div
                      style={{}}
                      onClick={() => {
                        let obj = {};
                        bookList.map((v) => {
                          obj[v["id"]] = "Y";
                        });
                        setSelectData_book({
                          ...obj,
                        });
                      }}
                    >
                      전체 선택
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      fontWeight: 400,
                      color: "#3D3A39",
                      fontSize: "14px",
                      marginLeft: "10px",
                    }}
                    onClick={() => {
                      setSelectData_book({});
                    }}
                  >
                    선택 취소
                  </div>
                  <div
                    style={{
                      display: "flex",
                      fontWeight: 400,
                      color: "#3D3A39",
                      fontSize: "14px",
                      marginLeft: "10px",
                    }}
                    onClick={() => {
                      setIsBookEdit(false);
                      setSelectData_book({});
                    }}
                  >
                    취소
                  </div>
                  <div
                    style={{
                      display: "flex",
                      fontWeight: 400,
                      color: style.common.fontColor_3,
                      fontSize: "14px",
                      marginLeft: "10px",
                    }}
                    onClick={() => {
                      apiPro_del_folder();
                    }}
                  >
                    폴더 내 교재 삭제
                  </div>
                  {params["who"] == "teacher" && Object.keys(selectData_book).length > 0 && (
                    <div
                      style={{
                        display: "flex",
                        fontWeight: 400,
                        color: style.common.fontColor_3,
                        fontSize: "14px",
                        marginLeft: "10px",
                      }}
                      onClick={() => {
                        let isOk = window.confirm(
                          `선택하신 ${Object.keys(selectData_book).length}개의 교재를 삭제하시겠어요?`
                        );
                        if (isOk) apiPro_book_del();
                        // console.log("??")
                      }}
                    >
                      선택 교재 삭제
                    </div>
                  )}
                </div>
              ) : (
                <img
                  src={icon_setting_gray}
                  onClick={() => {
                    setIsBookEdit(true);
                    // setIsModi(true)
                  }}
                  style={{
                    width: "16px",
                    height: "16px",
                  }}
                />
              )
            }
          </div>
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              width: "100%",
            }}
          >
            {bookList?.map((v, i) => {
              const normalLink =
                params["who"] === "teacher"
                  ? `/book/${params["who"]}/${v["id"]}/0/de`
                  : `/book/${params["who"]}/${v["id"]}/${v["classID"] ? v["classID"] : "subs"}/de`;
              const pdfLink = `/study-book/${v.id}`;
              const link = v.book_type === 1 ? pdfLink : normalLink;
              return (
                <div
                  style={{
                    display: "flex",
                    flexGrow: 1,
                    maxWidth: "120px",
                    // justifyContent:"center",
                    // width : "calc((100vw - 40px)/3)"
                  }}
                >
                  <UiBook
                    data={v}
                    key={"mark_folder_" + i}
                    id={i}
                    isOuth={v["type"]}
                    navigate={navigate}
                    params={params}
                    // navi={"/academybook/"+params["who"]+"/"+3}
                    navi={link}
                    setSelectData={setSelectData_book}
                    selectData={selectData_book}
                    isEdit={isBookEdit}
                    isTeacher={isTeacher}
                    // setIsSearch={setIsSearch}

                    fnLongClick={(data) => {
                      // setIsSearch(false)
                      setIsBookEdit(true);
                      setSelectData_book({
                        ...selectData_book,
                        [data["id"]]: "Y",
                      });
                    }}
                    fnCheck={(e, data) => {
                      if (selectData_book[data["id"]] == "Y") {
                        delete selectData_book[data["id"]];
                        setSelectData_book({
                          ...selectData_book,
                        });
                      } else {
                        setSelectData_book({
                          ...selectData_book,
                          [data["id"]]: "Y",
                        });
                      }
                    }}
                  />
                </div>
              );
            })}
            {bookList?.length == 0 && <div>폴더에 저장된 교재가 없습니다.</div>}
          </div>
        </div>
      </>
      {isModiFolder && (
        <Modal_
          title={"폴더명 수정"}
          placeholder={"폴더명을 입력해주세요."}
          setIsModal={setIsModiFolder}
          userInfo={userInfo}
          apiPro_modi_folder={apiPro_modi_folder}
        />
      )}
    </Frame>
  );
};

export default Book_folder;
