import { InsertLinkRounded, PlayCircleOutlineRounded } from "@mui/icons-material";
import { useClassDetailInfo } from "api/classDetail";
import { ClassRegistRequset } from "api/classRegist";
import CloudIcon from "asset/Img/cloud_upload.svg";
import ErrorMessage from "components/atoms/ErrorMessage";
import React, { useEffect, useMemo, useState } from "react";
import { useController, useFormContext } from "react-hook-form";
import { useParams } from "react-router";
import styled from "styled-components";
import ClassVideoArea from "./ClassVideoArea";
import ClassVideoLinkArea from "./ClassVideoLinkArea";

const FileUpload = () => {
  const params = useParams();
  const {
    setValue,
    register,
    watch,
    control,
    formState: { isSubmitted, errors },
  } = useFormContext<ClassRegistRequset>();

  // const {} = useClassUpdateInfo(params.boardId);
  const { data } = useClassDetailInfo({ boardId: params.boardId });
  const videoLink = watch("video_link");
  const videoFiles = watch("videoFiles");
  const videoFile = watch("videoFile");
  const loadVideoUrl = watch("loadVideoUrl");
  const [isShowLink, setIsShowLink] = useState<boolean>(!!videoLink);
  const [isShowVideo, setIsShowVideo] = useState<boolean>(!!params.boardId && !videoLink);

  const originalVideoLink = useMemo(() => {
    return data?.getBoard?.boards[0]?.video_link;
  }, [data]);

  const isUploadVideo = useMemo(
    () => typeof videoFiles === "object" && !!videoFiles?.[0],
    [videoFiles]
  );

  useEffect(() => {
    params.boardId && !originalVideoLink && setValue("loadVideoUrl", params.boardId);
  }, [originalVideoLink, params.boardId]);

  useController({
    name: "loadVideoUrl",
    control,
    rules: {
      required: "동영상을 선택해주세요",
    },
  });

  return (
    <Container>
      <input
        id={"lectureVideoUploadInput"}
        type={"file"}
        accept={"video/*"}
        multiple={false}
        {...register("videoFiles")}
      />
      {isShowVideo || isUploadVideo ? (
        <ClassVideoArea onCancel={() => setIsShowVideo(false)} />
      ) : isShowLink ? (
        <ClassVideoLinkArea onCancel={() => setIsShowLink(false)} />
      ) : (
        <>
          <IconContainer htmlFor={"lectureVideoUploadInput"}>
            <img src={CloudIcon} />
            <span>등록할 영상을 선택해주세요.</span>
          </IconContainer>
          <ButtonContainer>
            <FileUploadButton htmlFor={"lectureVideoUploadInput"}>
              <PlayCircleOutlineRounded />
              동영상 업로드
            </FileUploadButton>
            <LinkUploadButton onClick={() => setIsShowLink(true)} type="button">
              <InsertLinkRounded />
              영상 링크 등록
            </LinkUploadButton>
          </ButtonContainer>
        </>
      )}
      <ErrorMessage>
        {!(isShowVideo || videoLink) && isSubmitted
          ? (errors.videoFile || errors.video_link)?.message
          : null}
      </ErrorMessage>
    </Container>
  );
};

const Container = styled.div`
  & > input {
    display: none;
  }
`;

const IconContainer = styled.label`
  border: none;
  background-color: inherit;
  margin-top: 66px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 64px;
  color: #292929;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  & img {
    width: 135.991px;
    height: 98px;
  }
`;

const FileUploadButton = styled.label`
  border-radius: 10px;
  background: #ffa723;
`;

const LinkUploadButton = styled.button`
  border-radius: 10px;
  background: #ffdaa4;
  color: #ff9900 !important;
  & svg {
    transform: rotate(45deg);
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  margin-top: 44px;
  gap: 24px;
  & button,
  label {
    outline: 0;
    border: 0;
    display: flex;
    padding: 10px;
    align-items: center;
    justify-content: center;
    gap: 10px;
    flex: 1;
    color: #ffffff;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
`;

export default FileUpload;
