import style from "../../asset/style"

const UiOrderLog_Style = {
    c : {
        margin : "0px 0px 15px 0px",
        backgroundColor : 'white',
    },
    text_1 : {
        fontSize : "14px"
    },
    text_2 : {
        fontSize : "11px",
        padding : "0px 0px 3px 7px",
        color : style.common.fontColor_1
    },
    text_3 : {
        fontSize : "12px",
        color : style.common.fontColor_1
    },
    text_4 : {
        display:"flex",
        fontSize : "11px",
        // color : style.common.fontColor_1
        color : style.common.fontColor_6
    },
    text_w_1 : {
        display:"flex",
        alignItems : "center",
        padding : "3px 0px",
    },
    text_w_2 : {
        display:"flex",
        padding : "3px 0px",
        fontWeight: 800,
        fontSize: 14,
    },
    text_w_3 : {
        display:"flex",
        padding : "5px 0px",
    },

}

export default UiOrderLog_Style