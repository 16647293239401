import { useQuery } from "@apollo/client";
import { ArrowDropDown } from "@material-ui/icons";
import { PaymentContext } from "asset/context";
import dayjs from "dayjs";
import SelectTicket from "pages/Payment/Entrance/SelectTicket";
import { React, useContext, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { CheckPaymentLectureDocument } from "types/graphql/graphql";
import { PdfType, usePdfList } from "../../api/pdfView";
import add_qna from "../../asset/Img/add_qna.png";
import noBook from "../../asset/Img/book1.png";
import bookmark_on from "../../asset/Img/bookmark_on.png";
import icon_camera from "../../asset/Img/icon_camera.png";
import icon_setting_gray from "../../asset/Img/icon_setting_gray.png";
import icon_text from "../../asset/Img/icon_text.png";
import apiFn from "../../asset/apiClass";
import constants from "../../asset/constants";
import store from "../../asset/store";
import style from "../../asset/style";
import util from "../../asset/util";
import UiNotice from "../../uiComponents/UiNotice/UiNotice";
import UiQuestion from "../../uiComponents/UiQuestion/UiQuestion";
import UiTextBtn from "../../uiComponents/UiTextBtn/UiTextBtn";
import Profile_class_Style from "../Profile/Profile_class_Style";
import PdfImgPreview from "./components/PdfImgPreview";

let Qstyle = {
  wrapper: {
    display: "flex",
    fontSize: "14px",
    fontWeight: 400,
    justifyContent: "space-between",
  },
  box: {
    // width:"152px",
    // width:"120px",
    height: "15px",
    padding: "10px 5px",
    backgroundColor: "white",
    display: "flex",
    flex: 1,
    alignItems: "center",
    justifyContent: "space-around",
    borderRadius: "5px",
    border: "1px solid #EEEEEE",
    fontSize: "0.6rem",
    fontWeight: 500,
  },
  box_icon: {
    // width : "16px",
    // height : "16px",
    width: "1.3rem",
    height: "1.3rem",
  },
  number_box: {
    width: "1.2rem",
    height: "0.7rem",
    backgroundColor: "#EFD9B4",
    color: "white",
    borderRadius: "8px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "0.5rem",
    fontWeight: 400,
    padding: "1.5px 0px",
  },
};

const Book_book = ({
  setIsModi,
  setIsText,
  apiPro_img,
  isFull,
  setIsFull,
  bookData,
  userInfo,
  subsData,
  isTeacher,
  setFileObj,
  fileObj,
  qnaData,
  setQnaData,
  setHiddenUrl,
  qnaCount,
  myInfo,
  apiPro_read_link,
  linkData,
  explainData,
  setExplainData,
  explainPage,
  setExplainPage,
}) => {
  let navigate = useNavigate();
  let params = useParams();

  var [noticeData, setNoticeData] = useState([]);
  var [classData, setClassData] = useState({});

  const { isPaymentModalOpen, setPaymentModalOpen, selectedLectureId, pickResult } =
    useContext(PaymentContext);

  var [qList, setQList] = useState([]);

  const answerResData = usePdfList({ bookId: params.id, isAnswer: PdfType.answer });
  const answerList = useMemo(() => answerResData.data.qnaList, [answerResData.data.qnaList]);
  const qnaResData = usePdfList({ bookId: params.id, isAnswer: PdfType.question });
  const qnaList = useMemo(() => qnaResData.data.qnaList, [qnaResData.data.qnaList]);

  const [selectedAcademyId, setSelectedAcademyId] = useState(null);

  // 결제 기능 체크
  const { data: checkLecture } = useQuery(CheckPaymentLectureDocument, {
    fetchPolicy: "network-only",
    variables: { lectureId: String(selectedLectureId) },
    skip: !selectedLectureId,
  });

  // 공지사항
  const apiPro_read = async () => {
    try {
      let data = await apiFn.comApi({
        state: {
          table: "board",
          page: 1,
          amount: 9999,
          sort: "important desc",
          where: {
            "HIQDB_board.bid": `='${params["id"]}'`,
          },
        },
      });
      const resultList = data["list"].map((item) => {
        return {
          ...item,
          reg_date: dayjs(item.reg_date, "YYYYMMDDHHmmSS").unix(),
        };
      });
      setNoticeData(resultList);
    } catch (e) {}
  };

  // 클래스룸 정보
  const apiPro_read_2 = async () => {
    try {
      let data = await apiFn.comApi({
        state: {
          table: "class",
          page: 1,
          amount: 9999,
          sort: "reg_date desc",
          where: {
            "HIQDB_class.id": `='${params["cid"]}'`,
          },
          ref: [`B.name as academyName|HIQDB_class B on B.id=HIQDB_class.pid`],
        },
      });
      // console.log("data => ",data["list"])
      setClassData({
        ...data["list"][0],
      });
    } catch (e) {}
  };

  const apiPro_regi = async (data, bid) => {
    try {
      let isOk = await apiFn.comApi({
        state: {
          table: "book_link",
          page: 1,
          amount: 9999,
          sort: "mid desc",
          where: {
            "HIQDB_book_link.mid": `= '${userInfo["id"]}'`,
            "HIQDB_book_link.bid": `= '${params["id"]}'`,
          },
        },
      });
      if (isOk["list"].length > 0) {
      } else {
        await apiFn.comApi({
          state: {
            table: "book_link",
            ins: [
              {
                mid: userInfo["id"],
                bid: params["id"],
                type: 6,
                status: 0,
              },
            ],
          },
        });
        await apiPro_read_link();
      }
    } catch (e) {
      // console.log("@@ ",e)
    }
  };

  const apiPro_img_bookCover = async (e) => {
    try {
      const name = `/bookCover/${params["id"]}.jpg`;
      setFileObj({
        // img : img,
        // bookName : bookName,
        name: name,
        file: e,
      });
      navigate("/crop/teacher/rectangle");

      // let reader = new FileReader();
      // let file = e.target.files[0];
      // reader.readAsDataURL(file)

      // let _img = await util.toBase64(file);
      // const result =  await store.putBucket(_img, name);
      // // console.log(result);
      // await apiPro_read_2();
    } catch (e) {
      // console.log("apiPro_img Error= >",e)
    }
  };

  const apiPro_img_qna = async (e, data) => {
    try {
      setFileObj({
        name: ``,
        file: e,
      });
      setHiddenUrl(window.location.pathname);
      navigate(`/crop/${params["who"]}/se`);
    } catch (e) {
      // console.log("apiPro_img Error= >",e)
    }
  };

  const apiPro_del = async (navi) => {
    try {
      // let isOk = apiFn.comApi({
      //     state : {
      //         table : "book_link",
      //         del : [{
      //             mid : userInfo["id"],
      //             bid : params["id"]
      //         }]
      //     }
      // })

      let isOk = await apiFn.exitBook({
        state: {
          orderMid: userInfo["id"],
          targetMid: userInfo["id"],
          boid: params["id"],
        },
      });
      if (navi) {
        const stack = store.get("stack");
        if (stack.length > 0) navigate(-1);
        else navigate("/");
      } else {
        await apiPro_read_link();
      }
    } catch (e) {}
  };

  const apiPro_approve = async () => {
    try {
      let isOk = await apiFn.comApi({
        state: {
          table: "book_link",
          // sendMsg : {
          //     type : "book_invite",
          //     targetMid : d["id"],
          //     orderMid : userInfo["id"],
          //     boid : bid
          // },
          sendMsg: {
            type: "book_okay",
            orderMid: userInfo["id"],
            targetMid: userInfo["id"],
            boid: params["id"],
            channel: constants.channelId,
          },
          set: [
            {
              key: `mid:${userInfo["id"]}' and bid='${params["id"]}`,
              value: {
                type: 6,
                status: 1,
              },
            },
          ],
        },
      });

      await apiPro_read_link();
    } catch (e) {
      // console.log("error => ",e);
    }
  };

  const apiPro_find_link = async () => {
    try {
      console.log("@@@@");

      // 교재가 링크되어있는 강의 목록
      let c_data = [];

      // 가입되어있는 강의 목록
      let data = await apiFn.comApi({
        state: {
          table: "member_class",
          page: 1,
          amount: 9999,
          sort: "reg_date desc",
          where: {
            "HIQDB_member_class.mid": `= '${userInfo["id"]}'`,
          },
        },
      });
      console.log(data);

      // 가입되어있는 강의 목록 ID 값 필터링
      let in_id = data["list"].reduce((a, v, i) => {
        // return v["mid"].toString()
        c_data.push(v["cid"]);
        if (i == 0) {
          return (a = `'${v["cid"]}'`);
        } else {
          return (a = a + `,'${v["cid"]}'`);
        }
      }, "");

      console.log("in_id : ", in_id);

      // 강의 목록 중 현재 셀렉되어있는 교재가 링크된 목록
      let book_link = await apiFn.comApi({
        state: {
          table: "book_link",
          page: 1,
          amount: 9999,
          sort: "mid desc",
          where: {
            "HIQDB_book_link.cid": `in (${in_id})`,
            "HIQDB_book_link.bid": `= '${params["id"]}'`,
          },
        },
      });
      console.log("book_link Data : ", book_link);

      // 강의 목록 중 현재 셀렉되어있는 교재가 링크된 목록에서 cid 필터링
      let in_id2 = book_link["list"].reduce((a, v, i) => {
        // return v["mid"].toString()
        if (i == 0) {
          return (a = `'${v["cid"]}'`);
        } else {
          return (a = a + `,'${v["cid"]}'`);
        }
      }, "");

      // 필터링된 cid가 point column에 들어있는 목록 (열람자)
      let subs_link = await apiFn.comApi({
        state: {
          table: "book_link",
          page: 1,
          amount: 9999,
          sort: "mid desc",
          where: {
            "HIQDB_book_link.point": `in (${in_id2})`,
            "HIQDB_book_link.bid": `= '${params["id"]}'`,
          },
        },
      });

      console.log("subs_link : ", subs_link);
      // 필터링된 cid가 point column에 들어있는 목록 (열람자)들 필터링
      let del_array = subs_link["list"].reduce((a, v, i) => {
        return [
          ...a,
          {
            "HIQDB_book_link.bid": v["bid"],
            "HIQDB_book_link.mid": v["mid"],
            "HIQDB_book_link.point": v["point"],
          },
        ];
      }, []);

      console.log("delArray : ", del_array);
      console.log("delArray : ", del_array);

      // 교재 구독자가 구독 취소 할 때 공유된 모든 교재 링크 제거 + 공유된 강의 소속 열람자들 링크 제거

      // 1.강의에 연결된 교재 링크 제거
      // 2.그 각각의 강의에 링크된 열람자들 권한 제거

      // let promiseArray = del_array.map((v)=>
      //     apiFn.comApi({
      //         state : {
      //             table : "book_link",
      //             del : del_array
      //         }
      //     })
      // )

      let promiseArray2 = c_data.map((v) =>
        apiFn.comApi({
          state: {
            table: "book_link",
            del: [
              {
                "HIQDB_book_link.bid": params["id"],
                "HIQDB_book_link.cid": v,
              },
            ],
          },
        })
      );

      await apiFn.comApi({
        state: {
          table: "book_link",
          del: del_array,
        },
      });

      Promise.all(promiseArray);
      // Promise.all(promiseArray2)
    } catch (e) {
      console.log("apiPro_find_link ERROR : ", e);
    }
  };
  const apiPro_subs_regi = async (isDel, isNine) => {
    try {
      // console.log("bookData : ",bookData)
      // console.log("subsData: ",subsData)
      // return;
      let sendMsg = {
        type: "subs_apply",
        orderMid: userInfo["id"],
        targetMid: bookData["reg_id"],
        boid: params["id"],
        channel: constants.channelId,
      };

      if (params["who"] == "student") {
        sendMsg["type"] = "student_subs_apply";
      }

      if (isDel) {
        await apiFn.comApi({
          state: {
            table: "book_link",
            del: [
              {
                "HIQDB_book_link.bid": params["id"],
                "HIQDB_book_link.mid": userInfo["id"],
              },
            ],
          },
        });
      } else {
        // set : [{
        //     key : `id:${params["qid"]}`,
        //     value : {
        //         level : num
        //     }
        // }]
        if (isNine) {
          await apiFn.comApi({
            state: {
              table: "book_link",
              sendMsg: {
                ...sendMsg,
              },
              ins: [
                {
                  mid: userInfo["id"],
                  bid: params["id"],
                  type: isTeacher ? 8 : 4,
                  status: subsData["approve"] == "N" ? 0 : 1,
                },
              ],
            },
          });
          // await apiFn.comApi({
          //     state : {
          //         table : 'book_link',
          //         sendMsg : {
          //             ...sendMsg
          //         },
          //         set: [
          //             {
          //                 key : `bid:${params["id"]}' and mid='${userInfo["id"]}` ,
          //                 value : {
          //                     type : isTeacher ? 8 : 4,
          //                     status : subsData["approve"] == "N" ? 3 : 1,
          //                     point : "NULL"
          //                 }
          //                 // mid : userInfo["id"],
          //                 // bid : params["id"],
          //                 // type : isTeacher ? 8 : 4,
          //                 // status : subsData["approve"] == "N" ? 0 : 1,
          //             }
          //         ],
          //     }
          // })
        } else {
          await apiFn.comApi({
            state: {
              table: "book_link",
              sendMsg: {
                ...sendMsg,
              },
              ins: [
                {
                  mid: userInfo["id"],
                  bid: params["id"],
                  type: isTeacher ? 8 : 4,
                  status: subsData["approve"] == "N" ? 0 : 1,
                },
              ],
            },
          });
        }
      }
      if (!isDel) {
        if (subsData["approve"] == "N") {
          alert("교재구독 신청 되었습니다.");
        } else if (subsData["approve"] == "Y") {
          alert("교재 구독 되었습니다.");
        }
      } else {
        alert("교재 구독이 취소되었습니다.");
      }
      await apiPro_read_link();
    } catch (e) {
      console.log("E : ", e);
    }
  };

  const apiPro_mobum = async () => {
    try {
      let data = await apiFn.qnaList({
        state: {
          id: userInfo["id"],
          page: 1,
          sort: "reg_date desc",
          status: "mobum",
          bid: params["id"],
          amount: 4,
        },
      });

      console.log("MOBUM data : ", data);

      setQList([...data["list"]]);
    } catch (e) {}
  };

  useEffect(() => {
    apiPro_read();
    apiPro_read_2();
    setExplainData([]);
    setExplainPage(1);
    if (params["who"] == "student") {
      apiPro_mobum();
    }
  }, []);
  const UserBookState = ({ bookData }) => {
    let userState = "";
    const { type, status } = bookData;
    console.log("type", type, status);
    if (type === 5) {
      userState = "교재 관리자";
    } else if (type === 6 && status === 1) {
      userState = "교재 참여자";
    } else if (type === 9 && status === 5) {
      userState = "초대 승낙 대기 상태";
    } else if (type === 6 && status === 0) {
      userState = "참여 승낙 대기 상태";
    } else if ((type === 4 || type === 8) && status === 0) {
      userState = "구독 대기자";
    } else if ((type === 4 || type === 8) && status === 2) {
      userState = "구독 열람자";
    } else if ((type === 4 || type === 8) && status === 1) {
      userState = "구독자";
    } else if (type === undefined) {
      userState = "방문자";
    } else if (type === 0) {
      userState = "교재 열람자";
    } else if (type === 2) {
      userState = "교재 참여자";
    }
    return userState;
  };
  // useEffect(()=>{
  //     console.log("bookData => ",bookData);
  // },[bookData])

  // useEffect(()=>{
  //     console.log("linkData => ",linkData)
  // },[linkData])

  // useEffect(()=>{
  // console.log("userInfo => ",userInfo);
  // },[userInfo])

  // useEffect(()=>{
  //     console.log("Book_book subsData : ",subsData)
  // },[subsData])

  useEffect(() => {
    console.log("qnaCount : ", qnaCount, bookData);
  }, [qnaCount]);

  console.log("pickResult : ", pickResult);

  useEffect(() => {
    if (pickResult) {
      document.getElementById("question").click();
    }
  }, [pickResult]);

  return (
    <div
      style={{
        ...Profile_class_Style.c,
        backgroundColor: "",
        padding: "0px 20px",
      }}
    >
      {params["who"] == "student" ? (
        <div
          style={{
            width: "100%",
            borderRadius: 5,
          }}
        >
          <div
            style={{
              display: "flex",
              padding: "15px",
              backgroundColor: "#FAFAFA",
              borderRadius: 5,
            }}
          >
            <div
              style={{
                width: "80px",
                height: "120px",
                // backgroundColor:"orange",
                marginRight: "10px",
                borderRadius: 10,
              }}
            >
              <img
                onError={(e) => {
                  // setIsLoad(false)
                  e.currentTarget.src = noBook;
                }}
                style={{
                  width: "100%",
                  height: "100%",
                  borderRadius: 10,
                }}
                src={
                  bookData["img"]
                    ? constants.s3_url +
                      // '/public'
                      // +
                      `/bookCover/${bookData["img"]}` +
                      ".jpg"
                    : params["id"]
                      ? constants.apiUrl +
                        // ( constants.apiUrl.includes('dev.com') ? '/test' : '/public' )
                        // '/public'
                        // +
                        `/bookCover/${params["id"]}` +
                        // '/teacher/'+userInfo["id"].normalize('NFD')
                        ".jpg?" +
                        util.getRandomInt(1000, 9999)
                      : ""
                }
              />
            </div>
            <div
              style={{
                height: "120px",
                fontSize: "10px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                fontWeight: 400,
                color: "#3D3A39",
                // width:"100%",
                flex: 1,
              }}
            >
              <>
                <div
                  style={{
                    display: "flex",
                  }}
                >
                  전체 질문 수
                  <div
                    style={{
                      margin: "0px 5px 0px 3px",
                      // color : style.common.fontColor_3,
                    }}
                  >
                    {qnaCount["live"] + qnaCount["mobum"]}
                  </div>
                  <div style={{ marginRight: "5px" }}>
                    모범 풀이수 {qnaCount["mobum"] ? qnaCount["mobum"] : 0}
                  </div>
                  <div style={{ marginRight: "3px" }}>
                    공유 중 수업 {bookData["cntClass"] ? bookData["cntClass"] : 0}
                  </div>
                </div>
              </>
              <div style={Qstyle.wrapper}>
                <input
                  id="question"
                  type="file"
                  accept="image/*"
                  style={{
                    display: "none",
                  }}
                  onChange={(e) => {
                    setQnaData({
                      bid: params["id"],
                    });
                    apiPro_img_qna(e);
                  }}
                />
                <div
                  style={{
                    ...Qstyle.box,
                    marginRight: "2px",
                  }}
                  onClick={(e) => {
                    e.preventDefault();
                    if (checkLecture?.checkPaymentLecture?.isPayment) {
                      setSelectedAcademyId(checkLecture?.checkPaymentLecture?.academyId);
                      setPaymentModalOpen(true);
                    } else {
                      document.getElementById("question").click();
                    }
                  }}
                >
                  <label
                    htmlFor="question"
                    style={{
                      display: "flex",
                      // flexDirection:"column",
                      alignItems: "center",
                      // width : "24px",
                      // height : "24px",
                    }}
                  >
                    <img
                      style={{
                        ...Qstyle.box_icon,
                        marginRight: "5px",
                      }}
                      src={add_qna}
                    />
                    질문하기
                  </label>
                </div>
                <div
                  style={{
                    ...Qstyle.box,
                    marginLeft: "2px",
                    backgroundColor: "#F09294",
                    color: "white",
                  }}
                  onClick={() => {
                    navigate("/explainmodel/" + params["who"] + "/" + params["id"] + "/proc/book");
                  }}
                >
                  진행중인 질문
                  <div
                    style={{
                      ...Qstyle.number_box,
                      color: "#F09294",
                      backgroundColor: "white",
                    }}
                  >
                    {qnaCount["live"]}
                  </div>
                </div>
              </div>
              <div style={Qstyle.wrapper}>
                <div
                  style={{
                    ...Qstyle.box,
                    marginRight: "2px",
                    backgroundColor: "#F7B865",
                    color: "white",
                  }}
                >
                  <input
                    id="picture_find"
                    type="file"
                    accept="image/*"
                    style={{
                      display: "none",
                    }}
                    onChange={apiPro_img}
                  />
                  <label
                    htmlFor="picture_find"
                    style={{
                      display: "flex",
                      // flexDirection:"column",
                      alignItems: "center",
                      // width : "24px",
                      // height : "24px",
                    }}
                  >
                    <img
                      style={{
                        ...Qstyle.box_icon,
                        marginRight: "5px",
                      }}
                      src={icon_camera}
                    />
                    이미지로 해답 검색
                  </label>
                </div>
                <div
                  style={{
                    ...Qstyle.box,
                    marginLeft: "2px",
                    backgroundColor: "#5EA3C6",
                    color: "white",
                  }}
                  onClick={() => {
                    setIsText(true);
                  }}
                >
                  <img style={Qstyle.box_icon} src={icon_text} />
                  텍스트로 해답 검색
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div
          style={{
            width: "100%",
            borderRadius: 5,
          }}
        >
          <div
            style={{
              display: "flex",
              padding: "15px",
              backgroundColor: "#FAFAFA",
              borderRadius: 5,
            }}
          >
            <div
              style={{
                width: "80px",
                height: "120px",
                // backgroundColor:"orange",
                marginRight: "10px",
                borderRadius: 10,
              }}
            >
              <img
                onError={(e) => {
                  // setIsLoad(false)
                  e.currentTarget.src = noBook;
                }}
                style={{
                  width: "100%",
                  height: "100%",
                  borderRadius: 10,
                }}
                src={
                  bookData["img"]
                    ? constants.s3_url +
                      // '/public'
                      // +
                      `/bookCover/${bookData["img"]}` +
                      ".jpg"
                    : params["id"]
                      ? constants.apiUrl +
                        // ( constants.apiUrl.includes('dev.com') ? '/test' : '/public' )
                        // '/public'
                        // +
                        `/bookCover/${params["id"]}` +
                        // '/teacher/'+userInfo["id"].normalize('NFD')
                        ".jpg?" +
                        util.getRandomInt(1000, 9999)
                      : ""
                }
              />
            </div>
            {params["who"] == "teacher" ? (
              <div
                style={{
                  height: "120px",
                  fontSize: "14px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent:
                    linkData["mid"] && linkData["status"] == "1" ? "space-between" : "flex-start",
                  fontWeight: 400,
                  color: "#3D3A39",
                  // backgroundColor:"red",
                  // width:"100%",
                  flex: 1,
                }}
              >
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      // width:"100%",
                    }}
                  >
                    선생님은
                    <div
                      style={{
                        fontWeight: 700,
                        margin: "0px 3px",
                      }}
                    >
                      <UserBookState bookData={linkData} />
                    </div>
                    입니다.
                  </div>
                  {linkData["type"] == 5 ? (
                    <img
                      src={icon_setting_gray}
                      onClick={() => {
                        setIsModi(true);
                      }}
                      style={{
                        width: "16px",
                        height: "16px",
                      }}
                    />
                  ) : linkData["mid"] ? (
                    linkData["status"] == "1" || (linkData.type === 6 && linkData.status === 0) ? (
                      <div
                        style={{
                          fontWeight: 400,
                          fontSize: "12px",
                          color: style.common.fontColor_3,
                        }}
                        onClick={() => {
                          let isOk = window.confirm("교재 등록 취소를 하시겠습니까?");
                          if (isOk) {
                            apiPro_del();
                          }
                        }}
                      >
                        참여 취소
                      </div>
                    ) : (
                      <></>
                    )
                  ) : (
                    <div
                      style={{
                        fontWeight: 400,
                        fontSize: "12px",
                        color: style.common.fontColor_3,
                      }}
                      onClick={apiPro_regi}
                    >
                      참여 신청
                    </div>
                  )}
                </div>
                {
                  // 정상회원
                  linkData["mid"] && linkData["status"] == "1" ? (
                    <>
                      <div
                        style={{
                          display: "flex",
                        }}
                      >
                        전체 질문 미답변
                        <div
                          style={{
                            margin: "0px 3px",
                            color: style.common.fontColor_3,
                          }}
                        >
                          {qnaCount["live"]}
                        </div>
                        / 전체
                        <div
                          style={{
                            marginLeft: "3px",
                          }}
                        >
                          {qnaCount["live"] + qnaCount["qna"] + qnaCount["mobum"]}
                        </div>
                      </div>
                      <div
                        style={{
                          display: "flex",
                        }}
                      >
                        모범 풀이수 {qnaCount["mobum"] ? qnaCount["mobum"] : ""}
                      </div>

                      <div
                        style={{
                          display: "flex",
                        }}
                      >
                        공유 중 수업 {bookData["cntClass"] ? bookData["cntClass"] : ""}
                      </div>
                    </>
                  ) : // 회원 대기 상태
                  linkData.type === 9 && linkData.status === 5 ? (
                    <>
                      <div
                        style={{
                          display: "flex",
                        }}
                      >
                        <UiTextBtn
                          text={"초대 승낙"}
                          btnStyle={Profile_class_Style.gradebox_text_btn}
                          fnClick={() => {
                            let isOk = window.confirm("교재 등록 초대를 승낙하시겠습니까?");
                            if (isOk) {
                              apiPro_approve();
                            }
                          }}
                        />
                        <UiTextBtn
                          text={"초대 거절"}
                          btnStyle={{
                            ...Profile_class_Style.gradebox_text_btn,
                            marginLeft: "12px",
                          }}
                          fnClick={() => {
                            let isOk = window.confirm("교재 등록 초대를 거절하시겠습니까?");
                            if (isOk) {
                              apiPro_del();
                            }
                          }}
                        />
                      </div>
                    </>
                  ) : (
                    // 방문자
                    <></>
                  )
                }
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
      )}
      {[1, 2].includes(bookData.book_type) && linkData.status === 1 && (
        <div>
          <PdfImgPreview title={"문제이미지"} imgList={qnaList} />
          <PdfImgPreview title={"해설이미지"} imgList={answerList} />
        </div>
      )}
      {(params["who"] == "student" ||
        (linkData["status"] == 1 && !(linkData["type"] == 4 || linkData["type"] == 8))) && (
        <UiNotice
          isTeacher={params["who"] == "teacher" ? true : false}
          data={noticeData}
          where="book"
        />
      )}
      {/* 0221 구독 관련 임시 주석 */}
      {/* {
            (
                linkData["mid"] ?
                (
                    linkData["type"] == 4 ||
                    linkData["type"] == 5 ||
                    linkData["type"] == 8
                )
                :
                true
            ) &&
            (
               params["who"] == "student" ?
                (
                    params["cid"] == "subs" ?
                    <UiSubs
                        isFull={isFull}
                        setIsFull={setIsFull}
                        subsData={subsData}
                    />
                    :
                    <></>
                )
                :
                (subsData["bid"] && subsData["public"] != "N" )&&
                <UiSubs
                    isFull={isFull}
                    setIsFull={setIsFull}
                    subsData={subsData}
                />
            )
            } */}
      {isTeacher
        ? linkData["mid"] &&
          linkData["status"] == 1 && (
            <UiQuestion
              userInfo={userInfo}
              setIsText={setIsText}
              setQnaData={setQnaData}
              apiPro_img={apiPro_img}
              setFileObj={setFileObj}
              setHiddenUrl={setHiddenUrl}
              linkData={linkData}
              qnaCount={qnaCount}
              bookData={bookData}
              isTeacher={isTeacher}
              data={[1, 2, 3, 4, 5]}
              isSubs={
                linkData["type"] == 4 || linkData["type"] == 5 || linkData["type"] == 8
                  ? true
                  : false
              }
              isComplete={params["who"] == "teacher" ? true : false}
            />
          )
        : true && (
            //  linkData["mid"]
            // &&
            // linkData["status"] == 1
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                backgroundColor: "white",
                marginTop: "15px",
                fontSize: "13px",
                // backgroundColor : "#FFFBF5",
                borderRadius: 5,
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    display: "flex",
                  }}
                >
                  <img
                    src={bookmark_on}
                    style={{
                      width: "24px",
                      height: "24px",
                    }}
                  />
                  <div
                    style={{
                      // height:"40px",
                      fontWeight: 700,
                      fontSize: "16px",
                      marginLeft: "5px",
                    }}
                  >
                    모범 풀이
                  </div>
                  <ArrowDropDown
                    style={{
                      fontSize: "20px",
                    }}
                  />
                </div>
                <div
                  style={{
                    color: style.common.fontcolor_8,
                    fontWeight: 400,
                    fontSize: "12px",
                  }}
                  onClick={() => {
                    navigate("/explainmodel/" + params["who"] + "/" + params["id"] + "/de/book");
                  }}
                >
                  + 더보기
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  justifyContent: "space-between",
                }}
              >
                {qList.map((v) => (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      borderRadius: 5,
                      backgroundColor: "#FFFBF5",
                      width: "164px",
                      height: "135px",
                      borderRadius: "10px",
                      padding: "10px 5px",
                      marginTop: "10px",
                      fontSize: "12px",
                      fontWeight: 500,
                    }}
                    onClick={() => {
                      navigate(`/talk/${params["who"]}/0/${v["id"]}/${v["sid"]}/${v["tid"]}`);
                    }}
                  >
                    <img
                      style={{
                        width: "150px",
                        height: "110px",
                        marginBottom: "7px",
                      }}
                      src={
                        v["original"]
                          ? v["original_img"]
                            ? `${constants.s3_url}/qna/${v["original_img"]}.jpg`
                            : `${constants.s3_url}/qna/${v["original"]}.jpg`
                          : v["img"]
                            ? `${constants.s3_url}/qna/${v["img"]}.jpg`
                            : `${constants.apiUrl}/qna/${v["id"]}.jpg`
                      }
                    ></img>
                    {v["title"]}
                  </div>
                ))}
              </div>
            </div>
          )}
      {isPaymentModalOpen && <SelectTicket selectedAcademyId={selectedAcademyId} />}
    </div>
  );
};

export default Book_book;
