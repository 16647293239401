import { Add, HighlightOffTwoTone } from "@mui/icons-material";
import { ClassRegistRequset, ClassResponseFileItem } from "api/classRegist";
import Section from "components/atoms/Section";
import React, { useRef } from "react";
import { useFormContext } from "react-hook-form";
import styled from "styled-components";
const AdditionalFile = () => {
  const inputRef = useRef<HTMLInputElement>(null);
  const { control, watch, register, setValue } = useFormContext<ClassRegistRequset>();
  const watchFiles = watch("files");
  const handleFileDelete = (fileIndex: number) => {
    const newFiles = watchFiles?.filter((file, index) => fileIndex !== index);
    setValue("files", newFiles);
    inputRef.current && inputRef.current.focus();
    inputRef.current && inputRef.current.blur();
  };
  return (
    <Container>
      <input
        id={"classAdditionalFile"}
        type={"file"}
        multiple={true}
        {...register("files", {
          onChange(event) {
            const fileArray: File[] = Array.from(event.target.files);
            setValue("files", [...watchFiles, ...fileArray]);
          },
        })}
      />
      {/* <ButtonContinainer> */}
      <Button htmlFor="classAdditionalFile">
        <Add /> 파일 추가
      </Button>
      {/* </ButtonContinainer> */}
      <FileListContainer>
        {watchFiles?.map((file, index) => {
          const fileObject = file as File;
          const fileOrigin = file as ClassResponseFileItem;
          const fileSize = fileObject.size
            ? `${Math.round(fileObject.size / 1024).toLocaleString()}KB`
            : "";
          const fileName = fileOrigin.origin || fileObject.name;

          return (
            <FileItem key={file.name}>
              <FileName> {fileName}</FileName>
              <Section align="center">
                <FileSizeText>{fileSize}</FileSizeText>
                <CloseIconContainer onClick={() => handleFileDelete(index)}>
                  <HighlightOffTwoTone />
                </CloseIconContainer>
              </Section>
            </FileItem>
          );
        })}
      </FileListContainer>
    </Container>
  );
};

const Container = styled.div`
  & > input {
    display: none;
  }
  margin-top: 10px;
`;

const Button = styled.label`
  width: fit-content;
  margin-left: auto;
  cursor: pointer;
  display: flex;
  padding: 8px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 6px;
  background: #ffa723;
  color: var(--White, #fff);
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 166.667% */
`;

const FileListContainer = styled.div`
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const FileItem = styled.div`
  display: flex;
  padding: 16px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  border-radius: 10px;
  border: 2px solid #ffa723;
  background: #fff;
  box-shadow: 4px 4px 6px 0px rgba(0, 0, 0, 0.05);
  color: #292929;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  align-items: center;
`;

const FileSizeText = styled.span`
  color: #807e8a;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  flex: 1;
  display: flex;
  justify-content: flex-end;
  margin-right: 24px;
`;

const FileName = styled.div`
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const CloseIconContainer = styled.div`
  cursor: pointer;
  svg {
    color: #807e8a;
    font-size: 20px;
  }
`;
export default AdditionalFile;
