import { useMutation } from "@apollo/client";
import axios from "axios";
import { React, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { CreateInitialPaymentsDocument } from "types/graphql/graphql";
import apiFn from "../../../asset/apiClass";
import constants from "../../../asset/constants";
import style from "../../../asset/style";
import Basic from "../../../common/frame/Basic";
import UiBtn from "../../../uiComponents/UiBtn/UiBtn";
import { UiInput_title } from "../../../uiComponents/UiInput/UiInput";
import UiLoading from "../../../uiComponents/UiLoading/UiLoading";
import RegiForm from "./RegiForm";
import Register_One_style from "./Register_One_style";

const Register_Three = ({ regiData, setRegiData, setUserInfo }) => {
  let params = useParams();
  let navigate = useNavigate();

  const isTeacher = useMemo(() => params["who"] === "teacher", [params]);
  const isChk = useMemo(() => Boolean(regiData["nick"]), [regiData]);
  const [isLoading, setIsLoading] = useState(false);

  // 선생님 가입 시 결제 관련 테이블 생성
  const [createInitialPayments] = useMutation(CreateInitialPaymentsDocument, {
    errorPolicy: "all",
  });

  const apiPro_regi_classroom = async (data) => {
    try {
      let sendMsg = {
        type: "suup_apply",
        orderMid: data.id,
        targetMid: data.id,
        cid: constants.classroomId,
        channel: constants.channelId,
      };
      if (!isTeacher) {
        sendMsg["type"] = "student_apply";
      }
      let isOk = await apiFn.comApi({
        state: {
          table: "member_class",
          sendMsg: sendMsg,
          ins: [
            {
              mid: data.id,
              cid: constants.classroomId,
              type: 0,
              status: 1,
              reg_date: 0,
            },
          ],
        },
      });
    } catch (e) {
      // console.log("regi error => ",e);
    }
  };

  const apiPro_regi = async () => {
    setIsLoading(true);
    try {
      if (regiData["tel"] && regiData["tel"].startsWith("1")) {
        regiData["tel"] = "0" + regiData["tel"];
      }

      let data = await apiFn.providerSignUp({
        state: {
          ...regiData,
          type: isTeacher ? 2 : 1,
        },
      });
      // alert(JSON.stringify(data))
      if (!data) {
        alert("가입에 실패했습니다. 문제가 계속 발생하면 문의해주세요.");
        return;
      }
      let isAgreeDone = await axios.post(constants.apiUrl + "/user/tos", {
        list: regiData.agreement,
        id: data.id,
        type: isTeacher ? 2 : 1,
        user_index: data.user_index,
      });
      if (!isAgreeDone) {
        alert("약관 동의 여부 기록에 실패했습니다. 문제가 계속 발생하면 문의해주세요.");
        return;
      }
      let isOk = await apiFn.fnNewSignIn({
        state: {
          id: data["id"],
          pw: regiData["oid"],
        },
      });

      if (!isOk) {
        alert("로그인에 실패했습니다. 문제가 계속 발생하면 문의해주세요.");
        return;
      }
      setUserInfo({
        ...isOk,
      });

      await apiPro_regi_classroom();

      if (isTeacher) {
        await apiPro_createClass(isOk);
        // 선생님 가입 시 결제 관련 테이블 생성
        await createInitialPayments({
          variables: {
            userId: data.id,
          },
        });
      } else {
        alert("가입이 완료되었습니다.");
        navigate("/regiFour/" + params["who"]);
        setIsLoading(false);
      }
    } catch (e) {
      alert("문제가 발생했습니다. : " + e);
      navigate("/");
      setIsLoading(false);
    }
  };

  const apiPro_createClass = async (data) => {
    try {
      // // console.log("@@@@@ ",data)
      // // console.log("@@@@@ ",data)
      const tel = regiData["tel"].startsWith("1") ? "0" + regiData["tel"] : regiData["tel"];

      const [classRoom, defaultBook, ...rest] = await Promise.all([
        apiFn.createClass({
          state: {
            // ...c_regiData,
            name: `${data["name"]} 선생님의 학원`,
            mid: data["id"],
            tel: tel,
            owner: regiData["name"] ? regiData["name"] : "",
            email: regiData["email"] ? regiData["email"] : "",
          },
        }),
        apiFn.comApi({
          state: {
            table: "book",
            // id : userInfo["id"],
            ins: [
              {
                name: `${data["name"]} 질문방 기본 교재`,
                reg_date: 0,
                reg_id: data["id"],
              },
            ],
          },
        }),

        apiFn.comApi({
          state: {
            table: "book_link",
            ins: [
              {
                mid: data["id"],
                bid: 0,
                point: 0,
                type: 8,
                status: 5,
              },
            ],
          },
        }),
        apiFn.comApi({
          state: {
            table: "book_link",
            ins: [
              {
                mid: data["id"],
                bid: 687, // youtube 기출
                point: 0,
                type: 1,
                status: 1,
              },
            ],
          },
        }),
      ]);

      const defaultClass = await apiFn.createClass({
        state: {
          // ...c_regiData,
          name: `${data["name"]} 질문방`,
          mid: data["id"],
          pid: classRoom.id,
          tel: tel,
          owner: regiData["name"] ? regiData["name"] : "",
          email: regiData["email"] ? regiData["email"] : "",
          type: 1,
        },
      });

      const defaultResult = await fetch(`${constants.apiUrl}/class/${defaultClass.id}/default`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ userId: data.id, bookId: defaultBook.id }),
      });

      if (defaultResult.status === 200) alert("가입이 완료되었습니다.");
      else alert("기본 강의 생성에 실패했습니다.");
      if (constants.isAcademy) {
        navigate("/regiFive/" + params["who"] + "/c");
        util.removeLocalStorage();
      } else {
        navigate("/message/" + params["who"] + "/home");
      }
    } catch (e) {
      console.error(e);
      alert("가입에 실패했습니다.");
      // console.log("@@@  ## #  #",e);
      // util.c_err_log(`apiPro_regi => ${e}`)
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (
      !(
        regiData.agreement &&
        regiData.agreement.filter(({ isRequired }) => isRequired).every(({ agree }) => agree)
      )
    ) {
      navigate(`/regiOne/${params["who"]}/${params["type"]}`);
    }
  }, [regiData]);

  return (
    <Basic>
      <RegiForm num={3} isTeacher={isTeacher} title="개인정보 입력" />
      <div
        style={{
          width: "100%",
          marginTop: "20px",
        }}
      >
        {/* <UiInput_title
                    type='basic'
                    title="이름"
                    placeholder={isTeacher ? "학생이 보는 정보로써 반드시 설명을 입력해주세요." : "선생님이 보는 정보로써 반드시 설명을 입력해주세요."}
                    inputClass={isTeacher ? "input_t" : "input_s"}
                    fnChange={(e)=>{
                        // setId(e.target.value)
                        setRegiData({
                            ...regiData,
                            name : e.target.value
                        })
                    }}
                /> */}

        <UiInput_title
          type="basic"
          title="닉네임"
          placeholder="닉네임을 입력해주세요."
          inputClass={isTeacher ? "input_t" : "input_s"}
          fnChange={(e) => {
            const replaceId = constants.regex_imo;

            if (e.target.value.match(replaceId)) {
              alert("글자 혹은 숫자만 입력해주세요.");
              setRegiData({
                ...modiData,
                nick: e.target.value,
              });
            } else {
              setRegiData({
                ...regiData,
                nick: e.target.value,
              });
            }
          }}
        />
        {/* <UiInput_title
                    type='basic'
                    title="전화번호"
                    placeholder="전화번호"
                    // isPw={true}
                    inputClass={isTeacher ? "input_t" : "input_s"}
                    fnChange={(e)=>{
                        // setId(e.target.value)
                        setRegiData({
                            ...regiData,
                            tel : e.target.value
                        })
                    }}
                />
                <UiInput_title
                    type='basic'
                    title="이메일"
                    placeholder="이메일 입력"
                    // isPw={true}
                    inputClass={isTeacher ? "input_t" : "input_s"}
                    fnChange={(e)=>{
                        setRegiData({
                            ...regiData,
                            email : e.target.value
                        })
                    }}
                /> */}
      </div>
      <div style={Register_One_style.b_c}>
        <UiBtn
          btnStyle={{
            ...Register_One_style.b,
            borderColor: isChk
              ? isTeacher
                ? style.common.t_color
                : style.common.s_color
              : style.common.borderColor,
            backgroundColor: isChk
              ? isTeacher
                ? style.common.t_color
                : style.common.s_color
              : "rgb(200,200,200)",
          }}
          title="가입 완료"
          fnClick={isChk && apiPro_regi}
        />
      </div>
      {isLoading && <UiLoading text={"회원가입을 진행중입니다."} />}
    </Basic>
  );
};

export default Register_Three;
