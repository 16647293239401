import React, {useState, useEffect, useMemo} from 'react'
import styles from './style.module.scss'
import UiBtn from "../../../uiComponents/UiBtn/UiBtn";
import Register_One_style from "../../before/register/Register_One_style";
import style from "../../../asset/style";
import {UiCheckBox} from "../../../uiComponents/UiCheckBox/UiCheckBox";
import axios from "axios";
import constants from "../../../asset/constants";
import {KeyboardArrowDown} from "@material-ui/icons";
import {createPortal} from "react-dom";

const ToS = ({data, isTeacher, isChkList, setIsChkList, index}) => {
    const [isOpen, setIsOpen] = useState(false)
    return (
        <div>
            <div style={Register_One_style.section_a_c}>
                <div style={Register_One_style.section_a_w}>
                    <UiCheckBox
                        checked={isChkList[index]}
                        className={isTeacher ? "t" : "s"}
                        value={"Agr" + data['title']}
                        name={"Agr" + data['title']}
                        fnClick={() => {
                            setIsChkList(prev => {
                                const newCheckList = [...prev]
                                newCheckList[index] = !newCheckList[index]
                                return newCheckList
                            })
                        }}
                    />
                    <div style={Register_One_style.section_a_t}>
                        {data['title']}{data.is_required ? ' (필수)' : ' (선택)'}
                    </div>
                </div>
                <KeyboardArrowDown
                    onClick={() => {
                        setIsOpen(!isOpen)
                    }}
                />
            </div>
            {
                isOpen && <div style={{overflow: 'auto', height: '150px'}}>
                    <div dangerouslySetInnerHTML={{
                        __html:
                            data['contents'].replace(new RegExp('\n', 'g'), '<br/>')
                    }}>
                    </div>
                </div>
            }
        </div>
    )
}
const ToSAgreementModal = ({onAgree, userInfo, notAgreeTosData}) => {
    const [isChkList, setIsChkList] = useState([]);
    const isAgreeAll = useMemo(() => notAgreeTosData.length === isChkList.length && isChkList.every(item => item), [isChkList]);
    const isTeacher = useMemo(() => (userInfo?.type === 2), [userInfo]);
    const isPass = useMemo(() => !((notAgreeTosData || []).some((tos, index) =>
        tos.is_required && !isChkList[index]
    )), [isChkList, notAgreeTosData])

    return createPortal((<button
        className={styles.ToSAgreementModalContainer}
        onClick={() => {
        }}
    >
        <div
            className={styles.ToSAgreementModalContent}
        >
            <div>
                <div style={{
                    ...Register_One_style.section_a_c,
                    justifyContent: "flex-start",
                    padding: "30px 0px 20px 0px",
                    borderBottom: "1px solid black",
                }}
                >
                    <UiCheckBox
                        className={isTeacher || false ? "t" : "s"}
                        value={"D"}
                        name="D"
                        checked={isAgreeAll}
                        fnClick={() => {
                            if (isAgreeAll)
                                setIsChkList(new Array(notAgreeTosData.length).fill(false))
                            else
                                setIsChkList(new Array(notAgreeTosData.length).fill(true))
                        }}
                    />
                    <div style={{
                        ...Register_One_style.section_a_t,
                        fontSize: "14px",
                        fontWeight: "bold",
                    }}>
                        회원가입 약관에 모두 동의합니다.
                    </div>
                </div>
                {
                    notAgreeTosData && notAgreeTosData.map((tos, i) => (
                        <ToS key={i} data={tos} isTeacher={isTeacher} isChkList={isChkList} setIsChkList={setIsChkList}
                             index={i}/>
                    ))
                }
            </div>
            <UiBtn
                btnStyle={{
                    ...Register_One_style.b,
                    borderColor: isPass ?
                        (isTeacher ? style.common.t_color : style.common.s_color)
                        : style.common.borderColor,
                    backgroundColor: isPass ?
                        (isTeacher ? style.common.t_color : style.common.s_color)
                        : "rgb(200,200,200)"
                }}
                title="동의하기"
                fnClick={async () => {
                    if (isPass) {
                        try {
                            let isAgreeDone = await axios.post(constants.apiUrl + '/user/tos', {
                                list: notAgreeTosData.map((agr, i) => ({
                                    agree: isChkList[i],
                                    tos_id: agr.id,
                                    isRequired: agr.is_required,
                                    user_type: userInfo.user_type
                                })),
                                id: userInfo.id,
                                type: userInfo.type
                            })
                            isAgreeDone && onAgree && onAgree()
                        } catch (e) {
                            alert('약관 동의에 실패했습니다.')
                        }
                    }
                }}
            />
        </div>
    </button>), document.body)
}

export default ToSAgreementModal
