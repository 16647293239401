import { React , useState , useEffect } from 'react'
import { useParams , useNavigate } from 'react-router'
import style from '../../asset/style'
import Basic from '../../common/frame/Basic'
import RegiForm from '../before/register/RegiForm'
import aquery from '../../asset/Img/aquery.png'
import answer from '../../asset/Img/answer.png'
import { UiInput_title } from '../../uiComponents/UiInput/UiInput'
import { Register_One } from '../page_index'
import Register_One_style from '../before/register/Register_One_style'
import UiBtn from '../../uiComponents/UiBtn/UiBtn'
import UiHiqDescBox from '../../uiComponents/UiHiqDescBox/UiHiqDescBox'

const Academy_regi_one = ({
    c_regiData,
    setC_regiData
}) => {
    let params = useParams();
    let navigate = useNavigate();

    const [isTeacher ,setIsTeacher] = useState(false);
    const [isChk , setIsChk] = useState(false);


    useEffect(()=>{
        setC_regiData({})
    },[])

    useEffect(()=>{
        if(params["who"] == "teacher"){
            setIsTeacher(true)
        }
        else {
            setIsTeacher(false)
        }
    },[params])

    useEffect(()=>{
        if((c_regiData["name"] && c_regiData["owner"]) ){
            setIsChk(true)
        }
        else {
            setIsChk(false)
        }
    },[c_regiData])

    return (
        <Basic title={"새 클래스룸 생성"}>
            <RegiForm 
                num={1}
                isTeacher={isTeacher}
                title="클래스룸 기본 정보"
                isTopTitle={false}
                dotCnt = {3}
            />
            <UiHiqDescBox 
                isTeacher={isTeacher}
                desc1="클래스룸을 개설하고"
                desc2="클래스룸의 선생님들을 초대하세요."
                desc3="클래스룸을 개설한 후 수업을 생성하면"
                desc4="수강생들을 등록할 수 있습니다."
            />
            <UiInput_title 
                type="basic"
                title="클래스룸 이름"
                placeholder="클래스룸의 이름을 입력해주세요."
                inputClass={isTeacher ? "input_t" : "input_s"}
                fnChange={(e)=>{
                    setC_regiData({
                        ...c_regiData,
                        name : e.target.value
                    })
                }}
            />
            <UiInput_title 
                type="basic"
                title="대표자 이름"
                placeholder="클래스룸 원장님의 이름을 등록해보세요."
                inputClass={isTeacher ? "input_t" : "input_s"}
                fnChange={(e)=>{
                    setC_regiData({
                        ...c_regiData,
                        owner : e.target.value
                    })
                }}
            />
            <div style={Register_One_style.b_c}>
                <UiBtn
                    isTouch={isChk}
                    btnStyle={{
                        ...Register_One_style.b,
                        borderColor : 
                        isChk ? 
                        ( isTeacher ? style.common.t_color : style.common.s_color )
                        :  
                        style.common.borderColor
                        ,
                        backgroundColor : 
                        isChk ? 
                        ( isTeacher ? style.common.t_color : style.common.s_color )
                        :  
                        "rgb(200,200,200)"

                    }}
                    title="다음"
                    fnClick={()=>{
                        navigate("/academyregitwo/"+params["who"]+"/de")
                        return;
                        if(isChk){
                            navigate("/academyregitwo/"+params["who"])
                        }
                    }}
                />
            </div>

        </Basic>
    )
}

export default Academy_regi_one
