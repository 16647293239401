const UiSlide_Style = {
    c : {
        display:"flex",
        flexDirection : "column",
        justifyContent : 'center',
        fontSize : "14px",
      
    },
    w : {
        display:"flex",
        alignItems:'center',
    },
    arrow : {
        padding : 0,
        marginLeft : "3px",
        fontSize : "20px"
    },
    title_c : {
        display : "flex",
        // width:"100%",
        whiteSpace : 'nowrap',
        overflowX : 'scroll' ,
    },
    title_w : {
        margin : "0px 5px"
    },
    title_btn : {
        borderRadius : 20,
        padding : "10px 20px",
        height : "35px",
        fontSize : "13px",
    },
}

export default UiSlide_Style