import React, { ChangeEvent } from "react";
import styled from "styled-components";
import { LatexView } from "../../../../components/atoms/latexView";
import TextField from "@mui/material/TextField";

const Container = styled.div`
  flex: 1 1 auto;
  height: auto;
  overflow: hidden;
  display: flex;
  flex-direction: column;
`;

const TitleWrapper = styled.div<{ background?: string }>`
  background: ${({ background }) => background || "#fe544e"};
  color: #fff;
  padding: 10px;
  font-size: 14px;
  font-weight: 500;
  border-radius: 10px 10px 0 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 0;
`;

const ContentWrapper = styled.div<{ background?: string }>`
  background: ${({ background }) => background || "#fff"};
  padding: 10px;
  border-radius: 0 0 10px 10px;
  font-size: 14px;
  color: #262626;
  overflow: auto;
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const SubTitle = styled.p<{ color?: string }>`
  color: ${({ color }) => color || "#67f2af"};
  font-weight: 500;
`;

const TextArea = styled(TextField)<{ color?: string }>`
  width: 100%;
  padding: 6px 12px;
  outline: none;

  & > .MuiOutlinedInput-root {
    text-wrap: wrap;
    color: ${({ color }) => color || "#FFF"} !important;

    & > textarea {
      overflow: auto !important;
    }
  }
`;

const theme = {
  red: {
    primaryColor: "#fe544e",
    secondaryColor: "#fff8f8",
    pointFontColor: "#fe544e",
  },
  yellow: {
    primaryColor: "#ff9f47",
    secondaryColor: "#fff8f2",
    pointFontColor: "#ff9f47",
  },
  dark: {
    primaryColor: "#262626",
    secondaryColor: "#141414",
    pointFontColor: "#67f2af",
  },
};

const modeText = {
  view: {
    title: "Latex Editor로 작성한 문제 확인창",
    subTitle: "* 이곳에서 수정된 답변의 내용을 확인할 수 있어요.",
  },
  edit: {
    title: "Latex Editor",
    subTitle: "* 인식된 문제의 내용을 직접 수정할 수 있어요.",
  },
};

type LatexEditBoxProps = {
  mode?: "view" | "edit";
  themeType?: "red" | "yellow" | "dark";
  content?: string;
  onEditMessage?: (event: ChangeEvent<HTMLInputElement>) => void;
  titleActionButtonContent?: React.ReactNode;
  onTitleActionButtonClick?: () => void;
  editDisabled?: boolean;
};

export default function LatexEditor({
  mode,
  themeType,
  content,
  onEditMessage,
  titleActionButtonContent,
  onTitleActionButtonClick,
  editDisabled,
}: LatexEditBoxProps) {
  const currentTheme = theme[themeType || "red"];
  const currentText = modeText[mode || "view"];

  const defaultContent = "수정할 내용이 없습니다.";

  return (
    <Container>
      <TitleWrapper background={currentTheme.primaryColor}>
        {currentText.title}
        {titleActionButtonContent && (
          <button onClick={onTitleActionButtonClick}>{titleActionButtonContent}</button>
        )}
      </TitleWrapper>
      <ContentWrapper background={currentTheme.secondaryColor}>
        <SubTitle color={currentTheme.pointFontColor}>{currentText.subTitle}</SubTitle>
        {mode === "edit" ? (
          <TextArea value={content} onChange={onEditMessage} multiline disabled={editDisabled} />
        ) : (
          <LatexView style={{ width: "100%", overflowX: "auto" }}>
            {content || defaultContent}
          </LatexView>
        )}
      </ContentWrapper>
    </Container>
  );
}
