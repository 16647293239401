import { FormControl, MenuItem, StandardTextFieldProps, TextField } from "@mui/material";
import * as React from "react";
import { Ref, forwardRef } from "react";

interface OptionItem {
  name: string;
  value: string | number;
}
interface ISelectbox extends StandardTextFieldProps {
  label: string;
  options: OptionItem[];
}
const Selectbox = forwardRef(
  ({ label, options, ...props }: ISelectbox, ref: Ref<HTMLSelectElement>) => {
    return (
      <FormControl sx={{ width: "100%" }} size={"small"}>
        <TextField
          size={"small"}
          select
          label={label}
          style={{
            color: "#5A5D6C",
            backgroundColor: "#FFF",
          }}
          InputLabelProps={{
            style: {
              fontSize: 14,
            },
          }}
          InputProps={{
            style: {
              fontSize: 14,
            },
          }}
          inputRef={ref}
          {...props}
        >
          {options?.map(({ value, name }) => (
            <MenuItem
              key={value}
              value={value}
              sx={{
                fontSize: 14,
              }}
            >
              {name}
            </MenuItem>
          ))}
        </TextField>
      </FormControl>
    );
  }
);

export default Selectbox;
