const MyInfo_PW_Style = {
    c: {
        width:"100%",
        height : "100%",
        display:"flex",
        flexDirection:"column",
    },
    w_1 : {
        // display:"flex",
        // flexDirection : "column",
        // flex:1,
        padding : "10px",
        // flexGrow : 1,
    },
    w_2 : {
        display:"flex",
        padding : '15px 10px',
        flexDirection : "column",
        justifyContent:"flex-end",
        flex:1,
        flexGrow : 1,
    }
}

export default MyInfo_PW_Style