import { Download } from "@mui/icons-material";
import { PdfType, usePdfList } from "api/pdfView";
import constants from "asset/constants";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { useLocation, useParams } from "react-router";
import styled, { css } from "styled-components";
import store from "../../../../asset/store";
import PdfImgEditModal from "../PdfImgEditModal";
import { PdfViewActionsContextState, PdfViewContext } from "../PdfViewContext";

const Slider = () => {
  const params = useParams();
  const location = useLocation();
  const [isTransition, setIsTransition] = useState(false);
  const searchParams = new URLSearchParams(location.search);
  const { data } = usePdfList({
    bookId: params.bookId,
    isAnswer: searchParams.get("type") === "answer" ? PdfType.answer : PdfType.question,
  });
  const [imageTime, setImageTime] = useState<number>(new Date().getTime());
  const isMobile = store.isMobile();
  const qnaList = useMemo(() => data.qnaList, [data.qnaList]);
  const {
    changeCurrentSlideInfo,
    changeCurrentSlideIndex,
    currentSlide,
    changeImgViewModalShow,
    changePdfImgEditModalShow,
  } = useContext<PdfViewActionsContextState>(PdfViewContext);

  const [selectIndex, setSelectIndex] = useState<number | string>(
    searchParams?.get("page") ? Number(searchParams?.get("page")) + 1 : 1
  );

  useEffect(() => {
    if (selectIndex !== "") {
      changeCurrentSlideIndex(Number(selectIndex) - 1);
    }
  }, [selectIndex]);

  useEffect(() => {
    let showIndex = 0;
    if (searchParams.get("page")) {
      showIndex = Number(searchParams.get("page"));
    } else if (searchParams.get("questionId")) {
      showIndex = qnaList.findIndex(({ qid }) => qid === Number(searchParams.get("questionId")));
    }
    changeCurrentSlideIndex(showIndex);
    changeCurrentSlideInfo(qnaList[showIndex]);
  }, [qnaList]);

  const customRenterItem = (item: React.ReactElement, options?: { isSelected: boolean }) => {
    return (
      <SlideContainer>
        <div onClick={() => changeImgViewModalShow(true)}>
          <img
            src={item?.props?.children?.[0]?.props?.src}
            alt={`문제사진 ${item?.props?.children?.[1]?.props?.children}`}
            loading={"lazy"}
          />
        </div>
        <SourcesText>출처 {item?.props?.children?.[1]?.props?.children}</SourcesText>
      </SlideContainer>
    );
  };

  return (
    <>
      <div>
        <CurrentPageWrapper>
          <input
            type={"number"}
            value={selectIndex}
            onChange={(event) => {
              const value = Number(event.target.value);

              if (Math.abs(value - currentSlide) < 5) {
                setIsTransition(true);
                setTimeout(() => {
                  setIsTransition(false);
                }, 300);
              }

              setSelectIndex(event.target.value);
            }}
          />{" "}
          / {qnaList?.length}
          <ButtonBox>
            <a
              download
              href={
                isMobile
                  ? `http://native_download?filename=${qnaList[currentSlide]?.qid_HIQDB_online_qna?.img}.jpg?filepath=qna/${qnaList[currentSlide]?.qid_HIQDB_online_qna?.img}.jpg`
                  : `${constants.apiUrl}/qna/${qnaList[currentSlide]?.qid_HIQDB_online_qna?.img}.jpg`
              }
            >
              <Download />
            </a>
            <Button
              onClick={() => {
                changePdfImgEditModalShow(true);
              }}
            >
              편집
            </Button>
          </ButtonBox>
        </CurrentPageWrapper>
        <StyledCarousel
          isTransition={isTransition}
          showArrows={true}
          showIndicators={false}
          showStatus={false}
          dynamicHeight={false}
          showThumbs={false}
          renderItem={customRenterItem}
          selectedItem={currentSlide}
          onChange={(currentSlideIndex) => {
            changeCurrentSlideIndex(currentSlideIndex);
            setSelectIndex(currentSlideIndex + 1);
            changeCurrentSlideInfo(qnaList[currentSlideIndex]);
          }}
        >
          {qnaList.map((item) => {
            //이미지 캐시 때문에 계속 이미지가 변경되어도 이전 이미지를 보여주고있어서 날짜를 추가
            const imageSrc = `${constants.s3_url}/qna/${item.qid_HIQDB_online_qna.img}.jpg?${imageTime}`;
            return (
              <div key={item.qid_HIQDB_online_qna.img}>
                <img src={imageSrc} alt={"문제 사진"} loading={"lazy"} />
                <p className="legend">{item.qid_HIQDB_online_qna.title}</p>
              </div>
            );
          })}
        </StyledCarousel>
      </div>
      <PdfImgEditModal
        onSuccess={() => {
          setImageTime(new Date().getTime());
        }}
      />
    </>
  );
};

export default Slider;

const SourcesText = styled.div`
  font-size: 14px;
  margin-top: 10px;
  background-color: #fff;
  padding: 8px;
  word-wrap: break-word;
`;
const CurrentPageWrapper = styled.div`
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;
const Button = styled.button`
  background: none;
  border: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;

  svg {
    width: 24px;
    height: 24px;
  }
`;

const StyledCarousel = styled(Carousel)<{ isTransition: boolean }>`
  .thumb {
    padding: 0 3px;
    margin-right: 0;
  }
  .animated {
    ${({ isTransition }) =>
      !isTransition &&
      css`
        transition: none !important;
      `}
  }
`;
const SlideContainer = styled.div`
  img {
    width: 100%;
  }
`;

const ButtonBox = styled.div`
  display: flex;
  position: absolute;
  right: 0;
  top: 0;
`;
