import style from "../../asset/style"

const UiExplain_Style = {
    c : {
        display:"flex",
        flexDirection:"column",
        width : "100%",
        backgroundColor : "white",
        margin : "0px 0px 5px 0px",
        padding:"0px 0px 10px 0px",
    },
    w_1 : {
        display:"flex",
        alignItems:"center",
        width : "100%",
        minHeight : "54px",
        padding : "10px 0px 10px 0px"
    },
    w_1_i_w : {
        width : "54px",
        height : "54px",
        padding : "0px 10px",
    },
    w_1_i : {
        width : "54px",
        height : "54px",
    },
    w_1_desc_w : {
        width : "100%",
        height : "100%",
        // height : "60px",
        display:"flex",
        flexDirection:"column",
        // backgroundColor:"red",
        // justifyContent:"space-between",
    },
    w_1_desc_v : {
        padding : "0px 5px 0px 0px",
        display:"flex",
        flexDirection:"column",
        justifyContent:"space-between",
        height : "100%",
        // backgroundColor:"red",
    },
    w_1_desc_v_t_w : {
        display:"flex",
        alignItems:"center",
        padding : '0px  5px 0px 0px',
        justifyContent:"space-between",
        fontSize : "11px",
    },
    w_1_desc_v_t_w_l_w :  {
        display : "flex",
        // marginBottom : "3px",
        alignItems:"center",
    },
    w_1_desc_v_t_w_r_w : {
        display:"flex",
        justifyContent:"center",
        alignItems:"center",
        // width : "40px",
        fontSize : "11px",
        padding : "2px 5px",
        backgroundColor : style.common.s_color,
        color : "white",
        borderRadius : 5,
    },
    // w_1_desc_v_m_w : ,
    w_1_desc_v_b_w : {
        display:"flex",
        alignItems:"center",
        padding : '0px  5px 0px 0px',
        justifyContent:"space-between",
        fontSize : "11px",
        color : style.common.fontColor_6,
    },
    text_1 : {
        color : style.common.fontColor_3,
        fontWeight : "bold"
    },
    text_2 : {
        marginLeft : "3px",
        fontWeight : "bold"
    },
    text_3 : {
        fontSize : "11px",
        marginBottom:"3px",
        color : style.common.fontColor_6,
    },
    w_2 : {
        display:"flex",
        justifyContent:"center",
        width : "100%",
        minWidth : "50px",
        height : "220px",
        borderTop : "1px solid",
        borderTopColor : style.common.borderColor4,
        borderBottom : "1px solid",
        borderBottomColor : style.common.borderColor4,
        backgroundColor : style.common.borderColor2,
        // backgroundColor :"orange",
    },
    w_3 : {
        height : "60px",
        fontSize : "11px",
        color : style.common.fontColor_1,
    },
    w_4 : {
        // height : "30px",
    },
    w_4_v : {
        padding : "10px 5px 0px 5px",
        display:"flex",
        justifyContent:"space-between",
        alignItems:"center",
        fontSize : "11px",
        color : style.common.fontColor_1,
        // backgroundColor:"orange",
    },
}

export default UiExplain_Style