import { useMutation as useGqlMutation } from "@apollo/client";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import apiFn from "asset/apiClass";
import constants from "asset/constants";
import { getUser } from "asset/storage";
import {
  DeleteBoardReplyMutation,
  GetBoardQuery,
  GetReplyQuery,
  MutationCreateBoardReplyArgs,
  MutationDeleteBoardReplyArgs,
  MutationUpdateBoardCountArgs,
  MutationUpsertBoardLogArgs,
  UpdateBoardCountMutation,
  UpsertBoardLogMutation,
} from "types/graphql/graphql";
import { gqlClient } from "./common";
import {
  BOARD_ADD_VIEW_COUNT,
  BOARD_INFO,
  CREATE_BOARD_LOG,
  DELETE_BOARD_LOG,
  DELETE_REPLY,
  REGIST_REPLY,
  REPLY_LIST,
  UPSERT_BOARD_LOG,
} from "./graphql/board";

interface IClassInfoRequest {
  boardId: string | number;
  replyId?: string;
}

interface IFetchIsCheckResponse {
  list: [
    {
      fid: number;
      boid: number;
      type: number;
      tcnt: number;
      tseq: number;
      qid: string;
      mid: string;
      reg_date: string;
      mid_index: string;
    }
  ];
}
export const useIsPick = ({ boardId }: IClassInfoRequest) => {
  const userInfo = getUser();
  const userId = userInfo?.id;
  return useQuery(["useIsPick", { boardId }], async () => {
    return (await apiFn.comApi({
      state: {
        table: "folder_link",
        page: 1,
        amount: 9999,
        sort: "reg_date desc",
        where: {
          "HIQDB_folder_link.boid": `='${boardId}'`,
          "HIQDB_folder_link.mid": `='${userId}'`,
        },
      },
    })) as IFetchIsCheckResponse;
  });
};

export const useRemovePick = () => {
  const queryClient = useQueryClient();
  const userInfo = getUser();
  const userId = userInfo?.id;
  return useMutation({
    mutationFn: ({ boardId }: IClassInfoRequest) => {
      return apiFn.comApi({
        state: {
          table: "folder_link",
          del: [
            {
              boid: boardId,
              mid: userId,
            },
          ],
        },
      });
    },
    onSuccess(data, variables, context) {
      queryClient.invalidateQueries({
        queryKey: ["useIsPick", variables],
      });
    },
  });
};

export const useRemoveFavorite = ({
  bid,
  wid,
}: {
  bid: string;
  wid: string;
}) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async () => {
      return gqlClient.request(DELETE_BOARD_LOG, {
        bid,
        wid,
        btype: 2,
      });
    },
    onSuccess(data, variables, context) {
      queryClient.invalidateQueries({
        queryKey: ["useClassDetailInfo", { boardId: bid }],
      });
    },
  });
};

export const useAddFavorite = ({ boardId }: IClassInfoRequest) => {
  const queryClient = useQueryClient();
  const userInfo = getUser();
  const userId = userInfo?.id;
  return useMutation({
    mutationFn: async () => {
      return gqlClient.request(CREATE_BOARD_LOG, {
        input: {
          wid: userId,
          bid: Number(boardId),
          btype: 2,
        },
      });
    },
    onSuccess(data, variables, context) {
      queryClient.invalidateQueries({
        queryKey: ["useClassDetailInfo", { boardId }],
      });
    },
  });
};

interface IClassDetailResponse {
  list: IClassDetailItem[];
}

interface IClassDetailItem {
  video_start: number;
  video_end: number;
  id: number;
  title: string;
  important: string | null;
  content: string;
  video_link: string | null;
  mid: string;
  cat_id: number;
  cid: number;
  bid: string | null;
  reg_date: string;
  pub: number;
  aid: string | null;
  aaid: string | null;
  mosaic: string;
  img: string;
  mid_index: string | null;
  name: string;
  cname: string;
  type: number;
  rcnt: number;
  tcnt: number;
  tseq: number;
  readCnt: number;
  likeCnt: number;
  classList: { name: string }[];
  writer: {
    name: string;
    nick: string;
  };
  files: Files[];
}

export interface Files {
  id: number;
  name: string;
  cid: number;
  bid: number;
  origin: string;
  url: string;
}
// export const useClassDetailInfo = ({ boardId }: IClassInfoRequest) => {
//   return useSuspenseQuery<GetBoardQuery>(
//     BOARD_INFO,
//     boardId ? { variables: { boardId } } : skipToken
//   );
// };

export const useClassDetailInfo = ({ boardId }: IClassInfoRequest) => {
  return useQuery<GetBoardQuery>(
    ["useClassDetailInfo", { boardId }],
    async () => {
      return gqlClient.request(BOARD_INFO, { boardId });
    },
    { enabled: !!boardId }
  );
};

export const useClassDetailInfoWithoutSuspense = ({
  boardId,
}: IClassInfoRequest) => {
  return useQuery<GetBoardQuery>(
    ["useClassDetailInfo", { boardId }],
    async () => {
      return gqlClient.request(BOARD_INFO, { boardId });
    },
    { enabled: !!boardId, suspense: false }
  );
};

export const useIncreaseViewCount = (boardId: string) => {
  return useGqlMutation<UpdateBoardCountMutation, MutationUpdateBoardCountArgs>(
    BOARD_ADD_VIEW_COUNT,
    {
      variables: {
        boardId,
      },
    }
  );
};

interface ITeacherInfoResponse {
  list: ITeacherInfoItem[];
}
interface ITeacherInfoItem {
  id_token: string;
  credential_oid: null | string;
  device_token: string;
  credential_provider: string;
  academy_code: null | string;
  createdAt: string;
  updatedAt: string;
  user_id: number;
  name: string;
  nick: string;
  provider: string;
  oid: string;
  stype: null | string;
  sex: number;
  birth_date: number;
  join_date: number;
  pid: number;
  status: number;
  tel: string;
  pw: string;
  reg_date: string;
  content: null | string;
  qcount: number;
  qtotal: number;
  intro: string;
  t1: number;
  t2: number;
  t3: number;
  email: string;
  pw_ans: null | string;
  subject: string;
  collage: null | string;
  school: null | string;
  token: string;
  img: string;
  depart: null | string;
  cstatus: null | string;
  noti_new: number;
  noti_reply: number;
  perm: null | string;
  update_date: string;
  user_type: number;
  is_premium: number;
  is_admin: number;
  index: number;
  id: string;
  tcnt: number;
  tseq: number;
  classList: {
    name: string;
  }[];
  suupList: {
    name: string;
    id: number;
    pid: number | null;
    type: number;
    status: number;
  }[];
  hakwonList: {
    name: string;
    id: number;
    pid: number | null;
    type: number;
    status: number;
  }[];
  bookList: {
    id: number;
    name: string;
    content: null | string;
    open: number;
    usee: number;
    reg_id: string;
    reg_date: number;
    status: number;
    img: null | string;
    book_pw: number;
    approve: null | string;
    book_type: number;
    reg_id_index: null | string;
  }[];
  starCnt: number;
  starAvg: number;
  qnacnt: {
    total: number;
    answer: number;
    mobum: number;
    avgtime: number;
  };
  r_qnaCnt: {
    total: number;
    answer: number;
    mobum: number;
    avgtime: number;
  };
}

export const useTeacherInfo = (userId: string) => {
  return useQuery(
    ["useTeacherInfo"],
    async () => {
      return (await apiFn.comApi({
        state: {
          table: "user_credential",
          page: 1,
          amount: 9999,
          sort: "reg_date desc",
          ref: ["A.*|HIQDB_user A on A.index=HIQDB_user_credential.user_id"],
          where: {
            "HIQDB_user_credential.name": `= '${userId}'`,
          },
        },
      })) as ITeacherInfoResponse;
    },
    { enabled: !!userId }
  );
};

export const useDefaultBook = (cid?: string | number) => {
  return useQuery({
    queryKey: [`${constants.apiUrl}/class/${cid}/default`],
    queryFn: async ({ queryKey }) => {
      const [url] = queryKey;
      const result = await fetch(url);
      if (result.ok) {
        return await result.json();
      } else {
        return null;
      }
    },
    enabled: !!cid,
  });
};

export const useDeleteClass = () => {
  const userInfo = getUser();
  const userId = userInfo?.id;
  return useMutation({
    mutationFn: async ({ boardId }: IClassInfoRequest) => {
      return await apiFn.deleteBoard({
        state: {
          bid: boardId,
          orderMid: userId,
        },
      });
    },
  });
};

interface IReplyListResponse {
  list: IReplyListItem[];
}

export interface IReplyListItem {
  id: number;
  boid: number;
  rtype: number;
  wid_index: number;
  tcnt: number;
  tseq: number;
  wid: string;
  wtype: string;
  content: string;
  reg_date: string;
  nick: string;
}

// export const useReplyList = (variables: IClassInfoRequest) => {
//   return useSuspenseQuery<GetReplyQuery>(REPLY_LIST, {
//     variables,
//     fetchPolicy: "no-cache",
//   });
// };
export const useReplyList = ({ boardId }: IClassInfoRequest) => {
  return useQuery<GetReplyQuery>(
    ["useReplyList", { boardId }],
    async () => {
      return gqlClient.request(REPLY_LIST, { boardId });
    },
    { enabled: !!boardId }
  );
};

export const useReplyRegist = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (input: MutationCreateBoardReplyArgs["input"]) => {
      return gqlClient.request<MutationCreateBoardReplyArgs>(REGIST_REPLY, {
        input,
      });
    },
    onSuccess(data, variables, context) {
      queryClient.invalidateQueries({
        queryKey: ["useReplyList", { boardId: `${variables.boid}` }],
      });
    },
  });
};

// export const useReplyDelete = () => {
//   return useGqlMutation<DeleteBoardReplyMutation, MutationDeleteBoardReplyArgs>(
//     DELETE_REPLY,
//     {
//       refetchQueries: [REPLY_LIST, "GetBoard"],
//     }
//   );
// };
export const useReplyDelete = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ replyId }: MutationDeleteBoardReplyArgs) => {
      return gqlClient.request<
        DeleteBoardReplyMutation,
        MutationDeleteBoardReplyArgs
      >(DELETE_REPLY, { replyId });
    },
    onSuccess(data, variables, context) {
      queryClient.invalidateQueries({
        queryKey: [
          "useReplyList",
          { boardId: `${data.deleteBoardReply?.reply?.boid}` },
        ],
      });
    },
  });
};

interface IReplyDeleteResponse {
  list: {
    id: number;
    bid: number;
    btype: number;
    wid_index: number;
    tcnt: number;
    tseq: number;
    wid: string;
    reg_date: string;
    content: string;
    board: {
      title: string;
      nick: string;
      name: string;
      writer: string;
      reg_date: string;
      cat_id: number;
    };
    logger: {
      nick: string;
      name: string;
      writer: string;
    };
  }[];
}
export const useDeclarationBoard = ({ boardId }: IClassInfoRequest) => {
  const userInfo = getUser();
  const userId = userInfo?.id;
  return useQuery(["useDeclarationBoard", { boardId }], async () => {
    return (await apiFn.comApi({
      state: {
        table: "board_log",
        page: 1,
        amount: 9999,
        sort: "reg_date desc",
        where: {
          "HIQDB_board_log.wid": `='${userId}'`,
          "HIQDB_board_log.bid": `='${boardId}'`,
          "HIQDB_board_log.btype": `='3'`,
        },
      },
    })) as IReplyDeleteResponse;
  });
};

export const useDeclarationBoardCancel = () => {
  const queryClient = useQueryClient();
  const userInfo = getUser();
  const userId = userInfo?.id;
  return useMutation({
    mutationFn: async ({ boardId }: IClassInfoRequest) => {
      return await apiFn.comApi({
        state: {
          table: "board_log",
          del: [
            {
              wid: userId,
              bid: boardId,
              btype: 3,
            },
          ],
        },
      });
    },
    onSuccess(data, variables, context) {
      queryClient.invalidateQueries({
        queryKey: ["useDeclarationBoard", variables],
      });
    },
  });
};

export const useDeclarationReply = ({
  replyId,
  userId,
}: {
  replyId: string;
  userId: string;
}) => {
  return useQuery(
    ["useDeclarationReply", { replyId }],
    async () => {
      return (await apiFn.comApi({
        state: {
          table: "board_reply",
          page: 1,
          amount: 9999,
          sort: "reg_date desc",
          where: {
            "HIQDB_board_reply.wid": `='${userId}'`,
            "HIQDB_board_reply.boid": `='${replyId}'`,
            "HIQDB_board_reply.rtype": `='1'`,
          },
        },
      })) as IReplyListResponse;
    },
    { suspense: false }
  );
};

export const useDeclarationReplyCancel = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({
      replyId,
      userId,
    }: {
      replyId: string;
      userId: string;
    }) => {
      return await apiFn.comApi({
        state: {
          table: "board_reply",
          del: [
            {
              wid: userId,
              boid: replyId,
              rtype: 1,
            },
          ],
        },
      });
    },
    onSuccess(data, variables, context) {
      queryClient.invalidateQueries({
        queryKey: ["useDeclarationReply", variables],
      });
    },
  });
};

export const useVideoViewLog = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (input: MutationUpsertBoardLogArgs["input"]) =>
      gqlClient.request<UpsertBoardLogMutation, MutationUpsertBoardLogArgs>(
        UPSERT_BOARD_LOG,
        { input }
      ),

    {
      onSuccess(data, variables, context) {
        queryClient.invalidateQueries({
          queryKey: ["useClassDetailInfo", { boardId: `${variables.bid}` }],
        });
      },
    }
  );
};
