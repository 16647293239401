import { useMessageMarkFolderDelete } from "api/messageMark";
import { ReactComponent as IconEdit } from "asset/Img/icon_edit.svg";
import { ReactComponent as IconTrash } from "asset/Img/icon_trash.svg";
import ItemSubMenu from "components/molecules/ItemSubMenu";
import React, { useCallback, useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { MessageMarkContext } from "../context/MessageMarkContextProvider";
interface FolderModifyMenuProps {
  anchorElement?: HTMLElement | null;
  onClose?: () => void;
  folderId?: number;
}
const FolderModifyMenu = ({ anchorElement, onClose, folderId }: FolderModifyMenuProps) => {
  const open = Boolean(anchorElement);
  const { setModifyFolderId } = useContext(MessageMarkContext);
  const [anchorWidth, setAnchorWidth] = useState<number>();
  const { mutateAsync } = useMessageMarkFolderDelete();
  const handleDelete = useCallback(() => {
    if (
      confirm(
        "선택하신 노트를 삭제하시겠어요? \n(노트가 삭제되면 내부에 있는 오답노트 자료도 같이 삭제됩니다.)"
      )
    ) {
      mutateAsync([folderId]);
      onClose();
    }
  }, [folderId]);
  const handleModifyOpen = useCallback(() => {
    setModifyFolderId(folderId);
    onClose();
  }, [setModifyFolderId]);

  useEffect(() => {
    setAnchorWidth((prev) => prev || anchorElement?.clientWidth);
  }, [anchorElement]);
  return (
    <ItemSubMenu
      open={!!anchorElement}
      anchorElement={anchorElement}
      onClose={onClose}
      menuList={[
        { icon: <IconEdit />, text: "수정", onClick: handleModifyOpen },
        { icon: <IconTrash />, text: "삭제", onClick: handleDelete },
      ]}
    />
  );
};

const MenuItem = styled.li`
  cursor: pointer;
  background-color: #252525;
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  padding: 0px 8px;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  :first-child {
    border-bottom: 1.5px solid #fff;
    padding-bottom: 8px;
  }
  :last-child {
    padding-top: 8px;
  }
`;

export default FolderModifyMenu;
