import { React, useEffect, useState } from 'react';
import { Frame } from '../page_index';
import { useNavigate, useParams } from 'react-router';
import Academy_book_Style from './Academy_book_Style';
import UiTextBtn from '../../uiComponents/UiTextBtn/UiTextBtn';
import style from '../../asset/style';
import UiBook from '../../uiComponents/UiBook/UiBook';
import aquery from '../../asset/Img/aquery.png'
import answer from '../../asset/Img/answer.png'
import UiHiqDescBox from '../../uiComponents/UiHiqDescBox/UiHiqDescBox';
import { UiInput_title } from '../../uiComponents/UiInput/UiInput';
import UiBtn from '../../uiComponents/UiBtn/UiBtn';
import Academy_book_List_Style from './Academy_book_List_Style';
import UiConfirm from '../../uiComponents/UiConfirm/UiConfirm';
import UiTopPopTextBtn from '../../uiComponents/UiTopPopTextBtn/UiTopPopTextBtn';
import constants from '../../asset/constants';
import apiFn from '../../asset/apiClass';
import util from '../../asset/util';
import UiFolder from '../../uiComponents/UiFolder/UiFolder';

const Academy_book_folder = ({
    userInfo,
    setS_BookList
}) => {
    let params = useParams();
    let navigate = useNavigate();

    const [isTeacher ,setIsTeacher] = useState(true);

    var [studentData,setStudentData] = useState([])
    var [teacherData ,setTeacherData] = useState([])

    var [bookList,setBookList] = useState([])
    var [folderList,setFolderList] = useState([]);
    
    // 교재 리스트
    const apiPro_read = async () => {
        try {
            let where_obj = {
                "HIQDB_book.status" : `!= '9'`,
                "HIQDB_book_link.mid" : `= '${userInfo["id"]}'`,
                "HIQDB_book_link.fid" : `= 0`,
                "HIQDB_book_link.status" : "!= 0",
                // "HIQDB_book_link.cid" : `!= '${params["cid"]}'}`,
            }
            if(params["where"] == "depth"){
                where_obj["HIQDB_book_link.fid"] = `= '${params["id"]}'`
            }
            let isOk = await apiFn.comApi({
                state : {
                    cid : params["cid"],
                    table : "book",
                    page : 1,
                    amount : 999,
                    u_id : userInfo["id"],
                    sort : "reg_date desc",
                    ref : [
                        'HIQDB_book_link.cid|HIQDB_book_link.bid=HIQDB_book.id',
                        'HIQDB_book_link.type|HIQDB_book_link.bid=HIQDB_book.id',
                    ],
                    out :[
                        'HIQDB_book_subs.name as subs_name:bid:id'
                    ],
                    where : where_obj
                    
                }
            })
            
            // console.log("%%$%$% ",isOk);
            // console.log("%%$%$% ",isOk);
            // console.log("%%$%$% ",isOk);

            // let bookList = []
            isOk["list"].map((v, i)=>{
                if(v["type"] == 8 || v["type"] == 4){
                    v["name"] = v["subs_name"]
                }
                // bookList.push(v)
            })
            setBookList([
                ...isOk["list"]
            ])
        }
        catch(e){
        }
    }

    // 폴더 리스트
    const apiPro_read_folder = async () => {
        try {
            let data = await apiFn.comApi({
                state : {
                    table : "folder",
                    page : 1,
                    amount : 9999,
                    sort : "name desc",
                    where : {
                        "HIQDB_folder.mid" : `='${userInfo["id"]}'`,
                        "HIQDB_folder.ftype" : `='0'`,
                    }
                }
            })
            // console.log(data)
            setFolderList([
                ...data["list"]
            ])
        }
        catch(e){
        }
    }

    // 교재 -> 강의 공유 
    const apiPro_set = async (data) => {
        try {
            // console.log(data)
            let in_id = teacherData.reduce((a,v,i)=>{
                // return v["mid"].toString()
                if(i == 0 ){
                    return a =`'${v["id"]}'`
                }
                else {
                    return a = a + `,'${v["id"]}'`
                }
            },"")
            let array = await apiFn.comApi({
                state : {
                    table : "book_link",
                    page : 1,
                    amount : 9999,
                    sort : "mid desc",
                    where : {
                        "HIQDB_book_link.bid" : `= '${data["id"]}'`,
                        "HIQDB_book_link.mid" : `in (${in_id})`,
                        "HIQDB_book_link.type" : `in ('5','6')`,
                    }
                }
            })

            let chk_my_type = await apiFn.comApi({
                state : {
                    table : "book_link",
                    page : 1,
                    amount : 9999,
                    sort : "mid desc",
                    ref : [
                        'HIQDB_book_subs.public as subs_public|HIQDB_book_link.bid=HIQDB_book_subs.bid',
                    ],
                    where : {
                        "HIQDB_book_link.bid" : `= '${data["id"]}'`,
                        "HIQDB_book_link.mid" : `= '${userInfo["id"]}'`,
                        "HIQDB_book_link.type" : `in ('5','6')`,
                        
                    }
                }
            })


            // 교재가 구독 교재인경우 
            // 강의는 관리자이지만 교재는 구독자인 경우 + (강의 관리자인 경우 + 교재 관리자인 경우) .
            if(chk_my_type["list"].length > 0){
                if(chk_my_type["list"][0]["subs_public"] == "Y"){
                    if(chk_my_type["list"][0]["type"] == '8' || chk_my_type["list"][0]["type"] == '5'){
                        let auth_id = array["list"].reduce((a, v, i)=>{
                            return ([
                                ...a,
                                v["mid"],
                            ])
                        },[])
            
                        // console.log("post teacherData : ", teacherData)
                        // console.log(auth_id)
            
                        let after_teacherData = []
                        teacherData.map((v)=>{
                            if(v["id"].includes(auth_id)){}
                            else {
                                after_teacherData.push(v)
                            }
                        })
    
                        // let after_studentData = []
                        // studentData.map((v)=>{
                        //     if(v["id"].includes(auth_id)){}
                        //     else {
                        //         after_studentData.push(v)
                        //     }
                        // })
    
    
                        
                        // console.log("after teacherData : ", after_teacherData)
                        // console.log("studentData : ", studentData)
                        // return;
                        let peopleData = after_teacherData.concat(studentData)
                        // console.log(peopleData)
                        // return;
            
                        let promiseArray = peopleData.map((v)=>
                            apiPro_book_type(v["id"], data["id"])
                        )
            
                        await Promise.all([
                            ...promiseArray
                        ])
                    }
                }
            }

            // console.log("data : ",data)
            // return;
            let isOk = await apiFn.comApi({
                state : {
                    table : "book_link",
                    ins : [
                        {
                            fid : 0,
                            mid : 0,
                            qid : 0,
                            cid : params["cid"],
                            bid : data["id"],
                            // 1209 주석 
                            // type : data["type"],
                        }
                    ]
                }
            })
            // /lecture/detail/teacher/260
            navigate(`/lecture/detail/${params["who"]}/${params["cid"]}/de`)
        }
        catch(e){
            console.log("apiPro_set ERROR : ",e)
        }
    }

    // 강의 학생 목록
    const apiPro_read_student = async (sort,inSearch) => {
        try {
            let data = await apiFn.comApi({
                state : {
                    table : "class",
                    page : 1,
                    amount : 9999,
                    sort : "reg_date desc",
                    // sort : sort ? `${sort} desc` : "reg_date desc",
                    ref : [
                        'HIQDB_member_class.cid|HIQDB_class.id=HIQDB_member_class.cid',
                        'HIQDB_member_class.mid|HIQDB_class.id=HIQDB_member_class.cid',
                    ],
                    where : {
                        'HIQDB_class.id':`='${params["cid"]}'`,
                        'HIQDB_member_class.cid' :`='${params["cid"]}'`,
                        // 'HIQDB_member_class.type':`='0'`,   
                        // 'HIQDB_member_class.mid':`!='${userInfo["id"]}'`,   
                        
                    }
                }
            })

            // console.log("@ : : : : ",data);
            let in_id = data["list"].reduce((a,v,i)=>{
                // return v["mid"].toString()
                if(i == 0 ){
                    return a =`'${v["mid"]}'`
                }
                else {
                    return a = a + `,'${v["mid"]}'`
                }
            },"")

            let array = []

            if(in_id.length == 0){
                setStudentData([
                ])

            }


            let data2 = await apiFn.comApi({
                state : {
                    table : "user_credential",
                    page : 1,
                    amount : 9999,
                    sort : `name desc`,
                    where : {'HIQDB_user_credential.name':`in (${in_id})`},
                    ref : [
                        `HIQDB_member_class.type ,HIQDB_member_class.reg_date as member_date ,HIQDB_member_class.cid, HIQDB_member_class.status as s_status |HIQDB_member_class.mid=HIQDB_user_credential.name and HIQDB_member_class.cid = '${params["cid"]}' `,
                        'A.* |HIQDB_user A on A.index=HIQDB_user_credential.user_id and A.user_type = 1'
                    ]
                }
            })

            // console.log("data2 => ",data2["list"])

            data2["list"].map((v,i)=>{
                v["classList"] = []
                if(v["s_status"] == 1){
                    array.push(v)
                }
            })

            console.log("student data : ",array);
            setStudentData([
                ...array
            ])
        }
        catch(e){
            console.log("@@@@@@@@@@ ",e);
        }
    }

     // 강의 선생님 목록
    const apiPro_read_teacher = async (sort,search) => {
        try {
            let data = await apiFn.comApi({
                state : {
                    table : "member_class",
                    page : 1,
                    amount : 9999,
                    sort : "reg_date desc",
                    ref : [
                        // 'HIQDB_class.name|HIQDB_class.id=HIQDB_member_class.cid',
                        'HIQDB_class.intro|HIQDB_class.id=HIQDB_member_class.cid',
                        'HIQDB_class.address|HIQDB_class.id=HIQDB_member_class.cid',
                        'HIQDB_class.tel|HIQDB_class.id=HIQDB_member_class.cid',
                        'HIQDB_class.email|HIQDB_class.id=HIQDB_member_class.cid',
                        'HIQDB_class.owner|HIQDB_class.id=HIQDB_member_class.cid',
                        'HIQDB_class.pid|HIQDB_class.id=HIQDB_member_class.cid',
                        // 'HIQDB_teacher.qtotal|HIQDB_teacher.id=HIQDB_member_class.mid',
                    ],
                    where : {
                        'HIQDB_member_class.cid':`='${params["cid"]}'`,   // 수업 ID
                        // 'HIQDB_member_class.mid':`!='${userInfo["id"]}'`,
                        // 'HIQDB_class.id':`='${params["id"]}'`,   // 교재 ID
                        // 'HIQDB_member_class.status':`='0'`,   
                    }
                }
            })

            // util.objLog(data["list"][0])
            // console.log(data["list"])
            let in_id = data["list"].reduce((a,v,i)=>{
                // return v["mid"].toString()
                if(i == 0 ){
                    return a =`'${v["mid"]}'`
                }
                else {
                    return a = a + `,'${v["mid"]}'`
                }
            },"")
            // console.log(in_id)
            let where_obj = {}
            if(search){
                where_obj[`A.name like "%${search}%" or A.tel`] = `like '%${search}%'`
            }
            
            let data2 = await apiFn.comApi({
                state : {
                    // 0906 pid -> cid 로 변경
                    // cid : data["list"][0]["pid"],
                    cid : params["id"],
                    table : "user_credential",
                    page : 1,
                    amount : 9999,
                    sort : "reg_date desc",
                    where : {
                        'HIQDB_user_credential.name':`in (${in_id})`,
                        ...where_obj
                    },
                    ref : [
                        `HIQDB_member_class.type,HIQDB_member_class.cid, HIQDB_member_class.status as t_status |HIQDB_member_class.mid=HIQDB_user_credential.name and HIQDB_member_class.cid = '${data["list"][0]["cid"]}' `,
                        'A.* |HIQDB_user A on A.index=HIQDB_user_credential.user_id and A.user_type = 2'
                    ]
                }
            })

            let array = []
            
            data2["list"].reduce((a,v,i)=>{
                let obj = {
                    id : v["id"],
                    name : v["name"],
                    cid : v["cid"],
                    nick : v["nick"],
                    status : v["t_status"],
                    qtotal : v["qnacnt"]["total"], // 총 질문수
                    type : v["type"],
                    classList : v["classList"],
                    tel : v["tel"],
                    email : v["email"],
                    intro : v["intro"],
                    img : v["img"],
                }
                if(v["t_status"] == 1) {
                    array.push(obj)
                }
            },[])

            setTeacherData([
                ...array
            ])


        }
        catch(e){
            // console.log("@@@@@@@@@@ ",e);
        }
    }

    const apiPro_book_type = async ( id, bid ) => {
        try {   
            // let chk = await apiFn.comApi({
            //     state : {
            //         table : "book_link",
            //         page : 1,
            //         amount : 9999,
            //         sort : "mid desc",
            //         where : {
            //             "HIQDB_book_link.mid" : `= '${id}'`,
            //             "HIQDB_book_link.bid" : `= '${bid}'`,
            //             "HIQDB_book_link.type" : `not in ('9')`
            //         }
            //     }
            // })

            // console.log("@@ : ",chk);
            await apiFn.comApi({
                state : {
                    table : "book_link",
                    page : 1,
                    amount : 9999,
                    sort : "mid desc",
                    ins : [{
                        mid : id,
                        bid : bid,
                        type : 8,
                        status : 2,
                        point : params["cid"],
                    }]
                }
            })
            // if(chk["list"].length == 0){
            // }
            // else {
            //     return false
            // }
        }
        catch(e){
            console.log("APIPRO book type Error : ",e);
        }
    }

    useEffect(()=>{
        // console.log("parmas => ",params)
        if(params["who"] == "teacher"){
            setIsTeacher(true)
        }
        else {
            setIsTeacher(false)
        }
        apiPro_read_folder();
        apiPro_read();
        apiPro_read_teacher();
        apiPro_read_student();
    },[params])

    // useEffect(()=>{
    //     console.log("teacherData : ",teacherData);
    // },[teacherData])


    return (
        <Frame 
            title={"교재 공유 추가"}
            isSearch={false}
            userInfo={userInfo}
        >
            <div style={{
                width : "100%",
                padding : "0px 10px"
            }}>
                {
                params["where"] == "all" &&
                <div style={{
                    display:"flex",
                    flexWrap:"wrap",
                    padding : "17px 0px",
                }}>
                    {
                    folderList.map((v,i)=>
                    <UiFolder 
                        data={v}
                        navigate={navigate}
                        // academyall/teacher/박주민/all
                        navi={"/academyall/"+params["who"]+"/"+v["id"]+"/depth/"+params["cid"]}
                    
                    />
                    )
                    }
                </div>
                }
                <div style={{
                    display:"flex",
                    justifyContent:"center",
                }}>
                    <div style={{
                        ...Academy_book_Style.list_book_w,
                        // display:"flex",
                        // flexWrap:"wrap",
                        // justifyContent:"space-between",
                        // maxWidth : "350px",
                    }}>
                        {
                        bookList.map((v,i)=>
                        <UiBook
                            data={v} 
                            fnClick={(d)=>{
                                // console.log("book data : ", d);
                                // return;
                                apiPro_set(d)
                            }}
                        />
                        )
                        }
                    </div>
                </div>
            </div>
        </Frame>
    )
}

export default Academy_book_folder