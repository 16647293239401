import { gql } from "@apollo/client";

export const LECTURE_LIST = gql`
  query GetLecture($memberId: ID!, $academyId: ID) {
    getLecture(memberId: $memberId, academyId: $academyId) {
      error {
        code
        message
      }
      lectures {
        id
        name
        pid
        reg_id
        reg_date
        intro
        address
        tel
        email
        stype
        del_date
        assign_date
        owner
        memberType
        lectureStatus
        joinCnt
      }
      success
    }
  }
`;

export const GET_LECTURE_BY_STUDENT = gql`
  query GetLectureByStudent($memberId: ID!, $academyId: ID) {
    getLectureByStudent(memberId: $memberId, academyId: $academyId) {
      error {
        code
        message
      }
      success
      lectures {
        id
        name
        academyId
        academyName
      }
    }
  }
`;

export const GET_LECTURE_BY_TEACHER = gql`
  query GetLectureByTeacher($teacherId: ID!, $studentId: ID!, $academyId: ID) {
    getLectureByTeacher(teacherId: $teacherId, studentId: $studentId, academyId: $academyId) {
      error {
        code
        message
      }
      success
      lectures {
        id
        name
        academyId
        academyName
      }
    }
  }
`;

export const CHECK_PAYMENT_LECTURE = gql`
  query CheckPaymentLecture($lectureId: ID!) {
    checkPaymentLecture(lectureId: $lectureId) {
      error {
        code
        message
      }
      success
      isPayment
      academyId
    }
  }
`;

export const CHECK_PAYMENT_LECTURE_BY_TEACHER = gql`
  query CheckPaymentLectureByTeacher($lectureId: ID!, $teacherId: ID!) {
    checkPaymentLectureByTeacher(lectureId: $lectureId, teacherId: $teacherId) {
      error {
        code
        message
      }
      success
      id
      policy
      dashboard
    }
  }
`;
