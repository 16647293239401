import { ClassRegistRequset, useClassList } from "api/classRegist";
import { getUser } from "asset/storage";
import Input from "components/atoms/Input";
import Selectbox from "components/atoms/Selectbox";
import React, { useMemo } from "react";
import { Controller, useFormContext } from "react-hook-form";
import styled from "styled-components";

const LectureRegistForm = () => {
  const methods = useFormContext<ClassRegistRequset>();
  const {
    register,
    control,
    formState: { errors },
  } = methods;
  const userInfo = getUser();
  const { data } = useClassList(userInfo.id);
  const selectbotOptions = useMemo(
    () =>
      data?.map((item) => ({
        name: item.class_name,
        value: item.cid,
      })),
    [data]
  );
  const { watch } = methods;
  return (
    <Container>
      <Controller
        name="cid"
        control={control}
        rules={{ required: "강의를 선택해주세요" }}
        render={({ field }) => (
          <Selectbox
            label={"강의"}
            options={selectbotOptions}
            {...field}
            error={!!errors.cid}
            helperText={errors.cid?.message}
          />
        )}
      />
      <Controller
        name="title"
        control={control}
        rules={{ required: "제목을 입력해주세요" }}
        render={({ field }) => (
          <Input
            label="제목"
            {...field}
            error={!!errors.title}
            helperText={errors.title?.message}
          />
        )}
      ></Controller>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 14px;
  margin-top: 20px;
`;

export default LectureRegistForm;
