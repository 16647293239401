import * as Sentry from "@sentry/react";
import React from "react";

const ErrorBoundary = ({ children }) => {
  return (
    <Sentry.ErrorBoundary
      fallback={<div>오류가 발생 했습니다.</div>}
      showDialog
    >
      {children}
    </Sentry.ErrorBoundary>
  );
};

export default ErrorBoundary;
