import { gql } from "@apollo/client";

export const ONLINE_QNA_LOG_LIST = gql`
  query GetOnlineQnaLog($wid: ID!, $page: Int!) {
    getOnlineQnaLog(wid: $wid, page: $page) {
      success
      totalCount
      qnaLogs {
        wid
        qid
        ltype
        reg_date
        qid_HIQDB_online_qna {
          id
          img
          original
          original_img
          sid
          tid
          HIQDB_online_qna_msgs {
            content
          }
        }
      }
    }
  }
`;
