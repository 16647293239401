import { ClassRegistRequset, useClassRegist } from "api/classRegist";
import Container from "components/atoms/Container";
import React, { useCallback, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate } from "react-router";
import styled from "styled-components";
import AdditionalFile from "./components/AdditionalFile";
import ClassContentEditor from "./components/ClassContentEditor";
import LectureRegistForm from "./components/LectureRegistForm";
import LectureRegistHeader from "./components/LectureRegistHeader";
import VideoFileUpload from "./components/VideoFileUpload";
import UiLoading from "../../uiComponents/UiLoading/UiLoading";

const LectureRegist = () => {
  const methods = useForm<ClassRegistRequset>({
    defaultValues: { files: [] },
  });
  const [isLoading, setIsLoading] = useState(false);

  const { mutateAsync } = useClassRegist();
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = methods;

  const navigate = useNavigate();

  const onSubmit = useCallback(async (formData: ClassRegistRequset) => {
    setIsLoading(true);
    try {
      const res = await mutateAsync(formData);
      setIsLoading(false);
      navigate(`/lecture`);
    } catch (e) {
      console.log(e);
    } finally {
      setIsLoading(false);
    }
  }, []);
  const onError = useCallback((error) => {
    console.log(error);
  }, []);

  return (
    <FormProvider {...methods}>
      <Container>
        <form onSubmit={handleSubmit(onSubmit, onError)}>
          <LectureRegistHeader title="수업영상 등록" />
          <GapContainer>
            <LectureRegistForm />
            <VideoFileUpload />
            <ClassContentEditor />
            <AdditionalFile />
          </GapContainer>
        </form>
      </Container>
      {isLoading && <UiLoading />}
    </FormProvider>
  );
};

export const GapContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 20px;
`;

export default LectureRegist;
