import style from "../../asset/style"

const Message_Style = {
    c : {
        display:"flex",
        flexDirection:"column",
        flex:1,
        width:"100%",
        height:"100%",
        // padding : style.common.padding,
    },
    tap_c : {
        margin : "10px 0px",
        display:"flex",
        // justifyContent:"space-between",
        // justifyContent:"flex-end",
        alignItems:"center",
        width:"100%",
        height : "40px",
    },
    tap_w : {
        display : 'flex',
    },
    tap_text : {
        fontWeight : 'bold',
        fontSize : '18px',
        marginRight : '15px',
        padding : "5px 0px",
    },
}

export default Message_Style