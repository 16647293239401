import React from "react";
import Thumbnail from "../../../../../uiComponents/UiNotice/NoticeLectureItem/Thumbnail";
import styles from "./style.module.scss";

const Item = ({ boardInfo, userInfo }) => {
  return (
    <div className={styles.LectureVideoItemContainer}>
      <div className={styles.ThumbnailSection}>
        <Thumbnail data={boardInfo} who={userInfo.type === 1 ? "student" : "teacher"} />
      </div>
      <h2>{boardInfo.title}</h2>
      <div style={{ display: "flex", justifyContent: "space-around", width: "100%" }}>
        <div className={styles.NameSection}>{boardInfo.cid_HIQDB_class.name} 수업</div>
        <div className={styles.ViewSection}>조회수 {boardInfo.view ?? 0}회</div>
        <div className={styles.NameSection}>
          {boardInfo.mid_HIQDB_user_credential.user.name} 선생님
        </div>
      </div>
    </div>
  );
};

export default Item;
