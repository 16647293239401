import React, { CSSProperties, useEffect, useState } from "react";
import { useParams } from "react-router";
import face_student from "../../asset/Img/defaultpic_student.png";
import face_teacher from "../../asset/Img/defaultpic_teacher.png";
import apiFn from "../../asset/apiClass";
import constants from "../../asset/constants";
import style from "../../asset/style";
import { UiInput } from "../../uiComponents/UiInput/UiInput";
import UiMessage_Style from "../../uiComponents/UiMessage/UiMessage_Style";
import UiPeople_Style from "../../uiComponents/UiPeople/UiPeople_Style";

type Modal_peopleProps = {
  setIsModal: (isModal: boolean) => void;
  userInfo: any;
};

const Modal_people = ({ setIsModal, userInfo }: Modal_peopleProps) => {
  let params = useParams();

  var [list, setList] = useState([]);
  var [search, setSearch] = useState("");

  const apiPro_read = async () => {
    try {
      let state = {
        table: "user_credential",
        sort: "reg_date desc",
        ref: ["A.* |HIQDB_user A on A.index=HIQDB_user_credential.user_id and user_type = 2"],
        page: 1,
        amount: 999,
        or: {
          [`A.name`]: `= '${search}'`,
          [`HIQDB_user_credential.name`]: `= '${search}'`,
          [`A.tel`]: `= '${search}'`,
          [`A.nick`]: `= '${search}'`,
          // [`HIQDB_teacher.${searchType}`] : `= '${search}'`
        },
        where: { "A.user_type": "= 2" },
        // or:"y",
      };

      let data = await apiFn.comApi({
        state: state,
      });

      console.log("@@ ", data);

      if (data["list"].length > 0) {
        setList([...data["list"]]);
      } else {
        setList([]);
      }
    } catch (e) {
      console.log(e);
    }
  };

  // 선생님 교재 초대
  const apiPro_ins_book = async (d) => {
    try {
      let bid = params["id"];
      let isOk = await apiFn.comApi({
        state: {
          table: "book_link",
          sendMsg: {
            type: "book_invite",
            targetMid: d["id"],
            orderMid: userInfo["id"],
            boid: bid,
            channel: constants.channelId,
          },
          ins: [
            {
              mid: d["id"],
              bid: bid,
              type: 9,
              status: 5,
            },
          ],
        },
      });
      setIsModal(false);
    } catch (e) {}
  };

  useEffect(() => {}, []);

  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        zIndex: 130002,
        backgroundColor: "rgba(150, 150, 150,0.5)",
        // backgroundColor:"red",
        width: "100%",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
      onClick={() => {
        setIsModal(false);
      }}
    >
      <div
        style={{
          width: "75%",
          height: "70%",
          // minHeight:"55%",
          // maxHeight:"80%",
          backgroundColor: "white",
          padding: "15px 20px 30px 20px",
          borderRadius: 10,
          border: "1px solid rgb(200, 200, 200)",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          filter: "drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.37))",
        }}
        onClick={(e) => {
          // console.log("###");
          // e.stopPropagation();
          // e.preventDefault();
        }}
      >
        <div
          style={{
            fontWeight: 500,
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          선생님 찾기
        </div>
        <>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginTop: "10px",
              paddingBottom: "10px",
            }}
          >
            <SearchBox
              placeholder="이름, 전화번호, 이메일, 닉네임을 입력해주세요."
              search={search}
              setSearch={setSearch}
            />
            <div
              style={{
                backgroundColor: "#4479A9",
                width: "42px",
                height: "28px",
                borderRadius: 5,
                color: "white",
                fontWeight: 400,
                fontSize: "12px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginLeft: "5px",
              }}
              onClick={(e) => {
                apiPro_read();
                e.stopPropagation();
              }}
            >
              검색
            </div>
          </div>
          <div
            style={{
              paddingTop: "5px",
              overflowY: "scroll",
              display: "flex",
              justifyContent: "flex-start",
              flexDirection: "column",
              flex: 1,
            }}
          >
            {list.map((v) => (
              <Member
                fnClick={(data) => {
                  apiPro_ins_book(data);
                }}
                data={v}
              />
            ))}
          </div>
        </>
      </div>
    </div>
  );
};

type MemberProps = {
  data: any;
  fnClick: (data: any) => void;
};

const Member = ({ data, fnClick }: MemberProps) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        maxWidth: style.common.maxWidth,
      }}
      onClick={(e) => {
        fnClick(data);
        e.stopPropagation();
        e.preventDefault();
      }}
    >
      <div
        style={{
          display: "flex",
          padding: "7px 0px",
          width: "100%",
          alignItems: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginRight: "10px",
            }}
            onClick={() => {}}
          >
            <div
              style={{
                ...UiPeople_Style.section_i_w,
                width: "40px",
                height: "40px",
              }}
            >
              <img
                style={UiMessage_Style.section_i as CSSProperties}
                onError={(e) => {
                  e.currentTarget.src = data?.user_type === 2 ? face_teacher : face_student;
                }}
                src={
                  data
                    ? data["img"]
                      ? constants.s3_url +
                        `${data?.user_type === 2 ? "/teacher/" : "/student/"}${data["img"]}` +
                        ".jpg?"
                      : constants.apiUrl +
                        `${data?.user_type === 2 ? "/teacher/" : "/student/"}${data["id"].normalize("NFD")}` +
                        ".jpg?"
                    : data?.user_type === 2
                      ? face_teacher
                      : face_student
                }
              />
            </div>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "flex-end",
            }}
            onClick={() => {}}
          >
            <div style={{ ...UiPeople_Style.section_r_t_t_text_2, fontWeight: 500 }}>
              {`${data["name"] ? data["name"] : "이름없음"} (${data["nick"] ? data["nick"] : ""})`}
            </div>
            <div
              style={{
                fontWeight: 300,
                fontSize: "12px",
              }}
            >
              {data?.user_type === 2 ? "선생님" : "학생"}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

type SearchBoxProps = {
  search: string;
  setSearch: (search: string) => void;
  placeholder: string;
};

const SearchBox = ({ search, setSearch, placeholder }: SearchBoxProps) => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        height: "28px",
        flex: 1,
      }}
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <UiInput
        inputClass="input_t"
        placeholder={placeholder}
        type="basic"
        basic_container_style={{
          height: "94%",
          padding: "0px 0px 0px 5px",
        }}
        inputValue={search}
        fnChange={(e) => {
          setSearch(e.target.value);
          search = e.target.value;
          e.stopPropagation();
        }}
        fnFocus={(e) => {
          e.stopPropagation();
        }}
      />
    </div>
  );
};

export default Modal_people;
