import Draggable from "react-draggable";
import React from "react";
import constants from "../../../../asset/constants";
import { useParams } from "react-router";
import { Close } from "@material-ui/icons";
import { IcEdit } from "../../../../components/atoms/icons/edit";
import {
  AnswerBox,
  CloseButton,
  Container,
  HeaderTextSection,
  ImageEl,
  ImageWrapper,
  ModalHeaderWrapper,
  QuestionEditButton,
} from "./styeld";
import { DraggingModalAtom } from "../../../../application/recoils/ui/atoms";
import { DraggingModalEnum } from "../../../../application/recoils/ui/types";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import LatexEditor from "../LatexEditor";
import { answerContentAtom } from "../../../../application/recoils/answer/atom";
import { editShowModalAtom } from "../../../../application/recoils/modal/atoms";
import { EditModalEnum } from "../../../../application/recoils/modal/types";

export default function DraggableModal({
  usedEditButton,
}: {
  usedEditButton?: boolean;
}): JSX.Element {
  const params = useParams();
  const [ModalType, setModalType] = useRecoilState(DraggingModalAtom);
  const answerContent = useRecoilValue(answerContentAtom);
  const setEditModalData = useSetRecoilState(editShowModalAtom);

  if (ModalType === DraggingModalEnum.EMPTY) return <></>;

  const handleClose = () => {
    setModalType(DraggingModalEnum.EMPTY);
  };
  const handleEditQuestion = () => {
    setEditModalData(EditModalEnum.QUESTION);
    handleClose();
  };

  return (
    <Draggable positionOffset={{ x: "-50%", y: "-50%" }} cancel=".unused-drag">
      <Container>
        <ModalHeaderWrapper>
          <HeaderTextSection>
            <h3>{ModalType === DraggingModalEnum.QUESTION ? "문제이미지" : "원본 해설"}</h3>
            <p>드래그를 통해 자유롭게 움직 일 수 있습니다.</p>
          </HeaderTextSection>
          <CloseButton className={"unused-drag"} onClick={handleClose}>
            <Close
              style={{
                color: "#fff",
                width: "16px",
                height: "16px",
              }}
            />
          </CloseButton>
        </ModalHeaderWrapper>
        <ImageWrapper className={"unused-drag"}>
          {ModalType === DraggingModalEnum.QUESTION ? (
            <ImageEl
              src={`${constants.s3_url}/qna/${params.qid}.jpg`}
              alt={"문제사진"}
              onDragStart={(event) => event.preventDefault()}
            />
          ) : (
            <AnswerBox>
              <LatexEditor content={answerContent} themeType={"yellow"} />
            </AnswerBox>
          )}
        </ImageWrapper>
        {ModalType === DraggingModalEnum.QUESTION && usedEditButton && (
          <QuestionEditButton onClick={handleEditQuestion} className={"unused-drag"}>
            <IcEdit color={"#2bf096"} />
            문제수정
          </QuestionEditButton>
        )}
      </Container>
    </Draggable>
  );
}
