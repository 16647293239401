const UiRadioListStyle = {
    container : {
        display:'flex',
        // flex:1,
        // height:"100%",
    },
    label_style : {
        marginRight:5,
        color : "#8F8F8F",
        fontSize:"14px",
    }
}

export default UiRadioListStyle 