import { atom } from "recoil";
import { AtomKeys } from "../constants";
import { ActionButtonsType, DraggingModalEnum } from "./types";

export const answerViewAtom = atom<boolean>({
  key: AtomKeys.ANSWER_VIEW,
  default: false,
  // effects: [localForageEffect(AtomKeys.ANSWER_VIEW)],
});

export const actionButtonsAtom = atom<ActionButtonsType>({
  key: AtomKeys.ACTION_BUTTONS,
  default: {
    question: false,
    answer: false,
  },
});

export const fullSreenAtom = atom<boolean>({
  key: AtomKeys.FULL_SCREEN,
  default: false,
  // effects: [localForageEffect(AtomKeys.FULL_SCREEN)],
});
export const sideMenuAtom = atom<boolean>({
  key: AtomKeys.SIDE_MENU,
  default: false,
});

export const showMessageLoadingAtom = atom<boolean>({
  key: AtomKeys.SHOW_MESSAGE_LOADING,
  default: false,
});

export const DraggingModalAtom = atom<DraggingModalEnum>({
  key: AtomKeys.DRAGGING_MODAL,
  default: DraggingModalEnum.EMPTY,
});
