import { FontStyleMap } from "components/atoms/Font";
import TextInput from "components/atoms/TextInput";
import React, { useContext } from "react";
import { Controller, useFormContext } from "react-hook-form";
import styled from "styled-components";
import { PdfUploadForm, PdfUploadModalContext } from "..";

const InputArea = () => {
  const {
    control,
    formState: { errors },
  } = useFormContext<PdfUploadForm>();

  const { bookId } = useContext(PdfUploadModalContext);

  return (
    <Container>
      {bookId ? null : (
        <Controller
          name="title"
          control={control}
          rules={{ required: "제목을 입력해주세요" }}
          render={({ field }) => (
            <StyledInput
              label="교재명"
              {...field}
              size={"small"}
              error={!!errors.title}
              helperText={errors.title?.message}
              placeholder="교재명을 입력해주세요."
            />
          )}
        />
      )}
      <Controller
        name="sourceName" //출처명 파라미터명
        control={control}
        rules={{ required: "출처를 입력해주세요" }}
        render={({ field }) => (
          <StyledInput
            label="출처"
            {...field}
            size={"small"}
            error={!!errors.sourceName}
            helperText={errors.sourceName?.message}
            placeholder="출처명을 입력해주세요."
          />
        )}
      />
    </Container>
  );
};

const Container = styled.div`
  margin-top: 14px;
  gap: 18px;
  display: flex;
  flex-direction: column;
`;

export const StyledInput = styled(TextInput)`
  > label {
    color: var(--neutral-color-natural-70, var(--Default-Gray-90, #262626));
    ${FontStyleMap["KR/regular/$KR.CAP.REG.12"]}
  }
  .MuiTextField-root {
    flex: 1;
    border-radius: 10px;
    > .MuiOutlinedInput-root {
      border-radius: 10px;
      color: var(--neutral-color-natural-60, #434343);
      ${FontStyleMap["KR/regular/$KR.CAP.REG.12"]}
      padding-right: 6px;
      input {
        padding-right: 0;
      }
    }
  }
`;

export default InputArea;
