import {useState, useEffect, useRef} from 'react'
import {useParams} from 'react-router'
import apiFn from '../../asset/apiClass'
import UiFolder from '../../uiComponents/UiFolder/UiFolder'
import UiBook from '../../uiComponents/UiBook/UiBook'
import {axiosInstance} from "../../api/common";


const Modal_share_book = ({
                              setIsModal,
                              userInfo,
                          }) => {
    let params = useParams();
    const [isAll, setIsAll] = useState(false);
    const [studentData, setStudentData] = useState(null)
    const [teacherData, setTeacherData] = useState(null)
    const [bookList, setBookList] = useState(null)
    const [folderList, setFolderList] = useState(null);
    const [shareBookList, setShareBookList] = useState(null);

    const apiPro_already_share_books = async () => {
        try {
            let data = await apiFn.comApi({
                state: {
                    table: "book",
                    page: 1,
                    amount: 9999,
                    u_id: userInfo.id,
                    sort: `reg_date desc`,
                    ref: [
                        'HIQDB_book_subs.public as subs_public|HIQDB_book_link.bid=HIQDB_book_subs.bid',
                        'HIQDB_book_link.mid|HIQDB_book_link.bid=HIQDB_book.id',
                        'HIQDB_book_link.type|HIQDB_book_link.bid=HIQDB_book.id',
                    ],
                    out: [
                        'HIQDB_book_subs.name as subs_name:bid:id'
                    ],
                    where: {
                        'HIQDB_book_link.cid': `= '${params.id}'`,
                        'HIQDB_book.status': '= 1',
                    }
                }
            })
            return data.list
        } catch (e) {
            console.error(e);
        }
    }

    // 교재 리스트
    const apiPro_read = async (fid) => {
        try {
            const or = fid ? undefined : {
                "HIQDB_book_link.fid#": `= 0`,
                "HIQDB_book_link.fid": `IS NULL`,
            }
            const where = fid ? {
                "HIQDB_book_link.fid": `= ${fid}`,
                "HIQDB_book_link.mid": `= '${userInfo.id}'`,
                "HIQDB_book.status": `!= '9'`,
                "HIQDB_book_link.type": "!= 9",
            } : {
                "HIQDB_book_link.mid": `= '${userInfo.id}'`,
                "HIQDB_book.status": `!= '9'`,
                "HIQDB_book_link.type": "!= 9",
            }
            let isOk = await apiFn.comApi({
                state: {
                    table: "book",
                    page: 1,
                    amount: 999,
                    u_id: userInfo.id,
                    sort: "reg_date desc",
                    ref: [
                        'HIQDB_book_link.cid|HIQDB_book_link.bid=HIQDB_book.id',
                        'HIQDB_book_link.type|HIQDB_book_link.bid=HIQDB_book.id',
                        'HIQDB_book_link.status as link_status|HIQDB_book_link.bid=HIQDB_book.id',
                    ],
                    out: [
                        'HIQDB_book_subs.name as subs_name:bid:id'
                    ],
                    or,
                    where
                }
            })
            const shareBooks = await apiPro_already_share_books();
            const notShareBookList = isOk.list.reduce((acc, book) => {
                const isAlreadyShared = shareBooks.filter(x => x.id === book.id).length > 0;
                const isIncluded = acc.map(({id}) => id).includes(book.id)
                if (!isAlreadyShared && !isIncluded) acc.push(book)
                return acc;
            }, [])
            setBookList(notShareBookList);
            setShareBookList(shareBooks);
        } catch (e) {
            console.error(e);
        }
    }


    // 폴더 리스트
    const apiPro_read_folder = async () => {
        try {
            let data = await apiFn.comApi({
                state: {
                    table: "folder",
                    page: 1,
                    amount: 9999,
                    sort: "name desc",
                    where: {
                        "HIQDB_folder.mid": `='${userInfo.id}'`,
                        "HIQDB_folder.ftype": `='0'`,
                    }
                }
            })
            setFolderList(data.list)
        } catch (e) {
            console.error(e);
        }
    }


    // 교재 -> 강의 공유
    const apiPro_set = async (targetBook) => {
        try {
            const {data} = await axiosInstance.post('/book/share', {
                bookId: targetBook.id,
                classId: params.id,
                sharedId: userInfo.id
            })
        } catch (e) {
            console.error(e);
            if (e.response.status === 409) {
                alert('이미 공유되어있는 교재입니다.')
            } else if (e.response.status === 403) {
                alert(`권한이 없습니다. \n ${e?.response?.data?.message}`)
            } else if (e.response.status === 404) {
                alert('수업 정보가 없습니다.')
            } else if (e.response.status === 400) {
                alert('정보가 잘못 되었습니다.')
            }
        } finally {
            setIsModal(false)
        }
    }

    // 강의 학생 목록
    const apiPro_read_student = async (sort, inSearch) => {
        try {
            let data = await apiFn.comApi({
                state: {
                    table: "class",
                    page: 1,
                    amount: 9999,
                    sort: "reg_date desc",
                    ref: [
                        'HIQDB_member_class.cid|HIQDB_class.id=HIQDB_member_class.cid',
                        'HIQDB_member_class.mid|HIQDB_class.id=HIQDB_member_class.cid',
                    ],
                    where: {
                        'HIQDB_class.id': `='${params.cid}'`,
                        'HIQDB_member_class.cid': `='${params.cid}'`,
                    }
                }
            })
            let in_id = data.list.reduce((a, v, i) => {
                if (i === 0) {
                    return a = `'${v.mid}'`
                } else {
                    return a = a + `,'${v.mid}'`
                }
            }, "")

            let array = []
            if (in_id.length === 0) {
                setStudentData([])
            }


            let data2 = await apiFn.comApi({
                state: {
                    table: "user_credential",
                    page: 1,
                    amount: 9999,
                    sort: `A.name desc`,
                    where: {'HIQDB_user_credential.name': `in (${in_id})`},
                    ref: [
                        `HIQDB_member_class.type ,HIQDB_member_class.reg_date as member_date ,HIQDB_member_class.cid, HIQDB_member_class.status as s_status |HIQDB_member_class.mid=HIQDB_user_credential.name and HIQDB_member_class.cid = '${params.cid ?? params.id}' `,
                        'A.* |HIQDB_user A on A.index=HIQDB_user_credential.user_id and user_type = 1',
                    ]
                }
            })

            data2.list.map((v, i) => {
                v.classList = []
                if (v.s_status === 1) {
                    array.push(v)
                }
            })

            console.log("student data : ", array);
            setStudentData(array)
        } catch (e) {
            console.error("@@@@@@@@@@", e);
        }
    }

    // 강의 선생님 목록
    const apiPro_read_teacher = async (sort, search) => {
        try {
            let data = await apiFn.comApi({
                state: {
                    table: "member_class",
                    page: 1,
                    amount: 9999,
                    sort: "reg_date desc",
                    ref: [
                        'HIQDB_class.intro|HIQDB_class.id=HIQDB_member_class.cid',
                        'HIQDB_class.address|HIQDB_class.id=HIQDB_member_class.cid',
                        'HIQDB_class.tel|HIQDB_class.id=HIQDB_member_class.cid',
                        'HIQDB_class.email|HIQDB_class.id=HIQDB_member_class.cid',
                        'HIQDB_class.owner|HIQDB_class.id=HIQDB_member_class.cid',
                        'HIQDB_class.pid|HIQDB_class.id=HIQDB_member_class.cid',
                    ],
                    where: {
                        'HIQDB_member_class.cid': `='${params.cid}'`,   // 수업 ID
                    }
                }
            })
            let in_id = data.list.reduce((a, v, i) => {
                if (i === 0) {
                    return a = `'${v.mid}'`
                } else {
                    return a = a + `,'${v.mid}'`
                }
            }, "")
            let where_obj = {}
            if (search) {
                where_obj[`HIQDB_teacher.name like "%${search}%" or HIQDB_teacher.tel`] = `like '%${search}%'`
            }

            let data2 = await apiFn.comApi({
                state: {
                    // 0906 pid -> cid 로 변경
                    // cid : data["list"][0]["pid"],
                    cid: params.id,
                    table: "user_credential",
                    page: 1,
                    amount: 9999,
                    sort: "reg_date desc",
                    where: {
                        'HIQDB_user_credential.name': `in (${in_id})`,
                        ...where_obj
                    },
                    ref: [
                        `HIQDB_member_class.type,HIQDB_member_class.cid, HIQDB_member_class.status as t_status |HIQDB_member_class.mid=HIQDB_user_credential.name and HIQDB_member_class.cid = '${data.list[0].cid}' `,
                        'A.* |HIQDB_user A on A.index=HIQDB_user_credential.user_id and A.user_type = 2',
                    ]
                }
            })

            let array = []

            data2["list"].reduce((a, v, i) => {
                let obj = {
                    id: v.id,
                    name: v.name,
                    cid: v.cid,
                    nick: v.nick,
                    status: v.t_status,
                    qtotal: v.qnacnt.total, // 총 질문수
                    type: v.type,
                    classList: v.classList,
                    tel: v.tel,
                    email: v.email,
                    intro: v.intro,
                    img: v.img,
                }
                if (v.t_status === 1) {
                    array.push(obj)
                }
            }, [])

            setTeacherData(array)
        } catch (e) {
            console.error(e);
        }
    }

    const apiPro_book_type = async (id, bid) => {
        try {
            await apiFn.comApi({
                state: {
                    table: "book_link",
                    page: 1,
                    amount: 9999,
                    sort: "mid desc",
                    ins: [{
                        mid: id,
                        bid: bid,
                        type: 8,
                        status: 2,
                        point: params.cid,
                    }]
                }
            })
        } catch (e) {
            console.log("APIPRO book type Error : ", e);
        }
    }

    useEffect(() => {
        apiPro_read_folder();
        apiPro_read();
        apiPro_read_teacher();
        apiPro_read_student();
        apiPro_read_folder();
    }, [params])

    useEffect(() => {
        apiPro_read();
    }, [])

    return (
        <div style={{
            position: "fixed",
            top: 0,
            zIndex: 130002,
            backgroundColor: "rgba(150, 150, 150,0.5)",
            // backgroundColor:"red",
            width: "100%",
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
        }}
             onClick={() => {
                 setIsModal(false)
             }}
        >
            <div style={{
                // width : "75%",
                width: "300px",
                minHeight: "55%",
                maxHeight: "75%",
                backgroundColor: "white",
                padding: "35px 20px 40px 20px",
                borderRadius: 10,
                border: "1px solid rgb(200, 200, 200)",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                filter: "drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.37))",
            }}
                 onClick={(e) => {
                     e.stopPropagation();
                     // e.preventDefault();
                 }}
            >
                <div style={{
                    display: "flex",
                    justifyContent: "center",
                    fontSize: "18px",
                    fontWeight: 700,
                    marginBottom: "10px",
                }}>
                    {"공유할 교재 추가"}
                </div>
                {/* <UiSearchBox
                    placeholder={"수업명을 입력해주세요."}
                    inputClass={"input_t"}
                    fnChange={(e)=>{
                    }}
                    fnClick={()=>{
                    }}
                    isTeacher={true}
                /> */}
                <div style={{
                    width: "300px",
                    margin: "15px 0px",
                    overflowY: "scroll",
                    display: "flex",
                    justifyContent: "flex-start",
                    flexDirection: "column",
                    flex: 1,
                }}>
                    {
                        // params["where"] == "all" &&
                        <div style={{
                            display: "flex",
                            flexWrap: "wrap",
                            padding: "17px 0px",
                        }}>
                            {isAll ?
                                <div style={{
                                    // display:"flex",
                                    // flexGrow:1,
                                    // justifyContent:"center",
                                    maxWidth: "120px",
                                    // width : "calc((100vw - 40px)/3)"
                                }}>
                                    <UiFolder
                                        data={{id: "0", name: "전체"}}
                                        fnClick={(data) => {
                                            apiPro_read(data.id)
                                            setIsAll(false)
                                        }}
                                    />
                                </div>
                                :
                                (folderList?.map((v) =>
                                    <div key={v.id} style={{
                                        display: "flex",
                                        flexGrow: 1,
                                        justifyContent: "center",
                                        maxWidth: "120px",
                                        // width : "calc((100vw - 40px)/3)"
                                    }}>
                                        <UiFolder
                                            data={v}
                                            fnClick={(data) => {
                                                console.log("F DATA : ", data)
                                                apiPro_read(data.id)
                                                setIsAll(true)
                                            }}

                                        />
                                    </div>
                                ))
                            }
                        </div>
                    }
                    <div style={{
                        display: "flex",
                        flexWrap: "wrap",
                        width: "300px",
                        // justifyContent:"center",
                    }}>
                        {bookList?.map((v) =>
                            <div style={{
                                display: "flex",
                                flexGrow: 1,
                                maxWidth: "120px",
                            }} key={'book-container-' + v.id + '-' + v.typeof}>
                                <UiBook
                                    data={v}
                                    fnClick={(d) => {
                                        // console.log("book data : ", d);
                                        // return;
                                        apiPro_set(d)
                                    }}
                                />
                            </div>
                        )
                        }
                    </div>
                </div>
                <div style={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "center",
                    marginTop: "10px",
                }}>
                    <div style={{
                        display: "flex",
                        // width:"180px",
                        // justifyContent:"space-between",
                    }}>
                        <div style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            width: "87px",
                            height: "40px",
                            borderRadius: "15px",
                            backgroundColor: "#D8D8D8",
                            fontSize: "14px",
                            color: "#FFFFFF",
                            fontWeight: 700
                        }}
                             onClick={() => {
                                 setIsModal(false)
                             }}
                        >
                            취소
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Modal_share_book

