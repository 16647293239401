import {
  ArrowBackIosRounded,
  ArrowUpwardRounded,
  CloseRounded,
  MessageOutlined,
} from "@mui/icons-material";
import { useReplyList, useReplyRegist } from "api/classDetail";
import { getUser } from "asset/storage";
import Input from "components/atoms/Input";
import Section from "components/atoms/Section";
import React, { createContext, useCallback, useContext, useMemo, useState } from "react";
import { useParams } from "react-router";
import styled, { css } from "styled-components";
import { BoardReply } from "types/graphql/graphql";
import ReplyItem from "./ReplyItem";
export type ReplyStatusType = "list" | "simple";
interface IReplyContext {
  setReplyStatus?: (status: ReplyStatusType) => void;
  replyStatus?: ReplyStatusType;
  replyId?: string;
  setReplyId?: (id: string) => void;
}
interface IReplyArea extends IReplyContext {
  children: React.ReactNode;
}

export const ReplyContext = createContext<IReplyContext>(null);

const ReplyArea = ({ children, setReplyStatus, replyStatus }: IReplyArea) => {
  const [replyId, setReplyId] = useState<string>(null);
  return (
    <ReplyContext.Provider value={{ setReplyStatus, replyStatus, replyId, setReplyId }}>
      {children}
    </ReplyContext.Provider>
  );
};

const ReplySimple = () => {
  const { setReplyStatus } = useContext(ReplyContext);
  const params = useParams();
  const parameters = {
    boardId: params["id"],
  };

  // const { data } = useReplyList(parameters);
  const { data: gqlData, refetch } = useReplyList(parameters);

  const replyList = useMemo(() => {
    const replies = [...gqlData?.getBoard?.boards[0]?.replies];
    const top3Reply = replies.slice(0, 2);
    return top3Reply as BoardReply[];
  }, [gqlData]);

  const replyLength = useMemo(() => gqlData?.getBoard?.boards[0]?.replies.length ?? 0, [gqlData]);
  if (!replyList?.length) {
    const userInfo = getUser();

    return userInfo ? (
      <EmptyReplyItem onClick={() => setReplyStatus("list")}>
        <MessageOutlined />
        첫번째 댓글을 작성해주세요!
      </EmptyReplyItem>
    ) : (
      <></>
    );
  } else {
    return (
      <SimpleReplyContainer>
        <TitleFont>댓글 {replyLength}</TitleFont>
        {replyList?.map((replyItem) => (
          <ReplyItemContainer key={replyItem.id} onClick={() => setReplyStatus("list")}>
            <ReplyItem key={replyItem.id} {...replyItem} />
          </ReplyItemContainer>
        ))}
        <ReplyInputArea />
      </SimpleReplyContainer>
    );
  }
};

const ReplyListArea = () => {
  const { replyId } = useContext(ReplyContext);
  return replyId ? <ReReplyList /> : <ReplyList />;
};

const ReplyList = () => {
  const { setReplyStatus } = useContext(ReplyContext);
  const params = useParams();
  const parameters = {
    boardId: params["id"],
  };
  const { data: gqlData } = useReplyList(parameters);
  const replyList = useMemo(() => gqlData?.getBoard?.boards[0]?.replies as BoardReply[], [gqlData]);
  const userInfo = getUser();
  return (
    <Section gap={20} direction="column" flex={1}>
      <Section justify="space-between">
        <TitleFont>댓글 {replyList?.length ?? 0}</TitleFont>
        <IconContainer onClick={() => setReplyStatus("simple")}>
          <CloseRounded />
        </IconContainer>
      </Section>
      <Section direction="column" gap={32}>
        {replyList?.map((replyItem) => {
          return <ReplyItem key={replyItem.id} {...replyItem} />;
        })}
      </Section>
      {userInfo && <ReplyInputArea />}
    </Section>
  );
};

const ReReplyList = () => {
  const { setReplyId, replyId } = useContext(ReplyContext);
  const params = useParams();
  const parameters = {
    boardId: params["id"],
    replyId,
  };
  const userInfo = getUser();

  const { data: gqlData } = useReplyList(parameters);
  const activeReply = useMemo(
    () => gqlData?.getBoard?.boards?.[0]?.replies?.[0] as BoardReply,
    [gqlData]
  );

  const rereplyList = useMemo(() => activeReply?.replies as BoardReply[], [gqlData]);

  return (
    <Section gap={20} direction="column" flex={1}>
      <Section justify="space-between">
        <TitleFont
          onClick={() => {
            setReplyId(null);
          }}
        >
          <ArrowLeftIconContainer>
            <ArrowBackIosRounded />
          </ArrowLeftIconContainer>
          댓글
        </TitleFont>
        <IconContainer
          onClick={() => {
            setReplyId(null);
          }}
        >
          <CloseRounded />
        </IconContainer>
      </Section>
      {activeReply && (
        <ReplyItemContainer active>
          <ReplyItem {...activeReply} />
        </ReplyItemContainer>
      )}

      <Section direction="column" gap={32}>
        {rereplyList?.map((replyItem) => {
          return (
            <ReplyItemContainer reReply>
              <ReplyItem key={replyItem.id} {...replyItem} isRereply />
            </ReplyItemContainer>
          );
        })}
      </Section>
      {userInfo && <ReplyInputArea />}
    </Section>
  );
};

const ReplyInputArea = () => {
  const { replyId } = useContext(ReplyContext);
  const params = useParams();
  const userInfo = getUser();
  // const isTeacher = useMemo(() => userInfo.type === 2, [userInfo]);
  const [isSubmit, setIsSubmit] = useState(false);
  const parameters = {
    boid: Number(params["id"]),
    wid: userInfo?.id,
    wtype: `${userInfo?.type}`,
    parent_id: replyId ? Number(replyId) : null,
    rtype: false,
  };

  const [replyContent, setReplyContent] = useState("");

  const { mutate: registReply } = useReplyRegist();

  const handleSubmit = useCallback(async () => {
    setIsSubmit(true);
    if (replyContent === "" || !userInfo) {
      return;
    }
    await registReply({
      ...parameters,
      content: replyContent,
    });
    setReplyContent("");
    setIsSubmit(false);
  }, [replyContent]);

  const isError = useMemo(() => isSubmit && !replyContent, [isSubmit, replyContent]);
  return (
    <>
      <ReplyContainer>
        <ReplyInput
          value={replyContent}
          disabled={isSubmit}
          onChange={(e) => setReplyContent(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              handleSubmit();
            }
          }}
          placeholder="자유롭게 의견을 남겨주세요."
          error={isError}
          helperText={isError && "댓글을 입력해주세요"}
        />
        <UploadIconContainer disabled={isSubmit} onClick={handleSubmit} isError={isError}>
          <ArrowUpwardRounded />
        </UploadIconContainer>
      </ReplyContainer>
    </>
  );
};

ReplyArea.ReplyListArea = ReplyListArea;
ReplyArea.ReplySimple = ReplySimple;

const TitleFont = styled.div`
  color: #292929;
  font-feature-settings:
    "clig" off,
    "liga" off;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
  display: flex;
  align-items: center;
`;

const SimpleReplyContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  flex: 1;
  overflow: hidden;
`;

const ReplyItemContainer = styled.div<{ active?: boolean; reReply?: boolean }>`
  cursor: pointer;
  display: flex;
  padding: 16px;
  align-items: flex-start;
  align-self: stretch;
  border-radius: 10px;
  border: 1px solid #d9d9d9;
  ${({ active }) =>
    active &&
    css`
      border: 1px solid #ffa723;
    `}

  ${({ reReply }) =>
    reReply &&
    css`
      padding: 0 16px 0 32px;
      border: unset;
    `}
`;

const EmptyReplyItem = styled(ReplyItemContainer)`
  align-items: center;
  justify-content: center;
  gap: 8px;
  color: #67727e;
  margin-bottom: 16px;
`;

const IconContainer = styled.button`
  border: 0;
  outline: 0;
  background: unset;
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  & > svg {
    font-size: 24px;
    color: #8b8b8b;
  }
`;

const ReplyContainer = styled.div`
  display: flex;
  gap: 16px;
  flex: 1;
  align-items: flex-end;
  position: sticky;
  bottom: 0;
  padding: 16px 0;
  background: #fbfcfe;
`;

const ReplyInput = styled(Input)`
  width: 100%;
  input {
    color: var(--Grayish-Blue, #67727e);
    font-feature-settings:
      "clig" off,
      "liga" off;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 16.8px */
    width: 100%;
    background: #fff;
    box-sizing: border-box;
    height: 32px;
  }
`;

const UploadIconContainer = styled(IconContainer)<{ isError: boolean }>`
  ${({ isError }) =>
    isError &&
    css`
      margin-bottom: 24px;
    `}
  width: 32px;
  height: 32px;
  border-radius: 5px;
  background: #fff;
  border: 1.2px solid #ffa723;
  & svg {
    font-size: 16px;
    color: #ffa723;
  }
`;

const ArrowLeftIconContainer = styled(IconContainer)`
  display: inline-flex;
  align-items: center;
  & svg {
    font-size: 16px;
    color: inherit;
  }
`;

export default ReplyArea;
