import { useMutation, useQuery } from "@apollo/client";
import Checkbox from "@mui/material/Checkbox";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import * as PortOne from "@portone/browser-sdk/v2";
import card from "asset/Img/payment/card.png";
import kakaopay from "asset/Img/payment/kakaopay.png";
import naverpay from "asset/Img/payment/naverpay.png";
import phone from "asset/Img/payment/phone.png";
import trans from "asset/Img/payment/trans.png";
import { PaymentContext } from "asset/context";
import util from "asset/util";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { Link, useSearchParams } from "react-router-dom";
import {
  CreateOrderDocument,
  GetPayMethodsDocument,
  ValidatePaymentDocument,
} from "types/graphql/graphql";
import UiLoading from "uiComponents/UiLoading/UiLoading";
import styles from "./order.module.scss";
import supportedVirtualAccounts from "./supportedVirtualAccounts";

const METHOD_IMGS = {
  CARD: card,
  MOBILE: phone,
  TRANSFER: trans,
  VIRTUAL_ACCOUNT: trans,
  KAKAOPAY: kakaopay,
  NAVERPAY: naverpay,
};
const NAVERPAY = "NAVERPAY";
const KAKAOPAY = "KAKAOPAY";
const EASY_PAY = [NAVERPAY, KAKAOPAY];
const VIRTUAL_ACCOUNT = "VIRTUAL_ACCOUNT";

export default () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { tickets, setForceUpdate, academyId } = useContext(PaymentContext);
  const [virtualAccount, setVirtualAccount] = useState();
  const [virtualAccountName, setVirtualAccountName] = useState();
  const [methodType, setMethodType] = useState();
  const [showGuide, setShowGuide] = useState(false);
  const [privacyChecked, setPrivacyChecked] = useState(false);
  const [termsChecked, setTermsChecked] = useState(false);
  const { loading, error, data, refetch } = useQuery(GetPayMethodsDocument, {
    fetchPolicy: "network-only",
    variables: { academyId },
  });
  const [
    createOrder,
    { data: createOrerData, loading: createOrderLoading, error: createOrderError },
  ] = useMutation(CreateOrderDocument);
  const [
    validateOrder,
    { data: validateOrderData, loading: validateOrderLoading, error: validateOrderError },
  ] = useMutation(ValidatePaymentDocument);

  useEffect(async () => {
    if (!!createOrerData) {
      const payload = createOrerData?.createOrder?.payload;
      const success = createOrerData?.createOrder?.success || false;
      const error = createOrerData?.createOrder?.error?.message;
      if (success) {
        // Call PORTONE API!
        const response = await PortOne.requestPayment(payload);
        if (response.code != null) {
          // 오류 발생
          return alert(response.message);
        }
        await validateOrder({
          variables: {
            orderId: payload.paymentId,
          },
        });
      } else {
        alert(error);
      }
    }
  }, [createOrerData]);

  useEffect(async () => {
    if (!!validateOrderData) {
      const success = validateOrderData?.validatePayment?.success || false;
      const result = validateOrderData?.validatePayment?.result;
      const error = validateOrderData?.validatePayment?.error?.message;
      if (success) {
        const message = result.message;
        const status = result.status;
        alert(message);
        if (status == "PAID") {
          setForceUpdate(new Date().getTime());
          navigate("/myinfo", { replace: true });
        } else if (status == "VIRTUAL_ACCOUNT_ISSUED") {
          navigate(`/myinfo/order/${result.id}`, { replace: true });
        }
      } else {
        alert(error);
      }
    }
  }, [validateOrderData, validateOrderError, navigate, setForceUpdate]);

  const goBack = useCallback(() => {
    alert("주문을 취소했습니다.");
    navigate(-1);
  }, [navigate]);

  const selectMethod = useCallback(
    (code) => (e) => {
      setMethodType(code);
    },
    []
  );

  const onChangeVirtualAccount = useCallback((event) => {
    setVirtualAccount(event.target.value);
  });

  const onChangeVirtualAccountName = useCallback((event) => {
    setVirtualAccountName(event.target.value);
  });

  const items = tickets
    .map((ticket) => ({
      id: ticket.id,
      name: ticket.name,
      title: ticket.title,
      price: ticket.price,
      count: searchParams.get(ticket.saleType) || 0,
    }))
    .filter((item) => item.count > 0);
  const totalPrice = items.reduce((acc, item) => acc + item.count * item.price, 0);
  const methods = data?.getPayMethods;
  const selectedMethod = methods?.filter((item) => item.code == methodType)?.[0];

  const onClickSubmit = useCallback(async () => {
    // create order
    const variables = {
      input: {
        academyId,
        depositBank: virtualAccount,
        depositorName: virtualAccountName,
        payMethodCode: selectedMethod.code,
        tickets: items.map((item) => ({
          ticketId: item.id,
          quantity: parseInt(item.count),
        })),
      },
    };
    await createOrder({ variables });
  }, [academyId, selectedMethod, items, virtualAccount, virtualAccountName]);

  const canSumbmit =
    !!privacyChecked &&
    !!termsChecked &&
    !!selectedMethod &&
    (selectedMethod.code != VIRTUAL_ACCOUNT || (!!virtualAccount && !!virtualAccountName));

  // console.log(
  //   `selectedMethod:${selectedMethod?.code}, virtualAccount:${virtualAccount}, virtualAccountName:${virtualAccountName}`
  // );

  if (loading) {
    return <UiLoading isText={false} text="" isTransparent={true} />;
  }
  return (
    <div className={styles.orderContainer}>
      <button className={styles.closeButton} onClick={goBack}>
        X
      </button>
      <div className={`${styles.section} ${styles.price}`}>
        {items.map((item) => (
          <div className={styles.item}>
            <div className={styles.titleContainer}>
              <span className={styles.title}>{item.title}</span>
              <span className={styles.desc}>{util.formatCurrency(item.price)}</span>
            </div>
            <div className={styles.price}>{`x ${item.count}`}</div>
          </div>
        ))}
        <div className={styles.total}>{util.formatCurrency(totalPrice)}</div>
      </div>

      <div className={`${styles.section} ${styles.method}`}>
        <span className={styles.name}>결제수단 선택</span>
        <div className={styles.grid}>
          {methods.map((method) => (
            <button
              className={`${styles.item} ${methodType == method.code ? styles.selected : ""}`}
              onClick={selectMethod(method.code)}
            >
              <img className={styles.image} src={METHOD_IMGS[method.code]} />
              <div className={styles.title}>{method.name}</div>
            </button>
          ))}
        </div>
      </div>
      {selectedMethod && (
        <button className={`${styles.section} ${styles.guide}`} onClick={() => setShowGuide(true)}>
          <span className={styles.title}>{`${selectedMethod.name} 결제 안내`}</span>
        </button>
      )}

      {selectedMethod?.code == VIRTUAL_ACCOUNT && (
        <div className={`${styles.section} ${styles.virtualAccount}`}>
          <FormControl sx={{ m: 1, minWidth: 80 }}>
            <InputLabel id="virtual-account-select" size="small">
              입금은행
            </InputLabel>
            <Select
              size="small"
              labelId="virtual-account-select"
              value={virtualAccount}
              onChange={onChangeVirtualAccount}
              autoWidth
              label="VirtualAccount"
            >
              <MenuItem value="">
                <em>_</em>
              </MenuItem>
              {supportedVirtualAccounts.map((item) => (
                <MenuItem value={item.code}>{item.name}</MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl sx={{ m: 1, minWidth: 80 }}>
            <TextField
              id="virtual-account-name"
              label="입금자명"
              variant="outlined"
              size="small"
              value={virtualAccountName}
              onChange={onChangeVirtualAccountName}
            />
          </FormControl>
        </div>
      )}

      <FormGroup>
        <div className={styles.section}>
          <FormControlLabel
            onChange={(e) => setPrivacyChecked(e.target.checked)}
            control={<Checkbox size="small" disabled={!selectedMethod} />}
            label={
              <div className={styles.check}>
                <Typography className={styles.checkLabel}>개인정보 수집 동의</Typography>
                <Link to={"/privacy"} className={styles.checkLink}>
                  약관 보기
                </Link>
              </div>
            }
          />
          <FormControlLabel
            onChange={(e) => setTermsChecked(e.target.checked)}
            control={<Checkbox size="small" disabled={!selectedMethod} />}
            label={
              <div className={styles.check}>
                <Typography className={styles.checkLabel}>
                  상품, 가격 정보 확인 및 결제 동의
                </Typography>
                <Link to={"/terms"} className={styles.checkLink}>
                  정책 보기
                </Link>
              </div>
            }
          />
        </div>
      </FormGroup>

      <button disabled={!canSumbmit} className={styles.submitButton} onClick={onClickSubmit}>
        {`${util.formatCurrency(totalPrice)} 결제하기`}
      </button>

      <Dialog
        open={selectedMethod && showGuide}
        keepMounted
        onClose={() => setShowGuide(false)}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent>
          <div
            dangerouslySetInnerHTML={{
              __html: `${selectedMethod?.guide}`,
            }}
          />
        </DialogContent>
      </Dialog>

      {(createOrderLoading || validateOrderLoading) && (
        <UiLoading isText={false} text="" isTransparent={true} />
      )}
    </div>
  );
};
