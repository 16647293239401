import { React , useState , useEffect } from 'react'
import { useNavigate, useParams } from 'react-router'
import style from '../../asset/style'
import MyInfo_Style from './MyInfo_Style'
import util from '../../asset/util'
import UiTextBtn from '../../uiComponents/UiTextBtn/UiTextBtn'
import { TextareaAutosize} from '@mui/base'
import UiBtn from '../../uiComponents/UiBtn/UiBtn'
import './Myinfo_memo.css'
import UiMemo from '../../uiComponents/UiMemo/UiMemo'

const Myinfo_memo = ({
    isTeacher,
    myInfo,
    userInfo,
    setHiddenNav
}) => {

    let params = useParams();

    return (
        <UiMemo 
            setHiddenNav={setHiddenNav}
            lid={params["id"]}
            userInfo={userInfo}
        />
    )
}

export default Myinfo_memo

