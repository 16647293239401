import { ArrowBackIos } from "@material-ui/icons";
import { FolderItemInterface, useMessageMarkFolderList } from "api/messageMark";
import searchIcon from "asset/Img/search.svg";
import trashIcon from "asset/Img/trash.svg";
import HeaderLeft from "components/molecules/Header/HeaderLeft";
import React, { useCallback, useContext, useMemo } from "react";
import { useNavigate } from "react-router";
import styled from "styled-components";
import { MessageMarkContext } from "../context/MessageMarkContextProvider";

const LeftIcon = () => {
  const navigate = useNavigate();
  return (
    <IconContainer onClick={() => navigate(-1)}>
      <ArrowBackIos />
    </IconContainer>
  );
};

const TrashIconContainer = () => {
  const { setIsDeleteMode } = useContext(MessageMarkContext);

  return (
    <IconContainer onClick={() => setIsDeleteMode(true)}>
      <img src={trashIcon} alt="trashIcon" />
    </IconContainer>
  );
};

const SearchIconContainer = () => {
  return (
    <IconContainer>
      <img src={searchIcon} alt="searchIcon" />
    </IconContainer>
  );
};

const MessageMarkHeader = () => {
  const { data } = useMessageMarkFolderList();

  const folderList = useMemo(() => {
    return data?.pages.reduce((acc, page) => {
      return [...acc, ...page.list];
    }, [] as FolderItemInterface[]);
  }, [data]);

  const {
    isDeleteMode,
    setIsDeleteMode,
    allCheckedFolder,
    allRemoveCheckedFolder,
    checkedFolderList,
  } = useContext(MessageMarkContext);

  const isAllChecked = useMemo(() => {
    return folderList.length === checkedFolderList.length;
  }, [folderList, checkedFolderList]);
  const handleAllChecked = useCallback(() => {
    if (isAllChecked) {
      allRemoveCheckedFolder();
    } else {
      allCheckedFolder(folderList);
    }
  }, [allCheckedFolder, allRemoveCheckedFolder, folderList, isAllChecked]);

  const isExistFolder = useMemo(() => {
    return folderList.length > 0;
  }, [folderList]);
  return (
    <HeaderLeft
      title="오답노트"
      leftIcon={<LeftIcon />}
      rightIcon={
        isDeleteMode ? (
          <>
            <BlackButton onClick={() => setIsDeleteMode(false)}>취소</BlackButton>
            <WhiteButton onClick={handleAllChecked}>{isAllChecked ? "해제" : "전체"}</WhiteButton>
          </>
        ) : (
          <>
            {/* <SearchIconContainer /> */}
            {isExistFolder && <TrashIconContainer />}
          </>
        )
      }
    />
  );
};

const IconContainer = styled.button`
  background: none;
  border: 0;
  padding: 0;
  display: contents;
`;

const BlackButton = styled.button`
  outline: none;
  border: 0;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 57px;
  font-size: 14px;
  font-weight: 500;
  line-height: 14px;
  letter-spacing: 0.02em;
  background-color: #252525;
  color: white;
  border-radius: 15px;
`;
const WhiteButton = styled(BlackButton)`
  color: #252525;
  background-color: white;
  border: 1px solid #252525;
`;

export default MessageMarkHeader;
