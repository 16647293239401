import { ClassRegistRequset } from "api/classRegist";
import ContentEditor from "pages/LectureRegist/components/Editor/ContentEditor";
import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import styled from "styled-components";

const ClassContentEditor = () => {
  const {
    watch,
    setValue,
    control,
    formState: { errors },
  } = useFormContext<ClassRegistRequset>();
  return (
    <Container>
      <Controller
        name={"content"}
        control={control}
        render={({ field }) => (
          <ContentEditor content={field.value} onChange={(value) => field.onChange(value)} />
        )}
      />
    </Container>
  );
};

const Container = styled.div`
  margin-top: 10px;
  height: 250px;
  width: 100%;
  position: relative;
`;
export default ClassContentEditor;
