import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { axiosInstance } from "api/common";
import React from "react";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      retry: 0,
      queryFn: async ({ queryKey, pageParam }) => {
        const [url, param] = queryKey;
        const parameters = new URLSearchParams(pageParam ?? param).toString();
        const queryString = parameters ? `?${parameters}` : "";
        const result = await axiosInstance.get(`${url}${queryString}`);
        return result?.data ?? result;
      },
      refetchIntervalInBackground: false,
      refetchInterval: false,
      suspense: true,
    },
  },
});
const ReactQueryClientProvider = ({ children }) => {
  return (
    <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
  );
};

export default ReactQueryClientProvider;
