import { FolderItemInterface } from "api/messageMark";
import React, { createContext, useEffect, useMemo, useState } from "react";

export interface MessageMarkContextState {
  isDeleteMode: boolean;
  setIsDeleteMode?: (state: boolean) => void;
  checkedFolderList?: FolderItemInterface[];
  addCheckedFolder?: (folder: FolderItemInterface) => void;
  removeCheckedFolder?: (id: number) => void;
  allCheckedFolder?: (list: FolderItemInterface[]) => void;
  allRemoveCheckedFolder?: () => void;
  visibleMenuId?: number;
  setVisibleMenuId?: (id: number | null) => void;
  modifyFolderId?: number;
  setModifyFolderId?: (state: number | null) => void;
}

export const MessageMarkContext = createContext<MessageMarkContextState>({
  isDeleteMode: false,
});

const MessageMarkContextProvider = ({ children }) => {
  const [isDeleteMode, setIsDeleteMode] = useState<boolean>(false);
  const [modifyFolderId, setModifyFolderId] = useState<number | null>();
  const [checkedFolderList, setCheckedFolderList] = useState<
    FolderItemInterface[]
  >([]);
  const [visibleMenuId, setVisibleMenuId] = useState<number | null>(null);
  useEffect(() => {
    setCheckedFolderList([]);
  }, [isDeleteMode]);
  const actions = useMemo(() => {
    const addCheckedFolder = (folder: FolderItemInterface) => {
      setCheckedFolderList((prev) => [...prev, folder]);
    };

    const removeCheckedFolder = (id: number) => {
      setCheckedFolderList((prev) => prev.filter((item) => item.id !== id));
    };
    const allCheckedFolder = (list: FolderItemInterface[]) => {
      setCheckedFolderList(list);
    };
    const allRemoveCheckedFolder = () => {
      setCheckedFolderList([]);
    };
    return {
      isDeleteMode,
      setIsDeleteMode,
      checkedFolderList,
      addCheckedFolder,
      removeCheckedFolder,
      allCheckedFolder,
      allRemoveCheckedFolder,
      visibleMenuId,
      setVisibleMenuId,
      modifyFolderId,
      setModifyFolderId,
    };
  }, [
    isDeleteMode,
    setIsDeleteMode,
    checkedFolderList,
    setCheckedFolderList,
    visibleMenuId,
    setVisibleMenuId,
    modifyFolderId,
    setModifyFolderId,
  ]);

  return (
    <MessageMarkContext.Provider value={actions}>
      {children}
    </MessageMarkContext.Provider>
  );
};
export default MessageMarkContextProvider;
