import { useStudyBookList } from "api/studyBook";
import constants from "asset/constants";
import util from "asset/util";
import React, { forwardRef, Ref, useCallback, useContext, useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { useLongPress } from "use-long-press";
import { StudyBookContext } from "../context/StudyBookContext";
import StudyBookItem, { COLOR_LIST } from "./StudyBookItem";

const StudyBookList = () => {
  const [longPressHandle, setLongPressHandle] = useState(false);
  const { isConfigMode, setIsConfigMode, isDragging } = useContext(StudyBookContext);
  const { data } = useStudyBookList();
  const list = useMemo(() => data.bookList, [data.bookList]);
  const handleLongPress = useCallback(() => {
    setLongPressHandle((prev) => !prev);
    if (!isDragging) {
      setIsConfigMode(true);
    }
  }, [isDragging, longPressHandle, setIsConfigMode]);
  const longPress = useLongPress(handleLongPress, {});
  const navigate = useNavigate();

  const hiddenDragImages = useRef<HTMLDivElement>(null);
  return (
    <>
      <Container {...longPress()}>
        {list.map((item, index) => {
          return (
            <StudyBookItem
              key={item.id}
              {...item}
              index={index}
              isDragMode
              isConfigable
              hiddenDragImages={hiddenDragImages.current}
            />
          );
        })}
        {constants.isDev && (
          <PdfDemoButton onClick={() => navigate("/bookDemo")}>PDF 데모</PdfDemoButton>
        )}
      </Container>
      {isConfigMode && <DragImage ref={hiddenDragImages} />}
    </>
  );
};

const DragImage = forwardRef(({}, ref: Ref<HTMLDivElement>) => {
  const { checkedBookList } = useContext(StudyBookContext);
  const { data } = useStudyBookList();
  const list = useMemo(() => data.bookList, [data.bookList]);

  return (
    <HiddenDragImagesContainer ref={ref}>
      {list.map((item, index) => {
        const isCheck = checkedBookList.some((book) => book.id === item.id);
        if (!isCheck) {
          return;
        }
        const imageName = item.img ?? item.id;
        const imageUrl = `${constants.s3_url}/bookCover/${imageName}.jpg?${util.getRandomInt(1000, 9999)}`;
        return (
          <img
            key={item.id}
            src={imageUrl}
            onError={(event) => (event.currentTarget.src = COLOR_LIST[index % 6])}
          />
        );
      })}
    </HiddenDragImagesContainer>
  );
});

const Container = styled.div`
  margin-top: 24px;
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
  align-items: center;
  justify-content: center;
  overflow-y: auto;
  padding-bottom: 18px;
`;

const HiddenDragImagesContainer = styled.div`
  display: flex;
  gap: 8px;
  position: absolute;
  top: -1000px;
  left: -1000px;
  width: fit-content;
  max-width: 300px;
  flex-wrap: wrap;
  > img {
    width: 40px;
    object-fit: cover;
    border-radius: 4px;
  }
`;
const PdfDemoButton = styled.button`
  background-color: #ff7c7c;
  padding: 16px;
  color: white;
  border-radius: 8px;
`;
export default StudyBookList;
