const Academy_book_List_Style = {
    confrim_c : {
        display:"flex",
        flexDirection : "column",
        padding : "0px 10px",
        width : "100%",
        minHeight : "calc(100vh - 110px)",
    },
    confrim_btn_w : {
        display:"flex",
        flexDirection : "column",
        justifyContent:"flex-end",
        paddingBottom : "10px",
        width : "100%",
        flex:1,
    }
}

export default Academy_book_List_Style