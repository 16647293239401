import { useMessageMarkFolderModify, useMessageMarkFolderRegist } from "api/messageMark";
import CommonModal, { CommonModalContainer } from "components/atoms/CommonModal";
import HorizontalBlank from "components/atoms/HorizontalBlank";
import Input from "components/atoms/Input";
import ReactiveButtonContainer from "components/atoms/ReactiveButtonContainer";
import React, { useCallback, useEffect, useRef, useState } from "react";
import styled from "styled-components";
interface FolderRegistModalProps extends CommonModalControl {
  type: "modify" | "create";
  folderId?: number;
  originFolderName?: string;
}
const FolderRegistModal = ({
  type,
  open,
  onClose,
  originFolderName,
  folderId,
}: FolderRegistModalProps) => {
  const { mutateAsync: regist } = useMessageMarkFolderRegist();
  const { mutateAsync: modify } = useMessageMarkFolderModify();
  const [name, setName] = useState<string>(originFolderName);
  const handleSubmit = useCallback(async () => {
    if (type === "modify") {
      await modify({ folderId, name });
    } else {
      const result = await regist(name);
      if (result.id === 0) {
        alert("폴더명이 중복됩니다.");
        return;
      }
    }
    onClose();
  }, [name]);
  const typeName = type === "modify" ? "수정" : "추가";
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    setTimeout(() => {
      inputRef.current?.focus();
    }, 1000);
  }, [inputRef.current, name]);

  return (
    <CommonModal open={open} onClose={onClose}>
      <Container>
        <TitleFont>폴더 {typeName}</TitleFont>
        <HorizontalBlank height={24} />
        <Input
          placeholder="폴더명을 입력해주세요."
          onChange={(event) => setName(event.target.value)}
          onKeyUp={(event) => event.key === "Enter" && handleSubmit()}
          value={name}
          ref={inputRef}
          autoFocus
          autoComplete="off"
        />
        <HorizontalBlank height={32} />
        <ReactiveButtonContainer
          buttonList={[
            {
              onClick: onClose,
              variant: "text",
              title: "취소",
            },
            {
              onClick: handleSubmit,
              variant: "contained",
              title: typeName,
            },
          ]}
        />
      </Container>
    </CommonModal>
  );
};

const Container = styled(CommonModalContainer)`
  align-items: center;
  flex: 1;
  margin: auto 20px;
  box-sizing: border-box;
  max-width: 500px;
`;

const TitleFont = styled.span`
  font-family: Pretendard;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: center;
`;

const InvisibleButton = styled.button`
  outline: none;
  border: none;
  display: flex;
  font-size: 15px;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0em;
  flex: 1;
  color: #67727e;
  background-color: inherit;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 42px;
  border-radius: 10px;
`;

const ConfirmButton = styled(InvisibleButton)`
  color: #fff;
  background-color: #ffa723;
`;
export default FolderRegistModal;
