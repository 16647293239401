import { Add } from '@material-ui/icons'
import { React, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router'
import apiFn from '../../asset/apiClass'
import constants from '../../asset/constants'
import style from '../../asset/style'
import util from '../../asset/util'
import UiBtn from '../../uiComponents/UiBtn/UiBtn'
import UiPeoPle_ProfileTeacher from '../../uiComponents/UiPeoPle_ProfileTeacher/UiPeoPle_ProfileTeacher'
import UiTextBtn from '../../uiComponents/UiTextBtn/UiTextBtn'
import UiTopPopTextBtn from '../../uiComponents/UiTopPopTextBtn/UiTopPopTextBtn'
import Academy_book_Style from '../Academy/Academy_book_Style'
import Lecture_book_Style from './Lecture_book_Style'
import { SearchBox } from './Profile_lecture_detail'
import Profile_teacher_Style from './Profile_teacher_Style'

const Profile_teacher = ({
                             academyData,
                             isTeacher,
                             userInfo,
                             setHiddenNav,
                             hiddenNav,
                             scrollKey,
                             setScrolllKey,
                         }) => {
    let navigate = useNavigate();
    let params = useParams();

    let sort = [
        {name: "출처순", value: "P01"},
        {name: "평점순", value: "P02"},
        {name: "저도요순", value: "P03"},
        {name: "날짜순", value: "P04"},
    ]

    var [selectData_invite, setSelectData_invite] = useState({});
    var [selectData_l_invite, setSelectData_l_invite] = useState(0);

    var [selectData, setSelectData] = useState({})
    var [selectData_l, setSelectData_l] = useState(0)

    var [selectData_add, setSelectData_add] = useState({})
    var [selectData_add_l, setSelectData_add_l] = useState(0)

    const [isEdit_invite, setIsEdit_invite] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [isEdit_add, setIsEdit_add] = useState(false);

    var [invite_teacherData, setInvite_teacherData] = useState([])
    var [pre_teacherData, setPre_teacherData] = useState([])
    var [teacherData, setTeacherData] = useState([])

    var [sorting, setSorting] = useState('')
    var [search, setSearch] = useState("")

    // 클래스룸 내 가입신청 선생님 목록
    const apiPro_read_teacher = async (sort, inSearch) => {
        try {
            let where_obj = {}
            if (inSearch != "" && search != "") {
                where_obj[`HIQDB_teacher.name like "%${inSearch || search}%" or HIQDB_teacher.tel`] = `like '%${inSearch || search}%'`
            }

            let state = {
                table: "member_class",
                page: 1,
                amount: 9999,
                cid: params["id"],
                sort: sort || sorting ? (sort || sorting) : "reg_date desc",
                ref: [
                    // 'HIQDB_class.name|HIQDB_class.id=HIQDB_member_class.cid',
                    'HIQDB_class.intro|HIQDB_class.id=HIQDB_member_class.cid',
                    'HIQDB_class.address|HIQDB_class.id=HIQDB_member_class.cid',
                    'HIQDB_class.tel|HIQDB_class.id=HIQDB_member_class.cid',
                    'HIQDB_class.email|HIQDB_class.id=HIQDB_member_class.cid',
                    'HIQDB_class.owner|HIQDB_class.id=HIQDB_member_class.cid',
                    'HIQDB_teacher.qtotal|HIQDB_teacher.id=HIQDB_member_class.mid',
                    'HIQDB_teacher.name|HIQDB_teacher.id=HIQDB_member_class.mid',
                    'HIQDB_teacher.img|HIQDB_teacher.id=HIQDB_member_class.mid',
                    'HIQDB_teacher.intro as t_intro|HIQDB_teacher.id=HIQDB_member_class.mid',

                ],
                where: {
                    'HIQDB_class.id': `='${params["id"]}'`,   // 클래스룸 ID
                    ...where_obj
                    // 'HIQDB_member_class.status':`='0'`,   
                }
            }
            if (params["who"] == "student") {
                state["sid"] = userInfo["id"]
            }
            let data = await apiFn.comApi({
                state: state
            })

            // console.log(" ===>   " ,data)

            let invite_array = []
            let array = []
            let pre_array = []

            data["list"].reduce((a, v, i) => {
                let obj = {
                    id: v["mid"],
                    name: v["name"],
                    status: v["status"],
                    intro: v["t_intro"],
                    qtotal: v["qtotal"],
                    qnaCount: v["qnaCount"],
                    img: v["img"],
                    type: v["type"],
                    classList: v["classList"]
                }
                if (v["status"] == 0) {
                    pre_array.push(obj)
                } else if (v["status"] == 1) {
                    array.push(obj)
                } else if (v["status"] == 5) {
                    if (v["type"] == 9) {
                        invite_array.push(obj)
                    }
                }
            }, [])

            // console.log("pre_array => ",pre_array)

            setInvite_teacherData([
                ...invite_array
            ])

            setTeacherData([
                ...array
            ])

            setPre_teacherData([
                ...pre_array
            ])

        } catch (e) {
            // console.log("@@@@@@@@@@ ",e);
        }
    }

    // 선생님 등급 관리
    const apiPro_set = async (data, status, isType, isMulti) => {
        try {
            let value = {status}
            let sendMsg = {
                orderMid: userInfo["id"],
                targetMid: data["id"],
                cid: params["id"],
                channel: constants.channelId
            }
            if (status == 1) {
                sendMsg["type"] = "hakwon_approve"
            }
            if (status == 5) {
                sendMsg["type"] = "hakwon_adminup"
            }
            if (status == 6) {
                sendMsg["type"] = "hakwon_admindown"
            }

            if (isType) {
                value = {type: status}
            }
            let set = []
            if (isMulti) {
                Object.keys(data).map((v, i) => {
                    let _obj = {
                        key: `mid:${v}`,
                        value: isType ? {type: status} : {status: status}
                    }
                    set.push(_obj)
                })
            } else {
                set.push({
                    // key : `mid:${data["id"]}` , 
                    key: `mid:${data["id"]}' and cid='${params["id"]}`,
                    // key : `cid:${params["id"]}' and mid='${d["id"]}` , 
                    value: value
                })
            }
            let isOk = await apiFn.comApi({
                state: {
                    sendMsg: sendMsg,
                    typecheck: {
                        cid: params["id"],
                        id: userInfo["id"],
                    },
                    table: "member_class",
                    set: set
                }
            })

            // // console.log("@@@@@@@@ ",isOk)
            if (isOk["status"] == 30) {
                alert("해당 정보를 수정할 수 있는 권한이 없습니다.")
            }
            await apiPro_read_teacher();
        } catch (e) {
            util.c_err_log(`apiPro_set Error => ${e}`)
        }
    }

    const apiPro_del = async (data) => {
        try {
            let isOk = await apiFn.exitClass({
                state: {
                    orderMid: userInfo["id"],
                    targetMid: data["id"],
                    cid: params["id"]
                }
            })
            // console.log(isOk)

            await apiPro_read_teacher();
        } catch (e) {

        }
    }

    const apiPro_del_array = async (data) => {
        try {
            let id_key = Object.keys(data)
            let promiseArray = id_key.map((v) =>
                apiFn.exitClass({
                    state: {
                        orderMid: userInfo["id"],
                        targetMid: v,
                        cid: params["id"]
                    }
                })
            )
            await Promise.all([
                ...promiseArray
            ])

            setIsEdit(false)
            setIsEdit_invite(false)
            setIsEdit_add(false)
            util.postWeb("isModal_off")

            setSelectData([])
            setSelectData_add([])
            setSelectData_invite([])

            setSelectData_l(0)
            setSelectData_add_l(0)
            setSelectData_l_invite(0)

            await apiPro_read_teacher();
        } catch (e) {
            // console.log("?? ",e);
        }
    }

    // const apiPro_read = async () => {
    //     try {
    //         let data = await apiFn.comApi({
    //             state : {
    //                 table : "teacher",
    //                 page : 1,
    //                 amount : 9999,
    //                 sort : "reg_date desc",
    //                 where : {
    //                     'HIQDB_teacher.pid':`='124'`,
    //                 }
    //             }
    //         })
    //         setTeacherData([
    //             ...data["list"]
    //         ])
    //     }
    //     catch(e){
    //     }
    // }

    useEffect(() => {
        selectData_l_invite = 0;
        Object.keys(selectData_invite).map((v, i) => {
            if (selectData_invite[v] == "Y") {
                selectData_l_invite = selectData_l_invite + 1
            } else if (selectData_invite[v] == "N") {
                if (selectData_l_invite == 0) return;
                selectData_l_invite = selectData_l_invite - 1
            }
        })
        // console.log(selectData_invite)
        setSelectData_l_invite(selectData_l_invite)
    }, [selectData_invite])

    useEffect(() => {
        selectData_l = 0;
        Object.keys(selectData).map((v, i) => {
            if (selectData[v] == "Y") {
                selectData_l = selectData_l + 1
            } else if (selectData[v] == "N") {
                if (selectData_l == 0) return;
                selectData_l = selectData_l - 1
            }
        })
        setSelectData_l(selectData_l)
    }, [selectData])

    useEffect(() => {
        selectData_add_l = 0;
        Object.keys(selectData_add).map((v, i) => {
            if (selectData_add[v] == "Y") {
                selectData_add_l = selectData_add_l + 1
            } else if (selectData_add[v] == "N") {
                if (selectData_add_l == 0) return;
                selectData_add_l = selectData_add_l - 1
            }
        })
        setSelectData_add_l(selectData_add_l)
    }, [selectData_add])

    useEffect(() => {
        // apiPro_read();
        apiPro_read_teacher();
        if (window.ReactNativeWebView) {
            document.addEventListener("message", async (e) => {
                if (e.data == "isModal") {
                    setIsEdit(false)
                    setIsEdit_add(false)
                    setIsEdit_invite(false)
                }
            })
        }
        return () => {
            try {
                document.removeEventListener("message")
            } catch (e) {
            }
        }
    }, [])


    return (
        <div style={Profile_teacher_Style.c}>
            {
                (isEdit || isEdit_add) &&
                <UiTopPopTextBtn
                    title="편집 취소"
                    isTeacher={isTeacher}
                    fnClick={() => {
                        setIsEdit(false)
                        setIsEdit_add(false)
                        setIsEdit_invite(false)
                        util.postWeb("isModal_off")
                        // setIsSearch(true)
                        setSelectData({})
                        setSelectData_add({});
                    }}
                />
            }
            {
                isTeacher &&
                academyData["type"] == 5 &&
                <>
                    <div style={{
                        ...Profile_teacher_Style.t_btn_w,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        width: "100%",
                    }}>
                        <UiBtn
                            btnStyle={{
                                ...Profile_teacher_Style.t_btn,
                                width: '165px',
                            }}
                            title={
                                <div style={Academy_book_Style.btn_title}>
                                    <Add style={Academy_book_Style.btn_Add}/>
                                    <div style={{
                                        paddingLeft: "12px"
                                    }}>
                                        선생님 초대하기
                                    </div>
                                </div>
                                // <div style={Profile_teacher_Style.t_btn_text}>
                                //     <Add
                                //         style={Profile_teacher_Style.t_btn_Add}
                                //     />
                                //     선생님 초대하기
                                // </div>
                            }
                            fnClick={() => {
                                setHiddenNav("teacher")
                                navigate(`/invite/${params["who"]}/de/c/cid&${params["id"]}`)
                            }}
                        />
                        <SearchBox search={search} setSearch={setSearch} apiPro_read_student={apiPro_read_teacher}/>
                    </div>
                    {
                        invite_teacherData.length > 0 &&
                        <div style={{
                            fontSize: "14px",
                            marginTop: "15px",
                            fontWeight: "bold",
                            // display : academyData["type"] !== 5 && "none"
                        }}>
                            {`초대 승낙 대기 ${invite_teacherData.length}명`}
                        </div>
                    }
                    {
                        isEdit_invite &&
                        (
                            selectData_l_invite > 0 &&
                            <div style={{
                                ...Lecture_book_Style.edit_section_w,
                                borderTop: isEdit_invite ? "none" : "1px solid",
                                borderColor: style.common.borderColor,
                            }}>
                                선택된 선생님을
                                <div style={{}}>
                                    {
                                        isTeacher &&
                                        <div style={{
                                            display: "flex"
                                        }}>
                                            <UiTextBtn
                                                text={"초대 취소하기"}
                                                btnStyle={{
                                                    ...Lecture_book_Style.textBtn,
                                                    color: style.common.fontColor_3,
                                                    marginRight: "7.5px",
                                                }}
                                                fnClick={() => {
                                                    let isOk = window.confirm("선택하신 선생님들의 초대 신청을 취소하시겠어요?")
                                                    if (isOk) {
                                                        // apiPro_set(selectData,true,false,true)
                                                        apiPro_del_array(selectData_invite)
                                                    }
                                                }}
                                            />
                                        </div>
                                    }
                                </div>
                            </div>
                        )
                    }
                    <UiPeoPle_ProfileTeacher
                        isBlank={false}
                        data={invite_teacherData}
                        isRegi={true}
                        isManager={academyData["type"] == 5 ? true : false}
                        setSelectData={setSelectData_invite}
                        selectData={selectData_invite}
                        isEdit={isEdit_invite}
                        isTeacher={isTeacher}
                        fnClick_approve={(d) => {
                            apiPro_set(d, 1)
                        }}
                        fnClick_rejcet={(d) => {
                            // apiPro_set(d,2)
                            apiPro_del(d)
                        }}
                        // setIsSearch={setIsSearch}
                        fnLongClick={(id) => {
                            // setIsSearch(false)
                            setIsEdit_invite(true)
                            util.postWeb("isModal")
                            setSelectData_invite({
                                ...selectData_invite,
                                [id]: "Y"
                            })
                        }}
                        setHiddenNav={() => {
                            setHiddenNav("profile_teacher")
                        }}
                        fnCheck={(e) => {
                            if (selectData_invite[e.target.id] == "Y") {
                                delete selectData_invite[e.target.id]
                                setSelectData_invite({
                                    ...selectData_invite,
                                })
                            } else {
                                setSelectData_invite({
                                    ...selectData_invite,
                                    [e.target.id]: "Y"
                                })
                            }
                        }}
                        fnClick={(data) => {
                            // navigate("/columndetail/"+params["who"]+"/"+data+"/act")
                            navigate("/columndetail/" + params["who"] + "/" + data + "/" + params["id"])
                        }}
                        // key={"mark_folder_"+i}
                        // id={i}
                        navigate={navigate}
                        params={params}
                    />
                    {pre_teacherData.length > 0 &&
                        <div style={Profile_teacher_Style.text_w_1}>
                            {`가입 승인 대기 ${pre_teacherData.length}명`}
                        </div>
                    }
                    {isEdit &&
                        <div style={Profile_teacher_Style.textBtn_c}>
                            <div>
                                선택된 선생님을
                            </div>
                            <div style={Profile_teacher_Style.textBtn_w}>
                                <div style={Profile_teacher_Style.textBtn}
                                     onClick={() => {
                                         let isOk = window.confirm(constants.confirm.cf_18)
                                         if (isOk) {
                                             // apiPro_set(selectData,2,false,true)
                                             apiPro_del_array(selectData)
                                         }
                                     }}
                                >
                                    거절하기
                                </div>
                                <div style={{
                                    ...Profile_teacher_Style.textBtn,
                                    color: style.common.t_color
                                }}
                                     onClick={() => {
                                         let isOk = window.confirm(constants.confirm.cf_19)
                                         if (isOk) {
                                             apiPro_set(selectData, 1, false, true)
                                         }
                                     }}
                                >
                                    승인하기
                                </div>
                            </div>

                        </div>
                    }
                    <UiPeoPle_ProfileTeacher
                        isBlank={false}
                        data={pre_teacherData}
                        isRegi={true}
                        isManager={academyData["type"] == 5 ? true : false}
                        setSelectData={setSelectData}
                        selectData={selectData}
                        isEdit={isEdit}
                        isTeacher={isTeacher}
                        fnClick_approve={(d) => {
                            apiPro_set(d, 1)
                            // // console.log("APPROVE ?")
                        }}
                        fnClick_rejcet={(d) => {
                            // apiPro_set(d,2)
                            apiPro_del(d)
                        }}
                        setHiddenNav={() => {
                            setHiddenNav("profile_teacher")
                        }}
                        // setIsSearch={setIsSearch}
                        fnLongClick={(id) => {
                            // setIsSearch(false)
                            setIsEdit(true)
                            util.postWeb("isModal")
                            setSelectData({
                                ...selectData,
                                [id]: "Y"
                            })
                        }}
                        fnCheck={(e) => {
                            if (selectData[e.target.id] == "Y") {
                                delete selectData[e.target.id]
                                setSelectData({
                                    ...selectData,
                                })
                            } else {
                                setSelectData({
                                    ...selectData,
                                    [e.target.id]: "Y"
                                })
                            }
                        }}
                        fnClick={(data) => {
                            // navigate("/columndetail/"+params["who"]+"/"+data+"/act")
                            navigate("/columndetail/" + params["who"] + "/" + data + "/" + params["id"])
                        }}
                        // key={"mark_folder_"+i}
                        // id={i}
                        navigate={navigate}
                        params={params}
                    />
                </>
            }
            {isEdit_add ?
                <>
                    <div style={Profile_teacher_Style.text_w_2}>
                        {/* 전체 강사진 9명 */}
                        {`전체 강사진 ${teacherData.length}명`}
                    </div>
                    <div style={Profile_teacher_Style.textBtn_c}>
                        <div>
                            선택된 선생님을
                        </div>
                        <div style={Profile_teacher_Style.textBtn_w}>
                            <div style={Profile_teacher_Style.textBtn}
                                 onClick={() => {
                                     // apiPro_set(selectData,9,true,true)
                                     let isOk = window.confirm(constants.confirm.cf_17)
                                     if (isOk) {
                                         // apiPro_set(selectData_add,9,false,true)
                                         apiPro_del_array(selectData_add)
                                     }
                                 }}
                            >
                                탈퇴처리
                            </div>
                        </div>
                    </div>
                </>
                :
                <></>
                // <UiSorting
                //     isTeacher={isTeacher}
                //     title={`전체 강사진 ${teacherData.length}명`}
                //     data={sort}
                //     fnClick={e=>{
                //         setSorting(v)
                //         sorting = v
                //         apiPro_read_teacher(v)
                //     }}
                // />
            }
            <div style={Profile_teacher_Style.text_w_2}>
                {/* 전체 강사진 9명 */}
                {`전체 강사진 ${teacherData.length}명`}
            </div>
            <UiPeoPle_ProfileTeacher
                // key={"mark_folder_"+i}
                // id={i}
                isNoOpen={isTeacher ? false : true}
                isBlank={false}
                data={teacherData}

                navigate={navigate}
                params={params}
                isManager={academyData["type"] == 5 ? true : false}
                // navi={"/academybook/"+params["who"]+"/"+3+"/default"}

                setSelectData={setSelectData_add}
                selectData={selectData_add}

                isEdit={isEdit_add}
                isTeacher={isTeacher}
                // setIsSearch={setIsSearch}
                setHiddenNav={() => {
                    setHiddenNav("profile_teacher")
                }}

                fnClick_exit={(d) => {
                    let isOk = window.confirm(constants.confirm.cf_20)
                    if (isOk) {
                        // apiPro_set(d,9)
                        apiPro_del(d)
                    }
                }}
                fnClick_up={(d) => {
                    let isOk = window.confirm(constants.confirm.cf_21)
                    if (isOk) {
                        apiPro_set(d, 5, true)
                    }
                }}
                fnClick_down={d => {
                    let isOk = window.confirm("관리자에서 해지 하시겠습니까?")
                    if (isOk) {
                        apiPro_set(d, 6, true)
                    }
                }}

                fnLongClick={(id) => {
                    if (isTeacher) {
                        setIsEdit_add(true)
                        util.postWeb("isModal")
                        setSelectData_add({
                            ...selectData_add,
                            [id]: "Y"
                        })
                        // setIsSearch(false)
                    }
                }}

                fnCheck={(e) => {
                    if (selectData_add[e.target.id] == "Y") {
                        delete selectData_add[e.target.id]
                        setSelectData_add({
                            ...selectData_add,
                        })
                    } else {
                        setSelectData_add({
                            ...selectData_add,
                            [e.target.id]: "Y"
                        })
                    }
                }}

            />
        </div>
    )
}

export default Profile_teacher 