import style from "../../asset/style"

const UiSearchSection_Style = {
    f : {
        display:"flex",
        flex:1,
        flexDirection : 'column',
        paddingBottom : "10px",
    },
    t_w : {
        display:'flex',
        fontSize : "12px",
        marginBottom : "5px",
        fontWeight:"bold",
    },
    t_w_add : {
        color : style.common.t_color,
        marginLeft : '5px',
    },
    section_c : {
        display:"flex",
        margin : "10px 0px 0px 0px",
        width : "100%",
        border : '1px solid',
        borderColor : style.common.borderColor,
        borderRadius : 10,
        // height : "140px",
    },
    section_w : {
        display:"flex",
        flexDirection:"column",
        padding : '10px',
        width : "100%",
        borderRadius : 10,
    },
    section_t_w : {
        display:"flex",
        flexDirection : "column",
        // justifyContent:"space-between",
        width : "100%",
        minHeight : "85px",
        // paddingBottom : "4px",
        borderBottom : "1px solid",
        borderColor : style.common.borderColor,
        //backgroundColor : "violet"
    },
    section_t_t_c : {
        display:"flex",
        width : '100%',
        // backgroundColor : "green"
    },
    section_t_t_i_w : {
        width : "50px",
        height :'55px',
        border : "1px solid",
        borderColor : style.common.borderColor
    },
    section_t_t_r_w : {
        marginLeft : "17px",
        //backgroundColor : "orange"
    },
    section_t_t_r_w_text_1 : {
        fontSize : "15px",
        fontWeight:"bold",
        marginBottom : "7px",
        color : style.common.fontColor_1
    },
    section_t_t_r_w_text_2 : {
        padding : "2px 0px 4px 0px",
        fontSize : "11px",
        paddingTop : "1px",
    },
    section_t_b_w : {
        display:"flex",
        justifyContent:"space-between",
        width : '100%',
        // paddingBottom:"10px",
        marginTop:"15px",
        marginBottom:"10px",
    },
    section_t_b_mark : {
        display:"flex",
        justifyContent:"center",
        alignItems:"center",
        width : "40px",
        padding:"2.5px 5px",
        // padding:"5px 5px ",
        height:"10px",
        color : "white",
        borderRadius : 10,
        fontSize : '10px',
    },
    section_t_b_text : {
        marginLeft : "10px",
        fontSize : "11px",
        color : style.common.fontColor_1,
    },
    section_b_w : {
        display:"flex",
        alignItems:"center",
        overflow : "hidden",
        // maxHeight : "50px", 
        // paddingTop:"3px",
    },
    section_b_w_text : {
        overflow : "hidden",
        // height:"100%",
        // padding : "12px 0px",
        // height:"30px",
        fontSize : "11px",
        color : "rgb(100,100,100)",
        // backgroundColor : "red",
        width:"100%",
    },
    academyAdd_w : {
        display:"flex",
        justifyContent:"space-between",
        width : "100%",
        fontSize : "11px",
        marginTop:"10px",
        // height : "20px",
        // //backgroundColor : "green"

    }
}

export default UiSearchSection_Style