import React, { createContext, useEffect, useMemo, useState } from "react";

export type CommonStudyBookItem = {
  id: number;
  name: string;
  type: number;
  img?: string;
  book_type?: number;
};

export interface StudyBookContextState {
  isConfigMode: boolean;
  isDragging?: boolean;
  setIsDragging?: (state: boolean) => void;
  setIsConfigMode?: (state: boolean) => void;
  checkedBookList?: CommonStudyBookItem[];
  addCheckedBook?: (book: CommonStudyBookItem) => void;
  removeCheckedBook?: (id: number) => void;
  allCheckedBook?: (list: CommonStudyBookItem[]) => void;
  allRemoveCheckedBook?: () => void;
}

export const StudyBookContext = createContext<StudyBookContextState>({
  isConfigMode: false,
});

const StudyBookContextProvider = ({ children }) => {
  const [isConfigMode, setIsConfigMode] = useState<boolean>(false);
  const [isDragging, setIsDragging] = useState<boolean>(false);
  const [checkedBookList, setCheckedBookList] = useState<CommonStudyBookItem[]>([]);
  useEffect(() => {
    setCheckedBookList([]);
  }, [isConfigMode]);
  const actions = useMemo(() => {
    const addCheckedBook = (book: CommonStudyBookItem) => {
      setCheckedBookList((prev) => [...prev, book]);
    };

    const removeCheckedBook = (id: number) => {
      setCheckedBookList((prev) => prev.filter((item) => item.id !== id));
    };
    const allCheckedBook = (list: CommonStudyBookItem[]) => {
      setCheckedBookList(list);
    };
    const allRemoveCheckedBook = () => {
      setCheckedBookList([]);
    };
    return {
      isConfigMode,
      setIsConfigMode,
      checkedBookList,
      addCheckedBook,
      removeCheckedBook,
      allCheckedBook,
      allRemoveCheckedBook,
      isDragging,
      setIsDragging,
    };
  }, [
    isConfigMode,
    setIsConfigMode,
    checkedBookList,
    setCheckedBookList,
    isDragging,
    setIsDragging,
  ]);

  return <StudyBookContext.Provider value={actions}>{children}</StudyBookContext.Provider>;
};
export default StudyBookContextProvider;
