import { React } from "react";
import constants from "../../asset/constants";
import { Editor } from "@tinymce/tinymce-react";
import util from "../../asset/util";
import apiFn from "../../asset/apiClass";

const UiEditor = ({ readOnly = false, initValue, data = "", setHtml }) => {
  return (
    <Editor
      disabled={readOnly}
      tinymceScriptSrc="/tinymce/tinymce.min.js"
      // value={boardDetail.content}
      value={initValue ? initValue : null}
      init={{
        content_style: ` 
                    p{
                        margin: 0 !important;
                        font-size: 16px !important;
                        // font-family: "Nanum" !important;
                        font-weight : normal
                    } 
                    body {
                        margin : 2;
                    }
                `, // css
        width: "100%",
        height: "100%",
        resize: false,
        menubar: false,
        statusbar: false,
        branding: false,
        language: "ko_KR",
        plugins: !readOnly
          ? [
              "advlist autolink lists link image paste charmap print preview anchor",
              "searchreplace visualblocks code fullscreen",
              "insertdatetime media table paste code help wordcount",
            ]
          : ["autoresize"],
        toolbar:
          !readOnly &&
          `undo redo | formatselect | bold italic forecolor backcolor |
                alignleft aligncenter alignright alignjustify | 
                bullist numlist outdent indent | removeformat | images | help`,
        setup: (editor) => {
          editor.ui.registry.addButton("images", {
            icon: "image",
            onAction: () => {
              const fileSelector = document.createElement("input");
              fileSelector.setAttribute("type", "file");
              fileSelector.setAttribute("accept", "image/png, image/jpeg");
              fileSelector.onchange = async (e) => {
                // // console.log(e.target.files[0]);
                // // console.log(e.target.files);
                // const cid = (cmd=='app')?store.parseCat("어플사용 후기")["id"]:
                //     (cmd=='test')?store.parseCat("모의고사 해설강의")["id"]:
                //     (cmd=='lecture')?store.parseCat("개념강의")["id"]:
                //     (cmd=='review')?store.parseCat("수험생 후기")["id"]:null;
                const name =
                  "editor/" +
                  new Date().getTime() +
                  "_" +
                  util.getRandomInt(100, 999) +
                  "_" +
                  e.target.files[0].name;
                // // console.log(name);
                try {
                  let img = await util.toBase64(e.target.files[0]);
                  const result = await apiFn.uploadFile(name, img);
                  // console.log(result);
                } catch (e) {
                  // console.log(e);
                }
                editor.insertContent(`<img src='${constants.s3_url}/${name}'/>`);
                // editor.insertContent(`<img src="${constants.url+(constants.url.includes('dev.com')?'/test':'/public')+name}"/>`);
                fileSelector.remove();
              };
              fileSelector.click();
            },
          });
        },
      }}
      // onEditorChange={onChangeContent}
      onEditorChange={setHtml}
    />
  );
};

export default UiEditor;
