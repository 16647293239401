import { KeyboardArrowDown, KeyboardArrowUp, Star } from "@material-ui/icons";
import { React, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import face_student from "../../asset/Img/face_student.png";
import face_teacher from "../../asset/Img/teacher_.png";
import constants from "../../asset/constants";
import util from "../../asset/util";
import MyInfo_Style from "./MyInfo_Style";

const Active_Form = ({ basis_style, isTeacher, myInfo }) => {
  // useEffect(()=>{
  //     console.log("ACtive Form myinfo => ",myInfo)
  // },[ myInfo ])

  return (
    <div
      style={{
        width: "100%",
        backgroundColor: "white",
        marginTop: "5px",
        minHeight: "130px",
        padding: "10px 0px 10px 0px",
      }}
    >
      <div style={basis_style.box_flex}>
        <div style={basis_style.title}>누적활동</div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {myInfo["qnacnt"] && (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "76px",
                height: "76px",
                alignItems: "center",
                justifyContent: "center",
                borderRadius: 50,
                backgroundColor:
                  myInfo["qnacnt"]["total"] - myInfo["qnacnt"]["answer"] <= 10
                    ? "#A8E2B8"
                    : myInfo["qnacnt"]["total"] - myInfo["qnacnt"]["answer"] <=
                      50
                    ? "#FCDB92"
                    : "#F87A7A",
              }}
            >
              <div
                style={{
                  fontSize: "11px",
                  color: "white",
                  // marginBottom:"2px",
                  marginTop: "-4px",
                }}
              >
                미답변수
              </div>
              {/* 10 > 파랑색
                        10 ~ 50 노랑색
                        51 ~ 빨간색 */}
              <div
                style={{
                  fontSize: "30px",
                  fontWeight: 300,
                  // fontWeight:"bold,
                  color: "white",
                }}
              >
                {myInfo["qnacnt"] &&
                  (myInfo["qnacnt"]["total"] - myInfo["qnacnt"]["answer"] > 100
                    ? 99
                    : myInfo["qnacnt"]["total"] - myInfo["qnacnt"]["answer"]) +
                    "+"}
              </div>
            </div>
          )}
          {myInfo["qnacnt"] && (
            <div
              style={{
                marginTop: "12px",
              }}
            >
              {myInfo["qnacnt"]["total"] - myInfo["qnacnt"]["answer"] < 10
                ? "지금 질문을 하면 빠르게 답변을 받을 수 있어요."
                : myInfo["qnacnt"]["total"] - myInfo["qnacnt"]["answer"] <= 50
                ? "선생님의 답변을 주실 때 까지 조금 시간이 걸릴수 있어요."
                : "선생님의 답변이 많이 밀려있어요."}
            </div>
          )}
        </div>
      </div>

      <div style={basis_style.title}>
        <div style={basis_style.desc_flex}>
          전체 질문수
          <div style={basis_style.desc_2}>
            {myInfo["qnacnt"] && myInfo["qnacnt"]["total"]}
          </div>
        </div>
        {isTeacher && (
          <>
            <div style={basis_style.desc_flex}>
              전체 답변수
              <div style={basis_style.desc_2}>
                {myInfo["qnacnt"] && myInfo["qnacnt"]["answer"]}
              </div>
            </div>
            <div style={basis_style.desc_flex}>
              평균 응답시간
              <div style={basis_style.desc_2}>
                {myInfo["qnacnt"] &&
                  util.converToHour(myInfo["qnacnt"]["avgtime"])}
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

const Recnt_Form = ({
  myInfo,
  userInfo,
  apiPro,
  isTeacher,
  r_class,
  r_lecture,
  basis_style,
}) => {
  let params = useParams();
  return (
    <div style={MyInfo_Style.section_w}>
      <div style={MyInfo_Style.text_w_1}>
        <div
          style={{
            fontWeight: "bold",
            display: "flex",
            justifyContent: "center",
            marginTop: "15px",
            // justifyContent:"space-between",
            // fontSize:"14px",
          }}
        >
          <div
            style={{
              display: "flex",
              // alignItems:"flex-end",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <div style={basis_style.title}>최근활동</div>
            {myInfo["r_qnaCnt"] && myInfo["update_date"] > 0 && (
              <div
                style={{
                  fontWeight: 400,
                  display: "flex",
                  alignItems: "flex-end",
                  fontSize: "1px",
                  marginLeft: "3px",
                  color: "#B3B3B3",
                }}
              >
                (갱신일자
                <div
                  style={{
                    // color : style.common.t_color,
                    marginLeft: "8px",
                    // fontWeight:"bold",
                    fontSize: "12px",
                  }}
                >
                  {util.convertToDateTime_form2(myInfo["update_date"])}
                </div>
                )
              </div>
            )}
          </div>
        </div>
        <div style={basis_style.box_flex}>
          {/* <div style={MyInfo_Style.text_w_3}>
                        전체 질문수
                        <div style={{
                            color : isTeacher ? style.common.t_color : style.common.s_color,
                            ...MyInfo_Style.text_2
                        }}>
                            {
                            myInfo["r_qnaCnt"] && myInfo["r_qnaCnt"]["total"]
                            }
                        </div>
                    </div> */}

          {isTeacher && (
            <>
              <div style={basis_style.desc_flex}>
                전체 답변수
                <div style={basis_style.desc_2}>
                  {myInfo["r_qnaCnt"] && myInfo["r_qnaCnt"]["answer"]}
                </div>
              </div>
              <div style={basis_style.desc_flex}>
                평균 응답시간
                <div style={basis_style.desc_2}>
                  {myInfo["r_qnaCnt"] &&
                    util.converToHour(myInfo["r_qnaCnt"]["avgtime"])}
                </div>
              </div>
            </>
          )}
          {
            params["who"] == "teacher" &&
              r_class &&
              r_lecture &&
              userInfo["id"] == myInfo["id"] && (
                <div
                  style={{
                    backgroundColor: "rgba(248, 122, 122, 0.5)",
                    borderRadius: "5px",
                    padding: "3px 10px",
                    color: "white",
                    fontSize: "14px",
                    fontWeight: 400,
                    marginTop: "12px",
                  }}
                  onClick={() => {
                    let isOk = window.confirm("최근 활동을 초기화 하시겠어요?");
                    if (!isOk) return;
                    apiPro("Y");
                  }}
                >
                  최근 활동 초기화하기
                </div>
              )
            // <UiTextBtn
            //     isTeacher={true}
            //     btnStyle={{
            //         marginLeft : "5px",
            //         fontSize:"12px",
            //         marginBottom:"-1px"
            //     }}
            //     text={"초기화"}
            //     fnClick={()=>{
            // apiPro( "Y" )
            //     }}
            // />
          }
        </div>
      </div>
    </div>
  );
};
const Myinfo_Basis_teacher = ({
  setIsModi,
  isTeacher,
  myInfo,
  userInfo,
  r_class,
  r_lecture,
  apiPro,
}) => {
  let navigate = useNavigate();
  let params = useParams();

  const [isOpen, setIsOpen] = useState(false);

  var basis_style = {
    box: {
      marginTop: "10px",
    },
    box_flex: {
      marginTop: "20px",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    title: {
      fontWeight: 700,
      fontSize: "16px",
    },
    desc_flex: {
      fontWeight: 400,
      fontSize: "14px",
      marginTop: "7px",
      display: "flex",
      alignItems: "center",
    },
    desc: {
      fontWeight: 400,
      fontSize: "14px",
      marginTop: "7px",
    },
    desc_2: {
      fontWeight: 700,
      fontSize: "14px",
      marginLeft: "10px",
    },
  };
  return (
    <div
      style={{
        padding: "15px 20px",
      }}
    >
      {
        // (r_class || r_lecture) &&
        // (r_lecture) &&
        <div
          style={{
            display: "flex",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              width: "130px",
            }}
          >
            <div style={{}}>
              <img
                style={{
                  width: "100%",
                  height: "130px",
                  borderRadius: "15px",
                  border: "1px solid #EEEEEE",
                }}
                onError={(e) => {
                  // setIsLoad(false)
                  e.currentTarget.src = isTeacher ? face_teacher : face_student;
                }}
                src={
                  myInfo["id"]
                    ?
                      `${constants.s3_url}/${params["who"]}/${myInfo["id"].normalize("NFD")}.jpg?${new Date().getTime()}`
                    : isTeacher
                    ? face_teacher
                    : face_student
                }
              />
            </div>
            {myInfo["id"] == userInfo["id"] && (
              <div
                style={{
                  marginTop: "10px",
                  fontWeight: 700,
                  fontSize: "14px",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <div
                  style={{
                    color: "#F09294",
                    marginRight: "15px",
                  }}
                  onClick={() => {
                    let isOk = window.confirm(constants.confirm.cf_1);
                    if (isOk) {
                      navigate("/myinfom/" + params["who"] + "/confirm");
                    }
                  }}
                >
                  회원탈퇴
                </div>
                <div
                  style={{
                    color: "#5EA3C6",
                  }}
                  onClick={() => {
                    setIsModi(true);
                  }}
                >
                  정보변경
                </div>
              </div>
            )}
          </div>
          <div
            style={{
              marginLeft: "15px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{
                display: "flex",
              }}
            >
              <div style={basis_style.title}>{myInfo["name"]}</div>
              <div
                style={{
                  ...basis_style.title,
                  fontWeight: 400,
                  marginLeft: "3px",
                }}
              >
                {/* / 닉네임:{myInfo["nick"]} */}/ {myInfo["nick"]}
              </div>
            </div>
            {r_class && r_lecture && (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginTop: "10px",
                }}
                onClick={() => {
                  if (myInfo["id"] == userInfo["id"]) {
                    navigate("/myinforeview/" + params["who"]);
                  }
                }}
              >
                <Star
                  style={{
                    fontSize: "20px",
                    color: "#f5b21a",
                  }}
                />
                <div style={{ ...basis_style.desc, color: "#8F8F8F" }}>
                  {`${myInfo["starAvg"] && myInfo["starAvg"].toFixed(1)} (${
                    myInfo["starCnt"]
                  }개 리뷰)`}
                </div>
              </div>
            )}
            <div style={basis_style.box}>
              <div style={basis_style.title}>전화번호</div>
              <div style={basis_style.desc}>
                {myInfo["id"] == userInfo["id"]
                  ? myInfo["tel"]
                  : myInfo["tel"].substr(0, 4) +
                    "***" +
                    myInfo["tel"].substr(7, 4)}
              </div>
            </div>
            {myInfo["id"] == userInfo["id"] && (
              <div style={basis_style.box}>
                <div style={basis_style.title}>이메일</div>
                <div style={basis_style.desc}>{myInfo["email"]}</div>
              </div>
            )}
          </div>
          {!isTeacher && (
            <>
              {r_lecture && (
                <div style={MyInfo_Style.text_w_1}>
                  <div
                    style={{
                      fontWeight: "bold",
                      // fontSize:"14px",
                    }}
                  >
                    학교
                  </div>
                  <div style={MyInfo_Style.text_1}>
                    {myInfo["school"]}
                    {/* {myInfo[""]} */}
                  </div>
                </div>
              )}
              {r_class && r_lecture && (
                <div style={MyInfo_Style.text_w_1}>
                  <div
                    style={{
                      fontWeight: "bold",
                      // fontSize:"14px",
                    }}
                  >
                    진학 희망
                  </div>
                  <div style={MyInfo_Style.text_1}>
                    {/* 천하제익대학 우주최고학과 */}
                    {myInfo["collage"]}
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      }
      <div
        style={{
          ...basis_style.box,
          marginTop: "20px",
        }}
      >
        <div style={basis_style.title}>소개글</div>
        <div style={basis_style.desc}>{myInfo["intro"]}</div>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <RoundBox myInfo={myInfo} />
        <div
          style={{
            fontSize: "12px",
            fontWeight: 400,
            color: "#B3B3B3",
            display: "flex",
            alignItems: "center",
          }}
          onClick={() => {
            setIsOpen(!isOpen);
          }}
        >
          {isOpen ? `상세 활동 접기` : `상세 활동 보기`}
          {isOpen ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
        </div>
        {isOpen && (
          <>
            {isTeacher && r_lecture && (
              <div style={basis_style.box_flex}>
                <div style={basis_style.title}>교재</div>
                <div style={basis_style.desc_flex}>
                  보유 교재수
                  <div style={basis_style.desc_2}>{myInfo["bookCnt"]}</div>
                </div>
                <div style={basis_style.desc_flex}>
                  총 모범 풀이수
                  <div style={basis_style.desc_2}>
                    {myInfo["qnacnt"] && myInfo["qnacnt"]["mobum"]}
                  </div>
                </div>
              </div>
            )}
            {
              // myInfo["type"] == 1 ?
              util.isKeyExists(myInfo, "stype") ? (
                userInfo["id"] == myInfo["id"] ? (
                  <div style={basis_style.box_flex}>
                    <div style={basis_style.title}>누적활동</div>
                    <div style={basis_style.desc_flex}>
                      전체 질문수
                      <div style={basis_style.desc_2}>
                        {myInfo["qnacnt"] && myInfo["qnacnt"]["total"]}
                      </div>
                    </div>
                    {isTeacher && (
                      <>
                        <div style={basis_style.desc_flex}>
                          전체 답변수
                          <div style={basis_style.desc_2}>
                            {myInfo["qnacnt"] && myInfo["qnacnt"]["answer"]}
                          </div>
                        </div>
                        <div style={basis_style.desc_flex}>
                          평균 응답시간
                          <div style={basis_style.desc_2}>
                            {myInfo["qnacnt"] &&
                              util.converToHour(myInfo["qnacnt"]["avgtime"])}
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                ) : (
                  <div style={basis_style.box_flex}>
                    <div style={basis_style.title}>누적활동</div>
                    <div style={basis_style.desc_flex}>
                      전체 질문수
                      <div style={basis_style.desc_2}>
                        {myInfo["qnacnt"] && myInfo["qnacnt"]["total"]}
                      </div>
                    </div>
                    {myInfo["qnacnt"] && myInfo["qnacnt"]["total_my"] && (
                      <div style={basis_style.desc_flex}>
                        나와의 질문수
                        <div style={basis_style.desc_2}>
                          {myInfo["qnacnt"] && myInfo["qnacnt"]["total_my"]}
                        </div>
                      </div>
                    )}
                  </div>
                )
              ) : r_class && r_lecture ? (
                <div style={basis_style.box_flex}>
                  <div style={basis_style.title}>누적활동</div>
                  <div style={basis_style.desc_flex}>
                    전체 질문수
                    <div style={basis_style.desc_2}>
                      {myInfo["qnacnt"] && myInfo["qnacnt"]["total"]}
                    </div>
                  </div>
                  {isTeacher && (
                    <>
                      <div style={basis_style.desc_flex}>
                        전체 답변수
                        <div style={basis_style.desc_2}>
                          {/* {myInfo["anscount"]} */}
                          {myInfo["qnacnt"] && myInfo["qnacnt"]["answer"]}
                        </div>
                      </div>
                      <div style={basis_style.desc_flex}>
                        평균 응답시간
                        <div style={basis_style.desc_2}>
                          {/* {myInfo["qnacnt"] && myInfo["qnacnt"]["avgtime"].substring(0,5).replace(":","시간 ") + "분"} */}
                          {myInfo["qnacnt"] &&
                            util.converToHour(myInfo["qnacnt"]["avgtime"])}
                        </div>
                      </div>
                    </>
                  )}
                </div>
              ) : (
                <Active_Form
                  basis_style={basis_style}
                  myInfo={myInfo}
                  isTeacher={isTeacher}
                />
              )
            }

            {/*  최근으로 갱신된 질문 누적 Data  (선생님들만 보는 data)*/}
            {
              // myinfo["who"] == "teacher" &&
              isTeacher && (
                <Recnt_Form
                  basis_style={basis_style}
                  userInfo={userInfo}
                  r_class={r_class}
                  r_lecture={r_lecture}
                  apiPro={apiPro}
                  myInfo={myInfo}
                  isTeacher={isTeacher}
                />
              )
            }
            {/* {
                !util.isKeyExists(myInfo,"stype") ?
                <Recnt_Form r_class={r_class} r_lecture={r_lecture} apiPro={apiPro} myInfo={myInfo} isTeacher={isTeacher} />
                :
                <Recnt_Form r_class={r_class} r_lecture={r_lecture} apiPro={apiPro} myInfo={myInfo} isTeacher={isTeacher} />
                (r_class && r_lecture ) &&
                } */}
          </>
        )}
      </div>
    </div>
  );
};

const RoundBox = ({ myInfo }) => {
  var roundBox_style = {
    round: {
      // width:"80px",
      // height:"80px",
      borderRadius: "20px",
      display: "flex",
      // flexDirection:"column",
      justifyContent: "center",
      alignItems: "center",
      color: "#FFFFFF",
      fontWeight: 400,
      fontSize: "16px",
      padding: "7px 5px",
      width: "133px",
    },
    box: {
      marginTop: "10px",
    },
  };

  useEffect(() => {
    console.log("### : ", myInfo["qnacnt"]);
  }, [myInfo]);

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
      }}
    >
      <div
        style={{
          ...roundBox_style.box,
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          marginTop: "20px",
        }}
      >
        <div
          style={{
            ...roundBox_style.round,
            backgroundColor:
              myInfo["qnacnt"]["total"] - myInfo["qnacnt"]["answer"] <= 10
                ? "#A8E2B8"
                : myInfo["qnacnt"]["total"] - myInfo["qnacnt"]["answer"] <= 50
                ? "#FCDB92"
                : "#F87A7A",
            marginBottom: "3px",
          }}
        >
          {`미답변수 ${
            myInfo["qnacnt"]["total"] &&
            (myInfo["qnacnt"]["total"] - myInfo["qnacnt"]["answer"] > 100
              ? 99
              : myInfo["qnacnt"]["total"] - myInfo["qnacnt"]["answer"])
          }+`}
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "cneter",
            alignItems: "center",
            fontWeight: 400,
            fontSize: "14px",
            color:
              myInfo["qnacnt"]["total"] - myInfo["qnacnt"]["answer"] <= 10
                ? "#A8E2B8"
                : myInfo["qnacnt"]["total"] - myInfo["qnacnt"]["answer"] <= 50
                ? "#FCDB92"
                : "#F87A7A",
            marginTop: "5px",
            marginBottom: "10px",
          }}
        >
          {myInfo["qnacnt"]["total"] &&
          myInfo["qnacnt"]["total"] - myInfo["qnacnt"]["answer"] < 10
            ? "지금 질문을 하면 빠르게 답변을 받을 수 있어요."
            : myInfo["qnacnt"]["total"] - myInfo["qnacnt"]["answer"] <= 50
            ? "선생님의 답변을 주실 때 까지 조금 시간이 걸릴수 있어요."
            : "선생님의 답변이 많이 밀려있어요."}
        </div>
      </div>
    </div>
  );
};
export default Myinfo_Basis_teacher;
