import {React, useEffect, useMemo, useState} from "react";
import { useNavigate, useParams } from "react-router";
import face_teacher from "../../asset/Img/defaultpic_teacher.png";
import face_student from "../../asset/Img/student_.png";
import constants from "../../asset/constants";
import util from "../../asset/util";
import MyInfo_Style from "./MyInfo_Style";

const Active_Form = ({ basis_style, isTeacher, myInfo }) => {
  // useEffect(()=>{
  //     console.log("ACtive Form myinfo => ",myInfo)
  // },[ myInfo ])

  return (
    <div
      style={{
        width: "100%",
        backgroundColor: "white",
        marginTop: "5px",
        minHeight: "130px",
        padding: "10px 0px 10px 0px",
      }}
    >
      <div style={basis_style.box_flex}>
        <div style={basis_style.title}>누적활동</div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {myInfo["qnacnt"] && (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "76px",
                height: "76px",
                alignItems: "center",
                justifyContent: "center",
                borderRadius: 50,
                backgroundColor:
                  myInfo["qnacnt"]["total"] - myInfo["qnacnt"]["answer"] <= 10
                    ? "#A8E2B8"
                    : myInfo["qnacnt"]["total"] - myInfo["qnacnt"]["answer"] <=
                      50
                    ? "#FCDB92"
                    : "#F87A7A",
              }}
            >
              <div
                style={{
                  fontSize: "11px",
                  color: "white",
                  // marginBottom:"2px",
                  marginTop: "-4px",
                }}
              >
                미답변수
              </div>
              {/* 10 > 파랑색
                        10 ~ 50 노랑색
                        51 ~ 빨간색 */}
              <div
                style={{
                  fontSize: "30px",
                  fontWeight: 300,
                  // fontWeight:"bold,
                  color: "white",
                }}
              >
                {myInfo["qnacnt"] &&
                  (myInfo["qnacnt"]["total"] - myInfo["qnacnt"]["answer"] > 100
                    ? 99
                    : myInfo["qnacnt"]["total"] - myInfo["qnacnt"]["answer"]) +
                    "+"}
              </div>
            </div>
          )}
          {myInfo["qnacnt"] && (
            <div
              style={{
                marginTop: "12px",
              }}
            >
              {myInfo["qnacnt"]["total"] - myInfo["qnacnt"]["answer"] < 10
                ? "지금 질문을 하면 빠르게 답변을 받을 수 있어요."
                : myInfo["qnacnt"]["total"] - myInfo["qnacnt"]["answer"] <= 50
                ? "선생님의 답변을 주실 때 까지 조금 시간이 걸릴수 있어요."
                : "선생님의 답변이 많이 밀려있어요."}
            </div>
          )}
        </div>
      </div>

      <div style={basis_style.title}>
        <div style={basis_style.desc_flex}>
          전체 질문수
          <div style={basis_style.desc_2}>
            {myInfo["qnacnt"] && myInfo["qnacnt"]["total"]}
          </div>
        </div>
        {isTeacher && (
          <>
            <div style={basis_style.desc_flex}>
              전체 답변수
              <div style={basis_style.desc_2}>
                {myInfo["qnacnt"] && myInfo["qnacnt"]["answer"]}
              </div>
            </div>
            <div style={basis_style.desc_flex}>
              평균 응답시간
              <div style={basis_style.desc_2}>
                {myInfo["qnacnt"] &&
                  util.converToHour(myInfo["qnacnt"]["avgtime"])}
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

const Recnt_Form = ({
  myInfo,
  userInfo,
  apiPro,
  isTeacher,
  r_class,
  r_lecture,
  basis_style,
}) => {
  let params = useParams();
  return (
    <div style={MyInfo_Style.section_w}>
      <div style={MyInfo_Style.text_w_1}>
        <div
          style={{
            fontWeight: "bold",
            display: "flex",
            justifyContent: "center",
            marginTop: "15px",
            // justifyContent:"space-between",
            // fontSize:"14px",
          }}
        >
          <div
            style={{
              display: "flex",
              // alignItems:"flex-end",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <div style={basis_style.title}>최근활동</div>
            {myInfo["r_qnaCnt"] && myInfo["update_date"] > 0 && (
              <div
                style={{
                  fontWeight: 400,
                  display: "flex",
                  alignItems: "flex-end",
                  fontSize: "1px",
                  marginLeft: "3px",
                  color: "#B3B3B3",
                }}
              >
                (갱신일자
                <div
                  style={{
                    // color : style.common.t_color,
                    marginLeft: "8px",
                    // fontWeight:"bold",
                    fontSize: "12px",
                  }}
                >
                  {util.convertToDateTime_form2(myInfo["update_date"])}
                </div>
                )
              </div>
            )}
          </div>
        </div>
        <div style={basis_style.box_flex}>
          {/* <div style={MyInfo_Style.text_w_3}>
                        전체 질문수
                        <div style={{
                            color : isTeacher ? style.common.t_color : style.common.s_color,
                            ...MyInfo_Style.text_2
                        }}>
                            {
                            myInfo["r_qnaCnt"] && myInfo["r_qnaCnt"]["total"]
                            }
                        </div>
                    </div> */}

          {isTeacher && (
            <>
              <div style={basis_style.desc_flex}>
                전체 답변수
                <div style={basis_style.desc_2}>
                  {myInfo["r_qnaCnt"] && myInfo["r_qnaCnt"]["answer"]}
                </div>
              </div>
              <div style={basis_style.desc_flex}>
                평균 응답시간
                <div style={basis_style.desc_2}>
                  {myInfo["r_qnaCnt"] &&
                    util.converToHour(myInfo["r_qnaCnt"]["avgtime"])}
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

const Myinfo_Basis_student = ({
  setIsModi,
  isTeacher,
  myInfo,
  userInfo,
  r_class,
  r_lecture,
  apiPro,
}) => {
  let navigate = useNavigate();
  let params = useParams();


  const basis_style = {
    box: {
      marginTop: "15px",
    },
    box_flex: {
      marginTop: "20px",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    title: {
      display: "flex",
      justifyContent: "center",
      fontWeight: 700,
      fontSize: "16px",
    },
    desc_flex: {
      fontWeight: 400,
      fontSize: "14px",
      marginTop: "7px",
      display: "flex",
      alignItems: "center",
    },
    desc: {
      fontWeight: 400,
      fontSize: "14px",
      marginTop: "7px",
    },
    desc_2: {
      fontWeight: 700,
      fontSize: "14px",
      marginLeft: "10px",
    },
  };
  return (
    <div
      style={{
        padding: "15px 20px",
      }}
    >
      {
        // (r_class || r_lecture) &&
        // (r_lecture) &&
        <div
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              marginLeft: "15px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div
              style={{
                width: "88px",
                height: "88px",
                borderRadius: "15px",
              }}
            >
              <img
                style={{
                  width: "88px",
                  height: "88px",
                  borderRadius: "15px",
                  border: "1px solid #EEEEEE",
                }}
                onError={(e) => {
                  e.currentTarget.src = isTeacher ? face_teacher : face_student;
                }}
                src={
                  myInfo["id"]
                    ?  `${constants.s3_url}/${params["who"]}/${myInfo["id"].normalize("NFD")}.jpg?${new Date().getTime()}`
                    : isTeacher
                    ? face_teacher
                    : face_student
                }
              />
            </div>
            <div
              style={{
                display: "flex",
                marginTop: "15px",
              }}
            >
              <div style={basis_style.title}>{myInfo["name"]}</div>
              <div
                style={{
                  ...basis_style.title,
                  fontWeight: 400,
                  marginLeft: "3px",
                }}
              >
                {/* / 닉네임:{myInfo["nick"]} */}/ {myInfo["nick"]}
              </div>
            </div>
            <div style={basis_style.box}>
              <div style={{ ...basis_style.desc, marginTop: "0px" }}>
                {myInfo["intro"]}
              </div>
            </div>
            <div style={basis_style.box}>
              <div style={basis_style.title}>전화번호</div>
              <div style={basis_style.desc}>
                {myInfo["id"] == userInfo["id"]
                  ? myInfo["tel"]
                  : myInfo["tel"].substr(0, 4) +
                    "***" +
                    myInfo["tel"].substr(7, 4)}
              </div>
            </div>
            {myInfo["id"] == userInfo["id"] && (
              <div style={basis_style.box}>
                <div style={basis_style.title}>이메일</div>
                <div style={basis_style.desc}>{myInfo["email"]}</div>
              </div>
            )}
            {!isTeacher && (
              <>
                {r_lecture && (
                  <div style={basis_style.box}>
                    <div style={basis_style.title}>학교</div>
                    <div style={basis_style.desc}>{myInfo["school"]}</div>
                  </div>
                )}
                {r_class && r_lecture && (
                  <div style={basis_style.box}>
                    <div style={basis_style.title}>진학 희망</div>
                    <div style={basis_style.desc}>{myInfo["collage"]}</div>
                  </div>
                )}
                <div style={basis_style.box_flex}>
                  <div style={basis_style.title}>누적활동</div>
                  <div style={basis_style.desc_flex}>
                    전체 질문수
                    <div style={basis_style.desc_2}>
                      {myInfo["qnacnt"] && myInfo["qnacnt"]["total"]}
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      }
      {myInfo["id"] == userInfo["id"] && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            flex: 1,
            marginTop: "15px",
          }}
        >
          <div
            style={{
              width: "87px",
              height: "40px",
              borderRadius: "15px",
              backgroundColor: "#F09294",
              color: "#FFFFFF",
              fontSize: "14px",
              fontWeight: 700,
              marginRight: "2.5px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
            onClick={() => {
              let isOk = window.confirm(constants.confirm.cf_1);
              if (isOk) {
                navigate("/myinfom/" + params["who"] + "/confirm");
              }
            }}
          >
            회원탈퇴
          </div>
          <div
            style={{
              width: "87px",
              height: "40px",
              borderRadius: "15px",
              backgroundColor: "#5EA3C6",
              color: "#FFFFFF",
              fontSize: "14px",
              fontWeight: 700,
              marginLeft: "2.5px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
            onClick={() => {
              setIsModi(true);
            }}
          >
            정보변경
          </div>
        </div>
      )}
    </div>
  );
};

const RoundBox = ({ myInfo }) => {
  var roundBox_style = {
    round: {
      // width:"80px",
      // height:"80px",
      borderRadius: "20px",
      display: "flex",
      // flexDirection:"column",
      justifyContent: "center",
      alignItems: "center",
      color: "#FFFFFF",
      fontWeight: 400,
      fontSize: "16px",
      padding: "7px 5px",
      width: "133px",
    },
    box: {
      marginTop: "10px",
    },
  };
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
      }}
    >
      <div
        style={{
          ...roundBox_style.box,
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          marginTop: "20px",
        }}
      >
        <div
          style={{
            ...roundBox_style.round,
            backgroundColor:
              myInfo["qnacnt"]["total"] - myInfo["qnacnt"]["answer"] <= 10
                ? "#A8E2B8"
                : myInfo["qnacnt"]["total"] - myInfo["qnacnt"]["answer"] <= 50
                ? "#FCDB92"
                : "#F87A7A",
            marginBottom: "3px",
          }}
        >
          {`미답변수 ${
            myInfo["qnacnt"]["total"] &&
            (myInfo["qnacnt"]["total"] - myInfo["qnacnt"]["answer"] > 100
              ? 99
              : myInfo["qnacnt"]["total"] - myInfo["qnacnt"]["answer"])
          }+`}
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "cneter",
            alignItems: "center",
            fontWeight: 400,
            fontSize: "14px",
            color:
              myInfo["qnacnt"]["total"] - myInfo["qnacnt"]["answer"] <= 10
                ? "#A8E2B8"
                : myInfo["qnacnt"]["total"] - myInfo["qnacnt"]["answer"] <= 50
                ? "#FCDB92"
                : "#F87A7A",
            marginTop: "5px",
            marginBottom: "10px",
          }}
        >
          {myInfo["qnacnt"]["total"] &&
          myInfo["qnacnt"]["total"] - myInfo["qnacnt"]["answer"] < 10
            ? "지금 질문을 하면 빠르게 답변을 받을 수 있어요."
            : myInfo["qnacnt"]["total"] - myInfo["qnacnt"]["answer"] <= 50
            ? "선생님의 답변을 주실 때 까지 조금 시간이 걸릴수 있어요."
            : "선생님의 답변이 많이 밀려있어요."}
        </div>
      </div>
    </div>
  );
};
export default Myinfo_Basis_student;
